import { ControlCommunicationService } from 'src/app/form-builder/services/control-communication.service';
import { FormBuilderComponent } from './../../../form-builder.component';
import { FormGroup, FormControl } from '@angular/forms';
import { UnknownParams, ControlElement } from './../../../interfaces/form.interface';
import { Component, OnInit, Input } from '@angular/core'
import { optionsFactory } from 'angular-confirmation-popover/lib/confirmation-popover.module';

@Component({
    selector: 'radio-group-element',
    templateUrl: './radio-group.component.html'
})
export class RadioGroupComponent implements OnInit {

    attributes: UnknownParams = {}
    validations: UnknownParams = {}

    @Input() form: FormGroup

    @Input() data: ControlElement

    constructor(
        private parentControl: FormBuilderComponent,
        private communication: ControlCommunicationService,
    ) {
        this.communication.changeEmmited$.subscribe(async msg => {
            if (msg.identifier === 'inspection_type') {
                //console.log(msg)
                this.isInspectionNotAnnounced(msg.notAnnounced)
            }
        })
    }

    ngOnInit() {
        if (!this.form) {this.form = this.parentControl.formGroup}
        for (var i in this.data.attributes) {
            const attribute = this.data.attributes[i]
            this.attributes[attribute.name] = attribute.value
        }
        if (this.data.validations) {
            for (var i in this.data.validations) {
                const validation = this.data.validations[i]
                this.validations[validation.name] = validation.value
            }
        }

        // if (!this.attributes.options) throw new Error("Mandatory attribute 'options' was not provided")
        // if(this.attributes.options[0] !== 'C' && this.attributes.options[0] !== 'NC' && this.attributes.options[0] !== 'NA' && this.attributes.options[0] !== 'I' && this.attributes.options[0] !== 'NI' && this.attributes.options[0] !== 'Si' && this.attributes.options[0] !== 'No' && this.attributes.options[0] !== 'Buenas') {
        //     this.form.addControl(this.attributes.name, new FormControl(this.attributes.options[0]))
        // } else {
        //     this.form.addControl(this.attributes.name, new FormControl(''))
        // }
        this.form.addControl(this.attributes.name, new FormControl(''))
        if (this.attributes.other_option) {
            this.form.addControl(this.attributes.other_option[0], new FormControl(false))
            this.form.addControl(this.attributes.other_option[1], new FormControl(''))
        }
    }

    otherOptValueChanged(event: any, key: string) {
        this.form.setValue({
            ...this.form.value,
            [key]: ''
        })
    }

    async isInspectionNotAnnounced(isNotAnnounced) {
        //console.log(this.form, isNotAnnounced)
        //console.log(this.attributes)
        
        if(isNotAnnounced) {
            var newOptions = []
            await this.attributes.options.forEach(option => {
                if(option === "No Anunciada") {
                    //console.log(option)
                    newOptions.push(option)
                }
            });

            this.attributes.options = newOptions
        } else {
            var newOptions = []
            await this.attributes.options.forEach(option => {
                if(option !== "No Anunciada") {
                    //console.log(option)
                    newOptions.push(option)
                }
            });

            this.attributes.options = newOptions
        }
    }

}