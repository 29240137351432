import { SocketsService } from './core/services/sockets.service';
import { Component, HostListener } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { AuthenticationService } from './core/services';
import { User } from './core/models';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'eCert';
  user: User;
  loading = false;
  initials = ''
  operadores = false;
  public photoUrl = ''
  solicitudes = false;
  usuarios = false
  revisiones = false
  inspecciones = false
  dictamenes = false
  expedientes = false
  catalogos = false
  cotizador = false
  certificados = false
  sustancias = false
  perfil = false
  no_conformidades = false
  certificadoExterno = true //Validacion para mostrar/ocultar barra superior, certificado externo
  emails = false
  supportUtilities = false
  reports = false
  regenerateNcs = false

  public innerWidth: any;

  constructor(private authenticationService: AuthenticationService, private router: Router, private socketsService: SocketsService) {
    this.authenticationService.user.subscribe((x) => {
      this.user = x
      this.initials = x?.firstName.substring(0, 1) + x?.middleName.substring(0, 1)

      this.photoUrl = x?.photoUrl
      //console.log(x)
    });
    this.router.events.subscribe((e) => {
      if (e instanceof NavigationEnd) {
        //console.log(e.url);
        if (e.url.startsWith('/operadores')) { this.operadores = true } else { this.operadores = false }
        if (e.url.startsWith('/solicitudes')) { this.solicitudes = true } else { this.solicitudes = false }
        if (e.url.startsWith('/usuarios')) {
          if (this.user.type == 'ADMINISTRADOR_SISTEMA' || this.user.type == 'ROOT') {
            this.usuarios = true
          }
        } else { this.usuarios = false }
        if (e.url.startsWith('/revisiones')) { this.revisiones = true } else { this.revisiones = false }
        if (e.url.startsWith('/inspecciones')) { this.inspecciones = true } else { this.inspecciones = false }
        if (e.url.startsWith('/dictamenes')) { this.dictamenes = true } else { this.dictamenes = false }
        if (e.url.startsWith('/expedientes')) { this.expedientes = true } else { this.expedientes = false }
        if (e.url.startsWith('/catalogos')) { this.catalogos = true } else { this.catalogos = false }
        if (e.url.startsWith('/cotizador')) { this.cotizador = true } else { this.cotizador = false }
        if (e.url.startsWith('/certificados')) { this.certificados = true } else { this.certificados = false }
        if (e.url.startsWith('/sustancias')) { this.sustancias = true } else { this.sustancias = false }
        if (e.url.startsWith('/perfil')) { this.perfil = true } else { this.perfil = false }
        if (e.url.startsWith('/no_conformidades')) { this.no_conformidades = true } else { this.no_conformidades = false }
        if (e.url.startsWith('/certificado_consulta')) { this.certificadoExterno = false } else { this.certificadoExterno = true } //Ruta del certificado externo
        if (e.url.startsWith('/emails')) { this.emails = true } else { this.emails = false }
        if (e.url.startsWith('/support_utilities')) { this.supportUtilities = true } else { this.supportUtilities = false }
        if (e.url.startsWith('/reportes')) { this.reports = true } else { this.reports = false }
        if (e.url.startsWith('/regenerar_ncs')) { this.regenerateNcs = true } else { this.regenerateNcs = false }
      }
    });
  }

  ngOnInit() {
    this.innerWidth = window.innerWidth;
    //this.socketsService.setupSocketConnection()
  }

  //If you wanna keep it updated on resize
  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.innerWidth = window.innerWidth;
  }

  logout() {
    this.authenticationService.logout();
  }
}