import { AuthenticationService } from '@app/core/services';
import { ControlCommunicationService } from '../../../form-builder/services/control-communication.service';
import { AlertService, EvaluationsService, NcsService, UsersService, RequestsService, InspectionsService } from '../../services'
import { Component, Input, OnInit, OnDestroy } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { FormBuilder, FormGroup } from '@angular/forms';
import { first } from 'rxjs/operators';
import { Subscription } from 'rxjs';

const moment = require('moment')

import { Packer } from "docx";
import { DocumentCreatorMTO_FRYD_05_v1 } from '@app/features/generator/V1/docx.MTO_FRYD_05'; //ID: 68
import { DocumentCreatorMTO_FRYD_05_v2 } from '@app/features/generator/V2/docx.MTO_FRYD_05'; //ID: 268
import { DocumentCreatorMTO_FRYD_05_v3 } from '@app/features/generator/V3/docx.MTO_FRYD_05'; //ID: 368

@Component({
    selector: 'modal-no-conformidad',
    templateUrl: 'modal-no-conformidad.component.html',
    styleUrls: ['../../../app.component.scss']
})
export class ModalNoConformidad implements OnInit, OnDestroy {
    form: FormGroup;

    @Input() public inspectionId 
    @Input() public formId
    @Input() public prevData // datos básicos, como operador, inspector, etc
    @Input() public isEvaluation = false
    @Input() public ncNotificationSent = false
    @Input() public director
    @Input() public recordId
    @Input() public version
    @Input() public id //Id de inspeccion cuando esta en hallazgos


    loading = false;
    docloading = true
    submitted = false;
    res = false
    reviewers = []
    forbiddenId = false;
    submission = null
    requirements = null
    response = null
    showForm = false
    showDoc = true
    bdoc
    url
    saving = false
    alreadySaving = false

    attachments = []
    formsToNC = []
    files

    validateAllNC = true
    validateNC = []

    title = ''
    data

    loaded = false

    isReject = false
    isOnlySend = false
    isSave = false

    generating = false

    signatureUrl = ''
    dictator

    percentage = 0

    button = 'Dictaminar'

    private subscriptions: Subscription

    constructor(
        private formBuilder: FormBuilder,
        public modal: NgbActiveModal,
        private alertService: AlertService,
        private evaluationService: EvaluationsService,
        private communication: ControlCommunicationService,
        private inspectionService: InspectionsService,
        private ncsService: NcsService,
        private authService: AuthenticationService,
        private users: UsersService,
        private requestsService: RequestsService
    ) {
        const currentUser = authService.userValue
        this.subscriptions = this.communication.changeEmmited$.subscribe(async msg => {
            if (msg.identifier === 'send-form-nc' && !this.saving && !this.loaded) {
                const formGroup = msg.form as FormGroup
                const f = formGroup.value
                var data = f

                for (var i in this.requirements) {
                    const requirement = this.requirements[i]
                    if (Object.keys(requirement.data).length > 0) {
                        if (requirement.isDynamic) {
                            let form = data[requirement.requiredInto][0]
                            let nval = []
                            for (var j in requirement.data) {
                                const r = requirement.data[j]
                                let s = {}
                                f[requirement.requiredInto].forEach(elem => {
                                    Object.keys(r).forEach(key => {
                                        if (elem['nombre_comercial'] == r['nombre_comercial']) {
                                            s = elem
                                        }
                                    })
                                })
                                Object.keys(r).forEach(key => {
                                    s[key] = r[key]
                                })
                                nval.push(s)
                            }
                            data[requirement.requiredInto] = nval
                        } else if (requirement.full) {
                            for (var j in requirement.data.values) {
                                const val = requirement.data.values[j]
                                if (typeof val.value != 'object') {
                                    data[val.name] = val.value
                                } else if (Array.isArray(val.value)) {
                                    for (var k in val.value) {
                                        const elem = val.value[k]
                                        data[val.name][k] = {
                                            ...data[val.name][k],
                                            ...elem
                                        }
                                    }
                                } else {
                                    data[val.name] = {
                                        ...data[val.name],
                                        ...val.value
                                    }
                                }
                            }
                        } else {
                            data[requirement.requiredInto] = requirement.data
                        }
                    }
                }
                
                
                let requiresAction = []
                if (this.prevData.director) {
                    for(var i in data['lista-no-conformidades']) {
                        const elem = data['lista-no-conformidades'][i]
                        elem['aceptacion_revisor'] = `${currentUser.firstName} ${currentUser.middleName} ${currentUser.lastName ? currentUser.lastName : ''}`.trim()
                        elem['inspectionId'] = this.id
                        elem['evaluationId'] = this.inspectionId
                        elem['comentarios_operador'] = data['lista-no-conformidades'][i].no_conformidad_evidencia_a_generar
                        elem['forms'] = this.formsToNC
                        elem['correcciones'] = ''
                        elem["files"] = []
                        elem["ncNotificationSent"] = this.ncNotificationSent
                        elem["version"] = this.version

                        requiresAction.push(false)

                        if(i === '0') {
                            this.validateNC.push(true)
                        } else {
                            this.validateNC.push(false)
                        }
                    }
                }

                if(this.validateNC.length === 1) {
                    this.validateAllNC = false
                }

                for(var i in data['lista-no-conformidades']) {
                    const elem = data['lista-no-conformidades'][i]
                
                    this.loadAttachments(elem.informe_documentos_url)

                    if(!elem["fecha"] || !elem["no_conformidad_fecha_limite"]) {
                        const fecha = moment()
                        const fecha2 = moment().add(30, 'days')
                        var fechaInicio = fecha.format("YYYY-MM-DD")
                        var fechaLimite = fecha2.format("YYYY-MM-DD")
    
                        elem['fecha'] = fechaInicio
                        elem['no_conformidad_fecha_limite'] = fechaLimite
                    }

                    elem['dictator'] = this.dictator
                }

                localStorage.setItem("requiresAction"+this.inspectionId, JSON.stringify(requiresAction))
                
                setTimeout(() => {
                    this.loaded = true
                    this.communication.emitEvent({
                        identifier: 'set-values',
                        form: data
                    })
                }, 100);
            } else if (msg.identifier === 'send-form-nc' && this.saving) {
                this.alreadySaving = true
                const formGroup = msg.form as FormGroup

                try {
                    await this.requestsService.changeFilesIntoUrls(formGroup, this.formId, this.recordId)
                } catch (error) {
                    //console.log(error)
                }

                const f = formGroup.value
                
                for(var i in f["lista-no-conformidades"]) {
                    var docs = []
                    for(var j in f["lista-no-conformidades"][i].forms) {
                        const forms = f["lista-no-conformidades"][i].forms[j]

                        if(forms.selected && forms.id !== -1) {
                            docs.push(forms)
                        }
                    }

                    let hash = {};
                    docs = docs.filter(o => hash[o.idDoc] ? false : hash[o.idDoc] = true);

                    f["lista-no-conformidades"][i]["docs"] = docs
                }

                if(!this.isSave) {
                    var requiresAction = JSON.parse(localStorage.getItem("requiresAction"+this.inspectionId))
                    if(!this.isReject && this.isOnlySend) {
                        for(var i in f["lista-no-conformidades"]) {
                            f["lista-no-conformidades"][i]["requiresAction"] = requiresAction[i]
                        }
                    }
                }

                if (this.isEvaluation && f['personas_entrevistadas'] == undefined) {
                    this.evaluationService.saveForm(this.inspectionId, this.formId, f).subscribe(data => {
                        this.alertService.info('Formulario guardado', {
                            autoClose: true
                        })
                        this.saving = false

                        if(!this.isSave) {
                            if(this.isReject) {
                                this.evaluationService.getForm(this.inspectionId, this.formId, false, this.id)
                                    .pipe(first())
                                    .subscribe(
                                        async response => {
                                            var payload = new FormData()

                                            var findings = []
                                            for(var i in f["lista-no-conformidades"]) {
                                                const ncs = f["lista-no-conformidades"][i]
                                                var actionDetails = []

                                                if(f["lista-no-conformidades"][i].requiresAction) {
                                                    for(var j in ncs.forms) {
                                                        if(ncs.forms[j].selected) {
                                                            actionDetails.push({
                                                                "form_id": ncs.forms[j].id,
                                                                "message": ncs.correcciones,
                                                                "requiresAction": f["lista-no-conformidades"][i].requiresAction
                                                            })
                                                        }
                                                    }
                                                } else {
                                                    actionDetails.push({
                                                        "form_id": 404,
                                                        "message": '',
                                                        "requiresAction": false
                                                    })
                                                }

                                                findings.push({
                                                    actionDetails: actionDetails
                                                })
                                            }

                                            payload.append('json_data', JSON.stringify({
                                                findings: findings,
                                            }))

                                            const recordId = response.record.Id_Record
                                            const inspectionId = response.inspectionId

                                            this.ncsService.rejectNC(recordId, inspectionId, payload)
                                                .pipe(first())
                                                .subscribe(
                                                    response => {
                                                        if(response["success"]) {
                                                            this.alertService.success("Notificacion enviada al operador de manera satisfactoria");
                                                            this.modal.close(f)
                                                            this.modal.dismiss()
                                                        }
                                                    },
                                                    error => {
                                                        this.alertService.error("Error al enviar notifiacion al operador");
                                                        this.loading = false
                                                        this.generating = false
                                                    }
                                                )
                                        },
                                        error => {
                                            this.alertService.error(error);
                                            this.loading = false
                                            this.generating = false
                                        }
                                    )
                            } else {
                                this.evaluationService.getForm(this.inspectionId, this.formId, false, this.id)
                                    .pipe(first())
                                    .subscribe(
                                        async response => {
                                            var payload = new FormData()

                                            var findings = []
                                            var cont = 0
                                            for(var i in f["lista-no-conformidades"]) {
                                                const ncs = f["lista-no-conformidades"][i]

                                                let documentCreator
                                                if(this.version === 1) documentCreator = new DocumentCreatorMTO_FRYD_05_v1()
                                                if(this.version === 2) documentCreator = new DocumentCreatorMTO_FRYD_05_v2()
                                                if(this.version === 3) documentCreator = new DocumentCreatorMTO_FRYD_05_v3()

                                                const document = await documentCreator.create_MTO_FRYD_05(response, i)                                                
                                                const blob = await Packer.toBlob(document)
                                                var actionDetails = []

                                                if(f["lista-no-conformidades"][i].requiresAction) {
                                                    for(var j in ncs.forms) {
                                                        if(ncs.forms[j].selected) {
                                                            payload.append('files'+cont, blob, 'file'+i+'.docx')
                                                            
                                                            actionDetails.push({
                                                                "form_id": ncs.forms[j].id,
                                                                "message": ncs.correcciones,
                                                                "requiresAction": f["lista-no-conformidades"][i].requiresAction
                                                            })

                                                            cont++
                                                        }
                                                    }
                                                } else {
                                                    payload.append('files'+cont, blob, 'file'+i+'.docx')

                                                    actionDetails.push({
                                                        "form_id": 404,
                                                        "message": '',
                                                        "requiresAction": false
                                                    })

                                                    cont++
                                                }

                                                findings.push({
                                                    actionDetails: actionDetails
                                                })
                                            }

                                            payload.append('json_data', JSON.stringify({
                                                findings: findings,
                                            }))

                                            const recordId = response.record.Id_Record
                                            const inspectionId = response.inspectionId

                                            this.ncsService.sendNotification(recordId, inspectionId, payload)
                                                .pipe(first())
                                                .subscribe(
                                                    response => {
                                                        if(response["success"]) {
                                                            this.alertService.success("Notificacion enviada al operador de manera satisfactoria");
                                                            this.modal.close(f)
                                                            this.modal.dismiss()
                                                        }
                                                    },
                                                    error => {
                                                        this.alertService.error("Error al enviar notifiacion al operador");
                                                        this.loading = false
                                                        this.generating = false
                                                    }
                                                )
                                        },
                                        error => {
                                            this.alertService.error(error);
                                            this.loading = false
                                            this.generating = false
                                        }
                                    )
                            }
                        } else {
                            this.loading = false
                            this.generating = false
                        }
                    }, error => {
                        this.alertService.info('Error al guardar formulario', {
                            autoClose: true
                        })
                        
                        this.saving = false
                        this.loading = false
                        this.generating = false
                    })
                } else if (f['personas_entrevistadas'] == undefined) {
                    this.inspectionService.saveForm(this.inspectionId, this.formId, f, null, null).subscribe(data => {
                        this.alertService.info('Formulario guardado', {
                            autoClose: true
                        })
                        this.generating = false
                        this.saving = false
                        this.modal.close(f)
                        this.modal.dismiss()
                    }, error => {
                        this.alertService.info('Error al guardar formulario', {
                            autoClose: true
                        })
                    })
                }
            } else if(msg.identifier === "validate-form-nc") {
                this.validateNC[msg.index] = true

                this.validateAllNC = false
                for(var i in this.validateNC) {
                    if(!this.validateNC[i]) {
                        this.validateAllNC = true
                        break
                    }
                }
            } else if(msg.identifier === 'validate-actions') {
                var requiresAction = JSON.parse(localStorage.getItem("requiresAction"+this.inspectionId))

                if(requiresAction.includes(true)) {
                    this.button = 'Notificar a operador'
                } else {
                    this.button = 'Dictaminar'
                }
            } else if (msg.identifier === 'file') {
                let added = false
                for (var i in this.files) {
                    const file = this.files[i]
                    if (file.formname == msg.formname && file.atIndex == msg.atIndex) {
                        this.files[i] = msg
                        added = true
                        break
                    }
                }
                if (!added) {
                    this.files.push(msg)
                }
            } else if (msg.identifier === 'uploading') {
                this.percentage = msg.percentage
            }
        })
    }

    getFilename(url: string) {
        if (url.constructor.name !== 'String') {
            return ''
        }
        const parts = url.split("/")
        const name = parts[parts.length - 1]
        return name
    }

    loadAttachments(submission: any) {
        // puede ser un array
        if (Array.isArray(submission)) {
            for (var i in submission) {
                const elem = submission[i]
                this.loadAttachments(elem)
            }
            // puede ser un string    
        } else if (typeof submission === 'string') {
            if (submission.includes('http')) {
                this.attachments.push(submission)
            }
            // o puede ser un objeto
        } else if (typeof submission === 'object' && submission) {
            const keys = Object.keys(submission)
            for (var i in keys) {
                //if(keys[i] === "informe_documentos_url") {
                    this.loadAttachments(submission[keys[i]])
                //}
            }
        }
    }

    ngOnInit() {
        this.saving = false
        if (this.isEvaluation) {
            this.users.getSignature(this.director.dictatorId)
                .pipe(first())
                .subscribe(data => {
                    if (data.success) {
                        this.signatureUrl = data.file
                    }
                }
            )

            this.evaluationService.getForm(this.inspectionId, this.formId, false, this.id)
                .pipe(first())
                .subscribe(
                    async response => {
                        this.title = response.form.title
                        this.submission = response.submission
                        this.requirements = response.requirements
                        this.response = response
                        this.form = response.form
                        this.showDoc = false
                        this.showForm = true
                        this.docloading = false

                        this.dictator = {
                            dictator: this.director.dictator,
                            signature: this.signatureUrl
                        }

                        for(var i in response.forms) {
                            this.formsToNC.push({
                                id: response.forms[i].id,
                                form: response.forms[i].name,
                                title: response.forms[i].title,
                                idDoc: response.forms[i].docId,
                                selected: false
                            })
                        }
                        this.formsToNC.push({
                            id: -1,
                            form: "Otro",
                            title: "",
                            idDoc: -1,
                            selected: false
                        })

                        if(this.ncNotificationSent) {
                            this.files = await this.getFiles(response.record.Id_Record, response.inspectionId)
                        }

                        // let signs = {
                        //     idOperador: response.customer.Id_Operator,
                        //     idInspector: response.inspectorId,
                        //     idResponsible: response.responsableId,
                        //     idReviewer: response.reviewer.reviewerId
                        // }
                        localStorage.setItem("signs"+this.inspectionId, JSON.stringify(response.signatures))

                        if (this.response.special) {
                            let lastForm = {}
                            for (var i in this.response.special) {
                                const obj = this.response.special[i]
                                lastForm[obj.name] = obj.value
                            }
                            setTimeout(() => {
                                this.communication.emitEvent({
                                    identifier: 'set-values',
                                    form: lastForm
                                })
                            }, 100);
                        }

                        if (this.submission !== null) {
                            for(var i in this.submission[0].value) {
                                const anexos = this.submission[0].value[i]
                                this.loadAttachments(anexos.informe_documentos_url)
                            }

                            // Obtiene cada conjunto de registros del submission (lista-no-conformidades)
                            let lastForm = {}
                            for (var i in this.submission) {
                                const obj = this.submission[i]
                                lastForm[obj.name] = obj.value
                            }

                            // Recorre cada conjunto de registros del submission (lista-no-conformidades)
                            for(var i in lastForm["lista-no-conformidades"]) {
                                lastForm["lista-no-conformidades"][i].ncNotificationSent = this.ncNotificationSent
                                lastForm["lista-no-conformidades"][i].dictator = this.dictator

                                if(this.files) {
                                    lastForm["lista-no-conformidades"][i]["files"] = this.files[i]
                                } else {
                                    lastForm["lista-no-conformidades"][i]["files"] = []
                                }

                                /* temporal patch start*/
                                lastForm["lista-no-conformidades"][i]["inspectionId"] = this.id
                                lastForm["lista-no-conformidades"][i]["evaluationId"] = this.inspectionId
                                /* temporal patch end*/
                            }

                            var submissionList = this.getValueList(this.submission, "lista-no-conformidades")
                            let requiresAction = []
                            for(var i in submissionList) {
                                if(i === '0') {
                                    this.validateNC.push(true)
                                } else {
                                    this.validateNC.push(false)
                                }

                                requiresAction.push(false)
                            }

                            if(this.validateNC.length === 1) {
                                this.validateAllNC = false
                            }
                            localStorage.setItem("requiresAction"+this.inspectionId, JSON.stringify(requiresAction))

                            setTimeout(() => {
                                this.communication.emitEvent({
                                    identifier: 'set-values',
                                    form: lastForm
                                })
                            }, 100);
                        } else {
                            setTimeout(() => {
                                this.communication.emitEvent({
                                    identifier: 'submit-nc'
                                })
                            }, 100);
                        }
                    },
                    error => {
                        this.alertService.error(error);
                    })
        } else {
            this.inspectionService.getPlanForm(this.inspectionId, this.formId)
                .pipe(first())
                .subscribe(
                    response => {
                        this.title = response.data.form.title
                        this.submission = response.data.submission
                        this.loadAttachments(this.submission)
                        this.requirements = response.data.requirements
                        this.response = response.data
                        this.showDoc = false
                        this.showForm = true
                        this.docloading = false
                        if (this.response.special) {
                            let lastForm = {}
                            for (var i in this.response.special) {
                                const obj = this.response.special[i]
                                lastForm[obj.name] = obj.value
                            }
                            setTimeout(() => {
                                this.communication.emitEvent({
                                    identifier: 'set-values',
                                    form: lastForm
                                })
                            }, 100);
                        }
                        if (this.submission !== null) {
                            let lastForm = {}
                            for (var i in this.submission) {
                                const obj = this.submission[i]
                                lastForm[obj.name] = obj.value
                            }
                            setTimeout(() => {
                                this.communication.emitEvent({
                                    identifier: 'set-values',
                                    form: lastForm
                                })
                            }, 100);
                        }
                        setTimeout(() => {
                            this.communication.emitEvent({
                                identifier: 'submit-nc'
                            })
                        }, 100);
                    },
                    error => {
                        this.alertService.error(error);
                    })
        }
    }

    ngOnDestroy() {
        this.subscriptions.unsubscribe()
        localStorage.clear()
    }

    getFiles = async (recordId, inspectionId) => {
        return new Promise((resolve) => {
            this.ncsService.getNcsOtherOperatorFiles(recordId, inspectionId)
                .pipe(first())
                .subscribe(
                    async data => {
                        var files = []
                        for(var i in data.findings) {
                            const finding = data.findings[i].finding

                            var dataURLs = []
                            for(var j in data.data) {
                                const dataTemp = data.data[j]
                                if(finding === dataTemp.findingId) {
                                    dataURLs.push({
                                        message: dataTemp.message,
                                        url: dataTemp.url
                                    })
                                }
                            }
                            
                            files.push(dataURLs)
                        }

                        resolve(files)
                    },
                    error => {
                        resolve(null)
                        this.alertService.error("Error al consultar archivos que el operador subió");
                    }
                )
        })
    }

    onSubmit(isOnlySend) {
        this.isReject = false
        this.isSave = false
        this.isOnlySend = isOnlySend

        this.generating = true

        this.submitted = true;
        this.saving = true;
        // Reset alerts on submit
        this.alertService.clear();
        this.communication.emitEvent({
            identifier: 'submit-nc'
        })
        this.loading = true;
        this.forbiddenId = false
    }

    save() {
        this.isSave = true

        this.submitted = true;
        this.saving = true;
        // Reset alerts on submit
        this.alertService.clear();
        this.communication.emitEvent({
            identifier: 'submit-nc'
        })
        this.loading = true;
        this.forbiddenId = false
    }

    reject() {
        this.isReject = true
        this.isSave = false
        
        this.generating = true

        this.submitted = true;
        this.saving = true;
        // Reset alerts on submit
        this.alertService.clear();
        this.communication.emitEvent({
            identifier: 'submit-nc'
        })
        this.loading = true;
        this.forbiddenId = false
    }

    getValueList(submission, name) {
        var value = null
        for (const i in submission) {
            const element = submission[i]
            if (element.name === name) {
                value = element.value
            }
        }
        if (value) return value
        else return []
    }

}