import { AlignmentType, Document, Paragraph, TextRun, Media, Header, Footer, Table, TableRow, TableCell, VerticalAlign, WidthType, PageNumber, BorderStyle } from "docx";
import { logo } from "../logo";
import {userWithoutSign} from "../userWithoutSign"
import { DatePipe } from '@angular/common';

export class DocumentCreatorMTO_FSOL_08_1_4_v2 {

    public async create_MTO_FSOL_08_1_4(data, isReview = true, signature = '', signature2 = ''): Promise<Document> {
        const document = new Document();
        const image = Media.addImage(document, logo, 50, 50);
        var datePipe = new DatePipe('es-MX');

        let signatureImage = null
        if (signature) {
          signatureImage = await this.getBase64FromUrl(signature)
        } else {
          signatureImage = userWithoutSign
        }

        let signatureImage2 = null
        if (signature2) {
          signatureImage2 = await this.getBase64FromUrl(signature2)
        } else {
          signatureImage2 = isReview ? '' : userWithoutSign
        }

        var unidadesProduccion = ''
        const unidades = this.getValueList(data.submission, "lista_unidades")
        unidades.forEach(unidad => {
            unidadesProduccion += unidad.nombre_unidad_produccion + ', '
        });
        unidadesProduccion = unidadesProduccion.slice(0, -2)

        document.addSection({
            headers: {
                default: new Header({
                    children: [
                        new Paragraph({ alignment: AlignmentType.CENTER, children: [image], }),
                        new Paragraph({
                            alignment: AlignmentType.CENTER, children: [
                                new TextRun({
                                    font: 'Book Antiqua',
                                    size: 20,
                                    bold: true,
                                    children: [`MTO-FSOL-08-1-4| R 05 | 22-Abril-2021 | Pág. `, PageNumber.CURRENT]
                                }),
                                new TextRun({
                                    font: 'Book Antiqua',
                                    size: 20,
                                    bold: true,
                                    children: [' de ', PageNumber.TOTAL_PAGES]
                                }),
                            ], spacing: { before: 200, after: 300 }
                        })
                    ]
                })
            },
            footers: {
                default: new Footer({
                    children: [
                        new Paragraph({ alignment: AlignmentType.CENTER }),
                        this.createFooter()
                    ]
                }),
            },
            children: [
                new Paragraph({
                    alignment: AlignmentType.CENTER,
                    children: [
                        new TextRun({
                            text: 'PLAN ORGÁNICO PRODUCCIÓN VEGETAL DE RECOLECCIÓN SILVESTRE',
                            font: 'Calibri (Cuerpo)',
                            size: 22,
                            bold: true
                        })
                    ],
                    spacing: { after: 200 }
                }),
                this.createTableUDP(data, datePipe),
                new Paragraph({
                    spacing: {
                        after: 400
                    }
                }),
                this.createRegisters(data.submission),
                new Paragraph({
                    spacing: {
                        after: 400
                    }
                }),
                this.createHandling(data.submission),
                new Paragraph({
                    alignment: AlignmentType.JUSTIFIED,
                    children: [
                        new TextRun({
                            text: `El que suscribe: ${data.customer.Legal_Authorities[0].Name} ${data.customer.Legal_Authorities[0].Middle_Name} ${data.customer.Legal_Authorities[0].Last_Name ? data.customer.Legal_Authorities[0].Last_Name : ''} Titular / Representante Legal de la(s) Unidad(es) de Producción / Empresa: ${data.customer.Company_Type === "PERSONA_FISICA" ? unidadesProduccion : data.customer.Company_Name},  declaro que la información descrita en este Plan Orgánico y documentos adjuntos es verídica, y me comprometo a mantenerlo actualizado, al igual que las actividades descritas en él; con la finalidad de cumplir con la Ley de Productos Orgánicos de México (LPO) y el Régimen Orgánico de Canadá (COR/USCOEA).`,
                            font: 'Calibri (Cuerpo)',
                            size: 22
                        })
                    ],
                    spacing: {
                        before: 400,
                        after: 400
                    },
                }),
                new Paragraph({
                    alignment: AlignmentType.JUSTIFIED,
                    children: [
                        new TextRun({
                            text: 'Atentamente:',
                            font: 'Arial',
                            size: 18, bold: true
                        })
                    ],
                    spacing: {
                        before: 400,
                        after: 400
                    },
                }),
                new Paragraph({
                  alignment: AlignmentType.CENTER,
                  children: [
                    signatureImage ? Media.addImage(document, signatureImage.toString(), 200, 100) : new TextRun({
                      text: ''
                    })
                  ]
                }),
                new Paragraph({
                    alignment: AlignmentType.LEFT,
                    children: [
                        new TextRun({
                            text: `Fecha: ${datePipe.transform(new Date(data.record.Applications[0].Creation_Date.includes('UTC') ? data.record.Applications[0].Creation_Date.replace('UTC', '').trim() : data.record.Applications[0].Creation_Date), 'd-MMMM-y')}                  Nombre y firma del operador: ${data.customer.Legal_Authorities[0].Name} ${data.customer.Legal_Authorities[0].Middle_Name} ${data.customer.Legal_Authorities[0].Last_Name ? data.customer.Legal_Authorities[0].Last_Name : ''}`,
                            font: 'Arial',
                            size: 20,
                            italics: true
                        })
                    ],
                    spacing: {
                        after: 200
                    }
                }),
                new Table({
                  rows: [
                      new TableRow({
                          children: [
                              new TableCell({
                                  children: [
                                      new Paragraph({
                                          alignment: AlignmentType.CENTER,
                                          children: [
                                              new TextRun({
                                                  text: 'EVALUACIÓN POR EL REVISOR',
                                                  font: 'Arial',
                                                  size: 20,
                                                  bold: true
                                              })
                                          ],
                                          spacing: {
                                              after: 75,
                                              before: 75
                                          }
                                      }),
                                  ],
                                  verticalAlign: VerticalAlign.CENTER,
                                  columnSpan: 2,
                                  shading: {
                                      fill: '#C0C0C0'
                                  }
                              })
                          ]
                      }),
                      new TableRow({
                          children: [
                              new TableCell({
                                  children: [
                                      new Paragraph({
                                          alignment: AlignmentType.RIGHT,
                                          children: [
                                              new TextRun({
                                                  text: 'Nombre del Revisor: ',
                                                  font: 'Calibri (Cuerpo)',
                                                  size: 22,
                                              })
                                          ],
                                          spacing: { before: 100, after: 200 }
                                      })],
                                  verticalAlign: VerticalAlign.CENTER,
                              }),
                              new TableCell({
                                  children: [
                                      new Paragraph({
                                          alignment: AlignmentType.LEFT,
                                          children: [
                                              new TextRun({
                                                  text: data.reviewer ? data.reviewer.name : '',
                                                  font: 'Calibri (Cuerpo)',
                                                  size: 22,
                                              })
                                          ],
                                          spacing: { before: 100, after: 200 }
                                      })],
                                  verticalAlign: VerticalAlign.CENTER,
                              }),
                          ],
                      }),
                      new TableRow({
                          children: [
                              new TableCell({
                                  children: [
                                      new Paragraph({
                                          alignment: AlignmentType.RIGHT,
                                          children: [
                                              new TextRun({
                                                  text: 'Fecha: ',
                                                  font: 'Calibri (Cuerpo)',
                                                  size: 22,
                                              })
                                          ],
                                          spacing: { before: 100, after: 200 }
                                      })],
                                  verticalAlign: VerticalAlign.CENTER,
                              }),
                              new TableCell({
                                  children: [
                                      new Paragraph({
                                          alignment: AlignmentType.LEFT,
                                          children: [
                                              new TextRun({
                                                  text: `${data.reviewer ? datePipe.transform(new Date(data.reviewer.date), 'd-MMMM-y') : ''}`,
                                                  font: 'Calibri (Cuerpo)',
                                                  size: 22,
                                              })
                                          ],
                                          spacing: { before: 100, after: 200 }
                                      })],
                                  verticalAlign: VerticalAlign.CENTER,
                              }),
                          ],
                      }),
                      new TableRow({
                          children: [
                              new TableCell({
                                  children: [
                                      new Paragraph({
                                          alignment: AlignmentType.RIGHT,
                                          children: [
                                              new TextRun({
                                                  text: 'Conformidad: ',
                                                  font: 'Calibri (Cuerpo)',
                                                  size: 22,
                                              })
                                          ],
                                          spacing: { before: 100, after: 200 }
                                      })],
                                  verticalAlign: VerticalAlign.CENTER,
                              }),
                              new TableCell({
                                  children: [
                                      new Paragraph({
                                          alignment: AlignmentType.LEFT,
                                          children: [
                                              new TextRun({
                                                  text: `${this.getValue(data.revision, 'plan_organico_conformidad_no_conformidades_graves') === true ? '■' : '❏'} No conformidades graves, no procederá la certificación.`,
                                                  font: 'Arial',
                                                  size: 18
                                              })
                                          ]
                                      }),
                                      new Paragraph({
                                          alignment: AlignmentType.LEFT,
                                          children: [
                                              new TextRun({
                                                  text: `${this.getValue(data.revision, 'plan_organico_conformidad_no_conformidades_menores_con_hallazgos') === true ? '■' : '❏'} No conformidades menores, se requiere de corrección antes de inspección. Listar los hallazgos abajo.`,
                                                  font: 'Arial',
                                                  size: 18
                                              })
                                          ]
                                      }),
                                      new Paragraph({
                                          alignment: AlignmentType.LEFT,
                                          children: [
                                              new TextRun({
                                                  text: `${this.getValue(data.revision, 'plan_organico_conformidad_no_conformidades_menores') === true ? '■' : '❏'} No conformidades menores, se puede realizar inspección.`,
                                                  font: 'Arial',
                                                  size: 18
                                              })
                                          ]
                                      }),
                                      new Paragraph({
                                          alignment: AlignmentType.LEFT,
                                          children: [
                                              new TextRun({
                                                  text: `${this.getValue(data.revision, 'plan_organico_conformidad_conformidad') === true ? '■' : '❏'} Conformidad`,
                                                  font: 'Arial',
                                                  size: 18
                                              })
                                          ]
                                      }),
                                      new Paragraph({
                                          alignment: AlignmentType.LEFT,
                                          children: [
                                              new TextRun({
                                                  text: `${this.getValue(data.revision, 'plan_organico_conformidad_otro') === true ? `■ Otro: ${this.getValue(data.revision, 'plan_organico_conformidad_otro_text')}` : '❏ Otro:'}`,
                                                  font: 'Arial',
                                                  size: 18
                                              })
                                          ]
                                      })
                                  ],
                                  verticalAlign: VerticalAlign.CENTER,
                                  columnSpan: 1,
                              }),
                          ],
                      }),
                      new TableRow({
                          children: [
                              new TableCell({
                                  children: [
                                      new Paragraph({
                                          alignment: AlignmentType.RIGHT,
                                          children: [
                                              new TextRun({
                                                  text: 'Hallazgos: ',
                                                  font: 'Calibri (Cuerpo)',
                                                  size: 22,
                                              })
                                          ],
                                          spacing: { before: 100, after: 200 }
                                      })],
                                  verticalAlign: VerticalAlign.CENTER,
                              }),
                              new TableCell({
                                  children: [
                                      new Paragraph({
                                          alignment: AlignmentType.LEFT,
                                          children: [
                                              new TextRun({
                                                  text: this.getValue(data.revision, "plan_organico_hallazgos"),
                                                  font: 'Calibri (Cuerpo)',
                                                  size: 22,
                                              })
                                          ],
                                          spacing: { before: 100, after: 200 }
                                      })],
                                  verticalAlign: VerticalAlign.CENTER,
                              }),
                          ],
                      }),
                      new TableRow({
                          children: [
                              new TableCell({
                                  children: [
                                      new Paragraph({
                                          alignment: AlignmentType.RIGHT,
                                          children: [
                                              new TextRun({
                                                  text: 'Recomendaciones para inspector: ',
                                                  font: 'Calibri (Cuerpo)',
                                                  size: 22,
                                              })
                                          ],
                                          spacing: { before: 100, after: 200 }
                                      })],
                                  verticalAlign: VerticalAlign.CENTER,
                              }),
                              new TableCell({
                                  children: [
                                      new Paragraph({
                                          alignment: AlignmentType.LEFT,
                                          children: [
                                              new TextRun({
                                                  text: this.getValue(data.revision, "plan_organico_recomendaciones"),
                                                  font: 'Calibri (Cuerpo)',
                                                  size: 22,
                                              })
                                          ],
                                          spacing: { before: 100, after: 200 }
                                      })],
                                  verticalAlign: VerticalAlign.CENTER,
                              }),
                          ],
                      }),
                      new TableRow({
                          children: [
                              new TableCell({
                                  children: [
                                      new Paragraph({
                                          alignment: AlignmentType.RIGHT,
                                          children: [
                                              new TextRun({
                                                  text: 'Comentarios generales: ',
                                                  font: 'Calibri (Cuerpo)',
                                                  size: 22,
                                              })
                                          ],
                                          spacing: { before: 100, after: 200 }
                                      })],
                                  verticalAlign: VerticalAlign.CENTER,
                              }),
                              new TableCell({
                                  children: [
                                      new Paragraph({
                                          alignment: AlignmentType.LEFT,
                                          children: [
                                              new TextRun({
                                                  text: this.getValue(data.revision, "plan_organico_comentarios"),
                                                  font: 'Calibri (Cuerpo)',
                                                  size: 22,
                                              })
                                          ],
                                          spacing: { before: 100, after: 200 }
                                      })],
                                  verticalAlign: VerticalAlign.CENTER,
                              }),
                          ],
                      }),
                      new TableRow({
                          children: [
                              new TableCell({
                                  children: [
                                      new Paragraph({
                                          alignment: AlignmentType.RIGHT,
                                          children: [
                                              new TextRun({
                                                  text: `Firma del Revisor:`,
                                                  font: 'Calibri (Cuerpo)',
                                                  size: 22,
                                              })
                                          ],
                                          spacing: { before: 100, after: 200 }
                                      })],
                                  verticalAlign: VerticalAlign.CENTER,
                              }),
                              new TableCell({
                                  children: [
                                      new Paragraph({
                                          alignment: AlignmentType.LEFT,
                                          children: [
                                            signatureImage2 ? Media.addImage(document, signatureImage2.toString(), 200, 100) : new TextRun({
                                              text: ''
                                            })
                                          ],
                                          spacing: { before: 100, after: 200 }
                                      })
                                  ],
                                  verticalAlign: VerticalAlign.CENTER,
                                  columnSpan: 1,
                              }),
                          ],
                      }),
                  ],
                  margins: {
                      left: 70,
                      right: 70
                  },
                  width: {
                      size: 100,
                      type: WidthType.PERCENTAGE,
                  },
                  columnWidths: [2410, 7227]
                })
            ],
            margins: {
                top: 350,
                bottom: 500,
                left: 1000,
                right: 1000
            }
        });

        return document;
    }

    public createFooter(): Table {
        const table = new Table({
            rows: [
                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: 'Elaboró:',
                                            font: 'Calibri (Cuerpo)',
                                            size: 22
                                        })
                                    ]
                                })
                            ],
                            width: {
                                size: 33,
                                type: WidthType.PERCENTAGE
                            }
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: 'Revisó:',
                                            font: 'Calibri (Cuerpo)',
                                            size: 22
                                        })
                                    ]
                                })
                            ],
                            width: {
                                size: 33,
                                type: WidthType.PERCENTAGE
                            }
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: 'Autorizó:',
                                            font: 'Calibri (Cuerpo)',
                                            size: 22
                                        })
                                    ]
                                }),
                            ],
                            width: {
                                size: 33,
                                type: WidthType.PERCENTAGE
                            }
                        })
                    ]
                }),
                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: 'Director de certificación',
                                            font: 'Calibri (Cuerpo)',
                                            size: 16,
                                            bold: true
                                        })
                                    ]
                                }),

                            ],
                            width: {
                                size: 33,
                                type: WidthType.PERCENTAGE
                            },
                            shading: {
                                fill: '#C0C0C0'
                            }
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: 'Responsable de calidad',
                                            font: 'Calibri (Cuerpo)',
                                            size: 16,
                                            bold: true
                                        })
                                    ]
                                }),

                            ],
                            width: {
                                size: 33,
                                type: WidthType.PERCENTAGE
                            },
                            shading: {
                                fill: '#C0C0C0'
                            }
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: 'Director general',
                                            font: 'Calibri (Cuerpo)',
                                            size: 16,
                                            bold: true
                                        })
                                    ]
                                }),
                            ],
                            width: {
                                size: 33,
                                type: WidthType.PERCENTAGE
                            },
                            shading: {
                                fill: '#C0C0C0'
                            }
                        })
                    ]
                })
            ],
            margins: {
                left: 70,
                right: 70
            },
            //9638
            columnWidths: [3213, 3213, 3212],
            alignment: AlignmentType.CENTER
        })
        return table
    }

    public createRegisters(submission): Table {
      const table = new Table({
        rows: [
          new TableRow({
            children: [
              new TableCell({
                children: [
                  new Paragraph({
                    alignment: AlignmentType.CENTER,
                    children: [
                      new TextRun({
                        text: 'REGISTROS',
                        font: 'Arial',
                        size: 20,
                        bold: true
                      })
                    ],
                    spacing: {
                      after: 75,
                      before: 75
                    }
                  }),
                ],
                verticalAlign: VerticalAlign.CENTER,
                columnSpan: 6,
                shading: {
                  fill: '#C0C0C0'
                }
              })
            ]
          }),
          new TableRow({
            children: [
              new TableCell({
                children: [
                  new Paragraph({
                    alignment: AlignmentType.CENTER,
                    children: [
                      new TextRun({
                        text: 'No.',
                        font: 'Arial',
                        size: 18,
                        bold: true
                      })
                    ]
                  })
                ],
                rowSpan: 2,
                verticalAlign: VerticalAlign.CENTER,
                width: {
                  size: 25,
                  type: WidthType.PERCENTAGE
                }
              }),
              new TableCell({
                children: [
                  new Paragraph({
                    alignment: AlignmentType.CENTER,
                    children: [
                      new TextRun({
                        text: 'Requisitos',
                        font: 'Arial',
                        size: 18,
                        bold: true
                      })
                    ]
                  })
                ],
                rowSpan: 2,
                columnSpan: 1,
                verticalAlign: VerticalAlign.CENTER,
                width: {
                  size: 15,
                  type: WidthType.PERCENTAGE
                }
              }),
              new TableCell({
                children: [
                  new Paragraph({
                    alignment: AlignmentType.CENTER,
                    children: [
                      new TextRun({
                        text: 'Incluyo información',
                        font: 'Arial',
                        size: 18,
                        bold: true
                      })
                    ]
                  })
                ],
                rowSpan: 2,
                verticalAlign: VerticalAlign.CENTER,
                width: {
                  size: 15,
                  type: WidthType.PERCENTAGE
                }
              }),
              new TableCell({
                children: [
                  new Paragraph({
                    alignment: AlignmentType.CENTER,
                    children: [
                      new TextRun({
                        text: 'Comentarios',
                        font: 'Arial',
                        size: 18,
                        bold: true
                      })
                    ]
                  })
                ],
                rowSpan: 2,
                verticalAlign: VerticalAlign.CENTER,
                width: {
                  size: 15,
                  type: WidthType.PERCENTAGE
                }
              }),
              new TableCell({
                children: [
                    new Paragraph({
                        alignment: AlignmentType.CENTER,
                        children: [
                            new TextRun({
                                text: 'Verificación en sitio (Evaluada por Inspector)',
                                font: 'Arial',
                                size: 18,
                                bold: true
                            })
                        ]
                    })
                ],
                columnSpan: 3,
                verticalAlign: VerticalAlign.CENTER,
                width: {
                    size: 15,
                    type: WidthType.PERCENTAGE
                }
            })
            ]
          }),
          new TableRow({
            children: [new TableCell({
                children: [
                    new Paragraph({
                        alignment: AlignmentType.CENTER,
                        children: [
                            new TextRun({
                                text: 'Implementación',
                                font: 'Arial',
                                size: 18,
                                bold: true
                            })
                        ]
                    })
                ],
                verticalAlign: VerticalAlign.CENTER
            }),
            new TableCell({
                children: [
                    new Paragraph({
                        alignment: AlignmentType.CENTER,
                        children: [
                            new TextRun({
                                text: 'Confirmación',
                                font: 'Arial',
                                size: 18,
                                bold: true
                            })
                        ]
                    })
                ],
                verticalAlign: VerticalAlign.CENTER,
                columnSpan: 2
            }),]
          }),
          new TableRow({
            children: [
              new TableCell({
                children: [
                  new Paragraph({
                    alignment: AlignmentType.CENTER,
                    children: [
                      new TextRun({
                        text: '1',
                        font: 'Arial',
                        size: 18
                      })
                    ]
                  })
                ],
                verticalAlign: VerticalAlign.TOP
              }),
              new TableCell({
                children: [
                  new Paragraph({
                    alignment: AlignmentType.LEFT,
                    children: [
                      new TextRun({
                        text: 'He incluido una lista completa de las áreas de recolección que estoy sometiendo a evaluación para la certificación orgánica. MTO-FSOL-02',
                        font: 'Arial',
                        size: 18
                      })
                    ]
                  })
                ],
                verticalAlign: VerticalAlign.TOP
              }),
              new TableCell({
                children: [
                  new Paragraph({
                    alignment: AlignmentType.CENTER,
                    children: [
                      new TextRun({
                        text: this.getValue(submission, 'plan_organico_recoleccion_registros_1_declaracion') === 'Si' ? '■ SÍ  ❏ NO' : '❏ SÍ  ■ NO',
                        font: 'Arial',
                        size: 18
                      })
                    ]
                  })
                ],
                verticalAlign: VerticalAlign.TOP
              }),
              new TableCell({
                children: [
                  new Paragraph({
                    alignment: AlignmentType.LEFT,
                    children: [
                      new TextRun({
                        text: this.getValue(submission, 'plan_organico_recoleccion_registros_1_comentarios'),
                        font: 'Arial',
                        size: 18
                      })
                    ]
                  })
                ],
                verticalAlign: VerticalAlign.TOP
              }),
              new TableCell({
                children: [
                  new Paragraph({
                    alignment: AlignmentType.LEFT,
                    children: [
                        new TextRun({
                            text: ` ${this.getValue(submission, 'plan_organico_recoleccion_registros_1_implementacion') === 'I' ? '■' : '❏'} I`,
                            font: 'Arial',
                            size: 18
                        })
                    ]
                  }),
                  new Paragraph({
                    alignment: AlignmentType.LEFT,
                    children: [
                        new TextRun({
                            text: ` ${this.getValue(submission, 'plan_organico_recoleccion_registros_1_implementacion') === 'NI' ? '■' : '❏'} NI`,
                            font: 'Arial',
                            size: 18
                        })
                    ]
                  })
                ],
                verticalAlign: VerticalAlign.TOP
            }),
            new TableCell({
                children: [
                  new Paragraph({
                    alignment: AlignmentType.LEFT,
                    children: [
                        new TextRun({
                            text: ` ${this.getValue(submission, 'plan_organico_recoleccion_registros_1_conformidad') === 'C' ? '■' : '❏'} C`,
                            font: 'Arial',
                            size: 18
                        })
                    ]
                  }),
                  new Paragraph({
                    alignment: AlignmentType.LEFT,
                    children: [
                        new TextRun({
                            text: ` ${this.getValue(submission, 'plan_organico_recoleccion_registros_1_conformidad') === 'NC' ? '■' : '❏'} NC`,
                            font: 'Arial',
                            size: 18
                        })
                    ]
                  }),
                  new Paragraph({
                    alignment: AlignmentType.LEFT,
                    children: [
                        new TextRun({
                            text: ` ${this.getValue(submission, 'plan_organico_recoleccion_registros_1_conformidad') === 'NA' ? '■' : '❏'} NA`,
                            font: 'Arial',
                            size: 18
                        })
                    ]
                  })
                ],
                verticalAlign: VerticalAlign.TOP
            }),
            new TableCell({
                children: [
                  new Paragraph({
                    alignment: AlignmentType.LEFT,
                    children: [
                        new TextRun({
                            text: this.getValue(submission, 'plan_organico_recoleccion_registros_1_comentarios_inspector'),
                            font: 'Arial',
                            size: 18
                        })
                    ]
                  })
                ],
                verticalAlign: VerticalAlign.TOP
            })
            ]
          }),
          new TableRow({
            children: [
              new TableCell({
                children: [
                  new Paragraph({
                    alignment: AlignmentType.CENTER,
                    children: [
                      new TextRun({
                        text: '2',
                        font: 'Arial',
                        size: 18
                      })
                    ]
                  })
                ],
                verticalAlign: VerticalAlign.TOP
              }),
              new TableCell({
                children: [
                  new Paragraph({
                    alignment: AlignmentType.LEFT,
                    children: [
                      new TextRun({
                        text: 'He incluido una copia del permiso de registro vigente expedido por la SEMARNAT.',
                        font: 'Arial',
                        size: 18
                      })
                    ]
                  })
                ],
                verticalAlign: VerticalAlign.TOP
              }),
              new TableCell({
                children: [
                  new Paragraph({
                    alignment: AlignmentType.CENTER,
                    children: [
                      new TextRun({
                        text: this.getValue(submission, 'plan_organico_recoleccion_registros_2_declaracion') === 'Si' ? '■ SÍ  ❏ NO' : '❏ SÍ  ■ NO',
                        font: 'Arial',
                        size: 18
                      })
                    ]
                  })
                ],
                verticalAlign: VerticalAlign.TOP
              }),
              new TableCell({
                children: [
                  new Paragraph({
                    alignment: AlignmentType.LEFT,
                    children: [
                      new TextRun({
                        text: this.getValue(submission, 'plan_organico_recoleccion_registros_2_comentarios'),
                        font: 'Arial',
                        size: 18
                      })
                    ]
                  })
                ],
                verticalAlign: VerticalAlign.TOP
              }),
              new TableCell({
                children: [
                  new Paragraph({
                    alignment: AlignmentType.LEFT,
                    children: [
                        new TextRun({
                            text: ` ${this.getValue(submission, 'plan_organico_recoleccion_registros_2_implementacion') === 'I' ? '■' : '❏'} I`,
                            font: 'Arial',
                            size: 18
                        })
                    ]
                  }),
                  new Paragraph({
                    alignment: AlignmentType.LEFT,
                    children: [
                        new TextRun({
                            text: ` ${this.getValue(submission, 'plan_organico_recoleccion_registros_2_implementacion') === 'NI' ? '■' : '❏'} NI`,
                            font: 'Arial',
                            size: 18
                        })
                    ]
                  })
                ],
                verticalAlign: VerticalAlign.TOP
            }),
            new TableCell({
                children: [
                  new Paragraph({
                    alignment: AlignmentType.LEFT,
                    children: [
                        new TextRun({
                            text: ` ${this.getValue(submission, 'plan_organico_recoleccion_registros_2_conformidad') === 'C' ? '■' : '❏'} C`,
                            font: 'Arial',
                            size: 18
                        })
                    ]
                  }),
                  new Paragraph({
                    alignment: AlignmentType.LEFT,
                    children: [
                        new TextRun({
                            text: ` ${this.getValue(submission, 'plan_organico_recoleccion_registros_2_conformidad') === 'NC' ? '■' : '❏'} NC`,
                            font: 'Arial',
                            size: 18
                        })
                    ]
                  }),
                  new Paragraph({
                    alignment: AlignmentType.LEFT,
                    children: [
                        new TextRun({
                            text: ` ${this.getValue(submission, 'plan_organico_recoleccion_registros_2_conformidad') === 'NA' ? '■' : '❏'} NA`,
                            font: 'Arial',
                            size: 18
                        })
                    ]
                  })
                ],
                verticalAlign: VerticalAlign.TOP
            }),
            new TableCell({
                children: [
                  new Paragraph({
                    alignment: AlignmentType.LEFT,
                    children: [
                        new TextRun({
                            text: this.getValue(submission, 'plan_organico_recoleccion_registros_2_comentarios_inspector'),
                            font: 'Arial',
                            size: 18
                        })
                    ]
                  })
                ],
                verticalAlign: VerticalAlign.TOP
            })
            ]
          }),
          new TableRow({
            children: [
              new TableCell({
                children: [
                  new Paragraph({
                    alignment: AlignmentType.CENTER,
                    children: [
                      new TextRun({
                        text: '3',
                        font: 'Arial',
                        size: 18
                      })
                    ]
                  })
                ],
                verticalAlign: VerticalAlign.TOP
              }),
              new TableCell({
                children: [
                  new Paragraph({
                    alignment: AlignmentType.LEFT,
                    children: [
                      new TextRun({
                        text: 'En caso de conocer que en el área se hayan aplicado previamente sustancias prohibidas, he incluido copia de análisis de suelo o planta para demostrar que están libres de dichas sustancias.',
                        font: 'Arial',
                        size: 18
                      })
                    ]
                  })
                ],
                verticalAlign: VerticalAlign.TOP
              }),
              new TableCell({
                children: [
                  new Paragraph({
                    alignment: AlignmentType.CENTER,
                    children: [
                      new TextRun({
                        text: this.getValue(submission, 'plan_organico_recoleccion_registros_3_declaracion') === 'Si' ? '■ SÍ  ❏ NO' : '❏ SÍ  ■ NO',
                        font: 'Arial',
                        size: 18
                      })
                    ]
                  })
                ],
                verticalAlign: VerticalAlign.TOP
              }),
              new TableCell({
                children: [
                  new Paragraph({
                    alignment: AlignmentType.LEFT,
                    children: [
                      new TextRun({
                        text: this.getValue(submission, 'plan_organico_recoleccion_registros_3_comentarios'),
                        font: 'Arial',
                        size: 18
                      })
                    ]
                  })
                ],
                verticalAlign: VerticalAlign.TOP
              }),
              new TableCell({
                children: [
                  new Paragraph({
                    alignment: AlignmentType.LEFT,
                    children: [
                        new TextRun({
                            text: ` ${this.getValue(submission, 'plan_organico_recoleccion_registros_3_implementacion') === 'I' ? '■' : '❏'} I`,
                            font: 'Arial',
                            size: 18
                        })
                    ]
                  }),
                  new Paragraph({
                    alignment: AlignmentType.LEFT,
                    children: [
                        new TextRun({
                            text: ` ${this.getValue(submission, 'plan_organico_recoleccion_registros_3_implementacion') === 'NI' ? '■' : '❏'} NI`,
                            font: 'Arial',
                            size: 18
                        })
                    ]
                  })
                ],
                verticalAlign: VerticalAlign.TOP
            }),
            new TableCell({
                children: [
                  new Paragraph({
                    alignment: AlignmentType.LEFT,
                    children: [
                        new TextRun({
                            text: ` ${this.getValue(submission, 'plan_organico_recoleccion_registros_3_conformidad') === 'C' ? '■' : '❏'} C`,
                            font: 'Arial',
                            size: 18
                        })
                    ]
                  }),
                  new Paragraph({
                    alignment: AlignmentType.LEFT,
                    children: [
                        new TextRun({
                            text: ` ${this.getValue(submission, 'plan_organico_recoleccion_registros_3_conformidad') === 'NC' ? '■' : '❏'} NC`,
                            font: 'Arial',
                            size: 18
                        })
                    ]
                  }),
                  new Paragraph({
                    alignment: AlignmentType.LEFT,
                    children: [
                        new TextRun({
                            text: ` ${this.getValue(submission, 'plan_organico_recoleccion_registros_3_conformidad') === 'NA' ? '■' : '❏'} NA`,
                            font: 'Arial',
                            size: 18
                        })
                    ]
                  })
                ],
                verticalAlign: VerticalAlign.TOP
            }),
            new TableCell({
                children: [
                  new Paragraph({
                    alignment: AlignmentType.LEFT,
                    children: [
                        new TextRun({
                            text: this.getValue(submission, 'plan_organico_recoleccion_registros_3_comentarios_inspector'),
                            font: 'Arial',
                            size: 18
                        })
                    ]
                  })
                ],
                verticalAlign: VerticalAlign.TOP
            })
            ]
          }),
          new TableRow({
            children: [
              new TableCell({
                children: [
                  new Paragraph({
                    alignment: AlignmentType.CENTER,
                    children: [
                      new TextRun({
                        text: '4',
                        font: 'Arial',
                        size: 18
                      })
                    ]
                  })
                ],
                verticalAlign: VerticalAlign.TOP
              }),
              new TableCell({
                children: [
                  new Paragraph({
                    alignment: AlignmentType.LEFT,
                    children: [
                      new TextRun({
                        text: 'He incluido croquis de cada una de las áreas de recolección. MTO-LPO-FSOL-05',
                        font: 'Arial',
                        size: 18
                      })
                    ]
                  })
                ],
                verticalAlign: VerticalAlign.TOP
              }),
              new TableCell({
                children: [
                  new Paragraph({
                    alignment: AlignmentType.CENTER,
                    children: [
                      new TextRun({
                        text: this.getValue(submission, 'plan_organico_recoleccion_registros_4_declaracion') === 'Si' ? '■ SÍ  ❏ NO' : '❏ SÍ  ■ NO',
                        font: 'Arial',
                        size: 18
                      })
                    ]
                  })
                ],
                verticalAlign: VerticalAlign.TOP
              }),
              new TableCell({
                children: [
                  new Paragraph({
                    alignment: AlignmentType.LEFT,
                    children: [
                      new TextRun({
                        text: this.getValue(submission, 'plan_organico_recoleccion_registros_4_comentarios'),
                        font: 'Arial',
                        size: 18
                      })
                    ]
                  })
                ],
                verticalAlign: VerticalAlign.TOP
              }),
              new TableCell({
                children: [
                  new Paragraph({
                    alignment: AlignmentType.LEFT,
                    children: [
                        new TextRun({
                            text: ` ${this.getValue(submission, 'plan_organico_recoleccion_registros_4_implementacion') === 'I' ? '■' : '❏'} I`,
                            font: 'Arial',
                            size: 18
                        })
                    ]
                  }),
                  new Paragraph({
                    alignment: AlignmentType.LEFT,
                    children: [
                        new TextRun({
                            text: ` ${this.getValue(submission, 'plan_organico_recoleccion_registros_4_implementacion') === 'NI' ? '■' : '❏'} NI`,
                            font: 'Arial',
                            size: 18
                        })
                    ]
                  })
                ],
                verticalAlign: VerticalAlign.TOP
            }),
            new TableCell({
                children: [
                  new Paragraph({
                    alignment: AlignmentType.LEFT,
                    children: [
                        new TextRun({
                            text: ` ${this.getValue(submission, 'plan_organico_recoleccion_registros_4_conformidad') === 'C' ? '■' : '❏'} C`,
                            font: 'Arial',
                            size: 18
                        })
                    ]
                  }),
                  new Paragraph({
                    alignment: AlignmentType.LEFT,
                    children: [
                        new TextRun({
                            text: ` ${this.getValue(submission, 'plan_organico_recoleccion_registros_4_conformidad') === 'NC' ? '■' : '❏'} NC`,
                            font: 'Arial',
                            size: 18
                        })
                    ]
                  }),
                  new Paragraph({
                    alignment: AlignmentType.LEFT,
                    children: [
                        new TextRun({
                            text: ` ${this.getValue(submission, 'plan_organico_recoleccion_registros_4_conformidad') === 'NA' ? '■' : '❏'} NA`,
                            font: 'Arial',
                            size: 18
                        })
                    ]
                  })
                ],
                verticalAlign: VerticalAlign.TOP
            }),
            new TableCell({
                children: [
                  new Paragraph({
                    alignment: AlignmentType.LEFT,
                    children: [
                        new TextRun({
                            text: this.getValue(submission, 'plan_organico_recoleccion_registros_4_comentarios_inspector'),
                            font: 'Arial',
                            size: 18
                        })
                    ]
                  })
                ],
                verticalAlign: VerticalAlign.TOP
            })
            ]
          }),
          new TableRow({
            children: [
              new TableCell({
                children: [
                  new Paragraph({
                    alignment: AlignmentType.CENTER,
                    children: [
                      new TextRun({
                        text: '5',
                        font: 'Arial',
                        size: 18
                      })
                    ]
                  })
                ],
                verticalAlign: VerticalAlign.TOP
              }),
              new TableCell({
                children: [
                  new Table({
                    rows: [
                      new TableRow({
                        children: [
                          new TableCell({
                            children: [
                              new Paragraph({
                                alignment: AlignmentType.LEFT,
                                children: [
                                  new TextRun({
                                    text: 'Cuento con las siguientes bitácoras:',
                                    font: 'Arial',
                                    size: 18
                                  })
                                ]
                              })
                            ],
                            borders: {
                              left: {
                                style: BorderStyle.NONE,
                                color: "white",
                                size: 0
                              },
                              top: {
                                style: BorderStyle.NONE,
                                color: "white",
                                size: 0
                              },
                              bottom: {
                                style: BorderStyle.NONE,
                                color: "white",
                                size: 0
                              }
                            }
                          }),
                          new TableCell({
                            children: [
                              new Paragraph({
                                alignment: AlignmentType.CENTER,
                                children: [
                                  new TextRun({
                                    text: '',
                                    font: 'Arial',
                                    size: 18
                                  })
                                ]
                              })
                            ],
                            borders: {
                              right: {
                                style: BorderStyle.NONE,
                                color: "white",
                                size: 0
                              },
                              top: {
                                style: BorderStyle.NONE,
                                color: "white",
                                size: 0
                              },
                              bottom: {
                                style: BorderStyle.NONE,
                                color: "white",
                                size: 0
                              }
                            }
                          })
                        ]
                      }),
                      new TableRow({
                        children: [
                          new TableCell({
                            children: [
                              new Paragraph({
                                alignment: AlignmentType.LEFT,
                                children: [
                                  new TextRun({
                                    text: `${this.getValue(submission, 'plan_organico_recoleccion_registros_5_cosecha_check') ? '■' : '❏'} Cosecha`,
                                    font: 'Arial',
                                    size: 18
                                  })
                                ]
                              })
                            ],
                            borders: {
                              left: {
                                style: BorderStyle.NONE,
                                color: "white",
                                size: 0
                              },
                              top: {
                                style: BorderStyle.NONE,
                                color: "white",
                                size: 0
                              },
                              bottom: {
                                style: BorderStyle.NONE,
                                color: "white",
                                size: 0
                              }
                            }
                          }),
                          new TableCell({
                            children: [
                              new Paragraph({
                                alignment: AlignmentType.CENTER,
                                children: [
                                  new TextRun({
                                    text: `${this.getValue(submission, 'plan_organico_recoleccion_registros_5_cosecha') === "Si" ? '■ Sí ❏ No' : this.getValue(submission, 'plan_organico_recoleccion_registros_5_cosecha') === "No" ? '❏ Sí ■ No' : '❏ Sí ❏ No'}`,
                                    font: 'Arial',
                                    size: 18
                                  })
                                ]
                              })
                            ],
                            borders: {
                              right: {
                                style: BorderStyle.NONE,
                                color: "white",
                                size: 0
                              },
                              top: {
                                style: BorderStyle.NONE,
                                color: "white",
                                size: 0
                              },
                              bottom: {
                                style: BorderStyle.NONE,
                                color: "white",
                                size: 0
                              }
                            }
                          })
                        ]
                      }),
                      new TableRow({
                        children: [
                          new TableCell({
                            children: [
                              new Paragraph({
                                alignment: AlignmentType.LEFT,
                                children: [
                                  new TextRun({
                                    text: `${this.getValue(submission, 'plan_organico_recoleccion_registros_5_ventas_check') ? '■' : '❏'} Ventas`,
                                    font: 'Arial',
                                    size: 18
                                  })
                                ]
                              })
                            ],
                            borders: {
                              left: {
                                style: BorderStyle.NONE,
                                color: "white",
                                size: 0
                              },
                              top: {
                                style: BorderStyle.NONE,
                                color: "white",
                                size: 0
                              },
                              bottom: {
                                style: BorderStyle.NONE,
                                color: "white",
                                size: 0
                              }
                            }
                          }),
                          new TableCell({
                            children: [
                              new Paragraph({
                                alignment: AlignmentType.CENTER,
                                children: [
                                  new TextRun({
                                    text: `${this.getValue(submission, 'plan_organico_recoleccion_registros_5_ventas') === "Si" ? '■ Sí ❏ No' : this.getValue(submission, 'plan_organico_recoleccion_registros_5_ventas') === "No" ? '❏ Sí ■ No' : '❏ Sí ❏ No'}`,
                                    font: 'Arial',
                                    size: 18
                                  })
                                ]
                              })
                            ],
                            borders: {
                              right: {
                                style: BorderStyle.NONE,
                                color: "white",
                                size: 0
                              },
                              top: {
                                style: BorderStyle.NONE,
                                color: "white",
                                size: 0
                              },
                              bottom: {
                                style: BorderStyle.NONE,
                                color: "white",
                                size: 0
                              }
                            }
                          })
                        ]
                      }),
                      new TableRow({
                        children: [
                          new TableCell({
                            children: [
                              new Paragraph({
                                alignment: AlignmentType.LEFT,
                                children: [
                                  new TextRun({
                                    text: `${this.getValue(submission, 'plan_organico_recoleccion_registros_5_otro_check') ? '■' : '❏'} Otra: ${this.getValue(submission, 'plan_organico_recoleccion_registros_5_otro')}`,
                                    font: 'Arial',
                                    size: 18
                                  })
                                ]
                              })
                            ],
                            borders: {
                              left: {
                                style: BorderStyle.NONE,
                                color: "white",
                                size: 0
                              },
                              top: {
                                style: BorderStyle.NONE,
                                color: "white",
                                size: 0
                              },
                              bottom: {
                                style: BorderStyle.NONE,
                                color: "white",
                                size: 0
                              }
                            }
                          }),
                          new TableCell({
                            children: [
                              new Paragraph({
                                alignment: AlignmentType.CENTER,
                                children: [
                                  new TextRun({
                                    text: `${this.getValue(submission, 'plan_organico_recoleccion_registros_5_otro_declaracion') === "Si" ? '■ Sí ❏ No' : this.getValue(submission, 'plan_organico_recoleccion_registros_5_otro_declaracion') === "No" ? '❏ Sí ■ No' : '❏ Sí ❏ No'}`,
                                    font: 'Arial',
                                    size: 18
                                  })
                                ]
                              })
                            ],
                            borders: {
                              right: {
                                style: BorderStyle.NONE,
                                color: "white",
                                size: 0
                              },
                              top: {
                                style: BorderStyle.NONE,
                                color: "white",
                                size: 0
                              },
                              bottom: {
                                style: BorderStyle.NONE,
                                color: "white",
                                size: 0
                              }
                            }
                          })
                        ]
                      })
                    ],
                    //4395
                    columnWidths: [3075, 1320]
                  })
                ],
                columnSpan: 2
              }),
              new TableCell({
                children: [
                  new Paragraph({
                    alignment: AlignmentType.LEFT,
                    children: [
                      new TextRun({
                        text: this.getValue(submission, 'plan_organico_recoleccion_registros_5_comentarios'),
                        font: 'Arial',
                        size: 18
                      })
                    ]
                  })
                ],
                verticalAlign: VerticalAlign.TOP
              }),
              new TableCell({
                children: [
                  new Paragraph({
                    alignment: AlignmentType.LEFT,
                    children: [
                        new TextRun({
                            text: ` ${this.getValue(submission, 'plan_organico_recoleccion_registros_5_implementacion') === 'I' ? '■' : '❏'} I`,
                            font: 'Arial',
                            size: 18
                        })
                    ]
                  }),
                  new Paragraph({
                    alignment: AlignmentType.LEFT,
                    children: [
                        new TextRun({
                            text: ` ${this.getValue(submission, 'plan_organico_recoleccion_registros_5_implementacion') === 'NI' ? '■' : '❏'} NI`,
                            font: 'Arial',
                            size: 18
                        })
                    ]
                  })
                ],
                verticalAlign: VerticalAlign.TOP
            }),
            new TableCell({
                children: [
                  new Paragraph({
                    alignment: AlignmentType.LEFT,
                    children: [
                        new TextRun({
                            text: ` ${this.getValue(submission, 'plan_organico_recoleccion_registros_5_conformidad') === 'C' ? '■' : '❏'} C`,
                            font: 'Arial',
                            size: 18
                        })
                    ]
                  }),
                  new Paragraph({
                    alignment: AlignmentType.LEFT,
                    children: [
                        new TextRun({
                            text: ` ${this.getValue(submission, 'plan_organico_recoleccion_registros_5_conformidad') === 'NC' ? '■' : '❏'} NC`,
                            font: 'Arial',
                            size: 18
                        })
                    ]
                  }),
                  new Paragraph({
                    alignment: AlignmentType.LEFT,
                    children: [
                        new TextRun({
                            text: ` ${this.getValue(submission, 'plan_organico_recoleccion_registros_5_conformidad') === 'NA' ? '■' : '❏'} NA`,
                            font: 'Arial',
                            size: 18
                        })
                    ]
                  })
                ],
                verticalAlign: VerticalAlign.TOP
            }),
            new TableCell({
                children: [
                  new Paragraph({
                    alignment: AlignmentType.LEFT,
                    children: [
                        new TextRun({
                            text: this.getValue(submission, 'plan_organico_recoleccion_registros_5_comentarios_inspector'),
                            font: 'Arial',
                            size: 18
                        })
                    ]
                  })
                ],
                verticalAlign: VerticalAlign.TOP
            })
            ]
          }),
          new TableRow({
            children: [
              new TableCell({
                children: [
                  new Paragraph({
                    alignment: AlignmentType.CENTER,
                    children: [
                      new TextRun({
                        text: '6',
                        font: 'Arial',
                        size: 18
                      })
                    ]
                  })
                ],
                verticalAlign: VerticalAlign.TOP
              }),
              new TableCell({
                children: [
                  new Paragraph({
                    alignment: AlignmentType.LEFT,
                    children: [
                      new TextRun({
                        text: 'He incluido una lista completa de riesgos para la integridad orgánica de los productos en el formato MTO-FSOL-04',
                        font: 'Arial',
                        size: 18
                      })
                    ]
                  })
                ],
                verticalAlign: VerticalAlign.TOP
              }),
              new TableCell({
                children: [
                  new Paragraph({
                    alignment: AlignmentType.CENTER,
                    children: [
                      new TextRun({
                        text: this.getValue(submission, 'plan_organico_recoleccion_registros_6_declaracion') === 'Si' ? '■ SÍ  ❏ NO' : '❏ SÍ  ■ NO',
                        font: 'Arial',
                        size: 18
                      })
                    ]
                  })
                ],
                verticalAlign: VerticalAlign.TOP
              }),
              new TableCell({
                children: [
                  new Paragraph({
                    alignment: AlignmentType.LEFT,
                    children: [
                      new TextRun({
                        text: this.getValue(submission, 'plan_organico_recoleccion_registros_6_comentarios'),
                        font: 'Arial',
                        size: 18
                      })
                    ]
                  })
                ],
                verticalAlign: VerticalAlign.TOP
              }),
              new TableCell({
                children: [
                  new Paragraph({
                    alignment: AlignmentType.LEFT,
                    children: [
                        new TextRun({
                            text: ` ${this.getValue(submission, 'plan_organico_recoleccion_registros_6_implementacion') === 'I' ? '■' : '❏'} I`,
                            font: 'Arial',
                            size: 18
                        })
                    ]
                  }),
                  new Paragraph({
                    alignment: AlignmentType.LEFT,
                    children: [
                        new TextRun({
                            text: ` ${this.getValue(submission, 'plan_organico_recoleccion_registros_6_implementacion') === 'NI' ? '■' : '❏'} NI`,
                            font: 'Arial',
                            size: 18
                        })
                    ]
                  })
                ],
                verticalAlign: VerticalAlign.TOP
            }),
            new TableCell({
                children: [
                  new Paragraph({
                    alignment: AlignmentType.LEFT,
                    children: [
                        new TextRun({
                            text: ` ${this.getValue(submission, 'plan_organico_recoleccion_registros_6_conformidad') === 'C' ? '■' : '❏'} C`,
                            font: 'Arial',
                            size: 18
                        })
                    ]
                  }),
                  new Paragraph({
                    alignment: AlignmentType.LEFT,
                    children: [
                        new TextRun({
                            text: ` ${this.getValue(submission, 'plan_organico_recoleccion_registros_6_conformidad') === 'NC' ? '■' : '❏'} NC`,
                            font: 'Arial',
                            size: 18
                        })
                    ]
                  }),
                  new Paragraph({
                    alignment: AlignmentType.LEFT,
                    children: [
                        new TextRun({
                            text: ` ${this.getValue(submission, 'plan_organico_recoleccion_registros_6_conformidad') === 'NA' ? '■' : '❏'} NA`,
                            font: 'Arial',
                            size: 18
                        })
                    ]
                  })
                ],
                verticalAlign: VerticalAlign.TOP
            }),
            new TableCell({
                children: [
                  new Paragraph({
                    alignment: AlignmentType.LEFT,
                    children: [
                        new TextRun({
                            text: this.getValue(submission, 'plan_organico_recoleccion_registros_6_comentarios_inspector'),
                            font: 'Arial',
                            size: 18
                        })
                    ]
                  })
                ],
                verticalAlign: VerticalAlign.TOP
            })
            ]
          }),
          new TableRow({
            children: [
              new TableCell({
                children: [
                  new Paragraph({
                    alignment: AlignmentType.CENTER,
                    children: [
                      new TextRun({
                        text: '7',
                        font: 'Arial',
                        size: 18
                      })
                    ]
                  })
                ],
                verticalAlign: VerticalAlign.TOP
              }),
              new TableCell({
                children: [
                  new Paragraph({
                    alignment: AlignmentType.LEFT,
                    children: [
                      new TextRun({
                        text: 'He incluido un análisis completo de riesgos de contaminación por colindantes en el formato MTO-FSOL-06',
                        font: 'Arial',
                        size: 18
                      })
                    ]
                  })
                ],
                verticalAlign: VerticalAlign.TOP
              }),
              new TableCell({
                children: [
                  new Paragraph({
                    alignment: AlignmentType.CENTER,
                    children: [
                      new TextRun({
                        text: this.getValue(submission, 'plan_organico_recoleccion_registros_7_declaracion') === 'Si' ? '■ SÍ  ❏ NO' : '❏ SÍ  ■ NO',
                        font: 'Arial',
                        size: 18
                      })
                    ]
                  })
                ],
                verticalAlign: VerticalAlign.TOP
              }),
              new TableCell({
                children: [
                  new Paragraph({
                    alignment: AlignmentType.LEFT,
                    children: [
                      new TextRun({
                        text: this.getValue(submission, 'plan_organico_recoleccion_registros_7_comentarios'),
                        font: 'Arial',
                        size: 18
                      })
                    ]
                  })
                ],
                verticalAlign: VerticalAlign.TOP
              }),
              new TableCell({
                children: [
                  new Paragraph({
                    alignment: AlignmentType.LEFT,
                    children: [
                        new TextRun({
                            text: ` ${this.getValue(submission, 'plan_organico_recoleccion_registros_7_implementacion') === 'I' ? '■' : '❏'} I`,
                            font: 'Arial',
                            size: 18
                        })
                    ]
                  }),
                  new Paragraph({
                    alignment: AlignmentType.LEFT,
                    children: [
                        new TextRun({
                            text: ` ${this.getValue(submission, 'plan_organico_recoleccion_registros_7_implementacion') === 'NI' ? '■' : '❏'} NI`,
                            font: 'Arial',
                            size: 18
                        })
                    ]
                  })
                ],
                verticalAlign: VerticalAlign.TOP
            }),
            new TableCell({
                children: [
                  new Paragraph({
                    alignment: AlignmentType.LEFT,
                    children: [
                        new TextRun({
                            text: ` ${this.getValue(submission, 'plan_organico_recoleccion_registros_7_conformidad') === 'C' ? '■' : '❏'} C`,
                            font: 'Arial',
                            size: 18
                        })
                    ]
                  }),
                  new Paragraph({
                    alignment: AlignmentType.LEFT,
                    children: [
                        new TextRun({
                            text: ` ${this.getValue(submission, 'plan_organico_recoleccion_registros_7_conformidad') === 'NC' ? '■' : '❏'} NC`,
                            font: 'Arial',
                            size: 18
                        })
                    ]
                  }),
                  new Paragraph({
                    alignment: AlignmentType.LEFT,
                    children: [
                        new TextRun({
                            text: ` ${this.getValue(submission, 'plan_organico_recoleccion_registros_7_conformidad') === 'NA' ? '■' : '❏'} NA`,
                            font: 'Arial',
                            size: 18
                        })
                    ]
                  })
                ],
                verticalAlign: VerticalAlign.TOP
            }),
            new TableCell({
                children: [
                  new Paragraph({
                    alignment: AlignmentType.LEFT,
                    children: [
                        new TextRun({
                            text: this.getValue(submission, 'plan_organico_recoleccion_registros_7_comentarios_inspector'),
                            font: 'Arial',
                            size: 18
                        })
                    ]
                  })
                ],
                verticalAlign: VerticalAlign.TOP
            })
            ]
          }),
          new TableRow({
            children: [
              new TableCell({
                children: [
                  new Paragraph({
                    alignment: AlignmentType.CENTER,
                    children: [
                      new TextRun({
                        text: '8',
                        font: 'Arial',
                        size: 18
                      })
                    ]
                  })
                ],
                verticalAlign: VerticalAlign.TOP
              }),
              new TableCell({
                children: [
                  new Paragraph({
                    alignment: AlignmentType.LEFT,
                    children: [
                      new TextRun({
                        text: 'He incluido una copia del permiso de aprovechamiento de la especie a recolectar',
                        font: 'Arial',
                        size: 18
                      })
                    ]
                  })
                ],
                verticalAlign: VerticalAlign.TOP
              }),
              new TableCell({
                children: [
                  new Paragraph({
                    alignment: AlignmentType.CENTER,
                    children: [
                      new TextRun({
                        text: this.getValue(submission, 'plan_organico_recoleccion_registros_8_declaracion') === 'Si' ? '■ SÍ  ❏ NO' : '❏ SÍ  ■ NO',
                        font: 'Arial',
                        size: 18
                      })
                    ]
                  })
                ],
                verticalAlign: VerticalAlign.TOP
              }),
              new TableCell({
                children: [
                  new Paragraph({
                    alignment: AlignmentType.LEFT,
                    children: [
                      new TextRun({
                        text: this.getValue(submission, 'plan_organico_recoleccion_registros_8_comentarios'),
                        font: 'Arial',
                        size: 18
                      })
                    ]
                  })
                ],
                verticalAlign: VerticalAlign.TOP
              }),
              new TableCell({
                children: [
                  new Paragraph({
                    alignment: AlignmentType.LEFT,
                    children: [
                        new TextRun({
                            text: ` ${this.getValue(submission, 'plan_organico_recoleccion_registros_8_implementacion') === 'I' ? '■' : '❏'} I`,
                            font: 'Arial',
                            size: 18
                        })
                    ]
                  }),
                  new Paragraph({
                    alignment: AlignmentType.LEFT,
                    children: [
                        new TextRun({
                            text: ` ${this.getValue(submission, 'plan_organico_recoleccion_registros_8_implementacion') === 'NI' ? '■' : '❏'} NI`,
                            font: 'Arial',
                            size: 18
                        })
                    ]
                  })
                ],
                verticalAlign: VerticalAlign.TOP
            }),
            new TableCell({
                children: [
                  new Paragraph({
                    alignment: AlignmentType.LEFT,
                    children: [
                        new TextRun({
                            text: ` ${this.getValue(submission, 'plan_organico_recoleccion_registros_8_conformidad') === 'C' ? '■' : '❏'} C`,
                            font: 'Arial',
                            size: 18
                        })
                    ]
                  }),
                  new Paragraph({
                    alignment: AlignmentType.LEFT,
                    children: [
                        new TextRun({
                            text: ` ${this.getValue(submission, 'plan_organico_recoleccion_registros_8_conformidad') === 'NC' ? '■' : '❏'} NC`,
                            font: 'Arial',
                            size: 18
                        })
                    ]
                  }),
                  new Paragraph({
                    alignment: AlignmentType.LEFT,
                    children: [
                        new TextRun({
                            text: ` ${this.getValue(submission, 'plan_organico_recoleccion_registros_8_conformidad') === 'NA' ? '■' : '❏'} NA`,
                            font: 'Arial',
                            size: 18
                        })
                    ]
                  })
                ],
                verticalAlign: VerticalAlign.TOP
            }),
            new TableCell({
                children: [
                  new Paragraph({
                    alignment: AlignmentType.LEFT,
                    children: [
                        new TextRun({
                            text: this.getValue(submission, 'plan_organico_recoleccion_registros_8_comentarios_inspector'),
                            font: 'Arial',
                            size: 18
                        })
                    ]
                  })
                ],
                verticalAlign: VerticalAlign.TOP
            })
            ]
          })
        ],
        margins: {
          left: 70,
          right: 70
        },
        //9885
        columnWidths: [450, 3050, 1345, 1545, 1150, 800, 1545]
      })
        return table
    }

    public createHandling(submission): Table {
      const table = new Table({
        rows: [
          new TableRow({
            children: [
              new TableCell({
                children: [
                  new Paragraph({
                    alignment: AlignmentType.CENTER,
                    children: [
                      new TextRun({
                        text: 'MANEJO',
                        font: 'Arial',
                        size: 20,
                        bold: true
                      })
                    ],
                    spacing: {
                      after: 75,
                      before: 75
                    }
                  }),
                ],
                verticalAlign: VerticalAlign.CENTER,
                columnSpan: 6,
                shading: {
                  fill: '#C0C0C0'
                }
              })
            ]
          }),
          new TableRow({
            children: [
              new TableCell({
                children: [
                  new Paragraph({
                    alignment: AlignmentType.CENTER,
                    children: [
                      new TextRun({
                        text: 'No.',
                        font: 'Arial',
                        size: 18,
                        bold: true
                      })
                    ]
                  })
                ],
                rowSpan: 2,
                columnSpan: 1,
                verticalAlign: VerticalAlign.CENTER,
                width: {
                  size: 25,
                  type: WidthType.PERCENTAGE
                }
              }),
              new TableCell({
                children: [
                  new Paragraph({
                    alignment: AlignmentType.CENTER,
                    children: [
                      new TextRun({
                        text: 'Requisitos',
                        font: 'Arial',
                        size: 18,
                        bold: true
                      })
                    ]
                  })
                ],
                rowSpan: 2,
                columnSpan: 1,
                verticalAlign: VerticalAlign.CENTER,
                width: {
                  size: 15,
                  type: WidthType.PERCENTAGE
                }
              }),
              new TableCell({
                children: [
                  new Paragraph({
                    alignment: AlignmentType.CENTER,
                    children: [
                      new TextRun({
                        text: 'Comentarios',
                        font: 'Arial',
                        size: 18,
                        bold: true
                      })
                    ]
                  })
                ],
                rowSpan: 2,
                columnSpan: 1,
                verticalAlign: VerticalAlign.CENTER,
                width: {
                  size: 15,
                  type: WidthType.PERCENTAGE
                }
              }),
              new TableCell({
                children: [
                    new Paragraph({
                        alignment: AlignmentType.CENTER,
                        children: [
                            new TextRun({
                                text: 'Verificación en sitio (Evaluada por Inspector)',
                                font: 'Arial',
                                size: 18,
                                bold: true
                            })
                        ]
                    })
                ],
                columnSpan: 3,
                verticalAlign: VerticalAlign.CENTER,
                width: {
                    size: 15,
                    type: WidthType.PERCENTAGE
                }
            })
            ]
          }),
          new TableRow({
            children: [new TableCell({
                children: [
                    new Paragraph({
                        alignment: AlignmentType.CENTER,
                        children: [
                            new TextRun({
                                text: 'Implementación',
                                font: 'Arial',
                                size: 18,
                                bold: true
                            })
                        ]
                    })
                ],
                verticalAlign: VerticalAlign.CENTER
            }),
            new TableCell({
                children: [
                    new Paragraph({
                        alignment: AlignmentType.CENTER,
                        children: [
                            new TextRun({
                                text: 'Confirmación',
                                font: 'Arial',
                                size: 18,
                                bold: true
                            })
                        ]
                    })
                ],
                verticalAlign: VerticalAlign.CENTER,
                columnSpan: 2
            }),]
          }),
          new TableRow({
            children: [
              new TableCell({
                children: [
                  new Paragraph({
                    alignment: AlignmentType.CENTER,
                    children: [
                      new TextRun({
                        text: '1',
                        font: 'Arial',
                        size: 18
                      })
                    ]
                  })
                ],
                verticalAlign: VerticalAlign.TOP
              }),
              new TableCell({
                children: [
                  new Paragraph({
                    alignment: AlignmentType.LEFT,
                    children: [
                      new TextRun({
                        text: 'Realizo las siguientes actividades de acuerdo con el plan de manejo aprobado por SEMARNAT:',
                        font: 'Arial',
                        size: 18
                      })
                    ]
                  }),
                  new Paragraph({
                    alignment: AlignmentType.LEFT,
                    children: [
                      new TextRun({
                        text: (this.getValue(submission, "plan_organico_recoleccion_manejo_1_seleccion_plantas_madre") ? '■' : '❏') + '  Selección de plantas madre',
                        font: 'Arial',
                        size: 18
                      })
                    ]
                  }),
                  new Paragraph({
                    alignment: AlignmentType.LEFT,
                    children: [
                      new TextRun({
                        text: (this.getValue(submission, "plan_organico_recoleccion_manejo_1_seleccion_plantas_aprovechamiento") ? '■' : '❏') + '  Selección de plantas para aprovechamiento',
                        font: 'Arial',
                        size: 18
                      })
                    ]
                  }),
                  new Paragraph({
                    alignment: AlignmentType.LEFT,
                    children: [
                      new TextRun({
                        text: (this.getValue(submission, "plan_organico_recoleccion_manejo_1_esparcimiento") ? '■' : '❏') + '  Esparcimiento de semillas',
                        font: 'Arial',
                        size: 18
                      })
                    ]
                  }),
                  new Paragraph({
                    alignment: AlignmentType.LEFT,
                    children: [
                      new TextRun({
                        text: (this.getValue(submission, "plan_organico_recoleccion_manejo_1_monitoreo") ? '■' : '❏') + '  Monitoreo de poblaciones',
                        font: 'Arial',
                        size: 18
                      })
                    ]
                  }),
                  new Paragraph({
                    alignment: AlignmentType.LEFT,
                    children: [
                      new TextRun({
                        text: (this.getValue(submission, "plan_organico_recoleccion_manejo_1_cosecha_no_cercana_quimicos") ? '■' : '❏') + '  No cosecha en una zona de 25 m cercana a vecinos que usan químicos',
                        font: 'Arial',
                        size: 18
                      })
                    ]
                  }),
                  new Paragraph({
                    alignment: AlignmentType.LEFT,
                    children: [
                      new TextRun({
                        text: (this.getValue(submission, "plan_organico_recoleccion_manejo_1_otro") ? '■' : '❏') + ' Otro (¿Cuál?): ' + this.getValue(submission, "plan_organico_recoleccion_manejo_1_otro_text"),
                        font: 'Arial',
                        size: 18
                      })
                    ]
                  }),
                ],
                verticalAlign: VerticalAlign.TOP
              }),
              new TableCell({
                children: [
                  new Paragraph({
                    alignment: AlignmentType.LEFT,
                    children: [
                      new TextRun({
                        text: this.getValue(submission, "plan_organico_recoleccion_manejo_1_comentarios"),
                        font: 'Arial',
                        size: 18
                      })
                    ]
                  })
                ],
                verticalAlign: VerticalAlign.TOP
              }),
              new TableCell({
                children: [
                  new Paragraph({
                    alignment: AlignmentType.LEFT,
                    children: [
                        new TextRun({
                            text: ` ${this.getValue(submission, 'plan_organico_recoleccion_manejo_1_implementacion') === 'I' ? '■' : '❏'} I`,
                            font: 'Arial',
                            size: 18
                        })
                    ]
                  }),
                  new Paragraph({
                    alignment: AlignmentType.LEFT,
                    children: [
                        new TextRun({
                            text: ` ${this.getValue(submission, 'plan_organico_recoleccion_manejo_1_implementacion') === 'NI' ? '■' : '❏'} NI`,
                            font: 'Arial',
                            size: 18
                        })
                    ]
                  })
                ],
                verticalAlign: VerticalAlign.TOP
            }),
            new TableCell({
                children: [
                  new Paragraph({
                    alignment: AlignmentType.LEFT,
                    children: [
                        new TextRun({
                            text: ` ${this.getValue(submission, 'plan_organico_recoleccion_manejo_1_conformidad') === 'C' ? '■' : '❏'} C`,
                            font: 'Arial',
                            size: 18
                        })
                    ]
                  }),
                  new Paragraph({
                    alignment: AlignmentType.LEFT,
                    children: [
                        new TextRun({
                            text: ` ${this.getValue(submission, 'plan_organico_recoleccion_manejo_1_conformidad') === 'NC' ? '■' : '❏'} NC`,
                            font: 'Arial',
                            size: 18
                        })
                    ]
                  }),
                  new Paragraph({
                    alignment: AlignmentType.LEFT,
                    children: [
                        new TextRun({
                            text: ` ${this.getValue(submission, 'plan_organico_recoleccion_manejo_1_conformidad') === 'NA' ? '■' : '❏'} NA`,
                            font: 'Arial',
                            size: 18
                        })
                    ]
                  })
                ],
                verticalAlign: VerticalAlign.TOP
            }),
            new TableCell({
                children: [
                  new Paragraph({
                    alignment: AlignmentType.LEFT,
                    children: [
                        new TextRun({
                            text: this.getValue(submission, 'plan_organico_recoleccion_manejo_1_comentarios_inspector'),
                            font: 'Arial',
                            size: 18
                        })
                    ]
                  })
                ],
                verticalAlign: VerticalAlign.TOP
            })
            ]
          }),
          new TableRow({
            children: [
              new TableCell({
                children: [
                  new Paragraph({
                    alignment: AlignmentType.CENTER,
                    children: [
                      new TextRun({
                        text: '2',
                        font: 'Arial',
                        size: 18
                      })
                    ]
                  })
                ],
                verticalAlign: VerticalAlign.TOP
              }),
              new TableCell({
                children: [
                  new Paragraph({
                    alignment: AlignmentType.LEFT,
                    children: [
                      new TextRun({
                        text: 'Realizo las siguientes prácticas para favorecer el ecosistema bajo aprovechamiento:',
                        font: 'Arial',
                        size: 18
                      })
                    ]
                  }),
                  new Paragraph({
                    alignment: AlignmentType.LEFT,
                    children: [
                      new TextRun({
                        text: (this.getValue(submission, "plan_organico_recoleccion_manejo_2_obras_conservacion_suelos") ? '■' : '❏') + ' Obras de conservación de suelos',
                        font: 'Arial',
                        size: 18
                      })
                    ]
                  }),
                  new Paragraph({
                    alignment: AlignmentType.LEFT,
                    children: [
                      new TextRun({
                        text: (this.getValue(submission, "plan_organico_recoleccion_manejo_2_obras_conservacion_agua") ? '■' : '❏') + ' Obras de conservación de agua',
                        font: 'Arial',
                        size: 18
                      })
                    ]
                  }),
                  new Paragraph({
                    alignment: AlignmentType.LEFT,
                    children: [
                      new TextRun({
                        text: (this.getValue(submission, "plan_organico_recoleccion_manejo_2_campanas_consientizacion") ? '■' : '❏') + ' Campañas de concientización',
                        font: 'Arial',
                        size: 18
                      })
                    ]
                  }),
                  new Paragraph({
                    alignment: AlignmentType.LEFT,
                    children: [
                      new TextRun({
                        text: (this.getValue(submission, "plan_organico_recoleccion_manejo_2_otro") ? '■' : '❏') + ' Otro (¿Cuál?): ' + this.getValue(submission, "plan_organico_recoleccion_manejo_2_otro_text"),
                        font: 'Arial',
                        size: 18
                      })
                    ]
                  }),
                ],
                verticalAlign: VerticalAlign.TOP
              }),
              new TableCell({
                children: [
                  new Paragraph({
                    alignment: AlignmentType.LEFT,
                    children: [
                      new TextRun({
                        text: this.getValue(submission, "plan_organico_recoleccion_manejo_2_comentarios"),
                        font: 'Arial',
                        size: 18
                      })
                    ]
                  })
                ],
                verticalAlign: VerticalAlign.TOP
              }),
              new TableCell({
                children: [
                  new Paragraph({
                    alignment: AlignmentType.LEFT,
                    children: [
                        new TextRun({
                            text: ` ${this.getValue(submission, 'plan_organico_recoleccion_manejo_2_implementacion') === 'I' ? '■' : '❏'} I`,
                            font: 'Arial',
                            size: 18
                        })
                    ]
                  }),
                  new Paragraph({
                    alignment: AlignmentType.LEFT,
                    children: [
                        new TextRun({
                            text: ` ${this.getValue(submission, 'plan_organico_recoleccion_manejo_2_implementacion') === 'NI' ? '■' : '❏'} NI`,
                            font: 'Arial',
                            size: 18
                        })
                    ]
                  })
                ],
                verticalAlign: VerticalAlign.TOP
            }),
            new TableCell({
                children: [
                  new Paragraph({
                    alignment: AlignmentType.LEFT,
                    children: [
                        new TextRun({
                            text: ` ${this.getValue(submission, 'plan_organico_recoleccion_manejo_2_conformidad') === 'C' ? '■' : '❏'} C`,
                            font: 'Arial',
                            size: 18
                        })
                    ]
                  }),
                  new Paragraph({
                    alignment: AlignmentType.LEFT,
                    children: [
                        new TextRun({
                            text: ` ${this.getValue(submission, 'plan_organico_recoleccion_manejo_2_conformidad') === 'NC' ? '■' : '❏'} NC`,
                            font: 'Arial',
                            size: 18
                        })
                    ]
                  }),
                  new Paragraph({
                    alignment: AlignmentType.LEFT,
                    children: [
                        new TextRun({
                            text: ` ${this.getValue(submission, 'plan_organico_recoleccion_manejo_2_conformidad') === 'NA' ? '■' : '❏'} NA`,
                            font: 'Arial',
                            size: 18
                        })
                    ]
                  })
                ],
                verticalAlign: VerticalAlign.TOP
            }),
            new TableCell({
                children: [
                  new Paragraph({
                    alignment: AlignmentType.LEFT,
                    children: [
                        new TextRun({
                            text: this.getValue(submission, 'plan_organico_recoleccion_manejo_2_comentarios_inspector'),
                            font: 'Arial',
                            size: 18
                        })
                    ]
                  })
                ],
                verticalAlign: VerticalAlign.TOP
            })
            ]
          }),
          new TableRow({
            children: [
              new TableCell({
                children: [
                  new Paragraph({
                    alignment: AlignmentType.CENTER,
                    children: [
                      new TextRun({
                        text: '3',
                        font: 'Arial',
                        size: 18
                      })
                    ]
                  })
                ],
                verticalAlign: VerticalAlign.TOP
              }),
              new TableCell({
                children: [
                  new Paragraph({
                    alignment: AlignmentType.LEFT,
                    children: [
                      new TextRun({
                        text: 'Para la cosecha y post-cosecha las siguientes prácticas están bajo mi control y mantienen la calidad orgánica del producto:',
                        font: 'Arial',
                        size: 18
                      })
                    ]
                  }),
                  new Paragraph({
                    alignment: AlignmentType.LEFT,
                    children: [
                      new TextRun({
                        text: (this.getValue(submission, "plan_organico_recoleccion_manejo_3_corte") ? '■' : '❏') + '  Corte',
                        font: 'Arial',
                        size: 18
                      })
                    ]
                  }),
                  new Paragraph({
                    alignment: AlignmentType.LEFT,
                    children: [
                      new TextRun({
                        text: (this.getValue(submission, "plan_organico_recoleccion_manejo_3_seleccion") ? '■' : '❏') + '  Selección',
                        font: 'Arial',
                        size: 18
                      })
                    ]
                  }),
                  new Paragraph({
                    alignment: AlignmentType.LEFT,
                    children: [
                      new TextRun({
                        text: (this.getValue(submission, "plan_organico_recoleccion_manejo_3_lavado") ? '■' : '❏') + '  Lavado',
                        font: 'Arial',
                        size: 18
                      })
                    ]
                  }),
                  new Paragraph({
                    alignment: AlignmentType.LEFT,
                    children: [
                      new TextRun({
                        text: (this.getValue(submission, "plan_organico_recoleccion_manejo_3_refrigeracion") ? '■' : '❏') + '  Refrigeración',
                        font: 'Arial',
                        size: 18
                      })
                    ]
                  }),
                  new Paragraph({
                    alignment: AlignmentType.LEFT,
                    children: [
                      new TextRun({
                        text: (this.getValue(submission, "plan_organico_recoleccion_manejo_3_transporte") ? '■' : '❏') + '  Transporte',
                        font: 'Arial',
                        size: 18
                      })
                    ]
                  }),
                  new Paragraph({
                    alignment: AlignmentType.LEFT,
                    children: [
                      new TextRun({
                        text: (this.getValue(submission, "plan_organico_recoleccion_manejo_3_otro") ? '■' : '❏') + ' Otro (¿Cuál?): ' + this.getValue(submission, "plan_organico_recoleccion_manejo_3_otro_text"),
                        font: 'Arial',
                        size: 18
                      })
                    ]
                  }),
                ],
                verticalAlign: VerticalAlign.TOP
              }),
              new TableCell({
                children: [
                  new Paragraph({
                    alignment: AlignmentType.LEFT,
                    children: [
                      new TextRun({
                        text: this.getValue(submission, "plan_organico_recoleccion_manejo_3_comentarios"),
                        font: 'Arial',
                        size: 18
                      })
                    ]
                  })
                ],
                verticalAlign: VerticalAlign.TOP
              }),
              new TableCell({
                children: [
                  new Paragraph({
                    alignment: AlignmentType.LEFT,
                    children: [
                        new TextRun({
                            text: ` ${this.getValue(submission, 'plan_organico_recoleccion_manejo_3_implementacion') === 'I' ? '■' : '❏'} I`,
                            font: 'Arial',
                            size: 18
                        })
                    ]
                  }),
                  new Paragraph({
                    alignment: AlignmentType.LEFT,
                    children: [
                        new TextRun({
                            text: ` ${this.getValue(submission, 'plan_organico_recoleccion_manejo_3_implementacion') === 'NI' ? '■' : '❏'} NI`,
                            font: 'Arial',
                            size: 18
                        })
                    ]
                  })
                ],
                verticalAlign: VerticalAlign.TOP
            }),
            new TableCell({
                children: [
                  new Paragraph({
                    alignment: AlignmentType.LEFT,
                    children: [
                        new TextRun({
                            text: ` ${this.getValue(submission, 'plan_organico_recoleccion_manejo_3_conformidad') === 'C' ? '■' : '❏'} C`,
                            font: 'Arial',
                            size: 18
                        })
                    ]
                  }),
                  new Paragraph({
                    alignment: AlignmentType.LEFT,
                    children: [
                        new TextRun({
                            text: ` ${this.getValue(submission, 'plan_organico_recoleccion_manejo_3_conformidad') === 'NC' ? '■' : '❏'} NC`,
                            font: 'Arial',
                            size: 18
                        })
                    ]
                  }),
                  new Paragraph({
                    alignment: AlignmentType.LEFT,
                    children: [
                        new TextRun({
                            text: ` ${this.getValue(submission, 'plan_organico_recoleccion_manejo_3_conformidad') === 'NA' ? '■' : '❏'} NA`,
                            font: 'Arial',
                            size: 18
                        })
                    ]
                  })
                ],
                verticalAlign: VerticalAlign.TOP
            }),
            new TableCell({
                children: [
                  new Paragraph({
                    alignment: AlignmentType.LEFT,
                    children: [
                        new TextRun({
                            text: this.getValue(submission, 'plan_organico_recoleccion_manejo_3_comentarios_inspector'),
                            font: 'Arial',
                            size: 18
                        })
                    ]
                  })
                ],
                verticalAlign: VerticalAlign.TOP
            })
            ]
          }),
          new TableRow({
            children: [
              new TableCell({
                children: [
                  new Paragraph({
                    alignment: AlignmentType.CENTER,
                    children: [
                      new TextRun({
                        text: '4',
                        font: 'Arial',
                        size: 18
                      })
                    ]
                  })
                ],
                verticalAlign: VerticalAlign.TOP
              }),
              new TableCell({
                children: [
                  new Paragraph({
                    alignment: AlignmentType.LEFT,
                    children: [
                      new TextRun({
                        text: 'Los residuos de cosecha se destinan a:',
                        font: 'Arial',
                        size: 18
                      })
                    ]
                  }),
                  new Paragraph({
                    alignment: AlignmentType.LEFT,
                    children: [
                      new TextRun({
                        text: (this.getValue(submission, "plan_organico_recoleccion_manejo_4_reintegran") ? '■' : '❏') + '  Se reintegran a la parcela ',
                        font: 'Arial',
                        size: 18
                      })
                    ]
                  }),
                  new Paragraph({
                    alignment: AlignmentType.LEFT,
                    children: [
                      new TextRun({
                        text: (this.getValue(submission, "plan_organico_recoleccion_manejo_4_desechan") ? '■' : '❏') + '  Se desechan en instalaciones públicas',
                        font: 'Arial',
                        size: 18
                      })
                    ]
                  }),
                  new Paragraph({
                    alignment: AlignmentType.LEFT,
                    children: [
                      new TextRun({
                        text: (this.getValue(submission, "plan_organico_recoleccion_manejo_4_otro") ? '■' : '❏') + ' Otro (¿Cuál?): ' + this.getValue(submission, "plan_organico_recoleccion_manejo_4_otro_text"),
                        font: 'Arial',
                        size: 18
                      })
                    ]
                  }),
                ],
                verticalAlign: VerticalAlign.TOP
              }),
              new TableCell({
                children: [
                  new Paragraph({
                    alignment: AlignmentType.LEFT,
                    children: [
                      new TextRun({
                        text: this.getValue(submission, "plan_organico_recoleccion_manejo_4_comentarios"),
                        font: 'Arial',
                        size: 18
                      })
                    ]
                  })
                ],
                verticalAlign: VerticalAlign.TOP
              }),
              new TableCell({
                children: [
                  new Paragraph({
                    alignment: AlignmentType.LEFT,
                    children: [
                        new TextRun({
                            text: ` ${this.getValue(submission, 'plan_organico_recoleccion_manejo_4_implementacion') === 'I' ? '■' : '❏'} I`,
                            font: 'Arial',
                            size: 18
                        })
                    ]
                  }),
                  new Paragraph({
                    alignment: AlignmentType.LEFT,
                    children: [
                        new TextRun({
                            text: ` ${this.getValue(submission, 'plan_organico_recoleccion_manejo_4_implementacion') === 'NI' ? '■' : '❏'} NI`,
                            font: 'Arial',
                            size: 18
                        })
                    ]
                  })
                ],
                verticalAlign: VerticalAlign.TOP
            }),
            new TableCell({
                children: [
                  new Paragraph({
                    alignment: AlignmentType.LEFT,
                    children: [
                        new TextRun({
                            text: ` ${this.getValue(submission, 'plan_organico_recoleccion_manejo_4_conformidad') === 'C' ? '■' : '❏'} C`,
                            font: 'Arial',
                            size: 18
                        })
                    ]
                  }),
                  new Paragraph({
                    alignment: AlignmentType.LEFT,
                    children: [
                        new TextRun({
                            text: ` ${this.getValue(submission, 'plan_organico_recoleccion_manejo_4_conformidad') === 'NC' ? '■' : '❏'} NC`,
                            font: 'Arial',
                            size: 18
                        })
                    ]
                  }),
                  new Paragraph({
                    alignment: AlignmentType.LEFT,
                    children: [
                        new TextRun({
                            text: ` ${this.getValue(submission, 'plan_organico_recoleccion_manejo_4_conformidad') === 'NA' ? '■' : '❏'} NA`,
                            font: 'Arial',
                            size: 18
                        })
                    ]
                  })
                ],
                verticalAlign: VerticalAlign.TOP
            }),
            new TableCell({
                children: [
                  new Paragraph({
                    alignment: AlignmentType.LEFT,
                    children: [
                        new TextRun({
                            text: this.getValue(submission, 'plan_organico_recoleccion_manejo_4_comentarios_inspector'),
                            font: 'Arial',
                            size: 18
                        })
                    ]
                  })
                ],
                verticalAlign: VerticalAlign.TOP
            })
            ]
          })
        ],
        margins: {
          left: 70,
          right: 70
        },
        //9885
        columnWidths: [450, 3895, 2045, 1150, 800, 1545]
      })
        return table
    }

    public createTableUDP(data, datePipe): Table {
        const table = new Table({
            rows: [
                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.RIGHT,
                                    children: [
                                        new TextRun({
                                            text: 'Código de operación:',
                                            font: 'Calibri (Cuerpo)',
                                            size: 22,
                                            bold: true,

                                        })
                                    ],
                                    spacing: { before: 100, after: 200 }
                                })],
                            verticalAlign: VerticalAlign.CENTER,
                        }),
                        new TableCell({
                            children: [new Paragraph({
                                alignment: AlignmentType.CENTER,
                                children: [
                                    new TextRun({
                                        text: data.customer.Id_Comercial,
                                        font: 'Calibri (Cuerpo)',
                                        size: 22,
                                    })
                                ],
                            })],
                            verticalAlign: VerticalAlign.CENTER
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.RIGHT,
                                    children: [
                                        new TextRun({
                                            text: 'Fecha: ',
                                            font: 'Calibri (Cuerpo)',
                                            size: 22,
                                            bold: true,

                                        })
                                    ],
                                    spacing: { before: 100, after: 200 }
                                })],
                            verticalAlign: VerticalAlign.CENTER,
                            width: {
                                size: 25,
                                type: WidthType.PERCENTAGE,
                            },
                        }),
                        new TableCell({
                            children: [new Paragraph({
                                alignment: AlignmentType.CENTER,
                                children: [
                                    new TextRun({
                                        text: datePipe.transform(data.record.Applications[0].Creation_Date.includes('UTC') ? data.record.Applications[0].Creation_Date.replace('UTC', '').trim() : data.record.Applications[0].Creation_Date, "d-MMMM-y"),
                                        font: 'Calibri (Cuerpo)',
                                        size: 22,
                                    })
                                ],
                            })],
                            verticalAlign: VerticalAlign.CENTER,
                            width: {
                                size: 25,
                                type: WidthType.PERCENTAGE,
                            },
                        }),
                    ],
                }),
                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.RIGHT,
                                    children: [
                                        new TextRun({
                                            text: 'Operador: ',
                                            font: 'Calibri (Cuerpo)',
                                            size: 22,
                                            bold: true,

                                        })
                                    ],
                                    spacing: { before: 100, after: 200 }
                                })],
                            verticalAlign: VerticalAlign.CENTER,
                        }),
                        new TableCell({
                            children: [new Paragraph({
                                alignment: AlignmentType.CENTER,
                                children: [
                                    new TextRun({
                                        text: data.customer.Company_Name,
                                        font: 'Calibri (Cuerpo)',
                                        size: 22,
                                    })
                                ],
                            })],
                            verticalAlign: VerticalAlign.CENTER,
                            columnSpan: 3,
                        }),
                    ],
                })
            ],
            margins: {
                left: 70,
                right: 70
            },
            width: {
                size: 100,
                type: WidthType.PERCENTAGE,
            },
            //9638
            columnWidths: [2410, 2410, 2409, 2409]
        })

        return table
    }

    getValue(submission, name) {
        var value = null
        for (const i in submission) {
            const element = submission[i]
            if (element.name === name) {
                value = element.value
            }
        }
        if (value) return value
        else return ''
    }

    getValueList(submission, name) {
        var value = null
        for (const i in submission) {
            const element = submission[i]
            if (element.name === name) {
                value = element.value
            }
        }
        if (value) return value
        else return []
    }

    public getBase64FromUrl = async (url) => {
      const data = await fetch(url, {
        headers: {
            'Cache-Control': 'no-cache, no-store, must-revalidate',
            'Pragma': 'no-cache',
            'Expires': 'Sat, 01 Jan 2000 00:00:00 GMT',
            'If-Modified-Since': '0'
        }
    });
      const blob = await data.blob();
      return new Promise((resolve) => {
        const reader = new FileReader();
        reader.readAsDataURL(blob);
        reader.onloadend = function () {
          const base64data = reader.result;
          resolve(base64data);
        }
      });
    }
}
