import {
  AlignmentType,
  Document,
  Paragraph,
  TextRun,
  Media,
  Header,
  Footer,
  Table,
  TableRow,
  TableCell,
  VerticalAlign,
  WidthType,
  PageNumber,
  BorderStyle
} from "docx";
import {logo} from "../logo";
import {userWithoutSign} from "../userWithoutSign"
import {DatePipe} from '@angular/common';

export class DocumentCreatorMTO_FSOL_08_1_2_v3 {

  public getBase64FromUrl = async (url) => {
    const data = await fetch(url, {
      headers: {
          'Cache-Control': 'no-cache, no-store, must-revalidate',
          'Pragma': 'no-cache',
          'Expires': 'Sat, 01 Jan 2000 00:00:00 GMT',
          'If-Modified-Since': '0'
      }
  });
    const blob = await data.blob();
    return new Promise((resolve) => {
      const reader = new FileReader();
      reader.readAsDataURL(blob);
      reader.onloadend = function () {
        const base64data = reader.result;
        resolve(base64data);
      }
    });
  }

  // INIT MTO_FSOL_08_1_2
  public async create_MTO_FSOL_08_1_2(data, isReview = true, signature = '', signature2 = ''): Promise<Document> {
    const document = new Document();
    const image = Media.addImage(document, logo, 50, 50);
    var datePipe = new DatePipe('es-MX');

    let signatureImage = null
    if (signature) {
      signatureImage = await this.getBase64FromUrl(signature)
    } else {
      signatureImage = userWithoutSign
    }

    let signatureImage2 = null
    if (signature2) {
      signatureImage2 = await this.getBase64FromUrl(signature2)
    } else {
      signatureImage2 = isReview ? '' : userWithoutSign
    }

    var unidadesProduccion = ''
    const unidades = this.getValueList(data.submission, "lista_unidades")
    unidades.forEach(unidad => {
      unidadesProduccion += unidad.nombre_unidad_produccion + ', '
    });
    unidadesProduccion = unidadesProduccion.slice(0, -2)

    document.addSection({
      headers: {
        default: new Header({
          children: [
            new Paragraph({alignment: AlignmentType.CENTER, children: [image],}),
            new Paragraph({
              alignment: AlignmentType.CENTER, children: [
                new TextRun({
                  children: [`MTO-FSOL-08-1-2| R 09 | 15-Mayo-2022 | Pág. `, PageNumber.CURRENT],
                  font: 'Book Antiqua',
                  size: 20,
                  bold: true
                }),
                new TextRun({
                  font: 'Book Antiqua',
                  size: 20,
                  bold: true,
                  children: [' de ', PageNumber.TOTAL_PAGES]
                }),
              ], spacing: {before: 200, after: 100}
            })
          ]
        })
      },
      footers: {
        default: new Footer({
          children: [
            new Paragraph({alignment: AlignmentType.CENTER}),
            this.createFooter()
          ]
        }),
      },
      children: [
        new Paragraph({
          alignment: AlignmentType.CENTER,
          children: [
            new TextRun({
              text: 'PLAN ORGÁNICO PRODUCCIÓN VEGETAL DE ESTRUCTURAS Y CONTENEDORES',
              font: 'Calibri (Cuerpo)',
              size: 22,
              bold: true
            })
          ],
          spacing: {after: 800}
        }),
        this.createTableUDP(data, datePipe),
        new Paragraph({
          spacing: {
            after: 400
          }
        }),
        this.createRegisters_MTO_FSOL_08_1_2(data.submission),
        new Paragraph({
          spacing: {
            after: 400
          }
        }),
        this.createHandling_MTO_FSOL_08_1_2(data.submission),
        new Paragraph({
          spacing: {
            after: 400
          }
        }),
        this.createCultivo_Contenedores_MTO_FSOL_08_1_2(data.submission),
        new Paragraph({
          alignment: AlignmentType.JUSTIFIED,
          children: [
            new TextRun({
              text: `El que suscribe: ${data.customer.Legal_Authorities[0].Name} ${data.customer.Legal_Authorities[0].Middle_Name} ${data.customer.Legal_Authorities[0].Last_Name}, Titular / Representante Legal de la(s) Unidad(es) de Producción / Empresa: ${data.customer.Company_Type === "PERSONA_FISICA" ? unidadesProduccion : data.customer.Company_Name}, declaro que la información descrita en este Plan Orgánico y documentos adjuntos es verídica, y me comprometo a mantenerlo actualizado, al igual que las actividades descritas en él; con la finalidad de cumplir con la Ley de Productos Orgánicos de México (LPO) y las Regulaciones de Productos Orgánicos de Canadá (COR/USCOEA).`,
              font: 'Arial',
              size: 18
            })
          ],
          spacing: {
            before: 400,
            after: 400
          },
        }),
        new Paragraph({
          alignment: AlignmentType.JUSTIFIED,
          children: [
            new TextRun({
              text: 'Atentamente:',
              font: 'Arial',
              size: 18, bold: true
            })
          ],
          spacing: {
            before: 400,
            after: 400
          },
        }),
        new Paragraph({
          alignment: AlignmentType.CENTER,
          children: [
            signatureImage ? Media.addImage(document, signatureImage.toString(), 200, 100) : new TextRun({
              text: ''
            })
          ]
        }),
        new Paragraph({
          alignment: AlignmentType.LEFT,
          children: [
            new TextRun({
              text: `Fecha: ${datePipe.transform(new Date(data.record.Applications[0].Creation_Date.includes('UTC') ? data.record.Applications[0].Creation_Date.replace('UTC', '').trim() : data.record.Applications[0].Creation_Date), 'd-MMMM-y')}                  Nombre y firma del operador: ${data.customer.Legal_Authorities[0].Name} ${data.customer.Legal_Authorities[0].Middle_Name} ${data.customer.Legal_Authorities[0].Last_Name ? data.customer.Legal_Authorities[0].Last_Name : ''}`,
              font: 'Arial',
              size: 20,
              italics: true
            })
          ],
          spacing: {
            after: 200
          }
        }),
        new Table({
          rows: [
              new TableRow({
                  children: [
                      new TableCell({
                          children: [
                              new Paragraph({
                                  alignment: AlignmentType.CENTER,
                                  children: [
                                      new TextRun({
                                          text: 'EVALUACIÓN POR EL REVISOR',
                                          font: 'Arial',
                                          size: 20,
                                          bold: true
                                      })
                                  ],
                                  spacing: {
                                      after: 75,
                                      before: 75
                                  }
                              }),
                          ],
                          verticalAlign: VerticalAlign.CENTER,
                          columnSpan: 2,
                          shading: {
                              fill: '#C0C0C0'
                          }
                      })
                  ]
              }),
              new TableRow({
                  children: [
                      new TableCell({
                          children: [
                              new Paragraph({
                                  alignment: AlignmentType.RIGHT,
                                  children: [
                                      new TextRun({
                                          text: 'Nombre del Revisor: ',
                                          font: 'Calibri (Cuerpo)',
                                          size: 22,
                                      })
                                  ],
                                  spacing: { before: 100, after: 200 }
                              })],
                          verticalAlign: VerticalAlign.CENTER,
                      }),
                      new TableCell({
                          children: [
                              new Paragraph({
                                  alignment: AlignmentType.LEFT,
                                  children: [
                                      new TextRun({
                                          text: data.reviewer ? data.reviewer.name : '',
                                          font: 'Calibri (Cuerpo)',
                                          size: 22,
                                      })
                                  ],
                                  spacing: { before: 100, after: 200 }
                              })],
                          verticalAlign: VerticalAlign.CENTER,
                      }),
                  ],
              }),
              new TableRow({
                  children: [
                      new TableCell({
                          children: [
                              new Paragraph({
                                  alignment: AlignmentType.RIGHT,
                                  children: [
                                      new TextRun({
                                          text: 'Fecha: ',
                                          font: 'Calibri (Cuerpo)',
                                          size: 22,
                                      })
                                  ],
                                  spacing: { before: 100, after: 200 }
                              })],
                          verticalAlign: VerticalAlign.CENTER,
                      }),
                      new TableCell({
                          children: [
                              new Paragraph({
                                  alignment: AlignmentType.LEFT,
                                  children: [
                                      new TextRun({
                                          text: `${data.reviewer ? datePipe.transform(new Date(data.reviewer.date), 'd-MMMM-y') : ''}`,
                                          font: 'Calibri (Cuerpo)',
                                          size: 22,
                                      })
                                  ],
                                  spacing: { before: 100, after: 200 }
                              })],
                          verticalAlign: VerticalAlign.CENTER,
                      }),
                  ],
              }),
              new TableRow({
                  children: [
                      new TableCell({
                          children: [
                              new Paragraph({
                                  alignment: AlignmentType.RIGHT,
                                  children: [
                                      new TextRun({
                                          text: 'Conformidad: ',
                                          font: 'Calibri (Cuerpo)',
                                          size: 22,
                                      })
                                  ],
                                  spacing: { before: 100, after: 200 }
                              })],
                          verticalAlign: VerticalAlign.CENTER,
                      }),
                      new TableCell({
                          children: [
                              new Paragraph({
                                  alignment: AlignmentType.LEFT,
                                  children: [
                                      new TextRun({
                                          text: `${this.getValue(data.revision, 'plan_organico_conformidad_no_conformidades_graves') === true ? '■' : '❏'} No conformidades graves, no procederá la certificación.`,
                                          font: 'Arial',
                                          size: 18
                                      })
                                  ]
                              }),
                              new Paragraph({
                                  alignment: AlignmentType.LEFT,
                                  children: [
                                      new TextRun({
                                          text: `${this.getValue(data.revision, 'plan_organico_conformidad_no_conformidades_menores_con_hallazgos') === true ? '■' : '❏'} No conformidades menores, se requiere de corrección antes de inspección. Listar los hallazgos abajo.`,
                                          font: 'Arial',
                                          size: 18
                                      })
                                  ]
                              }),
                              new Paragraph({
                                  alignment: AlignmentType.LEFT,
                                  children: [
                                      new TextRun({
                                          text: `${this.getValue(data.revision, 'plan_organico_conformidad_no_conformidades_menores') === true ? '■' : '❏'} No conformidades menores, se puede realizar inspección.`,
                                          font: 'Arial',
                                          size: 18
                                      })
                                  ]
                              }),
                              new Paragraph({
                                  alignment: AlignmentType.LEFT,
                                  children: [
                                      new TextRun({
                                          text: `${this.getValue(data.revision, 'plan_organico_conformidad_conformidad') === true ? '■' : '❏'} Conformidad`,
                                          font: 'Arial',
                                          size: 18
                                      })
                                  ]
                              }),
                              new Paragraph({
                                  alignment: AlignmentType.LEFT,
                                  children: [
                                      new TextRun({
                                          text: `${this.getValue(data.revision, 'plan_organico_conformidad_otro') === true ? `■ Otro: ${this.getValue(data.revision, 'plan_organico_conformidad_otro_text')}` : '❏ Otro:'}`,
                                          font: 'Arial',
                                          size: 18
                                      })
                                  ]
                              })
                          ],
                          verticalAlign: VerticalAlign.CENTER,
                          columnSpan: 1,
                      }),
                  ],
              }),
              new TableRow({
                  children: [
                      new TableCell({
                          children: [
                              new Paragraph({
                                  alignment: AlignmentType.RIGHT,
                                  children: [
                                      new TextRun({
                                          text: 'Hallazgos: ',
                                          font: 'Calibri (Cuerpo)',
                                          size: 22,
                                      })
                                  ],
                                  spacing: { before: 100, after: 200 }
                              })],
                          verticalAlign: VerticalAlign.CENTER,
                      }),
                      new TableCell({
                          children: [
                              new Paragraph({
                                  alignment: AlignmentType.LEFT,
                                  children: [
                                      new TextRun({
                                          text: this.getValue(data.revision, "plan_organico_hallazgos"),
                                          font: 'Calibri (Cuerpo)',
                                          size: 22,
                                      })
                                  ],
                                  spacing: { before: 100, after: 200 }
                              })],
                          verticalAlign: VerticalAlign.CENTER,
                      }),
                  ],
              }),
              new TableRow({
                  children: [
                      new TableCell({
                          children: [
                              new Paragraph({
                                  alignment: AlignmentType.RIGHT,
                                  children: [
                                      new TextRun({
                                          text: 'Recomendaciones para inspector: ',
                                          font: 'Calibri (Cuerpo)',
                                          size: 22,
                                      })
                                  ],
                                  spacing: { before: 100, after: 200 }
                              })],
                          verticalAlign: VerticalAlign.CENTER,
                      }),
                      new TableCell({
                          children: [
                              new Paragraph({
                                  alignment: AlignmentType.LEFT,
                                  children: [
                                      new TextRun({
                                          text: this.getValue(data.revision, "plan_organico_recomendaciones"),
                                          font: 'Calibri (Cuerpo)',
                                          size: 22,
                                      })
                                  ],
                                  spacing: { before: 100, after: 200 }
                              })],
                          verticalAlign: VerticalAlign.CENTER,
                      }),
                  ],
              }),
              new TableRow({
                  children: [
                      new TableCell({
                          children: [
                              new Paragraph({
                                  alignment: AlignmentType.RIGHT,
                                  children: [
                                      new TextRun({
                                          text: 'Comentarios generales: ',
                                          font: 'Calibri (Cuerpo)',
                                          size: 22,
                                      })
                                  ],
                                  spacing: { before: 100, after: 200 }
                              })],
                          verticalAlign: VerticalAlign.CENTER,
                      }),
                      new TableCell({
                          children: [
                              new Paragraph({
                                  alignment: AlignmentType.LEFT,
                                  children: [
                                      new TextRun({
                                          text: this.getValue(data.revision, "plan_organico_comentarios"),
                                          font: 'Calibri (Cuerpo)',
                                          size: 22,
                                      })
                                  ],
                                  spacing: { before: 100, after: 200 }
                              })],
                          verticalAlign: VerticalAlign.CENTER,
                      }),
                  ],
              }),
              new TableRow({
                  children: [
                      new TableCell({
                          children: [
                              new Paragraph({
                                  alignment: AlignmentType.RIGHT,
                                  children: [
                                      new TextRun({
                                          text: `Firma del Revisor:`,
                                          font: 'Calibri (Cuerpo)',
                                          size: 22,
                                      })
                                  ],
                                  spacing: { before: 100, after: 200 }
                              })],
                          verticalAlign: VerticalAlign.CENTER,
                      }),
                      new TableCell({
                          children: [
                              new Paragraph({
                                  alignment: AlignmentType.LEFT,
                                  children: [
                                    signatureImage2 ? Media.addImage(document, signatureImage2.toString(), 200, 100) : new TextRun({
                                      text: ''
                                    })
                                  ],
                                  spacing: { before: 100, after: 200 }
                              })
                          ],
                          verticalAlign: VerticalAlign.CENTER,
                          columnSpan: 1,
                      }),
                  ],
              }),
          ],
          margins: {
              left: 70,
              right: 70
          },
          width: {
              size: 100,
              type: WidthType.PERCENTAGE,
          },
          columnWidths: [2410, 7227]
        })
      ],
      margins: {
        top: 350,
        bottom: 500,
        left: 1000,
        right: 1000
      }
    });

    return document;
  }

  public createFooter(): Table {
    const table = new Table({
      rows: [
        new TableRow({
          children: [
            new TableCell({
              children: [
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                    new TextRun({
                      text: 'Elaboró:',
                      font: 'Calibri (Cuerpo)',
                      size: 22
                    })
                  ]
                })
              ],
              width: {
                size: 33,
                type: WidthType.PERCENTAGE
              }
            }),
            new TableCell({
              children: [
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                    new TextRun({
                      text: 'Revisó:',
                      font: 'Calibri (Cuerpo)',
                      size: 22
                    })
                  ]
                })
              ],
              width: {
                size: 33,
                type: WidthType.PERCENTAGE
              }
            }),
            new TableCell({
              children: [
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                    new TextRun({
                      text: 'Autorizó:',
                      font: 'Calibri (Cuerpo)',
                      size: 22
                    })
                  ]
                }),
              ],
              width: {
                size: 33,
                type: WidthType.PERCENTAGE
              }
            })
          ]
        }),
        new TableRow({
          children: [
            new TableCell({
              children: [
                new Paragraph({
                  alignment: AlignmentType.CENTER,
                  children: [
                    new TextRun({
                      text: 'Director de certificación',
                      font: 'Calibri (Cuerpo)',
                      size: 16,
                      bold: true
                    })
                  ]
                }),

              ],
              width: {
                size: 33,
                type: WidthType.PERCENTAGE
              },
              shading: {
                fill: '#C0C0C0'
              }
            }),
            new TableCell({
              children: [
                new Paragraph({
                  alignment: AlignmentType.CENTER,
                  children: [
                    new TextRun({
                      text: 'Responsable de calidad',
                      font: 'Calibri (Cuerpo)',
                      size: 16,
                      bold: true
                    })
                  ]
                }),

              ],
              width: {
                size: 33,
                type: WidthType.PERCENTAGE
              },
              shading: {
                fill: '#C0C0C0'
              }
            }),
            new TableCell({
              children: [
                new Paragraph({
                  alignment: AlignmentType.CENTER,
                  children: [
                    new TextRun({
                      text: 'Director general',
                      font: 'Calibri (Cuerpo)',
                      size: 16,
                      bold: true
                    })
                  ]
                }),
              ],
              width: {
                size: 33,
                type: WidthType.PERCENTAGE
              },
              shading: {
                fill: '#C0C0C0'
              }
            })
          ]
        })
      ],
      margins: {
        left: 70,
        right: 70
      },
      //9638
      columnWidths: [3213, 3213, 3212],
      alignment: AlignmentType.CENTER
    })
    return table
  }

  public createRegisters_MTO_FSOL_08_1_2(submission): Table {
    const table = new Table({
      rows: [
        new TableRow({
          children: [
            new TableCell({
              children: [
                new Paragraph({
                  alignment: AlignmentType.CENTER,
                  children: [
                    new TextRun({
                      text: 'REGISTROS',
                      font: 'Arial',
                      size: 20,
                      bold: true
                    })
                  ],
                  spacing: {
                    after: 75,
                    before: 75
                  }
                }),
              ],
              verticalAlign: VerticalAlign.CENTER,
              columnSpan: 6,
              shading: {
                fill: '#C0C0C0'
              }
            })
          ]
        }),
        new TableRow({
          children: [
            new TableCell({
              children: [
                new Paragraph({
                  alignment: AlignmentType.CENTER,
                  children: [
                    new TextRun({
                      text: 'No.',
                      font: 'Arial',
                      size: 18,
                      bold: true
                    })
                  ]
                })
              ],
              rowSpan: 2,
              verticalAlign: VerticalAlign.CENTER,
              width: {
                size: 25,
                type: WidthType.PERCENTAGE
              }
            }),
            new TableCell({
              children: [
                new Paragraph({
                  alignment: AlignmentType.CENTER,
                  children: [
                    new TextRun({
                      text: 'Requisitos',
                      font: 'Arial',
                      size: 18,
                      bold: true
                    })
                  ]
                })
              ],
              rowSpan: 2,
              verticalAlign: VerticalAlign.CENTER,
              width: {
                size: 15,
                type: WidthType.PERCENTAGE
              }
            }),
            new TableCell({
              children: [
                new Paragraph({
                  alignment: AlignmentType.CENTER,
                  children: [
                    new TextRun({
                      text: 'Incluyo información',
                      font: 'Arial',
                      size: 18,
                      bold: true
                    })
                  ]
                })
              ],
              rowSpan: 2,
              verticalAlign: VerticalAlign.CENTER,
              width: {
                size: 15,
                type: WidthType.PERCENTAGE
              }
            }),
            new TableCell({
              children: [
                new Paragraph({
                  alignment: AlignmentType.CENTER,
                  children: [
                    new TextRun({
                      text: 'Comentarios',
                      font: 'Arial',
                      size: 18,
                      bold: true
                    })
                  ]
                })
              ],
              rowSpan: 2,
              verticalAlign: VerticalAlign.CENTER,
              width: {
                size: 15,
                type: WidthType.PERCENTAGE
              }
            }),
            new TableCell({
              children: [
                  new Paragraph({
                      alignment: AlignmentType.CENTER,
                      children: [
                          new TextRun({
                              text: 'Verificación en sitio (Evaluada por Inspector)',
                              font: 'Arial',
                              size: 18,
                              bold: true
                          })
                      ]
                  })
              ],
              columnSpan: 3,
              verticalAlign: VerticalAlign.CENTER,
              width: {
                  size: 15,
                  type: WidthType.PERCENTAGE
              }
          })
          ]
        }),
        new TableRow({
          children: [new TableCell({
              children: [
                  new Paragraph({
                      alignment: AlignmentType.CENTER,
                      children: [
                          new TextRun({
                              text: 'Implementación',
                              font: 'Arial',
                              size: 18,
                              bold: true
                          })
                      ]
                  })
              ],
              verticalAlign: VerticalAlign.CENTER
          }),
          new TableCell({
              children: [
                  new Paragraph({
                      alignment: AlignmentType.CENTER,
                      children: [
                          new TextRun({
                              text: 'Confirmación',
                              font: 'Arial',
                              size: 18,
                              bold: true
                          })
                      ]
                  })
              ],
              verticalAlign: VerticalAlign.CENTER,
              columnSpan: 2
          }),]
        }),
        new TableRow({
          children: [
            new TableCell({
              children: [
                new Paragraph({
                  alignment: AlignmentType.CENTER,
                  children: [
                    new TextRun({
                      text: '1',
                      font: 'Arial',
                      size: 18
                    })
                  ]
                })
              ],
              verticalAlign: VerticalAlign.TOP
            }),
            new TableCell({
              children: [
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                    new TextRun({
                      text: 'He incluido una lista completa de las parcelas que estoy sometiendo a evaluación para la certificación orgánica. MTO-FSOL-02',
                      font: 'Arial',
                      size: 18
                    })
                  ]
                })
              ],
              verticalAlign: VerticalAlign.TOP
            }),
            new TableCell({
              children: [
                new Paragraph({
                  alignment: AlignmentType.CENTER,
                  children: [
                    new TextRun({
                      text: this.getValue(submission, 'plan_organico_vegetal_registros_1_declaracion') === 'Si' ? '■ SÍ  ❏ NO' : '❏ SÍ  ■ NO',
                      font: 'Arial',
                      size: 18
                    })
                  ]
                })
              ],
              verticalAlign: VerticalAlign.TOP
            }),
            new TableCell({
              children: [
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                    new TextRun({
                      text: this.getValue(submission, 'plan_organico_vegetal_registros_1_comentarios'),
                      font: 'Arial',
                      size: 18
                    })
                  ]
                })
              ],
              verticalAlign: VerticalAlign.TOP
            }),
            new TableCell({
              children: [
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                      new TextRun({
                          text: ` ${this.getValue(submission, 'plan_organico_vegetal_registros_1_implementacion') === 'I' ? '■' : '❏'} I`,
                          font: 'Arial',
                          size: 18
                      })
                  ]
                }),
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                      new TextRun({
                          text: ` ${this.getValue(submission, 'plan_organico_vegetal_registros_1_implementacion') === 'NI' ? '■' : '❏'} NI`,
                          font: 'Arial',
                          size: 18
                      })
                  ]
                })
              ],
              verticalAlign: VerticalAlign.TOP
          }),
          new TableCell({
              children: [
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                      new TextRun({
                          text: ` ${this.getValue(submission, 'plan_organico_vegetal_registros_1_conformidad') === 'C' ? '■' : '❏'} C`,
                          font: 'Arial',
                          size: 18
                      })
                  ]
                }),
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                      new TextRun({
                          text: ` ${this.getValue(submission, 'plan_organico_vegetal_registros_1_conformidad') === 'NC' ? '■' : '❏'} NC`,
                          font: 'Arial',
                          size: 18
                      })
                  ]
                }),
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                      new TextRun({
                          text: ` ${this.getValue(submission, 'plan_organico_vegetal_registros_1_conformidad') === 'NA' ? '■' : '❏'} NA`,
                          font: 'Arial',
                          size: 18
                      })
                  ]
                })
              ],
              verticalAlign: VerticalAlign.TOP
          }),
          new TableCell({
              children: [
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                      new TextRun({
                          text: this.getValue(submission, 'plan_organico_vegetal_registros_1_comentarios_inspector'),
                          font: 'Arial',
                          size: 18
                      })
                  ]
                })
              ],
              verticalAlign: VerticalAlign.TOP
          })
          ]
        }),
        new TableRow({
          children: [
            new TableCell({
              children: [
                new Paragraph({
                  alignment: AlignmentType.CENTER,
                  children: [
                    new TextRun({
                      text: '2',
                      font: 'Arial',
                      size: 18
                    })
                  ]
                })
              ],
              verticalAlign: VerticalAlign.TOP
            }),
            new TableCell({
              children: [
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                    new TextRun({
                      text: 'He incluido croquis de cada una de las parcelas. MTO-FSOL-05',
                      font: 'Arial',
                      size: 18
                    })
                  ]
                })
              ],
              verticalAlign: VerticalAlign.TOP
            }),
            new TableCell({
              children: [
                new Paragraph({
                  alignment: AlignmentType.CENTER,
                  children: [
                    new TextRun({
                      text: this.getValue(submission, 'plan_organico_vegetal_registros_2_declaracion') === 'Si' ? '■ SÍ  ❏ NO' : '❏ SÍ  ■ NO',
                      font: 'Arial',
                      size: 18
                    })
                  ]
                })
              ],
              verticalAlign: VerticalAlign.TOP
            }),
            new TableCell({
              children: [
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                    new TextRun({
                      text: this.getValue(submission, 'plan_organico_vegetal_registros_2_comentarios'),
                      font: 'Arial',
                      size: 18
                    })
                  ]
                })
              ],
              verticalAlign: VerticalAlign.TOP
            }),
            new TableCell({
              children: [
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                      new TextRun({
                          text: ` ${this.getValue(submission, 'plan_organico_vegetal_registros_2_implementacion') === 'I' ? '■' : '❏'} I`,
                          font: 'Arial',
                          size: 18
                      })
                  ]
                }),
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                      new TextRun({
                          text: ` ${this.getValue(submission, 'plan_organico_vegetal_registros_2_implementacion') === 'NI' ? '■' : '❏'} NI`,
                          font: 'Arial',
                          size: 18
                      })
                  ]
                })
              ],
              verticalAlign: VerticalAlign.TOP
          }),
          new TableCell({
              children: [
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                      new TextRun({
                          text: ` ${this.getValue(submission, 'plan_organico_vegetal_registros_2_conformidad') === 'C' ? '■' : '❏'} C`,
                          font: 'Arial',
                          size: 18
                      })
                  ]
                }),
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                      new TextRun({
                          text: ` ${this.getValue(submission, 'plan_organico_vegetal_registros_2_conformidad') === 'NC' ? '■' : '❏'} NC`,
                          font: 'Arial',
                          size: 18
                      })
                  ]
                }),
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                      new TextRun({
                          text: ` ${this.getValue(submission, 'plan_organico_vegetal_registros_2_conformidad') === 'NA' ? '■' : '❏'} NA`,
                          font: 'Arial',
                          size: 18
                      })
                  ]
                })
              ],
              verticalAlign: VerticalAlign.TOP
          }),
          new TableCell({
              children: [
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                      new TextRun({
                          text: this.getValue(submission, 'plan_organico_vegetal_registros_2_comentarios_inspector'),
                          font: 'Arial',
                          size: 18
                      })
                  ]
                })
              ],
              verticalAlign: VerticalAlign.TOP
          })
          ]
        }),
        new TableRow({
          children: [
            new TableCell({
              children: [
                new Paragraph({
                  alignment: AlignmentType.CENTER,
                  children: [
                    new TextRun({
                      text: '3',
                      font: 'Arial',
                      size: 18
                    })
                  ]
                })
              ],
              verticalAlign: VerticalAlign.TOP
            }),
            new TableCell({
              children: [
                new Table({
                  rows: [
                    new TableRow({
                      children: [
                        new TableCell({
                          children: [
                            new Paragraph({
                              alignment: AlignmentType.LEFT,
                              children: [
                                new TextRun({
                                  text: 'Cuento con las siguientes bitácoras',
                                  font: 'Arial',
                                  size: 18
                                })
                              ]
                            })
                          ],
                          borders: {
                            left: {
                              style: BorderStyle.NONE,
                              color: "white",
                              size: 0
                            },
                            top: {
                              style: BorderStyle.NONE,
                              color: "white",
                              size: 0
                            },
                            bottom: {
                              style: BorderStyle.NONE,
                              color: "white",
                              size: 0
                            }
                          }
                        }),
                        new TableCell({
                          children: [
                            new Paragraph({
                              alignment: AlignmentType.LEFT,
                              children: [
                                new TextRun({
                                  text: '',
                                  font: 'Arial',
                                  size: 18
                                })
                              ]
                            })
                          ],
                          borders: {
                            right: {
                              style: BorderStyle.NONE,
                              color: "white",
                              size: 0
                            },
                            top: {
                              style: BorderStyle.NONE,
                              color: "white",
                              size: 0
                            },
                            bottom: {
                              style: BorderStyle.NONE,
                              color: "white",
                              size: 0
                            }
                          }
                        })
                      ]
                    }),
                    new TableRow({
                      children: [
                        new TableCell({
                          children: [
                            new Paragraph({
                              alignment: AlignmentType.LEFT,
                              children: [
                                new TextRun({
                                  text: `${this.getValue(submission, 'plan_organico_vegetal_registros_3_a_check') ? '■' : '❏'} Compra e Inventario de insumos`,
                                  font: 'Arial',
                                  size: 18
                                })
                              ]
                            })
                          ],
                          borders: {
                            left: {
                              style: BorderStyle.NONE,
                              color: "white",
                              size: 0
                            },
                            top: {
                              style: BorderStyle.NONE,
                              color: "white",
                              size: 0
                            },
                            bottom: {
                              style: BorderStyle.NONE,
                              color: "white",
                              size: 0
                            }
                          }
                        }),
                        new TableCell({
                          children: [
                            new Paragraph({
                              alignment: AlignmentType.CENTER,
                              children: [
                                new TextRun({
                                  text: `${this.getValue(submission, 'plan_organico_vegetal_registros_3_a_declaracion') === "Si" ? '■ Sí ❏ No' : this.getValue(submission, 'plan_organico_vegetal_registros_3_a_declaracion') === "No" ? '❏ Sí ■ No' : '❏ Sí ❏ No'}`,
                                  font: 'Arial',
                                  size: 18
                                })
                              ]
                            })
                          ],
                          borders: {
                            right: {
                              style: BorderStyle.NONE,
                              color: "white",
                              size: 0
                            },
                            top: {
                              style: BorderStyle.NONE,
                              color: "white",
                              size: 0
                            },
                            bottom: {
                              style: BorderStyle.NONE,
                              color: "white",
                              size: 0
                            }
                          }
                        })
                      ]
                    }),
                    new TableRow({
                      children: [
                        new TableCell({
                          children: [
                            new Paragraph({
                              alignment: AlignmentType.LEFT,
                              children: [
                                new TextRun({
                                  text: `${this.getValue(submission, 'plan_organico_vegetal_registros_3_b_check') ? '■' : '❏'} Aplicación de insumos`,
                                  font: 'Arial',
                                  size: 18
                                })
                              ]
                            })
                          ],
                          borders: {
                            left: {
                              style: BorderStyle.NONE,
                              color: "white",
                              size: 0
                            },
                            top: {
                              style: BorderStyle.NONE,
                              color: "white",
                              size: 0
                            },
                            bottom: {
                              style: BorderStyle.NONE,
                              color: "white",
                              size: 0
                            }
                          }
                        }),
                        new TableCell({
                          children: [
                            new Paragraph({
                              alignment: AlignmentType.CENTER,
                              children: [
                                new TextRun({
                                  text: `${this.getValue(submission, 'plan_organico_vegetal_registros_3_b_declaracion') === "Si" ? '■ Sí ❏ No' : this.getValue(submission, 'plan_organico_vegetal_registros_3_b_declaracion') === "No" ? '❏ Sí ■ No' : '❏ Sí ❏ No'}`,
                                  font: 'Arial',
                                  size: 18
                                })
                              ]
                            })
                          ],
                          borders: {
                            right: {
                              style: BorderStyle.NONE,
                              color: "white",
                              size: 0
                            },
                            top: {
                              style: BorderStyle.NONE,
                              color: "white",
                              size: 0
                            },
                            bottom: {
                              style: BorderStyle.NONE,
                              color: "white",
                              size: 0
                            }
                          }
                        })
                      ]
                    }),
                    new TableRow({
                      children: [
                        new TableCell({
                          children: [
                            new Paragraph({
                              alignment: AlignmentType.LEFT,
                              children: [
                                new TextRun({
                                  text: `${this.getValue(submission, 'plan_organico_vegetal_registros_3_c_check') ? '■' : '❏'} Cosecha`,
                                  font: 'Arial',
                                  size: 18
                                })
                              ]
                            })
                          ],
                          borders: {
                            left: {
                              style: BorderStyle.NONE,
                              color: "white",
                              size: 0
                            },
                            top: {
                              style: BorderStyle.NONE,
                              color: "white",
                              size: 0
                            },
                            bottom: {
                              style: BorderStyle.NONE,
                              color: "white",
                              size: 0
                            }
                          }
                        }),
                        new TableCell({
                          children: [
                            new Paragraph({
                              alignment: AlignmentType.CENTER,
                              children: [
                                new TextRun({
                                  text: `${this.getValue(submission, 'plan_organico_vegetal_registros_3_c_declaracion') === "Si" ? '■ Sí ❏ No' : this.getValue(submission, 'plan_organico_vegetal_registros_3_c_declaracion') === "No" ? '❏ Sí ■ No' : '❏ Sí ❏ No'}`,
                                  font: 'Arial',
                                  size: 18
                                })
                              ]
                            })
                          ],
                          borders: {
                            right: {
                              style: BorderStyle.NONE,
                              color: "white",
                              size: 0
                            },
                            top: {
                              style: BorderStyle.NONE,
                              color: "white",
                              size: 0
                            },
                            bottom: {
                              style: BorderStyle.NONE,
                              color: "white",
                              size: 0
                            }
                          }
                        })
                      ]
                    }),
                    new TableRow({
                      children: [
                        new TableCell({
                          children: [
                            new Paragraph({
                              alignment: AlignmentType.LEFT,
                              children: [
                                new TextRun({
                                  text: `${this.getValue(submission, 'plan_organico_vegetal_registros_3_d_check') ? '■' : '❏'} Ventas`,
                                  font: 'Arial',
                                  size: 18
                                })
                              ]
                            })
                          ],
                          borders: {
                            left: {
                              style: BorderStyle.NONE,
                              color: "white",
                              size: 0
                            },
                            top: {
                              style: BorderStyle.NONE,
                              color: "white",
                              size: 0
                            },
                            bottom: {
                              style: BorderStyle.NONE,
                              color: "white",
                              size: 0
                            }
                          }
                        }),
                        new TableCell({
                          children: [
                            new Paragraph({
                              alignment: AlignmentType.CENTER,
                              children: [
                                new TextRun({
                                  text: `${this.getValue(submission, 'plan_organico_vegetal_registros_3_d_declaracion') === "Si" ? '■ Sí ❏ No' : this.getValue(submission, 'plan_organico_vegetal_registros_3_d_declaracion') === "No" ? '❏ Sí ■ No' : '❏ Sí ❏ No'}`,
                                  font: 'Arial',
                                  size: 18
                                })
                              ]
                            })
                          ],
                          borders: {
                            right: {
                              style: BorderStyle.NONE,
                              color: "white",
                              size: 0
                            },
                            top: {
                              style: BorderStyle.NONE,
                              color: "white",
                              size: 0
                            },
                            bottom: {
                              style: BorderStyle.NONE,
                              color: "white",
                              size: 0
                            }
                          }
                        })
                      ]
                    }),
                    new TableRow({
                      children: [
                        new TableCell({
                          children: [
                            new Paragraph({
                              alignment: AlignmentType.LEFT,
                              children: [
                                new TextRun({
                                  text: `${this.getValue(submission, 'plan_organico_vegetal_registros_3_e_check') ? '■' : '❏'} Destino producto de zonas buffer`,
                                  font: 'Arial',
                                  size: 18
                                })
                              ]
                            })
                          ],
                          borders: {
                            left: {
                              style: BorderStyle.NONE,
                              color: "white",
                              size: 0
                            },
                            top: {
                              style: BorderStyle.NONE,
                              color: "white",
                              size: 0
                            },
                            bottom: {
                              style: BorderStyle.NONE,
                              color: "white",
                              size: 0
                            }
                          }
                        }),
                        new TableCell({
                          children: [
                            new Paragraph({
                              alignment: AlignmentType.CENTER,
                              children: [
                                new TextRun({
                                  text: `${this.getValue(submission, 'plan_organico_vegetal_registros_3_e_declaracion') === "Si" ? '■ Sí ❏ No' : this.getValue(submission, 'plan_organico_vegetal_registros_3_e_declaracion') === "No" ? '❏ Sí ■ No' : '❏ Sí ❏ No'}`,
                                  font: 'Arial',
                                  size: 18
                                })
                              ]
                            })
                          ],
                          borders: {
                            right: {
                              style: BorderStyle.NONE,
                              color: "white",
                              size: 0
                            },
                            top: {
                              style: BorderStyle.NONE,
                              color: "white",
                              size: 0
                            },
                            bottom: {
                              style: BorderStyle.NONE,
                              color: "white",
                              size: 0
                            }
                          }
                        })
                      ]
                    }),
                    new TableRow({
                      children: [
                        new TableCell({
                          children: [
                            new Paragraph({
                              alignment: AlignmentType.LEFT,
                              children: [
                                new TextRun({
                                  text: `${this.getValue(submission, 'plan_organico_vegetal_registros_3_otro_check') ? '■' : '❏'} Otra: ${this.getValue(submission, 'plan_organico_vegetal_registros_3_otro')}`,
                                  font: 'Arial',
                                  size: 18
                                })
                              ]
                            })
                          ],
                          borders: {
                            left: {
                              style: BorderStyle.NONE,
                              color: "white",
                              size: 0
                            },
                            top: {
                              style: BorderStyle.NONE,
                              color: "white",
                              size: 0
                            },
                            bottom: {
                              style: BorderStyle.NONE,
                              color: "white",
                              size: 0
                            }
                          }
                        }),
                        new TableCell({
                          children: [
                            new Paragraph({
                              alignment: AlignmentType.CENTER,
                              children: [
                                new TextRun({
                                  text: `${this.getValue(submission, 'plan_organico_vegetal_registros_3_otro_declaracion') === "Si" ? '■ Sí ❏ No' : this.getValue(submission, 'plan_organico_vegetal_registros_3_otro_declaracion') === "No" ? '❏ Sí ■ No' : '❏ Sí ❏ No'}`,
                                  font: 'Arial',
                                  size: 18
                                })
                              ]
                            })
                          ],
                          borders: {
                            right: {
                              style: BorderStyle.NONE,
                              color: "white",
                              size: 0
                            },
                            top: {
                              style: BorderStyle.NONE,
                              color: "white",
                              size: 0
                            },
                            bottom: {
                              style: BorderStyle.NONE,
                              color: "white",
                              size: 0
                            }
                          }
                        })
                      ]
                    })
                  ],
                  //4395
                  columnWidths: [3075, 1320]
                })
              ],
              columnSpan: 2
            }),
            new TableCell({
              children: [
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                    new TextRun({
                      text: this.getValue(submission, 'plan_organico_vegetal_registros_3_comentarios'),
                      font: 'Arial',
                      size: 18
                    })
                  ]
                })
              ],
              verticalAlign: VerticalAlign.TOP
            }),
            new TableCell({
              children: [
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                      new TextRun({
                          text: ` ${this.getValue(submission, 'plan_organico_vegetal_registros_3_implementacion') === 'I' ? '■' : '❏'} I`,
                          font: 'Arial',
                          size: 18
                      })
                  ]
                }),
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                      new TextRun({
                          text: ` ${this.getValue(submission, 'plan_organico_vegetal_registros_3_implementacion') === 'NI' ? '■' : '❏'} NI`,
                          font: 'Arial',
                          size: 18
                      })
                  ]
                })
              ],
              verticalAlign: VerticalAlign.TOP
          }),
          new TableCell({
              children: [
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                      new TextRun({
                          text: ` ${this.getValue(submission, 'plan_organico_vegetal_registros_3_conformidad') === 'C' ? '■' : '❏'} C`,
                          font: 'Arial',
                          size: 18
                      })
                  ]
                }),
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                      new TextRun({
                          text: ` ${this.getValue(submission, 'plan_organico_vegetal_registros_3_conformidad') === 'NC' ? '■' : '❏'} NC`,
                          font: 'Arial',
                          size: 18
                      })
                  ]
                }),
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                      new TextRun({
                          text: ` ${this.getValue(submission, 'plan_organico_vegetal_registros_3_conformidad') === 'NA' ? '■' : '❏'} NA`,
                          font: 'Arial',
                          size: 18
                      })
                  ]
                })
              ],
              verticalAlign: VerticalAlign.TOP
          }),
          new TableCell({
              children: [
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                      new TextRun({
                          text: this.getValue(submission, 'plan_organico_vegetal_registros_3_comentarios_inspector'),
                          font: 'Arial',
                          size: 18
                      })
                  ]
                })
              ],
              verticalAlign: VerticalAlign.TOP
          })
          ]
        }),
        new TableRow({
          children: [
            new TableCell({
              children: [
                new Paragraph({
                  alignment: AlignmentType.CENTER,
                  children: [
                    new TextRun({
                      text: '4',
                      font: 'Arial',
                      size: 18
                    })
                  ]
                })
              ],
              verticalAlign: VerticalAlign.TOP
            }),
            new TableCell({
              children: [
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                    new TextRun({
                      text: 'He incluido una lista completa de riesgos para la integridad orgánica de los productos en el formato MTO-FSOL-04.',
                      font: 'Arial',
                      size: 18
                    })
                  ]
                })
              ],
              verticalAlign: VerticalAlign.TOP
            }),
            new TableCell({
              children: [
                new Paragraph({
                  alignment: AlignmentType.CENTER,
                  children: [
                    new TextRun({
                      text: this.getValue(submission, 'plan_organico_vegetal_registros_4_declaracion') === 'Si' ? '■ SÍ  ❏ NO' : '❏ SÍ  ■ NO',
                      font: 'Arial',
                      size: 18
                    })
                  ]
                })
              ],
              verticalAlign: VerticalAlign.TOP
            }),
            new TableCell({
              children: [
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                    new TextRun({
                      text: this.getValue(submission, 'plan_organico_vegetal_registros_4_comentarios'),
                      font: 'Arial',
                      size: 18
                    })
                  ]
                })
              ],
              verticalAlign: VerticalAlign.TOP
            }),
            new TableCell({
              children: [
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                      new TextRun({
                          text: ` ${this.getValue(submission, 'plan_organico_vegetal_registros_4_implementacion') === 'I' ? '■' : '❏'} I`,
                          font: 'Arial',
                          size: 18
                      })
                  ]
                }),
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                      new TextRun({
                          text: ` ${this.getValue(submission, 'plan_organico_vegetal_registros_4_implementacion') === 'NI' ? '■' : '❏'} NI`,
                          font: 'Arial',
                          size: 18
                      })
                  ]
                })
              ],
              verticalAlign: VerticalAlign.TOP
          }),
          new TableCell({
              children: [
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                      new TextRun({
                          text: ` ${this.getValue(submission, 'plan_organico_vegetal_registros_4_conformidad') === 'C' ? '■' : '❏'} C`,
                          font: 'Arial',
                          size: 18
                      })
                  ]
                }),
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                      new TextRun({
                          text: ` ${this.getValue(submission, 'plan_organico_vegetal_registros_4_conformidad') === 'NC' ? '■' : '❏'} NC`,
                          font: 'Arial',
                          size: 18
                      })
                  ]
                }),
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                      new TextRun({
                          text: ` ${this.getValue(submission, 'plan_organico_vegetal_registros_4_conformidad') === 'NA' ? '■' : '❏'} NA`,
                          font: 'Arial',
                          size: 18
                      })
                  ]
                })
              ],
              verticalAlign: VerticalAlign.TOP
          }),
          new TableCell({
              children: [
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                      new TextRun({
                          text: this.getValue(submission, 'plan_organico_vegetal_registros_4_comentarios_inspector'),
                          font: 'Arial',
                          size: 18
                      })
                  ]
                })
              ],
              verticalAlign: VerticalAlign.TOP
          })
          ]
        }),
        new TableRow({
          children: [
            new TableCell({
              children: [
                new Paragraph({
                  alignment: AlignmentType.CENTER,
                  children: [
                    new TextRun({
                      text: '5',
                      font: 'Arial',
                      size: 18
                    })
                  ]
                })
              ],
              verticalAlign: VerticalAlign.TOP
            }),
            new TableCell({
              children: [
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                    new TextRun({
                      text: 'He incluido un análisis completo de riesgos de contaminación por colindantes en el formato MTO-FSOL-06',
                      font: 'Arial',
                      size: 18
                    })
                  ]
                })
              ],
              verticalAlign: VerticalAlign.TOP
            }),
            new TableCell({
              children: [
                new Paragraph({
                  alignment: AlignmentType.CENTER,
                  children: [
                    new TextRun({
                      text: this.getValue(submission, 'plan_organico_vegetal_registros_5_declaracion') === 'Si' ? '■ SÍ  ❏ NO' : '❏ SÍ  ■ NO',
                      font: 'Arial',
                      size: 18
                    })
                  ]
                })
              ],
              verticalAlign: VerticalAlign.TOP
            }),
            new TableCell({
              children: [
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                    new TextRun({
                      text: this.getValue(submission, 'plan_organico_vegetal_registros_5_comentarios'),
                      font: 'Arial',
                      size: 18
                    })
                  ]
                })
              ],
              verticalAlign: VerticalAlign.TOP
            }),
            new TableCell({
              children: [
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                      new TextRun({
                          text: ` ${this.getValue(submission, 'plan_organico_vegetal_registros_5_implementacion') === 'I' ? '■' : '❏'} I`,
                          font: 'Arial',
                          size: 18
                      })
                  ]
                }),
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                      new TextRun({
                          text: ` ${this.getValue(submission, 'plan_organico_vegetal_registros_5_implementacion') === 'NI' ? '■' : '❏'} NI`,
                          font: 'Arial',
                          size: 18
                      })
                  ]
                })
              ],
              verticalAlign: VerticalAlign.TOP
          }),
          new TableCell({
              children: [
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                      new TextRun({
                          text: ` ${this.getValue(submission, 'plan_organico_vegetal_registros_5_conformidad') === 'C' ? '■' : '❏'} C`,
                          font: 'Arial',
                          size: 18
                      })
                  ]
                }),
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                      new TextRun({
                          text: ` ${this.getValue(submission, 'plan_organico_vegetal_registros_5_conformidad') === 'NC' ? '■' : '❏'} NC`,
                          font: 'Arial',
                          size: 18
                      })
                  ]
                }),
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                      new TextRun({
                          text: ` ${this.getValue(submission, 'plan_organico_vegetal_registros_5_conformidad') === 'NA' ? '■' : '❏'} NA`,
                          font: 'Arial',
                          size: 18
                      })
                  ]
                })
              ],
              verticalAlign: VerticalAlign.TOP
          }),
          new TableCell({
              children: [
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                      new TextRun({
                          text: this.getValue(submission, 'plan_organico_vegetal_registros_5_comentarios_inspector'),
                          font: 'Arial',
                          size: 18
                      })
                  ]
                })
              ],
              verticalAlign: VerticalAlign.TOP
          })
          ]
        }),
        new TableRow({
          children: [
            new TableCell({
              children: [
                new Paragraph({
                  alignment: AlignmentType.CENTER,
                  children: [
                    new TextRun({
                      text: '6',
                      font: 'Arial',
                      size: 18
                    })
                  ]
                })
              ],
              verticalAlign: VerticalAlign.TOP
            }),
            new TableCell({
              children: [
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                    new TextRun({
                      text: 'He incluido una lista completa de los insumos que utilizo para el abonado, control de plagas, enfermedades y malezas en el formato MTO-FSOL-07',
                      font: 'Arial',
                      size: 18
                    })
                  ]
                })
              ],
              verticalAlign: VerticalAlign.TOP
            }),
            new TableCell({
              children: [
                new Paragraph({
                  alignment: AlignmentType.CENTER,
                  children: [
                    new TextRun({
                      text: this.getValue(submission, 'plan_organico_vegetal_registros_6_declaracion') === 'Si' ? '■ SÍ  ❏ NO' : '❏ SÍ  ■ NO',
                      font: 'Arial',
                      size: 18
                    })
                  ]
                })
              ],
              verticalAlign: VerticalAlign.TOP
            }),
            new TableCell({
              children: [
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                    new TextRun({
                      text: this.getValue(submission, 'plan_organico_vegetal_registros_6_comentarios'),
                      font: 'Arial',
                      size: 18
                    })
                  ]
                })
              ],
              verticalAlign: VerticalAlign.TOP
            }),
            new TableCell({
              children: [
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                      new TextRun({
                          text: ` ${this.getValue(submission, 'plan_organico_vegetal_registros_6_implementacion') === 'I' ? '■' : '❏'} I`,
                          font: 'Arial',
                          size: 18
                      })
                  ]
                }),
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                      new TextRun({
                          text: ` ${this.getValue(submission, 'plan_organico_vegetal_registros_6_implementacion') === 'NI' ? '■' : '❏'} NI`,
                          font: 'Arial',
                          size: 18
                      })
                  ]
                })
              ],
              verticalAlign: VerticalAlign.TOP
          }),
          new TableCell({
              children: [
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                      new TextRun({
                          text: ` ${this.getValue(submission, 'plan_organico_vegetal_registros_6_conformidad') === 'C' ? '■' : '❏'} C`,
                          font: 'Arial',
                          size: 18
                      })
                  ]
                }),
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                      new TextRun({
                          text: ` ${this.getValue(submission, 'plan_organico_vegetal_registros_6_conformidad') === 'NC' ? '■' : '❏'} NC`,
                          font: 'Arial',
                          size: 18
                      })
                  ]
                }),
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                      new TextRun({
                          text: ` ${this.getValue(submission, 'plan_organico_vegetal_registros_6_conformidad') === 'NA' ? '■' : '❏'} NA`,
                          font: 'Arial',
                          size: 18
                      })
                  ]
                })
              ],
              verticalAlign: VerticalAlign.TOP
          }),
          new TableCell({
              children: [
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                      new TextRun({
                          text: this.getValue(submission, 'plan_organico_vegetal_registros_6_comentarios_inspector'),
                          font: 'Arial',
                          size: 18
                      })
                  ]
                })
              ],
              verticalAlign: VerticalAlign.TOP
          })
          ]
        }),
        new TableRow({
          children: [
            new TableCell({
              children: [
                new Paragraph({
                  alignment: AlignmentType.CENTER,
                  children: [
                    new TextRun({
                      text: '7',
                      font: 'Arial',
                      size: 18
                    })
                  ]
                })
              ],
              verticalAlign: VerticalAlign.TOP
            }),
            new TableCell({
              children: [
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                    new TextRun({
                      text: 'Registro de la fuente, cantidad, tipo de estiércol y evaluación del cumplimiento del criterio 5.5.1 CAN/CGSB-32.310',
                      font: 'Arial',
                      size: 18
                    })
                  ]
                })
              ],
              verticalAlign: VerticalAlign.TOP
            }),
            new TableCell({
              children: [
                new Paragraph({
                  alignment: AlignmentType.CENTER,
                  children: [
                    new TextRun({
                      text: this.getValue(submission, 'plan_organico_vegetal_registros_7_declaracion') === 'Si' ? '■ SÍ  ❏ NO' : '❏ SÍ  ■ NO',
                      font: 'Arial',
                      size: 18
                    })
                  ]
                })
              ],
              verticalAlign: VerticalAlign.TOP
            }),
            new TableCell({
              children: [
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                    new TextRun({
                      text: this.getValue(submission, 'plan_organico_vegetal_registros_7_comentarios'),
                      font: 'Arial',
                      size: 18
                    })
                  ]
                })
              ],
              verticalAlign: VerticalAlign.TOP
            }),
            new TableCell({
              children: [
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                      new TextRun({
                          text: ` ${this.getValue(submission, 'plan_organico_vegetal_registros_7_implementacion') === 'I' ? '■' : '❏'} I`,
                          font: 'Arial',
                          size: 18
                      })
                  ]
                }),
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                      new TextRun({
                          text: ` ${this.getValue(submission, 'plan_organico_vegetal_registros_7_implementacion') === 'NI' ? '■' : '❏'} NI`,
                          font: 'Arial',
                          size: 18
                      })
                  ]
                })
              ],
              verticalAlign: VerticalAlign.TOP
          }),
          new TableCell({
              children: [
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                      new TextRun({
                          text: ` ${this.getValue(submission, 'plan_organico_vegetal_registros_7_conformidad') === 'C' ? '■' : '❏'} C`,
                          font: 'Arial',
                          size: 18
                      })
                  ]
                }),
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                      new TextRun({
                          text: ` ${this.getValue(submission, 'plan_organico_vegetal_registros_7_conformidad') === 'NC' ? '■' : '❏'} NC`,
                          font: 'Arial',
                          size: 18
                      })
                  ]
                }),
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                      new TextRun({
                          text: ` ${this.getValue(submission, 'plan_organico_vegetal_registros_7_conformidad') === 'NA' ? '■' : '❏'} NA`,
                          font: 'Arial',
                          size: 18
                      })
                  ]
                })
              ],
              verticalAlign: VerticalAlign.TOP
          }),
          new TableCell({
              children: [
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                      new TextRun({
                          text: this.getValue(submission, 'plan_organico_vegetal_registros_7_comentarios_inspector'),
                          font: 'Arial',
                          size: 18
                      })
                  ]
                })
              ],
              verticalAlign: VerticalAlign.TOP
          })
          ]
        })
      ],
      margins: {
        left: 70,
        right: 70
      },
      //9885
      columnWidths: [450, 3050, 1345, 1545, 1150, 800, 1545]
    })
    return table
  }

  public createHandling_MTO_FSOL_08_1_2(submission): Table {
    const table = new Table({
      rows: [
        new TableRow({
          children: [
            new TableCell({
              children: [
                new Paragraph({
                  alignment: AlignmentType.CENTER,
                  children: [
                    new TextRun({
                      text: 'MANEJO',
                      font: 'Arial',
                      size: 20,
                      bold: true
                    })
                  ],
                  spacing: {
                    after: 75,
                    before: 75
                  }
                }),
              ],
              verticalAlign: VerticalAlign.CENTER,
              columnSpan: 6,
              shading: {
                fill: '#C0C0C0'
              }
            })
          ]
        }),
        new TableRow({
          children: [
            new TableCell({
              children: [
                new Paragraph({
                  alignment: AlignmentType.CENTER,
                  children: [
                    new TextRun({
                      text: 'No.',
                      font: 'Arial',
                      size: 18,
                      bold: true
                    })
                  ]
                })
              ],
              rowSpan: 2,
              columnSpan: 1,
              verticalAlign: VerticalAlign.CENTER,
              width: {
                size: 25,
                type: WidthType.PERCENTAGE
              }
            }),
            new TableCell({
              children: [
                new Paragraph({
                  alignment: AlignmentType.CENTER,
                  children: [
                    new TextRun({
                      text: 'Requisitos',
                      font: 'Arial',
                      size: 18,
                      bold: true
                    })
                  ]
                })
              ],
              rowSpan: 2,
              columnSpan: 1,
              verticalAlign: VerticalAlign.CENTER,
              width: {
                size: 15,
                type: WidthType.PERCENTAGE
              }
            }),
            new TableCell({
              children: [
                new Paragraph({
                  alignment: AlignmentType.CENTER,
                  children: [
                    new TextRun({
                      text: 'Comentarios',
                      font: 'Arial',
                      size: 18,
                      bold: true
                    })
                  ]
                })
              ],
              rowSpan: 2,
              columnSpan: 1,
              verticalAlign: VerticalAlign.CENTER,
              width: {
                size: 15,
                type: WidthType.PERCENTAGE
              }
            }),
            new TableCell({
              children: [
                  new Paragraph({
                      alignment: AlignmentType.CENTER,
                      children: [
                          new TextRun({
                              text: 'Verificación en sitio (Evaluada por Inspector)',
                              font: 'Arial',
                              size: 18,
                              bold: true
                          })
                      ]
                  })
              ],
              columnSpan: 3,
              verticalAlign: VerticalAlign.CENTER,
              width: {
                  size: 15,
                  type: WidthType.PERCENTAGE
              }
          })
          ]
        }),
        new TableRow({
          children: [new TableCell({
              children: [
                  new Paragraph({
                      alignment: AlignmentType.CENTER,
                      children: [
                          new TextRun({
                              text: 'Implementación',
                              font: 'Arial',
                              size: 18,
                              bold: true
                          })
                      ]
                  })
              ],
              verticalAlign: VerticalAlign.CENTER
          }),
          new TableCell({
              children: [
                  new Paragraph({
                      alignment: AlignmentType.CENTER,
                      children: [
                          new TextRun({
                              text: 'Confirmación',
                              font: 'Arial',
                              size: 18,
                              bold: true
                          })
                      ]
                  })
              ],
              verticalAlign: VerticalAlign.CENTER,
              columnSpan: 2
          }),]
        }),
        new TableRow({
          children: [
            new TableCell({
              children: [
                new Paragraph({
                  alignment: AlignmentType.CENTER,
                  children: [
                    new TextRun({
                      text: '1',
                      font: 'Arial',
                      size: 18
                    })
                  ]
                })
              ],
              verticalAlign: VerticalAlign.TOP
            }),
            new TableCell({
              children: [
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                    new TextRun({
                      text: '¿Cuántos meses se ha mantenido el terreno sin aplicación de sustancias prohibidas?',
                      font: 'Arial',
                      size: 18
                    })
                  ]
                }),
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                    new TextRun({
                      text: (this.getValue(submission, 'plan_organico_vegetal_manejo_1_36_mas_meses') ? '■' : '❏') + '  36 meses o más',
                      font: 'Arial',
                      size: 18
                    })
                  ]
                }),
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                    new TextRun({
                      text: (this.getValue(submission, "plan_organico_vegetal_manejo_1_36_menos_meses") ? '■' : '❏') + '  Menos de 36 meses (¿Cuántos?): ' + this.getValue(submission, "plan_organico_vegetal_manejo_1_36_menos_meses_texto"),
                      font: 'Arial',
                      size: 18
                    })
                  ]
                })
              ],
              verticalAlign: VerticalAlign.TOP
            }),
            new TableCell({
              children: [
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                    new TextRun({
                      text: this.getValue(submission, "plan_organico_vegetal_manejo_1_comentarios"),
                      font: 'Arial',
                      size: 18
                    })
                  ]
                })
              ],
              verticalAlign: VerticalAlign.TOP
            }),
            new TableCell({
              children: [
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                      new TextRun({
                          text: ` ${this.getValue(submission, 'plan_organico_vegetal_manejo_1_implementacion') === 'I' ? '■' : '❏'} I`,
                          font: 'Arial',
                          size: 18
                      })
                  ]
                }),
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                      new TextRun({
                          text: ` ${this.getValue(submission, 'plan_organico_vegetal_manejo_1_implementacion') === 'NI' ? '■' : '❏'} NI`,
                          font: 'Arial',
                          size: 18
                      })
                  ]
                })
              ],
              verticalAlign: VerticalAlign.TOP
          }),
          new TableCell({
              children: [
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                      new TextRun({
                          text: ` ${this.getValue(submission, 'plan_organico_vegetal_manejo_1_conformidad') === 'C' ? '■' : '❏'} C`,
                          font: 'Arial',
                          size: 18
                      })
                  ]
                }),
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                      new TextRun({
                          text: ` ${this.getValue(submission, 'plan_organico_vegetal_manejo_1_conformidad') === 'NC' ? '■' : '❏'} NC`,
                          font: 'Arial',
                          size: 18
                      })
                  ]
                }),
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                      new TextRun({
                          text: ` ${this.getValue(submission, 'plan_organico_vegetal_manejo_1_conformidad') === 'NA' ? '■' : '❏'} NA`,
                          font: 'Arial',
                          size: 18
                      })
                  ]
                })
              ],
              verticalAlign: VerticalAlign.TOP
          }),
          new TableCell({
              children: [
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                      new TextRun({
                          text: this.getValue(submission, 'plan_organico_vegetal_manejo_1_comentarios_inspector'),
                          font: 'Arial',
                          size: 18
                      })
                  ]
                })
              ],
              verticalAlign: VerticalAlign.TOP
          })
          ]
        }),
        new TableRow({
          children: [
            new TableCell({
              children: [
                new Paragraph({
                  alignment: AlignmentType.CENTER,
                  children: [
                    new TextRun({
                      text: '2',
                      font: 'Arial',
                      size: 18
                    })
                  ]
                })
              ],
              verticalAlign: VerticalAlign.TOP
            }),
            new TableCell({
              children: [
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                    new TextRun({
                      text: 'El material de propagación (semilla, plántula )es:',
                      font: 'Arial',
                      size: 18
                    })
                  ]
                }),
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                    new TextRun({
                      text: (this.getValue(submission, 'plan_organico_vegetal_manejo_2_organico_propio') ? '■' : '❏') + ' Orgánico propio',
                      font: 'Arial',
                      size: 18
                    })
                  ]
                }),
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                    new TextRun({
                      text: (this.getValue(submission, "plan_organico_vegetal_manejo_2_organico_certificado") ? '■' : '❏') + ' Orgánico externo y certificado',
                      font: 'Arial',
                      size: 18
                    })
                  ]
                }),
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                    new TextRun({
                      text: (this.getValue(submission, "plan_organico_vegetal_manejo_2_convencional") ? '■' : '❏') + ' Convencional no tratado',
                      font: 'Arial',
                      size: 18
                    })
                  ]
                }),
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                    new TextRun({
                      text: (this.getValue(submission, "plan_organico_vegetal_manejo_2_convencional_tratado") ? '■' : '❏') + '  Convencional y tratado',
                      font: 'Arial',
                      size: 18
                    })
                  ]
                }),
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                    new TextRun({
                      text: (this.getValue(submission, "plan_organico_vegetal_manejo_2_otro") ? '■' : '❏') + '  Otro (¿Cuál?): '
                        + this.getValue(submission, "plan_organico_vegetal_manejo_2_otro_text"),
                      font: 'Arial',
                      size: 18
                    })
                  ]
                })
              ],
              verticalAlign: VerticalAlign.TOP
            }),
            new TableCell({
              children: [
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                    new TextRun({
                      text: this.getValue(submission, "plan_organico_vegetal_manejo_2_comentarios"),
                      font: 'Arial',
                      size: 18
                    })
                  ]
                })
              ],
              verticalAlign: VerticalAlign.TOP
            }),
            new TableCell({
              children: [
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                      new TextRun({
                          text: ` ${this.getValue(submission, 'plan_organico_vegetal_manejo_2_implementacion') === 'I' ? '■' : '❏'} I`,
                          font: 'Arial',
                          size: 18
                      })
                  ]
                }),
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                      new TextRun({
                          text: ` ${this.getValue(submission, 'plan_organico_vegetal_manejo_2_implementacion') === 'NI' ? '■' : '❏'} NI`,
                          font: 'Arial',
                          size: 18
                      })
                  ]
                })
              ],
              verticalAlign: VerticalAlign.TOP
          }),
          new TableCell({
              children: [
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                      new TextRun({
                          text: ` ${this.getValue(submission, 'plan_organico_vegetal_manejo_2_conformidad') === 'C' ? '■' : '❏'} C`,
                          font: 'Arial',
                          size: 18
                      })
                  ]
                }),
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                      new TextRun({
                          text: ` ${this.getValue(submission, 'plan_organico_vegetal_manejo_2_conformidad') === 'NC' ? '■' : '❏'} NC`,
                          font: 'Arial',
                          size: 18
                      })
                  ]
                }),
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                      new TextRun({
                          text: ` ${this.getValue(submission, 'plan_organico_vegetal_manejo_2_conformidad') === 'NA' ? '■' : '❏'} NA`,
                          font: 'Arial',
                          size: 18
                      })
                  ]
                })
              ],
              verticalAlign: VerticalAlign.TOP
          }),
          new TableCell({
              children: [
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                      new TextRun({
                          text: this.getValue(submission, 'plan_organico_vegetal_manejo_2_comentarios_inspector'),
                          font: 'Arial',
                          size: 18
                      })
                  ]
                })
              ],
              verticalAlign: VerticalAlign.TOP
          })
          ]
        }),
        new TableRow({
          children: [
            new TableCell({
              children: [
                new Paragraph({
                  alignment: AlignmentType.CENTER,
                  children: [
                    new TextRun({
                      text: '3',
                      font: 'Arial',
                      size: 18
                    })
                  ]
                })
              ],
              verticalAlign: VerticalAlign.TOP
            }),
            new TableCell({
              children: [
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                    new TextRun({
                      text: 'Realizo las siguientes prácticas de preparación del suelo:',
                      font: 'Arial',
                      size: 18
                    })
                  ]
                }),
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                    new TextRun({
                      text: (this.getValue(submission, "plan_organico_vegetal_manejo_3_barbecho") ? '■' : '❏') + '  Barbecho',
                      font: 'Arial',
                      size: 18
                    })
                  ]
                }),
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                    new TextRun({
                      text: (this.getValue(submission, "plan_organico_vegetal_manejo_3_rastreo") ? '■' : '❏') + '  Rastreo',
                      font: 'Arial',
                      size: 18
                    })
                  ]
                }),
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                    new TextRun({
                      text: (this.getValue(submission, "plan_organico_vegetal_manejo_3_surcado") ? '■' : '❏') + '  Surcado',
                      font: 'Arial',
                      size: 18
                    })
                  ]
                }),
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                    new TextRun({
                      text: (this.getValue(submission, "plan_organico_vegetal_manejo_3_nivelacion") ? '■' : '❏') + '  Nivelación',
                      font: 'Arial',
                      size: 18
                    })
                  ]
                }),
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                    new TextRun({
                      text: (this.getValue(submission, "plan_organico_vegetal_manejo_3_roza") ? '■' : '❏') + '  Roza',
                      font: 'Arial',
                      size: 18
                    })
                  ]
                }),
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                    new TextRun({
                      text: (this.getValue(submission, "plan_organico_vegetal_manejo_3_otro") ? '■' : '❏') + ' Otro (¿Cuál?): ' + this.getValue(submission, "plan_organico_vegetal_manejo_3_otro_text"),
                      font: 'Arial',
                      size: 18
                    })
                  ]
                }),
              ],
              verticalAlign: VerticalAlign.TOP
            }),
            new TableCell({
              children: [
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                    new TextRun({
                      text: this.getValue(submission, "plan_organico_vegetal_manejo_3_comentarios"),
                      font: 'Arial',
                      size: 18
                    })
                  ]
                })
              ],
              verticalAlign: VerticalAlign.TOP
            }),
            new TableCell({
              children: [
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                      new TextRun({
                          text: ` ${this.getValue(submission, 'plan_organico_vegetal_manejo_3_implementacion') === 'I' ? '■' : '❏'} I`,
                          font: 'Arial',
                          size: 18
                      })
                  ]
                }),
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                      new TextRun({
                          text: ` ${this.getValue(submission, 'plan_organico_vegetal_manejo_3_implementacion') === 'NI' ? '■' : '❏'} NI`,
                          font: 'Arial',
                          size: 18
                      })
                  ]
                })
              ],
              verticalAlign: VerticalAlign.TOP
          }),
          new TableCell({
              children: [
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                      new TextRun({
                          text: ` ${this.getValue(submission, 'plan_organico_vegetal_manejo_3_conformidad') === 'C' ? '■' : '❏'} C`,
                          font: 'Arial',
                          size: 18
                      })
                  ]
                }),
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                      new TextRun({
                          text: ` ${this.getValue(submission, 'plan_organico_vegetal_manejo_3_conformidad') === 'NC' ? '■' : '❏'} NC`,
                          font: 'Arial',
                          size: 18
                      })
                  ]
                }),
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                      new TextRun({
                          text: ` ${this.getValue(submission, 'plan_organico_vegetal_manejo_3_conformidad') === 'NA' ? '■' : '❏'} NA`,
                          font: 'Arial',
                          size: 18
                      })
                  ]
                })
              ],
              verticalAlign: VerticalAlign.TOP
          }),
          new TableCell({
              children: [
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                      new TextRun({
                          text: this.getValue(submission, 'plan_organico_vegetal_manejo_3_comentarios_inspector'),
                          font: 'Arial',
                          size: 18
                      })
                  ]
                })
              ],
              verticalAlign: VerticalAlign.TOP
          })
          ]
        }),
        new TableRow({
          children: [
            new TableCell({
              children: [
                new Paragraph({
                  alignment: AlignmentType.CENTER,
                  children: [
                    new TextRun({
                      text: '4',
                      font: 'Arial',
                      size: 18
                    })
                  ]
                })
              ],
              verticalAlign: VerticalAlign.TOP
            }),
            new TableCell({
              children: [
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                    new TextRun({
                      text: 'Realizo las siguientes actividades para la conservación y mejoramiento de la fertilidad del suelo',
                      font: 'Arial',
                      size: 18
                    })
                  ]
                }),
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                    new TextRun({
                      text: (this.getValue(submission, "plan_organico_vegetal_manejo_4_rotacion_cultivos") ? '■' : '❏') + ' Rotación de cultivos',
                      font: 'Arial',
                      size: 18
                    })
                  ]
                }),
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                    new TextRun({
                      text: (this.getValue(submission, "plan_organico_vegetal_manejo_4_materia_organica") ? '■' : '❏') + ' Aportación de materia orgánica',
                      font: 'Arial',
                      size: 18
                    })
                  ]
                }),
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                    new TextRun({
                      text: (this.getValue(submission, "plan_organico_vegetal_manejo_4_abonos_verdes") ? '■' : '❏') + ' Abonos verdes',
                      font: 'Arial',
                      size: 18
                    })
                  ]
                }),
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                    new TextRun({
                      text: (this.getValue(submission, "plan_organico_vegetal_manejo_4_apliaccion_cal_yeso") ? '■' : '❏') + ' Aplicación de cal o yeso',
                      font: 'Arial',
                      size: 18
                    })
                  ]
                }),
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                    new TextRun({
                      text: (this.getValue(submission, "plan_organico_vegetal_manejo_4_aplicacion_microorganismos") ? '■' : '❏') + ' Aplicación de microorganismos',
                      font: 'Arial',
                      size: 18
                    })
                  ]
                }),
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                    new TextRun({
                      text: (this.getValue(submission, "plan_organico_vegetal_manejo_4_aplicacion_mejoradores_suelo") ? '■' : '❏') + ' Aplicación de mejoradores del suelo',
                      font: 'Arial',
                      size: 18
                    })
                  ]
                }),
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                    new TextRun({
                      text: (this.getValue(submission, "plan_organico_vegetal_manejo_4_aplicacion_estiercol") ? '■' : '❏') + ' Aplicación de estiércol',
                      font: 'Arial',
                      size: 18
                    })
                  ]
                }),
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                    new TextRun({
                      text: (this.getValue(submission, "plan_organico_vegetal_manejo_4_otro") ? '■' : '❏') + '  Otro (¿Cuál?): ' + this.getValue(submission, "plan_organico_vegetal_manejo_4_otro_text"),
                      font: 'Arial',
                      size: 18
                    })
                  ]
                }),
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                    new TextRun({
                      text: '* Para productores certificados bajo la norma Europea, es obligatorio el uso de leguminosas en el plan de rotación.',
                      font: 'Arial',
                      size: 18
                    })
                  ]
                })
              ],
              verticalAlign: VerticalAlign.TOP
            }),
            new TableCell({
              children: [
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                    new TextRun({
                      text: this.getValue(submission, "plan_organico_vegetal_manejo_4_comentarios"),
                      font: 'Arial',
                      size: 18
                    })
                  ]
                })
              ],
              verticalAlign: VerticalAlign.TOP
            }),
            new TableCell({
              children: [
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                      new TextRun({
                          text: ` ${this.getValue(submission, 'plan_organico_vegetal_manejo_4_implementacion') === 'I' ? '■' : '❏'} I`,
                          font: 'Arial',
                          size: 18
                      })
                  ]
                }),
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                      new TextRun({
                          text: ` ${this.getValue(submission, 'plan_organico_vegetal_manejo_4_implementacion') === 'NI' ? '■' : '❏'} NI`,
                          font: 'Arial',
                          size: 18
                      })
                  ]
                })
              ],
              verticalAlign: VerticalAlign.TOP
          }),
          new TableCell({
              children: [
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                      new TextRun({
                          text: ` ${this.getValue(submission, 'plan_organico_vegetal_manejo_4_conformidad') === 'C' ? '■' : '❏'} C`,
                          font: 'Arial',
                          size: 18
                      })
                  ]
                }),
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                      new TextRun({
                          text: ` ${this.getValue(submission, 'plan_organico_vegetal_manejo_4_conformidad') === 'NC' ? '■' : '❏'} NC`,
                          font: 'Arial',
                          size: 18
                      })
                  ]
                }),
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                      new TextRun({
                          text: ` ${this.getValue(submission, 'plan_organico_vegetal_manejo_4_conformidad') === 'NA' ? '■' : '❏'} NA`,
                          font: 'Arial',
                          size: 18
                      })
                  ]
                })
              ],
              verticalAlign: VerticalAlign.TOP
          }),
          new TableCell({
              children: [
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                      new TextRun({
                          text: this.getValue(submission, 'plan_organico_vegetal_manejo_4_comentarios_inspector'),
                          font: 'Arial',
                          size: 18
                      })
                  ]
                })
              ],
              verticalAlign: VerticalAlign.TOP
          })
          ]
        }),
        new TableRow({
          children: [
            new TableCell({
              children: [
                new Paragraph({
                  alignment: AlignmentType.CENTER,
                  children: [
                    new TextRun({
                      text: '5',
                      font: 'Arial',
                      size: 18
                    })
                  ]
                })
              ],
              verticalAlign: VerticalAlign.TOP
            }),
            new TableCell({
              children: [
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                    new TextRun({
                      text: 'En caso de aplicación de estiércol:',
                      font: 'Arial',
                      size: 18
                    })
                  ]
                }),
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                    new TextRun({
                      text: (this.getValue(submission, "plan_organico_vegetal_manejo_5_misma_unidad") ? '■' : '❏') + ' Proviene de la misma unidad de producción',
                      font: 'Arial',
                      size: 18
                    })
                  ]
                }),
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                    new TextRun({
                      text: (this.getValue(submission, "plan_organico_vegetal_manejo_5_organico") ? '■' : '❏') + ' Es orgánico',
                      font: 'Arial',
                      size: 18
                    })
                  ]
                }),
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                    new TextRun({
                      text: (this.getValue(submission, "plan_organico_vegetal_manejo_5_no_organico") ? '■' : '❏') + ' No es orgánico pero proviene de ganadería extensiva (animales no enjaulados, no encerrados)',
                      font: 'Arial',
                      size: 18
                    })
                  ]
                }),
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                    new TextRun({
                      text: (this.getValue(submission, "plan_organico_vegetal_manejo_5_otro") ? '■' : '❏') + ' Otro: ' + this.getValue(submission, "plan_organico_vegetal_manejo_5_otro_text"),
                      font: 'Arial',
                      size: 18
                    })
                  ]
                })
              ],
              verticalAlign: VerticalAlign.TOP
            }),
            new TableCell({
              children: [
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                    new TextRun({
                      text: this.getValue(submission, "plan_organico_vegetal_manejo_5_comentarios"),
                      font: 'Arial',
                      size: 18
                    })
                  ]
                })
              ],
              verticalAlign: VerticalAlign.TOP
            }),
            new TableCell({
              children: [
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                      new TextRun({
                          text: ` ${this.getValue(submission, 'plan_organico_vegetal_manejo_5_implementacion') === 'I' ? '■' : '❏'} I`,
                          font: 'Arial',
                          size: 18
                      })
                  ]
                }),
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                      new TextRun({
                          text: ` ${this.getValue(submission, 'plan_organico_vegetal_manejo_5_implementacion') === 'NI' ? '■' : '❏'} NI`,
                          font: 'Arial',
                          size: 18
                      })
                  ]
                })
              ],
              verticalAlign: VerticalAlign.TOP
          }),
          new TableCell({
              children: [
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                      new TextRun({
                          text: ` ${this.getValue(submission, 'plan_organico_vegetal_manejo_5_conformidad') === 'C' ? '■' : '❏'} C`,
                          font: 'Arial',
                          size: 18
                      })
                  ]
                }),
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                      new TextRun({
                          text: ` ${this.getValue(submission, 'plan_organico_vegetal_manejo_5_conformidad') === 'NC' ? '■' : '❏'} NC`,
                          font: 'Arial',
                          size: 18
                      })
                  ]
                }),
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                      new TextRun({
                          text: ` ${this.getValue(submission, 'plan_organico_vegetal_manejo_5_conformidad') === 'NA' ? '■' : '❏'} NA`,
                          font: 'Arial',
                          size: 18
                      })
                  ]
                })
              ],
              verticalAlign: VerticalAlign.TOP
          }),
          new TableCell({
              children: [
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                      new TextRun({
                          text: this.getValue(submission, 'plan_organico_vegetal_manejo_5_comentarios_inspector'),
                          font: 'Arial',
                          size: 18
                      })
                  ]
                })
              ],
              verticalAlign: VerticalAlign.TOP
          })
          ]
        }),
        new TableRow({
          children: [
            new TableCell({
              children: [
                new Paragraph({
                  alignment: AlignmentType.CENTER,
                  children: [
                    new TextRun({
                      text: '6',
                      font: 'Arial',
                      size: 18
                    })
                  ]
                })
              ],
              verticalAlign: VerticalAlign.TOP
            }),
            new TableCell({
              children: [
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                    new TextRun({
                      text: 'En caso de aplicación de estiércol:',
                      font: 'Arial',
                      size: 18
                    })
                  ]
                }),
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                    new TextRun({
                      text: (this.getValue(submission, "plan_organico_vegetal_manejo_6_adecuadas_condiciones") ? '■' : '❏') + ' Se aplica en adecuadas condiciones de temperatura y humedad',
                      font: 'Arial',
                      size: 18
                    })
                  ]
                }),
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                    new TextRun({
                      text: (this.getValue(submission, "plan_organico_vegetal_manejo_6_no_contamina") ? '■' : '❏') + ' No contribuye a la contaminación de suelo y agua',
                      font: 'Arial',
                      size: 18
                    })
                  ]
                }),
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                    new TextRun({
                      text: (this.getValue(submission, "plan_organico_vegetal_manejo_6_90_120_dias_antes") ? '■' : '❏') + ' Se aplica 90 o 120 días antes de cosecha, según tipo de cultivo',
                      font: 'Arial',
                      size: 18
                    })
                  ]
                }),
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                    new TextRun({
                      text: (this.getValue(submission, "plan_organico_vegetal_manejo_6_ganado_parcela") ? '■' : '❏') + ' Se incluye manejo de ganado en la parcela, y se evita la contaminación del cultivo',
                      font: 'Arial',
                      size: 18
                    })
                  ]
                }),
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                    new TextRun({
                      text: (this.getValue(submission, "plan_organico_vegetal_manejo_6_otro") ? '■' : '❏') + ' Otro (¿Cuál?):  ' + this.getValue(submission, "plan_organico_vegetal_manejo_6_otro_text"),
                      font: 'Arial',
                      size: 18
                    })
                  ]
                })
              ],
              verticalAlign: VerticalAlign.TOP
            }),
            new TableCell({
              children: [
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                    new TextRun({
                      text: this.getValue(submission, "plan_organico_vegetal_manejo_6_comentarios"),
                      font: 'Arial',
                      size: 18
                    })
                  ]
                })
              ],
              verticalAlign: VerticalAlign.TOP
            }),
            new TableCell({
              children: [
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                      new TextRun({
                          text: ` ${this.getValue(submission, 'plan_organico_vegetal_manejo_6_implementacion') === 'I' ? '■' : '❏'} I`,
                          font: 'Arial',
                          size: 18
                      })
                  ]
                }),
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                      new TextRun({
                          text: ` ${this.getValue(submission, 'plan_organico_vegetal_manejo_6_implementacion') === 'NI' ? '■' : '❏'} NI`,
                          font: 'Arial',
                          size: 18
                      })
                  ]
                })
              ],
              verticalAlign: VerticalAlign.TOP
          }),
          new TableCell({
              children: [
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                      new TextRun({
                          text: ` ${this.getValue(submission, 'plan_organico_vegetal_manejo_6_conformidad') === 'C' ? '■' : '❏'} C`,
                          font: 'Arial',
                          size: 18
                      })
                  ]
                }),
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                      new TextRun({
                          text: ` ${this.getValue(submission, 'plan_organico_vegetal_manejo_6_conformidad') === 'NC' ? '■' : '❏'} NC`,
                          font: 'Arial',
                          size: 18
                      })
                  ]
                }),
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                      new TextRun({
                          text: ` ${this.getValue(submission, 'plan_organico_vegetal_manejo_6_conformidad') === 'NA' ? '■' : '❏'} NA`,
                          font: 'Arial',
                          size: 18
                      })
                  ]
                })
              ],
              verticalAlign: VerticalAlign.TOP
          }),
          new TableCell({
              children: [
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                      new TextRun({
                          text: this.getValue(submission, 'plan_organico_vegetal_manejo_6_comentarios_inspector'),
                          font: 'Arial',
                          size: 18
                      })
                  ]
                })
              ],
              verticalAlign: VerticalAlign.TOP
          })
          ]
        }),
        new TableRow({
          children: [
            new TableCell({
              children: [
                new Paragraph({
                  alignment: AlignmentType.CENTER,
                  children: [
                    new TextRun({
                      text: '7',
                      font: 'Arial',
                      size: 18
                    })
                  ]
                })
              ],
              verticalAlign: VerticalAlign.TOP
            }),
            new TableCell({
              children: [
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                    new TextRun({
                      text: 'Controlo las malezas por medio de:',
                      font: 'Arial',
                      size: 18
                    })
                  ]
                }),
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                    new TextRun({
                      text: (this.getValue(submission, "plan_organico_vegetal_manejo_7_machete") ? '■' : '❏') + ' Machete',
                      font: 'Arial',
                      size: 18
                    })
                  ]
                }),
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                    new TextRun({
                      text: (this.getValue(submission, "plan_organico_vegetal_manejo_7_pala") ? '■' : '❏') + ' Pala o azadón',
                      font: 'Arial',
                      size: 18
                    })
                  ]
                }),
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                    new TextRun({
                      text: (this.getValue(submission, "plan_organico_vegetal_manejo_7_desbrozadora") ? '■' : '❏') + ' Desbrozadora ',
                      font: 'Arial',
                      size: 18
                    })
                  ]
                }),
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                    new TextRun({
                      text: (this.getValue(submission, "plan_organico_vegetal_manejo_7_rastra") ? '■' : '❏') + ' Rastra',
                      font: 'Arial',
                      size: 18
                    })
                  ]
                }),
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                    new TextRun({
                      text: (this.getValue(submission, "plan_organico_vegetal_manejo_7_fuego") ? '■' : '❏') + ' Fuego',
                      font: 'Arial',
                      size: 18
                    })
                  ]
                }),
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                    new TextRun({
                      text: (this.getValue(submission, "plan_organico_vegetal_manejo_7_herbicida") ? '■' : '❏') + ' Herbicida',
                      font: 'Arial',
                      size: 18
                    })
                  ]
                }),
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                    new TextRun({
                      text: (this.getValue(submission, "plan_organico_vegetal_manejo_7_otro") ? '■' : '❏') + ' Otro (¿Cuál?): ' + this.getValue(submission, "plan_organico_vegetal_manejo_6_otro_text"),
                      font: 'Arial',
                      size: 18
                    })
                  ]
                })
              ],
              verticalAlign: VerticalAlign.TOP
            }),
            new TableCell({
              children: [
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                    new TextRun({
                      text: this.getValue(submission, "plan_organico_vegetal_manejo_7_comentarios"),
                      font: 'Arial',
                      size: 18
                    })
                  ]
                })
              ],
              verticalAlign: VerticalAlign.TOP
            }),
            new TableCell({
              children: [
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                      new TextRun({
                          text: ` ${this.getValue(submission, 'plan_organico_vegetal_manejo_7_implementacion') === 'I' ? '■' : '❏'} I`,
                          font: 'Arial',
                          size: 18
                      })
                  ]
                }),
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                      new TextRun({
                          text: ` ${this.getValue(submission, 'plan_organico_vegetal_manejo_7_implementacion') === 'NI' ? '■' : '❏'} NI`,
                          font: 'Arial',
                          size: 18
                      })
                  ]
                })
              ],
              verticalAlign: VerticalAlign.TOP
          }),
          new TableCell({
              children: [
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                      new TextRun({
                          text: ` ${this.getValue(submission, 'plan_organico_vegetal_manejo_7_conformidad') === 'C' ? '■' : '❏'} C`,
                          font: 'Arial',
                          size: 18
                      })
                  ]
                }),
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                      new TextRun({
                          text: ` ${this.getValue(submission, 'plan_organico_vegetal_manejo_7_conformidad') === 'NC' ? '■' : '❏'} NC`,
                          font: 'Arial',
                          size: 18
                      })
                  ]
                }),
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                      new TextRun({
                          text: ` ${this.getValue(submission, 'plan_organico_vegetal_manejo_7_conformidad') === 'NA' ? '■' : '❏'} NA`,
                          font: 'Arial',
                          size: 18
                      })
                  ]
                })
              ],
              verticalAlign: VerticalAlign.TOP
          }),
          new TableCell({
              children: [
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                      new TextRun({
                          text: this.getValue(submission, 'plan_organico_vegetal_manejo_7_comentarios_inspector'),
                          font: 'Arial',
                          size: 18
                      })
                  ]
                })
              ],
              verticalAlign: VerticalAlign.TOP
          })
          ]
        }),
        new TableRow({
          children: [
            new TableCell({
              children: [
                new Paragraph({
                  alignment: AlignmentType.CENTER,
                  children: [
                    new TextRun({
                      text: '8',
                      font: 'Arial',
                      size: 18
                    })
                  ]
                })
              ],
              verticalAlign: VerticalAlign.TOP
            }),
            new TableCell({
              children: [
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                    new TextRun({
                      text: 'Para el control de plagas y enfermedades realizó las siguientes prácticas:',
                      font: 'Arial',
                      size: 18
                    })
                  ]
                }),
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                    new TextRun({
                      text: (this.getValue(submission, "plan_organico_vegetal_manejo_8_enemigos_naturales") ? '■' : '❏') + ' Enemigos naturales',
                      font: 'Arial',
                      size: 18
                    })
                  ]
                }),
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                    new TextRun({
                      text: (this.getValue(submission, "plan_organico_vegetal_manejo_8_trampas") ? '■' : '❏') + ' Trampas',
                      font: 'Arial',
                      size: 18
                    })
                  ]
                }),
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                    new TextRun({
                      text: (this.getValue(submission, "plan_organico_vegetal_manejo_8_variedades") ? '■' : '❏') + ' Variedades resistentes',
                      font: 'Arial',
                      size: 18
                    })
                  ]
                }),
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                    new TextRun({
                      text: (this.getValue(submission, "plan_organico_vegetal_manejo_8_extractos") ? '■' : '❏') + ' Extractos vegetales',
                      font: 'Arial',
                      size: 18
                    })
                  ]
                }),
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                    new TextRun({
                      text: (this.getValue(submission, "plan_organico_vegetal_manejo_8_homeopaticas") ? '■' : '❏') + ' Sustancias homeopáticas',
                      font: 'Arial',
                      size: 18
                    })
                  ]
                }),
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                    new TextRun({
                      text: (this.getValue(submission, "plan_organico_vegetal_manejo_8_sustancias_permitidas") ? '■' : '❏') + ' Sustancias permitidas por las normas',
                      font: 'Arial',
                      size: 18
                    })
                  ]
                }),
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                    new TextRun({
                      text: (this.getValue(submission, "plan_organico_vegetal_manejo_8_vapor") ? '■' : '❏') + ' Agua caliente o vapor de agua',
                      font: 'Arial',
                      size: 18
                    })
                  ]
                }),
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                    new TextRun({
                      text: (this.getValue(submission, "plan_organico_vegetal_manejo_8_aspirado") ? '■' : '❏') + ' Aspirado',
                      font: 'Arial',
                      size: 18
                    })
                  ]
                }),
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                    new TextRun({
                      text: (this.getValue(submission, "plan_organico_vegetal_manejo_8_agroquimicos") ? '■' : '❏') + ' Agroquímicos',
                      font: 'Arial',
                      size: 18
                    })
                  ]
                }),
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                    new TextRun({
                      text: (this.getValue(submission, "plan_organico_vegetal_manejo_8_otro") ? '■' : '❏') + ' Otro ¿Cuál? ' + this.getValue(submission, "plan_organico_vegetal_manejo_8_otro_text"),
                      font: 'Arial',
                      size: 18
                    })
                  ]
                })
              ],
              verticalAlign: VerticalAlign.TOP
            }),
            new TableCell({
              children: [
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                    new TextRun({
                      text: this.getValue(submission, "plan_organico_vegetal_manejo_8_comentarios"),
                      font: 'Arial',
                      size: 18
                    })
                  ]
                })
              ],
              verticalAlign: VerticalAlign.TOP
            }),
            new TableCell({
              children: [
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                      new TextRun({
                          text: ` ${this.getValue(submission, 'plan_organico_vegetal_manejo_8_implementacion') === 'I' ? '■' : '❏'} I`,
                          font: 'Arial',
                          size: 18
                      })
                  ]
                }),
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                      new TextRun({
                          text: ` ${this.getValue(submission, 'plan_organico_vegetal_manejo_8_implementacion') === 'NI' ? '■' : '❏'} NI`,
                          font: 'Arial',
                          size: 18
                      })
                  ]
                })
              ],
              verticalAlign: VerticalAlign.TOP
          }),
          new TableCell({
              children: [
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                      new TextRun({
                          text: ` ${this.getValue(submission, 'plan_organico_vegetal_manejo_8_conformidad') === 'C' ? '■' : '❏'} C`,
                          font: 'Arial',
                          size: 18
                      })
                  ]
                }),
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                      new TextRun({
                          text: ` ${this.getValue(submission, 'plan_organico_vegetal_manejo_8_conformidad') === 'NC' ? '■' : '❏'} NC`,
                          font: 'Arial',
                          size: 18
                      })
                  ]
                }),
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                      new TextRun({
                          text: ` ${this.getValue(submission, 'plan_organico_vegetal_manejo_8_conformidad') === 'NA' ? '■' : '❏'} NA`,
                          font: 'Arial',
                          size: 18
                      })
                  ]
                })
              ],
              verticalAlign: VerticalAlign.TOP
          }),
          new TableCell({
              children: [
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                      new TextRun({
                          text: this.getValue(submission, 'plan_organico_vegetal_manejo_8_comentarios_inspector'),
                          font: 'Arial',
                          size: 18
                      })
                  ]
                })
              ],
              verticalAlign: VerticalAlign.TOP
          })
          ]
        }),
        new TableRow({
          children: [
            new TableCell({
              children: [
                new Paragraph({
                  alignment: AlignmentType.CENTER,
                  children: [
                    new TextRun({
                      text: '9',
                      font: 'Arial',
                      size: 18
                    })
                  ]
                })
              ],
              verticalAlign: VerticalAlign.TOP
            }),
            new TableCell({
              children: [
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                    new TextRun({
                      text: 'Para estimular el crecimiento y desarrollo del cultivo se usa:',
                      font: 'Arial',
                      size: 18
                    })
                  ]
                }),
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                    new TextRun({
                      text: (this.getValue(submission, "plan_organico_vegetal_manejo_9_control_temperatura") ? '■' : '❏') + ' Control de temperatura',
                      font: 'Arial',
                      size: 18
                    })
                  ]
                }),
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                    new TextRun({
                      text: (this.getValue(submission, "plan_organico_vegetal_manejo_9_niveles_luz") ? '■' : '❏') + ' Control de niveles de luz',
                      font: 'Arial',
                      size: 18
                    })
                  ]
                }),
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                    new TextRun({
                      text: (this.getValue(submission, "plan_organico_vegetal_manejo_9_desbrozadora") ? '■' : '❏') + ' Desbrozadora',
                      font: 'Arial',
                      size: 18
                    })
                  ]
                }),
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                    new TextRun({
                      text: (this.getValue(submission, "plan_organico_vegetal_manejo_9_sustancias_permitidas") ? '■' : '❏') + ' Sustancias permitidas CAN/CGSB 4.2',
                      font: 'Arial',
                      size: 18
                    })
                  ]
                }),
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                    new TextRun({
                      text: (this.getValue(submission, "plan_organico_vegetal_manejo_9_dioxido_carbono") ? '■' : '❏') + ' Dióxido de carbono',
                      font: 'Arial',
                      size: 18
                    })
                  ]
                }),
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                    new TextRun({
                      text: (this.getValue(submission, "plan_organico_fungi_manejo_9_fitohormonas") ? '■' : '❏') + ' Fitohormonas: ¿Cuáles?: ' + this.getValue(submission, "plan_organico_fungi_manejo_9_fitohormonas_texto"),
                      font: 'Arial',
                      size: 18
                    })
                  ]
                }),
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                    new TextRun({
                      text: (this.getValue(submission, "plan_organico_vegetal_manejo_9_otro") ? '■' : '❏') + ' Otro (¿Cuál?): ' + this.getValue(submission, "plan_organico_vegetal_manejo_9_otro_text"),
                      font: 'Arial',
                      size: 18
                    })
                  ]
                })
              ],
              verticalAlign: VerticalAlign.TOP
            }),
            new TableCell({
              children: [
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                    new TextRun({
                      text: this.getValue(submission, "plan_organico_vegetal_manejo_9_comentarios"),
                      font: 'Arial',
                      size: 18
                    })
                  ]
                })
              ],
              verticalAlign: VerticalAlign.TOP
            }),
            new TableCell({
              children: [
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                      new TextRun({
                          text: ` ${this.getValue(submission, 'plan_organico_vegetal_manejo_9_implementacion') === 'I' ? '■' : '❏'} I`,
                          font: 'Arial',
                          size: 18
                      })
                  ]
                }),
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                      new TextRun({
                          text: ` ${this.getValue(submission, 'plan_organico_vegetal_manejo_9_implementacion') === 'NI' ? '■' : '❏'} NI`,
                          font: 'Arial',
                          size: 18
                      })
                  ]
                })
              ],
              verticalAlign: VerticalAlign.TOP
          }),
          new TableCell({
              children: [
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                      new TextRun({
                          text: ` ${this.getValue(submission, 'plan_organico_vegetal_manejo_9_conformidad') === 'C' ? '■' : '❏'} C`,
                          font: 'Arial',
                          size: 18
                      })
                  ]
                }),
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                      new TextRun({
                          text: ` ${this.getValue(submission, 'plan_organico_vegetal_manejo_9_conformidad') === 'NC' ? '■' : '❏'} NC`,
                          font: 'Arial',
                          size: 18
                      })
                  ]
                }),
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                      new TextRun({
                          text: ` ${this.getValue(submission, 'plan_organico_vegetal_manejo_9_conformidad') === 'NA' ? '■' : '❏'} NA`,
                          font: 'Arial',
                          size: 18
                      })
                  ]
                })
              ],
              verticalAlign: VerticalAlign.TOP
          }),
          new TableCell({
              children: [
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                      new TextRun({
                          text: this.getValue(submission, 'plan_organico_vegetal_manejo_9_comentarios_inspector'),
                          font: 'Arial',
                          size: 18
                      })
                  ]
                })
              ],
              verticalAlign: VerticalAlign.TOP
          })
          ]
        }),
        new TableRow({
          children: [
            new TableCell({
              children: [
                new Paragraph({
                  alignment: AlignmentType.CENTER,
                  children: [
                    new TextRun({
                      text: '10',
                      font: 'Arial',
                      size: 18
                    })
                  ]
                })
              ],
              verticalAlign: VerticalAlign.TOP
            }),
            new TableCell({
              children: [
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                    new TextRun({
                      text: 'Tipo de plásticos usados:',
                      font: 'Arial',
                      size: 18
                    })
                  ]
                }),
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                    new TextRun({
                      text: '',
                      font: 'Arial',
                      size: 18
                    })
                  ]
                }),
                new Table({
                  rows: [
                    new TableRow({
                      children: [
                        new TableCell({
                          children: [
                            new Paragraph({
                              alignment: AlignmentType.CENTER,
                              children: [
                                new TextRun({
                                  text: 'Uso',
                                  font: 'Arial',
                                  size: 18,
                                  bold: true
                                })
                              ]
                            })
                          ],
                          verticalAlign: VerticalAlign.CENTER,
                          shading: {
                            fill: '#C0C0C0'
                          }
                        }),
                        new TableCell({
                          children: [
                            new Paragraph({
                              alignment: AlignmentType.CENTER,
                              children: [
                                new TextRun({
                                  text: 'Material',
                                  font: 'Arial',
                                  size: 18,
                                  bold: true
                                })
                              ]
                            })
                          ],
                          verticalAlign: VerticalAlign.CENTER,
                          shading: {
                            fill: '#C0C0C0'
                          }
                        }),
                      ]
                    }),
                    new TableRow({
                      children: [
                        new TableCell({
                          children: [
                            new Paragraph({
                              alignment: AlignmentType.LEFT,
                              children: [
                                new TextRun({
                                  text: (this.getValue(submission, "plan_organico_vegetal_manejo_10_cubierta_invernadero") ? '■' : '❏') + ' Cubierta invernadero:',
                                  font: 'Arial',
                                  size: 18,
                                })
                              ]
                            })
                          ],
                          verticalAlign: VerticalAlign.CENTER,
                        }),
                        new TableCell({
                          children: [
                            new Paragraph({
                              alignment: AlignmentType.LEFT,
                              children: [
                                new TextRun({
                                  text: `${(this.getValue(submission, "plan_organico_vegetal_manejo_10_cubierta_invernadero_polietileno") ? '■' : '❏')} Polietileno`,
                                  font: 'Arial',
                                  size: 18,
                                })
                              ]
                            }),
                            new Paragraph({
                              alignment: AlignmentType.LEFT,
                              children: [
                                new TextRun({
                                  text: `${(this.getValue(submission, "plan_organico_vegetal_manejo_10_cubierta_invernadero_polipropileno") ? '■' : '❏')} Polipropileno`,
                                  font: 'Arial',
                                  size: 18,
                                })
                              ]
                            }),
                            new Paragraph({
                              alignment: AlignmentType.LEFT,
                              children: [
                                new TextRun({
                                  text: `${(this.getValue(submission, "plan_organico_vegetal_manejo_10_cubierta_invernadero_pvc") ? '■' : '❏')} PVC`,
                                  font: 'Arial',
                                  size: 18,
                                })
                              ]
                            }),
                            new Paragraph({
                              alignment: AlignmentType.LEFT,
                              children: [
                                new TextRun({
                                  text: `${(this.getValue(submission, "plan_organico_vegetal_manejo_10_cubierta_invernadero_otro") ? '■' : '❏')} Otro: ${this.getValue(submission, "plan_organico_vegetal_manejo_10_cubierta_invernadero_otro_text")}`,
                                  font: 'Arial',
                                  size: 18,
                                })
                              ]
                            })
                          ],
                          verticalAlign: VerticalAlign.CENTER,
                        }),
                      ]
                    }),
                    new TableRow({
                      children: [
                        new TableCell({
                          children: [
                            new Paragraph({
                              alignment: AlignmentType.LEFT,
                              children: [
                                new TextRun({
                                  text: (this.getValue(submission, "plan_organico_vegetal_manejo_10_cubierta_suelo") ? '■' : '❏') + ' Cubierta suelo:',
                                  font: 'Arial',
                                  size: 18,
                                })
                              ]
                            })
                          ],
                          verticalAlign: VerticalAlign.CENTER,
                        }),
                        new TableCell({
                          children: [
                            new Paragraph({
                              alignment: AlignmentType.LEFT,
                              children: [
                                new TextRun({
                                  text: `${(this.getValue(submission, "plan_organico_vegetal_manejo_10_cubierta_suelo_polietileno") ? '■' : '❏')} Polietileno`,
                                  font: 'Arial',
                                  size: 18,
                                })
                              ]
                            }),
                            new Paragraph({
                              alignment: AlignmentType.LEFT,
                              children: [
                                new TextRun({
                                  text: `${(this.getValue(submission, "plan_organico_vegetal_manejo_10_cubierta_suelo_polipropileno") ? '■' : '❏')} Polipropileno`,
                                  font: 'Arial',
                                  size: 18,
                                })
                              ]
                            }),
                            new Paragraph({
                              alignment: AlignmentType.LEFT,
                              children: [
                                new TextRun({
                                  text: `${(this.getValue(submission, "plan_organico_vegetal_manejo_10_cubierta_suelo_pvc") ? '■' : '❏')} PVC`,
                                  font: 'Arial',
                                  size: 18,
                                })
                              ]
                            }),
                            new Paragraph({
                              alignment: AlignmentType.LEFT,
                              children: [
                                new TextRun({
                                  text: `${(this.getValue(submission, "plan_organico_vegetal_manejo_10_cubierta_suelo_otro") ? '■' : '❏')} Otro: ${this.getValue(submission, "plan_organico_vegetal_manejo_10_cubierta_suelo_otro_text")}`,
                                  font: 'Arial',
                                  size: 18,
                                })
                              ]
                            })
                          ],
                          verticalAlign: VerticalAlign.CENTER,
                        }),
                      ]
                    }),
                    new TableRow({
                      children: [
                        new TableCell({
                          children: [
                            new Paragraph({
                              alignment: AlignmentType.LEFT,
                              children: [
                                new TextRun({
                                  text: (this.getValue(submission, "plan_organico_vegetal_manejo_10_mallas") ? '■' : '❏') + ' Mallas (especifique): ' + this.getValue(submission, "plan_organico_vegetal_manejo_10_mallas_texto"),
                                  font: 'Arial',
                                  size: 18,
                                })
                              ]
                            })
                          ],
                          verticalAlign: VerticalAlign.CENTER,
                        }),
                        new TableCell({
                          children: [
                            new Paragraph({
                              alignment: AlignmentType.LEFT,
                              children: [
                                new TextRun({
                                  text: `${(this.getValue(submission, "plan_organico_vegetal_manejo_10_mallas_polietileno") ? '■' : '❏')} Polietileno`,
                                  font: 'Arial',
                                  size: 18,
                                })
                              ]
                            }),
                            new Paragraph({
                              alignment: AlignmentType.LEFT,
                              children: [
                                new TextRun({
                                  text: `${(this.getValue(submission, "plan_organico_vegetal_manejo_10_mallas_polipropileno") ? '■' : '❏')} Polipropileno`,
                                  font: 'Arial',
                                  size: 18,
                                })
                              ]
                            }),
                            new Paragraph({
                              alignment: AlignmentType.LEFT,
                              children: [
                                new TextRun({
                                  text: `${(this.getValue(submission, "plan_organico_vegetal_manejo_10_mallas_pvc") ? '■' : '❏')} PVC`,
                                  font: 'Arial',
                                  size: 18,
                                })
                              ]
                            }),
                            new Paragraph({
                              alignment: AlignmentType.LEFT,
                              children: [
                                new TextRun({
                                  text: `${(this.getValue(submission, "plan_organico_vegetal_manejo_10_mallas_otro") ? '■' : '❏')} Otro: ${this.getValue(submission, "plan_organico_vegetal_manejo_10_mallas_otro_text")}`,
                                  font: 'Arial',
                                  size: 18,
                                })
                              ]
                            })
                          ],
                          verticalAlign: VerticalAlign.CENTER,
                        }),
                      ]
                    }),
                    new TableRow({
                      children: [
                        new TableCell({
                          children: [
                            new Paragraph({
                              alignment: AlignmentType.LEFT,
                              children: [
                                new TextRun({
                                  text: (this.getValue(submission, "plan_organico_vegetal_manejo_10_tuberia") ? '■' : '❏') + ' Tubería de riego:',
                                  font: 'Arial',
                                  size: 18,
                                })
                              ]
                            })
                          ],
                          verticalAlign: VerticalAlign.CENTER,
                        }),
                        new TableCell({
                          children: [
                            new Paragraph({
                              alignment: AlignmentType.LEFT,
                              children: [
                                new TextRun({
                                  text: `${(this.getValue(submission, "plan_organico_vegetal_manejo_10_tuberia_polietileno") ? '■' : '❏')} Polietileno`,
                                  font: 'Arial',
                                  size: 18,
                                })
                              ]
                            }),
                            new Paragraph({
                              alignment: AlignmentType.LEFT,
                              children: [
                                new TextRun({
                                  text: `${(this.getValue(submission, "plan_organico_vegetal_manejo_10_tuberia_polipropileno") ? '■' : '❏')} Polipropileno`,
                                  font: 'Arial',
                                  size: 18,
                                })
                              ]
                            }),
                            new Paragraph({
                              alignment: AlignmentType.LEFT,
                              children: [
                                new TextRun({
                                  text: `${(this.getValue(submission, "plan_organico_vegetal_manejo_10_tuberia_pvc") ? '■' : '❏')} PVC`,
                                  font: 'Arial',
                                  size: 18,
                                })
                              ]
                            }),
                            new Paragraph({
                              alignment: AlignmentType.LEFT,
                              children: [
                                new TextRun({
                                  text: `${(this.getValue(submission, "plan_organico_vegetal_manejo_10_tuberia_otro") ? '■' : '❏')} Otro: ${this.getValue(submission, "plan_organico_vegetal_manejo_10_tuberia_otro_text")}`,
                                  font: 'Arial',
                                  size: 18,
                                })
                              ]
                            })
                          ],
                          verticalAlign: VerticalAlign.CENTER,
                        }),
                      ]
                    }),
                    new TableRow({
                      children: [
                        new TableCell({
                          children: [
                            new Paragraph({
                              alignment: AlignmentType.LEFT,
                              children: [
                                new TextRun({
                                  text: (this.getValue(submission, "plan_organico_vegetal_manejo_10_aspersor") ? '■' : '❏') + ' Aspersores o goteros:',
                                  font: 'Arial',
                                  size: 18,
                                })
                              ]
                            })
                          ],
                          verticalAlign: VerticalAlign.CENTER,
                        }),
                        new TableCell({
                          children: [
                            new Paragraph({
                              alignment: AlignmentType.LEFT,
                              children: [
                                new TextRun({
                                  text: `${(this.getValue(submission, "plan_organico_vegetal_manejo_10_aspersor_polietileno") ? '■' : '❏')} Polietileno`,
                                  font: 'Arial',
                                  size: 18,
                                })
                              ]
                            }),
                            new Paragraph({
                              alignment: AlignmentType.LEFT,
                              children: [
                                new TextRun({
                                  text: `${(this.getValue(submission, "plan_organico_vegetal_manejo_10_aspersor_polipropileno") ? '■' : '❏')} Polipropileno`,
                                  font: 'Arial',
                                  size: 18,
                                })
                              ]
                            }),
                            new Paragraph({
                              alignment: AlignmentType.LEFT,
                              children: [
                                new TextRun({
                                  text: `${(this.getValue(submission, "plan_organico_vegetal_manejo_10_aspersor_pvc") ? '■' : '❏')} PVC`,
                                  font: 'Arial',
                                  size: 18,
                                })
                              ]
                            }),
                            new Paragraph({
                              alignment: AlignmentType.LEFT,
                              children: [
                                new TextRun({
                                  text: `${(this.getValue(submission, "plan_organico_vegetal_manejo_10_aspersor_otro") ? '■' : '❏')} Otro: ${this.getValue(submission, "plan_organico_vegetal_manejo_10_aspersor_otro_text")}`,
                                  font: 'Arial',
                                  size: 18,
                                })
                              ]
                            })
                          ],
                          verticalAlign: VerticalAlign.CENTER,
                        }),
                      ]
                    }),
                    new TableRow({
                      children: [
                        new TableCell({
                          children: [
                            new Paragraph({
                              alignment: AlignmentType.LEFT,
                              children: [
                                new TextRun({
                                  text: (this.getValue(submission, "plan_organico_vegetal_manejo_10_macetas") ? '■' : '❏') + ' Contenedores / Macetas:',
                                  font: 'Arial',
                                  size: 18,
                                })
                              ]
                            })
                          ],
                          verticalAlign: VerticalAlign.CENTER,
                        }),
                        new TableCell({
                          children: [
                            new Paragraph({
                              alignment: AlignmentType.LEFT,
                              children: [
                                new TextRun({
                                  text: `${(this.getValue(submission, "plan_organico_vegetal_manejo_10_macetas_polietileno") ? '■' : '❏')} Polietileno`,
                                  font: 'Arial',
                                  size: 18,
                                })
                              ]
                            }),
                            new Paragraph({
                              alignment: AlignmentType.LEFT,
                              children: [
                                new TextRun({
                                  text: `${(this.getValue(submission, "plan_organico_vegetal_manejo_10_macetas_polipropileno") ? '■' : '❏')} Polipropileno`,
                                  font: 'Arial',
                                  size: 18,
                                })
                              ]
                            }),
                            new Paragraph({
                              alignment: AlignmentType.LEFT,
                              children: [
                                new TextRun({
                                  text: `${(this.getValue(submission, "plan_organico_vegetal_manejo_10_macetas_pvc") ? '■' : '❏')} PVC`,
                                  font: 'Arial',
                                  size: 18,
                                })
                              ]
                            }),
                            new Paragraph({
                              alignment: AlignmentType.LEFT,
                              children: [
                                new TextRun({
                                  text: `${(this.getValue(submission, "plan_organico_vegetal_manejo_10_macetas_otro") ? '■' : '❏')} Otro: ${this.getValue(submission, "plan_organico_vegetal_manejo_10_macetas_otro_text")}`,
                                  font: 'Arial',
                                  size: 18,
                                })
                              ]
                            })
                          ],
                          verticalAlign: VerticalAlign.CENTER,
                        }),
                      ]
                    }),
                    new TableRow({
                      children: [
                        new TableCell({
                          children: [
                            new Paragraph({
                              alignment: AlignmentType.LEFT,
                              children: [
                                new TextRun({
                                  text: `${(this.getValue(submission, "plan_organico_vegetal_manejo_10_cajas") ? '■' : '❏')}  Cajas de cosecha:`,
                                  font: 'Arial',
                                  size: 18,
                                })
                              ]
                            })
                          ],
                          verticalAlign: VerticalAlign.CENTER,
                        }),
                        new TableCell({
                          children: [
                            new Paragraph({
                              alignment: AlignmentType.LEFT,
                              children: [
                                new TextRun({
                                  text: `${(this.getValue(submission, "plan_organico_vegetal_manejo_10_cajas_polietileno") ? '■' : '❏')} Polietileno`,
                                  font: 'Arial',
                                  size: 18,
                                })
                              ]
                            }),
                            new Paragraph({
                              alignment: AlignmentType.LEFT,
                              children: [
                                new TextRun({
                                  text: `${(this.getValue(submission, "plan_organico_vegetal_manejo_10_cajas_polipropileno") ? '■' : '❏')} Polipropileno`,
                                  font: 'Arial',
                                  size: 18,
                                })
                              ]
                            }),
                            new Paragraph({
                              alignment: AlignmentType.LEFT,
                              children: [
                                new TextRun({
                                  text: `${(this.getValue(submission, "plan_organico_vegetal_manejo_10_cajas_pvc") ? '■' : '❏')} PVC`,
                                  font: 'Arial',
                                  size: 18,
                                })
                              ]
                            }),
                            new Paragraph({
                              alignment: AlignmentType.LEFT,
                              children: [
                                new TextRun({
                                  text: `${(this.getValue(submission, "plan_organico_vegetal_manejo_10_cajas_otro") ? '■' : '❏')} Otro: ${this.getValue(submission, "plan_organico_vegetal_manejo_10_cajas_otro_text")}`,
                                  font: 'Arial',
                                  size: 18,
                                })
                              ]
                            })
                          ],
                          verticalAlign: VerticalAlign.CENTER,
                        }),
                      ]
                    }),
                    new TableRow({
                      children: [
                        new TableCell({
                          children: [
                            new Paragraph({
                              alignment: AlignmentType.LEFT,
                              children: [
                                new TextRun({
                                  text: (this.getValue(submission, "plan_organico_vegetal_manejo_10_otro") ? '■' : '❏') + ' Otro (especifique): ' + this.getValue(submission, "plan_organico_vegetal_manejo_10_otro_name"),
                                  font: 'Arial',
                                  size: 18,
                                })
                              ]
                            })
                          ],
                          verticalAlign: VerticalAlign.CENTER,
                        }),
                        new TableCell({
                          children: [
                            new Paragraph({
                              alignment: AlignmentType.LEFT,
                              children: [
                                new TextRun({
                                  text: `${(this.getValue(submission, "plan_organico_vegetal_manejo_10_otro_polietileno") ? '■' : '❏')} Polietileno`,
                                  font: 'Arial',
                                  size: 18,
                                })
                              ]
                            }),
                            new Paragraph({
                              alignment: AlignmentType.LEFT,
                              children: [
                                new TextRun({
                                  text: `${(this.getValue(submission, "plan_organico_vegetal_manejo_10_otro_polipropileno") ? '■' : '❏')} Polipropileno`,
                                  font: 'Arial',
                                  size: 18,
                                })
                              ]
                            }),
                            new Paragraph({
                              alignment: AlignmentType.LEFT,
                              children: [
                                new TextRun({
                                  text: `${(this.getValue(submission, "plan_organico_vegetal_manejo_10_otro_pvc") ? '■' : '❏')} PVC`,
                                  font: 'Arial',
                                  size: 18,
                                })
                              ]
                            }),
                            new Paragraph({
                              alignment: AlignmentType.LEFT,
                              children: [
                                new TextRun({
                                  text: `${(this.getValue(submission, "plan_organico_vegetal_manejo_10_otro_otro") ? '■' : '❏')} Otro: ${this.getValue(submission, "plan_organico_vegetal_manejo_10_otro_text")}`,
                                  font: 'Arial',
                                  size: 18,
                                })
                              ]
                            })
                          ],
                          verticalAlign: VerticalAlign.CENTER,
                        }),
                      ]
                    }),
                  ],
                  margins: {
                    left: 70,
                    right: 70
                  },
                  columnWidths: [1870, 1870]
                })
              ],
              verticalAlign: VerticalAlign.TOP
            }),
            new TableCell({
              children: [
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                    new TextRun({
                      text: 'Comentarios: ' + this.getValue(submission, "plan_organico_vegetal_manejo_10_comentarios"),
                      font: 'Arial',
                      size: 18
                    })
                  ]
                })
              ],
              verticalAlign: VerticalAlign.TOP
            }),
            new TableCell({
              children: [
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                      new TextRun({
                          text: ` ${this.getValue(submission, 'plan_organico_vegetal_manejo_10_implementacion') === 'I' ? '■' : '❏'} I`,
                          font: 'Arial',
                          size: 18
                      })
                  ]
                }),
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                      new TextRun({
                          text: ` ${this.getValue(submission, 'plan_organico_vegetal_manejo_10_implementacion') === 'NI' ? '■' : '❏'} NI`,
                          font: 'Arial',
                          size: 18
                      })
                  ]
                })
              ],
              verticalAlign: VerticalAlign.TOP
          }),
          new TableCell({
              children: [
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                      new TextRun({
                          text: ` ${this.getValue(submission, 'plan_organico_vegetal_manejo_10_conformidad') === 'C' ? '■' : '❏'} C`,
                          font: 'Arial',
                          size: 18
                      })
                  ]
                }),
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                      new TextRun({
                          text: ` ${this.getValue(submission, 'plan_organico_vegetal_manejo_10_conformidad') === 'NC' ? '■' : '❏'} NC`,
                          font: 'Arial',
                          size: 18
                      })
                  ]
                }),
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                      new TextRun({
                          text: ` ${this.getValue(submission, 'plan_organico_vegetal_manejo_10_conformidad') === 'NA' ? '■' : '❏'} NA`,
                          font: 'Arial',
                          size: 18
                      })
                  ]
                })
              ],
              verticalAlign: VerticalAlign.TOP
          }),
          new TableCell({
              children: [
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                      new TextRun({
                          text: this.getValue(submission, 'plan_organico_vegetal_manejo_10_comentarios_inspector'),
                          font: 'Arial',
                          size: 18
                      })
                  ]
                })
              ],
              verticalAlign: VerticalAlign.TOP
          })
          ]
        }),
        new TableRow({
          children: [
            new TableCell({
              children: [
                new Paragraph({
                  alignment: AlignmentType.CENTER,
                  children: [
                    new TextRun({
                      text: '11',
                      font: 'Arial',
                      size: 18
                    })
                  ]
                })
              ],
              verticalAlign: VerticalAlign.TOP
            }),
            new TableCell({
              children: [
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                    new TextRun({
                      text: 'Para la desinfección del invernadero se usan:',
                      font: 'Arial',
                      size: 18
                    })
                  ]
                }),
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                    new TextRun({
                      text: (this.getValue(submission, "plan_organico_vegetal_manejo_11_sustancias_listadas") ? '■' : '❏') + ' Sustancias listadas en las tablas 7.3 y 7.4 del CAN/CGSB-32-311',
                      font: 'Arial',
                      size: 18
                    })
                  ]
                }),
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                    new TextRun({
                      text: (this.getValue(submission, "plan_organico_vegetal_manejo_11_vapor_calor") ? '■' : '❏') + ' Vapor-Calor',
                      font: 'Arial',
                      size: 18
                    })
                  ]
                }),
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                    new TextRun({
                      text: (this.getValue(submission, "plan_organico_vegetal_manejo_11_extractos") ? '■' : '❏') + ' Extractos vegetales ¿Cuál?:  '  + this.getValue(submission, "plan_organico_vegetal_manejo_11_extractos_texto"),
                      font: 'Arial',
                      size: 18
                    })
                  ]
                }),
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                    new TextRun({
                      text: (this.getValue(submission, "plan_organico_vegetal_manejo_11_otro") ? '■' : '❏') + ' Otro (¿Cuál?): ' + this.getValue(submission, "plan_organico_vegetal_manejo_11_otro_text"),
                      font: 'Arial',
                      size: 18
                    })
                  ]
                })
              ],
              verticalAlign: VerticalAlign.TOP
            }),
            new TableCell({
              children: [
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                    new TextRun({
                      text: this.getValue(submission, "plan_organico_vegetal_manejo_11_comentarios"),
                      font: 'Arial',
                      size: 18
                    })
                  ]
                })
              ],
              verticalAlign: VerticalAlign.TOP
            }),
            new TableCell({
              children: [
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                      new TextRun({
                          text: ` ${this.getValue(submission, 'plan_organico_vegetal_manejo_11_implementacion') === 'I' ? '■' : '❏'} I`,
                          font: 'Arial',
                          size: 18
                      })
                  ]
                }),
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                      new TextRun({
                          text: ` ${this.getValue(submission, 'plan_organico_vegetal_manejo_11_implementacion') === 'NI' ? '■' : '❏'} NI`,
                          font: 'Arial',
                          size: 18
                      })
                  ]
                })
              ],
              verticalAlign: VerticalAlign.TOP
          }),
          new TableCell({
              children: [
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                      new TextRun({
                          text: ` ${this.getValue(submission, 'plan_organico_vegetal_manejo_11_conformidad') === 'C' ? '■' : '❏'} C`,
                          font: 'Arial',
                          size: 18
                      })
                  ]
                }),
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                      new TextRun({
                          text: ` ${this.getValue(submission, 'plan_organico_vegetal_manejo_11_conformidad') === 'NC' ? '■' : '❏'} NC`,
                          font: 'Arial',
                          size: 18
                      })
                  ]
                }),
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                      new TextRun({
                          text: ` ${this.getValue(submission, 'plan_organico_vegetal_manejo_11_conformidad') === 'NA' ? '■' : '❏'} NA`,
                          font: 'Arial',
                          size: 18
                      })
                  ]
                })
              ],
              verticalAlign: VerticalAlign.TOP
          }),
          new TableCell({
              children: [
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                      new TextRun({
                          text: this.getValue(submission, 'plan_organico_vegetal_manejo_11_comentarios_inspector'),
                          font: 'Arial',
                          size: 18
                      })
                  ]
                })
              ],
              verticalAlign: VerticalAlign.TOP
          })
          ]
        }),
        new TableRow({
          children: [
            new TableCell({
              children: [
                new Paragraph({
                  alignment: AlignmentType.CENTER,
                  children: [
                    new TextRun({
                      text: '12',
                      font: 'Arial',
                      size: 18
                    })
                  ]
                })
              ],
              verticalAlign: VerticalAlign.TOP
            }),
            new TableCell({
              children: [
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                    new TextRun({
                      text: 'Para la cosecha y post-cosecha las siguientes prácticas están bajo mi control y mantienen la calidad orgánica del producto:',
                      font: 'Arial',
                      size: 18
                    })
                  ]
                }),
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                    new TextRun({
                      text: (this.getValue(submission, "plan_organico_vegetal_manejo_12_corte") ? '■' : '❏') + ' Corte (cosecha)',
                      font: 'Arial',
                      size: 18
                    })
                  ]
                }),
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                    new TextRun({
                      text: (this.getValue(submission, "plan_organico_vegetal_manejo_12_seleccion") ? '■' : '❏') + ' Selección',
                      font: 'Arial',
                      size: 18
                    })
                  ]
                }),
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                    new TextRun({
                      text: (this.getValue(submission, "plan_organico_vegetal_manejo_12_lavado") ? '■' : '❏') + ' Lavado',
                      font: 'Arial',
                      size: 18
                    })
                  ]
                }),
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                    new TextRun({
                      text: (this.getValue(submission, "plan_organico_vegetal_manejo_12_refrigeracion") ? '■' : '❏') + ' Refrigeración',
                      font: 'Arial',
                      size: 18
                    })
                  ]
                }),
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                    new TextRun({
                      text: (this.getValue(submission, "plan_organico_vegetal_manejo_12_transporte") ? '■' : '❏') + ' Transporte',
                      font: 'Arial',
                      size: 18
                    })
                  ]
                }),
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                    new TextRun({
                      text: (this.getValue(submission, "plan_organico_vegetal_manejo_12_otro") ? '■' : '❏') + ' Otro (¿Cuál?): ' + this.getValue(submission, "plan_organico_vegetal_manejo_12_otro_text"),
                      font: 'Arial',
                      size: 18
                    })
                  ]
                })
              ],
              verticalAlign: VerticalAlign.TOP
            }),
            new TableCell({
              children: [
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                    new TextRun({
                      text: this.getValue(submission, "plan_organico_vegetal_manejo_12_comentarios"),
                      font: 'Arial',
                      size: 18
                    })
                  ]
                })
              ],
              verticalAlign: VerticalAlign.TOP
            }),
            new TableCell({
              children: [
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                      new TextRun({
                          text: ` ${this.getValue(submission, 'plan_organico_vegetal_manejo_12_implementacion') === 'I' ? '■' : '❏'} I`,
                          font: 'Arial',
                          size: 18
                      })
                  ]
                }),
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                      new TextRun({
                          text: ` ${this.getValue(submission, 'plan_organico_vegetal_manejo_12_implementacion') === 'NI' ? '■' : '❏'} NI`,
                          font: 'Arial',
                          size: 18
                      })
                  ]
                })
              ],
              verticalAlign: VerticalAlign.TOP
          }),
          new TableCell({
              children: [
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                      new TextRun({
                          text: ` ${this.getValue(submission, 'plan_organico_vegetal_manejo_12_conformidad') === 'C' ? '■' : '❏'} C`,
                          font: 'Arial',
                          size: 18
                      })
                  ]
                }),
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                      new TextRun({
                          text: ` ${this.getValue(submission, 'plan_organico_vegetal_manejo_12_conformidad') === 'NC' ? '■' : '❏'} NC`,
                          font: 'Arial',
                          size: 18
                      })
                  ]
                }),
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                      new TextRun({
                          text: ` ${this.getValue(submission, 'plan_organico_vegetal_manejo_12_conformidad') === 'NA' ? '■' : '❏'} NA`,
                          font: 'Arial',
                          size: 18
                      })
                  ]
                })
              ],
              verticalAlign: VerticalAlign.TOP
          }),
          new TableCell({
              children: [
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                      new TextRun({
                          text: this.getValue(submission, 'plan_organico_vegetal_manejo_12_comentarios_inspector'),
                          font: 'Arial',
                          size: 18
                      })
                  ]
                })
              ],
              verticalAlign: VerticalAlign.TOP
          })
          ]
        }),
        new TableRow({
          children: [
            new TableCell({
              children: [
                new Paragraph({
                  alignment: AlignmentType.CENTER,
                  children: [
                    new TextRun({
                      text: '13',
                      font: 'Arial',
                      size: 18
                    })
                  ]
                })
              ],
              verticalAlign: VerticalAlign.TOP
            }),
            new TableCell({
              children: [
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                    new TextRun({
                      text: 'Los residuos de cosecha se destinan a:',
                      font: 'Arial',
                      size: 18
                    })
                  ]
                }),
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                    new TextRun({
                      text: (this.getValue(submission, "plan_organico_vegetal_manejo_13_reintegran") ? '■' : '❏') + ' Se reintegran a la parcela',
                      font: 'Arial',
                      size: 18
                    })
                  ]
                }),
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                    new TextRun({
                      text: (this.getValue(submission, "plan_organico_vegetal_manejo_13_desechan") ? '■' : '❏') + ' Se desechan en instalaciones públicas',
                      font: 'Arial',
                      size: 18
                    })
                  ]
                }),
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                    new TextRun({
                      text: (this.getValue(submission, "plan_organico_vegetal_manejo_13_venden") ? '■' : '❏') + ' Se venden',
                      font: 'Arial',
                      size: 18
                    })
                  ]
                }),
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                    new TextRun({
                      text: (this.getValue(submission, "plan_organico_vegetal_manejo_13_otro") ? '■' : '❏') + ' Otro (¿Cuál?) ' + this.getValue(submission, "plan_organico_vegetal_manejo_13_otro_text"),
                      font: 'Arial',
                      size: 18
                    })
                  ]
                })
              ],
              verticalAlign: VerticalAlign.TOP
            }),
            new TableCell({
              children: [
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                    new TextRun({
                      text: this.getValue(submission, "plan_organico_vegetal_manejo_13_comentarios"),
                      font: 'Arial',
                      size: 18
                    })
                  ]
                })
              ],
              verticalAlign: VerticalAlign.TOP
            }),
            new TableCell({
              children: [
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                      new TextRun({
                          text: ` ${this.getValue(submission, 'plan_organico_vegetal_manejo_13_implementacion') === 'I' ? '■' : '❏'} I`,
                          font: 'Arial',
                          size: 18
                      })
                  ]
                }),
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                      new TextRun({
                          text: ` ${this.getValue(submission, 'plan_organico_vegetal_manejo_13_implementacion') === 'NI' ? '■' : '❏'} NI`,
                          font: 'Arial',
                          size: 18
                      })
                  ]
                })
              ],
              verticalAlign: VerticalAlign.TOP
          }),
          new TableCell({
              children: [
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                      new TextRun({
                          text: ` ${this.getValue(submission, 'plan_organico_vegetal_manejo_13_conformidad') === 'C' ? '■' : '❏'} C`,
                          font: 'Arial',
                          size: 18
                      })
                  ]
                }),
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                      new TextRun({
                          text: ` ${this.getValue(submission, 'plan_organico_vegetal_manejo_13_conformidad') === 'NC' ? '■' : '❏'} NC`,
                          font: 'Arial',
                          size: 18
                      })
                  ]
                }),
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                      new TextRun({
                          text: ` ${this.getValue(submission, 'plan_organico_vegetal_manejo_13_conformidad') === 'NA' ? '■' : '❏'} NA`,
                          font: 'Arial',
                          size: 18
                      })
                  ]
                })
              ],
              verticalAlign: VerticalAlign.TOP
          }),
          new TableCell({
              children: [
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                      new TextRun({
                          text: this.getValue(submission, 'plan_organico_vegetal_manejo_13_comentarios_inspector'),
                          font: 'Arial',
                          size: 18
                      })
                  ]
                })
              ],
              verticalAlign: VerticalAlign.TOP
          })
          ]
        }),
        new TableRow({
          children: [
            new TableCell({
              children: [
                new Paragraph({
                  alignment: AlignmentType.CENTER,
                  children: [
                    new TextRun({
                      text: '14',
                      font: 'Arial',
                      size: 18
                    })
                  ]
                })
              ],
              verticalAlign: VerticalAlign.TOP
            }),
            new TableCell({
              children: [
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                    new TextRun({
                      text: 'El sustrato de desecho se destina a:',
                      font: 'Arial',
                      size: 18
                    })
                  ]
                }),
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                    new TextRun({
                      text: (this.getValue(submission, "plan_organico_vegetal_manejo_14_recicla_otro_terreno") ? '■' : '❏') + ' Se recicla en otro terreno',
                      font: 'Arial',
                      size: 18
                    })
                  ]
                }),
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                    new TextRun({
                      text: (this.getValue(submission, "plan_organico_vegetal_manejo_14_recicla_otro_uso") ? '■' : '❏') + ' Se recicla para otro uso',
                      font: 'Arial',
                      size: 18
                    })
                  ]
                }),
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                    new TextRun({
                      text: (this.getValue(submission, "plan_organico_vegetal_manejo_14_vende") ? '■' : '❏') + ' Se vende',
                      font: 'Arial',
                      size: 18
                    })
                  ]
                }),
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                    new TextRun({
                      text: (this.getValue(submission, "plan_organico_vegetal_manejo_14_otro") ? '■' : '❏') + ' Otro (¿Cuál?) ' + this.getValue(submission, "plan_organico_vegetal_manejo_14_otro_text"),
                      font: 'Arial',
                      size: 18
                    })
                  ]
                })
              ],
              verticalAlign: VerticalAlign.TOP
            }),
            new TableCell({
              children: [
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                    new TextRun({
                      text: this.getValue(submission, "plan_organico_vegetal_manejo_14_comentarios"),
                      font: 'Arial',
                      size: 18
                    })
                  ]
                })
              ],
              verticalAlign: VerticalAlign.TOP
            }),
            new TableCell({
              children: [
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                      new TextRun({
                          text: ` ${this.getValue(submission, 'plan_organico_vegetal_manejo_14_implementacion') === 'I' ? '■' : '❏'} I`,
                          font: 'Arial',
                          size: 18
                      })
                  ]
                }),
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                      new TextRun({
                          text: ` ${this.getValue(submission, 'plan_organico_vegetal_manejo_14_implementacion') === 'NI' ? '■' : '❏'} NI`,
                          font: 'Arial',
                          size: 18
                      })
                  ]
                })
              ],
              verticalAlign: VerticalAlign.TOP
          }),
          new TableCell({
              children: [
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                      new TextRun({
                          text: ` ${this.getValue(submission, 'plan_organico_vegetal_manejo_14_conformidad') === 'C' ? '■' : '❏'} C`,
                          font: 'Arial',
                          size: 18
                      })
                  ]
                }),
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                      new TextRun({
                          text: ` ${this.getValue(submission, 'plan_organico_vegetal_manejo_14_conformidad') === 'NC' ? '■' : '❏'} NC`,
                          font: 'Arial',
                          size: 18
                      })
                  ]
                }),
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                      new TextRun({
                          text: ` ${this.getValue(submission, 'plan_organico_vegetal_manejo_14_conformidad') === 'NA' ? '■' : '❏'} NA`,
                          font: 'Arial',
                          size: 18
                      })
                  ]
                })
              ],
              verticalAlign: VerticalAlign.TOP
          }),
          new TableCell({
              children: [
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                      new TextRun({
                          text: this.getValue(submission, 'plan_organico_vegetal_manejo_14_comentarios_inspector'),
                          font: 'Arial',
                          size: 18
                      })
                  ]
                })
              ],
              verticalAlign: VerticalAlign.TOP
          })
          ]
        })

      ],
      margins: {
        left: 70,
        right: 70
      },
      //9885
      columnWidths: [450, 3895, 2045, 1150, 800, 1545]
    })
    return table
  }


  public createCultivo_Contenedores_MTO_FSOL_08_1_2(submission): Table {
    const table = new Table({
      rows: [
        new TableRow({
          children: [
            new TableCell({
              children: [
                new Paragraph({
                  alignment: AlignmentType.CENTER,
                  children: [
                    new TextRun({
                      text: 'CULTIVO EN CONTENEDORES (NO LIGADO AL SUELO)',
                      font: 'Arial',
                      size: 20,
                      bold: true
                    })
                  ],
                  spacing: {
                    before: 75
                  }
                }),
                new Paragraph({
                  alignment: AlignmentType.CENTER,
                  children: [
                    new TextRun({
                      text: 'Los cultivos no ligados al suelo no se pueden certificar bajo el reglamento 848/2018, por lo que esta sección no aplicaría ni el producto bajo invernadero podría ser certificado.',
                      font: 'Arial',
                      size: 18,
                    })
                  ],
                  spacing: {
                    after: 75,
                  }
                }),
              ],
              verticalAlign: VerticalAlign.CENTER,
              columnSpan: 6,
              shading: {
                fill: '#C0C0C0'
              }
            })
          ]
        }),
        new TableRow({
          children: [
            new TableCell({
              children: [
                new Paragraph({
                  alignment: AlignmentType.CENTER,
                  children: [
                    new TextRun({
                      text: 'No.',
                      font: 'Arial',
                      size: 18,
                      bold: true
                    })
                  ]
                })
              ],
              rowSpan: 2,
              columnSpan: 1,
              verticalAlign: VerticalAlign.CENTER,
              width: {
                size: 25,
                type: WidthType.PERCENTAGE
              }
            }),
            new TableCell({
              children: [
                new Paragraph({
                  alignment: AlignmentType.CENTER,
                  children: [
                    new TextRun({
                      text: 'Requisitos',
                      font: 'Arial',
                      size: 18,
                      bold: true
                    })
                  ]
                })
              ],
              rowSpan: 2,
              columnSpan: 1,
              verticalAlign: VerticalAlign.CENTER,
              width: {
                size: 15,
                type: WidthType.PERCENTAGE
              }
            }),
            new TableCell({
              children: [
                new Paragraph({
                  alignment: AlignmentType.CENTER,
                  children: [
                    new TextRun({
                      text: 'Comentarios',
                      font: 'Arial',
                      size: 18,
                      bold: true
                    })
                  ]
                })
              ],
              rowSpan: 2,
              columnSpan: 1,
              verticalAlign: VerticalAlign.CENTER,
              width: {
                size: 15,
                type: WidthType.PERCENTAGE
              }
            }),
            new TableCell({
              children: [
                  new Paragraph({
                      alignment: AlignmentType.CENTER,
                      children: [
                          new TextRun({
                              text: 'Verificación en sitio (Evaluada por Inspector)',
                              font: 'Arial',
                              size: 18,
                              bold: true
                          })
                      ]
                  })
              ],
              columnSpan: 3,
              verticalAlign: VerticalAlign.CENTER,
              width: {
                  size: 15,
                  type: WidthType.PERCENTAGE
              }
          })
          ]
        }),
        new TableRow({
          children: [new TableCell({
              children: [
                  new Paragraph({
                      alignment: AlignmentType.CENTER,
                      children: [
                          new TextRun({
                              text: 'Implementación',
                              font: 'Arial',
                              size: 18,
                              bold: true
                          })
                      ]
                  })
              ],
              verticalAlign: VerticalAlign.CENTER
          }),
          new TableCell({
              children: [
                  new Paragraph({
                      alignment: AlignmentType.CENTER,
                      children: [
                          new TextRun({
                              text: 'Confirmación',
                              font: 'Arial',
                              size: 18,
                              bold: true
                          })
                      ]
                  })
              ],
              verticalAlign: VerticalAlign.CENTER,
              columnSpan: 2
          }),]
        }),
        new TableRow({
          children: [
            new TableCell({
              children: [
                new Paragraph({
                  alignment: AlignmentType.CENTER,
                  children: [
                    new TextRun({
                      text: '1',
                      font: 'Arial',
                      size: 18
                    })
                  ]
                })
              ],
              verticalAlign: VerticalAlign.TOP
            }),
            new TableCell({
              children: [
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                    new TextRun({
                      text: '¿Cuál es la justificación para que el cultivo se tenga que desarrollar en contenedores?',
                      font: 'Arial',
                      size: 18
                    })
                  ]
                }),
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                    new TextRun({
                      text: (this.getValue(submission, 'cultivo_contenedores_1_salinidad') ? '■' : '❏') + '  Salinidad del suelo',
                      font: 'Arial',
                      size: 18
                    })
                  ]
                }),
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                    new TextRun({
                      text: (this.getValue(submission, "cultivo_contenedores_1_pedregosidad") ? '■' : '❏') + '  Pedregosidad del suelo',
                      font: 'Arial',
                      size: 18
                    })
                  ]
                }),
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                    new TextRun({
                      text: (this.getValue(submission, "cultivo_contenedores_1_exeso_humedad") ? '■' : '❏') + '  Exceso de humedad en el suelo',
                      font: 'Arial',
                      size: 18
                    })
                  ]
                }),
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                    new TextRun({
                      text: (this.getValue(submission, "cultivo_contenedores_1_dificultades_riego") ? '■' : '❏') + '  Dificultades para el riego',
                      font: 'Arial',
                      size: 18
                    })
                  ]
                }),
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                    new TextRun({
                      text: (this.getValue(submission, "cultivo_contenedores_1_erquisitos_especificos_cultivo") ? '■' : '❏') + '  Requisitos específicos del cultivo',
                      font: 'Arial',
                      size: 18
                    })
                  ]
                }),
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                    new TextRun({
                      text: (this.getValue(submission, "cultivo_contenedores_1_manejo_plagas") ? '■' : '❏') + ' Manejo de plagas y/o enfermedades',
                      font: 'Arial',
                      size: 18
                    })
                  ]
                }),
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                    new TextRun({
                      text: `${this.getValue(submission, 'cultivo_contenedores_1_otro') === "Si" ? '■' : '❏'} Otro ¿Cuál?: ${this.getValue(submission, 'cultivo_contenedores_1_otro_text')}`,
                      font: 'Arial',
                      size: 18
                    })
                  ]
                }),
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                    new TextRun({
                      text: `Nota: Para cualquier caso, es necesario que se anexe una justificación por escrito donde se describan los detalles técnicos (con evidencia como análisis de laboratorio o recomendaciones de especialistas) del impedimento para desarrollar el cultivo en suelo.`,
                      font: 'Arial',
                      size: 18
                    })
                  ]
                })
              ],
              verticalAlign: VerticalAlign.TOP
            }),
            new TableCell({
              children: [
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                    new TextRun({
                      text: this.getValue(submission, "cultivo_contenedorees_1_comentarios"),
                      font: 'Arial',
                      size: 18
                    })
                  ]
                })
              ],
              verticalAlign: VerticalAlign.TOP
            }),
            new TableCell({
              children: [
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                      new TextRun({
                          text: ` ${this.getValue(submission, 'cultivo_contenedores_1_implementacion') === 'I' ? '■' : '❏'} I`,
                          font: 'Arial',
                          size: 18
                      })
                  ]
                }),
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                      new TextRun({
                          text: ` ${this.getValue(submission, 'cultivo_contenedores_1_implementacion') === 'NI' ? '■' : '❏'} NI`,
                          font: 'Arial',
                          size: 18
                      })
                  ]
                })
              ],
              verticalAlign: VerticalAlign.TOP
          }),
          new TableCell({
              children: [
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                      new TextRun({
                          text: ` ${this.getValue(submission, 'cultivo_contenedores_1_conformidad') === 'C' ? '■' : '❏'} C`,
                          font: 'Arial',
                          size: 18
                      })
                  ]
                }),
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                      new TextRun({
                          text: ` ${this.getValue(submission, 'cultivo_contenedores_1_conformidad') === 'NC' ? '■' : '❏'} NC`,
                          font: 'Arial',
                          size: 18
                      })
                  ]
                }),
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                      new TextRun({
                          text: ` ${this.getValue(submission, 'cultivo_contenedores_1_conformidad') === 'NA' ? '■' : '❏'} NA`,
                          font: 'Arial',
                          size: 18
                      })
                  ]
                })
              ],
              verticalAlign: VerticalAlign.TOP
          }),
          new TableCell({
              children: [
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                      new TextRun({
                          text: this.getValue(submission, 'cultivo_contenedores_1_comentarios_inspector'),
                          font: 'Arial',
                          size: 18
                      })
                  ]
                })
              ],
              verticalAlign: VerticalAlign.TOP
          })
          ]
        }),
        new TableRow({
          children: [
            new TableCell({
              children: [
                new Paragraph({
                  alignment: AlignmentType.CENTER,
                  children: [
                    new TextRun({
                      text: '2',
                      font: 'Arial',
                      size: 18
                    })
                  ]
                })
              ],
              verticalAlign: VerticalAlign.TOP
            }),
            new TableCell({
              children: [
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                    new TextRun({
                      text: '¿Sobre qué sustrato se desarrolla el cultivo?:',
                      font: 'Arial',
                      size: 18
                    })
                  ]
                }),
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                    new TextRun({
                      text: (this.getValue(submission, 'cultivo_contenedores_2_suelo') ? '■' : '❏') + ' Suelo',
                      font: 'Arial',
                      size: 18
                    })
                  ]
                }),
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                    new TextRun({
                      text: (this.getValue(submission, "cultivo_contenedores_2_sustrato") ? '■' : '❏') + ' Sustrato compuesto',
                      font: 'Arial',
                      size: 18
                    })
                  ]
                }),
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                    new TextRun({
                      text: (this.getValue(submission, "cultivo_contenedores_2_hidroponia") ? '■' : '❏') + ' Hidroponía',
                      font: 'Arial',
                      size: 18
                    })
                  ]
                }),
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                    new TextRun({
                      text: (this.getValue(submission, "cultivo_contenedores_2_aeroponia") ? '■' : '❏') + '  Aeroponia',
                      font: 'Arial',
                      size: 18
                    })
                  ]
                }),
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                    new TextRun({
                      text: (this.getValue(submission, "cultivo_contenedores_2_otro") ? '■' : '❏') + '  Otro (¿Cuál?): '
                        + this.getValue(submission, "cultivo_contenedores_2_otro_text"),
                      font: 'Arial',
                      size: 18
                    })
                  ]
                })
              ],
              verticalAlign: VerticalAlign.TOP
            }),
            new TableCell({
              children: [
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                    new TextRun({
                      text: this.getValue(submission, "cultivo_contenedores_2_comentarios"),
                      font: 'Arial',
                      size: 18
                    })
                  ]
                })
              ],
              verticalAlign: VerticalAlign.TOP
            }),
            new TableCell({
              children: [
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                      new TextRun({
                          text: ` ${this.getValue(submission, 'cultivo_contenedores_2_implementacion') === 'I' ? '■' : '❏'} I`,
                          font: 'Arial',
                          size: 18
                      })
                  ]
                }),
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                      new TextRun({
                          text: ` ${this.getValue(submission, 'cultivo_contenedores_2_implementacion') === 'NI' ? '■' : '❏'} NI`,
                          font: 'Arial',
                          size: 18
                      })
                  ]
                })
              ],
              verticalAlign: VerticalAlign.TOP
          }),
          new TableCell({
              children: [
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                      new TextRun({
                          text: ` ${this.getValue(submission, 'cultivo_contenedores_2_conformidad') === 'C' ? '■' : '❏'} C`,
                          font: 'Arial',
                          size: 18
                      })
                  ]
                }),
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                      new TextRun({
                          text: ` ${this.getValue(submission, 'cultivo_contenedores_2_conformidad') === 'NC' ? '■' : '❏'} NC`,
                          font: 'Arial',
                          size: 18
                      })
                  ]
                }),
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                      new TextRun({
                          text: ` ${this.getValue(submission, 'cultivo_contenedores_2_conformidad') === 'NA' ? '■' : '❏'} NA`,
                          font: 'Arial',
                          size: 18
                      })
                  ]
                })
              ],
              verticalAlign: VerticalAlign.TOP
          }),
          new TableCell({
              children: [
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                      new TextRun({
                          text: this.getValue(submission, 'cultivo_contenedores_2_comentarios_inspector'),
                          font: 'Arial',
                          size: 18
                      })
                  ]
                })
              ],
              verticalAlign: VerticalAlign.TOP
          })
          ]
        }),
        new TableRow({
          children: [
            new TableCell({
              children: [
                new Paragraph({
                  alignment: AlignmentType.CENTER,
                  children: [
                    new TextRun({
                      text: '3',
                      font: 'Arial',
                      size: 18
                    })
                  ]
                })
              ],
              verticalAlign: VerticalAlign.TOP
            }),
            new TableCell({
              children: [
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                    new TextRun({
                      text: 'En caso de usar suelo, ¿Cuántos meses se ha mantenido libre sustancias prohibidas?',
                      font: 'Arial',
                      size: 18
                    })
                  ]
                }),
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                    new TextRun({
                      text: (this.getValue(submission, "cultivo_contenedores_3_mas_36_meses") ? '■' : '❏') + '  36 meses o más',
                      font: 'Arial',
                      size: 18
                    })
                  ]
                }),
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                    new TextRun({
                      text: (this.getValue(submission, "cultivo_contenedores_3_menos_36_meses") ? '■' : '❏') + '  Menos de 36 meses (¿Cuántos?): ' + this.getValue(submission, "cultivo_contenedores_3_menos_36_meses_texto"),
                      font: 'Arial',
                      size: 18
                    })
                  ]
                })
              ],
              verticalAlign: VerticalAlign.TOP
            }),
            new TableCell({
              children: [
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                    new TextRun({
                      text: this.getValue(submission, "cultivo_contenedores_3_comentarios"),
                      font: 'Arial',
                      size: 18
                    })
                  ]
                })
              ],
              verticalAlign: VerticalAlign.TOP
            }),
            new TableCell({
              children: [
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                      new TextRun({
                          text: ` ${this.getValue(submission, 'cultivo_contenedores_3_implementacion') === 'I' ? '■' : '❏'} I`,
                          font: 'Arial',
                          size: 18
                      })
                  ]
                }),
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                      new TextRun({
                          text: ` ${this.getValue(submission, 'cultivo_contenedores_3_implementacion') === 'NI' ? '■' : '❏'} NI`,
                          font: 'Arial',
                          size: 18
                      })
                  ]
                })
              ],
              verticalAlign: VerticalAlign.TOP
          }),
          new TableCell({
              children: [
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                      new TextRun({
                          text: ` ${this.getValue(submission, 'cultivo_contenedores_3_conformidad') === 'C' ? '■' : '❏'} C`,
                          font: 'Arial',
                          size: 18
                      })
                  ]
                }),
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                      new TextRun({
                          text: ` ${this.getValue(submission, 'cultivo_contenedores_3_conformidad') === 'NC' ? '■' : '❏'} NC`,
                          font: 'Arial',
                          size: 18
                      })
                  ]
                }),
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                      new TextRun({
                          text: ` ${this.getValue(submission, 'cultivo_contenedores_3_conformidad') === 'NA' ? '■' : '❏'} NA`,
                          font: 'Arial',
                          size: 18
                      })
                  ]
                })
              ],
              verticalAlign: VerticalAlign.TOP
          }),
          new TableCell({
              children: [
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                      new TextRun({
                          text: this.getValue(submission, 'cultivo_contenedores_3_comentarios_inspector'),
                          font: 'Arial',
                          size: 18
                      })
                  ]
                })
              ],
              verticalAlign: VerticalAlign.TOP
          })
          ]
        }),
        new TableRow({
          children: [
            new TableCell({
              children: [
                new Paragraph({
                  alignment: AlignmentType.CENTER,
                  children: [
                    new TextRun({
                      text: '4',
                      font: 'Arial',
                      size: 18
                    })
                  ]
                })
              ],
              verticalAlign: VerticalAlign.TOP
            }),
            new TableCell({
              children: [
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                    new TextRun({
                      text: 'Para cultivo en contenedores describa los elementos que conforman el sustrato y en que proporciones:',
                      font: 'Arial',
                      size: 18
                    })
                  ]
                }),
                new Table({
                  rows: [
                    new TableRow({
                      children: [
                        new TableCell({
                          children: [
                            new Paragraph({
                              alignment: AlignmentType.CENTER,
                              children: [
                                new TextRun({
                                  text: 'Componente',
                                  font: 'Arial',
                                  size: 18,
                                  bold: true
                                })
                              ]
                            })
                          ],
                          verticalAlign: VerticalAlign.CENTER,
                          shading: {
                            fill: '#C0C0C0'
                          }
                        }),
                        new TableCell({
                          children: [
                            new Paragraph({
                              alignment: AlignmentType.CENTER,
                              children: [
                                new TextRun({
                                  text: 'Tipo',
                                  font: 'Arial',
                                  size: 18,
                                  bold: true
                                })
                              ]
                            })
                          ],
                          verticalAlign: VerticalAlign.CENTER,
                          shading: {
                            fill: '#C0C0C0'
                          }
                        }),
                        new TableCell({
                          children: [
                            new Paragraph({
                              alignment: AlignmentType.CENTER,
                              children: [
                                new TextRun({
                                  text: 'Proporcion',
                                  font: 'Arial',
                                  size: 18,
                                  bold: true
                                })
                              ]
                            })
                          ],
                          verticalAlign: VerticalAlign.CENTER,
                          shading: {
                            fill: '#C0C0C0'
                          }
                        })
                      ]
                    }),
                    new TableRow({
                      children: [
                        new TableCell({
                          children: [
                            new Paragraph({
                              alignment: AlignmentType.LEFT,
                              children: [
                                new TextRun({
                                  text: (this.getValue(submission, "Cambiar") ? '■' : '❏') + ' Composta:',
                                  font: 'Arial',
                                  size: 18,
                                })
                              ]
                            })
                          ],
                          verticalAlign: VerticalAlign.CENTER,
                        }),
                        new TableCell({
                          children: [
                            new Paragraph({
                              alignment: AlignmentType.LEFT,
                              children: [
                                new TextRun({
                                  text: this.getValue(submission, "cultivo_contenedores_4_composta_tipo"),
                                  font: 'Arial',
                                  size: 18,
                                })
                              ]
                            })
                          ],
                          verticalAlign: VerticalAlign.CENTER,
                        }),
                        new TableCell({
                          children: [
                            new Paragraph({
                              alignment: AlignmentType.LEFT,
                              children: [
                                new TextRun({
                                  text: this.getValue(submission, "cultivo_contenedores_4_composta_proporcion"),
                                  font: 'Arial',
                                  size: 18,
                                })
                              ]
                            })
                          ],
                          verticalAlign: VerticalAlign.CENTER,
                        })
                      ]
                    }),
                    new TableRow({
                      children: [
                        new TableCell({
                          children: [
                            new Paragraph({
                              alignment: AlignmentType.LEFT,
                              children: [
                                new TextRun({
                                  text: (this.getValue(submission, "cambiar") ? '■' : '❏') + ' Mineral (arena, limo, arcilla):',
                                  font: 'Arial',
                                  size: 18,
                                })
                              ]
                            })
                          ],
                          verticalAlign: VerticalAlign.CENTER,
                        }),
                        new TableCell({
                          children: [
                            new Paragraph({
                              alignment: AlignmentType.LEFT,
                              children: [
                                new TextRun({
                                  text: this.getValue(submission, "cultivo_contenedores_4_mineral_tipo"),
                                  font: 'Arial',
                                  size: 18,
                                })
                              ]
                            })
                          ],
                          verticalAlign: VerticalAlign.CENTER,
                        }),
                        new TableCell({
                          children: [
                            new Paragraph({
                              alignment: AlignmentType.LEFT,
                              children: [
                                new TextRun({
                                  text: this.getValue(submission, "cultivo_contenedores_4_mineral_proporcion"),
                                  font: 'Arial',
                                  size: 18,
                                })
                              ]
                            })
                          ],
                          verticalAlign: VerticalAlign.CENTER,
                        })
                      ]
                    }),
                    new TableRow({
                      children: [
                        new TableCell({
                          children: [
                            new Paragraph({
                              alignment: AlignmentType.LEFT,
                              children: [
                                new TextRun({
                                  text: (this.getValue(submission, "cambiar") ? '■' : '❏') + ' Otro mineral (perlita, vermiculita, otro)',
                                  font: 'Arial',
                                  size: 18,
                                })
                              ]
                            })
                          ],
                          verticalAlign: VerticalAlign.CENTER,
                        }),
                        new TableCell({
                          children: [
                            new Paragraph({
                              alignment: AlignmentType.LEFT,
                              children: [
                                new TextRun({
                                  text: this.getValue(submission, "cultivo_contenedores_4_otro_mineral_tipo"),
                                  font: 'Arial',
                                  size: 18,
                                })
                              ]
                            })
                          ],
                          verticalAlign: VerticalAlign.CENTER,
                        }),
                        new TableCell({
                          children: [
                            new Paragraph({
                              alignment: AlignmentType.LEFT,
                              children: [
                                new TextRun({
                                  text: this.getValue(submission, "cultivo_contenedores_4_otro_mineral_proporcion"),
                                  font: 'Arial',
                                  size: 18,
                                })
                              ]
                            })
                          ],
                          verticalAlign: VerticalAlign.CENTER,
                        })
                      ]
                    }),
                    new TableRow({
                      children: [
                        new TableCell({
                          children: [
                            new Paragraph({
                              alignment: AlignmentType.LEFT,
                              children: [
                                new TextRun({
                                  text: (this.getValue(submission, "cambiar") ? '■' : '❏') + ' Otro orgánico (fibra de coco, etc)',
                                  font: 'Arial',
                                  size: 18,
                                })
                              ]
                            })
                          ],
                          verticalAlign: VerticalAlign.CENTER,
                        }),
                        new TableCell({
                          children: [
                            new Paragraph({
                              alignment: AlignmentType.LEFT,
                              children: [
                                new TextRun({
                                  text: this.getValue(submission, "cultivo_contenedores_4_organico_tipo"),
                                  font: 'Arial',
                                  size: 18,
                                })
                              ]
                            })
                          ],
                          verticalAlign: VerticalAlign.CENTER,
                        }),
                        new TableCell({
                          children: [
                            new Paragraph({
                              alignment: AlignmentType.LEFT,
                              children: [
                                new TextRun({
                                  text: this.getValue(submission, "cultivo_contenedores_4_organico_proporcion"),
                                  font: 'Arial',
                                  size: 18,
                                })
                              ]
                            })
                          ],
                          verticalAlign: VerticalAlign.CENTER,
                        })
                      ]
                    }),
                    new TableRow({
                      children: [
                        new TableCell({
                          children: [
                            new Paragraph({
                              alignment: AlignmentType.LEFT,
                              children: [
                                new TextRun({
                                  text: (this.getValue(submission, "cambiar") ? '■' : '❏') + ' Organismos (lombrices, etc)',
                                  font: 'Arial',
                                  size: 18,
                                })
                              ]
                            })
                          ],
                          verticalAlign: VerticalAlign.CENTER,
                        }),
                        new TableCell({
                          children: [
                            new Paragraph({
                              alignment: AlignmentType.LEFT,
                              children: [
                                new TextRun({
                                  text: this.getValue(submission, "cultivo_contenedores_4_organismos_tipo"),
                                  font: 'Arial',
                                  size: 18,
                                })
                              ]
                            })
                          ],
                          verticalAlign: VerticalAlign.CENTER,
                        }),
                        new TableCell({
                          children: [
                            new Paragraph({
                              alignment: AlignmentType.LEFT,
                              children: [
                                new TextRun({
                                  text: this.getValue(submission, "cultivo_contenedores_4_proporcion"),
                                  font: 'Arial',
                                  size: 18,
                                })
                              ]
                            })
                          ],
                          verticalAlign: VerticalAlign.CENTER,
                        })
                      ]
                    }),
                    new TableRow({
                      children: [
                        new TableCell({
                          children: [
                            new Paragraph({
                              alignment: AlignmentType.LEFT,
                              children: [
                                new TextRun({
                                  text: (this.getValue(submission, "cambiar") ? '■' : '❏') + " Otro: (Cual?): " + this.getValue(submission, "cultivo_contenedores_4_otro_texto"),
                                  font: 'Arial',
                                  size: 18,
                                })
                              ]
                            })
                          ],
                          verticalAlign: VerticalAlign.CENTER,
                        }),
                        new TableCell({
                          children: [
                            new Paragraph({
                              alignment: AlignmentType.LEFT,
                              children: [
                                new TextRun({
                                  text: this.getValue(submission, "cultivo_contenedores_4_otro_tipo"),
                                  font: 'Arial',
                                  size: 18,
                                })
                              ]
                            })
                          ],
                          verticalAlign: VerticalAlign.CENTER,
                        }),
                        new TableCell({
                          children: [
                            new Paragraph({
                              alignment: AlignmentType.LEFT,
                              children: [
                                new TextRun({
                                  text: this.getValue(submission, "cultivo_contenedores_4_otro_proporcion"),
                                  font: 'Arial',
                                  size: 18,
                                })
                              ]
                            })
                          ],
                          verticalAlign: VerticalAlign.CENTER,
                        })
                      ]
                    }),
                  ],
                  margins: {
                    left: 70,
                    right: 70
                  },
                  columnWidths: [1250, 1250, 1250]
                })
              ],
              verticalAlign: VerticalAlign.TOP
            }),
            new TableCell({
              children: [
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                    new TextRun({
                      text: this.getValue(submission, "cultivo_contenedores_4_comentarios"),
                      font: 'Arial',
                      size: 18
                    })
                  ]
                })
              ],
              verticalAlign: VerticalAlign.TOP
            }),
            new TableCell({
              children: [
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                      new TextRun({
                          text: ` ${this.getValue(submission, 'cultivo_contenedores_4_implementacion') === 'I' ? '■' : '❏'} I`,
                          font: 'Arial',
                          size: 18
                      })
                  ]
                }),
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                      new TextRun({
                          text: ` ${this.getValue(submission, 'cultivo_contenedores_4_implementacion') === 'NI' ? '■' : '❏'} NI`,
                          font: 'Arial',
                          size: 18
                      })
                  ]
                })
              ],
              verticalAlign: VerticalAlign.TOP
          }),
          new TableCell({
              children: [
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                      new TextRun({
                          text: ` ${this.getValue(submission, 'cultivo_contenedores_4_conformidad') === 'C' ? '■' : '❏'} C`,
                          font: 'Arial',
                          size: 18
                      })
                  ]
                }),
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                      new TextRun({
                          text: ` ${this.getValue(submission, 'cultivo_contenedores_4_conformidad') === 'NC' ? '■' : '❏'} NC`,
                          font: 'Arial',
                          size: 18
                      })
                  ]
                }),
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                      new TextRun({
                          text: ` ${this.getValue(submission, 'cultivo_contenedores_4_conformidad') === 'NA' ? '■' : '❏'} NA`,
                          font: 'Arial',
                          size: 18
                      })
                  ]
                })
              ],
              verticalAlign: VerticalAlign.TOP
          }),
          new TableCell({
              children: [
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                      new TextRun({
                          text: this.getValue(submission, 'cultivo_contenedores_4_comentarios_inspector'),
                          font: 'Arial',
                          size: 18
                      })
                  ]
                })
              ],
              verticalAlign: VerticalAlign.TOP
          })
          ]
        }),
        new TableRow({
          children: [
            new TableCell({
              children: [
                new Paragraph({
                  alignment: AlignmentType.CENTER,
                  children: [
                    new TextRun({
                      text: '5',
                      font: 'Arial',
                      size: 18
                    })
                  ]
                })
              ],
              verticalAlign: VerticalAlign.TOP
            }),
            new TableCell({
              children: [
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                    new TextRun({
                      text: 'El volumen inicial del sustrato está calculado en función de:',
                      font: 'Arial',
                      size: 18
                    })
                  ]
                }),
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                    new TextRun({
                      text: (this.getValue(submission, "cultivo_contenedores_5_tamano_planta") ? '■' : '❏') + ' Tamaño de la planta',
                      font: 'Arial',
                      size: 18
                    })
                  ]
                }),
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                    new TextRun({
                      text: (this.getValue(submission, "cultivo_contenedores_5_tasa_crecimiento") ? '■' : '❏') + ' Tasa de crecimiento',
                      font: 'Arial',
                      size: 18
                    })
                  ]
                }),
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                    new TextRun({
                      text: (this.getValue(submission, "cultivo_contenedores_5_rendimiento") ? '■' : '❏') + ' Rendimiento objetivo',
                      font: 'Arial',
                      size: 18
                    })
                  ]
                }),
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                    new TextRun({
                      text: (this.getValue(submission, "cultivo_contenedores_5_duracion_ciclo") ? '■' : '❏') + ' Duración del ciclo de cultivo',
                      font: 'Arial',
                      size: 18
                    })
                  ]
                }),
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                    new TextRun({
                      text: (this.getValue(submission, "cultivo_contenedores_5_otro") ? '■' : '❏') + ' Otro: ' + this.getValue(submission, "cultivo_contenedores_5_otro_text"),
                      font: 'Arial',
                      size: 18
                    })
                  ]
                })
              ],
              verticalAlign: VerticalAlign.TOP
            }),
            new TableCell({
              children: [
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                    new TextRun({
                      text: this.getValue(submission, "cultivo_contenedores_5_comentarios"),
                      font: 'Arial',
                      size: 18
                    })
                  ]
                })
              ],
              verticalAlign: VerticalAlign.TOP
            }),
            new TableCell({
              children: [
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                      new TextRun({
                          text: ` ${this.getValue(submission, 'cultivo_contenedores_5_implementacion') === 'I' ? '■' : '❏'} I`,
                          font: 'Arial',
                          size: 18
                      })
                  ]
                }),
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                      new TextRun({
                          text: ` ${this.getValue(submission, 'cultivo_contenedores_5_implementacion') === 'NI' ? '■' : '❏'} NI`,
                          font: 'Arial',
                          size: 18
                      })
                  ]
                })
              ],
              verticalAlign: VerticalAlign.TOP
          }),
          new TableCell({
              children: [
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                      new TextRun({
                          text: ` ${this.getValue(submission, 'cultivo_contenedores_5_conformidad') === 'C' ? '■' : '❏'} C`,
                          font: 'Arial',
                          size: 18
                      })
                  ]
                }),
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                      new TextRun({
                          text: ` ${this.getValue(submission, 'cultivo_contenedores_5_conformidad') === 'NC' ? '■' : '❏'} NC`,
                          font: 'Arial',
                          size: 18
                      })
                  ]
                }),
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                      new TextRun({
                          text: ` ${this.getValue(submission, 'cultivo_contenedores_5_conformidad') === 'NA' ? '■' : '❏'} NA`,
                          font: 'Arial',
                          size: 18
                      })
                  ]
                })
              ],
              verticalAlign: VerticalAlign.TOP
          }),
          new TableCell({
              children: [
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                      new TextRun({
                          text: this.getValue(submission, 'cultivo_contenedores_5_comentarios_inspector'),
                          font: 'Arial',
                          size: 18
                      })
                  ]
                })
              ],
              verticalAlign: VerticalAlign.TOP
          })
          ]
        }),
        new TableRow({
          children: [
            new TableCell({
              children: [
                new Paragraph({
                  alignment: AlignmentType.CENTER,
                  children: [
                    new TextRun({
                      text: '6',
                      font: 'Arial',
                      size: 18
                    })
                  ]
                })
              ],
              verticalAlign: VerticalAlign.TOP
            }),
            new TableCell({
              children: [
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                    new TextRun({
                      text: '¿De qué tamaño es el área fotosintética de su instalación (calculada en función de 7.5.2.2 de CAN/CGSB-32.310?',
                      font: 'Arial',
                      size: 18
                    })
                  ]
                })
              ],
              verticalAlign: VerticalAlign.TOP
            }),
            new TableCell({
              children: [
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                    new TextRun({
                      text: this.getValue(submission, "cultivo_contenedores_6_comentarios"),
                      font: 'Arial',
                      size: 18
                    })
                  ]
                })
              ],
              verticalAlign: VerticalAlign.TOP
            }),
            new TableCell({
              children: [
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                      new TextRun({
                          text: ` ${this.getValue(submission, 'cultivo_contenedores_6_implementacion') === 'I' ? '■' : '❏'} I`,
                          font: 'Arial',
                          size: 18
                      })
                  ]
                }),
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                      new TextRun({
                          text: ` ${this.getValue(submission, 'cultivo_contenedores_6_implementacion') === 'NI' ? '■' : '❏'} NI`,
                          font: 'Arial',
                          size: 18
                      })
                  ]
                })
              ],
              verticalAlign: VerticalAlign.TOP
          }),
          new TableCell({
              children: [
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                      new TextRun({
                          text: ` ${this.getValue(submission, 'cultivo_contenedores_6_conformidad') === 'C' ? '■' : '❏'} C`,
                          font: 'Arial',
                          size: 18
                      })
                  ]
                }),
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                      new TextRun({
                          text: ` ${this.getValue(submission, 'cultivo_contenedores_6_conformidad') === 'NC' ? '■' : '❏'} NC`,
                          font: 'Arial',
                          size: 18
                      })
                  ]
                }),
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                      new TextRun({
                          text: ` ${this.getValue(submission, 'cultivo_contenedores_6_conformidad') === 'NA' ? '■' : '❏'} NA`,
                          font: 'Arial',
                          size: 18
                      })
                  ]
                })
              ],
              verticalAlign: VerticalAlign.TOP
          }),
          new TableCell({
              children: [
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                      new TextRun({
                          text: this.getValue(submission, 'cultivo_contenedores_6_comentarios_inspector'),
                          font: 'Arial',
                          size: 18
                      })
                  ]
                })
              ],
              verticalAlign: VerticalAlign.TOP
          })
          ]
        }),
        new TableRow({
          children: [
            new TableCell({
              children: [
                new Paragraph({
                  alignment: AlignmentType.CENTER,
                  children: [
                    new TextRun({
                      text: '7',
                      font: 'Arial',
                      size: 18
                    })
                  ]
                })
              ],
              verticalAlign: VerticalAlign.TOP
            }),
            new TableCell({
              children: [
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                    new TextRun({
                      text: '¿Cuál es el volumen de suelo mantenido en los contenedores desde la siembra o trasplante hasta el final de la cosecha?',
                      font: 'Arial',
                      size: 18
                    })
                  ]
                })
              ],
              verticalAlign: VerticalAlign.TOP
            }),
            new TableCell({
              children: [
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                    new TextRun({
                      text: this.getValue(submission, "cultivo_contenedores_7_comentarios"),
                      font: 'Arial',
                      size: 18
                    })
                  ]
                })
              ],
              verticalAlign: VerticalAlign.TOP
            }),
            new TableCell({
              children: [
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                      new TextRun({
                          text: ` ${this.getValue(submission, 'cultivo_contenedores_7_implementacion') === 'I' ? '■' : '❏'} I`,
                          font: 'Arial',
                          size: 18
                      })
                  ]
                }),
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                      new TextRun({
                          text: ` ${this.getValue(submission, 'cultivo_contenedores_7_implementacion') === 'NI' ? '■' : '❏'} NI`,
                          font: 'Arial',
                          size: 18
                      })
                  ]
                })
              ],
              verticalAlign: VerticalAlign.TOP
          }),
          new TableCell({
              children: [
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                      new TextRun({
                          text: ` ${this.getValue(submission, 'cultivo_contenedores_7_conformidad') === 'C' ? '■' : '❏'} C`,
                          font: 'Arial',
                          size: 18
                      })
                  ]
                }),
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                      new TextRun({
                          text: ` ${this.getValue(submission, 'cultivo_contenedores_7_conformidad') === 'NC' ? '■' : '❏'} NC`,
                          font: 'Arial',
                          size: 18
                      })
                  ]
                }),
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                      new TextRun({
                          text: ` ${this.getValue(submission, 'cultivo_contenedores_7_conformidad') === 'NA' ? '■' : '❏'} NA`,
                          font: 'Arial',
                          size: 18
                      })
                  ]
                })
              ],
              verticalAlign: VerticalAlign.TOP
          }),
          new TableCell({
              children: [
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                      new TextRun({
                          text: this.getValue(submission, 'cultivo_contenedores_7_comentarios_inspector'),
                          font: 'Arial',
                          size: 18
                      })
                  ]
                })
              ],
              verticalAlign: VerticalAlign.TOP
          })
          ]
        }),
        new TableRow({
          children: [
            new TableCell({
              children: [
                new Paragraph({
                  alignment: AlignmentType.CENTER,
                  children: [
                    new TextRun({
                      text: '8',
                      font: 'Arial',
                      size: 18
                    })
                  ]
                })
              ],
              verticalAlign: VerticalAlign.TOP
            }),
            new TableCell({
              children: [
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                    new TextRun({
                      text: 'Para la nutrición posterior a la siembra del cultivo en contenedores se utilizan las siguientes opciones:',
                      font: 'Arial',
                      size: 18
                    })
                  ]
                }),
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                    new TextRun({
                      text: (this.getValue(submission, "cultivo_contenedores_8_fertilizantes_diluidos") ? '■' : '❏') + ' Fertilizantes orgánicos diluidos en el riego',
                      font: 'Arial',
                      size: 18
                    })
                  ]
                }),
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                    new TextRun({
                      text: (this.getValue(submission, "cultivo_contenedores_8_fertilizantes_solidos") ? '■' : '❏') + ' Fertilizantes orgánicos sólidos agregados en los contenedores',
                      font: 'Arial',
                      size: 18
                    })
                  ]
                }),
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                    new TextRun({
                      text: (this.getValue(submission, "cultivo_contenedores_8_micro_organismos") ? '■' : '❏') + ' Micro-organismos',
                      font: 'Arial',
                      size: 18
                    })
                  ]
                }),
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                    new TextRun({
                      text: (this.getValue(submission, "cultivo_contenedores_8_otro") ? '■' : '❏') + ' Otro ¿Cuál? ' + this.getValue(submission, "cultivo_contenedores_8_otro_text"),
                      font: 'Arial',
                      size: 18
                    })
                  ]
                })
              ],
              verticalAlign: VerticalAlign.TOP
            }),
            new TableCell({
              children: [
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                    new TextRun({
                      text: this.getValue(submission, "cultivo_contenedores_8_comentarios"),
                      font: 'Arial',
                      size: 18
                    })
                  ]
                })
              ],
              verticalAlign: VerticalAlign.TOP
            }),
            new TableCell({
              children: [
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                      new TextRun({
                          text: ` ${this.getValue(submission, 'cultivo_contenedores_7_implementacion') === 'I' ? '■' : '❏'} I`,
                          font: 'Arial',
                          size: 18
                      })
                  ]
                }),
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                      new TextRun({
                          text: ` ${this.getValue(submission, 'cultivo_contenedores_7_implementacion') === 'NI' ? '■' : '❏'} NI`,
                          font: 'Arial',
                          size: 18
                      })
                  ]
                })
              ],
              verticalAlign: VerticalAlign.TOP
          }),
          new TableCell({
              children: [
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                      new TextRun({
                          text: ` ${this.getValue(submission, 'cultivo_contenedores_7_conformidad') === 'C' ? '■' : '❏'} C`,
                          font: 'Arial',
                          size: 18
                      })
                  ]
                }),
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                      new TextRun({
                          text: ` ${this.getValue(submission, 'cultivo_contenedores_7_conformidad') === 'NC' ? '■' : '❏'} NC`,
                          font: 'Arial',
                          size: 18
                      })
                  ]
                }),
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                      new TextRun({
                          text: ` ${this.getValue(submission, 'cultivo_contenedores_7_conformidad') === 'NA' ? '■' : '❏'} NA`,
                          font: 'Arial',
                          size: 18
                      })
                  ]
                })
              ],
              verticalAlign: VerticalAlign.TOP
          }),
          new TableCell({
              children: [
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                      new TextRun({
                          text: this.getValue(submission, 'cultivo_contenedores_7_comentarios_inspector'),
                          font: 'Arial',
                          size: 18
                      })
                  ]
                })
              ],
              verticalAlign: VerticalAlign.TOP
          })
          ]
        }),
        new TableRow({
          children: [
            new TableCell({
              children: [
                new Paragraph({
                  alignment: AlignmentType.CENTER,
                  children: [
                    new TextRun({
                      text: '9',
                      font: 'Arial',
                      size: 18
                    })
                  ]
                })
              ],
              verticalAlign: VerticalAlign.TOP
            }),
            new TableCell({
              children: [
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                    new TextRun({
                      text: 'Indicar el tamaño y material y tipo de contenedores usados:',
                      font: 'Arial',
                      size: 18
                    })
                  ]
                }),
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                    new TextRun({
                      text: 'Altura '+this.getValue(submission, "cultivo_contenedores_9_altura"),
                      font: 'Arial',
                      size: 18
                    })
                  ]
                }),
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                    new TextRun({
                      text: 'Volumen '+this.getValue(submission, "cultivo_contenedores_9_volumen"),
                      font: 'Arial',
                      size: 18
                    })
                  ]
                }),
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                    new TextRun({
                      text: 'Material '+this.getValue(submission, "cultivo_contenedores_9_material"),
                      font: 'Arial',
                      size: 18
                    })
                  ]
                })
              ],
              verticalAlign: VerticalAlign.TOP
            }),
            new TableCell({
              children: [
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                    new TextRun({
                      text: this.getValue(submission, "cultivo_contenedores_9_comentarios"),
                      font: 'Arial',
                      size: 18
                    })
                  ]
                })
              ],
              verticalAlign: VerticalAlign.TOP
            }),
            new TableCell({
              children: [
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                      new TextRun({
                          text: ` ${this.getValue(submission, 'cultivo_contenedores_9_implementacion') === 'I' ? '■' : '❏'} I`,
                          font: 'Arial',
                          size: 18
                      })
                  ]
                }),
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                      new TextRun({
                          text: ` ${this.getValue(submission, 'cultivo_contenedores_9_implementacion') === 'NI' ? '■' : '❏'} NI`,
                          font: 'Arial',
                          size: 18
                      })
                  ]
                })
              ],
              verticalAlign: VerticalAlign.TOP
          }),
          new TableCell({
              children: [
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                      new TextRun({
                          text: ` ${this.getValue(submission, 'cultivo_contenedores_9_conformidad') === 'C' ? '■' : '❏'} C`,
                          font: 'Arial',
                          size: 18
                      })
                  ]
                }),
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                      new TextRun({
                          text: ` ${this.getValue(submission, 'cultivo_contenedores_9_conformidad') === 'NC' ? '■' : '❏'} NC`,
                          font: 'Arial',
                          size: 18
                      })
                  ]
                }),
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                      new TextRun({
                          text: ` ${this.getValue(submission, 'cultivo_contenedores_9_conformidad') === 'NA' ? '■' : '❏'} NA`,
                          font: 'Arial',
                          size: 18
                      })
                  ]
                })
              ],
              verticalAlign: VerticalAlign.TOP
          }),
          new TableCell({
              children: [
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                      new TextRun({
                          text: this.getValue(submission, 'cultivo_contenedores_9_comentarios_inspector'),
                          font: 'Arial',
                          size: 18
                      })
                  ]
                })
              ],
              verticalAlign: VerticalAlign.TOP
          })
          ]
        }),
        new TableRow({
          children: [
            new TableCell({
              children: [
                new Paragraph({
                  alignment: AlignmentType.CENTER,
                  children: [
                    new TextRun({
                      text: '10',
                      font: 'Arial',
                      size: 18
                    })
                  ]
                })
              ],
              verticalAlign: VerticalAlign.TOP
            }),
            new TableCell({
              children: [
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                    new TextRun({
                      text: 'Cuáles prácticas se implementan para regenerar y reciclar los suelos:',
                      font: 'Arial',
                      size: 18
                    })
                  ]
                }),
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                    new TextRun({
                      text: (this.getValue(submission, "cultivo_contenedores_10_fertilizantes_diluidos") ? '■' : '❏') + ' Injerto sobre patrones resistentes',
                      font: 'Arial',
                      size: 18
                    })
                  ]
                }),
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                    new TextRun({
                      text: (this.getValue(submission, "cultivo_contenedores_10_fertilizantes_solidos") ? '■' : '❏') + ' Congelar suelo en invierno',
                      font: 'Arial',
                      size: 18
                    })
                  ]
                }),
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                    new TextRun({
                      text: (this.getValue(submission, "cultivo_contenedores_10_micro_organismos") ? '■' : '❏') + ' Uso de mantillo biodegradable',
                      font: 'Arial',
                      size: 18
                    })
                  ]
                }),
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                    new TextRun({
                      text: (this.getValue(submission, "cultivo_contenedores_10_micro_organismos") ? '■' : '❏') + ' Remplazo total o parcial del suelo',
                      font: 'Arial',
                      size: 18
                    })
                  ]
                }),
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                    new TextRun({
                      text: (this.getValue(submission, "cultivo_contenedores_10_otro") ? '■' : '❏') + ' Otro (¿Cuál?): ' + this.getValue(submission, "cultivo_contenedores_10_otro_text"),
                      font: 'Arial',
                      size: 18
                    })
                  ]
                })
              ],
              verticalAlign: VerticalAlign.TOP
            }),
            new TableCell({
              children: [
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                    new TextRun({
                      text: this.getValue(submission, "cultivo_contenedores_10_comentarios"),
                      font: 'Arial',
                      size: 18
                    })
                  ]
                })
              ],
              verticalAlign: VerticalAlign.TOP
            }),
            new TableCell({
              children: [
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                      new TextRun({
                          text: ` ${this.getValue(submission, 'cultivo_contenedores_10_implementacion') === 'I' ? '■' : '❏'} I`,
                          font: 'Arial',
                          size: 18
                      })
                  ]
                }),
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                      new TextRun({
                          text: ` ${this.getValue(submission, 'cultivo_contenedores_10_implementacion') === 'NI' ? '■' : '❏'} NI`,
                          font: 'Arial',
                          size: 18
                      })
                  ]
                })
              ],
              verticalAlign: VerticalAlign.TOP
          }),
          new TableCell({
              children: [
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                      new TextRun({
                          text: ` ${this.getValue(submission, 'cultivo_contenedores_10_conformidad') === 'C' ? '■' : '❏'} C`,
                          font: 'Arial',
                          size: 18
                      })
                  ]
                }),
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                      new TextRun({
                          text: ` ${this.getValue(submission, 'cultivo_contenedores_10_conformidad') === 'NC' ? '■' : '❏'} NC`,
                          font: 'Arial',
                          size: 18
                      })
                  ]
                }),
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                      new TextRun({
                          text: ` ${this.getValue(submission, 'cultivo_contenedores_10_conformidad') === 'NA' ? '■' : '❏'} NA`,
                          font: 'Arial',
                          size: 18
                      })
                  ]
                })
              ],
              verticalAlign: VerticalAlign.TOP
          }),
          new TableCell({
              children: [
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                      new TextRun({
                          text: this.getValue(submission, 'cultivo_contenedores_10_comentarios_inspector'),
                          font: 'Arial',
                          size: 18
                      })
                  ]
                })
              ],
              verticalAlign: VerticalAlign.TOP
          })
          ]
        }),
        new TableRow({
          children: [
            new TableCell({
              children: [
                new Paragraph({
                  alignment: AlignmentType.CENTER,
                  children: [
                    new TextRun({
                      text: '11',
                      font: 'Arial',
                      size: 18
                    })
                  ]
                })
              ],
              verticalAlign: VerticalAlign.TOP
            }),
            new TableCell({
              children: [
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                    new TextRun({
                      text: '¿Cómo se compensa el impacto en los recursos naturales?',
                      font: 'Arial',
                      size: 18
                    })
                  ]
                }),
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                    new TextRun({
                      text: (this.getValue(submission, "cultivo_contenedores_11_plantan_arboles") ? '■' : '❏') + ' Se plantan árboles',
                      font: 'Arial',
                      size: 18
                    })
                  ]
                }),
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                    new TextRun({
                      text: (this.getValue(submission, "cultivo_contenedores_11_mantiene_diversidad") ? '■' : '❏') + ' Se mantiene diversidad (otras plantas) en áreas sin cultivo',
                      font: 'Arial',
                      size: 18
                    })
                  ]
                }),
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                    new TextRun({
                      text: (this.getValue(submission, "cultivo_contenedores_11_mantiene_conservacion") ? '■' : '❏') + ' Se mantienen áreas de conservación',
                      font: 'Arial',
                      size: 18
                    })
                  ]
                }),
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                    new TextRun({
                      text: (this.getValue(submission, "cultivo_contenedores_11_protegen_fuentes_agua") ? '■' : '❏') + ' Se protegen fuentes de agua',
                      font: 'Arial',
                      size: 18
                    })
                  ]
                }),
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                    new TextRun({
                      text: (this.getValue(submission, "cultivo_contenedores_11_incorporan_residuos") ? '■' : '❏') + ' Se incorporan residuos vegetales en el suelo de áreas cercanas',
                      font: 'Arial',
                      size: 18
                    })
                  ]
                }),
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                    new TextRun({
                      text: (this.getValue(submission, "cultivo_contenedores_11_otro") ? '■' : '❏') + ' Otro (¿Cuál?): ' + this.getValue(submission, "cultivo_contenedores_11_otro_text"),
                      font: 'Arial',
                      size: 18
                    })
                  ]
                })
              ],
              verticalAlign: VerticalAlign.TOP
            }),
            new TableCell({
              children: [
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                    new TextRun({
                      text: this.getValue(submission, "cultivo_contenedores_11_comentarios"),
                      font: 'Arial',
                      size: 18
                    })
                  ]
                })
              ],
              verticalAlign: VerticalAlign.TOP
            }),
            new TableCell({
              children: [
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                      new TextRun({
                          text: ` ${this.getValue(submission, 'cultivo_contenedores_11_implementacion') === 'I' ? '■' : '❏'} I`,
                          font: 'Arial',
                          size: 18
                      })
                  ]
                }),
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                      new TextRun({
                          text: ` ${this.getValue(submission, 'cultivo_contenedores_11_implementacion') === 'NI' ? '■' : '❏'} NI`,
                          font: 'Arial',
                          size: 18
                      })
                  ]
                })
              ],
              verticalAlign: VerticalAlign.TOP
          }),
          new TableCell({
              children: [
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                      new TextRun({
                          text: ` ${this.getValue(submission, 'cultivo_contenedores_11_conformidad') === 'C' ? '■' : '❏'} C`,
                          font: 'Arial',
                          size: 18
                      })
                  ]
                }),
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                      new TextRun({
                          text: ` ${this.getValue(submission, 'cultivo_contenedores_11_conformidad') === 'NC' ? '■' : '❏'} NC`,
                          font: 'Arial',
                          size: 18
                      })
                  ]
                }),
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                      new TextRun({
                          text: ` ${this.getValue(submission, 'cultivo_contenedores_11_conformidad') === 'NA' ? '■' : '❏'} NA`,
                          font: 'Arial',
                          size: 18
                      })
                  ]
                })
              ],
              verticalAlign: VerticalAlign.TOP
          }),
          new TableCell({
              children: [
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                      new TextRun({
                          text: this.getValue(submission, 'cultivo_contenedores_11_comentarios_inspector'),
                          font: 'Arial',
                          size: 18
                      })
                  ]
                })
              ],
              verticalAlign: VerticalAlign.TOP
          })
          ]
        })

      ],
      margins: {
        left: 70,
        right: 70
      },
      //9885
      columnWidths: [450, 3895, 2045, 1150, 800, 1545]
    })
    return table
  }

  public createTableUDP(data, datePipe): Table {
    const table = new Table({
      rows: [
        new TableRow({
          children: [
            new TableCell({
              children: [
                new Paragraph({
                  alignment: AlignmentType.RIGHT,
                  children: [
                    new TextRun({
                      text: 'Operador: ',
                      font: 'Calibri (Cuerpo)',
                      size: 22,
                      bold: true,

                    })
                  ],
                  spacing: {before: 100, after: 200}
                })],
              verticalAlign: VerticalAlign.CENTER,
            }),
            new TableCell({
              children: [new Paragraph({
                alignment: AlignmentType.CENTER,
                children: [
                  new TextRun({
                    text: data.customer.Company_Name,
                    font: 'Calibri (Cuerpo)',
                    size: 22,
                  })
                ],
              })],
              verticalAlign: VerticalAlign.CENTER,
              columnSpan: 3,
            }),
          ],
        }),
        new TableRow({
          children: [
            new TableCell({
              children: [
                new Paragraph({
                  alignment: AlignmentType.RIGHT,
                  children: [
                    new TextRun({
                      text: 'Código del operador: ',
                      font: 'Calibri (Cuerpo)',
                      size: 22,
                      bold: true,

                    })
                  ],
                  spacing: {before: 100, after: 200}
                })],
              width: {
                size: 25,
                type: WidthType.PERCENTAGE,
              },
            }),
            new TableCell({
              children: [new Paragraph({
                alignment: AlignmentType.CENTER,
                children: [
                  new TextRun({
                    text: data.customer.Id_Comercial,
                    font: 'Calibri (Cuerpo)',
                    size: 22,
                  })
                ],
              })],
              verticalAlign: VerticalAlign.CENTER,
              width: {
                size: 25,
                type: WidthType.PERCENTAGE,
              },
            }),
            new TableCell({
              children: [
                new Paragraph({
                  alignment: AlignmentType.RIGHT,
                  children: [
                    new TextRun({
                      text: 'Fecha: ',
                      font: 'Calibri (Cuerpo)',
                      size: 22,
                      bold: true,

                    })
                  ],
                  spacing: {before: 100, after: 200}
                })],
              verticalAlign: VerticalAlign.CENTER,
              width: {
                size: 25,
                type: WidthType.PERCENTAGE,
              },
            }),
            new TableCell({
              children: [new Paragraph({
                alignment: AlignmentType.CENTER,
                children: [
                  new TextRun({
                    text: datePipe.transform(data.record.Applications[0].Creation_Date.includes('UTC') ? data.record.Applications[0].Creation_Date.replace('UTC', '').trim() : data.record.Applications[0].Creation_Date, "d-MMMM-y"),
                    font: 'Calibri (Cuerpo)',
                    size: 22,
                  })
                ],
              })],
              verticalAlign: VerticalAlign.CENTER,
              width: {
                size: 25,
                type: WidthType.PERCENTAGE,
              },
            }),
          ],
        }),
      ],
      margins: {
        left: 70,
        right: 70
      },
      width: {
        size: 100,
        type: WidthType.PERCENTAGE,
      },
      //9638
      columnWidths: [2410, 2410, 2409, 2409]
    })

    return table
  }

  getValue(submission, name) {
    var value = null
    for (const i in submission) {
      const element = submission[i]
      if (element.name === name) {
        value = element.value
      }
    }
    if (value) return value
    else return ''
  }

  getValueList(submission, name) {
    var value = null
    for (const i in submission) {
      const element = submission[i]
      if (element.name === name) {
        value = element.value
      }
    }
    if (value) return value
    else return []
  }

  // END MTO_FSOL_08_1_2
}
