<div class="d-flex justify-content-center">
    <div id="animalStepper" class="md-stepper-horizontal green scrollmenu">
        <div class="md-step" style="max-width: 200px; overflow: hidden;"
            [class]="currentIndex == i ? 'editable active' : step.submitted ? 'done': ''"
            [ngStyle]="{'width': step.hidden && !step.submitted ? '0px' : '200px', 'display': step.hidden ? 'none' : 'table-cell'}"
            *ngFor="let step of forms; let i = index;" (click)="goToForm(i)" [id]="'' + i">
            <div *ngIf="!step.hidden">
                <div class="md-step-circle green"><span>{{i+1}}</span></div>
                <div class="md-step-title">{{step.formname ? step.formname : 'ANEXOS'}}<br>{{step.title}}</div>
                <div class="md-step-bar-left"></div>
                <div class="md-step-bar-right"></div>
            </div>
        </div>
    </div>
</div>