import { AlignmentType, Document, PageBreak, Paragraph, TextRun, Media, Header, Footer, Table, TableRow, TableCell, WidthType, PageNumber } from "docx";
import { logo } from "../logo";
import { userWithoutSign } from "../userWithoutSign"
import { DatePipe } from '@angular/common';

//Moment import
const moment = require('moment')
moment.locale('es-mx')

export class DocumentCreatorMTO_FSOL_14_v2 {

    public getBase64FromUrl = async (url) => {
        const data = await fetch(url, {
            headers: {
                'Cache-Control': 'no-cache, no-store, must-revalidate',
                'Pragma': 'no-cache',
                'Expires': 'Sat, 01 Jan 2000 00:00:00 GMT',
                'If-Modified-Since': '0'
            }
        });
        const blob = await data.blob();
        return new Promise((resolve) => {
            const reader = new FileReader();
            reader.readAsDataURL(blob);
            reader.onloadend = function() {
                const base64data = reader.result;
                resolve(base64data);
            }
        });
    }

    // INIT MTO_FSOL_14
    public async create_MTO_FSOL_14(data, signature = ''): Promise<Document> {
        const document = new Document();
        const image = Media.addImage(document, logo, 50, 50);
        var datePipe = new DatePipe('es-MX');
        let signatureImage = null
        if (signature) {
            signatureImage = await this.getBase64FromUrl(signature)
        } else {
            signatureImage = userWithoutSign
        }

        document.addSection({
            headers: {
                default: new Header({
                    children: [
                        new Paragraph({ alignment: AlignmentType.CENTER, children: [image], }),
                        new Paragraph({
                            alignment: AlignmentType.CENTER, children: [
                                new TextRun({
                                    children: [`MTO-FSOL-14 | R 01 | 16-Diciembre-2020 | Pág. `, PageNumber.CURRENT],
                                    font: 'Book Antiqua',
                                    size: 20,
                                    bold: true
                                }),
                                new TextRun({
                                    font: 'Book Antiqua',
                                    size: 20,
                                    bold: true,
                                    children: [' de ', PageNumber.TOTAL_PAGES]
                                }),
                            ], spacing: { before: 200, after: 100 }
                        })
                    ]
                })
            },
            footers: {
                default: new Footer({
                    children: [
                        new Paragraph({ alignment: AlignmentType.CENTER }),
                        this.createFooter()
                    ]
                }),
            },
            children: [
                new Paragraph({
                    alignment: AlignmentType.CENTER,
                    children: [
                        new TextRun({
                            text: 'Solicitud de excepción para producción paralela',
                            font: 'Calibri (Cuerpo)',
                            size: 22,
                            bold: true
                        })
                    ],
                    spacing: { before: 400, after: 900 }
                }),
                new Paragraph({
                    alignment: AlignmentType.RIGHT,
                    children: [
                        new TextRun({
                            text: `Morelia Michoacán, ${moment().format('DD [de] MMMM [de] YYYY')}`,
                            font: 'Arial',
                            size: 22
                        })
                    ],
                    spacing: { after: 400 }
                }),
                new Paragraph({
                    alignment: AlignmentType.LEFT,
                    children: [
                        new TextRun({
                            text: `Ing. Mauricio Soberanes Hernández`,
                            font: 'Arial',
                            size: 22,
                            bold: true
                        })
                    ]
                }),
                new Paragraph({
                    alignment: AlignmentType.LEFT,
                    children: [
                        new TextRun({
                            text: `Director General de Metrocert`,
                            font: 'Arial',
                            size: 22,
                            bold: true
                        })
                    ],
                    spacing: { after: 600 }
                }),
                new Paragraph({
                    alignment: AlignmentType.JUSTIFIED,
                    children: [
                        new TextRun({
                            text: `Por este medio solicito formalmente se aplique la excepción de las regulaciones orgánicas, para la producción paralela en mi operación orgánica.`,
                            font: 'Arial',
                            size: 22
                        })
                    ],
                    spacing: { after: 200 }
                }),
                new Paragraph({
                    alignment: AlignmentType.JUSTIFIED,
                    children: [
                        new TextRun({
                            text: `Para ello, he implementado las medidas de identificación y separación necesarias, que listo en el formato de Análisis General de Riesgos MTO-FSOL-04, que anexo a la solicitud de inspección.`,
                            font: 'Arial',
                            size: 22
                        })
                    ],
                    spacing: { after: 200 }
                }),
                new Paragraph({
                    alignment: AlignmentType.JUSTIFIED,
                    children: [
                        new TextRun({
                            text: `Así también, estoy consciente de que deberé entregar información sobre la producción y ventas de la fracción no orgánica de mi operación al terminar la cosecha, para lo cual usaré el formato adjunto.`,
                            font: 'Arial',
                            size: 22
                        })
                    ],
                    spacing: { after: 400 }
                }),
                new Paragraph({
                    alignment: AlignmentType.JUSTIFIED,
                    children: [
                        new TextRun({
                            text: `Agradezco la atención y estaré atento por si se requiere de información adicional.`,
                            font: 'Arial',
                            size: 22
                        })
                    ],
                    spacing: { after: 200 }
                }),
                new Paragraph({
                    alignment: AlignmentType.CENTER,
                    children: [
                        new TextRun({
                            text: `Atte:`,
                            font: 'Arial',
                            size: 22
                        })
                    ],
                    spacing: { after: 600 }
                }),
                new Paragraph({
                    alignment: AlignmentType.CENTER,
                    children: [
                        signatureImage ? Media.addImage(document, signatureImage.toString(), 200, 100) : new TextRun({
                            text: ''
                        })
                    ]
                }),
                new Paragraph({
                    alignment: AlignmentType.CENTER,
                    children: [
                        new TextRun({
                            text: `_________________________`,
                            font: 'Arial',
                            size: 22
                        })
                    ]
                }),
                new Paragraph({
                    alignment: AlignmentType.CENTER,
                    children: [
                        new TextRun({
                            text: `C. ${data.customer.Legal_Authorities[0].Name} ${data.customer.Legal_Authorities[0].Middle_Name} ${data.customer.Legal_Authorities[0].Last_Name ? data.customer.Legal_Authorities[0].Last_Name : ''}`,
                            font: 'Arial',
                            size: 22,
                            bold: true
                        })
                    ]
                }),
                new Paragraph({
                    alignment: AlignmentType.CENTER,
                    children: [
                        new TextRun({
                            text: `Representante Legal`,
                            font: 'Arial',
                            size: 22,
                            bold: true
                        })
                    ]
                }),
                new Paragraph({
                    children: [
                        new PageBreak()
                    ],
                    spacing: {
                        after: 0,
                        before: 0
                    }
                }),
                new Paragraph({
                    alignment: AlignmentType.CENTER,
                    children: [
                        new TextRun({
                            text: 'Resultados de cosecha y ventas orgánico y no-orgánicos',
                            font: 'Calibri (Cuerpo)',
                            size: 22,
                            bold: true
                        })
                    ],
                    spacing: { before: 400 }
                }),
                this.createTableAO(data),
                this.createTableANO(data),
                new Paragraph({
                    alignment: AlignmentType.JUSTIFIED,
                    children: [
                        new TextRun({
                            text: `Confirmo que la información proporcionada en este cuadro es verdadera y estoy consciente de que el falseamiento de la misma puede representar una negación de la certificación.`,
                            font: 'Arial',
                            size: 22
                        })
                    ],
                    spacing: { before: 400, after: 400 }
                }),
                new Paragraph({
                    alignment: AlignmentType.CENTER,
                    children: [
                        new TextRun({
                            text: `Atte:`,
                            font: 'Arial',
                            size: 22
                        })
                    ],
                    spacing: { after: 600 }
                }),
                new Paragraph({
                    alignment: AlignmentType.CENTER,
                    children: [
                        signatureImage ? Media.addImage(document, signatureImage.toString(), 200, 100) : new TextRun({
                            text: ''
                        })
                    ]
                }),
                new Paragraph({
                    alignment: AlignmentType.CENTER,
                    children: [
                        new TextRun({
                            text: `_________________________`,
                            font: 'Arial',
                            size: 22
                        })
                    ]
                }),
                new Paragraph({
                    alignment: AlignmentType.CENTER,
                    children: [
                        new TextRun({
                            text: `C. ${data.customer.Legal_Authorities[0].Name} ${data.customer.Legal_Authorities[0].Middle_Name} ${data.customer.Legal_Authorities[0].Last_Name ? data.customer.Legal_Authorities[0].Last_Name : ''}`,
                            font: 'Arial',
                            size: 22,
                            bold: true
                        })
                    ]
                }),
                new Paragraph({
                    alignment: AlignmentType.CENTER,
                    children: [
                        new TextRun({
                            text: `Representante Legal`,
                            font: 'Arial',
                            size: 22,
                            bold: true
                        })
                    ]
                }),
                new Paragraph({
                    spacing: {
                        after: 400
                    }
                }),
            ],
            margins: {
                top: 350,
                bottom: 500,
                left: 800,
                right: 800
            }
        });

        return document;
    }

    public createFooter(): Table {
        const table = new Table({
            rows: [
                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: 'Elaboró:',
                                            font: 'Calibri (Cuerpo)',
                                            size: 22
                                        })
                                    ]
                                })
                            ],
                            width: {
                                size: 33,
                                type: WidthType.PERCENTAGE
                            }
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: 'Revisó:',
                                            font: 'Calibri (Cuerpo)',
                                            size: 22
                                        })
                                    ]
                                })
                            ],
                            width: {
                                size: 33,
                                type: WidthType.PERCENTAGE
                            }
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: 'Autorizó:',
                                            font: 'Calibri (Cuerpo)',
                                            size: 22
                                        })
                                    ]
                                }),
                            ],
                            width: {
                                size: 33,
                                type: WidthType.PERCENTAGE
                            }
                        })
                    ]
                }),
                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: 'Director de certificación',
                                            font: 'Calibri (Cuerpo)',
                                            size: 16,
                                            bold: true
                                        })
                                    ]
                                }),

                            ],
                            width: {
                                size: 33,
                                type: WidthType.PERCENTAGE
                            },
                            shading: {
                                fill: '#C0C0C0'
                            }
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: 'Responsable de calidad',
                                            font: 'Calibri (Cuerpo)',
                                            size: 16,
                                            bold: true
                                        })
                                    ]
                                }),

                            ],
                            width: {
                                size: 33,
                                type: WidthType.PERCENTAGE
                            },
                            shading: {
                                fill: '#C0C0C0'
                            }
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: 'Director general',
                                            font: 'Calibri (Cuerpo)',
                                            size: 16,
                                            bold: true
                                        })
                                    ]
                                }),
                            ],
                            width: {
                                size: 33,
                                type: WidthType.PERCENTAGE
                            },
                            shading: {
                                fill: '#C0C0C0'
                            }
                        })
                    ]
                })
            ],
            margins: {
                left: 70,
                right: 70
            },
            columnWidths: [3213, 3213, 3212]
        })
        return table
    }

    public createTableAO(data) {
        const table = new Table({
            rows: [
                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: `Área Orgánica`,
                                            font: 'Arial',
                                            size: 24,
                                            bold: true
                                        })
                                    ],
                                    spacing: { before: 100, after: 200 }
                                })
                            ],
                            columnSpan: 5,
                            shading: {
                                fill: "70AD46"
                            }
                        })
                    ]
                }),
                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: `Cultivo`,
                                            font: 'Arial',
                                            size: 22,
                                            bold: true
                                        })
                                    ],
                                    spacing: { before: 100, after: 200 }
                                })
                            ]
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: `Variedad`,
                                            font: 'Arial',
                                            size: 22,
                                            bold: true
                                        })
                                    ],
                                    spacing: { before: 100, after: 200 }
                                })
                            ]
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: `Cantidad cosechada (Kg)`,
                                            font: 'Arial',
                                            size: 22,
                                            bold: true
                                        })
                                    ],
                                    spacing: { before: 100, after: 200 }
                                })
                            ]
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: `Cantidad empacada (Kg)`,
                                            font: 'Arial',
                                            size: 22,
                                            bold: true
                                        })
                                    ],
                                    spacing: { before: 100, after: 200 }
                                })
                            ]
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: `Cantidad vendida (Kg)`,
                                            font: 'Arial',
                                            size: 22,
                                            bold: true
                                        })
                                    ],
                                    spacing: { before: 100, after: 200 }
                                })
                            ]
                        })
                    ]
                })

            ],
            margins: {
                left: 70,
                right: 70
            },
            width: {
                size: 100,
                type: WidthType.PERCENTAGE,
            },
            columnWidths: [3312, 3312, 3312, 3312, 3312]
        })

        let variedad = 0
        let cosechada = 0
        let empacada = 0
        let vendida = 0

        let listaUnidades = this.getValueList(data.submission, "lista_area_organica")
        listaUnidades.forEach(unidad => {
            table.addChildElement(new TableRow({
                children: [
                    new TableCell({
                        children: [
                            new Paragraph({
                                alignment: AlignmentType.CENTER,
                                children: [
                                    new TextRun({
                                        text: `${unidad.fsol_14_organico_cultivo}`,
                                        font: 'Arial',
                                        size: 22
                                    })
                                ],
                                spacing: { before: 100, after: 200 }
                            })
                        ]
                    }),
                    new TableCell({
                        children: [
                            new Paragraph({
                                alignment: AlignmentType.CENTER,
                                children: [
                                    new TextRun({
                                        text: `${unidad.fsol_14_organico_variedad}`,
                                        font: 'Arial',
                                        size: 22
                                    })
                                ],
                                spacing: { before: 100, after: 200 }
                            })
                        ]
                    }),
                    new TableCell({
                        children: [
                            new Paragraph({
                                alignment: AlignmentType.CENTER,
                                children: [
                                    new TextRun({
                                        text: `${unidad.fsol_14_organico_cantidad_cosechada}`,
                                        font: 'Arial',
                                        size: 22
                                    })
                                ],
                                spacing: { before: 100, after: 200 }
                            })
                        ]
                    }),
                    new TableCell({
                        children: [
                            new Paragraph({
                                alignment: AlignmentType.CENTER,
                                children: [
                                    new TextRun({
                                        text: `${unidad.fsol_14_organico_cantidad_empacada}`,
                                        font: 'Arial',
                                        size: 22
                                    })
                                ],
                                spacing: { before: 100, after: 200 }
                            })
                        ]
                    }),
                    new TableCell({
                        children: [
                            new Paragraph({
                                alignment: AlignmentType.CENTER,
                                children: [
                                    new TextRun({
                                        text: `${unidad.fsol_14_organico_cantidad_vendida}`,
                                        font: 'Arial',
                                        size: 22
                                    })
                                ],
                                spacing: { before: 100, after: 200 }
                            })
                        ]
                    })
                ]
            }))

            variedad = variedad + unidad.fsol_14_organico_variedad
            cosechada = cosechada + unidad.fsol_14_organico_cantidad_cosechada
            empacada = empacada + unidad.fsol_14_organico_cantidad_empacada
            vendida = vendida + unidad.fsol_14_organico_cantidad_vendida
        });

        table.addChildElement(new TableRow({
            children: [
                new TableCell({
                    children: [
                        new Paragraph({
                            alignment: AlignmentType.CENTER,
                            children: [
                                new TextRun({
                                    text: `Total`,
                                    font: 'Arial',
                                    size: 24,
                                    bold: true
                                })
                            ],
                            spacing: { before: 100, after: 200 }
                        })
                    ]
                }),
                new TableCell({
                    children: [
                        new Paragraph({
                            alignment: AlignmentType.CENTER,
                            children: [
                                new TextRun({
                                    text: `${variedad}`,
                                    font: 'Arial',
                                    size: 22
                                })
                            ],
                            spacing: { before: 100, after: 200 }
                        })
                    ]
                }),
                new TableCell({
                    children: [
                        new Paragraph({
                            alignment: AlignmentType.CENTER,
                            children: [
                                new TextRun({
                                    text: `${cosechada}`,
                                    font: 'Arial',
                                    size: 22
                                })
                            ],
                            spacing: { before: 100, after: 200 }
                        })
                    ]
                }),
                new TableCell({
                    children: [
                        new Paragraph({
                            alignment: AlignmentType.CENTER,
                            children: [
                                new TextRun({
                                    text: `${empacada}`,
                                    font: 'Arial',
                                    size: 22
                                })
                            ],
                            spacing: { before: 100, after: 200 }
                        })
                    ]
                }),
                new TableCell({
                    children: [
                        new Paragraph({
                            alignment: AlignmentType.CENTER,
                            children: [
                                new TextRun({
                                    text: `${vendida}`,
                                    font: 'Arial',
                                    size: 22
                                })
                            ],
                            spacing: { before: 100, after: 200 }
                        })
                    ]
                })
            ]
        }))

        return table;
    }

    public createTableANO(data) {
        const table = new Table({
            rows: [
                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: `Área no orgánico`,
                                            font: 'Arial',
                                            size: 24,
                                            bold: true
                                        })
                                    ],
                                    spacing: { before: 100, after: 200 }
                                })
                            ],
                            columnSpan: 5,
                            shading: {
                                fill: "FFFF00"
                            }
                        })
                    ]
                }),
                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: `Cultivo`,
                                            font: 'Arial',
                                            size: 22,
                                            bold: true
                                        })
                                    ],
                                    spacing: { before: 100, after: 200 }
                                })
                            ]
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: `Variedad`,
                                            font: 'Arial',
                                            size: 22,
                                            bold: true
                                        })
                                    ],
                                    spacing: { before: 100, after: 200 }
                                })
                            ]
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: `Cantidad cosechada (Kg)`,
                                            font: 'Arial',
                                            size: 22,
                                            bold: true
                                        })
                                    ],
                                    spacing: { before: 100, after: 200 }
                                })
                            ]
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: `Cantidad empacada (Kg)`,
                                            font: 'Arial',
                                            size: 22,
                                            bold: true
                                        })
                                    ],
                                    spacing: { before: 100, after: 200 }
                                })
                            ]
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: `Cantidad vendida (Kg)`,
                                            font: 'Arial',
                                            size: 22,
                                            bold: true
                                        })
                                    ],
                                    spacing: { before: 100, after: 200 }
                                })
                            ]
                        })
                    ]
                })

            ],
            margins: {
                left: 70,
                right: 70
            },
            width: {
                size: 100,
                type: WidthType.PERCENTAGE,
            },
            columnWidths: [3312, 3312, 3312, 3312, 3312]
        })

        let variedad = 0
        let cosechada = 0
        let empacada = 0
        let vendida = 0

        let listaUnidades = this.getValueList(data.submission, "lista_area_no_organica")
        listaUnidades.forEach(unidad => {
            table.addChildElement(new TableRow({
                children: [
                    new TableCell({
                        children: [
                            new Paragraph({
                                alignment: AlignmentType.CENTER,
                                children: [
                                    new TextRun({
                                        text: `${unidad.fsol_14_no_organico_cultivo}`,
                                        font: 'Arial',
                                        size: 22
                                    })
                                ],
                                spacing: { before: 100, after: 200 }
                            })
                        ]
                    }),
                    new TableCell({
                        children: [
                            new Paragraph({
                                alignment: AlignmentType.CENTER,
                                children: [
                                    new TextRun({
                                        text: `${unidad.fsol_14_no_organico_variedad}`,
                                        font: 'Arial',
                                        size: 22
                                    })
                                ],
                                spacing: { before: 100, after: 200 }
                            })
                        ]
                    }),
                    new TableCell({
                        children: [
                            new Paragraph({
                                alignment: AlignmentType.CENTER,
                                children: [
                                    new TextRun({
                                        text: `${unidad.fsol_14_no_organico_cantidad_cosechada}`,
                                        font: 'Arial',
                                        size: 22
                                    })
                                ],
                                spacing: { before: 100, after: 200 }
                            })
                        ]
                    }),
                    new TableCell({
                        children: [
                            new Paragraph({
                                alignment: AlignmentType.CENTER,
                                children: [
                                    new TextRun({
                                        text: `${unidad.fsol_14_no_organico_cantidad_empacada}`,
                                        font: 'Arial',
                                        size: 22
                                    })
                                ],
                                spacing: { before: 100, after: 200 }
                            })
                        ]
                    }),
                    new TableCell({
                        children: [
                            new Paragraph({
                                alignment: AlignmentType.CENTER,
                                children: [
                                    new TextRun({
                                        text: `${unidad.fsol_14_no_organico_cantidad_vendida}`,
                                        font: 'Arial',
                                        size: 22
                                    })
                                ],
                                spacing: { before: 100, after: 200 }
                            })
                        ]
                    })
                ]
            }))

            variedad = variedad + unidad.fsol_14_no_organico_variedad
            cosechada = cosechada + unidad.fsol_14_no_organico_cantidad_cosechada
            empacada = empacada + unidad.fsol_14_no_organico_cantidad_empacada
            vendida = vendida + unidad.fsol_14_no_organico_cantidad_vendida
        });

        table.addChildElement(new TableRow({
            children: [
                new TableCell({
                    children: [
                        new Paragraph({
                            alignment: AlignmentType.CENTER,
                            children: [
                                new TextRun({
                                    text: `Total`,
                                    font: 'Arial',
                                    size: 24,
                                    bold: true
                                })
                            ],
                            spacing: { before: 100, after: 200 }
                        })
                    ]
                }),
                new TableCell({
                    children: [
                        new Paragraph({
                            alignment: AlignmentType.CENTER,
                            children: [
                                new TextRun({
                                    text: `${variedad}`,
                                    font: 'Arial',
                                    size: 22
                                })
                            ],
                            spacing: { before: 100, after: 200 }
                        })
                    ]
                }),
                new TableCell({
                    children: [
                        new Paragraph({
                            alignment: AlignmentType.CENTER,
                            children: [
                                new TextRun({
                                    text: `${cosechada}`,
                                    font: 'Arial',
                                    size: 22
                                })
                            ],
                            spacing: { before: 100, after: 200 }
                        })
                    ]
                }),
                new TableCell({
                    children: [
                        new Paragraph({
                            alignment: AlignmentType.CENTER,
                            children: [
                                new TextRun({
                                    text: `${empacada}`,
                                    font: 'Arial',
                                    size: 22
                                })
                            ],
                            spacing: { before: 100, after: 200 }
                        })
                    ]
                }),
                new TableCell({
                    children: [
                        new Paragraph({
                            alignment: AlignmentType.CENTER,
                            children: [
                                new TextRun({
                                    text: `${vendida}`,
                                    font: 'Arial',
                                    size: 22
                                })
                            ],
                            spacing: { before: 100, after: 200 }
                        })
                    ]
                })
            ]
        }))

        return table;
    }

    getValue(submission, name) {
        var value = null
        for (const i in submission) {
            const element = submission[i]
            if (element.name === name) {
                value = element.value
            }
        }
        if (value) return value
        else return ''
    }
    getValueList(submission, name) {
        var value = null
        for (const i in submission) {
            const element = submission[i]
            if (element.name === name) {
                value = element.value
            }
        }
        if (value) return value
        else return []
    }
    // END MTO_FSOL_14
}
