<div class="p-4">
  <div class="container">
    <div *ngIf="loading" class="spinner-border spinner-border-sm"></div>
    <div *ngIf="user" class="home-fade-in">
      <h1>{{ user.firstName }} {{ user.middleName }} {{ user.lastName }}</h1>
      <h5 class="text-black-50">{{ type }}</h5>
      <div class="row">
        <div class="col-12">
          <hr />
          <h3>Configuración Módulo de Operadores</h3>

          <div *ngIf="user.type === 'ROOT' || user.type === 'DIRECTOR_CERTIFICACION' || user.type === 'SUSTANCIAS' || user.type === 'ADMINISTRADOR' || user.type === 'RESPONSABLE'">
            <form [formGroup]="form" (ngSubmit)="onSubmit()">
              <p>Tiempo de cancelación de prospectos<span class="text-danger">*</span></p>

              <div class="form-row">
                <div class="form-group col-4">
                  <label 
                    for="inputDays"
                  >
                    Días
                  </label>
                  <input 
                    class="form-control" 
                    formControlName="days"
                    id="inputDays"
                    (change)="updateFormat('days')"
                  >
                </div>

                <div class="form-group col-4">
                  <label 
                    for="inputHours"
                  >
                    Horas
                  </label>
                  <input 
                    class="form-control" 
                    formControlName="hours"
                    id="inputHours"
                    (change)="updateFormat('hours')"
                  >
                </div>

                <div class="form-group col-4">
                  <label 
                    for="inputMinutes"
                  >
                    Minutos
                  </label>
                  <input  
                    class="form-control" 
                    formControlName="minutes"
                    id="inputMinutes"
                    (change)="updateFormat('minutes')"
                  >
                </div>
              </div>

              <small id="" class="form-text text-muted mb-2">
                Indica la cantidad de horas y minutos que deben transcurrir para
                que un prospecto pase a la lista de prospectos cancelados.
              </small>

              <button 
                [disabled]="saving" 
                class="btn btn-primary">
                <span
                  *ngIf="saving"
                  class="spinner-border spinner-border-sm mr-1"
                ></span>
                Guardar
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
