import { ControlCommunicationService } from './../../../services/control-communication.service';
import { CataloguesService } from './../../../../core/services/catalogues.service';
import { FormBuilderComponent } from './../../../form-builder.component'
import { UnknownParams, ControlElement } from './../../../interfaces/form.interface';
import { Component, OnInit, Input } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { first } from 'rxjs/operators';
import { containsValidatorExtension } from '@rxweb/reactive-form-validators/validators-extension';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'select-element',
  templateUrl: './select.component.html'
})
export class SelectComponent implements OnInit {

  attributes: UnknownParams = {
    options: []
  }
  validations: UnknownParams = {}
  @Input() data: ControlElement

  @Input() form: FormGroup

  // inject parent FormBuilder Component in order to access FormGroup
  constructor(
    private parentControl: FormBuilderComponent, 
    private cataloguesService: CataloguesService,
    private route: ActivatedRoute,
    private communication: ControlCommunicationService,
  ) {
    this.communication.changeEmmited$.subscribe(async msg => {
      if (msg.identifier === 'unitsToProducts') {
        if(this.attributes.name === "lista_productos_unidad") {
          //console.log(msg.units, this.form, this.attributes)
          this.attributes.options = msg.units
        }
      }
    })
  }

  ngOnInit(): void {
    // asign parent formGroup to local formGroup
    if (!this.form) { this.form = this.parentControl.formGroup }
    // iterate and access component attributes
    for (var i in this.data.attributes) {
      const attribute = this.data.attributes[i]
      this.attributes[attribute.name] = attribute.value
    }
    for (var i in this.data.validations) {
      const validation = this.data.validations[i]
      this.validations[validation.name] = validation.value
    }
    this.form.addControl(this.attributes.name, new FormControl(""))
    if (this.attributes.dynamicOptions) {
      switch (this.attributes.dynamicOptions) {
        case 'countries':
          const countries = JSON.parse(localStorage.getItem("countries"))

          if(countries) {
            for (var i in countries) {
              const elem = countries[i]
              this.attributes.options.push(elem.title)
            }
          } else {
            this.cataloguesService.getCountries().pipe(first()).subscribe(data => {
              if (data.success) {
                localStorage.setItem("countries", JSON.stringify(data.data))
                for (var i in data.data) {
                  const elem = data.data[i]
                  this.attributes.options.push(elem.title)
                }
              }
            }, error => {
              console.log(error)
            })
          }
          break;
        case 'states':
          const states = JSON.parse(localStorage.getItem("states"))

          if(states) {
            for (var i in states) {
              const elem = states[i]
              this.attributes.options.push(elem.title)
            }
          } else {
            this.cataloguesService.getStates(42).pipe(first()).subscribe(data => {
              if (data.success) {
                localStorage.setItem("states", JSON.stringify(data.data))
                for (var i in data.data) {
                  const elem = data.data[i]
                  this.attributes.options.push(elem.title)
                }
              }
            }, error => {
              console.log(error)
            })
          }
          break;
        default:
          break;
      }
    }
    if(this.attributes.dinamicLaboratories) {
      this.cataloguesService.getOnlyLaboratories().pipe(first()).subscribe(data => {
        if(data.success) {
          for (var i in data.data) {
            const elem = data.data[i]
            this.attributes.options.push(elem.Name)
          }
        }
      }, error => {
        console.log("Error al consultar laboratorios")
      })
    }
    if (this.attributes.other) {
      this.form.addControl(this.attributes.other[0], new FormControl(false))
      this.form.addControl(this.attributes.other[1], new FormControl(''))
    }

    if(this.attributes.name === "lista_productos_unidad") {
      const recordId = this.route.snapshot.params['recordId'];
      if(recordId) {
        const units = JSON.parse(localStorage.getItem("units"+recordId))

        this.attributes.options = units
      }
    }

    if(["lista_productos_certificados_unidad_lpo", "lista_productos_certificados_unidad_cor"].includes(this.attributes.name)) {
      const evaluationId = this.route.snapshot.params['id'];
      if(evaluationId) {
        const units = JSON.parse(localStorage.getItem("unitsCertified"+evaluationId))

        this.attributes.options = units
      }
    }

    if(this.attributes.name === "unit_status") {
      const value = this.form.controls?.unit_status?.value ? this.form.controls?.unit_status?.value : ''
      //console.log("existe: ", this.form.controls?.unit_convertion ? true : false)

      if(this.form.controls?.unit_convertion) {
        if(value === "Orgánico" || value.length === 0) {
          this.form.controls.unit_convertion.disable()
          this.form.controls.unit_convertion.patchValue(null)
        } else if(value === "En Transición") {
          this.form.controls.unit_convertion.enable()
        }
      }
    }

    if(this.attributes.name === 'calculo_cobre_cultivo') {
      const inspectionId = this.route.snapshot.params['id'];
      if(inspectionId) {
        const products = JSON.parse(localStorage.getItem(`products_${inspectionId}`))

        this.attributes.options = products
      }
    }
  }

  otherValueChanged(event: any, key: string) {
    if (!event.target.checked) {
        this.form.setValue({
            ...this.form.value,
            [key]: ''
        })
    }
  }

  changeValue(event, attrName) {
    if(attrName === "informe_submuestra_1_laboratorio") {
      this.cataloguesService.getOnlyLaboratories().pipe(first()).subscribe(data => {
        if(data.success) {
          for (var i in data.data) {
            const elem = data.data[i]
            if(elem.Name === event.target.value) {
              var tests = JSON.parse(elem.Tests)
              let options = []
              for(var i in tests) {
                const option = tests[i]
                options.push({
                  name: option.name,
                  value: option.name
                })
              }

              setTimeout(() => {
                this.communication.emitEvent({
                  identifier: 'analisis',
                  analisis: options
                })
              }, 100);

              break
            }
          }
        }
      }, error => {
        console.log("Error al consultar analisis de laboratorio " + event.target.value)
      })
    } else if(attrName === "unit_status") {
      if(event.target.value === "Orgánico") {
        this.form.controls.unit_convertion.disable()
        this.form.controls.unit_convertion.patchValue(null)
      } else if(event.target.value === "En Transición") {
        this.form.controls.unit_convertion.enable()
      }
    }
  }
}
