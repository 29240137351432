export class Customer {
    idCommercial: string; //ID comercial del operador. Debe ser único.
    companyType: CustomerCompanyType; //Tipo de compañía: PERSONA_FISICA, PERSONA_MORAL
    companyName: string; //Nombre de la compañía
    commercialName: string; //Nombre comercial
    personName: string; //Nombre de la persona
    rfc: string; //RFC
    phone: string; //Teléfono
    country: string; //País
    state: string; //Estado/provincia
    city: string; //Ciudad
    town: string; //Localidad
    zip: string; //Código postal
    address1: string; //Colonia
    address2: string; //Calle
    addressNumberExt: string; //Número exterior
    addressNumberInt: string; //Número interior
    addressNotes: string; //Notas extra sobre la dirección
}

export enum CustomerCompanyType {
    PERSONA_FISICA,
    PERSONA_MORAL
}