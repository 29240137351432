import { AlignmentType, Document, Paragraph, TextRun, Media, Header, Footer, Table, TableRow, TableCell, VerticalAlign, WidthType, PageNumber, ShadingType, PageOrientation, UnderlineType } from "docx";
import { logo } from "../logo";
import { userWithoutSign } from "../userWithoutSign"
import { DatePipe } from '@angular/common';

export class DocumentCreatorMTO_FSOL_04_1_v2 {

    public getBase64FromUrl = async (url) => {
        const data = await fetch(url, {
            headers: {
                'Cache-Control': 'no-cache, no-store, must-revalidate',
                'Pragma': 'no-cache',
                'Expires': 'Sat, 01 Jan 2000 00:00:00 GMT',
                'If-Modified-Since': '0'
            }
        });
        const blob = await data.blob();
        return new Promise((resolve) => {
            const reader = new FileReader();
            reader.readAsDataURL(blob);
            reader.onloadend = function() {
                const base64data = reader.result;
                resolve(base64data);
            }
        });
    }

    // INIT MTO_FSOL_04_1
    public async create_MTO_FSOL_04_1(data, signature = ''): Promise<Document> {
        const document = new Document();
        const image = Media.addImage(document, logo, 50, 50);
        var datePipe = new DatePipe('es-MX');
        let signatureImage = null
        if (signature) {
            signatureImage = await this.getBase64FromUrl(signature)
        } else {
            signatureImage = userWithoutSign
        }

        document.addSection({
            headers: {
                default: new Header({
                    children: [
                        new Paragraph({ alignment: AlignmentType.CENTER, children: [image], }),
                        new Paragraph({
                            alignment: AlignmentType.CENTER, children: [
                                new TextRun({
                                    children: [`MTO-FSOL-04-1 | R 04 | 28-Septiembre-2021 | Pág. `, PageNumber.CURRENT],
                                    font: 'Book Antiqua',
                                    size: 20,
                                    bold: true
                                }),
                                new TextRun({
                                    font: 'Book Antiqua',
                                    size: 20,
                                    bold: true,
                                    children: [' de ', PageNumber.TOTAL_PAGES]
                                }),
                            ], spacing: { before: 200, after: 100 }
                        })
                    ]
                })
            },
            footers: {
                default: new Footer({
                    children: [
                        new Paragraph({ alignment: AlignmentType.CENTER }),
                        this.createFooter()
                    ]
                }),
            },
            children: [
                new Paragraph({
                    alignment: AlignmentType.CENTER,
                    children: [
                        new TextRun({
                            text: 'ANÁLISIS GENERAL DE RIESGOS PARA LA INTEGRIDAD ORGÁNICA DE PRODUCCIÓN VEGETAL',
                            font: 'Calibri (Cuerpo)',
                            size: 22,
                            bold: true
                        })
                    ],
                    spacing: { after: 200 }
                }),
                this.createTableUDP(data),
                new Paragraph({
                    alignment: AlignmentType.CENTER,
                    children: [
                        new TextRun({
                            text: '',
                            font: 'Calibri (Cuerpo)',
                            size: 22,
                            bold: true
                        })
                    ],
                    spacing: { after: 400 }
                }),
                this.createTableFases(data),
                new Paragraph({
                    alignment: AlignmentType.LEFT,
                    children: [
                        new TextRun({
                            text: '*** Para operadores certificados COR/USCOEA, en caso de producción paralela, el operador enviará registros completos de cosecha de los cultivos orgánico y no orgánico al momento de la inspección.',
                            font: 'Calibri (Body)',
                            size: 20
                        })
                    ],
                    spacing: { after: 200, before: 100 }
                }),
                new Paragraph({
                    alignment: AlignmentType.LEFT,
                    children: [
                        new TextRun({
                            text: 'I = Implementado, NI = No Implementado, C = Conforme, NC = No Conforme, NA = No Aplica',
                            font: 'Calibri (Body)',
                            size: 20
                        })
                    ],
                    spacing: { after: 200 }
                }),
                new Paragraph({
                    alignment: AlignmentType.LEFT,
                    children: [
                        new TextRun({
                            text: '____________________________________________________________________________________________________________________________________________________',
                            font: 'Calibri (Body)',
                            size: 20
                        })
                    ],
                    spacing: { after: 400 }
                }),
                new Paragraph({
                    alignment: AlignmentType.CENTER,
                    children: [
                        signatureImage ? Media.addImage(document, signatureImage.toString(), 200, 100) : new TextRun({
                            text: ''
                        })
                    ]
                }),
                new Paragraph({
                    alignment: AlignmentType.LEFT,
                    children: [
                        new TextRun({
                            text: `Fecha: `,
                            font: 'Arial',
                            size: 20,
                            italics: true
                        }),
                        new TextRun({
                            text: `                  ${datePipe.transform(new Date(data.record.Applications[0].Creation_Date.includes('UTC') ? data.record.Applications[0].Creation_Date.replace('UTC', '').trim() : data.record.Applications[0].Creation_Date), 'd-MMMM-y')}                  `,
                            font: 'Arial',
                            size: 20,
                            underline: {
                                color: '#000000',
                                type: UnderlineType.SINGLE
                            }
                        }),
                        new TextRun({
                            text: `                                  Nombre y Firma del Operador: `,
                            font: 'Arial',
                            size: 20,
                            italics: true
                        }),
                        new TextRun({
                            text: `                  ${data.customer.Legal_Authorities[0].Name + " " + data.customer.Legal_Authorities[0].Middle_Name + " " + data.customer.Legal_Authorities[0].Last_Name}                  `,
                            font: 'Arial',
                            size: 20,
                            underline: {
                                color: '#000000',
                                type: UnderlineType.SINGLE
                            }
                        }),
                    ],
                    spacing: {
                        after: 200,
                        before: 400
                    }
                })
            ],
            margins: {
                top: 350,
                bottom: 500,
                left: 1000,
                right: 1000
            },
            size: {
                orientation: PageOrientation.LANDSCAPE,
            }
        });

        return document;
    }

    public createFooter(): Table {
        const table = new Table({
            rows: [
                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: 'Elaboró:',
                                            font: 'Calibri (Cuerpo)',
                                            size: 22
                                        })
                                    ]
                                })
                            ],
                            width: {
                                size: 33,
                                type: WidthType.PERCENTAGE
                            }
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: 'Revisó:',
                                            font: 'Calibri (Cuerpo)',
                                            size: 22
                                        })
                                    ]
                                })
                            ],
                            width: {
                                size: 33,
                                type: WidthType.PERCENTAGE
                            }
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: 'Autorizó:',
                                            font: 'Calibri (Cuerpo)',
                                            size: 22
                                        })
                                    ]
                                }),
                            ],
                            width: {
                                size: 33,
                                type: WidthType.PERCENTAGE
                            }
                        })
                    ]
                }),
                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: 'Director de certificación',
                                            font: 'Calibri (Cuerpo)',
                                            size: 16,
                                            bold: true
                                        })
                                    ]
                                }),

                            ],
                            width: {
                                size: 33,
                                type: WidthType.PERCENTAGE
                            },
                            shading: {
                                fill: '#C0C0C0'
                            }
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: 'Responsable de calidad',
                                            font: 'Calibri (Cuerpo)',
                                            size: 16,
                                            bold: true
                                        })
                                    ]
                                }),

                            ],
                            width: {
                                size: 33,
                                type: WidthType.PERCENTAGE
                            },
                            shading: {
                                fill: '#C0C0C0'
                            }
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: 'Director general',
                                            font: 'Calibri (Cuerpo)',
                                            size: 16,
                                            bold: true
                                        })
                                    ]
                                }),
                            ],
                            width: {
                                size: 33,
                                type: WidthType.PERCENTAGE
                            },
                            shading: {
                                fill: '#C0C0C0'
                            }
                        })
                    ]
                })
            ],
            margins: {
                left: 70,
                right: 70
            },
            columnWidths: [3213, 3213, 3212],
            alignment: AlignmentType.CENTER
        })
        return table
    }

    public createTableUDP(data): Table {
        const table = new Table({
            rows: [
                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: 'Nombre del operador:',
                                            font: 'Calibri (Body)',
                                            size: 22
                                        })
                                    ]
                                }),
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: data.customer.Company_Name,
                                            font: 'Calibri (Body)',
                                            size: 22
                                        })
                                    ]
                                })
                            ]
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: 'Código del operador:',
                                            font: 'Calibri (Body)',
                                            size: 22
                                        })
                                    ]
                                }),
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: data.customer.Id_Comercial,
                                            font: 'Calibri (Body)',
                                            size: 22
                                        })
                                    ]
                                })
                            ]
                        })
                    ]
                })
            ],
            margins: {
                left: 70,
                right: 70
            },
            columnWidths: [4819, 4819],
            alignment: AlignmentType.CENTER
        })
        return table
    }

    public createTableFases(data): Table {
        const table = new Table({
            rows: [
                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: 'Fase',
                                            font: 'Calibri (Body)',
                                            size: 18,
                                            bold: true
                                        })
                                    ]
                                })
                            ],
                            shading: {
                                fill: '#C0C0C0',
                                val: ShadingType.PERCENT_90
                            },
                            verticalAlign: VerticalAlign.CENTER
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: 'Riesgo',
                                            font: 'Calibri (Body)',
                                            size: 18,
                                            bold: true
                                        })
                                    ]
                                })
                            ],
                            shading: {
                                fill: '#C0C0C0',
                                val: ShadingType.PERCENT_90
                            },
                            verticalAlign: VerticalAlign.CENTER
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: 'Control',
                                            font: 'Calibri (Body)',
                                            size: 18,
                                            bold: true
                                        })
                                    ]
                                })
                            ],
                            shading: {
                                fill: '#C0C0C0',
                                val: ShadingType.PERCENT_90
                            },
                            verticalAlign: VerticalAlign.CENTER
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: 'Evidencia',
                                            font: 'Calibri (Body)',
                                            size: 18,
                                            bold: true
                                        })
                                    ]
                                })
                            ],
                            shading: {
                                fill: '#C0C0C0',
                                val: ShadingType.PERCENT_90
                            },
                            verticalAlign: VerticalAlign.CENTER
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: 'Verificación MTO (Inspector)',
                                            font: 'Calibri (Body)',
                                            size: 18,
                                            bold: true
                                        })
                                    ]
                                })
                            ],
                            shading: {
                                fill: '#C0C0C0',
                                val: ShadingType.PERCENT_90
                            },
                            columnSpan: 2,
                            verticalAlign: VerticalAlign.CENTER
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: 'Observaciones',
                                            font: 'Calibri (Body)',
                                            size: 18,
                                            bold: true
                                        })
                                    ]
                                })
                            ],
                            shading: {
                                fill: '#C0C0C0',
                                val: ShadingType.PERCENT_90
                            },
                            verticalAlign: VerticalAlign.CENTER
                        }),
                    ]
                }),
                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: 'General***',
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                })
                            ]
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: ` ${this.getValue(data.submission, 'riesgo_general_produccion_paralela') ? '■' : '❏'} Se tiene producción paralela`,
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                })
                            ]
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: ` ${this.getValue(data.submission, 'control_general_separacion_parcelas') ? '■' : '❏'} Separación de parcelas`,
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                }),
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: ` ${this.getValue(data.submission, 'control_general_separacion_materiales') ? '■' : '❏'} Separación de materiales y equipos`,
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                }),
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: ` ${this.getValue(data.submission, 'control_general_separacion_almacenes') ? '■' : '❏'} Separación de almacenes`,
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                }),
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: ` ${this.getValue(data.submission, 'control_general_separacion_producto') ? '■' : '❏'} Separación de producto durante cosecha, empaque y transporte`,
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                }),
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: ` ${this.getValue(data.submission, 'control_general_supervicion_certificacion') ? '■' : '❏'} Supervisión por responsable de certificación`,
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                }),
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: ` ${this.getValue(data.submission, 'control_general_otro') ? '■' : '❏'} Otro: ${this.getValue(data.submission, 'control_general_otro_texto')}`,
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                })
                            ]
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: ` ${this.getValue(data.submission, 'evidencia_general_letreros') ? '■' : '❏'} Señales o letreros`,
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                }),
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: ` ${this.getValue(data.submission, 'evidencia_general_equipos_separados') ? '■' : '❏'} Equipos y almacenes separados y señalados`,
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                }),
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: ` ${this.getValue(data.submission, 'evidencia_general_construcciones_separadas') ? '■' : '❏'} Construcciones separadas y rotuladas`,
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                }),
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: ` ${this.getValue(data.submission, 'evidencia_general_registros_empaque') ? '■' : '❏'} Registros de cosecha, empaque y transporte señalando calidad`,
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                }),
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: ` ${this.getValue(data.submission, 'evidencia_general_bitacoras') ? '■' : '❏'} Bitácoras`,
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                }),
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: ` ${this.getValue(data.submission, 'evidencia_general_otro') ? '■' : '❏'} Otra: ${this.getValue(data.submission, 'evidencia_general_otro_texto')}`,
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                })
                            ]
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: ` ${this.getValue(data.submission, 'fase_general_implementacion') === 'I' ? '■' : '❏'} I`,
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                }),
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: ` ${this.getValue(data.submission, 'fase_general_implementacion') === 'NI' ? '■' : '❏'} NI`,
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                })
                            ]
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: ` ${this.getValue(data.submission, 'fase_general_conformidad') === 'C' ? '■' : '❏'} C`,
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                }),
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: ` ${this.getValue(data.submission, 'fase_general_conformidad') === 'NC' ? '■' : '❏'} NC`,
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                }),
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: ` ${this.getValue(data.submission, 'fase_general_conformidad') === 'NA' ? '■' : '❏'} NA`,
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                })
                            ]
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: this.getValue(data.submission, 'fase_general_comentarios_inspector'),
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                })
                            ]
                        })
                    ]
                }),
                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: 'Uso previo del terreno',
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                })
                            ]
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: ` ${this.getValue(data.submission, 'riesgo_uso_previo_sustancias_no_permitidas') ? '■' : '❏'} Uso de sustancias no permitidas (36 meses antes de primer cosecha orgánica)`,
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                }),
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: ` ${this.getValue(data.submission, 'riesgo_uso_previo_otro') ? '■' : '❏'} Otro: ${this.getValue(data.submission, 'riesgo_uso_previo_otro_texto')}`,
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                })
                            ]
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: ` ${this.getValue(data.submission, 'control_uso_previo_terreno_propio') ? '■' : '❏'} Terreno propio`,
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                }),
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: ` ${this.getValue(data.submission, 'control_uso_previo_supervicion_certificacion') ? '■' : '❏'} Supervisión por responsable de certificación`,
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                }),
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: ` ${this.getValue(data.submission, 'control_uso_previo_otro') ? '■' : '❏'} Otro: ${this.getValue(data.submission, 'control_uso_previo_otro_texto')}${this.getValue(data.submission, 'control_uso_previo_otro_texto')}`,
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                })
                            ]
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: ` ${this.getValue(data.submission, 'evidencia_uso_previo_escritura') ? '■' : '❏'} Escritura, título o similares`,
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                }),
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: ` ${this.getValue(data.submission, 'evidencia_uso_previo_declaracion_jurada') ? '■' : '❏'} Declaración jurada propia`,
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                }),
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: ` ${this.getValue(data.submission, 'evidencia_uso_previo_declaracion_autoridad') ? '■' : '❏'} Declaración de autoridad`,
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                }),
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: ` ${this.getValue(data.submission, 'evidencia_uso_previo_historial') ? '■' : '❏'} Historial de uso del terreno`,
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                }),
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: ` ${this.getValue(data.submission, 'evidencia_uso_previo_bitacoras') ? '■' : '❏'} Bitácoras de manejo`,
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                }),
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: ` ${this.getValue(data.submission, 'evidencia_uso_previo_otro') ? '■' : '❏'} Otra: ${this.getValue(data.submission, 'evidencia_uso_previo_otro_texto')}`,
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                })
                            ]
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: ` ${this.getValue(data.submission, 'fase_uso_previo_terreno_implementacion') === 'I' ? '■' : '❏'} I`,
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                }),
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: ` ${this.getValue(data.submission, 'fase_uso_previo_terreno_implementacion') === 'NI' ? '■' : '❏'} NI`,
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                })
                            ]
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: ` ${this.getValue(data.submission, 'fase_uso_previo_terreno_conformidad') === 'C' ? '■' : '❏'} C`,
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                }),
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: ` ${this.getValue(data.submission, 'fase_uso_previo_terreno_conformidad') === 'NC' ? '■' : '❏'} NC`,
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                }),
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: ` ${this.getValue(data.submission, 'fase_uso_previo_terreno_conformidad') === 'NA' ? '■' : '❏'} NA`,
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                })
                            ]
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: this.getValue(data.submission, 'fase_uso_previo_terreno_comentarios_inspector'),
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                })
                            ]
                        })
                    ]
                }),
                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: 'Calidad de la semilla',
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                })
                            ]
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: ` ${this.getValue(data.submission, 'riesgo_calidad_semilla_no_organica') ? '■' : '❏'} Semilla no orgánica`,
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                }),
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: ` ${this.getValue(data.submission, 'riesgo_calidad_semilla_tratada_no_permitido') ? '■' : '❏'} Semilla tratada con material no permitido`,
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                }),
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: ` ${this.getValue(data.submission, 'riesgo_calidad_semilla_otro') ? '■' : '❏'} Otro: ${this.getValue(data.submission, 'riesgo_calidad_semilla_otro_texto')}`,
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                })
                            ]
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: ` ${this.getValue(data.submission, 'control_calidad_semilla_produccion_propia') ? '■' : '❏'} Producción propia de semilla`,
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                }),
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: ` ${this.getValue(data.submission, 'control_calidad_semilla_compra_semilla_organica') ? '■' : '❏'} Compra de semilla orgánica`,
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                }),
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: ` ${this.getValue(data.submission, 'control_calidad_semilla_compra_semilla_convencional') ? '■' : '❏'} Compra de semilla convencional no tratada`,
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                }),
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: ` ${this.getValue(data.submission, 'control_calidad_semilla_otro') ? '■' : '❏'} Otro: ${this.getValue(data.submission, 'control_calidad_semilla_otro_texto')}`,
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                })
                            ]
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: ` ${this.getValue(data.submission, 'evidencia_calidad_semilla_bitacoras') ? '■' : '❏'} Bitácoras de producción, cosecha, inventario de semilla`,
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                }),
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: ` ${this.getValue(data.submission, 'evidencia_calidad_semilla_facturas') ? '■' : '❏'} Facturas y certificado`,
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                }),
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: ` ${this.getValue(data.submission, 'evidencia_calidad_semilla_otro') ? '■' : '❏'} Otro: ${this.getValue(data.submission, 'evidencia_calidad_semilla_otro_texto')}`,
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                })
                            ]
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: ` ${this.getValue(data.submission, 'fase_calidad_semilla_implementacion') === 'I' ? '■' : '❏'} I`,
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                }),
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: ` ${this.getValue(data.submission, 'fase_calidad_semilla_implementacion') === 'NI' ? '■' : '❏'} NI`,
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                })
                            ]
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: ` ${this.getValue(data.submission, 'fase_calidad_semilla_conformidad') === 'C' ? '■' : '❏'} C`,
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                }),
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: ` ${this.getValue(data.submission, 'fase_calidad_semilla_conformidad') === 'NC' ? '■' : '❏'} NC`,
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                }),
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: ` ${this.getValue(data.submission, 'fase_calidad_semilla_conformidad') === 'NA' ? '■' : '❏'} NA`,
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                })
                            ]
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: this.getValue(data.submission, 'fase_calidad_semilla_comentarios_inspector'),
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                })
                            ]
                        })
                    ]
                }),
                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: 'Semillero / Vivero',
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                })
                            ]
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: ` ${this.getValue(data.submission, 'riesgo_semillero_sustancias_no_permitidas') ? '■' : '❏'} Uso de sustancias no permitidas`,
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                }),
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: ` ${this.getValue(data.submission, 'riesgo_semillero_mezcla_plantula') ? '■' : '❏'} Mezcla de plántula orgánica con plántula convencional`,
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                }),
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: ` ${this.getValue(data.submission, 'riesgo_semillero_otro') ? '■' : '❏'} Otro: ${this.getValue(data.submission, 'riesgo_semillero_otro_texto')}`,
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                })
                            ]
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: ` ${this.getValue(data.submission, 'control_semillero_capacitacion') ? '■' : '❏'} Capacitación de responsable`,
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                }),
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: ` ${this.getValue(data.submission, 'control_semillero_supervision') ? '■' : '❏'} Supervisión de responsable`,
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                }),
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: ` ${this.getValue(data.submission, 'control_semillero_otro') ? '■' : '❏'} Otro: ${this.getValue(data.submission, 'control_semillero_otro_texto')}`,
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                })
                            ]
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: ` ${this.getValue(data.submission, 'evidencia_semillero_registros_capacitacion') ? '■' : '❏'} Registros de capacitación`,
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                }),
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: ` ${this.getValue(data.submission, 'evidencia_semillero_bitacoras') ? '■' : '❏'} Bitácoras de manejo`,
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                }),
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: ` ${this.getValue(data.submission, 'evidencia_semillero_otro') ? '■' : '❏'} Otro: ${this.getValue(data.submission, 'evidencia_semillero_otro_texto')}`,
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                })
                            ]
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: ` ${this.getValue(data.submission, 'fase_semillero_vivero_implementacion') === 'I' ? '■' : '❏'} I`,
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                }),
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: ` ${this.getValue(data.submission, 'fase_semillero_vivero_implementacion') === 'NI' ? '■' : '❏'} NI`,
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                })
                            ]
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: ` ${this.getValue(data.submission, 'fase_semillero_vivero_conformidad') === 'C' ? '■' : '❏'} C`,
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                }),
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: ` ${this.getValue(data.submission, 'fase_semillero_vivero_conformidad') === 'NC' ? '■' : '❏'} NC`,
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                }),
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: ` ${this.getValue(data.submission, 'fase_semillero_vivero_conformidad') === 'NA' ? '■' : '❏'} NA`,
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                })
                            ]
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: this.getValue(data.submission, 'fase_semillero_vivero_comentarios_inspector'),
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                })
                            ]
                        })
                    ]
                }),
                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: 'Producción en campo',
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                })
                            ]
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: ` ${this.getValue(data.submission, 'riesgo_produccion_sustancias_no_permitidas') ? '■' : '❏'} Uso de sustancias no permitidas`,
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                }),
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: ` ${this.getValue(data.submission, 'riesgo_produccion_colindantes') ? '■' : '❏'} Riesgo de contaminación por colindantes`,
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                }),
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: ` ${this.getValue(data.submission, 'riesgo_produccion_otro') ? '■' : '❏'} Otro: ${this.getValue(data.submission, 'riesgo_produccion_otro_texto')}`,
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                })
                            ]
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: ` ${this.getValue(data.submission, 'control_produccion_capacitacion') ? '■' : '❏'} Capacitación de responsable`,
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                }),
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: ` ${this.getValue(data.submission, 'control_produccion_supervision') ? '■' : '❏'} Supervisión de responsable`,
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                }),
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: ` ${this.getValue(data.submission, 'control_produccion_zonas_buffer') ? '■' : '❏'} Establecimiento de zonas buffer`,
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                }),
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: ` ${this.getValue(data.submission, 'control_produccion_acuerdo_vecinos') ? '■' : '❏'} Acuerdo convecinos`,
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                }),
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: ` ${this.getValue(data.submission, 'control_produccion_barreras') ? '■' : '❏'} Barreras de protección`,
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                }),
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: ` ${this.getValue(data.submission, 'control_produccion_zanjas') ? '■' : '❏'} Zanjas de filtración`,
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                }),
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: ` ${this.getValue(data.submission, 'control_produccion_uso_equipo_contaminado') ? '■' : '❏'} Uso de equipo contaminado (aspersor)`,
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                }),
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: ` ${this.getValue(data.submission, 'control_produccion_otro') ? '■' : '❏'} Otro: ${this.getValue(data.submission, 'control_produccion_otro_texto')}`,
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                })
                            ]
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: ` ${this.getValue(data.submission, 'evidencia_produccion_registros_capacitacion') ? '■' : '❏'} Registros de capacitación`,
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                }),
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: ` ${this.getValue(data.submission, 'evidencia_produccion_bitacoras') ? '■' : '❏'} Bitácoras de manejo`,
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                }),
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: ` ${this.getValue(data.submission, 'evidencia_produccion_registro_zonas_buffer') ? '■' : '❏'} Registros de cosecha y venta de producto de zonas buffer`,
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                }),
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: ` ${this.getValue(data.submission, 'evidencia_produccion_acuerdos') ? '■' : '❏'} Acuerdos firmados`,
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                }),
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: ` ${this.getValue(data.submission, 'evidencia_produccion_equipo_etiquetado') ? '■' : '❏'} Equipo etiquetado`,
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                }),
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: ` ${this.getValue(data.submission, 'evidencia_produccion_otro') ? '■' : '❏'} Otro: ${this.getValue(data.submission, 'evidencia_produccion_otro_texto')}`,
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                })
                            ]
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: ` ${this.getValue(data.submission, 'fase_produccion_campo_implementacion') === 'I' ? '■' : '❏'} I`,
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                }),
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: ` ${this.getValue(data.submission, 'fase_produccion_campo_implementacion') === 'NI' ? '■' : '❏'} NI`,
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                })
                            ]
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: ` ${this.getValue(data.submission, 'fase_produccion_campo_conformidad') === 'C' ? '■' : '❏'} C`,
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                }),
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: ` ${this.getValue(data.submission, 'fase_produccion_campo_conformidad') === 'NC' ? '■' : '❏'} NC`,
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                }),
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: ` ${this.getValue(data.submission, 'fase_produccion_campo_conformidad') === 'NA' ? '■' : '❏'} NA`,
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                })
                            ]
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: this.getValue(data.submission, 'fase_produccion_campo_comentarios_inspector'),
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                })
                            ]
                        })
                    ]
                }),
                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: 'Riego',
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                })
                            ]
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: ` ${this.getValue(data.submission, 'riesgo_riego_agua_contaminada') ? '■' : '❏'} Uso de agua contaminada`,
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                }),
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: ` ${this.getValue(data.submission, 'riesgo_riego_equipo_contaminado') ? '■' : '❏'} Uso de equipo contaminado`,
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                }),
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: ` ${this.getValue(data.submission, 'riesgo_riego_lineas_mixtas') ? '■' : '❏'} Líneas mixtas o cruce de líneas o retroceso`,
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                }),
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: ` ${this.getValue(data.submission, 'riesgo_riego_otro') ? '■' : '❏'} Otro: ${this.getValue(data.submission, 'riesgo_riego_otro_texto')}`,
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                })
                            ]
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: ` ${this.getValue(data.submission, 'control_riego_fuente_agua_exclusiva') ? '■' : '❏'} Fuente de agua exclusiva y limpia`,
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                }),
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: ` ${this.getValue(data.submission, 'control_riego_analisis_agua_periodo') ? '■' : '❏'} Análisis de agua cada ${this.getValue(data.submission, 'control_riego_analisis_agua_periodo_meses')} meses`,
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                }),
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: ` ${this.getValue(data.submission, 'control_riego_uso_equipo_exclusivo_lavado') ? '■' : '❏'} Uso de equipo exclusivo o lavado previamente`,
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                }),
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: ` ${this.getValue(data.submission, 'control_riego_lineas_exclusivas') ? '■' : '❏'} Líneas exclusivas`,
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                }),
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: ` ${this.getValue(data.submission, 'control_riego_uso_valvulas_check') ? '■' : '❏'} Uso de válvulas check`,
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                }),
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: ` ${this.getValue(data.submission, 'control_riego_otro') ? '■' : '❏'} Otro: ${this.getValue(data.submission, 'control_riego_otro_texto')}`,
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                })
                            ]
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: ` ${this.getValue(data.submission, 'evidencia_riego_planos') ? '■' : '❏'} Planos de instalación`,
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                }),
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: ` ${this.getValue(data.submission, 'evidencia_riego_observacion_directa') ? '■' : '❏'} Observación directa`,
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                }),
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: ` ${this.getValue(data.submission, 'evidencia_riego_resultado_analisis') ? '■' : '❏'} Resultado de análisis`,
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                }),
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: ` ${this.getValue(data.submission, 'evidencia_riego_otro') ? '■' : '❏'} Otro: ${this.getValue(data.submission, 'evidencia_riego_otro_texto')}`,
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                })
                            ]
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: ` ${this.getValue(data.submission, 'fase_riego_implementacion') === 'I' ? '■' : '❏'} I`,
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                }),
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: ` ${this.getValue(data.submission, 'fase_riego_implementacion') === 'NI' ? '■' : '❏'} NI`,
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                })
                            ]
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: ` ${this.getValue(data.submission, 'fase_riego_conformidad') === 'C' ? '■' : '❏'} C`,
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                }),
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: ` ${this.getValue(data.submission, 'fase_riego_conformidad') === 'NC' ? '■' : '❏'} NC`,
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                }),
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: ` ${this.getValue(data.submission, 'fase_riego_conformidad') === 'NA' ? '■' : '❏'} NA`,
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                })
                            ]
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: this.getValue(data.submission, 'fase_riego_comentarios_inspector'),
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                })
                            ]
                        })
                    ]
                }),
                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: 'Cosecha',
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                })
                            ]
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: ` ${this.getValue(data.submission, 'riesgo_cosecha_contaminacion_equipo') ? '■' : '❏'} Contaminación por equipo o materiales de cosecha`,
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                }),
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: ` ${this.getValue(data.submission, 'riesgo_cosecha_mezcla_no_organica') ? '■' : '❏'} Mezcla con producto no orgánico`,
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                }),
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: ` ${this.getValue(data.submission, 'riesgo_cosecha_otro') ? '■' : '❏'} Otro: ${this.getValue(data.submission, 'riesgo_cosecha_otro_texto')}`,
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                })
                            ]
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: ` ${this.getValue(data.submission, 'control_cosecha_equipo_exclusivo') ? '■' : '❏'} Equipo exclusivo y marcado`,
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                }),
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: ` ${this.getValue(data.submission, 'control_cosecha_almacenes_exclusivos') ? '■' : '❏'} Almacenes exclusivos`,
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                }),
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: ` ${this.getValue(data.submission, 'control_cosecha_almacenes_marcados') ? '■' : '❏'} Almacenes marcados y rotulados`,
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                }),
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: ` ${this.getValue(data.submission, 'control_cosecha_marcado_etiquetado') ? '■' : '❏'} Marcado y/o etiquetado`,
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                }),
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: ` ${this.getValue(data.submission, 'control_cosecha_bitacoras') ? '■' : '❏'} Bitácoras de cosecha`,
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                }),
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: ` ${this.getValue(data.submission, 'control_cosecha_otro') ? '■' : '❏'} Otro: ${this.getValue(data.submission, 'control_cosecha_otro_texto')}`,
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                })
                            ]
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: ` ${this.getValue(data.submission, 'evidencia_cosecha_disponibilidad_equipo') ? '■' : '❏'} Disponibilidad de equipo`,
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                }),
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: ` ${this.getValue(data.submission, 'evidencia_cosecha_material_etiquetado') ? '■' : '❏'} Material marcado y etiquetado`,
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                }),
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: ` ${this.getValue(data.submission, 'evidencia_cosecha_otro') ? '■' : '❏'} Otro: ${this.getValue(data.submission, 'evidencia_cosecha_otro_texto')}`,
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                })
                            ]
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: ` ${this.getValue(data.submission, 'fase_cosecha_implementacion') === 'I' ? '■' : '❏'} I`,
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                }),
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: ` ${this.getValue(data.submission, 'fase_cosecha_implementacion') === 'NI' ? '■' : '❏'} NI`,
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                })
                            ]
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: ` ${this.getValue(data.submission, 'fase_cosecha_conformidad') === 'C' ? '■' : '❏'} C`,
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                }),
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: ` ${this.getValue(data.submission, 'fase_cosecha_conformidad') === 'NC' ? '■' : '❏'} NC`,
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                }),
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: ` ${this.getValue(data.submission, 'fase_cosecha_conformidad') === 'NA' ? '■' : '❏'} NA`,
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                })
                            ]
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: this.getValue(data.submission, 'fase_cosecha_comentarios_inspector'),
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                })
                            ]
                        })
                    ]
                }),
                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: 'Transporte',
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                })
                            ]
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: ` ${this.getValue(data.submission, 'riesgo_transporte_contaminacion') ? '■' : '❏'} Contaminación por transporte`,
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                }),
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: ` ${this.getValue(data.submission, 'riesgo_transporte_mezcla_no_organica') ? '■' : '❏'} Mezcla con producto no orgánico`,
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                }),
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: ` ${this.getValue(data.submission, 'riesgo_transporte_otro') ? '■' : '❏'} Otro: ${this.getValue(data.submission, 'riesgo_transporte_otro_texto')}`,
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                })
                            ]
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: ` ${this.getValue(data.submission, 'control_transporte_medio_exclusivo') ? '■' : '❏'} Medio de transporte exclusivo y marcado`,
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                }),
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: ` ${this.getValue(data.submission, 'control_transporte_marcado_etiquetado') ? '■' : '❏'} Marcado y/o etiquetado`,
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                }),
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: ` ${this.getValue(data.submission, 'control_transporte_otro') ? '■' : '❏'} Otro: ${this.getValue(data.submission, 'control_transporte_otro_texto')}`,
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                })
                            ]
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: ` ${this.getValue(data.submission, 'evidencia_transporte_disponibilidad_transporte') ? '■' : '❏'} Disponibilidad de medios de transporte`,
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                }),
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: ` ${this.getValue(data.submission, 'evidencia_transporte_material_etiquetado') ? '■' : '❏'} Material marcado y etiquetado`,
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                }),
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: ` ${this.getValue(data.submission, 'evidencia_transporte_otro') ? '■' : '❏'} Otro: ${this.getValue(data.submission, 'evidencia_transporte_otro_texto')}`,
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                })
                            ]
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: ` ${this.getValue(data.submission, 'fase_transporte_implementacion') === 'I' ? '■' : '❏'} I`,
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                }),
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: ` ${this.getValue(data.submission, 'fase_transporte_implementacion') === 'NI' ? '■' : '❏'} NI`,
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                })
                            ]
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: ` ${this.getValue(data.submission, 'fase_transporte_conformidad') === 'C' ? '■' : '❏'} C`,
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                }),
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: ` ${this.getValue(data.submission, 'fase_transporte_conformidad') === 'NC' ? '■' : '❏'} NC`,
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                }),
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: ` ${this.getValue(data.submission, 'fase_transporte_conformidad') === 'NA' ? '■' : '❏'} NA`,
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                })
                            ]
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: this.getValue(data.submission, 'fase_transporte_comentarios_inspector'),
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                })
                            ]
                        })
                    ]
                })
            ],
            columnWidths: [1606,3016,3016,3016,1053, 1053,2106],
            alignment: AlignmentType.CENTER
        })
        return table
    }

    getValue(submission, name) {
        var value = null
        for (const i in submission) {
            const element = submission[i]
            if (element.name === name) {
                value = element.value
            }
        }
        if (value) return value
        else return ''
    }

    getValueList(submission, name) {
        var value = null
        for (const i in submission) {
            const element = submission[i]
            if (element.name === name) {
                value = element.value
            }
        }
        if (value) return value
        else return []
    }
    // END MTO_FSOL_04_1
}
