import { ControlElement, Form } from './../../interfaces/form.interface';
import { Component, OnInit, Input } from '@angular/core'
import { FormGroup } from '@angular/forms';

@Component({
    selector: 'containers-fabric',
    templateUrl: './containers-fabric.component.html'
})
export class ContainersFabricComponent implements OnInit {

    @Input() content: ControlElement[]
    @Input() formGroup: FormGroup
    
    constructor() {}

    ngOnInit() {}

}