<div class="modal-header">
    <h4 class="modal-title" id="modal-title">Conexión a internet restablecida</h4>
    <button
      type="button"
      class="close"
      aria-label="Close button"
      aria-describedby="modal-title"
      (click)="modal.dismiss('Cross click')"
    >
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  
    <div class="modal-body">
      <p>
        <strong>
          Solicitud de aprovación para subir datos locales al servidor.
        </strong>
      </p>
    </div>
    <div class="modal-footer">
      <button
        type="button"
        class="btn btn-outline-secondary"
        (click)="modal.dismiss('cancel click')"
      >
        Cancelar
      </button>
      <button
        [disabled]="loading"
        type="button"
        ngbAutofocus
        class="btn btn-success"
        (click)="onSubmit()"
      >
        <span
          *ngIf="loading"
          class="spinner-border spinner-border-sm mr-1"
        ></span>
        Aceptar
      </button>
    </div>
  