export const urlToBase64 = async (url: string): Promise<string | ArrayBuffer | null> => {
    const data = await fetch(url);
    const blob = await data.blob();
    return new Promise((resolve) => {
        const reader = new FileReader();
        reader.readAsDataURL(blob); 
        reader.onloadend = function() {
            const base64data = reader.result;
            resolve(base64data);
        };
    });
};