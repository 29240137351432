import { NgModule } from '@angular/core'
import { Routes, RouterModule } from '@angular/router'

import { HomeComponent } from '@app/features/home'
import { LoginComponent } from '@app/features/login'
import { AuthGuard } from '@app/core/helpers'
import { ForgotPasswordComponent } from "@app/features/forgot-password";
import { ForgotPasswordConfirmationComponent } from "@app/features/forgot-password-confirmation";
import { ResetPasswordComponent } from "@app/features/reset-password";
import { SettingsComponent } from "@app/features/settings";

const usersModule = () => import('./features/users/users.module').then(x => x.UsersModule);
const customersModule = () => import('./features/customers/customers.module').then(x => x.CustomersModule);
const requestsModule = () => import('./features/requests/requests.module').then(x => x.RequestsModule);
const reviewsModule = () => import('./features/reviews/reviews.module').then(x => x.ReviewsModule);
const inspectionsModule = () => import('./features/inspections/inspections.module').then(x => x.InspectionsModule);
const evaluationsModule = () => import('./features/evaluations/evaluations.module').then(x => x.EvaluationsModule);
const recordsModule = () => import('./features/records/records.module').then(x => x.RecordsModule);
const cataloguesModule = () => import('./features/catalogues/catalogues.module').then(x => x.CataloguesModule);
const quotesModule = () => import('./features/quotes/quotes.module').then(x => x.QuotesModule);
const certificatesModule = () => import('./features/certificates/certificates.module').then(x => x.CertificatesModule);
const substancesModule = () => import('./features/substances/substances.module').then(x => x.SubstancesModule);
const certificatesExternalModule = () => import('./features/certificate-external/certificate-external.module').then(x => x.CertificatesExternalModule)
const noConformidadesModule = () => import('./features/no-conformidades/no-conformidades.module').then(x => x.NoConformidadesModule)
const profileModule = () => import('./features/profile/profile.module').then(x => x.ProfileModule)
const mailModule = () => import('./features/mail-controller/mail.module').then(x => x.MailModule)
const supportUtilitiesModule = () => import('./features/support-utilities/support-utilities.module').then(x => x.SupportUtilitiesModule)
const reportsModule = () => import('./features/reports/reports.module').then(x => x.ReportsModule)
const RegenerateDocsModule = () => import('./features/regenerateDocs/regenerate-docs.module').then(x => x.RegenerateDocsModule)

const routes: Routes = [
  { path: '', component: HomeComponent, canActivate: [AuthGuard] },
  { path: 'login', component: LoginComponent },
  { path: 'recuperarCuenta', component: ForgotPasswordComponent },
  { path: 'confirmacion', component: ForgotPasswordConfirmationComponent },
  { path: 'cambiarContrasena/:token', component: ResetPasswordComponent },
  { path: 'bienvenido/:token', component: ResetPasswordComponent },
  { path: 'usuarios', loadChildren: usersModule, canActivate: [AuthGuard] },
  { path: 'operadores', loadChildren: customersModule, canActivate: [AuthGuard] },
  { path: 'configuracion', component: SettingsComponent, canActivate: [AuthGuard] },
  { path: 'solicitudes', loadChildren: requestsModule, canActivate: [AuthGuard] },
  { path: 'revisiones', loadChildren: reviewsModule, canActivate: [AuthGuard] },
  { path: 'inspecciones', loadChildren: inspectionsModule, canActivate: [AuthGuard] },
  { path: 'dictamenes', loadChildren: evaluationsModule, canActivate: [AuthGuard] },
  { path: 'expedientes', loadChildren: recordsModule, canActivate: [AuthGuard] },
  { path: 'catalogos', loadChildren: cataloguesModule, canActivate: [AuthGuard] },
  { path: 'cotizador', loadChildren: quotesModule, canActivate: [AuthGuard] },
  { path: 'certificados', loadChildren: certificatesModule, canActivate: [AuthGuard] },
  { path: 'sustancias', loadChildren: substancesModule, canActivate: [AuthGuard] },
  { path: 'no_conformidades', loadChildren: noConformidadesModule, canActivate: [AuthGuard] },
  { path: 'perfil', loadChildren: profileModule, canActivate: [AuthGuard] },
  { path: 'emails', loadChildren: mailModule, canActivate: [AuthGuard] },
  { path: 'support_utilities', loadChildren: supportUtilitiesModule, canActivate: [AuthGuard] },
  { path: 'reportes', loadChildren: reportsModule, canActivate: [AuthGuard] },
  { path: 'certificado_consulta', loadChildren: certificatesExternalModule },
  { path: 'regenerar_docs', loadChildren: RegenerateDocsModule, canActivate: [AuthGuard] },

  // Otherwise redirect to home
  { path: '**', redirectTo: '' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {onSameUrlNavigation: 'reload'})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
