import { AlignmentType, Document, Paragraph, PageOrientation, TextRun, Media, Header, Footer, Table, TableRow, TableCell, WidthType, PageNumber, BorderStyle } from "docx";
import { logo } from "../logo";
import { userWithoutSign } from "../userWithoutSign"
import { DatePipe } from '@angular/common';

export class DocumentCreatorMTO_FSOL_07_v1 {

    public getBase64FromUrl = async (url) => {
        const data = await fetch(url, {
            headers: {
                'Cache-Control': 'no-cache, no-store, must-revalidate',
                'Pragma': 'no-cache',
                'Expires': 'Sat, 01 Jan 2000 00:00:00 GMT',
                'If-Modified-Since': '0'
            }
        });
        const blob = await data.blob();
        return new Promise((resolve) => {
            const reader = new FileReader();
            reader.readAsDataURL(blob); 
            reader.onloadend = function() {
                const base64data = reader.result;   
                resolve(base64data);
            }
        });
    }

    // INIT MTO_FSOL_7
    public async create_MTO_FSOL_07(data, signature = '', signature2 = ''): Promise<Document> {
        const document = new Document();
        const image = Media.addImage(document, logo, 50, 50);
        var datePipe = new DatePipe('es-MX');
        let signatureImage = null
        if (signature) {
            signatureImage = await this.getBase64FromUrl(signature)
        } else {
            signatureImage = userWithoutSign
        }
        let signatureImage2 = null
        if(signature2) {
            signatureImage2 = await this.getBase64FromUrl(signature2)
        } else {
            signatureImage2 = userWithoutSign
        }

        document.addSection({
            headers: {
                default: new Header({
                    children: [
                        new Paragraph({ alignment: AlignmentType.CENTER, children: [image], }),
                        new Paragraph({
                            alignment: AlignmentType.CENTER, children: [
                                new TextRun({
                                    children: [`MTO-FSOL-07| R 00 | 01-Julio-2017 | Pág. `, PageNumber.CURRENT],
                                    font: 'Book Antiqua',
                                    size: 20,
                                    bold: true
                                }),
                                new TextRun({
                                    font: 'Book Antiqua',
                                    size: 20,
                                    bold: true,
                                    children: [' de ', PageNumber.TOTAL_PAGES]
                                }),
                            ], spacing: { before: 200, after: 100 }
                        })
                    ]
                })
            },
            footers: {
                default: new Footer({
                    children: [
                        new Paragraph({ alignment: AlignmentType.CENTER }),
                        this.createFooter()
                    ]
                }),
            },
            children: [
                new Paragraph({
                    alignment: AlignmentType.CENTER,
                    children: [
                        new TextRun({
                            text: 'LISTA DE SUSTANCIAS',
                            font: 'Calibri (Cuerpo)',
                            size: 22,
                            bold: true
                        })
                    ],
                    spacing: { before: 200, after: 200 }
                }),
                this.createTableHeader(data),
                new Paragraph({
                    spacing: { 
                        after: 400 
                    }
                }),
                this.createTableUDP(data),
                new Paragraph({
                    spacing: {
                        after: 400
                    }
                }),
                new Table({
                    rows: [
                        new TableRow({
                            children: [
                                new TableCell({
                                    children: [

                                    ],
                                    columnSpan: 1,
                                    borders: {
                                        top: {
                                            style: BorderStyle.NONE,
                                            color: "white",
                                            size: 0
                                        },
                                        bottom: {
                                            style: BorderStyle.NONE,
                                            color: "white",
                                            size: 0
                                        },
                                        right: {
                                            style: BorderStyle.NONE,
                                            color: "white",
                                            size: 0
                                        },
                                        left: {
                                            style: BorderStyle.NONE,
                                            color: "white",
                                            size: 0
                                        }
                                    }
                                }),
                                new TableCell({
                                    children: [
                                        new Paragraph({
                                            alignment: AlignmentType.LEFT,
                                            children: [
                                                new TextRun({
                                                    text: `Declara el operador:`,
                                                    font: 'Arial',
                                                    size: 24,
                                                    italics: true,
                                                    bold: true
                                                })
                                            ],
                                            spacing: {
                                                after: 100
                                            }
                                        }),
                                        new Paragraph({
                                            alignment: AlignmentType.LEFT,
                                            children: [
                                                signatureImage ? Media.addImage(document, signatureImage.toString(), 200, 100) : new TextRun({
                                                    text: ''
                                                })
                                            ]
                                        }),
                                        new Paragraph({
                                            alignment: AlignmentType.LEFT,
                                            children: [
                                                new TextRun({
                                                    text: `Fecha: ${datePipe.transform(new Date(data.record.Applications[0].Signature_Date), 'd-MMMM-y')}                  Nombre y Firma del Operador: ${data.customer.Legal_Authorities[0].Name} ${data.customer.Legal_Authorities[0].Middle_Name} ${data.customer.Legal_Authorities[0].Last_Name ? data.customer.Legal_Authorities[0].Last_Name : ''}`,
                                                    font: 'Arial',
                                                    size: 24,
                                                    italics: true
                                                })
                                            ],
                                            spacing: {
                                                after: 200
                                            }
                                        }), 
                                        new Paragraph({
                                            alignment: AlignmentType.LEFT,
                                            children: [
                                                new TextRun({
                                                    text: `Declara el revisor:`,
                                                    font: 'Arial',
                                                    size: 24,
                                                    italics: true,
                                                    bold: true
                                                })
                                            ],
                                            spacing: {
                                                after: 100
                                            }
                                        }),
                                        new Paragraph({
                                            alignment: AlignmentType.LEFT,
                                            children: [
                                                signatureImage2 ? Media.addImage(document, signatureImage2.toString(), 200, 100) : new TextRun({
                                                    text: ''
                                                })
                                            ]
                                        }),
                                        new Paragraph({
                                            alignment: AlignmentType.LEFT,
                                            children: [
                                                new TextRun({
                                                    text: `Fecha: ${data.reviewer.date ? datePipe.transform(new Date(data.reviewer.date), 'd-MMMM-y') : ''}                  Nombre y firma del revisor: ${data.reviewer.name ? data.reviewer.name : ''}`,
                                                    font: 'Arial',
                                                    size: 24,
                                                    italics: true
                                                })
                                            ],
                                            spacing: {
                                                after: 200
                                            }
                                        }),
                                    ],
                                    columnSpan: 3,
                                    borders: {
                                        top: {
                                            style: BorderStyle.NONE,
                                            color: "white",
                                            size: 0
                                        },
                                        bottom: {
                                            style: BorderStyle.NONE,
                                            color: "white",
                                            size: 0
                                        },
                                        right: {
                                            style: BorderStyle.NONE,
                                            color: "white",
                                            size: 0
                                        },
                                        left: {
                                            style: BorderStyle.NONE,
                                            color: "white",
                                            size: 0
                                        }
                                    }
                                })
                            ]
                        })
                    ],
                    margins: {
                        left: 70,
                        right: 70
                    },
                    width: {
                        size: 100,
                        type: WidthType.PERCENTAGE,
                    },
                    columnWidths: [3312, 3312, 3312, 3312]
                }),
            ],
            margins: {
                top: 350,
                bottom: 500,
                left: 800,
                right: 800
            },
            size: {
                orientation: PageOrientation.LANDSCAPE,
                height: 29000,
                width: 21000
            }
        });

        return document;
    }

    public createFooter(): Table {
        const table = new Table({
            rows: [
                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: 'Elaboró:',
                                            font: 'Calibri (Cuerpo)',
                                            size: 22
                                        })
                                    ]
                                })
                            ],
                            width: {
                                size: 33,
                                type: WidthType.PERCENTAGE
                            }
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: 'Revisó:',
                                            font: 'Calibri (Cuerpo)',
                                            size: 22
                                        })
                                    ]
                                })
                            ],
                            width: {
                                size: 33,
                                type: WidthType.PERCENTAGE
                            }
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: 'Autorizó:',
                                            font: 'Calibri (Cuerpo)',
                                            size: 22
                                        })
                                    ]
                                }),
                            ],
                            width: {
                                size: 33,
                                type: WidthType.PERCENTAGE
                            }
                        })
                    ]
                }),
                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: 'Director de certificación',
                                            font: 'Calibri (Cuerpo)',
                                            size: 16,
                                            bold: true
                                        })
                                    ]
                                }),

                            ],
                            width: {
                                size: 33,
                                type: WidthType.PERCENTAGE
                            },
                            shading: {
                                fill: '#C0C0C0'
                            }
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: 'Responsable de calidad',
                                            font: 'Calibri (Cuerpo)',
                                            size: 16,
                                            bold: true
                                        })
                                    ]
                                }),

                            ],
                            width: {
                                size: 33,
                                type: WidthType.PERCENTAGE
                            },
                            shading: {
                                fill: '#C0C0C0'
                            }
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: 'Director general',
                                            font: 'Calibri (Cuerpo)',
                                            size: 16,
                                            bold: true
                                        })
                                    ]
                                }),
                            ],
                            width: {
                                size: 33,
                                type: WidthType.PERCENTAGE
                            },
                            shading: {
                                fill: '#C0C0C0'
                            }
                        })
                    ]
                })
            ],
            margins: {
                left: 70,
                right: 70
            },
            columnWidths: [3213, 3213, 3212],
            alignment: AlignmentType.CENTER
        })
        return table
    }

    public createTableHeader(data) {
        const table = new Table({
            rows: [
                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: `Nombre del operador: ${data.customer.Company_Name}`,
                                            font: 'Arial',
                                            size: 22
                                        })
                                    ],
                                    spacing: { before: 100, after: 200 }
                                })
                            ]
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: `Codigo del operador: ${data.customer.Id_Comercial}`,
                                            font: 'Arial',
                                            size: 22
                                        })
                                    ],
                                    spacing: { before: 100, after: 200 }
                                })
                            ]
                        })
                    ]
                })
            ],
            margins: {
                left: 70,
                right: 70
            },
            columnWidths: [8250, 8250],
            alignment: AlignmentType.CENTER
        })

        return table
    }

    public createTableUDP(data) {
        const table = new Table({
            rows: [
                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: `No.`,
                                            font: 'Arial',
                                            size: 24,
                                            bold: true
                                        })
                                    ],
                                    spacing: { before: 100, after: 200 }
                                })
                            ],
                            columnSpan: 1,
                            shading: {
                                fill: "#BFBFBF"
                            }
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: `Nombre comercial o común del producto`,
                                            font: 'Arial',
                                            size: 24,
                                            bold: true
                                        })
                                    ],
                                    spacing: { before: 100, after: 200 }
                                })
                            ],
                            columnSpan: 1,
                            shading: {
                                fill: "#BFBFBF"
                            }
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: `Listado`,
                                            font: 'Arial',
                                            size: 24,
                                            bold: true
                                        })
                                    ],
                                    spacing: { before: 100, after: 200 }
                                })
                            ],
                            columnSpan: 1,
                            shading: {
                                fill: "#BFBFBF"
                            }
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: `Ingredientes activos`,
                                            font: 'Arial',
                                            size: 24,
                                            bold: true
                                        })
                                    ],
                                    spacing: { before: 100, after: 200 }
                                })
                            ],
                            columnSpan: 1,
                            shading: {
                                fill: "#BFBFBF"
                            }
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: `Ingredientes inertes`,
                                            font: 'Arial',
                                            size: 24,
                                            bold: true
                                        })
                                    ],
                                    spacing: { before: 100, after: 200 }
                                })
                            ],
                            columnSpan: 1,
                            shading: {
                                fill: "#BFBFBF"
                            }
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: `Proveedor (Mismo operador o fabricante)`,
                                            font: 'Arial',
                                            size: 24,
                                            bold: true
                                        })
                                    ],
                                    spacing: { before: 100, after: 200 }
                                })
                            ],
                            columnSpan: 1,
                            shading: {
                                fill: "#BFBFBF"
                            }
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: `Uso (Abono, fungicida, insecticida, limpieza, etc.)`,
                                            font: 'Arial',
                                            size: 24,
                                            bold: true
                                        })
                                    ],
                                    spacing: { before: 100, after: 200 }
                                })
                            ],
                            columnSpan: 1,
                            shading: {
                                fill: "#BFBFBF"
                            }
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: `Cultivo o área de aplicación`,
                                            font: 'Arial',
                                            size: 24,
                                            bold: true
                                        })
                                    ],
                                    spacing: { before: 100, after: 200 }
                                })
                            ],
                            columnSpan: 1,
                            shading: {
                                fill: "#BFBFBF"
                            }
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: `Verificación (Llenado por Metrocert)`,
                                            font: 'Arial',
                                            size: 24,
                                            bold: true
                                        })
                                    ],
                                    spacing: { before: 100, after: 200 }
                                })
                            ],
                            columnSpan: 1,
                            shading: {
                                fill: "#BFBFBF"
                            }
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: `Restricciones (Llenado por Metrocert)`,
                                            font: 'Arial',
                                            size: 24,
                                            bold: true
                                        })
                                    ],
                                    spacing: { before: 100, after: 200 }
                                })
                            ],
                            columnSpan: 1,
                            shading: {
                                fill: "#BFBFBF"
                            }
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: `Anexos`,
                                            font: 'Arial',
                                            size: 24,
                                            bold: true
                                        })
                                    ],
                                    spacing: { before: 100, after: 200 }
                                })
                            ],
                            columnSpan: 1,
                            shading: {
                                fill: "#BFBFBF"
                            }
                        })
                    ]             
                })
            ],
            margins: {
                left: 70,
                right: 70
            },
            width: {
                size: 100,
                type: WidthType.PERCENTAGE,
            },
            columnWidths: [1312, 3312, 2812, 3312, 3312, 3312, 3312, 3312, 2812, 3512, 2800]
        })

        let listaSustancias = this.getValueList(data.submission, "lista_sustancias")
        listaSustancias.forEach((sustancia, i) => {
            var anexos = ''
            for(var j in sustancia.ficha_tecnica_anexos_urls) {
                const tmp = sustancia.ficha_tecnica_anexos_urls[j].split("/")
                anexos += tmp[tmp.length-1] + ", "
            }

            anexos = anexos.substr(0, anexos.length-2)

            table.addChildElement(new TableRow({
                children: [
                    new TableCell({
                        children: [
                            new Paragraph({
                                alignment: AlignmentType.CENTER,
                                children: [
                                    new TextRun({
                                        text: `${i+1}`,
                                        font: 'Arial',
                                        size: 24,
                                        bold: true
                                    })
                                ],
                                spacing: { before: 100, after: 200 }
                            })
                        ],
                        columnSpan: 1
                    }),
                    new TableCell({
                        children: [
                            new Paragraph({
                                alignment: AlignmentType.CENTER,
                                children: [
                                    new TextRun({
                                        text: sustancia.nombre_comercial,
                                        font: 'Arial',
                                        size: 24,
                                        bold: true
                                    })
                                ],
                                spacing: { before: 100, after: 200 }
                            })
                        ],
                        columnSpan: 1
                    }),
                    new TableCell({
                        children: [
                            new Paragraph({
                                alignment: AlignmentType.LEFT,
                                children: [
                                    new TextRun({
                                        text: `${sustancia.listado_omri_canada ? '■' : '❏'} OMRI Canadá`,
                                        font: 'Arial',
                                        size: 24,
                                        bold: true
                                    })
                                ]
                            }),
                            new Paragraph({
                                alignment: AlignmentType.LEFT,
                                children: [
                                    new TextRun({
                                        text: `${sustancia.listado_omri_nop ? '■' : '❏'} OMRI NOP`,
                                        font: 'Arial',
                                        size: 24,
                                        bold: true
                                    })
                                ]
                            }),
                            new Paragraph({
                                alignment: AlignmentType.LEFT,
                                children: [
                                    new TextRun({
                                        text: `${sustancia.listado_otro ? '■ Otra: ' + sustancia.listado_otro_texto : '❏ Otra'}`,
                                        font: 'Arial',
                                        size: 24,
                                        bold: true
                                    })
                                ]
                            })
                        ],
                        columnSpan: 1
                    }),
                    new TableCell({
                        children: [
                            new Paragraph({
                                alignment: AlignmentType.CENTER,
                                children: [
                                    new TextRun({
                                        text: sustancia.ingredientes_activos,
                                        font: 'Arial',
                                        size: 24,
                                        bold: true
                                    })
                                ],
                                spacing: { before: 100, after: 200 }
                            })
                        ],
                        columnSpan: 1
                    }),
                    new TableCell({
                        children: [
                            new Paragraph({
                                alignment: AlignmentType.CENTER,
                                children: [
                                    new TextRun({
                                        text: sustancia.ingredientes_inertes,
                                        font: 'Arial',
                                        size: 24,
                                        bold: true
                                    })
                                ],
                                spacing: { before: 100, after: 200 }
                            })
                        ],
                        columnSpan: 1
                    }),
                    new TableCell({
                        children: [
                            new Paragraph({
                                alignment: AlignmentType.CENTER,
                                children: [
                                    new TextRun({
                                        text: sustancia.nombre_proveedor,
                                        font: 'Arial',
                                        size: 24,
                                        bold: true
                                    })
                                ],
                                spacing: { before: 100, after: 200 }
                            })
                        ],
                        columnSpan: 1
                    }),
                    new TableCell({
                        children: [
                            new Paragraph({
                                alignment: AlignmentType.CENTER,
                                children: [
                                    new TextRun({
                                        text: sustancia.uso_abono_fungicida_etc,
                                        font: 'Arial',
                                        size: 24,
                                        bold: true
                                    })
                                ],
                                spacing: { before: 100, after: 200 }
                            })
                        ],
                        columnSpan: 1
                    }),
                    new TableCell({
                        children: [
                            new Paragraph({
                                alignment: AlignmentType.CENTER,
                                children: [
                                    new TextRun({
                                        text: sustancia.cultivo_area_aplicacion,
                                        font: 'Arial',
                                        size: 24,
                                        bold: true
                                    })
                                ],
                                spacing: { before: 100, after: 200 }
                            })
                        ],
                        columnSpan: 1
                    }),
                    new TableCell({
                        children: [
                            new Paragraph({
                                alignment: AlignmentType.LEFT,
                                children: [
                                    new TextRun({
                                        text: `${sustancia.verificacion_lpo ? '■' : '❏'} Permitido LPO`,
                                        font: 'Arial',
                                        size: 24,
                                        bold: true
                                    })
                                ]
                            }),
                            new Paragraph({
                                alignment: AlignmentType.LEFT,
                                children: [
                                    new TextRun({
                                        text: `${sustancia.verificacion_opr ? '■' : '❏'} Permitido OPR`,
                                        font: 'Arial',
                                        size: 24,
                                        bold: true
                                    })
                                ]
                            }),
                            new Paragraph({
                                alignment: AlignmentType.LEFT,
                                children: [
                                    new TextRun({
                                        text: `${sustancia.verificacion_no_permitido ? '■' : '❏'} No Permitido`,
                                        font: 'Arial',
                                        size: 24,
                                        bold: true
                                    })
                                ]
                            })
                        ],
                        columnSpan: 1
                    }),
                    new TableCell({
                        children: [
                            new Paragraph({
                                alignment: AlignmentType.CENTER,
                                children: [
                                    new TextRun({
                                        text: `${sustancia.restricciones ? sustancia.restricciones : ""}`,
                                        font: 'Arial',
                                        size: 24,
                                        bold: true
                                    })
                                ],
                                spacing: { before: 100, after: 200 }
                            })
                        ],
                        columnSpan: 1
                    }),
                    new TableCell({
                        children: [
                            new Paragraph({
                                alignment: AlignmentType.CENTER,
                                children: [
                                    new TextRun({
                                        text: `${anexos === '' ? 'Sin anexos' : anexos}`,
                                        font: 'Arial',
                                        size: 24,
                                        bold: true
                                    })
                                ],
                                spacing: { before: 100, after: 200 }
                            })
                        ],
                        columnSpan: 1
                    })
                ]             
            }))
        });

        return table;
    }

    getValue(submission, name) {
        var value = null
        for (const i in submission) {
            const element = submission[i]
            if (element.name === name) {
                value = element.value
            }
        }
        if (value) return value
        else return ''
    }
    getValueList(submission, name) {
        var value = null
        for (const i in submission) {
            const element = submission[i]
            if (element.name === name) {
                value = element.value
            }
        }
        if (value) return value
        else return []
    }
    // END MTO_FSOL_07
}