<div class="modal-header">
    <h4 class="modal-title" id="modal-title">Instrucciones para el uso fuera de línea</h4>
    <button
      type="button"
      class="close"
      aria-label="Close button"
      aria-describedby="modal-title"
      (click)="modal.dismiss('Cross click')"
    >
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  
    <div class="modal-body">
      <span>
        <strong>Instrucciones:</strong>

        <ol>
          <li>Estando conectado, señalar la fecha de inspección en el formato MTO-FINS-02.</li>
          <li>Estando conectado, abrir cada uno de los formularios.</li>
          <li>Estando offline, puedes navegar entre los formularios, llenar o actualizar información y guardarla como se haría normalmente.</li>
          <li>Al recuperar la conexión, aparecerá una pantalla emergente para confirmar la subida de datos.</li>
        </ol>

        <br>

        <strong>Notas:</strong>
        <ol>
          <li>Estando en el modo offline, no salga de la lista de formularios. El hacerlo implicará la <strong><span class="text-danger">pérdida de información.</span></strong></li>
          <li>Si no confirmas la subida de información una vez se recupera la conexión, <strong><span class="text-danger">no se garantiza que la información sea almacenada.</span></strong></li>
          <li>No cerrar el navegador o pestaña ni apagar la computadora.</li>
        </ol>
      </span>
    </div>
    <div class="modal-footer">
      <button
        [disabled]="loading"
        type="button"
        ngbAutofocus
        class="btn btn-success"
        (click)="onSubmit()"
      >
        <span
          *ngIf="loading"
          class="spinner-border spinner-border-sm mr-1"
        ></span>
        Cerrar
      </button>
    </div>
  