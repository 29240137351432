import { Component, OnInit, Type } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms'
import { first } from 'rxjs/operators';

import { UsersService, AuthenticationService, AlertService, SettingsService } from '@app/core/services';
import { User } from '@app/core/models';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ModalChangePassword } from "@app/core/components/change-password";

const MODALS: { [name: string]: Type<any> } = {
    changePassword: ModalChangePassword
};
@Component({ 
    templateUrl: 'settings.component.html', 
    styleUrls: ['../../app.component.scss']
})
export class SettingsComponent implements OnInit {
    form: FormGroup;
    submitted = false;
    user: User;
    loading = false;
    type = ''
    isEditable: false;
    saving = false;

    formData: FormData;
    changingPassword = false

    constructor(
        private authenticationService: AuthenticationService,
        private usersService: UsersService,
        private alertService: AlertService,
        private settingsService: SettingsService,
        private formBuilder: FormBuilder,
        private _modalService: NgbModal
    ) {
        this.user = this.authenticationService.userValue;
        switch (this.user.type) {
            case "ROOT":
                this.type = 'Administrador'
                break;
            case "OPERADOR":
                this.type = 'Operador'
                break;
            case "CAPTURISTA":
                this.type = 'Capturista'
                break;
            case "RESPONSABLE":
                this.type = 'Responsable'
                break;
            case "ADMINISTRADOR":
                this.type = 'Administrador'
                break;
            case "DIRECTOR_CERTIFICACION":
                this.type = 'Supervisor'
                break;
            case "REVISOR":
                this.type = 'Revisor'
                break;
            case "INSPECTOR":
                this.type = 'Inspector'
                break;
            case "DICTAMINADOR":
                this.type = 'Dictaminador'
                break;
            case "OBSERVADOR":
                this.type = 'Observador'
                break;
            default:
                break;
        }
    }

    ngOnInit() {
        this.form = this.formBuilder.group({
            days: [0],
            hours: [0],
            minutes: [0]
        })

        this.usersService.getMe()
            .pipe(first())
            .subscribe(
                data => {
                    //console.log(data)
                },
                error => {
                    this.alertService.error(error);
                })

        this.settingsService.getConfig()
            .pipe(first())
            .subscribe(
                data => {
                    //console.log(data)
                    const t = data.data
                    const days = Math.trunc(t/1440)
                    const hours = Math.trunc((t%1440)/60)
                    const minutes = ((t%1440)%60)

                    //console.log(`dias: ${days}, horas: ${hours}, minutos: ${minutes}`)

                    this.f.days.setValue(days)
                    this.f.hours.setValue(hours)
                    this.f.minutes.setValue(minutes)
                },
                error => {
                    this.alertService.error(error);
                })
    }

    // Convenience getter for easy access to form fields
    get f() { return this.form.controls; }

    onSubmit() {
        const days = this.form.value.days
        const hours = this.form.value.hours
        const minutes = this.form.value.minutes

        //console.log(`dias: ${days}, horas: ${hours}, minutos: ${minutes}`)

        // Reset alerts on submit
        this.alertService.clear();
        
        // Stop here if form is invalid
        if(days === 0 && hours === 0 && minutes === 0) {
            this.alertService.warn("El tiempo de cancelacion de prospectos no puede ser 0", { autoClose: true })
            return
        }

        /* 
            * Days to minutes: days*24*60 or days*1440
            * Hours to minutes: minutes*60
        */
        const time = (days*1440) + (hours*60) + minutes

        //console.log(`time: ${time}`)

        //console.log("update")
        this.settingsService.updateSettings(time)
            .pipe(first())
            .subscribe(
                data => {
                    //console.log(data)
                    this.alertService.success('La configuración a sido guardada exitosamente', { autoClose: true });
                    this.saving = false;
                },
                error => {
                    this.alertService.error(error);
                    this.saving = false;
                });
    }

    updateFormat(control) {
        const value = this.form.get(control).value

        var val = isNaN(parseInt(value)) ? 0 : parseInt(value)
        val = val < 0 ? 0 : val

        if(control === "hours") {
            val = val >= 24 ? 24 : val
        } else if(control === "minutes") {
            val = val >= 60 ? 60 : val
        }

        this.form.get(control).patchValue(val)
    }
}