import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AlertService, AuthenticationService, ReviewsService } from '@app/core/services'
import { first } from 'rxjs/operators';

@Component({
    selector: 'modal-changeReviewer',
    templateUrl: 'modal-changeReviewer.component.html',
    styleUrls: ['../../../app.component.scss']
})
export class ModalChangeReviewer implements OnInit {
    form: FormGroup;
    @Input() public name = ''
    @Input() public id

    forbiddenId = false;
    loading = false;
    submitted = false;
    res = false;
    reviewers = [];
  isShowPasswordReviewer = false;

    constructor(
        private formBuilder: FormBuilder,
        public modal: NgbActiveModal,
        private authenticationService: AuthenticationService,
        private alertService: AlertService,
        private reviewsService: ReviewsService
    ) { }
    ngOnInit() {
        this.reviewsService.getReviewers()
            .pipe(first())
            .subscribe(
                response => {
                    //console.log(response)
                    this.reviewers = response.data
                },
                error => {
                    this.alertService.error(error);
                })
        this.form = this.formBuilder.group({
            newReviewer: ['', Validators.required],
            password: ['', Validators.required]
        });

    }

    // Convenience getter for easy access to form fields
    get f() { return this.form.controls; }

    onSubmit() {
        this.submitted = true;

        // Reset alerts on submit
        this.alertService.clear();

        // Stop here if form is invalid
        if (this.form.invalid) {
            return;
        }
        if (this.form.value.password) {
            this.forbiddenId = false
            this.loading = true;
            this.authenticationService.isPasswordValidated(this.form.value.password)
                .pipe(first())
                .subscribe(
                    data => {
                        this.alertService.clear()
                        if (data.validated) {
                            this.forbiddenId = false

                            if (this.form.value.newReviewer) {
                                this.loading = true;
                                this.reviewsService.changeReviewer(this.form.value.newReviewer, this.id)
                                    .pipe(first())
                                    .subscribe(
                                        response => {
                                            console.log(response)
                                            this.alertService.clear()
                                            if (response.success) {
                                                this.alertService.success('Se ha asignado el revisor correctamente', { autoClose: true })
                                                this.modal.close(true)
                                                this.modal.dismiss()
                                            } else {
                                            }
                                            this.loading = false;
                                        },
                                        error => {
                                            this.alertService.error(error);
                                            this.loading = false;
                                    });
                            }
                        } else {
                            this.forbiddenId = true
                        }
                        this.loading = false;
                    },
                    error => {
                        this.alertService.error(error);
                        this.loading = false;
                });
        }
    }
  showPasswordReviewer(): void {
    this.isShowPasswordReviewer = !this.isShowPasswordReviewer;
  }
}
