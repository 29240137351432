import { AlignmentType, Document, Paragraph, TextRun, Media, Header, Footer, Table, TableRow, TableCell, WidthType, PageNumber, ShadingType, PageBreak, BorderStyle } from "docx";
import { logo } from "../logo";
import { DatePipe } from '@angular/common';

//Moment import
const moment = require('moment')
moment.locale('es-mx')

export class DocumentCreatorMTO_FINS_03_12_v3 {
    // INIT MTO_FINS_03_1_12
    public create_MTO_FINS_03_12(data, typeInspection = ''): Document {
        const document = new Document();
        const image = Media.addImage(document, logo, 50, 50);
        var datePipe = new DatePipe('es-MX');

        const listaBalance = this.getValueList(data.submission, "lista-balance-masas")
        listaBalance.forEach(element => {
            document.addSection({
                headers: {
                    default: new Header({
                        children: [
                            new Paragraph({ alignment: AlignmentType.CENTER, children: [image], }),
                            new Paragraph({
                                alignment: AlignmentType.CENTER, children: [
                                    new TextRun({
                                        children: [`MTO-FINS-03-12 | R 01 | 18-Junio-2024 | Pág. `, PageNumber.CURRENT],
                                        font: 'Book Antiqua',
                                        size: 20,
                                        bold: true
                                    }),
                                    new TextRun({
                                        font: 'Book Antiqua',
                                        size: 20,
                                        bold: true,
                                        children: [' de ', PageNumber.TOTAL_PAGES]
                                    }),
                                ], spacing: { before: 200, after: 100 }
                            })
                        ]
                    })
                },
                footers: {
                    default: new Footer({
                        children: [
                            new Paragraph({ alignment: AlignmentType.CENTER }),
                            this.createFooter()
                        ]
                    }),
                },
                children: [
                    new Paragraph({
                        alignment: AlignmentType.CENTER,
                        children: [
                            new TextRun({
                                text: 'BALANCE DE MASAS',
                                font: 'Calibri (Body)',
                                size: 22,
                                bold: true
                            })
                        ],
                        spacing: { after: 300, before: 200 }
                    }),
                    this.createTableUDP(data, datePipe, typeInspection),
                    new Paragraph({
                        spacing: {
                            after: 0,
                            before: 0
                        }
                    }),
                    this.createProductores(element),
                    new Paragraph({
                        spacing: {
                            after: 0,
                            before: 0
                        }
                    }),
                    this.createProcesadoresComercializadores(element),
                    new Paragraph({
                        spacing: {
                            after: 0,
                            before: 0
                        }
                    }),
                    this.createProcesarodesMultiIngredientes(element),
                ],
                margins: {
                    top: 350,
                    bottom: 500,
                    left: 1000,
                    right: 1000
                }
            });
        });

        return document;
    }

    public createFooter(): Table {
        const table = new Table({
            rows: [
                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: 'Elaboró:',
                                            font: 'Calibri (Cuerpo)',
                                            size: 22
                                        })
                                    ]
                                })
                            ],
                            width: {
                                size: 33,
                                type: WidthType.PERCENTAGE
                            }
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: 'Revisó:',
                                            font: 'Calibri (Cuerpo)',
                                            size: 22
                                        })
                                    ]
                                })
                            ],
                            width: {
                                size: 33,
                                type: WidthType.PERCENTAGE
                            }
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: 'Autorizó:',
                                            font: 'Calibri (Cuerpo)',
                                            size: 22
                                        })
                                    ]
                                }),
                            ],
                            width: {
                                size: 33,
                                type: WidthType.PERCENTAGE
                            }
                        })
                    ]
                }),
                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: 'Director de certificación',
                                            font: 'Calibri (Cuerpo)',
                                            size: 16,
                                            bold: true
                                        })
                                    ]
                                }),

                            ],
                            width: {
                                size: 33,
                                type: WidthType.PERCENTAGE
                            },
                            shading: {
                                fill: '#C0C0C0'
                            }
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: 'Responsable de calidad',
                                            font: 'Calibri (Cuerpo)',
                                            size: 16,
                                            bold: true
                                        })
                                    ]
                                }),

                            ],
                            width: {
                                size: 33,
                                type: WidthType.PERCENTAGE
                            },
                            shading: {
                                fill: '#C0C0C0'
                            }
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: 'Director general',
                                            font: 'Calibri (Cuerpo)',
                                            size: 16,
                                            bold: true
                                        })
                                    ]
                                }),
                            ],
                            width: {
                                size: 33,
                                type: WidthType.PERCENTAGE
                            },
                            shading: {
                                fill: '#C0C0C0'
                            }
                        })
                    ]
                })
            ],
            margins: {
                left: 70,
                right: 70
            },
            //9638
            columnWidths: [3213, 3213, 3212],
            alignment: AlignmentType.CENTER
        })
        return table
    }

    public createTableUDP(data, datePipe, tipo_inspeccion): Table {
        const table = new Table({
            rows: [
                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: 'Código de operación:',
                                            font: 'Arial',
                                            size: 22,
                                            bold: true
                                        })
                                    ],
                                    spacing: { before: 100, after: 100 }
                                })
                            ],
                            columnSpan: 1
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: data.customer.Id_Comercial,
                                            font: 'Arial',
                                            size: 22
                                        })
                                    ],
                                    spacing: { before: 100, after: 100 }
                                })
                            ],
                            columnSpan: 3
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: 'Fecha:',
                                            font: 'Arial',
                                            size: 22,
                                            bold: true
                                        })
                                    ],
                                    spacing: { before: 100, after: 100 }
                                })
                            ],
                            columnSpan: 1
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: `${datePipe.transform(data.inspectionDate, 'd-MMMM-y')}`,
                                            font: 'Arial',
                                            size: 22
                                        })
                                    ],
                                    spacing: { before: 100, after: 100 }
                                })
                            ],
                            columnSpan: 2
                        }),
                    ]
                }),
                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: 'Operador:',
                                            font: 'Arial',
                                            size: 22,
                                            bold: true
                                        })
                                    ],
                                    spacing: { before: 100, after: 100 }
                                })
                            ],
                            columnSpan: 1
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: data.customer.Company_Name,
                                            font: 'Arial',
                                            size: 22
                                        })
                                    ],
                                    spacing: { before: 100, after: 100 }
                                })
                            ],
                            columnSpan: 6
                        })
                    ]
                }),
                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: 'Tipo de Operador:',
                                            font: 'Arial',
                                            size: 22,
                                            bold: true
                                        })
                                    ],
                                    spacing: { before: 100, after: 100 }
                                })
                            ],
                            columnSpan: 1
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: `${this.getValue(data.submission, 'tipo_operador') === 'Productor' ? '■' : '❏'} Productor`,
                                            font: 'Calibri (Body)',
                                            size: 20
                                        })
                                    ]
                                }),
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: `${this.getValue(data.submission, 'tipo_operador') === 'Procesador/Comercializador' ? '■' : '❏'} Procesador/Comercializador`,
                                            font: 'Calibri (Body)',
                                            size: 20
                                        })
                                    ]
                                })
                            ],
                            columnSpan: 3
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: `Tipo de inspección:`,
                                            font: 'Arial',
                                            size: 22,
                                            bold: true
                                        })
                                    ],
                                    spacing: { before: 100, after: 100 }
                                })
                            ],
                            columnSpan: 1
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: `${tipo_inspeccion === 'Renovación' ? '■' : '❏'} Renovación`,
                                            font: 'Calibri (Body)',
                                            size: 20
                                        })
                                    ]
                                }),
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: `${tipo_inspeccion === 'Primer Inspección' ? '■' : '❏'} Primer Inspección`,
                                            font: 'Calibri (Body)',
                                            size: 20
                                        })
                                    ]
                                }),
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: `${tipo_inspeccion === 'Adicional' ? '■' : '❏'} Adicional`,
                                            font: 'Calibri (Body)',
                                            size: 20
                                        })
                                    ]
                                }),
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: `${tipo_inspeccion === 'Anunciada' ? '■' : '❏'} Anunciada`,
                                            font: 'Calibri (Body)',
                                            size: 20
                                        })
                                    ]
                                }),
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: `${tipo_inspeccion === 'No Anunciada' ? '■' : '❏'} No Anunciada`,
                                            font: 'Calibri (Body)',
                                            size: 20
                                        })
                                    ]
                                })
                            ],
                            columnSpan: 2
                        })
                    ]
                }),
                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: 'Inspector:',
                                            font: 'Arial',
                                            size: 22,
                                            bold: true
                                        })
                                    ],
                                    spacing: { before: 100, after: 100 }
                                })
                            ],
                            columnSpan: 1
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: data.inspector,
                                            font: 'Arial',
                                            size: 22
                                        })
                                    ],
                                    spacing: { before: 100, after: 100 }
                                })
                            ],
                            columnSpan: 6
                        })
                    ]
                }),
            ],
            margins: {
                left: 70,
                right: 70
            },
            width: {
                size: 100,
                type: WidthType.PERCENTAGE,
            },
            columnWidths: [2192, 1802, 1792, 1972, 1892, 1802, 1792]
        })

        return table
    }

    public createProductores(data) {
        const table = new Table({
            rows: [
                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: 'PRODUCTORES',
                                            font: 'Calibri (Body)',
                                            size: 28,
                                            bold: true
                                        })
                                    ]
                                })
                            ],
                            shading: {
                                fill: '#C0C0C0',
                                val: ShadingType.PERCENT_80
                            },
                            columnSpan: 10
                        })
                    ]
                }),

                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: '1',
                                            font: 'Arial',
                                            size: 20
                                        })
                                    ],
                                    spacing: {
                                        after: 100,
                                        before: 100
                                    }
                                })
                            ],
                            columnSpan: 1
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: 'Producto seleccionado',
                                            font: 'Arial',
                                            size: 20
                                        })
                                    ],
                                    spacing: {
                                        after: 100,
                                        before: 100
                                    }
                                })
                            ],
                            columnSpan: 3
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: `${data.productores_1_pregunta}`,
                                            font: 'Arial',
                                            size: 20
                                        })
                                    ],
                                    spacing: {
                                        after: 100,
                                        before: 100
                                    }
                                })
                            ],
                            columnSpan: 6
                        })
                    ]
                }),

                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: '2',
                                            font: 'Arial',
                                            size: 20
                                        })
                                    ],
                                    spacing: {
                                        after: 100,
                                        before: 100
                                    }
                                })
                            ],
                            columnSpan: 1
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: 'Periodo seleccionado',
                                            font: 'Arial',
                                            size: 20
                                        })
                                    ],
                                    spacing: {
                                        after: 100,
                                        before: 100
                                    }
                                })
                            ],
                            columnSpan: 3
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: `Desde: ${moment(data.productores_2_pregunta_desde).format('DD-MMMM-YYYY')}`,
                                            font: 'Arial',
                                            size: 20
                                        })
                                    ],
                                    spacing: {
                                        after: 100,
                                        before: 100
                                    }
                                }),
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: `Hasta: ${moment(data.productores_2_pregunta_hasta).format('DD-MMMM-YYYY')}`,
                                            font: 'Arial',
                                            size: 20
                                        })
                                    ],
                                    spacing: {
                                        after: 100,
                                        before: 100
                                    }
                                })
                            ],
                            columnSpan: 6
                        })
                    ]
                }),

                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: '3',
                                            font: 'Arial',
                                            size: 20
                                        })
                                    ],
                                    spacing: {
                                        after: 100,
                                        before: 100
                                    }
                                })
                            ],
                            columnSpan: 1
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: 'Unidad de medida',
                                            font: 'Arial',
                                            size: 20
                                        })
                                    ],
                                    spacing: {
                                        after: 100,
                                        before: 100
                                    }
                                })
                            ],
                            columnSpan: 3
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: data.productores_3_pregunta,
                                            font: 'Arial',
                                            size: 20
                                        })
                                    ],
                                    spacing: {
                                        after: 100,
                                        before: 100
                                    }
                                })
                            ],
                            columnSpan: 6
                        })
                    ]
                }),

                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: '',
                                            font: 'Arial',
                                            size: 20
                                        })
                                    ],
                                    spacing: {
                                        after: 100,
                                        before: 100
                                    }
                                })
                            ],
                            columnSpan: 1
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: 'Parámetros revisados',
                                            font: 'Arial',
                                            size: 20
                                        })
                                    ],
                                    spacing: {
                                        after: 100,
                                        before: 100
                                    }
                                })
                            ],
                            shading: {
                                fill: '#C0C0C0',
                                val: ShadingType.PERCENT_80
                            },
                            columnSpan: 3
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: 'Resultado',
                                            font: 'Arial',
                                            size: 20
                                        })
                                    ],
                                    spacing: {
                                        after: 100,
                                        before: 100
                                    }
                                })
                            ],
                            shading: {
                                fill: '#C0C0C0',
                                val: ShadingType.PERCENT_80
                            },
                            columnSpan: 3
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: 'Documentos revisados',
                                            font: 'Arial',
                                            size: 20
                                        })
                                    ],
                                    spacing: {
                                        after: 100,
                                        before: 100
                                    }
                                })
                            ],
                            shading: {
                                fill: '#C0C0C0',
                                val: ShadingType.PERCENT_80
                            },
                            columnSpan: 3
                        })
                    ]
                }),

                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: '4',
                                            font: 'Arial',
                                            size: 20
                                        })
                                    ],
                                    spacing: {
                                        after: 100,
                                        before: 100
                                    }
                                })
                            ],
                            columnSpan: 1
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: `Cantidad total cosechada:`,
                                            font: 'Arial',
                                            size: 20
                                        })
                                    ],
                                    spacing: {
                                        after: 100,
                                        before: 100
                                    }
                                })
                            ],
                            columnSpan: 3
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: data.productores_4_resultado,
                                            font: 'Arial',
                                            size: 20
                                        })
                                    ],
                                    spacing: {
                                        after: 100,
                                        before: 100
                                    }
                                })
                            ],
                            columnSpan: 3
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: data.productores_4_documentos,
                                            font: 'Arial',
                                            size: 20
                                        })
                                    ],
                                    spacing: {
                                        after: 100,
                                        before: 100
                                    }
                                })
                            ],
                            columnSpan: 3
                        })
                    ]
                }),

                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: '5',
                                            font: 'Arial',
                                            size: 20
                                        })
                                    ],
                                    spacing: {
                                        after: 100,
                                        before: 100
                                    }
                                })
                            ],
                            columnSpan: 1
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: `Cantidad total vendida:`,
                                            font: 'Arial',
                                            size: 20
                                        })
                                    ],
                                    spacing: {
                                        after: 100,
                                        before: 100
                                    }
                                })
                            ],
                            columnSpan: 3
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: data.productores_5_resultado,
                                            font: 'Arial',
                                            size: 20
                                        })
                                    ],
                                    spacing: {
                                        after: 100,
                                        before: 100
                                    }
                                })
                            ],
                            columnSpan: 3
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: data.productores_5_documentos,
                                            font: 'Arial',
                                            size: 20
                                        })
                                    ],
                                    spacing: {
                                        after: 100,
                                        before: 100
                                    }
                                })
                            ],
                            columnSpan: 3
                        })
                    ]
                }),

                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: '6',
                                            font: 'Arial',
                                            size: 20
                                        })
                                    ],
                                    spacing: {
                                        after: 100,
                                        before: 100
                                    }
                                })
                            ],
                            columnSpan: 1
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: `Pérdidas/Rechazos:`,
                                            font: 'Arial',
                                            size: 20
                                        })
                                    ],
                                    spacing: {
                                        after: 100,
                                        before: 100
                                    }
                                })
                            ],
                            columnSpan: 3
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: data.productores_6_resultado,
                                            font: 'Arial',
                                            size: 20
                                        })
                                    ],
                                    spacing: {
                                        after: 100,
                                        before: 100
                                    }
                                })
                            ],
                            columnSpan: 3
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: data.productores_6_documentos,
                                            font: 'Arial',
                                            size: 20
                                        })
                                    ],
                                    spacing: {
                                        after: 100,
                                        before: 100
                                    }
                                })
                            ],
                            columnSpan: 3
                        })
                    ]
                }),

                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: '7',
                                            font: 'Arial',
                                            size: 20
                                        })
                                    ],
                                    spacing: {
                                        after: 100,
                                        before: 100
                                    }
                                })
                            ],
                            columnSpan: 1
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: `Superficie cosechada (ha):`,
                                            font: 'Arial',
                                            size: 20
                                        })
                                    ],
                                    spacing: {
                                        after: 100,
                                        before: 100
                                    }
                                })
                            ],
                            columnSpan: 3
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: data.productores_7_resultado,
                                            font: 'Arial',
                                            size: 20
                                        })
                                    ],
                                    spacing: {
                                        after: 100,
                                        before: 100
                                    }
                                })
                            ],
                            columnSpan: 3
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: data.productores_7_documentos,
                                            font: 'Arial',
                                            size: 20
                                        })
                                    ],
                                    spacing: {
                                        after: 100,
                                        before: 100
                                    }
                                })
                            ],
                            columnSpan: 3
                        })
                    ]
                }),

                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: '8',
                                            font: 'Arial',
                                            size: 20
                                        })
                                    ],
                                    spacing: {
                                        after: 100,
                                        before: 100
                                    }
                                })
                            ],
                            columnSpan: 1
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: `Rendimiento obtenido:`,
                                            font: 'Arial',
                                            size: 20
                                        })
                                    ],
                                    spacing: {
                                        after: 100,
                                        before: 100
                                    }
                                })
                            ],
                            columnSpan: 3
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: data.productores_8_resultado,
                                            font: 'Arial',
                                            size: 20
                                        })
                                    ],
                                    spacing: {
                                        after: 100,
                                        before: 100
                                    }
                                })
                            ],
                            columnSpan: 3
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: data.productores_8_documentos,
                                            font: 'Arial',
                                            size: 20
                                        })
                                    ],
                                    spacing: {
                                        after: 100,
                                        before: 100
                                    }
                                })
                            ],
                            columnSpan: 3
                        })
                    ]
                }),

                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: '9',
                                            font: 'Arial',
                                            size: 20
                                        })
                                    ],
                                    spacing: {
                                        after: 100,
                                        before: 100
                                    }
                                })
                            ],
                            columnSpan: 1
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: '¿Los rendimientos corresponden con la variedad, manejo, porte del cultivo observado?',
                                            font: 'Arial',
                                            size: 20
                                        })
                                    ],
                                    spacing: {
                                        after: 100,
                                        before: 100
                                    }
                                })
                            ],
                            columnSpan: 6
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: `${data.productores_9_conformidad === 'C' ? '■' : '❏'} C ${data.productores_9_conformidad === 'NC' ? '■' : '❏'} NC ${data.productores_9_conformidad === 'NA' ? '■' : '❏'} NA`,
                                            font: 'Arial',
                                            size: 20
                                        })
                                    ],
                                    spacing: {
                                        after: 100,
                                        before: 100
                                    }
                                })
                            ],
                            columnSpan: 3
                        })
                    ]
                }),
                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: 'Explique: ' + data.productores_9_pregunta,
                                            font: 'Arial',
                                            size: 20
                                        })
                                    ],
                                    spacing: {
                                        after: 100,
                                        before: 100
                                    }
                                })
                            ],
                            columnSpan: 10
                        }),
                    ]
                }),

                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: '10',
                                            font: 'Arial',
                                            size: 20
                                        })
                                    ],
                                    spacing: {
                                        after: 100,
                                        before: 100
                                    }
                                })
                            ],
                            columnSpan: 1
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: '¿Los rendimientos corresponden con los antecedentes de producción del operador?',
                                            font: 'Arial',
                                            size: 20
                                        })
                                    ],
                                    spacing: {
                                        after: 100,
                                        before: 100
                                    }
                                })
                            ],
                            columnSpan: 6
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: `${data.productores_10_conformidad === 'C' ? '■' : '❏'} C ${data.productores_10_conformidad === 'NC' ? '■' : '❏'} NC ${data.productores_10_conformidad === 'NA' ? '■' : '❏'} NA`,
                                            font: 'Arial',
                                            size: 20
                                        })
                                    ],
                                    spacing: {
                                        after: 100,
                                        before: 100
                                    }
                                })
                            ],
                            columnSpan: 3
                        })
                    ]
                }),
                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: 'Explique: ' + data.productores_10_pregunta,
                                            font: 'Arial',
                                            size: 20
                                        })
                                    ],
                                    spacing: {
                                        after: 100,
                                        before: 100
                                    }
                                })
                            ],
                            columnSpan: 10
                        }),
                    ]
                }),

                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: '11',
                                            font: 'Arial',
                                            size: 20
                                        })
                                    ],
                                    spacing: {
                                        after: 100,
                                        before: 100
                                    }
                                })
                            ],
                            columnSpan: 1
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: '¿Cómo son los rendimientos en comparación con los cultivos convencionales de la zona?',
                                            font: 'Arial',
                                            size: 20
                                        })
                                    ],
                                    spacing: {
                                        after: 100,
                                        before: 100
                                    }
                                })
                            ],
                            columnSpan: 6
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: `${data.productores_11_conformidad === 'C' ? '■' : '❏'} C ${data.productores_11_conformidad === 'NC' ? '■' : '❏'} NC ${data.productores_11_conformidad === 'NA' ? '■' : '❏'} NA`,
                                            font: 'Arial',
                                            size: 20
                                        })
                                    ],
                                    spacing: {
                                        after: 100,
                                        before: 100
                                    }
                                })
                            ],
                            columnSpan: 3
                        })
                    ]
                }),
                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: 'Explique: ' + data.productores_11_pregunta,
                                            font: 'Arial',
                                            size: 20
                                        })
                                    ],
                                    spacing: {
                                        after: 100,
                                        before: 100
                                    }
                                })
                            ],
                            columnSpan: 10
                        }),
                    ]
                }),

                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: '12',
                                            font: 'Arial',
                                            size: 20
                                        })
                                    ],
                                    spacing: {
                                        after: 100,
                                        before: 100
                                    }
                                })
                            ],
                            columnSpan: 1
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: '¿Se encuentra congruencia entre las cantidades cosechadas, empacadas, transportadas y vendidas?',
                                            font: 'Arial',
                                            size: 20
                                        })
                                    ],
                                    spacing: {
                                        after: 100,
                                        before: 100
                                    }
                                })
                            ],
                            columnSpan: 6
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: `${data.productores_12_conformidad === 'C' ? '■' : '❏'} C ${data.productores_12_conformidad === 'NC' ? '■' : '❏'} NC ${data.productores_12_conformidad === 'NA' ? '■' : '❏'} NA`,
                                            font: 'Arial',
                                            size: 20
                                        })
                                    ],
                                    spacing: {
                                        after: 100,
                                        before: 100
                                    }
                                })
                            ],
                            columnSpan: 3
                        })
                    ]
                }),
                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: 'Explique: ' + data.productores_12_pregunta,
                                            font: 'Arial',
                                            size: 20
                                        })
                                    ],
                                    spacing: {
                                        after: 100,
                                        before: 100
                                    }
                                })
                            ],
                            columnSpan: 10
                        }),
                    ]
                }),

                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: '13',
                                            font: 'Arial',
                                            size: 20
                                        })
                                    ],
                                    spacing: {
                                        after: 100,
                                        before: 100
                                    }
                                })
                            ],
                            columnSpan: 1
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: '¿Cuál es el rendimiento esperador para la siguiente temporada?',
                                            font: 'Arial',
                                            size: 20
                                        })
                                    ],
                                    spacing: {
                                        after: 100,
                                        before: 100
                                    }
                                })
                            ],
                            columnSpan: 6
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: `Resp: ${data.productores_13_pregunta}`,
                                            font: 'Arial',
                                            size: 20
                                        })
                                    ],
                                    spacing: {
                                        after: 100,
                                        before: 100
                                    }
                                })
                            ],
                            columnSpan: 3
                        })
                    ]
                }),

                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: 'Comentarios: ' + data.productores_comentarios_generales,
                                            font: 'Arial',
                                            size: 20
                                        })
                                    ],
                                    spacing: {
                                        after: 100,
                                        before: 100
                                    }
                                })
                            ],
                            columnSpan: 10
                        }),
                    ]
                }),
            ],
            margins: {
                left: 70,
                right: 70
            },
            width: {
                size: 100,
                type: WidthType.PERCENTAGE
            },
            columnWidths: [500,1359,1359,1359,1359,1359,1359,1359,1200,1200]
        })

        return table
    }

    public createProcesadoresComercializadores(data) {
        const table = new Table({
            rows: [
                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: 'PROCESADORES / COMERCIALIZADORES',
                                            font: 'Calibri (Body)',
                                            size: 28,
                                            bold: true
                                        })
                                    ]
                                })
                            ],
                            shading: {
                                fill: '#C0C0C0',
                                val: ShadingType.PERCENT_80
                            },
                            columnSpan: 10
                        })
                    ]
                }),

                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: '1',
                                            font: 'Arial',
                                            size: 20
                                        })
                                    ],
                                    spacing: {
                                        after: 100,
                                        before: 100
                                    }
                                })
                            ],
                            columnSpan: 1
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: 'Producto/Ingrediente seleccionado',
                                            font: 'Arial',
                                            size: 20
                                        })
                                    ],
                                    spacing: {
                                        after: 100,
                                        before: 100
                                    }
                                })
                            ],
                            columnSpan: 3
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: `${data.procesadores_comercializadores_1_pregunta}`,
                                            font: 'Arial',
                                            size: 20
                                        })
                                    ],
                                    spacing: {
                                        after: 100,
                                        before: 100
                                    }
                                })
                            ],
                            columnSpan: 6
                        })
                    ]
                }),

                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: '2',
                                            font: 'Arial',
                                            size: 20
                                        })
                                    ],
                                    spacing: {
                                        after: 100,
                                        before: 100
                                    }
                                })
                            ],
                            columnSpan: 1
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: 'Periodo seleccionado',
                                            font: 'Arial',
                                            size: 20
                                        })
                                    ],
                                    spacing: {
                                        after: 100,
                                        before: 100
                                    }
                                })
                            ],
                            columnSpan: 3
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: `Desde: ${moment(data.procesadores_comercializadores_2_pregunta_desde).format('DD-MMMM-YYYY')}`,
                                            font: 'Arial',
                                            size: 20
                                        })
                                    ],
                                    spacing: {
                                        after: 100,
                                        before: 100
                                    }
                                }),
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: `Hasta: ${moment(data.procesadores_comercializadores_2_pregunta_hasta).format('DD-MMMM-YYYY')}`,
                                            font: 'Arial',
                                            size: 20
                                        })
                                    ],
                                    spacing: {
                                        after: 100,
                                        before: 100
                                    }
                                })
                            ],
                            columnSpan: 6
                        })
                    ]
                }),

                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: '3',
                                            font: 'Arial',
                                            size: 20
                                        })
                                    ],
                                    spacing: {
                                        after: 100,
                                        before: 100
                                    }
                                })
                            ],
                            columnSpan: 1
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: 'Unidad de medida',
                                            font: 'Arial',
                                            size: 20
                                        })
                                    ],
                                    spacing: {
                                        after: 100,
                                        before: 100
                                    }
                                })
                            ],
                            columnSpan: 3
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: data.procesadores_comercializadores_3_pregunta,
                                            font: 'Arial',
                                            size: 20
                                        })
                                    ],
                                    spacing: {
                                        after: 100,
                                        before: 100
                                    }
                                })
                            ],
                            columnSpan: 6
                        })
                    ]
                }),

                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: '',
                                            font: 'Arial',
                                            size: 20
                                        })
                                    ],
                                    spacing: {
                                        after: 100,
                                        before: 100
                                    }
                                })
                            ],
                            columnSpan: 1
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: 'Parámetros revisados',
                                            font: 'Arial',
                                            size: 20
                                        })
                                    ],
                                    spacing: {
                                        after: 100,
                                        before: 100
                                    }
                                })
                            ],
                            shading: {
                                fill: '#C0C0C0',
                                val: ShadingType.PERCENT_80
                            },
                            columnSpan: 3
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: 'Resultado',
                                            font: 'Arial',
                                            size: 20
                                        })
                                    ],
                                    spacing: {
                                        after: 100,
                                        before: 100
                                    }
                                })
                            ],
                            shading: {
                                fill: '#C0C0C0',
                                val: ShadingType.PERCENT_80
                            },
                            columnSpan: 2
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: 'Documentos revisados',
                                            font: 'Arial',
                                            size: 20
                                        })
                                    ],
                                    spacing: {
                                        after: 100,
                                        before: 100
                                    }
                                })
                            ],
                            shading: {
                                fill: '#C0C0C0',
                                val: ShadingType.PERCENT_80
                            },
                            columnSpan: 2
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: 'Comentarios',
                                            font: 'Arial',
                                            size: 20
                                        })
                                    ],
                                    spacing: {
                                        after: 100,
                                        before: 100
                                    }
                                })
                            ],
                            shading: {
                                fill: '#C0C0C0',
                                val: ShadingType.PERCENT_80
                            },
                            columnSpan: 2
                        })
                    ]
                }),

                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: '4',
                                            font: 'Arial',
                                            size: 20
                                        })
                                    ],
                                    spacing: {
                                        after: 100,
                                        before: 100
                                    }
                                })
                            ],
                            columnSpan: 1
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: `Inventario inicial:`,
                                            font: 'Arial',
                                            size: 20
                                        })
                                    ],
                                    spacing: {
                                        after: 100,
                                        before: 100
                                    }
                                })
                            ],
                            columnSpan: 3
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: data.procesadores_comercializadores_4_resultado,
                                            font: 'Arial',
                                            size: 20
                                        })
                                    ],
                                    spacing: {
                                        after: 100,
                                        before: 100
                                    }
                                })
                            ],
                            columnSpan: 2
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: data.procesadores_comercializadores_4_documentos,
                                            font: 'Arial',
                                            size: 20
                                        })
                                    ],
                                    spacing: {
                                        after: 100,
                                        before: 100
                                    }
                                })
                            ],
                            columnSpan: 2
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: data.procesadores_comercializadores_4_comentarios,
                                            font: 'Arial',
                                            size: 20
                                        })
                                    ],
                                    spacing: {
                                        after: 100,
                                        before: 100
                                    }
                                })
                            ],
                            columnSpan: 2
                        })
                    ]
                }),

                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: '5',
                                            font: 'Arial',
                                            size: 20
                                        })
                                    ],
                                    spacing: {
                                        after: 100,
                                        before: 100
                                    }
                                })
                            ],
                            columnSpan: 1
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: `Inventario final:`,
                                            font: 'Arial',
                                            size: 20
                                        })
                                    ],
                                    spacing: {
                                        after: 100,
                                        before: 100
                                    }
                                })
                            ],
                            columnSpan: 3
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: data.procesadores_comercializadores_5_resultado,
                                            font: 'Arial',
                                            size: 20
                                        })
                                    ],
                                    spacing: {
                                        after: 100,
                                        before: 100
                                    }
                                })
                            ],
                            columnSpan: 2
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: data.procesadores_comercializadores_5_documentos,
                                            font: 'Arial',
                                            size: 20
                                        })
                                    ],
                                    spacing: {
                                        after: 100,
                                        before: 100
                                    }
                                })
                            ],
                            columnSpan: 2
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: data.procesadores_comercializadores_5_comentarios,
                                            font: 'Arial',
                                            size: 20
                                        })
                                    ],
                                    spacing: {
                                        after: 100,
                                        before: 100
                                    }
                                })
                            ],
                            columnSpan: 2
                        })
                    ]
                }),

                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: '6',
                                            font: 'Arial',
                                            size: 20
                                        })
                                    ],
                                    spacing: {
                                        after: 100,
                                        before: 100
                                    }
                                })
                            ],
                            columnSpan: 1
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: `Producción/Compras:`,
                                            font: 'Arial',
                                            size: 20
                                        })
                                    ],
                                    spacing: {
                                        after: 100,
                                        before: 100
                                    }
                                })
                            ],
                            columnSpan: 3
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: data.procesadores_comercializadores_6_resultado,
                                            font: 'Arial',
                                            size: 20
                                        })
                                    ],
                                    spacing: {
                                        after: 100,
                                        before: 100
                                    }
                                })
                            ],
                            columnSpan: 2
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: data.procesadores_comercializadores_6_documentos,
                                            font: 'Arial',
                                            size: 20
                                        })
                                    ],
                                    spacing: {
                                        after: 100,
                                        before: 100
                                    }
                                })
                            ],
                            columnSpan: 2
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: data.procesadores_comercializadores_6_comentarios,
                                            font: 'Arial',
                                            size: 20
                                        })
                                    ],
                                    spacing: {
                                        after: 100,
                                        before: 100
                                    }
                                })
                            ],
                            columnSpan: 2
                        })
                    ]
                }),

                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: '7',
                                            font: 'Arial',
                                            size: 20
                                        })
                                    ],
                                    spacing: {
                                        after: 100,
                                        before: 100
                                    }
                                })
                            ],
                            columnSpan: 1
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: `Ventas:`,
                                            font: 'Arial',
                                            size: 20
                                        })
                                    ],
                                    spacing: {
                                        after: 100,
                                        before: 100
                                    }
                                })
                            ],
                            columnSpan: 3
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: data.procesadores_comercializadores_7_resultado,
                                            font: 'Arial',
                                            size: 20
                                        })
                                    ],
                                    spacing: {
                                        after: 100,
                                        before: 100
                                    }
                                })
                            ],
                            columnSpan: 2
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: data.procesadores_comercializadores_7_documentos,
                                            font: 'Arial',
                                            size: 20
                                        })
                                    ],
                                    spacing: {
                                        after: 100,
                                        before: 100
                                    }
                                })
                            ],
                            columnSpan: 2
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: data.procesadores_comercializadores_7_comentarios,
                                            font: 'Arial',
                                            size: 20
                                        })
                                    ],
                                    spacing: {
                                        after: 100,
                                        before: 100
                                    }
                                })
                            ],
                            columnSpan: 2
                        })
                    ]
                }),

                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: '8',
                                            font: 'Arial',
                                            size: 20
                                        })
                                    ],
                                    spacing: {
                                        after: 100,
                                        before: 100
                                    }
                                })
                            ],
                            columnSpan: 1
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: `Mermas:`,
                                            font: 'Arial',
                                            size: 20
                                        })
                                    ],
                                    spacing: {
                                        after: 100,
                                        before: 100
                                    }
                                })
                            ],
                            columnSpan: 3
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: data.procesadores_comercializadores_8_resultado,
                                            font: 'Arial',
                                            size: 20
                                        })
                                    ],
                                    spacing: {
                                        after: 100,
                                        before: 100
                                    }
                                })
                            ],
                            columnSpan: 2
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: data.procesadores_comercializadores_8_documentos,
                                            font: 'Arial',
                                            size: 20
                                        })
                                    ],
                                    spacing: {
                                        after: 100,
                                        before: 100
                                    }
                                })
                            ],
                            columnSpan: 2
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: data.procesadores_comercializadores_8_comentarios,
                                            font: 'Arial',
                                            size: 20
                                        })
                                    ],
                                    spacing: {
                                        after: 100,
                                        before: 100
                                    }
                                })
                            ],
                            columnSpan: 2
                        })
                    ]
                }),

                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: '9',
                                            font: 'Arial',
                                            size: 20
                                        })
                                    ],
                                    spacing: {
                                        after: 100,
                                        before: 100
                                    }
                                })
                            ],
                            columnSpan: 1
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: `Inventario final teórico: (4+6-7-8)`,
                                            font: 'Arial',
                                            size: 20
                                        })
                                    ],
                                    spacing: {
                                        after: 100,
                                        before: 100
                                    }
                                })
                            ],
                            columnSpan: 3
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: data.procesadores_comercializadores_9_resultado,
                                            font: 'Arial',
                                            size: 20
                                        })
                                    ],
                                    spacing: {
                                        after: 100,
                                        before: 100
                                    }
                                })
                            ],
                            columnSpan: 2
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: data.procesadores_comercializadores_9_documentos,
                                            font: 'Arial',
                                            size: 20
                                        })
                                    ],
                                    spacing: {
                                        after: 100,
                                        before: 100
                                    }
                                })
                            ],
                            columnSpan: 2
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: data.procesadores_comercializadores_9_comentarios,
                                            font: 'Arial',
                                            size: 20
                                        })
                                    ],
                                    spacing: {
                                        after: 100,
                                        before: 100
                                    }
                                })
                            ],
                            columnSpan: 2
                        })
                    ]
                }),

                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: '10',
                                            font: 'Arial',
                                            size: 20
                                        })
                                    ],
                                    spacing: {
                                        after: 100,
                                        before: 100
                                    }
                                })
                            ],
                            columnSpan: 1
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: `Diferencia entre inventario final teórico y físico: (9-5):`,
                                            font: 'Arial',
                                            size: 20
                                        })
                                    ],
                                    spacing: {
                                        after: 100,
                                        before: 100
                                    }
                                })
                            ],
                            columnSpan: 3
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: data.procesadores_comercializadores_10_resultado,
                                            font: 'Arial',
                                            size: 20
                                        })
                                    ],
                                    spacing: {
                                        after: 100,
                                        before: 100
                                    }
                                })
                            ],
                            columnSpan: 2
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: data.procesadores_comercializadores_10_documentos,
                                            font: 'Arial',
                                            size: 20
                                        })
                                    ],
                                    spacing: {
                                        after: 100,
                                        before: 100
                                    }
                                })
                            ],
                            columnSpan: 2
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: data.procesadores_comercializadores_10_comentarios,
                                            font: 'Arial',
                                            size: 20
                                        })
                                    ],
                                    spacing: {
                                        after: 100,
                                        before: 100
                                    }
                                })
                            ],
                            columnSpan: 2
                        })
                    ]
                }),

                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: '11',
                                            font: 'Arial',
                                            size: 20
                                        })
                                    ],
                                    spacing: {
                                        after: 100,
                                        before: 100
                                    }
                                })
                            ],
                            columnSpan: 1
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: `Diferencia de inventario final en proporción con volumen total comercializado: (10*100/7)`,
                                            font: 'Arial',
                                            size: 20
                                        })
                                    ],
                                    spacing: {
                                        after: 100,
                                        before: 100
                                    }
                                })
                            ],
                            columnSpan: 3
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: data.procesadores_comercializadores_11_resultado,
                                            font: 'Arial',
                                            size: 20
                                        })
                                    ],
                                    spacing: {
                                        after: 100,
                                        before: 100
                                    }
                                })
                            ],
                            columnSpan: 2
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: data.procesadores_comercializadores_11_documentos,
                                            font: 'Arial',
                                            size: 20
                                        })
                                    ],
                                    spacing: {
                                        after: 100,
                                        before: 100
                                    }
                                })
                            ],
                            columnSpan: 2
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: data.procesadores_comercializadores_11_comentarios,
                                            font: 'Arial',
                                            size: 20
                                        })
                                    ],
                                    spacing: {
                                        after: 100,
                                        before: 100
                                    }
                                })
                            ],
                            columnSpan: 2
                        })
                    ]
                }),

                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: '12',
                                            font: 'Arial',
                                            size: 20
                                        })
                                    ],
                                    spacing: {
                                        after: 100,
                                        before: 100
                                    }
                                })
                            ],
                            columnSpan: 1
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: `Cantidad de producto orgánico vendido como producto convencional:`,
                                            font: 'Arial',
                                            size: 20
                                        })
                                    ],
                                    spacing: {
                                        after: 100,
                                        before: 100
                                    }
                                })
                            ],
                            columnSpan: 3
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: data.procesadores_comercializadores_12_resultado,
                                            font: 'Arial',
                                            size: 20
                                        })
                                    ],
                                    spacing: {
                                        after: 100,
                                        before: 100
                                    }
                                })
                            ],
                            columnSpan: 2
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: data.procesadores_comercializadores_12_documentos,
                                            font: 'Arial',
                                            size: 20
                                        })
                                    ],
                                    spacing: {
                                        after: 100,
                                        before: 100
                                    }
                                })
                            ],
                            columnSpan: 2
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: data.procesadores_comercializadores_12_comentarios,
                                            font: 'Arial',
                                            size: 20
                                        })
                                    ],
                                    spacing: {
                                        after: 100,
                                        before: 100
                                    }
                                })
                            ],
                            columnSpan: 2
                        })
                    ]
                }),
                
                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: '13',
                                            font: 'Arial',
                                            size: 20
                                        })
                                    ],
                                    spacing: {
                                        after: 100,
                                        before: 100
                                    }
                                })
                            ],
                            columnSpan: 1
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: '¿El ejercicio de balance de masas es positivo?',
                                            font: 'Arial',
                                            size: 20
                                        })
                                    ],
                                    spacing: {
                                        after: 100,
                                        before: 100
                                    }
                                })
                            ],
                            columnSpan: 6
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: `${data.procesadores_comercializadores_13_conformidad === 'C' ? '■' : '❏'} C ${data.procesadores_comercializadores_13_conformidad === 'NC' ? '■' : '❏'} NC ${data.procesadores_comercializadores_13_conformidad === 'NA' ? '■' : '❏'} NA`,
                                            font: 'Arial',
                                            size: 20
                                        })
                                    ],
                                    spacing: {
                                        after: 100,
                                        before: 100
                                    }
                                })
                            ],
                            columnSpan: 3
                        })
                    ]
                }),
                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: 'Explique: ' + data.procesadores_comercializadores_13_pregunta,
                                            font: 'Arial',
                                            size: 20
                                        })
                                    ],
                                    spacing: {
                                        after: 100,
                                        before: 100
                                    }
                                })
                            ],
                            columnSpan: 10
                        }),
                    ]
                }),

                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: '14',
                                            font: 'Arial',
                                            size: 20
                                        })
                                    ],
                                    spacing: {
                                        after: 100,
                                        before: 100
                                    }
                                })
                            ],
                            columnSpan: 1
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: '¿Los rendimientos del proceso son razonables?',
                                            font: 'Arial',
                                            size: 20
                                        })
                                    ],
                                    spacing: {
                                        after: 100,
                                        before: 100
                                    }
                                })
                            ],
                            columnSpan: 6
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: `${data.procesadores_comercializadores_14_conformidad === 'C' ? '■' : '❏'} C ${data.procesadores_comercializadores_14_conformidad === 'NC' ? '■' : '❏'} NC ${data.procesadores_comercializadores_14_conformidad === 'NA' ? '■' : '❏'} NA`,
                                            font: 'Arial',
                                            size: 20
                                        })
                                    ],
                                    spacing: {
                                        after: 100,
                                        before: 100
                                    }
                                })
                            ],
                            columnSpan: 3
                        })
                    ]
                }),
                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: 'Explique: ' + data.procesadores_comercializadores_14_pregunta,
                                            font: 'Arial',
                                            size: 20
                                        })
                                    ],
                                    spacing: {
                                        after: 100,
                                        before: 100
                                    }
                                })
                            ],
                            columnSpan: 10
                        }),
                    ]
                }),

                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: '15',
                                            font: 'Arial',
                                            size: 20
                                        })
                                    ],
                                    spacing: {
                                        after: 100,
                                        before: 100
                                    }
                                })
                            ],
                            columnSpan: 1
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: '¿Cuál es el volumen de producción/ventas estimado para la siguiente temporada?',
                                            font: 'Arial',
                                            size: 20
                                        })
                                    ],
                                    spacing: {
                                        after: 100,
                                        before: 100
                                    }
                                })
                            ],
                            columnSpan: 6
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: `Resp: ${data.procesadores_comercializadores_15_pregunta}`,
                                            font: 'Arial',
                                            size: 20
                                        })
                                    ],
                                    spacing: {
                                        after: 100,
                                        before: 100
                                    }
                                })
                            ],
                            columnSpan: 3
                        })
                    ]
                }),

                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: 'Comentarios Generales: ' + data.procesadores_comercializadores_comentarios_generales,
                                            font: 'Arial',
                                            size: 20
                                        })
                                    ],
                                    spacing: {
                                        after: 100,
                                        before: 100
                                    }
                                })
                            ],
                            columnSpan: 10
                        }),
                    ]
                }),
            ],
            margins: {
                left: 70,
                right: 70
            },
            width: {
                size: 100,
                type: WidthType.PERCENTAGE
            },
            columnWidths: [500,1359,1359,1359,1359,1359,1359,1359,1200,1200]
        })

        return table
    }

    public createProcesarodesMultiIngredientes(data) {
        const table = new Table({
            rows: [
                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: 'PROCESADORES MULTI-INGREDIENTES',
                                            font: 'Calibri (Body)',
                                            size: 28,
                                            bold: true
                                        })
                                    ]
                                })
                            ],
                            shading: {
                                fill: '#C0C0C0',
                                val: ShadingType.PERCENT_80
                            },
                            columnSpan: 10
                        })
                    ]
                }),

                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: '1',
                                            font: 'Arial',
                                            size: 20
                                        })
                                    ],
                                    spacing: {
                                        after: 100,
                                        before: 100
                                    }
                                })
                            ],
                            columnSpan: 1
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: 'Ingrediente seleccionado',
                                            font: 'Arial',
                                            size: 20
                                        })
                                    ],
                                    spacing: {
                                        after: 100,
                                        before: 100
                                    }
                                })
                            ],
                            columnSpan: 3
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: `${data.procesadores_multi_ingredientes_1_pregunta}`,
                                            font: 'Arial',
                                            size: 20
                                        })
                                    ],
                                    spacing: {
                                        after: 100,
                                        before: 100
                                    }
                                })
                            ],
                            columnSpan: 6
                        })
                    ]
                }),

                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: '2',
                                            font: 'Arial',
                                            size: 20
                                        })
                                    ],
                                    spacing: {
                                        after: 100,
                                        before: 100
                                    }
                                })
                            ],
                            columnSpan: 1
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: 'Periodo seleccionado',
                                            font: 'Arial',
                                            size: 20
                                        })
                                    ],
                                    spacing: {
                                        after: 100,
                                        before: 100
                                    }
                                })
                            ],
                            columnSpan: 3
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: `Desde: ${moment(data.procesadores_multi_ingredientes_2_pregunta_desde).format('DD-MMMM-YYYY')}`,
                                            font: 'Arial',
                                            size: 20
                                        })
                                    ],
                                    spacing: {
                                        after: 100,
                                        before: 100
                                    }
                                }),
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: `Hasta: ${moment(data.procesadores_multi_ingredientes_2_pregunta_hasta).format('DD-MMMM-YYYY')}`,
                                            font: 'Arial',
                                            size: 20
                                        })
                                    ],
                                    spacing: {
                                        after: 100,
                                        before: 100
                                    }
                                })
                            ],
                            columnSpan: 6
                        })
                    ]
                }),

                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: '3',
                                            font: 'Arial',
                                            size: 20
                                        })
                                    ],
                                    spacing: {
                                        after: 100,
                                        before: 100
                                    }
                                })
                            ],
                            columnSpan: 1
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: 'Unidad de medida',
                                            font: 'Arial',
                                            size: 20
                                        })
                                    ],
                                    spacing: {
                                        after: 100,
                                        before: 100
                                    }
                                })
                            ],
                            columnSpan: 3
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: data.procesadores_multi_ingredientes_3_pregunta,
                                            font: 'Arial',
                                            size: 20
                                        })
                                    ],
                                    spacing: {
                                        after: 100,
                                        before: 100
                                    }
                                })
                            ],
                            columnSpan: 6
                        })
                    ]
                }),

                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: '',
                                            font: 'Arial',
                                            size: 20
                                        })
                                    ],
                                    spacing: {
                                        after: 100,
                                        before: 100
                                    }
                                })
                            ],
                            columnSpan: 1
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: 'Parámetros revisados',
                                            font: 'Arial',
                                            size: 20
                                        })
                                    ],
                                    spacing: {
                                        after: 100,
                                        before: 100
                                    }
                                })
                            ],
                            shading: {
                                fill: '#C0C0C0',
                                val: ShadingType.PERCENT_80
                            },
                            columnSpan: 3
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: 'Resultado',
                                            font: 'Arial',
                                            size: 20
                                        })
                                    ],
                                    spacing: {
                                        after: 100,
                                        before: 100
                                    }
                                })
                            ],
                            shading: {
                                fill: '#C0C0C0',
                                val: ShadingType.PERCENT_80
                            },
                            columnSpan: 2
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: 'Documentos revisados',
                                            font: 'Arial',
                                            size: 20
                                        })
                                    ],
                                    spacing: {
                                        after: 100,
                                        before: 100
                                    }
                                })
                            ],
                            shading: {
                                fill: '#C0C0C0',
                                val: ShadingType.PERCENT_80
                            },
                            columnSpan: 2
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: 'Comentarios',
                                            font: 'Arial',
                                            size: 20
                                        })
                                    ],
                                    spacing: {
                                        after: 100,
                                        before: 100
                                    }
                                })
                            ],
                            shading: {
                                fill: '#C0C0C0',
                                val: ShadingType.PERCENT_80
                            },
                            columnSpan: 2
                        })
                    ]
                }),

                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: '4',
                                            font: 'Arial',
                                            size: 20
                                        })
                                    ],
                                    spacing: {
                                        after: 100,
                                        before: 100
                                    }
                                })
                            ],
                            columnSpan: 1
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: `Inventario inicial:`,
                                            font: 'Arial',
                                            size: 20
                                        })
                                    ],
                                    spacing: {
                                        after: 100,
                                        before: 100
                                    }
                                })
                            ],
                            columnSpan: 3
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: data.procesadores_multi_ingredientes_4_resultado,
                                            font: 'Arial',
                                            size: 20
                                        })
                                    ],
                                    spacing: {
                                        after: 100,
                                        before: 100
                                    }
                                })
                            ],
                            columnSpan: 2
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: data.procesadores_multi_ingredientes_4_documentos,
                                            font: 'Arial',
                                            size: 20
                                        })
                                    ],
                                    spacing: {
                                        after: 100,
                                        before: 100
                                    }
                                })
                            ],
                            columnSpan: 2
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: data.procesadores_multi_ingredientes_4_comentarios,
                                            font: 'Arial',
                                            size: 20
                                        })
                                    ],
                                    spacing: {
                                        after: 100,
                                        before: 100
                                    }
                                })
                            ],
                            columnSpan: 2
                        })
                    ]
                }),

                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: '5',
                                            font: 'Arial',
                                            size: 20
                                        })
                                    ],
                                    spacing: {
                                        after: 100,
                                        before: 100
                                    }
                                })
                            ],
                            columnSpan: 1
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: `Inventario final:`,
                                            font: 'Arial',
                                            size: 20
                                        })
                                    ],
                                    spacing: {
                                        after: 100,
                                        before: 100
                                    }
                                })
                            ],
                            columnSpan: 3
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: data.procesadores_multi_ingredientes_5_resultado,
                                            font: 'Arial',
                                            size: 20
                                        })
                                    ],
                                    spacing: {
                                        after: 100,
                                        before: 100
                                    }
                                })
                            ],
                            columnSpan: 2
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: data.procesadores_multi_ingredientes_5_documentos,
                                            font: 'Arial',
                                            size: 20
                                        })
                                    ],
                                    spacing: {
                                        after: 100,
                                        before: 100
                                    }
                                })
                            ],
                            columnSpan: 2
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: data.procesadores_multi_ingredientes_5_comentarios,
                                            font: 'Arial',
                                            size: 20
                                        })
                                    ],
                                    spacing: {
                                        after: 100,
                                        before: 100
                                    }
                                })
                            ],
                            columnSpan: 2
                        })
                    ]
                }),

                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: '6',
                                            font: 'Arial',
                                            size: 20
                                        })
                                    ],
                                    spacing: {
                                        after: 100,
                                        before: 100
                                    }
                                })
                            ],
                            columnSpan: 1
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: `Compras:`,
                                            font: 'Arial',
                                            size: 20
                                        })
                                    ],
                                    spacing: {
                                        after: 100,
                                        before: 100
                                    }
                                })
                            ],
                            columnSpan: 3
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: data.procesadores_multi_ingredientes_6_resultado,
                                            font: 'Arial',
                                            size: 20
                                        })
                                    ],
                                    spacing: {
                                        after: 100,
                                        before: 100
                                    }
                                })
                            ],
                            columnSpan: 2
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: data.procesadores_multi_ingredientes_6_documentos,
                                            font: 'Arial',
                                            size: 20
                                        })
                                    ],
                                    spacing: {
                                        after: 100,
                                        before: 100
                                    }
                                })
                            ],
                            columnSpan: 2
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: data.procesadores_multi_ingredientes_6_comentarios,
                                            font: 'Arial',
                                            size: 20
                                        })
                                    ],
                                    spacing: {
                                        after: 100,
                                        before: 100
                                    }
                                })
                            ],
                            columnSpan: 2
                        })
                    ]
                }),

                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: '7',
                                            font: 'Arial',
                                            size: 20
                                        })
                                    ],
                                    spacing: {
                                        after: 100,
                                        before: 100
                                    }
                                })
                            ],
                            columnSpan: 1
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: `Usado en Producción:`,
                                            font: 'Arial',
                                            size: 20
                                        })
                                    ],
                                    spacing: {
                                        after: 100,
                                        before: 100
                                    }
                                })
                            ],
                            columnSpan: 3
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: data.procesadores_multi_ingredientes_7_resultado,
                                            font: 'Arial',
                                            size: 20
                                        })
                                    ],
                                    spacing: {
                                        after: 100,
                                        before: 100
                                    }
                                })
                            ],
                            columnSpan: 2
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: data.procesadores_multi_ingredientes_7_documentos,
                                            font: 'Arial',
                                            size: 20
                                        })
                                    ],
                                    spacing: {
                                        after: 100,
                                        before: 100
                                    }
                                })
                            ],
                            columnSpan: 2
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: data.procesadores_multi_ingredientes_7_comentarios,
                                            font: 'Arial',
                                            size: 20
                                        })
                                    ],
                                    spacing: {
                                        after: 100,
                                        before: 100
                                    }
                                })
                            ],
                            columnSpan: 2
                        })
                    ]
                }),
                
                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: '',
                                            font: 'Arial',
                                            size: 20
                                        })
                                    ],
                                    spacing: {
                                        after: 100,
                                        before: 100
                                    }
                                })
                            ],
                            columnSpan: 1
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: `Producto 1: ${data.procesadores_multi_ingredientes_7_producto_1_nombre}`,
                                            font: 'Arial',
                                            size: 20
                                        })
                                    ],
                                    spacing: {
                                        after: 100,
                                        before: 100
                                    }
                                })
                            ],
                            columnSpan: 3
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: data.procesadores_multi_ingredientes_7_producto_1_nombre_resultado,
                                            font: 'Arial',
                                            size: 20
                                        })
                                    ],
                                    spacing: {
                                        after: 100,
                                        before: 100
                                    }
                                })
                            ],
                            columnSpan: 2
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: data.procesadores_multi_ingredientes_7_producto_1_nombre_documentos,
                                            font: 'Arial',
                                            size: 20
                                        })
                                    ],
                                    spacing: {
                                        after: 100,
                                        before: 100
                                    }
                                })
                            ],
                            columnSpan: 2
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: data.procesadores_multi_ingredientes_7_producto_1_nombre_comentarios,
                                            font: 'Arial',
                                            size: 20
                                        })
                                    ],
                                    spacing: {
                                        after: 100,
                                        before: 100
                                    }
                                })
                            ],
                            columnSpan: 2
                        })
                    ]
                }),

                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: '',
                                            font: 'Arial',
                                            size: 20
                                        })
                                    ],
                                    spacing: {
                                        after: 100,
                                        before: 100
                                    }
                                })
                            ],
                            columnSpan: 1
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: `Volumen Producido:`,
                                            font: 'Arial',
                                            size: 20
                                        })
                                    ],
                                    spacing: {
                                        after: 100,
                                        before: 100
                                    }
                                })
                            ],
                            columnSpan: 1
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: data.procesadores_multi_ingredientes_7_producto_1_volumen,
                                            font: 'Arial',
                                            size: 20
                                        })
                                    ],
                                    spacing: {
                                        after: 100,
                                        before: 100
                                    }
                                })
                            ],
                            columnSpan: 2
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: data.procesadores_multi_ingredientes_7_producto_1_volumen_resultado,
                                            font: 'Arial',
                                            size: 20
                                        })
                                    ],
                                    spacing: {
                                        after: 100,
                                        before: 100
                                    }
                                })
                            ],
                            columnSpan: 2
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: data.procesadores_multi_ingredientes_7_producto_1_volumen_documentos,
                                            font: 'Arial',
                                            size: 20
                                        })
                                    ],
                                    spacing: {
                                        after: 100,
                                        before: 100
                                    }
                                })
                            ],
                            columnSpan: 2
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: data.procesadores_multi_ingredientes_7_producto_1_volumen_comentarios,
                                            font: 'Arial',
                                            size: 20
                                        })
                                    ],
                                    spacing: {
                                        after: 100,
                                        before: 100
                                    }
                                })
                            ],
                            columnSpan: 2
                        })
                    ]
                }),

                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: '',
                                            font: 'Arial',
                                            size: 20
                                        })
                                    ],
                                    spacing: {
                                        after: 100,
                                        before: 100
                                    }
                                })
                            ],
                            columnSpan: 1
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: `% Ingrediente:`,
                                            font: 'Arial',
                                            size: 20
                                        })
                                    ],
                                    spacing: {
                                        after: 100,
                                        before: 100
                                    }
                                })
                            ],
                            columnSpan: 1
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: data.procesadores_multi_ingredientes_7_producto_1_porcentaje,
                                            font: 'Arial',
                                            size: 20
                                        })
                                    ],
                                    spacing: {
                                        after: 100,
                                        before: 100
                                    }
                                })
                            ],
                            columnSpan: 2
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: data.procesadores_multi_ingredientes_7_producto_1_porcentaje_resultado,
                                            font: 'Arial',
                                            size: 20
                                        })
                                    ],
                                    spacing: {
                                        after: 100,
                                        before: 100
                                    }
                                })
                            ],
                            columnSpan: 2
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: data.procesadores_multi_ingredientes_7_producto_1_porcentaje_documentos,
                                            font: 'Arial',
                                            size: 20
                                        })
                                    ],
                                    spacing: {
                                        after: 100,
                                        before: 100
                                    }
                                })
                            ],
                            columnSpan: 2
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: data.procesadores_multi_ingredientes_7_producto_1_porcentaje_comentarios,
                                            font: 'Arial',
                                            size: 20
                                        })
                                    ],
                                    spacing: {
                                        after: 100,
                                        before: 100
                                    }
                                })
                            ],
                            columnSpan: 2
                        })
                    ]
                }),

                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: '',
                                            font: 'Arial',
                                            size: 20
                                        })
                                    ],
                                    spacing: {
                                        after: 100,
                                        before: 100
                                    }
                                })
                            ],
                            columnSpan: 1
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: `Volumen ingrediente: ${data.procesadores_multi_ingredientes_7_producto_1_volumen_ingrediente}`,
                                            font: 'Arial',
                                            size: 20
                                        })
                                    ],
                                    spacing: {
                                        after: 100,
                                        before: 100
                                    }
                                })
                            ],
                            columnSpan: 3
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: data.procesadores_multi_ingredientes_7_producto_1_volumen_ingrediente_resultado,
                                            font: 'Arial',
                                            size: 20
                                        })
                                    ],
                                    spacing: {
                                        after: 100,
                                        before: 100
                                    }
                                })
                            ],
                            columnSpan: 2
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: data.procesadores_multi_ingredientes_7_producto_1_volumen_ingrediente_documentos,
                                            font: 'Arial',
                                            size: 20
                                        })
                                    ],
                                    spacing: {
                                        after: 100,
                                        before: 100
                                    }
                                })
                            ],
                            columnSpan: 2
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: data.procesadores_multi_ingredientes_7_producto_1_volumen_ingrediente_comentarios,
                                            font: 'Arial',
                                            size: 20
                                        })
                                    ],
                                    spacing: {
                                        after: 100,
                                        before: 100
                                    }
                                })
                            ],
                            columnSpan: 2
                        })
                    ]
                }),
                
                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: '',
                                            font: 'Arial',
                                            size: 20
                                        })
                                    ],
                                    spacing: {
                                        after: 100,
                                        before: 100
                                    }
                                })
                            ],
                            columnSpan: 1
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: `Producto 1: ${data.procesadores_multi_ingredientes_7_producto_2_nombre}`,
                                            font: 'Arial',
                                            size: 20
                                        })
                                    ],
                                    spacing: {
                                        after: 100,
                                        before: 100
                                    }
                                })
                            ],
                            columnSpan: 3
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: data.procesadores_multi_ingredientes_7_producto_2_nombre_resultado,
                                            font: 'Arial',
                                            size: 20
                                        })
                                    ],
                                    spacing: {
                                        after: 100,
                                        before: 100
                                    }
                                })
                            ],
                            columnSpan: 2
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: data.procesadores_multi_ingredientes_7_producto_2_nombre_documentos,
                                            font: 'Arial',
                                            size: 20
                                        })
                                    ],
                                    spacing: {
                                        after: 100,
                                        before: 100
                                    }
                                })
                            ],
                            columnSpan: 2
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: data.procesadores_multi_ingredientes_7_producto_2_nombre_comentarios,
                                            font: 'Arial',
                                            size: 20
                                        })
                                    ],
                                    spacing: {
                                        after: 100,
                                        before: 100
                                    }
                                })
                            ],
                            columnSpan: 2
                        })
                    ]
                }),

                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: '',
                                            font: 'Arial',
                                            size: 20
                                        })
                                    ],
                                    spacing: {
                                        after: 100,
                                        before: 100
                                    }
                                })
                            ],
                            columnSpan: 1
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: `Volumen Producido:`,
                                            font: 'Arial',
                                            size: 20
                                        })
                                    ],
                                    spacing: {
                                        after: 100,
                                        before: 100
                                    }
                                })
                            ],
                            columnSpan: 1
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: data.procesadores_multi_ingredientes_7_producto_2_volumen,
                                            font: 'Arial',
                                            size: 20
                                        })
                                    ],
                                    spacing: {
                                        after: 100,
                                        before: 100
                                    }
                                })
                            ],
                            columnSpan: 2
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: data.procesadores_multi_ingredientes_7_producto_2_volumen_resultado,
                                            font: 'Arial',
                                            size: 20
                                        })
                                    ],
                                    spacing: {
                                        after: 100,
                                        before: 100
                                    }
                                })
                            ],
                            columnSpan: 2
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: data.procesadores_multi_ingredientes_7_producto_2_volumen_documentos,
                                            font: 'Arial',
                                            size: 20
                                        })
                                    ],
                                    spacing: {
                                        after: 100,
                                        before: 100
                                    }
                                })
                            ],
                            columnSpan: 2
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: data.procesadores_multi_ingredientes_7_producto_2_volumen_comentarios,
                                            font: 'Arial',
                                            size: 20
                                        })
                                    ],
                                    spacing: {
                                        after: 100,
                                        before: 100
                                    }
                                })
                            ],
                            columnSpan: 2
                        })
                    ]
                }),

                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: '',
                                            font: 'Arial',
                                            size: 20
                                        })
                                    ],
                                    spacing: {
                                        after: 100,
                                        before: 100
                                    }
                                })
                            ],
                            columnSpan: 1
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: `% Ingrediente:`,
                                            font: 'Arial',
                                            size: 20
                                        })
                                    ],
                                    spacing: {
                                        after: 100,
                                        before: 100
                                    }
                                })
                            ],
                            columnSpan: 1
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: data.procesadores_multi_ingredientes_7_producto_2_porcentaje,
                                            font: 'Arial',
                                            size: 20
                                        })
                                    ],
                                    spacing: {
                                        after: 100,
                                        before: 100
                                    }
                                })
                            ],
                            columnSpan: 2
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: data.procesadores_multi_ingredientes_7_producto_2_porcentaje_resultado,
                                            font: 'Arial',
                                            size: 20
                                        })
                                    ],
                                    spacing: {
                                        after: 100,
                                        before: 100
                                    }
                                })
                            ],
                            columnSpan: 2
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: data.procesadores_multi_ingredientes_7_producto_2_porcentaje_documentos,
                                            font: 'Arial',
                                            size: 20
                                        })
                                    ],
                                    spacing: {
                                        after: 100,
                                        before: 100
                                    }
                                })
                            ],
                            columnSpan: 2
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: data.procesadores_multi_ingredientes_7_producto_2_porcentaje_comentarios,
                                            font: 'Arial',
                                            size: 20
                                        })
                                    ],
                                    spacing: {
                                        after: 100,
                                        before: 100
                                    }
                                })
                            ],
                            columnSpan: 2
                        })
                    ]
                }),

                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: '',
                                            font: 'Arial',
                                            size: 20
                                        })
                                    ],
                                    spacing: {
                                        after: 100,
                                        before: 100
                                    }
                                })
                            ],
                            columnSpan: 1
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: `Volumen ingrediente: ${data.procesadores_multi_ingredientes_7_producto_2_volumen_ingrediente}`,
                                            font: 'Arial',
                                            size: 20
                                        })
                                    ],
                                    spacing: {
                                        after: 100,
                                        before: 100
                                    }
                                })
                            ],
                            columnSpan: 3
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: data.procesadores_multi_ingredientes_7_producto_2_volumen_ingrediente_resultado,
                                            font: 'Arial',
                                            size: 20
                                        })
                                    ],
                                    spacing: {
                                        after: 100,
                                        before: 100
                                    }
                                })
                            ],
                            columnSpan: 2
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: data.procesadores_multi_ingredientes_7_producto_2_volumen_ingrediente_documentos,
                                            font: 'Arial',
                                            size: 20
                                        })
                                    ],
                                    spacing: {
                                        after: 100,
                                        before: 100
                                    }
                                })
                            ],
                            columnSpan: 2
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: data.procesadores_multi_ingredientes_7_producto_2_volumen_ingrediente_comentarios,
                                            font: 'Arial',
                                            size: 20
                                        })
                                    ],
                                    spacing: {
                                        after: 100,
                                        before: 100
                                    }
                                })
                            ],
                            columnSpan: 2
                        })
                    ]
                }),
                
                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: '',
                                            font: 'Arial',
                                            size: 20
                                        })
                                    ],
                                    spacing: {
                                        after: 100,
                                        before: 100
                                    }
                                })
                            ],
                            columnSpan: 1
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: `Producto 1: ${data.procesadores_multi_ingredientes_7_producto_3_nombre}`,
                                            font: 'Arial',
                                            size: 20
                                        })
                                    ],
                                    spacing: {
                                        after: 100,
                                        before: 100
                                    }
                                })
                            ],
                            columnSpan: 3
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: data.procesadores_multi_ingredientes_7_producto_3_nombre_resultado,
                                            font: 'Arial',
                                            size: 20
                                        })
                                    ],
                                    spacing: {
                                        after: 100,
                                        before: 100
                                    }
                                })
                            ],
                            columnSpan: 2
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: data.procesadores_multi_ingredientes_7_producto_3_nombre_documentos,
                                            font: 'Arial',
                                            size: 20
                                        })
                                    ],
                                    spacing: {
                                        after: 100,
                                        before: 100
                                    }
                                })
                            ],
                            columnSpan: 2
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: data.procesadores_multi_ingredientes_7_producto_3_nombre_comentarios,
                                            font: 'Arial',
                                            size: 20
                                        })
                                    ],
                                    spacing: {
                                        after: 100,
                                        before: 100
                                    }
                                })
                            ],
                            columnSpan: 2
                        })
                    ]
                }),

                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: '',
                                            font: 'Arial',
                                            size: 20
                                        })
                                    ],
                                    spacing: {
                                        after: 100,
                                        before: 100
                                    }
                                })
                            ],
                            columnSpan: 1
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: `Volumen Producido:`,
                                            font: 'Arial',
                                            size: 20
                                        })
                                    ],
                                    spacing: {
                                        after: 100,
                                        before: 100
                                    }
                                })
                            ],
                            columnSpan: 1
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: data.procesadores_multi_ingredientes_7_producto_3_volumen,
                                            font: 'Arial',
                                            size: 20
                                        })
                                    ],
                                    spacing: {
                                        after: 100,
                                        before: 100
                                    }
                                })
                            ],
                            columnSpan: 2
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: data.procesadores_multi_ingredientes_7_producto_3_volumen_resultado,
                                            font: 'Arial',
                                            size: 20
                                        })
                                    ],
                                    spacing: {
                                        after: 100,
                                        before: 100
                                    }
                                })
                            ],
                            columnSpan: 2
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: data.procesadores_multi_ingredientes_7_producto_3_volumen_documentos,
                                            font: 'Arial',
                                            size: 20
                                        })
                                    ],
                                    spacing: {
                                        after: 100,
                                        before: 100
                                    }
                                })
                            ],
                            columnSpan: 2
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: data.procesadores_multi_ingredientes_7_producto_3_volumen_comentarios,
                                            font: 'Arial',
                                            size: 20
                                        })
                                    ],
                                    spacing: {
                                        after: 100,
                                        before: 100
                                    }
                                })
                            ],
                            columnSpan: 2
                        })
                    ]
                }),

                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: '',
                                            font: 'Arial',
                                            size: 20
                                        })
                                    ],
                                    spacing: {
                                        after: 100,
                                        before: 100
                                    }
                                })
                            ],
                            columnSpan: 1
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: `% Ingrediente:`,
                                            font: 'Arial',
                                            size: 20
                                        })
                                    ],
                                    spacing: {
                                        after: 100,
                                        before: 100
                                    }
                                })
                            ],
                            columnSpan: 1
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: data.procesadores_multi_ingredientes_7_producto_3_porcentaje,
                                            font: 'Arial',
                                            size: 20
                                        })
                                    ],
                                    spacing: {
                                        after: 100,
                                        before: 100
                                    }
                                })
                            ],
                            columnSpan: 2
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: data.procesadores_multi_ingredientes_7_producto_3_porcentaje_resultado,
                                            font: 'Arial',
                                            size: 20
                                        })
                                    ],
                                    spacing: {
                                        after: 100,
                                        before: 100
                                    }
                                })
                            ],
                            columnSpan: 2
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: data.procesadores_multi_ingredientes_7_producto_3_porcentaje_documentos,
                                            font: 'Arial',
                                            size: 20
                                        })
                                    ],
                                    spacing: {
                                        after: 100,
                                        before: 100
                                    }
                                })
                            ],
                            columnSpan: 2
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: data.procesadores_multi_ingredientes_7_producto_3_porcentaje_comentarios,
                                            font: 'Arial',
                                            size: 20
                                        })
                                    ],
                                    spacing: {
                                        after: 100,
                                        before: 100
                                    }
                                })
                            ],
                            columnSpan: 2
                        })
                    ]
                }),

                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: '',
                                            font: 'Arial',
                                            size: 20
                                        })
                                    ],
                                    spacing: {
                                        after: 100,
                                        before: 100
                                    }
                                })
                            ],
                            columnSpan: 1
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: `Volumen ingrediente: ${data.procesadores_multi_ingredientes_7_producto_3_volumen_ingrediente}`,
                                            font: 'Arial',
                                            size: 20
                                        })
                                    ],
                                    spacing: {
                                        after: 100,
                                        before: 100
                                    }
                                })
                            ],
                            columnSpan: 3
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: data.procesadores_multi_ingredientes_7_producto_3_volumen_ingrediente_resultado,
                                            font: 'Arial',
                                            size: 20
                                        })
                                    ],
                                    spacing: {
                                        after: 100,
                                        before: 100
                                    }
                                })
                            ],
                            columnSpan: 2
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: data.procesadores_multi_ingredientes_7_producto_3_volumen_ingrediente_documentos,
                                            font: 'Arial',
                                            size: 20
                                        })
                                    ],
                                    spacing: {
                                        after: 100,
                                        before: 100
                                    }
                                })
                            ],
                            columnSpan: 2
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: data.procesadores_multi_ingredientes_7_producto_3_volumen_ingrediente_comentarios,
                                            font: 'Arial',
                                            size: 20
                                        })
                                    ],
                                    spacing: {
                                        after: 100,
                                        before: 100
                                    }
                                })
                            ],
                            columnSpan: 2
                        })
                    ]
                }),
                
                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: '8',
                                            font: 'Arial',
                                            size: 20
                                        })
                                    ],
                                    spacing: {
                                        after: 100,
                                        before: 100
                                    }
                                })
                            ],
                            columnSpan: 1
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: `Mermas:`,
                                            font: 'Arial',
                                            size: 20
                                        })
                                    ],
                                    spacing: {
                                        after: 100,
                                        before: 100
                                    }
                                })
                            ],
                            columnSpan: 3
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: data.procesadores_multi_ingredientes_8_resultado,
                                            font: 'Arial',
                                            size: 20
                                        })
                                    ],
                                    spacing: {
                                        after: 100,
                                        before: 100
                                    }
                                })
                            ],
                            columnSpan: 2
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: data.procesadores_multi_ingredientes_8_documentos,
                                            font: 'Arial',
                                            size: 20
                                        })
                                    ],
                                    spacing: {
                                        after: 100,
                                        before: 100
                                    }
                                })
                            ],
                            columnSpan: 2
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: data.procesadores_multi_ingredientes_8_comentarios,
                                            font: 'Arial',
                                            size: 20
                                        })
                                    ],
                                    spacing: {
                                        after: 100,
                                        before: 100
                                    }
                                })
                            ],
                            columnSpan: 2
                        })
                    ]
                }),

                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: '9',
                                            font: 'Arial',
                                            size: 20
                                        })
                                    ],
                                    spacing: {
                                        after: 100,
                                        before: 100
                                    }
                                })
                            ],
                            columnSpan: 1
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: `Inventario final teórico: (4+6-7-8)`,
                                            font: 'Arial',
                                            size: 20
                                        })
                                    ],
                                    spacing: {
                                        after: 100,
                                        before: 100
                                    }
                                })
                            ],
                            columnSpan: 3
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: data.procesadores_multi_ingredientes_9_resultado,
                                            font: 'Arial',
                                            size: 20
                                        })
                                    ],
                                    spacing: {
                                        after: 100,
                                        before: 100
                                    }
                                })
                            ],
                            columnSpan: 2
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: data.procesadores_multi_ingredientes_9_documentos,
                                            font: 'Arial',
                                            size: 20
                                        })
                                    ],
                                    spacing: {
                                        after: 100,
                                        before: 100
                                    }
                                })
                            ],
                            columnSpan: 2
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: data.procesadores_multi_ingredientes_9_comentarios,
                                            font: 'Arial',
                                            size: 20
                                        })
                                    ],
                                    spacing: {
                                        after: 100,
                                        before: 100
                                    }
                                })
                            ],
                            columnSpan: 2
                        })
                    ]
                }),

                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: '10',
                                            font: 'Arial',
                                            size: 20
                                        })
                                    ],
                                    spacing: {
                                        after: 100,
                                        before: 100
                                    }
                                })
                            ],
                            columnSpan: 1
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: `Diferencia entre inventario final teórico y físico: (9-5):`,
                                            font: 'Arial',
                                            size: 20
                                        })
                                    ],
                                    spacing: {
                                        after: 100,
                                        before: 100
                                    }
                                })
                            ],
                            columnSpan: 3
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: data.procesadores_multi_ingredientes_10_resultado,
                                            font: 'Arial',
                                            size: 20
                                        })
                                    ],
                                    spacing: {
                                        after: 100,
                                        before: 100
                                    }
                                })
                            ],
                            columnSpan: 2
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: data.procesadores_multi_ingredientes_10_documentos,
                                            font: 'Arial',
                                            size: 20
                                        })
                                    ],
                                    spacing: {
                                        after: 100,
                                        before: 100
                                    }
                                })
                            ],
                            columnSpan: 2
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: data.procesadores_multi_ingredientes_10_comentarios,
                                            font: 'Arial',
                                            size: 20
                                        })
                                    ],
                                    spacing: {
                                        after: 100,
                                        before: 100
                                    }
                                })
                            ],
                            columnSpan: 2
                        })
                    ]
                }),

                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: '11',
                                            font: 'Arial',
                                            size: 20
                                        })
                                    ],
                                    spacing: {
                                        after: 100,
                                        before: 100
                                    }
                                })
                            ],
                            columnSpan: 1
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: `Diferencia de inventario final en proporción con volumen total comercializado: (10*100/7)`,
                                            font: 'Arial',
                                            size: 20
                                        })
                                    ],
                                    spacing: {
                                        after: 100,
                                        before: 100
                                    }
                                })
                            ],
                            columnSpan: 3
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: data.procesadores_multi_ingredientes_11_resultado,
                                            font: 'Arial',
                                            size: 20
                                        })
                                    ],
                                    spacing: {
                                        after: 100,
                                        before: 100
                                    }
                                })
                            ],
                            columnSpan: 2
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: data.procesadores_multi_ingredientes_11_documentos,
                                            font: 'Arial',
                                            size: 20
                                        })
                                    ],
                                    spacing: {
                                        after: 100,
                                        before: 100
                                    }
                                })
                            ],
                            columnSpan: 2
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: data.procesadores_multi_ingredientes_11_comentarios,
                                            font: 'Arial',
                                            size: 20
                                        })
                                    ],
                                    spacing: {
                                        after: 100,
                                        before: 100
                                    }
                                })
                            ],
                            columnSpan: 2
                        })
                    ]
                }),

                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: '12',
                                            font: 'Arial',
                                            size: 20
                                        })
                                    ],
                                    spacing: {
                                        after: 100,
                                        before: 100
                                    }
                                })
                            ],
                            columnSpan: 1
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: `Cantidad de producto orgánico vendido como producto convencional:`,
                                            font: 'Arial',
                                            size: 20
                                        })
                                    ],
                                    spacing: {
                                        after: 100,
                                        before: 100
                                    }
                                })
                            ],
                            columnSpan: 3
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: data.procesadores_multi_ingredientes_12_resultado,
                                            font: 'Arial',
                                            size: 20
                                        })
                                    ],
                                    spacing: {
                                        after: 100,
                                        before: 100
                                    }
                                })
                            ],
                            columnSpan: 2
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: data.procesadores_multi_ingredientes_12_documentos,
                                            font: 'Arial',
                                            size: 20
                                        })
                                    ],
                                    spacing: {
                                        after: 100,
                                        before: 100
                                    }
                                })
                            ],
                            columnSpan: 2
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: data.procesadores_multi_ingredientes_12_comentarios,
                                            font: 'Arial',
                                            size: 20
                                        })
                                    ],
                                    spacing: {
                                        after: 100,
                                        before: 100
                                    }
                                })
                            ],
                            columnSpan: 2
                        })
                    ]
                }),
                
                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: '13',
                                            font: 'Arial',
                                            size: 20
                                        })
                                    ],
                                    spacing: {
                                        after: 100,
                                        before: 100
                                    }
                                })
                            ],
                            columnSpan: 1
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: '¿El ejercicio de balance de masas es positivo?',
                                            font: 'Arial',
                                            size: 20
                                        })
                                    ],
                                    spacing: {
                                        after: 100,
                                        before: 100
                                    }
                                })
                            ],
                            columnSpan: 6
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: `${data.procesadores_multi_ingredientes_13_conformidad === 'C' ? '■' : '❏'} C ${data.procesadores_multi_ingredientes_13_conformidad === 'NC' ? '■' : '❏'} NC ${data.procesadores_multi_ingredientes_13_conformidad === 'NA' ? '■' : '❏'} NA`,
                                            font: 'Arial',
                                            size: 20
                                        })
                                    ],
                                    spacing: {
                                        after: 100,
                                        before: 100
                                    }
                                })
                            ],
                            columnSpan: 3
                        })
                    ]
                }),
                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: 'Explique: ' + data.procesadores_multi_ingredientes_13_pregunta,
                                            font: 'Arial',
                                            size: 20
                                        })
                                    ],
                                    spacing: {
                                        after: 100,
                                        before: 100
                                    }
                                })
                            ],
                            columnSpan: 10
                        }),
                    ]
                }),

                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: '14',
                                            font: 'Arial',
                                            size: 20
                                        })
                                    ],
                                    spacing: {
                                        after: 100,
                                        before: 100
                                    }
                                })
                            ],
                            columnSpan: 1
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: '¿Los rendimientos del proceso son razonables?',
                                            font: 'Arial',
                                            size: 20
                                        })
                                    ],
                                    spacing: {
                                        after: 100,
                                        before: 100
                                    }
                                })
                            ],
                            columnSpan: 6
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: `${data.procesadores_multi_ingredientes_14_conformidad === 'C' ? '■' : '❏'} C ${data.procesadores_multi_ingredientes_14_conformidad === 'NC' ? '■' : '❏'} NC ${data.procesadores_multi_ingredientes_14_conformidad === 'NA' ? '■' : '❏'} NA`,
                                            font: 'Arial',
                                            size: 20
                                        })
                                    ],
                                    spacing: {
                                        after: 100,
                                        before: 100
                                    }
                                })
                            ],
                            columnSpan: 3
                        })
                    ]
                }),
                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: 'Explique: ' + data.procesadores_multi_ingredientes_14_pregunta,
                                            font: 'Arial',
                                            size: 20
                                        })
                                    ],
                                    spacing: {
                                        after: 100,
                                        before: 100
                                    }
                                })
                            ],
                            columnSpan: 10
                        }),
                    ]
                }),

                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: '15',
                                            font: 'Arial',
                                            size: 20
                                        })
                                    ],
                                    spacing: {
                                        after: 100,
                                        before: 100
                                    }
                                })
                            ],
                            columnSpan: 1
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: '¿Cuál es el volumen de producción/ventas estimado para la siguiente temporada?',
                                            font: 'Arial',
                                            size: 20
                                        })
                                    ],
                                    spacing: {
                                        after: 100,
                                        before: 100
                                    }
                                })
                            ],
                            columnSpan: 6
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: `Resp: ${data.procesadores_multi_ingredientes_15_pregunta}`,
                                            font: 'Arial',
                                            size: 20
                                        })
                                    ],
                                    spacing: {
                                        after: 100,
                                        before: 100
                                    }
                                })
                            ],
                            columnSpan: 3
                        })
                    ]
                }),

                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: 'Comentarios Generales: ' + data.procesadores_multi_ingredientes_comentarios_generales,
                                            font: 'Arial',
                                            size: 20
                                        })
                                    ],
                                    spacing: {
                                        after: 100,
                                        before: 100
                                    }
                                })
                            ],
                            columnSpan: 10
                        }),
                    ]
                }),
            ],
            margins: {
                left: 70,
                right: 70
            },
            width: {
                size: 100,
                type: WidthType.PERCENTAGE
            },
            columnWidths: [500,1359,1359,1359,1359,1359,1359,1359,1200,1200]
        })

        return table
    }

    getValue(submission, name) {
        var value = null
        for (const i in submission) {
            const element = submission[i]
            if (element.name === name) {
                value = element.value
            }
        }
        if (value) return value
        else return ''
    }

    getValueList(submission, name) {
        var value = null
        for (const i in submission) {
            const element = submission[i]
            if (element.name === name) {
                value = element.value
            }
        }
        if (value) return value
        else return []
    }

    getFileList(value) {
        var anexos = ''
        for(var i in value) {
            const tmp = value[i].split("/")
            anexos += tmp[tmp.length-1] + ", "
        }

        anexos = anexos.substr(0, anexos.length-2)

        return anexos
    }
    // END MTO_FINS_03_1_12
}
