<div class="card border shadow-sm mb-2" [class]="attributes.class ? attributes.class : 's'">
    <div class="card-header" *ngIf="attributes.header">
        {{attributes.header}}
    </div>
    <div class="card-body">
        <h5 class="card-title">{{attributes.title}}</h5>
        <div>
            <controls-fabric [content]="data.content" [formGroup]="form"></controls-fabric>
        </div>
    </div>
    <div class="card-footer text-muted" *ngIf="attributes.footer">
        {{attributes.footer}}
    </div>
</div>