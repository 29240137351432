import { ControlCommunicationService } from './../../../services/control-communication.service';
import { FormBuilderComponent } from './../../../form-builder.component';
import { ContainerElement, UnknownParams, ControlElement } from './../../../interfaces/form.interface';
import { FormGroup, FormArray, FormControl } from '@angular/forms';
import { Component, OnInit, OnDestroy, Input, Type } from '@angular/core'
import { Observable, of } from 'rxjs';
import { catchError, debounceTime, distinctUntilChanged, tap, switchMap, first } from 'rxjs/operators';
import { NgbModal, NgbModalConfig } from '@ng-bootstrap/ng-bootstrap';

import { SubstancesService } from '../../../../core/services/substances.services'
import { AuthenticationService, UsersService, CustomersService } from '@app/core/services';
import { User } from '@app/core/models';
import { ModalPreview } from '@app/core/components/modal-preview';
import { copyFileSync } from 'fs';
import { Console } from 'console';
import { Subscription } from 'rxjs';

const MODALS: { [name: string]: Type<any> } = {
    preview: ModalPreview
};

@Component({
    selector: 'card-right-dynamic',
    templateUrl: './card-right.component.html'
})
export class cardRightDynamicComponent implements OnInit, OnDestroy {
    model: any;
    searching = false;
    searchFailed = false;

    user: User;
    type = ''

    attributes: UnknownParams = {}

    @Input() form: FormGroup
    formArray: FormArray

    keys: String[][]

    @Input() data: ContainerElement

    currentIndex = 0
    cardIndex = 0

    newValue = true


    commentsToOperator = ''
    timer: any

    private subscriptions: Subscription
    
    constructor(
        private authenticationService: AuthenticationService,
        private substancesService: SubstancesService,
        private parentControl: FormBuilderComponent, 
        private communication: ControlCommunicationService,
        private _modalService: NgbModal,
        private usersService: UsersService,
        private customersService: CustomersService,
    ) { 
        this.user = this.authenticationService.userValue;
        switch (this.user.type) {
            case "ROOT":
                this.type = 'Administrador'
                break;
            case "OPERADOR":
                this.type = 'Operador'
                break;
            case "CAPTURISTA":
                this.type = 'Capturista'
                break;
            case "RESPONSABLE":
                this.type = 'Responsable'
                break;
            case "ADMINISTRADOR":
                this.type = 'Administrador'
                break;
            case "DIRECTOR_CERTIFICACION":
                this.type = 'Supervisor'
                break;
            case "REVISOR":
                this.type = 'Revisor'
                break;
            case "INSPECTOR":
                this.type = 'Inspector'
                break;
            case "DICTAMINADOR":
                this.type = 'Dictaminador'
                break;
            case "OBSERVADOR":
                this.type = 'Observador'
                break;
            default:
                break;
        }
    }

    ngOnInit() {
        // Create objects containing attributes and validations
        for (var i in this.data.attributes) {
            const attribute = this.data.attributes[i]
            this.attributes[attribute.name] = attribute.value
        }
        if (!this.form) { this.parentControl.formGroup }
        this.form.addControl(this.attributes.group_name, new FormArray([
            new FormGroup({})
        ]))
        this.formArray = this.form.get(this.attributes.group_name) as FormArray
        let keys = []
        for (var i in this.formArray.controls) {
            const formGroup: FormGroup = this.formArray.controls[i] as FormGroup
            keys.push(Object.keys(formGroup.controls))
            //this.currentIndex = parseInt(i)
        }
        this.keys = keys
        this.subscriptions = this.communication.changeEmmited$.subscribe(msg => {
            //console.log("2", msg)
            if (msg.identifier == "set-values") {
                //console.log("1", msg)
                this.formArray = this.form.get(this.attributes.group_name) as FormArray
            }
        })
    }

    ngOnDestroy() {
        this.subscriptions.unsubscribe()
    }

    addFormGroup(event) {
        event.preventDefault()
        this.currentIndex = this.formArray.length - 1
        if (this.currentIndex !== this.formArray.length - 1) {
            this.currentIndex = this.formArray.length - 1
        } else {
            this.formArray.push(new FormGroup({}))
            this.currentIndex = this.formArray.length - 1
            setTimeout(() => {
                document.getElementById('dynamic-container').scroll({
                    behavior: 'smooth',
                    top: document.getElementById('dynamic-container').scrollHeight
                })
            }, 100);
            let keys = []
            for (var i in this.formArray.controls) {
                const formGroup: FormGroup = this.formArray.controls[i] as FormGroup
                keys.push(Object.keys(formGroup.controls))
            }
            this.keys = keys
        }
    }

    changeIndexCard(index) {
        this.cardIndex = index


        //console.log(this.formArray )
    }

    changeIndex(index) {
        this.currentIndex = index
    }

    deleteGroup(index) {
        this.formArray.removeAt(index)
        let keys = []
        for (var i in this.formArray.controls) {
            const formGroup: FormGroup = this.formArray.controls[i] as FormGroup
            keys.push(Object.keys(formGroup.controls))
        }
        this.keys = keys
        this.currentIndex = this.formArray.length - 1
    }
}