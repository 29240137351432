import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AlertService, CustomersService } from '@app/core/services'
import { first } from 'rxjs/operators';

import { Observable, of } from 'rxjs';
import { catchError, debounceTime, distinctUntilChanged, tap, switchMap } from 'rxjs/operators';

@Component({
    selector: 'modal-selectCustomer',
    templateUrl: 'modal-selectCustomer.component.html',
    styleUrls: ['../../../app.component.scss']
})
export class ModalSelectCustomer implements OnInit {
    model: any;
    searching = false;
    searchFailed = false;


    form: FormGroup    

    @Input() file: any 

    loading = false
    submitted = false
    customers = []
    idOperator

    constructor(
        private formBuilder: FormBuilder,
        public modal: NgbActiveModal,
        private alertService: AlertService,
        private customerSevice: CustomersService
    ) { }

    ngOnInit() {
        this.customerSevice.getCustomersActive(1, '')
            .pipe(first())
            .subscribe( dataActive => {
                this.customerSevice.getCustomersPending()
                    .pipe(first())
                    .subscribe( dataPending => {
                        this.customerSevice.getCustomersInactive(1, '')
                            .pipe(first())
                            .subscribe( dataInactive => {
                                this.customers = this.customers.concat(dataActive.data)
                                this.customers = this.customers.concat(dataPending.data)
                                this.customers = this.customers.concat(dataInactive.data)
                            })
                    })
            })

        this.form = this.formBuilder.group({
            customer: [null, Validators.required],
        });
    }

    // Convenience getter for easy access to form fields
    get f() { return this.form.controls; }

    onSubmit() {
        this.submitted = true;

        // Reset alerts on submit
        this.alertService.clear();

        // Stop here if form is invalid
        if (this.form.invalid) {
            return;
        }

        console.log(this.idOperator)

        this.alertService.success('Se ha asignado cotización a operador correctamente', { autoClose: true })
        this.modal.close(true)
        this.modal.dismiss()
        

        /*if (this.form.value.newInspector) {
            this.loading = true;
            this.inspectionsService.changeInspector(this.form.value.newInspector, this.id)
                .pipe(first())
                .subscribe(
                    response => {
                        console.log(response)
                        this.alertService.clear()
                        if (response.success) {
                            this.alertService.success('Se ha asignado el inspector correctamente', { autoClose: true })
                            this.modal.close(true)
                            this.modal.dismiss()
                        } else {
                        }
                        this.loading = false;
                    },
                    error => {
                        this.alertService.error(error);
                        this.loading = false;
                    });
        }*/
    }

    search = (text$: Observable<string>) =>
        text$.pipe(
            debounceTime(300),
            distinctUntilChanged(),
            tap(() => this.searching = true),
            switchMap(term =>
            this.customerSevice.searchAll(term).pipe(
                tap(() => this.searchFailed = false),
                catchError(() => {
                this.searchFailed = true;
                return of([]);
                }))
            ),
            tap(() => this.searching = false)
        )

    formatter = (customer: string) => customer["Id_Comercial"] + " - " + customer["Name"]

    loadSubstance(event) {
        //console.log(event)

        this.idOperator = event.item.Id_Operator
    }
}