<div [formGroup]="form">
    <div id="dynamic-container" class="row overflow-auto" style="max-height: 35vh;">
        <div class="col-lg-4" style="margin-bottom: 10px; margin-top: 10px;"
            *ngFor="let formGroup of formArray.controls; let i = index;">
            <div class="card" [ngClass]="{'border-success': i===currentIndex, 'shadow-sm': i===currentIndex}"
                [id]="i">
                <div class="card-body">
                    <h5 class="card-title">
                        {{formGroup.get(attributes.card_title_prop) && formGroup.get(attributes.card_title_prop).value ? formGroup.get(attributes.card_title_prop).value.substring(0, 24) : 'Nuevo'}}
                    </h5>
                    <a class="card-link" style="cursor: pointer;" (click)="changeIndex(i)"
                        [ngClass]="{'text-success': i===currentIndex, 'text-info': i!==currentIndex}">{{currentIndex === i ? 'Seleccionado': 'Editar'}}</a>
                </div>
            </div>
        </div>
    </div>
    <div class="card">
        <div class="card-body">
            <div [formArrayName]="attributes.group_name">
                <div class="row">
                    <div class="col-12">
                        <h3>{{formArray.controls[currentIndex] && formArray.controls[currentIndex].get(attributes.card_title_prop) ? formArray.controls[currentIndex].get(attributes.card_title_prop).value : 'Nuevo'}}
                        </h3>
                    </div>
                </div>
                <containers-fabric *ngFor="let formGroup of formArray.controls; let i = index;"
                    [content]="data.content" [formGroup]="formGroup" [ngClass]="{'d-none': i !== currentIndex}">
                </containers-fabric>
            </div>
        </div>
    </div>
    <button *ngIf="!attributes.fixed" class="btn btn-info" (click)="addFormGroup($event)">Añadir</button>
</div>