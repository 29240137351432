import { AlignmentType, Document, Paragraph, PageOrientation, TextRun, Media, Header, Footer, Table, TableRow, TableCell, WidthType, PageNumber, BorderStyle } from "docx";
import { logo } from "../logo";
import { userWithoutSign } from "../userWithoutSign"
import { DatePipe } from '@angular/common';

export class DocumentCreatorMTO_FSOL_06_v1 {

    public getBase64FromUrl = async (url) => {
        const data = await fetch(url, {
            headers: {
                'Cache-Control': 'no-cache, no-store, must-revalidate',
                'Pragma': 'no-cache',
                'Expires': 'Sat, 01 Jan 2000 00:00:00 GMT',
                'If-Modified-Since': '0'
            }
        });
        const blob = await data.blob();
        return new Promise((resolve) => {
            const reader = new FileReader();
            reader.readAsDataURL(blob); 
            reader.onloadend = function() {
                const base64data = reader.result;   
                resolve(base64data);
            }
        });
    }

    // INIT MTO_FSOL_06
    public async create_MTO_FSOL_06(data, signature = ''): Promise<Document> {
        const document = new Document();
        const image = Media.addImage(document, logo, 50, 50);
        var datePipe = new DatePipe('es-MX');

        let signatureImage = null
        if (signature) {
            signatureImage = await this.getBase64FromUrl(signature)
        } else {
            signatureImage = userWithoutSign
        }

        let listaUnidades = this.getValueList(data.submission, "lista_limites_riesgo")
        listaUnidades.forEach(unidad => {
            
            document.addSection({
                headers: {
                    default: new Header({
                        children: [
                            new Paragraph({ alignment: AlignmentType.CENTER, children: [image], }),
                            new Paragraph({
                                alignment: AlignmentType.CENTER, children: [
                                    new TextRun({
                                        children: [`MTO-FSOL-06| R 00 | 01-Julio-2017 | Pág. `, PageNumber.CURRENT],
                                        font: 'Book Antiqua',
                                        size: 20,
                                        bold: true
                                    }),
                                    new TextRun({
                                        font: 'Book Antiqua',
                                        size: 20,
                                        bold: true,
                                        children: [' de ', PageNumber.TOTAL_PAGES]
                                    }),
                                ], spacing: { before: 200, after: 100 }
                            })
                        ]
                    })
                },
                footers: {
                    default: new Footer({
                        children: [
                            new Paragraph({ alignment: AlignmentType.CENTER }),
                            this.createFooter()
                        ]
                    }),
                },
                children: [
                    new Paragraph({
                        alignment: AlignmentType.CENTER,
                        children: [
                            new TextRun({
                                text: 'LIMITES CON RIESGO DE CONTAMINACIÓN',
                                font: 'Calibri (Cuerpo)',
                                size: 22,
                                bold: true
                            })
                        ],
                        spacing: { after: 400 }
                    }),
                    this.createTableUDP(unidad, data),
                    new Paragraph({
                        spacing: {
                            after: 400
                        }
                    }),
                    new Paragraph({
                        alignment: AlignmentType.CENTER,
                        children: [
                            signatureImage ? Media.addImage(document, signatureImage.toString(), 200, 100) : new TextRun({
                                text: ''
                            })
                        ]
                    }),
                    new Paragraph({
                        alignment: AlignmentType.LEFT,
                        children: [
                            new TextRun({
                                text: `Fecha: ${datePipe.transform(new Date(data.record.Applications[0].Signature_Date), 'd-MMMM-y')}                  Nombre y firma del operador: ${data.customer.Legal_Authorities[0].Name} ${data.customer.Legal_Authorities[0].Middle_Name} ${data.customer.Legal_Authorities[0].Last_Name ? data.customer.Legal_Authorities[0].Last_Name : ''}`,
                                font: 'Arial',
                                size: 22,
                                italics: true
                            })
                        ],
                        spacing: {
                            after: 200
                        }
                    }),
                ],
                margins: {
                    top: 350,
                    bottom: 500,
                    left: 1000,
                    right: 1000
                },
                size: {
                    orientation: PageOrientation.LANDSCAPE
                }
            });

        });

        return document;
    }

    public createFooter(): Table {
        const table = new Table({
            rows: [
                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: 'Elaboró:',
                                            font: 'Calibri (Cuerpo)',
                                            size: 22
                                        })
                                    ]
                                })
                            ],
                            width: {
                                size: 33,
                                type: WidthType.PERCENTAGE
                            }
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: 'Revisó:',
                                            font: 'Calibri (Cuerpo)',
                                            size: 22
                                        })
                                    ]
                                })
                            ],
                            width: {
                                size: 33,
                                type: WidthType.PERCENTAGE
                            }
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: 'Autorizó:',
                                            font: 'Calibri (Cuerpo)',
                                            size: 22
                                        })
                                    ]
                                }),
                            ],
                            width: {
                                size: 33,
                                type: WidthType.PERCENTAGE
                            }
                        })
                    ]
                }),
                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: 'Director de certificación',
                                            font: 'Calibri (Cuerpo)',
                                            size: 16,
                                            bold: true
                                        })
                                    ]
                                }),

                            ],
                            width: {
                                size: 33,
                                type: WidthType.PERCENTAGE
                            },
                            shading: {
                                fill: '#C0C0C0'
                            }
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: 'Responsable de calidad',
                                            font: 'Calibri (Cuerpo)',
                                            size: 16,
                                            bold: true
                                        })
                                    ]
                                }),

                            ],
                            width: {
                                size: 33,
                                type: WidthType.PERCENTAGE
                            },
                            shading: {
                                fill: '#C0C0C0'
                            }
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: 'Director general',
                                            font: 'Calibri (Cuerpo)',
                                            size: 16,
                                            bold: true
                                        })
                                    ]
                                }),
                            ],
                            width: {
                                size: 33,
                                type: WidthType.PERCENTAGE
                            },
                            shading: {
                                fill: '#C0C0C0'
                            }
                        })
                    ]
                })
            ],
            margins: {
                left: 70,
                right: 70
            },
            columnWidths: [3213, 3213, 3212],
            alignment: AlignmentType.CENTER
        })
        return table
    }

    public createTableUDP(unidad, data) {
        const table = new Table({
            rows: [
                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: `Nombre del operador: ${data.customer.Company_Name}`,
                                            font: 'Arial',
                                            size: 22
                                        })
                                    ],
                                    spacing: { before: 100, after: 200 }
                                })
                            ],
                            columnSpan: 2
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: `Nombre de la unidad de producción: ${unidad.nombre_unidad_produccion}`,
                                            font: 'Arial',
                                            size: 22
                                        })
                                    ],
                                    spacing: { before: 100, after: 200 }
                                })
                            ],
                            columnSpan: 2
                        })
                    ]
                }),
                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    spacing: { before: 50, after: 50 }
                                })
                            ],
                            borders: {
                                left: {
                                    style: BorderStyle.NONE,
                                    color: "white",
                                    size: 0
                                },
                                right: {
                                    style: BorderStyle.NONE,
                                    color: "white",
                                    size: 0
                                },
                            }
                        }),
                    ]
                }),
                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: `Colindancia`,
                                            font: 'Arial',
                                            size: 22,
                                            bold: true
                                        })
                                    ],
                                    spacing: { before: 100, after: 200 }
                                })
                            ],
                            shading: {
                                fill: '#C0C0C0'
                            },
                            width: {
                                size: 33,
                                type: WidthType.PERCENTAGE,
                            },
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: `Uso del suelo`,
                                            font: 'Arial',
                                            size: 22,
                                            bold: true
                                        })
                                    ],
                                    spacing: { before: 100, after: 200 }
                                })
                            ],
                            shading: {
                                fill: '#C0C0C0'
                            },
                            width: {
                                size: 33,
                                type: WidthType.PERCENTAGE,
                            },
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: `Riesgo detectado`,
                                            font: 'Arial',
                                            size: 22,
                                            bold: true
                                        })
                                    ],
                                    spacing: { before: 100, after: 200 }
                                })
                            ],
                            shading: {
                                fill: '#C0C0C0'
                            },
                            width: {
                                size: 33,
                                type: WidthType.PERCENTAGE,
                            },
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: `Medida de control`,
                                            font: 'Arial',
                                            size: 22,
                                            bold: true
                                        })
                                    ],
                                    spacing: { before: 100, after: 200 }
                                })
                            ],
                            shading: {
                                fill: '#C0C0C0'
                            },
                            width: {
                                size: 33,
                                type: WidthType.PERCENTAGE,
                            },
                        })
                    ]
                }),
                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: `Norte`,
                                            font: 'Arial',
                                            size: 22,
                                            bold: true
                                        })
                                    ],
                                    spacing: { before: 100, after: 200 }
                                })
                            ],
                            width: {
                                size: 33,
                                type: WidthType.PERCENTAGE,
                            },
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: (unidad.norte_uso_suelo_cultivo_herbaceo ? '■' : '❏') + "Cultivo herbáceo convencional",
                                            font: 'Arial',
                                            size: 22
                                        })
                                    ]
                                }),
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: (unidad.norte_uso_suelo_carretera ? '■' : '❏') + "Carretera",
                                            font: 'Arial',
                                            size: 22
                                        })
                                    ]
                                }),
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: (unidad.norte_uso_suelo_plantacion_arborea ? '■' : '❏') + "Plantación arbórea convencional extensiva",
                                            font: 'Arial',
                                            size: 22
                                        })
                                    ]
                                }),
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: (unidad.norte_uso_suelo_fabrica ? '■' : '❏') + "Fábrica",
                                            font: 'Arial',
                                            size: 22
                                        })
                                    ]
                                }),
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: (unidad.norte_uso_suelo_otro ? '■' : '❏') + "Otro: " + unidad.norte_uso_suelo_otro_texto,
                                            font: 'Arial',
                                            size: 22
                                        })
                                    ]
                                })
                            ],
                            width: {
                                size: 33,
                                type: WidthType.PERCENTAGE,
                            },
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: (unidad.norte_riesgo_deriva_baja ? '■' : '❏') + "Deriva baja",
                                            font: 'Arial',
                                            size: 22
                                        })
                                    ]
                                }),
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: (unidad.norte_riesgo_deriva_alta ? '■' : '❏') + "Deriva alta",
                                            font: 'Arial',
                                            size: 22
                                        })
                                    ]
                                }),
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: (unidad.norte_riesgo_contaminacion_suelo_foliar ? '■' : '❏') + "Contaminación por suelo / foliar",
                                            font: 'Arial',
                                            size: 22
                                        })
                                    ]
                                }),
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: (unidad.norte_riesgo_contaminacion_mantos_acuiferos ? '■' : '❏') + "Contaminación por mantos acuíferos",
                                            font: 'Arial',
                                            size: 22
                                        })
                                    ]
                                }),
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: (unidad.norte_riesgo_contaminacion_emisiones_aire ? '■' : '❏') + "Contaminación por emisiones al aire",
                                            font: 'Arial',
                                            size: 22
                                        })
                                    ]
                                }),
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: (unidad.norte_riesgo_otro ? '■' : '❏') + "Otro: " + unidad.norte_riesgo_otro_texto,
                                            font: 'Arial',
                                            size: 22
                                        })
                                    ]
                                })
                            ],
                            width: {
                                size: 33,
                                type: WidthType.PERCENTAGE,
                            },
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: (unidad.norte_control_señales_letreros ? '■' : '❏') + "Zanjas de filtración",
                                            font: 'Arial',
                                            size: 22
                                        })
                                    ]
                                }),
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: (unidad.norte_control_franja_amortiguamiento ? '■' : '❏') + "Franja de amortiguamiento o Zona buffer",
                                            font: 'Arial',
                                            size: 22
                                        })
                                    ]
                                }),
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: (unidad.norte_control_convenio_vecino ? '■' : '❏') + "Convenio con vecino convencional",
                                            font: 'Arial',
                                            size: 22
                                        })
                                    ]
                                }),
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: (unidad.norte_control_otro ? '■' : '❏') + "Otro: " + unidad.norte_control_otro_texto,
                                            font: 'Arial',
                                            size: 22
                                        })
                                    ]
                                })
                            ],
                            width: {
                                size: 33,
                                type: WidthType.PERCENTAGE,
                            },
                        })
                    ]
                }),
                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: `Sur`,
                                            font: 'Arial',
                                            size: 22,
                                            bold: true
                                        })
                                    ],
                                    spacing: { before: 100, after: 200 }
                                })
                            ],
                            width: {
                                size: 33,
                                type: WidthType.PERCENTAGE,
                            },
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: (unidad.sur_uso_suelo_cultivo_herbaceo ? '■' : '❏') + "Cultivo herbáceo convencional",
                                            font: 'Arial',
                                            size: 22
                                        })
                                    ]
                                }),
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: (unidad.sur_uso_suelo_carretera ? '■' : '❏') + "Carretera",
                                            font: 'Arial',
                                            size: 22
                                        })
                                    ]
                                }),
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: (unidad.sur_uso_suelo_plantacion_arborea ? '■' : '❏') + "Plantación arbórea convencional extensiva",
                                            font: 'Arial',
                                            size: 22
                                        })
                                    ]
                                }),
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: (unidad.sur_uso_suelo_fabrica ? '■' : '❏') + "Fábrica",
                                            font: 'Arial',
                                            size: 22
                                        })
                                    ]
                                }),
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: (unidad.sur_uso_suelo_otro ? '■' : '❏') + "Otro: " + unidad.sur_uso_suelo_otro_texto,
                                            font: 'Arial',
                                            size: 22
                                        })
                                    ]
                                })
                            ],
                            width: {
                                size: 33,
                                type: WidthType.PERCENTAGE,
                            },
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: (unidad.sur_riesgo_deriva_baja ? '■' : '❏') + "Deriva baja",
                                            font: 'Arial',
                                            size: 22
                                        })
                                    ]
                                }),
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: (unidad.sur_riesgo_deriva_alta ? '■' : '❏') + "Deriva alta",
                                            font: 'Arial',
                                            size: 22
                                        })
                                    ]
                                }),
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: (unidad.sur_riesgo_contaminacion_suelo_foliar ? '■' : '❏') + "Contaminación por suelo / foliar",
                                            font: 'Arial',
                                            size: 22
                                        })
                                    ]
                                }),
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: (unidad.sur_riesgo_contaminacion_mantos_acuiferos ? '■' : '❏') + "Contaminación por mantos acuíferos",
                                            font: 'Arial',
                                            size: 22
                                        })
                                    ]
                                }),
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: (unidad.sur_riesgo_contaminacion_emisiones_aire ? '■' : '❏') + "Contaminación por emisiones al aire",
                                            font: 'Arial',
                                            size: 22
                                        })
                                    ]
                                }),
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: (unidad.sur_riesgo_otro ? '■' : '❏') + "Otro: " + unidad.sur_riesgo_otro_texto,
                                            font: 'Arial',
                                            size: 22
                                        })
                                    ]
                                })
                            ],
                            width: {
                                size: 33,
                                type: WidthType.PERCENTAGE,
                            },
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: (unidad.sur_control_señales_letreros ? '■' : '❏') + "Zanjas de filtración",
                                            font: 'Arial',
                                            size: 22
                                        })
                                    ]
                                }),
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: (unidad.sur_control_franja_amortiguamiento ? '■' : '❏') + "Franja de amortiguamiento o Zona buffer",
                                            font: 'Arial',
                                            size: 22
                                        })
                                    ]
                                }),
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: (unidad.sur_control_convenio_vecino ? '■' : '❏') + "Convenio con vecino convencional",
                                            font: 'Arial',
                                            size: 22
                                        })
                                    ]
                                }),
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: (unidad.sur_control_otro ? '■' : '❏') + "Otro: " + unidad.sur_control_otro_texto,
                                            font: 'Arial',
                                            size: 22
                                        })
                                    ]
                                })
                            ],
                            width: {
                                size: 33,
                                type: WidthType.PERCENTAGE,
                            },
                        })
                    ]
                }),
                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: `Este`,
                                            font: 'Arial',
                                            size: 22,
                                            bold: true
                                        })
                                    ],
                                    spacing: { before: 100, after: 200 }
                                })
                            ],
                            width: {
                                size: 33,
                                type: WidthType.PERCENTAGE,
                            },
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: (unidad.este_uso_suelo_cultivo_herbaceo ? '■' : '❏') + "Cultivo herbáceo convencional",
                                            font: 'Arial',
                                            size: 22
                                        })
                                    ]
                                }),
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: (unidad.este_uso_suelo_carretera ? '■' : '❏') + "Carretera",
                                            font: 'Arial',
                                            size: 22
                                        })
                                    ]
                                }),
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: (unidad.este_uso_suelo_plantacion_arborea ? '■' : '❏') + "Plantación arbórea convencional extensiva",
                                            font: 'Arial',
                                            size: 22
                                        })
                                    ]
                                }),
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: (unidad.este_uso_suelo_fabrica ? '■' : '❏') + "Fábrica",
                                            font: 'Arial',
                                            size: 22
                                        })
                                    ]
                                }),
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: (unidad.este_uso_suelo_otro ? '■' : '❏') + "Otro: " + unidad.este_uso_suelo_otro_texto,
                                            font: 'Arial',
                                            size: 22
                                        })
                                    ]
                                })
                            ],
                            width: {
                                size: 33,
                                type: WidthType.PERCENTAGE,
                            },
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: (unidad.este_riesgo_deriva_baja ? '■' : '❏') + "Deriva baja",
                                            font: 'Arial',
                                            size: 22
                                        })
                                    ]
                                }),
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: (unidad.este_riesgo_deriva_alta ? '■' : '❏') + "Deriva alta",
                                            font: 'Arial',
                                            size: 22
                                        })
                                    ]
                                }),
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: (unidad.este_riesgo_contaminacion_suelo_foliar ? '■' : '❏') + "Contaminación por suelo / foliar",
                                            font: 'Arial',
                                            size: 22
                                        })
                                    ]
                                }),
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: (unidad.este_riesgo_contaminacion_mantos_acuiferos ? '■' : '❏') + "Contaminación por mantos acuíferos",
                                            font: 'Arial',
                                            size: 22
                                        })
                                    ]
                                }),
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: (unidad.este_riesgo_contaminacion_emisiones_aire ? '■' : '❏') + "Contaminación por emisiones al aire",
                                            font: 'Arial',
                                            size: 22
                                        })
                                    ]
                                }),
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: (unidad.este_riesgo_otro ? '■' : '❏') + "Otro: " + unidad.este_riesgo_otro_texto,
                                            font: 'Arial',
                                            size: 22
                                        })
                                    ]
                                })
                            ],
                            width: {
                                size: 33,
                                type: WidthType.PERCENTAGE,
                            },
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: (unidad.este_control_señales_letreros ? '■' : '❏') + "Zanjas de filtración",
                                            font: 'Arial',
                                            size: 22
                                        })
                                    ]
                                }),
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: (unidad.este_control_franja_amortiguamiento ? '■' : '❏') + "Franja de amortiguamiento o Zona buffer",
                                            font: 'Arial',
                                            size: 22
                                        })
                                    ]
                                }),
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: (unidad.este_control_convenio_vecino ? '■' : '❏') + "Convenio con vecino convencional",
                                            font: 'Arial',
                                            size: 22
                                        })
                                    ]
                                }),
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: (unidad.este_control_otro ? '■' : '❏') + "Otro: " + unidad.este_control_otro_texto,
                                            font: 'Arial',
                                            size: 22
                                        })
                                    ]
                                })
                            ],
                            width: {
                                size: 33,
                                type: WidthType.PERCENTAGE,
                            },
                        })
                    ]
                }),
                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: `Oeste`,
                                            font: 'Arial',
                                            size: 22,
                                            bold: true
                                        })
                                    ],
                                    spacing: { before: 100, after: 200 }
                                })
                            ],
                            width: {
                                size: 33,
                                type: WidthType.PERCENTAGE,
                            },
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: (unidad.oeste_uso_suelo_cultivo_herbaceo ? '■' : '❏') + "Cultivo herbáceo convencional",
                                            font: 'Arial',
                                            size: 22
                                        })
                                    ]
                                }),
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: (unidad.oeste_uso_suelo_carretera ? '■' : '❏') + "Carretera",
                                            font: 'Arial',
                                            size: 22
                                        })
                                    ]
                                }),
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: (unidad.oeste_uso_suelo_plantacion_arborea ? '■' : '❏') + "Plantación arbórea convencional extensiva",
                                            font: 'Arial',
                                            size: 22
                                        })
                                    ]
                                }),
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: (unidad.oeste_uso_suelo_fabrica ? '■' : '❏') + "Fábrica",
                                            font: 'Arial',
                                            size: 22
                                        })
                                    ]
                                }),
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: (unidad.oeste_uso_suelo_otro ? '■' : '❏') + "Otro: " + unidad.oeste_uso_suelo_otro_texto,
                                            font: 'Arial',
                                            size: 22
                                        })
                                    ]
                                })
                            ],
                            width: {
                                size: 33,
                                type: WidthType.PERCENTAGE,
                            },
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: (unidad.oeste_riesgo_deriva_baja ? '■' : '❏') + "Deriva baja",
                                            font: 'Arial',
                                            size: 22
                                        })
                                    ]
                                }),
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: (unidad.oeste_riesgo_deriva_alta ? '■' : '❏') + "Deriva alta",
                                            font: 'Arial',
                                            size: 22
                                        })
                                    ]
                                }),
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: (unidad.oeste_riesgo_contaminacion_suelo_foliar ? '■' : '❏') + "Contaminación por suelo / foliar",
                                            font: 'Arial',
                                            size: 22
                                        })
                                    ]
                                }),
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: (unidad.oeste_riesgo_contaminacion_mantos_acuiferos ? '■' : '❏') + "Contaminación por mantos acuíferos",
                                            font: 'Arial',
                                            size: 22
                                        })
                                    ]
                                }),
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: (unidad.oeste_riesgo_contaminacion_emisiones_aire ? '■' : '❏') + "Contaminación por emisiones al aire",
                                            font: 'Arial',
                                            size: 22
                                        })
                                    ]
                                }),
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: (unidad.oeste_riesgo_otro ? '■' : '❏') + "Otro: " + unidad.oeste_riesgo_otro_texto,
                                            font: 'Arial',
                                            size: 22
                                        })
                                    ]
                                })
                            ],
                            width: {
                                size: 33,
                                type: WidthType.PERCENTAGE,
                            },
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: (unidad.oeste_control_señales_letreros ? '■' : '❏') + "Zanjas de filtración",
                                            font: 'Arial',
                                            size: 22
                                        })
                                    ]
                                }),
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: (unidad.oeste_control_franja_amortiguamiento ? '■' : '❏') + "Franja de amortiguamiento o Zona buffer",
                                            font: 'Arial',
                                            size: 22
                                        })
                                    ]
                                }),
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: (unidad.oeste_control_convenio_vecino ? '■' : '❏') + "Convenio con vecino convencional",
                                            font: 'Arial',
                                            size: 22
                                        })
                                    ]
                                }),
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: (unidad.oeste_control_otro ? '■' : '❏') + "Otro: " + unidad.oeste_control_otro_texto,
                                            font: 'Arial',
                                            size: 22
                                        })
                                    ]
                                })
                            ],
                            width: {
                                size: 33,
                                type: WidthType.PERCENTAGE,
                            },
                        })
                    ]
                }),
                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: `En caso de tener Franjas de Amortiguamiento o Zonas buffer, por favor describa como realiza la separación del producto y cuál es el destino del mismo: ` + unidad.riesgos_colindancias_franjas_amortiguamiento_explicacion,
                                            font: 'Arial',
                                            size: 22
                                        })
                                    ],
                                    spacing: { before: 100, after: 200 }
                                })
                            ],
                            width: {
                                size: 100,
                                type: WidthType.PERCENTAGE,
                            },
                            borders: {
                                left: {
                                    style: BorderStyle.NONE,
                                    color: "white",
                                    size: 0
                                },
                                right: {
                                    style: BorderStyle.NONE,
                                    color: "white",
                                    size: 0
                                },
                                top: {
                                    style: BorderStyle.NONE,
                                    color: "white",
                                    size: 0
                                },
                                bottom: {
                                    style: BorderStyle.OUTSET,
                                    color: "black",
                                    size: 1
                                }
                            }
                        })
                    ]
                })
            ],
            margins: {
                left: 70,
                right: 70
            },
            width: {
                size: 100,
                type: WidthType.PERCENTAGE,
            },
            columnWidths: [1298, 3650, 3650, 3650]
        })

        return table;
    }

    getValue(submission, name) {
        var value = null
        for (const i in submission) {
            const element = submission[i]
            if (element.name === name) {
                value = element.value
            }
        }
        if (value) return value
        else return ''
    }
    getValueList(submission, name) {
        var value = null
        for (const i in submission) {
            const element = submission[i]
            if (element.name === name) {
                value = element.value
            }
        }
        if (value) return value
        else return []
    }
    // END MTO_FSOL_06
}