<div class="form-group" [formGroup]="form" style="margin-bottom: 10px;">
    <label *ngIf="attributes.label">{{attributes.label}}</label>
    <ng-select 
        [items]="options"
        bindLabel="name"
        bindValue="value"
        [multiple]="true"
        [searchable]="false"
        [formControlName]="attributes.name"
        [ngStyle]="{'width': ['informe_submuestra_1_analisis', 'calculo_cobre_parcela'].includes(attributes.name) ? '' : '200px'}"
        style="font-size: 12px;"
        [readonly]="validations.disabled"
    >
    </ng-select>
</div>