<div *ngIf="content">
    <div *ngFor="let item of content">
        <div [ngSwitch]="item.type">
            <input-element *ngSwitchCase="'input'" [data]="item" [form]="formGroup"></input-element>
            <select-element *ngSwitchCase="'select'" [data]="item" [form]="formGroup"></select-element>
            <button-element *ngSwitchCase="'button'" [data]="item" [form]="formGroup"></button-element>
            <checkbox-group-element *ngSwitchCase="'checkbox'" [data]="item" [form]="formGroup"></checkbox-group-element>
            <radio-group-element *ngSwitchCase="'radio'" [data]="item" [form]="formGroup"></radio-group-element>
            <text-element *ngSwitchCase="'text'" [data]="item"></text-element>
            <textarea-element *ngSwitchCase="'textarea'" [data]="item" [form]="formGroup"></textarea-element>
            <file-upload-element *ngSwitchCase="'fileupload'" [data]="item" [form]="formGroup"></file-upload-element>
            <search-element *ngSwitchCase="'search'" [data]="item" [form]="formGroup"></search-element>
            <multiselect-element *ngSwitchCase="'multiselect'" [data]="item" [form]="formGroup"></multiselect-element>
            <timepicker-element *ngSwitchCase="'timepicker'" [data]="item" [form]="formGroup"></timepicker-element>
        </div>
        <containers-fabric *ngIf="item.class == 'container'" [content]="[item]" [formGroup]="formGroup"></containers-fabric>
    </div>
</div>