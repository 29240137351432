<div class="d-flex justify-content-center">
    <div id="animalStepper" class="md-stepper-horizontal green scrollmenu">
        <div 
            class="md-step" 
            style="min-width: auto; max-width: auto; overflow: hidden;" 
            [class]="step.statusColor === 'green' 
                        ? 'done' 
                        : step.statusColor === 'red' 
                            ? 'stopped' 
                            : step.statusColor === 'yellow' 
                                ? 'waiting' 
                                : ''
                    " 
            *ngFor="let step of forms; let i = index;" 
            [id]="'' + i"
        >
            <div class="md-step-circle green"><span>{{i+1}}</span></div>
            <div class="md-step-title">
                <span
                    [class]="step.statusColor ? 'tittle' : ''"
                >
                    {{step.stage}}
                </span>
                <br>
                <span 
                    style="color: grey;" 
                    *ngIf="step.date !== null"
                >
                    {{ step.date | date: "mediumDate" }}
                </span>
            </div>
            <div class="md-step-bar-left"></div>
            <div class="md-step-bar-right"></div>
        </div>
    </div>
</div>