import { NumericPipe } from './pipes/numeric.pipe';
import { FormStepper } from '@app/core/components/form-stepper';
import { FormStepperRecord } from '@app/core/components/form-stepper-record';
import { ModalPreview } from './components/modal-preview/modal-preview.component';
import { ReactiveFormsModule } from '@angular/forms';
import { FormBuilderComponent } from '../form-builder/form-builder.component';
import { ControlCommunicationService } from '../form-builder/services/control-communication.service';
import { FormBuilderModule } from '../form-builder/form-builder.module';
import { NgxDocViewerModule } from 'ngx-doc-viewer';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CoreComponent } from './core.component';


@NgModule({
  declarations: [CoreComponent, FormStepper, FormStepperRecord],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    NgxDocViewerModule
  ],
  exports: [CoreComponent, FormStepper, FormStepperRecord]
})
export class CoreModule { }
