import { Component, Input, OnInit, EventEmitter, Output } from '@angular/core';

@Component({
    selector: 'form-stepper-record',
    templateUrl: 'form-stepper-record.component.html',
    styleUrls: ['./nav.scss']
})
export class FormStepperRecord implements OnInit {
    
    @Input() public forms = []

    constructor() {}

    ngOnInit() {
    }
}