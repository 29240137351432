<div class="form-group" [formGroup]="form">
    <label *ngIf="attributes.label">{{attributes.label}}</label>
    <textarea 
        style="width: 100%" 
        class="form-control" 
        [ngClass]="{'is-invalid': form.get(attributes.name).value.length === 0 && validations.required}" 
        rows="5" 
        [formControlName]="attributes.name | sanitizeInputText" 
        [required]="validations.required" 
        [placeholder]="attributes.placeholder ? attributes.placeholder : ''" 
        [attr.disabled]="validations.disabled"
    ></textarea>
    <div class="valid-feedback">{{attributes.input_valid}}</div>
    <div class="invalid-feedback">{{attributes.input_invalid}}</div>
</div>