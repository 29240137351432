import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AlertService, AuthenticationService, RequestsService } from '@app/core/services'

@Component({
    selector: 'modal-instructions',
    templateUrl: 'modal-instructions.component.html',
    styleUrls: ['../../../app.component.scss']
})
export class ModalInstructions implements OnInit {
    
    forbiddenId = false;
    loading = false;
    submitted = false;

    constructor(public modal: NgbActiveModal,
        private alertService: AlertService,
    ) { }
    ngOnInit() {
    }

    onSubmit() {
        //console.log("SUBMITT")
        this.submitted = true;

        // Reset alerts on submit
        this.alertService.clear();
        
        this.modal.close(true)
        this.modal.dismiss()
    }
}