<div class="container" >
  <div class="row align-items-center text-center" style="height: 100vh;">
    <form class="form-recover-password fade-in" [formGroup]="recoverForm" (ngSubmit)="onSubmit()">
      <img class="mb-4 fade-in" src="/assets/icons/ecert.png" alt="" width="72" height="72" />
      <h3 class="h3 mb-3 font-weight-normal">Por favor introduce el email con el que ingresas a tu cuenta.</h3>
          
      <div class="form-group text-left pt-2 pb-2">
        <label for="email">Correo Electrónico</label>
        <input
          type="text"
          formControlName="email"
          class="form-control"
          [ngClass]="{ 'is-invalid': submitted && f.email.errors }"
        />
        <div *ngIf="submitted && f.email.errors" class="invalid-feedback">
          <div *ngIf="f.email.errors.required">Correo electrónico es requerido</div>
        </div>
      </div>
      <div class="form-group fade-in">
        <button [disabled]="loading" class="btn btn-primary">
          <span
            *ngIf="loading"
            class="spinner-border spinner-border-sm mr-1"
          ></span>
          Recuperar Contraseña
        </button>
      </div>
    </form>
  </div>
</div>
<!-- <div style="background-color: #030303;" class="native-fixed fade-in">
  <a
    style="color: #ffffff;"
    class="native-link fade-in"
    href="https://digitalignition.com.mx/es/home/"
  >
    <div
      class="native-sponsor"
      style="background-color: #ffffff; color: #030303;"
    >
      En Desarrollo
    </div>
    <div class="native-text">
      Digital Ignition — El sistema se encuentra en fase de desarrollo por lo que algunas funcionalidades pueden no estar disponibles.
    </div>
  </a>
</div> -->