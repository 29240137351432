import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '@environments/environment';

@Injectable({ providedIn: 'root' })
export class NcsService {

    constructor( private http: HttpClient ) { }

    getNcsList() {
        const jwtToken = (document.cookie.split(';').find(x => x.includes('jwtToken')) || '=').split('=')[1]

        const headers = {
            'Content-Type': 'application/json',
            'Authorization': jwtToken
        };

        return this.http.get<any>(`${environment.apiUrl}/ncs/operator/list`, { headers });
    }

    getNcsByRecord(recordId, inspectionId) {
        const jwtToken = (document.cookie.split(';').find(x => x.includes('jwtToken')) || '=').split('=')[1]

        const headers = {
            'Content-Type': 'application/json',
            'Authorization': jwtToken
        };

        return this.http.get<any>(`${environment.apiUrl}/ncs/operator/list/${recordId}/${inspectionId}`, { headers });
    }

    getNcsAttachments(recordId, inspectionId) {
        const jwtToken = (document.cookie.split(';').find(x => x.includes('jwtToken')) || '=').split('=')[1]

        const headers = {
            'Content-Type': 'application/json',
            'Authorization': jwtToken
        };

        return this.http.get<any>(`${environment.apiUrl}/ncs/getOtherOperatorFiles/${recordId}/${inspectionId}`, { headers });
    }

    acceptNcsReview(recordId, inspectionId) {
        const jwtToken = (document.cookie.split(';').find(x => x.includes('jwtToken')) || '=').split('=')[1]

        const headers = {
            'Content-Type': 'application/json',
            'Authorization': jwtToken
        };

        return this.http.patch<any>(`${environment.apiUrl}/ncs/operator/updatedNc/${recordId}/${inspectionId}`, {}, { headers });
    }

    sendNotification(recordId, inspectionId, payload) {
        const jwtToken = (document.cookie.split(';').find(x => x.includes('jwtToken')) || '=').split('=')[1]

        const headers = {
            'Authorization': jwtToken
        };

        return this.http.post<any>(`${environment.apiUrl}/ncs/accept/${recordId}/${inspectionId}`, payload, { headers });
    }

    rejectNC(recordId, inspectionId, payload) {
        const jwtToken = (document.cookie.split(';').find(x => x.includes('jwtToken')) || '=').split('=')[1]

        const headers = {
            'Authorization': jwtToken
        };

        return this.http.post<any>(`${environment.apiUrl}/ncs/reject/${recordId}/${inspectionId}`, payload, { headers });
    }

    uploadAttachments(documentId: any, files: File[], message: String) {
        const jwtToken = (document.cookie.split(';').find(x => x.includes('jwtToken')) || '=').split('=')[1]

        const headers = {
            'Authorization': jwtToken
        };

        const payload = new FormData()
        for (var i in files) {
            const file = files[i]
            file instanceof Blob && payload.append('files' + i, file, file.name)
        }
        payload.append('json_data', JSON.stringify({
            'messages': [
                message
            ]
        }))

        return this.http.post<any>(`${environment.apiUrl}/ncs/operator/uploadFiles/${documentId}`, payload, { headers });
    }

    getNcsOtherOperatorFiles(recordId, inspectionId) {
        const jwtToken = (document.cookie.split(';').find(x => x.includes('jwtToken')) || '=').split('=')[1]

        const headers = {
            'Content-Type': 'application/json',
            'Authorization': jwtToken
        };

        return this.http.get<any>(`${environment.apiUrl}/ncs/getOtherOperatorFiles/${recordId}/${inspectionId}`, { headers });
    }



    
    regenerateList() {
        const jwtToken = (document.cookie.split(';').find(x => x.includes('jwtToken')) || '=').split('=')[1]

        const headers = {
            'Content-Type': 'application/json',
            'Authorization': jwtToken
        };

        return this.http.get<any>(`${environment.apiUrl}/ncs/regenerate/list`, { headers });
    }

    regenerateNotification(recordId, inspectionId, payload, isINA) {
        const jwtToken = (document.cookie.split(';').find(x => x.includes('jwtToken')) || '=').split('=')[1]

        const headers = {
            'Authorization': jwtToken
        };

        return this.http.post<any>(`${environment.apiUrl}/ncs/regenerate/${recordId}/${inspectionId}/${isINA}`, payload, { headers });
    }
}