<div class="row" [ngClass]="{'row': attributes.variant === 'row', 'col': attributes.variant !== 'row'}"
    [formGroup]="form" style="margin-bottom: 15px;">
    <h6 *ngIf="attributes.label" style="margin-left: -15px;">{{attributes.label}}</h6>
    <div class="col" *ngFor="let option of attributes.options">
        <div class="form-check">
            <label class="form-check-label">
                <input class="form-check-input" type="radio" [formControlName]="attributes.name" [value]="option" [attr.disabled]="validations.disabled" [required]="validations.required"/>
                <span>
                    {{option}}
                </span>
            </label>
        </div>
    </div>
    <div class="col" *ngIf="attributes.other_option">
        <div class="form-check">
            <label class="form-check-label">
                <input class="form-check-input" type="radio" [formControlName]="attributes.name"
                    (change)="otherOptValueChanged($event, attributes.other_option[1])"
                    [attr.disabled]="validations.disabled" value="Otro:" />
                Otro:
            </label>
        </div>
    </div>
    <input *ngIf="attributes.other_option" class="form-control"
        [attr.disabled]="form.get(attributes.name) && form.get(attributes.name).value === 'Otro:' ? validations.disabled ? true : null : true"
        [formControlName]="attributes.other_option[1]" />
</div>