<div class="modal-header">
  <h4 class="modal-title" id="modal-title">Acuerdo para la Certificación</h4>
  <button
    type="button"
    class="close"
    aria-label="Close button"
    aria-describedby="modal-title"
    (click)="modal.dismiss('Cross click')"
  >
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
  <h4 class="text-center text-danger mb-4">{{ name }}</h4>

  <ngb-accordion [closeOthers]="true">
    <ngb-panel *ngIf="isMX" id="mx" cardClass="toggle-header">
      <ng-template ngbPanelHeader let-opened="opened">
        <button ngbPanelToggle class="btn btn-link" [ngClass]="{'toggle-button': !opened, 'toggle-button-active': opened}">
            LPO
            <svg *ngIf="opened" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"><path d="M18 15l-6-6-6 6"/></svg>
            <svg *ngIf="!opened" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"><path d="M6 9l6 6 6-6"/></svg>
        </button>
      </ng-template>

      <ng-template ngbPanelContent>
        <contract-mexico-v3
          [date]="date"
          [customer]="customer"
          [metrocert]="metrocert"
          [signOperator]="signOperator"
        ></contract-mexico-v3>
      </ng-template>
    </ngb-panel>

    <ngb-panel *ngIf="isCAN" id="can" cardClass="toggle-header">
      <ng-template ngbPanelHeader let-opened="opened">
        <button ngbPanelToggle class="btn btn-link" [ngClass]="{'toggle-button': !opened, 'toggle-button-active': opened}">
            COR/USCOEA
            <svg *ngIf="opened" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"><path d="M18 15l-6-6-6 6"/></svg>
            <svg *ngIf="!opened" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"><path d="M6 9l6 6 6-6"/></svg>
        </button>
      </ng-template>

      <ng-template ngbPanelContent>
        <contract-canada-v3
          [date]="date"
          [customer]="customer"
          [metrocert]="metrocert"
          [signOperator]="signOperator"
        ></contract-canada-v3>
      </ng-template>
    </ngb-panel>

    <ngb-panel *ngIf="isUE" id="ue" cardClass="toggle-header">
      <ng-template ngbPanelHeader let-opened="opened">
        <button ngbPanelToggle class="btn btn-link" [ngClass]="{'toggle-button': !opened, 'toggle-button-active': opened}">
            REG. UE 848/2018 (Unión Europea)
            <svg *ngIf="opened" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"><path d="M18 15l-6-6-6 6"/></svg>
            <svg *ngIf="!opened" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"><path d="M6 9l6 6 6-6"/></svg>
        </button>
      </ng-template>

      <ng-template ngbPanelContent>
        <contract-europe-v3
          [date]="date"
          [customer]="customer"
          [metrocert]="metrocert"
          [signOperator]="signOperator"
        ></contract-europe-v3>
      </ng-template>
    </ngb-panel>
  </ngb-accordion>
</div>
<div class="modal-footer">
  <button
    type="button"
    class="btn btn-outline-danger"
    (click)="modal.dismiss('cancel click')"
  >
    Cancelar
  </button>
  <button
    [disabled]="loading"
    type="button"
    ngbAutofocus
    class="btn btn-dark"
    (click)="createContract()"
  >
    <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>
    Crear contrato
  </button>
</div>
