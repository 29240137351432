import { FormBuilderComponent } from './../../../form-builder.component';
import { CoreComponent } from './../../../../core/core.component';
import { Form } from './../../../interfaces/form.interface';
import { ControlElement } from '../../../interfaces/form.interface';
import { Component, OnInit, Input } from '@angular/core'
import { FormGroup } from '@angular/forms';

@Component({
    selector: 'form-layout',
    templateUrl: './form.component.html'
})
export class FormComponent implements OnInit {

    @Input() content: [ControlElement[], ControlElement[], ControlElement[], ControlElement[]]
    form: FormGroup

    constructor(private parentForm: FormBuilderComponent) {}

    ngOnInit() {
        this.form = this.parentForm.formGroup
    }

}