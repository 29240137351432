import { urlToBase64 } from '../../urlToBase64';
import { logo } from '../../../features/generator/logo';
import { signDictator } from '../../../features/generator/signDictator';

export async function getContract(
  datePipe,
  date,
  signOperator,
  customer,
  metrocert
) {
  return {
    pageSize: 'LETTER',
    pageMargins: [40, 100, 40, 60],
    info: {
      title: 'Acuerdo para la certificación',
      author: 'eCert - Metrocert',
    },
    header: function (currentPage, pageCount) {
      return [
        {
          image: logo,
          width: 62,
          height: 60,
          alignment: 'center',
          margin: [0, 5, 0, 0],
        },
        {
          text:
            'MTO-FSOL-03-2-2 | R 00 | 10-Junio-2024 | Pág.' +
            currentPage.toString() +
            ' of ' +
            pageCount,
          alignment: 'center',
          style: 'headerDoc',
        },
      ];
    },
    footer: function () {
      return [
        {
          style: 'tableFooter',
          table: {
            widths: ['*', '*', '*'],
            body: [
              [
                {
                  text: 'Elaboró:',
                  style: 'textFooter',
                },
                {
                  text: 'Revisó:',
                  style: 'textFooter',
                },
                {
                  text: 'Autorizó:',
                  style: 'textFooter',
                },
              ],
              [
                {
                  text: 'Director de certificación',
                  alignment: 'center',
                  fillColor: '#C0C0C0',
                  style: 'textFooter',
                },
                {
                  text: 'Director de calidad',
                  alignment: 'center',
                  fillColor: '#C0C0C0',
                  style: 'textFooter',
                },
                {
                  text: 'Director',
                  alignment: 'center',
                  fillColor: '#C0C0C0',
                  style: 'textFooter',
                },
              ],
            ],
          },
        },
      ];
    },
    content: [
      {
        text: 'Acuerdo para la certificación',
        style: 'header',
      },
      {
        text: 'Art.1 General',
        style: 'article',
      },
      {
        ol: [
          {
            text: [
              'El presente acuerdo tiene la finalidad de presentar las bases sobre las que se prestará el servicio de inspección y certificación orgánica ofrecido por METROCERT al OPERADOR',
              {
                text: ` ${customer} `,
                style: {
                  bold: true,
                },
              },
              'solicitante, en conformidad con las regulaciones orgánicas señaladas en su solicitud de certificación y la ISO/IEC 17065.',
            ],
            counter: 1.1,
            style: 'text',
          },
          {
            text: 'METROCERT desarrollará las actividades previstas en el presente contrato a través de su oficina ubicada en Académico de Letrán 7 Fracc. Andrés Quintana Roo CP 58088 Morelia, Michoacán, México.',
            counter: 1.2,
            style: 'text',
          },
          {
            text: 'Firmando esta solicitud/contrato, el OPERADOR declara que el/ella ha recibido una copia de las regulaciones que le aplicarán de acuerdo con el tipo de certificación solicitada.',
            counter: 1.3,
            style: 'text',
          },
          {
            text: 'Régimen Orgánico de Canadá (COR), Principios generales del sistema y Estándares de manejo CAN/CGSB-32-310:2020 y Lista de sustancias permitidas CAN/CGSB-32-311:2020.',
            counter: 1.4,
            style: 'text',
          },
        ],
      },
      {
        text: 'Art.2 Obligaciones Contractuales del OPERADOR',
        style: 'article',
      },
      {
        ol: [
          {
            text: 'El OPERADOR se compromete a:',
            counter: 2.1,
            style: 'text',
          },
          {
            type: 'lower-alpha',
            ol: [
              {
                text: 'Llevar a cabo las actividades sujetas para la certificación en cumplimiento de las normas orgánicas bajo las cuales será evaluado y observar todas las provisiones legales que tengan que ver con las actividades realizadas.',
                style: 'text',
              },
              {
                text: 'Entregar la documentación requerida por la regulación orgánica bajo la cual se le está evaluando.',
                style: 'text',
              },
              {
                text: 'Dar facilidades a los inspectores, así como acceso a las instalaciones, los registros y documentos necesarios para hacer una inspección apropiada.',
                style: 'text',
              },
              {
                text: 'Aceptar, en caso de incumplimiento, las medidas de sanción que se mencionan en el Manual de Certificación.',
                style: 'text',
              },
              {
                text: 'Proveer a METROCERT con toda la información necesaria para los propósitos de la inspección, a través del llenado de la solicitud, el plan orgánico y la inspección en sitio, entre otros formatos que le hará llegar la certificadora.',
                style: 'text',
              },
              {
                text: 'Informar a METROCERT de todos los cambios en las actividades sujetas para la certificación y los datos contenidos en la solicitud como son: la condición legal, comercial, de organización o de propiedad, organización y gestión (por ejemplo, directivos clave, personal que toma decisiones o personal técnico), modificaciones en el producto o en el método de producción, direcciones de contacto y sitios de producción, cambios importantes en el sistema de gestión de la calidad. (ej. Datos de identificación de las partes involucradas, cambios en la unidad de producción, aéreas agrícolas, etc.)',
                style: 'text',
              },
              {
                text: 'Cumplir con las obligaciones financieras y administrativas relacionadas con las actividades descritas en este contrato, reconociendo que para realizar los servicios de certificación, METROCERT requiere la entrega de recursos técnicos y que consecuentemente, METROCERT no se hace responsable por no alcanzar los objetivos de certificación por los cuales METROCERT lleva a cabo un papel funcional.',
                style: 'text',
              },
              {
                text: 'El operador cumple con todos los requisitos que pueda estipular el esquema de certificación con relación al uso de las marcas de conformidad y a la información relacionada con el producto.',
                style: 'text',
              },
              {
                text: 'Se abstengan de seguir utilizando la marca de certificación, distintivo nacional, logos, etiquetas de conformidad y/o cualquier otra indicación que se refiera a la inspección y certificación de METROCERT después de terminada la vigencia del certificado o después de la terminación, suspensión o retiro del certificado y de la autorización para usar la marca en casos previstos por el Manual de Certificación.',
                style: 'text',
              },
              {
                text: 'Hacer declaraciones coherentes con la certificación obtenida bajo las condiciones de este acuerdo, en relación con el estatus y alcance del proceso de certificación.',
                style: 'text',
              },
              {
                text: 'No utilizar su certificación de producto de manera que ocasione mala reputación para el organismo de certificación, y no hace ninguna declaración relacionada con su certificación de producto que el organismo de certificación pueda considerar engañosa o no autorizada.',
                style: 'text',
              },
              {
                text: 'Si el operador suministra copias de los documentos de certificación a otros, los documentos se deben reproducir en su totalidad o según lo especifique el esquema de certificación.',
                style: 'text',
              },
              {
                text: 'Al hacer referencia a su certificación de producto en medios de comunicación tales como documentos, folletos o publicidad, el operador debe cumplir con los requisitos del organismo de certificación o los especificados por el esquema de certificación.',
                style: 'text',
              },
              {
                text: 'Mantener registros de todas las quejas referidas a los productos certificados, tomar las acciones respecto a las quejas y documentar las acciones.',
                style: 'text',
              },
              {
                text: 'Permitir la participación de observadores de las autoridades competentes o asignados por Metrocert dentro de sus procesos de gestión de calidad.',
                style: 'text',
              },
              {
                text: 'Informar por escrito y sin dilaciones injustificadas a los compradores de los productos pertinentes e intercambiar información con la autoridad competente, o, en su caso, con la autoridad de control o el organismo de control, en caso de que se demuestre una sospecha de incumplimiento, de que no pueda descartarse dicha sospecha, o de que se demuestre un incumplimiento que afecte a la integridad de los productos en cuestión.',
                style: 'text',
              },
              {
                text: 'Aceptar que Metrocert transfiera su expediente de control en caso de cambio de la autoridad de control u organismo de control. En caso de retirada de la producción ecológica, mantener el expediente de control durante al menos cinco años por la última autoridad de control u organismo de control.',
                style: 'text',
              },
              {
                text: 'Informar inmediatamente a la autoridad competente o a la autoridad u organismo designado conforme a lo dispuesto en el artículo 34, apartado 4, en caso de retirada de la producción ecológica.',
                style: 'text',
              },
              {
                text: 'Aceptar intercambiar información entre autoridades de control u organismos de control, en caso de que los subcontratistas sean inspeccionados por distintas autoridades de control u organismos de control.',
                style: 'text',
              },
              {
                text: 'Cuando un operador sospeche que un producto que él mismo ha producido, preparado, importado o recibido de otro operador no cumple lo dispuesto en el presente Reglamento, dicho operador, con sujeción a lo dispuesto en el artículo 28, apartado 2:',
                style: 'text',
              },
              {
                type: 'lower-alpha',
                ol: [
                  {
                    text: 'Identificará y separará el producto de que se trate.',
                    style: 'text',
                  },
                  {
                    text: 'Comprobará si la sospecha puede demostrarse.',
                    style: 'text',
                  },
                  {
                    text: 'No comercializará el producto de que se trate como producto ecológico o en conversión ni lo utilizará en la producción ecológica, a menos que pueda descartarse la sospecha.',
                    style: 'text',
                  },
                  {
                    text: 'Cuando se demuestre la sospecha o cuando esta no pueda descartarse, informará de inmediato a la autoridad competente correspondiente, o, cuando proceda, a la autoridad de control u organismo de control correspondiente, y le comunicará los elementos de que disponga, en su caso.',
                    style: 'text',
                  },
                  {
                    text: 'Cooperará plenamente con la autoridad competente correspondiente, o, cuando proceda, con la  autoridad  de  control  u  organismo  de control correspondiente, para verificar e identificar los motivos del supuesto incumplimiento.',
                    style: 'text',
                  },
                ],
                margin: [20, 0, 0, 0],
              },
              {
                text: 'Aceptar las sanciones definidas por Metrocert derivadas de incumplimientos con las regulaciones orgánicas identificadas en el proceso de certificación o derivadas de investigaciones desarrolladas por Metrocert. En caso que así lo defina Metrocert, se dejará de hacer referencia a la certificación orgánica de los productos en etiquetas o material de promoción.',
                style: 'text',
              },
              {
                text: 'Aceptar inspecciones y controles adicionales definidas por Metrocert, en caso de incumplimientos críticos, repetitivos o continuos.',
                style: 'text',
              },
            ],
          },
        ],
      },
      {
        text: 'Art.3 Obligaciones Contractuales de METROCERT',
        style: 'article',
      },
      {
        ol: [
          {
            text: 'Desarrollar las actividades objeto de la certificación, con la pericia requerida para tales actividades, cumplir con las reglas de la certificadora y reglamentos y cumplir con lo previsto en el Manual de Certificación.',
            counter: 3.1,
            style: 'text',
          },
          {
            text: [
              'METROCERT se obliga a informar al operador acerca de todos los cambios y/o modificaciones a los Reglamentos de Certificación, a través de la página web (',
              {
                text: 'www.metrocert.com',
                style: 'text',
                decoration: 'underline',
              },
              '), la cual es constantemente actualizada.',
            ],
            counter: 3.2,
            style: 'text',
          },
          {
            text: 'METROCERT no asume alguna responsabilidad, en caso de que el operador no cumpla con las obligaciones establecidas por las regulaciones orgánicas y los prerrequisitos para una correcta inspección y actividad de certificación.',
            counter: 3.3,
            style: 'text',
          },
          {
            text: 'De acuerdo con los requisitos ISO 17065 y las leyes aplicables, METROCERT se comprometerme a mantener la confidencialidad de los datos personales y de todas las informaciones empresariales derivadas de la ejecución de las acciones de control, con excepción de aquellos para los cuales el Manual para la certificación y eventuales disposiciones de ley o judiciales prevén la publicación, transmisión y comunicación.',
            counter: 3.4,
            style: 'text',
          },
        ],
      },
      {
        text: 'Art.4 Tarifas y Modalidades de Pago',
        style: 'article',
      },
      {
        ol: [
          {
            text: 'Conforme al respectivo pago para las prestaciones solicitadas a METROCERT y objeto del presente contrato, el OPERADOR retribuirá a METROCERT los importes señalados en el Presupuesto o Tarifario METROCERT vigente para las actividades de control y certificación y fijados por METROCERT. Dicho tarifario es suscrito contextualmente con el presente contrato, por el OPERADOR y constituye parte integrante del mismo.',
            counter: 4.1,
            style: 'text',
          },
          {
            text: 'En cada caso, el OPERADOR se encuentra obligado a mantenerse informado del Tarifario vigente incluso en el momento de la realización de cada control individual, más allá de las modificaciones de las disposiciones previstas por el Reglamento para la certificación METROCERT.',
            counter: 4.2,
            style: 'text',
          },
          {
            text: 'La firma del OPERADOR en el Reporte de Inspección dará testimonio del completo conocimiento del OPERADOR y acepta las tasas y disposiciones, sin excepciones. Para este fin METROCERT dará la información en forma oportuna y pronta a través del sitio web www.metrocert.com',
            counter: 4.3,
            style: 'text',
          },
          {
            text: 'Los importes referidos a las cuotas debidas en base a las tarifas serán retribuidos según las modalidades establecidas en el Tarifario METROCERT (o presupuesto) vigente.',
            counter: 4.4,
            style: 'text',
          },
          {
            text: 'Cada año, antes del 28 de febrero, METROCERT comunicará al operador cualquier cambio relativo al Tarifario, por correo o a través de comunicación escrita.',
            counter: 4.5,
            style: 'text',
          },
        ],
      },
      {
        text: 'Art.5 Validez y duración - renovación automática',
        style: 'article',
      },
      {
        ol: [
          {
            text: 'El presente contrato será válido a partir de la fecha de recepción por parte de /METROCERT.',
            counter: 5.1,
            style: 'text',
          },
          {
            text: 'El presente contrato es válido hasta el 31 de diciembre del año siguiente al de la suscripción. Se considerará como automáticamente renovado por los años sucesivos si no se realiza notificación de revocación de una de las partes. Tales comunicaciones deben llegar al menos treinta (30) días antes del vencimiento del contrato.',
            counter: 5.2,
            style: 'text',
          },
        ],
      },
      {
        text: 'Art.6 Facultad de rescisión y terminación del contrato',
        style: 'article',
      },
      {
        ol: [
          {
            text: 'El OPERADOR podrá renunciar en cualquier momento a la certificación, comunicando su renuncia por medio de correo electrónico o alguna otra comunicación escrita que muestre la fecha correcta, para ser enviada a la oficina de METROCERT. El OPERADOR quedará sin embargo obligado al pago de las tarifas fijas y variables por el trabajo desarrollado por METROCERT, en base a lo suscrito en el tarifario METROCERT (o estimado).',
            counter: 6.1,
            style: 'text',
          },
          {
            text: 'El contrato se entenderá mutuamente terminado al momento de la rescisión mediante acto escrito, dejando a salvo el derecho de METROCERT a negociar la cuota según el Tarifario.',
            counter: 6.2,
            style: 'text',
          },
        ],
      },
      {
        text: 'Art.7 Clausula de Terminación Explicita',
        style: 'article',
      },
      {
        text: 'METROCERT podrá terminar este contrato y cualquier acuerdo entre las partes, sin previo aviso, en casos donde el OPERADOR no haya cumplido con las obligaciones a su cargo establecidas por el presente contrato, violando el reglamento y el listado de tarifas que constituyen parte de este contrato. Sólo para los fines del eventual pago de la cuota, la resolución está equiparada a la renuncia y por lo tanto la cantidad total que deberá retribuir el operador se calculará de acuerdo al Tarifario de METROCERT (o estimado).',
        style: 'text',
      },
      {
        text: 'Art. 8 Jurisdicción Competente',
        style: 'article',
      },
      {
        ol: [
          {
            text: 'En caso de una controversia derivada del presente acuerdo y que involucre tareas, responsabilidades, derechos y deberes de METROCERT, la jurisdicción competente será la de los tribunales de la jurisdicción del Distrito Judicial de la ciudad de Morelia, Michoacán, México.',
            counter: 8.1,
            style: 'text',
          },
          {
            text: 'Para asuntos diversos y de competencia de METROCERT, en caso de controversia y para la recuperación de los pagos, se aplicarán las normas de competencia y jurisdicción como se encuentran previstas por el Código de Procedimientos Civiles.',
            counter: 8.2,
            style: 'text',
          },
        ],
      },
      {
        columns: [
          [
            {
              text: 'Fecha',
              style: 'subheader',
            },
            {
              text:
                datePipe.transform(date, 'd') +
                ' de ' +
                datePipe.transform(date, 'MMMM') +
                ' de ' +
                datePipe.transform(date, 'y'),
              style: 'text',
            },
          ],
          [
            {
              text: 'Firma del Operador',
              style: 'subheader',
            },
            {
              image: await urlToBase64(signOperator),
              width: 120,
              height: 60,
            },
            {
              text: customer,
              style: 'text',
            },
          ],
        ],
      },
      {
        columns: [
          [
            {
              text: 'Fecha',
              style: 'subheader',
            },
            {
              text:
                datePipe.transform(date, 'd') +
                ' de ' +
                datePipe.transform(date, 'MMMM') +
                ' de ' +
                datePipe.transform(date, 'y'),
              style: 'text',
            },
          ],
          [
            {
              text: 'Firma de Metrocert',
              style: 'subheader',
            },
            {
              image: signDictator,
              width: 120,
              height: 60,
            },
            {
              text: metrocert,
              style: 'text',
            },
          ],
        ],
      },
      {
        table: {
          widths: ['*'],
          body: [
            [
              {
                text: 'Aviso de Privacidad',
                style: 'header',
                border: [false, false, false, true],
              },
            ],
          ],
        },
        layout: {
          hLineColor: function (i, node) {
            return 'green';
          },
          paddingBottom: function (i, node) {
            return 0;
          },
        },
        margin: [0, 30, 0, 0],
      },
      {
        text: [
          'De acuerdo con la Ley Federal de Protección de Datos Personales en Posesión de Particulares,',
          {
            text: ' METROCERT',
            style: 'text',
            bold: true,
          },
          ' informa al OPERADOR que sus datos personales se manejarán de la siguiente manera:',
        ],
        style: 'text',
        margin: [0, 30, 0, 0],
      },
      {
        type: 'upper-roman',
        ol: [
          {
            text: [
              ' La identidad y domicilio del responsable que los recaba: El responsable es el Representante legal de METROCERT, domiciliado para efectos de la siguiente comisión en Académico de Letrán 7 Fracc. Andrés Quintana Roo CP 58088',
              {
                text: ' Morelia, Michoacán, México,',
                style: 'text',
                bold: true,
              },
              ' E-mail: contacto@metrocert.com',
            ],
            style: 'text',
          },
          {
            text: 'Las finalidades del tratamiento de datos: Los datos proporcionados por usted se utilizarán inicialmente para poder desarrollar adecuadamente el servicio de inspección y certificación que nos ha solicitado. Sus datos generales y el estatus de su certificación también serán publicado en la lista de operadores certificados por esta agencia, para cumplir con requerimientos de Ley y de Norma de acreditación. Los datos podrán ser comunicados a las Instituciones nacionales, Secretarias de Estado, Oficinas estatales, Oficinas regionales, Organismos de Certificación, Centro de elaboración de datos u otros entes afines, complementarios o sinérgicos a nuestras actividades. La agencia también podrá usar sus datos para hacerle llegar actualizaciones de las regulaciones orgánicas u otra información de importancia, que puede incluir la publicación de eventos de difusión o capacitación.',
            style: 'text',
          },
          {
            text: 'Las opciones y medios que el responsable ofrezca a los titulares para limitar el uso o divulgación de los datos: En caso de que usted no este de acuerdo en que sus datos o una parte de ellos sean tratados con fines adicionales, usted puede comunicarlo por escrito a esta agencia de certificación.',
            style: 'text',
          },
          {
            text: [
              'Los medios para ejercer los derechos de acceso, rectificación, cancelación u oposición, de conformidad con lo dispuesto en esta Ley; Usted tendrá acceso a sus datos personales, así como a su rectificación, a través del sitio web ',
              {
                text: 'www.metrocert.com',
                style: 'text',
                decoration: 'underline',
              },
              ', y utilizando la clave y código de acceso que le han sido asignados. La rectificación de sus datos puede implicar cambios en los documentos emitidos por esta certificadora, por lo que información o procesos adicionales puede ser necesario que sean implementados, para hacer efectivas las rectificaciones. Para cancelar u oponerse a que sus datos sean tratados con fines adicionales, usted podrá dirigir un escrito al titular del organismo de certificación.',
            ],
            style: 'text',
          },
          {
            text: 'Transferencias de datos que se efectúen: Si usted ha solicitado un servicio de certificación válido en otros países, Metrocert tendrá que transferir la información que la regulación tenga definida, con fines de control, estadísticos o de otro tipo.',
            style: 'text',
          },
          {
            text: 'El procedimiento y medio por el cual el responsable comunicará a los titulares de cambios al aviso de privacidad, de conformidad con lo previsto en esta Ley. Los cambios en el aviso de privacidad se le darán a conocer enviándole un nuevo formato de acuerdo para la certificación, que incluya los cambios correspondientes.',
            style: 'text',
          },
        ],
      },
      {
        text: 'Plenamente informado de las características del tratamiento y subrayado que el mismo es efectuado con la plena observancia del Aviso de privacidad, expreso el consentimiento al tratamiento de mis datos personales, inclusive para su comunicación a terceros.',
        style: 'text',
      },
      {
        columns: [
          [
            {
              text: 'Fecha',
              style: 'subheader',
            },
            {
              text:
                datePipe.transform(date, 'd') +
                ' de ' +
                datePipe.transform(date, 'MMMM') +
                ' de ' +
                datePipe.transform(date, 'y'),
              style: 'text',
            },
          ],
          [
            {
              text: 'Firma del Operador',
              style: 'subheader',
            },
            {
              image: await urlToBase64(signOperator),
              width: 120,
              height: 60,
            },
            {
              text: customer,
              style: 'text',
            },
          ],
        ],
      },
    ],
    styles: {
      header: {
        fontSize: 16,
        bold: true,
        alignment: 'center',
        margin: [0, 0, 0, 0],
      },
      subheader: {
        fontSize: 11,
        bold: true,
        margin: [0, 13, 0, 0],
      },
      article: {
        fontSize: 9,
        bold: true,
        alignment: 'justify',
        margin: [0, 15, 0, 2],
      },
      text: {
        fontSize: 9,
        alignment: 'justify',
        margin: [0, 2, 0, 2],
      },
      tableFooter: {
        margin: [40, 0, 40, 0],
      },
      textFooter: {
        fontSize: 8,
      },
      headerDoc: {
        fontSize: 10,
        bold: true,
        margin: [0, 5, 0, 0],
      },
    },
    defaultStyle: {
      fontSize: 9,
    },
  };
}
