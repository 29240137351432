import { readFileSync } from 'fs';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '@environments/environment';

@Injectable({ providedIn: 'root' })
export class ReviewsService {

    constructor(private http: HttpClient) { }

    getPendings(page, search) {
        const jwtToken = (document.cookie.split(';').find(x => x.includes('jwtToken')) || '=').split('=')[1]

        const headers = {
            'Content-Type': 'application/json',
            'Authorization': jwtToken
        };

        const body = {
            page: page,
            search: search
        }

        return this.http.post<any>(`${environment.apiUrl}/reviews/pending`, body, { headers });
    }

    getAssigned(page, search) {
        const jwtToken = (document.cookie.split(';').find(x => x.includes('jwtToken')) || '=').split('=')[1]

        const headers = {
            'Content-Type': 'application/json',
            'Authorization': jwtToken
        };

        const body = {
            page: page,
            search: search
        }

        return this.http.post<any>(`${environment.apiUrl}/reviews/assigned`, body, { headers });
    }

    getReviewers() {
        const jwtToken = (document.cookie.split(';').find(x => x.includes('jwtToken')) || '=').split('=')[1]

        const headers = {
            'Content-Type': 'application/json',
            'Authorization': jwtToken
        };

        return this.http.get<any>(`${environment.apiUrl}/reviews/reviewers`, { headers });
    }

    changeReviewer(reviewerId, reviewId) {
        const jwtToken = (document.cookie.split(';').find(x => x.includes('jwtToken')) || '=').split('=')[1]

        const headers = {
            'Content-Type': 'application/json',
            'Authorization': jwtToken
        };

        const payload = {}

        return this.http.patch<any>(`${environment.apiUrl}/reviews/reviewers/${reviewerId}/assign/${reviewId}`, payload, { headers });
    }

    assign(reviewId) {
        const jwtToken = (document.cookie.split(';').find(x => x.includes('jwtToken')) || '=').split('=')[1]

        const headers = {
            'Content-Type': 'application/json',
            'Authorization': jwtToken
        };

        const payload = {}

        return this.http.patch<any>(`${environment.apiUrl}/reviews/assign/${reviewId}`, payload, { headers });
    }

    uploadPreview(file: Blob, ext: string) {
        const jwtToken = (document.cookie.split(';').find(x => x.includes('jwtToken')) || '=').split('=')[1]

        const headers = {
            'authorization': jwtToken
        };

        const payload = new FormData()
        payload.append('file', file, 'file.' + ext)

        return this.http.post<any>(`${environment.apiUrl}/reviews/preview`, payload, { headers });
    }

    getReviewData(reviewId) {
        const jwtToken = (document.cookie.split(';').find(x => x.includes('jwtToken')) || '=').split('=')[1]

        const headers = {
            'Content-Type': 'application/json',
            'Authorization': jwtToken
        };

        return this.http.get<any>(`${environment.apiUrl}/reviews/review/${reviewId}`, { headers });
    }

    signReview(reviewId, formId) {
        const jwtToken = (document.cookie.split(';').find(x => x.includes('jwtToken')) || '=').split('=')[1]

        const headers = {
            'Content-Type': 'application/json',
            'Authorization': jwtToken
        };

        const payload = {}

        return this.http.patch<any>(`${environment.apiUrl}/reviews/review/${reviewId}/form/${formId}/sign`, payload, { headers });
    }

    reviewComplete(reviewId, values) {
        const jwtToken = (document.cookie.split(';').find(x => x.includes('jwtToken')) || '=').split('=')[1]

        const headers = {
            'Content-Type': 'application/json',
            'Authorization': jwtToken
        };

        const payload = values

        return this.http.patch<any>(`${environment.apiUrl}/reviews/review/${reviewId}/complete`, payload, { headers });
    }

    getForm(reviewId, formId) {
        const jwtToken = (document.cookie.split(';').find(x => x.includes('jwtToken')) || '=').split('=')[1]

        const headers = {
            'Content-Type': 'application/json',
            'Authorization': jwtToken
        };

        return this.http.get<any>(`${environment.apiUrl}/reviews/review/${reviewId}/form/${formId}`, { headers });
    }

    saveForm(reviewId, formId, form) {
        let body = {
            submission: []
        }

        for (var i in Object.keys(form)) {
            const key = Object.keys(form)[i]
            body.submission.push({
                name: key,
                value: form[key]
            })
        }

        const jwtToken = (document.cookie.split(';').find(x => x.includes('jwtToken')) || '=').split('=')[1]

        const headers = {
            'Content-Type': 'application/json',
            'Authorization': jwtToken
        };

        return this.http.patch<any>(`${environment.apiUrl}/reviews/review/${reviewId}/form/${formId}/submit`, body, { headers });
    }

    getUrlStatus(url: string) {
        return this.http.get(url);
    }
}