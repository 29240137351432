import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '@environments/environment';

@Injectable({ providedIn: 'root' })
export class UtilitiesService {
    constructor(private http: HttpClient) { }
    getKeyPassword() {
        const jwtToken = (document.cookie.split(';').find(x => x.includes('jwtToken')) || '=').split('=')[1]

        const headers = {
            'Content-Type': 'application/json',
            'Authorization': jwtToken
        };

        return this.http.get<any>(`${environment.apiUrl}/utilities/key`, { headers });
    }

    updateKeyPassword(password, time) {
        //console.log(time)
        const body = {
            time: time,
            password: password
          }
        const jwtToken = (document.cookie.split(';').find(x => x.includes('jwtToken')) || '=').split('=')[1]

        const headers = {
            'Content-Type': 'application/json',
            'Authorization': jwtToken
        };

        return this.http.post(`${environment.apiUrl}/utilities/key`, body, { headers })
    }
}