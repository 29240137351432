import { AlignmentType, Document, Paragraph, TextRun, Media, Header, Footer, Table, TableRow, TableCell, WidthType, PageNumber, ShadingType, PageBreak, BorderStyle } from "docx";
import { logo } from "../logo";
import { DatePipe } from '@angular/common';
import {userWithoutSign} from "../userWithoutSign"

export class DocumentCreatorMTO_FINS_03_2_4_v3 {
  public getBase64FromUrl = async (url) => {
      const data = await fetch(url, {
        headers: {
            'Cache-Control': 'no-cache, no-store, must-revalidate',
            'Pragma': 'no-cache',
            'Expires': 'Sat, 01 Jan 2000 00:00:00 GMT',
            'If-Modified-Since': '0'
        }
    });
      const blob = await data.blob();
      return new Promise((resolve) => {
        const reader = new FileReader();
        reader.readAsDataURL(blob);
        reader.onloadend = function () {
          const base64data = reader.result;
          resolve(base64data);
        }
      });
    }

    // INIT MTO_FINS_03_2_4
    public async create_MTO_FINS_03_2_4(data, typeInspection = '', signature = '', signature2 = ''): Promise<Document> {
        const document = new Document();
        const image = Media.addImage(document, logo, 50, 50);
        var datePipe = new DatePipe('es-MX');

        let signatureImage = null
        if (signature) {
            signatureImage = await this.getBase64FromUrl(signature)
        } else {
            signatureImage = userWithoutSign
        }

        let signatureImage2 = null
        if (signature2) {
        signatureImage2 = await this.getBase64FromUrl(signature2)
        } else {
        signatureImage2 = userWithoutSign
        }

        document.addSection({
            headers: {
                default: new Header({
                    children: [
                        new Paragraph({ alignment: AlignmentType.CENTER, children: [image], }),
                        new Paragraph({
                            alignment: AlignmentType.CENTER, children: [
                                new TextRun({
                                    children: [`MTO-FINS-03-2-4| R 03 | 25-Junio-2024 | Pág. `, PageNumber.CURRENT],
                                    font: 'Book Antiqua',
                                    size: 20,
                                    bold: true
                                }),
                                new TextRun({
                                    font: 'Book Antiqua',
                                    size: 20,
                                    bold: true,
                                    children: [' de ', PageNumber.TOTAL_PAGES]
                                }),
                            ], spacing: { before: 200, after: 100 }
                        })
                    ]
                })
            },
            footers: {
                default: new Footer({
                    children: [
                        new Paragraph({ alignment: AlignmentType.CENTER }),
                        this.createFooter()
                    ]
                }),
            },
            children: [
                new Paragraph({
                    alignment: AlignmentType.CENTER,
                    children: [
                        new TextRun({
                            text: 'REPORTE DE INSPECCIÓN PRODUCCIÓN ANIMAL ACUÍCOLA',
                            font: 'Calibri (Body)',
                            size: 22,
                            bold: true
                        })
                    ],
                    spacing: { after: 300, before: 200 }
                }),
                this.createTableUDP(data, datePipe, typeInspection),
                new Paragraph({
                    spacing: {
                        after: 0,
                        before: 0
                    }
                }),
                this.createRegistros(data),
                new Paragraph({
                    spacing: {
                        after: 0,
                        before: 0
                    }
                }),
              this.createInstalaciones(data),
              new Paragraph({
                spacing: {
                  after: 0,
                  before: 0
                }
              }),
              this.createEspecies(data),
              new Paragraph({
                spacing: {
                  after: 0,
                  before: 0
                }
              }),
              this.createReproduccion(data),
              new Paragraph({
                spacing: {
                  after: 0,
                  before: 0
                }
              }),
                this.createManejo(data),
                new Paragraph({
                    spacing: {
                        after: 0,
                        before: 0
                    }
                }),
                this.createProductosCertificados(data),
                new Paragraph({
                    children: [
                        new PageBreak()
                    ],
                    spacing: {
                        after: 0,
                        before: 0
                    }
                }),
                new Paragraph({
                    alignment: AlignmentType.CENTER,
                    children: [
                        new TextRun({
                            text: 'RESUMEN DE HALLAZGOS',
                            font: 'Calibri (Body)',
                            size: 22,
                            bold: true
                        })
                    ],
                    spacing: { after: 300, before: 200 }
                }),
                this.createResumen(data),
                new Paragraph({
                    spacing: {
                        after: 0,
                        before: 0
                    }
                }),
                this.createResumenReunion(data),
                new Paragraph({
                    spacing: {
                        after: 0,
                        before: 0
                    }
                }),
                this.createComentariosOperador(data),
                new Paragraph({
                    spacing: {
                        after: 0,
                        before: 0
                    }
                }),
                this.createSigns(data, document, signatureImage, signatureImage2),
            ],
            margins: {
                top: 350,
                bottom: 500,
                left: 1000,
                right: 1000
            }
        });

        return document;
    }

    public createFooter(): Table {
        const table = new Table({
            rows: [
                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: 'Elaboró:',
                                            font: 'Calibri (Cuerpo)',
                                            size: 22
                                        })
                                    ]
                                })
                            ],
                            width: {
                                size: 33,
                                type: WidthType.PERCENTAGE
                            }
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: 'Revisó:',
                                            font: 'Calibri (Cuerpo)',
                                            size: 22
                                        })
                                    ]
                                })
                            ],
                            width: {
                                size: 33,
                                type: WidthType.PERCENTAGE
                            }
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: 'Autorizó:',
                                            font: 'Calibri (Cuerpo)',
                                            size: 22
                                        })
                                    ]
                                }),
                            ],
                            width: {
                                size: 33,
                                type: WidthType.PERCENTAGE
                            }
                        })
                    ]
                }),
                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: 'Director de certificación',
                                            font: 'Calibri (Cuerpo)',
                                            size: 16,
                                            bold: true
                                        })
                                    ]
                                }),

                            ],
                            width: {
                                size: 33,
                                type: WidthType.PERCENTAGE
                            },
                            shading: {
                                fill: '#C0C0C0'
                            }
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: 'Responsable de calidad',
                                            font: 'Calibri (Cuerpo)',
                                            size: 16,
                                            bold: true
                                        })
                                    ]
                                }),

                            ],
                            width: {
                                size: 33,
                                type: WidthType.PERCENTAGE
                            },
                            shading: {
                                fill: '#C0C0C0'
                            }
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: 'Director general',
                                            font: 'Calibri (Cuerpo)',
                                            size: 16,
                                            bold: true
                                        })
                                    ]
                                }),
                            ],
                            width: {
                                size: 33,
                                type: WidthType.PERCENTAGE
                            },
                            shading: {
                                fill: '#C0C0C0'
                            }
                        })
                    ]
                })
            ],
            margins: {
                left: 70,
                right: 70
            },
            columnWidths: [3213, 3213, 3212],
            alignment: AlignmentType.CENTER
        })
        return table
    }

    public createTableUDP(data, datePipe, tipo_inspeccion): Table {
        const table = new Table({
            rows: [
                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: 'Código de operación:',
                                            font: 'Arial',
                                            size: 22,
                                            bold: true
                                        })
                                    ],
                                    spacing: { before: 100, after: 100 }
                                })
                            ],
                            columnSpan: 1
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: data.customer.Id_Comercial,
                                            font: 'Arial',
                                            size: 22
                                        })
                                    ],
                                    spacing: { before: 100, after: 100 }
                                })
                            ],
                            columnSpan: 1
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: 'Fecha:',
                                            font: 'Arial',
                                            size: 22,
                                            bold: true
                                        })
                                    ],
                                    spacing: { before: 100, after: 100 }
                                })
                            ],
                            columnSpan: 1
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: `${datePipe.transform(data.inspectionDate, 'd-MMMM-y')}`,
                                            font: 'Arial',
                                            size: 22
                                        })
                                    ],
                                    spacing: { before: 100, after: 100 }
                                })
                            ],
                            columnSpan: 1
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: `Tipo de inspección:`,
                                            font: 'Arial',
                                            size: 22,
                                            bold: true
                                        })
                                    ],
                                    spacing: { before: 100, after: 100 }
                                })
                            ],
                            columnSpan: 1
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: `${tipo_inspeccion === 'Renovación' ? '■' : '❏'} Renovación`,
                                            font: 'Calibri (Body)',
                                            size: 20
                                        })
                                    ]
                                }),
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: `${tipo_inspeccion === 'Primer Inspección' ? '■' : '❏'} Primer Inspección`,
                                            font: 'Calibri (Body)',
                                            size: 20
                                        })
                                    ]
                                }),
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: `${tipo_inspeccion === 'Adicional' ? '■' : '❏'} Adicional`,
                                            font: 'Calibri (Body)',
                                            size: 20
                                        })
                                    ]
                                }),
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: `${tipo_inspeccion === 'Anunciada' ? '■' : '❏'} Anunciada`,
                                            font: 'Calibri (Body)',
                                            size: 20
                                        })
                                    ]
                                }),
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: `${tipo_inspeccion === 'No Anunciada' ? '■' : '❏'} No Anunciada`,
                                            font: 'Calibri (Body)',
                                            size: 20
                                        })
                                    ]
                                })
                            ],
                            columnSpan: 2
                        })
                    ]
                }),
                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: 'Inspector:',
                                            font: 'Arial',
                                            size: 22,
                                            bold: true
                                        })
                                    ],
                                    spacing: { before: 100, after: 100 }
                                })
                            ],
                            columnSpan: 1
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: data.inspector,
                                            font: 'Arial',
                                            size: 22
                                        })
                                    ],
                                    spacing: { before: 100, after: 100 }
                                })
                            ],
                            columnSpan: 6
                        })
                    ]
                }),
                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: 'Operador:',
                                            font: 'Arial',
                                            size: 22,
                                            bold: true
                                        })
                                    ],
                                    spacing: { before: 100, after: 100 }
                                })
                            ],
                            columnSpan: 1
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: data.customer.Company_Name,
                                            font: 'Arial',
                                            size: 22
                                        })
                                    ],
                                    spacing: { before: 100, after: 100 }
                                })
                            ],
                            columnSpan: 6
                        })
                    ]
                }),
            ],
            margins: {
                left: 70,
                right: 70
            },
            width: {
                size: 100,
                type: WidthType.PERCENTAGE,
            },
            columnWidths: [2192, 1802, 1792, 1972, 1892, 1802, 1792]
        })

        const unidades = this.getValueList(data.submission, 'unidades_produccion')
        unidades.forEach( (unidad, index) => {
          table.addChildElement(
              new TableRow({
                  children: [
                      new TableCell({
                          children: [
                              new Paragraph({
                                  alignment: AlignmentType.LEFT,
                                  children: [
                                      new TextRun({
                                          text: `${index === 0 ? 'Unidades de producción evaluadas:' : ''}`,
                                          font: 'Arial',
                                          size: 22,
                                          bold: true
                                      })
                                  ],
                                  spacing: { before: 100, after: 100 }
                              })
                          ],
                          columnSpan: 1,
                          borders: {
                            top: {
                              style: BorderStyle.NONE,
                              color: "white",
                              size: 0
                            },
                            bottom: {
                              style: BorderStyle.NONE,
                              color: "white",
                              size: 0
                            }
                          }
                      }),
                      new TableCell({
                          children: [
                              new Paragraph({
                                  alignment: AlignmentType.LEFT,
                                  children: [
                                      new TextRun({
                                          text: `Nombre: `,
                                          font: 'Arial',
                                          size: 22,
                                          bold: true
                                      }),
                                      new TextRun({
                                          text: `${unidad['unidades_produccion_nombre']}`,
                                          font: 'Arial',
                                          size: 22
                                      })
                                  ],
                                  spacing: { before: 100 }
                              }),
                              new Paragraph({
                                  alignment: AlignmentType.LEFT,
                                  children: [
                                      new TextRun({
                                          text: `Ubicación: `,
                                          font: 'Arial',
                                          size: 22,
                                          bold: true
                                      }),
                                      new TextRun({
                                          text: `${unidad['unidades_produccion_ubicacion']}`,
                                          font: 'Arial',
                                          size: 22
                                      })
                                  ],
                                  spacing: { after: 100 }
                              }),
                              new Paragraph({
                                  alignment: AlignmentType.LEFT,
                                  children: [
                                      new TextRun({
                                          text: `Especie: `,
                                          font: 'Arial',
                                          size: 22,
                                          bold: true
                                      }),
                                      new TextRun({
                                          text: `${unidad['unidades_produccion_especie']}`,
                                          font: 'Arial',
                                          size: 22
                                      })
                                  ],
                                  spacing: { after: 100 }
                              }),
                              new Paragraph({
                                  alignment: AlignmentType.LEFT,
                                  children: [
                                      new TextRun({
                                          text: `No. Tanques: ${parseFloat(unidad.unidades_produccion_unidades_tanques_total) !== 0 ? '■' : '❏'} Total: `,
                                          font: 'Arial',
                                          size: 22,
                                          bold: true
                                      }),
                                      new TextRun({
                                          text: `${parseFloat(unidad.unidades_produccion_unidades_tanques_total) === 0 ? 0 : parseFloat(unidad.unidades_produccion_unidades_tanques_total).toFixed(2)} `,
                                          font: 'Arial',
                                          size: 22
                                      }),
                                      new TextRun({
                                          text: `${parseFloat(unidad.unidades_produccion_unidades_tanques_organica) !== 0 ? '■' : '❏'} Orgánica: `,
                                          font: 'Arial',
                                          size: 22,
                                          bold: true
                                      }),
                                      new TextRun({
                                          text: `${parseFloat(unidad.unidades_produccion_unidades_tanques_organica) === 0 ? 0 : parseFloat(unidad.unidades_produccion_unidades_tanques_organica).toFixed(2)} `,
                                          font: 'Arial',
                                          size: 22
                                      }),
                                      new TextRun({
                                          text: `${parseFloat(unidad.unidades_produccion_unidades_tanques_transicion) !== 0 ? '■' : '❏'} En transición: `,
                                          font: 'Arial',
                                          size: 22,
                                          bold: true
                                      }),
                                      new TextRun({
                                          text: `${parseFloat(unidad.unidades_produccion_unidades_tanques_transicion) === 0 ? 0 : parseFloat(unidad.unidades_produccion_unidades_tanques_transicion).toFixed(2)} `,
                                          font: 'Arial',
                                          size: 22
                                      }),
                                      new TextRun({
                                          text: `${parseFloat(unidad.unidades_produccion_unidades_tanques_convencional) !== 0 ? '■' : '❏'} Convencional: `,
                                          font: 'Arial',
                                          size: 22,
                                          bold: true
                                      }),
                                      new TextRun({
                                          text: `${parseFloat(unidad.unidades_produccion_unidades_tanques_convencional) === 0 ? 0 : parseFloat(unidad.unidades_produccion_unidades_tanques_convencional).toFixed(2)}`,
                                          font: 'Arial',
                                          size: 22
                                      })
                                  ],
                                  spacing: { after: 100 }
                              }),
                              new Paragraph({
                                  alignment: AlignmentType.LEFT,
                                  children: [
                                      new TextRun({
                                          text: `Animales acuícolas: ${parseFloat(unidad.unidades_produccion_superficie_total) !== 0 ? '■' : '❏'} Total: `,
                                          font: 'Arial',
                                          size: 22,
                                          bold: true
                                      }),
                                      new TextRun({
                                          text: `${parseFloat(unidad.unidades_produccion_superficie_total) === 0 ? 0 : parseFloat(unidad.unidades_produccion_superficie_total).toFixed(2)} `,
                                          font: 'Arial',
                                          size: 22
                                      }),
                                      new TextRun({
                                          text: `${parseFloat(unidad.unidades_produccion_superficie_organica) !== 0 ? '■' : '❏'} Orgánica: `,
                                          font: 'Arial',
                                          size: 22,
                                          bold: true
                                      }),
                                      new TextRun({
                                          text: `${parseFloat(unidad.unidades_produccion_superficie_organica) === 0 ? 0 : parseFloat(unidad.unidades_produccion_superficie_organica).toFixed(2)} `,
                                          font: 'Arial',
                                          size: 22
                                      }),
                                      new TextRun({
                                          text: `${parseFloat(unidad.unidades_produccion_superficie_transicion) !== 0 ? '■' : '❏'} En transición: `,
                                          font: 'Arial',
                                          size: 22,
                                          bold: true
                                      }),
                                      new TextRun({
                                          text: `${parseFloat(unidad.unidades_produccion_superficie_transicion) === 0 ? 0 : parseFloat(unidad.unidades_produccion_superficie_transicion).toFixed(2)} `,
                                          font: 'Arial',
                                          size: 22
                                      }),
                                      new TextRun({
                                          text: `${parseFloat(unidad.unidades_produccion_superficie_convencional) !== 0 ? '■' : '❏'} Convencional: `,
                                          font: 'Arial',
                                          size: 22,
                                          bold: true
                                      }),
                                      new TextRun({
                                          text: `${parseFloat(unidad.unidades_produccion_superficie_convencional) === 0 ? 0 : parseFloat(unidad.unidades_produccion_superficie_convencional).toFixed(2)}`,
                                          font: 'Arial',
                                          size: 22
                                      })
                                  ],
                                  spacing: { after: 100 }
                              })
                          ],
                          columnSpan: 6
                      })
                  ]
              })
          )
        })

        const start = new Date(`2020-01-01 ${this.getValue(data.submission, 'duracion_inspeccion_hora_inicio')}`)
        const end = new Date(`2020-01-01 ${this.getValue(data.submission, 'duracion_inspeccion_hora_fin')}`)

        const duration = end.getHours() - start.getHours()

        table.addChildElement(
            new TableRow({
                children: [
                    new TableCell({
                        children: [
                            new Paragraph({
                                alignment: AlignmentType.LEFT,
                                children: [
                                    new TextRun({
                                        text: `Duración de la inspección:`,
                                        font: 'Arial',
                                        size: 22,
                                        bold: true
                                    })
                                ],
                                spacing: { before: 100 }
                            })
                        ],
                        columnSpan: 1
                    }),
                    new TableCell({
                        children: [
                            new Paragraph({
                                alignment: AlignmentType.LEFT,
                                children: [
                                    new TextRun({
                                        text: `Hora de inicio`,
                                        font: 'Arial',
                                        size: 22,
                                        bold: true
                                    })
                                ],
                                spacing: { before: 100 }
                            })
                        ],
                        columnSpan: 1
                    }),
                    new TableCell({
                        children: [
                            new Paragraph({
                                alignment: AlignmentType.LEFT,
                                children: [
                                    new TextRun({
                                        text: this.getValue(data.submission, 'duracion_inspeccion_hora_inicio'),
                                        font: 'Arial',
                                        size: 22
                                    })
                                ],
                                spacing: { before: 100 }
                            })
                        ],
                        columnSpan: 1
                    }),
                    new TableCell({
                        children: [
                            new Paragraph({
                                alignment: AlignmentType.LEFT,
                                children: [
                                    new TextRun({
                                        text: `Hora de Terminación`,
                                        font: 'Arial',
                                        size: 22,
                                        bold: true
                                    })
                                ],
                                spacing: { before: 100 }
                            })
                        ],
                        columnSpan: 1
                    }),
                    new TableCell({
                        children: [
                            new Paragraph({
                                alignment: AlignmentType.LEFT,
                                children: [
                                    new TextRun({
                                        text: this.getValue(data.submission, 'duracion_inspeccion_hora_fin'),
                                        font: 'Arial',
                                        size: 22
                                    })
                                ],
                                spacing: { before: 100 }
                            })
                        ],
                        columnSpan: 1
                    }),
                    new TableCell({
                        children: [
                            new Paragraph({
                                alignment: AlignmentType.LEFT,
                                children: [
                                    new TextRun({
                                        text: `Duración:`,
                                        font: 'Arial',
                                        size: 22,
                                        bold: true
                                    })
                                ],
                                spacing: { before: 100 }
                            })
                        ],
                        columnSpan: 1
                    }),
                    new TableCell({
                        children: [
                            new Paragraph({
                                alignment: AlignmentType.LEFT,
                                children: [
                                    new TextRun({
                                        text: `${duration} hrs.`,
                                        font: 'Arial',
                                        size: 22
                                    })
                                ],
                                spacing: { before: 100 }
                            })
                        ],
                        columnSpan: 1
                    })
                ]
            }),
        )

        const persons = this.getValueList(data.submission, 'personas_entrevistadas')
        persons.forEach( (person, index) => {
            table.addChildElement(
                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: `${index === 0 ? 'Personas entrevistadas:' : ''}`,
                                            font: 'Arial',
                                            size: 22,
                                            bold: true
                                        })
                                    ],
                                    spacing: { before: 100 }
                                })
                            ],
                            columnSpan: 1
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: `Nombre: `,
                                            font: 'Arial',
                                            size: 22,
                                            bold: true
                                        })
                                    ],
                                    spacing: { before: 100 }
                                }),
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: person.personas_entrevistadas_nombre,
                                            font: 'Arial',
                                            size: 22
                                        })
                                    ],
                                    spacing: { after: 100 }
                                })
                            ],
                            columnSpan: 3
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: `Cargo: `,
                                            font: 'Arial',
                                            size: 22,
                                            bold: true
                                        })
                                    ],
                                    spacing: { before: 100 }
                                }),
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: person.personas_entrevistadas_cargo,
                                            font: 'Arial',
                                            size: 22
                                        })
                                    ],
                                    spacing: { after: 100 }
                                })
                            ],
                            columnSpan: 3
                        })
                    ]
                })
            )
        })

        return table
    }

    public createRegistros(data) {
        const table = new Table({
            rows: [
                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: 'REGISTROS',
                                            font: 'Calibri (Body)',
                                            size: 28,
                                            bold: true
                                        })
                                    ]
                                })
                            ],
                            shading: {
                                fill: '#C0C0C0',
                                val: ShadingType.PERCENT_80
                            },
                            columnSpan: 10
                        })
                    ]
                }),

                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: '1',
                                            font: 'Arial',
                                            size: 20
                                        })
                                    ],
                                    spacing: {
                                        after: 100,
                                        before: 100
                                    }
                                })
                            ],
                            columnSpan: 1
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: '¿El Plan de Manejo Orgánico refleja la realidad de la unidad de producción?',
                                            font: 'Arial',
                                            size: 20
                                        })
                                    ],
                                    spacing: {
                                        after: 100,
                                        before: 100
                                    }
                                })
                            ],
                            columnSpan: 7
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: `${this.getValue(data.submission, "registros_1_conformidad") === 'C' ? '■' : '❏'} C ${this.getValue(data.submission, "registros_1_conformidad") === 'NC' ? '■' : '❏'} NC ${this.getValue(data.submission, "registros_1_conformidad") === 'NA' ? '■' : '❏'} NA`,
                                            font: 'Arial',
                                            size: 20
                                        })
                                    ],
                                    spacing: {
                                        after: 100,
                                        before: 100
                                    }
                                })
                            ],
                            columnSpan: 2
                        })
                    ]
                }),
                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text:`Anexos: ${this.getFileList(this.getValue(data.submission, "registros_1_adjuntos_url"))}`,
                                            font: 'Arial',
                                            size: 20
                                        })
                                    ],
                                    spacing: {
                                        after: 100,
                                        before: 100
                                    }
                                })
                            ],
                            columnSpan: 10
                        }),
                    ]
                }),
                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: 'Explique: ' + this.getValue(data.submission, "registros_1_pregunta"),
                                            font: 'Arial',
                                            size: 20
                                        })
                                    ],
                                    spacing: {
                                        after: 100,
                                        before: 100
                                    }
                                })
                            ],
                            columnSpan: 10
                        }),
                    ]
                }),

                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: '2',
                                            font: 'Arial',
                                            size: 20
                                        })
                                    ],
                                    spacing: {
                                        after: 100,
                                        before: 100
                                    }
                                })
                            ],
                            columnSpan: 1
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: '¿El operador cuenta con registros (bitácoras) que reflejan las actividades desarrolladas regularmente en las unidades de producción?',
                                            font: 'Arial',
                                            size: 20
                                        })
                                    ],
                                    spacing: {
                                        after: 100,
                                        before: 100
                                    }
                                })
                            ],
                            columnSpan: 7
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: `${this.getValue(data.submission, "registros_2_conformidad") === 'C' ? '■' : '❏'} C ${this.getValue(data.submission, "registros_2_conformidad") === 'NC' ? '■' : '❏'} NC ${this.getValue(data.submission, "registros_2_conformidad") === 'NA' ? '■' : '❏'} NA`,
                                            font: 'Arial',
                                            size: 20
                                        })
                                    ],
                                    spacing: {
                                        after: 100,
                                        before: 100
                                    }
                                })
                            ],
                            columnSpan: 2
                        })
                    ]
                }),
                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text:`Anexos: ${this.getFileList(this.getValue(data.submission, "registros_2_adjuntos_url"))}`,
                                            font: 'Arial',
                                            size: 20
                                        })
                                    ],
                                    spacing: {
                                        after: 100,
                                        before: 100
                                    }
                                })
                            ],
                            columnSpan: 10
                        }),
                    ]
                }),
                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: 'Explique: ' + this.getValue(data.submission, "registros_2_pregunta"),
                                            font: 'Arial',
                                            size: 20
                                        })
                                    ],
                                    spacing: {
                                        after: 100,
                                        before: 100
                                    }
                                })
                            ],
                            columnSpan: 10
                        }),
                    ]
                }),

                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: '3',
                                            font: 'Arial',
                                            size: 20
                                        })
                                    ],
                                    spacing: {
                                        after: 100,
                                        before: 100
                                    }
                                })
                            ],
                            columnSpan: 1
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: '¿Los registros (bitácoras) revisados, reflejan el uso de prácticas y sustancias permitidas?',
                                            font: 'Arial',
                                            size: 20
                                        })
                                    ],
                                    spacing: {
                                        after: 100,
                                        before: 100
                                    }
                                })
                            ],
                            columnSpan: 7
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: `${this.getValue(data.submission, "registros_3_conformidad") === 'C' ? '■' : '❏'} C ${this.getValue(data.submission, "registros_3_conformidad") === 'NC' ? '■' : '❏'} NC ${this.getValue(data.submission, "registros_3_conformidad") === 'NA' ? '■' : '❏'} NA`,
                                            font: 'Arial',
                                            size: 20
                                        })
                                    ],
                                    spacing: {
                                        after: 100,
                                        before: 100
                                    }
                                })
                            ],
                            columnSpan: 2
                        })
                    ]
                }),
                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text:`Anexos: ${this.getFileList(this.getValue(data.submission, "registros_3_adjuntos_url"))}`,
                                            font: 'Arial',
                                            size: 20
                                        })
                                    ],
                                    spacing: {
                                        after: 100,
                                        before: 100
                                    }
                                })
                            ],
                            columnSpan: 10
                        }),
                    ]
                }),
                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: 'Explique: ' + this.getValue(data.submission, "registros_3_pregunta"),
                                            font: 'Arial',
                                            size: 20
                                        })
                                    ],
                                    spacing: {
                                        after: 100,
                                        before: 100
                                    }
                                })
                            ],
                            columnSpan: 10
                        }),
                    ]
                }),

                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: '4',
                                            font: 'Arial',
                                            size: 20
                                        })
                                    ],
                                    spacing: {
                                        after: 100,
                                        before: 100
                                    }
                                })
                            ],
                            columnSpan: 1
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: '¿Las etiquetas, uso de logos y referencias a la calidad orgánica del producto son conformes?',
                                            font: 'Arial',
                                            size: 20
                                        })
                                    ],
                                    spacing: {
                                        after: 100,
                                        before: 100
                                    }
                                })
                            ],
                            columnSpan: 7
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: `${this.getValue(data.submission, "registros_4_conformidad") === 'C' ? '■' : '❏'} C ${this.getValue(data.submission, "registros_4_conformidad") === 'NC' ? '■' : '❏'} NC ${this.getValue(data.submission, "registros_4_conformidad") === 'NA' ? '■' : '❏'} NA`,
                                            font: 'Arial',
                                            size: 20
                                        })
                                    ],
                                    spacing: {
                                        after: 100,
                                        before: 100
                                    }
                                })
                            ],
                            columnSpan: 2
                        })
                    ]
                }),
                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text:`Anexos: ${this.getFileList(this.getValue(data.submission, "registros_4_adjuntos_url"))}`,
                                            font: 'Arial',
                                            size: 20
                                        })
                                    ],
                                    spacing: {
                                        after: 100,
                                        before: 100
                                    }
                                })
                            ],
                            columnSpan: 10
                        }),
                    ]
                }),
                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: 'Explique: ' + this.getValue(data.submission, "registros_4_pregunta"),
                                            font: 'Arial',
                                            size: 20
                                        })
                                    ],
                                    spacing: {
                                        after: 100,
                                        before: 100
                                    }
                                })
                            ],
                            columnSpan: 10
                        }),
                    ]
                }),

                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: '5',
                                            font: 'Arial',
                                            size: 20
                                        })
                                    ],
                                    spacing: {
                                        after: 100,
                                        before: 100
                                    }
                                })
                            ],
                            columnSpan: 1
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: '¿El operador tiene un registro completo de quejas y atención a las quejas?',
                                            font: 'Arial',
                                            size: 20
                                        })
                                    ],
                                    spacing: {
                                        after: 100,
                                        before: 100
                                    }
                                })
                            ],
                            columnSpan: 7
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: `${this.getValue(data.submission, "registros_5_conformidad") === 'C' ? '■' : '❏'} C ${this.getValue(data.submission, "registros_5_conformidad") === 'NC' ? '■' : '❏'} NC ${this.getValue(data.submission, "registros_5_conformidad") === 'NA' ? '■' : '❏'} NA`,
                                            font: 'Arial',
                                            size: 20
                                        })
                                    ],
                                    spacing: {
                                        after: 100,
                                        before: 100
                                    }
                                })
                            ],
                            columnSpan: 2
                        })
                    ]
                }),
                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text:`Anexos: ${this.getFileList(this.getValue(data.submission, "registros_5_adjuntos_url"))}`,
                                            font: 'Arial',
                                            size: 20
                                        })
                                    ],
                                    spacing: {
                                        after: 100,
                                        before: 100
                                    }
                                })
                            ],
                            columnSpan: 10
                        }),
                    ]
                }),
                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: 'Explique: ' + this.getValue(data.submission, "registros_5_pregunta"),
                                            font: 'Arial',
                                            size: 20
                                        })
                                    ],
                                    spacing: {
                                        after: 100,
                                        before: 100
                                    }
                                })
                            ],
                            columnSpan: 10
                        }),
                    ]
                }),

                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: '6',
                                            font: 'Arial',
                                            size: 20
                                        })
                                    ],
                                    spacing: {
                                        after: 100,
                                        before: 100
                                    }
                                })
                            ],
                            columnSpan: 1
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: '¿Los registros son conservados por al menos 5 años?',
                                            font: 'Arial',
                                            size: 20
                                        })
                                    ],
                                    spacing: {
                                        after: 100,
                                        before: 100
                                    }
                                })
                            ],
                            columnSpan: 7
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: `${this.getValue(data.submission, "registros_6_conformidad") === 'C' ? '■' : '❏'} C ${this.getValue(data.submission, "registros_6_conformidad") === 'NC' ? '■' : '❏'} NC ${this.getValue(data.submission, "registros_6_conformidad") === 'NA' ? '■' : '❏'} NA`,
                                            font: 'Arial',
                                            size: 20
                                        })
                                    ],
                                    spacing: {
                                        after: 100,
                                        before: 100
                                    }
                                })
                            ],
                            columnSpan: 2
                        })
                    ]
                }),
                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text:`Anexos: ${this.getFileList(this.getValue(data.submission, "registros_6_adjuntos_url"))}`,
                                            font: 'Arial',
                                            size: 20
                                        })
                                    ],
                                    spacing: {
                                        after: 100,
                                        before: 100
                                    }
                                })
                            ],
                            columnSpan: 10
                        }),
                    ]
                }),
                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: 'Explique: ' + this.getValue(data.submission, "registros_6_pregunta"),
                                            font: 'Arial',
                                            size: 20
                                        })
                                    ],
                                    spacing: {
                                        after: 100,
                                        before: 100
                                    }
                                })
                            ],
                            columnSpan: 10
                        }),
                    ]
                }),

                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: '7',
                                            font: 'Arial',
                                            size: 20
                                        })
                                    ],
                                    spacing: {
                                        after: 100,
                                        before: 100
                                    }
                                })
                            ],
                            columnSpan: 1
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: '¿Se dio seguimiento a las No Conformidades detectadas en la evaluación anterior?',
                                            font: 'Arial',
                                            size: 20
                                        })
                                    ],
                                    spacing: {
                                        after: 100,
                                        before: 100
                                    }
                                })
                            ],
                            columnSpan: 7
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: `${this.getValue(data.submission, "registros_7_conformidad") === 'C' ? '■' : '❏'} C ${this.getValue(data.submission, "registros_7_conformidad") === 'NC' ? '■' : '❏'} NC ${this.getValue(data.submission, "registros_7_conformidad") === 'NA' ? '■' : '❏'} NA`,
                                            font: 'Arial',
                                            size: 20
                                        })
                                    ],
                                    spacing: {
                                        after: 100,
                                        before: 100
                                    }
                                })
                            ],
                            columnSpan: 2
                        })
                    ]
                }),
                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text:`Anexos: ${this.getFileList(this.getValue(data.submission, "registros_7_adjuntos_url"))}`,
                                            font: 'Arial',
                                            size: 20
                                        })
                                    ],
                                    spacing: {
                                        after: 100,
                                        before: 100
                                    }
                                })
                            ],
                            columnSpan: 10
                        }),
                    ]
                }),
              new TableRow({
                children: [
                  new TableCell({
                    children: [
                      new Paragraph({
                        alignment: AlignmentType.LEFT,
                        children: [
                          new TextRun({
                            text: 'Explique: ' + this.getValue(data.submission, "registros_7_pregunta"),
                            font: 'Arial',
                            size: 20
                          })
                        ],
                        spacing: {
                          after: 100,
                          before: 100
                        }
                      })
                    ],
                    columnSpan: 10
                  }),
                ]
              })
            ],
            margins: {
                left: 70,
                right: 70
            },
            width: {
                size: 100,
                type: WidthType.PERCENTAGE
            },
            columnWidths: [500,1359,1359,1359,1359,1359,1359,1359,1200,1200]
        })

        let tableRowConformidades = new TableRow({
            children: []
        })

        let tableCellConformidades = new TableCell({
            children: []
        })

        let paragraphConformidades = new Paragraph({
            alignment: AlignmentType.LEFT,
            children: [
                new TextRun({
                    text: '',
                    font: 'Arial',
                    size: 20
                })
            ]
        })

        let table_conformidades = new Table({
            rows: [
                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: 'No Conformidad',
                                            font: 'Arial',
                                            size: 20,
                                            bold: true
                                        })
                                    ]
                                })
                            ],
                            shading: {
                                fill: '#C0C0C0',
                                val: ShadingType.PERCENT_80
                            },
                            columnSpan: 4
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: 'Acción correctiva propuesta',
                                            font: 'Arial',
                                            size: 20,
                                            bold: true
                                        })
                                    ]
                                })
                            ],
                            shading: {
                                fill: '#C0C0C0',
                                val: ShadingType.PERCENT_80
                            },
                            columnSpan: 4
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: 'Situación encontrada',
                                            font: 'Arial',
                                            size: 20,
                                            bold: true
                                        })
                                    ]
                                })
                            ],
                            shading: {
                                fill: '#C0C0C0',
                                val: ShadingType.PERCENT_80
                            },
                            columnSpan: 4
                        }),
                    ]
                })
            ],
            margins: {
                left: 70,
                right: 70
            },
            width: {
                size: 100,
                type: WidthType.PERCENTAGE
            },
            columnWidths: [500,1359,1359,1359,1359,1359,1359,1359,1200,1200]
        })

        let listaConformidades = this.getValueList(data.submission, "registros_no_conformidades")
        listaConformidades.forEach(conformidad => {
            table_conformidades.addChildElement(
                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: conformidad.registros_no_conformidades_nombre,
                                            font: 'Arial',
                                            size: 20
                                        })
                                    ],
                                    spacing: {
                                        after: 100,
                                        before: 100
                                    }
                                })
                            ],
                            columnSpan: 4
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: conformidad.registros_no_conformidades_acciones_correctiva,
                                            font: 'Arial',
                                            size: 20
                                        })
                                    ],
                                    spacing: {
                                        after: 100,
                                        before: 100
                                    }
                                })
                            ],
                            columnSpan: 4
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: conformidad.registros_no_conformidades_situacion_encontrada,
                                            font: 'Arial',
                                            size: 20
                                        })
                                    ],
                                    spacing: {
                                        after: 100,
                                        before: 100
                                    }
                                })
                            ],
                            columnSpan: 4
                        }),
                    ]
                })
            )
        });

        tableCellConformidades.addChildElement(paragraphConformidades)
        tableCellConformidades.addChildElement(table_conformidades)
        tableCellConformidades.addChildElement(paragraphConformidades)
        tableRowConformidades.addChildElement(tableCellConformidades)
        table.addChildElement(tableRowConformidades)

        return table
    }

  public createInstalaciones(data) {
    const table = new Table({
      rows: [
        new TableRow({
          children: [
            new TableCell({
              children: [
                new Paragraph({
                  alignment: AlignmentType.CENTER,
                  children: [
                    new TextRun({
                      text: 'INSTALACIONES',
                      font: 'Calibri (Body)',
                      size: 28,
                      bold: true
                    })
                  ]
                })
              ],
              shading: {
                fill: '#C0C0C0',
                val: ShadingType.PERCENT_80
              },
              columnSpan: 10
            })
          ]
        }),

        new TableRow({
          children: [
            new TableCell({
              children: [
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                    new TextRun({
                      text: '1',
                      font: 'Arial',
                      size: 20
                    })
                  ],
                  spacing: {
                    after: 100,
                    before: 100
                  }
                })
              ],
              columnSpan: 1
            }),
            new TableCell({
              children: [
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                    new TextRun({
                      text: '¿Las instalaciones no impactan negativamente en los ecosistemas circundantes?',
                      font: 'Arial',
                      size: 20
                    })
                  ],
                  spacing: {
                    after: 100,
                    before: 100
                  }
                })
              ],
              columnSpan: 7
            }),
            new TableCell({
              children: [
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                    new TextRun({
                      text: `${this.getValue(data.submission, "instalaciones_1_conformidad") === 'C' ? '■' : '❏'} C ${this.getValue(data.submission, "instalaciones_1_conformidad") === 'NC' ? '■' : '❏'} NC ${this.getValue(data.submission, "instalaciones_1_conformidad") === 'NA' ? '■' : '❏'} NA`,
                      font: 'Arial',
                      size: 20
                    })
                  ],
                  spacing: {
                    after: 100,
                    before: 100
                  }
                })
              ],
              columnSpan: 2
            })
          ]
        }),
        new TableRow({
          children: [
            new TableCell({
              children: [
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                    new TextRun({
                      text:`Anexos: ${this.getFileList(this.getValue(data.submission, "instalaciones_1_adjuntos_url"))}`,
                      font: 'Arial',
                      size: 20
                    })
                  ],
                  spacing: {
                    after: 100,
                    before: 100
                  }
                })
              ],
              columnSpan: 10
            }),
          ]
        }),
        new TableRow({
          children: [
            new TableCell({
              children: [
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                    new TextRun({
                      text: 'Explique: ' + this.getValue(data.submission, "instalaciones_1_pregunta"),
                      font: 'Arial',
                      size: 20
                    })
                  ],
                  spacing: {
                    after: 100,
                    before: 100
                  }
                })
              ],
              columnSpan: 10
            }),
          ]
        }),

        new TableRow({
          children: [
            new TableCell({
              children: [
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                    new TextRun({
                      text: '2',
                      font: 'Arial',
                      size: 20
                    })
                  ],
                  spacing: {
                    after: 100,
                    before: 100
                  }
                })
              ],
              columnSpan: 1
            }),
            new TableCell({
              children: [
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                    new TextRun({
                      text: '¿Con el diseño del sistema y las instalaciones se reducen o eliminan problemas sanitarios y de inocuidad?',
                      font: 'Arial',
                      size: 20
                    })
                  ],
                  spacing: {
                    after: 100,
                    before: 100
                  }
                })
              ],
              columnSpan: 7
            }),
            new TableCell({
              children: [
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                    new TextRun({
                      text: `${this.getValue(data.submission, "instalaciones_2_conformidad") === 'C' ? '■' : '❏'} C ${this.getValue(data.submission, "instalaciones_2_conformidad") === 'NC' ? '■' : '❏'} NC ${this.getValue(data.submission, "instalaciones_2_conformidad") === 'NA' ? '■' : '❏'} NA`,
                      font: 'Arial',
                      size: 20
                    })
                  ],
                  spacing: {
                    after: 100,
                    before: 100
                  }
                })
              ],
              columnSpan: 2
            })
          ]
        }),
        new TableRow({
          children: [
            new TableCell({
              children: [
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                    new TextRun({
                      text:`Anexos: ${this.getFileList(this.getValue(data.submission, "instalaciones_2_adjuntos_url"))}`,
                      font: 'Arial',
                      size: 20
                    })
                  ],
                  spacing: {
                    after: 100,
                    before: 100
                  }
                })
              ],
              columnSpan: 10
            }),
          ]
        }),
        new TableRow({
          children: [
            new TableCell({
              children: [
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                    new TextRun({
                      text: 'Explique: ' + this.getValue(data.submission, "instalaciones_2_pregunta"),
                      font: 'Arial',
                      size: 20
                    })
                  ],
                  spacing: {
                    after: 100,
                    before: 100
                  }
                })
              ],
              columnSpan: 10
            }),
          ]
        }),

        new TableRow({
          children: [
            new TableCell({
              children: [
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                    new TextRun({
                      text: '3',
                      font: 'Arial',
                      size: 20
                    })
                  ],
                  spacing: {
                    after: 100,
                    before: 100
                  }
                })
              ],
              columnSpan: 1
            }),
            new TableCell({
              children: [
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                    new TextRun({
                      text: '¿Están claramente separadas las unidades de producción orgánica y convencional?',
                      font: 'Arial',
                      size: 20
                    })
                  ],
                  spacing: {
                    after: 100,
                    before: 100
                  }
                })
              ],
              columnSpan: 7
            }),
            new TableCell({
              children: [
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                    new TextRun({
                      text: `${this.getValue(data.submission, "instalaciones_3_conformidad") === 'C' ? '■' : '❏'} C ${this.getValue(data.submission, "instalaciones_3_conformidad") === 'NC' ? '■' : '❏'} NC ${this.getValue(data.submission, "instalaciones_3_conformidad") === 'NA' ? '■' : '❏'} NA`,
                      font: 'Arial',
                      size: 20
                    })
                  ],
                  spacing: {
                    after: 100,
                    before: 100
                  }
                })
              ],
              columnSpan: 2
            })
          ]
        }),
        new TableRow({
          children: [
            new TableCell({
              children: [
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                    new TextRun({
                      text:`Anexos: ${this.getFileList(this.getValue(data.submission, "instalaciones_3_adjuntos_url"))}`,
                      font: 'Arial',
                      size: 20
                    })
                  ],
                  spacing: {
                    after: 100,
                    before: 100
                  }
                })
              ],
              columnSpan: 10
            }),
          ]
        }),
        new TableRow({
          children: [
            new TableCell({
              children: [
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                    new TextRun({
                      text: 'Explique: ' + this.getValue(data.submission, "instalaciones_3_pregunta"),
                      font: 'Arial',
                      size: 20
                    })
                  ],
                  spacing: {
                    after: 100,
                    before: 100
                  }
                })
              ],
              columnSpan: 10
            }),
          ]
        }),

        new TableRow({
          children: [
            new TableCell({
              children: [
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                    new TextRun({
                      text: '4',
                      font: 'Arial',
                      size: 20
                    })
                  ],
                  spacing: {
                    after: 100,
                    before: 100
                  }
                })
              ],
              columnSpan: 1
            }),
            new TableCell({
              children: [
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                    new TextRun({
                      text: '¿Se emplean métodos no perjudiciales para la protección contra depredadores?',
                      font: 'Arial',
                      size: 20
                    })
                  ],
                  spacing: {
                    after: 100,
                    before: 100
                  }
                })
              ],
              columnSpan: 7
            }),
            new TableCell({
              children: [
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                    new TextRun({
                      text: `${this.getValue(data.submission, "instalaciones_4_conformidad") === 'C' ? '■' : '❏'} C ${this.getValue(data.submission, "instalaciones_4_conformidad") === 'NC' ? '■' : '❏'} NC ${this.getValue(data.submission, "instalaciones_4_conformidad") === 'NA' ? '■' : '❏'} NA`,
                      font: 'Arial',
                      size: 20
                    })
                  ],
                  spacing: {
                    after: 100,
                    before: 100
                  }
                })
              ],
              columnSpan: 2
            })
          ]
        }),
        new TableRow({
          children: [
            new TableCell({
              children: [
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                    new TextRun({
                      text:`Anexos: ${this.getFileList(this.getValue(data.submission, "instalaciones_4_adjuntos_url"))}`,
                      font: 'Arial',
                      size: 20
                    })
                  ],
                  spacing: {
                    after: 100,
                    before: 100
                  }
                })
              ],
              columnSpan: 10
            }),
          ]
        }),
        new TableRow({
          children: [
            new TableCell({
              children: [
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                    new TextRun({
                      text: 'Explique: ' + this.getValue(data.submission, "instalaciones_4_pregunta"),
                      font: 'Arial',
                      size: 20
                    })
                  ],
                  spacing: {
                    after: 100,
                    before: 100
                  }
                })
              ],
              columnSpan: 10
            }),
          ]
        }),

        new TableRow({
          children: [
            new TableCell({
              children: [
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                    new TextRun({
                      text: '5',
                      font: 'Arial',
                      size: 20
                    })
                  ],
                  spacing: {
                    after: 100,
                    before: 100
                  }
                })
              ],
              columnSpan: 1
            }),
            new TableCell({
              children: [
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                    new TextRun({
                      text: '¿Se utiliza energia preferentemente renovable?',
                      font: 'Arial',
                      size: 20
                    })
                  ],
                  spacing: {
                    after: 100,
                    before: 100
                  }
                })
              ],
              columnSpan: 7
            }),
            new TableCell({
              children: [
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                    new TextRun({
                      text: `${this.getValue(data.submission, "instalaciones_5_conformidad") === 'C' ? '■' : '❏'} C ${this.getValue(data.submission, "instalaciones_5_conformidad") === 'NC' ? '■' : '❏'} NC ${this.getValue(data.submission, "instalaciones_5_conformidad") === 'NA' ? '■' : '❏'} NA`,
                      font: 'Arial',
                      size: 20
                    })
                  ],
                  spacing: {
                    after: 100,
                    before: 100
                  }
                })
              ],
              columnSpan: 2
            })
          ]
        }),
        new TableRow({
          children: [
            new TableCell({
              children: [
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                    new TextRun({
                      text:`Anexos: ${this.getFileList(this.getValue(data.submission, "instalaciones_5_adjuntos_url"))}`,
                      font: 'Arial',
                      size: 20
                    })
                  ],
                  spacing: {
                    after: 100,
                    before: 100
                  }
                })
              ],
              columnSpan: 10
            }),
          ]
        }),
        new TableRow({
          children: [
            new TableCell({
              children: [
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                    new TextRun({
                      text: 'Explique: ' + this.getValue(data.submission, "instalaciones_5_pregunta"),
                      font: 'Arial',
                      size: 20
                    })
                  ],
                  spacing: {
                    after: 100,
                    before: 100
                  }
                })
              ],
              columnSpan: 10
            }),
          ]
        }),

        new TableRow({
          children: [
            new TableCell({
              children: [
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                    new TextRun({
                      text: '6',
                      font: 'Arial',
                      size: 20
                    })
                  ],
                  spacing: {
                    after: 100,
                    before: 100
                  }
                })
              ],
              columnSpan: 1
            }),
            new TableCell({
              children: [
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                    new TextRun({
                      text: '¿El agua se reutiliza para el mismo sistema o para otros sistemas productivos?',
                      font: 'Arial',
                      size: 20
                    })
                  ],
                  spacing: {
                    after: 100,
                    before: 100
                  }
                })
              ],
              columnSpan: 7
            }),
            new TableCell({
              children: [
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                    new TextRun({
                      text: `${this.getValue(data.submission, "instalaciones_6_conformidad") === 'C' ? '■' : '❏'} C ${this.getValue(data.submission, "instalaciones_6_conformidad") === 'NC' ? '■' : '❏'} NC ${this.getValue(data.submission, "instalaciones_6_conformidad") === 'NA' ? '■' : '❏'} NA`,
                      font: 'Arial',
                      size: 20
                    })
                  ],
                  spacing: {
                    after: 100,
                    before: 100
                  }
                })
              ],
              columnSpan: 2
            })
          ]
        }),
        new TableRow({
          children: [
            new TableCell({
              children: [
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                    new TextRun({
                      text:`Anexos: ${this.getFileList(this.getValue(data.submission, "instalaciones_6_adjuntos_url"))}`,
                      font: 'Arial',
                      size: 20
                    })
                  ],
                  spacing: {
                    after: 100,
                    before: 100
                  }
                })
              ],
              columnSpan: 10
            }),
          ]
        }),
        new TableRow({
          children: [
            new TableCell({
              children: [
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                    new TextRun({
                      text: 'Explique: ' + this.getValue(data.submission, "instalaciones_6_pregunta"),
                      font: 'Arial',
                      size: 20
                    })
                  ],
                  spacing: {
                    after: 100,
                    before: 100
                  }
                })
              ],
              columnSpan: 10
            }),
          ]
        }),

        new TableRow({
          children: [
            new TableCell({
              children: [
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                    new TextRun({
                      text: '7',
                      font: 'Arial',
                      size: 20
                    })
                  ],
                  spacing: {
                    after: 100,
                    before: 100
                  }
                })
              ],
              columnSpan: 1
            }),
            new TableCell({
              children: [
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                    new TextRun({
                      text: '¿Se reducen al máximo los desechos?',
                      font: 'Arial',
                      size: 20
                    })
                  ],
                  spacing: {
                    after: 100,
                    before: 100
                  }
                })
              ],
              columnSpan: 7
            }),
            new TableCell({
              children: [
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                    new TextRun({
                      text: `${this.getValue(data.submission, "instalaciones_7_conformidad") === 'C' ? '■' : '❏'} C ${this.getValue(data.submission, "instalaciones_7_conformidad") === 'NC' ? '■' : '❏'} NC ${this.getValue(data.submission, "instalaciones_7_conformidad") === 'NA' ? '■' : '❏'} NA`,
                      font: 'Arial',
                      size: 20
                    })
                  ],
                  spacing: {
                    after: 100,
                    before: 100
                  }
                })
              ],
              columnSpan: 2
            })
          ]
        }),
        new TableRow({
          children: [
            new TableCell({
              children: [
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                    new TextRun({
                      text:`Anexos: ${this.getFileList(this.getValue(data.submission, "instalaciones_7_adjuntos_url"))}`,
                      font: 'Arial',
                      size: 20
                    })
                  ],
                  spacing: {
                    after: 100,
                    before: 100
                  }
                })
              ],
              columnSpan: 10
            }),
          ]
        }),
        new TableRow({
          children: [
            new TableCell({
              children: [
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                    new TextRun({
                      text: 'Explique: ' + this.getValue(data.submission, "instalaciones_7_pregunta"),
                      font: 'Arial',
                      size: 20
                    })
                  ],
                  spacing: {
                    after: 100,
                    before: 100
                  }
                })
              ],
              columnSpan: 10
            }),
          ]
        }),

        new TableRow({
          children: [
            new TableCell({
              children: [
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                    new TextRun({
                      text: '8',
                      font: 'Arial',
                      size: 20
                    })
                  ],
                  spacing: {
                    after: 100,
                    before: 100
                  }
                })
              ],
              columnSpan: 1
            }),
            new TableCell({
              children: [
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                    new TextRun({
                      text: '¿La instalación cumple con el periodo de conversión por tipo?',
                      font: 'Arial',
                      size: 20
                    })
                  ],
                  spacing: {
                    after: 50,
                    before: 100
                  }
                })
              ],
              columnSpan: 7
            }),
            new TableCell({
              children: [
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                    new TextRun({
                      text: `${this.getValue(data.submission, "instalaciones_8_conformidad") === 'C' ? '■' : '❏'} C ${this.getValue(data.submission, "instalaciones_8_conformidad") === 'NC' ? '■' : '❏'} NC ${this.getValue(data.submission, "instalaciones_8_conformidad") === 'NA' ? '■' : '❏'} NA`,
                      font: 'Arial',
                      size: 20
                    })
                  ],
                  spacing: {
                    after: 100,
                    before: 100
                  }
                })
              ],
              columnSpan: 2
            })
          ]
        }),
        new TableRow({
          children: [
            new TableCell({
              children: [
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                    new TextRun({
                      text:`Anexos: ${this.getFileList(this.getValue(data.submission, "instalaciones_8_adjuntos_url"))}`,
                      font: 'Arial',
                      size: 20
                    })
                  ],
                  spacing: {
                    after: 100,
                    before: 100
                  }
                })
              ],
              columnSpan: 10
            }),
          ]
        }),
        new TableRow({
          children: [
            new TableCell({
              children: [
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                    new TextRun({
                      text: 'Explique: ' + this.getValue(data.submission, "instalaciones_8_pregunta"),
                      font: 'Arial',
                      size: 20
                    })
                  ],
                  spacing: {
                    after: 100,
                    before: 100
                  }
                })
              ],
              columnSpan: 10
            }),
          ]
        }),

        new TableRow({
          children: [
            new TableCell({
              children: [
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                    new TextRun({
                      text: '9',
                      font: 'Arial',
                      size: 20
                    })
                  ],
                  spacing: {
                    after: 100,
                    before: 100
                  }
                })
              ],
              columnSpan: 1
            }),
            new TableCell({
              children: [
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                    new TextRun({
                      text: '¿Se reducen al máximo los desechos?',
                      font: 'Arial',
                      size: 20
                    })
                  ],
                  spacing: {
                    after: 50,
                    before: 100
                  }
                })
              ],
              columnSpan: 7
            }),
            new TableCell({
              children: [
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                    new TextRun({
                      text: `${this.getValue(data.submission, "instalaciones_9_conformidad") === 'C' ? '■' : '❏'} C ${this.getValue(data.submission, "instalaciones_9_conformidad") === 'NC' ? '■' : '❏'} NC ${this.getValue(data.submission, "instalaciones_9_conformidad") === 'NA' ? '■' : '❏'} NA`,
                      font: 'Arial',
                      size: 20
                    })
                  ],
                  spacing: {
                    after: 100,
                    before: 100
                  }
                })
              ],
              columnSpan: 2
            })
          ]
        }),
        new TableRow({
          children: [
            new TableCell({
              children: [
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                    new TextRun({
                      text:`Anexos: ${this.getFileList(this.getValue(data.submission, "instalaciones_9_adjuntos_url"))}`,
                      font: 'Arial',
                      size: 20
                    })
                  ],
                  spacing: {
                    after: 100,
                    before: 100
                  }
                })
              ],
              columnSpan: 10
            }),
          ]
        }),
        new TableRow({
          children: [
            new TableCell({
              children: [
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                    new TextRun({
                      text: 'Explique: ' + this.getValue(data.submission, "instalaciones_9_pregunta"),
                      font: 'Arial',
                      size: 20
                    })
                  ],
                  spacing: {
                    after: 100,
                    before: 100
                  }
                })
              ],
              columnSpan: 10
            }),
          ]
        })
      ],
      margins: {
        left: 70,
        right: 70
      },
      width: {
        size: 100,
        type: WidthType.PERCENTAGE
      },
      columnWidths: [500,1359,1359,1359,1359,1359,1359,1359,1200,1200]
    })

    return table
  }

  public createEspecies(data) {
    const table = new Table({
      rows: [
        new TableRow({
          children: [
            new TableCell({
              children: [
                new Paragraph({
                  alignment: AlignmentType.CENTER,
                  children: [
                    new TextRun({
                      text: 'ESPECIES Y VARIEDADES',
                      font: 'Calibri (Body)',
                      size: 28,
                      bold: true
                    })
                  ]
                })
              ],
              shading: {
                fill: '#C0C0C0',
                val: ShadingType.PERCENT_80
              },
              columnSpan: 10
            })
          ]
        }),

        new TableRow({
          children: [
            new TableCell({
              children: [
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                    new TextRun({
                      text: '1',
                      font: 'Arial',
                      size: 20
                    })
                  ],
                  spacing: {
                    after: 100,
                    before: 100
                  }
                })
              ],
              columnSpan: 1
            }),
            new TableCell({
              children: [
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                    new TextRun({
                      text: '¿Se utilizan especies nativas o adaptadas?',
                      font: 'Arial',
                      size: 20
                    })
                  ],
                  spacing: {
                    after: 100,
                    before: 100
                  }
                })
              ],
              columnSpan: 7
            }),
            new TableCell({
              children: [
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                    new TextRun({
                      text: `${this.getValue(data.submission, "especies_1_conformidad") === 'C' ? '■' : '❏'} C ${this.getValue(data.submission, "especies_1_conformidad") === 'NC' ? '■' : '❏'} NC ${this.getValue(data.submission, "especies_1_conformidad") === 'NA' ? '■' : '❏'} NA`,
                      font: 'Arial',
                      size: 20
                    })
                  ],
                  spacing: {
                    after: 100,
                    before: 100
                  }
                })
              ],
              columnSpan: 2
            })
          ]
        }),
        new TableRow({
          children: [
            new TableCell({
              children: [
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                    new TextRun({
                      text:`Anexos: ${this.getFileList(this.getValue(data.submission, "especies_1_adjuntos_url"))}`,
                      font: 'Arial',
                      size: 20
                    })
                  ],
                  spacing: {
                    after: 100,
                    before: 100
                  }
                })
              ],
              columnSpan: 10
            }),
          ]
        }),
        new TableRow({
          children: [
            new TableCell({
              children: [
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                    new TextRun({
                      text: 'Explique: ' + this.getValue(data.submission, "especies_1_pregunta"),
                      font: 'Arial',
                      size: 20
                    })
                  ],
                  spacing: {
                    after: 100,
                    before: 100
                  }
                })
              ],
              columnSpan: 10
            }),
          ]
        }),

        new TableRow({
          children: [
            new TableCell({
              children: [
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                    new TextRun({
                      text: '2',
                      font: 'Arial',
                      size: 20
                    })
                  ],
                  spacing: {
                    after: 100,
                    before: 100
                  }
                })
              ],
              columnSpan: 1
            }),
            new TableCell({
              children: [
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                    new TextRun({
                      text: 'En el caso de especies introducidas ¿Se tiene evidencia de que la especie no se esparce y pueda competir con especies nativas?',
                      font: 'Arial',
                      size: 20
                    })
                  ],
                  spacing: {
                    after: 100,
                    before: 100
                  }
                })
              ],
              columnSpan: 7
            }),
            new TableCell({
              children: [
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                    new TextRun({
                      text: `${this.getValue(data.submission, "especies_2_conformidad") === 'C' ? '■' : '❏'} C ${this.getValue(data.submission, "especies_2_conformidad") === 'NC' ? '■' : '❏'} NC ${this.getValue(data.submission, "especies_2_conformidad") === 'NA' ? '■' : '❏'} NA`,
                      font: 'Arial',
                      size: 20
                    })
                  ],
                  spacing: {
                    after: 100,
                    before: 100
                  }
                })
              ],
              columnSpan: 2
            })
          ]
        }),
        new TableRow({
          children: [
            new TableCell({
              children: [
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                    new TextRun({
                      text:`Anexos: ${this.getFileList(this.getValue(data.submission, "especies_2_adjuntos_url"))}`,
                      font: 'Arial',
                      size: 20
                    })
                  ],
                  spacing: {
                    after: 100,
                    before: 100
                  }
                })
              ],
              columnSpan: 10
            }),
          ]
        }),
        new TableRow({
          children: [
            new TableCell({
              children: [
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                    new TextRun({
                      text: 'Explique: ' + this.getValue(data.submission, "especies_2_pregunta"),
                      font: 'Arial',
                      size: 20
                    })
                  ],
                  spacing: {
                    after: 100,
                    before: 100
                  }
                })
              ],
              columnSpan: 10
            }),
          ]
        }),

        new TableRow({
          children: [
            new TableCell({
              children: [
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                    new TextRun({
                      text: '3',
                      font: 'Arial',
                      size: 20
                    })
                  ],
                  spacing: {
                    after: 100,
                    before: 100
                  }
                })
              ],
              columnSpan: 1
            }),
            new TableCell({
              children: [
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                    new TextRun({
                      text: '¿Se utilizan reproductores, larvas o alevines orgánicos?',
                      font: 'Arial',
                      size: 20
                    })
                  ],
                  spacing: {
                    after: 100,
                    before: 100
                  }
                })
              ],
              columnSpan: 7
            }),
            new TableCell({
              children: [
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                    new TextRun({
                      text: `${this.getValue(data.submission, "especies_3_conformidad") === 'C' ? '■' : '❏'} C ${this.getValue(data.submission, "especies_3_conformidad") === 'NC' ? '■' : '❏'} NC ${this.getValue(data.submission, "especies_3_conformidad") === 'NA' ? '■' : '❏'} NA`,
                      font: 'Arial',
                      size: 20
                    })
                  ],
                  spacing: {
                    after: 100,
                    before: 100
                  }
                })
              ],
              columnSpan: 2
            })
          ]
        }),
        new TableRow({
          children: [
            new TableCell({
              children: [
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                    new TextRun({
                      text:`Anexos: ${this.getFileList(this.getValue(data.submission, "especies_3_adjuntos_url"))}`,
                      font: 'Arial',
                      size: 20
                    })
                  ],
                  spacing: {
                    after: 100,
                    before: 100
                  }
                })
              ],
              columnSpan: 10
            }),
          ]
        }),
        new TableRow({
          children: [
            new TableCell({
              children: [
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                    new TextRun({
                      text: 'Explique: ' + this.getValue(data.submission, "especies_3_pregunta"),
                      font: 'Arial',
                      size: 20
                    })
                  ],
                  spacing: {
                    after: 100,
                    before: 100
                  }
                })
              ],
              columnSpan: 10
            }),
          ]
        }),

        new TableRow({
          children: [
            new TableCell({
              children: [
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                    new TextRun({
                      text: '4',
                      font: 'Arial',
                      size: 20
                    })
                  ],
                  spacing: {
                    after: 100,
                    before: 100
                  }
                })
              ],
              columnSpan: 1
            }),
            new TableCell({
              children: [
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                    new TextRun({
                      text: 'En caso de que los reproductores no sean orgánico, ¿Se manejaron de manera orgánica al menos 3 meses antes de que sus crias se consideren para desarollarse y comercializarse como orgánicas?',
                      font: 'Arial',
                      size: 20
                    })
                  ],
                  spacing: {
                    after: 100,
                    before: 100
                  }
                })
              ],
              columnSpan: 7
            }),
            new TableCell({
              children: [
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                    new TextRun({
                      text: `${this.getValue(data.submission, "especies_4_conformidad") === 'C' ? '■' : '❏'} C ${this.getValue(data.submission, "especies_4_conformidad") === 'NC' ? '■' : '❏'} NC ${this.getValue(data.submission, "especies_4_conformidad") === 'NA' ? '■' : '❏'} NA`,
                      font: 'Arial',
                      size: 20
                    })
                  ],
                  spacing: {
                    after: 100,
                    before: 100
                  }
                })
              ],
              columnSpan: 2
            })
          ]
        }),
        new TableRow({
          children: [
            new TableCell({
              children: [
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                    new TextRun({
                      text:`Anexos: ${this.getFileList(this.getValue(data.submission, "especies_4_adjuntos_url"))}`,
                      font: 'Arial',
                      size: 20
                    })
                  ],
                  spacing: {
                    after: 100,
                    before: 100
                  }
                })
              ],
              columnSpan: 10
            }),
          ]
        }),
        new TableRow({
          children: [
            new TableCell({
              children: [
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                    new TextRun({
                      text: 'Explique: ' + this.getValue(data.submission, "especies_4_pregunta"),
                      font: 'Arial',
                      size: 20
                    })
                  ],
                  spacing: {
                    after: 100,
                    before: 100
                  }
                })
              ],
              columnSpan: 10
            }),
          ]
        }),

        new TableRow({
          children: [
            new TableCell({
              children: [
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                    new TextRun({
                      text: '5',
                      font: 'Arial',
                      size: 20
                    })
                  ],
                  spacing: {
                    after: 100,
                    before: 100
                  }
                })
              ],
              columnSpan: 1
            }),
            new TableCell({
              children: [
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                    new TextRun({
                      text: 'Si se utilizan juveniles no orgánicos para la producción y comercialización ¿pasan al menos las dos terceras partes de su vida con manejo orgánico?',
                      font: 'Arial',
                      size: 20
                    })
                  ],
                  spacing: {
                    after: 100,
                    before: 100
                  }
                })
              ],
              columnSpan: 7
            }),
            new TableCell({
              children: [
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                    new TextRun({
                      text: `${this.getValue(data.submission, "especies_5_conformidad") === 'C' ? '■' : '❏'} C ${this.getValue(data.submission, "especies_5_conformidad") === 'NC' ? '■' : '❏'} NC ${this.getValue(data.submission, "especies_5_conformidad") === 'NA' ? '■' : '❏'} NA`,
                      font: 'Arial',
                      size: 20
                    })
                  ],
                  spacing: {
                    after: 100,
                    before: 100
                  }
                })
              ],
              columnSpan: 2
            })
          ]
        }),
        new TableRow({
          children: [
            new TableCell({
              children: [
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                    new TextRun({
                      text:`Anexos: ${this.getFileList(this.getValue(data.submission, "especies_5_adjuntos_url"))}`,
                      font: 'Arial',
                      size: 20
                    })
                  ],
                  spacing: {
                    after: 100,
                    before: 100
                  }
                })
              ],
              columnSpan: 10
            }),
          ]
        }),
        new TableRow({
          children: [
            new TableCell({
              children: [
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                    new TextRun({
                      text: 'Explique: ' + this.getValue(data.submission, "especies_5_pregunta"),
                      font: 'Arial',
                      size: 20
                    })
                  ],
                  spacing: {
                    after: 100,
                    before: 100
                  }
                })
              ],
              columnSpan: 10
            }),
          ]
        }),

        new TableRow({
          children: [
            new TableCell({
              children: [
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                    new TextRun({
                      text: '6',
                      font: 'Arial',
                      size: 20
                    })
                  ],
                  spacing: {
                    after: 100,
                    before: 100
                  }
                })
              ],
              columnSpan: 1
            }),
            new TableCell({
              children: [
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                    new TextRun({
                      text: '¿Se presentó evidencia de que los organismos no fueron reproducidos con tecnología OGM?',
                      font: 'Arial',
                      size: 20
                    })
                  ],
                  spacing: {
                    after: 100,
                    before: 100
                  }
                })
              ],
              columnSpan: 7
            }),
            new TableCell({
              children: [
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                    new TextRun({
                      text: `${this.getValue(data.submission, "especies_6_conformidad") === 'C' ? '■' : '❏'} C ${this.getValue(data.submission, "especies_6_conformidad") === 'NC' ? '■' : '❏'} NC ${this.getValue(data.submission, "especies_6_conformidad") === 'NA' ? '■' : '❏'} NA`,
                      font: 'Arial',
                      size: 20
                    })
                  ],
                  spacing: {
                    after: 100,
                    before: 100
                  }
                })
              ],
              columnSpan: 2
            })
          ]
        }),
        new TableRow({
          children: [
            new TableCell({
              children: [
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                    new TextRun({
                      text:`Anexos: ${this.getFileList(this.getValue(data.submission, "especies_6_adjuntos_url"))}`,
                      font: 'Arial',
                      size: 20
                    })
                  ],
                  spacing: {
                    after: 100,
                    before: 100
                  }
                })
              ],
              columnSpan: 10
            }),
          ]
        }),
        new TableRow({
          children: [
            new TableCell({
              children: [
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                    new TextRun({
                      text: 'Explique: ' + this.getValue(data.submission, "especies_6_pregunta"),
                      font: 'Arial',
                      size: 20
                    })
                  ],
                  spacing: {
                    after: 100,
                    before: 100
                  }
                })
              ],
              columnSpan: 10
            }),
          ]
        })
      ],
      margins: {
        left: 70,
        right: 70
      },
      width: {
        size: 100,
        type: WidthType.PERCENTAGE
      },
      columnWidths: [500,1359,1359,1359,1359,1359,1359,1359,1200,1200]
    })
    return table
  }

  public createReproduccion(data) {
    const table = new Table({
      rows: [
        new TableRow({
          children: [
            new TableCell({
              children: [
                new Paragraph({
                  alignment: AlignmentType.CENTER,
                  children: [
                    new TextRun({
                      text: 'REPRODUCCIÓN',
                      font: 'Calibri (Body)',
                      size: 28,
                      bold: true
                    })
                  ]
                })
              ],
              shading: {
                fill: '#C0C0C0',
                val: ShadingType.PERCENT_80
              },
              columnSpan: 10
            })
          ]
        }),

        new TableRow({
          children: [
            new TableCell({
              children: [
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                    new TextRun({
                      text: '1',
                      font: 'Arial',
                      size: 20
                    })
                  ],
                  spacing: {
                    after: 100,
                    before: 100
                  }
                })
              ],
              columnSpan: 1
            }),
            new TableCell({
              children: [
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                    new TextRun({
                      text: '¿Se utilizan métodos de reproducción naturales o permitidos?',
                      font: 'Arial',
                      size: 20
                    })
                  ],
                  spacing: {
                    after: 100,
                    before: 100
                  }
                })
              ],
              columnSpan: 7
            }),
            new TableCell({
              children: [
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                    new TextRun({
                      text: `${this.getValue(data.submission, "reproduccion_1_conformidad") === 'S' ? '■' : '❏'} S ${this.getValue(data.submission, "reproduccion_1_conformidad") === 'N' ? '■' : '❏'} N ${this.getValue(data.submission, "reproduccion_1_conformidad") === 'NA' ? '■' : '❏'} NA`,
                      font: 'Arial',
                      size: 20
                    })
                  ],
                  spacing: {
                    after: 100,
                    before: 100
                  }
                })
              ],
              columnSpan: 2
            })
          ]
        }),
        new TableRow({
          children: [
            new TableCell({
              children: [
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                    new TextRun({
                      text:`Anexos: ${this.getFileList(this.getValue(data.submission, "reproduccion_1_adjuntos_url"))}`,
                      font: 'Arial',
                      size: 20
                    })
                  ],
                  spacing: {
                    after: 100,
                    before: 100
                  }
                })
              ],
              columnSpan: 10
            }),
          ]
        }),
        new TableRow({
          children: [
            new TableCell({
              children: [
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                    new TextRun({
                      text: 'Explique: ' + this.getValue(data.submission, "reproduccion_1_pregunta"),
                      font: 'Arial',
                      size: 20
                    })
                  ],
                  spacing: {
                    after: 100,
                    before: 100
                  }
                })
              ],
              columnSpan: 10
            }),
          ]
        }),

        new TableRow({
          children: [
            new TableCell({
              children: [
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                    new TextRun({
                      text: '2',
                      font: 'Arial',
                      size: 20
                    })
                  ],
                  spacing: {
                    after: 100,
                    before: 100
                  }
                })
              ],
              columnSpan: 1
            }),
            new TableCell({
              children: [
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                    new TextRun({
                      text: '¿Para la cría de larvas y juveniles se mantiene el criterio de 1Kg por 8 litros de agua?',
                      font: 'Arial',
                      size: 20
                    })
                  ],
                  spacing: {
                    after: 100,
                    before: 100
                  }
                })
              ],
              columnSpan: 7
            }),
            new TableCell({
              children: [
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                    new TextRun({
                      text: `${this.getValue(data.submission, "reproduccion_2_conformidad") === 'S' ? '■' : '❏'} S ${this.getValue(data.submission, "reproduccion_2_conformidad") === 'N' ? '■' : '❏'} N ${this.getValue(data.submission, "reproduccion_2_conformidad") === 'NA' ? '■' : '❏'} NA`,
                      font: 'Arial',
                      size: 20
                    })
                  ],
                  spacing: {
                    after: 100,
                    before: 100
                  }
                })
              ],
              columnSpan: 2
            })
          ]
        }),
        new TableRow({
          children: [
            new TableCell({
              children: [
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                    new TextRun({
                      text:`Anexos: ${this.getFileList(this.getValue(data.submission, "reproduccion_2_adjuntos_url"))}`,
                      font: 'Arial',
                      size: 20
                    })
                  ],
                  spacing: {
                    after: 100,
                    before: 100
                  }
                })
              ],
              columnSpan: 10
            }),
          ]
        }),
        new TableRow({
          children: [
            new TableCell({
              children: [
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                    new TextRun({
                      text: 'Explique: ' + this.getValue(data.submission, "reproduccion_2_pregunta"),
                      font: 'Arial',
                      size: 20
                    })
                  ],
                  spacing: {
                    after: 100,
                    before: 100
                  }
                })
              ],
              columnSpan: 10
            }),
          ]
        }),

        new TableRow({
          children: [
            new TableCell({
              children: [
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                    new TextRun({
                      text: '3',
                      font: 'Arial',
                      size: 20
                    })
                  ],
                  spacing: {
                    after: 100,
                    before: 100
                  }
                })
              ],
              columnSpan: 1
            }),
            new TableCell({
              children: [
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                    new TextRun({
                      text: '¿Se utiliza ozono o luz ultravioleta solo para el cultivo de alevines y juveniles?',
                      font: 'Arial',
                      size: 20
                    })
                  ],
                  spacing: {
                    after: 100,
                    before: 100
                  }
                })
              ],
              columnSpan: 7
            }),
            new TableCell({
              children: [
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                    new TextRun({
                      text: `${this.getValue(data.submission, "reproduccion_3_conformidad") === 'S' ? '■' : '❏'} S ${this.getValue(data.submission, "reproduccion_3_conformidad") === 'N' ? '■' : '❏'} N ${this.getValue(data.submission, "reproduccion_3_conformidad") === 'NA' ? '■' : '❏'} NA`,
                      font: 'Arial',
                      size: 20
                    })
                  ],
                  spacing: {
                    after: 100,
                    before: 100
                  }
                })
              ],
              columnSpan: 2
            })
          ]
        }),
        new TableRow({
          children: [
            new TableCell({
              children: [
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                    new TextRun({
                      text:`Anexos: ${this.getFileList(this.getValue(data.submission, "reproduccion_3_adjuntos_url"))}`,
                      font: 'Arial',
                      size: 20
                    })
                  ],
                  spacing: {
                    after: 100,
                    before: 100
                  }
                })
              ],
              columnSpan: 10
            }),
          ]
        }),
        new TableRow({
          children: [
            new TableCell({
              children: [
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                    new TextRun({
                      text: 'Explique: ' + this.getValue(data.submission, "reproduccion_3_pregunta"),
                      font: 'Arial',
                      size: 20
                    })
                  ],
                  spacing: {
                    after: 100,
                    before: 100
                  }
                })
              ],
              columnSpan: 10
            }),
          ]
        }),

        new TableRow({
          children: [
            new TableCell({
              children: [
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                    new TextRun({
                      text: '4',
                      font: 'Arial',
                      size: 20
                    })
                  ],
                  spacing: {
                    after: 100,
                    before: 100
                  }
                })
              ],
              columnSpan: 1
            }),
            new TableCell({
              children: [
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                    new TextRun({
                      text: 'En el caso del uso de hormonas para Tilapia:',
                      font: 'Arial',
                      size: 20
                    })
                  ],
                  spacing: {
                    after: 100,
                    before: 100
                  }
                }),
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                    new TextRun({
                      text: `${this.getValue(data.submission, "reproduccion_4_pregunta_a") === 'Si' ? '■ Sí' : '❏ No'} a.- ¿La hormona usada es methyltestosterona?`,
                      font: 'Arial',
                      size: 20
                    })
                  ],
                  spacing: {
                    after: 100,
                    before: 100
                  }
                }),
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                    new TextRun({
                      text: `${this.getValue(data.submission, "reproduccion_4_pregunta_b") === 'Si' ? '■ Sí' : '❏ No'} b.- ¿Se tienen análisis para evidenciar que no quedan residuos de la hormona en el tejido antes de la venta?`,
                      font: 'Arial',
                      size: 20
                    })
                  ],
                  spacing: {
                    after: 100,
                    before: 100
                  }
                }),
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                    new TextRun({
                      text: `${this.getValue(data.submission, "reproduccion_4_pregunta_c") === 'Si' ? '■ Sí' : '❏ No'} c.- ¿La dósis, vía de administración y frecuencia es conforme a la norma correspondiente?`,
                      font: 'Arial',
                      size: 20
                    })
                  ],
                  spacing: {
                    after: 100,
                    before: 100
                  }
                }),
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                    new TextRun({
                      text: `${this.getValue(data.submission, "reproduccion_4_pregunta_d") === 'Si' ? '■ Sí' : '❏ No'} d.- ¿Se toman medidas de prevención para el personal y para el ambiente?`,
                      font: 'Arial',
                      size: 20
                    })
                  ],
                  spacing: {
                    after: 100,
                    before: 100
                  }
                }),
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                    new TextRun({
                      text: `${this.getValue(data.submission, "reproduccion_4_pregunta_e") === 'Si' ? '■ Sí' : '❏ No'} e.- ¿Los residuos del tratamiento se retienen al menos 48 horas?`,
                      font: 'Arial',
                      size: 20
                    })
                  ],
                  spacing: {
                    after: 100,
                    before: 100
                  }
                })
              ],
              columnSpan: 7
            }),
            new TableCell({
              children: [
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                    new TextRun({
                      text: `${this.getValue(data.submission, "reproduccion_4_conformidad") === 'S' ? '■' : '❏'} S ${this.getValue(data.submission, "reproduccion_4_conformidad") === 'N' ? '■' : '❏'} N ${this.getValue(data.submission, "reproduccion_4_conformidad") === 'NA' ? '■' : '❏'} NA`,
                      font: 'Arial',
                      size: 20
                    })
                  ],
                  spacing: {
                    after: 100,
                    before: 100
                  }
                })
              ],
              columnSpan: 2
            })
          ]
        }),
        new TableRow({
          children: [
            new TableCell({
              children: [
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                    new TextRun({
                      text:`Anexos: ${this.getFileList(this.getValue(data.submission, "reproduccion_4_adjuntos_url"))}`,
                      font: 'Arial',
                      size: 20
                    })
                  ],
                  spacing: {
                    after: 100,
                    before: 100
                  }
                })
              ],
              columnSpan: 10
            }),
          ]
        }),
        new TableRow({
          children: [
            new TableCell({
              children: [
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                    new TextRun({
                      text: 'Explique: ' + this.getValue(data.submission, "reproduccion_4_pregunta"),
                      font: 'Arial',
                      size: 20
                    })
                  ],
                  spacing: {
                    after: 100,
                    before: 100
                  }
                })
              ],
              columnSpan: 10
            }),
          ]
        })
      ],
      margins: {
        left: 70,
        right: 70
      },
      width: {
        size: 100,
        type: WidthType.PERCENTAGE
      },
      columnWidths: [500,1359,1359,1359,1359,1359,1359,1359,1200,1200]
    })
    return table
  }

    public createManejo(data) {
        const table = new Table({
            rows: [
                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: 'MANEJO',
                                            font: 'Calibri (Body)',
                                            size: 28,
                                            bold: true
                                        })
                                    ]
                                })
                            ],
                            shading: {
                                fill: '#C0C0C0',
                                val: ShadingType.PERCENT_80
                            },
                            columnSpan: 10
                        })
                    ]
                }),

                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: '1',
                                            font: 'Arial',
                                            size: 20
                                        })
                                    ],
                                    spacing: {
                                        after: 100,
                                        before: 100
                                    }
                                })
                            ],
                            columnSpan: 1
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: '¿Se tienen implementados y registrados los parámetros sobre calidad del agua?',
                                            font: 'Arial',
                                            size: 20
                                        })
                                    ],
                                    spacing: {
                                        after: 100,
                                        before: 100
                                    }
                                }),
                              new Paragraph({
                                alignment: AlignmentType.LEFT,
                                children: [
                                  new TextRun({
                                    text: `${this.getValue(data.submission, "manejo_1_pregunta_a") ? '■' : '❏'} a.- Temperatura',
                                    font: 'Arial`,
                                    size: 20
                                  })
                                ],
                                spacing: {
                                  after: 100,
                                  before: 100
                                }
                              }),
                              new Paragraph({
                                alignment: AlignmentType.LEFT,
                                children: [
                                  new TextRun({
                                    text: `${this.getValue(data.submission, "manejo_1_pregunta_b") ? '■' : '❏'} b.- pH`,
                                    font: 'Arial',
                                    size: 20
                                  })
                                ],
                                spacing: {
                                  after: 100,
                                  before: 100
                                }
                              }),
                              new Paragraph({
                                alignment: AlignmentType.LEFT,
                                children: [
                                  new TextRun({
                                    text: `${this.getValue(data.submission, "manejo_1_pregunta_c") ? '■' : '❏'} c.- Salinidad`,
                                    font: 'Arial',
                                    size: 20
                                  })
                                ],
                                spacing: {
                                  after: 100,
                                  before: 100
                                }
                              }),
                              new Paragraph({
                                alignment: AlignmentType.LEFT,
                                children: [
                                  new TextRun({
                                    text: `${this.getValue(data.submission, "manejo_1_pregunta_d") ? '■' : '❏'} d.- Oxígeno`,
                                    font: 'Arial',
                                    size: 20
                                  })
                                ],
                                spacing: {
                                  after: 100,
                                  before: 100
                                }
                              }),
                              new Paragraph({
                                alignment: AlignmentType.LEFT,
                                children: [
                                  new TextRun({
                                    text: `${this.getValue(data.submission, "manejo_1_pregunta_e") ? '■' : '❏'} e.- Amonio`,
                                    font: 'Arial',
                                    size: 20
                                  })
                                ],
                                spacing: {
                                  after: 100,
                                  before: 100
                                }
                              }),
                              new Paragraph({
                                alignment: AlignmentType.LEFT,
                                children: [
                                  new TextRun({
                                    text: `${this.getValue(data.submission, "manejo_1_pregunta_f") ? '■' : '❏'} f.- Nitratos`,
                                    font: 'Arial',
                                    size: 20
                                  })
                                ],
                                spacing: {
                                  after: 100,
                                  before: 100
                                }
                              })
                            ],
                            columnSpan: 7
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: `${this.getValue(data.submission, "manejo_1_conformidad") === 'S' ? '■' : '❏'} S ${this.getValue(data.submission, "manejo_1_conformidad") === 'N' ? '■' : '❏'} N ${this.getValue(data.submission, "manejo_1_conformidad") === 'NA' ? '■' : '❏'} NA`,
                                            font: 'Arial',
                                            size: 20
                                        })
                                    ],
                                    spacing: {
                                        after: 100,
                                        before: 100
                                    }
                                })
                            ],
                            columnSpan: 2
                        })
                    ]
                }),
                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text:`Anexos: ${this.getFileList(this.getValue(data.submission, "manejo_1_adjuntos_url"))}`,
                                            font: 'Arial',
                                            size: 20
                                        })
                                    ],
                                    spacing: {
                                        after: 100,
                                        before: 100
                                    }
                                })
                            ],
                            columnSpan: 10
                        }),
                    ]
                }),
                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: 'Explique: ' + this.getValue(data.submission, "manejo_1_pregunta"),
                                            font: 'Arial',
                                            size: 20
                                        })
                                    ],
                                    spacing: {
                                        after: 100,
                                        before: 100
                                    }
                                })
                            ],
                            columnSpan: 10
                        }),
                    ]
                }),

                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: '2',
                                            font: 'Arial',
                                            size: 20
                                        })
                                    ],
                                    spacing: {
                                        after: 100,
                                        before: 100
                                    }
                                })
                            ],
                            columnSpan: 1
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: '¿Se tienen implementadas medidas preventivas para el control de enfermedades?',
                                            font: 'Arial',
                                            size: 20
                                        })
                                    ],
                                    spacing: {
                                        after: 100,
                                        before: 100
                                    }
                                })
                            ],
                            columnSpan: 7
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: `${this.getValue(data.submission, "manejo_2_conformidad") === 'S' ? '■' : '❏'} S ${this.getValue(data.submission, "manejo_2_conformidad") === 'N' ? '■' : '❏'} N ${this.getValue(data.submission, "manejo_2_conformidad") === 'NA' ? '■' : '❏'} NA`,
                                            font: 'Arial',
                                            size: 20
                                        })
                                    ],
                                    spacing: {
                                        after: 100,
                                        before: 100
                                    }
                                })
                            ],
                            columnSpan: 2
                        })
                    ]
                }),
                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text:`Anexos: ${this.getFileList(this.getValue(data.submission, "manejo_2_adjuntos_url"))}`,
                                            font: 'Arial',
                                            size: 20
                                        })
                                    ],
                                    spacing: {
                                        after: 100,
                                        before: 100
                                    }
                                })
                            ],
                            columnSpan: 10
                        }),
                    ]
                }),
                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: 'Explique: ' + this.getValue(data.submission, "manejo_2_pregunta"),
                                            font: 'Arial',
                                            size: 20
                                        })
                                    ],
                                    spacing: {
                                        after: 100,
                                        before: 100
                                    }
                                })
                            ],
                            columnSpan: 10
                        }),
                    ]
                }),

                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: '3',
                                            font: 'Arial',
                                            size: 20
                                        })
                                    ],
                                    spacing: {
                                        after: 100,
                                        before: 100
                                    }
                                })
                            ],
                            columnSpan: 1
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: '¿Se utilizan sustancias permitidas para elmanejo y control de enfermedades?',
                                            font: 'Arial',
                                            size: 20
                                        })
                                    ],
                                    spacing: {
                                        after: 100,
                                        before: 100
                                    }
                                })
                            ],
                            columnSpan: 7
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: `${this.getValue(data.submission, "manejo_3_conformidad") === 'S' ? '■' : '❏'} S ${this.getValue(data.submission, "manejo_3_conformidad") === 'N' ? '■' : '❏'} N ${this.getValue(data.submission, "manejo_3_conformidad") === 'NA' ? '■' : '❏'} NA`,
                                            font: 'Arial',
                                            size: 20
                                        })
                                    ],
                                    spacing: {
                                        after: 100,
                                        before: 100
                                    }
                                })
                            ],
                            columnSpan: 2
                        })
                    ]
                }),
                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text:`Anexos: ${this.getFileList(this.getValue(data.submission, "manejo_3_adjuntos_url"))}`,
                                            font: 'Arial',
                                            size: 20
                                        })
                                    ],
                                    spacing: {
                                        after: 100,
                                        before: 100
                                    }
                                })
                            ],
                            columnSpan: 10
                        }),
                    ]
                }),
                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: 'Explique: ' + this.getValue(data.submission, "manejo_3_pregunta"),
                                            font: 'Arial',
                                            size: 20
                                        })
                                    ],
                                    spacing: {
                                        after: 100,
                                        before: 100
                                    }
                                })
                            ],
                            columnSpan: 10
                        }),
                    ]
                }),

                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: '4',
                                            font: 'Arial',
                                            size: 20
                                        })
                                    ],
                                    spacing: {
                                        after: 100,
                                        before: 100
                                    }
                                })
                            ],
                            columnSpan: 1
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: '¿En caso de utilizar tratamientos veterinarios convencionales, el lote tratado se separa para la venta no-orgánica?',
                                            font: 'Arial',
                                            size: 20
                                        })
                                    ],
                                    spacing: {
                                        after: 100,
                                        before: 100
                                    }
                                })
                            ],
                            columnSpan: 7
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: `${this.getValue(data.submission, "manejo_4_conformidad") === 'S' ? '■' : '❏'} S ${this.getValue(data.submission, "manejo_4_conformidad") === 'N' ? '■' : '❏'} N ${this.getValue(data.submission, "manejo_4_conformidad") === 'NA' ? '■' : '❏'} NA`,
                                            font: 'Arial',
                                            size: 20
                                        })
                                    ],
                                    spacing: {
                                        after: 100,
                                        before: 100
                                    }
                                })
                            ],
                            columnSpan: 2
                        })
                    ]
                }),
                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text:`Anexos: ${this.getFileList(this.getValue(data.submission, "manejo_4_adjuntos_url"))}`,
                                            font: 'Arial',
                                            size: 20
                                        })
                                    ],
                                    spacing: {
                                        after: 100,
                                        before: 100
                                    }
                                })
                            ],
                            columnSpan: 10
                        }),
                    ]
                }),
                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: 'Explique: ' + this.getValue(data.submission, "manejo_4_pregunta"),
                                            font: 'Arial',
                                            size: 20
                                        })
                                    ],
                                    spacing: {
                                        after: 100,
                                        before: 100
                                    }
                                })
                            ],
                            columnSpan: 10
                        }),
                    ]
                }),

                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: '5',
                                            font: 'Arial',
                                            size: 20
                                        })
                                    ],
                                    spacing: {
                                        after: 100,
                                        before: 100
                                    }
                                })
                            ],
                            columnSpan: 1
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: '¿Si un lote tratado con materiales convencionales se comercializó como orgánico, se tienen resultados de análisis de que estaba libre de residuos del tratamiento?',
                                            font: 'Arial',
                                            size: 20
                                        })
                                    ],
                                    spacing: {
                                        after: 100,
                                        before: 100
                                    }
                                })
                            ],
                            columnSpan: 7
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: `${this.getValue(data.submission, "manejo_5_conformidad") === 'S' ? '■' : '❏'} S ${this.getValue(data.submission, "manejo_5_conformidad") === 'N' ? '■' : '❏'} N ${this.getValue(data.submission, "manejo_5_conformidad") === 'NA' ? '■' : '❏'} NA`,
                                            font: 'Arial',
                                            size: 20
                                        })
                                    ],
                                    spacing: {
                                        after: 100,
                                        before: 100
                                    }
                                })
                            ],
                            columnSpan: 2
                        })
                    ]
                }),
                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text:`Anexos: ${this.getFileList(this.getValue(data.submission, "manejo_5_adjuntos_url"))}`,
                                            font: 'Arial',
                                            size: 20
                                        })
                                    ],
                                    spacing: {
                                        after: 100,
                                        before: 100
                                    }
                                })
                            ],
                            columnSpan: 10
                        }),
                    ]
                }),
                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: 'Explique: ' + this.getValue(data.submission, "manejo_5_pregunta"),
                                            font: 'Arial',
                                            size: 20
                                        })
                                    ],
                                    spacing: {
                                        after: 100,
                                        before: 100
                                    }
                                })
                            ],
                            columnSpan: 10
                        }),
                    ]
                }),

              new TableRow({
                children: [
                  new TableCell({
                    children: [
                      new Paragraph({
                        alignment: AlignmentType.LEFT,
                        children: [
                          new TextRun({
                            text: '6',
                            font: 'Arial',
                            size: 20
                          })
                        ],
                        spacing: {
                          after: 100,
                          before: 100
                        }
                      })
                    ],
                    columnSpan: 1
                  }),
                  new TableCell({
                    children: [
                      new Paragraph({
                        alignment: AlignmentType.LEFT,
                        children: [
                          new TextRun({
                            text: 'Para el sacrificio ¿se asegura que los animales quedan inmediatamente inconscientes e insensibles al dolor?',
                            font: 'Arial',
                            size: 20
                          })
                        ],
                        spacing: {
                          after: 100,
                          before: 100
                        }
                      })
                    ],
                    columnSpan: 7
                  }),
                  new TableCell({
                    children: [
                      new Paragraph({
                        alignment: AlignmentType.LEFT,
                        children: [
                          new TextRun({
                            text: `${this.getValue(data.submission, "manejo_6_conformidad") === 'S' ? '■' : '❏'} S ${this.getValue(data.submission, "manejo_6_conformidad") === 'N' ? '■' : '❏'} N ${this.getValue(data.submission, "manejo_6_conformidad") === 'NA' ? '■' : '❏'} NA`,
                            font: 'Arial',
                            size: 20
                          })
                        ],
                        spacing: {
                          after: 100,
                          before: 100
                        }
                      })
                    ],
                    columnSpan: 2
                  })
                ]
              }),
              new TableRow({
                children: [
                  new TableCell({
                    children: [
                      new Paragraph({
                        alignment: AlignmentType.LEFT,
                        children: [
                          new TextRun({
                            text:`Anexos: ${this.getFileList(this.getValue(data.submission, "manejo_6_adjuntos_url"))}`,
                            font: 'Arial',
                            size: 20
                          })
                        ],
                        spacing: {
                          after: 100,
                          before: 100
                        }
                      })
                    ],
                    columnSpan: 10
                  }),
                ]
              }),
              new TableRow({
                children: [
                  new TableCell({
                    children: [
                      new Paragraph({
                        alignment: AlignmentType.LEFT,
                        children: [
                          new TextRun({
                            text: 'Explique: ' + this.getValue(data.submission, "manejo_6_pregunta"),
                            font: 'Arial',
                            size: 20
                          })
                        ],
                        spacing: {
                          after: 100,
                          before: 100
                        }
                      })
                    ],
                    columnSpan: 10
                  }),
                ]
              }),

                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: '7',
                                            font: 'Arial',
                                            size: 20
                                        })
                                    ],
                                    spacing: {
                                        after: 100,
                                        before: 100
                                    }
                                })
                            ],
                            columnSpan: 1
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: '¿Se tomaron muestras para análisis de laboratorio?',
                                            font: 'Arial',
                                            size: 20
                                        })
                                    ],
                                    spacing: {
                                        after: 100,
                                        before: 100
                                    }
                                }),
                              new Paragraph({
                                alignment: AlignmentType.LEFT,
                                children: [
                                  new TextRun({
                                    text: 'En caso positivo, favor de indicar el tipo de muestra, el análisis requerido, el lugar y condiciones de la toma de muestra y cualquier otra información que considere relevante.',
                                    font: 'Arial',
                                    size: 20
                                  })
                                ],
                                spacing: {
                                  after: 100,
                                  before: 100
                                }
                              })
                            ],
                            columnSpan: 7
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: `${this.getValue(data.submission, "manejo_7_conformidad") === 'S' ? '■' : '❏'} S ${this.getValue(data.submission, "manejo_7_conformidad") === 'N' ? '■' : '❏'} N ${this.getValue(data.submission, "manejo_7_conformidad") === 'NA' ? '■' : '❏'} NA`,
                                            font: 'Arial',
                                            size: 20
                                        })
                                    ],
                                    spacing: {
                                        after: 100,
                                        before: 100
                                    }
                                })
                            ],
                            columnSpan: 2
                        })
                    ]
                }),
                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text:`Anexos: ${this.getFileList(this.getValue(data.submission, "manejo_7_adjuntos_url"))}`,
                                            font: 'Arial',
                                            size: 20
                                        })
                                    ],
                                    spacing: {
                                        after: 100,
                                        before: 100
                                    }
                                })
                            ],
                            columnSpan: 10
                        }),
                    ]
                }),
                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: 'Explique: ' + this.getValue(data.submission, "manejo_7_pregunta"),
                                            font: 'Arial',
                                            size: 20
                                        })
                                    ],
                                    spacing: {
                                        after: 100,
                                        before: 100
                                    }
                                })
                            ],
                            columnSpan: 10
                        }),
                    ]
                }),

                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: '8',
                                            font: 'Arial',
                                            size: 20
                                        })
                                    ],
                                    spacing: {
                                        after: 100,
                                        before: 100
                                    }
                                })
                            ],
                            columnSpan: 1
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: '¿Las prácticas y registros de manejo observadas permiten mantener la integridad orgánica del producto?',
                                            font: 'Arial',
                                            size: 20
                                        })
                                    ],
                                    spacing: {
                                        after: 50,
                                        before: 100
                                    }
                                })
                            ],
                            columnSpan: 7
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: `${this.getValue(data.submission, "manejo_8_conformidad") === 'C' ? '■' : '❏'} C ${this.getValue(data.submission, "manejo_8_conformidad") === 'NC' ? '■' : '❏'} NC ${this.getValue(data.submission, "manejo_8_conformidad") === 'NA' ? '■' : '❏'} NA`,
                                            font: 'Arial',
                                            size: 20
                                        })
                                    ],
                                    spacing: {
                                        after: 100,
                                        before: 100
                                    }
                                })
                            ],
                            columnSpan: 2
                        })
                    ]
                }),
                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text:`Anexos: ${this.getFileList(this.getValue(data.submission, "manejo_8_adjuntos_url"))}`,
                                            font: 'Arial',
                                            size: 20
                                        })
                                    ],
                                    spacing: {
                                        after: 100,
                                        before: 100
                                    }
                                })
                            ],
                            columnSpan: 10
                        }),
                    ]
                }),
                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: 'Explique: ' + this.getValue(data.submission, "manejo_8_pregunta"),
                                            font: 'Arial',
                                            size: 20
                                        })
                                    ],
                                    spacing: {
                                        after: 100,
                                        before: 100
                                    }
                                })
                            ],
                            columnSpan: 10
                        }),
                    ]
                }),

              new TableRow({
                children: [
                  new TableCell({
                    children: [
                      new Paragraph({
                        alignment: AlignmentType.LEFT,
                        children: [
                          new TextRun({
                            text: '9',
                            font: 'Arial',
                            size: 20
                          })
                        ],
                        spacing: {
                          after: 100,
                          before: 100
                        }
                      })
                    ],
                    columnSpan: 1
                  }),
                  new TableCell({
                    children: [
                      new Paragraph({
                        alignment: AlignmentType.LEFT,
                        children: [
                          new TextRun({
                            text: '¿Las prácticas y registros de cosecha permiten mantener la integridad del producto?',
                            font: 'Arial',
                            size: 20
                          })
                        ],
                        spacing: {
                          after: 50,
                          before: 100
                        }
                      })
                    ],
                    columnSpan: 7
                  }),
                  new TableCell({
                    children: [
                      new Paragraph({
                        alignment: AlignmentType.LEFT,
                        children: [
                          new TextRun({
                            text: `${this.getValue(data.submission, "manejo_9_conformidad") === 'C' ? '■' : '❏'} C ${this.getValue(data.submission, "manejo_9_conformidad") === 'NC' ? '■' : '❏'} NC ${this.getValue(data.submission, "manejo_9_conformidad") === 'NA' ? '■' : '❏'} NA`,
                            font: 'Arial',
                            size: 20
                          })
                        ],
                        spacing: {
                          after: 100,
                          before: 100
                        }
                      })
                    ],
                    columnSpan: 2
                  })
                ]
              }),
              new TableRow({
                children: [
                  new TableCell({
                    children: [
                      new Paragraph({
                        alignment: AlignmentType.LEFT,
                        children: [
                          new TextRun({
                            text:`Anexos: ${this.getFileList(this.getValue(data.submission, "manejo_9_adjuntos_url"))}`,
                            font: 'Arial',
                            size: 20
                          })
                        ],
                        spacing: {
                          after: 100,
                          before: 100
                        }
                      })
                    ],
                    columnSpan: 10
                  }),
                ]
              }),
              new TableRow({
                children: [
                  new TableCell({
                    children: [
                      new Paragraph({
                        alignment: AlignmentType.LEFT,
                        children: [
                          new TextRun({
                            text: 'Explique: ' + this.getValue(data.submission, "manejo_9_pregunta"),
                            font: 'Arial',
                            size: 20
                          })
                        ],
                        spacing: {
                          after: 100,
                          before: 100
                        }
                      })
                    ],
                    columnSpan: 10
                  }),
                ]
              }),

              new TableRow({
                children: [
                  new TableCell({
                    children: [
                      new Paragraph({
                        alignment: AlignmentType.LEFT,
                        children: [
                          new TextRun({
                            text: '10',
                            font: 'Arial',
                            size: 20
                          })
                        ],
                        spacing: {
                          after: 100,
                          before: 100
                        }
                      })
                    ],
                    columnSpan: 1
                  }),
                  new TableCell({
                    children: [
                      new Paragraph({
                        alignment: AlignmentType.LEFT,
                        children: [
                          new TextRun({
                            text: '¿Las prácticas y registros de transporte permiten mantener la integridad orgánica del producto?',
                            font: 'Arial',
                            size: 20
                          })
                        ],
                        spacing: {
                          after: 50,
                          before: 100
                        }
                      })
                    ],
                    columnSpan: 7
                  }),
                  new TableCell({
                    children: [
                      new Paragraph({
                        alignment: AlignmentType.LEFT,
                        children: [
                          new TextRun({
                            text: `${this.getValue(data.submission, "manejo_10_conformidad") === 'C' ? '■' : '❏'} C ${this.getValue(data.submission, "manejo_10_conformidad") === 'NC' ? '■' : '❏'} NC ${this.getValue(data.submission, "manejo_10_conformidad") === 'NA' ? '■' : '❏'} NA`,
                            font: 'Arial',
                            size: 20
                          })
                        ],
                        spacing: {
                          after: 100,
                          before: 100
                        }
                      })
                    ],
                    columnSpan: 2
                  })
                ]
              }),
              new TableRow({
                children: [
                  new TableCell({
                    children: [
                      new Paragraph({
                        alignment: AlignmentType.LEFT,
                        children: [
                          new TextRun({
                            text:`Anexos: ${this.getFileList(this.getValue(data.submission, "manejo_10_adjuntos_url"))}`,
                            font: 'Arial',
                            size: 20
                          })
                        ],
                        spacing: {
                          after: 100,
                          before: 100
                        }
                      })
                    ],
                    columnSpan: 10
                  }),
                ]
              }),
              new TableRow({
                children: [
                  new TableCell({
                    children: [
                      new Paragraph({
                        alignment: AlignmentType.LEFT,
                        children: [
                          new TextRun({
                            text: 'Explique: ' + this.getValue(data.submission, "manejo_10_pregunta"),
                            font: 'Arial',
                            size: 20
                          })
                        ],
                        spacing: {
                          after: 100,
                          before: 100
                        }
                      })
                    ],
                    columnSpan: 10
                  }),
                ]
              })
            ],
            margins: {
                left: 70,
                right: 70
            },
            width: {
                size: 100,
                type: WidthType.PERCENTAGE
            },
            columnWidths: [500,1359,1359,1359,1359,1359,1359,1359,1200,1200]
        })

        return table
    }

    public createProductosCertificados(data) {
        const table = new Table({
            rows: [
                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: 'PRODUCTOS CERTIFICADOS',
                                            font: 'Calibri (Body)',
                                            size: 28,
                                            bold: true
                                        })
                                    ]
                                })
                            ],
                            columnSpan: 10
                        })
                    ]
                }),

                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: '1',
                                            font: 'Arial',
                                            size: 20
                                        })
                                    ],
                                    spacing: {
                                        after: 100,
                                        before: 100
                                    }
                                })
                            ],
                            columnSpan: 1
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: '¿Todos los productos comercializados como orgánicos fueron incluidos en la solicitud de certificación?',
                                            font: 'Arial',
                                            size: 20
                                        })
                                    ],
                                    spacing: {
                                        after: 100,
                                        before: 100
                                    }
                                })
                            ],
                            columnSpan: 7
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: `${this.getValue(data.submission, "productos_certificados_1_conformidad") === 'C' ? '■' : '❏'} C ${this.getValue(data.submission, "productos_certificados_1_conformidad") === 'NC' ? '■' : '❏'} NC ${this.getValue(data.submission, "productos_certificados_1_conformidad") === 'NA' ? '■' : '❏'} NA`,
                                            font: 'Arial',
                                            size: 20
                                        })
                                    ],
                                    spacing: {
                                        after: 100,
                                        before: 100
                                    }
                                })
                            ],
                            columnSpan: 2
                        })
                    ]
                }),
                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: 'Explique: ' + this.getValue(data.submission, "productos_certificados_1_explique"),
                                            font: 'Arial',
                                            size: 20
                                        })
                                    ],
                                    spacing: {
                                        after: 100,
                                        before: 100
                                    }
                                })
                            ],
                            columnSpan: 10
                        }),
                    ]
                }),

                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: '',
                                            font: 'Arial',
                                            size: 20
                                        })
                                    ],
                                    spacing: {
                                        after: 100,
                                        before: 100
                                    }
                                })
                            ],
                            columnSpan: 1
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: '¿Fue posible realizar un balance de masas?',
                                            font: 'Arial',
                                            size: 20
                                        })
                                    ],
                                    spacing: {
                                        after: 100,
                                        before: 100
                                    }
                                })
                            ],
                            columnSpan: 7
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: `${this.getValue(data.submission, "productos_certificados_1_tabla_conformidad") === 'C' ? '■' : '❏'} C ${this.getValue(data.submission, "productos_certificados_1_tabla_conformidad") === 'NC' ? '■' : '❏'} NC ${this.getValue(data.submission, "productos_certificados_1_tabla_conformidad") === 'NA' ? '■' : '❏'} NA`,
                                            font: 'Arial',
                                            size: 20
                                        })
                                    ],
                                    spacing: {
                                        after: 100,
                                        before: 100
                                    }
                                })
                            ],
                            columnSpan: 2
                        })
                    ]
                })
            ],
            margins: {
                left: 70,
                right: 70
            },
            width: {
                size: 100,
                type: WidthType.PERCENTAGE
            },
            columnWidths: [500,1359,1359,1359,1359,1359,1359,1359,1200,1200]
        })

        let tableRowBalance = new TableRow({
            children: []
        })

        let tableCellBalance = new TableCell({
            children: []
        })

        let paragraphBalance = new Paragraph({
            alignment: AlignmentType.LEFT,
            children: [
                new TextRun({
                    text: '',
                    font: 'Arial',
                    size: 20
                })
            ]
        })

        let table_balance = new Table({
            rows: [
                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: 'Producto',
                                            font: 'Arial',
                                            size: 20,
                                            bold: true
                                        })
                                    ]
                                })
                            ],
                            shading: {
                                fill: '#C0C0C0',
                                val: ShadingType.PERCENT_80
                            },
                            columnSpan: 2
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: 'Cant. Estimada(Kg)',
                                            font: 'Arial',
                                            size: 20,
                                            bold: true
                                        })
                                    ]
                                })
                            ],
                            shading: {
                                fill: '#C0C0C0',
                                val: ShadingType.PERCENT_80
                            },
                            columnSpan: 1
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: 'Cant. Cosechada(Kg)',
                                            font: 'Arial',
                                            size: 20,
                                            bold: true
                                        })
                                    ]
                                })
                            ],
                            shading: {
                                fill: '#C0C0C0',
                                val: ShadingType.PERCENT_80
                            },
                            columnSpan: 1
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: 'Cant. vendida(Kg)',
                                            font: 'Arial',
                                            size: 20,
                                            bold: true
                                        })
                                    ]
                                })
                            ],
                            shading: {
                                fill: '#C0C0C0',
                                val: ShadingType.PERCENT_80
                            },
                            columnSpan: 1
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: 'Documentos de referencia',
                                            font: 'Arial',
                                            size: 20,
                                            bold: true
                                        })
                                    ]
                                })
                            ],
                            shading: {
                                fill: '#C0C0C0',
                                val: ShadingType.PERCENT_80
                            },
                            columnSpan: 2
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: 'Anexos',
                                            font: 'Arial',
                                            size: 20,
                                            bold: true
                                        })
                                    ]
                                })
                            ],
                            shading: {
                                fill: '#C0C0C0',
                                val: ShadingType.PERCENT_80
                            },
                            columnSpan: 2
                        }),
                    ]
                })
            ],
            margins: {
                left: 70,
                right: 70
            },
            width: {
                size: 100,
                type: WidthType.PERCENTAGE
            },
            columnWidths: [500,1359,1359,1359,1359,1359,1359,1359,1200,1200]
        })

        let listaBalances = this.getValueList(data.submission, "productos_comercializados")
        listaBalances.forEach(balance => {
            var anexos = ''
            for(var i in balance.documentos_urls) {
                const tmp = balance.documentos_urls[i].split("/")
                anexos += tmp[tmp.length-1] + ", "
            }

            anexos = anexos.substr(0, anexos.length-2)

            table_balance.addChildElement(
                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: balance.producto,
                                            font: 'Arial',
                                            size: 20
                                        })
                                    ],
                                    spacing: {
                                        after: 100,
                                        before: 100
                                    }
                                })
                            ],
                            columnSpan: 2
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: balance.cant_estimada,
                                            font: 'Arial',
                                            size: 20
                                        })
                                    ],
                                    spacing: {
                                        after: 100,
                                        before: 100
                                    }
                                })
                            ],
                            columnSpan: 1
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: balance.cant_cosechada,
                                            font: 'Arial',
                                            size: 20
                                        })
                                    ],
                                    spacing: {
                                        after: 100,
                                        before: 100
                                    }
                                })
                            ],
                            columnSpan: 1
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: balance.cant_vendida,
                                            font: 'Arial',
                                            size: 20
                                        })
                                    ],
                                    spacing: {
                                        after: 100,
                                        before: 100
                                    }
                                })
                            ],
                            columnSpan: 1
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: balance.documentos,
                                            font: 'Arial',
                                            size: 20
                                        })
                                    ],
                                    spacing: {
                                        after: 100,
                                        before: 100
                                    }
                                })
                            ],
                            columnSpan: 2
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: `${anexos === '' ? 'Sin anexos' : anexos}`,
                                            font: 'Arial',
                                            size: 20
                                        })
                                    ],
                                    spacing: {
                                        after: 100,
                                        before: 100
                                    }
                                })
                            ],
                            columnSpan: 2
                        }),
                    ]
                }))
        });

        tableCellBalance.addChildElement(paragraphBalance)
        tableCellBalance.addChildElement(table_balance)
        tableCellBalance.addChildElement(paragraphBalance)
        tableRowBalance.addChildElement(tableCellBalance)
        table.addChildElement(tableRowBalance)

        table.addChildElement(new TableRow({
            children: [
                new TableCell({
                    children: [
                        new Paragraph({
                            alignment: AlignmentType.LEFT,
                            children: [
                                new TextRun({
                                    text: 'Comentarios: ' + this.getValue(data.submission, "productos_certificados_1_comentarios"),
                                    font: 'Arial',
                                    size: 20
                                })
                            ],
                            spacing: {
                                after: 100,
                                before: 100
                            }
                        })
                    ],
                    columnSpan: 10
                }),
            ]
        }))

        table.addChildElement(new TableRow({
            children: [
                new TableCell({
                    children: [
                        new Paragraph({
                            alignment: AlignmentType.LEFT,
                            children: [
                                new TextRun({
                                    text: '2',
                                    font: 'Arial',
                                    size: 20
                                })
                            ],
                            spacing: {
                                after: 100,
                                before: 100
                            }
                        })
                    ],
                    columnSpan: 1
                }),
                new TableCell({
                    children: [
                        new Paragraph({
                            alignment: AlignmentType.LEFT,
                            children: [
                                new TextRun({
                                    text: '¿Las cantidades cosechadas corresponden con los rendimientos esperados?',
                                    font: 'Arial',
                                    size: 20
                                })
                            ],
                            spacing: {
                                after: 100,
                                before: 100
                            }
                        })
                    ],
                    columnSpan: 7
                }),
                new TableCell({
                    children: [
                        new Paragraph({
                            alignment: AlignmentType.LEFT,
                            children: [
                                new TextRun({
                                    text: `${this.getValue(data.submission, "productos_certificados_2_conformidad") === 'C' ? '■' : '❏'} C ${this.getValue(data.submission, "productos_certificados_2_conformidad") === 'NC' ? '■' : '❏'} NC ${this.getValue(data.submission, "productos_certificados_2_conformidad") === 'NA' ? '■' : '❏'} NA`,
                                    font: 'Arial',
                                    size: 20
                                })
                            ],
                            spacing: {
                                after: 100,
                                before: 100
                            }
                        })
                    ],
                    columnSpan: 2
                })
            ]
        }))
        table.addChildElement(new TableRow({
            children: [
                new TableCell({
                    children: [
                        new Paragraph({
                            alignment: AlignmentType.LEFT,
                            children: [
                                new TextRun({
                                    text: 'Explique: ' + this.getValue(data.submission, "productos_certificados_2_pregunta"),
                                    font: 'Arial',
                                    size: 20
                                })
                            ],
                            spacing: {
                                after: 100,
                                before: 100
                            }
                        })
                    ],
                    columnSpan: 10
                }),
            ]
        }))

        table.addChildElement(new TableRow({
            children: [
                new TableCell({
                    children: [
                        new Paragraph({
                            alignment: AlignmentType.LEFT,
                            children: [
                                new TextRun({
                                    text: '3',
                                    font: 'Arial',
                                    size: 20
                                })
                            ],
                            spacing: {
                                after: 100,
                                before: 100
                            }
                        })
                    ],
                    columnSpan: 1
                }),
                new TableCell({
                    children: [
                        new Paragraph({
                            alignment: AlignmentType.LEFT,
                            children: [
                                new TextRun({
                                    text: '¿Se encuentra congruencia entre las cantidades cosechadas, empacadas, transportadas y vendidas?',
                                    font: 'Arial',
                                    size: 20
                                })
                            ],
                            spacing: {
                                after: 100,
                                before: 100
                            }
                        })
                    ],
                    columnSpan: 7
                }),
                new TableCell({
                    children: [
                        new Paragraph({
                            alignment: AlignmentType.LEFT,
                            children: [
                                new TextRun({
                                    text: `${this.getValue(data.submission, "productos_certificados_3_conformidad") === 'C' ? '■' : '❏'} C ${this.getValue(data.submission, "productos_certificados_3_conformidad") === 'NC' ? '■' : '❏'} NC ${this.getValue(data.submission, "productos_certificados_3_conformidad") === 'NA' ? '■' : '❏'} NA`,
                                    font: 'Arial',
                                    size: 20
                                })
                            ],
                            spacing: {
                                after: 100,
                                before: 100
                            }
                        })
                    ],
                    columnSpan: 2
                })
            ]
        }))
        table.addChildElement(new TableRow({
            children: [
                new TableCell({
                    children: [
                        new Paragraph({
                            alignment: AlignmentType.LEFT,
                            children: [
                                new TextRun({
                                    text: 'Explique: ' + this.getValue(data.submission, "productos_certificados_3_pregunta"),
                                    font: 'Arial',
                                    size: 20
                                })
                            ],
                            spacing: {
                                after: 100,
                                before: 100
                            }
                        })
                    ],
                    columnSpan: 10
                }),
            ]
        }))

        table.addChildElement(new TableRow({
            children: [
                new TableCell({
                    children: [
                        new Paragraph({
                            alignment: AlignmentType.LEFT,
                            children: [
                                new TextRun({
                                    text: '4',
                                    font: 'Arial',
                                    size: 20
                                })
                            ],
                            spacing: {
                                after: 100,
                                before: 100
                            }
                        })
                    ],
                    columnSpan: 1
                }),
                new TableCell({
                    children: [
                        new Paragraph({
                            alignment: AlignmentType.LEFT,
                            children: [
                                new TextRun({
                                    text: '¿Fue posible realizar un ejercicio de trazabilidad?',
                                    font: 'Arial',
                                    size: 20
                                })
                            ],
                            spacing: {
                                after: 100,
                                before: 100
                            }
                        })
                    ],
                    columnSpan: 7
                }),
                new TableCell({
                    children: [
                        new Paragraph({
                            alignment: AlignmentType.LEFT,
                            children: [
                                new TextRun({
                                    text: `${this.getValue(data.submission, "productos_certificados_4_conformidad") === 'C' ? '■' : '❏'} C ${this.getValue(data.submission, "productos_certificados_4_conformidad") === 'NC' ? '■' : '❏'} NC ${this.getValue(data.submission, "productos_certificados_4_conformidad") === 'NA' ? '■' : '❏'} NA`,
                                    font: 'Arial',
                                    size: 20
                                })
                            ],
                            spacing: {
                                after: 100,
                                before: 100
                            }
                        })
                    ],
                    columnSpan: 2
                })
            ]
        }))


        let tableRowTrazabilidad = new TableRow({
            children: []
        })

        let tableCellTrazabilidad = new TableCell({
            children: []
        })

        let paragraphTrazabilidad = new Paragraph({
            alignment: AlignmentType.LEFT,
            children: [
                new TextRun({
                    text: '',
                    font: 'Arial',
                    size: 20
                })
            ]
        })

        let table_trazabilidad = new Table({
            rows: [
                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: 'Lote venta',
                                            font: 'Arial',
                                            size: 20,
                                            bold: true
                                        })
                                    ]
                                })
                            ],
                            shading: {
                                fill: '#C0C0C0',
                                val: ShadingType.PERCENT_80
                            },
                            columnSpan: 1
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: 'Cantidad',
                                            font: 'Arial',
                                            size: 20,
                                            bold: true
                                        })
                                    ]
                                })
                            ],
                            shading: {
                                fill: '#C0C0C0',
                                val: ShadingType.PERCENT_80
                            },
                            columnSpan: 1
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: 'Fecha venta',
                                            font: 'Arial',
                                            size: 20,
                                            bold: true
                                        })
                                    ]
                                })
                            ],
                            shading: {
                                fill: '#C0C0C0',
                                val: ShadingType.PERCENT_80
                            },
                            columnSpan: 1
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: 'Fecha producción',
                                            font: 'Arial',
                                            size: 20,
                                            bold: true
                                        })
                                    ]
                                })
                            ],
                            shading: {
                                fill: '#C0C0C0',
                                val: ShadingType.PERCENT_80
                            },
                            columnSpan: 2
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: 'Unidad de origen',
                                            font: 'Arial',
                                            size: 20,
                                            bold: true
                                        })
                                    ]
                                })
                            ],
                            shading: {
                                fill: '#C0C0C0',
                                val: ShadingType.PERCENT_80
                            },
                            columnSpan: 2
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: 'Documentos de referencia',
                                            font: 'Arial',
                                            size: 20,
                                            bold: true
                                        })
                                    ]
                                })
                            ],
                            shading: {
                                fill: '#C0C0C0',
                                val: ShadingType.PERCENT_80
                            },
                            columnSpan: 2
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: 'Anexos',
                                            font: 'Arial',
                                            size: 20,
                                            bold: true
                                        })
                                    ]
                                })
                            ],
                            shading: {
                                fill: '#C0C0C0',
                                val: ShadingType.PERCENT_80
                            },
                            columnSpan: 2
                        }),
                    ]
                })
            ],
            margins: {
                left: 70,
                right: 70
            },
            width: {
                size: 100,
                type: WidthType.PERCENTAGE
            },
            columnWidths: [500,1359,1359,1359,1359,1359,1359,1359,1200,1200]
        })

        let listaTrazabilidad = this.getValueList(data.submission, "trazabilidad")
        listaTrazabilidad.forEach(trazabilidad => {
            var anexos = ''
            for(var i in trazabilidad.anexos) {
                const tmp = trazabilidad.anexos[i].split("/")
                anexos += tmp[tmp.length-1] + ", "
            }

            anexos = anexos.substr(0, anexos.length-2)

            table_trazabilidad.addChildElement(
                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: trazabilidad.lote,
                                            font: 'Arial',
                                            size: 20
                                        })
                                    ],
                                    spacing: {
                                        after: 100,
                                        before: 100
                                    }
                                })
                            ],
                            columnSpan: 2
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: trazabilidad.cantidad,
                                            font: 'Arial',
                                            size: 20
                                        })
                                    ],
                                    spacing: {
                                        after: 100,
                                        before: 100
                                    }
                                })
                            ],
                            columnSpan: 2
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: trazabilidad.fecha_venta,
                                            font: 'Arial',
                                            size: 20
                                        })
                                    ],
                                    spacing: {
                                        after: 100,
                                        before: 100
                                    }
                                })
                            ],
                            columnSpan: 2
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: trazabilidad.fecha_produccion,
                                            font: 'Arial',
                                            size: 20
                                        })
                                    ],
                                    spacing: {
                                        after: 100,
                                        before: 100
                                    }
                                })
                            ],
                            columnSpan: 2
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: trazabilidad.unidad_origen,
                                            font: 'Arial',
                                            size: 20
                                        })
                                    ],
                                    spacing: {
                                        after: 100,
                                        before: 100
                                    }
                                })
                            ],
                            columnSpan: 2
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: trazabilidad.documentos,
                                            font: 'Arial',
                                            size: 20
                                        })
                                    ],
                                    spacing: {
                                        after: 100,
                                        before: 100
                                    }
                                })
                            ],
                            columnSpan: 2
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: `${anexos === '' ? 'Sin anexos' : anexos}`,
                                            font: 'Arial',
                                            size: 20
                                        })
                                    ],
                                    spacing: {
                                        after: 100,
                                        before: 100
                                    }
                                })
                            ],
                            columnSpan: 2
                        }),
                    ]
                })
            )
        });

        tableCellTrazabilidad.addChildElement(paragraphTrazabilidad)
        tableCellTrazabilidad.addChildElement(table_trazabilidad)
        tableCellTrazabilidad.addChildElement(paragraphTrazabilidad)
        tableRowTrazabilidad.addChildElement(tableCellTrazabilidad)
        table.addChildElement(tableRowTrazabilidad)


        table.addChildElement(new TableRow({
            children: [
                new TableCell({
                    children: [
                        new Paragraph({
                            alignment: AlignmentType.LEFT,
                            children: [
                                new TextRun({
                                    text: 'Comentarios: ' + this.getValue(data.submission, "productos_certificados_4_comentarios"),
                                    font: 'Arial',
                                    size: 20
                                })
                            ],
                            spacing: {
                                after: 100,
                                before: 100
                            }
                        })
                    ],
                    columnSpan: 10
                }),
            ]
        }))


        let tableRowCiclo = new TableRow({
            children: []
        })

        let tableCellCiclo = new TableCell({
            children: []
        })

        let paragraphCiclo = new Paragraph({
            alignment: AlignmentType.LEFT,
            children: [
                new TextRun({
                    text: '',
                    font: 'Arial',
                    size: 20
                })
            ]
        })

        let table_ciclo = new Table({
            rows: [
                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: 'Producto',
                                            font: 'Arial',
                                            size: 20,
                                            bold: true
                                        })
                                    ]
                                })
                            ],
                            shading: {
                                fill: '#C0C0C0',
                                val: ShadingType.PERCENT_80
                            },
                            columnSpan: 3
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: 'Unidad de producción',
                                            font: 'Arial',
                                            size: 20,
                                            bold: true
                                        })
                                    ]
                                })
                            ],
                            shading: {
                                fill: '#C0C0C0',
                                val: ShadingType.PERCENT_80
                            },
                            columnSpan: 3
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: 'Superficie (ha)',
                                            font: 'Arial',
                                            size: 20,
                                            bold: true
                                        })
                                    ]
                                })
                            ],
                            shading: {
                                fill: '#C0C0C0',
                                val: ShadingType.PERCENT_80
                            },
                            columnSpan: 2
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: 'Estimación (Kg)',
                                            font: 'Arial',
                                            size: 20,
                                            bold: true
                                        })
                                    ]
                                })
                            ],
                            shading: {
                                fill: '#C0C0C0',
                                val: ShadingType.PERCENT_80
                            },
                            columnSpan: 2
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: 'Anexos',
                                            font: 'Arial',
                                            size: 20,
                                            bold: true
                                        })
                                    ]
                                })
                            ],
                            shading: {
                                fill: '#C0C0C0',
                                val: ShadingType.PERCENT_80
                            },
                            columnSpan: 2
                        }),
                    ]
                })
            ],
            margins: {
                left: 70,
                right: 70
            },
            width: {
                size: 100,
                type: WidthType.PERCENTAGE
            },
            columnWidths: [500,1359,1359,1359,1359,1359,1359,1359,1200,1200]
        })

        let listaCiclo = this.getValueList(data.submission, "trazabilidad2")
        listaCiclo.forEach(ciclo => {
            var anexos = ''
            for(var i in ciclo.anexos) {
                const tmp = ciclo.anexos[i].split("/")
                anexos += tmp[tmp.length-1] + ", "
            }

            anexos = anexos.substr(0, anexos.length-2)

            table_ciclo.addChildElement(
                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: ciclo.producto,
                                            font: 'Arial',
                                            size: 20
                                        })
                                    ],
                                    spacing: {
                                        after: 100,
                                        before: 100
                                    }
                                })
                            ],
                            columnSpan: 3
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: ciclo.parcela,
                                            font: 'Arial',
                                            size: 20
                                        })
                                    ],
                                    spacing: {
                                        after: 100,
                                        before: 100
                                    }
                                })
                            ],
                            columnSpan: 3
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: ciclo.superficie,
                                            font: 'Arial',
                                            size: 20
                                        })
                                    ],
                                    spacing: {
                                        after: 100,
                                        before: 100
                                    }
                                })
                            ],
                            columnSpan: 2
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: ciclo.estimacion,
                                            font: 'Arial',
                                            size: 20
                                        })
                                    ],
                                    spacing: {
                                        after: 100,
                                        before: 100
                                    }
                                })
                            ],
                            columnSpan: 2
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: `${anexos === '' ? 'Sin anexos' : anexos}`,
                                            font: 'Arial',
                                            size: 20
                                        })
                                    ],
                                    spacing: {
                                        after: 100,
                                        before: 100
                                    }
                                })
                            ],
                            columnSpan: 2
                        }),
                    ]
                })
            )
        });

        tableCellCiclo.addChildElement(new Paragraph({
            alignment: AlignmentType.CENTER,
            children: [
                new TextRun({
                    text: 'Agregue un cuadro con información del siguiente ciclo de producción',
                    font: 'Arial',
                    size: 20,
                    bold: true
                })
            ]
        }))
        tableCellCiclo.addChildElement(table_ciclo)
        tableCellCiclo.addChildElement(paragraphCiclo)
        tableRowCiclo.addChildElement(tableCellCiclo)
        table.addChildElement(tableRowCiclo)

        table.addChildElement(new TableRow({
            children: [
                new TableCell({
                    children: [
                        new Paragraph({
                            alignment: AlignmentType.LEFT,
                            children: [
                                new TextRun({
                                    text: 'Comentarios: ' + this.getValue(data.submission, "productos_certificados_4_comentarios2"),
                                    font: 'Arial',
                                    size: 20
                                })
                            ],
                            spacing: {
                                after: 100,
                                before: 100
                            }
                        })
                    ],
                    columnSpan: 10
                }),
            ]
        }))

        table.addChildElement(new TableRow({
            children: [
                new TableCell({
                    children: [
                        new Paragraph({
                            alignment: AlignmentType.LEFT,
                            children: [
                                new TextRun({
                                    text: '5',
                                    font: 'Arial',
                                    size: 20
                                })
                            ],
                            spacing: {
                                after: 100,
                                before: 100
                            }
                        })
                    ],
                    columnSpan: 1
                }),
                new TableCell({
                    children: [
                        new Paragraph({
                            alignment: AlignmentType.LEFT,
                            children: [
                                new TextRun({
                                    text: 'Se llenó y se dejó copia del formato de Salida de Inspección MTO-FINS-03-14',
                                    font: 'Arial',
                                    size: 20
                                })
                            ],
                            spacing: {
                                after: 100,
                                before: 100
                            }
                        })
                    ],
                    columnSpan: 7
                }),
                new TableCell({
                    children: [
                        new Paragraph({
                            alignment: AlignmentType.LEFT,
                            children: [
                                new TextRun({
                                    text: `${this.getValue(data.submission, "productos_certificados_5_conformidad") === 'C' ? '■' : '❏'} C ${this.getValue(data.submission, "productos_certificados_5_conformidad") === 'NC' ? '■' : '❏'} NC ${this.getValue(data.submission, "productos_certificados_5_conformidad") === 'NA' ? '■' : '❏'} NA`,
                                    font: 'Arial',
                                    size: 20
                                })
                            ],
                            spacing: {
                                after: 100,
                                before: 100
                            }
                        })
                    ],
                    columnSpan: 2
                })
            ]
        }))
        table.addChildElement(new TableRow({
            children: [
                new TableCell({
                    children: [
                        new Paragraph({
                            alignment: AlignmentType.LEFT,
                            children: [
                                new TextRun({
                                    text: 'Explique: ' + this.getValue(data.submission, "productos_certificados_5_pregunta"),
                                    font: 'Arial',
                                    size: 20,
                                })
                            ],
                            spacing: {
                                after: 100,
                                before: 100
                            }
                        })
                    ],
                    columnSpan: 10
                }),
            ]
        }))

        return table
    }

    public createResumen(data) {
        const table = new Table({
            rows: [
                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: 'No',
                                            font: 'Arial',
                                            size: 20,
                                            bold: true
                                        })
                                    ],
                                    spacing: {
                                        after: 100,
                                        before: 100
                                    }
                                })
                            ],
                            columnSpan: 1
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: 'Hallazgos',
                                            font: 'Arial',
                                            size: 20,
                                            bold: true
                                        })
                                    ],
                                    spacing: {
                                        after: 100,
                                        before: 100
                                    }
                                })
                            ],
                            columnSpan: 3
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: 'Apartado de la norma',
                                            font: 'Arial',
                                            size: 20,
                                            bold: true
                                        })
                                    ],
                                    spacing: {
                                        after: 100,
                                        before: 100
                                    }
                                })
                            ],
                            columnSpan: 2
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: 'Evicencia',
                                            font: 'Arial',
                                            size: 20,
                                            bold: true
                                        })
                                    ],
                                    spacing: {
                                        after: 100,
                                        before: 100
                                    }
                                })
                            ],
                            columnSpan: 3
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: 'Clasificación',
                                            font: 'Arial',
                                            size: 20,
                                            bold: true
                                        })
                                    ],
                                    spacing: {
                                        after: 100,
                                        before: 100
                                    }
                                })
                            ],
                            columnSpan: 2
                        }),
                    ]
                })
            ],
            margins: {
                left: 70,
                right: 70
            },
            width: {
                size: 100,
                type: WidthType.PERCENTAGE
            },
            columnWidths: [500,1359,1359,1359,1359,1359,1359,1359,1200,1200]
        })

        const listaComentarios = this.getValueList(data.submission, "hallazgos")
        listaComentarios.forEach((comentario, index) => {
            table.addChildElement(
                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: (index+1),
                                            font: 'Arial',
                                            size: 20,
                                            bold: true
                                        })
                                    ],
                                    spacing: {
                                        after: 100,
                                        before: 100
                                    }
                                })
                            ],
                            columnSpan: 1
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: comentario.resumen_hallazgos_hallazgo,
                                            font: 'Arial',
                                            size: 20,
                                            bold: true
                                        })
                                    ],
                                    spacing: {
                                        after: 100,
                                        before: 100
                                    }
                                })
                            ],
                            columnSpan: 3
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: comentario.resumen_hallazgos_apartado,
                                            font: 'Arial',
                                            size: 20,
                                            bold: true
                                        })
                                    ],
                                    spacing: {
                                        after: 100,
                                        before: 100
                                    }
                                })
                            ],
                            columnSpan: 2
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: comentario.resumen_hallazgos_evidencia,
                                            font: 'Arial',
                                            size: 20,
                                            bold: true
                                        })
                                    ],
                                    spacing: {
                                        after: 100,
                                        before: 100
                                    }
                                })
                            ],
                            columnSpan: 3
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: comentario.resumen_hallazgos_clasificación === 'Mayor' ? '■ Mayor' : '❏ Mayor',
                                            font: 'Arial',
                                            size: 20,
                                            bold: true
                                        })
                                    ],
                                    spacing: {
                                        after: 100,
                                        before: 100
                                    }
                                }),
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: comentario.resumen_hallazgos_clasificación === 'Menor' ? '■ Menor' : '❏ Menor',
                                            font: 'Arial',
                                            size: 20,
                                            bold: true
                                        })
                                    ],
                                    spacing: {
                                        after: 100,
                                        before: 100
                                    }
                                })
                            ],
                            columnSpan: 2
                        }),
                    ]
                })
            )
        });

        return table
    }

    public createResumenReunion(data) {
        const table = new Table({
            rows: [
                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: 'Resumen de Reunión Final',
                                            font: 'Arial',
                                            size: 20,
                                            bold: true
                                        })
                                    ],
                                    spacing: {
                                        after: 100,
                                        before: 100
                                    }
                                })
                            ],
                            shading: {
                                fill: '#C0C0C0',
                                val: ShadingType.PERCENT_80
                            },
                            columnSpan: 10
                        })
                    ]
                })
            ],
            margins: {
                left: 70,
                right: 70
            },
            width: {
                size: 100,
                type: WidthType.PERCENTAGE
            },
            columnWidths: [500,1359,1359,1359,1359,1359,1359,1359,1200,1200]
        })

        const listaComentarios = this.getValueList(data.submission, "resumen_reunion")
        listaComentarios.forEach((comentario, index) => {
            table.addChildElement(
                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: `${index+1}`,
                                            font: 'Arial',
                                            size: 20,
                                            bold: true
                                        })
                                    ],
                                    spacing: {
                                        after: 100,
                                        before: 100
                                    }
                                })
                            ],
                            columnSpan: 1
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: comentario.resumen_reunion_final,
                                            font: 'Arial',
                                            size: 20,
                                            bold: true
                                        })
                                    ],
                                    spacing: {
                                        after: 100,
                                        before: 100
                                    }
                                })
                            ],
                            columnSpan: 9
                        })
                    ]
                })
            )
        });

        return table
    }

    public createComentariosOperador(data) {
        const table = new Table({
            rows: [
                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: 'Comentarios del Operador',
                                            font: 'Arial',
                                            size: 20,
                                            bold: true
                                        })
                                    ],
                                    spacing: {
                                        after: 100,
                                        before: 100
                                    }
                                })
                            ],
                            shading: {
                                fill: '#C0C0C0',
                                val: ShadingType.PERCENT_80
                            },
                            columnSpan: 10
                        })
                    ]
                })
            ],
            margins: {
                left: 70,
                right: 70
            },
            width: {
                size: 100,
                type: WidthType.PERCENTAGE
            },
            columnWidths: [500,1359,1359,1359,1359,1359,1359,1359,1200,1200]
        })

        const listaComentarios = this.getValueList(data.submission, "resumen_comentarios")
        listaComentarios.forEach((comentario, index) => {
            table.addChildElement(
                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: `${index+1}`,
                                            font: 'Arial',
                                            size: 20,
                                            bold: true
                                        })
                                    ],
                                    spacing: {
                                        after: 100,
                                        before: 100
                                    }
                                })
                            ],
                            columnSpan: 1
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: comentario.resumen_comentarios_operador,
                                            font: 'Arial',
                                            size: 20,
                                            bold: true
                                        })
                                    ],
                                    spacing: {
                                        after: 100,
                                        before: 100
                                    }
                                })
                            ],
                            columnSpan: 9
                        })
                    ]
                })
            )
        });

        return table
    }

    public createSigns(data, document, signatureImage, signatureImage2) {
        const table = new Table({
            rows: [
                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: 'Confirmación de la información',
                                            font: 'Arial',
                                            size: 20,
                                            bold: true
                                        })
                                    ],
                                    spacing: {
                                        after: 100,
                                        before: 100
                                    }
                                })
                            ],
                            shading: {
                                fill: '#C0C0C0',
                                val: ShadingType.PERCENT_80
                            },
                            columnSpan: 2
                        })
                    ]
                }),
                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        signatureImage2 
                                            ? Media.addImage(document, signatureImage2.toString(), 200, 100) 
                                            : new TextRun({
                                                text: ''
                                            })
                                    ],
                                    spacing: {
                                        after: 100,
                                        before: 100
                                    }
                                }),
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: data.inspector,
                                            font: 'Arial',
                                            size: 20,
                                        })
                                    ]
                                }),
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: '______________________________________',
                                            font: 'Arial',
                                            size: 20,
                                        })
                                    ]
                                }),
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: 'Inspector',
                                            font: 'Arial',
                                            size: 20,
                                        })
                                    ],
                                    spacing: {
                                        after: 100,
                                        before: 100
                                    }
                                })
                            ]
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        signatureImage 
                                            ? Media.addImage(document, signatureImage.toString(), 200, 100) 
                                            : new TextRun({
                                                text: ''
                                            })
                                    ],
                                    spacing: {
                                        after: 100,
                                        before: 100
                                    }
                                }),
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: data.customer.Company_Name,
                                            font: 'Arial',
                                            size: 20,
                                        })
                                    ]
                                }),new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: '______________________________________',
                                            font: 'Arial',
                                            size: 20,
                                        })
                                    ]
                                }),
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: 'Operador',
                                            font: 'Arial',
                                            size: 20,
                                        })
                                    ],
                                    spacing: {
                                        after: 100,
                                        before: 100
                                    }
                                })
                            ]
                        })
                    ]
                })
            ],
            margins: {
                left: 70,
                right: 70
            },
            width: {
                size: 100,
                type: WidthType.PERCENTAGE
            },
            columnWidths: [6206, 6206]
        })

        return table
    }

    getValue(submission, name) {
        var value = null
        for (const i in submission) {
            const element = submission[i]
            if (element.name === name) {
                value = element.value
            }
        }
        if (value) return value
        else return ''
    }

    getValueList(submission, name) {
        var value = null
        for (const i in submission) {
            const element = submission[i]
            if (element.name === name) {
                value = element.value
            }
        }
        if (value) return value
        else return []
    }

    getFileList(value) {
        var anexos = ''
        for(var i in value) {
            const tmp = value[i].split("/")
            anexos += tmp[tmp.length-1] + ", "
        }

        anexos = anexos.substr(0, anexos.length-2)

        return anexos
    }
    // END MTO_FINS_03_2_4
}
