import { AlignmentType, Document, Paragraph, TextRun, Media, Header, Footer, Table, TableRow, TableCell, WidthType, PageNumber, ShadingType, PageBreak, BorderStyle } from "docx";
import { logo } from "../logo";
import { DatePipe } from '@angular/common';
import {userWithoutSign} from "../userWithoutSign"

export class DocumentCreatorMTO_FINS_03_1_1_v3 {
    public getBase64FromUrl = async (url) => {
        const data = await fetch(url, {
          headers: {
              'Cache-Control': 'no-cache, no-store, must-revalidate',
              'Pragma': 'no-cache',
              'Expires': 'Sat, 01 Jan 2000 00:00:00 GMT',
              'If-Modified-Since': '0'
          }
      });
        const blob = await data.blob();
        return new Promise((resolve) => {
          const reader = new FileReader();
          reader.readAsDataURL(blob);
          reader.onloadend = function () {
            const base64data = reader.result;
            resolve(base64data);
          }
        });
      }

    // INIT MTO_FINS_03_1_1
    public async create_MTO_FINS_03_1_1(data, typeInspection = '', signature = '', signature2 = ''): Promise<Document> {
        const document = new Document();
        const image = Media.addImage(document, logo, 50, 50);
        var datePipe = new DatePipe('es-MX');

        let signatureImage = null
        if (signature) {
            signatureImage = await this.getBase64FromUrl(signature)
        } else {
            signatureImage = userWithoutSign
        }

        let signatureImage2 = null
        if (signature2) {
        signatureImage2 = await this.getBase64FromUrl(signature2)
        } else {
        signatureImage2 = userWithoutSign
        }

        document.addSection({
            headers: {
                default: new Header({
                    children: [
                        new Paragraph({ alignment: AlignmentType.CENTER, children: [image], }),
                        new Paragraph({
                            alignment: AlignmentType.CENTER, children: [
                                new TextRun({
                                    children: [`MTO-FINS-03-1-1 | R 11 | 25-Junio-2024 | Pág. `, PageNumber.CURRENT],
                                    font: 'Book Antiqua',
                                    size: 20,
                                    bold: true
                                }),
                                new TextRun({
                                    font: 'Book Antiqua',
                                    size: 20,
                                    bold: true,
                                    children: [' de ', PageNumber.TOTAL_PAGES]
                                }),
                            ], spacing: { before: 200, after: 100 }
                        })
                    ]
                })
            },
            footers: {
                default: new Footer({
                    children: [
                        new Paragraph({ alignment: AlignmentType.CENTER }),
                        this.createFooter()
                    ]
                }),
            },
            children: [
                new Paragraph({
                    alignment: AlignmentType.CENTER,
                    children: [
                        new TextRun({
                            text: 'REPORTE DE INSPECCIÓN PRODUCCIÓN VEGETAL GENERAL',
                            font: 'Calibri (Body)',
                            size: 22,
                            bold: true
                        })
                    ],
                    spacing: { after: 300, before: 200 }
                }),
                this.createTableUDP(data, datePipe, typeInspection),
                new Paragraph({
                    spacing: {
                        after: 0,
                        before: 0
                    }
                }),
                this.createRegistros(data),
                new Paragraph({
                    spacing: {
                        after: 0,
                        before: 0
                    }
                }),
                this.createManejo(data),
                new Paragraph({
                    spacing: {
                        after: 0,
                        before: 0
                    }
                }),
                this.createProductosCertificados(data),
                new Paragraph({
                    children: [
                        new PageBreak()
                    ],
                    spacing: {
                        after: 0,
                        before: 0
                    }
                }),
                new Paragraph({
                    alignment: AlignmentType.CENTER,
                    children: [
                        new TextRun({
                            text: 'RESUMEN DE HALLAZGOS',
                            font: 'Calibri (Body)',
                            size: 22,
                            bold: true
                        })
                    ],
                    spacing: { after: 300, before: 200 }
                }),
                this.createResumen(data),
                new Paragraph({
                    spacing: {
                        after: 0,
                        before: 0
                    }
                }),
                this.createResumenReunion(data),
                new Paragraph({
                    spacing: {
                        after: 0,
                        before: 0
                    }
                }),
                this.createComentariosOperador(data),
                new Paragraph({
                    spacing: {
                        after: 0,
                        before: 0
                    }
                }),
                this.createSigns(data, document, signatureImage, signatureImage2),
            ],
            margins: {
                top: 350,
                bottom: 500,
                left: 1000,
                right: 1000
            }
        });

        return document;
    }

    public createFooter(): Table {
        const table = new Table({
            rows: [
                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: 'Elaboró:',
                                            font: 'Calibri (Cuerpo)',
                                            size: 22
                                        })
                                    ]
                                })
                            ],
                            width: {
                                size: 33,
                                type: WidthType.PERCENTAGE
                            }
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: 'Revisó:',
                                            font: 'Calibri (Cuerpo)',
                                            size: 22
                                        })
                                    ]
                                })
                            ],
                            width: {
                                size: 33,
                                type: WidthType.PERCENTAGE
                            }
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: 'Autorizó:',
                                            font: 'Calibri (Cuerpo)',
                                            size: 22
                                        })
                                    ]
                                }),
                            ],
                            width: {
                                size: 33,
                                type: WidthType.PERCENTAGE
                            }
                        })
                    ]
                }),
                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: 'Director de certificación',
                                            font: 'Calibri (Cuerpo)',
                                            size: 16,
                                            bold: true
                                        })
                                    ]
                                }),

                            ],
                            width: {
                                size: 33,
                                type: WidthType.PERCENTAGE
                            },
                            shading: {
                                fill: '#C0C0C0'
                            }
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: 'Responsable de calidad',
                                            font: 'Calibri (Cuerpo)',
                                            size: 16,
                                            bold: true
                                        })
                                    ]
                                }),

                            ],
                            width: {
                                size: 33,
                                type: WidthType.PERCENTAGE
                            },
                            shading: {
                                fill: '#C0C0C0'
                            }
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: 'Director general',
                                            font: 'Calibri (Cuerpo)',
                                            size: 16,
                                            bold: true
                                        })
                                    ]
                                }),
                            ],
                            width: {
                                size: 33,
                                type: WidthType.PERCENTAGE
                            },
                            shading: {
                                fill: '#C0C0C0'
                            }
                        })
                    ]
                })
            ],
            margins: {
                left: 70,
                right: 70
            },
            //9638
            columnWidths: [3213, 3213, 3212],
            alignment: AlignmentType.CENTER
        })
        return table
    }

    public createTableUDP(data, datePipe, tipo_inspeccion): Table {
        const table = new Table({
            rows: [
                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: 'Código de operación:',
                                            font: 'Arial',
                                            size: 22,
                                            bold: true
                                        })
                                    ],
                                    spacing: { before: 100, after: 100 }
                                })
                            ],
                            columnSpan: 1
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: data.customer.Id_Comercial,
                                            font: 'Arial',
                                            size: 22
                                        })
                                    ],
                                    spacing: { before: 100, after: 100 }
                                })
                            ],
                            columnSpan: 1
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: 'Fecha:',
                                            font: 'Arial',
                                            size: 22,
                                            bold: true
                                        })
                                    ],
                                    spacing: { before: 100, after: 100 }
                                })
                            ],
                            columnSpan: 1
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: `${datePipe.transform(data.inspectionDate, 'd-MMMM-y')}`,
                                            font: 'Arial',
                                            size: 22
                                        })
                                    ],
                                    spacing: { before: 100, after: 100 }
                                })
                            ],
                            columnSpan: 1
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: `Tipo de inspección:`,
                                            font: 'Arial',
                                            size: 22,
                                            bold: true
                                        })
                                    ],
                                    spacing: { before: 100, after: 100 }
                                })
                            ],
                            columnSpan: 1
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: `${tipo_inspeccion === 'Renovación' ? '■' : '❏'} Renovación`,
                                            font: 'Calibri (Body)',
                                            size: 20
                                        })
                                    ]
                                }),
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: `${tipo_inspeccion === 'Primer Inspección' ? '■' : '❏'} Primer Inspección`,
                                            font: 'Calibri (Body)',
                                            size: 20
                                        })
                                    ]
                                }),
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: `${tipo_inspeccion === 'Adicional' ? '■' : '❏'} Adicional`,
                                            font: 'Calibri (Body)',
                                            size: 20
                                        })
                                    ]
                                }),
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: `${tipo_inspeccion === 'Anunciada' ? '■' : '❏'} Anunciada`,
                                            font: 'Calibri (Body)',
                                            size: 20
                                        })
                                    ]
                                }),
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: `${tipo_inspeccion === 'No Anunciada' ? '■' : '❏'} No Anunciada`,
                                            font: 'Calibri (Body)',
                                            size: 20
                                        })
                                    ]
                                })
                            ],
                            columnSpan: 2
                        })
                    ]
                }),
                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: 'Inspector:',
                                            font: 'Arial',
                                            size: 22,
                                            bold: true
                                        })
                                    ],
                                    spacing: { before: 100, after: 100 }
                                })
                            ],
                            columnSpan: 1
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: data.inspector,
                                            font: 'Arial',
                                            size: 22
                                        })
                                    ],
                                    spacing: { before: 100, after: 100 }
                                })
                            ],
                            columnSpan: 6
                        })
                    ]
                }),
                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: 'Operador:',
                                            font: 'Arial',
                                            size: 22,
                                            bold: true
                                        })
                                    ],
                                    spacing: { before: 100, after: 100 }
                                })
                            ],
                            columnSpan: 1
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: data.customer.Company_Name,
                                            font: 'Arial',
                                            size: 22
                                        })
                                    ],
                                    spacing: { before: 100, after: 100 }
                                })
                            ],
                            columnSpan: 6
                        })
                    ]
                }),
            ],
            margins: {
                left: 70,
                right: 70
            },
            width: {
                size: 100,
                type: WidthType.PERCENTAGE,
            },
            columnWidths: [2192, 1802, 1792, 1972, 1892, 1802, 1792]
        })

        const unidades = this.getValueList(data.submission, 'unidades_produccion')
        unidades.forEach( (unidad, index) => {
            table.addChildElement(
                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: `${index === 0 ? 'Unidades de producción evaluadas:' : ''}`,
                                            font: 'Arial',
                                            size: 22,
                                            bold: true
                                        })
                                    ],
                                    spacing: { before: 100, after: 100 }
                                })
                            ],
                            columnSpan: 1,
                            borders: {
                              top: {
                                style: BorderStyle.NONE,
                                color: "white",
                                size: 0
                              },
                              bottom: {
                                style: BorderStyle.NONE,
                                color: "white",
                                size: 0
                              }
                            }
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: `Nombre:`,
                                            font: 'Arial',
                                            size: 22,
                                            bold: true
                                        })
                                    ],
                                    spacing: { before: 100 }
                                }),
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: unidad.unidades_produccion_nombre,
                                            font: 'Arial',
                                            size: 22
                                        })
                                    ],
                                    spacing: { after: 100 }
                                })
                            ],
                            columnSpan: 3
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: `Código:`,
                                            font: 'Arial',
                                            size: 22,
                                            bold: true
                                        })
                                    ],
                                    spacing: { before: 100 }
                                }),
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: unidad.unidades_produccion_codigo,
                                            font: 'Arial',
                                            size: 22
                                        })
                                    ],
                                    spacing: { after: 100 }
                                })
                            ],
                            columnSpan: 3
                        })
                    ]
                })
            )
            table.addChildElement(
                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: ``,
                                            font: 'Arial',
                                            size: 22,
                                            bold: true
                                        })
                                    ],
                                    spacing: { before: 100 }
                                })
                            ],
                            columnSpan: 1,
                            borders: {
                              top: {
                                style: BorderStyle.NONE,
                                color: "white",
                                size: 0
                              },
                              bottom: {
                                style: BorderStyle.NONE,
                                color: "white",
                                size: 0
                              }
                            }
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: `Superficie total:`,
                                            font: 'Arial',
                                            size: 22,
                                            bold: true
                                        })
                                    ],
                                    spacing: { before: 100 }
                                }),
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: unidad.unidades_produccion_superficie_total,
                                            font: 'Arial',
                                            size: 22
                                        })
                                    ],
                                    spacing: { after: 100 }
                                })
                            ],
                            columnSpan: 3
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: `Superficie orgánica:`,
                                            font: 'Arial',
                                            size: 22,
                                            bold: true
                                        })
                                    ],
                                    spacing: { before: 100 }
                                }),
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: unidad.unidades_produccion_superficie_organica,
                                            font: 'Arial',
                                            size: 22
                                        })
                                    ],
                                    spacing: { after: 100 }
                                })
                            ],
                            columnSpan: 3
                        })
                    ]
                }),
            )
        })

        const start = new Date(`2020-01-01 ${this.getValue(data.submission, 'duracion_inspeccion_hora_inicio')}`)
        const end = new Date(`2020-01-01 ${this.getValue(data.submission, 'duracion_inspeccion_hora_fin')}`)

        const duration = end.getHours() - start.getHours()

        table.addChildElement(
            new TableRow({
                children: [
                    new TableCell({
                        children: [
                            new Paragraph({
                                alignment: AlignmentType.LEFT,
                                children: [
                                    new TextRun({
                                        text: `Duración de la inspección:`,
                                        font: 'Arial',
                                        size: 22,
                                        bold: true
                                    })
                                ],
                                spacing: { before: 100 }
                            })
                        ],
                        columnSpan: 1
                    }),
                    new TableCell({
                        children: [
                            new Paragraph({
                                alignment: AlignmentType.LEFT,
                                children: [
                                    new TextRun({
                                        text: `Hora de inicio`,
                                        font: 'Arial',
                                        size: 22,
                                        bold: true
                                    })
                                ],
                                spacing: { before: 100 }
                            })
                        ],
                        columnSpan: 1
                    }),
                    new TableCell({
                        children: [
                            new Paragraph({
                                alignment: AlignmentType.LEFT,
                                children: [
                                    new TextRun({
                                        text: this.getValue(data.submission, 'duracion_inspeccion_hora_inicio'),
                                        font: 'Arial',
                                        size: 22
                                    })
                                ],
                                spacing: { before: 100 }
                            })
                        ],
                        columnSpan: 1
                    }),
                    new TableCell({
                        children: [
                            new Paragraph({
                                alignment: AlignmentType.LEFT,
                                children: [
                                    new TextRun({
                                        text: `Hora de Terminación`,
                                        font: 'Arial',
                                        size: 22,
                                        bold: true
                                    })
                                ],
                                spacing: { before: 100 }
                            })
                        ],
                        columnSpan: 1
                    }),
                    new TableCell({
                        children: [
                            new Paragraph({
                                alignment: AlignmentType.LEFT,
                                children: [
                                    new TextRun({
                                        text: this.getValue(data.submission, 'duracion_inspeccion_hora_fin'),
                                        font: 'Arial',
                                        size: 22
                                    })
                                ],
                                spacing: { before: 100 }
                            })
                        ],
                        columnSpan: 1
                    }),
                    new TableCell({
                        children: [
                            new Paragraph({
                                alignment: AlignmentType.LEFT,
                                children: [
                                    new TextRun({
                                        text: `Duración:`,
                                        font: 'Arial',
                                        size: 22,
                                        bold: true
                                    })
                                ],
                                spacing: { before: 100 }
                            })
                        ],
                        columnSpan: 1
                    }),
                    new TableCell({
                        children: [
                            new Paragraph({
                                alignment: AlignmentType.LEFT,
                                children: [
                                    new TextRun({
                                        text: `${duration} hrs.`,
                                        font: 'Arial',
                                        size: 22
                                    })
                                ],
                                spacing: { before: 100 }
                            })
                        ],
                        columnSpan: 1
                    })
                ]
            }),
        )

        const persons = this.getValueList(data.submission, 'personas_entrevistadas')
        persons.forEach( (person, index) => {
            table.addChildElement(
                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: `${index === 0 ? 'Personas entrevistadas:' : ''}`,
                                            font: 'Arial',
                                            size: 22,
                                            bold: true
                                        })
                                    ],
                                    spacing: { before: 100 }
                                })
                            ],
                            columnSpan: 1
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: `Nombre: `,
                                            font: 'Arial',
                                            size: 22,
                                            bold: true
                                        })
                                    ],
                                    spacing: { before: 100 }
                                }),
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: person.personas_entrevistadas_nombre,
                                            font: 'Arial',
                                            size: 22
                                        })
                                    ],
                                    spacing: { after: 100 }
                                })
                            ],
                            columnSpan: 3
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: `Cargo: `,
                                            font: 'Arial',
                                            size: 22,
                                            bold: true
                                        })
                                    ],
                                    spacing: { before: 100 }
                                }),
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: person.personas_entrevistadas_cargo,
                                            font: 'Arial',
                                            size: 22
                                        })
                                    ],
                                    spacing: { after: 100 }
                                })
                            ],
                            columnSpan: 3
                        })
                    ]
                })
            )
        })

        return table
    }

    public createRegistros(data) {
        const table = new Table({
            rows: [
                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: 'REGISTROS',
                                            font: 'Calibri (Body)',
                                            size: 28,
                                            bold: true
                                        })
                                    ]
                                })
                            ],
                            shading: {
                                fill: '#C0C0C0',
                                val: ShadingType.PERCENT_80
                            },
                            columnSpan: 10
                        })
                    ]
                }),
                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: '1',
                                            font: 'Arial',
                                            size: 20
                                        })
                                    ],
                                    spacing: {
                                        after: 100,
                                        before: 100
                                    }
                                })
                            ],
                            columnSpan: 1
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: '¿El Plan de Manejo Orgánico refleja la realidad de la unidad de producción?',
                                            font: 'Arial',
                                            size: 20
                                        })
                                    ],
                                    spacing: {
                                        after: 100,
                                        before: 100
                                    }
                                })
                            ],
                            columnSpan: 7
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: `${this.getValue(data.submission, "registros_1_conformidad") === 'C' ? '■' : '❏'} C ${this.getValue(data.submission, "registros_1_conformidad") === 'NC' ? '■' : '❏'} NC ${this.getValue(data.submission, "registros_1_conformidad") === 'NA' ? '■' : '❏'} NA`,
                                            font: 'Arial',
                                            size: 20
                                        })
                                    ],
                                    spacing: {
                                        after: 100,
                                        before: 100
                                    }
                                })
                            ],
                            columnSpan: 2
                        })
                    ]
                }),
                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text:`Anexos: ${this.getFileList(this.getValue(data.submission, "registros_1_adjuntos_url"))}`,
                                            font: 'Arial',
                                            size: 20
                                        })
                                    ],
                                    spacing: {
                                        after: 100,
                                        before: 100
                                    }
                                })
                            ],
                            columnSpan: 10
                        }),
                    ]
                }),
                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: 'Explique: ' + this.getValue(data.submission, "registros_1_pregunta"),
                                            font: 'Arial',
                                            size: 20
                                        })
                                    ],
                                    spacing: {
                                        after: 100,
                                        before: 100
                                    }
                                })
                            ],
                            columnSpan: 10
                        }),
                    ]
                }),
                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: '2',
                                            font: 'Arial',
                                            size: 20
                                        })
                                    ],
                                    spacing: {
                                        after: 100,
                                        before: 100
                                    }
                                })
                            ],
                            columnSpan: 1
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: '¿El operador cuenta con registros (bitácoras) que reflejan las actividades desarrolladas regularmente en la unidad de producción?',
                                            font: 'Arial',
                                            size: 20
                                        })
                                    ],
                                    spacing: {
                                        after: 100,
                                        before: 100
                                    }
                                })
                            ],
                            columnSpan: 7
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: `${this.getValue(data.submission, "registros_2_conformidad") === 'C' ? '■' : '❏'} C ${this.getValue(data.submission, "registros_2_conformidad") === 'NC' ? '■' : '❏'} NC ${this.getValue(data.submission, "registros_2_conformidad") === 'NA' ? '■' : '❏'} NA`,
                                            font: 'Arial',
                                            size: 20
                                        })
                                    ],
                                    spacing: {
                                        after: 100,
                                        before: 100
                                    }
                                })
                            ],
                            columnSpan: 2
                        })
                    ]
                }),
                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text:`Anexos: ${this.getFileList(this.getValue(data.submission, "registros_2_adjuntos_url"))}`,
                                            font: 'Arial',
                                            size: 20
                                        })
                                    ],
                                    spacing: {
                                        after: 100,
                                        before: 100
                                    }
                                })
                            ],
                            columnSpan: 10
                        }),
                    ]
                }),
                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: 'Explique: ' + this.getValue(data.submission, "registros_2_pregunta"),
                                            font: 'Arial',
                                            size: 20
                                        })
                                    ],
                                    spacing: {
                                        after: 100,
                                        before: 100
                                    }
                                })
                            ],
                            columnSpan: 10
                        }),
                    ]
                }),
                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: '3',
                                            font: 'Arial',
                                            size: 20
                                        })
                                    ],
                                    spacing: {
                                        after: 100,
                                        before: 100
                                    }
                                })
                            ],
                            columnSpan: 1
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: '¿Los registros (bitácoras) revisados, reflejan el uso de prácticas y sustancias permitidas?',
                                            font: 'Arial',
                                            size: 20
                                        })
                                    ],
                                    spacing: {
                                        after: 100,
                                        before: 100
                                    }
                                })
                            ],
                            columnSpan: 7
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: `${this.getValue(data.submission, "registros_3_conformidad") === 'C' ? '■' : '❏'} C ${this.getValue(data.submission, "registros_3_conformidad") === 'NC' ? '■' : '❏'} NC ${this.getValue(data.submission, "registros_3_conformidad") === 'NA' ? '■' : '❏'} NA`,
                                            font: 'Arial',
                                            size: 20
                                        })
                                    ],
                                    spacing: {
                                        after: 100,
                                        before: 100
                                    }
                                })
                            ],
                            columnSpan: 2
                        })
                    ]
                }),
                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text:`Anexos: ${this.getFileList(this.getValue(data.submission, "registros_3_adjuntos_url"))}`,
                                            font: 'Arial',
                                            size: 20
                                        })
                                    ],
                                    spacing: {
                                        after: 100,
                                        before: 100
                                    }
                                })
                            ],
                            columnSpan: 10
                        }),
                    ]
                }),
                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: 'Explique: ' + this.getValue(data.submission, "registros_3_pregunta"),
                                            font: 'Arial',
                                            size: 20
                                        })
                                    ],
                                    spacing: {
                                        after: 100,
                                        before: 100
                                    }
                                })
                            ],
                            columnSpan: 10
                        }),
                    ]
                }),
                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: '4',
                                            font: 'Arial',
                                            size: 20
                                        })
                                    ],
                                    spacing: {
                                        after: 100,
                                        before: 100
                                    }
                                })
                            ],
                            columnSpan: 1
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: '¿Los registros (bitácoras) incluyen la información necesaria (materiales, fecha de aplicación, cantidad, cultivo, unidad de producción)?',
                                            font: 'Arial',
                                            size: 20
                                        })
                                    ],
                                    spacing: {
                                        after: 100,
                                        before: 100
                                    }
                                })
                            ],
                            columnSpan: 7
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: `${this.getValue(data.submission, "registros_4_conformidad") === 'C' ? '■' : '❏'} C ${this.getValue(data.submission, "registros_4_conformidad") === 'NC' ? '■' : '❏'} NC ${this.getValue(data.submission, "registros_4_conformidad") === 'NA' ? '■' : '❏'} NA`,
                                            font: 'Arial',
                                            size: 20
                                        })
                                    ],
                                    spacing: {
                                        after: 100,
                                        before: 100
                                    }
                                })
                            ],
                            columnSpan: 2
                        })
                    ]
                }),
                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text:`Anexos: ${this.getFileList(this.getValue(data.submission, "registros_4_adjuntos_url"))}`,
                                            font: 'Arial',
                                            size: 20
                                        })
                                    ],
                                    spacing: {
                                        after: 100,
                                        before: 100
                                    }
                                })
                            ],
                            columnSpan: 10
                        }),
                    ]
                }),
                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: 'Explique: ' + this.getValue(data.submission, "registros_4_pregunta"),
                                            font: 'Arial',
                                            size: 20
                                        })
                                    ],
                                    spacing: {
                                        after: 100,
                                        before: 100
                                    }
                                })
                            ],
                            columnSpan: 10
                        }),
                    ]
                }),
                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: '5',
                                            font: 'Arial',
                                            size: 20
                                        })
                                    ],
                                    spacing: {
                                        after: 100,
                                        before: 100
                                    }
                                })
                            ],
                            columnSpan: 1
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: '¿Las etiquetas, uso de logos y referencias a la calidad orgánica del producto son conformes?',
                                            font: 'Arial',
                                            size: 20
                                        })
                                    ],
                                    spacing: {
                                        after: 100,
                                        before: 100
                                    }
                                })
                            ],
                            columnSpan: 7
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: `${this.getValue(data.submission, "registros_5_conformidad") === 'C' ? '■' : '❏'} C ${this.getValue(data.submission, "registros_5_conformidad") === 'NC' ? '■' : '❏'} NC ${this.getValue(data.submission, "registros_5_conformidad") === 'NA' ? '■' : '❏'} NA`,
                                            font: 'Arial',
                                            size: 20
                                        })
                                    ],
                                    spacing: {
                                        after: 100,
                                        before: 100
                                    }
                                })
                            ],
                            columnSpan: 2
                        })
                    ]
                }),
                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text:`Anexos: ${this.getFileList(this.getValue(data.submission, "registros_5_adjuntos_url"))}`,
                                            font: 'Arial',
                                            size: 20
                                        })
                                    ],
                                    spacing: {
                                        after: 100,
                                        before: 100
                                    }
                                })
                            ],
                            columnSpan: 10
                        }),
                    ]
                }),
                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: 'Explique: ' + this.getValue(data.submission, "registros_5_pregunta"),
                                            font: 'Arial',
                                            size: 20
                                        })
                                    ],
                                    spacing: {
                                        after: 100,
                                        before: 100
                                    }
                                })
                            ],
                            columnSpan: 10
                        }),
                    ]
                }),
                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: '6',
                                            font: 'Arial',
                                            size: 20
                                        })
                                    ],
                                    spacing: {
                                        after: 100,
                                        before: 100
                                    }
                                })
                            ],
                            columnSpan: 1
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: '¿El operador tiene un registro completo de quejas y atención a las quejas?',
                                            font: 'Arial',
                                            size: 20
                                        })
                                    ],
                                    spacing: {
                                        after: 100,
                                        before: 100
                                    }
                                })
                            ],
                            columnSpan: 7
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: `${this.getValue(data.submission, "registros_6_conformidad") === 'C' ? '■' : '❏'} C ${this.getValue(data.submission, "registros_6_conformidad") === 'NC' ? '■' : '❏'} NC ${this.getValue(data.submission, "registros_6_conformidad") === 'NA' ? '■' : '❏'} NA`,
                                            font: 'Arial',
                                            size: 20
                                        })
                                    ],
                                    spacing: {
                                        after: 100,
                                        before: 100
                                    }
                                })
                            ],
                            columnSpan: 2
                        })
                    ]
                }),
                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text:`Anexos: ${this.getFileList(this.getValue(data.submission, "registros_6_adjuntos_url"))}`,
                                            font: 'Arial',
                                            size: 20
                                        })
                                    ],
                                    spacing: {
                                        after: 100,
                                        before: 100
                                    }
                                })
                            ],
                            columnSpan: 10
                        }),
                    ]
                }),
                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: 'Explique: ' + this.getValue(data.submission, "registros_6_pregunta"),
                                            font: 'Arial',
                                            size: 20
                                        })
                                    ],
                                    spacing: {
                                        after: 100,
                                        before: 100
                                    }
                                })
                            ],
                            columnSpan: 10
                        }),
                    ]
                }),
                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: '7',
                                            font: 'Arial',
                                            size: 20
                                        })
                                    ],
                                    spacing: {
                                        after: 100,
                                        before: 100
                                    }
                                })
                            ],
                            columnSpan: 1
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: '¿Los registros son conservados por al menos 5 años?',
                                            font: 'Arial',
                                            size: 20
                                        })
                                    ],
                                    spacing: {
                                        after: 100,
                                        before: 100
                                    }
                                })
                            ],
                            columnSpan: 7
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: `${this.getValue(data.submission, "registros_7_conformidad") === 'C' ? '■' : '❏'} C ${this.getValue(data.submission, "registros_7_conformidad") === 'NC' ? '■' : '❏'} NC ${this.getValue(data.submission, "registros_7_conformidad") === 'NA' ? '■' : '❏'} NA`,
                                            font: 'Arial',
                                            size: 20
                                        })
                                    ],
                                    spacing: {
                                        after: 100,
                                        before: 100
                                    }
                                })
                            ],
                            columnSpan: 2
                        })
                    ]
                }),
                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text:`Anexos: ${this.getFileList(this.getValue(data.submission, "registros_7_adjuntos_url"))}`,
                                            font: 'Arial',
                                            size: 20
                                        })
                                    ],
                                    spacing: {
                                        after: 100,
                                        before: 100
                                    }
                                })
                            ],
                            columnSpan: 10
                        }),
                    ]
                }),
                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: 'Explique: ' + this.getValue(data.submission, "registros_7_pregunta"),
                                            font: 'Arial',
                                            size: 20
                                        })
                                    ],
                                    spacing: {
                                        after: 100,
                                        before: 100
                                    }
                                })
                            ],
                            columnSpan: 10
                        }),
                    ]
                }),
                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: '8',
                                            font: 'Arial',
                                            size: 20
                                        })
                                    ],
                                    spacing: {
                                        after: 100,
                                        before: 100
                                    }
                                })
                            ],
                            columnSpan: 1
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: '¿Las evidencias y resgistros para el reconocimiento retroactivo del periodo de conversión se conservan por al menos 3 años?',
                                            font: 'Arial',
                                            size: 20
                                        })
                                    ],
                                    spacing: {
                                        after: 100,
                                        before: 100
                                    }
                                })
                            ],
                            columnSpan: 7
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: `${this.getValue(data.submission, "registros_8_conformidad") === 'C' ? '■' : '❏'} C ${this.getValue(data.submission, "registros_8_conformidad") === 'NC' ? '■' : '❏'} NC ${this.getValue(data.submission, "registros_8_conformidad") === 'NA' ? '■' : '❏'} NA`,
                                            font: 'Arial',
                                            size: 20
                                        })
                                    ],
                                    spacing: {
                                        after: 100,
                                        before: 100
                                    }
                                })
                            ],
                            columnSpan: 2
                        })
                    ]
                }),
                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text:`Anexos: ${this.getFileList(this.getValue(data.submission, "registros_8_adjuntos_url"))}`,
                                            font: 'Arial',
                                            size: 20
                                        })
                                    ],
                                    spacing: {
                                        after: 100,
                                        before: 100
                                    }
                                })
                            ],
                            columnSpan: 10
                        }),
                    ]
                }),
                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: 'Explique: ' + this.getValue(data.submission, "registros_8_pregunta"),
                                            font: 'Arial',
                                            size: 20
                                        })
                                    ],
                                    spacing: {
                                        after: 100,
                                        before: 100
                                    }
                                })
                            ],
                            columnSpan: 10
                        }),
                    ]
                }),
                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: '9',
                                            font: 'Arial',
                                            size: 20
                                        })
                                    ],
                                    spacing: {
                                        after: 100,
                                        before: 100
                                    }
                                })
                            ],
                            columnSpan: 1
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: '¿Se dio seguimiento a las No Conformidades detectadas en la evaluación anterior?',
                                            font: 'Arial',
                                            size: 20
                                        })
                                    ],
                                    spacing: {
                                        after: 100,
                                        before: 100
                                    }
                                })
                            ],
                            columnSpan: 7
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: `${this.getValue(data.submission, "registros_9_conformidad") === 'C' ? '■' : '❏'} C ${this.getValue(data.submission, "registros_9_conformidad") === 'NC' ? '■' : '❏'} NC ${this.getValue(data.submission, "registros_9_conformidad") === 'NA' ? '■' : '❏'} NA`,
                                            font: 'Arial',
                                            size: 20
                                        })
                                    ],
                                    spacing: {
                                        after: 100,
                                        before: 100
                                    }
                                })
                            ],
                            columnSpan: 2
                        })
                    ]
                }),
                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text:`Anexos: ${this.getFileList(this.getValue(data.submission, "registros_9_adjuntos_url"))}`,
                                            font: 'Arial',
                                            size: 20
                                        })
                                    ],
                                    spacing: {
                                        after: 100,
                                        before: 100
                                    }
                                })
                            ],
                            columnSpan: 10
                        }),
                    ]
                }),
                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: 'Explique: ' + this.getValue(data.submission, "registros_9_pregunta"),
                                            font: 'Arial',
                                            size: 20
                                        })
                                    ],
                                    spacing: {
                                        after: 100,
                                        before: 100
                                    }
                                })
                            ],
                            columnSpan: 10
                        }),
                    ]
                }),
            ],
            margins: {
                left: 70,
                right: 70
            },
            width: {
                size: 100,
                type: WidthType.PERCENTAGE
            },
            columnWidths: [500,1359,1359,1359,1359,1359,1359,1359,1200,1200]
        })

        let tableRowConformidades = new TableRow({
            children: []
        })

        let tableCellConformidades = new TableCell({
            children: []
        })

        let paragraphConformidades = new Paragraph({
            alignment: AlignmentType.LEFT,
            children: [
                new TextRun({
                    text: '',
                    font: 'Arial',
                    size: 20
                })
            ]
        })

        let table_conformidades = new Table({
            rows: [
                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: 'No Conformidad',
                                            font: 'Arial',
                                            size: 20,
                                            bold: true
                                        })
                                    ]
                                })
                            ],
                            shading: {
                                fill: '#C0C0C0',
                                val: ShadingType.PERCENT_80
                            },
                            columnSpan: 4
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: 'Acción correctiva propuesta',
                                            font: 'Arial',
                                            size: 20,
                                            bold: true
                                        })
                                    ]
                                })
                            ],
                            shading: {
                                fill: '#C0C0C0',
                                val: ShadingType.PERCENT_80
                            },
                            columnSpan: 4
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: 'Situación encontrada',
                                            font: 'Arial',
                                            size: 20,
                                            bold: true
                                        })
                                    ]
                                })
                            ],
                            shading: {
                                fill: '#C0C0C0',
                                val: ShadingType.PERCENT_80
                            },
                            columnSpan: 4
                        }),
                    ]
                })
            ],
            margins: {
                left: 70,
                right: 70
            },
            width: {
                size: 100,
                type: WidthType.PERCENTAGE
            },
            columnWidths: [500,1359,1359,1359,1359,1359,1359,1359,1200,1200]
        })

        let listaConformidades = this.getValueList(data.submission, "registros_no_conformidades")
        listaConformidades.forEach(conformidad => {
            table_conformidades.addChildElement(
                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: conformidad.registros_no_conformidades_nombre,
                                            font: 'Arial',
                                            size: 20
                                        })
                                    ],
                                    spacing: {
                                        after: 100,
                                        before: 100
                                    }
                                })
                            ],
                            columnSpan: 4
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: conformidad.registros_no_conformidades_acciones_correctiva,
                                            font: 'Arial',
                                            size: 20
                                        })
                                    ],
                                    spacing: {
                                        after: 100,
                                        before: 100
                                    }
                                })
                            ],
                            columnSpan: 4
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: conformidad.registros_no_conformidades_situacion_encontrada,
                                            font: 'Arial',
                                            size: 20
                                        })
                                    ],
                                    spacing: {
                                        after: 100,
                                        before: 100
                                    }
                                })
                            ],
                            columnSpan: 4
                        }),
                    ]
                })
            )
        });

        tableCellConformidades.addChildElement(paragraphConformidades)
        tableCellConformidades.addChildElement(table_conformidades)
        tableCellConformidades.addChildElement(paragraphConformidades)
        tableRowConformidades.addChildElement(tableCellConformidades)
        table.addChildElement(tableRowConformidades)


        const question_10_1 = new TableRow({
            children: [
                new TableCell({
                    children: [
                        new Paragraph({
                            alignment: AlignmentType.LEFT,
                            children: [
                                new TextRun({
                                    text: '10',
                                    font: 'Arial',
                                    size: 20
                                })
                            ],
                            spacing: {
                                after: 100,
                                before: 100
                            }
                        })
                    ],
                    columnSpan: 1
                }),
                new TableCell({
                    children: [
                        new Paragraph({
                            alignment: AlignmentType.LEFT,
                            children: [
                                new TextRun({
                                    text: '¿El operador implementó las medidas definidas en el Art. 27 de reglamento UE 848/2018 en caso de “sospecha”?',
                                    font: 'Arial',
                                    size: 20
                                })
                            ],
                            spacing: {
                                after: 100,
                                before: 100
                            }
                        })
                    ],
                    columnSpan: 7
                }),
                new TableCell({
                    children: [
                        new Paragraph({
                            alignment: AlignmentType.LEFT,
                            children: [
                                new TextRun({
                                    text: `${this.getValue(data.submission, "registros_10_conformidad") === 'C' ? '■' : '❏'} C ${this.getValue(data.submission, "registros_10_conformidad") === 'NC' ? '■' : '❏'} NC ${this.getValue(data.submission, "registros_10_conformidad") === 'NA' ? '■' : '❏'} NA`,
                                    font: 'Arial',
                                    size: 20
                                })
                            ],
                            spacing: {
                                after: 100,
                                before: 100
                            }
                        })
                    ],
                    columnSpan: 2
                })
            ]
        })
        const question_10_2 = new TableRow({
            children: [
                new TableCell({
                    children: [
                        new Paragraph({
                            alignment: AlignmentType.LEFT,
                            children: [
                                new TextRun({
                                    text:`Anexos: ${this.getFileList(this.getValue(data.submission, "registros_10_adjuntos_url"))}`,
                                    font: 'Arial',
                                    size: 20
                                })
                            ],
                            spacing: {
                                after: 100,
                                before: 100
                            }
                        })
                    ],
                    columnSpan: 10
                }),
            ]
        })
        const question_10_3 = new TableRow({
            children: [
                new TableCell({
                    children: [
                        new Paragraph({
                            alignment: AlignmentType.LEFT,
                            children: [
                                new TextRun({
                                    text: 'Explique: ' + this.getValue(data.submission, "registros_10_pregunta"),
                                    font: 'Arial',
                                    size: 20
                                })
                            ],
                            spacing: {
                                after: 100,
                                before: 100
                            }
                        })
                    ],
                    columnSpan: 10
                }),
            ]
        })
        table.addChildElement(question_10_1)
        table.addChildElement(question_10_2)
        table.addChildElement(question_10_3)

        const question_11_1 = new TableRow({
            children: [
                new TableCell({
                    children: [
                        new Paragraph({
                            alignment: AlignmentType.LEFT,
                            children: [
                                new TextRun({
                                    text: '11',
                                    font: 'Arial',
                                    size: 20
                                })
                            ],
                            spacing: {
                                after: 100,
                                before: 100
                            }
                        })
                    ],
                    columnSpan: 1
                }),
                new TableCell({
                    children: [
                        new Paragraph({
                            alignment: AlignmentType.LEFT,
                            children: [
                                new TextRun({
                                    text: '¿Se encontró evidencia de que el plan de manejo orgánico actualizado se ha implementado completamente por un periodo de al menos 12 meses y el terreno ha estado libre de sustancias no permitidas en los últimos 36 meses?',
                                    font: 'Arial',
                                    size: 20
                                })
                            ],
                            spacing: {
                                after: 100,
                                before: 100
                            }
                        })
                    ],
                    columnSpan: 7
                }),
                new TableCell({
                    children: [
                        new Paragraph({
                            alignment: AlignmentType.LEFT,
                            children: [
                                new TextRun({
                                    text: `${this.getValue(data.submission, "registros_11_conformidad") === 'C' ? '■' : '❏'} C ${this.getValue(data.submission, "registros_11_conformidad") === 'NC' ? '■' : '❏'} NC ${this.getValue(data.submission, "registros_11_conformidad") === 'NA' ? '■' : '❏'} NA`,
                                    font: 'Arial',
                                    size: 20
                                })
                            ],
                            spacing: {
                                after: 100,
                                before: 100
                            }
                        })
                    ],
                    columnSpan: 2
                })
            ]
        })
        const question_11_2 = new TableRow({
            children: [
                new TableCell({
                    children: [
                        new Paragraph({
                            alignment: AlignmentType.LEFT,
                            children: [
                                new TextRun({
                                    text:`Anexos: ${this.getFileList(this.getValue(data.submission, "registros_11_adjuntos_url"))}`,
                                    font: 'Arial',
                                    size: 20
                                })
                            ],
                            spacing: {
                                after: 100,
                                before: 100
                            }
                        })
                    ],
                    columnSpan: 10
                }),
            ]
        })
        const question_11_3 = new TableRow({
            children: [
                new TableCell({
                    children: [
                        new Paragraph({
                            alignment: AlignmentType.LEFT,
                            children: [
                                new TextRun({
                                    text: 'Explique: ' + this.getValue(data.submission, "registros_11_pregunta"),
                                    font: 'Arial',
                                    size: 20
                                })
                            ],
                            spacing: {
                                after: 100,
                                before: 100
                            }
                        })
                    ],
                    columnSpan: 10
                }),
            ]
        })
        table.addChildElement(question_11_1)
        table.addChildElement(question_11_2)
        table.addChildElement(question_11_3)

        return table
    }

    public createManejo(data) {
        const table = new Table({
            rows: [
                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: 'MANEJO',
                                            font: 'Calibri (Body)',
                                            size: 28,
                                            bold: true
                                        })
                                    ]
                                })
                            ],
                            shading: {
                                fill: '#C0C0C0',
                                val: ShadingType.PERCENT_80
                            },
                            columnSpan: 10
                        })
                    ]
                }),
                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: '1',
                                            font: 'Arial',
                                            size: 20
                                        })
                                    ],
                                    spacing: {
                                        after: 100,
                                        before: 100
                                    }
                                })
                            ],
                            columnSpan: 1
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: '¿Se encontró evidencia de que el terreno cumple con el periodo de conversión?',
                                            font: 'Arial',
                                            size: 20
                                        }),
                                      new TextRun({
                                        text: 'LPO/UE - 2 años antes de cosecha para cultivos anuales y forrajes, y 3 años antes de cosecha para perennes',
                                        font: 'Arial',
                                        size: 20
                                      }),
                                      new TextRun({
                                        text: 'COR - 36 meses antes de la primer cosecha orgánica',
                                        font: 'Arial',
                                        size: 20
                                      })
                                    ],
                                    spacing: {
                                        after: 100,
                                        before: 100
                                    }
                                })
                            ],
                            columnSpan: 7
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: `${this.getValue(data.submission, "manejo_1_conformidad") === 'C' ? '■' : '❏'} C ${this.getValue(data.submission, "manejo_1_conformidad") === 'NC' ? '■' : '❏'} NC ${this.getValue(data.submission, "manejo_1_conformidad") === 'NA' ? '■' : '❏'} NA`,
                                            font: 'Arial',
                                            size: 20
                                        })
                                    ],
                                    spacing: {
                                        after: 100,
                                        before: 100
                                    }
                                })
                            ],
                            columnSpan: 2
                        })
                    ]
                }),
                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text:`Anexos: ${this.getFileList(this.getValue(data.submission, "manejo_1_adjuntos_url"))}`,
                                            font: 'Arial',
                                            size: 20
                                        })
                                    ],
                                    spacing: {
                                        after: 100,
                                        before: 100
                                    }
                                })
                            ],
                            columnSpan: 10
                        }),
                    ]
                }),
                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: 'Explique: ' + this.getValue(data.submission, "manejo_1_pregunta"),
                                            font: 'Arial',
                                            size: 20
                                        })
                                    ],
                                    spacing: {
                                        after: 100,
                                        before: 100
                                    }
                                })
                            ],
                            columnSpan: 10
                        }),
                    ]
                }),
                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: '2',
                                            font: 'Arial',
                                            size: 20
                                        })
                                    ],
                                    spacing: {
                                        after: 100,
                                        before: 100
                                    }
                                })
                            ],
                            columnSpan: 1
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: '¿El inspector llenó un informe para cada una de las unidades de producción para las que se esta solicitando reconocimiento retroactivo del periodo de conversión?',
                                            font: 'Arial',
                                            size: 20
                                        })
                                    ],
                                    spacing: {
                                        after: 100,
                                        before: 100
                                    }
                                })
                            ],
                            columnSpan: 7
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: `${this.getValue(data.submission, "manejo_2_conformidad") === 'C' ? '■' : '❏'} C ${this.getValue(data.submission, "manejo_2_conformidad") === 'NC' ? '■' : '❏'} NC ${this.getValue(data.submission, "manejo_2_conformidad") === 'NA' ? '■' : '❏'} NA`,
                                            font: 'Arial',
                                            size: 20
                                        })
                                    ],
                                    spacing: {
                                        after: 100,
                                        before: 100
                                    }
                                })
                            ],
                            columnSpan: 2
                        })
                    ]
                }),
                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text:`Anexos: ${this.getFileList(this.getValue(data.submission, "manejo_2_adjuntos_url"))}`,
                                            font: 'Arial',
                                            size: 20
                                        })
                                    ],
                                    spacing: {
                                        after: 100,
                                        before: 100
                                    }
                                })
                            ],
                            columnSpan: 10
                        }),
                    ]
                }),
                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: 'Explique: ' + this.getValue(data.submission, "manejo_2_pregunta"),
                                            font: 'Arial',
                                            size: 20
                                        })
                                    ],
                                    spacing: {
                                        after: 100,
                                        before: 100
                                    }
                                })
                            ],
                            columnSpan: 10
                        }),
                    ]
                }),
                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: '3',
                                            font: 'Arial',
                                            size: 20
                                        })
                                    ],
                                    spacing: {
                                        after: 100,
                                        before: 100
                                    }
                                })
                            ],
                            columnSpan: 1
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: '¿Se tienen en uso prácticas para controlar los riesgos de contaminación por áreas colindantes?',
                                            font: 'Arial',
                                            size: 20
                                        })
                                    ],
                                    spacing: {
                                        after: 100,
                                        before: 100
                                    }
                                })
                            ],
                            columnSpan: 7
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: `${this.getValue(data.submission, "manejo_3_conformidad") === 'C' ? '■' : '❏'} C ${this.getValue(data.submission, "manejo_3_conformidad") === 'NC' ? '■' : '❏'} NC ${this.getValue(data.submission, "manejo_3_conformidad") === 'NA' ? '■' : '❏'} NA`,
                                            font: 'Arial',
                                            size: 20
                                        })
                                    ],
                                    spacing: {
                                        after: 100,
                                        before: 100
                                    }
                                })
                            ],
                            columnSpan: 2
                        })
                    ]
                }),
                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text:`Anexos: ${this.getFileList(this.getValue(data.submission, "manejo_3_adjuntos_url"))}`,
                                            font: 'Arial',
                                            size: 20
                                        })
                                    ],
                                    spacing: {
                                        after: 100,
                                        before: 100
                                    }
                                })
                            ],
                            columnSpan: 10
                        }),
                    ]
                }),
                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: 'Explique: ' + this.getValue(data.submission, "manejo_3_pregunta"),
                                            font: 'Arial',
                                            size: 20
                                        })
                                    ],
                                    spacing: {
                                        after: 100,
                                        before: 100
                                    }
                                })
                            ],
                            columnSpan: 10
                        }),
                    ]
                }),
                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: '4',
                                            font: 'Arial',
                                            size: 20
                                        })
                                    ],
                                    spacing: {
                                        after: 100,
                                        before: 100
                                    }
                                })
                            ],
                            columnSpan: 1
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: '¿Se tiene una clara separación de productos convencionales de los orgánicos (producción paralela) y se cumple con requisitos de excepción COR?',
                                            font: 'Arial',
                                            size: 20
                                        })
                                    ],
                                    spacing: {
                                        after: 100,
                                        before: 100
                                    }
                                })
                            ],
                            columnSpan: 7
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: `${this.getValue(data.submission, "manejo_4_conformidad") === 'C' ? '■' : '❏'} C ${this.getValue(data.submission, "manejo_4_conformidad") === 'NC' ? '■' : '❏'} NC ${this.getValue(data.submission, "manejo_4_conformidad") === 'NA' ? '■' : '❏'} NA`,
                                            font: 'Arial',
                                            size: 20
                                        })
                                    ],
                                    spacing: {
                                        after: 100,
                                        before: 100
                                    }
                                })
                            ],
                            columnSpan: 2
                        })
                    ]
                }),
                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text:`Anexos: ${this.getFileList(this.getValue(data.submission, "manejo_4_adjuntos_url"))}`,
                                            font: 'Arial',
                                            size: 20
                                        })
                                    ],
                                    spacing: {
                                        after: 100,
                                        before: 100
                                    }
                                })
                            ],
                            columnSpan: 10
                        }),
                    ]
                }),
                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: 'Explique: ' + this.getValue(data.submission, "manejo_4_pregunta"),
                                            font: 'Arial',
                                            size: 20
                                        })
                                    ],
                                    spacing: {
                                        after: 100,
                                        before: 100
                                    }
                                })
                            ],
                            columnSpan: 10
                        }),
                    ]
                }),
                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: '5',
                                            font: 'Arial',
                                            size: 20
                                        })
                                    ],
                                    spacing: {
                                        after: 100,
                                        before: 100
                                    }
                                })
                            ],
                            columnSpan: 1
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: 'Para agricultores UE con producción paralela ¿Las variedades usadas para los cultivos orgánicos que se pueden diferenciar fácilmente de las usadas en los cultivos convencionales?',
                                            font: 'Arial',
                                            size: 20
                                        })
                                    ],
                                    spacing: {
                                        after: 100,
                                        before: 100
                                    }
                                })
                            ],
                            columnSpan: 7
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: `${this.getValue(data.submission, "manejo_5_conformidad") === 'C' ? '■' : '❏'} C ${this.getValue(data.submission, "manejo_5_conformidad") === 'NC' ? '■' : '❏'} NC ${this.getValue(data.submission, "manejo_5_conformidad") === 'NA' ? '■' : '❏'} NA`,
                                            font: 'Arial',
                                            size: 20
                                        })
                                    ],
                                    spacing: {
                                        after: 100,
                                        before: 100
                                    }
                                })
                            ],
                            columnSpan: 2
                        })
                    ]
                }),
                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text:`Anexos: ${this.getFileList(this.getValue(data.submission, "manejo_5_adjuntos_url"))}`,
                                            font: 'Arial',
                                            size: 20
                                        })
                                    ],
                                    spacing: {
                                        after: 100,
                                        before: 100
                                    }
                                })
                            ],
                            columnSpan: 10
                        }),
                    ]
                }),
                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: 'Explique: ' + this.getValue(data.submission, "manejo_5_pregunta"),
                                            font: 'Arial',
                                            size: 20
                                        })
                                    ],
                                    spacing: {
                                        after: 100,
                                        before: 100
                                    }
                                })
                            ],
                            columnSpan: 10
                        }),
                    ]
                }),
              new TableRow({
                children: [
                  new TableCell({
                    children: [
                      new Paragraph({
                        alignment: AlignmentType.LEFT,
                        children: [
                          new TextRun({
                            text: '6',
                            font: 'Arial',
                            size: 20
                          })
                        ],
                        spacing: {
                          after: 100,
                          before: 100
                        }
                      })
                    ],
                    columnSpan: 1
                  }),
                  new TableCell({
                    children: [
                      new Paragraph({
                        alignment: AlignmentType.LEFT,
                        children: [
                          new TextRun({
                            text: 'Para agricultores UE con producción paralela de cultivos perennes ¿Se tiene definido un plan de conversión para implementarse en un plazo máximo de 5 años después de la primer visita de inspección UE 848/2018?',
                            font: 'Arial',
                            size: 20
                          })
                        ],
                        spacing: {
                          after: 100,
                          before: 100
                        }
                      })
                    ],
                    columnSpan: 7
                  }),
                  new TableCell({
                    children: [
                      new Paragraph({
                        alignment: AlignmentType.LEFT,
                        children: [
                          new TextRun({
                            text: `${this.getValue(data.submission, "manejo_6_conformidad") === 'C' ? '■' : '❏'} C ${this.getValue(data.submission, "manejo_6_conformidad") === 'NC' ? '■' : '❏'} NC ${this.getValue(data.submission, "manejo_6_conformidad") === 'NA' ? '■' : '❏'} NA`,
                            font: 'Arial',
                            size: 20
                          })
                        ],
                        spacing: {
                          after: 100,
                          before: 100
                        }
                      })
                    ],
                    columnSpan: 2
                  })
                ]
              }),
              new TableRow({
                children: [
                  new TableCell({
                    children: [
                      new Paragraph({
                        alignment: AlignmentType.LEFT,
                        children: [
                          new TextRun({
                            text:`Anexos: ${this.getFileList(this.getValue(data.submission, "manejo_6_adjuntos_url"))}`,
                            font: 'Arial',
                            size: 20
                          })
                        ],
                        spacing: {
                          after: 100,
                          before: 100
                        }
                      })
                    ],
                    columnSpan: 10
                  }),
                ]
              }),
              new TableRow({
                children: [
                  new TableCell({
                    children: [
                      new Paragraph({
                        alignment: AlignmentType.LEFT,
                        children: [
                          new TextRun({
                            text: 'Explique: ' + this.getValue(data.submission, "manejo_6_pregunta"),
                            font: 'Arial',
                            size: 20
                          })
                        ],
                        spacing: {
                          after: 100,
                          before: 100
                        }
                      })
                    ],
                    columnSpan: 10
                  }),
                ]
              }),
                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: '7',
                                            font: 'Arial',
                                            size: 20
                                        })
                                    ],
                                    spacing: {
                                        after: 100,
                                        before: 100
                                    }
                                })
                            ],
                            columnSpan: 1
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: 'Para agricultores UE con producción paralela de cultivos perennes ¿Se presentó evidencia de que se le aviso a Metrocert del inicio de la cosecha al menos 48 horas antes?',
                                            font: 'Arial',
                                            size: 20
                                        })
                                    ],
                                    spacing: {
                                        after: 100,
                                        before: 100
                                    }
                                })
                            ],
                            columnSpan: 7
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: `${this.getValue(data.submission, "manejo_7_conformidad") === 'C' ? '■' : '❏'} C ${this.getValue(data.submission, "manejo_7_conformidad") === 'NC' ? '■' : '❏'} NC ${this.getValue(data.submission, "manejo_7_conformidad") === 'NA' ? '■' : '❏'} NA`,
                                            font: 'Arial',
                                            size: 20
                                        })
                                    ],
                                    spacing: {
                                        after: 100,
                                        before: 100
                                    }
                                })
                            ],
                            columnSpan: 2
                        })
                    ]
                }),
                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text:`Anexos: ${this.getFileList(this.getValue(data.submission, "manejo_7_adjuntos_url"))}`,
                                            font: 'Arial',
                                            size: 20
                                        })
                                    ],
                                    spacing: {
                                        after: 100,
                                        before: 100
                                    }
                                })
                            ],
                            columnSpan: 10
                        }),
                    ]
                }),
                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: 'Explique: ' + this.getValue(data.submission, "manejo_7_pregunta"),
                                            font: 'Arial',
                                            size: 20
                                        })
                                    ],
                                    spacing: {
                                        after: 100,
                                        before: 100
                                    }
                                })
                            ],
                            columnSpan: 10
                        }),
                    ]
                }),
                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: '8',
                                            font: 'Arial',
                                            size: 20
                                        })
                                    ],
                                    spacing: {
                                        after: 100,
                                        before: 100
                                    }
                                })
                            ],
                            columnSpan: 1
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: 'Para agricultores UE con producción paralela de cultivos perennes ¿Se presentó evidencia del reporte de cosecha a Metrocert a más tardar 15 días naturales después terminadas las actividades de cosecha?',
                                            font: 'Arial',
                                            size: 20
                                        })
                                    ],
                                    spacing: {
                                        after: 100,
                                        before: 100
                                    }
                                })
                            ],
                            columnSpan: 7
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: `${this.getValue(data.submission, "manejo_8_conformidad") === 'C' ? '■' : '❏'} C ${this.getValue(data.submission, "manejo_8_conformidad") === 'NC' ? '■' : '❏'} NC ${this.getValue(data.submission, "manejo_8_conformidad") === 'NA' ? '■' : '❏'} NA`,
                                            font: 'Arial',
                                            size: 20
                                        })
                                    ],
                                    spacing: {
                                        after: 100,
                                        before: 100
                                    }
                                })
                            ],
                            columnSpan: 2
                        })
                    ]
                }),
                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text:`Anexos: ${this.getFileList(this.getValue(data.submission, "manejo_8_adjuntos_url"))}`,
                                            font: 'Arial',
                                            size: 20
                                        })
                                    ],
                                    spacing: {
                                        after: 100,
                                        before: 100
                                    }
                                })
                            ],
                            columnSpan: 10
                        }),
                    ]
                }),
                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: 'Explique: ' + this.getValue(data.submission, "manejo_8_pregunta"),
                                            font: 'Arial',
                                            size: 20
                                        })
                                    ],
                                    spacing: {
                                        after: 100,
                                        before: 100
                                    }
                                })
                            ],
                            columnSpan: 10
                        }),
                    ]
                }),
                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: '9',
                                            font: 'Arial',
                                            size: 20
                                        })
                                    ],
                                    spacing: {
                                        after: 100,
                                        before: 100
                                    }
                                })
                            ],
                            columnSpan: 1
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: '¿Se usa material de propagación orgánico?',
                                            font: 'Arial',
                                            size: 20
                                        })
                                    ],
                                    spacing: {
                                        after: 100,
                                        before: 100
                                    }
                                })
                            ],
                            columnSpan: 7
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: `${this.getValue(data.submission, "manejo_9_conformidad") === 'C' ? '■' : '❏'} C ${this.getValue(data.submission, "manejo_9_conformidad") === 'NC' ? '■' : '❏'} NC ${this.getValue(data.submission, "manejo_9_conformidad") === 'NA' ? '■' : '❏'} NA`,
                                            font: 'Arial',
                                            size: 20
                                        })
                                    ],
                                    spacing: {
                                        after: 100,
                                        before: 100
                                    }
                                })
                            ],
                            columnSpan: 2
                        })
                    ]
                }),
                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text:`Anexos: ${this.getFileList(this.getValue(data.submission, "manejo_9_adjuntos_url"))}`,
                                            font: 'Arial',
                                            size: 20
                                        })
                                    ],
                                    spacing: {
                                        after: 100,
                                        before: 100
                                    }
                                })
                            ],
                            columnSpan: 10
                        }),
                    ]
                }),
                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: 'Explique: ' + this.getValue(data.submission, "manejo_9_pregunta"),
                                            font: 'Arial',
                                            size: 20
                                        })
                                    ],
                                    spacing: {
                                        after: 100,
                                        before: 100
                                    }
                                })
                            ],
                            columnSpan: 10
                        }),
                    ]
                }),
                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: '10',
                                            font: 'Arial',
                                            size: 20
                                        })
                                    ],
                                    spacing: {
                                        after: 100,
                                        before: 100
                                    }
                                })
                            ],
                            columnSpan: 1
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: 'En caso de usarse material de propagación no-orgánico ¿Se presentó autorización de Metrocert para uso de material de propagación no-orgánico?',
                                            font: 'Arial',
                                            size: 20
                                        }),
                                        new TextRun({
                                            text: 'Nota 1: La autorización se debe renovar anualmente',
                                            font: 'Arial',
                                            size: 20
                                        }),
                                        new TextRun({
                                            text: 'Nota 2.- No se puede autorizar el uso de plántula no orgánica para cultivos en los que se consume la parte vegetativa.',
                                            font: 'Arial',
                                            size: 20
                                        })
                                    ],
                                    spacing: {
                                        after: 100,
                                        before: 100
                                    }
                                })
                            ],
                            columnSpan: 7
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: `${this.getValue(data.submission, "manejo_10_conformidad") === 'C' ? '■' : '❏'} C ${this.getValue(data.submission, "manejo_10_conformidad") === 'NC' ? '■' : '❏'} NC ${this.getValue(data.submission, "manejo_10_conformidad") === 'NA' ? '■' : '❏'} NA`,
                                            font: 'Arial',
                                            size: 20
                                        })
                                    ],
                                    spacing: {
                                        after: 100,
                                        before: 100
                                    }
                                })
                            ],
                            columnSpan: 2
                        })
                    ]
                }),
                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text:`Anexos: ${this.getFileList(this.getValue(data.submission, "manejo_10_adjuntos_url"))}`,
                                            font: 'Arial',
                                            size: 20
                                        })
                                    ],
                                    spacing: {
                                        after: 100,
                                        before: 100
                                    }
                                })
                            ],
                            columnSpan: 10
                        }),
                    ]
                }),
                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: 'Explique: ' + this.getValue(data.submission, "manejo_10_pregunta"),
                                            font: 'Arial',
                                            size: 20
                                        })
                                    ],
                                    spacing: {
                                        after: 100,
                                        before: 100
                                    }
                                })
                            ],
                            columnSpan: 10
                        }),
                    ]
                }),
                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: '11',
                                            font: 'Arial',
                                            size: 20
                                        })
                                    ],
                                    spacing: {
                                        after: 100,
                                        before: 100
                                    }
                                })
                            ],
                            columnSpan: 1
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: '¿La fecha de autorización de uso de material de propagación no orgánico es anterior a la fecha de siembra/plantación?',
                                            font: 'Arial',
                                            size: 20
                                        })
                                    ],
                                    spacing: {
                                        after: 100,
                                        before: 100
                                    }
                                })
                            ],
                            columnSpan: 7
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: `${this.getValue(data.submission, "manejo_11_conformidad") === 'C' ? '■' : '❏'} C ${this.getValue(data.submission, "manejo_11_conformidad") === 'NC' ? '■' : '❏'} NC ${this.getValue(data.submission, "manejo_11_conformidad") === 'NA' ? '■' : '❏'} NA`,
                                            font: 'Arial',
                                            size: 20
                                        })
                                    ],
                                    spacing: {
                                        after: 100,
                                        before: 100
                                    }
                                })
                            ],
                            columnSpan: 2
                        })
                    ]
                }),
                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text:`Anexos: ${this.getFileList(this.getValue(data.submission, "manejo_11_adjuntos_url"))}`,
                                            font: 'Arial',
                                            size: 20
                                        })
                                    ],
                                    spacing: {
                                        after: 100,
                                        before: 100
                                    }
                                })
                            ],
                            columnSpan: 10
                        }),
                    ]
                }),
                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: 'Explique: ' + this.getValue(data.submission, "manejo_11_pregunta"),
                                            font: 'Arial',
                                            size: 20
                                        })
                                    ],
                                    spacing: {
                                        after: 100,
                                        before: 100
                                    }
                                })
                            ],
                            columnSpan: 10
                        }),
                    ]
                }),
                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: '12',
                                            font: 'Arial',
                                            size: 20
                                        })
                                    ],
                                    spacing: {
                                        after: 100,
                                        before: 100
                                    }
                                })
                            ],
                            columnSpan: 1
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: '¿El material de propagación esta tratado con sustancias permitidas o cumple con las excepciones (tratamientos requeridos por la autoridad)?',
                                            font: 'Arial',
                                            size: 20
                                        })
                                    ],
                                    spacing: {
                                        after: 100,
                                        before: 100
                                    }
                                })
                            ],
                            columnSpan: 7
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: `${this.getValue(data.submission, "manejo_12_conformidad") === 'C' ? '■' : '❏'} C ${this.getValue(data.submission, "manejo_12_conformidad") === 'NC' ? '■' : '❏'} NC ${this.getValue(data.submission, "manejo_12_conformidad") === 'NA' ? '■' : '❏'} NA`,
                                            font: 'Arial',
                                            size: 20
                                        })
                                    ],
                                    spacing: {
                                        after: 100,
                                        before: 100
                                    }
                                })
                            ],
                            columnSpan: 2
                        })
                    ]
                }),
                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text:`Anexos: ${this.getFileList(this.getValue(data.submission, "manejo_12_adjuntos_url"))}`,
                                            font: 'Arial',
                                            size: 20
                                        })
                                    ],
                                    spacing: {
                                        after: 100,
                                        before: 100
                                    }
                                })
                            ],
                            columnSpan: 10
                        }),
                    ]
                }),
                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: 'Explique: ' + this.getValue(data.submission, "manejo_12_pregunta"),
                                            font: 'Arial',
                                            size: 20
                                        })
                                    ],
                                    spacing: {
                                        after: 100,
                                        before: 100
                                    }
                                })
                            ],
                            columnSpan: 10
                        }),
                    ]
                }),
                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: '13',
                                            font: 'Arial',
                                            size: 20
                                        })
                                    ],
                                    spacing: {
                                        after: 100,
                                        before: 100
                                    }
                                })
                            ],
                            columnSpan: 1
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: '¿Se tienen adecuadas y suficientes actividades para mantener o mejorar la fertilidad del suelo y su contenido de materia orgánica?',
                                            font: 'Arial',
                                            size: 20
                                        })
                                    ],
                                    spacing: {
                                        after: 100,
                                        before: 100
                                    }
                                })
                            ],
                            columnSpan: 7
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: `${this.getValue(data.submission, "manejo_13_conformidad") === 'C' ? '■' : '❏'} C ${this.getValue(data.submission, "manejo_13_conformidad") === 'NC' ? '■' : '❏'} NC ${this.getValue(data.submission, "manejo_13_conformidad") === 'NA' ? '■' : '❏'} NA`,
                                            font: 'Arial',
                                            size: 20
                                        })
                                    ],
                                    spacing: {
                                        after: 100,
                                        before: 100
                                    }
                                })
                            ],
                            columnSpan: 2
                        })
                    ]
                }),
                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text:`Anexos: ${this.getFileList(this.getValue(data.submission, "manejo_13_adjuntos_url"))}`,
                                            font: 'Arial',
                                            size: 20
                                        })
                                    ],
                                    spacing: {
                                        after: 100,
                                        before: 100
                                    }
                                })
                            ],
                            columnSpan: 10
                        }),
                    ]
                }),
                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: 'Explique: ' + this.getValue(data.submission, "manejo_13_pregunta"),
                                            font: 'Arial',
                                            size: 20
                                        })
                                    ],
                                    spacing: {
                                        after: 100,
                                        before: 100
                                    }
                                })
                            ],
                            columnSpan: 10
                        }),
                    ]
                }),
                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: '14',
                                            font: 'Arial',
                                            size: 20
                                        })
                                    ],
                                    spacing: {
                                        after: 100,
                                        before: 100
                                    }
                                })
                            ],
                            columnSpan: 1
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: '¿Las practicas de labranza mantienen y mejoran la condición física, química y mecánica del suelo? ¿minimizan el daño a la estructura y minimizan la erosión del suelo?',
                                            font: 'Arial',
                                            size: 20
                                        })
                                    ],
                                    spacing: {
                                        after: 100,
                                        before: 100
                                    }
                                })
                            ],
                            columnSpan: 7
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: `${this.getValue(data.submission, "manejo_14_conformidad") === 'C' ? '■' : '❏'} C ${this.getValue(data.submission, "manejo_14_conformidad") === 'NC' ? '■' : '❏'} NC ${this.getValue(data.submission, "manejo_14_conformidad") === 'NA' ? '■' : '❏'} NA`,
                                            font: 'Arial',
                                            size: 20
                                        })
                                    ],
                                    spacing: {
                                        after: 100,
                                        before: 100
                                    }
                                })
                            ],
                            columnSpan: 2
                        })
                    ]
                }),
                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text:`Anexos: ${this.getFileList(this.getValue(data.submission, "manejo_14_adjuntos_url"))}`,
                                            font: 'Arial',
                                            size: 20
                                        })
                                    ],
                                    spacing: {
                                        after: 100,
                                        before: 100
                                    }
                                })
                            ],
                            columnSpan: 10
                        }),
                    ]
                }),
                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: 'Explique: ' + this.getValue(data.submission, "manejo_14_pregunta"),
                                            font: 'Arial',
                                            size: 20
                                        })
                                    ],
                                    spacing: {
                                        after: 100,
                                        before: 100
                                    }
                                })
                            ],
                            columnSpan: 10
                        }),
                    ]
                }),
                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                            new Paragraph({
                                alignment: AlignmentType.LEFT,
                                children: [
                                new TextRun({
                                    text: '15',
                                    font: 'Arial',
                                    size: 20
                                })
                                ],
                                spacing: {
                                after: 100,
                                before: 100
                                }
                            })
                            ],
                            columnSpan: 1
                        }),
                        new TableCell({
                            children: [
                                new Table({
                                    rows: [
                                    new TableRow({
                                        children: [
                                        new TableCell({
                                            children: [
                                            new Paragraph({
                                                alignment: AlignmentType.LEFT,
                                                children: [
                                                new TextRun({
                                                    text: 'Si se usan estiércoles:',
                                                    font: 'Arial',
                                                    size: 18
                                                })
                                                ]
                                            })
                                            ],
                                            columnSpan: 5,
                                            borders: {
                                            left: {
                                                style: BorderStyle.NONE,
                                                color: "white",
                                                size: 0
                                            },
                                            top: {
                                                style: BorderStyle.NONE,
                                                color: "white",
                                                size: 0
                                            },
                                            bottom: {
                                                style: BorderStyle.NONE,
                                                color: "white",
                                                size: 0
                                            }
                                            }
                                        }),
                                        new TableCell({
                                            children: [
                                            new Paragraph({
                                                alignment: AlignmentType.LEFT,
                                                children: [
                                                new TextRun({
                                                    text: '',
                                                    font: 'Arial',
                                                    size: 18
                                                })
                                                ]
                                            })
                                            ],
                                            columnSpan: 2,
                                            borders: {
                                            right: {
                                                style: BorderStyle.NONE,
                                                color: "white",
                                                size: 0
                                            },
                                            top: {
                                                style: BorderStyle.NONE,
                                                color: "white",
                                                size: 0
                                            },
                                            bottom: {
                                                style: BorderStyle.NONE,
                                                color: "white",
                                                size: 0
                                            }
                                            }
                                        })
                                        ]
                                    }),
                                    new TableRow({
                                        children: [
                                        new TableCell({
                                            children: [
                                            new Paragraph({
                                                alignment: AlignmentType.LEFT,
                                                children: [
                                                new TextRun({
                                                    text: `a. ¿Se cumple con los requisitos de fuente, y calidad y tipo de ganadería?`,
                                                    font: 'Arial',
                                                    size: 18
                                                })
                                                ]
                                            })
                                            ],
                                            columnSpan: 5,
                                            borders: {
                                            left: {
                                                style: BorderStyle.NONE,
                                                color: "white",
                                                size: 0
                                            },
                                            top: {
                                                style: BorderStyle.NONE,
                                                color: "white",
                                                size: 0
                                            },
                                            bottom: {
                                                style: BorderStyle.NONE,
                                                color: "white",
                                                size: 0
                                            }
                                            }
                                        }),
                                        new TableCell({
                                            children: [
                                            new Paragraph({
                                                alignment: AlignmentType.CENTER,
                                                children: [
                                                new TextRun({
                                                    text: `${this.getValue(data.submission, "manejo_15_pregunta_a") === 'Si' ? '■' : '❏'} SI ${this.getValue(data.submission, "manejo_15_pregunta_a") === 'No' ? '■' : '❏'} NO`,
                                                    font: 'Arial',
                                                    size: 18
                                                })
                                                ]
                                            })
                                            ],
                                            columnSpan: 2,
                                            borders: {
                                            right: {
                                                style: BorderStyle.NONE,
                                                color: "white",
                                                size: 0
                                            },
                                            top: {
                                                style: BorderStyle.NONE,
                                                color: "white",
                                                size: 0
                                            },
                                            bottom: {
                                                style: BorderStyle.NONE,
                                                color: "white",
                                                size: 0
                                            }
                                            }
                                        })
                                        ]
                                    }),
                                    new TableRow({
                                        children: [
                                        new TableCell({
                                            children: [
                                            new Paragraph({
                                                alignment: AlignmentType.LEFT,
                                                children: [
                                                new TextRun({
                                                    text: `b. ¿El momento, dosis y método de aplicación previenen la contaminación del producto, suelo y agua?`,
                                                    font: 'Arial',
                                                    size: 18
                                                })
                                                ]
                                            })
                                            ],
                                            columnSpan: 5,
                                            borders: {
                                            left: {
                                                style: BorderStyle.NONE,
                                                color: "white",
                                                size: 0
                                            },
                                            top: {
                                                style: BorderStyle.NONE,
                                                color: "white",
                                                size: 0
                                            },
                                            bottom: {
                                                style: BorderStyle.NONE,
                                                color: "white",
                                                size: 0
                                            }
                                            }
                                        }),
                                        new TableCell({
                                            children: [
                                            new Paragraph({
                                                alignment: AlignmentType.CENTER,
                                                children: [
                                                new TextRun({
                                                    text: `${this.getValue(data.submission, "manejo_15_pregunta_b") === 'Si' ? '■' : '❏'} SI ${this.getValue(data.submission, "manejo_15_pregunta_b") === 'No' ? '■' : '❏'} NO`,
                                                    font: 'Arial',
                                                    size: 18
                                                })
                                                ]
                                            })
                                            ],
                                            columnSpan: 2,
                                            borders: {
                                            right: {
                                                style: BorderStyle.NONE,
                                                color: "white",
                                                size: 0
                                            },
                                            top: {
                                                style: BorderStyle.NONE,
                                                color: "white",
                                                size: 0
                                            },
                                            bottom: {
                                                style: BorderStyle.NONE,
                                                color: "white",
                                                size: 0
                                            }
                                            }
                                        })
                                        ]
                                    }),
                                    new TableRow({
                                        children: [
                                        new TableCell({
                                            children: [
                                            new Paragraph({
                                                alignment: AlignmentType.LEFT,
                                                children: [
                                                new TextRun({
                                                    text: `c. ¿Se aplica en adecuadas condiciones de humedad y temperatura para favorecer la bio-oxidación?`,
                                                    font: 'Arial',
                                                    size: 18
                                                })
                                                ]
                                            })
                                            ],
                                            columnSpan: 5,
                                            borders: {
                                            left: {
                                                style: BorderStyle.NONE,
                                                color: "white",
                                                size: 0
                                            },
                                            top: {
                                                style: BorderStyle.NONE,
                                                color: "white",
                                                size: 0
                                            },
                                            bottom: {
                                                style: BorderStyle.NONE,
                                                color: "white",
                                                size: 0
                                            }
                                            }
                                        }),
                                        new TableCell({
                                            children: [
                                            new Paragraph({
                                                alignment: AlignmentType.CENTER,
                                                children: [
                                                new TextRun({
                                                    text: `${this.getValue(data.submission, "manejo_15_pregunta_c") === 'Si' ? '■' : '❏'} SI ${this.getValue(data.submission, "manejo_15_pregunta_c") === 'No' ? '■' : '❏'} NO`,
                                                    font: 'Arial',
                                                    size: 18
                                                })
                                                ]
                                            })
                                            ],
                                            columnSpan: 2,
                                            borders: {
                                            right: {
                                                style: BorderStyle.NONE,
                                                color: "white",
                                                size: 0
                                            },
                                            top: {
                                                style: BorderStyle.NONE,
                                                color: "white",
                                                size: 0
                                            },
                                            bottom: {
                                                style: BorderStyle.NONE,
                                                color: "white",
                                                size: 0
                                            }
                                            }
                                        })
                                        ]
                                    }),
                                    new TableRow({
                                        children: [
                                        new TableCell({
                                            children: [
                                            new Paragraph({
                                                alignment: AlignmentType.LEFT,
                                                children: [
                                                new TextRun({
                                                    text: `d. ¿Se aplica 120 días antes de cosecha para cultivos en contacto o 90 días antes para cultivos sin contacto?`,
                                                    font: 'Arial',
                                                    size: 18
                                                })
                                                ]
                                            })
                                            ],
                                            columnSpan: 5,
                                            borders: {
                                            left: {
                                                style: BorderStyle.NONE,
                                                color: "white",
                                                size: 0
                                            },
                                            top: {
                                                style: BorderStyle.NONE,
                                                color: "white",
                                                size: 0
                                            },
                                            bottom: {
                                                style: BorderStyle.NONE,
                                                color: "white",
                                                size: 0
                                            }
                                            }
                                        }),
                                        new TableCell({
                                            children: [
                                            new Paragraph({
                                                alignment: AlignmentType.CENTER,
                                                children: [
                                                new TextRun({
                                                    text: `${this.getValue(data.submission, "manejo_15_pregunta_d") === 'Si' ? '■' : '❏'} SI ${this.getValue(data.submission, "manejo_15_pregunta_d") === 'No' ? '■' : '❏'} NO`,
                                                    font: 'Arial',
                                                    size: 18
                                                })
                                                ]
                                            })
                                            ],
                                            columnSpan: 2,
                                            borders: {
                                            right: {
                                                style: BorderStyle.NONE,
                                                color: "white",
                                                size: 0
                                            },
                                            top: {
                                                style: BorderStyle.NONE,
                                                color: "white",
                                                size: 0
                                            },
                                            bottom: {
                                                style: BorderStyle.NONE,
                                                color: "white",
                                                size: 0
                                            }
                                            }
                                        })
                                        ]
                                    }),
                                    new TableRow({
                                        children: [
                                        new TableCell({
                                            children: [
                                            new Paragraph({
                                                alignment: AlignmentType.LEFT,
                                                children: [
                                                new TextRun({
                                                    text: `e. Si se maneja ganado dentro de la parcela, ¿se previene la contaminación de la cosecha?`,
                                                    font: 'Arial',
                                                    size: 18
                                                })
                                                ]
                                            })
                                            ],
                                            columnSpan: 5,
                                            borders: {
                                            left: {
                                                style: BorderStyle.NONE,
                                                color: "white",
                                                size: 0
                                            },
                                            top: {
                                                style: BorderStyle.NONE,
                                                color: "white",
                                                size: 0
                                            },
                                            bottom: {
                                                style: BorderStyle.NONE,
                                                color: "white",
                                                size: 0
                                            }
                                            }
                                        }),
                                        new TableCell({
                                            children: [
                                            new Paragraph({
                                                alignment: AlignmentType.CENTER,
                                                children: [
                                                new TextRun({
                                                    text: `${this.getValue(data.submission, "manejo_15_pregunta_e") === 'Si' ? '■' : '❏'} SI ${this.getValue(data.submission, "manejo_15_pregunta_e") === 'No' ? '■' : '❏'} NO`,
                                                    font: 'Arial',
                                                    size: 18
                                                })
                                                ]
                                            })
                                            ],
                                            columnSpan: 2,
                                            borders: {
                                            right: {
                                                style: BorderStyle.NONE,
                                                color: "white",
                                                size: 0
                                            },
                                            top: {
                                                style: BorderStyle.NONE,
                                                color: "white",
                                                size: 0
                                            },
                                            bottom: {
                                                style: BorderStyle.NONE,
                                                color: "white",
                                                size: 0
                                            }
                                            }
                                        })
                                        ]
                                    }),
                                    new TableRow({
                                        children: [
                                        new TableCell({
                                            children: [
                                            new Paragraph({
                                                alignment: AlignmentType.LEFT,
                                                children: [
                                                new TextRun({
                                                    text: `f. ¿Se cumple con las cantidades de N/ha/año permitidas por el reglamento UE 848/2018?`,
                                                    font: 'Arial',
                                                    size: 18
                                                })
                                                ]
                                            })
                                            ],
                                            columnSpan: 5,
                                            borders: {
                                            left: {
                                                style: BorderStyle.NONE,
                                                color: "white",
                                                size: 0
                                            },
                                            top: {
                                                style: BorderStyle.NONE,
                                                color: "white",
                                                size: 0
                                            },
                                            bottom: {
                                                style: BorderStyle.NONE,
                                                color: "white",
                                                size: 0
                                            }
                                            }
                                        }),
                                        new TableCell({
                                            children: [
                                            new Paragraph({
                                                alignment: AlignmentType.CENTER,
                                                children: [
                                                new TextRun({
                                                    text: `${this.getValue(data.submission, "manejo_15_pregunta_f") === 'Si' ? '■' : '❏'} SI ${this.getValue(data.submission, "manejo_15_pregunta_f") === 'No' ? '■' : '❏'} NO`,
                                                    font: 'Arial',
                                                    size: 18
                                                })
                                                ]
                                            })
                                            ],
                                            columnSpan: 2,
                                            borders: {
                                            right: {
                                                style: BorderStyle.NONE,
                                                color: "white",
                                                size: 0
                                            },
                                            top: {
                                                style: BorderStyle.NONE,
                                                color: "white",
                                                size: 0
                                            },
                                            bottom: {
                                                style: BorderStyle.NONE,
                                                color: "white",
                                                size: 0
                                            }
                                            }
                                        })
                                        ]
                                    })
                                    ],
                                    columnWidths: [1359, 1359, 1359, 1359, 1359, 1359, 1359]
                                })
                            ],
                            columnSpan: 7
                        }),
                        new TableCell({
                            children: [
                            new Paragraph({
                                alignment: AlignmentType.LEFT,
                                children: [
                                new TextRun({
                                    text: `${this.getValue(data.submission, "manejo_15_conformidad") === 'C' ? '■' : '❏'} C ${this.getValue(data.submission, "manejo_15_conformidad") === 'NC' ? '■' : '❏'} NC ${this.getValue(data.submission, "manejo_15_conformidad") === 'NA' ? '■' : '❏'} NA`,
                                    font: 'Arial',
                                    size: 20
                                })
                                ],
                                spacing: {
                                after: 100,
                                before: 100
                                }
                            })
                            ],
                            columnSpan: 2
                        })
                    ]
                }),
                new TableRow({
                    children: [
                    new TableCell({
                        children: [
                        new Paragraph({
                            alignment: AlignmentType.LEFT,
                            children: [
                            new TextRun({
                                text:`Anexos: ${this.getFileList(this.getValue(data.submission, "manejo_15_adjuntos_url"))}`,
                                font: 'Arial',
                                size: 20
                            })
                            ],
                            spacing: {
                            after: 100,
                            before: 100
                            }
                        })
                        ],
                        columnSpan: 10
                    }),
                    ]
                }),
                new TableRow({
                    children: [
                    new TableCell({
                        children: [
                        new Paragraph({
                            alignment: AlignmentType.LEFT,
                            children: [
                            new TextRun({
                                text: 'Explique: ' + this.getValue(data.submission, "manejo_15_pregunta"),
                                font: 'Arial',
                                size: 20
                            })
                            ],
                            spacing: {
                            after: 100,
                            before: 100
                            }
                        })
                        ],
                        columnSpan: 10
                    }),
                    ]
                }),
                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: '16',
                                            font: 'Arial',
                                            size: 20
                                        })
                                    ],
                                    spacing: {
                                        after: 100,
                                        before: 100
                                    }
                                })
                            ],
                            columnSpan: 1
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: '¿Se aplica un programa de rotación/asociación de cultivos?',
                                            font: 'Arial',
                                            size: 20
                                        })
                                    ],
                                    spacing: {
                                        after: 100,
                                        before: 100
                                    }
                                })
                            ],
                            columnSpan: 7
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: `${this.getValue(data.submission, "manejo_16_conformidad") === 'C' ? '■' : '❏'} C ${this.getValue(data.submission, "manejo_16_conformidad") === 'NC' ? '■' : '❏'} NC ${this.getValue(data.submission, "manejo_16_conformidad") === 'NA' ? '■' : '❏'} NA`,
                                            font: 'Arial',
                                            size: 20
                                        })
                                    ],
                                    spacing: {
                                        after: 100,
                                        before: 100
                                    }
                                })
                            ],
                            columnSpan: 2
                        })
                    ]
                }),
                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text:`Anexos: ${this.getFileList(this.getValue(data.submission, "manejo_16_adjuntos_url"))}`,
                                            font: 'Arial',
                                            size: 20
                                        })
                                    ],
                                    spacing: {
                                        after: 100,
                                        before: 100
                                    }
                                })
                            ],
                            columnSpan: 10
                        }),
                    ]
                }),
                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: 'Explique: ' + this.getValue(data.submission, "manejo_16_pregunta"),
                                            font: 'Arial',
                                            size: 20
                                        })
                                    ],
                                    spacing: {
                                        after: 100,
                                        before: 100
                                    }
                                })
                            ],
                            columnSpan: 10
                        }),
                    ]
                }),
                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: '17',
                                            font: 'Arial',
                                            size: 20
                                        })
                                    ],
                                    spacing: {
                                        after: 100,
                                        before: 100
                                    }
                                })
                            ],
                            columnSpan: 1
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: '¿Se incluyen especies leguminosas en el plan de rotación?',
                                            font: 'Arial',
                                            size: 20
                                        })
                                    ],
                                    spacing: {
                                        after: 100,
                                        before: 100
                                    }
                                })
                            ],
                            columnSpan: 7
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: `${this.getValue(data.submission, "manejo_17_conformidad") === 'C' ? '■' : '❏'} C ${this.getValue(data.submission, "manejo_17_conformidad") === 'NC' ? '■' : '❏'} NC ${this.getValue(data.submission, "manejo_17_conformidad") === 'NA' ? '■' : '❏'} NA`,
                                            font: 'Arial',
                                            size: 20
                                        })
                                    ],
                                    spacing: {
                                        after: 100,
                                        before: 100
                                    }
                                })
                            ],
                            columnSpan: 2
                        })
                    ]
                }),
                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text:`Anexos: ${this.getFileList(this.getValue(data.submission, "manejo_17_adjuntos_url"))}`,
                                            font: 'Arial',
                                            size: 20
                                        })
                                    ],
                                    spacing: {
                                        after: 100,
                                        before: 100
                                    }
                                })
                            ],
                            columnSpan: 10
                        }),
                    ]
                }),
                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: 'Explique: ' + this.getValue(data.submission, "manejo_17_pregunta"),
                                            font: 'Arial',
                                            size: 20
                                        })
                                    ],
                                    spacing: {
                                        after: 100,
                                        before: 100
                                    }
                                })
                            ],
                            columnSpan: 10
                        }),
                    ]
                }),
                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: '18',
                                            font: 'Arial',
                                            size: 20
                                        })
                                    ],
                                    spacing: {
                                        after: 100,
                                        before: 100
                                    }
                                })
                            ],
                            columnSpan: 1
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: '¿Se usan prácticas y sustancias permitidas para el abonado?, ¿se respetan las restricciones?',
                                            font: 'Arial',
                                            size: 20
                                        })
                                    ],
                                    spacing: {
                                        after: 100,
                                        before: 100
                                    }
                                })
                            ],
                            columnSpan: 7
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: `${this.getValue(data.submission, "manejo_18_conformidad") === 'C' ? '■' : '❏'} C ${this.getValue(data.submission, "manejo_18_conformidad") === 'NC' ? '■' : '❏'} NC ${this.getValue(data.submission, "manejo_18_conformidad") === 'NA' ? '■' : '❏'} NA`,
                                            font: 'Arial',
                                            size: 20
                                        })
                                    ],
                                    spacing: {
                                        after: 100,
                                        before: 100
                                    }
                                })
                            ],
                            columnSpan: 2
                        })
                    ]
                }),
                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text:`Anexos: ${this.getFileList(this.getValue(data.submission, "manejo_18_adjuntos_url"))}`,
                                            font: 'Arial',
                                            size: 20
                                        })
                                    ],
                                    spacing: {
                                        after: 100,
                                        before: 100
                                    }
                                })
                            ],
                            columnSpan: 10
                        }),
                    ]
                }),
                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: 'Explique: ' + this.getValue(data.submission, "manejo_18_pregunta"),
                                            font: 'Arial',
                                            size: 20
                                        })
                                    ],
                                    spacing: {
                                        after: 100,
                                        before: 100
                                    }
                                })
                            ],
                            columnSpan: 10
                        }),
                    ]
                }),
                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: '19',
                                            font: 'Arial',
                                            size: 20
                                        })
                                    ],
                                    spacing: {
                                        after: 100,
                                        before: 100
                                    }
                                })
                            ],
                            columnSpan: 1
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: '¿Se usan prácticas y sustancias permitidas para el control de hierbas?, ¿se respetan las restricciones?',
                                            font: 'Arial',
                                            size: 20
                                        })
                                    ],
                                    spacing: {
                                        after: 100,
                                        before: 100
                                    }
                                })
                            ],
                            columnSpan: 7
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: `${this.getValue(data.submission, "manejo_19_conformidad") === 'C' ? '■' : '❏'} C ${this.getValue(data.submission, "manejo_19_conformidad") === 'NC' ? '■' : '❏'} NC ${this.getValue(data.submission, "manejo_19_conformidad") === 'NA' ? '■' : '❏'} NA`,
                                            font: 'Arial',
                                            size: 20
                                        })
                                    ],
                                    spacing: {
                                        after: 100,
                                        before: 100
                                    }
                                })
                            ],
                            columnSpan: 2
                        })
                    ]
                }),
                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text:`Anexos: ${this.getFileList(this.getValue(data.submission, "manejo_19_adjuntos_url"))}`,
                                            font: 'Arial',
                                            size: 20
                                        })
                                    ],
                                    spacing: {
                                        after: 100,
                                        before: 100
                                    }
                                })
                            ],
                            columnSpan: 10
                        }),
                    ]
                }),
                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: 'Explique: ' + this.getValue(data.submission, "manejo_19_pregunta"),
                                            font: 'Arial',
                                            size: 20
                                        })
                                    ],
                                    spacing: {
                                        after: 100,
                                        before: 100
                                    }
                                })
                            ],
                            columnSpan: 10
                        }),
                    ]
                }),
                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: '20',
                                            font: 'Arial',
                                            size: 20
                                        })
                                    ],
                                    spacing: {
                                        after: 100,
                                        before: 100
                                    }
                                })
                            ],
                            columnSpan: 1
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: '¿Se usan prácticas y sustancias permitidas para el control de plagas y enfermedades?',
                                            font: 'Arial',
                                            size: 20
                                        })
                                    ],
                                    spacing: {
                                        after: 100,
                                        before: 100
                                    }
                                })
                            ],
                            columnSpan: 7
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: `${this.getValue(data.submission, "manejo_20_conformidad") === 'C' ? '■' : '❏'} C ${this.getValue(data.submission, "manejo_20_conformidad") === 'NC' ? '■' : '❏'} NC ${this.getValue(data.submission, "manejo_20_conformidad") === 'NA' ? '■' : '❏'} NA`,
                                            font: 'Arial',
                                            size: 20
                                        })
                                    ],
                                    spacing: {
                                        after: 100,
                                        before: 100
                                    }
                                })
                            ],
                            columnSpan: 2
                        })
                    ]
                }),
                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text:`Anexos: ${this.getFileList(this.getValue(data.submission, "manejo_20_adjuntos_url"))}`,
                                            font: 'Arial',
                                            size: 20
                                        })
                                    ],
                                    spacing: {
                                        after: 100,
                                        before: 100
                                    }
                                })
                            ],
                            columnSpan: 10
                        }),
                    ]
                }),
                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: 'Explique: ' + this.getValue(data.submission, "manejo_20_pregunta"),
                                            font: 'Arial',
                                            size: 20
                                        })
                                    ],
                                    spacing: {
                                        after: 100,
                                        before: 100
                                    }
                                })
                            ],
                            columnSpan: 10
                        }),
                    ]
                }),
                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: '21',
                                            font: 'Arial',
                                            size: 20
                                        })
                                    ],
                                    spacing: {
                                        after: 100,
                                        before: 100
                                    }
                                })
                            ],
                            columnSpan: 1
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: '¿Todas las sustancias se usan para los fines y con las restricciones señaladas en las listas?',
                                            font: 'Arial',
                                            size: 20
                                        })
                                    ],
                                    spacing: {
                                        after: 100,
                                        before: 100
                                    }
                                })
                            ],
                            columnSpan: 7
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: `${this.getValue(data.submission, "manejo_21_conformidad") === 'C' ? '■' : '❏'} C ${this.getValue(data.submission, "manejo_21_conformidad") === 'NC' ? '■' : '❏'} NC ${this.getValue(data.submission, "manejo_21_conformidad") === 'NA' ? '■' : '❏'} NA`,
                                            font: 'Arial',
                                            size: 20
                                        })
                                    ],
                                    spacing: {
                                        after: 100,
                                        before: 100
                                    }
                                })
                            ],
                            columnSpan: 2
                        })
                    ]
                }),
                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text:`Anexos: ${this.getFileList(this.getValue(data.submission, "manejo_21_adjuntos_url"))}`,
                                            font: 'Arial',
                                            size: 20
                                        })
                                    ],
                                    spacing: {
                                        after: 100,
                                        before: 100
                                    }
                                })
                            ],
                            columnSpan: 10
                        }),
                    ]
                }),
                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: 'Explique: ' + this.getValue(data.submission, "manejo_21_pregunta"),
                                            font: 'Arial',
                                            size: 20
                                        })
                                    ],
                                    spacing: {
                                        after: 100,
                                        before: 100
                                    }
                                })
                            ],
                            columnSpan: 10
                        }),
                    ]
                }),
                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: '22',
                                            font: 'Arial',
                                            size: 20
                                        })
                                    ],
                                    spacing: {
                                        after: 100,
                                        before: 100
                                    }
                                })
                            ],
                            columnSpan: 1
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: '¿Las herramientas y principalmente el equipo de aspersión es exclusivo o lavado para eliminar riesgos de contaminación?',
                                            font: 'Arial',
                                            size: 20
                                        })
                                    ],
                                    spacing: {
                                        after: 100,
                                        before: 100
                                    }
                                })
                            ],
                            columnSpan: 7
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: `${this.getValue(data.submission, "manejo_22_conformidad") === 'C' ? '■' : '❏'} C ${this.getValue(data.submission, "manejo_22_conformidad") === 'NC' ? '■' : '❏'} NC ${this.getValue(data.submission, "manejo_22_conformidad") === 'NA' ? '■' : '❏'} NA`,
                                            font: 'Arial',
                                            size: 20
                                        })
                                    ],
                                    spacing: {
                                        after: 100,
                                        before: 100
                                    }
                                })
                            ],
                            columnSpan: 2
                        })
                    ]
                }),
                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text:`Anexos: ${this.getFileList(this.getValue(data.submission, "manejo_22_adjuntos_url"))}`,
                                            font: 'Arial',
                                            size: 20
                                        })
                                    ],
                                    spacing: {
                                        after: 100,
                                        before: 100
                                    }
                                })
                            ],
                            columnSpan: 10
                        }),
                    ]
                }),
                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: 'Explique: ' + this.getValue(data.submission, "manejo_22_pregunta"),
                                            font: 'Arial',
                                            size: 20
                                        })
                                    ],
                                    spacing: {
                                        after: 100,
                                        before: 100
                                    }
                                })
                            ],
                            columnSpan: 10
                        }),
                    ]
                }),
                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: '23',
                                            font: 'Arial',
                                            size: 20
                                        })
                                    ],
                                    spacing: {
                                        after: 100,
                                        before: 100
                                    }
                                })
                            ],
                            columnSpan: 1
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: 'En caso de que se usen trampas con atrayentes que no sean feromonas, ¿Se previene que la sustancia haga contacto con los productos o cultivos?',
                                            font: 'Arial',
                                            size: 20
                                        })
                                    ],
                                    spacing: {
                                        after: 100,
                                        before: 100
                                    }
                                })
                            ],
                            columnSpan: 7
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: `${this.getValue(data.submission, "manejo_23_conformidad") === 'C' ? '■' : '❏'} C ${this.getValue(data.submission, "manejo_23_conformidad") === 'NC' ? '■' : '❏'} NC ${this.getValue(data.submission, "manejo_23_conformidad") === 'NA' ? '■' : '❏'} NA`,
                                            font: 'Arial',
                                            size: 20
                                        })
                                    ],
                                    spacing: {
                                        after: 100,
                                        before: 100
                                    }
                                })
                            ],
                            columnSpan: 2
                        })
                    ]
                }),
                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text:`Anexos: ${this.getFileList(this.getValue(data.submission, "manejo_23_adjuntos_url"))}`,
                                            font: 'Arial',
                                            size: 20
                                        })
                                    ],
                                    spacing: {
                                        after: 100,
                                        before: 100
                                    }
                                })
                            ],
                            columnSpan: 10
                        }),
                    ]
                }),
                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: 'Explique: ' + this.getValue(data.submission, "manejo_23_pregunta"),
                                            font: 'Arial',
                                            size: 20
                                        })
                                    ],
                                    spacing: {
                                        after: 100,
                                        before: 100
                                    }
                                })
                            ],
                            columnSpan: 10
                        }),
                    ]
                }),
                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: '24',
                                            font: 'Arial',
                                            size: 20
                                        })
                                    ],
                                    spacing: {
                                        after: 100,
                                        before: 100
                                    }
                                })
                            ],
                            columnSpan: 1
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: 'Si se usa madera ¿es madera sin tratamiento o con tratamientos permitidos?',
                                            font: 'Arial',
                                            size: 20
                                        })
                                    ],
                                    spacing: {
                                        after: 100,
                                        before: 100
                                    }
                                })
                            ],
                            columnSpan: 7
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: `${this.getValue(data.submission, "manejo_24_conformidad") === 'C' ? '■' : '❏'} C ${this.getValue(data.submission, "manejo_24_conformidad") === 'NC' ? '■' : '❏'} NC ${this.getValue(data.submission, "manejo_24_conformidad") === 'NA' ? '■' : '❏'} NA`,
                                            font: 'Arial',
                                            size: 20
                                        })
                                    ],
                                    spacing: {
                                        after: 100,
                                        before: 100
                                    }
                                })
                            ],
                            columnSpan: 2
                        })
                    ]
                }),
                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text:`Anexos: ${this.getFileList(this.getValue(data.submission, "manejo_24_adjuntos_url"))}`,
                                            font: 'Arial',
                                            size: 20
                                        })
                                    ],
                                    spacing: {
                                        after: 100,
                                        before: 100
                                    }
                                })
                            ],
                            columnSpan: 10
                        }),
                    ]
                }),
                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: 'Explique: ' + this.getValue(data.submission, "manejo_24_pregunta"),
                                            font: 'Arial',
                                            size: 20
                                        })
                                    ],
                                    spacing: {
                                        after: 100,
                                        before: 100
                                    }
                                })
                            ],
                            columnSpan: 10
                        }),
                    ]
                }),
                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: '25',
                                            font: 'Arial',
                                            size: 20
                                        })
                                    ],
                                    spacing: {
                                        after: 100,
                                        before: 100
                                    }
                                })
                            ],
                            columnSpan: 1
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: '¿Se tienen implementadas prácticas para la conservación de los recursos naturales (ecosistema)?',
                                            font: 'Arial',
                                            size: 20
                                        })
                                    ],
                                    spacing: {
                                        after: 100,
                                        before: 100
                                    }
                                })
                            ],
                            columnSpan: 7
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: `${this.getValue(data.submission, "manejo_25_conformidad") === 'C' ? '■' : '❏'} C ${this.getValue(data.submission, "manejo_25_conformidad") === 'NC' ? '■' : '❏'} NC ${this.getValue(data.submission, "manejo_25_conformidad") === 'NA' ? '■' : '❏'} NA`,
                                            font: 'Arial',
                                            size: 20
                                        })
                                    ],
                                    spacing: {
                                        after: 100,
                                        before: 100
                                    }
                                })
                            ],
                            columnSpan: 2
                        })
                    ]
                }),
                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text:`Anexos: ${this.getFileList(this.getValue(data.submission, "manejo_25_adjuntos_url"))}`,
                                            font: 'Arial',
                                            size: 20
                                        })
                                    ],
                                    spacing: {
                                        after: 100,
                                        before: 100
                                    }
                                })
                            ],
                            columnSpan: 10
                        }),
                    ]
                }),
                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: 'Explique: ' + this.getValue(data.submission, "manejo_25_pregunta"),
                                            font: 'Arial',
                                            size: 20
                                        })
                                    ],
                                    spacing: {
                                        after: 100,
                                        before: 100
                                    }
                                })
                            ],
                            columnSpan: 10
                        }),
                    ]
                }),
                new TableRow({
                    children: [
                    new TableCell({
                        children: [
                        new Paragraph({
                            alignment: AlignmentType.LEFT,
                            children: [
                            new TextRun({
                                text: '26',
                                font: 'Arial',
                                size: 20
                            })
                            ],
                            spacing: {
                            after: 100,
                            before: 100
                            }
                        })
                        ],
                        columnSpan: 1
                    }),
                    new TableCell({
                        children: [
                        new Paragraph({
                            alignment: AlignmentType.LEFT,
                            children: [
                            new TextRun({
                                text: '¿Se tomaron muestras para análisis de laboratorio?',
                                font: 'Arial',
                                size: 20
                            }),
                            new TextRun({
                                text: 'En caso positivo, favor de indicar el tipo de muestra, el análisis requerido, el lugar y condiciones de la toma de muestra y cualquier otra información que considere relevante.',
                                font: 'Arial',
                                size: 20
                            })
                            ],
                            spacing: {
                            after: 100,
                            before: 100
                            }
                        })
                        ],
                        columnSpan: 7
                    }),
                    new TableCell({
                        children: [
                        new Paragraph({
                            alignment: AlignmentType.LEFT,
                            children: [
                            new TextRun({
                                text: `${this.getValue(data.submission, "manejo_26_conformidad") === 'C' ? '■' : '❏'} C ${this.getValue(data.submission, "manejo_26_conformidad") === 'NC' ? '■' : '❏'} NC ${this.getValue(data.submission, "manejo_26_conformidad") === 'NA' ? '■' : '❏'} NA`,
                                font: 'Arial',
                                size: 20
                            })
                            ],
                            spacing: {
                            after: 100,
                            before: 100
                            }
                        })
                        ],
                        columnSpan: 2
                    })
                    ]
                }),
                new TableRow({
                    children: [
                    new TableCell({
                        children: [
                        new Paragraph({
                            alignment: AlignmentType.LEFT,
                            children: [
                            new TextRun({
                                text:`Anexos: ${this.getFileList(this.getValue(data.submission, "manejo_26_adjuntos_url"))}`,
                                font: 'Arial',
                                size: 20
                            })
                            ],
                            spacing: {
                            after: 100,
                            before: 100
                            }
                        })
                        ],
                        columnSpan: 10
                    }),
                    ]
                }),
                new TableRow({
                    children: [
                    new TableCell({
                        children: [
                        new Paragraph({
                            alignment: AlignmentType.LEFT,
                            children: [
                            new TextRun({
                                text: 'Explique: ' + this.getValue(data.submission, "manejo_26_pregunta"),
                                font: 'Arial',
                                size: 20
                            })
                            ],
                            spacing: {
                            after: 100,
                            before: 100
                            }
                        })
                        ],
                        columnSpan: 10
                    }),
                    ]
                }),
                new TableRow({
                    children: [
                    new TableCell({
                        children: [
                        new Paragraph({
                            alignment: AlignmentType.LEFT,
                            children: [
                            new TextRun({
                                text: '27',
                                font: 'Arial',
                                size: 20
                            })
                            ],
                            spacing: {
                            after: 100,
                            before: 100
                            }
                        })
                        ],
                        columnSpan: 1
                    }),
                    new TableCell({
                        children: [
                        new Paragraph({
                            alignment: AlignmentType.LEFT,
                            children: [
                            new TextRun({
                                text: '¿Las prácticas y registros de manejo permiten mantener la integridad orgánica del producto?',
                                font: 'Arial',
                                size: 20
                            })
                            ],
                            spacing: {
                            after: 100,
                            before: 100
                            }
                        })
                        ],
                        columnSpan: 7
                    }),
                    new TableCell({
                        children: [
                        new Paragraph({
                            alignment: AlignmentType.LEFT,
                            children: [
                            new TextRun({
                                text: `${this.getValue(data.submission, "manejo_27_conformidad") === 'C' ? '■' : '❏'} C ${this.getValue(data.submission, "manejo_27_conformidad") === 'NC' ? '■' : '❏'} NC ${this.getValue(data.submission, "manejo_27_conformidad") === 'NA' ? '■' : '❏'} NA`,
                                font: 'Arial',
                                size: 20
                            })
                            ],
                            spacing: {
                            after: 100,
                            before: 100
                            }
                        })
                        ],
                        columnSpan: 2
                    })
                    ]
                }),
                new TableRow({
                    children: [
                    new TableCell({
                        children: [
                        new Paragraph({
                            alignment: AlignmentType.LEFT,
                            children: [
                            new TextRun({
                                text:`Anexos: ${this.getFileList(this.getValue(data.submission, "manejo_27_adjuntos_url"))}`,
                                font: 'Arial',
                                size: 20
                            })
                            ],
                            spacing: {
                            after: 100,
                            before: 100
                            }
                        })
                        ],
                        columnSpan: 10
                    }),
                    ]
                }),
                new TableRow({
                    children: [
                    new TableCell({
                        children: [
                        new Paragraph({
                            alignment: AlignmentType.LEFT,
                            children: [
                            new TextRun({
                                text: 'Explique: ' + this.getValue(data.submission, "manejo_27_pregunta"),
                                font: 'Arial',
                                size: 20
                            })
                            ],
                            spacing: {
                            after: 100,
                            before: 100
                            }
                        })
                        ],
                        columnSpan: 10
                    }),
                    ]
                }),
                new TableRow({
                    children: [
                    new TableCell({
                        children: [
                        new Paragraph({
                            alignment: AlignmentType.LEFT,
                            children: [
                            new TextRun({
                                text: '28',
                                font: 'Arial',
                                size: 20
                            })
                            ],
                            spacing: {
                            after: 100,
                            before: 100
                            }
                        })
                        ],
                        columnSpan: 1
                    }),
                    new TableCell({
                        children: [
                        new Paragraph({
                            alignment: AlignmentType.LEFT,
                            children: [
                            new TextRun({
                                text: '¿Las prácticas y registros de cosecha permiten mantener la integridad del producto?',
                                font: 'Arial',
                                size: 20
                            })
                            ],
                            spacing: {
                            after: 100,
                            before: 100
                            }
                        })
                        ],
                        columnSpan: 7
                    }),
                    new TableCell({
                        children: [
                        new Paragraph({
                            alignment: AlignmentType.LEFT,
                            children: [
                            new TextRun({
                                text: `${this.getValue(data.submission, "manejo_28_conformidad") === 'C' ? '■' : '❏'} C ${this.getValue(data.submission, "manejo_28_conformidad") === 'NC' ? '■' : '❏'} NC ${this.getValue(data.submission, "manejo_28_conformidad") === 'NA' ? '■' : '❏'} NA`,
                                font: 'Arial',
                                size: 21
                            })
                            ],
                            spacing: {
                            after: 100,
                            before: 100
                            }
                        })
                        ],
                        columnSpan: 2
                    })
                    ]
                }),
                new TableRow({
                    children: [
                    new TableCell({
                        children: [
                        new Paragraph({
                            alignment: AlignmentType.LEFT,
                            children: [
                            new TextRun({
                                text:`Anexos: ${this.getFileList(this.getValue(data.submission, "manejo_28_adjuntos_url"))}`,
                                font: 'Arial',
                                size: 20
                            })
                            ],
                            spacing: {
                            after: 100,
                            before: 100
                            }
                        })
                        ],
                        columnSpan: 10
                    }),
                    ]
                }),
                new TableRow({
                    children: [
                    new TableCell({
                        children: [
                        new Paragraph({
                            alignment: AlignmentType.LEFT,
                            children: [
                            new TextRun({
                                text: 'Explique: ' + this.getValue(data.submission, "manejo_28_pregunta"),
                                font: 'Arial',
                                size: 20
                            })
                            ],
                            spacing: {
                            after: 100,
                            before: 100
                            }
                        })
                        ],
                        columnSpan: 10
                    }),
                    ]
                }),
                new TableRow({
                    children: [
                    new TableCell({
                        children: [
                        new Paragraph({
                            alignment: AlignmentType.LEFT,
                            children: [
                            new TextRun({
                                text: '29',
                                font: 'Arial',
                                size: 20
                            })
                            ],
                            spacing: {
                            after: 100,
                            before: 100
                            }
                        })
                        ],
                        columnSpan: 1
                    }),
                    new TableCell({
                        children: [
                        new Paragraph({
                            alignment: AlignmentType.LEFT,
                            children: [
                            new TextRun({
                                text: '¿Se utilizan sustancias permitidas para la limpieza y desinfección del producto orgánico?',
                                font: 'Arial',
                                size: 20
                            })
                            ],
                            spacing: {
                            after: 100,
                            before: 100
                            }
                        })
                        ],
                        columnSpan: 7
                    }),
                    new TableCell({
                        children: [
                        new Paragraph({
                            alignment: AlignmentType.LEFT,
                            children: [
                            new TextRun({
                                text: `${this.getValue(data.submission, "manejo_29_conformidad") === 'C' ? '■' : '❏'} C ${this.getValue(data.submission, "manejo_29_conformidad") === 'NC' ? '■' : '❏'} NC ${this.getValue(data.submission, "manejo_29_conformidad") === 'NA' ? '■' : '❏'} NA`,
                                font: 'Arial',
                                size: 21
                            })
                            ],
                            spacing: {
                            after: 100,
                            before: 100
                            }
                        })
                        ],
                        columnSpan: 2
                    })
                    ]
                }),
                new TableRow({
                    children: [
                    new TableCell({
                        children: [
                        new Paragraph({
                            alignment: AlignmentType.LEFT,
                            children: [
                            new TextRun({
                                text:`Anexos: ${this.getFileList(this.getValue(data.submission, "manejo_29_adjuntos_url"))}`,
                                font: 'Arial',
                                size: 20
                            })
                            ],
                            spacing: {
                            after: 100,
                            before: 100
                            }
                        })
                        ],
                        columnSpan: 10
                    }),
                    ]
                }),
                new TableRow({
                    children: [
                    new TableCell({
                        children: [
                        new Paragraph({
                            alignment: AlignmentType.LEFT,
                            children: [
                            new TextRun({
                                text: 'Explique: ' + this.getValue(data.submission, "manejo_29_pregunta"),
                                font: 'Arial',
                                size: 20
                            })
                            ],
                            spacing: {
                            after: 100,
                            before: 100
                            }
                        })
                        ],
                        columnSpan: 10
                    }),
                    ]
                }),
                new TableRow({
                    children: [
                    new TableCell({
                        children: [
                        new Paragraph({
                            alignment: AlignmentType.LEFT,
                            children: [
                            new TextRun({
                                text: '30',
                                font: 'Arial',
                                size: 20
                            })
                            ],
                            spacing: {
                            after: 100,
                            before: 100
                            }
                        })
                        ],
                        columnSpan: 1
                    }),
                    new TableCell({
                        children: [
                        new Paragraph({
                            alignment: AlignmentType.LEFT,
                            children: [
                            new TextRun({
                                text: '¿Las prácticas y registros de manejo post-cosecha/empaque permiten mantener la integridad orgánica del producto?',
                                font: 'Arial',
                                size: 20
                            })
                            ],
                            spacing: {
                            after: 100,
                            before: 100
                            }
                        })
                        ],
                        columnSpan: 7
                    }),
                    new TableCell({
                        children: [
                        new Paragraph({
                            alignment: AlignmentType.LEFT,
                            children: [
                            new TextRun({
                                text: `${this.getValue(data.submission, "manejo_30_conformidad") === 'C' ? '■' : '❏'} C ${this.getValue(data.submission, "manejo_30_conformidad") === 'NC' ? '■' : '❏'} NC ${this.getValue(data.submission, "manejo_30_conformidad") === 'NA' ? '■' : '❏'} NA`,
                                font: 'Arial',
                                size: 21
                            })
                            ],
                            spacing: {
                            after: 100,
                            before: 100
                            }
                        })
                        ],
                        columnSpan: 2
                    })
                    ]
                }),
                new TableRow({
                    children: [
                    new TableCell({
                        children: [
                        new Paragraph({
                            alignment: AlignmentType.LEFT,
                            children: [
                            new TextRun({
                                text:`Anexos: ${this.getFileList(this.getValue(data.submission, "manejo_30_adjuntos_url"))}`,
                                font: 'Arial',
                                size: 20
                            })
                            ],
                            spacing: {
                            after: 100,
                            before: 100
                            }
                        })
                        ],
                        columnSpan: 10
                    }),
                    ]
                }),
                new TableRow({
                    children: [
                    new TableCell({
                        children: [
                        new Paragraph({
                            alignment: AlignmentType.LEFT,
                            children: [
                            new TextRun({
                                text: 'Explique: ' + this.getValue(data.submission, "manejo_30_pregunta"),
                                font: 'Arial',
                                size: 20
                            })
                            ],
                            spacing: {
                            after: 100,
                            before: 100
                            }
                        })
                        ],
                        columnSpan: 10
                    }),
                    ]
                }),
                new TableRow({
                    children: [
                    new TableCell({
                        children: [
                        new Paragraph({
                            alignment: AlignmentType.LEFT,
                            children: [
                            new TextRun({
                                text: '31',
                                font: 'Arial',
                                size: 20
                            })
                            ],
                            spacing: {
                            after: 100,
                            before: 100
                            }
                        })
                        ],
                        columnSpan: 1
                    }),
                    new TableCell({
                        children: [
                        new Paragraph({
                            alignment: AlignmentType.LEFT,
                            children: [
                            new TextRun({
                                text: '¿Las prácticas de transporte mantienen la integridad orgánica del producto y están registradas?',
                                font: 'Arial',
                                size: 20
                            })
                            ],
                            spacing: {
                            after: 100,
                            before: 100
                            }
                        })
                        ],
                        columnSpan: 7
                    }),
                    new TableCell({
                        children: [
                        new Paragraph({
                            alignment: AlignmentType.LEFT,
                            children: [
                            new TextRun({
                                text: `${this.getValue(data.submission, "manejo_31_conformidad") === 'C' ? '■' : '❏'} C ${this.getValue(data.submission, "manejo_31_conformidad") === 'NC' ? '■' : '❏'} NC ${this.getValue(data.submission, "manejo_31_conformidad") === 'NA' ? '■' : '❏'} NA`,
                                font: 'Arial',
                                size: 21
                            })
                            ],
                            spacing: {
                            after: 100,
                            before: 100
                            }
                        })
                        ],
                        columnSpan: 2
                    })
                    ]
                }),
                new TableRow({
                    children: [
                    new TableCell({
                        children: [
                        new Paragraph({
                            alignment: AlignmentType.LEFT,
                            children: [
                            new TextRun({
                                text:`Anexos: ${this.getFileList(this.getValue(data.submission, "manejo_31_adjuntos_url"))}`,
                                font: 'Arial',
                                size: 20
                            })
                            ],
                            spacing: {
                            after: 100,
                            before: 100
                            }
                        })
                        ],
                        columnSpan: 10
                    }),
                    ]
                }),
                new TableRow({
                    children: [
                    new TableCell({
                        children: [
                        new Paragraph({
                            alignment: AlignmentType.LEFT,
                            children: [
                            new TextRun({
                                text: 'Explique: ' + this.getValue(data.submission, "manejo_31_pregunta"),
                                font: 'Arial',
                                size: 20
                            })
                            ],
                            spacing: {
                            after: 100,
                            before: 100
                            }
                        })
                        ],
                        columnSpan: 10
                    }),
                    ]
                }),
                new TableRow({
                    children: [
                    new TableCell({
                        children: [
                        new Paragraph({
                            alignment: AlignmentType.LEFT,
                            children: [
                            new TextRun({
                                text: '32',
                                font: 'Arial',
                                size: 20
                            })
                            ],
                            spacing: {
                            after: 100,
                            before: 100
                            }
                        })
                        ],
                        columnSpan: 1
                    }),
                    new TableCell({
                        children: [
                        new Paragraph({
                            alignment: AlignmentType.LEFT,
                            children: [
                            new TextRun({
                                text: '¿Se encontró una clara separación física y documental de los productos orgánicos, en conversion y no-orgánicos durante la producción, cosecha, transporte y venta?',
                                font: 'Arial',
                                size: 20
                            })
                            ],
                            spacing: {
                            after: 100,
                            before: 100
                            }
                        })
                        ],
                        columnSpan: 7
                    }),
                    new TableCell({
                        children: [
                        new Paragraph({
                            alignment: AlignmentType.LEFT,
                            children: [
                            new TextRun({
                                text: `${this.getValue(data.submission, "manejo_32_conformidad") === 'C' ? '■' : '❏'} C ${this.getValue(data.submission, "manejo_32_conformidad") === 'NC' ? '■' : '❏'} NC ${this.getValue(data.submission, "manejo_32_conformidad") === 'NA' ? '■' : '❏'} NA`,
                                font: 'Arial',
                                size: 21
                            })
                            ],
                            spacing: {
                            after: 100,
                            before: 100
                            }
                        })
                        ],
                        columnSpan: 2
                    })
                    ]
                }),
                new TableRow({
                    children: [
                    new TableCell({
                        children: [
                        new Paragraph({
                            alignment: AlignmentType.LEFT,
                            children: [
                            new TextRun({
                                text:`Anexos: ${this.getFileList(this.getValue(data.submission, "manejo_32_adjuntos_url"))}`,
                                font: 'Arial',
                                size: 20
                            })
                            ],
                            spacing: {
                            after: 100,
                            before: 100
                            }
                        })
                        ],
                        columnSpan: 10
                    }),
                    ]
                }),
                new TableRow({
                    children: [
                    new TableCell({
                        children: [
                        new Paragraph({
                            alignment: AlignmentType.LEFT,
                            children: [
                            new TextRun({
                                text: 'Explique: ' + this.getValue(data.submission, "manejo_32_pregunta"),
                                font: 'Arial',
                                size: 20
                            })
                            ],
                            spacing: {
                            after: 100,
                            before: 100
                            }
                        })
                        ],
                        columnSpan: 10
                    }),
                    ]
                }),
            ],
            margins: {
                left: 70,
                right: 70
            },
            width: {
                size: 100,
                type: WidthType.PERCENTAGE
            },
            columnWidths: [500,1359,1359,1359,1359,1359,1359,1359,1200,1200]
        })

        return table
    }

    public createProductosCertificados(data) {
        const table = new Table({
            rows: [
                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: 'PRODUCTOS CERTIFICADOS',
                                            font: 'Calibri (Body)',
                                            size: 28,
                                            bold: true
                                        })
                                    ]
                                })
                            ],
                            columnSpan: 10
                        })
                    ]
                }),
                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: '1',
                                            font: 'Arial',
                                            size: 20
                                        })
                                    ],
                                    spacing: {
                                        after: 100,
                                        before: 100
                                    }
                                })
                            ],
                            columnSpan: 1
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: '¿Todos los productos comercializados como orgánicos fueron incluidos en la solicitud de certificación?',
                                            font: 'Arial',
                                            size: 20
                                        })
                                    ],
                                    spacing: {
                                        after: 100,
                                        before: 100
                                    }
                                })
                            ],
                            columnSpan: 7
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: `${this.getValue(data.submission, "productos_certificados_1_conformidad") === 'C' ? '■' : '❏'} C ${this.getValue(data.submission, "productos_certificados_1_conformidad") === 'NC' ? '■' : '❏'} NC ${this.getValue(data.submission, "productos_certificados_1_conformidad") === 'NA' ? '■' : '❏'} NA`,
                                            font: 'Arial',
                                            size: 20
                                        })
                                    ],
                                    spacing: {
                                        after: 100,
                                        before: 100
                                    }
                                })
                            ],
                            columnSpan: 2
                        })
                    ]
                }),
                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: 'Explique: ' + this.getValue(data.submission, "productos_certificados_1_explique"),
                                            font: 'Arial',
                                            size: 20
                                        })
                                    ],
                                    spacing: {
                                        after: 100,
                                        before: 100
                                    }
                                })
                            ],
                            columnSpan: 10
                        }),
                    ]
                }),
                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: '2',
                                            font: 'Arial',
                                            size: 20
                                        })
                                    ],
                                    spacing: {
                                        after: 100,
                                        before: 100
                                    }
                                })
                            ],
                            columnSpan: 1
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: '¿Fue posible realizar un balance de masas?',
                                            font: 'Arial',
                                            size: 20
                                        })
                                    ],
                                    spacing: {
                                        after: 100,
                                        before: 100
                                    }
                                })
                            ],
                            columnSpan: 7
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: `${this.getValue(data.submission, "productos_certificados_1_tabla_conformidad") === 'C' ? '■' : '❏'} C ${this.getValue(data.submission, "productos_certificados_1_tabla_conformidad") === 'NC' ? '■' : '❏'} NC ${this.getValue(data.submission, "productos_certificados_1_tabla_conformidad") === 'NA' ? '■' : '❏'} NA`,
                                            font: 'Arial',
                                            size: 20
                                        })
                                    ],
                                    spacing: {
                                        after: 100,
                                        before: 100
                                    }
                                })
                            ],
                            columnSpan: 2
                        })
                    ]
                }),
                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: 'Explique: ' + this.getValue(data.submission, "productos_certificados_1_comentarios"),
                                            font: 'Arial',
                                            size: 20
                                        })
                                    ],
                                    spacing: {
                                        after: 100,
                                        before: 100
                                    }
                                })
                            ],
                            columnSpan: 10
                        }),
                    ]
                }),
                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: '3',
                                            font: 'Arial',
                                            size: 20
                                        })
                                    ],
                                    spacing: {
                                        after: 100,
                                        before: 100
                                    }
                                })
                            ],
                            columnSpan: 1
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: '¿Fue posible realizar un ejercicio de trazabilidad?',
                                            font: 'Arial',
                                            size: 20
                                        })
                                    ],
                                    spacing: {
                                        after: 100,
                                        before: 100
                                    }
                                })
                            ],
                            columnSpan: 7
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: `${this.getValue(data.submission, "productos_certificados_3_conformidad") === 'C' ? '■' : '❏'} C ${this.getValue(data.submission, "productos_certificados_3_conformidad") === 'NC' ? '■' : '❏'} NC ${this.getValue(data.submission, "productos_certificados_3_conformidad") === 'NA' ? '■' : '❏'} NA`,
                                            font: 'Arial',
                                            size: 20
                                        })
                                    ],
                                    spacing: {
                                        after: 100,
                                        before: 100
                                    }
                                })
                            ],
                            columnSpan: 2
                        })
                    ]
                }),
                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: 'Explique: ' + this.getValue(data.submission, "productos_certificados_3_comentarios"),
                                            font: 'Arial',
                                            size: 20,
                                            bold: true
                                        })
                                    ],
                                    spacing: {
                                        after: 100,
                                        before: 100
                                    }
                                })
                            ],
                            columnSpan: 10
                        }),
                    ]
                })
            ],
            margins: {
                left: 70,
                right: 70
            },
            width: {
                size: 100,
                type: WidthType.PERCENTAGE
            },
            columnWidths: [500,1359,1359,1359,1359,1359,1359,1359,1200,1200]
        })

        let tableRowCiclo = new TableRow({
            children: []
        })

        let tableCellCiclo = new TableCell({
            children: []
        })

        let paragraphCiclo = new Paragraph({
            alignment: AlignmentType.LEFT,
            children: [
                new TextRun({
                    text: '',
                    font: 'Arial',
                    size: 20
                })
            ]
        })

        let table_ciclo = new Table({
            rows: [
                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: 'Producto',
                                            font: 'Arial',
                                            size: 20,
                                            bold: true
                                        })
                                    ]
                                })
                            ],
                            shading: {
                                fill: '#C0C0C0',
                                val: ShadingType.PERCENT_80
                            },
                            columnSpan: 3
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: 'Parcela',
                                            font: 'Arial',
                                            size: 20,
                                            bold: true
                                        })
                                    ]
                                })
                            ],
                            shading: {
                                fill: '#C0C0C0',
                                val: ShadingType.PERCENT_80
                            },
                            columnSpan: 3
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: 'Superficie (ha)',
                                            font: 'Arial',
                                            size: 20,
                                            bold: true
                                        })
                                    ]
                                })
                            ],
                            shading: {
                                fill: '#C0C0C0',
                                val: ShadingType.PERCENT_80
                            },
                            columnSpan: 2
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: 'Estimación (Kg)',
                                            font: 'Arial',
                                            size: 20,
                                            bold: true
                                        })
                                    ]
                                })
                            ],
                            shading: {
                                fill: '#C0C0C0',
                                val: ShadingType.PERCENT_80
                            },
                            columnSpan: 2
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: 'Anexos',
                                            font: 'Arial',
                                            size: 20,
                                            bold: true
                                        })
                                    ]
                                })
                            ],
                            shading: {
                                fill: '#C0C0C0',
                                val: ShadingType.PERCENT_80
                            },
                            columnSpan: 2
                        }),
                    ]
                })
            ],
            margins: {
                left: 70,
                right: 70
            },
            width: {
                size: 100,
                type: WidthType.PERCENTAGE
            },
            columnWidths: [500,1359,1359,1359,1359,1359,1359,1359,1200,1200]
        })

        let listaCiclo = this.getValueList(data.submission, "trazabilidad")
        listaCiclo.forEach(ciclo => {
            var anexos = ''
            for(var i in ciclo.anexos) {
                const tmp = ciclo.anexos[i].split("/")
                anexos += tmp[tmp.length-1] + ", "
            }

            anexos = anexos.substr(0, anexos.length-2)

            table_ciclo.addChildElement(
                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: ciclo.producto,
                                            font: 'Arial',
                                            size: 20
                                        })
                                    ],
                                    spacing: {
                                        after: 100,
                                        before: 100
                                    }
                                })
                            ],
                            columnSpan: 3
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: ciclo.parcela,
                                            font: 'Arial',
                                            size: 20
                                        })
                                    ],
                                    spacing: {
                                        after: 100,
                                        before: 100
                                    }
                                })
                            ],
                            columnSpan: 3
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: ciclo.superficie,
                                            font: 'Arial',
                                            size: 20
                                        })
                                    ],
                                    spacing: {
                                        after: 100,
                                        before: 100
                                    }
                                })
                            ],
                            columnSpan: 2
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: ciclo.estimacion,
                                            font: 'Arial',
                                            size: 20
                                        })
                                    ],
                                    spacing: {
                                        after: 100,
                                        before: 100
                                    }
                                })
                            ],
                            columnSpan: 2
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: `${anexos === '' ? 'Sin anexos' : anexos}`,
                                            font: 'Arial',
                                            size: 20
                                        })
                                    ],
                                    spacing: {
                                        after: 100,
                                        before: 100
                                    }
                                })
                            ],
                            columnSpan: 2
                        }),
                    ]
                })
            )
        });

        tableCellCiclo.addChildElement(new Paragraph({
            alignment: AlignmentType.CENTER,
            children: [
                new TextRun({
                    text: 'Agregue un cuadro con información del siguiente ciclo de producción',
                    font: 'Arial',
                    size: 20,
                    bold: true
                })
            ]
        }))
        tableCellCiclo.addChildElement(table_ciclo)
        tableCellCiclo.addChildElement(paragraphCiclo)
        tableRowCiclo.addChildElement(tableCellCiclo)
        table.addChildElement(tableRowCiclo)

        table.addChildElement(new TableRow({
            children: [
                new TableCell({
                    children: [
                        new Paragraph({
                            alignment: AlignmentType.LEFT,
                            children: [
                                new TextRun({
                                    text: 'Comentarios: ' + this.getValue(data.submission, "productos_certificados_3_comentarios2"),
                                    font: 'Arial',
                                    size: 20,
                                    bold: true
                                })
                            ],
                            spacing: {
                                after: 100,
                                before: 100
                            }
                        })
                    ],
                    columnSpan: 10
                }),
            ]
        }))

        table.addChildElement(new TableRow({
            children: [
                new TableCell({
                    children: [
                        new Paragraph({
                            alignment: AlignmentType.LEFT,
                            children: [
                                new TextRun({
                                    text: '4',
                                    font: 'Arial',
                                    size: 20
                                })
                            ],
                            spacing: {
                                after: 100,
                                before: 100
                            }
                        })
                    ],
                    columnSpan: 1
                }),
                new TableCell({
                    children: [
                        new Paragraph({
                            alignment: AlignmentType.LEFT,
                            children: [
                                new TextRun({
                                    text: 'Se llenó y se dejó copia del formato de Salida de Inspección MTO-FINS-03-14',
                                    font: 'Arial',
                                    size: 20
                                })
                            ],
                            spacing: {
                                after: 100,
                                before: 100
                            }
                        })
                    ],
                    columnSpan: 7
                }),
                new TableCell({
                    children: [
                        new Paragraph({
                            alignment: AlignmentType.LEFT,
                            children: [
                                new TextRun({
                                    text: `${this.getValue(data.submission, "productos_certificados_4_conformidad") === 'C' ? '■' : '❏'} C ${this.getValue(data.submission, "productos_certificados_4_conformidad") === 'NC' ? '■' : '❏'} NC ${this.getValue(data.submission, "productos_certificados_4_conformidad") === 'NA' ? '■' : '❏'} NA`,
                                    font: 'Arial',
                                    size: 20
                                })
                            ],
                            spacing: {
                                after: 100,
                                before: 100
                            }
                        })
                    ],
                    columnSpan: 2
                })
            ]
        }))
        table.addChildElement(new TableRow({
            children: [
                new TableCell({
                    children: [
                        new Paragraph({
                            alignment: AlignmentType.LEFT,
                            children: [
                                new TextRun({
                                    text: 'Explique: ' + this.getValue(data.submission, "productos_certificados_4_pregunta"),
                                    font: 'Arial',
                                    size: 20,
                                })
                            ],
                            spacing: {
                                after: 100,
                                before: 100
                            }
                        })
                    ],
                    columnSpan: 10
                }),
            ]
        }))

        return table
    }

    public createResumen(data) {
        const table = new Table({
            rows: [
                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: 'No',
                                            font: 'Arial',
                                            size: 20,
                                            bold: true
                                        })
                                    ],
                                    spacing: {
                                        after: 100,
                                        before: 100
                                    }
                                })
                            ],
                            columnSpan: 1
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: 'Hallazgos',
                                            font: 'Arial',
                                            size: 20,
                                            bold: true
                                        })
                                    ],
                                    spacing: {
                                        after: 100,
                                        before: 100
                                    }
                                })
                            ],
                            columnSpan: 3
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: 'Apartado de la norma',
                                            font: 'Arial',
                                            size: 20,
                                            bold: true
                                        })
                                    ],
                                    spacing: {
                                        after: 100,
                                        before: 100
                                    }
                                })
                            ],
                            columnSpan: 2
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: 'Evicencia',
                                            font: 'Arial',
                                            size: 20,
                                            bold: true
                                        })
                                    ],
                                    spacing: {
                                        after: 100,
                                        before: 100
                                    }
                                })
                            ],
                            columnSpan: 3
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: 'Clasificación',
                                            font: 'Arial',
                                            size: 20,
                                            bold: true
                                        })
                                    ],
                                    spacing: {
                                        after: 100,
                                        before: 100
                                    }
                                })
                            ],
                            columnSpan: 2
                        }),
                    ]
                })
            ],
            margins: {
                left: 70,
                right: 70
            },
            width: {
                size: 100,
                type: WidthType.PERCENTAGE
            },
            columnWidths: [500,1359,1359,1359,1359,1359,1359,1359,1200,1200]
        })

        const listaComentarios = this.getValueList(data.submission, "hallazgos")
        listaComentarios.forEach((comentario, index) => {
            table.addChildElement(
                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: (index+1),
                                            font: 'Arial',
                                            size: 20,
                                            bold: true
                                        })
                                    ],
                                    spacing: {
                                        after: 100,
                                        before: 100
                                    }
                                })
                            ],
                            columnSpan: 1
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: comentario.resumen_hallazgos_hallazgo,
                                            font: 'Arial',
                                            size: 20,
                                            bold: true
                                        })
                                    ],
                                    spacing: {
                                        after: 100,
                                        before: 100
                                    }
                                })
                            ],
                            columnSpan: 3
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: comentario.resumen_hallazgos_apartado,
                                            font: 'Arial',
                                            size: 20,
                                            bold: true
                                        })
                                    ],
                                    spacing: {
                                        after: 100,
                                        before: 100
                                    }
                                })
                            ],
                            columnSpan: 2
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: comentario.resumen_hallazgos_evidencia,
                                            font: 'Arial',
                                            size: 20,
                                            bold: true
                                        })
                                    ],
                                    spacing: {
                                        after: 100,
                                        before: 100
                                    }
                                })
                            ],
                            columnSpan: 3
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: comentario.resumen_hallazgos_clasificación === 'Mayor' ? '■ Mayor' : '❏ Mayor',
                                            font: 'Arial',
                                            size: 20,
                                            bold: true
                                        })
                                    ],
                                    spacing: {
                                        after: 100,
                                        before: 100
                                    }
                                }),
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: comentario.resumen_hallazgos_clasificación === 'Menor' ? '■ Menor' : '❏ Menor',
                                            font: 'Arial',
                                            size: 20,
                                            bold: true
                                        })
                                    ],
                                    spacing: {
                                        after: 100,
                                        before: 100
                                    }
                                })
                            ],
                            columnSpan: 2
                        }),
                    ]
                })
            )
        });

        return table
    }

    public createResumenReunion(data) {
        const table = new Table({
            rows: [
                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: 'Resumen de Reunión Final',
                                            font: 'Arial',
                                            size: 20,
                                            bold: true
                                        })
                                    ],
                                    spacing: {
                                        after: 100,
                                        before: 100
                                    }
                                })
                            ],
                            shading: {
                                fill: '#C0C0C0',
                                val: ShadingType.PERCENT_80
                            },
                            columnSpan: 10
                        })
                    ]
                })
            ],
            margins: {
                left: 70,
                right: 70
            },
            width: {
                size: 100,
                type: WidthType.PERCENTAGE
            },
            columnWidths: [500,1359,1359,1359,1359,1359,1359,1359,1200,1200]
        })

        const listaComentarios = this.getValueList(data.submission, "resumen_reunion")
        listaComentarios.forEach((comentario, index) => {
            table.addChildElement(
                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: `${index+1}`,
                                            font: 'Arial',
                                            size: 20,
                                            bold: true
                                        })
                                    ],
                                    spacing: {
                                        after: 100,
                                        before: 100
                                    }
                                })
                            ],
                            columnSpan: 1
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: comentario.resumen_reunion_final,
                                            font: 'Arial',
                                            size: 20,
                                            bold: true
                                        })
                                    ],
                                    spacing: {
                                        after: 100,
                                        before: 100
                                    }
                                })
                            ],
                            columnSpan: 9
                        })
                    ]
                })
            )
        });

        return table
    }

    public createComentariosOperador(data) {
        const table = new Table({
            rows: [
                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: 'Comentarios del Operador',
                                            font: 'Arial',
                                            size: 20,
                                            bold: true
                                        })
                                    ],
                                    spacing: {
                                        after: 100,
                                        before: 100
                                    }
                                })
                            ],
                            shading: {
                                fill: '#C0C0C0',
                                val: ShadingType.PERCENT_80
                            },
                            columnSpan: 10
                        })
                    ]
                })
            ],
            margins: {
                left: 70,
                right: 70
            },
            width: {
                size: 100,
                type: WidthType.PERCENTAGE
            },
            columnWidths: [500,1359,1359,1359,1359,1359,1359,1359,1200,1200]
        })

        const listaComentarios = this.getValueList(data.submission, "resumen_comentarios")
        listaComentarios.forEach((comentario, index) => {
            table.addChildElement(
                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: `${index+1}`,
                                            font: 'Arial',
                                            size: 20,
                                            bold: true
                                        })
                                    ],
                                    spacing: {
                                        after: 100,
                                        before: 100
                                    }
                                })
                            ],
                            columnSpan: 1
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: comentario.resumen_comentarios_operador,
                                            font: 'Arial',
                                            size: 20,
                                            bold: true
                                        })
                                    ],
                                    spacing: {
                                        after: 100,
                                        before: 100
                                    }
                                })
                            ],
                            columnSpan: 9
                        })
                    ]
                })
            )
        });

        return table
    }

    public createSigns(data, document, signatureImage, signatureImage2) {
        const table = new Table({
            rows: [
                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: 'Confirmación de la información',
                                            font: 'Arial',
                                            size: 20,
                                            bold: true
                                        })
                                    ],
                                    spacing: {
                                        after: 100,
                                        before: 100
                                    }
                                })
                            ],
                            shading: {
                                fill: '#C0C0C0',
                                val: ShadingType.PERCENT_80
                            },
                            columnSpan: 2
                        })
                    ]
                }),
                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        signatureImage2 
                                            ? Media.addImage(document, signatureImage2.toString(), 200, 100) 
                                            : new TextRun({
                                                text: ''
                                            })
                                    ],
                                    spacing: {
                                        after: 100,
                                        before: 100
                                    }
                                }),
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: data.inspector,
                                            font: 'Arial',
                                            size: 20,
                                        })
                                    ]
                                }),
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: '______________________________________',
                                            font: 'Arial',
                                            size: 20,
                                        })
                                    ]
                                }),
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: 'Inspector',
                                            font: 'Arial',
                                            size: 20,
                                        })
                                    ],
                                    spacing: {
                                        after: 100,
                                        before: 100
                                    }
                                })
                            ]
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        signatureImage 
                                            ? Media.addImage(document, signatureImage.toString(), 200, 100) 
                                            : new TextRun({
                                                text: ''
                                            })
                                    ],
                                    spacing: {
                                        after: 100,
                                        before: 100
                                    }
                                }),
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: data.customer.Company_Name,
                                            font: 'Arial',
                                            size: 20,
                                        })
                                    ]
                                }),new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: '______________________________________',
                                            font: 'Arial',
                                            size: 20,
                                        })
                                    ]
                                }),
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: 'Operador',
                                            font: 'Arial',
                                            size: 20,
                                        })
                                    ],
                                    spacing: {
                                        after: 100,
                                        before: 100
                                    }
                                })
                            ]
                        })
                    ]
                })
            ],
            margins: {
                left: 70,
                right: 70
            },
            width: {
                size: 100,
                type: WidthType.PERCENTAGE
            },
            columnWidths: [6206, 6206]
        })

        return table
    }

    getValue(submission, name) {
        var value = null
        for (const i in submission) {
            const element = submission[i]
            if (element.name === name) {
                value = element.value
            }
        }
        if (value) return value
        else return ''
    }

    getValueList(submission, name) {
        var value = null
        for (const i in submission) {
            const element = submission[i]
            if (element.name === name) {
                value = element.value
            }
        }
        if (value) return value
        else return []
    }

    getFileList(value) {
        var anexos = ''
        for(var i in value) {
            const tmp = value[i].split("/")
            anexos += tmp[tmp.length-1] + ", "
        }

        anexos = anexos.substr(0, anexos.length-2)

        return anexos
    }
    // END MTO_FINS_03_1_1
}
