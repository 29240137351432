<div class="p-4">
  <div class="container">
    <div *ngIf="loading" class="spinner-border spinner-border-sm"></div>
    <div *ngIf="user" class="home-fade-in">
      <div style="display: flex; justify-content: left; align-items: center;">
        <img *ngIf="user.photoUrl" style="background-color: rgb(219, 219, 219); width: 70px; height: 70px; border-radius: 100%; margin-right: 20px;" [src]="user.photoUrl"/>
        <div style="display: flex; flex-direction: column; justify-content: left; align-items: flex-start;">
          <h2>¡Hola, {{ user.firstName }}!</h2>
          <p style="font-size: 12px; color: rgb(192, 192, 192); margin: 0;">Has iniciado sesión como <b>{{type}}</b></p>
        </div>
      </div>
      <hr />
      <br />
      <div class="row">
        <div class="col-md-4 mb-4" *ngFor="let module of modules">
          <dashboard-item [module]="module"></dashboard-item>
        </div>
      </div>
    </div>
  </div>
</div>