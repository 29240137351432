import { readFileSync } from 'fs';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '@environments/environment';

@Injectable({ providedIn: 'root' })
export class RecordsService {

    constructor(private http: HttpClient) { }

    createNotAnnouncedInspection(recordId) {
        const jwtToken = (document.cookie.split(';').find(x => x.includes('jwtToken')) || '=').split('=')[1]

        const headers = {
            'Content-Type': 'application/json',
            'Authorization': jwtToken
        };

        return this.http.patch<any>(`${environment.apiUrl}/inspections/${recordId}/notAnnounced `, { headers });
    }

    getPendings(page, search) {
        const jwtToken = (document.cookie.split(';').find(x => x.includes('jwtToken')) || '=').split('=')[1]

        const headers = {
            'Content-Type': 'application/json',
            'Authorization': jwtToken
        };
        
        const body = {
            page: page,
            search: search
        }

        return this.http.post<any>(`${environment.apiUrl}/records/pending`, body, { headers });
    }

    getDone(page, search) {
        const jwtToken = (document.cookie.split(';').find(x => x.includes('jwtToken')) || '=').split('=')[1]

        const headers = {
            'Content-Type': 'application/json',
            'Authorization': jwtToken
        };

        const body = {
            page: page,
            search: search
        }

        return this.http.post<any>(`${environment.apiUrl}/records/done`, body, { headers });
    }

    getRecord(idRecord) {
        const jwtToken = (document.cookie.split(';').find(x => x.includes('jwtToken')) || '=').split('=')[1]

        const headers = {
            'Content-Type': 'application/json',
            'Authorization': jwtToken
        };

        return this.http.get<any>(`${environment.apiUrl}/records/done/${idRecord}`, { headers });
    }

    getRecordNotAnnounced(idRecord, inspectionId) {
        const jwtToken = (document.cookie.split(';').find(x => x.includes('jwtToken')) || '=').split('=')[1]

        const headers = {
            'Content-Type': 'application/json',
            'Authorization': jwtToken
        };

        return this.http.get<any>(`${environment.apiUrl}/records/done/${idRecord}/${inspectionId}`, { headers });
    }

    saveForm(recordId, formId, form) {
        if(formId === 13) {
            for(var i in form) {
                for(var j in form[i]) {
                    form[i][j]["isSaved"] = "No"
                }
            }
        }

        let body = {
            submission: []
        }

        for (var i in Object.keys(form)) {
            const key = Object.keys(form)[i]
            body.submission.push({
                name: key,
                value: form[key]
            })
        }

        const jwtToken = (document.cookie.split(';').find(x => x.includes('jwtToken')) || '=').split('=')[1]

        const headers = {
            'Content-Type': 'application/json',
            'Authorization': jwtToken
        };

        return this.http.post<any>(`${environment.apiUrl}/records/${recordId}/form/${formId}`, body, { headers });
    }

    getForms(recordId) {
        const jwtToken = (document.cookie.split(';').find(x => x.includes('jwtToken')) || '=').split('=')[1]

        const headers = {
            'Content-Type': 'application/json',
            'Authorization': jwtToken
        };

        return this.http.get<any>(`${environment.apiUrl}/records/formsToUpdate/${recordId}`, { headers });
    }

    getForm(recordId, formId) {
        const jwtToken = (document.cookie.split(';').find(x => x.includes('jwtToken')) || '=').split('=')[1]

        const headers = {
            'Content-Type': 'application/json',
            'Authorization': jwtToken
        };

        return this.http.get<any>(`${environment.apiUrl}/records/${recordId}/form/${formId}`, { headers });
    }


    updateJSONCertificate(recordId) {
        const jwtToken = (document.cookie.split(';').find(x => x.includes('jwtToken')) || '=').split('=')[1]

        const headers = {
            'Content-Type': 'application/json',
            'Authorization': jwtToken
        };

        const body = {}

        return this.http.post<any>(`${environment.apiUrl}/records/updateJson/${recordId}`, body, { headers });
    }

    getUrlStatus(url: string) {
        return this.http.get(url);
    }

    cancelRecord(recordId) {
        const jwtToken = (document.cookie.split(';').find(x => x.includes('jwtToken')) || '=').split('=')[1]

        const headers = {
            'Content-Type': 'application/json',
            'Authorization': jwtToken
        };

        return this.http.patch<any>(`${environment.apiUrl}/records/${recordId}/cancel `, { headers });
    }

    getCanceled(page, search) {
        const jwtToken = (document.cookie.split(';').find(x => x.includes('jwtToken')) || '=').split('=')[1]

        const headers = {
            'Content-Type': 'application/json',
            'Authorization': jwtToken
        };

        const body = {
            page: page,
            search: search
        }

        return this.http.post<any>(`${environment.apiUrl}/records/canceled`, body, { headers });
    }
}