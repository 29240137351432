import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { environment } from '@environments/environment';
import { User } from '@app/core/models';
import { AlertService } from '@app/core/services';

// Item in local storage for registered user
const userKey = "user"

@Injectable({ providedIn: 'root' })
export class AuthenticationService {
    public userSubject: BehaviorSubject<User>;
    public user: Observable<User>;

    constructor(
        private router: Router,
        private http: HttpClient,
        private alertService: AlertService
    ) {
        this.userSubject = new BehaviorSubject<User>(JSON.parse(localStorage.getItem('user')));
        this.user = this.userSubject.asObservable();
    }

    public get userValue(): User {
        return this.userSubject.value;
    }

    public updatePhoto(data) {
        this.userSubject.next(data)
    }

    getUserkey(){
        return userKey
    }

    login(email: string, password: string) {
        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json'
            })
        };
        return this.http.post<any>(`${environment.apiUrl}/auth/login`, { email, password }, httpOptions)
            .pipe(map(response => {
                const user = {
                    id: response.user.id,
                    email: response.user.email,
                    firstName: response.user.firstName,
                    middleName: response.user.middleName,
                    lastName: response.user.lastName,
                    photoUrl: response.user.photoUrl,
                    lastLogin: response.user.lastLogin,
                    type: response.user.type
                }
                localStorage.setItem(userKey, JSON.stringify(user))

                // Add token cookie that expires in 24 hours
                const expires = new Date(Date.now() + 24 * 60 * 60 * 1000).toUTCString();
                document.cookie = `jwtToken=${response.authorization}; expires=${expires}; path=/`;

                // Add token refresh cookie that expires in 5 days
                const expiresRefresh = new Date(Date.now() + 5 * 24 * 60 * 60 * 1000).toUTCString();
                document.cookie = `jwtRefresh=${response.refresh}; expires=${expiresRefresh}; path=/`;

                this.userSubject.next(user);
                return user;
            }));
    }

    recoverPassword(email: string) {
        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json'
            })
        };
        return this.http.post<any>(`${environment.apiUrl}/auth/validate`, { email }, httpOptions)
            .pipe(map(response => {
            }));
    }

    logout() {
        this.stopRefreshTokenTimer();
        const jwtToken = (document.cookie.split(';').find(x => x.includes('jwtToken')) || '=').split('=')[1]

        const headers = {
            'Content-Type': 'application/json',
            'Authorization': jwtToken
        };

        // Reset alerts on submit
        this.alertService.clear();

        this.http.post<any>(`${environment.apiUrl}/auth/logout`, {}, { headers }).subscribe(data => {
            //console.log(data)
            if (data.success) {
                this.alertService.success(data.message);
            } else {
                this.alertService.error(data.message);
            }
        })

        document.cookie = `jwtToken=; expires=${Date.now()}; path=/`;
        document.cookie = `jwtRefresh=; expires=${Date.now()}; path=/`;
        const date = new Date();

        // Set it expire in -1 days
        date.setTime(date.getTime() + (-1 * 24 * 60 * 60 * 1000));

        // Set it
        document.cookie = "jwtToken=; expires=" + date.toUTCString() + "; path=/";
        document.cookie = "jwtRefresh=; expires=" + date.toUTCString() + "; path=/";

        // Remove user from local storage and set current user to null
        localStorage.removeItem('user');
        this.userSubject.next(null);
        this.router.navigate(['/login']);
    }

    isLoggedIn() {
        // Check if token is expired
        const jwtToken = (document.cookie.split(';').find(x => x.includes('jwtToken')) || '=').split('=')[1]

        // Get token refresh
        const jwtTokenRefresh = (document.cookie.split(';').find(x => x.includes('jwtRefresh')) || '=').split('=')[1]

        //console.log(`token: ${jwtToken}, refresh: ${jwtTokenRefresh}`)

        if (!jwtToken && !jwtTokenRefresh) {
            this.stopRefreshTokenTimer();
            localStorage.removeItem('user');
            this.userSubject.next(null);
            this.router.navigate(['/login']);
            return false
        }

        return true;
    }

    refreshToken() {
        return this.http.post<any>(`${environment.apiUrl}/users/refresh-token`, {}, { withCredentials: true })
            .pipe(map((user) => {
                this.userSubject.next(user);
                this.startRefreshTokenTimer();
                return user;
            }));
    }

    register(user: User) {
        return this.http.post(`${environment.apiUrl}/users/register`, user);
    }

    getAll() {
        return this.http.get<User[]>(`${environment.apiUrl}/users`);
    }

    getById(id: string) {
        return this.http.get<User>(`${environment.apiUrl}/users/${id}`);
    }

    update(id, params) {
        return this.http.put(`${environment.apiUrl}/users/${id}`, params)
            .pipe(map(x => {
                // Update stored user if the logged in user updated their own record
                if (id == this.userValue.id) {
                    // Update local storage
                    const user = { ...this.userValue, ...params };
                    localStorage.setItem('user', JSON.stringify(user));

                    // Publish updated user to subscribers
                    this.userSubject.next(user);
                }
                return x;
            }));
    }

    // delete(id: string) {
    //     return this.http.delete(`${environment.apiUrl}/users/${id}`)
    //         .pipe(map(x => {
    //             // Auto logout if the logged in user deleted their own record
    //             if (id == this.userValue.id) {
    //                 this.logout();
    //             }
    //             return x;
    //         }));
    // }

    // Helper methods

    private refreshTokenTimeout;

    private startRefreshTokenTimer() {
        // Parse json object from base64 encoded jwt token
        const jwtToken = JSON.parse(atob(this.userValue.jwtToken.split('.')[1]));

        // Set a timeout to refresh the token a minute before it expires
        const expires = new Date(jwtToken.exp * 1000);
        const timeout = expires.getTime() - Date.now() - (60 * 1000);
        this.refreshTokenTimeout = setTimeout(() => this.refreshToken().subscribe(), timeout);
    }

    private stopRefreshTokenTimer() {
        clearTimeout(this.refreshTokenTimeout);
    }

    isPasswordValidated(password) {
        const jwtToken = (document.cookie.split(';').find(x => x.includes('jwtToken')) || '=').split('=')[1]

        const headers = {
            'Content-Type': 'application/json',
            'Authorization': jwtToken
        };

        const payload = {
            password: password
        }

        return this.http.post<any>(`${environment.apiUrl}/auth/validate`, payload, { headers });
    }

    changePassword(oldPassword, newPassword) {
        const jwtToken = (document.cookie.split(';').find(x => x.includes('jwtToken')) || '=').split('=')[1]

        const headers = {
            'Content-Type': 'application/json',
            'Authorization': jwtToken
        };

        const payload = {
            oldPassword,
            newPassword
        }

        return this.http.patch<any>(`${environment.apiUrl}/auth/change-password`, payload, { headers });
    }

    getMe() {
        const jwtToken = (document.cookie.split(';').find(x => x.includes('jwtToken')) || '=').split('=')[1]

        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                'Authorization': jwtToken
            })
        };

        return this.http.get<any>(`${environment.apiUrl}/users/me`, httpOptions)
            .pipe(map(r => {
                const response = r.data
                const user = {
                    id: response.Id_User,
                    employeeId: response.Employee_Id,
                    email: response.Email,
                    firstName: response.Name,
                    middleName: response.Middle_Name,
                    lastName: response.Last_Name,
                    photoUrl: response.Photo_Url,
                    lastLogin: response.Last_Login,
                    type: response.Type
                }
                localStorage.setItem(userKey, JSON.stringify(user))
                this.userSubject.next(user);
                return user;
            }));
    }
}