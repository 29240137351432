import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, CanDeactivate, Router } from '@angular/router';
import { Observable } from 'rxjs';

export interface PuedeDesactivar {
  permitirSalirDeRuta: () => Observable<boolean> | Promise<boolean> | boolean;
}

@Injectable({
  providedIn: 'root'
})
export class CanDeactivateGuard implements CanDeactivate<PuedeDesactivar> {
  constructor(
    private router: Router,
  ) {}

  canDeactivate(component: PuedeDesactivar,
      currentRoute: ActivatedRouteSnapshot,
      currentState: RouterStateSnapshot,
      nextState: RouterStateSnapshot,
  ) {
    
   
    /*console.log(component);
    console.log(currentRoute);
    console.log(currentState);
    console.log(nextState);
    console.log(this.router.routerState.snapshot.url)*/

    return component.permitirSalirDeRuta ? component.permitirSalirDeRuta() : true;
  }
}