import { NcsService } from './../../services/ncs.service';
import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AlertService, AuthenticationService, RequestsService, InspectionsService, EvaluationsService, UsersService } from '@app/core/services';
import { first } from 'rxjs/operators';

@Component({
    selector: 'modal-confirm-autofocus',
    templateUrl: 'modal-confirm.component.html',
    styleUrls: ['../../../app.component.scss']
})
export class ModalConfirmAutofocus implements OnInit {
    form: FormGroup;
    @Input() public name = '';

    forbiddenId = false;
    loading = false;
    submitted = false;
    res = false;
    isShowPasswordConfirm = false;
    constructor(private formBuilder: FormBuilder, public modal: NgbActiveModal,
                private authenticationService: AuthenticationService,
                private alertService: AlertService) { }
    ngOnInit() {
        this.form = this.formBuilder.group({
            password: ['', Validators.required],
        });
    }

    // Convenience getter for easy access to form fields
    get f() { return this.form.controls; }

    onSubmit() {
        this.submitted = true;

        // Reset alerts on submit
        this.alertService.clear();

        // Stop here if form is invalid
        if (this.form.invalid) {
            return;
        }
        if (this.form.value.password) {
            this.forbiddenId = false;
            this.loading = true;
            this.authenticationService.isPasswordValidated(this.form.value.password)
                .pipe(first())
                .subscribe(
                    data => {
                        this.alertService.clear();
                        if (data.validated) {
                            this.forbiddenId = false;
                            this.alertService.success('Contraseña valida', { autoClose: true });
                            this.modal.close(true);
                            this.modal.dismiss();
                        } else {
                            this.forbiddenId = true;
                        }
                        this.loading = false;
                    },
                    error => {
                        this.alertService.error(error);
                        this.loading = false;
                    });
        }
    }
  showPasswordApproveConfirm(): void {
    this.isShowPasswordConfirm = !this.isShowPasswordConfirm;
  }
}

@Component({
    selector: 'modal-confirm-updateLaboratory',
    templateUrl: 'modal-confirm-updateLaboratory.component.html',
    styleUrls: ['../../../app.component.scss']
})
export class ModalConfirmUpdateLaboratory implements OnInit {
    form: FormGroup;
    @Input() public name = '';
    @Input() public update = false;
    @Input() public delete = false;

    forbiddenId = false;
    loading = false;
    submitted = false;
    res = false;
    isShowPasswordUpdateLab = false;
    constructor(private formBuilder: FormBuilder, public modal: NgbActiveModal,
                private authenticationService: AuthenticationService,
                private alertService: AlertService) { }
    ngOnInit() {
        this.form = this.formBuilder.group({
            password: ['', Validators.required],
        });
    }

    // Convenience getter for easy access to form fields
    get f() { return this.form.controls; }

    onSubmit() {
        this.submitted = true;

        // Reset alerts on submit
        this.alertService.clear();

        // Stop here if form is invalid
        if (this.form.invalid) {
            return;
        }
        if (this.form.value.password) {
            this.forbiddenId = false;
            this.loading = true;
            this.authenticationService.isPasswordValidated(this.form.value.password)
                .pipe(first())
                .subscribe(
                    data => {
                        this.alertService.clear();
                        if (data.validated) {
                            this.forbiddenId = false;
                            this.alertService.success('Contraseña valida', { autoClose: true });
                            this.modal.close(true);
                            this.modal.dismiss();
                        } else {
                            this.forbiddenId = true;
                        }
                        this.loading = false;
                    },
                    error => {
                        this.alertService.error(error);
                        this.loading = false;
                    });
        }
    }
  showPasswordUpdateLab(): void {
    this.isShowPasswordUpdateLab = !this.isShowPasswordUpdateLab;
  }
}

@Component({
    selector: 'modal-sign',
    templateUrl: 'modal-sign.component.html',
    styleUrls: ['../../../app.component.scss']
})
export class ModalSign implements OnInit {
    form: FormGroup;
    @Input() public name = '';
    @Input() public recordId = '';
    @Input() public isNc = false;
    @Input() public inspectionId = '';

    forbiddenId = false;
    loading = false;
    submitted = false;
    res = false;
    isShowPasswordSign = false;
    constructor(private formBuilder: FormBuilder, public modal: NgbActiveModal,
                private authenticationService: AuthenticationService,
                private alertService: AlertService,
                private requestsService: RequestsService,
                private ncsService: NcsService
    ) { }
    ngOnInit() {
        this.form = this.formBuilder.group({
            password: ['', Validators.required],
        });
    }

    // Convenience getter for easy access to form fields
    get f() { return this.form.controls; }

    onSubmit() {
        this.submitted = true;

        // Reset alerts on submit
        this.alertService.clear();

        // Stop here if form is invalid
        if (this.form.invalid) {
            return;
        }
        if (this.form.value.password) {
            this.forbiddenId = false;
            this.loading = true;
            this.authenticationService.isPasswordValidated(this.form.value.password)
                .pipe(first())
                .subscribe(
                    data => {
                        this.alertService.clear();
                        if (data.validated && !this.isNc) {
                            this.alertService.success('Contraseña valida', { autoClose: true });
                            this.requestsService.signApplication(this.recordId)
                                .pipe(first())
                                .subscribe(
                                    data => {
                                        // console.log(data)
                                        this.forbiddenId = false;
                                        this.alertService.success('Solicitud firmada', { autoClose: true });
                                        this.modal.close(true);
                                        this.modal.dismiss();
                                    },
                                    error => {
                                        this.alertService.error(error);
                                        this.loading = false;
                                    }
                                );
                        } else if (data.validated && this.isNc) {
                            this.ncsService.acceptNcsReview(this.recordId, this.inspectionId).subscribe(data => {
                                this.alertService.success('Se ha enviado la respuesta al personal de Metrocert', { autoClose: true });
                                this.forbiddenId = false;
                                this.modal.close(true);
                                this.modal.dismiss();
                            }, error => {
                                this.alertService.error(error);
                                this.loading = false;
                            });
                        } else {
                            this.forbiddenId = true;
                        }
                        this.loading = false;
                    },
                    error => {
                        this.alertService.error(error);
                        this.loading = false;
                    });
        }
    }
  showPasswordSign(): void {
    this.isShowPasswordSign = !this.isShowPasswordSign;
  }
}

@Component({
    selector: 'modal-approve',
    templateUrl: 'modal-approve.component.html',
    styleUrls: ['../../../app.component.scss']
})
export class ModalApprove implements OnInit {
    form: FormGroup;
    @Input() public name = '';
    @Input() public recordId = '';

    forbiddenId = false;
    loading = false;
    submitted = false;
    res = false;
    isShowedApprove = false;
    constructor(private formBuilder: FormBuilder, public modal: NgbActiveModal,
                private authenticationService: AuthenticationService,
                private alertService: AlertService,
                private requestsService: RequestsService
    ) { }
    ngOnInit() {
        this.form = this.formBuilder.group({
            password: ['', Validators.required],
        });
    }

    // Convenience getter for easy access to form fields
    get f() { return this.form.controls; }

    onSubmit() {
        // console.log("SUBMITT")
        this.submitted = true;

        // Reset alerts on submit
        this.alertService.clear();

        // Stop here if form is invalid
        if (this.form.invalid) {
            return;
        }
        if (this.form.value.password) {
            this.forbiddenId = false;
            this.loading = true;
            this.authenticationService.isPasswordValidated(this.form.value.password)
                .pipe(first())
                .subscribe(
                    data => {
                        this.alertService.clear();
                        if (data.validated) {
                            this.alertService.success('Contraseña valida', { autoClose: true });
                            this.requestsService.approveApplication(this.recordId)
                                .pipe(first())
                                .subscribe(
                                    data => {
                                        this.forbiddenId = false;
                                        this.alertService.success('Solicitud enviada a revisiones', { autoClose: true });
                                        this.modal.close(true);
                                        this.modal.dismiss();
                                    },
                                    error => {
                                        this.alertService.error(error);
                                        this.loading = false;
                                    }
                                );
                        } else {
                            this.forbiddenId = true;
                        }
                        this.loading = false;
                    },
                    error => {
                        this.alertService.error(error);
                        this.loading = false;
                    });
        }
    }
  showPasswordApprove(): void {
    this.isShowedApprove = !this.isShowedApprove;
  }
}

@Component({
    selector: 'modal-complete',
    templateUrl: 'modal-complete.component.html',
    styleUrls: ['../../../app.component.scss']
})
export class ModalComplete implements OnInit {
    form: FormGroup;
    @Input() public name = '';
    @Input() public recordId = '';

    forbiddenId = false;
    loading = false;
    submitted = false;
    res = false;
    isShowedComplete = false;
    constructor(private formBuilder: FormBuilder, public modal: NgbActiveModal,
                private authenticationService: AuthenticationService,
                private alertService: AlertService,
                private requestsService: RequestsService
    ) { }
    ngOnInit() {
        this.form = this.formBuilder.group({
            password: ['', Validators.required],
        });
    }

    // Convenience getter for easy access to form fields
    get f() { return this.form.controls; }

    onSubmit() {
        // console.log("SUBMITT")
        this.submitted = true;

        // Reset alerts on submit
        this.alertService.clear();

        // Stop here if form is invalid
        if (this.form.invalid) {
            return;
        }
        if (this.form.value.password) {
            this.forbiddenId = false;
            this.loading = true;
            this.authenticationService.isPasswordValidated(this.form.value.password)
                .pipe(first())
                .subscribe(
                    data => {
                        this.alertService.clear();
                        if (data.validated) {
                            this.alertService.success('Contraseña valida', { autoClose: true });
                            this.requestsService.completeApplication(this.recordId)
                                .pipe(first())
                                .subscribe(
                                    data => {
                                        if (data.success) {
                                            this.forbiddenId = false;
                                            this.alertService.success('La solicitud a sido marcada como completa', { autoClose: true });
                                            this.modal.close(true);
                                            this.modal.dismiss();
                                        } else {
                                            this.alertService.error('Los formularios requeridos de la solicitud no han terminado de ser llenados');
                                            this.modal.close(true);
                                            this.modal.dismiss();
                                        }

                                    },
                                    error => {
                                        this.alertService.error(error);
                                        this.loading = false;
                                    }
                                );
                        } else {
                            this.forbiddenId = true;
                        }
                        this.loading = false;
                    },
                    error => {
                        this.alertService.error(error);
                        this.loading = false;
                    });
        }
    }

  showPasswordComplete(): void {
    this.isShowedComplete = !this.isShowedComplete;
  }
}

@Component({
    selector: 'modal-delete',
    templateUrl: 'modal-delete.component.html',
    styleUrls: ['../../../app.component.scss']
})
export class ModalDelete implements OnInit {
    form: FormGroup;
    @Input() public name = ''
    @Input() public recordId = ''

    forbiddenId = false
    loading = false
    submitted = false
    res = false
    isShowPasswordDelete = false

    constructor(
        private formBuilder: FormBuilder, public modal: NgbActiveModal,
        private authenticationService: AuthenticationService,
        private alertService: AlertService,
        private requestsService: RequestsService
    ) {}

    ngOnInit() {
        this.form = this.formBuilder.group({
            password: ['', Validators.required],
        })
    }

    // Convenience getter for easy access to form fields
    get f() { return this.form.controls; }

    onSubmit() {
        // console.log("SUBMITT")
        this.submitted = true

        // Reset alerts on submit
        this.alertService.clear()

        // Stop here if form is invalid
        if (this.form.invalid) {
            return
        }

        if (this.form.value.password) {
            this.forbiddenId = false
            this.loading = true
            this.authenticationService.isPasswordValidated(this.form.value.password)
                .pipe(first())
                .subscribe(
                    data => {
                        this.alertService.clear()
                        if (data.validated) {
                            this.alertService.success('Contraseña valida', { autoClose: true })
                            this.requestsService.deleteApplication(this.recordId)
                                .pipe(first())
                                .subscribe(
                                    data => {
                                        if (data.success) {
                                            this.forbiddenId = false
                                            this.alertService.success('La solicitud a sido eliminada correctamente', { autoClose: true })
                                            this.modal.close(true)
                                            this.modal.dismiss()
                                        } else {
                                            this.alertService.error('La solicitud no pudo ser eliminada');
                                            this.modal.close(true)
                                            this.modal.dismiss()
                                        }

                                    },
                                    error => {
                                        this.alertService.error(error)
                                        this.loading = false
                                    }
                                );
                        } else {
                            this.forbiddenId = true
                        }
                        this.loading = false
                    },
                    error => {
                        this.alertService.error(error)
                        this.loading = false
                    });
        }
    }

    showPasswordDelete(): void {
        this.isShowPasswordDelete = !this.isShowPasswordDelete
    }
}

@Component({
    selector: 'modal-approveInspection',
    templateUrl: 'modal-approveInspection.component.html',
    styleUrls: ['../../../app.component.scss']
})
export class ModalApproveInspection implements OnInit {
    form: FormGroup;
    @Input() public name = '';
    @Input() public inspectionId = '';
    @Input() public title = 'Aceptar';
    @Input() public sign = false;

    forbiddenId = false;
    loading = false;
    submitted = false;
    res = false;
    isApproveInspection = false;
    constructor(private formBuilder: FormBuilder, public modal: NgbActiveModal,
                private authenticationService: AuthenticationService,
                private alertService: AlertService,
                private requestsService: RequestsService,
                private inspectionsService: InspectionsService
    ) { }
    ngOnInit() {
        this.form = this.formBuilder.group({
            password: ['', Validators.required],
        });
    }

    // Convenience getter for easy access to form fields
    get f() { return this.form.controls; }

    onSubmit() {
        // console.log("SUBMITT")
        this.submitted = true;

        // Reset alerts on submit
        this.alertService.clear();

        // Stop here if form is invalid
        if (this.form.invalid) {
            return;
        }
        if (this.form.value.password) {
            this.forbiddenId = false;
            this.loading = true;
            this.authenticationService.isPasswordValidated(this.form.value.password)
                .pipe(first())
                .subscribe(
                    data => {
                        this.alertService.clear();
                        if (data.validated) {
                            this.alertService.success('Contraseña valida', { autoClose: true });
                            this.modal.close(true);
                            this.modal.dismiss();
                        } else {
                            this.forbiddenId = true;
                        }
                        this.loading = false;
                    },
                    error => {
                        this.alertService.error(error);
                        this.loading = false;
                    });
        }
    }

    showPasswordApproveInspection(){
      this.isApproveInspection = !this.isApproveInspection;
    }
}

@Component({
    selector: 'modal-denyInspection',
    templateUrl: 'modal-denyInspection.component.html',
    styleUrls: ['../../../app.component.scss']
})
export class ModalDenyInspection implements OnInit {
    form: FormGroup;
    @Input() public name = '';
    @Input() public inspectionId = '';

    forbiddenId = false;
    loading = false;
    submitted = false;
    res = false;
    isShowPasswordDenyInspection = false;
    constructor(private formBuilder: FormBuilder, public modal: NgbActiveModal,
                private authenticationService: AuthenticationService,
                private alertService: AlertService,
                private requestsService: RequestsService,
                private inspectionsService: InspectionsService
    ) { }
    ngOnInit() {
        this.form = this.formBuilder.group({
            password: ['', Validators.required],
            comment: ['', Validators.required]
        });
    }

    // Convenience getter for easy access to form fields
    get f() { return this.form.controls; }

    onSubmit() {
        // console.log("SUBMITT")
        this.submitted = true;

        // Reset alerts on submit
        this.alertService.clear();

        // Stop here if form is invalid
        if (this.form.invalid) {
            return;
        }
        if (this.form.value.password) {
            this.forbiddenId = false;
            this.loading = true;
            this.authenticationService.isPasswordValidated(this.form.value.password)
                .pipe(first())
                .subscribe(
                    data => {
                        this.alertService.clear();
                        if (data.validated) {
                            this.alertService.success('Contraseña valida', { autoClose: true });
                            this.inspectionsService.denyInspection(this.inspectionId, this.form.value.comment)
                                .pipe(first())
                                .subscribe(
                                    data => {
                                        this.forbiddenId = false;
                                        this.alertService.success('Inspeccion denegada', { autoClose: true });
                                        this.modal.close(true);
                                        this.modal.dismiss();
                                    },
                                    error => {
                                        this.alertService.error(error);
                                        this.loading = false;
                                    }
                                );
                        } else {
                            this.forbiddenId = true;
                        }
                        this.loading = false;
                    },
                    error => {
                        this.alertService.error(error);
                        this.loading = false;
                    });
        }
    }
  showPasswordDenyInspection(): void {
    this.isShowPasswordDenyInspection = !this.isShowPasswordDenyInspection;
  }
}

@Component({
    selector: 'modal-denyComments',
    templateUrl: 'modal-denyComments.component.html',
    styleUrls: ['../../../app.component.scss']
})
export class ModalDenyComments implements OnInit {
    @Input() public comments = '';

    constructor(
        public modal: NgbActiveModal,
    ) { }

    ngOnInit() { }
}

@Component({
    selector: 'modal-uploadOfflineData',
    templateUrl: 'modal-uploadOfflineData.component.html',
    styleUrls: ['../../../app.component.scss']
})
export class ModalUploadOfflineData implements OnInit {

    forbiddenId = false;
    loading = false;
    submitted = false;

    constructor(public modal: NgbActiveModal,
                private alertService: AlertService,
    ) { }
    ngOnInit() {
    }

    onSubmit() {
        // console.log("SUBMITT")
        this.submitted = true;

        // Reset alerts on submit
        this.alertService.clear();

        this.modal.close(true);
        this.modal.dismiss();
    }
}

@Component({
    selector: 'modal-approveEvaluation',
    templateUrl: 'modal-approveEvaluation.component.html',
    styleUrls: ['../../../app.component.scss']
})
export class ModalApproveEvaluation implements OnInit {
    form: FormGroup;
    @Input() public name = '';
    @Input() public inspectionId = '';

    forbiddenId = false;
    loading = false;
    submitted = false;
    res = false;
    isShowedApproveEvaluation = false;
    constructor(private formBuilder: FormBuilder, public modal: NgbActiveModal,
                private authenticationService: AuthenticationService,
                private alertService: AlertService
    ) { }
    ngOnInit() {
        this.form = this.formBuilder.group({
            password: ['', Validators.required],
        });
    }

    // Convenience getter for easy access to form fields
    get f() { return this.form.controls; }

    onSubmit() {
        // console.log("SUBMITT")
        this.submitted = true;

        // Reset alerts on submit
        this.alertService.clear();

        // Stop here if form is invalid
        if (this.form.invalid) {
            return;
        }
        if (this.form.value.password) {
            this.forbiddenId = false;
            this.loading = true;
            this.authenticationService.isPasswordValidated(this.form.value.password)
                .pipe(first())
                .subscribe(
                    data => {
                        this.alertService.clear();
                        if (data.validated) {
                            this.alertService.success('Contraseña valida', { autoClose: true });
                            this.modal.close(true);
                            this.modal.dismiss();
                        } else {
                            this.forbiddenId = true;
                        }
                        this.loading = false;
                    },
                    error => {
                        this.alertService.error(error);
                        this.loading = false;
                    });
        }
    }
  showPasswordApproveEvaluation(): void {
    this.isShowedApproveEvaluation = !this.isShowedApproveEvaluation;
  }
}

@Component({
    selector: 'modal-denyEvaluation',
    templateUrl: 'modal-denyEvaluation.component.html',
    styleUrls: ['../../../app.component.scss']
})
export class ModalDenyEvaluation implements OnInit {
    form: FormGroup;
    @Input() public name = '';
    @Input() public evaluationId = '';

    forbiddenId = false;
    loading = false;
    submitted = false;
    res = false;
    isShowPasswordDenyEvaluation = false;
    constructor(private formBuilder: FormBuilder, public modal: NgbActiveModal,
                private authenticationService: AuthenticationService,
                private alertService: AlertService,
                private requestsService: RequestsService,
                private inspectionsService: InspectionsService,
                private evaluationsService: EvaluationsService
    ) { }
    ngOnInit() {
        this.form = this.formBuilder.group({
            password: ['', Validators.required],
        });
    }

    // Convenience getter for easy access to form fields
    get f() { return this.form.controls; }

    onSubmit() {
        // console.log("SUBMITT")
        this.submitted = true;

        // Reset alerts on submit
        this.alertService.clear();

        // Stop here if form is invalid
        if (this.form.invalid) {
            return;
        }
        if (this.form.value.password) {
            this.forbiddenId = false;
            this.loading = true;
            this.authenticationService.isPasswordValidated(this.form.value.password)
                .pipe(first())
                .subscribe(
                    data => {
                        this.alertService.clear();
                        if (data.validated) {
                            this.alertService.success('Contraseña valida', { autoClose: true });
                            this.evaluationsService.reprogramInspection(this.evaluationId)
                                .pipe(first())
                                .subscribe(
                                    data => {
                                        this.forbiddenId = false;
                                        this.alertService.success('Dictaminación denegada', { autoClose: true });
                                        this.modal.close(true);
                                        this.modal.dismiss();
                                    },
                                    error => {
                                        this.alertService.error(error);
                                        this.loading = false;
                                    }
                                );
                        } else {
                            this.forbiddenId = true;
                        }
                        this.loading = false;
                    },
                    error => {
                        this.alertService.error(error);
                        this.loading = false;
                    });
        }
    }
  showPasswordDenyEvaluation(): void {
    this.isShowPasswordDenyEvaluation = !this.isShowPasswordDenyEvaluation;
  }
}

@Component({
    selector: 'modal-approveSubstances',
    templateUrl: 'modal-approveSubstances.component.html',
    styleUrls: ['../../../app.component.scss']
})
export class ModalApproveSubstances implements OnInit {
    form: FormGroup;
    @Input() public name = '';
    @Input() public inspectionId = '';

    forbiddenId = false;
    loading = false;
    submitted = false;
    res = false;
    isApproveSustances = false;
    constructor(private formBuilder: FormBuilder, public modal: NgbActiveModal,
                private authenticationService: AuthenticationService,
                private alertService: AlertService
    ) { }
    ngOnInit() {
        this.form = this.formBuilder.group({
            password: ['', Validators.required],
        });
    }

    // Convenience getter for easy access to form fields
    get f() { return this.form.controls; }

    onSubmit() {
        // console.log("SUBMITT")
        this.submitted = true;

        // Reset alerts on submit
        this.alertService.clear();

        // Stop here if form is invalid
        if (this.form.invalid) {
            return;
        }
        if (this.form.value.password) {
            this.forbiddenId = false;
            this.loading = true;
            this.authenticationService.isPasswordValidated(this.form.value.password)
                .pipe(first())
                .subscribe(
                    data => {
                        this.alertService.clear();
                        if (data.validated) {
                            this.alertService.success('Contraseña valida', { autoClose: true });
                            this.modal.close(true);
                            this.modal.dismiss();
                        } else {
                            this.forbiddenId = true;
                        }
                        this.loading = false;
                    },
                    error => {
                        this.alertService.error(error);
                        this.loading = false;
                    });
        }
    }
  showPasswordApproveSustances(): void {
    this.isApproveSustances = !this.isApproveSustances;
  }
}

@Component({
    selector: 'modal-confirmCertificate',
    templateUrl: 'modal-confirmGenerateCertificate.component.html',
    styleUrls: ['../../../app.component.scss']
})
export class ModalConfirmGenerateCertificate implements OnInit {
    form: FormGroup;
    @Input() public code = '';
    @Input() public recordId = '';

    forbiddenId = false;
    loading = false;
    submitted = false;
    res = false;
    isShowPasswordGenerateCertificate = false;
    constructor(private formBuilder: FormBuilder, public modal: NgbActiveModal,
                private authenticationService: AuthenticationService,
                private alertService: AlertService) { }
    ngOnInit() {
        this.form = this.formBuilder.group({
            password: ['', Validators.required],
        });
    }

    // Convenience getter for easy access to form fields
    get f() { return this.form.controls; }

    onSubmit() {
        this.submitted = true;

        // Reset alerts on submit
        this.alertService.clear();

        // Stop here if form is invalid
        if (this.form.invalid) {
            return;
        }
        if (this.form.value.password) {
            this.forbiddenId = false;
            this.loading = true;
            this.authenticationService.isPasswordValidated(this.form.value.password)
                .pipe(first())
                .subscribe(
                    data => {
                        this.alertService.clear();
                        if (data.validated) {
                            this.forbiddenId = false;
                            this.alertService.success('Contraseña valida', { autoClose: true });
                            this.modal.close(true);
                            this.modal.dismiss();
                        } else {
                            this.forbiddenId = true;
                        }
                        this.loading = false;
                    },
                    error => {
                        this.alertService.error(error);
                        this.loading = false;
                    });
        }
    }
  showPasswordGenerateCertificate(): void {
    this.isShowPasswordGenerateCertificate = !this.isShowPasswordGenerateCertificate;
  }
}

@Component({
    selector: 'modal-userActions',
    templateUrl: 'modal-userActions.component.html',
    styleUrls: ['../../../app.component.scss']
})
export class ModalUserActions implements OnInit {
    form: FormGroup;
    @Input() public name = '';
    @Input() public delete = false;
    @Input() public reactivate = false;

    forbiddenId = false;
    loading = false;
    submitted = false;
    res = false;
    isShowPasswordUserActions = false;
    constructor(
        private formBuilder: FormBuilder,
        public modal: NgbActiveModal,
        private authenticationService: AuthenticationService,
        private alertService: AlertService,
        private requestsService: RequestsService
    ) { }
    ngOnInit() {
        this.form = this.formBuilder.group({
            password: ['', Validators.required],
        });
    }

    // Convenience getter for easy access to form fields
    get f() { return this.form.controls; }

    onSubmit() {
        // console.log("SUBMITT")
        this.submitted = true;

        // Reset alerts on submit
        this.alertService.clear();

        // Stop here if form is invalid
        if (this.form.invalid) {
            return;
        }
        if (this.form.value.password) {
            this.forbiddenId = false;
            this.loading = true;
            this.authenticationService.isPasswordValidated(this.form.value.password)
                .pipe(first())
                .subscribe(
                    data => {
                        this.alertService.clear();
                        if (data.validated) {
                            this.alertService.success('Contraseña valida', { autoClose: true });
                            this.modal.close(true);
                            this.modal.dismiss();
                        } else {
                            this.forbiddenId = true;
                        }
                        this.loading = false;
                    },
                    error => {
                        this.alertService.error(error);
                        this.loading = false;
                    }
                );
        }
    }
  showPasswordUserActions(): void {
    this.isShowPasswordUserActions = !this.isShowPasswordUserActions;
  }
}

@Component({
    selector: 'modal-restorePassword',
    templateUrl: 'modal-restorePassword.component.html',
    styleUrls: ['../../../app.component.scss']
})
export class ModalRestorePassword implements OnInit {
    form: FormGroup;
    @Input() public name = '';
    @Input() public email = '';

    forbiddenId = false;
    loading = false;
    submitted = false;
    res = false;
    constructor(
        private formBuilder: FormBuilder,
        public modal: NgbActiveModal,
        private authenticationService: AuthenticationService,
        private alertService: AlertService,
        private usersService: UsersService,
    ) { }
    ngOnInit() {
        this.form = this.formBuilder.group({
            email: ['', Validators.required],
        });

        this.f.email.setValue(this.email);
    }

    // Convenience getter for easy access to form fields
    get f() { return this.form.controls; }

    onSubmit() {
        // console.log("SUBMITT")
        this.submitted = true;

        // Reset alerts on submit
        this.alertService.clear();

        // Stop here if form is invalid
        if (this.form.invalid) {
            return;
        }
        if (this.form.value.email) {
            this.forbiddenId = false;
            this.loading = true;
            this.alertService.clear();
            this.usersService.resetPassword(this.f.email.value)
                .pipe(first())
                .subscribe(
                    data => {
                        // console.log(data)
                        this.alertService.success('Correo para restablecer contraseña enviado', { autoClose: true });
                        this.modal.close(true);
                        this.modal.dismiss();
                    },
                    error => {
                        this.alertService.error(error);
                        this.loading = false;
                        this.forbiddenId = true;
                    });
        }
    }
}

@Component({
    selector: 'modal-informGeneratingReport',
    templateUrl: 'modal-informGeneratingReport.component.html',
    styleUrls: ['../../../app.component.scss']
})
export class ModalInformGeneratingReport implements OnInit {
    @Input() public email = ''

    constructor(
        public modal: NgbActiveModal,
    ) { }

    ngOnInit() {
    }
}

@Component({
    selector: 'modal-approve-inspection-not-announced',
    templateUrl: 'modal-approve-inspection-not-announced.component.html',
    styleUrls: ['../../../app.component.scss']
})
export class ModalApproveInspectionNotAnnounced implements OnInit {
    form: FormGroup;
    @Input() public name = ''
    @Input() public code = ''

    forbiddenId = false
    loading = false
    submitted = false
    res = false
    isShowPasswordDelete = false

    constructor(
        private formBuilder: FormBuilder, public modal: NgbActiveModal,
        private authenticationService: AuthenticationService,
        private alertService: AlertService
    ) {}

    ngOnInit() {
        this.form = this.formBuilder.group({
            password: ['', Validators.required],
        })
    }

    // Convenience getter for easy access to form fields
    get f() { return this.form.controls; }

    onSubmit() {
        // console.log("SUBMITT")
        this.submitted = true

        // Reset alerts on submit
        this.alertService.clear()

        // Stop here if form is invalid
        if (this.form.invalid) {
            return
        }

        if (this.form.value.password) {
            this.forbiddenId = false
            this.loading = true
            this.authenticationService.isPasswordValidated(this.form.value.password)
                .pipe(first())
                .subscribe(
                    data => {
                        this.alertService.clear()
                        if (data.validated) {
                            this.alertService.success('Contraseña valida', { autoClose: true })

                            this.forbiddenId = false

                            this.modal.close(true)
                            this.modal.dismiss()
                        } else {
                            this.forbiddenId = true
                        }
                        this.loading = false
                    },
                    error => {
                        this.alertService.error(error)
                        this.loading = false
                    });
        }
    }

    showPasswordDelete(): void {
        this.isShowPasswordDelete = !this.isShowPasswordDelete
    }
}

@Component({
    selector: 'modal-approve-evaluation-not-announced',
    templateUrl: 'modal-approve-evaluation-not-announced.component.html',
    styleUrls: ['../../../app.component.scss']
})
export class ModalApproveEvaluationNotAnnounced implements OnInit {
    form: FormGroup;
    @Input() public name = ''
    @Input() public code = ''

    forbiddenId = false
    loading = false
    submitted = false
    res = false
    isShowedApproveEvaluation = false

    constructor(
        private formBuilder: FormBuilder, public modal: NgbActiveModal,
        private authenticationService: AuthenticationService,
        private alertService: AlertService
    ) {}

    ngOnInit() {
        this.form = this.formBuilder.group({
            password: ['', Validators.required],
        })
    }

    // Convenience getter for easy access to form fields
    get f() { return this.form.controls; }

    onSubmit() {
        // console.log("SUBMITT")
        this.submitted = true

        // Reset alerts on submit
        this.alertService.clear()

        // Stop here if form is invalid
        if (this.form.invalid) {
            return
        }

        if (this.form.value.password) {
            this.forbiddenId = false
            this.loading = true
            this.authenticationService.isPasswordValidated(this.form.value.password)
                .pipe(first())
                .subscribe(
                    data => {
                        this.alertService.clear()
                        if (data.validated) {
                            this.alertService.success('Contraseña valida', { autoClose: true })

                            this.forbiddenId = false

                            this.modal.close(true)
                            this.modal.dismiss()
                        } else {
                            this.forbiddenId = true
                        }
                        this.loading = false
                    },
                    error => {
                        this.alertService.error(error)
                        this.loading = false
                    });
        }
    }

    showPasswordApproveEvaluation(): void {
        this.isShowedApproveEvaluation = !this.isShowedApproveEvaluation
    }
}

@Component({
    selector: 'modal-confirm-cancel-customer',
    templateUrl: 'modal-confirm-cancel-customer.component.html',
    styleUrls: ['../../../app.component.scss']
})
export class ModalConfirmCancelCustomer implements OnInit {
    form: FormGroup;
    @Input() public code = '';

    forbiddenId = false;
    loading = false;
    submitted = false;

    isShowPasswordCancelCustromer = false;

    constructor(private formBuilder: FormBuilder, public modal: NgbActiveModal,
                private authenticationService: AuthenticationService,
                private alertService: AlertService) { }
    ngOnInit() {
        this.form = this.formBuilder.group({
            password: ['', Validators.required],
        });
    }

    // Convenience getter for easy access to form fields
    get f() { return this.form.controls; }

    onSubmit() {
        this.submitted = true;

        // Reset alerts on submit
        this.alertService.clear();

        // Stop here if form is invalid
        if (this.form.invalid) {
            return;
        }
        if (this.form.value.password) {
            this.forbiddenId = false;
            this.loading = true;
            this.authenticationService.isPasswordValidated(this.form.value.password)
                .pipe(first())
                .subscribe(
                    data => {
                        this.alertService.clear();
                        if (data.validated) {
                            this.forbiddenId = false;
                            this.alertService.success('Contraseña valida', { autoClose: true });
                            this.modal.close(true);
                            this.modal.dismiss();
                        } else {
                            this.forbiddenId = true;
                        }
                        this.loading = false;
                    },
                    error => {
                        this.alertService.error(error);
                        this.loading = false;
                    });
        }
    }

    showPasswordCancelCustomer(): void {
        this.isShowPasswordCancelCustromer = !this.isShowPasswordCancelCustromer;
    }
}


@Component({
    selector: 'modal-cancel-record',
    templateUrl: 'modal-cancel-record.component.html',
    styleUrls: ['../../../app.component.scss']
})
export class ModalCancelRecord implements OnInit {
    form: FormGroup;
    @Input() public name = ''
    @Input() public code = ''

    forbiddenId = false
    loading = false
    submitted = false
    res = false
    isShowedApproveCancellation = false

    constructor(
        private formBuilder: FormBuilder, public modal: NgbActiveModal,
        private authenticationService: AuthenticationService,
        private alertService: AlertService
    ) {}

    ngOnInit() {
        this.form = this.formBuilder.group({
            password: ['', Validators.required],
        })
    }

    // Convenience getter for easy access to form fields
    get f() { return this.form.controls; }

    onSubmit() {
        // console.log("SUBMITT")
        this.submitted = true

        // Reset alerts on submit
        this.alertService.clear()

        // Stop here if form is invalid
        if (this.form.invalid) {
            return
        }

        if (this.form.value.password) {
            this.forbiddenId = false
            this.loading = true
            this.authenticationService.isPasswordValidated(this.form.value.password)
                .pipe(first())
                .subscribe(
                    data => {
                        this.alertService.clear()
                        if (data.validated) {
                            this.alertService.success('Contraseña valida', { autoClose: true })

                            this.forbiddenId = false

                            this.modal.close(true)
                            this.modal.dismiss()
                        } else {
                            this.forbiddenId = true
                        }
                        this.loading = false
                    },
                    error => {
                        this.alertService.error(error)
                        this.loading = false
                    });
        }
    }

    showPasswordApproveCancellation(): void {
        this.isShowedApproveCancellation = !this.isShowedApproveCancellation
    }
}