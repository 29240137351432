import { AlignmentType, Document, Paragraph, TextRun, Media, Header, Footer, Table, TableRow, TableCell, WidthType, PageNumber, ShadingType, PageBreak, BorderStyle } from "docx";
import { logo } from "../logo";
import { DatePipe } from '@angular/common';

//Moment import
const moment = require('moment')
moment.locale('es-mx')

export class DocumentCreatorMTO_FINS_03_13_v3 {
    // INIT MTO_FINS_03_1_13
    public create_MTO_FINS_03_13(data, typeInspection = ''): Document {
        const document = new Document();
        const image = Media.addImage(document, logo, 50, 50);
        var datePipe = new DatePipe('es-MX');

        const listaTrazabilidad = this.getValueList(data.submission, "lista-trazabilidad")
        listaTrazabilidad.forEach(element => {
            document.addSection({
                headers: {
                    default: new Header({
                        children: [
                            new Paragraph({ alignment: AlignmentType.CENTER, children: [image], }),
                            new Paragraph({
                                alignment: AlignmentType.CENTER, children: [
                                    new TextRun({
                                        children: [`MTO-FINS-03-13 | R 01 | 10-Abril-2023 | Pág. `, PageNumber.CURRENT],
                                        font: 'Book Antiqua',
                                        size: 20,
                                        bold: true
                                    }),
                                    new TextRun({
                                        font: 'Book Antiqua',
                                        size: 20,
                                        bold: true,
                                        children: [' de ', PageNumber.TOTAL_PAGES]
                                    }),
                                ], spacing: { before: 200, after: 100 }
                            })
                        ]
                    })
                },
                footers: {
                    default: new Footer({
                        children: [
                            new Paragraph({ alignment: AlignmentType.CENTER }),
                            this.createFooter()
                        ]
                    }),
                },
                children: [
                    new Paragraph({
                        alignment: AlignmentType.CENTER,
                        children: [
                            new TextRun({
                                text: 'TRAZABILIDAD',
                                font: 'Calibri (Body)',
                                size: 22,
                                bold: true
                            })
                        ],
                        spacing: { after: 300, before: 200 }
                    }),
                    this.createTableUDP(data, datePipe, typeInspection),
                    new Paragraph({
                        spacing: {
                            after: 0,
                            before: 0
                        }
                    }),
                    this.createTableRegistros(element),
                ],
                margins: {
                    top: 350,
                    bottom: 500,
                    left: 1000,
                    right: 1000
                }
            });
        })

        return document;
    }

    public createFooter(): Table {
        const table = new Table({
            rows: [
                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: 'Elaboró:',
                                            font: 'Calibri (Cuerpo)',
                                            size: 22
                                        })
                                    ]
                                })
                            ],
                            width: {
                                size: 33,
                                type: WidthType.PERCENTAGE
                            }
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: 'Revisó:',
                                            font: 'Calibri (Cuerpo)',
                                            size: 22
                                        })
                                    ]
                                })
                            ],
                            width: {
                                size: 33,
                                type: WidthType.PERCENTAGE
                            }
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: 'Autorizó:',
                                            font: 'Calibri (Cuerpo)',
                                            size: 22
                                        })
                                    ]
                                }),
                            ],
                            width: {
                                size: 33,
                                type: WidthType.PERCENTAGE
                            }
                        })
                    ]
                }),
                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: 'Director de certificación',
                                            font: 'Calibri (Cuerpo)',
                                            size: 16,
                                            bold: true
                                        })
                                    ]
                                }),

                            ],
                            width: {
                                size: 33,
                                type: WidthType.PERCENTAGE
                            },
                            shading: {
                                fill: '#C0C0C0'
                            }
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: 'Responsable de calidad',
                                            font: 'Calibri (Cuerpo)',
                                            size: 16,
                                            bold: true
                                        })
                                    ]
                                }),

                            ],
                            width: {
                                size: 33,
                                type: WidthType.PERCENTAGE
                            },
                            shading: {
                                fill: '#C0C0C0'
                            }
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: 'Director general',
                                            font: 'Calibri (Cuerpo)',
                                            size: 16,
                                            bold: true
                                        })
                                    ]
                                }),
                            ],
                            width: {
                                size: 33,
                                type: WidthType.PERCENTAGE
                            },
                            shading: {
                                fill: '#C0C0C0'
                            }
                        })
                    ]
                })
            ],
            margins: {
                left: 70,
                right: 70
            },
            //9638
            columnWidths: [3213, 3213, 3212],
            alignment: AlignmentType.CENTER
        })
        return table
    }

    public createTableUDP(data, datePipe, tipo_inspeccion): Table {
        const table = new Table({
            rows: [
                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: 'Código de operación:',
                                            font: 'Arial',
                                            size: 22,
                                            bold: true
                                        })
                                    ],
                                    spacing: { before: 100, after: 100 }
                                })
                            ],
                            columnSpan: 1
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: data.customer.Id_Comercial,
                                            font: 'Arial',
                                            size: 22
                                        })
                                    ],
                                    spacing: { before: 100, after: 100 }
                                })
                            ],
                            columnSpan: 3
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: 'Fecha:',
                                            font: 'Arial',
                                            size: 22,
                                            bold: true
                                        })
                                    ],
                                    spacing: { before: 100, after: 100 }
                                })
                            ],
                            columnSpan: 1
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: `${datePipe.transform(data.inspectionDate, 'd-MMMM-y')}`,
                                            font: 'Arial',
                                            size: 22
                                        })
                                    ],
                                    spacing: { before: 100, after: 100 }
                                })
                            ],
                            columnSpan: 2
                        }),
                    ]
                }),
                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: 'Operador:',
                                            font: 'Arial',
                                            size: 22,
                                            bold: true
                                        })
                                    ],
                                    spacing: { before: 100, after: 100 }
                                })
                            ],
                            columnSpan: 1
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: data.customer.Company_Name,
                                            font: 'Arial',
                                            size: 22
                                        })
                                    ],
                                    spacing: { before: 100, after: 100 }
                                })
                            ],
                            columnSpan: 6
                        })
                    ]
                }),
                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: 'Tipo de Operador:',
                                            font: 'Arial',
                                            size: 22,
                                            bold: true
                                        })
                                    ],
                                    spacing: { before: 100, after: 100 }
                                })
                            ],
                            columnSpan: 1
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: `${this.getValue(data.submission, 'tipo_operador') === 'Productor' ? '■' : '❏'} Productor`,
                                            font: 'Calibri (Body)',
                                            size: 20
                                        })
                                    ]
                                }),
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: `${this.getValue(data.submission, 'tipo_operador') === 'Procesador/Comercializador' ? '■' : '❏'} Procesador/Comercializador`,
                                            font: 'Calibri (Body)',
                                            size: 20
                                        })
                                    ]
                                })
                            ],
                            columnSpan: 3
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: `Tipo de inspección:`,
                                            font: 'Arial',
                                            size: 22,
                                            bold: true
                                        })
                                    ],
                                    spacing: { before: 100, after: 100 }
                                })
                            ],
                            columnSpan: 1
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: `${tipo_inspeccion === 'Renovación' ? '■' : '❏'} Renovación`,
                                            font: 'Calibri (Body)',
                                            size: 20
                                        })
                                    ]
                                }),
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: `${tipo_inspeccion === 'Primer Inspección' ? '■' : '❏'} Primer Inspección`,
                                            font: 'Calibri (Body)',
                                            size: 20
                                        })
                                    ]
                                }),
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: `${tipo_inspeccion === 'Adicional' ? '■' : '❏'} Adicional`,
                                            font: 'Calibri (Body)',
                                            size: 20
                                        })
                                    ]
                                }),
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: `${tipo_inspeccion === 'Anunciada' ? '■' : '❏'} Anunciada`,
                                            font: 'Calibri (Body)',
                                            size: 20
                                        })
                                    ]
                                }),
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: `${tipo_inspeccion === 'No Anunciada' ? '■' : '❏'} No Anunciada`,
                                            font: 'Calibri (Body)',
                                            size: 20
                                        })
                                    ]
                                })
                            ],
                            columnSpan: 2
                        })
                    ]
                }),
                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: 'Inspector:',
                                            font: 'Arial',
                                            size: 22,
                                            bold: true
                                        })
                                    ],
                                    spacing: { before: 100, after: 100 }
                                })
                            ],
                            columnSpan: 1
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: data.inspector,
                                            font: 'Arial',
                                            size: 22
                                        })
                                    ],
                                    spacing: { before: 100, after: 100 }
                                })
                            ],
                            columnSpan: 6
                        })
                    ]
                }),
            ],
            margins: {
                left: 70,
                right: 70
            },
            width: {
                size: 100,
                type: WidthType.PERCENTAGE,
            },
            columnWidths: [2192, 1802, 1792, 1972, 1892, 1802, 1792]
        })

        return table
    }

    public createTableRegistros(data) {
        const table = new Table({
            rows: [
                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: '1',
                                            font: 'Arial',
                                            size: 20
                                        })
                                    ],
                                    spacing: {
                                        after: 100,
                                        before: 100
                                    }
                                })
                            ],
                            columnSpan: 1
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: 'Producto/Ingrediente seleccionado',
                                            font: 'Arial',
                                            size: 20
                                        })
                                    ],
                                    spacing: {
                                        after: 100,
                                        before: 100
                                    }
                                })
                            ],
                            columnSpan: 2
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: data.registros_1_pregunta,
                                            font: 'Arial',
                                            size: 20
                                        })
                                    ],
                                    spacing: {
                                        after: 100,
                                        before: 100
                                    }
                                })
                            ],
                            columnSpan: 3
                        })
                    ]
                }),

                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: '2',
                                            font: 'Arial',
                                            size: 20
                                        })
                                    ],
                                    spacing: {
                                        after: 100,
                                        before: 100
                                    }
                                })
                            ],
                            columnSpan: 1
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: 'Factura / Lote seleccionado',
                                            font: 'Arial',
                                            size: 20
                                        })
                                    ],
                                    spacing: {
                                        after: 100,
                                        before: 100
                                    }
                                })
                            ],
                            columnSpan: 2
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: data.registros_2_pregunta,
                                            font: 'Arial',
                                            size: 20
                                        })
                                    ],
                                    spacing: {
                                        after: 100,
                                        before: 100
                                    }
                                })
                            ],
                            columnSpan: 3
                        })
                    ]
                }),
            ],
            margins: {
                left: 70,
                right: 70
            },
            width: {
                size: 100,
                type: WidthType.PERCENTAGE
            },
            columnWidths: [500, 3123, 2323, 2067, 1200, 1200]
        })

        const question_3 = new TableRow({
            children: [
                new TableCell({
                    children: [
                        new Paragraph({
                            alignment: AlignmentType.CENTER,
                            children: [
                                new TextRun({
                                    text: '3',
                                    font: 'Arial',
                                    size: 20
                                })
                            ],
                            spacing: {
                                after: 100,
                                before: 100
                            }
                        })
                    ],
                    columnSpan: 1
                }),
                new TableCell({
                    children: [
                        new Paragraph({
                            alignment: AlignmentType.CENTER,
                            children: [
                                new TextRun({
                                    text: 'Documento revisado',
                                    font: 'Arial',
                                    size: 20
                                })
                            ],
                            spacing: {
                                after: 100,
                                before: 100
                            }
                        })
                    ],
                    shading: {
                        fill: '#C0C0C0',
                        val: ShadingType.PERCENT_80
                    },
                    columnSpan: 1
                }),
                new TableCell({
                    children: [
                        new Paragraph({
                            alignment: AlignmentType.CENTER,
                            children: [
                                new TextRun({
                                    text: 'Datos de entrada para trazabilidad',
                                    font: 'Arial',
                                    size: 20
                                })
                            ],
                            spacing: {
                                after: 100,
                                before: 100
                            }
                        })
                    ],
                    shading: {
                        fill: '#C0C0C0',
                        val: ShadingType.PERCENT_80
                    },
                    columnSpan: 1
                }),
                new TableCell({
                    children: [
                        new Paragraph({
                            alignment: AlignmentType.CENTER,
                            children: [
                                new TextRun({
                                    text: 'Datos de salida para trazabilidad',
                                    font: 'Arial',
                                    size: 20
                                })
                            ],
                            spacing: {
                                after: 100,
                                before: 100
                            }
                        })
                    ],
                    shading: {
                        fill: '#C0C0C0',
                        val: ShadingType.PERCENT_80
                    },
                    columnSpan: 1
                }),
                new TableCell({
                    children: [
                        new Paragraph({
                            alignment: AlignmentType.CENTER,
                            children: [
                                new TextRun({
                                    text: 'Fecha de operación',
                                    font: 'Arial',
                                    size: 20
                                })
                            ],
                            spacing: {
                                after: 100,
                                before: 100
                            }
                        })
                    ],
                    shading: {
                        fill: '#C0C0C0',
                        val: ShadingType.PERCENT_80
                    },
                    columnSpan: 1
                }),
                new TableCell({
                    children: [
                        new Paragraph({
                            alignment: AlignmentType.CENTER,
                            children: [
                                new TextRun({
                                    text: 'Comentario',
                                    font: 'Arial',
                                    size: 20
                                })
                            ],
                            spacing: {
                                after: 100,
                                before: 100
                            }
                        })
                    ],
                    shading: {
                        fill: '#C0C0C0',
                        val: ShadingType.PERCENT_80
                    },
                    columnSpan: 1
                }),
            ]
        })
        table.addChildElement(question_3)

        const documents = data.registros_3_pregunta
        documents.forEach(document => {
            const documentRow = new TableRow({
                children: [
                    new TableCell({
                        children: [
                            new Paragraph({
                                alignment: AlignmentType.CENTER,
                                children: [
                                    new TextRun({
                                        text: '',
                                        font: 'Arial',
                                        size: 20
                                    })
                                ],
                                spacing: {
                                    after: 100,
                                    before: 100
                                }
                            })
                        ],
                        columnSpan: 1
                    }),
                    new TableCell({
                        children: [
                            new Paragraph({
                                alignment: AlignmentType.LEFT,
                                children: [
                                    new TextRun({
                                        text: document.documento_revisado,
                                        font: 'Arial',
                                        size: 20
                                    })
                                ],
                                spacing: {
                                    after: 100,
                                    before: 100
                                }
                            })
                        ],
                        columnSpan: 1
                    }),
                    new TableCell({
                        children: [
                            new Paragraph({
                                alignment: AlignmentType.LEFT,
                                children: [
                                    new TextRun({
                                        text: document.datos_entrada_trazabilidad,
                                        font: 'Arial',
                                        size: 20
                                    })
                                ],
                                spacing: {
                                    after: 100,
                                    before: 100
                                }
                            })
                        ],
                        columnSpan: 1
                    }),
                    new TableCell({
                        children: [
                            new Paragraph({
                                alignment: AlignmentType.LEFT,
                                children: [
                                    new TextRun({
                                        text: document.datos_salida_trazabilidad,
                                        font: 'Arial',
                                        size: 20
                                    })
                                ],
                                spacing: {
                                    after: 100,
                                    before: 100
                                }
                            })
                        ],
                        columnSpan: 1
                    }),
                    new TableCell({
                        children: [
                            new Paragraph({
                                alignment: AlignmentType.LEFT,
                                children: [
                                    new TextRun({
                                        text: moment(document.fecha_operacion).format('DD-MMMM-YYYY'),
                                        font: 'Arial',
                                        size: 20
                                    })
                                ],
                                spacing: {
                                    after: 100,
                                    before: 100
                                }
                            })
                        ],
                        columnSpan: 1
                    }),
                    new TableCell({
                        children: [
                            new Paragraph({
                                alignment: AlignmentType.LEFT,
                                children: [
                                    new TextRun({
                                        text: document.comentario,
                                        font: 'Arial',
                                        size: 20
                                    })
                                ],
                                spacing: {
                                    after: 100,
                                    before: 100
                                }
                            })
                        ],
                        columnSpan: 1
                    }),
                ]
            })

            table.addChildElement(documentRow)
        })

        const question_4 = new TableRow({
            children: [
                new TableCell({
                    children: [
                        new Paragraph({
                            alignment: AlignmentType.CENTER,
                            children: [
                                new TextRun({
                                    text: '4',
                                    font: 'Arial',
                                    size: 20
                                })
                            ],
                            spacing: {
                                after: 100,
                                before: 100
                            }
                        })
                    ],
                    columnSpan: 1
                }),
                new TableCell({
                    children: [
                        new Paragraph({
                            alignment: AlignmentType.LEFT,
                            children: [
                                new TextRun({
                                    text: '¿Fue posible rastrear el producto/ingrediente hasta una parcela/proveedor de origen?',
                                    font: 'Arial',
                                    size: 20
                                })
                            ],
                            spacing: {
                                after: 100,
                                before: 100
                            }
                        })
                    ],
                    columnSpan: 2
                }),
                new TableCell({
                    children: [
                        new Paragraph({
                            alignment: AlignmentType.CENTER,
                            children: [
                                new TextRun({
                                    text: `${data.registros_4_conformidad === 'C' ? '■' : '❏'} C ${data.registros_4_conformidad === 'NC' ? '■' : '❏'} NC ${data.registros_4_conformidad === 'NA' ? '■' : '❏'} NA`,
                                    font: 'Arial',
                                    size: 20
                                })
                            ],
                            spacing: {
                                after: 100,
                                before: 100
                            }
                        })
                    ],
                    columnSpan: 3
                }),
            ]
        })
        const question_4_explique = new TableRow({
            children: [
                new TableCell({
                    children: [
                        new Paragraph({
                            alignment: AlignmentType.LEFT,
                            children: [
                                new TextRun({
                                    text: 'Explique: ' + data.registros_4_pregunta,
                                    font: 'Arial',
                                    size: 20
                                })
                            ],
                            spacing: {
                                after: 100,
                                before: 100
                            }
                        })
                    ],
                    columnSpan: 6
                }),
            ]
        })
        table.addChildElement(question_4)
        table.addChildElement(question_4_explique)

        const question_5 = new TableRow({
            children: [
                new TableCell({
                    children: [
                        new Paragraph({
                            alignment: AlignmentType.CENTER,
                            children: [
                                new TextRun({
                                    text: '5',
                                    font: 'Arial',
                                    size: 20
                                })
                            ],
                            spacing: {
                                after: 100,
                                before: 100
                            }
                        })
                    ],
                    columnSpan: 1
                }),
                new TableCell({
                    children: [
                        new Paragraph({
                            alignment: AlignmentType.CENTER,
                            children: [
                                new TextRun({
                                    text: 'En los registros revisados se pudo identificar:',
                                    font: 'Arial',
                                    size: 20
                                })
                            ],
                            spacing: {
                                after: 100,
                                before: 100
                            }
                        })
                    ],
                    columnSpan: 5
                }),
            ]
        })
        table.addChildElement(question_5)

        const question_request_headers = new TableRow({
            children: [
                new TableCell({
                    children: [
                        new Paragraph({
                            alignment: AlignmentType.CENTER,
                            children: [
                                new TextRun({
                                    text: '',
                                    font: 'Arial',
                                    size: 20
                                })
                            ],
                            spacing: {
                                after: 100,
                                before: 100
                            }
                        })
                    ],
                    columnSpan: 1
                }),
                new TableCell({
                    children: [
                        new Paragraph({
                            alignment: AlignmentType.LEFT,
                            children: [
                                new TextRun({
                                    text: 'Requisito',
                                    font: 'Arial',
                                    size: 20
                                })
                            ],
                            spacing: {
                                after: 100,
                                before: 100
                            }
                        })
                    ],
                    shading: {
                        fill: '#C0C0C0',
                        val: ShadingType.PERCENT_80
                    },
                    columnSpan: 2
                }),
                new TableCell({
                    children: [
                        new Paragraph({
                            alignment: AlignmentType.LEFT,
                            children: [
                                new TextRun({
                                    text: 'Evaluación',
                                    font: 'Arial',
                                    size: 20
                                })
                            ],
                            spacing: {
                                after: 100,
                                before: 100
                            }
                        })
                    ],
                    shading: {
                        fill: '#C0C0C0',
                        val: ShadingType.PERCENT_80
                    },
                    columnSpan: 1
                }),
                new TableCell({
                    children: [
                        new Paragraph({
                            alignment: AlignmentType.LEFT,
                            children: [
                                new TextRun({
                                    text: 'Comentarios',
                                    font: 'Arial',
                                    size: 20
                                })
                            ],
                            spacing: {
                                after: 100,
                                before: 100
                            }
                        })
                    ],
                    shading: {
                        fill: '#C0C0C0',
                        val: ShadingType.PERCENT_80
                    },
                    columnSpan: 2
                }),
            ]
        })
        table.addChildElement(question_request_headers)

        const question_request_1 = new TableRow({
            children: [
                new TableCell({
                    children: [
                        new Paragraph({
                            alignment: AlignmentType.CENTER,
                            children: [
                                new TextRun({
                                    text: '',
                                    font: 'Arial',
                                    size: 20
                                })
                            ],
                            spacing: {
                                after: 100,
                                before: 100
                            }
                        })
                    ],
                    columnSpan: 1
                }),
                new TableCell({
                    children: [
                        new Paragraph({
                            alignment: AlignmentType.LEFT,
                            children: [
                                new TextRun({
                                    text: 'Nombre del proveedor (ingrediente)',
                                    font: 'Arial',
                                    size: 20
                                })
                            ],
                            spacing: {
                                after: 100,
                                before: 100
                            }
                        })
                    ],
                    columnSpan: 2
                }),
                new TableCell({
                    children: [
                        new Paragraph({
                            alignment: AlignmentType.LEFT,
                            children: [
                                new TextRun({
                                    text: `${data.registros_5_pregunta_1 === 'C' ? '■' : '❏'} C ${data.registros_5_pregunta_1 === 'NC' ? '■' : '❏'} NC ${data.registros_5_pregunta_1 === 'NA' ? '■' : '❏'} NA`,
                                    font: 'Arial',
                                    size: 20
                                })
                            ],
                            spacing: {
                                after: 100,
                                before: 100
                            }
                        })
                    ],
                    columnSpan: 1
                }),
                new TableCell({
                    children: [
                        new Paragraph({
                            alignment: AlignmentType.LEFT,
                            children: [
                                new TextRun({
                                    text: data.registros_5_comentarios_1,
                                    font: 'Arial',
                                    size: 20
                                })
                            ],
                            spacing: {
                                after: 100,
                                before: 100
                            }
                        })
                    ],
                    columnSpan: 2
                }),
            ]
        })
        table.addChildElement(question_request_1)

        const question_request_2 = new TableRow({
            children: [
                new TableCell({
                    children: [
                        new Paragraph({
                            alignment: AlignmentType.CENTER,
                            children: [
                                new TextRun({
                                    text: '',
                                    font: 'Arial',
                                    size: 20
                                })
                            ],
                            spacing: {
                                after: 100,
                                before: 100
                            }
                        })
                    ],
                    columnSpan: 1
                }),
                new TableCell({
                    children: [
                        new Paragraph({
                            alignment: AlignmentType.LEFT,
                            children: [
                                new TextRun({
                                    text: 'Nombre del operador (inspeccionado)',
                                    font: 'Arial',
                                    size: 20
                                })
                            ],
                            spacing: {
                                after: 100,
                                before: 100
                            }
                        })
                    ],
                    columnSpan: 2
                }),
                new TableCell({
                    children: [
                        new Paragraph({
                            alignment: AlignmentType.LEFT,
                            children: [
                                new TextRun({
                                    text: `${data.registros_5_pregunta_2 === 'C' ? '■' : '❏'} C ${data.registros_5_pregunta_2 === 'NC' ? '■' : '❏'} NC ${data.registros_5_pregunta_2 === 'NA' ? '■' : '❏'} NA`,
                                    font: 'Arial',
                                    size: 20
                                })
                            ],
                            spacing: {
                                after: 100,
                                before: 100
                            }
                        })
                    ],
                    columnSpan: 1
                }),
                new TableCell({
                    children: [
                        new Paragraph({
                            alignment: AlignmentType.LEFT,
                            children: [
                                new TextRun({
                                    text: data.registros_5_comentarios_2,
                                    font: 'Arial',
                                    size: 20
                                })
                            ],
                            spacing: {
                                after: 100,
                                before: 100
                            }
                        })
                    ],
                    columnSpan: 2
                }),
            ]
        })
        table.addChildElement(question_request_2)

        const question_request_3 = new TableRow({
            children: [
                new TableCell({
                    children: [
                        new Paragraph({
                            alignment: AlignmentType.CENTER,
                            children: [
                                new TextRun({
                                    text: '',
                                    font: 'Arial',
                                    size: 20
                                })
                            ],
                            spacing: {
                                after: 100,
                                before: 100
                            }
                        })
                    ],
                    columnSpan: 1
                }),
                new TableCell({
                    children: [
                        new Paragraph({
                            alignment: AlignmentType.LEFT,
                            children: [
                                new TextRun({
                                    text: 'Nombre del destinatario (comprador)',
                                    font: 'Arial',
                                    size: 20
                                })
                            ],
                            spacing: {
                                after: 100,
                                before: 100
                            }
                        })
                    ],
                    columnSpan: 2
                }),
                new TableCell({
                    children: [
                        new Paragraph({
                            alignment: AlignmentType.LEFT,
                            children: [
                                new TextRun({
                                    text: `${data.registros_5_pregunta_3 === 'C' ? '■' : '❏'} C ${data.registros_5_pregunta_3 === 'NC' ? '■' : '❏'} NC ${data.registros_5_pregunta_3 === 'NA' ? '■' : '❏'} NA`,
                                    font: 'Arial',
                                    size: 20
                                })
                            ],
                            spacing: {
                                after: 100,
                                before: 100
                            }
                        })
                    ],
                    columnSpan: 1
                }),
                new TableCell({
                    children: [
                        new Paragraph({
                            alignment: AlignmentType.LEFT,
                            children: [
                                new TextRun({
                                    text: data.registros_5_comentarios_3,
                                    font: 'Arial',
                                    size: 20
                                })
                            ],
                            spacing: {
                                after: 100,
                                before: 100
                            }
                        })
                    ],
                    columnSpan: 2
                }),
            ]
        })
        table.addChildElement(question_request_3)

        const question_request_4 = new TableRow({
            children: [
                new TableCell({
                    children: [
                        new Paragraph({
                            alignment: AlignmentType.CENTER,
                            children: [
                                new TextRun({
                                    text: '',
                                    font: 'Arial',
                                    size: 20
                                })
                            ],
                            spacing: {
                                after: 100,
                                before: 100
                            }
                        })
                    ],
                    columnSpan: 1
                }),
                new TableCell({
                    children: [
                        new Paragraph({
                            alignment: AlignmentType.LEFT,
                            children: [
                                new TextRun({
                                    text: 'Nombre del producto',
                                    font: 'Arial',
                                    size: 20
                                })
                            ],
                            spacing: {
                                after: 100,
                                before: 100
                            }
                        })
                    ],
                    columnSpan: 2
                }),
                new TableCell({
                    children: [
                        new Paragraph({
                            alignment: AlignmentType.LEFT,
                            children: [
                                new TextRun({
                                    text: `${data.registros_5_pregunta_4 === 'C' ? '■' : '❏'} C ${data.registros_5_pregunta_4 === 'NC' ? '■' : '❏'} NC ${data.registros_5_pregunta_4 === 'NA' ? '■' : '❏'} NA`,
                                    font: 'Arial',
                                    size: 20
                                })
                            ],
                            spacing: {
                                after: 100,
                                before: 100
                            }
                        })
                    ],
                    columnSpan: 1
                }),
                new TableCell({
                    children: [
                        new Paragraph({
                            alignment: AlignmentType.LEFT,
                            children: [
                                new TextRun({
                                    text: data.registros_5_comentarios_4,
                                    font: 'Arial',
                                    size: 20
                                })
                            ],
                            spacing: {
                                after: 100,
                                before: 100
                            }
                        })
                    ],
                    columnSpan: 2
                }),
            ]
        })
        table.addChildElement(question_request_4)

        const question_request_5 = new TableRow({
            children: [
                new TableCell({
                    children: [
                        new Paragraph({
                            alignment: AlignmentType.CENTER,
                            children: [
                                new TextRun({
                                    text: '',
                                    font: 'Arial',
                                    size: 20
                                })
                            ],
                            spacing: {
                                after: 100,
                                before: 100
                            }
                        })
                    ],
                    columnSpan: 1
                }),
                new TableCell({
                    children: [
                        new Paragraph({
                            alignment: AlignmentType.LEFT,
                            children: [
                                new TextRun({
                                    text: 'Certificado orgánico del proveedor vigente',
                                    font: 'Arial',
                                    size: 20
                                })
                            ],
                            spacing: {
                                after: 100,
                                before: 100
                            }
                        })
                    ],
                    columnSpan: 2
                }),
                new TableCell({
                    children: [
                        new Paragraph({
                            alignment: AlignmentType.LEFT,
                            children: [
                                new TextRun({
                                    text: `${data.registros_5_pregunta_5 === 'C' ? '■' : '❏'} C ${data.registros_5_pregunta_5 === 'NC' ? '■' : '❏'} NC ${data.registros_5_pregunta_5 === 'NA' ? '■' : '❏'} NA`,
                                    font: 'Arial',
                                    size: 20
                                })
                            ],
                            spacing: {
                                after: 100,
                                before: 100
                            }
                        })
                    ],
                    columnSpan: 1
                }),
                new TableCell({
                    children: [
                        new Paragraph({
                            alignment: AlignmentType.LEFT,
                            children: [
                                new TextRun({
                                    text: data.registros_5_comentarios_5,
                                    font: 'Arial',
                                    size: 20
                                })
                            ],
                            spacing: {
                                after: 100,
                                before: 100
                            }
                        })
                    ],
                    columnSpan: 2
                }),
            ]
        })
        table.addChildElement(question_request_5)

        const question_request_6 = new TableRow({
            children: [
                new TableCell({
                    children: [
                        new Paragraph({
                            alignment: AlignmentType.CENTER,
                            children: [
                                new TextRun({
                                    text: '',
                                    font: 'Arial',
                                    size: 20
                                })
                            ],
                            spacing: {
                                after: 100,
                                before: 100
                            }
                        })
                    ],
                    columnSpan: 1
                }),
                new TableCell({
                    children: [
                        new Paragraph({
                            alignment: AlignmentType.LEFT,
                            children: [
                                new TextRun({
                                    text: 'Certificado orgánico del proveedor para cada norma evaluada',
                                    font: 'Arial',
                                    size: 20
                                })
                            ],
                            spacing: {
                                after: 100,
                                before: 100
                            }
                        })
                    ],
                    columnSpan: 2
                }),
                new TableCell({
                    children: [
                        new Paragraph({
                            alignment: AlignmentType.LEFT,
                            children: [
                                new TextRun({
                                    text: `${data.registros_5_pregunta_6 === 'C' ? '■' : '❏'} C ${data.registros_5_pregunta_6 === 'NC' ? '■' : '❏'} NC ${data.registros_5_pregunta_6 === 'NA' ? '■' : '❏'} NA`,
                                    font: 'Arial',
                                    size: 20
                                })
                            ],
                            spacing: {
                                after: 100,
                                before: 100
                            }
                        })
                    ],
                    columnSpan: 1
                }),
                new TableCell({
                    children: [
                        new Paragraph({
                            alignment: AlignmentType.LEFT,
                            children: [
                                new TextRun({
                                    text: data.registros_5_comentarios_6,
                                    font: 'Arial',
                                    size: 20
                                })
                            ],
                            spacing: {
                                after: 100,
                                before: 100
                            }
                        })
                    ],
                    columnSpan: 2
                }),
            ]
        })
        table.addChildElement(question_request_6)

        const question_request_7 = new TableRow({
            children: [
                new TableCell({
                    children: [
                        new Paragraph({
                            alignment: AlignmentType.CENTER,
                            children: [
                                new TextRun({
                                    text: '',
                                    font: 'Arial',
                                    size: 20
                                })
                            ],
                            spacing: {
                                after: 100,
                                before: 100
                            }
                        })
                    ],
                    columnSpan: 1
                }),
                new TableCell({
                    children: [
                        new Paragraph({
                            alignment: AlignmentType.LEFT,
                            children: [
                                new TextRun({
                                    text: 'Identificación clara del lote',
                                    font: 'Arial',
                                    size: 20
                                })
                            ],
                            spacing: {
                                after: 100,
                                before: 100
                            }
                        })
                    ],
                    columnSpan: 2
                }),
                new TableCell({
                    children: [
                        new Paragraph({
                            alignment: AlignmentType.LEFT,
                            children: [
                                new TextRun({
                                    text: `${data.registros_5_pregunta_7 === 'C' ? '■' : '❏'} C ${data.registros_5_pregunta_7 === 'NC' ? '■' : '❏'} NC ${data.registros_5_pregunta_7 === 'NA' ? '■' : '❏'} NA`,
                                    font: 'Arial',
                                    size: 20
                                })
                            ],
                            spacing: {
                                after: 100,
                                before: 100
                            }
                        })
                    ],
                    columnSpan: 1
                }),
                new TableCell({
                    children: [
                        new Paragraph({
                            alignment: AlignmentType.LEFT,
                            children: [
                                new TextRun({
                                    text: data.registros_5_comentarios_7,
                                    font: 'Arial',
                                    size: 20
                                })
                            ],
                            spacing: {
                                after: 100,
                                before: 100
                            }
                        })
                    ],
                    columnSpan: 2
                }),
            ]
        })
        table.addChildElement(question_request_7)

        const question_request_8 = new TableRow({
            children: [
                new TableCell({
                    children: [
                        new Paragraph({
                            alignment: AlignmentType.CENTER,
                            children: [
                                new TextRun({
                                    text: '',
                                    font: 'Arial',
                                    size: 20
                                })
                            ],
                            spacing: {
                                after: 100,
                                before: 100
                            }
                        })
                    ],
                    columnSpan: 1
                }),
                new TableCell({
                    children: [
                        new Paragraph({
                            alignment: AlignmentType.LEFT,
                            children: [
                                new TextRun({
                                    text: '¿Se usa el código de lote descrito en el plan orgánico?',
                                    font: 'Arial',
                                    size: 20
                                })
                            ],
                            spacing: {
                                after: 100,
                                before: 100
                            }
                        })
                    ],
                    columnSpan: 2
                }),
                new TableCell({
                    children: [
                        new Paragraph({
                            alignment: AlignmentType.LEFT,
                            children: [
                                new TextRun({
                                    text: `${data.registros_5_pregunta_8 === 'C' ? '■' : '❏'} C ${data.registros_5_pregunta_8 === 'NC' ? '■' : '❏'} NC ${data.registros_5_pregunta_8 === 'NA' ? '■' : '❏'} NA`,
                                    font: 'Arial',
                                    size: 20
                                })
                            ],
                            spacing: {
                                after: 100,
                                before: 100
                            }
                        })
                    ],
                    columnSpan: 1
                }),
                new TableCell({
                    children: [
                        new Paragraph({
                            alignment: AlignmentType.LEFT,
                            children: [
                                new TextRun({
                                    text: data.registros_5_comentarios_8,
                                    font: 'Arial',
                                    size: 20
                                })
                            ],
                            spacing: {
                                after: 100,
                                before: 100
                            }
                        })
                    ],
                    columnSpan: 2
                }),
            ]
        })
        table.addChildElement(question_request_8)

        const question_request_9 = new TableRow({
            children: [
                new TableCell({
                    children: [
                        new Paragraph({
                            alignment: AlignmentType.CENTER,
                            children: [
                                new TextRun({
                                    text: '',
                                    font: 'Arial',
                                    size: 20
                                })
                            ],
                            spacing: {
                                after: 100,
                                before: 100
                            }
                        })
                    ],
                    columnSpan: 1
                }),
                new TableCell({
                    children: [
                        new Paragraph({
                            alignment: AlignmentType.LEFT,
                            children: [
                                new TextRun({
                                    text: 'Código asignado a Metrocert (UE)',
                                    font: 'Arial',
                                    size: 20
                                })
                            ],
                            spacing: {
                                after: 100,
                                before: 100
                            }
                        })
                    ],
                    columnSpan: 2
                }),
                new TableCell({
                    children: [
                        new Paragraph({
                            alignment: AlignmentType.LEFT,
                            children: [
                                new TextRun({
                                    text: `${data.registros_5_pregunta_9 === 'C' ? '■' : '❏'} C ${data.registros_5_pregunta_9 === 'NC' ? '■' : '❏'} NC ${data.registros_5_pregunta_9 === 'NA' ? '■' : '❏'} NA`,
                                    font: 'Arial',
                                    size: 20
                                })
                            ],
                            spacing: {
                                after: 100,
                                before: 100
                            }
                        })
                    ],
                    columnSpan: 1
                }),
                new TableCell({
                    children: [
                        new Paragraph({
                            alignment: AlignmentType.LEFT,
                            children: [
                                new TextRun({
                                    text: data.registros_5_comentarios_9,
                                    font: 'Arial',
                                    size: 20
                                })
                            ],
                            spacing: {
                                after: 100,
                                before: 100
                            }
                        })
                    ],
                    columnSpan: 2
                }),
            ]
        })
        table.addChildElement(question_request_9)

        const general_comments = new TableRow({
            children: [
                new TableCell({
                    children: [
                        new Paragraph({
                            alignment: AlignmentType.LEFT,
                            children: [
                                new TextRun({
                                    text: `Comentarios Generales: ${data.comentarios_generales}`,
                                    font: 'Arial',
                                    size: 20
                                })
                            ],
                            spacing: {
                                after: 100,
                                before: 100
                            }
                        })
                    ],
                    columnSpan: 6
                }),
            ]
        })
        table.addChildElement(general_comments)


        return table
    }

    getValue(submission, name) {
        var value = null
        for (const i in submission) {
            const element = submission[i]
            if (element.name === name) {
                value = element.value
            }
        }
        if (value) return value
        else return ''
    }

    getValueList(submission, name) {
        var value = null
        for (const i in submission) {
            const element = submission[i]
            if (element.name === name) {
                value = element.value
            }
        }
        if (value) return value
        else return []
    }

    getFileList(value) {
        var anexos = ''
        for(var i in value) {
            const tmp = value[i].split("/")
            anexos += tmp[tmp.length-1] + ", "
        }

        anexos = anexos.substr(0, anexos.length-2)

        return anexos
    }
    // END MTO_FINS_03_1_13
}
