import { ControlElement } from './../../../form-builder/interfaces/form.interface';
import { Component, Input, OnInit } from '@angular/core'
import { FormGroup } from '@angular/forms';

@Component({
    selector: 'controls-fabric',
    templateUrl: './controls-fabric.component.html'
})
export class ControlsFabricComponent implements OnInit {

    @Input() content: ControlElement[]
    @Input() formGroup: FormGroup
    
    constructor() {}

    ngOnInit() {
    }

}