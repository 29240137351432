import { ControlCommunicationService } from './../../../services/control-communication.service';
import { FormGroup, FormControl } from '@angular/forms';
import { FormBuilderComponent } from './../../../form-builder.component';
import { UnknownParams } from './../../../interfaces/form.interface';
import { ControlElement } from '../../../interfaces/form.interface';
import { Component, OnInit, Input, ViewEncapsulation } from '@angular/core';
import { DecimalPipe } from '@angular/common';
import { Pipe } from '@angular/core';

//Moment import
const moment = require('moment');
moment.locale('es-mx');

@Component({
  selector: 'timepicker-element',
  templateUrl: './timepicker.component.html',
  styleUrls: ['./timepicker.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class TimePickerComponent implements OnInit {
  attributes: UnknownParams = {};
  validations: UnknownParams = {};

  @Input() form: FormGroup;
  @Input() data: ControlElement;

  // inject parent FormBuilder Component in order to access FormGroup
  constructor(
    private parentControl: FormBuilderComponent,
    private communicationService: ControlCommunicationService
  ) {
    this.communicationService.changeEmmited$.subscribe((msg) => {});
  }

  ngOnInit(): void {
    // asign parent formGroup to local formGroup
    if (!this.form) {
      this.form = this.parentControl.formGroup;
    }
    // iterate and access component attributes
    for (var i in this.data.attributes) {
      const attribute = this.data.attributes[i];
      this.attributes[attribute.name] = attribute.value;
    }
    for (var i in this.data.validations) {
      const validation = this.data.validations[i];
      this.validations[validation.name] = validation.value;
    }
    this.form.addControl(this.attributes.name, new FormControl(''));
  }
}
