<div class="modal-header">
    <h4 class="modal-title" id="modal-title">Asignar/Cambiar dictaminador</h4>
    <button
      type="button"
      class="close"
      aria-label="Close button"
      aria-describedby="modal-title"
      (click)="modal.dismiss('Cross click')"
    >
      <span aria-hidden="true">&times;</span>
    </button>
</div>
  
<form [formGroup]="form" (ngSubmit)="onSubmit()">
    <div class="modal-body">
      <p>
        <strong *ngIf="tmpName !== ''">
          ¿Estás seguro de que deseas cambiar al dictaminador actual asignado
          <span class="text-primary">
            "{{ name }}"
          </span>
          ?
        </strong>

        <strong *ngIf="tmpName === ''">
          Asignación de nuevo dictaminador
        </strong>
      </p>
      
      <div class="form-group">
        <label for="newDictator"
          >Asignar nuevo dictaminador<span class="text-danger">*</span></label
        >
        <select
          formControlName="newDictator"
          class="form-control"
        >
          <option
            *ngFor="let r of dictators"
            [value]="r.id"
          >
            {{ r.name }}
          </option>
        </select>
        <div *ngIf="submitted && f.newDictator.errors">
          <div *ngIf="f.newDictator.errors.required" class="text-danger">
            <small>Nuevo dictaminador requerido</small>
          </div>
        </div>
      </div>
    </div>
    <div class="modal-footer">
      <button
        type="button"
        class="btn btn-outline-secondary"
        (click)="modal.dismiss('cancel click')"
      >
        Cancelar
      </button>
      <button
        [disabled]="loading"
        type="button"
        ngbAutofocus
        class="btn btn-primary"
        (click)="onSubmit()"
      >
        <span
          *ngIf="loading"
          class="spinner-border spinner-border-sm mr-1"
        ></span>
        Aceptar
      </button>
    </div>
</form>
  