import { FormBuilderComponent } from './../../../form-builder.component';
import { UnknownParams, ControlElement } from './../../../interfaces/form.interface';
import { Component, OnInit, Input } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';

@Component({
  selector: 'textarea-element',
  templateUrl: './text-area.component.html'
})
export class TextAreaComponent implements OnInit {

  attributes: UnknownParams = {}
  validations: UnknownParams = {}

  @Input() data: ControlElement

  @Input() form: FormGroup

  constructor(private parentControl: FormBuilderComponent) {
  }

  ngOnInit(): void {
    if (!this.form) {this.form = this.parentControl.formGroup}
    for (var i in this.data.attributes) {
      const attribute = this.data.attributes[i]
      this.attributes[attribute.name] = attribute.value
    }
    for (var i in this.data.validations) {
      const validation = this.data.validations[i]
      this.validations[validation.name] = validation.value
    }
    this.form.addControl(this.attributes.name, new FormControl(''))
  }


}
