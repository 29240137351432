import { Subscription } from 'rxjs';
import { ContainerElement, Form } from './interfaces/form.interface';
import { Component, OnInit, Input, Output, EventEmitter, OnDestroy, ChangeDetectionStrategy, Injectable } from '@angular/core';
import { FormGroup, FormControl, FormArray } from '@angular/forms'
import { ControlCommunicationService } from './services/control-communication.service';

@Component({
	selector: 'form-builder',
	templateUrl: './form-builder.component.html',
	// If we use the default behaviour of change detection, Angular will throw an error since
	// changes will be made on runtime
	// changeDetection: ChangeDetectionStrategy.OnPush 
})
export class FormBuilderComponent implements OnInit, OnDestroy {

	@Input() formData: Form

	public formGroup: FormGroup

	private subscriptions: Subscription

	constructor(private _controlCommunicationService: ControlCommunicationService) {

	}

	ngOnInit(): void {
		this.formGroup = new FormGroup({})
		this.subscriptions = this._controlCommunicationService.changeEmmited$.subscribe(async msg => {
			//console.log("5", msg)
			if (msg.identifier === 'add-input' && msg.type === 'click') {
				this.addElementToTable()
			} else if (msg.identifier === 'submit') {
				await setTimeout(() => {
					this._controlCommunicationService.emitEvent({
						identifier: 'send-form',
						form: this.formGroup,
						text: msg.text
					})
				}, 100)
			} else if (msg.identifier === 'submit-nc') {
				await setTimeout(() => {
					// submit event during no conformidades
					this._controlCommunicationService.emitEvent({
						identifier: 'send-form-nc',
						form: this.formGroup
					})
				}, 100)
			} else if (msg.identifier === 'submit-tm') {
				await setTimeout(() => {
					// submit event during toma de muestras
					this._controlCommunicationService.emitEvent({
						identifier: 'send-form-tm',
						form: this.formGroup
					})
				}, 100)
			} else if (msg.identifier === 'set-values') {
				for (var i in Object.keys(msg.form)) {
					const key = Object.keys(msg.form)[i]
					const elem = msg.form[key]
					if (this.formGroup.get(key) && Array.isArray(elem) && this.formGroup.get(key).constructor.name === 'FormArray') {
						this.formGroup.setControl(key, this.getFormArray<any>(elem))
					}
				}
				this.formGroup.patchValue(msg.form)
			}
		})
	}

	getFormArray<T>(elements: T[]): FormArray {
		return new FormArray(
			elements.map((x: T) => {
				const obj = new FormGroup({});
				Object.keys(x).forEach(k => {
					if (Array.isArray(x[k]) && x[k][0] && x[k][0].constructor === Object) {
						obj.addControl(k, this.getFormArray(x[k]))
					}
					obj.addControl(k, new FormControl(x[k]));
				});
				return obj;
			})
		);
	}

	addElementToTable() {
		let table = this.formData.columns[0][1]
		table.content.push({
			class: 'container',
			type: 'table-row',
			attributes: [],
			content: [
				{
					class: 'control',
					type: 'text',
					attributes: [
						{
							name: 'text',
							value: 'Holis'
						},
					],
					description: '',
					validations: [],
				}
			],
			description: ''
		})
	}

	ngOnDestroy() {
		// Always unsuscribe from observables
		this.subscriptions.unsubscribe()
	}

}