import { Component, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { getContractMexicoV3, getContractCanadaV3, getContractEuropaV3 } from '@app/utils/contracts'

import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
import { DatePipe } from '@angular/common';
pdfMake.vfs = pdfFonts.pdfMake.vfs;

@Component({
  selector: 'modal-contract',
  templateUrl: 'modal-contract.component.html',
  styleUrls: ['../../../app.component.scss']
})
export class ModalContract {
  @Input() public name = ''
  @Input() public date
  @Input() public customer
  @Input() public metrocert
  @Input() public signOperator = ''
  @Input() public isMX = false
  @Input() public isCAN = false
  @Input() public isUE = false

  loading = false

  constructor(
      public modal: NgbActiveModal,
      private datePipe: DatePipe,
  ) {}

  async createContract() {
    this.loading = true

    let payload = new FormData()
    console.log(this.isMX, this.isCAN, this.isUE)

    let index = 0
    let key = 'file'
    if(this.isMX) {
      const document = await getContractMexicoV3(this.datePipe, this.date, this.signOperator, this.customer, this.metrocert)
      const pdfMX = pdfMake.createPdf(document)

      if(index === 0) index++
      else {
        key = `file${index}`
        index++
      }

      const blob = await this.getBlobAsPromise(pdfMX)
      payload.append(key, blob, 'MTO-FSOL-03-2-1.pdf')
    }

    if(this.isCAN) {
      const document = await getContractCanadaV3(this.datePipe, this.date, this.signOperator, this.customer, this.metrocert)
      const pdfCAN = pdfMake.createPdf(document)

      if(index === 0) index++
      else {
        key = `file${index}`
        index++
      }

      const blob = await this.getBlobAsPromise(pdfCAN)
      payload.append(key, blob, 'MTO-FSOL-03-2-2.pdf')
    }

    if(this.isUE) {
      const document = await getContractEuropaV3(this.datePipe, this.date, this.signOperator, this.customer, this.metrocert)
      const pdfUE = pdfMake.createPdf(document)

      if(index === 0) index++
      else {
        key = `file${index}`
        index++
      }

      const blob = await this.getBlobAsPromise(pdfUE)
      payload.append(key, blob, 'MTO-FSOL-03-2-3.pdf')
    }

    const result = {
      result: true,
      contracts: payload
    }

    this.modal.close(result)
    this.modal.dismiss()
    this.loading = false
  }

  getBlobAsPromise = async (pdf): Promise<Blob> => {
    return new Promise((resolve) => {
      pdf.getBlob(blob => {
        resolve(blob)
      })
    })
  }
}