import { AlignmentType,  Document,  Paragraph,  TextRun,  Media,  Header,  Footer,  Table,  TableRow,  TableCell,  WidthType, PageNumber } from "docx";
import {logo} from "../logo";
import {DatePipe} from '@angular/common';
import {userWithoutSign} from "../userWithoutSign"

export class DocumentCreatorMTO_FRYD_03_v3 {
  // INIT MTO_FRYD_03
  public getBase64FromUrl = async (url) => {
    const data = await fetch(url, {
      headers: {
          'Cache-Control': 'no-cache, no-store, must-revalidate',
          'Pragma': 'no-cache',
          'Expires': 'Sat, 01 Jan 2000 00:00:00 GMT',
          'If-Modified-Since': '0'
      }
  });
    const blob = await data.blob();
    return new Promise((resolve) => {
      const reader = new FileReader();
      reader.readAsDataURL(blob);
      reader.onloadend = function () {
        const base64data = reader.result;
        resolve(base64data);
      }
    });
  }

  public async create_MTO_FRYD_03(data, signature = ''): Promise<Document> {
    const document = new Document();
    const image = Media.addImage(document, logo, 50, 50);
    var datePipe = new DatePipe('es-MX');
    let signatureImage = null
    if (signature) {
      signatureImage = await this.getBase64FromUrl(signature)
    } else {
      signatureImage = userWithoutSign
    }

    document.addSection({
      headers: {
        default: new Header({
          children: [
            new Paragraph({alignment: AlignmentType.CENTER, children: [image],}),
            new Paragraph({
              alignment: AlignmentType.CENTER, children: [
                new TextRun({
                  children: [`MTO-FRYD-03| R 01 | 12-Agosto-2019 | Pág. `, PageNumber.CURRENT],
                  font: 'Book Antiqua',
                  size: 20,
                  bold: true
                }),
                new TextRun({
                  font: 'Book Antiqua',
                  size: 20,
                  bold: true,
                  children: [' de ', PageNumber.TOTAL_PAGES]
                }),
              ], spacing: {before: 200, after: 100}
            })
          ]
        })
      },
      footers: {
        default: new Footer({
          children: [
            new Paragraph({alignment: AlignmentType.CENTER}),
            this.createFooter()
          ]
        }),
      },
      children: [
        new Paragraph({
          alignment: AlignmentType.CENTER,
          children: [
            new TextRun({
              text: 'PROPUESTA DE RESULTADO REVISOR',
              font: 'Calibri (Cuerpo)',
              size: 22,
              bold: true
            })
          ],
          spacing: {before: 200, after: 200}
        }),
        this.createTableHeader(data),
        new Paragraph({
          spacing: {
            after: 400
          }
        }),
        this.createTableUDP(data, document, signatureImage)
      ],
      margins: {
        top: 350,
        bottom: 500,
        left: 800,
        right: 800
      }
    });

    return document;
  }

  public createFooter(): Table {
    const table = new Table({
      rows: [
        new TableRow({
          children: [
            new TableCell({
              children: [
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                    new TextRun({
                      text: 'Elaboró:',
                      font: 'Calibri (Cuerpo)',
                      size: 22
                    })
                  ]
                })
              ],
              width: {
                size: 33,
                type: WidthType.PERCENTAGE
              }
            }),
            new TableCell({
              children: [
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                    new TextRun({
                      text: 'Revisó:',
                      font: 'Calibri (Cuerpo)',
                      size: 22
                    })
                  ]
                })
              ],
              width: {
                size: 33,
                type: WidthType.PERCENTAGE
              }
            }),
            new TableCell({
              children: [
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                    new TextRun({
                      text: 'Autorizó:',
                      font: 'Calibri (Cuerpo)',
                      size: 22
                    })
                  ]
                }),
              ],
              width: {
                size: 33,
                type: WidthType.PERCENTAGE
              }
            })
          ]
        }),
        new TableRow({
          children: [
            new TableCell({
              children: [
                new Paragraph({
                  alignment: AlignmentType.CENTER,
                  children: [
                    new TextRun({
                      text: 'Director de certificación',
                      font: 'Calibri (Cuerpo)',
                      size: 16,
                      bold: true
                    })
                  ]
                }),

              ],
              width: {
                size: 33,
                type: WidthType.PERCENTAGE
              },
              shading: {
                fill: '#C0C0C0'
              }
            }),
            new TableCell({
              children: [
                new Paragraph({
                  alignment: AlignmentType.CENTER,
                  children: [
                    new TextRun({
                      text: 'Responsable de calidad',
                      font: 'Calibri (Cuerpo)',
                      size: 16,
                      bold: true
                    })
                  ]
                }),

              ],
              width: {
                size: 33,
                type: WidthType.PERCENTAGE
              },
              shading: {
                fill: '#C0C0C0'
              }
            }),
            new TableCell({
              children: [
                new Paragraph({
                  alignment: AlignmentType.CENTER,
                  children: [
                    new TextRun({
                      text: 'Director general',
                      font: 'Calibri (Cuerpo)',
                      size: 16,
                      bold: true
                    })
                  ]
                }),
              ],
              width: {
                size: 33,
                type: WidthType.PERCENTAGE
              },
              shading: {
                fill: '#C0C0C0'
              }
            })
          ]
        })
      ],
      margins: {
        left: 70,
        right: 70
      },
      columnWidths: [3213, 3213, 3212],
      alignment: AlignmentType.CENTER
    })
    return table
  }

  public createTableHeader(data) {
    const table = new Table({
      rows: [
        new TableRow({
          children: [
            new TableCell({
              children: [
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                    new TextRun({
                      text: `Operador:`,
                      font: 'Arial',
                      size: 22
                    })
                  ],
                  spacing: {before: 100, after: 200}
                })
              ],
              columnSpan: 1
            }),
            new TableCell({
              children: [
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                    new TextRun({
                      text: `${data.operator[0].Name} ${data.operator[0].Middle_Name} ${data.operator[0].Last_Name}`,
                      font: 'Arial',
                      size: 22
                    })
                  ],
                  spacing: {before: 100, after: 200}
                })
              ],
              columnSpan: 2
            })
          ]
        }),
        new TableRow({
          children: [
            new TableCell({
              children: [
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                    new TextRun({
                      text: `Código de operación:`,
                      font: 'Arial',
                      size: 22
                    })
                  ],
                  spacing: {before: 100, after: 200}
                })
              ],
              columnSpan: 1
            }),
            new TableCell({
              children: [
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                    new TextRun({
                      text: data.code,
                      font: 'Arial',
                      size: 22
                    })
                  ],
                  spacing: {before: 100, after: 200}
                })
              ],
              columnSpan: 2
            })
          ]
        }),
        new TableRow({
          children: [
            new TableCell({
              children: [
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                    new TextRun({
                      text: `Responsable de revisión:`,
                      font: 'Arial',
                      size: 22
                    })
                  ],
                  spacing: {before: 100, after: 200},
                })
              ],
              columnSpan: 1
            }),
            new TableCell({
              children: [
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                    new TextRun({
                      text: data.reviewerName,
                      font: 'Arial',
                      size: 22
                    })
                  ],
                  spacing: {before: 100, after: 200}
                })
              ],
              columnSpan: 2
            })
          ]
        })
      ],
      margins: {
        left: 70,
        right: 70
      },
      columnWidths: [3312, 3312, 3312],
      alignment: AlignmentType.CENTER
    })

    return table
  }

  public createTableUDP(data, document, signatureImage) {
    const table = new Table({
      rows: [
        new TableRow({
          children: [
            new TableCell({
              children: [
                new Paragraph({
                  alignment: AlignmentType.CENTER,
                  children: [
                    new TextRun({
                      text: `RESULTADO PROPUESTO POR EL REVISOR`,
                      font: 'Arial',
                      size: 22,
                      bold: true
                    })
                  ]
                })
              ],
              columnSpan: 3
            })
          ]
        }),
        new TableRow({
          children: [
            new TableCell({
              children: [
                new Paragraph({
                  alignment: AlignmentType.CENTER,
                  children: [
                    new TextRun({
                      text: `Basado en el expediente del operador revisado, se propone el siguiente resultado:`,
                      font: 'Arial',
                      size: 22,
                      bold: true
                    })
                  ]
                })
              ],
              columnSpan: 3
            })
          ]
        }),
        new TableRow({
          children: [
            new TableCell({
              children: [
                new Paragraph({
                  alignment: AlignmentType.CENTER,
                  children: [
                    new TextRun({
                      text: `RESULTADO`,
                      font: 'Arial',
                      size: 22,
                      bold: true
                    })
                  ]
                })
              ],
              columnSpan: 1
            }),
            new TableCell({
              children: [
                new Paragraph({
                  alignment: AlignmentType.CENTER,
                  children: [
                    new TextRun({
                      text: `Observaciones o comentarios`,
                      font: 'Arial',
                      size: 22,
                      bold: true
                    })
                  ]
                })
              ],
              columnSpan: 1
            }),
            new TableCell({
              children: [
                new Paragraph({
                  alignment: AlignmentType.CENTER,
                  children: [
                    new TextRun({
                      text: `FIRMA`,
                      font: 'Arial',
                      size: 22,
                      bold: true
                    })
                  ]
                })
              ],
              columnSpan: 1
            })
          ]
        }),
        new TableRow({
          children: [
            new TableCell({
              children: [
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                    new TextRun({
                      text: (data.submission[0].value === 'Aprobado' ? '■' : '❏') + 'Aprobado',
                      font: 'Arial',
                      size: 22
                    })
                  ]
                }),
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                    new TextRun({
                      text: (data.submission[0].value === 'No Aprobado' ? '■' : '❏') + 'No Aprobado',
                      font: 'Arial',
                      size: 22
                    })
                  ]
                }),
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                    new TextRun({
                      text: (data.submission[0].value === 'Seguimiento' ? '■' : '❏') + 'Seguimiento',
                      font: 'Arial',
                      size: 22
                    })
                  ]
                })
              ],
              columnSpan: 1
            }),
            new TableCell({
              children: [
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                    new TextRun({
                      text: data.submission[1].value,
                      font: 'Arial',
                      size: 22
                    })
                  ]
                })
              ],
              columnSpan: 1
            }),
            new TableCell({
              children: [
                new Paragraph({
                  alignment: AlignmentType.CENTER,
                  children: [
                    signatureImage ? Media.addImage(document, signatureImage.toString(), 100, 50) : new TextRun({
                      text: ''
                    })
                  ],
                  spacing: {
                    after: 100,
                    before: 100
                  }
                })
              ],
              columnSpan: 1
            })
          ]
        })
      ],
      margins: {
        left: 70,
        right: 70
      },
      width: {
        size: 100,
        type: WidthType.PERCENTAGE,
      },
      columnWidths: [3312, 3312, 3312]
    })

    return table;
  }

  getValue(submission, name) {
    var value = null
    for (const i in submission) {
      const element = submission[i]
      if (element.name === name) {
        value = element.value
      }
    }
    if (value) return value
    else return ''
  }

  getValueList(submission, name) {
    var value = null
    for (const i in submission) {
      const element = submission[i]
      if (element.name === name) {
        value = element.value
      }
    }
    if (value) return value
    else return []
  }

  // END MTO_FRYD_03
}
