import {
  AlignmentType,
  Document,
  Paragraph,
  TextRun,
  Media,
  Header,
  Footer,
  Table,
  TableRow,
  TableCell,
  VerticalAlign,
  WidthType,
  PageNumber,
  BorderStyle
} from "docx";
import {logo} from "../logo";
import {userWithoutSign} from "../userWithoutSign"
import {DatePipe} from '@angular/common';

export class DocumentCreatorMTO_FSOL_08_2_4_v3 {

  public getBase64FromUrl = async (url) => {
    const data = await fetch(url, {
      headers: {
          'Cache-Control': 'no-cache, no-store, must-revalidate',
          'Pragma': 'no-cache',
          'Expires': 'Sat, 01 Jan 2000 00:00:00 GMT',
          'If-Modified-Since': '0'
      }
  });
    const blob = await data.blob();
    return new Promise((resolve) => {
      const reader = new FileReader();
      reader.readAsDataURL(blob);
      reader.onloadend = function () {
        const base64data = reader.result;
        resolve(base64data);
      }
    });
  }

  // INIT MTO_FSOL_08_1_2
  public async create_MTO_FSOL_08_2_4(data, isReview = true, signature = '', signature2 = ''): Promise<Document> {
    const document = new Document();
    const image = Media.addImage(document, logo, 50, 50);
    var datePipe = new DatePipe('es-MX');

    let signatureImage = null
    if (signature) {
      signatureImage = await this.getBase64FromUrl(signature)
    } else {
      signatureImage = userWithoutSign
    }

    let signatureImage2 = null
    if (signature2) {
      signatureImage2 = await this.getBase64FromUrl(signature2)
    } else {
      signatureImage2 = isReview ? '' : userWithoutSign
    }

    var unidadesProduccion = ''
    const unidades = this.getValueList(data.submission, "lista_unidades")
    unidades.forEach(unidad => {
      unidadesProduccion += unidad.nombre_unidad_produccion + ', '
    });
    unidadesProduccion = unidadesProduccion.slice(0, -2)

    document.addSection({
      headers: {
        default: new Header({
          children: [
            new Paragraph({alignment: AlignmentType.CENTER, children: [image],}),
            new Paragraph({
              alignment: AlignmentType.CENTER, children: [
                new TextRun({
                  children: [`MTO-FSOL-08-2-4| R 02 | 22-Abril-2021 | Pág. `, PageNumber.CURRENT],
                  font: 'Book Antiqua',
                  size: 20,
                  bold: true
                }),
                new TextRun({
                  font: 'Book Antiqua',
                  size: 20,
                  bold: true,
                  children: [' de ', PageNumber.TOTAL_PAGES]
                }),
              ], spacing: {before: 200, after: 100}
            })
          ]
        })
      },
      footers: {
        default: new Footer({
          children: [
            new Paragraph({alignment: AlignmentType.CENTER}),
            this.createFooter()
          ]
        }),
      },
      children: [
        new Paragraph({
          alignment: AlignmentType.CENTER,
          children: [
            new TextRun({
              text: 'PLAN ORGÁNICO PRODUCCIÓN ANIMAL ACUICOLA',
              font: 'Calibri (Cuerpo)',
              size: 22,
              bold: true
            })
          ],
          spacing: {after: 200}
        }),
        this.createTableUDP(data, datePipe),
        new Paragraph({
          spacing: {
            after: 400
          }
        }),
        this.createRegisters_MTO_FSOL_08_2_4(data.submission),
        new Paragraph({
          spacing: {
            after: 400
          }
        }),
        this.createHandling_MTO_FSOL_08_2_4(data.submission),
        new Paragraph({
          alignment: AlignmentType.JUSTIFIED,
          children: [
            new TextRun({
              text: `El que suscribe: ${data.customer.Legal_Authorities[0].Name} ${data.customer.Legal_Authorities[0].Middle_Name} ${data.customer.Legal_Authorities[0].Last_Name}, Titular / Representante Legal de la(s) Unidad(es) de Producción / Empresa: ${data.customer.Company_Type === "PERSONA_FISICA" ? unidadesProduccion : data.customer.Company_Name}, declaro que la información descrita en este Plan Orgánico y documentos adjuntos es verídica, y me comprometo a mantenerlo actualizado, al igual que las actividades descritas en él; con la finalidad de cumplir con la Ley de Productos Orgánicos de México (LPO) y las Regulaciones de Productos Orgánicos de Canadá (COR/USCOEA).`,
              font: 'Arial',
              size: 18
            })
          ],
          spacing: {
            before: 400,
            after: 400
          },
        }),
        new Paragraph({
          alignment: AlignmentType.JUSTIFIED,
          children: [
            new TextRun({
              text: 'Atentamente:',
              font: 'Arial',
              size: 18, bold: true
            })
          ],
          spacing: {
            before: 400,
            after: 400
          },
        }),
        new Paragraph({
          alignment: AlignmentType.CENTER,
          children: [
            signatureImage ? Media.addImage(document, signatureImage.toString(), 200, 100) : new TextRun({
              text: ''
            })
          ]
        }),
        new Paragraph({
          alignment: AlignmentType.LEFT,
          children: [
            new TextRun({
              text: `Fecha: ${datePipe.transform(new Date(data.record.Applications[0].Creation_Date.includes('UTC') ? data.record.Applications[0].Creation_Date.replace('UTC', '').trim() : data.record.Applications[0].Creation_Date), 'd-MMMM-y')}                  Nombre y firma del operador: ${data.customer.Legal_Authorities[0].Name} ${data.customer.Legal_Authorities[0].Middle_Name} ${data.customer.Legal_Authorities[0].Last_Name ? data.customer.Legal_Authorities[0].Last_Name : ''}`,
              font: 'Arial',
              size: 20,
              italics: true
            })
          ],
          spacing: {
            after: 200
          }
        }),
        new Table({
          rows: [
              new TableRow({
                  children: [
                      new TableCell({
                          children: [
                              new Paragraph({
                                  alignment: AlignmentType.CENTER,
                                  children: [
                                      new TextRun({
                                          text: 'EVALUACIÓN POR EL REVISOR',
                                          font: 'Arial',
                                          size: 20,
                                          bold: true
                                      })
                                  ],
                                  spacing: {
                                      after: 75,
                                      before: 75
                                  }
                              }),
                          ],
                          verticalAlign: VerticalAlign.CENTER,
                          columnSpan: 2,
                          shading: {
                              fill: '#C0C0C0'
                          }
                      })
                  ]
              }),
              new TableRow({
                  children: [
                      new TableCell({
                          children: [
                              new Paragraph({
                                  alignment: AlignmentType.RIGHT,
                                  children: [
                                      new TextRun({
                                          text: 'Nombre del Revisor: ',
                                          font: 'Calibri (Cuerpo)',
                                          size: 22,
                                      })
                                  ],
                                  spacing: { before: 100, after: 200 }
                              })],
                          verticalAlign: VerticalAlign.CENTER,
                      }),
                      new TableCell({
                          children: [
                              new Paragraph({
                                  alignment: AlignmentType.LEFT,
                                  children: [
                                      new TextRun({
                                          text: data.reviewer ? data.reviewer.name : '',
                                          font: 'Calibri (Cuerpo)',
                                          size: 22,
                                      })
                                  ],
                                  spacing: { before: 100, after: 200 }
                              })],
                          verticalAlign: VerticalAlign.CENTER,
                      }),
                  ],
              }),
              new TableRow({
                  children: [
                      new TableCell({
                          children: [
                              new Paragraph({
                                  alignment: AlignmentType.RIGHT,
                                  children: [
                                      new TextRun({
                                          text: 'Fecha: ',
                                          font: 'Calibri (Cuerpo)',
                                          size: 22,
                                      })
                                  ],
                                  spacing: { before: 100, after: 200 }
                              })],
                          verticalAlign: VerticalAlign.CENTER,
                      }),
                      new TableCell({
                          children: [
                              new Paragraph({
                                  alignment: AlignmentType.LEFT,
                                  children: [
                                      new TextRun({
                                          text: `${data.reviewer ? datePipe.transform(new Date(data.reviewer.date), 'd-MMMM-y') : ''}`,
                                          font: 'Calibri (Cuerpo)',
                                          size: 22,
                                      })
                                  ],
                                  spacing: { before: 100, after: 200 }
                              })],
                          verticalAlign: VerticalAlign.CENTER,
                      }),
                  ],
              }),
              new TableRow({
                  children: [
                      new TableCell({
                          children: [
                              new Paragraph({
                                  alignment: AlignmentType.RIGHT,
                                  children: [
                                      new TextRun({
                                          text: 'Conformidad: ',
                                          font: 'Calibri (Cuerpo)',
                                          size: 22,
                                      })
                                  ],
                                  spacing: { before: 100, after: 200 }
                              })],
                          verticalAlign: VerticalAlign.CENTER,
                      }),
                      new TableCell({
                          children: [
                              new Paragraph({
                                  alignment: AlignmentType.LEFT,
                                  children: [
                                      new TextRun({
                                          text: `${this.getValue(data.revision, 'plan_organico_conformidad_no_conformidades_graves') === true ? '■' : '❏'} No conformidades graves, no procederá la certificación.`,
                                          font: 'Arial',
                                          size: 18
                                      })
                                  ]
                              }),
                              new Paragraph({
                                  alignment: AlignmentType.LEFT,
                                  children: [
                                      new TextRun({
                                          text: `${this.getValue(data.revision, 'plan_organico_conformidad_no_conformidades_menores_con_hallazgos') === true ? '■' : '❏'} No conformidades menores, se requiere de corrección antes de inspección. Listar los hallazgos abajo.`,
                                          font: 'Arial',
                                          size: 18
                                      })
                                  ]
                              }),
                              new Paragraph({
                                  alignment: AlignmentType.LEFT,
                                  children: [
                                      new TextRun({
                                          text: `${this.getValue(data.revision, 'plan_organico_conformidad_no_conformidades_menores') === true ? '■' : '❏'} No conformidades menores, se puede realizar inspección.`,
                                          font: 'Arial',
                                          size: 18
                                      })
                                  ]
                              }),
                              new Paragraph({
                                  alignment: AlignmentType.LEFT,
                                  children: [
                                      new TextRun({
                                          text: `${this.getValue(data.revision, 'plan_organico_conformidad_conformidad') === true ? '■' : '❏'} Conformidad`,
                                          font: 'Arial',
                                          size: 18
                                      })
                                  ]
                              }),
                              new Paragraph({
                                  alignment: AlignmentType.LEFT,
                                  children: [
                                      new TextRun({
                                          text: `${this.getValue(data.revision, 'plan_organico_conformidad_otro') === true ? `■ Otro: ${this.getValue(data.revision, 'plan_organico_conformidad_otro_text')}` : '❏ Otro:'}`,
                                          font: 'Arial',
                                          size: 18
                                      })
                                  ]
                              })
                          ],
                          verticalAlign: VerticalAlign.CENTER,
                          columnSpan: 1,
                      }),
                  ],
              }),
              new TableRow({
                  children: [
                      new TableCell({
                          children: [
                              new Paragraph({
                                  alignment: AlignmentType.RIGHT,
                                  children: [
                                      new TextRun({
                                          text: 'Hallazgos: ',
                                          font: 'Calibri (Cuerpo)',
                                          size: 22,
                                      })
                                  ],
                                  spacing: { before: 100, after: 200 }
                              })],
                          verticalAlign: VerticalAlign.CENTER,
                      }),
                      new TableCell({
                          children: [
                              new Paragraph({
                                  alignment: AlignmentType.LEFT,
                                  children: [
                                      new TextRun({
                                          text: this.getValue(data.revision, "plan_organico_hallazgos"),
                                          font: 'Calibri (Cuerpo)',
                                          size: 22,
                                      })
                                  ],
                                  spacing: { before: 100, after: 200 }
                              })],
                          verticalAlign: VerticalAlign.CENTER,
                      }),
                  ],
              }),
              new TableRow({
                  children: [
                      new TableCell({
                          children: [
                              new Paragraph({
                                  alignment: AlignmentType.RIGHT,
                                  children: [
                                      new TextRun({
                                          text: 'Recomendaciones para inspector: ',
                                          font: 'Calibri (Cuerpo)',
                                          size: 22,
                                      })
                                  ],
                                  spacing: { before: 100, after: 200 }
                              })],
                          verticalAlign: VerticalAlign.CENTER,
                      }),
                      new TableCell({
                          children: [
                              new Paragraph({
                                  alignment: AlignmentType.LEFT,
                                  children: [
                                      new TextRun({
                                          text: this.getValue(data.revision, "plan_organico_recomendaciones"),
                                          font: 'Calibri (Cuerpo)',
                                          size: 22,
                                      })
                                  ],
                                  spacing: { before: 100, after: 200 }
                              })],
                          verticalAlign: VerticalAlign.CENTER,
                      }),
                  ],
              }),
              new TableRow({
                  children: [
                      new TableCell({
                          children: [
                              new Paragraph({
                                  alignment: AlignmentType.RIGHT,
                                  children: [
                                      new TextRun({
                                          text: 'Comentarios generales: ',
                                          font: 'Calibri (Cuerpo)',
                                          size: 22,
                                      })
                                  ],
                                  spacing: { before: 100, after: 200 }
                              })],
                          verticalAlign: VerticalAlign.CENTER,
                      }),
                      new TableCell({
                          children: [
                              new Paragraph({
                                  alignment: AlignmentType.LEFT,
                                  children: [
                                      new TextRun({
                                          text: this.getValue(data.revision, "plan_organico_comentarios"),
                                          font: 'Calibri (Cuerpo)',
                                          size: 22,
                                      })
                                  ],
                                  spacing: { before: 100, after: 200 }
                              })],
                          verticalAlign: VerticalAlign.CENTER,
                      }),
                  ],
              }),
              new TableRow({
                  children: [
                      new TableCell({
                          children: [
                              new Paragraph({
                                  alignment: AlignmentType.RIGHT,
                                  children: [
                                      new TextRun({
                                          text: `Firma del Revisor:`,
                                          font: 'Calibri (Cuerpo)',
                                          size: 22,
                                      })
                                  ],
                                  spacing: { before: 100, after: 200 }
                              })],
                          verticalAlign: VerticalAlign.CENTER,
                      }),
                      new TableCell({
                          children: [
                              new Paragraph({
                                  alignment: AlignmentType.LEFT,
                                  children: [
                                    signatureImage2 ? Media.addImage(document, signatureImage2.toString(), 200, 100) : new TextRun({
                                      text: ''
                                    })
                                  ],
                                  spacing: { before: 100, after: 200 }
                              })
                          ],
                          verticalAlign: VerticalAlign.CENTER,
                          columnSpan: 1,
                      }),
                  ],
              }),
          ],
          margins: {
              left: 70,
              right: 70
          },
          width: {
              size: 100,
              type: WidthType.PERCENTAGE,
          },
          columnWidths: [2410, 7227]
        })
      ],
      margins: {
        top: 350,
        bottom: 500,
        left: 1000,
        right: 1000
      }
    });

    return document;
  }

  public createFooter(): Table {
    const table = new Table({
      rows: [
        new TableRow({
          children: [
            new TableCell({
              children: [
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                    new TextRun({
                      text: 'Elaboró:',
                      font: 'Calibri (Cuerpo)',
                      size: 22
                    })
                  ]
                })
              ],
              width: {
                size: 33,
                type: WidthType.PERCENTAGE
              }
            }),
            new TableCell({
              children: [
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                    new TextRun({
                      text: 'Revisó:',
                      font: 'Calibri (Cuerpo)',
                      size: 22
                    })
                  ]
                })
              ],
              width: {
                size: 33,
                type: WidthType.PERCENTAGE
              }
            }),
            new TableCell({
              children: [
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                    new TextRun({
                      text: 'Autorizó:',
                      font: 'Calibri (Cuerpo)',
                      size: 22
                    })
                  ]
                }),
              ],
              width: {
                size: 33,
                type: WidthType.PERCENTAGE
              }
            })
          ]
        }),
        new TableRow({
          children: [
            new TableCell({
              children: [
                new Paragraph({
                  alignment: AlignmentType.CENTER,
                  children: [
                    new TextRun({
                      text: 'Director de certificación',
                      font: 'Calibri (Cuerpo)',
                      size: 16,
                      bold: true
                    })
                  ]
                }),

              ],
              width: {
                size: 33,
                type: WidthType.PERCENTAGE
              },
              shading: {
                fill: '#C0C0C0'
              }
            }),
            new TableCell({
              children: [
                new Paragraph({
                  alignment: AlignmentType.CENTER,
                  children: [
                    new TextRun({
                      text: 'Responsable de calidad',
                      font: 'Calibri (Cuerpo)',
                      size: 16,
                      bold: true
                    })
                  ]
                }),

              ],
              width: {
                size: 33,
                type: WidthType.PERCENTAGE
              },
              shading: {
                fill: '#C0C0C0'
              }
            }),
            new TableCell({
              children: [
                new Paragraph({
                  alignment: AlignmentType.CENTER,
                  children: [
                    new TextRun({
                      text: 'Director general',
                      font: 'Calibri (Cuerpo)',
                      size: 16,
                      bold: true
                    })
                  ]
                }),
              ],
              width: {
                size: 33,
                type: WidthType.PERCENTAGE
              },
              shading: {
                fill: '#C0C0C0'
              }
            })
          ]
        })
      ],
      margins: {
        left: 70,
        right: 70
      },
      //9638
      columnWidths: [3213, 3213, 3212],
      alignment: AlignmentType.CENTER
    })
    return table
  }

  public createRegisters_MTO_FSOL_08_2_4(submission): Table {
    const table = new Table({
      rows: [
        new TableRow({
          children: [
            new TableCell({
              children: [
                new Paragraph({
                  alignment: AlignmentType.CENTER,
                  children: [
                    new TextRun({
                      text: 'REGISTROS',
                      font: 'Arial',
                      size: 20,
                      bold: true
                    })
                  ],
                  spacing: {
                    after: 75,
                    before: 75
                  }
                }),
              ],
              verticalAlign: VerticalAlign.CENTER,
              columnSpan: 7,
              shading: {
                fill: '#C0C0C0'
              }
            })
          ]
        }),
        new TableRow({
          children: [
            new TableCell({
              children: [
                new Paragraph({
                  alignment: AlignmentType.CENTER,
                  children: [
                    new TextRun({
                      text: 'No.',
                      font: 'Arial',
                      size: 18,
                      bold: true
                    })
                  ]
                })
              ],
              rowSpan: 2,
              verticalAlign: VerticalAlign.CENTER,
              width: {
                size: 25,
                type: WidthType.PERCENTAGE
              }
            }),
            new TableCell({
              children: [
                new Paragraph({
                  alignment: AlignmentType.CENTER,
                  children: [
                    new TextRun({
                      text: 'Requisitos',
                      font: 'Arial',
                      size: 18,
                      bold: true
                    })
                  ]
                })
              ],
              rowSpan: 2,
              verticalAlign: VerticalAlign.CENTER,
              width: {
                size: 15,
                type: WidthType.PERCENTAGE
              }
            }),
            new TableCell({
              children: [
                new Paragraph({
                  alignment: AlignmentType.CENTER,
                  children: [
                    new TextRun({
                      text: 'Incluyo información',
                      font: 'Arial',
                      size: 18,
                      bold: true
                    })
                  ]
                })
              ],
              rowSpan: 2,
              verticalAlign: VerticalAlign.CENTER,
              width: {
                size: 15,
                type: WidthType.PERCENTAGE
              }
            }),
            new TableCell({
              children: [
                new Paragraph({
                  alignment: AlignmentType.CENTER,
                  children: [
                    new TextRun({
                      text: 'Comentarios',
                      font: 'Arial',
                      size: 18,
                      bold: true
                    })
                  ]
                })
              ],
              rowSpan: 2,
              verticalAlign: VerticalAlign.CENTER,
              width: {
                size: 15,
                type: WidthType.PERCENTAGE
              }
            }),
            new TableCell({
              children: [
                  new Paragraph({
                      alignment: AlignmentType.CENTER,
                      children: [
                          new TextRun({
                              text: 'Conformidad (Evaluada por Inspector)',
                              font: 'Arial',
                              size: 18,
                              bold: true
                          })
                      ]
                  })
              ],
              columnSpan: 3,
              verticalAlign: VerticalAlign.CENTER,
              width: {
                  size: 15,
                  type: WidthType.PERCENTAGE
              }
            })
          ]
        }),
        new TableRow({
          children: [new TableCell({
              children: [
                  new Paragraph({
                      alignment: AlignmentType.CENTER,
                      children: [
                          new TextRun({
                              text: 'Implementación',
                              font: 'Arial',
                              size: 18,
                              bold: true
                          })
                      ]
                  })
              ],
              verticalAlign: VerticalAlign.CENTER
          }),
          new TableCell({
              children: [
                  new Paragraph({
                      alignment: AlignmentType.CENTER,
                      children: [
                          new TextRun({
                              text: 'Conformidad',
                              font: 'Arial',
                              size: 18,
                              bold: true
                          })
                      ]
                  })
              ],
              verticalAlign: VerticalAlign.CENTER,
              columnSpan: 2
          }),]
        }),
        new TableRow({
          children: [
            new TableCell({
              children: [
                new Paragraph({
                  alignment: AlignmentType.CENTER,
                  children: [
                    new TextRun({
                      text: '1',
                      font: 'Arial',
                      size: 18
                    })
                  ]
                })
              ],
              verticalAlign: VerticalAlign.TOP
            }),
            new TableCell({
              children: [
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                    new TextRun({
                      text: 'He incluido una lista completa de las unidades de producción que estoy sometiendo a evaluación para la certificación orgánica. MTO-FSOL-28',
                      font: 'Arial',
                      size: 18
                    })
                  ]
                })
              ],
              verticalAlign: VerticalAlign.TOP
            }),
            new TableCell({
              children: [
                new Paragraph({
                  alignment: AlignmentType.CENTER,
                  children: [
                    new TextRun({
                      text: this.getValue(submission, 'plan_organico_animal_registros_1_declaracion') === 'Si' ? '■ SÍ  ❏ NO' : '❏ SÍ  ■ NO',
                      font: 'Arial',
                      size: 18
                    })
                  ]
                })
              ],
              verticalAlign: VerticalAlign.TOP
            }),
            new TableCell({
              children: [
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                    new TextRun({
                      text: this.getValue(submission, 'plan_organico_animal_registros_1_comentarios'),
                      font: 'Arial',
                      size: 18
                    })
                  ]
                })
              ],
              verticalAlign: VerticalAlign.TOP
            }),
            new TableCell({
              children: [
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                      new TextRun({
                          text: ` ${this.getValue(submission, 'plan_organico_animal_registros_1_implementacion') === 'I' ? '■' : '❏'} I`,
                          font: 'Arial',
                          size: 18
                      })
                  ]
                }),
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                      new TextRun({
                          text: ` ${this.getValue(submission, 'plan_organico_animal_registros_1_implementacion') === 'NI' ? '■' : '❏'} NI`,
                          font: 'Arial',
                          size: 18
                      })
                  ]
                })
              ],
              verticalAlign: VerticalAlign.TOP
          }),
          new TableCell({
              children: [
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                      new TextRun({
                          text: ` ${this.getValue(submission, 'plan_organico_animal_registros_1_conformidad') === 'C' ? '■' : '❏'} C`,
                          font: 'Arial',
                          size: 18
                      })
                  ]
                }),
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                      new TextRun({
                          text: ` ${this.getValue(submission, 'plan_organico_animal_registros_1_conformidad') === 'NC' ? '■' : '❏'} NC`,
                          font: 'Arial',
                          size: 18
                      })
                  ]
                }),
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                      new TextRun({
                          text: ` ${this.getValue(submission, 'plan_organico_animal_registros_1_conformidad') === 'NA' ? '■' : '❏'} NA`,
                          font: 'Arial',
                          size: 18
                      })
                  ]
                })
              ],
              verticalAlign: VerticalAlign.TOP
          }),
          new TableCell({
              children: [
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                      new TextRun({
                          text: this.getValue(submission, 'plan_organico_animal_registros_1_comentarios_inspector'),
                          font: 'Arial',
                          size: 18
                      })
                  ]
                })
              ],
              verticalAlign: VerticalAlign.TOP
          })
          ]
        }),
        new TableRow({
          children: [
            new TableCell({
              children: [
                new Paragraph({
                  alignment: AlignmentType.CENTER,
                  children: [
                    new TextRun({
                      text: '2',
                      font: 'Arial',
                      size: 18
                    })
                  ]
                })
              ],
              verticalAlign: VerticalAlign.TOP
            }),
            new TableCell({
              children: [
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                    new TextRun({
                      text: 'He incluido una lista completa de las sustancias para alimentación, control de enfermedades y tratamientos veterinarios MTO-FSOL-07',
                      font: 'Arial',
                      size: 18
                    })
                  ]
                })
              ],
              verticalAlign: VerticalAlign.TOP
            }),
            new TableCell({
              children: [
                new Paragraph({
                  alignment: AlignmentType.CENTER,
                  children: [
                    new TextRun({
                      text: this.getValue(submission, 'plan_organico_animal_registros_2_declaracion') === 'Si' ? '■ SÍ  ❏ NO' : '❏ SÍ  ■ NO',
                      font: 'Arial',
                      size: 18
                    })
                  ]
                })
              ],
              verticalAlign: VerticalAlign.TOP
            }),
            new TableCell({
              children: [
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                    new TextRun({
                      text: this.getValue(submission, 'plan_organico_animal_registros_2_comentarios'),
                      font: 'Arial',
                      size: 18
                    })
                  ]
                })
              ],
              verticalAlign: VerticalAlign.TOP
            }),
            new TableCell({
              children: [
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                      new TextRun({
                          text: ` ${this.getValue(submission, 'plan_organico_animal_registros_2_implementacion') === 'I' ? '■' : '❏'} I`,
                          font: 'Arial',
                          size: 18
                      })
                  ]
                }),
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                      new TextRun({
                          text: ` ${this.getValue(submission, 'plan_organico_animal_registros_2_implementacion') === 'NI' ? '■' : '❏'} NI`,
                          font: 'Arial',
                          size: 18
                      })
                  ]
                })
              ],
              verticalAlign: VerticalAlign.TOP
          }),
          new TableCell({
              children: [
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                      new TextRun({
                          text: ` ${this.getValue(submission, 'plan_organico_animal_registros_2_conformidad') === 'C' ? '■' : '❏'} C`,
                          font: 'Arial',
                          size: 18
                      })
                  ]
                }),
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                      new TextRun({
                          text: ` ${this.getValue(submission, 'plan_organico_animal_registros_2_conformidad') === 'NC' ? '■' : '❏'} NC`,
                          font: 'Arial',
                          size: 18
                      })
                  ]
                }),
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                      new TextRun({
                          text: ` ${this.getValue(submission, 'plan_organico_animal_registros_2_conformidad') === 'NA' ? '■' : '❏'} NA`,
                          font: 'Arial',
                          size: 18
                      })
                  ]
                })
              ],
              verticalAlign: VerticalAlign.TOP
          }),
          new TableCell({
              children: [
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                      new TextRun({
                          text: this.getValue(submission, 'plan_organico_animal_registros_2_comentarios_inspector'),
                          font: 'Arial',
                          size: 18
                      })
                  ]
                })
              ],
              verticalAlign: VerticalAlign.TOP
          })
          ]
        }),
        new TableRow({
          children: [
            new TableCell({
              children: [
                new Paragraph({
                  alignment: AlignmentType.CENTER,
                  children: [
                    new TextRun({
                      text: '3',
                      font: 'Arial',
                      size: 18
                    })
                  ]
                })
              ],
              verticalAlign: VerticalAlign.TOP
            }),
            new TableCell({
              children: [
                new Table({
                  rows: [
                    new TableRow({
                      children: [
                        new TableCell({
                          children: [
                            new Paragraph({
                              alignment: AlignmentType.LEFT,
                              children: [
                                new TextRun({
                                  text: 'Cuento con las siguientes bitácoras:',
                                  font: 'Arial',
                                  size: 18
                                })
                              ]
                            })
                          ],
                          borders: {
                            left: {
                              style: BorderStyle.NONE,
                              color: "white",
                              size: 0
                            },
                            top: {
                              style: BorderStyle.NONE,
                              color: "white",
                              size: 0
                            },
                            bottom: {
                              style: BorderStyle.NONE,
                              color: "white",
                              size: 0
                            }
                          }
                        }),
                        new TableCell({
                          children: [
                            new Paragraph({
                              alignment: AlignmentType.LEFT,
                              children: [
                                new TextRun({
                                  text: '',
                                  font: 'Arial',
                                  size: 18
                                })
                              ]
                            })
                          ],
                          borders: {
                            right: {
                              style: BorderStyle.NONE,
                              color: "white",
                              size: 0
                            },
                            top: {
                              style: BorderStyle.NONE,
                              color: "white",
                              size: 0
                            },
                            bottom: {
                              style: BorderStyle.NONE,
                              color: "white",
                              size: 0
                            }
                          }
                        })
                      ]
                    }),
                    new TableRow({
                      children: [
                        new TableCell({
                          children: [
                            new Paragraph({
                              alignment: AlignmentType.LEFT,
                              children: [
                                new TextRun({
                                  text: `${this.getValue(submission, 'plan_organico_animal_registros_3_origen_check') ? '■' : '❏'} Origen de animales`,
                                  font: 'Arial',
                                  size: 18
                                })
                              ]
                            })
                          ],
                          borders: {
                            left: {
                              style: BorderStyle.NONE,
                              color: "white",
                              size: 0
                            },
                            top: {
                              style: BorderStyle.NONE,
                              color: "white",
                              size: 0
                            },
                            bottom: {
                              style: BorderStyle.NONE,
                              color: "white",
                              size: 0
                            }
                          }
                        }),
                        new TableCell({
                          children: [
                            new Paragraph({
                              alignment: AlignmentType.CENTER,
                              children: [
                                new TextRun({
                                  text: `${this.getValue(submission, 'plan_organico_animal_registros_3_origen') === "Si" ? '■ Sí ❏ No' : this.getValue(submission, 'plan_organico_animal_registros_3_origen') === "No" ? '❏ Sí ■ No' : '❏ Sí ❏ No'}`,
                                  font: 'Arial',
                                  size: 18
                                })
                              ]
                            })
                          ],
                          borders: {
                            right: {
                              style: BorderStyle.NONE,
                              color: "white",
                              size: 0
                            },
                            top: {
                              style: BorderStyle.NONE,
                              color: "white",
                              size: 0
                            },
                            bottom: {
                              style: BorderStyle.NONE,
                              color: "white",
                              size: 0
                            }
                          }
                        })
                      ]
                    }),
                    new TableRow({
                      children: [
                        new TableCell({
                          children: [
                            new Paragraph({
                              alignment: AlignmentType.LEFT,
                              children: [
                                new TextRun({
                                  text: `${this.getValue(submission, 'plan_organico_animal_registros_3_alimentacion_check') ? '■' : '❏'} Alimentación`,
                                  font: 'Arial',
                                  size: 18
                                })
                              ]
                            })
                          ],
                          borders: {
                            left: {
                              style: BorderStyle.NONE,
                              color: "white",
                              size: 0
                            },
                            top: {
                              style: BorderStyle.NONE,
                              color: "white",
                              size: 0
                            },
                            bottom: {
                              style: BorderStyle.NONE,
                              color: "white",
                              size: 0
                            }
                          }
                        }),
                        new TableCell({
                          children: [
                            new Paragraph({
                              alignment: AlignmentType.CENTER,
                              children: [
                                new TextRun({
                                  text: `${this.getValue(submission, 'plan_organico_animal_registros_3_alimentacion') === "Si" ? '■ Sí ❏ No' : this.getValue(submission, 'plan_organico_animal_registros_3_alimentacion') === "No" ? '❏ Sí ■ No' : '❏ Sí ❏ No'}`,
                                  font: 'Arial',
                                  size: 18
                                })
                              ]
                            })
                          ],
                          borders: {
                            right: {
                              style: BorderStyle.NONE,
                              color: "white",
                              size: 0
                            },
                            top: {
                              style: BorderStyle.NONE,
                              color: "white",
                              size: 0
                            },
                            bottom: {
                              style: BorderStyle.NONE,
                              color: "white",
                              size: 0
                            }
                          }
                        })
                      ]
                    }),
                    new TableRow({
                      children: [
                        new TableCell({
                          children: [
                            new Paragraph({
                              alignment: AlignmentType.LEFT,
                              children: [
                                new TextRun({
                                  text: `${this.getValue(submission, 'plan_organico_animal_registros_3_tratamientos_check') ? '■' : '❏'} Tratamientos sanitarios`,
                                  font: 'Arial',
                                  size: 18
                                })
                              ]
                            })
                          ],
                          borders: {
                            left: {
                              style: BorderStyle.NONE,
                              color: "white",
                              size: 0
                            },
                            top: {
                              style: BorderStyle.NONE,
                              color: "white",
                              size: 0
                            },
                            bottom: {
                              style: BorderStyle.NONE,
                              color: "white",
                              size: 0
                            }
                          }
                        }),
                        new TableCell({
                          children: [
                            new Paragraph({
                              alignment: AlignmentType.CENTER,
                              children: [
                                new TextRun({
                                  text: `${this.getValue(submission, 'plan_organico_animal_registros_3_tratamientos') === "Si" ? '■ Sí ❏ No' : this.getValue(submission, 'plan_organico_animal_registros_3_tratamientos') === "No" ? '❏ Sí ■ No' : '❏ Sí ❏ No'}`,
                                  font: 'Arial',
                                  size: 18
                                })
                              ]
                            })
                          ],
                          borders: {
                            right: {
                              style: BorderStyle.NONE,
                              color: "white",
                              size: 0
                            },
                            top: {
                              style: BorderStyle.NONE,
                              color: "white",
                              size: 0
                            },
                            bottom: {
                              style: BorderStyle.NONE,
                              color: "white",
                              size: 0
                            }
                          }
                        })
                      ]
                    }),
                    new TableRow({
                      children: [
                        new TableCell({
                          children: [
                            new Paragraph({
                              alignment: AlignmentType.LEFT,
                              children: [
                                new TextRun({
                                  text: `${this.getValue(submission, 'plan_organico_animal_registros_3_cosecha_check') ? '■' : '❏'} Cosecha`,
                                  font: 'Arial',
                                  size: 18
                                })
                              ]
                            })
                          ],
                          borders: {
                            left: {
                              style: BorderStyle.NONE,
                              color: "white",
                              size: 0
                            },
                            top: {
                              style: BorderStyle.NONE,
                              color: "white",
                              size: 0
                            },
                            bottom: {
                              style: BorderStyle.NONE,
                              color: "white",
                              size: 0
                            }
                          }
                        }),
                        new TableCell({
                          children: [
                            new Paragraph({
                              alignment: AlignmentType.CENTER,
                              children: [
                                new TextRun({
                                  text: `${this.getValue(submission, 'plan_organico_animal_registros_3_cosecha') === "Si" ? '■ Sí ❏ No' : this.getValue(submission, 'plan_organico_animal_registros_3_cosecha') === "No" ? '❏ Sí ■ No' : '❏ Sí ❏ No'}`,
                                  font: 'Arial',
                                  size: 18
                                })
                              ]
                            })
                          ],
                          borders: {
                            right: {
                              style: BorderStyle.NONE,
                              color: "white",
                              size: 0
                            },
                            top: {
                              style: BorderStyle.NONE,
                              color: "white",
                              size: 0
                            },
                            bottom: {
                              style: BorderStyle.NONE,
                              color: "white",
                              size: 0
                            }
                          }
                        })
                      ]
                    }),
                    new TableRow({
                      children: [
                        new TableCell({
                          children: [
                            new Paragraph({
                              alignment: AlignmentType.LEFT,
                              children: [
                                new TextRun({
                                  text: `${this.getValue(submission, 'plan_organico_animal_registros_3_ventas_check') ? '■' : '❏'} Ventas`,
                                  font: 'Arial',
                                  size: 18
                                })
                              ]
                            })
                          ],
                          borders: {
                            left: {
                              style: BorderStyle.NONE,
                              color: "white",
                              size: 0
                            },
                            top: {
                              style: BorderStyle.NONE,
                              color: "white",
                              size: 0
                            },
                            bottom: {
                              style: BorderStyle.NONE,
                              color: "white",
                              size: 0
                            }
                          }
                        }),
                        new TableCell({
                          children: [
                            new Paragraph({
                              alignment: AlignmentType.CENTER,
                              children: [
                                new TextRun({
                                  text: `${this.getValue(submission, 'plan_organico_animal_registros_3_ventas') === "Si" ? '■ Sí ❏ No' : this.getValue(submission, 'plan_organico_animal_registros_3_ventas') === "No" ? '❏ Sí ■ No' : '❏ Sí ❏ No'}`,
                                  font: 'Arial',
                                  size: 18
                                })
                              ]
                            })
                          ],
                          borders: {
                            right: {
                              style: BorderStyle.NONE,
                              color: "white",
                              size: 0
                            },
                            top: {
                              style: BorderStyle.NONE,
                              color: "white",
                              size: 0
                            },
                            bottom: {
                              style: BorderStyle.NONE,
                              color: "white",
                              size: 0
                            }
                          }
                        })
                      ]
                    }),
                    new TableRow({
                      children: [
                        new TableCell({
                          children: [
                            new Paragraph({
                              alignment: AlignmentType.LEFT,
                              children: [
                                new TextRun({
                                  text: `${this.getValue(submission, 'plan_organico_animal_registros_3_actividades_limpieza_check') ? '■' : '❏'} Actividades de limpieza y desinfección de instalaciones`,
                                  font: 'Arial',
                                  size: 18
                                })
                              ]
                            })
                          ],
                          borders: {
                            left: {
                              style: BorderStyle.NONE,
                              color: "white",
                              size: 0
                            },
                            top: {
                              style: BorderStyle.NONE,
                              color: "white",
                              size: 0
                            },
                            bottom: {
                              style: BorderStyle.NONE,
                              color: "white",
                              size: 0
                            }
                          }
                        }),
                        new TableCell({
                          children: [
                            new Paragraph({
                              alignment: AlignmentType.CENTER,
                              children: [
                                new TextRun({
                                  text: `${this.getValue(submission, 'plan_organico_animal_registros_3_actividades_limpieza') === "Si" ? '■ Sí ❏ No' : this.getValue(submission, 'plan_organico_animal_registros_3_actividades_limpieza') === "No" ? '❏ Sí ■ No' : '❏ Sí ❏ No'}`,
                                  font: 'Arial',
                                  size: 18
                                })
                              ]
                            })
                          ],
                          borders: {
                            right: {
                              style: BorderStyle.NONE,
                              color: "white",
                              size: 0
                            },
                            top: {
                              style: BorderStyle.NONE,
                              color: "white",
                              size: 0
                            },
                            bottom: {
                              style: BorderStyle.NONE,
                              color: "white",
                              size: 0
                            }
                          }
                        })
                      ]
                    }),
                    new TableRow({
                      children: [
                        new TableCell({
                          children: [
                            new Paragraph({
                              alignment: AlignmentType.LEFT,
                              children: [
                                new TextRun({
                                  text: `${this.getValue(submission, 'plan_organico_animal_registros_3_otro_check') ? '■' : '❏'} Otro (¿Cuál?): ${this.getValue(submission, 'plan_organico_animal_registros_3_otro')}`,
                                  font: 'Arial',
                                  size: 18
                                })
                              ]
                            })
                          ],
                          borders: {
                            left: {
                              style: BorderStyle.NONE,
                              color: "white",
                              size: 0
                            },
                            top: {
                              style: BorderStyle.NONE,
                              color: "white",
                              size: 0
                            },
                            bottom: {
                              style: BorderStyle.NONE,
                              color: "white",
                              size: 0
                            }
                          }
                        }),
                        new TableCell({
                          children: [
                            new Paragraph({
                              alignment: AlignmentType.CENTER,
                              children: [
                                new TextRun({
                                  text: `${this.getValue(submission, 'plan_organico_animal_registros_3_otro_declaracion') === "Si" ? '■ Sí ❏ No' : this.getValue(submission, 'plan_organico_animal_registros_3_otro_declaracion') === "No" ? '❏ Sí ■ No' : '❏ Sí ❏ No'}`,
                                  font: 'Arial',
                                  size: 18
                                })
                              ]
                            })
                          ],
                          borders: {
                            right: {
                              style: BorderStyle.NONE,
                              color: "white",
                              size: 0
                            },
                            top: {
                              style: BorderStyle.NONE,
                              color: "white",
                              size: 0
                            },
                            bottom: {
                              style: BorderStyle.NONE,
                              color: "white",
                              size: 0
                            }
                          }
                        })
                      ]
                    })
                  ],
                  //4395
                  columnWidths: [3075, 1320]
                })
              ],
              columnSpan: 2
            }),
            new TableCell({
              children: [
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                    new TextRun({
                      text: this.getValue(submission, 'plan_organico_animal_registros_3_comentarios'),
                      font: 'Arial',
                      size: 18
                    })
                  ]
                })
              ],
              verticalAlign: VerticalAlign.TOP
            }),
            new TableCell({
              children: [
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                      new TextRun({
                          text: ` ${this.getValue(submission, 'plan_organico_animal_registros_3_implementacion') === 'I' ? '■' : '❏'} I`,
                          font: 'Arial',
                          size: 18
                      })
                  ]
                }),
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                      new TextRun({
                          text: ` ${this.getValue(submission, 'plan_organico_animal_registros_3_implementacion') === 'NI' ? '■' : '❏'} NI`,
                          font: 'Arial',
                          size: 18
                      })
                  ]
                })
              ],
              verticalAlign: VerticalAlign.TOP
          }),
          new TableCell({
              children: [
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                      new TextRun({
                          text: ` ${this.getValue(submission, 'plan_organico_animal_registros_3_conformidad') === 'C' ? '■' : '❏'} C`,
                          font: 'Arial',
                          size: 18
                      })
                  ]
                }),
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                      new TextRun({
                          text: ` ${this.getValue(submission, 'plan_organico_animal_registros_3_conformidad') === 'NC' ? '■' : '❏'} NC`,
                          font: 'Arial',
                          size: 18
                      })
                  ]
                }),
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                      new TextRun({
                          text: ` ${this.getValue(submission, 'plan_organico_animal_registros_3_conformidad') === 'NA' ? '■' : '❏'} NA`,
                          font: 'Arial',
                          size: 18
                      })
                  ]
                })
              ],
              verticalAlign: VerticalAlign.TOP
          }),
          new TableCell({
              children: [
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                      new TextRun({
                          text: this.getValue(submission, 'plan_organico_animal_registros_3_comentarios_inspector'),
                          font: 'Arial',
                          size: 18
                      })
                  ]
                })
              ],
              verticalAlign: VerticalAlign.TOP
          })
          ]
        }),
        new TableRow({
          children: [
            new TableCell({
              children: [
                new Paragraph({
                  alignment: AlignmentType.CENTER,
                  children: [
                    new TextRun({
                      text: '4',
                      font: 'Arial',
                      size: 18
                    })
                  ]
                })
              ],
              verticalAlign: VerticalAlign.TOP
            }),
            new TableCell({
              children: [
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                    new TextRun({
                      text: 'He incluido una lista completa de análisis de riesgos para la integridad orgánica de los productos en el formato MTO-FSOL-04-3',
                      font: 'Arial',
                      size: 18
                    })
                  ]
                })
              ],
              verticalAlign: VerticalAlign.TOP
            }),
            new TableCell({
              children: [
                new Paragraph({
                  alignment: AlignmentType.CENTER,
                  children: [
                    new TextRun({
                      text: this.getValue(submission, 'plan_organico_animal_registros_4_declaracion') === 'Si' ? '■ SÍ  ❏ NO' : '❏ SÍ  ■ NO',
                      font: 'Arial',
                      size: 18
                    })
                  ]
                })
              ],
              verticalAlign: VerticalAlign.TOP
            }),
            new TableCell({
              children: [
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                    new TextRun({
                      text: this.getValue(submission, 'plan_organico_animal_registros_4_comentarios'),
                      font: 'Arial',
                      size: 18
                    })
                  ]
                })
              ],
              verticalAlign: VerticalAlign.TOP
            }),
            new TableCell({
              children: [
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                      new TextRun({
                          text: ` ${this.getValue(submission, 'plan_organico_animal_registros_4_implementacion') === 'I' ? '■' : '❏'} I`,
                          font: 'Arial',
                          size: 18
                      })
                  ]
                }),
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                      new TextRun({
                          text: ` ${this.getValue(submission, 'plan_organico_animal_registros_4_implementacion') === 'NI' ? '■' : '❏'} NI`,
                          font: 'Arial',
                          size: 18
                      })
                  ]
                })
              ],
              verticalAlign: VerticalAlign.TOP
          }),
          new TableCell({
              children: [
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                      new TextRun({
                          text: ` ${this.getValue(submission, 'plan_organico_animal_registros_4_conformidad') === 'C' ? '■' : '❏'} C`,
                          font: 'Arial',
                          size: 18
                      })
                  ]
                }),
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                      new TextRun({
                          text: ` ${this.getValue(submission, 'plan_organico_animal_registros_4_conformidad') === 'NC' ? '■' : '❏'} NC`,
                          font: 'Arial',
                          size: 18
                      })
                  ]
                }),
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                      new TextRun({
                          text: ` ${this.getValue(submission, 'plan_organico_animal_registros_4_conformidad') === 'NA' ? '■' : '❏'} NA`,
                          font: 'Arial',
                          size: 18
                      })
                  ]
                })
              ],
              verticalAlign: VerticalAlign.TOP
          }),
          new TableCell({
              children: [
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                      new TextRun({
                          text: this.getValue(submission, 'plan_organico_animal_registros_4_comentarios_inspector'),
                          font: 'Arial',
                          size: 18
                      })
                  ]
                })
              ],
              verticalAlign: VerticalAlign.TOP
          })
          ]
        }),
        new TableRow({
          children: [
            new TableCell({
              children: [
                new Paragraph({
                  alignment: AlignmentType.CENTER,
                  children: [
                    new TextRun({
                      text: '5',
                      font: 'Arial',
                      size: 18
                    })
                  ]
                })
              ],
              verticalAlign: VerticalAlign.TOP
            }),
            new TableCell({
              children: [
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                    new TextRun({
                      text: 'He incluido diagrama de flujo de producción, desde obtención de cría hasta venta de producto',
                      font: 'Arial',
                      size: 18
                    })
                  ]
                })
              ],
              verticalAlign: VerticalAlign.TOP
            }),
            new TableCell({
              children: [
                new Paragraph({
                  alignment: AlignmentType.CENTER,
                  children: [
                    new TextRun({
                      text: this.getValue(submission, 'plan_organico_animal_registros_5_declaracion') === 'Si' ? '■ SÍ  ❏ NO' : '❏ SÍ  ■ NO',
                      font: 'Arial',
                      size: 18
                    })
                  ]
                })
              ],
              verticalAlign: VerticalAlign.TOP
            }),
            new TableCell({
              children: [
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                    new TextRun({
                      text: this.getValue(submission, 'plan_organico_animal_registros_5_comentarios'),
                      font: 'Arial',
                      size: 18
                    })
                  ]
                })
              ],
              verticalAlign: VerticalAlign.TOP
            }),
            new TableCell({
              children: [
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                      new TextRun({
                          text: ` ${this.getValue(submission, 'plan_organico_animal_registros_5_implementacion') === 'I' ? '■' : '❏'} I`,
                          font: 'Arial',
                          size: 18
                      })
                  ]
                }),
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                      new TextRun({
                          text: ` ${this.getValue(submission, 'plan_organico_animal_registros_5_implementacion') === 'NI' ? '■' : '❏'} NI`,
                          font: 'Arial',
                          size: 18
                      })
                  ]
                })
              ],
              verticalAlign: VerticalAlign.TOP
          }),
          new TableCell({
              children: [
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                      new TextRun({
                          text: ` ${this.getValue(submission, 'plan_organico_animal_registros_5_conformidad') === 'C' ? '■' : '❏'} C`,
                          font: 'Arial',
                          size: 18
                      })
                  ]
                }),
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                      new TextRun({
                          text: ` ${this.getValue(submission, 'plan_organico_animal_registros_5_conformidad') === 'NC' ? '■' : '❏'} NC`,
                          font: 'Arial',
                          size: 18
                      })
                  ]
                }),
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                      new TextRun({
                          text: ` ${this.getValue(submission, 'plan_organico_animal_registros_5_conformidad') === 'NA' ? '■' : '❏'} NA`,
                          font: 'Arial',
                          size: 18
                      })
                  ]
                })
              ],
              verticalAlign: VerticalAlign.TOP
          }),
          new TableCell({
              children: [
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                      new TextRun({
                          text: this.getValue(submission, 'plan_organico_animal_registros_5_comentarios_inspector'),
                          font: 'Arial',
                          size: 18
                      })
                  ]
                })
              ],
              verticalAlign: VerticalAlign.TOP
          })
          ]
        })
      ],
      margins: {
        left: 70,
        right: 70
      },
      //9885
      columnWidths: [450, 3050, 1345, 1545, 1150, 800, 1545]
    })
    return table
  }

  public createHandling_MTO_FSOL_08_2_4(submission): Table {
    const table = new Table({
      rows: [
        new TableRow({
          children: [
            new TableCell({
              children: [
                new Paragraph({
                  alignment: AlignmentType.CENTER,
                  children: [
                    new TextRun({
                      text: 'MANEJO',
                      font: 'Arial',
                      size: 20,
                      bold: true
                    })
                  ],
                  spacing: {
                    after: 75,
                    before: 75
                  }
                }),
              ],
              verticalAlign: VerticalAlign.CENTER,
              columnSpan: 6,
              shading: {
                fill: '#C0C0C0'
              }
            })
          ]
        }),
        new TableRow({
          children: [
            new TableCell({
              children: [
                new Paragraph({
                  alignment: AlignmentType.CENTER,
                  children: [
                    new TextRun({
                      text: 'No.',
                      font: 'Arial',
                      size: 18,
                      bold: true
                    })
                  ]
                })
              ],
              rowSpan: 2,
              columnSpan: 1,
              verticalAlign: VerticalAlign.CENTER,
              width: {
                size: 25,
                type: WidthType.PERCENTAGE
              }
            }),
            new TableCell({
              children: [
                new Paragraph({
                  alignment: AlignmentType.CENTER,
                  children: [
                    new TextRun({
                      text: 'Requisitos',
                      font: 'Arial',
                      size: 18,
                      bold: true
                    })
                  ]
                })
              ],
              rowSpan: 2,
              columnSpan: 1,
              verticalAlign: VerticalAlign.CENTER,
              width: {
                size: 15,
                type: WidthType.PERCENTAGE
              }
            }),
            new TableCell({
              children: [
                new Paragraph({
                  alignment: AlignmentType.CENTER,
                  children: [
                    new TextRun({
                      text: 'Comentarios',
                      font: 'Arial',
                      size: 18,
                      bold: true
                    })
                  ]
                })
              ],
              rowSpan: 2,
              columnSpan: 1,
              verticalAlign: VerticalAlign.CENTER,
              width: {
                size: 15,
                type: WidthType.PERCENTAGE
              }
            }),
            new TableCell({
              children: [
                  new Paragraph({
                      alignment: AlignmentType.CENTER,
                      children: [
                          new TextRun({
                              text: 'Conformidad (Evaluada por Inspector)',
                              font: 'Arial',
                              size: 18,
                              bold: true
                          })
                      ]
                  })
              ],
              columnSpan: 3,
              verticalAlign: VerticalAlign.CENTER,
              width: {
                  size: 15,
                  type: WidthType.PERCENTAGE
              }
          })
          ]
        }),
        new TableRow({
          children: [new TableCell({
              children: [
                  new Paragraph({
                      alignment: AlignmentType.CENTER,
                      children: [
                          new TextRun({
                              text: 'Implementación',
                              font: 'Arial',
                              size: 18,
                              bold: true
                          })
                      ]
                  })
              ],
              verticalAlign: VerticalAlign.CENTER
          }),
          new TableCell({
              children: [
                  new Paragraph({
                      alignment: AlignmentType.CENTER,
                      children: [
                          new TextRun({
                              text: 'Conformidad',
                              font: 'Arial',
                              size: 18,
                              bold: true
                          })
                      ]
                  })
              ],
              verticalAlign: VerticalAlign.CENTER,
              columnSpan: 2
          }),]
        }),
        new TableRow({
          children: [
            new TableCell({
              children: [
                new Paragraph({
                  alignment: AlignmentType.CENTER,
                  children: [
                    new TextRun({
                      text: '1',
                      font: 'Arial',
                      size: 18
                    })
                  ]
                })
              ],
              verticalAlign: VerticalAlign.TOP
            }),
            new TableCell({
              children: [
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                    new TextRun({
                      text: 'Por favor describa las instalaciones utilizadas',
                      font: 'Arial',
                      size: 18
                    })
                  ]
                }),
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                    new TextRun({
                      text: (this.getValue(submission, 'plan_organico_animal_manejo_1_adaptacion') ? '■' : '❏') + '  Adaptación de ambientes naturales',
                      font: 'Arial',
                      size: 18
                    })
                  ]
                }),
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                    new TextRun({
                      text: (this.getValue(submission, "plan_organico_animal_manejo_1_recipientes") ? '■' : '❏') + '  Recipientes artificiales en espacios cerrados ',
                      font: 'Arial',
                      size: 18
                    })
                  ]
                }),
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                    new TextRun({
                      text: (this.getValue(submission, "plan_organico_animal_manejo_1_otro_texto") ? '■' : '❏') + '  Otro (¿Cuál?): ' + this.getValue(submission, "plan_organico_animal_manejo_1_otro"),
                      font: 'Arial',
                      size: 18
                    })
                  ]
                })
              ],
              verticalAlign: VerticalAlign.TOP
            }),
            new TableCell({
              children: [
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                    new TextRun({
                      text: this.getValue(submission, "plan_organico_animal_manejo_1_comentarios"),
                      font: 'Arial',
                      size: 18
                    })
                  ]
                })
              ],
              verticalAlign: VerticalAlign.TOP
            }),
            new TableCell({
              children: [
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                      new TextRun({
                          text: ` ${this.getValue(submission, 'plan_organico_animal_manejo_1_implementacion') === 'I' ? '■' : '❏'} I`,
                          font: 'Arial',
                          size: 18
                      })
                  ]
                }),
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                      new TextRun({
                          text: ` ${this.getValue(submission, 'plan_organico_animal_manejo_1_implementacion') === 'NI' ? '■' : '❏'} NI`,
                          font: 'Arial',
                          size: 18
                      })
                  ]
                })
              ],
              verticalAlign: VerticalAlign.TOP
          }),
          new TableCell({
              children: [
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                      new TextRun({
                          text: ` ${this.getValue(submission, 'plan_organico_animal_manejo_1_conformidad') === 'C' ? '■' : '❏'} C`,
                          font: 'Arial',
                          size: 18
                      })
                  ]
                }),
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                      new TextRun({
                          text: ` ${this.getValue(submission, 'plan_organico_animal_manejo_1_conformidad') === 'NC' ? '■' : '❏'} NC`,
                          font: 'Arial',
                          size: 18
                      })
                  ]
                }),
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                      new TextRun({
                          text: ` ${this.getValue(submission, 'plan_organico_animal_manejo_1_conformidad') === 'NA' ? '■' : '❏'} NA`,
                          font: 'Arial',
                          size: 18
                      })
                  ]
                })
              ],
              verticalAlign: VerticalAlign.TOP
          }),
          new TableCell({
              children: [
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                      new TextRun({
                          text: this.getValue(submission, 'plan_organico_animal_manejo_1_comentarios_inspector'),
                          font: 'Arial',
                          size: 18
                      })
                  ]
                })
              ],
              verticalAlign: VerticalAlign.TOP
          })
          ]
        }),
        new TableRow({
          children: [
            new TableCell({
              children: [
                new Paragraph({
                  alignment: AlignmentType.CENTER,
                  children: [
                    new TextRun({
                      text: '2',
                      font: 'Arial',
                      size: 18
                    })
                  ]
                })
              ],
              verticalAlign: VerticalAlign.TOP
            }),
            new TableCell({
              children: [
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                    new TextRun({
                      text: '¿Qué tipo de energía utiliza en el manejo y producción del sistema?:',
                      font: 'Arial',
                      size: 18
                    })
                  ]
                }),
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                    new TextRun({
                      text: (this.getValue(submission, 'plan_organico_animal_manejo_2_renovable') ? '■' : '❏') + ' Renovable',
                      font: 'Arial',
                      size: 18
                    })
                  ]
                }),
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                    new TextRun({
                      text: (this.getValue(submission, "plan_organico_animal_manejo_2_no_renovable") ? '■' : '❏') + ' No renovable',
                      font: 'Arial',
                      size: 18
                    })
                  ]
                }),
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                    new TextRun({
                      text: (this.getValue(submission, "plan_organico_animal_manejo_2_combinado") ? '■' : '❏') + ' Renovable/no renovable (combinación)',
                      font: 'Arial',
                      size: 18
                    })
                  ]
                }),
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                    new TextRun({
                      text: (this.getValue(submission, "plan_organico_animal_manejo_2_otro_text") ? '■' : '❏') + '  Otro (¿Cuál?): '
                        + this.getValue(submission, "plan_organico_animal_manejo_2_otro"),
                      font: 'Arial',
                      size: 18
                    })
                  ]
                })
              ],
              verticalAlign: VerticalAlign.TOP
            }),
            new TableCell({
              children: [
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                    new TextRun({
                      text: this.getValue(submission, "plan_organico_animal_manejo_2_comentarios"),
                      font: 'Arial',
                      size: 18
                    })
                  ]
                })
              ],
              verticalAlign: VerticalAlign.TOP
            }),
            new TableCell({
              children: [
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                      new TextRun({
                          text: ` ${this.getValue(submission, 'plan_organico_animal_manejo_2_implementacion') === 'I' ? '■' : '❏'} I`,
                          font: 'Arial',
                          size: 18
                      })
                  ]
                }),
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                      new TextRun({
                          text: ` ${this.getValue(submission, 'plan_organico_animal_manejo_2_implementacion') === 'NI' ? '■' : '❏'} NI`,
                          font: 'Arial',
                          size: 18
                      })
                  ]
                })
              ],
              verticalAlign: VerticalAlign.TOP
          }),
          new TableCell({
              children: [
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                      new TextRun({
                          text: ` ${this.getValue(submission, 'plan_organico_animal_manejo_2_conformidad') === 'C' ? '■' : '❏'} C`,
                          font: 'Arial',
                          size: 18
                      })
                  ]
                }),
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                      new TextRun({
                          text: ` ${this.getValue(submission, 'plan_organico_animal_manejo_2_conformidad') === 'NC' ? '■' : '❏'} NC`,
                          font: 'Arial',
                          size: 18
                      })
                  ]
                }),
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                      new TextRun({
                          text: ` ${this.getValue(submission, 'plan_organico_animal_manejo_2_conformidad') === 'NA' ? '■' : '❏'} NA`,
                          font: 'Arial',
                          size: 18
                      })
                  ]
                })
              ],
              verticalAlign: VerticalAlign.TOP
          }),
          new TableCell({
              children: [
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                      new TextRun({
                          text: this.getValue(submission, 'plan_organico_animal_manejo_2_comentarios_inspector'),
                          font: 'Arial',
                          size: 18
                      })
                  ]
                })
              ],
              verticalAlign: VerticalAlign.TOP
          })
          ]
        }),
        new TableRow({
          children: [
            new TableCell({
              children: [
                new Paragraph({
                  alignment: AlignmentType.CENTER,
                  children: [
                    new TextRun({
                      text: '3',
                      font: 'Arial',
                      size: 18
                    })
                  ]
                })
              ],
              verticalAlign: VerticalAlign.TOP
            }),
            new TableCell({
              children: [
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                    new TextRun({
                      text: '¿Qué destino tiene el agua de desecho?',
                      font: 'Arial',
                      size: 18
                    })
                  ]
                }),
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                    new TextRun({
                      text: (this.getValue(submission, "plan_organico_animal_manejo_3_reutiliza_mismo_sistema") ? '■' : '❏') + '  Se reutiliza en el mismo sistema',
                      font: 'Arial',
                      size: 18
                    })
                  ]
                }),
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                    new TextRun({
                      text: (this.getValue(submission, "plan_organico_animal_manejo_3_reutiliza_otro_sistema") ? '■' : '❏') + '  Se reutiliza en otro sistema productivo',
                      font: 'Arial',
                      size: 18
                    })
                  ]
                }),
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                    new TextRun({
                      text: (this.getValue(submission, "plan_organico_animal_manejo_3_tratamiento") ? '■' : '❏') + '  Se le da tratamiento y se regresa a la fuente natural',
                      font: 'Arial',
                      size: 18
                    })
                  ]
                }),
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                    new TextRun({
                      text: (this.getValue(submission, "plan_organico_animal_manejo_3_reintegra_sin_tratamiento") ? '■' : '❏') + '  Se reintegra a la fuente natural sin tratamiento',
                      font: 'Arial',
                      size: 18
                    })
                  ]
                }),
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                    new TextRun({
                      text: (this.getValue(submission, "plan_organico_animal_manejo_3_otro") ? '■' : '❏') + ' Otro (¿Cuál?): ' + this.getValue(submission, "plan_organico_animal_manejo_3_otro_text"),
                      font: 'Arial',
                      size: 18
                    })
                  ]
                }),
              ],
              verticalAlign: VerticalAlign.TOP
            }),
            new TableCell({
              children: [
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                    new TextRun({
                      text: this.getValue(submission, "plan_organico_animal_manejo_3_comentarios"),
                      font: 'Arial',
                      size: 18
                    })
                  ]
                })
              ],
              verticalAlign: VerticalAlign.TOP
            }),
            new TableCell({
              children: [
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                      new TextRun({
                          text: ` ${this.getValue(submission, 'plan_organico_animal_manejo_3_implementacion') === 'I' ? '■' : '❏'} I`,
                          font: 'Arial',
                          size: 18
                      })
                  ]
                }),
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                      new TextRun({
                          text: ` ${this.getValue(submission, 'plan_organico_animal_manejo_3_implementacion') === 'NI' ? '■' : '❏'} NI`,
                          font: 'Arial',
                          size: 18
                      })
                  ]
                })
              ],
              verticalAlign: VerticalAlign.TOP
          }),
          new TableCell({
              children: [
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                      new TextRun({
                          text: ` ${this.getValue(submission, 'plan_organico_animal_manejo_3_conformidad') === 'C' ? '■' : '❏'} C`,
                          font: 'Arial',
                          size: 18
                      })
                  ]
                }),
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                      new TextRun({
                          text: ` ${this.getValue(submission, 'plan_organico_animal_manejo_3_conformidad') === 'NC' ? '■' : '❏'} NC`,
                          font: 'Arial',
                          size: 18
                      })
                  ]
                }),
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                      new TextRun({
                          text: ` ${this.getValue(submission, 'plan_organico_animal_manejo_3_conformidad') === 'NA' ? '■' : '❏'} NA`,
                          font: 'Arial',
                          size: 18
                      })
                  ]
                })
              ],
              verticalAlign: VerticalAlign.TOP
          }),
          new TableCell({
              children: [
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                      new TextRun({
                          text: this.getValue(submission, 'plan_organico_animal_manejo_3_comentarios_inspector'),
                          font: 'Arial',
                          size: 18
                      })
                  ]
                })
              ],
              verticalAlign: VerticalAlign.TOP
          })
          ]
        }),
        new TableRow({
          children: [
            new TableCell({
              children: [
                new Paragraph({
                  alignment: AlignmentType.CENTER,
                  children: [
                    new TextRun({
                      text: '4',
                      font: 'Arial',
                      size: 18
                    })
                  ]
                })
              ],
              verticalAlign: VerticalAlign.TOP
            }),
            new TableCell({
              children: [
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                    new TextRun({
                      text: '¿Cuáles son los principales residuos del sistema de producción?',
                      font: 'Arial',
                      size: 18
                    })
                  ]
                }),
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                    new TextRun({
                      text: `R: ${this.getValue(submission, 'plan_organico_animal_manejo_4_respuesta')}`,
                      font: 'Arial',
                      size: 18
                    })
                  ]
                })
              ],
              verticalAlign: VerticalAlign.TOP
            }),
            new TableCell({
              children: [
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                    new TextRun({
                      text: this.getValue(submission, "plan_organico_animal_manejo_4_comentarios"),
                      font: 'Arial',
                      size: 18
                    })
                  ]
                })
              ],
              verticalAlign: VerticalAlign.TOP
            }),
            new TableCell({
              children: [
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                      new TextRun({
                          text: ` ${this.getValue(submission, 'plan_organico_animal_manejo_4_implementacion') === 'I' ? '■' : '❏'} I`,
                          font: 'Arial',
                          size: 18
                      })
                  ]
                }),
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                      new TextRun({
                          text: ` ${this.getValue(submission, 'plan_organico_animal_manejo_4_implementacion') === 'NI' ? '■' : '❏'} NI`,
                          font: 'Arial',
                          size: 18
                      })
                  ]
                })
              ],
              verticalAlign: VerticalAlign.TOP
          }),
          new TableCell({
              children: [
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                      new TextRun({
                          text: ` ${this.getValue(submission, 'plan_organico_animal_manejo_4_conformidad') === 'C' ? '■' : '❏'} C`,
                          font: 'Arial',
                          size: 18
                      })
                  ]
                }),
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                      new TextRun({
                          text: ` ${this.getValue(submission, 'plan_organico_animal_manejo_4_conformidad') === 'NC' ? '■' : '❏'} NC`,
                          font: 'Arial',
                          size: 18
                      })
                  ]
                }),
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                      new TextRun({
                          text: ` ${this.getValue(submission, 'plan_organico_animal_manejo_4_conformidad') === 'NA' ? '■' : '❏'} NA`,
                          font: 'Arial',
                          size: 18
                      })
                  ]
                })
              ],
              verticalAlign: VerticalAlign.TOP
          }),
          new TableCell({
              children: [
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                      new TextRun({
                          text: this.getValue(submission, 'plan_organico_animal_manejo_4_comentarios_inspector'),
                          font: 'Arial',
                          size: 18
                      })
                  ]
                })
              ],
              verticalAlign: VerticalAlign.TOP
          })
          ]
        }),
        new TableRow({
          children: [
            new TableCell({
              children: [
                new Paragraph({
                  alignment: AlignmentType.CENTER,
                  children: [
                    new TextRun({
                      text: '5',
                      font: 'Arial',
                      size: 18
                    })
                  ]
                })
              ],
              verticalAlign: VerticalAlign.TOP
            }),
            new TableCell({
              children: [
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                    new TextRun({
                      text: 'Antes de ser usada su instalación para la producción orgánica, ¿qué proceso de vaciado limpieza se implementó?',
                      font: 'Arial',
                      size: 18
                    })
                  ]
                }),
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                    new TextRun({
                      text: (this.getValue(submission, "plan_organico_animal_manejo_5_no_vacio") ? '■' : '❏') + ' No se vació',
                      font: 'Arial',
                      size: 18
                    })
                  ]
                }),
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                    new TextRun({
                      text: (this.getValue(submission, "plan_organico_animal_manejo_5_si_vacio") ? '■' : '❏') + ' Si se vació',
                      font: 'Arial',
                      size: 18
                    })
                  ]
                }),
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                    new TextRun({
                      text: (this.getValue(submission, "plan_organico_animal_manejo_5_ninguna") ? '■' : '❏') + ' Ninguna, se trata de aguas abiertas',
                      font: 'Arial',
                      size: 18
                    })
                  ]
                }),
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                    new TextRun({
                      text: (this.getValue(submission, "plan_organico_animal_manejo_5_otro") ? '■' : '❏') + ' Otro: ' + this.getValue(submission, "plan_organico_animal_manejo_5_otro_text"),
                      font: 'Arial',
                      size: 18
                    })
                  ]
                })
              ],
              verticalAlign: VerticalAlign.TOP
            }),
            new TableCell({
              children: [
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                    new TextRun({
                      text: this.getValue(submission, "plan_organico_animal_manejo_5_comentarios"),
                      font: 'Arial',
                      size: 18
                    })
                  ]
                })
              ],
              verticalAlign: VerticalAlign.TOP
            }),
            new TableCell({
              children: [
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                      new TextRun({
                          text: ` ${this.getValue(submission, 'plan_organico_animal_manejo_5_implementacion') === 'I' ? '■' : '❏'} I`,
                          font: 'Arial',
                          size: 18
                      })
                  ]
                }),
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                      new TextRun({
                          text: ` ${this.getValue(submission, 'plan_organico_animal_manejo_5_implementacion') === 'NI' ? '■' : '❏'} NI`,
                          font: 'Arial',
                          size: 18
                      })
                  ]
                })
              ],
              verticalAlign: VerticalAlign.TOP
          }),
          new TableCell({
              children: [
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                      new TextRun({
                          text: ` ${this.getValue(submission, 'plan_organico_animal_manejo_5_conformidad') === 'C' ? '■' : '❏'} C`,
                          font: 'Arial',
                          size: 18
                      })
                  ]
                }),
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                      new TextRun({
                          text: ` ${this.getValue(submission, 'plan_organico_animal_manejo_5_conformidad') === 'NC' ? '■' : '❏'} NC`,
                          font: 'Arial',
                          size: 18
                      })
                  ]
                }),
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                      new TextRun({
                          text: ` ${this.getValue(submission, 'plan_organico_animal_manejo_5_conformidad') === 'NA' ? '■' : '❏'} NA`,
                          font: 'Arial',
                          size: 18
                      })
                  ]
                })
              ],
              verticalAlign: VerticalAlign.TOP
          }),
          new TableCell({
              children: [
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                      new TextRun({
                          text: this.getValue(submission, 'plan_organico_animal_manejo_5_comentarios_inspector'),
                          font: 'Arial',
                          size: 18
                      })
                  ]
                })
              ],
              verticalAlign: VerticalAlign.TOP
          })
          ]
        }),
        new TableRow({
          children: [
            new TableCell({
              children: [
                new Paragraph({
                  alignment: AlignmentType.CENTER,
                  children: [
                    new TextRun({
                      text: '6',
                      font: 'Arial',
                      size: 18
                    })
                  ]
                })
              ],
              verticalAlign: VerticalAlign.TOP
            }),
            new TableCell({
              children: [
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                    new TextRun({
                      text: '¿Qué tipo de especie y variedad se utiliza?',
                      font: 'Arial',
                      size: 18
                    })
                  ]
                }),
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                    new TextRun({
                      text: (this.getValue(submission, "plan_organico_animal_manejo_6_nativas") ? '■' : '❏') + ' Nativas',
                      font: 'Arial',
                      size: 18
                    })
                  ]
                }),
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                    new TextRun({
                      text: (this.getValue(submission, "plan_organico_animal_manejo_6_entornos_similares") ? '■' : '❏') + ' Seleccionadas de entornos similares',
                      font: 'Arial',
                      size: 18
                    })
                  ]
                }),
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                    new TextRun({
                      text: (this.getValue(submission, "plan_organico_animal_manejo_6_produccion_laboratorio") ? '■' : '❏') + ' Producidos en laboratorio',
                      font: 'Arial',
                      size: 18
                    })
                  ]
                }),
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                    new TextRun({
                      text: (this.getValue(submission, "plan_organico_animal_manejo_6_otro") ? '■' : '❏') + ' Otro (¿Cuál?):  ' + this.getValue(submission, "plan_organico_animal_manejo_6_otro_text"),
                      font: 'Arial',
                      size: 18
                    })
                  ]
                })
              ],
              verticalAlign: VerticalAlign.TOP
            }),
            new TableCell({
              children: [
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                    new TextRun({
                      text: this.getValue(submission, "plan_organico_animal_manejo_6_comentarios"),
                      font: 'Arial',
                      size: 18
                    })
                  ]
                })
              ],
              verticalAlign: VerticalAlign.TOP
            }),
            new TableCell({
              children: [
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                      new TextRun({
                          text: ` ${this.getValue(submission, 'plan_organico_animal_manejo_6_implementacion') === 'I' ? '■' : '❏'} I`,
                          font: 'Arial',
                          size: 18
                      })
                  ]
                }),
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                      new TextRun({
                          text: ` ${this.getValue(submission, 'plan_organico_animal_manejo_6_implementacion') === 'NI' ? '■' : '❏'} NI`,
                          font: 'Arial',
                          size: 18
                      })
                  ]
                })
              ],
              verticalAlign: VerticalAlign.TOP
          }),
          new TableCell({
              children: [
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                      new TextRun({
                          text: ` ${this.getValue(submission, 'plan_organico_animal_manejo_6_conformidad') === 'C' ? '■' : '❏'} C`,
                          font: 'Arial',
                          size: 18
                      })
                  ]
                }),
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                      new TextRun({
                          text: ` ${this.getValue(submission, 'plan_organico_animal_manejo_6_conformidad') === 'NC' ? '■' : '❏'} NC`,
                          font: 'Arial',
                          size: 18
                      })
                  ]
                }),
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                      new TextRun({
                          text: ` ${this.getValue(submission, 'plan_organico_animal_manejo_6_conformidad') === 'NA' ? '■' : '❏'} NA`,
                          font: 'Arial',
                          size: 18
                      })
                  ]
                })
              ],
              verticalAlign: VerticalAlign.TOP
          }),
          new TableCell({
              children: [
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                      new TextRun({
                          text: this.getValue(submission, 'plan_organico_animal_manejo_6_comentarios_inspector'),
                          font: 'Arial',
                          size: 18
                      })
                  ]
                })
              ],
              verticalAlign: VerticalAlign.TOP
          })
          ]
        }),
        new TableRow({
          children: [
            new TableCell({
              children: [
                new Paragraph({
                  alignment: AlignmentType.CENTER,
                  children: [
                    new TextRun({
                      text: '7',
                      font: 'Arial',
                      size: 18
                    })
                  ]
                })
              ],
              verticalAlign: VerticalAlign.TOP
            }),
            new TableCell({
              children: [
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                    new TextRun({
                      text: '¿Cuál es el origen de los reproductores, larvas o alevines?',
                      font: 'Arial',
                      size: 18
                    })
                  ]
                }),
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                    new TextRun({
                      text: (this.getValue(submission, "plan_organico_animal_manejo_7_organico") ? '■' : '❏') + ' Orgánico',
                      font: 'Arial',
                      size: 18
                    })
                  ]
                }),
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                    new TextRun({
                      text: (this.getValue(submission, "plan_organico_animal_manejo_7_no_organico") ? '■' : '❏') + ' No orgánico',
                      font: 'Arial',
                      size: 18
                    })
                  ]
                }),
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                    new TextRun({
                      text: (this.getValue(submission, "plan_organico_animal_manejo_7_otro") ? '■' : '❏') + ' Otro (¿Cuál?): ' + this.getValue(submission, "plan_organico_animal_manejo_7_otro_text"),
                      font: 'Arial',
                      size: 18
                    })
                  ]
                })
              ],
              verticalAlign: VerticalAlign.TOP
            }),
            new TableCell({
              children: [
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                    new TextRun({
                      text: this.getValue(submission, "plan_organico_animal_manejo_7_comentarios"),
                      font: 'Arial',
                      size: 18
                    })
                  ]
                })
              ],
              verticalAlign: VerticalAlign.TOP
            }),
            new TableCell({
              children: [
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                      new TextRun({
                          text: ` ${this.getValue(submission, 'plan_organico_animal_manejo_7_implementacion') === 'I' ? '■' : '❏'} I`,
                          font: 'Arial',
                          size: 18
                      })
                  ]
                }),
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                      new TextRun({
                          text: ` ${this.getValue(submission, 'plan_organico_animal_manejo_7_implementacion') === 'NI' ? '■' : '❏'} NI`,
                          font: 'Arial',
                          size: 18
                      })
                  ]
                })
              ],
              verticalAlign: VerticalAlign.TOP
          }),
          new TableCell({
              children: [
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                      new TextRun({
                          text: ` ${this.getValue(submission, 'plan_organico_animal_manejo_7_conformidad') === 'C' ? '■' : '❏'} C`,
                          font: 'Arial',
                          size: 18
                      })
                  ]
                }),
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                      new TextRun({
                          text: ` ${this.getValue(submission, 'plan_organico_animal_manejo_7_conformidad') === 'NC' ? '■' : '❏'} NC`,
                          font: 'Arial',
                          size: 18
                      })
                  ]
                }),
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                      new TextRun({
                          text: ` ${this.getValue(submission, 'plan_organico_animal_manejo_7_conformidad') === 'NA' ? '■' : '❏'} NA`,
                          font: 'Arial',
                          size: 18
                      })
                  ]
                })
              ],
              verticalAlign: VerticalAlign.TOP
          }),
          new TableCell({
              children: [
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                      new TextRun({
                          text: this.getValue(submission, 'plan_organico_animal_manejo_7_comentarios_inspector'),
                          font: 'Arial',
                          size: 18
                      })
                  ]
                })
              ],
              verticalAlign: VerticalAlign.TOP
          })
          ]
        }),
        new TableRow({
          children: [
            new TableCell({
              children: [
                new Paragraph({
                  alignment: AlignmentType.CENTER,
                  children: [
                    new TextRun({
                      text: '8',
                      font: 'Arial',
                      size: 18
                    })
                  ]
                })
              ],
              verticalAlign: VerticalAlign.TOP
            }),
            new TableCell({
              children: [
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                    new TextRun({
                      text: 'Si se inició con reproductores no ecológicos, antes de iniciar la reproducción ¿cuántos meses se manejaron de manera orgánica?',
                      font: 'Arial',
                      size: 18
                    })
                  ]
                }),
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                    new TextRun({
                      text: (this.getValue(submission, "plan_organico_animal_manejo_8_mas_3_meses") ? '■' : '❏') + ' 3 meses o más',
                      font: 'Arial',
                      size: 18
                    })
                  ]
                }),
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                    new TextRun({
                      text: (this.getValue(submission, "plan_organico_animal_manejo_8_menos_3_meses") ? '■' : '❏') + ' Menos de 3 meses',
                      font: 'Arial',
                      size: 18
                    })
                  ]
                })
              ],
              verticalAlign: VerticalAlign.TOP
            }),
            new TableCell({
              children: [
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                    new TextRun({
                      text: this.getValue(submission, "plan_organico_animal_manejo_8_comentarios"),
                      font: 'Arial',
                      size: 18
                    })
                  ]
                })
              ],
              verticalAlign: VerticalAlign.TOP
            }),
            new TableCell({
              children: [
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                      new TextRun({
                          text: ` ${this.getValue(submission, 'plan_organico_animal_manejo_8_implementacion') === 'I' ? '■' : '❏'} I`,
                          font: 'Arial',
                          size: 18
                      })
                  ]
                }),
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                      new TextRun({
                          text: ` ${this.getValue(submission, 'plan_organico_animal_manejo_8_implementacion') === 'NI' ? '■' : '❏'} NI`,
                          font: 'Arial',
                          size: 18
                      })
                  ]
                })
              ],
              verticalAlign: VerticalAlign.TOP
          }),
          new TableCell({
              children: [
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                      new TextRun({
                          text: ` ${this.getValue(submission, 'plan_organico_animal_manejo_8_conformidad') === 'C' ? '■' : '❏'} C`,
                          font: 'Arial',
                          size: 18
                      })
                  ]
                }),
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                      new TextRun({
                          text: ` ${this.getValue(submission, 'plan_organico_animal_manejo_8_conformidad') === 'NC' ? '■' : '❏'} NC`,
                          font: 'Arial',
                          size: 18
                      })
                  ]
                }),
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                      new TextRun({
                          text: ` ${this.getValue(submission, 'plan_organico_animal_manejo_8_conformidad') === 'NA' ? '■' : '❏'} NA`,
                          font: 'Arial',
                          size: 18
                      })
                  ]
                })
              ],
              verticalAlign: VerticalAlign.TOP
          }),
          new TableCell({
              children: [
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                      new TextRun({
                          text: this.getValue(submission, 'plan_organico_animal_manejo_8_comentarios_inspector'),
                          font: 'Arial',
                          size: 18
                      })
                  ]
                })
              ],
              verticalAlign: VerticalAlign.TOP
          })
          ]
        }),
        new TableRow({
          children: [
            new TableCell({
              children: [
                new Paragraph({
                  alignment: AlignmentType.CENTER,
                  children: [
                    new TextRun({
                      text: '9',
                      font: 'Arial',
                      size: 18
                    })
                  ]
                })
              ],
              verticalAlign: VerticalAlign.TOP
            }),
            new TableCell({
              children: [
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                    new TextRun({
                      text: '¿Cuál es el tiempo que necesita la especie para alcanzar la edad/talla comercial?',
                      font: 'Arial',
                      size: 18
                    })
                  ]
                }),
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                    new TextRun({
                      text: `R: ${this.getValue(submission, 'plan_organico_animal_manejo_9_respuesta')}`,
                      font: 'Arial',
                      size: 18
                    })
                  ]
                })
              ],
              verticalAlign: VerticalAlign.TOP
            }),
            new TableCell({
              children: [
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                    new TextRun({
                      text: this.getValue(submission, "plan_organico_animal_manejo_9_comentarios"),
                      font: 'Arial',
                      size: 18
                    })
                  ]
                })
              ],
              verticalAlign: VerticalAlign.TOP
            }),
            new TableCell({
              children: [
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                      new TextRun({
                          text: ` ${this.getValue(submission, 'plan_organico_animal_manejo_9_implementacion') === 'I' ? '■' : '❏'} I`,
                          font: 'Arial',
                          size: 18
                      })
                  ]
                }),
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                      new TextRun({
                          text: ` ${this.getValue(submission, 'plan_organico_animal_manejo_9_implementacion') === 'NI' ? '■' : '❏'} NI`,
                          font: 'Arial',
                          size: 18
                      })
                  ]
                })
              ],
              verticalAlign: VerticalAlign.TOP
          }),
          new TableCell({
              children: [
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                      new TextRun({
                          text: ` ${this.getValue(submission, 'plan_organico_animal_manejo_9_conformidad') === 'C' ? '■' : '❏'} C`,
                          font: 'Arial',
                          size: 18
                      })
                  ]
                }),
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                      new TextRun({
                          text: ` ${this.getValue(submission, 'plan_organico_animal_manejo_9_conformidad') === 'NC' ? '■' : '❏'} NC`,
                          font: 'Arial',
                          size: 18
                      })
                  ]
                }),
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                      new TextRun({
                          text: ` ${this.getValue(submission, 'plan_organico_animal_manejo_9_conformidad') === 'NA' ? '■' : '❏'} NA`,
                          font: 'Arial',
                          size: 18
                      })
                  ]
                })
              ],
              verticalAlign: VerticalAlign.TOP
          }),
          new TableCell({
              children: [
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                      new TextRun({
                          text: this.getValue(submission, 'plan_organico_animal_manejo_9_comentarios_inspector'),
                          font: 'Arial',
                          size: 18
                      })
                  ]
                })
              ],
              verticalAlign: VerticalAlign.TOP
          })
          ]
        }),
        new TableRow({
          children: [
            new TableCell({
              children: [
                new Paragraph({
                  alignment: AlignmentType.CENTER,
                  children: [
                    new TextRun({
                      text: '10',
                      font: 'Arial',
                      size: 18
                    })
                  ]
                })
              ],
              verticalAlign: VerticalAlign.TOP
            }),
            new TableCell({
              children: [
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                    new TextRun({
                      text: 'Para juveniles que serán destinados al mercado, de procedencia no orgánica, ¿cuánto tiempo se manejaron de manera orgánica antes de la cosecha?',
                      font: 'Arial',
                      size: 18
                    })
                  ]
                }),
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                    new TextRun({
                      text: (this.getValue(submission, "plan_organico_animal_manejo_10_dos_tercios") ? '■' : '❏') + ' Los dos últimos tercios de la duración del ciclo de producción',
                      font: 'Arial',
                      size: 18
                    })
                  ]
                }),
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                    new TextRun({
                      text: (this.getValue(submission, "plan_organico_animal_manejo_10_menos_3_meses") ? '■' : '❏') + ' Menos de 3 meses',
                      font: 'Arial',
                      size: 18
                    })
                  ]
                })
              ],
              verticalAlign: VerticalAlign.TOP
            }),
            new TableCell({
              children: [
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                    new TextRun({
                      text: this.getValue(submission, "plan_organico_animal_manejo_10_comentarios"),
                      font: 'Arial',
                      size: 18
                    })
                  ]
                })
              ],
              verticalAlign: VerticalAlign.TOP
            }),
            new TableCell({
              children: [
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                      new TextRun({
                          text: ` ${this.getValue(submission, 'plan_organico_animal_manejo_10_implementacion') === 'I' ? '■' : '❏'} I`,
                          font: 'Arial',
                          size: 18
                      })
                  ]
                }),
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                      new TextRun({
                          text: ` ${this.getValue(submission, 'plan_organico_animal_manejo_10_implementacion') === 'NI' ? '■' : '❏'} NI`,
                          font: 'Arial',
                          size: 18
                      })
                  ]
                })
              ],
              verticalAlign: VerticalAlign.TOP
          }),
          new TableCell({
              children: [
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                      new TextRun({
                          text: ` ${this.getValue(submission, 'plan_organico_animal_manejo_10_conformidad') === 'C' ? '■' : '❏'} C`,
                          font: 'Arial',
                          size: 18
                      })
                  ]
                }),
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                      new TextRun({
                          text: ` ${this.getValue(submission, 'plan_organico_animal_manejo_10_conformidad') === 'NC' ? '■' : '❏'} NC`,
                          font: 'Arial',
                          size: 18
                      })
                  ]
                }),
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                      new TextRun({
                          text: ` ${this.getValue(submission, 'plan_organico_animal_manejo_10_conformidad') === 'NA' ? '■' : '❏'} NA`,
                          font: 'Arial',
                          size: 18
                      })
                  ]
                })
              ],
              verticalAlign: VerticalAlign.TOP
          }),
          new TableCell({
              children: [
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                      new TextRun({
                          text: this.getValue(submission, 'plan_organico_animal_manejo_10_comentarios_inspector'),
                          font: 'Arial',
                          size: 18
                      })
                  ]
                })
              ],
              verticalAlign: VerticalAlign.TOP
          })
          ]
        }),
        new TableRow({
          children: [
            new TableCell({
              children: [
                new Paragraph({
                  alignment: AlignmentType.CENTER,
                  children: [
                    new TextRun({
                      text: '11',
                      font: 'Arial',
                      size: 18
                    })
                  ]
                })
              ],
              verticalAlign: VerticalAlign.TOP
            }),
            new TableCell({
              children: [
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                    new TextRun({
                      text: '¿Cuáles prácticas de reproducción tiene implementadas?',
                      font: 'Arial',
                      size: 18
                    })
                  ]
                }),
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                    new TextRun({
                      text: (this.getValue(submission, "plan_organico_animal_manejo_11_naturales") ? '■' : '❏') + ' Naturales',
                      font: 'Arial',
                      size: 18
                    })
                  ]
                }),
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                    new TextRun({
                      text: (this.getValue(submission, "plan_organico_animal_manejo_11_hormonas") ? '■' : '❏') + ' Uso de hormonas',
                      font: 'Arial',
                      size: 18
                    })
                  ]
                }),
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                    new TextRun({
                      text: (this.getValue(submission, "plan_organico_animal_manejo_11_otro") ? '■' : '❏') + ' Otro (¿Cuál?): ' + this.getValue(submission, "plan_organico_animal_manejo_11_otro_text"),
                      font: 'Arial',
                      size: 18
                    })
                  ]
                })
              ],
              verticalAlign: VerticalAlign.TOP
            }),
            new TableCell({
              children: [
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                    new TextRun({
                      text: this.getValue(submission, "plan_organico_animal_manejo_11_comentarios"),
                      font: 'Arial',
                      size: 18
                    })
                  ]
                })
              ],
              verticalAlign: VerticalAlign.TOP
            }),
            new TableCell({
              children: [
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                      new TextRun({
                          text: ` ${this.getValue(submission, 'plan_organico_animal_manejo_11_implementacion') === 'I' ? '■' : '❏'} I`,
                          font: 'Arial',
                          size: 18
                      })
                  ]
                }),
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                      new TextRun({
                          text: ` ${this.getValue(submission, 'plan_organico_animal_manejo_11_implementacion') === 'NI' ? '■' : '❏'} NI`,
                          font: 'Arial',
                          size: 18
                      })
                  ]
                })
              ],
              verticalAlign: VerticalAlign.TOP
          }),
          new TableCell({
              children: [
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                      new TextRun({
                          text: ` ${this.getValue(submission, 'plan_organico_animal_manejo_11_conformidad') === 'C' ? '■' : '❏'} C`,
                          font: 'Arial',
                          size: 18
                      })
                  ]
                }),
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                      new TextRun({
                          text: ` ${this.getValue(submission, 'plan_organico_animal_manejo_11_conformidad') === 'NC' ? '■' : '❏'} NC`,
                          font: 'Arial',
                          size: 18
                      })
                  ]
                }),
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                      new TextRun({
                          text: ` ${this.getValue(submission, 'plan_organico_animal_manejo_11_conformidad') === 'NA' ? '■' : '❏'} NA`,
                          font: 'Arial',
                          size: 18
                      })
                  ]
                })
              ],
              verticalAlign: VerticalAlign.TOP
          }),
          new TableCell({
              children: [
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                      new TextRun({
                          text: this.getValue(submission, 'plan_organico_animal_manejo_11_comentarios_inspector'),
                          font: 'Arial',
                          size: 18
                      })
                  ]
                })
              ],
              verticalAlign: VerticalAlign.TOP
          })
          ]
        }),
        new TableRow({
          children: [
            new TableCell({
              children: [
                new Paragraph({
                  alignment: AlignmentType.CENTER,
                  children: [
                    new TextRun({
                      text: '12',
                      font: 'Arial',
                      size: 18
                    })
                  ]
                })
              ],
              verticalAlign: VerticalAlign.TOP
            }),
            new TableCell({
              children: [
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                    new TextRun({
                      text: 'En caso de uso de producción de tilapia:',
                      font: 'Arial',
                      size: 18
                    })
                  ]
                }),
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                    new TextRun({
                      text: (this.getValue(submission, "plan_organico_animal_manejo_12_uso_methyltestosterona") ? '■' : '❏') + ' Se usa la hormona methyltestosterona',
                      font: 'Arial',
                      size: 18
                    })
                  ]
                }),
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                    new TextRun({
                      text: (this.getValue(submission, "plan_organico_animal_manejo_12_analisis_residuos") ? '■' : '❏') + ' Se comprueba mediante análisis que no quedan residuos antes de la venta',
                      font: 'Arial',
                      size: 18
                    })
                  ]
                }),
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                    new TextRun({
                      text: (this.getValue(submission, "plan_organico_animal_manejo_12_tratamiento") ? '■' : '❏') + ' El tratamiento se aplica conforme a la norma aplicable',
                      font: 'Arial',
                      size: 18
                    })
                  ]
                }),
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                    new TextRun({
                      text: (this.getValue(submission, "plan_organico_animal_manejo_12_medidas_riesgo") ? '■' : '❏') + ' Se toman medidas para evitar riesgo para el personal que aplica la hormona',
                      font: 'Arial',
                      size: 18
                    })
                  ]
                }),
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                    new TextRun({
                      text: (this.getValue(submission, "plan_organico_animal_manejo_12_residuos_tratamiento") ? '■' : '❏') + ' Los residuos del tratamiento se retienen al menos 48 horas antes de desecharlo',
                      font: 'Arial',
                      size: 18
                    })
                  ]
                }),
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                    new TextRun({
                      text: (this.getValue(submission, "plan_organico_animal_manejo_12_otro") ? '■' : '❏') + ' Otro (¿Cuál?): ' + this.getValue(submission, "plan_organico_animal_manejo_12_otro_text"),
                      font: 'Arial',
                      size: 18
                    })
                  ]
                })
              ],
              verticalAlign: VerticalAlign.TOP
            }),
            new TableCell({
              children: [
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                    new TextRun({
                      text: this.getValue(submission, "plan_organico_animal_manejo_12_comentarios"),
                      font: 'Arial',
                      size: 18
                    })
                  ]
                })
              ],
              verticalAlign: VerticalAlign.TOP
            }),
            new TableCell({
              children: [
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                      new TextRun({
                          text: ` ${this.getValue(submission, 'plan_organico_animal_manejo_12_implementacion') === 'I' ? '■' : '❏'} I`,
                          font: 'Arial',
                          size: 18
                      })
                  ]
                }),
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                      new TextRun({
                          text: ` ${this.getValue(submission, 'plan_organico_animal_manejo_12_implementacion') === 'NI' ? '■' : '❏'} NI`,
                          font: 'Arial',
                          size: 18
                      })
                  ]
                })
              ],
              verticalAlign: VerticalAlign.TOP
          }),
          new TableCell({
              children: [
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                      new TextRun({
                          text: ` ${this.getValue(submission, 'plan_organico_animal_manejo_12_conformidad') === 'C' ? '■' : '❏'} C`,
                          font: 'Arial',
                          size: 18
                      })
                  ]
                }),
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                      new TextRun({
                          text: ` ${this.getValue(submission, 'plan_organico_animal_manejo_12_conformidad') === 'NC' ? '■' : '❏'} NC`,
                          font: 'Arial',
                          size: 18
                      })
                  ]
                }),
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                      new TextRun({
                          text: ` ${this.getValue(submission, 'plan_organico_animal_manejo_12_conformidad') === 'NA' ? '■' : '❏'} NA`,
                          font: 'Arial',
                          size: 18
                      })
                  ]
                })
              ],
              verticalAlign: VerticalAlign.TOP
          }),
          new TableCell({
              children: [
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                      new TextRun({
                          text: this.getValue(submission, 'plan_organico_animal_manejo_12_comentarios_inspector'),
                          font: 'Arial',
                          size: 18
                      })
                  ]
                })
              ],
              verticalAlign: VerticalAlign.TOP
          })
          ]
        }),
        new TableRow({
          children: [
            new TableCell({
              children: [
                new Paragraph({
                  alignment: AlignmentType.CENTER,
                  children: [
                    new TextRun({
                      text: '13',
                      font: 'Arial',
                      size: 18
                    })
                  ]
                })
              ],
              verticalAlign: VerticalAlign.TOP
            }),
            new TableCell({
              children: [
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                    new TextRun({
                      text: '¿Qué parámetros de la calidad del agua tiene controlados y registrados?',
                      font: 'Arial',
                      size: 18
                    })
                  ]
                }),
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                    new TextRun({
                      text: (this.getValue(submission, "plan_organico_animal_manejo_13_temperatura") ? '■' : '❏') + ' Temperatura',
                      font: 'Arial',
                      size: 18
                    })
                  ]
                }),
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                    new TextRun({
                      text: (this.getValue(submission, "plan_organico_animal_manejo_13_ph") ? '■' : '❏') + ' pH',
                      font: 'Arial',
                      size: 18
                    })
                  ]
                }),
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                    new TextRun({
                      text: (this.getValue(submission, "plan_organico_animal_manejo_13_salinidad") ? '■' : '❏') + ' Salinidad',
                      font: 'Arial',
                      size: 18
                    })
                  ]
                }),
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                    new TextRun({
                      text: (this.getValue(submission, "plan_organico_animal_manejo_13_oxigeno") ? '■' : '❏') + ' Oxígeno',
                      font: 'Arial',
                      size: 18
                    })
                  ]
                }),
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                    new TextRun({
                      text: (this.getValue(submission, "plan_organico_animal_manejo_13_amonio") ? '■' : '❏') + ' Amonio',
                      font: 'Arial',
                      size: 18
                    })
                  ]
                }),
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                    new TextRun({
                      text: (this.getValue(submission, "plan_organico_animal_manejo_13_nitratos") ? '■' : '❏') + ' Nitratos',
                      font: 'Arial',
                      size: 18
                    })
                  ]
                }),
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                    new TextRun({
                      text: (this.getValue(submission, "plan_organico_animal_manejo_13_otro") ? '■' : '❏') + ' Otro (¿Cuál?) ' + this.getValue(submission, "plan_organico_animal_manejo_13_otro_text"),
                      font: 'Arial',
                      size: 18
                    })
                  ]
                })
              ],
              verticalAlign: VerticalAlign.TOP
            }),
            new TableCell({
              children: [
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                    new TextRun({
                      text: this.getValue(submission, "plan_organico_animal_manejo_13_comentarios"),
                      font: 'Arial',
                      size: 18
                    })
                  ]
                })
              ],
              verticalAlign: VerticalAlign.TOP
            }),
            new TableCell({
              children: [
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                      new TextRun({
                          text: ` ${this.getValue(submission, 'plan_organico_animal_manejo_13_implementacion') === 'I' ? '■' : '❏'} I`,
                          font: 'Arial',
                          size: 18
                      })
                  ]
                }),
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                      new TextRun({
                          text: ` ${this.getValue(submission, 'plan_organico_animal_manejo_13_implementacion') === 'NI' ? '■' : '❏'} NI`,
                          font: 'Arial',
                          size: 18
                      })
                  ]
                })
              ],
              verticalAlign: VerticalAlign.TOP
          }),
          new TableCell({
              children: [
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                      new TextRun({
                          text: ` ${this.getValue(submission, 'plan_organico_animal_manejo_13_conformidad') === 'C' ? '■' : '❏'} C`,
                          font: 'Arial',
                          size: 18
                      })
                  ]
                }),
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                      new TextRun({
                          text: ` ${this.getValue(submission, 'plan_organico_animal_manejo_13_conformidad') === 'NC' ? '■' : '❏'} NC`,
                          font: 'Arial',
                          size: 18
                      })
                  ]
                }),
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                      new TextRun({
                          text: ` ${this.getValue(submission, 'plan_organico_animal_manejo_13_conformidad') === 'NA' ? '■' : '❏'} NA`,
                          font: 'Arial',
                          size: 18
                      })
                  ]
                })
              ],
              verticalAlign: VerticalAlign.TOP
          }),
          new TableCell({
              children: [
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                      new TextRun({
                          text: this.getValue(submission, 'plan_organico_animal_manejo_13_comentarios_inspector'),
                          font: 'Arial',
                          size: 18
                      })
                  ]
                })
              ],
              verticalAlign: VerticalAlign.TOP
          })
          ]
        }),
        new TableRow({
          children: [
            new TableCell({
              children: [
                new Paragraph({
                  alignment: AlignmentType.CENTER,
                  children: [
                    new TextRun({
                      text: '14',
                      font: 'Arial',
                      size: 18
                    })
                  ]
                })
              ],
              verticalAlign: VerticalAlign.TOP
            }),
            new TableCell({
              children: [
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                    new TextRun({
                      text: '¿Cuál de las siguientes actividades para la prevención y control de enfermedades tiene implementadas?',
                      font: 'Arial',
                      size: 18
                    })
                  ]
                }),
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                    new TextRun({
                      text: (this.getValue(submission, "plan_organico_animal_manejo_14_practicas") ? '■' : '❏') + ' Prácticas de inocuidad',
                      font: 'Arial',
                      size: 18
                    })
                  ]
                }),
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                    new TextRun({
                      text: (this.getValue(submission, "plan_organico_animal_manejo_14_separacion_organismos") ? '■' : '❏') + ' Separación de organismos enfermos',
                      font: 'Arial',
                      size: 18
                    })
                  ]
                }),
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                    new TextRun({
                      text: (this.getValue(submission, "plan_organico_animal_manejo_14_sustancias_permitidas") ? '■' : '❏') + ' Sustancias permitidas',
                      font: 'Arial',
                      size: 18
                    })
                  ]
                }),
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                    new TextRun({
                      text: (this.getValue(submission, "plan_organico_animal_manejo_14_tratamientos") ? '■' : '❏') + ' Tratamientos convencionales',
                      font: 'Arial',
                      size: 18
                    })
                  ]
                }),
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                    new TextRun({
                      text: (this.getValue(submission, "plan_organico_animal_manejo_14_otro") ? '■' : '❏') + ' Otro (¿Cuál?) ' + this.getValue(submission, "plan_organico_animal_manejo_14_otro_text"),
                      font: 'Arial',
                      size: 18
                    })
                  ]
                })
              ],
              verticalAlign: VerticalAlign.TOP
            }),
            new TableCell({
              children: [
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                    new TextRun({
                      text: this.getValue(submission, "plan_organico_animal_manejo_14_comentarios"),
                      font: 'Arial',
                      size: 18
                    })
                  ]
                })
              ],
              verticalAlign: VerticalAlign.TOP
            }),
            new TableCell({
              children: [
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                      new TextRun({
                          text: ` ${this.getValue(submission, 'plan_organico_animal_manejo_14_implementacion') === 'I' ? '■' : '❏'} I`,
                          font: 'Arial',
                          size: 18
                      })
                  ]
                }),
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                      new TextRun({
                          text: ` ${this.getValue(submission, 'plan_organico_animal_manejo_14_implementacion') === 'NI' ? '■' : '❏'} NI`,
                          font: 'Arial',
                          size: 18
                      })
                  ]
                })
              ],
              verticalAlign: VerticalAlign.TOP
          }),
          new TableCell({
              children: [
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                      new TextRun({
                          text: ` ${this.getValue(submission, 'plan_organico_animal_manejo_14_conformidad') === 'C' ? '■' : '❏'} C`,
                          font: 'Arial',
                          size: 18
                      })
                  ]
                }),
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                      new TextRun({
                          text: ` ${this.getValue(submission, 'plan_organico_animal_manejo_14_conformidad') === 'NC' ? '■' : '❏'} NC`,
                          font: 'Arial',
                          size: 18
                      })
                  ]
                }),
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                      new TextRun({
                          text: ` ${this.getValue(submission, 'plan_organico_animal_manejo_14_conformidad') === 'NA' ? '■' : '❏'} NA`,
                          font: 'Arial',
                          size: 18
                      })
                  ]
                })
              ],
              verticalAlign: VerticalAlign.TOP
          }),
          new TableCell({
              children: [
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                      new TextRun({
                          text: this.getValue(submission, 'plan_organico_animal_manejo_14_comentarios_inspector'),
                          font: 'Arial',
                          size: 18
                      })
                  ]
                })
              ],
              verticalAlign: VerticalAlign.TOP
          })
          ]
        }),
        new TableRow({
          children: [
            new TableCell({
              children: [
                new Paragraph({
                  alignment: AlignmentType.CENTER,
                  children: [
                    new TextRun({
                      text: '15',
                      font: 'Arial',
                      size: 18
                    })
                  ]
                })
              ],
              verticalAlign: VerticalAlign.TOP
            }),
            new TableCell({
              children: [
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                    new TextRun({
                      text: '¿Cuál es la densidad de población de la especie, en cada una de las etapas de desarrollo (cría, juvenil, adulto)?',
                      font: 'Arial',
                      size: 18
                    })
                  ]
                }),
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                    new TextRun({
                      text: `R: ${this.getValue(submission, 'plan_organico_animal_manejo_15_respuesta')}`,
                      font: 'Arial',
                      size: 18
                    })
                  ]
                })
              ],
              verticalAlign: VerticalAlign.TOP
            }),
            new TableCell({
              children: [
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                    new TextRun({
                      text: this.getValue(submission, "plan_organico_animal_manejo_15_comentarios"),
                      font: 'Arial',
                      size: 18
                    })
                  ]
                })
              ],
              verticalAlign: VerticalAlign.TOP
            }),
            new TableCell({
              children: [
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                      new TextRun({
                          text: ` ${this.getValue(submission, 'plan_organico_animal_manejo_15_implementacion') === 'I' ? '■' : '❏'} I`,
                          font: 'Arial',
                          size: 18
                      })
                  ]
                }),
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                      new TextRun({
                          text: ` ${this.getValue(submission, 'plan_organico_animal_manejo_15_implementacion') === 'NI' ? '■' : '❏'} NI`,
                          font: 'Arial',
                          size: 18
                      })
                  ]
                })
              ],
              verticalAlign: VerticalAlign.TOP
          }),
          new TableCell({
              children: [
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                      new TextRun({
                          text: ` ${this.getValue(submission, 'plan_organico_animal_manejo_15_conformidad') === 'C' ? '■' : '❏'} C`,
                          font: 'Arial',
                          size: 18
                      })
                  ]
                }),
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                      new TextRun({
                          text: ` ${this.getValue(submission, 'plan_organico_animal_manejo_15_conformidad') === 'NC' ? '■' : '❏'} NC`,
                          font: 'Arial',
                          size: 18
                      })
                  ]
                }),
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                      new TextRun({
                          text: ` ${this.getValue(submission, 'plan_organico_animal_manejo_15_conformidad') === 'NA' ? '■' : '❏'} NA`,
                          font: 'Arial',
                          size: 18
                      })
                  ]
                })
              ],
              verticalAlign: VerticalAlign.TOP
          }),
          new TableCell({
              children: [
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                      new TextRun({
                          text: this.getValue(submission, 'plan_organico_animal_manejo_15_comentarios_inspector'),
                          font: 'Arial',
                          size: 18
                      })
                  ]
                })
              ],
              verticalAlign: VerticalAlign.TOP
          })
          ]
        }),
        new TableRow({
          children: [
            new TableCell({
              children: [
                new Paragraph({
                  alignment: AlignmentType.CENTER,
                  children: [
                    new TextRun({
                      text: '16',
                      font: 'Arial',
                      size: 18
                    })
                  ]
                })
              ],
              verticalAlign: VerticalAlign.TOP
            }),
            new TableCell({
              children: [
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                    new TextRun({
                      text: '¿Cómo definió la densidad de población?',
                      font: 'Arial',
                      size: 18
                    })
                  ]
                }),
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                    new TextRun({
                      text: `R: ${this.getValue(submission, 'plan_organico_animal_manejo_16_respuesta')}`,
                      font: 'Arial',
                      size: 18
                    })
                  ]
                })
              ],
              verticalAlign: VerticalAlign.TOP
            }),
            new TableCell({
              children: [
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                    new TextRun({
                      text: this.getValue(submission, "plan_organico_animal_manejo_16_comentarios"),
                      font: 'Arial',
                      size: 18
                    })
                  ]
                })
              ],
              verticalAlign: VerticalAlign.TOP
            }),
            new TableCell({
              children: [
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                      new TextRun({
                          text: ` ${this.getValue(submission, 'plan_organico_animal_manejo_16_implementacion') === 'I' ? '■' : '❏'} I`,
                          font: 'Arial',
                          size: 18
                      })
                  ]
                }),
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                      new TextRun({
                          text: ` ${this.getValue(submission, 'plan_organico_animal_manejo_16_implementacion') === 'NI' ? '■' : '❏'} NI`,
                          font: 'Arial',
                          size: 18
                      })
                  ]
                })
              ],
              verticalAlign: VerticalAlign.TOP
            }),
            new TableCell({
                children: [
                  new Paragraph({
                    alignment: AlignmentType.LEFT,
                    children: [
                        new TextRun({
                            text: ` ${this.getValue(submission, 'plan_organico_animal_manejo_16_conformidad') === 'C' ? '■' : '❏'} C`,
                            font: 'Arial',
                            size: 18
                        })
                    ]
                  }),
                  new Paragraph({
                    alignment: AlignmentType.LEFT,
                    children: [
                        new TextRun({
                            text: ` ${this.getValue(submission, 'plan_organico_animal_manejo_16_conformidad') === 'NC' ? '■' : '❏'} NC`,
                            font: 'Arial',
                            size: 18
                        })
                    ]
                  }),
                  new Paragraph({
                    alignment: AlignmentType.LEFT,
                    children: [
                        new TextRun({
                            text: ` ${this.getValue(submission, 'plan_organico_animal_manejo_16_conformidad') === 'NA' ? '■' : '❏'} NA`,
                            font: 'Arial',
                            size: 18
                        })
                    ]
                  })
                ],
                verticalAlign: VerticalAlign.TOP
            }),
            new TableCell({
                children: [
                  new Paragraph({
                    alignment: AlignmentType.LEFT,
                    children: [
                        new TextRun({
                            text: this.getValue(submission, 'plan_organico_animal_manejo_16_comentarios_inspector'),
                            font: 'Arial',
                            size: 18
                        })
                    ]
                  })
                ],
                verticalAlign: VerticalAlign.TOP
            })
          ]
        })
      ],
      margins: {
        left: 70,
        right: 70
      },
      //9885
      columnWidths: [450, 3895, 2045, 1150, 800, 1545]
    })

    const tableRow = new TableRow({
      children: [
        new TableCell({
          children: [
            new Paragraph({
              alignment: AlignmentType.CENTER,
              children: [
                new TextRun({
                  text: '17',
                  font: 'Arial',
                  size: 18
                })
              ]
            })
          ],
          columnSpan: 1,
          verticalAlign: VerticalAlign.TOP
        })
      ]
    })

    const tableCell = new TableCell({
      children: [
        new Paragraph({
          alignment: AlignmentType.LEFT,
          children: [
            new TextRun({
              text: 'Por favor señale los componentes de una ración diaria',
              font: 'Arial',
              size: 18
            })
          ]
        }),
        new Paragraph({
          alignment: AlignmentType.LEFT,
          children: [
            new TextRun({
              text: '',
              font: 'Arial',
              size: 18
            })
          ]
        })
      ],
      columnSpan: 5,
      verticalAlign: VerticalAlign.TOP
    })

    const tableIngredientes = new Table({
      rows: [
        new TableRow({
          children: [
            new TableCell({
              children: [
                new Paragraph({
                  alignment: AlignmentType.CENTER,
                  children: [
                    new TextRun({
                      text: 'Ingrediente',
                      font: 'Arial',
                      size: 18,
                      bold: true
                    })
                  ]
                })
              ],
              verticalAlign: VerticalAlign.CENTER,
              shading: {
                fill: '#C0C0C0'
              }
            }),
            new TableCell({
              children: [
                new Paragraph({
                  alignment: AlignmentType.CENTER,
                  children: [
                    new TextRun({
                      text: 'Cantidad (Kg)',
                      font: 'Arial',
                      size: 18,
                      bold: true
                    })
                  ]
                })
              ],
              verticalAlign: VerticalAlign.CENTER,
              shading: {
                fill: '#C0C0C0'
              }
            }),
            new TableCell({
              children: [
                new Paragraph({
                  alignment: AlignmentType.CENTER,
                  children: [
                    new TextRun({
                      text: 'Proporción (% Materia Seca)',
                      font: 'Arial',
                      size: 18,
                      bold: true
                    })
                  ]
                })
              ],
              verticalAlign: VerticalAlign.CENTER,
              shading: {
                fill: '#C0C0C0'
              }
            }),
            new TableCell({
              children: [
                new Paragraph({
                  alignment: AlignmentType.CENTER,
                  children: [
                    new TextRun({
                      text: 'Proveedor',
                      font: 'Arial',
                      size: 18,
                      bold: true
                    })
                  ]
                })
              ],
              verticalAlign: VerticalAlign.CENTER,
              shading: {
                fill: '#C0C0C0'
              }
            }),
            new TableCell({
              children: [
                new Paragraph({
                  alignment: AlignmentType.CENTER,
                  children: [
                    new TextRun({
                      text: 'Calidad',
                      font: 'Arial',
                      size: 18,
                      bold: true
                    })
                  ]
                })
              ],
              verticalAlign: VerticalAlign.CENTER,
              shading: {
                fill: '#C0C0C0'
              }
            })
          ]
        })
      ],
      margins: {
        left: 70,
        right: 70
      },
      //9885
      columnWidths: [3895, 2045, 1150, 800, 1545]
    })

    let listaIngredientes = this.getValueList(submission, "racion-diaria")
    listaIngredientes.forEach(ingrediente => {
      tableIngredientes.addChildElement(
        new TableRow({
          children: [
            new TableCell({
              children: [
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                    new TextRun({
                      text: ingrediente.ingrediente,
                      font: 'Arial',
                      size: 18,
                    })
                  ]
                })
              ],
              verticalAlign: VerticalAlign.CENTER,
            }),
            new TableCell({
              children: [
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                    new TextRun({
                      text: ingrediente.cantidad,
                      font: 'Arial',
                      size: 18,
                    })
                  ]
                })
              ],
              verticalAlign: VerticalAlign.CENTER,
            }),
            new TableCell({
              children: [
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                    new TextRun({
                      text: ingrediente.proporcion,
                      font: 'Arial',
                      size: 18,
                    })
                  ]
                })
              ],
              verticalAlign: VerticalAlign.CENTER,
            }),
            new TableCell({
              children: [
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                    new TextRun({
                      text: ingrediente.proveedor,
                      font: 'Arial',
                      size: 18,
                    })
                  ]
                })
              ],
              verticalAlign: VerticalAlign.CENTER,
            }),
            new TableCell({
              children: [
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                    new TextRun({
                      text: `${ingrediente.plan_organico_animal_manejo_organica ? '■' : '❏'} Orgánica`,
                      font: 'Arial',
                      size: 18,
                    })
                  ]
                }),
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                    new TextRun({
                      text: `${ingrediente.plan_organico_animal_manejo_convencional ? '■' : '❏'} Convencional`,
                      font: 'Arial',
                      size: 18,
                    })
                  ]
                }),
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                    new TextRun({
                      text: `${ingrediente.plan_organico_animal_manejo_transicion ? '■' : '❏'} Transición`,
                      font: 'Arial',
                      size: 18,
                    })
                  ]
                }),
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                    new TextRun({
                      text: `${ingrediente.plan_organico_vegetal_manejo_10_cubierta_suelo_otro ? '■' : '❏'} Otro: ${ingrediente.plan_organico_vegetal_manejo_10_cubierta_suelo_otro_text}`,
                      font: 'Arial',
                      size: 18,
                    })
                  ]
                })
              ],
              verticalAlign: VerticalAlign.CENTER,
            })
          ]
        })
      )
    });

    tableCell.addChildElement(tableIngredientes)
    tableCell.addChildElement(new Paragraph({
      alignment: AlignmentType.LEFT,
      children: [
        new TextRun({
          text: `Comentarios: ${this.getValue(submission, "productos_certificados_balance_comentarios")}`,
          font: 'Arial',
          size: 18
        })
      ]
    }))
    tableCell.addChildElement(new Paragraph({
      alignment: AlignmentType.LEFT,
      children: [
        new TextRun({
          text: '',
          font: 'Arial',
          size: 18
        })
      ]
    }))
    tableRow.addChildElement(tableCell)
    table.addChildElement(tableRow)

    table.addChildElement(new TableRow({
        children: [
          new TableCell({
            children: [
              new Paragraph({
                alignment: AlignmentType.CENTER,
                children: [
                  new TextRun({
                    text: '18',
                    font: 'Arial',
                    size: 18
                  })
                ]
              })
            ],
            verticalAlign: VerticalAlign.TOP
          }),
          new TableCell({
            children: [
              new Paragraph({
                alignment: AlignmentType.LEFT,
                children: [
                  new TextRun({
                    text: '¿Cuáles prácticas se siguen durante el transporte de organismos vivos?',
                    font: 'Arial',
                    size: 18
                  })
                ]
              }),
              new Paragraph({
                alignment: AlignmentType.LEFT,
                children: [
                  new TextRun({
                    text: (this.getValue(submission, "plan_organico_animal_manejo_18_practicas") ? '■' : '❏') + ' Se mantienen los niveles de oxígeno',
                    font: 'Arial',
                    size: 18
                  })
                ]
              }),
              new Paragraph({
                alignment: AlignmentType.LEFT,
                children: [
                  new TextRun({
                    text: (this.getValue(submission, "plan_organico_animal_manejo_18_separacion_organismos") ? '■' : '❏') + ' Densidad de 1 Kg/8 litros de agua o menor',
                    font: 'Arial',
                    size: 18
                  })
                ]
              }),
              new Paragraph({
                alignment: AlignmentType.LEFT,
                children: [
                  new TextRun({
                    text: (this.getValue(submission, "plan_organico_animal_manejo_18_sustancias_permitidas") ? '■' : '❏') + ' Cambio de agua cada 6 horas',
                    font: 'Arial',
                    size: 18
                  })
                ]
              }),
              new Paragraph({
                alignment: AlignmentType.LEFT,
                children: [
                  new TextRun({
                    text: (this.getValue(submission, "plan_organico_animal_manejo_18_tratamientos") ? '■' : '❏') + ' Recorrido menor o igual a 10 horas',
                    font: 'Arial',
                    size: 18
                  })
                ]
              }),
              new Paragraph({
                alignment: AlignmentType.LEFT,
                children: [
                  new TextRun({
                    text: (this.getValue(submission, "plan_organico_animal_manejo_18_otro") ? '■' : '❏') + ' Otro (¿Cuál?) ' + this.getValue(submission, "plan_organico_animal_manejo_18_otro_text"),
                    font: 'Arial',
                    size: 18
                  })
                ]
              })
            ],
            verticalAlign: VerticalAlign.TOP
          }),
          new TableCell({
            children: [
              new Paragraph({
                alignment: AlignmentType.LEFT,
                children: [
                  new TextRun({
                    text: this.getValue(submission, "plan_organico_animal_manejo_18_comentarios"),
                    font: 'Arial',
                    size: 18
                  })
                ]
              })
            ],
            verticalAlign: VerticalAlign.TOP
          }),
          new TableCell({
            children: [
              new Paragraph({
                alignment: AlignmentType.LEFT,
                children: [
                    new TextRun({
                        text: ` ${this.getValue(submission, 'plan_organico_animal_manejo_18_implementacion') === 'I' ? '■' : '❏'} I`,
                        font: 'Arial',
                        size: 18
                    })
                ]
              }),
              new Paragraph({
                alignment: AlignmentType.LEFT,
                children: [
                    new TextRun({
                        text: ` ${this.getValue(submission, 'plan_organico_animal_manejo_18_implementacion') === 'NI' ? '■' : '❏'} NI`,
                        font: 'Arial',
                        size: 18
                    })
                ]
              })
            ],
            verticalAlign: VerticalAlign.TOP
        }),
        new TableCell({
            children: [
              new Paragraph({
                alignment: AlignmentType.LEFT,
                children: [
                    new TextRun({
                        text: ` ${this.getValue(submission, 'plan_organico_animal_manejo_18_conformidad') === 'C' ? '■' : '❏'} C`,
                        font: 'Arial',
                        size: 18
                    })
                ]
              }),
              new Paragraph({
                alignment: AlignmentType.LEFT,
                children: [
                    new TextRun({
                        text: ` ${this.getValue(submission, 'plan_organico_animal_manejo_18_conformidad') === 'NC' ? '■' : '❏'} NC`,
                        font: 'Arial',
                        size: 18
                    })
                ]
              }),
              new Paragraph({
                alignment: AlignmentType.LEFT,
                children: [
                    new TextRun({
                        text: ` ${this.getValue(submission, 'plan_organico_animal_manejo_18_conformidad') === 'NA' ? '■' : '❏'} NA`,
                        font: 'Arial',
                        size: 18
                    })
                ]
              })
            ],
            verticalAlign: VerticalAlign.TOP
        }),
        new TableCell({
            children: [
              new Paragraph({
                alignment: AlignmentType.LEFT,
                children: [
                    new TextRun({
                        text: this.getValue(submission, 'plan_organico_animal_manejo_18_comentarios_inspector'),
                        font: 'Arial',
                        size: 18
                    })
                ]
              })
            ],
            verticalAlign: VerticalAlign.TOP
        })
        ]
    }))

    table.addChildElement(new TableRow({
        children: [
          new TableCell({
            children: [
              new Paragraph({
                alignment: AlignmentType.CENTER,
                children: [
                  new TextRun({
                    text: '19',
                    font: 'Arial',
                    size: 18
                  })
                ]
              })
            ],
            verticalAlign: VerticalAlign.TOP
          }),
          new TableCell({
            children: [
              new Paragraph({
                alignment: AlignmentType.LEFT,
                children: [
                  new TextRun({
                    text: 'Describa las actividades para el sacrificio:',
                    font: 'Arial',
                    size: 18
                  })
                ]
              }),
              new Paragraph({
                alignment: AlignmentType.LEFT,
                children: [
                  new TextRun({
                    text: `R: ${this.getValue(submission, 'plan_organico_animal_manejo_19_respuesta')}`,
                    font: 'Arial',
                    size: 18
                  })
                ]
              })
            ],
            verticalAlign: VerticalAlign.TOP
          }),
          new TableCell({
            children: [
              new Paragraph({
                alignment: AlignmentType.LEFT,
                children: [
                  new TextRun({
                    text: this.getValue(submission, "plan_organico_animal_manejo_19_comentarios"),
                    font: 'Arial',
                    size: 18
                  })
                ]
              })
            ],
            verticalAlign: VerticalAlign.TOP
          }),
          new TableCell({
            children: [
              new Paragraph({
                alignment: AlignmentType.LEFT,
                children: [
                    new TextRun({
                        text: ` ${this.getValue(submission, 'plan_organico_animal_manejo_19_implementacion') === 'I' ? '■' : '❏'} I`,
                        font: 'Arial',
                        size: 18
                    })
                ]
              }),
              new Paragraph({
                alignment: AlignmentType.LEFT,
                children: [
                    new TextRun({
                        text: ` ${this.getValue(submission, 'plan_organico_animal_manejo_19_implementacion') === 'NI' ? '■' : '❏'} NI`,
                        font: 'Arial',
                        size: 18
                    })
                ]
              })
            ],
            verticalAlign: VerticalAlign.TOP
        }),
        new TableCell({
            children: [
              new Paragraph({
                alignment: AlignmentType.LEFT,
                children: [
                    new TextRun({
                        text: ` ${this.getValue(submission, 'plan_organico_animal_manejo_19_conformidad') === 'C' ? '■' : '❏'} C`,
                        font: 'Arial',
                        size: 18
                    })
                ]
              }),
              new Paragraph({
                alignment: AlignmentType.LEFT,
                children: [
                    new TextRun({
                        text: ` ${this.getValue(submission, 'plan_organico_animal_manejo_19_conformidad') === 'NC' ? '■' : '❏'} NC`,
                        font: 'Arial',
                        size: 18
                    })
                ]
              }),
              new Paragraph({
                alignment: AlignmentType.LEFT,
                children: [
                    new TextRun({
                        text: ` ${this.getValue(submission, 'plan_organico_animal_manejo_19_conformidad') === 'NA' ? '■' : '❏'} NA`,
                        font: 'Arial',
                        size: 18
                    })
                ]
              })
            ],
            verticalAlign: VerticalAlign.TOP
        }),
        new TableCell({
            children: [
              new Paragraph({
                alignment: AlignmentType.LEFT,
                children: [
                    new TextRun({
                        text: this.getValue(submission, 'plan_organico_animal_manejo_19_comentarios_inspector'),
                        font: 'Arial',
                        size: 18
                    })
                ]
              })
            ],
            verticalAlign: VerticalAlign.TOP
        })
        ]
    }))

    table.addChildElement(new TableRow({
        children: [
          new TableCell({
            children: [
              new Paragraph({
                alignment: AlignmentType.CENTER,
                children: [
                  new TextRun({
                    text: '20',
                    font: 'Arial',
                    size: 18
                  })
                ]
              })
            ],
            verticalAlign: VerticalAlign.TOP
          }),
          new TableCell({
            children: [
              new Paragraph({
                alignment: AlignmentType.LEFT,
                children: [
                  new TextRun({
                    text: '¿Qué tipo de venta hace de su producción?',
                    font: 'Arial',
                    size: 18
                  })
                ]
              }),
              new Paragraph({
                alignment: AlignmentType.LEFT,
                children: [
                  new TextRun({
                    text: (this.getValue(submission, "plan_organico_animal_manejo_20_granel") ? '■' : '❏') + ' Granel',
                    font: 'Arial',
                    size: 18
                  })
                ]
              }),
              new Paragraph({
                alignment: AlignmentType.LEFT,
                children: [
                  new TextRun({
                    text: (this.getValue(submission, "plan_organico_animal_manejo_20_mayoreo") ? '■' : '❏') + ' Mayoreo',
                    font: 'Arial',
                    size: 18
                  })
                ]
              }),
              new Paragraph({
                alignment: AlignmentType.LEFT,
                children: [
                  new TextRun({
                    text: (this.getValue(submission, "plan_organico_animal_manejo_20_menudeo") ? '■' : '❏') + ' Menudeo',
                    font: 'Arial',
                    size: 18
                  })
                ]
              }),
              new Paragraph({
                alignment: AlignmentType.LEFT,
                children: [
                  new TextRun({
                    text: '*Favor de anexar etiquetas',
                    font: 'Arial',
                    size: 18
                  })
                ]
              })
            ],
            verticalAlign: VerticalAlign.TOP
          }),
          new TableCell({
            children: [
              new Paragraph({
                alignment: AlignmentType.LEFT,
                children: [
                  new TextRun({
                    text: this.getValue(submission, "plan_organico_animal_manejo_18_comentarios"),
                    font: 'Arial',
                    size: 18
                  })
                ]
              })
            ],
            verticalAlign: VerticalAlign.TOP
          }),
          new TableCell({
            children: [
              new Paragraph({
                alignment: AlignmentType.LEFT,
                children: [
                    new TextRun({
                        text: ` ${this.getValue(submission, 'plan_organico_animal_manejo_20_implementacion') === 'I' ? '■' : '❏'} I`,
                        font: 'Arial',
                        size: 18
                    })
                ]
              }),
              new Paragraph({
                alignment: AlignmentType.LEFT,
                children: [
                    new TextRun({
                        text: ` ${this.getValue(submission, 'plan_organico_animal_manejo_20_implementacion') === 'NI' ? '■' : '❏'} NI`,
                        font: 'Arial',
                        size: 18
                    })
                ]
              })
            ],
            verticalAlign: VerticalAlign.TOP
        }),
        new TableCell({
            children: [
              new Paragraph({
                alignment: AlignmentType.LEFT,
                children: [
                    new TextRun({
                        text: ` ${this.getValue(submission, 'plan_organico_animal_manejo_20_conformidad') === 'C' ? '■' : '❏'} C`,
                        font: 'Arial',
                        size: 18
                    })
                ]
              }),
              new Paragraph({
                alignment: AlignmentType.LEFT,
                children: [
                    new TextRun({
                        text: ` ${this.getValue(submission, 'plan_organico_animal_manejo_20_conformidad') === 'NC' ? '■' : '❏'} NC`,
                        font: 'Arial',
                        size: 18
                    })
                ]
              }),
              new Paragraph({
                alignment: AlignmentType.LEFT,
                children: [
                    new TextRun({
                        text: ` ${this.getValue(submission, 'plan_organico_animal_manejo_20_conformidad') === 'NA' ? '■' : '❏'} NA`,
                        font: 'Arial',
                        size: 18
                    })
                ]
              })
            ],
            verticalAlign: VerticalAlign.TOP
        }),
        new TableCell({
            children: [
              new Paragraph({
                alignment: AlignmentType.LEFT,
                children: [
                    new TextRun({
                        text: this.getValue(submission, 'plan_organico_animal_manejo_20_comentarios_inspector'),
                        font: 'Arial',
                        size: 18
                    })
                ]
              })
            ],
            verticalAlign: VerticalAlign.TOP
        })
        ]
    }))

    return table
  }


  public createTableUDP(data, datePipe): Table {
    const table = new Table({
      rows: [
        new TableRow({
          children: [
            new TableCell({
              children: [
                new Paragraph({
                  alignment: AlignmentType.RIGHT,
                  children: [
                    new TextRun({
                      text: 'Operador: ',
                      font: 'Calibri (Cuerpo)',
                      size: 22,
                      bold: true,

                    })
                  ],
                  spacing: {before: 100, after: 200}
                })],
              verticalAlign: VerticalAlign.CENTER,
            }),
            new TableCell({
              children: [new Paragraph({
                alignment: AlignmentType.CENTER,
                children: [
                  new TextRun({
                    text: data.customer.Company_Name,
                    font: 'Calibri (Cuerpo)',
                    size: 22,
                  })
                ],
              })],
              verticalAlign: VerticalAlign.CENTER,
              columnSpan: 3,
            }),
          ],
        }),
        new TableRow({
          children: [
            new TableCell({
              children: [
                new Paragraph({
                  alignment: AlignmentType.RIGHT,
                  children: [
                    new TextRun({
                      text: 'Código del operador: ',
                      font: 'Calibri (Cuerpo)',
                      size: 22,
                      bold: true,

                    })
                  ],
                  spacing: {before: 100, after: 200}
                })],
              width: {
                size: 25,
                type: WidthType.PERCENTAGE,
              },
            }),
            new TableCell({
              children: [new Paragraph({
                alignment: AlignmentType.CENTER,
                children: [
                  new TextRun({
                    text: data.customer.Id_Comercial,
                    font: 'Calibri (Cuerpo)',
                    size: 22,
                  })
                ],
              })],
              verticalAlign: VerticalAlign.CENTER,
              width: {
                size: 25,
                type: WidthType.PERCENTAGE,
              },
            }),
            new TableCell({
              children: [
                new Paragraph({
                  alignment: AlignmentType.RIGHT,
                  children: [
                    new TextRun({
                      text: 'Fecha: ',
                      font: 'Calibri (Cuerpo)',
                      size: 22,
                      bold: true,

                    })
                  ],
                  spacing: {before: 100, after: 200}
                })],
              verticalAlign: VerticalAlign.CENTER,
              width: {
                size: 25,
                type: WidthType.PERCENTAGE,
              },
            }),
            new TableCell({
              children: [new Paragraph({
                alignment: AlignmentType.CENTER,
                children: [
                  new TextRun({
                    text: datePipe.transform(data.record.Applications[0].Creation_Date.includes('UTC') ? data.record.Applications[0].Creation_Date.replace('UTC', '').trim() : data.record.Applications[0].Creation_Date, "d-MMMM-y"),
                    font: 'Calibri (Cuerpo)',
                    size: 22,
                  })
                ],
              })],
              verticalAlign: VerticalAlign.CENTER,
              width: {
                size: 25,
                type: WidthType.PERCENTAGE,
              },
            }),
          ],
        }),
      ],
      margins: {
        left: 70,
        right: 70
      },
      width: {
        size: 100,
        type: WidthType.PERCENTAGE,
      },
      //9638
      columnWidths: [2410, 2410, 2409, 2409]
    })

    return table
  }

  getValue(submission, name) {
    var value = null
    for (const i in submission) {
      const element = submission[i]
      if (element.name === name) {
        value = element.value
      }
    }
    if (value) return value
    else return ''
  }

  getValueList(submission, name) {
    var value = null
    for (const i in submission) {
      const element = submission[i]
      if (element.name === name) {
        value = element.value
      }
    }
    if (value) return value
    else return []
  }

  // END MTO_FSOL_08_2_4
}
