import { ControlCommunicationService } from './../../../services/control-communication.service';
import { FormBuilderComponent } from './../../../form-builder.component';
import { FormGroup } from '@angular/forms';
import { UnknownParams, ControlElement } from './../../../interfaces/form.interface';
import { Component, OnInit, Input, Renderer2, ElementRef, OnDestroy } from '@angular/core'

@Component({
    selector: 'button-element',
    templateUrl: './button.component.html'
})
export class ButtonComponent implements OnInit, OnDestroy {
    attributes: UnknownParams = {}
    validations: UnknownParams = {}

    // Save every listener created in order to remove it later
    private listeners: Array<() => void> = []

    @Input() form: FormGroup

    @Input() data: ControlElement
    
    // inject parent FormBuilder Component in order to access FormGroup
    constructor(private parentControl: FormBuilderComponent, private renderer: Renderer2, private ref: ElementRef, private _controlCommunicationService: ControlCommunicationService) { }

    ngOnInit(): void {
        // asign parent formGroup to local formGroup
        this.form = <FormGroup>this.parentControl.formGroup
        // iterate and access component attributes
        for (var i in this.data.attributes) {
            const attribute = this.data.attributes[i]
            this.attributes[attribute.name] = attribute.value
        }
        for (var i in this.data.validations) {
            const validation = this.data.validations[i]
            this.validations[validation.name] = validation.value
        }
        if (this.data.events) {
            for (var i in this.data.events) {
                const {type, identifier} = this.data.events[i]
                this.listeners.push(
                    this.renderer.listen( this.ref.nativeElement, type, (event) => {
                        this._controlCommunicationService.emitEvent({
                            type,
                            identifier,
                            event,
                            form: this.parentControl.formGroup
                        })
                    })
                )
            }
        }
    }

    ngOnDestroy() {
        // Destroy every listener once the component is unmounted
        for(var index in this.listeners) {
            this.listeners[index]()
        }
    }

}