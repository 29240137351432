import { ControlCommunicationService } from './../../../services/control-communication.service';
import { FormBuilderComponent } from './../../../form-builder.component';
import { ContainerElement, UnknownParams, ControlElement } from './../../../interfaces/form.interface';
import { FormGroup, FormArray, FormControl } from '@angular/forms';
import { Component, OnInit, OnDestroy, Input, Type } from '@angular/core'
import { Observable, of } from 'rxjs';
import { catchError, debounceTime, distinctUntilChanged, tap, switchMap, first } from 'rxjs/operators';
import { NgbModal, NgbModalConfig } from '@ng-bootstrap/ng-bootstrap';

import { SubstancesService } from '../../../../core/services/substances.services'
import { AuthenticationService, UsersService, CustomersService } from '@app/core/services';
import { User } from '@app/core/models';
import { ModalPreview } from '@app/core/components/modal-preview';
import { copyFileSync } from 'fs';
import { Console } from 'console';

const MODALS: { [name: string]: Type<any> } = {
    preview: ModalPreview
};

@Component({
    selector: 'dynamic-container',
    templateUrl: './dynamic.component.html'
})
export class DynamicComponent implements OnInit {
    attributes: UnknownParams = {}

    @Input() form: FormGroup
    @Input() data: ContainerElement
    
    constructor() {}

    ngOnInit() {
        // Create objects containing attributes and validations
        for (var i in this.data.attributes) {
            const attribute = this.data.attributes[i]
            this.attributes[attribute.name] = attribute.value
        }

        //console.log(this.attributes)
    }
}