<div [ngClass]="{'was-validated': validations.required, 'form': true}" class="form-group" [formGroup]="form" style="margin-bottom: 10px;">
    <label *ngIf="attributes.label">{{attributes.label}}</label>
    <select [formControlName]="attributes.name" class="form-control browser-default select" [required]="validations.required"
        [class]="attributes.class ? attributes.class : 's'" [attr.disabled]="validations.disabled" (change)="changeValue($event, attributes.name);otherValueChanged($event, attributes.other[1])">
        <option *ngFor="let option of attributes.options" [value]="option">{{option}}</option>
        <!--<option *ngIf="attributes.other">{{attributes.other[0]}}</option>-->
    </select>
    <div *ngIf="attributes.other && form.get(attributes.name).value == attributes.other[0]" style="margin-top: 10px;">
        <input class="form-control" [formControlName]="attributes.other[1]" />
    </div>
    <div class="valid-feedback">{{attributes.input_valid}}</div>
    <div class="invalid-feedback">{{attributes.input_invalid}}</div>
</div>