import { AlignmentType, Document, Paragraph, TextRun, Media, Header, Footer, Table, TableRow, TableCell, VerticalAlign, WidthType, PageNumber, BorderStyle } from "docx";
import { logo } from "../logo";
import { userWithoutSign } from "../userWithoutSign"
import { DatePipe } from '@angular/common';

export class DocumentCreatorMTO_FSOL_09_v3 {

    public getBase64FromUrl = async (url) => {
        const data = await fetch(url, {
            headers: {
                'Cache-Control': 'no-cache, no-store, must-revalidate',
                'Pragma': 'no-cache',
                'Expires': 'Sat, 01 Jan 2000 00:00:00 GMT',
                'If-Modified-Since': '0'
            }
        });
        const blob = await data.blob();
        return new Promise((resolve) => {
            const reader = new FileReader();
            reader.readAsDataURL(blob);
            reader.onloadend = function() {
                const base64data = reader.result;
                resolve(base64data);
            }
        });
    }

    // INIT MTO_FSOL_09

    subTotalOrganicos = 0
    subTotalAgricolas = 0
    subTotalOtros = 0
    subtotalAguaSal = 0
    porcentajeProductosOrganicos = 0
    cantidadOrganicos = 0
    cantidadOtros = 0
    cantidadIngredientesSinAguaSal = 0
    cantidadIngredientes = 0

    public async create_MTO_FSOL_09(data, signature = ''): Promise<Document> {
        const document = new Document();
        const image = Media.addImage(document, logo, 50, 50);
        var datePipe = new DatePipe('es-MX');
        const listaProductos = this.getValueList(data.submission, "lista_productos")

        let signatureImage = null
        if (signature) {
            signatureImage = await this.getBase64FromUrl(signature)
        } else {
            signatureImage = userWithoutSign
        }

        for(var i in listaProductos) {
            const producto = listaProductos[i]

            this.subTotalOrganicos = 0
            this.subTotalAgricolas = 0
            this.subTotalOtros = 0
            this.subtotalAguaSal = 0
            this.porcentajeProductosOrganicos = 0
            this.cantidadOrganicos = 0
            this.cantidadOtros = 0
            this.cantidadIngredientesSinAguaSal = 0
            this.cantidadIngredientes = 0

            document.addSection({
                headers: {
                    default: new Header({
                        children: [
                            new Paragraph({ alignment: AlignmentType.CENTER, children: [image], }),
                            new Paragraph({
                                alignment: AlignmentType.CENTER, children: [
                                    new TextRun({
                                        children: [`MTO-FSOL-09| R 00 | 01-Julio-2017 | Pág. `, PageNumber.CURRENT],
                                        font: 'Book Antiqua',
                                        size: 20,
                                        bold: true
                                    }),
                                    new TextRun({
                                        font: 'Book Antiqua',
                                        size: 20,
                                        bold: true,
                                        children: [' de ', PageNumber.TOTAL_PAGES]
                                    }),
                                ], spacing: { before: 200, after: 100 }
                            })
                        ]
                    })
                },
                footers: {
                    default: new Footer({
                        children: [
                            new Paragraph({ alignment: AlignmentType.CENTER }),
                            this.createFooter()
                        ]
                    }),
                },
                children: [
                    new Paragraph({
                        alignment: AlignmentType.CENTER,
                        children: [
                            new TextRun({
                                text: 'LISTA DE INGREDIENTES',
                                font: 'Calibri (Cuerpo)',
                                size: 24,
                                bold: true
                            })
                        ],
                        spacing: { after: 200 }
                    }),
                    new Paragraph({
                        alignment: AlignmentType.CENTER,
                        children: [
                            new TextRun({
                                text: 'Receta para Productos Procesados',
                                font: 'Calibri (Cuerpo)',
                                size: 22,
                                bold: true
                            })
                        ],
                        spacing: { after: 200 }
                    }),
                    new Paragraph({
                        alignment: AlignmentType.LEFT,
                        children: [
                            new TextRun({
                                text: `Nombre del Operador: ${data.customer.Legal_Authorities[0].Name} ${data.customer.Legal_Authorities[0].Middle_Name} ${data.customer.Legal_Authorities[0].Last_Name ? data.customer.Legal_Authorities[0].Last_Name : ''}`,
                                font: 'Calibri (Cuerpo)',
                                size: 22
                            })
                        ]
                    }),
                    new Paragraph({
                        alignment: AlignmentType.LEFT,
                        children: [
                            new TextRun({
                                text: `Producto: ${producto.receta_nombre_producto}`,
                                font: 'Calibri (Cuerpo)',
                                size: 22
                            })
                        ]
                    }),
                    new Paragraph({
                        alignment: AlignmentType.LEFT,
                        children: [
                            new TextRun({
                                text: `Cantidad de Producto Terminado (Kg/Lt): ${producto.receta_cantidad_producto_terminado}`,
                                font: 'Calibri (Cuerpo)',
                                size: 22
                            })
                        ],
                        spacing: { after: 200 }
                    }),
                    this.createTableIngredientes(producto),
                    new Paragraph({
                        spacing: {
                            after: 200
                        }
                    }),
                    this.createTableAditivos(producto),
                    new Paragraph({
                        spacing: {
                            after: 200
                        }
                    }),
                    this.createTableAguaSal(producto),
                    new Paragraph({
                        spacing: {
                            after: 200
                        }
                    }),
                    this.createTableTotales(producto),
                    new Paragraph({
                        alignment: AlignmentType.CENTER,
                        children: [
                            signatureImage ? Media.addImage(document, signatureImage.toString(), 200, 100) : new TextRun({
                                text: ''
                            })
                        ]
                    }),
                    new Paragraph({
                        alignment: AlignmentType.CENTER,
                        children: [
                            new TextRun({
                                text: `Fecha: ${datePipe.transform(new Date(data.record.Applications[0].Creation_Date.includes('UTC') ? data.record.Applications[0].Creation_Date.replace('UTC', '').trim() : data.record.Applications[0].Creation_Date), 'd-MMMM-y')}                  Nombre y Firma del Operador: ${data.customer.Legal_Authorities[0].Name} ${data.customer.Legal_Authorities[0].Middle_Name} ${data.customer.Legal_Authorities[0].Last_Name ? data.customer.Legal_Authorities[0].Last_Name : ''}`,
                                font: 'Arial',
                                size: 24,
                                italics: true
                            })
                        ],
                        spacing: {
                            before: 200,
                            after: 200
                        }
                    }),
                ],
                margins: {
                    top: 350,
                    bottom: 500,
                    left: 1000,
                    right: 1000
                },
                size: {
                    height: 23000,
                    width: 18000
                }
            });

        }

        return document;
    }

    public createFooter(): Table {
        const table = new Table({
            rows: [
                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: 'Elaboró:',
                                            font: 'Calibri (Cuerpo)',
                                            size: 22
                                        })
                                    ]
                                })
                            ],
                            width: {
                                size: 33,
                                type: WidthType.PERCENTAGE
                            }
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: 'Revisó:',
                                            font: 'Calibri (Cuerpo)',
                                            size: 22
                                        })
                                    ]
                                })
                            ],
                            width: {
                                size: 33,
                                type: WidthType.PERCENTAGE
                            }
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: 'Autorizó:',
                                            font: 'Calibri (Cuerpo)',
                                            size: 22
                                        })
                                    ]
                                }),
                            ],
                            width: {
                                size: 33,
                                type: WidthType.PERCENTAGE
                            }
                        })
                    ]
                }),
                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: 'Director de certificación',
                                            font: 'Calibri (Cuerpo)',
                                            size: 16,
                                            bold: true
                                        })
                                    ]
                                }),

                            ],
                            width: {
                                size: 33,
                                type: WidthType.PERCENTAGE
                            },
                            shading: {
                                fill: '#C0C0C0'
                            }
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: 'Responsable de calidad',
                                            font: 'Calibri (Cuerpo)',
                                            size: 16,
                                            bold: true
                                        })
                                    ]
                                }),

                            ],
                            width: {
                                size: 33,
                                type: WidthType.PERCENTAGE
                            },
                            shading: {
                                fill: '#C0C0C0'
                            }
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: 'Director general',
                                            font: 'Calibri (Cuerpo)',
                                            size: 16,
                                            bold: true
                                        })
                                    ]
                                }),
                            ],
                            width: {
                                size: 33,
                                type: WidthType.PERCENTAGE
                            },
                            shading: {
                                fill: '#C0C0C0'
                            }
                        })
                    ]
                })
            ],
            margins: {
                left: 70,
                right: 70
            },
            columnWidths: [3213, 3213, 3212],
            alignment: AlignmentType.CENTER

        })
        return table
    }

    public createTableIngredientes(data) {
        const table = new Table({
            rows: [
                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: "INGREDIENTES AGRICOLAS",
                                            font: 'Arial',
                                            size: 22
                                        })
                                    ],
                                    spacing: { before: 100, after: 200 }
                                })
                            ],
                            shading: {
                                fill: "#BFBFBF"
                            },
                            verticalAlign: VerticalAlign.CENTER
                        })
                    ]
                }),
                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: "INGREDIENTE",
                                            font: 'Arial',
                                            size: 22
                                        })
                                    ],
                                    spacing: { before: 100, after: 200 }
                                })
                            ],
                            columnSpan: 2,
                            verticalAlign: VerticalAlign.CENTER
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: "PROVEEDOR",
                                            font: 'Arial',
                                            size: 22
                                        })
                                    ],
                                    spacing: { before: 100, after: 200 }
                                })
                            ],
                            columnSpan: 1,
                            verticalAlign: VerticalAlign.CENTER
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: "CERTIFICADORA",
                                            font: 'Arial',
                                            size: 22
                                        })
                                    ],
                                    spacing: { before: 100, after: 200 }
                                })
                            ],
                            columnSpan: 1,
                            verticalAlign: VerticalAlign.CENTER
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: "NORMA",
                                            font: 'Arial',
                                            size: 22
                                        })
                                    ],
                                    spacing: { before: 100, after: 200 }
                                })
                            ],
                            columnSpan: 1,
                            verticalAlign: VerticalAlign.CENTER
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: "CANTIDAD CONV",
                                            font: 'Arial',
                                            size: 22
                                        })
                                    ],
                                    spacing: { before: 100, after: 200 }
                                })
                            ],
                            columnSpan: 1,
                            verticalAlign: VerticalAlign.CENTER
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: "CANTIDAD ORG",
                                            font: 'Arial',
                                            size: 22
                                        })
                                    ],
                                    spacing: { before: 100, after: 200 }
                                })
                            ],
                            columnSpan: 1,
                            verticalAlign: VerticalAlign.CENTER
                        })
                    ]
                })
            ],
            margins: {
                left: 70,
                right: 70
            },
            width: {
                size: 100,
                type: WidthType.PERCENTAGE,
            },
            columnWidths: [1312, 5912, 4902, 3912, 2312, 2312, 2312]
        })

        const listaIngredientesAgricolas = data.lista_ingredientes_agricolas
        listaIngredientesAgricolas.forEach((ingrediente, i) => {
            const cantidad_convencional = isNaN(parseFloat(ingrediente.ingredientes_cantidad_convencional)) ? 0 : parseFloat(ingrediente.ingredientes_cantidad_convencional)
            const cantidad_organica = isNaN(parseFloat(ingrediente.ingredientes_cantidad_organica)) ? 0 : parseFloat(ingrediente.ingredientes_cantidad_organica)

            table.addChildElement(new TableRow({
                children: [
                    new TableCell({
                        children: [
                            new Paragraph({
                                alignment: AlignmentType.CENTER,
                                children: [
                                    new TextRun({
                                        text: `${i+1}`,
                                        font: 'Arial',
                                        size: 22
                                    })
                                ],
                                spacing: { before: 100, after: 200 }
                            })
                        ],
                        verticalAlign: VerticalAlign.CENTER
                    }),
                    new TableCell({
                        children: [
                            new Paragraph({
                                alignment: AlignmentType.LEFT,
                                children: [
                                    new TextRun({
                                        text: `${ingrediente.ingredientes_ingrediente}`,
                                        font: 'Arial',
                                        size: 22
                                    })
                                ],
                                spacing: { before: 100, after: 200 }
                            })
                        ],
                        verticalAlign: VerticalAlign.CENTER
                    }),
                    new TableCell({
                        children: [
                            new Paragraph({
                                alignment: AlignmentType.LEFT,
                                children: [
                                    new TextRun({
                                        text: `${ingrediente.ingredientes_proveedor}`,
                                        font: 'Arial',
                                        size: 22
                                    })
                                ],
                                spacing: { before: 100, after: 200 }
                            })
                        ],
                        verticalAlign: VerticalAlign.CENTER
                    }),
                    new TableCell({
                        children: [
                            new Paragraph({
                                alignment: AlignmentType.LEFT,
                                children: [
                                    new TextRun({
                                        text: `${ingrediente.ingredientes_certificadora}`,
                                        font: 'Arial',
                                        size: 22
                                    })
                                ],
                                spacing: { before: 100, after: 200 }
                            })
                        ],
                        verticalAlign: VerticalAlign.CENTER
                    }),
                    new TableCell({
                        children: [
                            new Paragraph({
                                alignment: AlignmentType.CENTER,
                                children: [
                                    new TextRun({
                                        text: `${ingrediente.ingredientes_norma}`,
                                        font: 'Arial',
                                        size: 22
                                    })
                                ],
                                spacing: { before: 100, after: 200 }
                            })
                        ],
                        verticalAlign: VerticalAlign.CENTER
                    }),
                    new TableCell({
                        children: [
                            new Paragraph({
                                alignment: AlignmentType.CENTER,
                                children: [
                                    new TextRun({
                                        text: Number(cantidad_convencional).toLocaleString('en-US', { minimumFractionDigits: 4, maximumFractionDigits: 4 }),
                                        font: 'Arial',
                                        size: 22
                                    })
                                ],
                                spacing: { before: 100, after: 200 }
                            })
                        ],
                        verticalAlign: VerticalAlign.CENTER
                    }),
                    new TableCell({
                        children: [
                            new Paragraph({
                                alignment: AlignmentType.CENTER,
                                children: [
                                    new TextRun({
                                        text: Number(cantidad_organica).toLocaleString('en-US', { minimumFractionDigits: 4, maximumFractionDigits: 4 }),
                                        font: 'Arial',
                                        size: 22
                                    })
                                ],
                                spacing: { before: 100, after: 200 }
                            })
                        ],
                        verticalAlign: VerticalAlign.CENTER
                    })
                ]
            }))

            this.subTotalAgricolas += (cantidad_convencional + cantidad_organica)
            this.subTotalOrganicos += cantidad_organica

            this.cantidadOrganicos = ingrediente.ingredientes_ingrediente.length ? this.cantidadOrganicos + 1 : this.cantidadOrganicos
        });
        this.cantidadIngredientes += this.cantidadOrganicos

        table.addChildElement(new TableRow({
            children: [
                new TableCell({
                    children: [
                        new Paragraph({
                            alignment: AlignmentType.CENTER,
                            children: [
                                new TextRun({
                                    text: listaIngredientesAgricolas.length,
                                    font: 'Arial',
                                    size: 22
                                })
                            ],
                            spacing: { before: 100, after: 200 }
                        })
                    ],
                    columnSpan: 1,
                    verticalAlign: VerticalAlign.CENTER
                }),
                new TableCell({
                    children: [
                        new Paragraph({
                            alignment: AlignmentType.LEFT,
                            children: [
                                new TextRun({
                                    text: 'Cantidad de Ingredientes Agricolas',
                                    font: 'Arial',
                                    size: 22
                                })
                            ],
                            spacing: { before: 100, after: 200 }
                        })
                    ],
                    columnSpan: 2,
                    borders: {
                        top: {
                            style: BorderStyle.NONE,
                            color: "white",
                            size: 0
                        },
                        bottom: {
                            style: BorderStyle.NONE,
                            color: "white",
                            size: 0
                        },
                        left: {
                            style: BorderStyle.NONE,
                            color: "white",
                            size: 0
                        },
                        right: {
                            style: BorderStyle.NONE,
                            color: "white",
                            size: 0
                        }
                    },
                    verticalAlign: VerticalAlign.CENTER
                }),
                new TableCell({
                    children: [
                        new Paragraph({
                            alignment: AlignmentType.RIGHT,
                            children: [
                                new TextRun({
                                    text: `Subtotal Ingredientes Orgánicos`,
                                    font: 'Arial',
                                    size: 22
                                })
                            ],
                            spacing: { before: 100, after: 200 }
                        })
                    ],
                    columnSpan: 3,
                    borders: {
                        top: {
                            style: BorderStyle.NONE,
                            color: "white",
                            size: 0
                        },
                        bottom: {
                            style: BorderStyle.NONE,
                            color: "white",
                            size: 0
                        },
                        left: {
                            style: BorderStyle.NONE,
                            color: "white",
                            size: 0
                        },
                        right: {
                            style: BorderStyle.NONE,
                            color: "white",
                            size: 0
                        }
                    },
                    verticalAlign: VerticalAlign.CENTER
                }),
                new TableCell({
                    children: [
                        new Paragraph({
                            alignment: AlignmentType.CENTER,
                            children: [
                                new TextRun({
                                    text: Number(this.subTotalOrganicos).toLocaleString('en-US', { minimumFractionDigits: 4, maximumFractionDigits: 4 }),
                                    font: 'Arial',
                                    size: 22
                                })
                            ],
                            spacing: { before: 100, after: 200 }
                        })
                    ],
                    columnSpan: 1,
                    verticalAlign: VerticalAlign.CENTER
                }),
            ]
        }))
        table.addChildElement(new TableRow({
            children: [
                new TableCell({
                    children: [
                        new Paragraph({
                            alignment: AlignmentType.RIGHT,
                            children: [
                                new TextRun({
                                    text: '',
                                    font: 'Arial',
                                    size: 22
                                })
                            ],
                            spacing: { before: 100, after: 200 }
                        })
                    ],
                    columnSpan: 3,
                    borders: {
                        top: {
                            style: BorderStyle.NONE,
                            color: "white",
                            size: 0
                        },
                        bottom: {
                            style: BorderStyle.NONE,
                            color: "white",
                            size: 0
                        },
                        left: {
                            style: BorderStyle.NONE,
                            color: "white",
                            size: 0
                        },
                        right: {
                            style: BorderStyle.NONE,
                            color: "white",
                            size: 0
                        }
                    },
                    verticalAlign: VerticalAlign.CENTER
                }),
                new TableCell({
                    children: [
                        new Paragraph({
                            alignment: AlignmentType.RIGHT,
                            children: [
                                new TextRun({
                                    text: `Subtotal Ingredientes Agricolas`,
                                    font: 'Arial',
                                    size: 22
                                })
                            ],
                            spacing: { before: 100, after: 200 }
                        })
                    ],
                    columnSpan: 3,
                    borders: {
                        top: {
                            style: BorderStyle.NONE,
                            color: "white",
                            size: 0
                        },
                        bottom: {
                            style: BorderStyle.NONE,
                            color: "white",
                            size: 0
                        },
                        left: {
                            style: BorderStyle.NONE,
                            color: "white",
                            size: 0
                        },
                        right: {
                            style: BorderStyle.NONE,
                            color: "white",
                            size: 0
                        }
                    },
                    verticalAlign: VerticalAlign.CENTER
                }),
                new TableCell({
                    children: [
                        new Paragraph({
                            alignment: AlignmentType.CENTER,
                            children: [
                                new TextRun({
                                    text: Number(this.subTotalAgricolas).toLocaleString('en-US', { minimumFractionDigits: 4, maximumFractionDigits: 4 }),
                                    font: 'Arial',
                                    size: 22
                                })
                            ],
                            spacing: { before: 100, after: 200 }
                        })
                    ],
                    columnSpan: 1,
                    verticalAlign: VerticalAlign.CENTER
                }),
            ]
        }))

        return table
    }

    public createTableAditivos(data) {
        const table = new Table({
            rows: [
                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: "ADITIVOS, AROMAS NATURALES Y OTROS INGREDIENTES",
                                            font: 'Arial',
                                            size: 22
                                        })
                                    ],
                                    spacing: { before: 100, after: 200 }
                                })
                            ],
                            shading: {
                                fill: "#BFBFBF"
                            },
                            verticalAlign: VerticalAlign.CENTER
                        })
                    ]
                }),
                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: "INGREDIENTE",
                                            font: 'Arial',
                                            size: 22
                                        })
                                    ],
                                    spacing: { before: 100, after: 200 }
                                })
                            ],
                            columnSpan: 2,
                            verticalAlign: VerticalAlign.CENTER
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: "SINTETICO",
                                            font: 'Arial',
                                            size: 22
                                        })
                                    ],
                                    spacing: { before: 100, after: 200 }
                                })
                            ],
                            columnSpan: 1,
                            verticalAlign: VerticalAlign.CENTER
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: "PROVEEDOR",
                                            font: 'Arial',
                                            size: 22
                                        })
                                    ],
                                    spacing: { before: 100, after: 200 }
                                })
                            ],
                            columnSpan: 1,
                            verticalAlign: VerticalAlign.CENTER
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: "PROPOSITO",
                                            font: 'Arial',
                                            size: 22
                                        })
                                    ],
                                    spacing: { before: 100, after: 200 }
                                })
                            ],
                            columnSpan: 1,
                            verticalAlign: VerticalAlign.CENTER
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: "CANTIDAD",
                                            font: 'Arial',
                                            size: 22
                                        })
                                    ],
                                    spacing: { before: 100, after: 200 }
                                })
                            ],
                            columnSpan: 1,
                            verticalAlign: VerticalAlign.CENTER
                        })
                    ]
                })
            ],
            margins: {
                left: 70,
                right: 70
            },
            width: {
                size: 100,
                type: WidthType.PERCENTAGE,
            },
            columnWidths: [1012, 5212, 2012, 4912, 4912, 2012]
        })

        const listaAditivos = data.lista_aditivos
        listaAditivos.forEach((ingrediente, i) => {
            const aditivos_cantidad = isNaN(parseFloat(ingrediente.aditivos_cantidad)) ? 0 : parseFloat(ingrediente.aditivos_cantidad)

            table.addChildElement(new TableRow({
                children: [
                    new TableCell({
                        children: [
                            new Paragraph({
                                alignment: AlignmentType.CENTER,
                                children: [
                                    new TextRun({
                                        text: `${i+1}`,
                                        font: 'Arial',
                                        size: 22
                                    })
                                ],
                                spacing: { before: 100, after: 200 }
                            })
                        ],
                        verticalAlign: VerticalAlign.CENTER
                    }),
                    new TableCell({
                        children: [
                            new Paragraph({
                                alignment: AlignmentType.LEFT,
                                children: [
                                    new TextRun({
                                        text: `${ingrediente.aditivos_ingrediente}`,
                                        font: 'Arial',
                                        size: 22
                                    })
                                ],
                                spacing: { before: 100, after: 200 }
                            })
                        ],
                        verticalAlign: VerticalAlign.CENTER
                    }),
                    new TableCell({
                        children: [
                            new Paragraph({
                                alignment: AlignmentType.LEFT,
                                children: [
                                    new TextRun({
                                        text: `${ingrediente.aditivos_sintetico}`,
                                        font: 'Arial',
                                        size: 22
                                    })
                                ],
                                spacing: { before: 100, after: 200 }
                            })
                        ],
                        verticalAlign: VerticalAlign.CENTER
                    }),
                    new TableCell({
                        children: [
                            new Paragraph({
                                alignment: AlignmentType.LEFT,
                                children: [
                                    new TextRun({
                                        text: `${ingrediente.aditivos_proveedor}`,
                                        font: 'Arial',
                                        size: 22
                                    })
                                ],
                                spacing: { before: 100, after: 200 }
                            })
                        ],
                        verticalAlign: VerticalAlign.CENTER
                    }),
                    new TableCell({
                        children: [
                            new Paragraph({
                                alignment: AlignmentType.CENTER,
                                children: [
                                    new TextRun({
                                        text: `${ingrediente.aditivos_proposito}`,
                                        font: 'Arial',
                                        size: 22
                                    })
                                ],
                                spacing: { before: 100, after: 200 }
                            })
                        ],
                        verticalAlign: VerticalAlign.CENTER
                    }),
                    new TableCell({
                        children: [
                            new Paragraph({
                                alignment: AlignmentType.CENTER,
                                children: [
                                    new TextRun({
                                        text: Number(aditivos_cantidad).toLocaleString('en-US', { minimumFractionDigits: 4, maximumFractionDigits: 4 }),
                                        font: 'Arial',
                                        size: 22
                                    })
                                ],
                                spacing: { before: 100, after: 200 }
                            })
                        ],
                        verticalAlign: VerticalAlign.CENTER
                    })
                ]
            }))

            this.subTotalOtros = this.subTotalOtros + (isNaN(parseFloat(ingrediente.aditivos_cantidad)) ? 0 : parseFloat(ingrediente.aditivos_cantidad))

            this.cantidadOtros = ingrediente.aditivos_ingrediente.length ? this.cantidadOtros + 1 : this.cantidadOtros
        });
        this.cantidadIngredientes += this.cantidadOtros
        this.subTotalOtros = this.cantidadOtros > 0 ? this.subTotalOtros : 0

        table.addChildElement(new TableRow({
            children: [
                new TableCell({
                    children: [
                        new Paragraph({
                            alignment: AlignmentType.CENTER,
                            children: [
                                new TextRun({
                                    text: `${this.cantidadOtros}`,
                                    font: 'Arial',
                                    size: 22
                                })
                            ],
                            spacing: { before: 100, after: 200 }
                        })
                    ],
                    columnSpan: 1,
                    verticalAlign: VerticalAlign.CENTER
                }),
                new TableCell({
                    children: [
                        new Paragraph({
                            alignment: AlignmentType.LEFT,
                            children: [
                                new TextRun({
                                    text: 'Cantidad de Ingredientes No Agricolas',
                                    font: 'Arial',
                                    size: 22
                                })
                            ],
                            spacing: { before: 100, after: 200 }
                        })
                    ],
                    columnSpan: 2,
                    borders: {
                        top: {
                            style: BorderStyle.NONE,
                            color: "white",
                            size: 0
                        },
                        bottom: {
                            style: BorderStyle.NONE,
                            color: "white",
                            size: 0
                        },
                        left: {
                            style: BorderStyle.NONE,
                            color: "white",
                            size: 0
                        },
                        right: {
                            style: BorderStyle.NONE,
                            color: "white",
                            size: 0
                        }
                    },
                    verticalAlign: VerticalAlign.CENTER
                }),
                new TableCell({
                    children: [
                        new Paragraph({
                            alignment: AlignmentType.RIGHT,
                            children: [
                                new TextRun({
                                    text: `Subtotal Otros Ingredientes`,
                                    font: 'Arial',
                                    size: 22
                                })
                            ],
                            spacing: { before: 100, after: 200 }
                        })
                    ],
                    columnSpan: 2,
                    borders: {
                        top: {
                            style: BorderStyle.NONE,
                            color: "white",
                            size: 0
                        },
                        bottom: {
                            style: BorderStyle.NONE,
                            color: "white",
                            size: 0
                        },
                        left: {
                            style: BorderStyle.NONE,
                            color: "white",
                            size: 0
                        },
                        right: {
                            style: BorderStyle.NONE,
                            color: "white",
                            size: 0
                        }
                    },
                    verticalAlign: VerticalAlign.CENTER
                }),
                new TableCell({
                    children: [
                        new Paragraph({
                            alignment: AlignmentType.CENTER,
                            children: [
                                new TextRun({
                                    text: Number(this.subTotalOtros).toLocaleString('en-US', { minimumFractionDigits: 4, maximumFractionDigits: 4 }),
                                    font: 'Arial',
                                    size: 22
                                })
                            ],
                            spacing: { before: 100, after: 200 }
                        })
                    ],
                    columnSpan: 1,
                    verticalAlign: VerticalAlign.CENTER
                }),
            ]
        }))

        return table
    }

    public createTableAguaSal(data) {
        //console.log(data)
        const agua = isNaN(parseFloat(data.agua)) ? 0 : parseFloat(data.agua)
        const sal = isNaN(parseFloat(data.sal)) ? 0 : parseFloat(data.sal)

        this.subtotalAguaSal = agua + sal
        this.cantidadIngredientesSinAguaSal = this.subTotalAgricolas + this.subTotalOtros
        this.porcentajeProductosOrganicos = this.subTotalOrganicos/this.cantidadIngredientesSinAguaSal
        const porcentaje = this.porcentajeProductosOrganicos * 100

        const table = new Table({
            rows: [
                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: "",
                                            font: 'Arial',
                                            size: 22
                                        })
                                    ],
                                    spacing: { before: 100, after: 200 }
                                })
                            ],
                            columnSpan: 4,
                            verticalAlign: VerticalAlign.CENTER,
                            borders: {
                                top: {
                                    style: BorderStyle.NONE,
                                    color: "white",
                                    size: 0
                                },
                                bottom: {
                                    style: BorderStyle.NONE,
                                    color: "white",
                                    size: 0
                                },
                                left: {
                                    style: BorderStyle.NONE,
                                    color: "white",
                                    size: 0
                                },
                                right: {
                                    style: BorderStyle.NONE,
                                    color: "white",
                                    size: 0
                                }
                            }
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: "AGUA Y SAL",
                                            font: 'Arial',
                                            size: 22
                                        })
                                    ],
                                    spacing: { before: 100, after: 200 }
                                })
                            ],
                            columnSpan: 1,
                            shading: {
                                fill: "#BFBFBF"
                            },
                            verticalAlign: VerticalAlign.CENTER
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: "CANTDAD",
                                            font: 'Arial',
                                            size: 22
                                        })
                                    ],
                                    spacing: { before: 100, after: 200 }
                                })
                            ],
                            columnSpan: 1,
                            shading: {
                                fill: "#BFBFBF"
                            },
                            verticalAlign: VerticalAlign.CENTER
                        })
                    ]
                }),

                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: ``,
                                            font: 'Arial',
                                            size: 22
                                        })
                                    ],
                                    spacing: { before: 100, after: 200 }
                                })
                            ],
                            columnSpan: 1,
                            verticalAlign: VerticalAlign.CENTER,
                            borders: {
                                top: {
                                    style: BorderStyle.NONE,
                                    color: "white",
                                    size: 0
                                },
                                bottom: {
                                    style: BorderStyle.NONE,
                                    color: "white",
                                    size: 0
                                },
                                left: {
                                    style: BorderStyle.NONE,
                                    color: "white",
                                    size: 0
                                },
                                right: {
                                    style: BorderStyle.NONE,
                                    color: "white",
                                    size: 0
                                }
                            }
                        }),

                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: 'Cantidad de Ingredientes',
                                            font: 'Arial',
                                            size: 24,
                                            bold: true
                                        })
                                    ],
                                    spacing: { before: 100, after: 200 }
                                })
                            ],
                            columnSpan: 1,
                            shading: {
                                fill: "#BFBFBF"
                            },
                            verticalAlign: VerticalAlign.CENTER
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: `${this.cantidadIngredientes}`,
                                            font: 'Arial',
                                            size: 22
                                        })
                                    ],
                                    spacing: { before: 100, after: 200 }
                                })
                            ],
                            columnSpan: 1,
                            verticalAlign: VerticalAlign.CENTER
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: ``,
                                            font: 'Arial',
                                            size: 22
                                        })
                                    ],
                                    spacing: { before: 100, after: 200 }
                                })
                            ],
                            columnSpan: 1,
                            verticalAlign: VerticalAlign.CENTER,
                            borders: {
                                top: {
                                    style: BorderStyle.NONE,
                                    color: "white",
                                    size: 0
                                },
                                bottom: {
                                    style: BorderStyle.NONE,
                                    color: "white",
                                    size: 0
                                },
                                left: {
                                    style: BorderStyle.NONE,
                                    color: "white",
                                    size: 0
                                },
                                right: {
                                    style: BorderStyle.NONE,
                                    color: "white",
                                    size: 0
                                }
                            }
                        }),

                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: "AGUA",
                                            font: 'Arial',
                                            size: 22
                                        })
                                    ],
                                    spacing: { before: 100, after: 200 }
                                })
                            ],
                            columnSpan: 1,
                            verticalAlign: VerticalAlign.CENTER
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: Number(agua).toLocaleString('en-US', { minimumFractionDigits: 4, maximumFractionDigits: 4 }),
                                            font: 'Arial',
                                            size: 22
                                        })
                                    ],
                                    spacing: { before: 100, after: 200 }
                                })
                            ],
                            columnSpan: 1,
                            verticalAlign: VerticalAlign.CENTER
                        })
                    ]
                }),

                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: ``,
                                            font: 'Arial',
                                            size: 22
                                        })
                                    ],
                                    spacing: { before: 100, after: 200 }
                                })
                            ],
                            columnSpan: 1,
                            verticalAlign: VerticalAlign.CENTER,
                            borders: {
                                top: {
                                    style: BorderStyle.NONE,
                                    color: "white",
                                    size: 0
                                },
                                bottom: {
                                    style: BorderStyle.NONE,
                                    color: "white",
                                    size: 0
                                },
                                left: {
                                    style: BorderStyle.NONE,
                                    color: "white",
                                    size: 0
                                },
                                right: {
                                    style: BorderStyle.NONE,
                                    color: "white",
                                    size: 0
                                }
                            }
                        }),

                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: 'Porcentaje de Ingredientes Orgánicos',
                                            font: 'Arial',
                                            size: 24,
                                            bold: true
                                        })
                                    ],
                                    spacing: { before: 100, after: 200 }
                                })
                            ],
                            columnSpan: 1,
                            shading: {
                                fill: "#BFBFBF"
                            },
                            verticalAlign: VerticalAlign.CENTER
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: `${Number(porcentaje).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}%`,
                                            font: 'Arial',
                                            size: 22
                                        })
                                    ],
                                    spacing: { before: 100, after: 200 }
                                })
                            ],
                            columnSpan: 1,
                            verticalAlign: VerticalAlign.CENTER
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: ``,
                                            font: 'Arial',
                                            size: 22
                                        })
                                    ],
                                    spacing: { before: 100, after: 200 }
                                })
                            ],
                            columnSpan: 1,
                            verticalAlign: VerticalAlign.CENTER,
                            borders: {
                                top: {
                                    style: BorderStyle.NONE,
                                    color: "white",
                                    size: 0
                                },
                                bottom: {
                                    style: BorderStyle.NONE,
                                    color: "white",
                                    size: 0
                                },
                                left: {
                                    style: BorderStyle.NONE,
                                    color: "white",
                                    size: 0
                                },
                                right: {
                                    style: BorderStyle.NONE,
                                    color: "white",
                                    size: 0
                                }
                            }
                        }),

                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: "SAL",
                                            font: 'Arial',
                                            size: 22
                                        })
                                    ],
                                    spacing: { before: 100, after: 200 }
                                })
                            ],
                            columnSpan: 1,
                            verticalAlign: VerticalAlign.CENTER
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: Number(sal).toLocaleString('en-US', { minimumFractionDigits: 4, maximumFractionDigits: 4 }),
                                            font: 'Arial',
                                            size: 22
                                        })
                                    ],
                                    spacing: { before: 100, after: 200 }
                                })
                            ],
                            columnSpan: 1,
                            verticalAlign: VerticalAlign.CENTER
                        })
                    ]
                }),

                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: ``,
                                            font: 'Arial',
                                            size: 22
                                        })
                                    ],
                                    spacing: { before: 100, after: 200 }
                                })
                            ],
                            columnSpan: 4,
                            verticalAlign: VerticalAlign.CENTER,
                            borders: {
                                top: {
                                    style: BorderStyle.NONE,
                                    color: "white",
                                    size: 0
                                },
                                bottom: {
                                    style: BorderStyle.NONE,
                                    color: "white",
                                    size: 0
                                },
                                left: {
                                    style: BorderStyle.NONE,
                                    color: "white",
                                    size: 0
                                },
                                right: {
                                    style: BorderStyle.NONE,
                                    color: "white",
                                    size: 0
                                }
                            }
                        }),

                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: "Subtotal Agua y Sal:",
                                            font: 'Arial',
                                            size: 22
                                        })
                                    ],
                                    spacing: { before: 100, after: 200 }
                                })
                            ],
                            columnSpan: 1,
                            verticalAlign: VerticalAlign.CENTER
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: Number(this.subtotalAguaSal).toLocaleString('en-US', { minimumFractionDigits: 4, maximumFractionDigits: 4 }),
                                            font: 'Arial',
                                            size: 22
                                        })
                                    ],
                                    spacing: { before: 100, after: 200 }
                                })
                            ],
                            columnSpan: 1,
                            verticalAlign: VerticalAlign.CENTER
                        })
                    ]
                }),
            ],
            margins: {
                left: 70,
                right: 70
            },
            width: {
                size: 100,
                type: WidthType.PERCENTAGE,
            },
            columnWidths: [1012, 6216, 3016, 3882, 3930, 2012]
        })

        return table
    }

    public createTableTotales(data) {
        const totalIngredientes = this.subTotalAgricolas + this.subTotalOtros + this.subtotalAguaSal

        const table = new Table({
            rows: [
                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: "",
                                            font: 'Arial',
                                            size: 22
                                        })
                                    ],
                                    spacing: { before: 100, after: 200 }
                                })
                            ],
                            rowSpan: 2,
                            columnSpan: 1,
                            verticalAlign: VerticalAlign.CENTER,
                            borders: {
                                top: {
                                    style: BorderStyle.NONE,
                                    color: "white",
                                    size: 0
                                },
                                bottom: {
                                    style: BorderStyle.NONE,
                                    color: "white",
                                    size: 0
                                },
                                left: {
                                    style: BorderStyle.NONE,
                                    color: "white",
                                    size: 0
                                },
                                right: {
                                    style: BorderStyle.NONE,
                                    color: "white",
                                    size: 0
                                }
                            }
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: "Categoría de producto LPO",
                                            font: 'Arial',
                                            size: 22
                                        })
                                    ],
                                    spacing: { before: 100, after: 200 }
                                })
                            ],
                            rowSpan: 2,
                            columnSpan: 1,
                            verticalAlign: VerticalAlign.CENTER,
                            shading: {
                                fill: "#76933C"
                            },
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: this.getCategoriaLPO(),
                                            font: 'Arial',
                                            size: 22
                                        })
                                    ],
                                    spacing: { before: 100, after: 200 }
                                })
                            ],
                            rowSpan: 2,
                            columnSpan: 1,
                            verticalAlign: VerticalAlign.CENTER,
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: "",
                                            font: 'Arial',
                                            size: 22
                                        })
                                    ],
                                    spacing: { before: 100, after: 200 }
                                })
                            ],
                            rowSpan: 2,
                            columnSpan: 1,
                            verticalAlign: VerticalAlign.CENTER,
                            borders: {
                                top: {
                                    style: BorderStyle.NONE,
                                    color: "white",
                                    size: 0
                                },
                                bottom: {
                                    style: BorderStyle.NONE,
                                    color: "white",
                                    size: 0
                                },
                                left: {
                                    style: BorderStyle.NONE,
                                    color: "white",
                                    size: 0
                                },
                                right: {
                                    style: BorderStyle.NONE,
                                    color: "white",
                                    size: 0
                                }
                            }
                        }),

                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.RIGHT,
                                    children: [
                                        new TextRun({
                                            text: "Total de Ingredientes Orgánicos:",
                                            font: 'Arial',
                                            size: 22
                                        })
                                    ],
                                    spacing: { before: 100, after: 200 }
                                })
                            ],
                            columnSpan: 1,
                            verticalAlign: VerticalAlign.CENTER,
                            borders: {
                                top: {
                                    style: BorderStyle.NONE,
                                    color: "white",
                                    size: 0
                                },
                                bottom: {
                                    style: BorderStyle.NONE,
                                    color: "white",
                                    size: 0
                                },
                                left: {
                                    style: BorderStyle.NONE,
                                    color: "white",
                                    size: 0
                                },
                                right: {
                                    style: BorderStyle.NONE,
                                    color: "white",
                                    size: 0
                                }
                            }
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: `${Number(this.subTotalOrganicos).toLocaleString('en-US', { minimumFractionDigits: 4, maximumFractionDigits: 4 })}`,
                                            font: 'Arial',
                                            size: 22
                                        })
                                    ],
                                    spacing: { before: 100, after: 200 }
                                })
                            ],
                            columnSpan: 1,
                            verticalAlign: VerticalAlign.CENTER
                        })
                    ]
                }),
                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.RIGHT,
                                    children: [
                                        new TextRun({
                                            text: "Total de Ingredientes Agrícolas:",
                                            font: 'Arial',
                                            size: 22
                                        })
                                    ],
                                    spacing: { before: 100, after: 200 }
                                })
                            ],
                            columnSpan: 1,
                            verticalAlign: VerticalAlign.CENTER,
                            borders: {
                                top: {
                                    style: BorderStyle.NONE,
                                    color: "white",
                                    size: 0
                                },
                                bottom: {
                                    style: BorderStyle.NONE,
                                    color: "white",
                                    size: 0
                                },
                                left: {
                                    style: BorderStyle.NONE,
                                    color: "white",
                                    size: 0
                                },
                                right: {
                                    style: BorderStyle.NONE,
                                    color: "white",
                                    size: 0
                                }
                            }
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: `${Number(this.subTotalAgricolas).toLocaleString('en-US', { minimumFractionDigits: 4, maximumFractionDigits: 4 })}`,
                                            font: 'Arial',
                                            size: 22
                                        })
                                    ],
                                    spacing: { before: 100, after: 200 }
                                })
                            ],
                            columnSpan: 1,
                            verticalAlign: VerticalAlign.CENTER
                        })
                    ]
                }),

                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: "",
                                            font: 'Arial',
                                            size: 22
                                        })
                                    ],
                                    spacing: { before: 100, after: 200 }
                                })
                            ],
                            rowSpan: 2,
                            columnSpan: 1,
                            verticalAlign: VerticalAlign.CENTER,
                            borders: {
                                top: {
                                    style: BorderStyle.NONE,
                                    color: "white",
                                    size: 0
                                },
                                bottom: {
                                    style: BorderStyle.NONE,
                                    color: "white",
                                    size: 0
                                },
                                left: {
                                    style: BorderStyle.NONE,
                                    color: "white",
                                    size: 0
                                },
                                right: {
                                    style: BorderStyle.NONE,
                                    color: "white",
                                    size: 0
                                }
                            }
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: "Categoría de producto COR",
                                            font: 'Arial',
                                            size: 22
                                        })
                                    ],
                                    spacing: { before: 100, after: 200 }
                                })
                            ],
                            rowSpan: 2,
                            columnSpan: 1,
                            verticalAlign: VerticalAlign.CENTER,
                            shading: {
                                fill: "#E26B0A"
                            },
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: this.getCategoriaCOR(),
                                            font: 'Arial',
                                            size: 22
                                        })
                                    ],
                                    spacing: { before: 100, after: 200 }
                                })
                            ],
                            rowSpan: 2,
                            columnSpan: 1,
                            verticalAlign: VerticalAlign.CENTER,
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: "",
                                            font: 'Arial',
                                            size: 22
                                        })
                                    ],
                                    spacing: { before: 100, after: 200 }
                                })
                            ],
                            rowSpan: 2,
                            columnSpan: 1,
                            verticalAlign: VerticalAlign.CENTER,
                            borders: {
                                top: {
                                    style: BorderStyle.NONE,
                                    color: "white",
                                    size: 0
                                },
                                bottom: {
                                    style: BorderStyle.NONE,
                                    color: "white",
                                    size: 0
                                },
                                left: {
                                    style: BorderStyle.NONE,
                                    color: "white",
                                    size: 0
                                },
                                right: {
                                    style: BorderStyle.NONE,
                                    color: "white",
                                    size: 0
                                }
                            }
                        }),
                        
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.RIGHT,
                                    children: [
                                        new TextRun({
                                            text: "Total Otros Ingredientes:",
                                            font: 'Arial',
                                            size: 22
                                        })
                                    ],
                                    spacing: { before: 100, after: 200 }
                                })
                            ],
                            columnSpan: 1,
                            verticalAlign: VerticalAlign.CENTER,
                            borders: {
                                top: {
                                    style: BorderStyle.NONE,
                                    color: "white",
                                    size: 0
                                },
                                bottom: {
                                    style: BorderStyle.NONE,
                                    color: "white",
                                    size: 0
                                },
                                left: {
                                    style: BorderStyle.NONE,
                                    color: "white",
                                    size: 0
                                },
                                right: {
                                    style: BorderStyle.NONE,
                                    color: "white",
                                    size: 0
                                }
                            }
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: `${Number(this.subTotalOtros).toLocaleString('en-US', { minimumFractionDigits: 4, maximumFractionDigits: 4 })}`,
                                            font: 'Arial',
                                            size: 22
                                        })
                                    ],
                                    spacing: { before: 100, after: 200 }
                                })
                            ],
                            columnSpan: 1,
                            verticalAlign: VerticalAlign.CENTER
                        })
                    ]
                }),
                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.RIGHT,
                                    children: [
                                        new TextRun({
                                            text: "",
                                            font: 'Arial',
                                            size: 22
                                        })
                                    ],
                                    spacing: { before: 100, after: 200 }
                                })
                            ],
                            columnSpan: 2,
                            verticalAlign: VerticalAlign.CENTER,
                            borders: {
                                top: {
                                    style: BorderStyle.NONE,
                                    color: "white",
                                    size: 0
                                },
                                bottom: {
                                    style: BorderStyle.NONE,
                                    color: "white",
                                    size: 0
                                },
                                left: {
                                    style: BorderStyle.NONE,
                                    color: "white",
                                    size: 0
                                },
                                right: {
                                    style: BorderStyle.NONE,
                                    color: "white",
                                    size: 0
                                }
                            }
                        })
                    ]
                }),


                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: "",
                                            font: 'Arial',
                                            size: 22
                                        })
                                    ],
                                    spacing: { before: 100, after: 200 }
                                })
                            ],
                            rowSpan: 2,
                            columnSpan: 1,
                            verticalAlign: VerticalAlign.CENTER,
                            borders: {
                                top: {
                                    style: BorderStyle.NONE,
                                    color: "white",
                                    size: 0
                                },
                                bottom: {
                                    style: BorderStyle.NONE,
                                    color: "white",
                                    size: 0
                                },
                                left: {
                                    style: BorderStyle.NONE,
                                    color: "white",
                                    size: 0
                                },
                                right: {
                                    style: BorderStyle.NONE,
                                    color: "white",
                                    size: 0
                                }
                            }
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: "Categoría de producto EU",
                                            font: 'Arial',
                                            size: 22
                                        })
                                    ],
                                    spacing: { before: 100, after: 200 }
                                })
                            ],
                            rowSpan: 2,
                            columnSpan: 1,
                            verticalAlign: VerticalAlign.CENTER,
                            shading: {
                                fill: "#FFC000"
                            },
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: this.getCategoriaEU(),
                                            font: 'Arial',
                                            size: 22
                                        })
                                    ],
                                    spacing: { before: 100, after: 200 }
                                })
                            ],
                            rowSpan: 2,
                            columnSpan: 1,
                            verticalAlign: VerticalAlign.CENTER,
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: "",
                                            font: 'Arial',
                                            size: 22
                                        })
                                    ],
                                    spacing: { before: 100, after: 200 }
                                })
                            ],
                            rowSpan: 2,
                            columnSpan: 1,
                            verticalAlign: VerticalAlign.CENTER,
                            borders: {
                                top: {
                                    style: BorderStyle.NONE,
                                    color: "white",
                                    size: 0
                                },
                                bottom: {
                                    style: BorderStyle.NONE,
                                    color: "white",
                                    size: 0
                                },
                                left: {
                                    style: BorderStyle.NONE,
                                    color: "white",
                                    size: 0
                                },
                                right: {
                                    style: BorderStyle.NONE,
                                    color: "white",
                                    size: 0
                                }
                            }
                        }),
                        
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.RIGHT,
                                    children: [
                                        new TextRun({
                                            text: "Total de Ingredientes (Sin Agua y Sal):",
                                            font: 'Arial',
                                            size: 22
                                        })
                                    ],
                                    spacing: { before: 100, after: 200 }
                                })
                            ],
                            columnSpan: 1,
                            verticalAlign: VerticalAlign.CENTER,
                            borders: {
                                top: {
                                    style: BorderStyle.NONE,
                                    color: "white",
                                    size: 0
                                },
                                bottom: {
                                    style: BorderStyle.NONE,
                                    color: "white",
                                    size: 0
                                },
                                left: {
                                    style: BorderStyle.NONE,
                                    color: "white",
                                    size: 0
                                },
                                right: {
                                    style: BorderStyle.NONE,
                                    color: "white",
                                    size: 0
                                }
                            }
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: `${Number(this.cantidadIngredientesSinAguaSal).toLocaleString('en-US', { minimumFractionDigits: 4, maximumFractionDigits: 4 })}`,
                                            font: 'Arial',
                                            size: 22
                                        })
                                    ],
                                    spacing: { before: 100, after: 200 }
                                })
                            ],
                            columnSpan: 1,
                            verticalAlign: VerticalAlign.CENTER
                        })
                    ]
                }),
                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.RIGHT,
                                    children: [
                                        new TextRun({
                                            text: "Total de Ingredientes:",
                                            font: 'Arial',
                                            size: 22
                                        })
                                    ],
                                    spacing: { before: 100, after: 200 }
                                })
                            ],
                            columnSpan: 1,
                            verticalAlign: VerticalAlign.CENTER,
                            borders: {
                                top: {
                                    style: BorderStyle.NONE,
                                    color: "white",
                                    size: 0
                                },
                                bottom: {
                                    style: BorderStyle.NONE,
                                    color: "white",
                                    size: 0
                                },
                                left: {
                                    style: BorderStyle.NONE,
                                    color: "white",
                                    size: 0
                                },
                                right: {
                                    style: BorderStyle.NONE,
                                    color: "white",
                                    size: 0
                                }
                            }
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: `${Number(totalIngredientes).toLocaleString('en-US', { minimumFractionDigits: 4, maximumFractionDigits: 4 })}`,
                                            font: 'Arial',
                                            size: 22
                                        })
                                    ],
                                    spacing: { before: 100, after: 200 }
                                })
                            ],
                            columnSpan: 1,
                            verticalAlign: VerticalAlign.CENTER
                        })
                    ]
                })
            ],
            margins: {
                left: 70,
                right: 70
            },
            width: {
                size: 100,
                type: WidthType.PERCENTAGE,
            },
            columnWidths: [1012, 6216, 3016, 1882, 5930, 2012]
        })

        return table
    }

    getCategoriaLPO() {
        const percentage = this.porcentajeProductosOrganicos * 100
        const porcentaje_formatted = Number(percentage).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })

        if (this.subTotalOrganicos === this.subTotalAgricolas) {
            if (this.cantidadIngredientes > 1) {
                if (this.porcentajeProductosOrganicos === 1) {
                    return '100% ORGÁNICO';
                } else if (this.porcentajeProductosOrganicos >= 0.95 && this.porcentajeProductosOrganicos < 1) {
                    return 'ORGÁNICO';
                } else if (this.porcentajeProductosOrganicos >= 0.7 && this.porcentajeProductosOrganicos < 0.95) {
                    return `ELABORADO CON ${porcentaje_formatted}% DE INGREDIENTES ORGÁNICOS`;
                } else if (this.porcentajeProductosOrganicos < 0.7) {
                    return 'CONTIENE ALGUNOS INGREDIENTES ORGÁNICOS';
                } else {
                    return 'ERROR';
                }
            } else {
                return this.porcentajeProductosOrganicos === 1 ? 'ORGÁNICO' : 'ERROR';
            }
        } else {
            if (this.porcentajeProductosOrganicos >= 0.7 && this.porcentajeProductosOrganicos < 1) {
                return `ELABORADO CON ${porcentaje_formatted}% DE INGREDIENTES ORGÁNICOS`;
            } else if (this.porcentajeProductosOrganicos < 0.7) {
                return 'CONTIENE ALGUNOS INGREDIENTES ORGÁNICOS';
            }
        }

        return 'ERROR';
    }

    getCategoriaCOR() {
        const percentage = this.porcentajeProductosOrganicos * 100
        const porcentaje_formatted = Number(percentage).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })

        if (this.subTotalOrganicos === this.subTotalAgricolas) {
            if (this.cantidadIngredientes > 1) {
                if (this.porcentajeProductosOrganicos === 1) {
                    return 'ORGÁNICO';
                } else if (this.porcentajeProductosOrganicos >= 0.95 && this.porcentajeProductosOrganicos < 1) {
                    return 'ORGÁNICO';
                } else if (this.porcentajeProductosOrganicos >= 0.7 && this.porcentajeProductosOrganicos < 0.95) {
                    return `CONTIENE ${porcentaje_formatted}% DE INGREDIENTES ORGÁNICOS`;
                } else if (this.porcentajeProductosOrganicos < 0.7) {
                    return 'CONTIENE MENOS DEL 70% DE INGREDIENTES ORGÁNICOS, SOLO INDICAR EN LISTA DE INGREDIENTES';
                } else {
                    return 'ERROR';
                }
            } else {
                return this.porcentajeProductosOrganicos === 1 ? 'ORGÁNICO' : 'ERROR';
            }
        } else {
            if (this.porcentajeProductosOrganicos >= 0.7 && this.porcentajeProductosOrganicos < 1) {
                return `CONTIENE ${porcentaje_formatted}% DE INGREDIENTES ORGÁNICOS`;
            } else if (this.porcentajeProductosOrganicos < 0.7) {
                return 'CONTIENE MENOS DEL 70% DE INGREDIENTES ORGÁNICOS, SOLO INDICAR EN LISTA DE INGREDIENTES';
            }
        }

        return 'ERROR';
    }

    getCategoriaEU(){
        if (this.subTotalOrganicos === this.subTotalAgricolas) {
            if (this.cantidadIngredientes > 1) {
                if (this.porcentajeProductosOrganicos === 1) {
                    return 'ORGÁNICO';
                } else if (this.porcentajeProductosOrganicos >= 0.95 && this.porcentajeProductosOrganicos < 1) {
                    return 'ORGÁNICO';
                } else if (this.porcentajeProductosOrganicos < 0.95) {
                    return 'CONTIENE MENOS DEL 95% DE INGREDIENTES ORGÁNICOS, SOLO INDICAR EN LISTA DE INGREDIENTES';
                } else {
                    return 'ERROR';
                }
            } else {
                return this.porcentajeProductosOrganicos === 1 ? 'ORGÁNICO' : 'ERROR';
            }
        } else {
            return this.porcentajeProductosOrganicos < 1
                ? 'CONTIENE INGREDIENTES CONVENCIONALES, SOLO INDICAR EN LISTA DE INGREDIENTES ORGÁNICOS'
                : 'ERROR';
        }
    }

    getValue(submission, name) {
        var value = null
        for (const i in submission) {
            const element = submission[i]
            if (element.name === name) {
                value = element.value
            }
        }
        if (value) return value
        else return ''
    }
    getValueList(submission, name) {
        var value = null
        for (const i in submission) {
            const element = submission[i]
            if (element.name === name) {
                value = element.value
            }
        }
        if (value) return value
        else return []
    }
    // END MTO_FSOL_09
}
