import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AlertService, AuthenticationService, InspectionsService } from '@app/core/services'
import { first } from 'rxjs/operators';

@Component({
    selector: 'modal-changeInspector',
    templateUrl: 'modal-changeInspector.component.html',
    styleUrls: ['../../../app.component.scss']
})
export class ModalChangeInspector implements OnInit {
    form: FormGroup;
    @Input() public name = ''
    @Input() public id

    loading = false;
    submitted = false;
    res = false
    inspectors = []

    constructor(
        private formBuilder: FormBuilder,
        public modal: NgbActiveModal,
        private authenticationService: AuthenticationService,
        private alertService: AlertService,
        private inspectionsService: InspectionsService
    ) { }
    ngOnInit() {
        this.inspectionsService.getInspectors()
            .pipe(first())
            .subscribe(
                response => {
                    //console.log(response)
                    this.inspectors = response.data
                },
                error => {
                    this.alertService.error(error);
                })
        this.form = this.formBuilder.group({
            newInspector: ['', Validators.required],
        });

    }

    // Convenience getter for easy access to form fields
    get f() { return this.form.controls; }

    onSubmit() {
        this.submitted = true;

        // Reset alerts on submit
        this.alertService.clear();

        // Stop here if form is invalid
        if (this.form.invalid) {
            return;
        }
        if (this.form.value.newInspector) {
            this.loading = true;
            this.inspectionsService.changeInspector(this.form.value.newInspector, this.id)
                .pipe(first())
                .subscribe(
                    response => {
                        console.log(response)
                        this.alertService.clear()
                        if (response.success) {
                            this.alertService.success('Se ha asignado el inspector correctamente', { autoClose: true })
                            this.modal.close(true)
                            this.modal.dismiss()
                        } else {
                        }
                        this.loading = false;
                    },
                    error => {
                        this.alertService.error(error);
                        this.loading = false;
                    });
        }
    }
}