import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, NavigationEnd } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { first } from 'rxjs/operators';

import { AuthenticationService, AlertService, UsersService } from '@app/core/services';

@Component({ templateUrl: 'reset-password.component.html', styleUrls: ['../../styles/signin.component.scss', '../../styles/sponsor.component.scss'] })
export class ResetPasswordComponent implements OnInit {
    recoverForm: FormGroup;
    loading = false;
    submitted = false;
    token: string;
    error = '';
    data;
    usersKey;
    isTemp = false;
    isShowed1 = false;
    isShowed2 = false;
    isShowed3 = false;

    constructor(
        private formBuilder: FormBuilder,
        private route: ActivatedRoute,
        private router: Router,
        private authenticationService: AuthenticationService,
        private alertService: AlertService,
        private usersService: UsersService
    ) {
        // Redirect to home if already logged in
        // if (this.authenticationService.userValue) {
        //     this.router.navigate(['/']);
        // }
        this.router.events.subscribe((e) => {
            if (e instanceof NavigationEnd) {
                //console.log(e.url);
                if (e.url.startsWith('/cambiarContrasena')) { this.isTemp = false } else { this.isTemp = true }
            }
        })
    }

    ngOnInit() {
        if (this.isTemp) {
            this.recoverForm = this.formBuilder.group({
                temp: ['', Validators.required],
                newPassword: ['', Validators.required],
                confirmPassword: ['', Validators.required],
            });
        } else {
            this.recoverForm = this.formBuilder.group({
                newPassword: ['', Validators.required],
                confirmPassword: ['', Validators.required],
            });
        }


        // Get return url from route parameters or default to '/'
        this.token = this.route.snapshot.paramMap.get('token')
        //console.log(this.token)
    }

    // Convenience getter for easy access to form fields
    get f() { return this.recoverForm.controls; }

    onSubmit() {
        this.submitted = true;

        // Reset alerts on submit
        this.alertService.clear();

        // Stop here if form is invalid
        if (this.recoverForm.invalid) {
            return;
        }
        if (this.f.newPassword.value !== this.f.confirmPassword.value) {
            this.alertService.error("Las contraseñas no coinciden");
            return;
        }

        this.loading = true;
        if (this.isTemp) {
            this.usersService.confirmUser(this.f.newPassword.value, this.f.temp.value, this.token)
                .pipe(first())
                .subscribe(
                    data => {
                        //console.log(data)
                        this.data = data
                        this.alertService.success(this.data.message, { keepAfterRouteChange: true, autoClose: true })
                        const user = {
                            id: this.data.user.id,
                            email: this.data.user.email,
                            firstName: this.data.user.firstName,
                            middleName: this.data.user.middleName,
                            lastName: this.data.user.lastName,
                            photoUrl: this.data.user.photoUrl,
                            lastLogin: this.data.user.lastLogin,
                            type: this.data.user.type
                        }
                        this.usersKey = this.authenticationService.getUserkey
                        localStorage.setItem(this.usersKey, JSON.stringify(user))

                        // Add token cookie that expires in 24 hours
                        const expires = new Date(Date.now() + 24 * 60 * 60 * 1000).toUTCString();
                        document.cookie = `jwtToken=${this.data.authorization}; expires=${expires}; path=/`;

                        // Add token refresh cookie that expires in 5 days
                        const expiresRefresh = new Date(Date.now() + 5 * 24 * 60 * 60 * 1000).toUTCString();
                        document.cookie = `jwtRefresh=${this.data.refresh}; expires=${expiresRefresh}; path=/`;

                        this.authenticationService.userSubject.next(user)
                        this.router.navigate(['/'])
                    },
                    error => {
                        this.alertService.error(error);
                        this.error = error;
                        this.loading = false;
                    })
        } else {
            this.usersService.resetPasswordPatch(this.f.newPassword.value, this.token)
                .pipe(first())
                .subscribe(
                    data => {
                        //console.log(data)
                        this.data = data
                        this.alertService.success(this.data.message, { keepAfterRouteChange: true, autoClose: true })
                        const user = {
                            id: this.data.user.id,
                            email: this.data.user.email,
                            firstName: this.data.user.firstName,
                            middleName: this.data.user.middleName,
                            lastName: this.data.user.lastName,
                            photoUrl: this.data.user.photoUrl,
                            lastLogin: this.data.user.lastLogin,
                            type: this.data.user.type
                        }
                        this.usersKey = this.authenticationService.getUserkey
                        localStorage.setItem(this.usersKey, JSON.stringify(user))

                        // Add token cookie that expires in 24 hours
                        const expires = new Date(Date.now() + 24 * 60 * 60 * 1000).toUTCString();
                        document.cookie = `jwtToken=${this.data.authorization}; expires=${expires}; path=/`;

                        // Add token refresh cookie that expires in 5 days
                        const expiresRefresh = new Date(Date.now() + 5 * 24 * 60 * 60 * 1000).toUTCString();
                        document.cookie = `jwtRefresh=${this.data.refresh}; expires=${expiresRefresh}; path=/`;

                        this.authenticationService.userSubject.next(user)
                        this.router.navigate(['/'])
                    },
                    error => {
                        this.alertService.error(error);
                        this.error = error;
                        this.loading = false;
                    })
        }

    }

  showPassword1(): void {
    this.isShowed1 = !this.isShowed1;
  }
  showPassword2(): void {
    this.isShowed2 = !this.isShowed2;
  }
  showPassword3(): void {
    this.isShowed3 = !this.isShowed3;
  }
}


