import { Injectable } from "@angular/core";
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';

import { AuthenticationService } from '@app/core/services';

@Injectable({ providedIn: 'root' })
export class AuthGuard implements CanActivate {
    constructor(
        private router: Router,
        private authenticationService: AuthenticationService
    ) {}

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        const user = this.authenticationService.userValue;
        if (user) {
            const isTokenExpired = this.authenticationService.isLoggedIn
            if (isTokenExpired) {
                // Authorised (logged) so return true
                return true;
            } else {
                // Not logged in so redirect to login page with the return url
                this.router.navigate(['/login']/* , { queryParams: { returnUrl: state.url } } */);
                return false;
            }
        } else {
            // Not logged in so redirect to login page with the return url
            this.router.navigate(['/login']/* , { queryParams: { returnUrl: state.url } } */);
            return false;
        }
    }
}