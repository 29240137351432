import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'sanitizeInputText',
})
export class SanitizeInputPipe implements PipeTransform {
  transform(value: string): string {
    if (!value) return '';
    // Reemplazar caracteres de control (U+0000 - U+001F y U+007F - U+009F)
    return value.replace(/[\u0000-\u001F\u007F-\u009F](?<!\n|\r)/g, '');
  }
}
