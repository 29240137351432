import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { first } from 'rxjs/operators';

import { AuthenticationService, AlertService } from '@app/core/services';

@Component({ templateUrl: 'forgot-password-confirmation.component.html', styleUrls: ['../../styles/signin.component.scss', '../../styles/sponsor.component.scss'] })
export class ForgotPasswordConfirmationComponent implements OnInit {
    recoverForm: FormGroup;
    loading = false;
    submitted = false;
    returnUrl: string;
    error = '';

    constructor(
        private formBuilder: FormBuilder,
        private route: ActivatedRoute,
        private router: Router,
        private authenticationService: AuthenticationService,
        private alertService: AlertService
    ) {
        // Redirect to home if already logged in
        if (this.authenticationService.userValue) {
            this.router.navigate(['/']);
        }
    }

    ngOnInit() {
        this.recoverForm = this.formBuilder.group({
        });

        // Get return url from route parameters or default to '/'
        this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/';
    }

    // Convenience getter for easy access to form fields
    get f() { return this.recoverForm.controls; }

    onSubmit() {
        this.submitted = true;

        // Reset alerts on submit
        this.alertService.clear();

        // Stop here if form is invalid
        if (this.recoverForm.invalid) {
            return;
        }

        this.router.navigate(['/login']);
        // this.loading = true;
        // this.authenticationService.recoverPassword(this.f.email.value)
        //     .pipe(first())
        //     .subscribe({
        //         next: () => {
        //             this.router.navigate([this.returnUrl]);
        //         },
        //         error: error => {
        //             this.alertService.error(error);
        //             this.error = error;
        //             this.loading = false;
        //         }
        //     });
    }
}
