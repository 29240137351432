// Certificates V1
export { getContract as getContractMexicoV1 } from './V1/mexico'
export { getContract as getContractCanadaV1 } from './V1/canada'

// Certificates V2
export { getContract as getContractMexicoV2 } from './V2/mexico'
export { getContract as getContractCanadaV2 } from './V2/canada'

// Certificates V3
export { getContract as getContractMexicoV3 } from './V3/mexico'
export { getContract as getContractCanadaV3 } from './V3/canada'
export { getContract as getContractEuropaV3 } from './V3/europe'