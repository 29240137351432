import { FormGroup } from '@angular/forms';
import { ControlElement, UnknownParams } from './../../../interfaces/form.interface';
import { Component, Input, OnInit } from '@angular/core'

@Component({
    selector: 'text-element',
    template: `<p class="mb-2" [class]="attributes.class ? attributes.class : 's'" [ngStyle]="{'white-space': attributes.breakLine ? 'break-spaces' : ''}">{{attributes.text}}</p>`
})
export class TextComponent implements OnInit {

    attributes: UnknownParams = {}
    validations: UnknownParams = {}

    @Input() data: ControlElement

    constructor() {}

    ngOnInit(): void {
        // iterate and access component attributes
        for (var i in this.data.attributes) {
            const attribute = this.data.attributes[i]
            this.attributes[attribute.name] = attribute.value
        }
        for (var i in this.data.validations) {
            const validation = this.data.validations[i]
            this.validations[validation.name] = validation.value
        }
    }

}