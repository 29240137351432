import { AlignmentType, Document, Paragraph, PageOrientation, TextRun, Media, Header, Footer, Table, TableRow, TableCell, WidthType, PageNumber, BorderStyle } from "docx";
import { logo } from "../logo";
import { userWithoutSign } from "../userWithoutSign"
import { DatePipe } from '@angular/common';

export class DocumentCreatorMTO_FSOL_04_3_v2 {

    public getBase64FromUrl = async (url) => {
        const data = await fetch(url, {
            headers: {
                'Cache-Control': 'no-cache, no-store, must-revalidate',
                'Pragma': 'no-cache',
                'Expires': 'Sat, 01 Jan 2000 00:00:00 GMT',
                'If-Modified-Since': '0'
            }
        });
        const blob = await data.blob();
        return new Promise((resolve) => {
            const reader = new FileReader();
            reader.readAsDataURL(blob);
            reader.onloadend = function() {
                const base64data = reader.result;
                resolve(base64data);
            }
        });
    }

    // INIT MTO_FSOL_04_3
    public async create_MTO_FSOL_04_3(data, signature = ''): Promise<Document> {
        const document = new Document();
        const image = Media.addImage(document, logo, 50, 50);
        var datePipe = new DatePipe('es-MX');

        let signatureImage = null
        if (signature) {
            signatureImage = await this.getBase64FromUrl(signature)
        } else {
            signatureImage = userWithoutSign
        }

        document.addSection({
            headers: {
                default: new Header({
                    children: [
                        new Paragraph({ alignment: AlignmentType.CENTER, children: [image], }),
                        new Paragraph({
                            alignment: AlignmentType.CENTER, children: [
                                new TextRun({
                                    children: [`MTO-FSOL-04-3 | R 03 | 28-Septiembre-2021 | Pág. `, PageNumber.CURRENT],
                                    font: 'Book Antiqua',
                                    size: 20,
                                    bold: true
                                }),
                                new TextRun({
                                    font: 'Book Antiqua',
                                    size: 20,
                                    bold: true,
                                    children: [' de ', PageNumber.TOTAL_PAGES]
                                }),
                            ], spacing: { before: 200, after: 100 }
                        })
                    ]
                })
            },
            footers: {
                default: new Footer({
                    children: [
                        new Paragraph({ alignment: AlignmentType.CENTER }),
                        this.createFooter()
                    ]
                }),
            },
            children: [
                new Paragraph({
                    alignment: AlignmentType.CENTER,
                    children: [
                        new TextRun({
                            text: 'ANÁLISIS GENERAL DE RIESGOS PARA LA INTEGRIDAD ORGÁNICA DE PROCESAMIENTO Y COMERCIALIZACIÓN',
                            font: 'Calibri (Cuerpo)',
                            size: 22,
                            bold: true
                        })
                    ],
                    spacing: { before: 200, after: 200 }
                }),
                this.createTableHeader(data),
                new Paragraph({
                    spacing: {
                        after: 200
                    }
                }),
                this.createTableUDP(data),
                new Paragraph({
                    spacing: {
                        after: 200
                    }
                }),
                new Paragraph({
                    alignment: AlignmentType.CENTER,
                    children: [
                        signatureImage ? Media.addImage(document, signatureImage.toString(), 200, 100) : new TextRun({
                            text: ''
                        })
                    ]
                }),
                new Paragraph({
                    alignment: AlignmentType.CENTER,
                    children: [
                        new TextRun({
                            text: `Fecha: ${datePipe.transform(new Date(data.record.Applications[0].Creation_Date.includes('UTC') ? data.record.Applications[0].Creation_Date.replace('UTC', '').trim() : data.record.Applications[0].Creation_Date), 'd-MMMM-y')}                  Nombre y Firma del Operador: ${data.customer.Legal_Authorities[0].Name} ${data.customer.Legal_Authorities[0].Middle_Name} ${data.customer.Legal_Authorities[0].Last_Name ? data.customer.Legal_Authorities[0].Last_Name : ''}`,
                            font: 'Arial',
                            size: 24,
                            italics: true
                        })
                    ],
                    spacing: {
                        after: 200
                    }
                }),
            ],
            margins: {
                top: 350,
                bottom: 500,
                left: 800,
                right: 800
            },
            size: {
                orientation: PageOrientation.LANDSCAPE,
            }
        });

        return document;
    }

    public createFooter(): Table {
        const table = new Table({
            rows: [
                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: 'Elaboró:',
                                            font: 'Calibri (Cuerpo)',
                                            size: 22
                                        })
                                    ]
                                })
                            ],
                            width: {
                                size: 33,
                                type: WidthType.PERCENTAGE
                            }
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: 'Revisó:',
                                            font: 'Calibri (Cuerpo)',
                                            size: 22
                                        })
                                    ]
                                })
                            ],
                            width: {
                                size: 33,
                                type: WidthType.PERCENTAGE
                            }
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: 'Autorizó:',
                                            font: 'Calibri (Cuerpo)',
                                            size: 22
                                        })
                                    ]
                                }),
                            ],
                            width: {
                                size: 33,
                                type: WidthType.PERCENTAGE
                            }
                        })
                    ]
                }),
                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: 'Director de certificación',
                                            font: 'Calibri (Cuerpo)',
                                            size: 16,
                                            bold: true
                                        })
                                    ]
                                }),

                            ],
                            width: {
                                size: 33,
                                type: WidthType.PERCENTAGE
                            },
                            shading: {
                                fill: '#C0C0C0'
                            }
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: 'Responsable de calidad',
                                            font: 'Calibri (Cuerpo)',
                                            size: 16,
                                            bold: true
                                        })
                                    ]
                                }),

                            ],
                            width: {
                                size: 33,
                                type: WidthType.PERCENTAGE
                            },
                            shading: {
                                fill: '#C0C0C0'
                            }
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: 'Director general',
                                            font: 'Calibri (Cuerpo)',
                                            size: 16,
                                            bold: true
                                        })
                                    ]
                                }),
                            ],
                            width: {
                                size: 33,
                                type: WidthType.PERCENTAGE
                            },
                            shading: {
                                fill: '#C0C0C0'
                            }
                        })
                    ]
                })
            ],
            margins: {
                left: 70,
                right: 70
            },
            columnWidths: [3213, 3213, 3212],
            alignment: AlignmentType.CENTER
        })
        return table
    }

    public createTableHeader(data) {
        const table = new Table({
            rows: [
                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: ``,
                                            font: 'Arial',
                                            size: 22
                                        })
                                    ],
                                    spacing: { before: 100, after: 200 }
                                })
                            ],
                            borders: {
                                top: {
                                    style: BorderStyle.NONE,
                                    color: "white",
                                    size: 0
                                },
                                bottom: {
                                    style: BorderStyle.NONE,
                                    color: "white",
                                    size: 0
                                },
                                left: {
                                    style: BorderStyle.NONE,
                                    color: "white",
                                    size: 0
                                },
                                right: {
                                    style: BorderStyle.NONE,
                                    color: "white",
                                    size: 0
                                }
                            }
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: `Nombre del operador: ${data.customer.Company_Name}`,
                                            font: 'Arial',
                                            size: 22
                                        })
                                    ],
                                    spacing: { before: 100, after: 200 }
                                })
                            ]
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: `Codigo del operador: ${data.customer.Id_Comercial}`,
                                            font: 'Arial',
                                            size: 22
                                        })
                                    ],
                                    spacing: { before: 100, after: 200 }
                                })
                            ]
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: ``,
                                            font: 'Arial',
                                            size: 22
                                        })
                                    ],
                                    spacing: { before: 100, after: 200 }
                                })
                            ],
                            borders: {
                                top: {
                                    style: BorderStyle.NONE,
                                    color: "white",
                                    size: 0
                                },
                                bottom: {
                                    style: BorderStyle.NONE,
                                    color: "white",
                                    size: 0
                                },
                                left: {
                                    style: BorderStyle.NONE,
                                    color: "white",
                                    size: 0
                                },
                                right: {
                                    style: BorderStyle.NONE,
                                    color: "white",
                                    size: 0
                                }
                            }
                        })
                    ]
                })
            ],
            margins: {
                left: 70,
                right: 70
            },
            width: {
                size: 100,
                type: WidthType.PERCENTAGE,
            },
            columnWidths: [1612, 5012, 5012, 1612]
        })

        return table
    }

    public createTableUDP(data) {
        const table = new Table({
            rows: [
                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: `Fase`,
                                            font: 'Arial',
                                            size: 24,
                                            bold: true
                                        })
                                    ],
                                    spacing: { before: 100, after: 200 }
                                })
                            ],
                            columnSpan: 1,
                            shading: {
                                fill: "#BFBFBF"
                            }
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: `Riesgo`,
                                            font: 'Arial',
                                            size: 24,
                                            bold: true
                                        })
                                    ],
                                    spacing: { before: 100, after: 200 }
                                })
                            ],
                            columnSpan: 1,
                            shading: {
                                fill: "#BFBFBF"
                            }
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: `Control`,
                                            font: 'Arial',
                                            size: 24,
                                            bold: true
                                        })
                                    ],
                                    spacing: { before: 100, after: 200 }
                                })
                            ],
                            columnSpan: 1,
                            shading: {
                                fill: "#BFBFBF"
                            }
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: `Evidencia`,
                                            font: 'Arial',
                                            size: 24,
                                            bold: true
                                        })
                                    ],
                                    spacing: { before: 100, after: 200 }
                                })
                            ],
                            columnSpan: 1,
                            shading: {
                                fill: "#BFBFBF"
                            }
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: `Verificación MTO (Inspector)`,
                                            font: 'Arial',
                                            size: 24,
                                            bold: true
                                        })
                                    ],
                                    spacing: { before: 100, after: 200 }
                                })
                            ],
                            columnSpan: 2,
                            shading: {
                                fill: "#BFBFBF"
                            }
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: `Observaciones`,
                                            font: 'Arial',
                                            size: 24,
                                            bold: true
                                        })
                                    ],
                                    spacing: { before: 100, after: 200 }
                                })
                            ],
                            columnSpan: 1,
                            shading: {
                                fill: "#BFBFBF"
                            }
                        })
                    ]
                }),
                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: `General***`,
                                            font: 'Arial',
                                            size: 22
                                        })
                                    ]
                                })
                            ]
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: `${this.getValue(data.submission, 'riesgo_general_proceso_paralelo') ? '■' : '❏'} Se tiene proceso paralelo`,
                                            font: 'Arial',
                                            size: 22
                                        })
                                    ]
                                })
                            ]
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: `${this.getValue(data.submission, 'control_general_separacion_almacenes') ? '■' : '❏'} Separación en almacenes`,
                                            font: 'Arial',
                                            size: 22
                                        })
                                    ]
                                }),
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: `${this.getValue(data.submission, 'control_general_separacion_equipos') ? '■' : '❏'} Separación de equipos`,
                                            font: 'Arial',
                                            size: 22
                                        })
                                    ]
                                }),
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: `${this.getValue(data.submission, 'control_general_separacion_proceso_transporte') ? '■' : '❏'} Separación de producto durante proceso y transporte`,
                                            font: 'Arial',
                                            size: 22
                                        })
                                    ]
                                }),
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: `${this.getValue(data.submission, 'control_general_supervicion_certificacion') ? '■' : '❏'} Supervisión por responsable de certificación`,
                                            font: 'Arial',
                                            size: 22
                                        })
                                    ]
                                }),
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: `${this.getValue(data.submission, 'control_general_otro') ? '■' : '❏'} Otro: ${this.getValue(data.submission, 'control_general_otro_texto')}`,
                                            font: 'Arial',
                                            size: 22
                                        })
                                    ]
                                })
                            ]
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: `${this.getValue(data.submission, 'evidencia_general_señales_letreros') ? '■' : '❏'} Señales o letreros`,
                                            font: 'Arial',
                                            size: 22
                                        })
                                    ]
                                }),
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: `${this.getValue(data.submission, 'evidencia_general_equipos_separados') ? '■' : '❏'} Equipos separados y señalados`,
                                            font: 'Arial',
                                            size: 22
                                        })
                                    ]
                                }),
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: `${this.getValue(data.submission, 'evidencia_general_registro_empaque_transporte') ? '■' : '❏'} Registros de empaque y transporte señalando calidad`,
                                            font: 'Arial',
                                            size: 22
                                        })
                                    ]
                                }),
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: `${this.getValue(data.submission, 'evidencia_general_bitacoras') ? '■' : '❏'} Bitácoras`,
                                            font: 'Arial',
                                            size: 22
                                        })
                                    ]
                                }),
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: `${this.getValue(data.submission, 'evidencia_general_otro') ? '■' : '❏'} Otro: ${this.getValue(data.submission, 'evidencia_general_otro_texto')}`,
                                            font: 'Arial',
                                            size: 22
                                        })
                                    ]
                                })
                            ]
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: this.getValue(data.submission, "riesgos_procesados_fase_general_implementacion") === "I" ? `■ I` : `❏ I`,
                                            font: 'Arial',
                                            size: 22
                                        })
                                    ]
                                }),
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: this.getValue(data.submission, "riesgos_procesados_fase_general_implementacion") === "NI" ? `■ NI` : `❏ NI`,
                                            font: 'Arial',
                                            size: 22
                                        })
                                    ]
                                })
                            ]
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: this.getValue(data.submission, "riesgos_procesados_fase_general_conformidad") === "C" ? `■ C` : `❏ C`,
                                            font: 'Arial',
                                            size: 22
                                        })
                                    ]
                                }),
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: this.getValue(data.submission, "riesgos_procesados_fase_general_conformidad") === "NC" ? `■ NC` : `❏ NC`,
                                            font: 'Arial',
                                            size: 22
                                        })
                                    ]
                                }),
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: this.getValue(data.submission, "riesgos_procesados_fase_general_conformidad") === "NA" ? `■ NA` : `❏ NA`,
                                            font: 'Arial',
                                            size: 22
                                        })
                                    ]
                                })
                            ]
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: this.getValue(data.submission, "riesgos_procesados_fase_general_comentarios_inspector"),
                                            font: 'Arial',
                                            size: 22
                                        })
                                    ]
                                })
                            ]
                        })
                    ]
                }),
                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: `Ingreso de ingredientes`,
                                            font: 'Arial',
                                            size: 22
                                        })
                                    ]
                                })
                            ]
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: `${this.getValue(data.submission, 'riesgo_ingreso_ingredientes_no_permitidos') ? '■' : '❏'} Uso de ingredientes no permitidos`,
                                            font: 'Arial',
                                            size: 22
                                        })
                                    ]
                                }),
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: `${this.getValue(data.submission, 'riesgo_ingreso_ingredientes_otro') ? '■' : '❏'} Otro: ${this.getValue(data.submission, 'riesgo_ingreso_ingredientes_otro_texto')}`,
                                            font: 'Arial',
                                            size: 22
                                        })
                                    ]
                                })
                            ]
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: `${this.getValue(data.submission, 'control_ingreso_ingredientes_requerimiento_respaldos') ? '■' : '❏'} Requerimiento de respaldos de calidad de ingredientes`,
                                            font: 'Arial',
                                            size: 22
                                        })
                                    ]
                                }),
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: `${this.getValue(data.submission, 'control_ingreso_ingredientes_supervicion_certificacion') ? '■' : '❏'} Supervisión por responsable de certificación`,
                                            font: 'Arial',
                                            size: 22
                                        })
                                    ]
                                }),
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: `${this.getValue(data.submission, 'control_ingreso_ingredientes_otro') ? '■' : '❏'} Otro: ${this.getValue(data.submission, 'control_ingreso_ingredientes_otro_texto')}`,
                                            font: 'Arial',
                                            size: 22
                                        })
                                    ]
                                })
                            ]
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: `${this.getValue(data.submission, 'evidencia_ingreso_ingredientes_archivo_lote') ? '■' : '❏'} Archivo por lote de ingreso de ingredientes`,
                                            font: 'Arial',
                                            size: 22
                                        })
                                    ]
                                }),
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: `${this.getValue(data.submission, 'evidencia_ingreso_ingredientes_lista_verificacion') ? '■' : '❏'} Lista de verificación de ingredientes`,
                                            font: 'Arial',
                                            size: 22
                                        })
                                    ]
                                }),
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: `${this.getValue(data.submission, 'evidencia_ingreso_ingredientes_otro') ? '■' : '❏'} Otro: ${this.getValue(data.submission, 'evidencia_ingreso_ingredientes_otro_texto')}`,
                                            font: 'Arial',
                                            size: 22
                                        })
                                    ]
                                })
                            ]
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: this.getValue(data.submission, "riesgos_procesados_fase_ingreso_ingredientes_implementacion") === "I" ? `■ I` : `❏ I`,
                                            font: 'Arial',
                                            size: 22
                                        })
                                    ]
                                }),
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: this.getValue(data.submission, "riesgos_procesados_fase_ingreso_ingredientes_implementacion") === "NI" ? `■ NI` : `❏ NI`,
                                            font: 'Arial',
                                            size: 22
                                        })
                                    ]
                                })
                            ]
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: this.getValue(data.submission, "riesgos_procesados_fase_ingreso_ingredientes_conformidad") === "C" ? `■ C` : `❏ C`,
                                            font: 'Arial',
                                            size: 22
                                        })
                                    ]
                                }),
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: this.getValue(data.submission, "riesgos_procesados_fase_ingreso_ingredientes_conformidad") === "NC" ? `■ NC` : `❏ NC`,
                                            font: 'Arial',
                                            size: 22
                                        })
                                    ]
                                }),
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: this.getValue(data.submission, "riesgos_procesados_fase_ingreso_ingredientes_conformidad") === "NA" ? `■ NA` : `❏ NA`,
                                            font: 'Arial',
                                            size: 22
                                        })
                                    ]
                                })
                            ]
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: this.getValue(data.submission, "riesgos_procesados_fase_ingreso_ingredientes_comentarios_inspector"),
                                            font: 'Arial',
                                            size: 22
                                        })
                                    ]
                                })
                            ]
                        })
                    ]
                }),
                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: `Contaminación de ingredientes en almacén`,
                                            font: 'Arial',
                                            size: 22
                                        })
                                    ]
                                })
                            ]
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: `${this.getValue(data.submission, 'riesgo_contaminacion_almacen_sustancias_no_permitidas_plagas') ? '■' : '❏'} Uso de sustancias no permitidas para control de plagas`,
                                            font: 'Arial',
                                            size: 22
                                        })
                                    ]
                                }),
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: `${this.getValue(data.submission, 'riesgo_contaminacion_almacen_contaminacion_otros_prodcutos') ? '■' : '❏'} Contaminación por otros productos almacenados`,
                                            font: 'Arial',
                                            size: 22
                                        })
                                    ]
                                }),
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: `${this.getValue(data.submission, 'riesgo_contaminacion_almacen_otro') ? '■' : '❏'} Otro: ${this.getValue(data.submission, 'riesgo_contaminacion_almacen_otro_texto')}`,
                                            font: 'Arial',
                                            size: 22
                                        })
                                    ]
                                })
                            ]
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: `${this.getValue(data.submission, 'control_contaminacion_almacen_sustancias_permitidas') ? '■' : '❏'} Uso de sustancias permitidas`,
                                            font: 'Arial',
                                            size: 22
                                        })
                                    ]
                                }),
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: `${this.getValue(data.submission, 'control_contaminacion_almacen_protocolo_desalojo') ? '■' : '❏'} Protocolo de desalojo de ingredientes`,
                                            font: 'Arial',
                                            size: 22
                                        })
                                    ]
                                }),
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: `${this.getValue(data.submission, 'control_contaminacion_almacen_envases_hermeticos') ? '■' : '❏'} Envases herméticos`,
                                            font: 'Arial',
                                            size: 22
                                        })
                                    ]
                                }),
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: `${this.getValue(data.submission, 'control_contaminacion_almacen_exclusivo') ? '■' : '❏'} Almacén exclusivo`,
                                            font: 'Arial',
                                            size: 22
                                        })
                                    ]
                                }),
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: `${this.getValue(data.submission, 'control_contaminacion_almacen_otro') ? '■' : '❏'} Otro: ${this.getValue(data.submission, 'control_contaminacion_almacen_otro_texto')}`,
                                            font: 'Arial',
                                            size: 22
                                        })
                                    ]
                                })
                            ]
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: `${this.getValue(data.submission, 'evidencia_contaminacion_almacen_bitacoras') ? '■' : '❏'} Bitácoras de control de plagas`,
                                            font: 'Arial',
                                            size: 22
                                        })
                                    ]
                                }),
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: `${this.getValue(data.submission, 'evidencia_contaminacion_almacen_registro_desalojo') ? '■' : '❏'} Registros de desalojo de ingredientes`,
                                            font: 'Arial',
                                            size: 22
                                        })
                                    ]
                                }),
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: `${this.getValue(data.submission, 'evidencia_contaminacion_almacen_inventario') ? '■' : '❏'} Inventario físico`,
                                            font: 'Arial',
                                            size: 22
                                        })
                                    ]
                                }),
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: `${this.getValue(data.submission, 'evidencia_contaminacion_almacen_verificacion') ? '■' : '❏'} Verificación física`,
                                            font: 'Arial',
                                            size: 22
                                        })
                                    ]
                                }),
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: `${this.getValue(data.submission, 'evidencia_contaminacion_almacen_otro') ? '■' : '❏'} Otro: ${this.getValue(data.submission, 'evidencia_contaminacion_almacen_otro_texto')}`,
                                            font: 'Arial',
                                            size: 22
                                        })
                                    ]
                                })
                            ]
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: this.getValue(data.submission, "riesgos_procesados_fase_contaminacion_almacen_implementacion") === "I" ? `■ I` : `❏ I`,
                                            font: 'Arial',
                                            size: 22
                                        })
                                    ]
                                }),
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: this.getValue(data.submission, "riesgos_procesados_fase_contaminacion_almacen_implementacion") === "NI" ? `■ NI` : `❏ NI`,
                                            font: 'Arial',
                                            size: 22
                                        })
                                    ]
                                })
                            ]
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: this.getValue(data.submission, "riesgos_procesados_fase_contaminacion_almacen_conformidad") === "C" ? `■ C` : `❏ C`,
                                            font: 'Arial',
                                            size: 22
                                        })
                                    ]
                                }),
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: this.getValue(data.submission, "riesgos_procesados_fase_contaminacion_almacen_conformidad") === "NC" ? `■ NC` : `❏ NC`,
                                            font: 'Arial',
                                            size: 22
                                        })
                                    ]
                                }),
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: this.getValue(data.submission, "riesgos_procesados_fase_contaminacion_almacen_conformidad") === "NA" ? `■ NA` : `❏ NA`,
                                            font: 'Arial',
                                            size: 22
                                        })
                                    ]
                                })
                            ]
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: this.getValue(data.submission, "riesgos_procesados_fase_contaminacion_almacen_comentarios_inspector"),
                                            font: 'Arial',
                                            size: 22
                                        })
                                    ]
                                })
                            ]
                        })
                    ]
                }),
                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: `Proceso`,
                                            font: 'Arial',
                                            size: 22
                                        })
                                    ]
                                })
                            ],
                            rowSpan: 5
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: `${this.getValue(data.submission, 'riesgo_proceso_equipos_contaminados') ? '■' : '❏'} Uso de equipos contaminado`,
                                            font: 'Arial',
                                            size: 22
                                        })
                                    ]
                                })
                            ]
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: `${this.getValue(data.submission, 'control_proceso_uso_equipos_exclusivos') ? '■' : '❏'} Uso de equipos exclusivos`,
                                            font: 'Arial',
                                            size: 22
                                        })
                                    ]
                                }),
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: `${this.getValue(data.submission, 'control_proceso_limpieza_equipos') ? '■' : '❏'} Limpieza de equipos`,
                                            font: 'Arial',
                                            size: 22
                                        })
                                    ]
                                }),
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: `${this.getValue(data.submission, 'control_proceso_equipos_otro') ? '■' : '❏'} Otro: ${this.getValue(data.submission, 'control_proceso_equipos_otro_texto')}`,
                                            font: 'Arial',
                                            size: 22
                                        })
                                    ]
                                })
                            ]
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: `${this.getValue(data.submission, 'evidencia_proceso_verificacion_fisica') ? '■' : '❏'} Verificación física`,
                                            font: 'Arial',
                                            size: 22
                                        })
                                    ]
                                }),
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: `${this.getValue(data.submission, 'evidencia_proceso_bitacoras_limpieza') ? '■' : '❏'} Bitácoras de limpieza de equipos`,
                                            font: 'Arial',
                                            size: 22
                                        })
                                    ]
                                }),
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: `${this.getValue(data.submission, 'evidencia_proceso_equipos_otro') ? '■' : '❏'} Otro: ${this.getValue(data.submission, 'evidencia_proceso_equipos_otro_texto')}`,
                                            font: 'Arial',
                                            size: 22
                                        })
                                    ]
                                })
                            ]
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: this.getValue(data.submission, "riesgos_procesados_fase_proceso_implementacion_1") === "I" ? `■ I` : `❏ I`,
                                            font: 'Arial',
                                            size: 22
                                        })
                                    ]
                                }),
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: this.getValue(data.submission, "riesgos_procesados_fase_proceso_implementacion_1") === "NI" ? `■ NI` : `❏ NI`,
                                            font: 'Arial',
                                            size: 22
                                        })
                                    ]
                                })
                            ]
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: this.getValue(data.submission, "riesgos_procesados_fase_proceso_conformidad_1") === "C" ? `■ C` : `❏ C`,
                                            font: 'Arial',
                                            size: 22
                                        })
                                    ]
                                }),
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: this.getValue(data.submission, "riesgos_procesados_fase_proceso_conformidad_1") === "NC" ? `■ NC` : `❏ NC`,
                                            font: 'Arial',
                                            size: 22
                                        })
                                    ]
                                }),
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: this.getValue(data.submission, "riesgos_procesados_fase_proceso_conformidad_1") === "NA" ? `■ NA` : `❏ NA`,
                                            font: 'Arial',
                                            size: 22
                                        })
                                    ]
                                })
                            ]
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: this.getValue(data.submission, "riesgos_procesados_fase_proceso_comentarios_inspector_1"),
                                            font: 'Arial',
                                            size: 22
                                        })
                                    ]
                                })
                            ]
                        })
                    ]
                }),
                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: `${this.getValue(data.submission, 'riesgo_proceso_uso_ingredientes_no_permitidos') ? '■' : '❏'} Uso de ingredientes, aditivos o ayudas de proceso no permitidos`,
                                            font: 'Arial',
                                            size: 22
                                        })
                                    ]
                                })
                            ]
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: `${this.getValue(data.submission, 'control_proceso_capacitacion_responsable') ? '■' : '❏'} Capacitación de responsable`,
                                            font: 'Arial',
                                            size: 22
                                        })
                                    ]
                                }),
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: `${this.getValue(data.submission, 'control_proceso_ordenes_proceso_especificas') ? '■' : '❏'} Ordenes de proceso específica`,
                                            font: 'Arial',
                                            size: 22
                                        })
                                    ]
                                }),
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: `${this.getValue(data.submission, 'control_proceso_no_permitidos_otro') ? '■' : '❏'} Otro: ${this.getValue(data.submission, 'control_proceso_no_permitidos_otro_texto')}`,
                                            font: 'Arial',
                                            size: 22
                                        })
                                    ]
                                })
                            ]
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: `${this.getValue(data.submission, 'evidencia_proceso_constancias_capacitacion') ? '■' : '❏'} Constancias de capacitación`,
                                            font: 'Arial',
                                            size: 22
                                        })
                                    ]
                                }),
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: `${this.getValue(data.submission, 'evidencia_proceso_registros') ? '■' : '❏'} Registros de proceso`,
                                            font: 'Arial',
                                            size: 22
                                        })
                                    ]
                                }),
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: `${this.getValue(data.submission, 'evidencia_proceso_no_permitidos_otro') ? '■' : '❏'} Otro: ${this.getValue(data.submission, 'evidencia_proceso_no_permitidos_otro_texto')}`,
                                            font: 'Arial',
                                            size: 22
                                        })
                                    ]
                                })
                            ]
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: this.getValue(data.submission, "riesgos_procesados_fase_proceso_implementacion_2") === "I" ? `■ I` : `❏ I`,
                                            font: 'Arial',
                                            size: 22
                                        })
                                    ]
                                }),
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: this.getValue(data.submission, "riesgos_procesados_fase_proceso_implementacion_2") === "NI" ? `■ NI` : `❏ NI`,
                                            font: 'Arial',
                                            size: 22
                                        })
                                    ]
                                })
                            ]
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: this.getValue(data.submission, "riesgos_procesados_fase_proceso_conformidad_2") === "C" ? `■ C` : `❏ C`,
                                            font: 'Arial',
                                            size: 22
                                        })
                                    ]
                                }),
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: this.getValue(data.submission, "riesgos_procesados_fase_proceso_conformidad_2") === "NC" ? `■ NC` : `❏ NC`,
                                            font: 'Arial',
                                            size: 22
                                        })
                                    ]
                                }),
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: this.getValue(data.submission, "riesgos_procesados_fase_proceso_conformidad_2") === "NA" ? `■ NA` : `❏ NA`,
                                            font: 'Arial',
                                            size: 22
                                        })
                                    ]
                                })
                            ]
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: this.getValue(data.submission, "riesgos_procesados_fase_proceso_comentarios_inspector_2"),
                                            font: 'Arial',
                                            size: 22
                                        })
                                    ]
                                })
                            ]
                        })
                    ]
                }),
                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: `${this.getValue(data.submission, 'riesgo_proceso_contaminacion_detergentes') ? '■' : '❏'} Contaminación por detergentes o sanitizantes`,
                                            font: 'Arial',
                                            size: 22
                                        })
                                    ]
                                })
                            ]
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: `${this.getValue(data.submission, 'control_proceso_uso_detergentes_permitidos') ? '■' : '❏'} Uso de detergentes y sanitizantes permitidos`,
                                            font: 'Arial',
                                            size: 22
                                        })
                                    ]
                                }),
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: `${this.getValue(data.submission, 'control_proceso_enjuague') ? '■' : '❏'} Enjuague`,
                                            font: 'Arial',
                                            size: 22
                                        })
                                    ]
                                }),
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: `${this.getValue(data.submission, 'control_proceso_verificacion_residuos') ? '■' : '❏'} Verificación de residuos`,
                                            font: 'Arial',
                                            size: 22
                                        })
                                    ]
                                }),
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: `${this.getValue(data.submission, 'control_proceso_contaminacion_otro') ? '■' : '❏'} Otro: ${this.getValue(data.submission, 'control_proceso_contaminacion_otro_texto')}`,
                                            font: 'Arial',
                                            size: 22
                                        })
                                    ]
                                })
                            ]
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: `${this.getValue(data.submission, 'evidencia_proceso_registro_actividades') ? '■' : '❏'} Registro de actividades de limpieza`,
                                            font: 'Arial',
                                            size: 22
                                        })
                                    ]
                                }),
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: `${this.getValue(data.submission, 'evidencia_proceso_reportes_analisis_residuo') ? '■' : '❏'} Reporte de análisis de residuos`,
                                            font: 'Arial',
                                            size: 22
                                        })
                                    ]
                                }),
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: `${this.getValue(data.submission, 'evidencia_proceso_contaminacion_otro') ? '■' : '❏'} Otro: ${this.getValue(data.submission, 'evidencia_proceso_contaminacion_otro_texto')}`,
                                            font: 'Arial',
                                            size: 22
                                        })
                                    ]
                                })
                            ]
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: this.getValue(data.submission, "riesgos_procesados_fase_proceso_implementacion_3") === "I" ? `■ I` : `❏ I`,
                                            font: 'Arial',
                                            size: 22
                                        })
                                    ]
                                }),
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: this.getValue(data.submission, "riesgos_procesados_fase_proceso_implementacion_3") === "NI" ? `■ NI` : `❏ NI`,
                                            font: 'Arial',
                                            size: 22
                                        })
                                    ]
                                })
                            ]
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: this.getValue(data.submission, "riesgos_procesados_fase_proceso_conformidad_3") === "C" ? `■ C` : `❏ C`,
                                            font: 'Arial',
                                            size: 22
                                        })
                                    ]
                                }),
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: this.getValue(data.submission, "riesgos_procesados_fase_proceso_conformidad_3") === "NC" ? `■ NC` : `❏ NC`,
                                            font: 'Arial',
                                            size: 22
                                        })
                                    ]
                                }),
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: this.getValue(data.submission, "riesgos_procesados_fase_proceso_conformidad_3") === "NA" ? `■ NA` : `❏ NA`,
                                            font: 'Arial',
                                            size: 22
                                        })
                                    ]
                                })
                            ]
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: this.getValue(data.submission, "riesgos_procesados_fase_proceso_comentarios_inspector_3"),
                                            font: 'Arial',
                                            size: 22
                                        })
                                    ]
                                })
                            ]
                        })
                    ]
                }),
                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: `${this.getValue(data.submission, 'riesgo_proceso_agua_contaminada') ? '■' : '❏'} Uso de agua contaminada`,
                                            font: 'Arial',
                                            size: 22
                                        })
                                    ]
                                })
                            ]
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: `${this.getValue(data.submission, 'control_proceso_fuente_agua_exclusiva') ? '■' : '❏'} Fuente de agua exclusiva y limpia`,
                                            font: 'Arial',
                                            size: 22
                                        })
                                    ]
                                }),
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: `${this.getValue(data.submission, 'control_proceso_analisis_agua') ? '■' : '❏'} Análisis de agua cada ${this.getValue(data.submission, 'control_proceso_analisis_agua') ? this.getValue(data.submission, 'control_proceso_analisis_agua_texto') : 'N/A'} meses`,
                                            font: 'Arial',
                                            size: 22
                                        })
                                    ]
                                }),
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: `${this.getValue(data.submission, 'control_proceso_agua_otro') ? '■' : '❏'} Otro: ${this.getValue(data.submission, 'control_proceso_agua_otro_texto')}`,
                                            font: 'Arial',
                                            size: 22
                                        })
                                    ]
                                })
                            ]
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: `${this.getValue(data.submission, 'evidencia_proceso_planos_instalacion') ? '■' : '❏'} Planos de instalación`,
                                            font: 'Arial',
                                            size: 22
                                        })
                                    ]
                                }),
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: `${this.getValue(data.submission, 'evidencia_proceso_observacion') ? '■' : '❏'} Observación directa`,
                                            font: 'Arial',
                                            size: 22
                                        })
                                    ]
                                }),
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: `${this.getValue(data.submission, 'evidencia_proceso_resultado_analisis') ? '■' : '❏'} Resultado de análisis`,
                                            font: 'Arial',
                                            size: 22
                                        })
                                    ]
                                }),
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: `${this.getValue(data.submission, 'evidencia_proceso_agua_otro') ? '■' : '❏'} Otro: ${this.getValue(data.submission, 'evidencia_proceso_agua_otro_texto')}`,
                                            font: 'Arial',
                                            size: 22
                                        })
                                    ]
                                })
                            ]
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: this.getValue(data.submission, "riesgos_procesados_fase_proceso_implementacion_4") === "I" ? `■ I` : `❏ I`,
                                            font: 'Arial',
                                            size: 22
                                        })
                                    ]
                                }),
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: this.getValue(data.submission, "riesgos_procesados_fase_proceso_implementacion_4") === "NI" ? `■ NI` : `❏ NI`,
                                            font: 'Arial',
                                            size: 22
                                        })
                                    ]
                                })
                            ]
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: this.getValue(data.submission, "riesgos_procesados_fase_proceso_conformidad_4") === "C" ? `■ C` : `❏ C`,
                                            font: 'Arial',
                                            size: 22
                                        })
                                    ]
                                }),
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: this.getValue(data.submission, "riesgos_procesados_fase_proceso_conformidad_4") === "NC" ? `■ NC` : `❏ NC`,
                                            font: 'Arial',
                                            size: 22
                                        })
                                    ]
                                }),
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: this.getValue(data.submission, "riesgos_procesados_fase_proceso_conformidad_4") === "NA" ? `■ NA` : `❏ NA`,
                                            font: 'Arial',
                                            size: 22
                                        })
                                    ]
                                })
                            ]
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: this.getValue(data.submission, "riesgos_procesados_fase_proceso_comentarios_inspector_4"),
                                            font: 'Arial',
                                            size: 22
                                        })
                                    ]
                                })
                            ]
                        })
                    ]
                }),
                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: `${this.getValue(data.submission, 'riesgo_proceso_otro') ? '■' : '❏'} Otro: ${this.getValue(data.submission, 'riesgo_proceso_otro_texto')}`,
                                            font: 'Arial',
                                            size: 22
                                        })
                                    ]
                                })
                            ]
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: `${this.getValue(data.submission, 'control_proceso_otro') ? '■' : '❏'} Otro: ${this.getValue(data.submission, 'control_proceso_otro_texto')}`,
                                            font: 'Arial',
                                            size: 22
                                        })
                                    ]
                                })
                            ]
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: `${this.getValue(data.submission, 'evidencia_proceso_otro') ? '■' : '❏'} Otro: ${this.getValue(data.submission, 'evidencia_proceso_otro_texto')}`,
                                            font: 'Arial',
                                            size: 22
                                        })
                                    ]
                                })
                            ]
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: this.getValue(data.submission, "riesgos_procesados_fase_proceso_implementacion_otro") === "I" ? `■ I` : `❏ I`,
                                            font: 'Arial',
                                            size: 22
                                        })
                                    ]
                                }),
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: this.getValue(data.submission, "riesgos_procesados_fase_proceso_implementacion_otro") === "NI" ? `■ NI` : `❏ NI`,
                                            font: 'Arial',
                                            size: 22
                                        })
                                    ]
                                })
                            ]
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: this.getValue(data.submission, "riesgos_procesados_fase_proceso_conformidad_otro") === "C" ? `■ C` : `❏ C`,
                                            font: 'Arial',
                                            size: 22
                                        })
                                    ]
                                }),
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: this.getValue(data.submission, "riesgos_procesados_fase_proceso_conformidad_otro") === "NC" ? `■ NC` : `❏ NC`,
                                            font: 'Arial',
                                            size: 22
                                        })
                                    ]
                                }),
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: this.getValue(data.submission, "riesgos_procesados_fase_proceso_conformidad_otro") === "NA" ? `■ NA` : `❏ NA`,
                                            font: 'Arial',
                                            size: 22
                                        })
                                    ]
                                })
                            ]
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: this.getValue(data.submission, "riesgos_procesados_fase_proceso_comentarios_inspector_otro"),
                                            font: 'Arial',
                                            size: 22
                                        })
                                    ]
                                })
                            ]
                        })
                    ]
                }),
                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: `Empaque`,
                                            font: 'Arial',
                                            size: 22
                                        })
                                    ]
                                })
                            ]
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: `${this.getValue(data.submission, 'riesgo_empaque_contaminacion_material_empaque') ? '■' : '❏'} Contaminación por material de empaque`,
                                            font: 'Arial',
                                            size: 22
                                        })
                                    ]
                                }),
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: `${this.getValue(data.submission, 'riesgo_empaque_otro') ? '■' : '❏'} Otro: ${this.getValue(data.submission, 'riesgo_empaque_otro_texto')}`,
                                            font: 'Arial',
                                            size: 22
                                        })
                                    ]
                                })
                            ]
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: `${this.getValue(data.submission, 'control_empaque_uso_grado_alimenticio') ? '■' : '❏'} Uso de empaque grado alimenticio`,
                                            font: 'Arial',
                                            size: 22
                                        })
                                    ]
                                }),
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: `${this.getValue(data.submission, 'control_empaque_no_uso_sustancias_prohibidas') ? '■' : '❏'} No uso de sustancias prohibidas en almacén de empaques`,
                                            font: 'Arial',
                                            size: 22
                                        })
                                    ]
                                }),
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: `${this.getValue(data.submission, 'control_empaque_otro') ? '■' : '❏'} Otro: ${this.getValue(data.submission, 'control_empaque_otro_texto')}`,
                                            font: 'Arial',
                                            size: 22
                                        })
                                    ]
                                })
                            ]
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: `${this.getValue(data.submission, 'evidencia_empaque_ficha_tecnica') ? '■' : '❏'} Ficha técnica de empaque`,
                                            font: 'Arial',
                                            size: 22
                                        })
                                    ]
                                }),
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: `${this.getValue(data.submission, 'evidencia_empaque_registros_limpieza_control') ? '■' : '❏'} Registros de limpieza y control de plagas en almacén`,
                                            font: 'Arial',
                                            size: 22
                                        })
                                    ]
                                }),
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: `${this.getValue(data.submission, 'evidencia_empaque_otro') ? '■' : '❏'} Otro: ${this.getValue(data.submission, 'evidencia_empaque_otro_texto')}`,
                                            font: 'Arial',
                                            size: 22
                                        })
                                    ]
                                })
                            ]
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: this.getValue(data.submission, "riesgos_procesados_fase_empaque_implementacion") === "I" ? `■ I` : `❏ I`,
                                            font: 'Arial',
                                            size: 22
                                        })
                                    ]
                                }),
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: this.getValue(data.submission, "riesgos_procesados_fase_empaque_implementacion") === "NI" ? `■ NI` : `❏ NI`,
                                            font: 'Arial',
                                            size: 22
                                        })
                                    ]
                                })
                            ]
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: this.getValue(data.submission, "riesgos_procesados_fase_empaque_conformidad") === "C" ? `■ C` : `❏ C`,
                                            font: 'Arial',
                                            size: 22
                                        })
                                    ]
                                }),
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: this.getValue(data.submission, "riesgos_procesados_fase_empaque_conformidad") === "NC" ? `■ NC` : `❏ NC`,
                                            font: 'Arial',
                                            size: 22
                                        })
                                    ]
                                }),
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: this.getValue(data.submission, "riesgos_procesados_fase_empaque_conformidad") === "NA" ? `■ NA` : `❏ NA`,
                                            font: 'Arial',
                                            size: 22
                                        })
                                    ]
                                })
                            ]
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: this.getValue(data.submission, "riesgos_procesados_fase_empaque_comentarios_inspector"),
                                            font: 'Arial',
                                            size: 22
                                        })
                                    ]
                                })
                            ]
                        })
                    ]
                }),
                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: `Transporte`,
                                            font: 'Arial',
                                            size: 22
                                        })
                                    ]
                                })
                            ]
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: `${this.getValue(data.submission, 'riesgo_transporte_contaminacion') ? '■' : '❏'} Contaminación por transporte`,
                                            font: 'Arial',
                                            size: 22
                                        })
                                    ]
                                }),
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: `${this.getValue(data.submission, 'riesgo_transporte_mezcla_no_organica') ? '■' : '❏'} Mezcla con producto no compatible con orgánico`,
                                            font: 'Arial',
                                            size: 22
                                        })
                                    ]
                                }),
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: `${this.getValue(data.submission, 'riesgo_transporte_otro') ? '■' : '❏'} Otro: ${this.getValue(data.submission, 'riesgo_transporte_otro_texto')}`,
                                            font: 'Arial',
                                            size: 22
                                        })
                                    ]
                                })
                            ]
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: `${this.getValue(data.submission, 'control_transporte_medio_transporte_exclusivo') ? '■' : '❏'} Medio de transporte exclusivo y marcado`,
                                            font: 'Arial',
                                            size: 22
                                        })
                                    ]
                                }),
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: `${this.getValue(data.submission, 'control_transporte_marcado_envases') ? '■' : '❏'} Marcado y/o etiquetado de envases`,
                                            font: 'Arial',
                                            size: 22
                                        })
                                    ]
                                }),
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: `${this.getValue(data.submission, 'control_transporte_otro') ? '■' : '❏'} Otro: ${this.getValue(data.submission, 'control_transporte_otro_texto')}`,
                                            font: 'Arial',
                                            size: 22
                                        })
                                    ]
                                })
                            ]
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: `${this.getValue(data.submission, 'evidencia_transporte_verificacion_fisica_transporte') ? '■' : '❏'} Verificación física de transporte y registros`,
                                            font: 'Arial',
                                            size: 22
                                        })
                                    ]
                                }),
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: `${this.getValue(data.submission, 'evidencia_transporte_verificacion_fisica_envases') ? '■' : '❏'} Verificación física de envases y registros`,
                                            font: 'Arial',
                                            size: 22
                                        })
                                    ]
                                }),
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: `${this.getValue(data.submission, 'evidencia_transporte_otro') ? '■' : '❏'} Otro: ${this.getValue(data.submission, 'evidencia_transporte_otro_texto')}`,
                                            font: 'Arial',
                                            size: 22
                                        })
                                    ]
                                })
                            ]
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: this.getValue(data.submission, "riesgos_procesados_fase_transporte_implementacion") === "I" ? `■ I` : `❏ I`,
                                            font: 'Arial',
                                            size: 22
                                        })
                                    ]
                                }),
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: this.getValue(data.submission, "riesgos_procesados_fase_transporte_implementacion") === "NI" ? `■ NI` : `❏ NI`,
                                            font: 'Arial',
                                            size: 22
                                        })
                                    ]
                                })
                            ]
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: this.getValue(data.submission, "riesgos_procesados_fase_transporte_conformidad") === "C" ? `■ C` : `❏ C`,
                                            font: 'Arial',
                                            size: 22
                                        })
                                    ]
                                }),
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: this.getValue(data.submission, "riesgos_procesados_fase_transporte_conformidad") === "NC" ? `■ NC` : `❏ NC`,
                                            font: 'Arial',
                                            size: 22
                                        })
                                    ]
                                }),
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: this.getValue(data.submission, "riesgos_procesados_fase_transporte_conformidad") === "NA" ? `■ NA` : `❏ NA`,
                                            font: 'Arial',
                                            size: 22
                                        })
                                    ]
                                })
                            ]
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: this.getValue(data.submission, "riesgos_procesados_fase_transporte_comentarios_inspector"),
                                            font: 'Arial',
                                            size: 22
                                        })
                                    ]
                                })
                            ]
                        })
                    ]
                }),
                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: `*** Para operadores certificados COR/USCOEA, en caso de procesamiento paralelo, el operador enviará registros completos de trazabilidad entre orgánico y no orgánico al momento de la inspección.`,
                                            font: 'Arial',
                                            size: 22
                                        })
                                    ],
                                    spacing: { before: 100, after: 200 }
                                }),
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: `I = Implementado, NI = No Implementado, C = Conforme, NC = No Conforme, NA = No Aplica`,
                                            font: 'Arial',
                                            size: 22
                                        })
                                    ],
                                    spacing: { before: 100 }
                                })
                            ],
                            borders: {
                                left: {
                                    style: BorderStyle.NONE,
                                    color: "white",
                                    size: 0
                                },
                                right: {
                                    style: BorderStyle.NONE,
                                    color: "white",
                                    size: 0
                                },
                                top: {
                                    style: BorderStyle.NONE,
                                    color: "white",
                                    size: 0
                                },
                                bottom: {
                                    style: BorderStyle.NONE,
                                    color: "white",
                                    size: 0
                                }
                            }
                        })
                    ]
                }),
                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                            ],
                            borders: {
                                left: {
                                    style: BorderStyle.NONE,
                                    color: "white",
                                    size: 0
                                },
                                right: {
                                    style: BorderStyle.NONE,
                                    color: "white",
                                    size: 0
                                },
                                top: {
                                    style: BorderStyle.NONE,
                                    color: "white",
                                    size: 0
                                },
                                bottom: {
                                    style: BorderStyle.OUTSET,
                                    color: "black",
                                    size: 3
                                }
                            }
                        })
                    ]
                })

            ],
            margins: {
                left: 70,
                right: 70
            },
            width: {
                size: 100,
                type: WidthType.PERCENTAGE,
            },
            columnWidths: [2312, 3312, 4612, 4612, 1312, 1312, 4312]
        })

        return table;
    }

    getValue(submission, name) {
        var value = null
        for (const i in submission) {
            const element = submission[i]
            if (element.name === name) {
                value = element.value
            }
        }
        if (value) return value
        else return ''
    }
    getValueList(submission, name) {
        var value = null
        for (const i in submission) {
            const element = submission[i]
            if (element.name === name) {
                value = element.value
            }
        }
        if (value) return value
        else return []
    }
    // END MTO_FSOL_04_3
}
