import {Component, Input, OnInit} from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {AlertService, AuthenticationService} from '@app/core/services';
import {first} from 'rxjs/operators';

@Component({
  selector: 'change-password-autofocus',
  templateUrl: 'change-password.component.html',
  styleUrls: ['../../../app.component.scss']
})
export class ModalChangePassword implements OnInit {
  form: FormGroup;
  @Input() public name = '';

  forbiddenId = false;
  loading = false;
  submitted = false;
  res = false;
  isShowedActualPass = false;
  isShowedNewPass = false;
  isShowedConfirm = false;

  constructor(private formBuilder: FormBuilder, public modal: NgbActiveModal,
              private authenticationService: AuthenticationService,
              private alertService: AlertService) {
  }

  ngOnInit() {
    this.form = this.formBuilder.group({
      temp: ['', Validators.required],
      newPassword: ['', Validators.required],
      confirmPassword: ['', Validators.required],
    });
  }

  // Convenience getter for easy access to form fields
  get f() {
    return this.form.controls;
  }

  onSubmit() {
    this.submitted = true;

    // Reset alerts on submit
    this.alertService.clear();

    // Stop here if form is invalid
    if (this.form.invalid) {
      return;
    }

    if (this.f.newPassword.value !== this.f.confirmPassword.value) {
      this.alertService.error("Las contraseñas no coinciden");
      return;
    }

    if (this.form.value.newPassword) {
      this.forbiddenId = false
      this.loading = true;
      this.authenticationService.changePassword(this.form.value.temp, this.form.value.newPassword)
        .pipe(first())
        .subscribe(
          data => {
            this.alertService.clear()
            if (data.success) {
              this.alertService.success('La contraseña a sido actualizada', {autoClose: true})
              this.modal.close(true)
              this.modal.dismiss()
            } else {

            }
            this.loading = false;
          },
          error => {
            this.alertService.error(error);
            this.loading = false;
          });
    }
  }

  showPasswordActual(): void {
    this.isShowedActualPass = !this.isShowedActualPass;
  }
  showPasswordNew(): void {
    this.isShowedNewPass = !this.isShowedNewPass;
  }
  showPasswordConfirm(): void {
    this.isShowedConfirm = !this.isShowedConfirm;
  }
}
