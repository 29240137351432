import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '@environments/environment';

@Injectable({ providedIn: 'root' })
export class UsersService {
    constructor(private http: HttpClient) { }
    getMe() {
        const jwtToken = (document.cookie.split(';').find(x => x.includes('jwtToken')) || '=').split('=')[1]

        const headers = {
            'Content-Type': 'application/json',
            'Authorization': jwtToken
        };

        return this.http.get<any>(`${environment.apiUrl}/users/me`, { headers });
    }
    getAll() {
        const jwtToken = (document.cookie.split(';').find(x => x.includes('jwtToken')) || '=').split('=')[1]

        const headers = {
            'Content-Type': 'application/json',
            'Authorization': jwtToken
        };

        return this.http.get<any>(`${environment.apiUrl}/users/`, { headers });
    }
    getById(id) {
        const jwtToken = (document.cookie.split(';').find(x => x.includes('jwtToken')) || '=').split('=')[1]

        const headers = {
            'Content-Type': 'application/json',
            'Authorization': jwtToken
        };

        return this.http.get<any>(`${environment.apiUrl}/users/${id}`, { headers });
    }
    getAvailable(id) {
        const jwtToken = (document.cookie.split(';').find(x => x.includes('jwtToken')) || '=').split('=')[1]

        const headers = {
            'Content-Type': 'application/json',
            'Authorization': jwtToken
        };

        return this.http.get<any>(`${environment.apiUrl}/users/validate/${id}`, { headers });
    }
    getEmailAvailable(email) {
        const jwtToken = (document.cookie.split(';').find(x => x.includes('jwtToken')) || '=').split('=')[1]

        const headers = {
            'Content-Type': 'application/json',
            'Authorization': jwtToken
        };

        return this.http.get<any>(`${environment.apiUrl}/users/validate-email/${email}`, { headers });
    }
    registerUser(values) {
        const body = {
            email: values.email,
            name: values.name,
            middle_name: values.middle_name,
            last_name: values.last_name,
            employeeId: values.employeeId,
            type: values.type
          }
        const jwtToken = (document.cookie.split(';').find(x => x.includes('jwtToken')) || '=').split('=')[1]

        const headers = {
            'Content-Type': 'application/json',
            'Authorization': jwtToken
        };

        //console.log(body)
        return this.http.post(`${environment.apiUrl}/users/`, body, { headers })
    }
    updateUser(values, id) {

        let body

        if(values.type === 'OPERADOR'){
            body = {
                email: values.email,
                name: values.name,
                middle_name: values.middle_name,
                last_name: values.last_name,
                employeeId: values.employeeId,
                type: values.type,
                phone: values.phone_number
            }
        } else {
            body = {
                email: values.email,
                name: values.name,
                middle_name: values.middle_name,
                last_name: values.last_name,
                employeeId: values.employeeId,
                type: values.type
            }
        }

        const jwtToken = (document.cookie.split(';').find(x => x.includes('jwtToken')) || '=').split('=')[1]

        const headers = {
            'Content-Type': 'application/json',
            'Authorization': jwtToken
        };

        //console.log(body)
        return this.http.patch(`${environment.apiUrl}/users/${id}`, body, { headers })
    }
    deleteUser(id) {
        const jwtToken = (document.cookie.split(';').find(x => x.includes('jwtToken')) || '=').split('=')[1]

        const headers = {
            'Content-Type': 'application/json',
            'Authorization': jwtToken
        };

        return this.http.delete(`${environment.apiUrl}/users/${id}`, { headers })
    }
    resetPassword(email: string){
        const headers = {
            'Content-Type': 'application/json'
        };
        //console.log(email)
        const body = {
            email: email 
        }

        return this.http.post(`${environment.apiUrl}/users/reset-password`, body, { headers })
    }
    resetPasswordPatch(password: string, token){
        const headers = {
            'Content-Type': 'application/json',
            'Authorization': token
        };
        const body = {
            password: password
        }

        return this.http.patch(`${environment.apiUrl}/users/reset-password`, body, { headers })
    }
    confirmUser(newPassword: string, password: string, token){
        const headers = {
            'Content-Type': 'application/json'
        };
        const body = {
            password,
            newPassword,
            token
        }

        return this.http.post(`${environment.apiUrl}/users/confirm-user`, body, { headers })
    }

    uploadSignature(userId: number, file: File) {
        const jwtToken = (document.cookie.split(';').find(x => x.includes('jwtToken')) || '=').split('=')[1]

        const headers = {
            'Authorization': jwtToken
        };

        const body = new FormData()
        body.append('file', file)

        return this.http.post<any>(`${environment.apiUrl}/users/${userId}/signature/upload`, body, { headers });
    }

    getSignature(userId: number) {
        const jwtToken = (document.cookie.split(';').find(x => x.includes('jwtToken')) || '=').split('=')[1]

        const headers = {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'Authorization': jwtToken,
            'Cache-Control': "no-store"
        }

        return this.http.get<any>(`${environment.apiUrl}/users/${userId}/signature/download`, { headers });
    }

    updateProfileUser(values, id) {
        const body = {
            email: values.email,
            name: values.name,
            middle_name: values.middle_name,
            last_name: values.last_name,
            employeeId: values.employeeId,
            type: values.type
          }
        const jwtToken = (document.cookie.split(';').find(x => x.includes('jwtToken')) || '=').split('=')[1]

        const headers = {
            'Content-Type': 'application/json',
            'Authorization': jwtToken
        };

        //console.log(body)
        return this.http.patch(`${environment.apiUrl}/users/me/${id}`, body, { headers })
    }

    resendInvitation(userId: number) {
        const jwtToken = (document.cookie.split(';').find(x => x.includes('jwtToken')) || '=').split('=')[1]

        const headers = {
            'Authorization': jwtToken
        };

        const body = {}

        return this.http.post<any>(`${environment.apiUrl}/users/resend-invitation/${userId}`, body, { headers });
    }

    reactivateUser(userId: number) {
        const jwtToken = (document.cookie.split(';').find(x => x.includes('jwtToken')) || '=').split('=')[1]

        const headers = {
            'Authorization': jwtToken
        };

        const body = {}

        return this.http.patch<any>(`${environment.apiUrl}/users/reactivate-user/${userId}`, body, { headers });
    }
}