<div 
    [ngClass]="{'was-validated': validations.required, 'form': true}"
    [class]="attributes.class ? attributes.class : 's'" 
    style="margin-bottom: 10px;" 
    [formGroup]="form"
>
    <div class="timepicker-group" style="position: relative;">
        <label *ngIf="attributes.label" [for]="attributes.name">{{attributes.label}}</label>
        <input [ngxTimepicker]="picker" class="form-control" [formControlName]="attributes.name" [required]="validations.required" autocomplete="off" [format]="24">
        <ngx-material-timepicker-toggle [for]="picker" style="position: absolute; top: 50%; right: 1%;"></ngx-material-timepicker-toggle>
        <ngx-material-timepicker #picker></ngx-material-timepicker>
    </div>

    <div class="valid-feedback">{{attributes.input_valid}}</div>
    <div class="invalid-feedback">{{attributes.input_invalid}}</div>
</div>