<div class="form-group" [formGroup]="form">
    <ng-template [ngIf]="uploadedFiles.length > 0">
        <div style="margin-bottom: 10px;" *ngFor="let url of uploadedFiles; let i = index">
            <a class="text-dark" style="padding: 5px 20px 5px 20px; border-radius: 5px; background-color: #fafafa;" target="_blank" [href]="url"><i style="margin-right: 10px;" class="fas fa-file"></i>{{getFilename(url)}}</a>
            <button *ngIf="!validations.disabled" mwlConfirmationPopover popoverTitle="Eliminar archivo" popoverMessage="¿Estas seguro de eliminar este archivo? No podrá ser recuperado." (confirm)="deleteFile(getFilename(url))" cancelText="Cancelar" confirmText="Eliminar" placement="top" style="margin-top: -5px;" class="btn"><i class="fa fa-trash"></i></button>
        </div>
    </ng-template>
    <div *ngIf="form.get(attributes.name).value.length > 0">
        <div *ngFor="let file of form.get(attributes.name).value">
            <div class="d-flex justify-content-center" style="max-height: 60px; border: 1px solid rgb(199, 199, 199); border-radius: 5px; padding: 5px; margin-bottom: 5px; overflow: hidden; text-overflow: ellipsis;" *ngIf="file.constructor.name === 'File'">
                <p style="text-overflow: ellipsis; width: 90%; height: auto;">{{file.name}}</p>
                <button class="btn" (click)="removeUploadingFile(file)"><i class="fas fa-times"></i></button>
            </div>
        </div>
    </div>
    <p *ngIf="!validations.unlimited && !validations.noMaxFiles && !validations.disabled && !validations.noMaxFilesLabel" style="font-size: 10px; opacity: 0.5; margin: 0; margin-bottom: 5px;">Máximo {{validations.maxFiles ? validations.maxFiles : '5'}} archivo(s) de 10 MB cada uno, es posible cargar más de un archivo al mismo tiempo</p>
    <p *ngIf="!validations.unlimited && validations.noMaxFilesLabel" style="font-size: 10px; opacity: 0.5; margin: 0; margin-bottom: 5px;">Máximo {{validations.maxFiles ? validations.maxFiles : '5'}} archivo(s) de 10 MB cada uno, es posible cargar más de un archivo al mismo tiempo</p>
    <p *ngIf="validations.unlimited" style="font-size: 10px; opacity: 0.5; margin: 0; margin-bottom: 5px;">Sin limite de archivos, máximo {{validations.maxFiles ? validations.maxFiles : '5'}} archivo(s) de 10 MB cada uno por subida, es posible cargar más de un archivo al mismo tiempo</p>
    <div *ngIf="!validations.disabled" class="custom-file">
        <input type="file" class="custom-file-input" id="customFileLang" lang="es"  [accept]="validations.accept" [attr.disabled]="validations.disabled" max="5" [formControlName]="attributes.name" multiple [writeFile]="true" (change)="onFileSelected($event)" style="padding-top: 60px;" />
        <label class="custom-file-label" for="customFileLang">{{attributes.label}}</label>
    </div>
    <div *ngIf="validations.disabled && attributes.disabled_placeholder && uploadedFiles.length === 0">{{attributes.disabled_placeholder}}</div>
</div>