import {
  AlignmentType,
  Document,
  Paragraph,
  TextRun,
  Media,
  Header,
  Footer,
  Table,
  TableRow,
  TableCell,
  VerticalAlign,
  WidthType,
  PageNumber,
  PageOrientation,
  UnderlineType
} from "docx";
import {logo} from "../logo";
import {userWithoutSign} from "../userWithoutSign"
import {DatePipe} from '@angular/common';

export class DocumentCreatorMTO_FSOL_04_2_v3 {

  public getBase64FromUrl = async (url) => {
    const data = await fetch(url, {
      headers: {
          'Cache-Control': 'no-cache, no-store, must-revalidate',
          'Pragma': 'no-cache',
          'Expires': 'Sat, 01 Jan 2000 00:00:00 GMT',
          'If-Modified-Since': '0'
      }
  });
    const blob = await data.blob();
    return new Promise((resolve) => {
      const reader = new FileReader();
      reader.readAsDataURL(blob);
      reader.onloadend = function () {
        const base64data = reader.result;
        resolve(base64data);
      }
    });
  }

  // INIT MTO_FSOL_04_1
  public async create_MTO_FSOL_04_2(data, signature = ''): Promise<Document> {
    const document = new Document();
    const image = Media.addImage(document, logo, 50, 50);
    var datePipe = new DatePipe('es-MX');
    let signatureImage = null
    if (signature) {
      signatureImage = await this.getBase64FromUrl(signature)
    } else {
      signatureImage = userWithoutSign
    }

    document.addSection({
      headers: {
        default: new Header({
          children: [
            new Paragraph({alignment: AlignmentType.CENTER, children: [image],}),
            new Paragraph({
              alignment: AlignmentType.CENTER, children: [
                new TextRun({
                  children: [`MTO-FSOL-04-2 | R 02 | 28-Septiembre-2021 | Pág. `, PageNumber.CURRENT],
                  font: 'Book Antiqua',
                  size: 20,
                  bold: true
                }),
                new TextRun({
                  font: 'Book Antiqua',
                  size: 20,
                  bold: true,
                  children: [' de ', PageNumber.TOTAL_PAGES]
                }),
              ], spacing: {before: 200, after: 100}
            })
          ]
        })
      },
      footers: {
        default: new Footer({
          children: [
            new Paragraph({alignment: AlignmentType.CENTER}),
            this.createFooter()
          ]
        }),
      },
      children: [
        new Paragraph({
          alignment: AlignmentType.CENTER,
          children: [
            new TextRun({
              text: 'ANÁLISIS GENERAL DE RIESGOS PARA LA INTEGRIDAD ORGÁNICA DE PRODUCCIÓN ANIMAL',
              font: 'Calibri (Cuerpo)',
              size: 22,
              bold: true
            })
          ],
          spacing: {after: 200}
        }),
        this.createTableUDP(data),
        new Paragraph({
          alignment: AlignmentType.CENTER,
          children: [
            new TextRun({
              text: '',
              font: 'Calibri (Cuerpo)',
              size: 22,
              bold: true
            })
          ],
          spacing: {after: 400}
        }),
        this.createTableFases(data),
        new Paragraph({
          alignment: AlignmentType.LEFT,
          children: [
            new TextRun({
              text: '***  Para operadores certificados COR/USCOEA, en caso de procesamiento paralelo, el operador enviará registros completos de trazabilidad entre orgánico y no orgánico al momento de la inspección.',
              font: 'Calibri (Body)',
              size: 20
            })
          ],
          spacing: {after: 200, before: 100}
        }),
        new Paragraph({
          alignment: AlignmentType.LEFT,
          children: [
            new TextRun({
              text: 'I = Implementado, NI = No Implementado, C = Conforme, NC = No Conforme, NA = No Aplica',
              font: 'Calibri (Body)',
              size: 20
            })
          ],
          spacing: {after: 200}
        }),
        new Paragraph({
          alignment: AlignmentType.LEFT,
          children: [
            new TextRun({
              text: '____________________________________________________________________________________________________________________________________________________',
              font: 'Calibri (Body)',
              size: 20
            })
          ],
          spacing: {after: 400}
        }),
        new Paragraph({
          alignment: AlignmentType.CENTER,
          children: [
            signatureImage ? Media.addImage(document, signatureImage.toString(), 200, 100) : new TextRun({
              text: ''
            })
          ]
        }),
        new Paragraph({
          alignment: AlignmentType.LEFT,
          children: [
            new TextRun({
              text: `Fecha: `,
              font: 'Arial',
              size: 20,
              italics: true
            }),
            new TextRun({
              text: `                  ${datePipe.transform(new Date(data.record.Applications[0].Creation_Date.includes('UTC') ? data.record.Applications[0].Creation_Date.replace('UTC', '').trim() : data.record.Applications[0].Creation_Date), 'd-MMMM-y')}                  `,
              font: 'Arial',
              size: 20,
              underline: {
                color: '#000000',
                type: UnderlineType.SINGLE
              }
            }),
            new TextRun({
              text: `                                  Nombre y Firma del Operador: `,
              font: 'Arial',
              size: 20,
              italics: true
            }),
            new TextRun({
              text: `                  ${data.customer.Legal_Authorities[0].Name + " " + data.customer.Legal_Authorities[0].Middle_Name + " " + data.customer.Legal_Authorities[0].Last_Name}                  `,
              font: 'Arial',
              size: 20,
              underline: {
                color: '#000000',
                type: UnderlineType.SINGLE
              }
            }),
          ],
          spacing: {
            after: 200,
            before: 400
          }
        })
      ],
      margins: {
        top: 350,
        bottom: 500,
        left: 1000,
        right: 1000
      },
      size: {
        orientation: PageOrientation.LANDSCAPE,
      }
    });

    return document;
  }

  public createFooter(): Table {
    const table = new Table({
      rows: [
        new TableRow({
          children: [
            new TableCell({
              children: [
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                    new TextRun({
                      text: 'Elaboró:',
                      font: 'Calibri (Cuerpo)',
                      size: 22
                    })
                  ]
                })
              ],
              width: {
                size: 33,
                type: WidthType.PERCENTAGE
              }
            }),
            new TableCell({
              children: [
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                    new TextRun({
                      text: 'Revisó:',
                      font: 'Calibri (Cuerpo)',
                      size: 22
                    })
                  ]
                })
              ],
              width: {
                size: 33,
                type: WidthType.PERCENTAGE
              }
            }),
            new TableCell({
              children: [
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                    new TextRun({
                      text: 'Autorizó:',
                      font: 'Calibri (Cuerpo)',
                      size: 22
                    })
                  ]
                }),
              ],
              width: {
                size: 33,
                type: WidthType.PERCENTAGE
              }
            })
          ]
        }),
        new TableRow({
          children: [
            new TableCell({
              children: [
                new Paragraph({
                  alignment: AlignmentType.CENTER,
                  children: [
                    new TextRun({
                      text: 'Director de certificación',
                      font: 'Calibri (Cuerpo)',
                      size: 16,
                      bold: true
                    })
                  ]
                }),

              ],
              width: {
                size: 33,
                type: WidthType.PERCENTAGE
              },
              shading: {
                fill: '#C0C0C0'
              }
            }),
            new TableCell({
              children: [
                new Paragraph({
                  alignment: AlignmentType.CENTER,
                  children: [
                    new TextRun({
                      text: 'Responsable de calidad',
                      font: 'Calibri (Cuerpo)',
                      size: 16,
                      bold: true
                    })
                  ]
                }),

              ],
              width: {
                size: 33,
                type: WidthType.PERCENTAGE
              },
              shading: {
                fill: '#C0C0C0'
              }
            }),
            new TableCell({
              children: [
                new Paragraph({
                  alignment: AlignmentType.CENTER,
                  children: [
                    new TextRun({
                      text: 'Director general',
                      font: 'Calibri (Cuerpo)',
                      size: 16,
                      bold: true
                    })
                  ]
                }),
              ],
              width: {
                size: 33,
                type: WidthType.PERCENTAGE
              },
              shading: {
                fill: '#C0C0C0'
              }
            })
          ]
        })
      ],
      margins: {
        left: 70,
        right: 70
      },
      columnWidths: [3213, 3213, 3212],
      alignment: AlignmentType.CENTER
    })
    return table
  }

  public createTableUDP(data): Table {
    const table = new Table({
      rows: [
        new TableRow({
          children: [
            new TableCell({
              children: [
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                    new TextRun({
                      text: 'Nombre del operador:',
                      font: 'Calibri (Body)',
                      size: 22
                    })
                  ]
                }),
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                    new TextRun({
                      text: data.customer.Company_Name,
                      font: 'Calibri (Body)',
                      size: 22
                    })
                  ]
                })
              ]
            }),
            new TableCell({
              children: [
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                    new TextRun({
                      text: 'Código del operador:',
                      font: 'Calibri (Body)',
                      size: 22
                    })
                  ]
                }),
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                    new TextRun({
                      text: data.customer.Id_Comercial,
                      font: 'Calibri (Body)',
                      size: 22
                    })
                  ]
                })
              ]
            })
          ]
        })
      ],
      margins: {
        left: 70,
        right: 70
      },
      columnWidths: [4819, 4819],
      alignment: AlignmentType.CENTER
    })
    return table
  }

  public createTableFases(data): Table {
    const table = new Table({
      rows: [
        new TableRow({
          children: [
            new TableCell({
              children: [
                new Paragraph({
                  alignment: AlignmentType.CENTER,
                  children: [
                    new TextRun({
                      text: 'Fase',
                      font: 'Calibri (Body)',
                      size: 18,
                      bold: true
                    })
                  ]
                })
              ],
              shading: {
                fill: '#C0C0C0'
              },
              verticalAlign: VerticalAlign.CENTER
            }),
            new TableCell({
              children: [
                new Paragraph({
                  alignment: AlignmentType.CENTER,
                  children: [
                    new TextRun({
                      text: 'Riesgo',
                      font: 'Calibri (Body)',
                      size: 18,
                      bold: true
                    })
                  ]
                })
              ],
              shading: {
                fill: '#C0C0C0'
              },
              verticalAlign: VerticalAlign.CENTER
            }),
            new TableCell({
              children: [
                new Paragraph({
                  alignment: AlignmentType.CENTER,
                  children: [
                    new TextRun({
                      text: 'Control',
                      font: 'Calibri (Body)',
                      size: 18,
                      bold: true
                    })
                  ]
                })
              ],
              shading: {
                fill: '#C0C0C0'
              },
              verticalAlign: VerticalAlign.CENTER
            }),
            new TableCell({
              children: [
                new Paragraph({
                  alignment: AlignmentType.CENTER,
                  children: [
                    new TextRun({
                      text: 'Evidencia',
                      font: 'Calibri (Body)',
                      size: 18,
                      bold: true
                    })
                  ]
                })
              ],
              shading: {
                fill: '#C0C0C0'
              },
              verticalAlign: VerticalAlign.CENTER
            }),
            new TableCell({
              children: [
                new Paragraph({
                  alignment: AlignmentType.CENTER,
                  children: [
                    new TextRun({
                      text: 'Verificación MTO (Inspector)',
                      font: 'Calibri (Body)',
                      size: 18,
                      bold: true
                    })
                  ]
                })
              ],
              shading: {
                fill: '#C0C0C0'
              },
              columnSpan: 2,
              verticalAlign: VerticalAlign.CENTER
            }),
            new TableCell({
              children: [
                new Paragraph({
                  alignment: AlignmentType.CENTER,
                  children: [
                    new TextRun({
                      text: 'Observaciones',
                      font: 'Calibri (Body)',
                      size: 18,
                      bold: true
                    })
                  ]
                })
              ],
              shading: {
                fill: '#C0C0C0'
              },
              verticalAlign: VerticalAlign.CENTER
            }),
          ]
        }),
        new TableRow({
          children: [
            new TableCell({
              children: [
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                    new TextRun({
                      text: 'General***',
                      font: 'Arial',
                      size: 18
                    })
                  ]
                })
              ]
            }),
            new TableCell({
              children: [
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                    new TextRun({
                      text: ` ${this.getValue(data.submission, 'riesgo_general_produccion_paralela') ? '■' : '❏'} Se tiene producción paralela`,
                      font: 'Arial',
                      size: 18
                    })
                  ]
                })
              ]
            }),
            new TableCell({
              children: [
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                    new TextRun({
                      text: ` ${this.getValue(data.submission, 'control_general_separacion_corrales') ? '■' : '❏'} Separación en corrales/tanques/apiarios`,
                      font: 'Arial',
                      size: 18
                    })
                  ]
                }),
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                    new TextRun({
                      text: ` ${this.getValue(data.submission, 'control_general_separacion_equipos') ? '■' : '❏'} Separación de materiales y equipos`,
                      font: 'Arial',
                      size: 18
                    })
                  ]
                }),
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                    new TextRun({
                      text: ` ${this.getValue(data.submission, 'control_general_separacion_almacenes') ? '■' : '❏'} Separación de almacenes`,
                      font: 'Arial',
                      size: 18
                    })
                  ]
                }),
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                    new TextRun({
                      text: ` ${this.getValue(data.submission, 'control_general_separacion_producto') ? '■' : '❏'} Separación de producto durante cosecha, empaque y transporte`,
                      font: 'Arial',
                      size: 18
                    })
                  ]
                }),
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                    new TextRun({
                      text: ` ${this.getValue(data.submission, 'control_general_supervision') ? '■' : '❏'} Supervisión por responsable de certificación`,
                      font: 'Arial',
                      size: 18
                    })
                  ]
                }),
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                    new TextRun({
                      text: ` ${this.getValue(data.submission, 'control_general_otro') ? '■' : '❏'} Otro: ${this.getValue(data.submission, 'control_general_otro_texto')}`,
                      font: 'Arial',
                      size: 18
                    })
                  ]
                })
              ]
            }),
            new TableCell({
              children: [
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                    new TextRun({
                      text: ` ${this.getValue(data.submission, 'evidencia_general_señales_letreros') ? '■' : '❏'} Señales o letreros`,
                      font: 'Arial',
                      size: 18
                    })
                  ]
                }),
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                    new TextRun({
                      text: ` ${this.getValue(data.submission, 'evidencia_general_equipos_separados') ? '■' : '❏'} Equipos y almacenes separados y señalados`,
                      font: 'Arial',
                      size: 18
                    })
                  ]
                }),
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                    new TextRun({
                      text: ` ${this.getValue(data.submission, 'evidencia_general_construcciones') ? '■' : '❏'} Construcciones separadas y rotuladas`,
                      font: 'Arial',
                      size: 18
                    })
                  ]
                }),
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                    new TextRun({
                      text: ` ${this.getValue(data.submission, 'evidencia_general_registros') ? '■' : '❏'} Registros de cosecha, empaque y transporte señalando calidad`,
                      font: 'Arial',
                      size: 18
                    })
                  ]
                }),
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                    new TextRun({
                      text: ` ${this.getValue(data.submission, 'evidencia_general_bitacoras') ? '■' : '❏'} Bitácoras`,
                      font: 'Arial',
                      size: 18
                    })
                  ]
                }),
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                    new TextRun({
                      text: ` ${this.getValue(data.submission, 'evidencia_general_otro') ? '■' : '❏'} Otra: ${this.getValue(data.submission, 'evidencia_general_otro_texto')}`,
                      font: 'Arial',
                      size: 18
                    })
                  ]
                })
              ]
            }),
            new TableCell({
              children: [
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                    new TextRun({
                      text: ` ${this.getValue(data.submission, 'fase_general_implementacion') === 'I' ? '■' : '❏'} I`,
                      font: 'Arial',
                      size: 18
                    })
                  ]
                }),
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                    new TextRun({
                      text: ` ${this.getValue(data.submission, 'fase_general_implementacion') === 'NI' ? '■' : '❏'} NI`,
                      font: 'Arial',
                      size: 18
                    })
                  ]
                })
              ]
            }),
            new TableCell({
              children: [
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                    new TextRun({
                      text: ` ${this.getValue(data.submission, 'fase_general_conformidad') === 'C' ? '■' : '❏'} C`,
                      font: 'Arial',
                      size: 18
                    })
                  ]
                }),
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                    new TextRun({
                      text: ` ${this.getValue(data.submission, 'fase_general_conformidad') === 'NC' ? '■' : '❏'} NC`,
                      font: 'Arial',
                      size: 18
                    })
                  ]
                }),
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                    new TextRun({
                      text: ` ${this.getValue(data.submission, 'fase_general_conformidad') === 'NA' ? '■' : '❏'} NA`,
                      font: 'Arial',
                      size: 18
                    })
                  ]
                })
              ]
            }),
            new TableCell({
              children: [
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                    new TextRun({
                      text: this.getValue(data.submission, 'fase_general_comentarios_inspector'),
                      font: 'Arial',
                      size: 18
                    })
                  ]
                })
              ]
            })
          ]
        }),
        new TableRow({
          children: [
            new TableCell({
              children: [
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                    new TextRun({
                      text: 'Calidad de pies de cría/ juveniles/reinas/colmenas/colonias',
                      font: 'Arial',
                      size: 18
                    })
                  ]
                })
              ]
            }),
            new TableCell({
              children: [
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                    new TextRun({
                      text: ` ${this.getValue(data.submission, 'riesgo_calidad_inividuos') ? '■' : '❏'} Individuos no orgánica`,
                      font: 'Arial',
                      size: 18
                    })
                  ]
                }),
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                    new TextRun({
                      text: ` ${this.getValue(data.submission, 'riesgo_calidad_otro') ? '■' : '❏'} Otro: ${this.getValue(data.submission, 'riesgo_calidad_otro')}`,
                      font: 'Arial',
                      size: 18
                    })
                  ]
                })
              ]
            }),
            new TableCell({
              children: [
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                    new TextRun({
                      text: ` ${this.getValue(data.submission, 'control_calidad_produccion') ? '■' : '❏'} Producción propia`,
                      font: 'Arial',
                      size: 18
                    })
                  ]
                }),
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                    new TextRun({
                      text: ` ${this.getValue(data.submission, 'control_calidad_compra') ? '■' : '❏'} Compra de individuos orgánicos`,
                      font: 'Arial',
                      size: 18
                    })
                  ]
                }),
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                    new TextRun({
                      text: ` ${this.getValue(data.submission, 'control_calidad_otro') ? '■' : '❏'} Otro: ${this.getValue(data.submission, 'control_calidad_otro')}`,
                      font: 'Arial',
                      size: 18
                    })
                  ]
                })
              ]
            }),
            new TableCell({
              children: [
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                    new TextRun({
                      text: ` ${this.getValue(data.submission, 'evidencia_calidad_bitacoras') ? '■' : '❏'} Bitácoras de producción`,
                      font: 'Arial',
                      size: 18
                    })
                  ]
                }),
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                    new TextRun({
                      text: ` ${this.getValue(data.submission, 'evidencia_calidad_facturas') ? '■' : '❏'} Facturas y certificado`,
                      font: 'Arial',
                      size: 18
                    })
                  ]
                }),
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                    new TextRun({
                      text: ` ${this.getValue(data.submission, 'evidencia_calidad_otro') ? '■' : '❏'} Otra: ${this.getValue(data.submission, 'evidencia_calidad_otro_texto')}`,
                      font: 'Arial',
                      size: 18
                    })
                  ]
                })
              ]
            }),
            new TableCell({
              children: [
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                    new TextRun({
                      text: ` ${this.getValue(data.submission, 'fase_uso_previo_terreno_implementacion') === 'I' ? '■' : '❏'} I`,
                      font: 'Arial',
                      size: 18
                    })
                  ]
                }),
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                    new TextRun({
                      text: ` ${this.getValue(data.submission, 'fase_uso_previo_terreno_implementacion') === 'NI' ? '■' : '❏'} NI`,
                      font: 'Arial',
                      size: 18
                    })
                  ]
                })
              ]
            }),
            new TableCell({
              children: [
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                    new TextRun({
                      text: ` ${this.getValue(data.submission, 'fase_uso_previo_terreno_conformidad') === 'C' ? '■' : '❏'} C`,
                      font: 'Arial',
                      size: 18
                    })
                  ]
                }),
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                    new TextRun({
                      text: ` ${this.getValue(data.submission, 'fase_uso_previo_terreno_conformidad') === 'NC' ? '■' : '❏'} NC`,
                      font: 'Arial',
                      size: 18
                    })
                  ]
                }),
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                    new TextRun({
                      text: ` ${this.getValue(data.submission, 'fase_uso_previo_terreno_conformidad') === 'NA' ? '■' : '❏'} NA`,
                      font: 'Arial',
                      size: 18
                    })
                  ]
                })
              ]
            }),
            new TableCell({
              children: [
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                    new TextRun({
                      text: this.getValue(data.submission, 'fase_uso_previo_terreno_comentarios_inspector'),
                      font: 'Arial',
                      size: 18
                    })
                  ]
                })
              ]
            })
          ]
        }),
        new TableRow({
          children: [
            new TableCell({
              children: [
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                    new TextRun({
                      text: 'Alimentación',
                      font: 'Arial',
                      size: 18
                    })
                  ]
                })
              ]
            }),
            new TableCell({
              children: [
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                    new TextRun({
                      text: ` ${this.getValue(data.submission, 'riesgo_alimentacion_no_organicos') ? '■' : '❏'} Uso se ingredientes no orgánicos`,
                      font: 'Arial',
                      size: 18
                    })
                  ]
                }),
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                    new TextRun({
                      text: ` ${this.getValue(data.submission, 'riesgo_alimentacion_otro') ? '■' : '❏'} Otro: ${this.getValue(data.submission, 'riesgo_alimentacion_otro_texto')}`,
                      font: 'Arial',
                      size: 18
                    })
                  ]
                })
              ]
            }),
            new TableCell({
              children: [
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                    new TextRun({
                      text: ` ${this.getValue(data.submission, 'control_alimentacion_requerimiento') ? '■' : '❏'} Requerimiento de respaldos de calidad de ingredientes`,
                      font: 'Arial',
                      size: 18
                    })
                  ]
                }),
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                    new TextRun({
                      text: ` ${this.getValue(data.submission, 'control_alimentacion_supervicion') ? '■' : '❏'} Supervisión por responsable de certificación`,
                      font: 'Arial',
                      size: 18
                    })
                  ]
                }),
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                    new TextRun({
                      text: ` ${this.getValue(data.submission, 'control_alimentacion_validacion_proveedores') ? '■' : '❏'} Validación de proveedores`,
                      font: 'Arial',
                      size: 18
                    })
                  ]
                }),
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                    new TextRun({
                      text: ` ${this.getValue(data.submission, 'control_alimentacion_otro') ? '■' : '❏'} Otro: ${this.getValue(data.submission, 'control_alimentacion_otro')}`,
                      font: 'Arial',
                      size: 18
                    })
                  ]
                })
              ]
            }),
            new TableCell({
              children: [
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                    new TextRun({
                      text: ` ${this.getValue(data.submission, 'evidencia_alimentacion_ingredientes') ? '■' : '❏'} Archivo por lote de ingreso de ingredientes`,
                      font: 'Arial',
                      size: 18
                    })
                  ]
                }),
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                    new TextRun({
                      text: ` ${this.getValue(data.submission, 'evidencia_alimentacion_verificacion_ingredientes') ? '■' : '❏'} Lista de verificación de ingredientes`,
                      font: 'Arial',
                      size: 18
                    })
                  ]
                }),
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                    new TextRun({
                      text: ` ${this.getValue(data.submission, 'evidencia_alimentacion_otro') ? '■' : '❏'} Otro: ${this.getValue(data.submission, 'evidencia_alimentacion_otro_texto')}`,
                      font: 'Arial',
                      size: 18
                    })
                  ]
                })
              ]
            }),
            new TableCell({
              children: [
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                    new TextRun({
                      text: ` ${this.getValue(data.submission, 'fase_calidad_semilla_implementacion') === 'I' ? '■' : '❏'} I`,
                      font: 'Arial',
                      size: 18
                    })
                  ]
                }),
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                    new TextRun({
                      text: ` ${this.getValue(data.submission, 'fase_calidad_semilla_implementacion') === 'NI' ? '■' : '❏'} NI`,
                      font: 'Arial',
                      size: 18
                    })
                  ]
                })
              ]
            }),
            new TableCell({
              children: [
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                    new TextRun({
                      text: ` ${this.getValue(data.submission, 'fase_calidad_semilla_conformidad') === 'C' ? '■' : '❏'} C`,
                      font: 'Arial',
                      size: 18
                    })
                  ]
                }),
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                    new TextRun({
                      text: ` ${this.getValue(data.submission, 'fase_calidad_semilla_conformidad') === 'NC' ? '■' : '❏'} NC`,
                      font: 'Arial',
                      size: 18
                    })
                  ]
                }),
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                    new TextRun({
                      text: ` ${this.getValue(data.submission, 'fase_calidad_semilla_conformidad') === 'NA' ? '■' : '❏'} NA`,
                      font: 'Arial',
                      size: 18
                    })
                  ]
                })
              ]
            }),
            new TableCell({
              children: [
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                    new TextRun({
                      text: this.getValue(data.submission, 'fase_calidad_semilla_comentarios_inspector'),
                      font: 'Arial',
                      size: 18
                    })
                  ]
                })
              ]
            })
          ]
        }),
        new TableRow({
          children: [
            new TableCell({
              children: [
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                    new TextRun({
                      text: 'Profilaxis',
                      font: 'Arial',
                      size: 18
                    })
                  ]
                })
              ]
            }),
            new TableCell({
              children: [
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                    new TextRun({
                      text: ` ${this.getValue(data.submission, 'riesgo_profilaxis_medicamentos_no_permitidos') ? '■' : '❏'} Uso se medicamentos no permitidos`,
                      font: 'Arial',
                      size: 18
                    })
                  ]
                }),
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                    new TextRun({
                      text: ` ${this.getValue(data.submission, 'riesgo_profilaxis_otro') ? '■' : '❏'} Otro: ${this.getValue(data.submission, 'riesgo_profilaxis_otro_texto')}`,
                      font: 'Arial',
                      size: 18
                    })
                  ]
                })
              ]
            }),
            new TableCell({
              children: [
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                    new TextRun({
                      text: ` ${this.getValue(data.submission, 'control_profilaxis_capacitacion') ? '■' : '❏'} Capacitación de responsable`,
                      font: 'Arial',
                      size: 18
                    })
                  ]
                }),
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                    new TextRun({
                      text: ` ${this.getValue(data.submission, 'control_profilaxis_supervicion') ? '■' : '❏'} Supervisión de responsable`,
                      font: 'Arial',
                      size: 18
                    })
                  ]
                }),
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                    new TextRun({
                      text: ` ${this.getValue(data.submission, 'control_profilaxis_otro') ? '■' : '❏'} Otro: ${this.getValue(data.submission, 'control_profilaxis_otro_texto')}`,
                      font: 'Arial',
                      size: 18
                    })
                  ]
                })
              ]
            }),
            new TableCell({
              children: [
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                    new TextRun({
                      text: ` ${this.getValue(data.submission, 'evidencia_profilaxis_registros') ? '■' : '❏'} Registros de capacitación`,
                      font: 'Arial',
                      size: 18
                    })
                  ]
                }),
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                    new TextRun({
                      text: ` ${this.getValue(data.submission, 'evidencia_profilaxis_bitacoras') ? '■' : '❏'} Bitácoras de manejo`,
                      font: 'Arial',
                      size: 18
                    })
                  ]
                }),
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                    new TextRun({
                      text: ` ${this.getValue(data.submission, 'evidencia_profilaxis_otro') ? '■' : '❏'} Otro: ${this.getValue(data.submission, 'evidencia_profilaxis_otro_texto')}`,
                      font: 'Arial',
                      size: 18
                    })
                  ]
                })
              ]
            }),
            new TableCell({
              children: [
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                    new TextRun({
                      text: ` ${this.getValue(data.submission, 'fase_semillero_vivero_implementacion') === 'I' ? '■' : '❏'} I`,
                      font: 'Arial',
                      size: 18
                    })
                  ]
                }),
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                    new TextRun({
                      text: ` ${this.getValue(data.submission, 'fase_semillero_vivero_implementacion') === 'NI' ? '■' : '❏'} NI`,
                      font: 'Arial',
                      size: 18
                    })
                  ]
                })
              ]
            }),
            new TableCell({
              children: [
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                    new TextRun({
                      text: ` ${this.getValue(data.submission, 'fase_semillero_vivero_conformidad') === 'C' ? '■' : '❏'} C`,
                      font: 'Arial',
                      size: 18
                    })
                  ]
                }),
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                    new TextRun({
                      text: ` ${this.getValue(data.submission, 'fase_semillero_vivero_conformidad') === 'NC' ? '■' : '❏'} NC`,
                      font: 'Arial',
                      size: 18
                    })
                  ]
                }),
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                    new TextRun({
                      text: ` ${this.getValue(data.submission, 'fase_semillero_vivero_conformidad') === 'NA' ? '■' : '❏'} NA`,
                      font: 'Arial',
                      size: 18
                    })
                  ]
                })
              ]
            }),
            new TableCell({
              children: [
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                    new TextRun({
                      text: this.getValue(data.submission, 'fase_semillero_vivero_comentarios_inspector'),
                      font: 'Arial',
                      size: 18
                    })
                  ]
                })
              ]
            })
          ]
        }),
        new TableRow({
          children: [
            new TableCell({
              children: [
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                    new TextRun({
                      text: 'Cosecha / Sacrificio',
                      font: 'Arial',
                      size: 18
                    })
                  ]
                })
              ]
            }),
            new TableCell({
              children: [
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                    new TextRun({
                      text: ` ${this.getValue(data.submission, 'riesgo_cosecha_contaminacion') ? '■' : '❏'} Contaminación por equipo o materiales de cosecha/sacrificio`,
                      font: 'Arial',
                      size: 18
                    })
                  ]
                }),
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                    new TextRun({
                      text: ` ${this.getValue(data.submission, 'riesgo_cosecha_mezcla') ? '■' : '❏'} Mezcla con producto no orgánico`,
                      font: 'Arial',
                      size: 18
                    })
                  ]
                }),
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                    new TextRun({
                      text: ` ${this.getValue(data.submission, 'riesgo_cosecha_otro') ? '■' : '❏'} Otro: ${this.getValue(data.submission, 'riesgo_cosecha_otro_texto')}`,
                      font: 'Arial',
                      size: 18
                    })
                  ]
                })
              ]
            }),
            new TableCell({
              children: [
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                    new TextRun({
                      text: ` ${this.getValue(data.submission, 'control_cosecha_equipo') ? '■' : '❏'} Equipo exclusivo y marcado`,
                      font: 'Arial',
                      size: 18
                    })
                  ]
                }),
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                    new TextRun({
                      text: ` ${this.getValue(data.submission, 'control_cosecha_almacenes_excluidos') ? '■' : '❏'} Almacenes exclusivos`,
                      font: 'Arial',
                      size: 18
                    })
                  ]
                }),
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                    new TextRun({
                      text: ` ${this.getValue(data.submission, 'control_cosecha_almacenes_marcados') ? '■' : '❏'} Almacenes marcados y rotulados`,
                      font: 'Arial',
                      size: 18
                    })
                  ]
                }),
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                    new TextRun({
                      text: ` ${this.getValue(data.submission, 'control_cosecha_marcado') ? '■' : '❏'} Marcado y/o etiquetado`,
                      font: 'Arial',
                      size: 18
                    })
                  ]
                }),
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                    new TextRun({
                      text: ` ${this.getValue(data.submission, 'control_cosecha_bitacoras') ? '■' : '❏'} Bitácoras de cosecha`,
                      font: 'Arial',
                      size: 18
                    })
                  ]
                }),
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                    new TextRun({
                      text: ` ${this.getValue(data.submission, 'control_cosecha_otro') ? '■' : '❏'} Otro: ${this.getValue(data.submission, 'control_cosecha_otro_texto')}`,
                      font: 'Arial',
                      size: 18
                    })
                  ]
                })
              ]
            }),
            new TableCell({
              children: [
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                    new TextRun({
                      text: ` ${this.getValue(data.submission, 'evidencia_cosecha_disponibilidad') ? '■' : '❏'} Disponibilidad de equipo`,
                      font: 'Arial',
                      size: 18
                    })
                  ]
                }),
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                    new TextRun({
                      text: ` ${this.getValue(data.submission, 'evidencia_cosecha_material') ? '■' : '❏'} Material marcado y etiquetado`,
                      font: 'Arial',
                      size: 18
                    })
                  ]
                }),
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                    new TextRun({
                      text: ` ${this.getValue(data.submission, 'evidencia_cosecha_otro') ? '■' : '❏'} Otro: ${this.getValue(data.submission, 'evidencia_cosecha_otro_texto')}`,
                      font: 'Arial',
                      size: 18
                    })
                  ]
                })
              ]
            }),
            new TableCell({
              children: [
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                    new TextRun({
                      text: ` ${this.getValue(data.submission, 'fase_produccion_campo_implementacion') === 'I' ? '■' : '❏'} I`,
                      font: 'Arial',
                      size: 18
                    })
                  ]
                }),
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                    new TextRun({
                      text: ` ${this.getValue(data.submission, 'fase_produccion_campo_implementacion') === 'NI' ? '■' : '❏'} NI`,
                      font: 'Arial',
                      size: 18
                    })
                  ]
                })
              ]
            }),
            new TableCell({
              children: [
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                    new TextRun({
                      text: ` ${this.getValue(data.submission, 'fase_produccion_campo_conformidad') === 'C' ? '■' : '❏'} C`,
                      font: 'Arial',
                      size: 18
                    })
                  ]
                }),
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                    new TextRun({
                      text: ` ${this.getValue(data.submission, 'fase_produccion_campo_conformidad') === 'NC' ? '■' : '❏'} NC`,
                      font: 'Arial',
                      size: 18
                    })
                  ]
                }),
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                    new TextRun({
                      text: ` ${this.getValue(data.submission, 'fase_produccion_campo_conformidad') === 'NA' ? '■' : '❏'} NA`,
                      font: 'Arial',
                      size: 18
                    })
                  ]
                })
              ]
            }),
            new TableCell({
              children: [
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                    new TextRun({
                      text: this.getValue(data.submission, 'fase_produccion_campo_comentarios_inspector'),
                      font: 'Arial',
                      size: 18
                    })
                  ]
                })
              ]
            })
          ]
        }),
        new TableRow({
          children: [
            new TableCell({
              children: [
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                    new TextRun({
                      text: 'Empaque',
                      font: 'Arial',
                      size: 18
                    })
                  ]
                })
              ]
            }),
            new TableCell({
              children: [
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                    new TextRun({
                      text: ` ${this.getValue(data.submission, 'riesgo_empaque_contaminacion') ? '■' : '❏'} Contaminación por material de empaque`,
                      font: 'Arial',
                      size: 18
                    })
                  ]
                }),
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                    new TextRun({
                      text: ` ${this.getValue(data.submission, 'riesgo_empaque_otro') ? '■' : '❏'} Otro: ${this.getValue(data.submission, 'riesgo_empaque_otro_texto')}`,
                      font: 'Arial',
                      size: 18
                    })
                  ]
                })
              ]
            }),
            new TableCell({
              children: [
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                    new TextRun({
                      text: ` ${this.getValue(data.submission, 'control_empaque_uso_empaque') ? '■' : '❏'} Uso de empaque grado alimenticio`,
                      font: 'Arial',
                      size: 18
                    })
                  ]
                }),
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                    new TextRun({
                      text: ` ${this.getValue(data.submission, 'control_empaque_no_uso_sustancias_prohibidas') ? '■' : '❏'} No uso de sustancias prohibidas en almacén de empaques`,
                      font: 'Arial',
                      size: 18
                    })
                  ]
                }),
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                    new TextRun({
                      text: ` ${this.getValue(data.submission, 'control_empaque_otro') ? '■' : '❏'} Otro: ${this.getValue(data.submission, 'control_empaque_otro_texto')}`,
                      font: 'Arial',
                      size: 18
                    })
                  ]
                })
              ]
            }),
            new TableCell({
              children: [
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                    new TextRun({
                      text: ` ${this.getValue(data.submission, 'evidencia_empaque_ficha_tecnica') ? '■' : '❏'} Ficha técnica de empaque`,
                      font: 'Arial',
                      size: 18
                    })
                  ]
                }),
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                    new TextRun({
                      text: ` ${this.getValue(data.submission, 'evidencia_empaque_registros') ? '■' : '❏'} Registros de limpieza y control de plagas en almacén`,
                      font: 'Arial',
                      size: 18
                    })
                  ]
                }),
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                    new TextRun({
                      text: ` ${this.getValue(data.submission, 'evidencia_empaque_otro') ? '■' : '❏'} Otro: ${this.getValue(data.submission, 'evidencia_empaque_otro_texto')}`,
                      font: 'Arial',
                      size: 18
                    })
                  ]
                })
              ]
            }),
            new TableCell({
              children: [
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                    new TextRun({
                      text: ` ${this.getValue(data.submission, 'fase_riego_implementacion') === 'I' ? '■' : '❏'} I`,
                      font: 'Arial',
                      size: 18
                    })
                  ]
                }),
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                    new TextRun({
                      text: ` ${this.getValue(data.submission, 'fase_riego_implementacion') === 'NI' ? '■' : '❏'} NI`,
                      font: 'Arial',
                      size: 18
                    })
                  ]
                })
              ]
            }),
            new TableCell({
              children: [
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                    new TextRun({
                      text: ` ${this.getValue(data.submission, 'fase_riego_conformidad') === 'C' ? '■' : '❏'} C`,
                      font: 'Arial',
                      size: 18
                    })
                  ]
                }),
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                    new TextRun({
                      text: ` ${this.getValue(data.submission, 'fase_riego_conformidad') === 'NC' ? '■' : '❏'} NC`,
                      font: 'Arial',
                      size: 18
                    })
                  ]
                }),
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                    new TextRun({
                      text: ` ${this.getValue(data.submission, 'fase_riego_conformidad') === 'NA' ? '■' : '❏'} NA`,
                      font: 'Arial',
                      size: 18
                    })
                  ]
                })
              ]
            }),
            new TableCell({
              children: [
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                    new TextRun({
                      text: this.getValue(data.submission, 'fase_riego_comentarios_inspector'),
                      font: 'Arial',
                      size: 18
                    })
                  ]
                })
              ]
            })
          ]
        }),
        new TableRow({
          children: [
            new TableCell({
              children: [
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                    new TextRun({
                      text: 'Transporte',
                      font: 'Arial',
                      size: 18
                    })
                  ]
                })
              ]
            }),
            new TableCell({
              children: [
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                    new TextRun({
                      text: ` ${this.getValue(data.submission, 'riesgo_transporte_contaminacion') ? '■' : '❏'} Contaminación por transporte`,
                      font: 'Arial',
                      size: 18
                    })
                  ]
                }),

                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                    new TextRun({
                      text: ` ${this.getValue(data.submission, 'riesgo_transporte_mezcla') ? '■' : '❏'} Mezcla con producto no compatible con orgánico`,
                      font: 'Arial',
                      size: 18
                    })
                  ]
                }),

                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                    new TextRun({
                      text: ` ${this.getValue(data.submission, 'riesgo_transporte_otro') ? '■' : '❏'} Otro: ${this.getValue(data.submission, 'riesgo_transporte_otro_texto')}`,
                      font: 'Arial',
                      size: 18
                    })
                  ]
                })
              ]
            }),
            new TableCell({
              children: [
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                    new TextRun({
                      text: ` ${this.getValue(data.submission, 'control_transporte_medio_transporte') ? '■' : '❏'} Medio de transporte exclusivo y marcado`,
                      font: 'Arial',
                      size: 18
                    })
                  ]
                }),
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                    new TextRun({
                      text: ` ${this.getValue(data.submission, 'control_transporte_marcado_envases') ? '■' : '❏'} Marcado y/o etiquetado de envases`,
                      font: 'Arial',
                      size: 18
                    })
                  ]
                }),
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                    new TextRun({
                      text: ` ${this.getValue(data.submission, 'control_transporte_otro') ? '■' : '❏'} Otro: ${this.getValue(data.submission, 'control_transporte_otro_texto')}`,
                      font: 'Arial',
                      size: 18
                    })
                  ]
                })
              ]
            }),
            new TableCell({
              children: [
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                    new TextRun({
                      text: ` ${this.getValue(data.submission, 'evidencia_transporte_verificacion_transporte') ? '■' : '❏'} Verificación física de transporte y registros`,
                      font: 'Arial',
                      size: 18
                    })
                  ]
                }),
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                    new TextRun({
                      text: ` ${this.getValue(data.submission, 'evidencia_transporte_verificacion_envases') ? '■' : '❏'} Verificación física de envases y registros`,
                      font: 'Arial',
                      size: 18
                    })
                  ]
                }),
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                    new TextRun({
                      text: ` ${this.getValue(data.submission, 'evidencia_transporte_otro') ? '■' : '❏'} Otro: ${this.getValue(data.submission, 'evidencia_transporte_otro_texto')}`,
                      font: 'Arial',
                      size: 18
                    })
                  ]
                })
              ]
            }),
            new TableCell({
              children: [
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                    new TextRun({
                      text: ` ${this.getValue(data.submission, 'fase_cosecha_implementacion') === 'I' ? '■' : '❏'} I`,
                      font: 'Arial',
                      size: 18
                    })
                  ]
                }),
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                    new TextRun({
                      text: ` ${this.getValue(data.submission, 'fase_cosecha_implementacion') === 'NI' ? '■' : '❏'} NI`,
                      font: 'Arial',
                      size: 18
                    })
                  ]
                })
              ]
            }),
            new TableCell({
              children: [
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                    new TextRun({
                      text: ` ${this.getValue(data.submission, 'fase_cosecha_conformidad') === 'C' ? '■' : '❏'} C`,
                      font: 'Arial',
                      size: 18
                    })
                  ]
                }),
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                    new TextRun({
                      text: ` ${this.getValue(data.submission, 'fase_cosecha_conformidad') === 'NC' ? '■' : '❏'} NC`,
                      font: 'Arial',
                      size: 18
                    })
                  ]
                }),
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                    new TextRun({
                      text: ` ${this.getValue(data.submission, 'fase_cosecha_conformidad') === 'NA' ? '■' : '❏'} NA`,
                      font: 'Arial',
                      size: 18
                    })
                  ]
                })
              ]
            }),
            new TableCell({
              children: [
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                    new TextRun({
                      text: this.getValue(data.submission, 'fase_cosecha_comentarios_inspector'),
                      font: 'Arial',
                      size: 18
                    })
                  ]
                })
              ]
            })
          ]
        }),

        new TableRow({
          children: [
            new TableCell({
              children: [
                new Paragraph({
                  alignment: AlignmentType.CENTER,
                  children: [
                    new TextRun({
                      text: 'CLASE INSECTA',
                      font: 'Arial',
                      size: 18
                    })
                  ]
                })
              ],
              columnSpan: 7,
              shading: {
                fill: '#C0C0C0'
              },
              verticalAlign: VerticalAlign.CENTER
            })
          ]
        }),
        new TableRow({
          children: [
            new TableCell({
              children: [
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                    new TextRun({
                      text: 'Ubicación del apiario',
                      font: 'Arial',
                      size: 18
                    })
                  ]
                })
              ]
            }),
            new TableCell({
              children: [
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                    new TextRun({
                      text: ` ${this.getValue(data.submission, 'riesgo_insecta_ubicacion_cultivo_cercano') ? '■' : '❏'} Cultivo convencional cercano`,
                      font: 'Arial',
                      size: 18
                    })
                  ]
                }),
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                    new TextRun({
                      text: ` ${this.getValue(data.submission, 'riesgo_insecta_ubicacion_basurero_cercano') ? '■' : '❏'} Basurero cercano`,
                      font: 'Arial',
                      size: 18
                    })
                  ]
                }),
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                    new TextRun({
                      text: ` ${this.getValue(data.submission, 'riesgo_insecta_ubicacion_carretera_cercana') ? '■' : '❏'} Carreteras cercanas`,
                      font: 'Arial',
                      size: 18
                    })
                  ]
                }),
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                    new TextRun({
                      text: ` ${this.getValue(data.submission, 'riesgo_insecta_ubicacion_poblacion_cercana') ? '■' : '❏'} Poblaciones cercanas`,
                      font: 'Arial',
                      size: 18
                    })
                  ]
                }),
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                    new TextRun({
                      text: ` ${this.getValue(data.submission, 'riesgo_insecta_ubicacion_otro') ? '■' : '❏'} Otro: ${this.getValue(data.submission, 'riesgo_insecta_ubicacion_otro_texto')}`,
                      font: 'Arial',
                      size: 18
                    })
                  ]
                })
              ]
            }),
            new TableCell({
              children: [
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                    new TextRun({
                      text: ` ${this.getValue(data.submission, 'control_insecta_ubicacion_apiarios') ? '■' : '❏'} Ubicación de apiarios en sitios sin riesgo (3 km de radio de área de pecoreo)`,
                      font: 'Arial',
                      size: 18
                    })
                  ]
                }),
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                    new TextRun({
                      text: ` ${this.getValue(data.submission, 'control_insecta_ubicacion_otro') ? '■' : '❏'} Otro: ${this.getValue(data.submission, 'control_insecta_ubicacion_otro_texto')}`,
                      font: 'Arial',
                      size: 18
                    })
                  ]
                })
              ]
            }),
            new TableCell({
              children: [
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                    new TextRun({
                      text: ` ${this.getValue(data.submission, 'evidencia_insecta_ubicacion_mapas') ? '■' : '❏'} Mapas de ubicación de los apiarios`,
                      font: 'Arial',
                      size: 18
                    })
                  ]
                }),
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                    new TextRun({
                      text: ` ${this.getValue(data.submission, 'evidencia_insecta_ubicacion_otro') ? '■' : '❏'} Otro: ${this.getValue(data.submission, 'evidencia_insecta_ubicacion_otro_texto')}`,
                      font: 'Arial',
                      size: 18
                    })
                  ]
                })
              ]
            }),
            new TableCell({
              children: [
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                    new TextRun({
                      text: ` ${this.getValue(data.submission, 'fase_produccion_campo_implementacion') === 'I' ? '■' : '❏'} I`,
                      font: 'Arial',
                      size: 18
                    })
                  ]
                }),
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                    new TextRun({
                      text: ` ${this.getValue(data.submission, 'fase_produccion_campo_implementacion') === 'NI' ? '■' : '❏'} NI`,
                      font: 'Arial',
                      size: 18
                    })
                  ]
                })
              ]
            }),
            new TableCell({
              children: [
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                    new TextRun({
                      text: ` ${this.getValue(data.submission, 'fase_produccion_campo_conformidad') === 'C' ? '■' : '❏'} C`,
                      font: 'Arial',
                      size: 18
                    })
                  ]
                }),
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                    new TextRun({
                      text: ` ${this.getValue(data.submission, 'fase_produccion_campo_conformidad') === 'NC' ? '■' : '❏'} NC`,
                      font: 'Arial',
                      size: 18
                    })
                  ]
                }),
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                    new TextRun({
                      text: ` ${this.getValue(data.submission, 'fase_produccion_campo_conformidad') === 'NA' ? '■' : '❏'} NA`,
                      font: 'Arial',
                      size: 18
                    })
                  ]
                })
              ]
            }),
            new TableCell({
              children: [
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                    new TextRun({
                      text: this.getValue(data.submission, 'fase_produccion_campo_comentarios_inspector'),
                      font: 'Arial',
                      size: 18
                    })
                  ]
                })
              ]
            })
          ]
        }),

        new TableRow({
          children: [
            new TableCell({
              children: [
                new Paragraph({
                  alignment: AlignmentType.CENTER,
                  children: [
                    new TextRun({
                      text: 'ACUICOLA',
                      font: 'Arial',
                      size: 18
                    })
                  ]
                })
              ],
              columnSpan: 7,
              shading: {
                fill: '#C0C0C0'
              },
              verticalAlign: VerticalAlign.CENTER
            })
          ]
        }),
        new TableRow({
          children: [
            new TableCell({
              children: [
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                    new TextRun({
                      text: 'Agua',
                      font: 'Arial',
                      size: 18
                    })
                  ]
                })
              ]
            }),
            new TableCell({
              children: [
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                    new TextRun({
                      text: ` ${this.getValue(data.submission, 'riesgo_agua_contaminada') ? '■' : '❏'} Agua contaminada`,
                      font: 'Arial',
                      size: 18
                    })
                  ]
                })
              ]
            }),
            new TableCell({
              children: [
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                    new TextRun({
                      text: ` ${this.getValue(data.submission, 'control_agua_tratamiento') ? '■' : '❏'} Tratamiento local de agua`,
                      font: 'Arial',
                      size: 18
                    })
                  ]
                }),
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                    new TextRun({
                      text: ` ${this.getValue(data.submission, 'control_agua_sumiinistro') ? '■' : '❏'} Suministro de agua libre de contaminación`,
                      font: 'Arial',
                      size: 18
                    })
                  ]
                }),
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                    new TextRun({
                      text: ` ${this.getValue(data.submission, 'control_agua_otro') ? '■' : '❏'} Otro: ${this.getValue(data.submission, 'control_agua_otro_texto')}`,
                      font: 'Arial',
                      size: 18
                    })
                  ]
                })
              ]
            }),
            new TableCell({
              children: [
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                    new TextRun({
                      text: ` ${this.getValue(data.submission, 'evidencia_agua_analisis') ? '■' : '❏'} Análisis de agua`,
                      font: 'Arial',
                      size: 18
                    })
                  ]
                }),
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                    new TextRun({
                      text: ` ${this.getValue(data.submission, 'evidencia_agua_contratos') ? '■' : '❏'} Facturas o contratos por agua sin contaminación`,
                      font: 'Arial',
                      size: 18
                    })
                  ]
                }),
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                    new TextRun({
                      text: ` ${this.getValue(data.submission, 'evidencia_agua_otro') ? '■' : '❏'} Otro: ${this.getValue(data.submission, 'evidencia_agua_otro_texto')}`,
                      font: 'Arial',
                      size: 18
                    })
                  ]
                })
              ]
            }),
            new TableCell({
              children: [
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                    new TextRun({
                      text: ` ${this.getValue(data.submission, 'fase_produccion_campo_implementacion') === 'I' ? '■' : '❏'} I`,
                      font: 'Arial',
                      size: 18
                    })
                  ]
                }),
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                    new TextRun({
                      text: ` ${this.getValue(data.submission, 'fase_produccion_campo_implementacion') === 'NI' ? '■' : '❏'} NI`,
                      font: 'Arial',
                      size: 18
                    })
                  ]
                })
              ]
            }),
            new TableCell({
              children: [
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                    new TextRun({
                      text: ` ${this.getValue(data.submission, 'fase_produccion_campo_conformidad') === 'C' ? '■' : '❏'} C`,
                      font: 'Arial',
                      size: 18
                    })
                  ]
                }),
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                    new TextRun({
                      text: ` ${this.getValue(data.submission, 'fase_produccion_campo_conformidad') === 'NC' ? '■' : '❏'} NC`,
                      font: 'Arial',
                      size: 18
                    })
                  ]
                }),
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                    new TextRun({
                      text: ` ${this.getValue(data.submission, 'fase_produccion_campo_conformidad') === 'NA' ? '■' : '❏'} NA`,
                      font: 'Arial',
                      size: 18
                    })
                  ]
                })
              ]
            }),
            new TableCell({
              children: [
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                    new TextRun({
                      text: this.getValue(data.submission, 'fase_produccion_campo_comentarios_inspector'),
                      font: 'Arial',
                      size: 18
                    })
                  ]
                })
              ]
            })
          ]
        })
      ],
      columnWidths: [1606, 3016, 3016, 3016, 1053, 1053, 2106],
      alignment: AlignmentType.CENTER
    })
    return table
  }

  getValue(submission, name) {
    var value = null
    for (const i in submission) {
      const element = submission[i]
      if (element.name === name) {
        value = element.value
      }
    }
    if (value) return value
    else return ''
  }

  getValueList(submission, name) {
    var value = null
    for (const i in submission) {
      const element = submission[i]
      if (element.name === name) {
        value = element.value
      }
    }
    if (value) return value
    else return []
  }

  // END MTO_FSOL_04_1
}
