<div class="row" style="margin-top: 30px;">
  <div class="text-center col-lg-6 offset-lg-3">
    <h4>
      <img
        src="/assets/icons/ecert.png"
        class="img-fluid"
        style="height: 60px;"
        alt="metrocert"
      />
    </h4>
    <h6>
      Copyright &copy; {{year}} &middot; eCert - Metrocert
    </h6>
  </div>
</div>
