<div class="modal-header">
  <h4 class="modal-title" id="modal-title">Cambiar contraseña</h4>
  <button
    type="button"
    class="close"
    aria-label="Close button"
    aria-describedby="modal-title"
    (click)="modal.dismiss('Cross click')"
  >
    <span aria-hidden="true">&times;</span>
  </button>
</div>

<form [formGroup]="form" (ngSubmit)="onSubmit()">
  <div class="modal-body">
    <p class="text-center">
      <strong>
        <span class="text-primary">{{ name }}</span> se recomienda usar una
        contraseña segura que no uses en ningún otro sitio.
      </strong>
    </p>
    <div class="form-group text-left pt-2 pb-2 actualPass">
      <label for="temp">Contraseña actual</label>
      <span class="icon-eye" (click)="showPasswordActual()">
          <i class="fas fa-eye"></i>
        </span>
      <input
        type = "{{isShowedActualPass ? 'text' : 'password'}}"
        formControlName="temp"
        class="form-control"
        [ngClass]="{ 'is-invalid': submitted && f.temp.errors }"
      />
      <div *ngIf="submitted && f.temp.errors" class="invalid-feedback">
        <div *ngIf="f.temp.errors.required">Contraseña actual requerida</div>
      </div>
    </div>
    <div class="form-group text-left pt-2 pb-2 newPass">
      <label for="newPassword">Contraseña nueva</label>
      <span class="icon-eye" (click)="showPasswordNew()">
          <i class="fas fa-eye"></i>
        </span>
      <input
        type = "{{isShowedNewPass ? 'text' : 'password'}}"
        formControlName="newPassword"
        class="form-control"
        [ngClass]="{ 'is-invalid': submitted && f.newPassword.errors }"
      />
      <div *ngIf="submitted && f.newPassword.errors" class="invalid-feedback">
        <div *ngIf="f.newPassword.errors.required">Contraseña nueva requerida</div>
      </div>
    </div>
    <div class="form-group text-left pt-2 pb-2 confirmPass">
      <label for="confirmPassword">Confirmar nueva contraseña</label>
      <span class="icon-eye" (click)="showPasswordConfirm()">
          <i class="fas fa-eye"></i>
        </span>
      <input
        type = "{{isShowedConfirm ? 'text' : 'password'}}"
        formControlName="confirmPassword"
        class="form-control"
        [ngClass]="{ 'is-invalid': submitted && f.confirmPassword.errors }"
      />
      <div *ngIf="submitted && f.confirmPassword.errors" class="invalid-feedback">
        <div *ngIf="f.confirmPassword.errors.required">Contraseña nueva requerida</div>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <button
      type="button"
      class="btn btn-outline-secondary"
      (click)="modal.dismiss('cancel click')"
    >
      Cancelar
    </button>
    <button
      [disabled]="loading"
      type="button"
      ngbAutofocus
      class="btn btn-primary"
      (click)="onSubmit()"
    >
      <span
        *ngIf="loading"
        class="spinner-border spinner-border-sm mr-1"
      ></span>
      Guardar
    </button>
  </div>
</form>
