import { AlignmentType, Document, Paragraph, TextRun, Media, Header, Footer, Table, TableRow, TableCell, VerticalAlign, WidthType, PageNumber,BorderStyle } from "docx";
import { logo } from "../logo";
import { DatePipe } from '@angular/common';

export class DocumentCreatorMTO_FSOL_08_5_v1 {

    public create_MTO_FSOL_08_5(data: any): Document {
        const document = new Document();
        const image = Media.addImage(document, logo, 50, 50);
        var datePipe = new DatePipe('es-MX');

        var unidadesProduccion = ''
        const unidades = this.getValueList(data.submission, "lista_unidades")
        unidades.forEach(unidad => {
            unidadesProduccion += unidad.nombre_unidad_produccion + ', '
        });
        unidadesProduccion = unidadesProduccion.slice(0, -2)

        document.addSection({
            headers: {
                default: new Header({
                    children: [
                        new Paragraph({ alignment: AlignmentType.CENTER, children: [image], }),
                        new Paragraph({
                            alignment: AlignmentType.CENTER, children: [
                                new TextRun({
                                    font: 'Book Antiqua',
                                    size: 20,
                                    bold: true,
                                    children: [`MTO-FSOL-08-5| R 001 | 03-Julio-2020 | Pág. `, PageNumber.CURRENT]
                                }),
                                new TextRun({
                                    font: 'Book Antiqua',
                                    size: 20,
                                    bold: true,
                                    children: [' de ', PageNumber.TOTAL_PAGES]
                                }),
                            ], spacing: { before: 200, after: 300 }
                        })
                    ]
                })
            },
            footers: {
                default: new Footer({
                    children: [
                        new Paragraph({ alignment: AlignmentType.CENTER }),
                        this.createFooter()
                    ]
                }),
            },
            children: [
                new Paragraph({
                    alignment: AlignmentType.CENTER,
                    children: [
                        new TextRun({
                            text: 'PLAN ORGÁNICO CLASE FUNGI',
                            font: 'Calibri (Cuerpo)',
                            size: 22,
                            bold: true
                        })
                    ],
                    spacing: { after: 200 }
                }),
                this.createTableUDP(data, datePipe),
                new Paragraph({
                    spacing: {
                        after: 400
                    }
                }),
                this.createRegisters(data.submission),
                new Paragraph({
                    spacing: {
                        after: 400
                    }
                }),
                this.createHandling(data.submission),
                new Paragraph({
                    alignment: AlignmentType.JUSTIFIED,
                    children: [
                        new TextRun({
                            text: `El que suscribe: ${data.customer.Legal_Authorities[0].Name} ${data.customer.Legal_Authorities[0].Middle_Name} ${data.customer.Legal_Authorities[0].Last_Name ? data.customer.Legal_Authorities[0].Last_Name : ''} representante legal de la operación: ${data.customer.Company_Type === "PERSONA_FISICA" ? unidadesProduccion : data.customer.Company_Name}, en cumplimiento de la Ley de Producción Orgánica, declaro que la información descrita en este Plan Orgánico y documentos adjuntos, es verídica, y me comprometo a mantenerlo actualizado, al igual que las actividades descritas en él, con la finalidad de cumplir con la Ley de Productos Orgánicos.`,
                            font: 'Calibri (Cuerpo)',
                            size: 22
                        })
                    ],
                    spacing: {
                        before: 400,
                        after: 400
                    },
                }),
                new Paragraph({
                    alignment: AlignmentType.JUSTIFIED,
                    children: [
                        new TextRun({
                            text: 'Atentamente:',
                            font: 'Arial',
                            size: 18, bold: true
                        })
                    ],
                    spacing: {
                        before: 400,
                        after: 400
                    },
                }),
                new Paragraph({
                    alignment: AlignmentType.LEFT,
                    children: [
                        new TextRun({
                            text: `Fecha: ${datePipe.transform(new Date(data.record.Applications[0].Signature_Date), 'd-MMMM-y')}                  Nombre y firma del operador: ${data.customer.Legal_Authorities[0].Name} ${data.customer.Legal_Authorities[0].Middle_Name} ${data.customer.Legal_Authorities[0].Last_Name ? data.customer.Legal_Authorities[0].Last_Name : ''}`,
                            font: 'Arial',
                            size: 20,
                            italics: true
                        })
                    ],
                    spacing: {
                        after: 200
                    }
                }),
                new Table({
                    rows: [
                        new TableRow({
                            children: [
                                new TableCell({
                                    children: [
                                        new Paragraph({
                                            alignment: AlignmentType.CENTER,
                                            children: [
                                                new TextRun({
                                                    text: 'EVALUACIÓN POR EL REVISOR',
                                                    font: 'Arial',
                                                    size: 20,
                                                    bold: true
                                                })
                                            ],
                                            spacing: {
                                                after: 75,
                                                before: 75
                                            }
                                        }),
                                    ],
                                    verticalAlign: VerticalAlign.CENTER,
                                    columnSpan: 2,
                                    shading: {
                                        fill: '#C0C0C0'
                                    }
                                })
                            ]
                        }),
                        new TableRow({
                            children: [
                                new TableCell({
                                    children: [
                                        new Paragraph({
                                            alignment: AlignmentType.RIGHT,
                                            children: [
                                                new TextRun({
                                                    text: 'Nombre del Revisor: ',
                                                    font: 'Calibri (Cuerpo)',
                                                    size: 22,
                                                })
                                            ],
                                            spacing: { before: 100, after: 200 }
                                        })],
                                    verticalAlign: VerticalAlign.CENTER,
                                }),
                                new TableCell({
                                    children: [new Paragraph({})],
                                    verticalAlign: VerticalAlign.CENTER,
                                    columnSpan: 1,
                                }),
                            ],
                        }),
                        new TableRow({
                            children: [
                                new TableCell({
                                    children: [
                                        new Paragraph({
                                            alignment: AlignmentType.RIGHT,
                                            children: [
                                                new TextRun({
                                                    text: 'Fecha: ',
                                                    font: 'Calibri (Cuerpo)',
                                                    size: 22,
                                                })
                                            ],
                                            spacing: { before: 100, after: 200 }
                                        })],
                                    verticalAlign: VerticalAlign.CENTER,
                                }),
                                new TableCell({
                                    children: [new Paragraph({})],
                                    verticalAlign: VerticalAlign.CENTER,
                                    columnSpan: 1,
                                }),
                            ],
                        }),
                        new TableRow({
                            children: [
                                new TableCell({
                                    children: [
                                        new Paragraph({
                                            alignment: AlignmentType.RIGHT,
                                            children: [
                                                new TextRun({
                                                    text: 'Conformidad: ',
                                                    font: 'Calibri (Cuerpo)',
                                                    size: 22,
                                                })
                                            ],
                                            spacing: { before: 100, after: 200 }
                                        })],
                                    verticalAlign: VerticalAlign.CENTER,
                                }),
                                new TableCell({
                                    children: [
                                        new Paragraph({
                                            alignment: AlignmentType.LEFT,
                                            children: [
                                                new TextRun({
                                                    text: '❏ No conformidades graves, no procederá la certificación.',
                                                    font: 'Arial',
                                                    size: 18
                                                })
                                            ]
                                        }),
                                        new Paragraph({
                                            alignment: AlignmentType.LEFT,
                                            children: [
                                                new TextRun({
                                                    text: '❏ No conformidades menores, se requiere de corrección antes de inspección. Listar los hallazgos abajo.',
                                                    font: 'Arial',
                                                    size: 18
                                                })
                                            ]
                                        }),
                                        new Paragraph({
                                            alignment: AlignmentType.LEFT,
                                            children: [
                                                new TextRun({
                                                    text: '❏ No conformidades menores, se puede realizar inspección.',
                                                    font: 'Arial',
                                                    size: 18
                                                })
                                            ]
                                        }),
                                        new Paragraph({
                                            alignment: AlignmentType.LEFT,
                                            children: [
                                                new TextRun({
                                                    text: '❏ Conformidad.',
                                                    font: 'Arial',
                                                    size: 18
                                                })
                                            ]
                                        }),
                                        new Paragraph({
                                            alignment: AlignmentType.LEFT,
                                            children: [
                                                new TextRun({
                                                    text: '❏ Otro:',
                                                    font: 'Arial',
                                                    size: 18
                                                })
                                            ]
                                        })
                                    ],
                                    verticalAlign: VerticalAlign.CENTER,
                                    columnSpan: 1,
                                }),
                            ],
                        }),
                        new TableRow({
                            children: [
                                new TableCell({
                                    children: [
                                        new Paragraph({
                                            alignment: AlignmentType.RIGHT,
                                            children: [
                                                new TextRun({
                                                    text: 'Hallazgos: ',
                                                    font: 'Calibri (Cuerpo)',
                                                    size: 22,
                                                })
                                            ],
                                            spacing: { before: 100, after: 200 }
                                        })],
                                    verticalAlign: VerticalAlign.CENTER,
                                }),
                                new TableCell({
                                    children: [new Paragraph({})],
                                    verticalAlign: VerticalAlign.CENTER,
                                    columnSpan: 1,
                                }),
                            ],
                        }),
                        new TableRow({
                            children: [
                                new TableCell({
                                    children: [
                                        new Paragraph({
                                            alignment: AlignmentType.RIGHT,
                                            children: [
                                                new TextRun({
                                                    text: 'Recomendaciones para inspector: ',
                                                    font: 'Calibri (Cuerpo)',
                                                    size: 22,
                                                })
                                            ],
                                            spacing: { before: 100, after: 200 }
                                        })],
                                    verticalAlign: VerticalAlign.CENTER,
                                }),
                                new TableCell({
                                    children: [new Paragraph({})],
                                    verticalAlign: VerticalAlign.CENTER,
                                    columnSpan: 1,
                                }),
                            ],
                        }),
                        new TableRow({
                            children: [
                                new TableCell({
                                    children: [
                                        new Paragraph({
                                            alignment: AlignmentType.RIGHT,
                                            children: [
                                                new TextRun({
                                                    text: 'Comentarios generales: ',
                                                    font: 'Calibri (Cuerpo)',
                                                    size: 22,
                                                })
                                            ],
                                            spacing: { before: 100, after: 200 }
                                        })],
                                    verticalAlign: VerticalAlign.CENTER,
                                }),
                                new TableCell({
                                    children: [new Paragraph({})],
                                    verticalAlign: VerticalAlign.CENTER,
                                    columnSpan: 1,
                                }),
                            ],
                        }),
                        new TableRow({
                            children: [
                                new TableCell({
                                    children: [
                                        new Paragraph({
                                            alignment: AlignmentType.RIGHT,
                                            children: [
                                                new TextRun({
                                                    text: 'Firma del Revisor: ',
                                                    font: 'Calibri (Cuerpo)',
                                                    size: 22,
                                                })
                                            ],
                                            spacing: { before: 100, after: 200 }
                                        })],
                                    verticalAlign: VerticalAlign.CENTER,
                                }),
                                new TableCell({
                                    children: [new Paragraph({})],
                                    verticalAlign: VerticalAlign.CENTER,
                                    columnSpan: 1,
                                }),
                            ],
                        }),
                    ],
                    margins: {
                        left: 70,
                        right: 70
                    },
                    width: {
                        size: 100,
                        type: WidthType.PERCENTAGE,
                    },
                    columnWidths: [2410, 7227]
                }),
            ],
            margins: {
                top: 350,
                bottom: 500,
                left: 1000,
                right: 1000
            }
        });

        return document;
    }

    public createFooter(): Table {
        const table = new Table({
            rows: [
                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: 'Elaboró:',
                                            font: 'Calibri (Cuerpo)',
                                            size: 22
                                        })
                                    ]
                                })
                            ],
                            width: {
                                size: 33,
                                type: WidthType.PERCENTAGE
                            }
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: 'Revisó:',
                                            font: 'Calibri (Cuerpo)',
                                            size: 22
                                        })
                                    ]
                                })
                            ],
                            width: {
                                size: 33,
                                type: WidthType.PERCENTAGE
                            }
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: 'Autorizó:',
                                            font: 'Calibri (Cuerpo)',
                                            size: 22
                                        })
                                    ]
                                }),
                            ],
                            width: {
                                size: 33,
                                type: WidthType.PERCENTAGE
                            }
                        })
                    ]
                }),
                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: 'Director de certificación',
                                            font: 'Calibri (Cuerpo)',
                                            size: 16,
                                            bold: true
                                        })
                                    ]
                                }),

                            ],
                            width: {
                                size: 33,
                                type: WidthType.PERCENTAGE
                            },
                            shading: {
                                fill: '#C0C0C0'
                            }
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: 'Responsable de calidad',
                                            font: 'Calibri (Cuerpo)',
                                            size: 16,
                                            bold: true
                                        })
                                    ]
                                }),

                            ],
                            width: {
                                size: 33,
                                type: WidthType.PERCENTAGE
                            },
                            shading: {
                                fill: '#C0C0C0'
                            }
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: 'Director general',
                                            font: 'Calibri (Cuerpo)',
                                            size: 16,
                                            bold: true
                                        })
                                    ]
                                }),
                            ],
                            width: {
                                size: 33,
                                type: WidthType.PERCENTAGE
                            },
                            shading: {
                                fill: '#C0C0C0'
                            }
                        })
                    ]
                })
            ],
            margins: {
                left: 70,
                right: 70
            },
            columnWidths: [3213, 3213, 3212]
        })
        return table
    }

    public createRegisters(submission): Table {
        const table = new Table({
            rows: [
                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: 'REGISTROS',
                                            font: 'Arial',
                                            size: 20,
                                            bold: true
                                        })
                                    ],
                                    spacing: {
                                        after: 75,
                                        before: 75
                                    }
                                }),
                            ],
                            verticalAlign: VerticalAlign.CENTER,
                            columnSpan: 7,
                            shading: {
                                fill: '#C0C0C0'
                            }
                        })
                    ]
                }),
                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: 'No.',
                                            font: 'Arial',
                                            size: 18,
                                            bold: true
                                        })
                                    ]
                                })
                            ],
                            rowSpan: 2,
                            verticalAlign: VerticalAlign.CENTER,
                            width: {
                                size: 25,
                                type: WidthType.PERCENTAGE
                            }
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: 'Requisitos',
                                            font: 'Arial',
                                            size: 18,
                                            bold: true
                                        })
                                    ]
                                })
                            ],
                            rowSpan: 2,
                            verticalAlign: VerticalAlign.CENTER,
                            width: {
                                size: 15,
                                type: WidthType.PERCENTAGE
                            }
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: 'Declaración',
                                            font: 'Arial',
                                            size: 18,
                                            bold: true
                                        })
                                    ]
                                })
                            ],
                            rowSpan: 2,
                            verticalAlign: VerticalAlign.CENTER,
                            width: {
                                size: 15,
                                type: WidthType.PERCENTAGE
                            }
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: 'Comentarios',
                                            font: 'Arial',
                                            size: 18,
                                            bold: true
                                        })
                                    ]
                                })
                            ],
                            rowSpan: 2,
                            verticalAlign: VerticalAlign.CENTER,
                            width: {
                                size: 15,
                                type: WidthType.PERCENTAGE
                            }
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: 'Verificación en sitio (Evaluada por Inspector)',
                                            font: 'Arial',
                                            size: 18,
                                            bold: true
                                        })
                                    ]
                                })
                            ],
                            columnSpan: 3,
                            verticalAlign: VerticalAlign.CENTER,
                            width: {
                                size: 15,
                                type: WidthType.PERCENTAGE
                            }
                        })
                    ]
                }),
                new TableRow({
                    children: [new TableCell({
                        children: [
                            new Paragraph({
                                alignment: AlignmentType.CENTER,
                                children: [
                                    new TextRun({
                                        text: 'Implementación',
                                        font: 'Arial',
                                        size: 18,
                                        bold: true
                                    })
                                ]
                            })
                        ],
                        verticalAlign: VerticalAlign.CENTER
                    }),
                    new TableCell({
                        children: [
                            new Paragraph({
                                alignment: AlignmentType.CENTER,
                                children: [
                                    new TextRun({
                                        text: 'Confirmación',
                                        font: 'Arial',
                                        size: 18,
                                        bold: true
                                    })
                                ]
                            })
                        ],
                        verticalAlign: VerticalAlign.CENTER,
                        columnSpan: 2
                    }),]
                }),
                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: '1',
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                })
                            ],
                            verticalAlign: VerticalAlign.TOP
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: 'He incluido una lista completa de las unidades de producción que estoy sometiendo a evaluación para la certificación orgánica. MTO-LPO-FSOL-02',
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                })
                            ],
                            verticalAlign: VerticalAlign.TOP
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: this.getValue(submission, 'plan_organico_fungi_registros_1_declaracion') === 'Si' ? '■ SÍ  ❏ NO' : '❏ SÍ  ■ NO',
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                })
                            ],
                            verticalAlign: VerticalAlign.TOP
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: this.getValue(submission, 'plan_organico_fungi_registros_1_comentarios'),
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                })
                            ],
                            verticalAlign: VerticalAlign.TOP
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: '❏ I  ❏ NI',
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                })
                            ],
                            verticalAlign: VerticalAlign.TOP
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: '❏ C  ❏ NC ❏ NA',
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                })
                            ],
                            verticalAlign: VerticalAlign.TOP
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: 'Comentario:',
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                })
                            ],
                            verticalAlign: VerticalAlign.TOP
                        })
                    ]
                }),
                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: '2',
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                })
                            ],
                            verticalAlign: VerticalAlign.TOP
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: 'He incluido croquis de cada una de las unidades de producción. MTO-LPO-FSOL-03',
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                })
                            ],
                            verticalAlign: VerticalAlign.TOP
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: this.getValue(submission, 'plan_organico_fungi_registros_2_declaracion') === 'Si' ? '■ SÍ  ❏ NO' : '❏ SÍ  ■ NO',
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                })
                            ],
                            verticalAlign: VerticalAlign.TOP
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: this.getValue(submission, 'plan_organico_fungi_registros_2_comentarios'),
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                })
                            ],
                            verticalAlign: VerticalAlign.TOP
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: '❏ I  ❏ NI',
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                })
                            ],
                            verticalAlign: VerticalAlign.TOP
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: '❏ C  ❏ NC ❏ NA',
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                })
                            ],
                            verticalAlign: VerticalAlign.TOP
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: 'Comentario:',
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                })
                            ],
                            verticalAlign: VerticalAlign.TOP
                        })
                    ]
                }),
                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: '3',
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                })
                            ],
                            verticalAlign: VerticalAlign.TOP
                        }),
                        new TableCell({
                            children: [
                                new Table({
                                    rows: [
                                        new TableRow({
                                            children: [
                                                new TableCell({
                                                    children: [
                                                        new Paragraph({
                                                            alignment: AlignmentType.LEFT,
                                                            children: [
                                                                new TextRun({
                                                                    text: 'Cuento con las siguientes bitácoras:',
                                                                    font: 'Arial',
                                                                    size: 18
                                                                })
                                                            ]
                                                        })
                                                    ],
                                                    borders: {
                                                        left: {
                                                            style: BorderStyle.NONE,
                                                            color: "white",
                                                            size: 0
                                                        },
                                                        top: {
                                                            style: BorderStyle.NONE,
                                                            color: "white",
                                                            size: 0
                                                        },
                                                        bottom: {
                                                            style: BorderStyle.NONE,
                                                            color: "white",
                                                            size: 0
                                                        }
                                                    }
                                                }),
                                                new TableCell({
                                                    children: [
                                                        new Paragraph({
                                                            alignment: AlignmentType.CENTER,
                                                            children: [
                                                                new TextRun({
                                                                    text: '',
                                                                    font: 'Arial',
                                                                    size: 18
                                                                })
                                                            ]
                                                        })
                                                    ],
                                                    borders: {
                                                        right: {
                                                            style: BorderStyle.NONE,
                                                            color: "white",
                                                            size: 0
                                                        },
                                                        top: {
                                                            style: BorderStyle.NONE,
                                                            color: "white",
                                                            size: 0
                                                        },
                                                        bottom: {
                                                            style: BorderStyle.NONE,
                                                            color: "white",
                                                            size: 0
                                                        }
                                                    }
                                                })
                                            ]
                                        }),
                                        new TableRow({
                                            children: [
                                                new TableCell({
                                                    children: [
                                                        new Paragraph({
                                                            alignment: AlignmentType.LEFT,
                                                            children: [
                                                                new TextRun({
                                                                    text: `${this.getValue(submission, 'plan_organico_fungi_registros_3_a_declaracion') === "Si" ? '■' : '❏'} Compra e Inventario de semillas/micelio`,
                                                                    font: 'Arial',
                                                                    size: 18
                                                                })
                                                            ]
                                                        })
                                                    ],
                                                    borders: {
                                                        left: {
                                                            style: BorderStyle.NONE,
                                                            color: "white",
                                                            size: 0
                                                        },
                                                        top: {
                                                            style: BorderStyle.NONE,
                                                            color: "white",
                                                            size: 0
                                                        },
                                                        bottom: {
                                                            style: BorderStyle.NONE,
                                                            color: "white",
                                                            size: 0
                                                        }
                                                    }
                                                }),
                                                new TableCell({
                                                    children: [
                                                        new Paragraph({
                                                            alignment: AlignmentType.CENTER,
                                                            children: [
                                                                new TextRun({
                                                                    text: `${this.getValue(submission, 'plan_organico_fungi_registros_3_a_declaracion') === "Si" ? '■ Sí ❏ No' : '❏ Sí ■ No'}`,
                                                                    font: 'Arial',
                                                                    size: 18
                                                                })
                                                            ]
                                                        })
                                                    ],
                                                    borders: {
                                                        right: {
                                                            style: BorderStyle.NONE,
                                                            color: "white",
                                                            size: 0
                                                        },
                                                        top: {
                                                            style: BorderStyle.NONE,
                                                            color: "white",
                                                            size: 0
                                                        },
                                                        bottom: {
                                                            style: BorderStyle.NONE,
                                                            color: "white",
                                                            size: 0
                                                        }
                                                    }
                                                })
                                            ]
                                        }),
                                        new TableRow({
                                            children: [
                                                new TableCell({
                                                    children: [
                                                        new Paragraph({
                                                            alignment: AlignmentType.LEFT,
                                                            children: [
                                                                new TextRun({
                                                                    text: `${this.getValue(submission, 'plan_organico_fungi_registros_3_b_declaracion') === "Si" ? '■' : '❏'} Compra e Inventario de insumos`,
                                                                    font: 'Arial',
                                                                    size: 18
                                                                })
                                                            ]
                                                        })
                                                    ],
                                                    borders: {
                                                        left: {
                                                            style: BorderStyle.NONE,
                                                            color: "white",
                                                            size: 0
                                                        },
                                                        top: {
                                                            style: BorderStyle.NONE,
                                                            color: "white",
                                                            size: 0
                                                        },
                                                        bottom: {
                                                            style: BorderStyle.NONE,
                                                            color: "white",
                                                            size: 0
                                                        }
                                                    }
                                                }),
                                                new TableCell({
                                                    children: [
                                                        new Paragraph({
                                                            alignment: AlignmentType.CENTER,
                                                            children: [
                                                                new TextRun({
                                                                    text: `${this.getValue(submission, 'plan_organico_fungi_registros_3_b_declaracion') === "Si" ? '■ Sí ❏ No' : '❏ Sí ■ No'}`,
                                                                    font: 'Arial',
                                                                    size: 18
                                                                })
                                                            ]
                                                        })
                                                    ],
                                                    borders: {
                                                        right: {
                                                            style: BorderStyle.NONE,
                                                            color: "white",
                                                            size: 0
                                                        },
                                                        top: {
                                                            style: BorderStyle.NONE,
                                                            color: "white",
                                                            size: 0
                                                        },
                                                        bottom: {
                                                            style: BorderStyle.NONE,
                                                            color: "white",
                                                            size: 0
                                                        }
                                                    }
                                                })
                                            ]
                                        }),
                                        new TableRow({
                                            children: [
                                                new TableCell({
                                                    children: [
                                                        new Paragraph({
                                                            alignment: AlignmentType.LEFT,
                                                            children: [
                                                                new TextRun({
                                                                    text: `${this.getValue(submission, 'plan_organico_fungi_registros_3_c_declaracion') === "Si" ? '■' : '❏'} Aplicación de insumos`,
                                                                    font: 'Arial',
                                                                    size: 18
                                                                })
                                                            ]
                                                        })
                                                    ],
                                                    borders: {
                                                        left: {
                                                            style: BorderStyle.NONE,
                                                            color: "white",
                                                            size: 0
                                                        },
                                                        top: {
                                                            style: BorderStyle.NONE,
                                                            color: "white",
                                                            size: 0
                                                        },
                                                        bottom: {
                                                            style: BorderStyle.NONE,
                                                            color: "white",
                                                            size: 0
                                                        }
                                                    }
                                                }),
                                                new TableCell({
                                                    children: [
                                                        new Paragraph({
                                                            alignment: AlignmentType.CENTER,
                                                            children: [
                                                                new TextRun({
                                                                    text: `${this.getValue(submission, 'plan_organico_fungi_registros_3_c_declaracion') === "Si" ? '■ Sí ❏ No' : '❏ Sí ■ No'}`,
                                                                    font: 'Arial',
                                                                    size: 18
                                                                })
                                                            ]
                                                        })
                                                    ],
                                                    borders: {
                                                        right: {
                                                            style: BorderStyle.NONE,
                                                            color: "white",
                                                            size: 0
                                                        },
                                                        top: {
                                                            style: BorderStyle.NONE,
                                                            color: "white",
                                                            size: 0
                                                        },
                                                        bottom: {
                                                            style: BorderStyle.NONE,
                                                            color: "white",
                                                            size: 0
                                                        }
                                                    }
                                                })
                                            ]
                                        }),
                                        new TableRow({
                                            children: [
                                                new TableCell({
                                                    children: [
                                                        new Paragraph({
                                                            alignment: AlignmentType.LEFT,
                                                            children: [
                                                                new TextRun({
                                                                    text: `${this.getValue(submission, 'plan_organico_fungi_registros_3_d_declaracion') === "Si" ? '■' : '❏'} Cosecha`,
                                                                    font: 'Arial',
                                                                    size: 18
                                                                })
                                                            ]
                                                        })
                                                    ],
                                                    borders: {
                                                        left: {
                                                            style: BorderStyle.NONE,
                                                            color: "white",
                                                            size: 0
                                                        },
                                                        top: {
                                                            style: BorderStyle.NONE,
                                                            color: "white",
                                                            size: 0
                                                        },
                                                        bottom: {
                                                            style: BorderStyle.NONE,
                                                            color: "white",
                                                            size: 0
                                                        }
                                                    }
                                                }),
                                                new TableCell({
                                                    children: [
                                                        new Paragraph({
                                                            alignment: AlignmentType.CENTER,
                                                            children: [
                                                                new TextRun({
                                                                    text: `${this.getValue(submission, 'plan_organico_fungi_registros_3_d_declaracion') === "Si" ? '■ Sí ❏ No' : '❏ Sí ■ No'}`,
                                                                    font: 'Arial',
                                                                    size: 18
                                                                })
                                                            ]
                                                        })
                                                    ],
                                                    borders: {
                                                        right: {
                                                            style: BorderStyle.NONE,
                                                            color: "white",
                                                            size: 0
                                                        },
                                                        top: {
                                                            style: BorderStyle.NONE,
                                                            color: "white",
                                                            size: 0
                                                        },
                                                        bottom: {
                                                            style: BorderStyle.NONE,
                                                            color: "white",
                                                            size: 0
                                                        }
                                                    }
                                                })
                                            ]
                                        }),
                                        new TableRow({
                                            children: [
                                                new TableCell({
                                                    children: [
                                                        new Paragraph({
                                                            alignment: AlignmentType.LEFT,
                                                            children: [
                                                                new TextRun({
                                                                    text: `${this.getValue(submission, 'plan_organico_fungi_registros_3_e_declaracion') === "Si" ? '■' : '❏'} Ventas`,
                                                                    font: 'Arial',
                                                                    size: 18
                                                                })
                                                            ]
                                                        })
                                                    ],
                                                    borders: {
                                                        left: {
                                                            style: BorderStyle.NONE,
                                                            color: "white",
                                                            size: 0
                                                        },
                                                        top: {
                                                            style: BorderStyle.NONE,
                                                            color: "white",
                                                            size: 0
                                                        },
                                                        bottom: {
                                                            style: BorderStyle.NONE,
                                                            color: "white",
                                                            size: 0
                                                        }
                                                    }
                                                }),
                                                new TableCell({
                                                    children: [
                                                        new Paragraph({
                                                            alignment: AlignmentType.CENTER,
                                                            children: [
                                                                new TextRun({
                                                                    text: `${this.getValue(submission, 'plan_organico_fungi_registros_3_e_declaracion') === "Si" ? '■ Sí ❏ No' : '❏ Sí ■ No'}`,
                                                                    font: 'Arial',
                                                                    size: 18
                                                                })
                                                            ]
                                                        })
                                                    ],
                                                    borders: {
                                                        right: {
                                                            style: BorderStyle.NONE,
                                                            color: "white",
                                                            size: 0
                                                        },
                                                        top: {
                                                            style: BorderStyle.NONE,
                                                            color: "white",
                                                            size: 0
                                                        },
                                                        bottom: {
                                                            style: BorderStyle.NONE,
                                                            color: "white",
                                                            size: 0
                                                        }
                                                    }
                                                })
                                            ]
                                        }),
                                        new TableRow({
                                            children: [
                                                new TableCell({
                                                    children: [
                                                        new Paragraph({
                                                            alignment: AlignmentType.LEFT,
                                                            children: [
                                                                new TextRun({
                                                                    text: `${this.getValue(submission, 'plan_organico_fungi_registros_3_f_declaracion') === "Si" ? '■' : '❏'} Destino producto de zonas buffer`,
                                                                    font: 'Arial',
                                                                    size: 18
                                                                })
                                                            ]
                                                        })
                                                    ],
                                                    borders: {
                                                        left: {
                                                            style: BorderStyle.NONE,
                                                            color: "white",
                                                            size: 0
                                                        },
                                                        top: {
                                                            style: BorderStyle.NONE,
                                                            color: "white",
                                                            size: 0
                                                        },
                                                        bottom: {
                                                            style: BorderStyle.NONE,
                                                            color: "white",
                                                            size: 0
                                                        }
                                                    }
                                                }),
                                                new TableCell({
                                                    children: [
                                                        new Paragraph({
                                                            alignment: AlignmentType.CENTER,
                                                            children: [
                                                                new TextRun({
                                                                    text: `${this.getValue(submission, 'plan_organico_fungi_registros_3_f_declaracion') === "Si" ? '■ Sí ❏ No' : '❏ Sí ■ No'}`,
                                                                    font: 'Arial',
                                                                    size: 18
                                                                })
                                                            ]
                                                        })
                                                    ],
                                                    borders: {
                                                        right: {
                                                            style: BorderStyle.NONE,
                                                            color: "white",
                                                            size: 0
                                                        },
                                                        top: {
                                                            style: BorderStyle.NONE,
                                                            color: "white",
                                                            size: 0
                                                        },
                                                        bottom: {
                                                            style: BorderStyle.NONE,
                                                            color: "white",
                                                            size: 0
                                                        }
                                                    }
                                                })
                                            ]
                                        }),
                                        new TableRow({
                                            children: [
                                                new TableCell({
                                                    children: [
                                                        new Paragraph({
                                                            alignment: AlignmentType.LEFT,
                                                            children: [
                                                                new TextRun({
                                                                    text: `${this.getValue(submission, 'plan_organico_fungi_registros_3_otro_declaracion') ? '■' : '❏'} Otra: ${this.getValue(submission, 'plan_organico_fungi_registros_3_otro')}`,
                                                                    font: 'Arial',
                                                                    size: 18
                                                                })
                                                            ]
                                                        })
                                                    ],
                                                    borders: {
                                                        left: {
                                                            style: BorderStyle.NONE,
                                                            color: "white",
                                                            size: 0
                                                        },
                                                        top: {
                                                            style: BorderStyle.NONE,
                                                            color: "white",
                                                            size: 0
                                                        },
                                                        bottom: {
                                                            style: BorderStyle.NONE,
                                                            color: "white",
                                                            size: 0
                                                        }
                                                    }
                                                }),
                                                new TableCell({
                                                    children: [
                                                        new Paragraph({
                                                            alignment: AlignmentType.CENTER,
                                                            children: [
                                                                new TextRun({
                                                                    text: `${this.getValue(submission, 'plan_organico_fungi_registros_3_otro_declaracion') === "Si" ? '■ Sí ❏ No' : '❏ Sí ■ No'}`,
                                                                    font: 'Arial',
                                                                    size: 18
                                                                })
                                                            ]
                                                        })
                                                    ],
                                                    borders: {
                                                        right: {
                                                            style: BorderStyle.NONE,
                                                            color: "white",
                                                            size: 0
                                                        },
                                                        top: {
                                                            style: BorderStyle.NONE,
                                                            color: "white",
                                                            size: 0
                                                        },
                                                        bottom: {
                                                            style: BorderStyle.NONE,
                                                            color: "white",
                                                            size: 0
                                                        }
                                                    }
                                                })
                                            ]
                                        })
                                    ],
                                    columnWidths: [1994, 1447]
                                })
                            ],
                            columnSpan: 2
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: this.getValue(submission, 'plan_organico_fungi_registros_3_comentarios'),
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                })
                            ],
                            verticalAlign: VerticalAlign.TOP
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: '❏ I  ❏ NI',
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                })
                            ],
                            verticalAlign: VerticalAlign.TOP
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: '❏ C  ❏ NC ❏ NA',
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                })
                            ],
                            verticalAlign: VerticalAlign.TOP
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: 'Comentario:',
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                })
                            ],
                            verticalAlign: VerticalAlign.TOP
                        })
                    ]
                }),
                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: '4',
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                })
                            ],
                            verticalAlign: VerticalAlign.TOP
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: 'He incluido una lista completa de riesgos para la integridad orgánica de los productos en el formato MTO-FSOL-04',
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                })
                            ],
                            verticalAlign: VerticalAlign.TOP
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: this.getValue(submission, 'plan_organico_fungi_registros_4_declaracion') === 'Si' ? '■ SÍ  ❏ NO' : '❏ SÍ  ■ NO',
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                })
                            ],
                            verticalAlign: VerticalAlign.TOP
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: this.getValue(submission, 'plan_organico_fungi_registros_4_comentarios'),
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                })
                            ],
                            verticalAlign: VerticalAlign.TOP
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: '❏ I  ❏ NI',
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                })
                            ],
                            verticalAlign: VerticalAlign.TOP
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: '❏ C  ❏ NC ❏ NA',
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                })
                            ],
                            verticalAlign: VerticalAlign.TOP
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: 'Comentario:',
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                })
                            ],
                            verticalAlign: VerticalAlign.TOP
                        })
                    ]
                }),
                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: '5',
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                })
                            ],
                            verticalAlign: VerticalAlign.TOP
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: 'He incluido un análisis completo de riesgos de contaminación por colindantes en el formato MTO-FSOL-06',
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                })
                            ],
                            verticalAlign: VerticalAlign.TOP
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: this.getValue(submission, 'plan_organico_fungi_registros_5_declaracion') === 'Si' ? '■ SÍ  ❏ NO' : '❏ SÍ  ■ NO',
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                })
                            ],
                            verticalAlign: VerticalAlign.TOP
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: this.getValue(submission, 'plan_organico_fungi_registros_5_comentarios'),
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                })
                            ],
                            verticalAlign: VerticalAlign.TOP
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: '❏ I  ❏ NI',
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                })
                            ],
                            verticalAlign: VerticalAlign.TOP
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: '❏ C  ❏ NC ❏ NA',
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                })
                            ],
                            verticalAlign: VerticalAlign.TOP
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: 'Comentario:',
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                })
                            ],
                            verticalAlign: VerticalAlign.TOP
                        })
                    ]
                }),
                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: '6',
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                })
                            ],
                            verticalAlign: VerticalAlign.TOP
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: 'He incluido una lista completa de los insumos que utilizo para la  fertilización en el formato MTO-FSOL-07',
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                })
                            ],
                            verticalAlign: VerticalAlign.TOP
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: this.getValue(submission, 'plan_organico_fungi_registros_6_declaracion') === 'Si' ? '■ SÍ  ❏ NO' : '❏ SÍ  ■ NO',
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                })
                            ],
                            verticalAlign: VerticalAlign.TOP
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: this.getValue(submission, 'plan_organico_fungi_registros_6_comentarios'),
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                })
                            ],
                            verticalAlign: VerticalAlign.TOP
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: '❏ I  ❏ NI',
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                })
                            ],
                            verticalAlign: VerticalAlign.TOP
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: '❏ C  ❏ NC ❏ NA',
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                })
                            ],
                            verticalAlign: VerticalAlign.TOP
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: 'Comentario:',
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                })
                            ],
                            verticalAlign: VerticalAlign.TOP
                        })
                    ]
                }),
                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: '7',
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                })
                            ],
                            verticalAlign: VerticalAlign.TOP
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: 'Registo de la fuente, cantidad, tipo de estiércol y evaluación del cumplimiento del criterio 5.5.1 CAN/CGSB-32.310',
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                })
                            ],
                            verticalAlign: VerticalAlign.TOP
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: this.getValue(submission, 'plan_organico_fungi_registros_7_declaracion') === 'Si' ? '■ SÍ  ❏ NO' : '❏ SÍ  ■ NO',
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                })
                            ],
                            verticalAlign: VerticalAlign.TOP
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: this.getValue(submission, 'plan_organico_fungi_registros_7_comentarios'),
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                })
                            ],
                            verticalAlign: VerticalAlign.TOP
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: '❏ I  ❏ NI',
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                })
                            ],
                            verticalAlign: VerticalAlign.TOP
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: '❏ C  ❏ NC ❏ NA',
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                })
                            ],
                            verticalAlign: VerticalAlign.TOP
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: 'Comentario:',
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                })
                            ],
                            verticalAlign: VerticalAlign.TOP
                        })
                    ]
                }),
                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: '8',
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                })
                            ],
                            verticalAlign: VerticalAlign.TOP
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: 'He incluido una lista completa de las sustancias que utilizo para el control de plagas y enfermedades en el formato MTO-FSOL-07 ',
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                })
                            ],
                            verticalAlign: VerticalAlign.TOP
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: this.getValue(submission, 'plan_organico_fungi_registros_8_declaracion') === 'Si' ? '■ SÍ  ❏ NO' : '❏ SÍ  ■ NO',
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                })
                            ],
                            verticalAlign: VerticalAlign.TOP
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: this.getValue(submission, 'plan_organico_fungi_registros_8_comentarios'),
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                })
                            ],
                            verticalAlign: VerticalAlign.TOP
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: '❏ I  ❏ NI',
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                })
                            ],
                            verticalAlign: VerticalAlign.TOP
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: '❏ C  ❏ NC ❏ NA',
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                })
                            ],
                            verticalAlign: VerticalAlign.TOP
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: 'Comentario:',
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                })
                            ],
                            verticalAlign: VerticalAlign.TOP
                        })
                    ]
                }),
                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: '9',
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                })
                            ],
                            verticalAlign: VerticalAlign.TOP
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: 'He incluido la solicitud de la excepción para:',
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                }),
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: `${this.getValue(submission, 'plan_organico_fungi_registros_9_a_declaracion') === "Si" ? '■' : '❏'} Reconocimiento retroactivo de periodo de conversión`,
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                }),
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: `${this.getValue(submission, 'plan_organico_fungi_registros_9_b_declaracion') === "Si" ? '■' : '❏'} Uso de semillas no orgánicas`,
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                }),
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: `${this.getValue(submission, 'plan_organico_fungi_registros_9_c_declaracion') === "Si" ? '■' : '❏'} Producción paralela`,
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                }),
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: `${this.getValue(submission, 'plan_organico_fungi_registros_9_otro_declaracion') ? '■' : '❏'} Otra: ${this.getValue(submission, 'plan_organico_fungi_registros_9_otro')}`,
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                }),
                            ],
                            verticalAlign: VerticalAlign.TOP
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: '',
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                }),
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: '',
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                }),
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: '',
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                }),
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: `${this.getValue(submission, 'plan_organico_fungi_registros_9_a_declaracion') === "Si" ? '■ Sí ❏ No' : '❏ Sí ■ No'}`,
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ],
                                    spacing: {
                                        after: 500
                                    }
                                }),
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: `${this.getValue(submission, 'plan_organico_fungi_registros_9_b_declaracion') === "Si" ? '■ Sí ❏ No' : '❏ Sí ■ No'}`,
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ],
                                    spacing: {
                                        after: 200
                                    }
                                }),
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: `${this.getValue(submission, 'plan_organico_fungi_registros_9_c_declaracion') === "Si" ? '■ Sí ❏ No' : '❏ Sí ■ No'}`,
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ],
                                    spacing: {
                                        after: 200
                                    }
                                }),
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: `${this.getValue(submission, 'plan_organico_fungi_registros_9_otro_declaracion') === "Si" ? '■ Sí ❏ No' : '❏ Sí ■ No'}`,
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                }),
                            ],
                            verticalAlign: VerticalAlign.TOP
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: this.getValue(submission, 'plan_organico_fungi_registros_9_comentarios'),
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                })
                            ],
                            verticalAlign: VerticalAlign.TOP
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: '❏ I  ❏ NI',
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                })
                            ],
                            verticalAlign: VerticalAlign.TOP
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: '❏ C  ❏ NC ❏ NA',
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                })
                            ],
                            verticalAlign: VerticalAlign.TOP
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: 'Comentario:',
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                })
                            ],
                            verticalAlign: VerticalAlign.TOP
                        })
                    ]
                }),
            ],
            margins: {
                left: 70,
                right: 70
            },
            columnWidths: [800, 2067, 1467, 1767, 1767, 1770, 1767]
        })
        return table
    }
    public createHandling(submission): Table {
        const table = new Table({
            rows: [
                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: 'MANEJO',
                                            font: 'Arial',
                                            size: 20,
                                            bold: true
                                        })
                                    ],
                                    spacing: {
                                        after: 75,
                                        before: 75
                                    }
                                }),
                            ],
                            verticalAlign: VerticalAlign.CENTER,
                            columnSpan: 6,
                            shading: {
                                fill: '#C0C0C0'
                            }
                        })
                    ]
                }),
                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: 'No.',
                                            font: 'Arial',
                                            size: 18,
                                            bold: true
                                        })
                                    ]
                                })
                            ],
                            rowSpan: 2,
                            verticalAlign: VerticalAlign.CENTER,
                            width: {
                                size: 25,
                                type: WidthType.PERCENTAGE
                            }
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: 'Requisitos',
                                            font: 'Arial',
                                            size: 18,
                                            bold: true
                                        })
                                    ]
                                })
                            ],
                            rowSpan: 2,
                            verticalAlign: VerticalAlign.CENTER,
                            width: {
                                size: 15,
                                type: WidthType.PERCENTAGE
                            }
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: 'Comentarios',
                                            font: 'Arial',
                                            size: 18,
                                            bold: true
                                        })
                                    ]
                                })
                            ],
                            rowSpan: 2,
                            verticalAlign: VerticalAlign.CENTER,
                            width: {
                                size: 15,
                                type: WidthType.PERCENTAGE
                            }
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: 'Verificación en sitio (Evaluada por Inspector)',
                                            font: 'Arial',
                                            size: 18,
                                            bold: true
                                        })
                                    ]
                                })
                            ],
                            columnSpan: 3,
                            verticalAlign: VerticalAlign.CENTER,
                            width: {
                                size: 15,
                                type: WidthType.PERCENTAGE
                            }
                        })
                    ]
                }),
                new TableRow({
                    children: [new TableCell({
                        children: [
                            new Paragraph({
                                alignment: AlignmentType.CENTER,
                                children: [
                                    new TextRun({
                                        text: 'Implementación',
                                        font: 'Arial',
                                        size: 18,
                                        bold: true
                                    })
                                ]
                            })
                        ],
                        verticalAlign: VerticalAlign.CENTER
                    }),
                    new TableCell({
                        children: [
                            new Paragraph({
                                alignment: AlignmentType.CENTER,
                                children: [
                                    new TextRun({
                                        text: 'Confirmación',
                                        font: 'Arial',
                                        size: 18,
                                        bold: true
                                    })
                                ]
                            })
                        ],
                        verticalAlign: VerticalAlign.CENTER,
                        columnSpan: 2
                    }),]
                }),
                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: '1',
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                })
                            ],
                            verticalAlign: VerticalAlign.TOP
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: 'El medio de reproducción utilizado es:',
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                }),
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: (this.getValue(submission, "plan_organico_fungi_manejo_1_troncos") ? '■' : '❏') + '  Troncos',
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                }),
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: (this.getValue(submission, "plan_organico_fungi_manejo_1_suelo") ? '■' : '❏') + '  Suelo',
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                }),
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: (this.getValue(submission, "plan_organico_fungi_manejo_1_sustrato") ? '■' : '❏') + '  Sustrato',
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                }),
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: (this.getValue(submission, "plan_organico_fungi_manejo_1_otro") ? '■' : '❏') + ' Otro: ' + this.getValue(submission, "plan_organico_fungi_manejo_1_otro_text"),
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                }),
                            ],
                            verticalAlign: VerticalAlign.TOP
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: this.getValue(submission, "plan_organico_fungi_manejo_1_comentarios"),
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                })
                            ],
                            verticalAlign: VerticalAlign.TOP
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: '❏ I  ❏ NI',
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                })
                            ],
                            verticalAlign: VerticalAlign.TOP
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: '❏ C  ❏ NC ❏ NA',
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                })
                            ],
                            verticalAlign: VerticalAlign.TOP
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: 'Comentario:',
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                })
                            ],
                            verticalAlign: VerticalAlign.TOP
                        })
                    ]
                }),
                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: '2',
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                })
                            ],
                            verticalAlign: VerticalAlign.TOP
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: 'La calidad del medio de reproducción es:',
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                }),
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: (this.getValue(submission, "plan_organico_fungi_manejo_2_organica") ? '■' : '❏') + ' Orgánica',
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                }),
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: (this.getValue(submission, "plan_organico_fungi_manejo_2_natural") ? '■' : '❏') + ' Natural (no tratado)',
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                }),
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: (this.getValue(submission, "plan_organico_fungi_manejo_2_convencional") ? '■' : '❏') + ' Convencional',
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                }),
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: (this.getValue(submission, "plan_organico_fungi_manejo_2_otro") ? '■' : '❏') + ' Otro: ' + this.getValue(submission, "plan_organico_fungi_manejo_2_otro_text"),
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                }),
                            ],
                            verticalAlign: VerticalAlign.TOP
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: this.getValue(submission, "plan_organico_fungi_manejo_2_comentarios"),
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                })
                            ],
                            verticalAlign: VerticalAlign.TOP
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: '❏ I  ❏ NI',
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                })
                            ],
                            verticalAlign: VerticalAlign.TOP
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: '❏ C  ❏ NC ❏ NA',
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                })
                            ],
                            verticalAlign: VerticalAlign.TOP
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: 'Comentario:',
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                })
                            ],
                            verticalAlign: VerticalAlign.TOP
                        })
                    ]
                }),
                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: '3',
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                })
                            ],
                            verticalAlign: VerticalAlign.TOP
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: 'En caso de reproducción el suelo, se tiene evidencia de que este no ha recibido aplicación de sustancias permitidas en al menos 36 meses:',
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                }),
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: (this.getValue(submission, "plan_organico_fungi_manejo_3_si") ? '■' : '❏') + '  Si',
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                }),
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: (this.getValue(submission, "plan_organico_fungi_manejo_3_no") ? '■' : '❏') + '  No',
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                })
                            ],
                            verticalAlign: VerticalAlign.TOP
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: this.getValue(submission, "plan_organico_fungi_manejo_3_comentarios"),
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                })
                            ],
                            verticalAlign: VerticalAlign.TOP
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: '❏ I  ❏ NI',
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                })
                            ],
                            verticalAlign: VerticalAlign.TOP
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: '❏ C  ❏ NC ❏ NA',
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                })
                            ],
                            verticalAlign: VerticalAlign.TOP
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: 'Comentario:',
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                })
                            ],
                            verticalAlign: VerticalAlign.TOP
                        })
                    ]
                }),
                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: '4',
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                })
                            ],
                            verticalAlign: VerticalAlign.TOP
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: 'En caso de uso de troncos, se usan como cubiertas para prevenir la perdida de humedad:',
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                }),
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: (this.getValue(submission, "plan_organico_fungi_manejo_4_cera_abeja") ? '■' : '❏') + '  Cera de abeja',
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                }),
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: (this.getValue(submission, "plan_organico_fungi_manejo_4_parafina") ? '■' : '❏') + '  Parafina grado alimenticio',
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                }),
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: (this.getValue(submission, "plan_organico_fungi_manejo_4_cera_queso") ? '■' : '❏') + '  Cera para queso',
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                }),
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: (this.getValue(submission, "plan_organico_fungi_manejo_4_aceite") ? '■' : '❏') + '  Aceite mineral',
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                }),
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: (this.getValue(submission, "plan_organico_fungi_manejo_4_otro") ? '■' : '❏') + ' Otro: ' + this.getValue(submission, "plan_organico_fungi_manejo_4_otro_text"),
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                }),
                            ],
                            verticalAlign: VerticalAlign.TOP
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: this.getValue(submission, "plan_organico_fungi_manejo_4_comentarios"),
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                })
                            ],
                            verticalAlign: VerticalAlign.TOP
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: '❏ I  ❏ NI',
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                })
                            ],
                            verticalAlign: VerticalAlign.TOP
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: '❏ C  ❏ NC ❏ NA',
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                })
                            ],
                            verticalAlign: VerticalAlign.TOP
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: 'Comentario:',
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                })
                            ],
                            verticalAlign: VerticalAlign.TOP
                        })
                    ]
                }),
                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: '5',
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                })
                            ],
                            verticalAlign: VerticalAlign.TOP
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: 'Si el método de reproducción es en espacios abiertos, la distancia con cultivos convencionales o fuentes de contmianción es:',
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                }),
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: (this.getValue(submission, "plan_organico_fungi_manejo_5_menor_35") ? '■' : '❏') + '  Menor a 35 m',
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                }),
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: (this.getValue(submission, "plan_organico_fungi_manejo_5_35_mayor") ? '■' : '❏') + '  Igual o mayor a 35 m',
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                }),
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: (this.getValue(submission, "plan_organico_fungi_manejo_5_otro") ? '■' : '❏') + ' Otro: ' + this.getValue(submission, "plan_organico_fungi_manejo_5_otro_text"),
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                }),
                            ],
                            verticalAlign: VerticalAlign.TOP
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: this.getValue(submission, "plan_organico_fungi_manejo_5_comentarios"),
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                })
                            ],
                            verticalAlign: VerticalAlign.TOP
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: '❏ I  ❏ NI',
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                })
                            ],
                            verticalAlign: VerticalAlign.TOP
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: '❏ C  ❏ NC ❏ NA',
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                })
                            ],
                            verticalAlign: VerticalAlign.TOP
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: 'Comentario:',
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                })
                            ],
                            verticalAlign: VerticalAlign.TOP
                        })
                    ]
                }),
                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: '6',
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                })
                            ],
                            verticalAlign: VerticalAlign.TOP
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: 'En caso de uso de estiércol como medio de reroducción, cumple los requisitos:',
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                }),
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: (this.getValue(submission, "plan_organico_fungi_manejo_6_composteado") ? '■' : '❏') + '  Composteado',
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                }),
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: (this.getValue(submission, "plan_organico_fungi_manejo_6_ganaderia_extensiva") ? '■' : '❏') + '  Proviene de ganadería extensiva (animales no enjaulados, animales no encerrados, se tienen registros de la cantidad, origen y tipo de ganadería )',
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                })
                            ],
                            verticalAlign: VerticalAlign.TOP
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: this.getValue(submission, "plan_organico_fungi_manejo_6_comentarios"),
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                })
                            ],
                            verticalAlign: VerticalAlign.TOP
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: '❏ I  ❏ NI',
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                })
                            ],
                            verticalAlign: VerticalAlign.TOP
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: '❏ C  ❏ NC ❏ NA',
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                })
                            ],
                            verticalAlign: VerticalAlign.TOP
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: 'Comentario:',
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                })
                            ],
                            verticalAlign: VerticalAlign.TOP
                        })
                    ]
                }),
                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: '7',
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                })
                            ],
                            verticalAlign: VerticalAlign.TOP
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: 'En caso de uso de estiércol como medio de reroducción, cumple los requisitos:',
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                }),
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: (this.getValue(submission, "plan_organico_fungi_manejo_7_composteado") ? '■' : '❏') + '  Composteado',
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                }),
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: (this.getValue(submission, "plan_organico_fungi_manejo_7_ganaderia_extensiva") ? '■' : '❏') + '  Proviene de ganadería extensiva (animales no enjaulados, animales no encerrados, se tienen registros de la cantidad, origen y tipo de ganadería )',
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                })
                            ],
                            verticalAlign: VerticalAlign.TOP
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: this.getValue(submission, "plan_organico_fungi_manejo_7_comentarios"),
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                })
                            ],
                            verticalAlign: VerticalAlign.TOP
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: '❏ I  ❏ NI',
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                })
                            ],
                            verticalAlign: VerticalAlign.TOP
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: '❏ C  ❏ NC ❏ NA',
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                })
                            ],
                            verticalAlign: VerticalAlign.TOP
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: 'Comentario:',
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                })
                            ],
                            verticalAlign: VerticalAlign.TOP
                        })
                    ]
                }),
                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: '8',
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                })
                            ],
                            verticalAlign: VerticalAlign.TOP
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: 'La semilla o mocelio es:',
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                }),
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: (this.getValue(submission, "plan_organico_fungi_manejo_8_organica") ? '■' : '❏') + '  Orgánica',
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                }),
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: (this.getValue(submission, "plan_organico_fungi_manejo_8_natural") ? '■' : '❏') + '  Natural, no tratada',
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                }),
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: (this.getValue(submission, "plan_organico_fungi_manejo_8_otro") ? '■' : '❏') + ' Otro: ' + this.getValue(submission, "plan_organico_fungi_manejo_8_otro_text"),
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                }),
                            ],
                            verticalAlign: VerticalAlign.TOP
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: this.getValue(submission, "plan_organico_fungi_manejo_8_comentarios"),
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                })
                            ],
                            verticalAlign: VerticalAlign.TOP
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: '❏ I  ❏ NI',
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                })
                            ],
                            verticalAlign: VerticalAlign.TOP
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: '❏ C  ❏ NC ❏ NA',
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                })
                            ],
                            verticalAlign: VerticalAlign.TOP
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: 'Comentario:',
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                })
                            ],
                            verticalAlign: VerticalAlign.TOP
                        })
                    ]
                }),
                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: '9',
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                })
                            ],
                            verticalAlign: VerticalAlign.TOP
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: 'El agua utilizada es:',
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                }),
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: (this.getValue(submission, "plan_organico_fungi_manejo_9_potable") ? '■' : '❏') + '  Potable',
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                }),
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: (this.getValue(submission, "plan_organico_fungi_manejo_9_natural") ? '■' : '❏') + '  Natural, no tratada',
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                }),
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: (this.getValue(submission, "plan_organico_fungi_manejo_9_analisis_disponible") ? '■' : '❏') + '  Análisis de calidad disponibles ',
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                }),
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: (this.getValue(submission, "plan_organico_fungi_manejo_9_otro") ? '■' : '❏') + ' Otro: ' + this.getValue(submission, "plan_organico_fungi_manejo_9_otro_text"),
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                }),
                            ],
                            verticalAlign: VerticalAlign.TOP
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: this.getValue(submission, "plan_organico_fungi_manejo_9_comentarios"),
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                })
                            ],
                            verticalAlign: VerticalAlign.TOP
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: '❏ I  ❏ NI',
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                })
                            ],
                            verticalAlign: VerticalAlign.TOP
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: '❏ C  ❏ NC ❏ NA',
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                })
                            ],
                            verticalAlign: VerticalAlign.TOP
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: 'Comentario:',
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                })
                            ],
                            verticalAlign: VerticalAlign.TOP
                        })
                    ]
                }),
                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: '10',
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                })
                            ],
                            verticalAlign: VerticalAlign.TOP
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: 'Para el control de plagas y enfermedades realizó las siguientes prácticas:',
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                }),
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: (this.getValue(submission, "plan_organico_fungi_manejo_10_retiran_enfermas") ? '■' : '❏') + '  Se retiran las piezas enfermas (50m) ',
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                }),
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: (this.getValue(submission, "plan_organico_fungi_manejo_10_trampas") ? '■' : '❏') + '  Trampas y barreras físicas',
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                }),
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: (this.getValue(submission, "plan_organico_fungi_manejo_10_control_biologico") ? '■' : '❏') + '  Control biológico',
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                }),
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: (this.getValue(submission, "plan_organico_fungi_manejo_10_sustancias_permitidas") ? '■' : '❏') + '  Sustancias permitidas',
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                }),
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: (this.getValue(submission, "plan_organico_fungi_manejo_10_otro") ? '■' : '❏') + ' Otro: ' + this.getValue(submission, "plan_organico_fungi_manejo_10_otro_text"),
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                }),
                            ],
                            verticalAlign: VerticalAlign.TOP
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: this.getValue(submission, "plan_organico_fungi_manejo_10_comentarios"),
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                })
                            ],
                            verticalAlign: VerticalAlign.TOP
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: '❏ I  ❏ NI',
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                })
                            ],
                            verticalAlign: VerticalAlign.TOP
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: '❏ C  ❏ NC ❏ NA',
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                })
                            ],
                            verticalAlign: VerticalAlign.TOP
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: 'Comentario:',
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                })
                            ],
                            verticalAlign: VerticalAlign.TOP
                        })
                    ]
                }),
                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: '11',
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                })
                            ],
                            verticalAlign: VerticalAlign.TOP
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: 'Para la cosecha y post-cosecha las siguientes prácticas están bajo mi control y mantienen la calidad orgánica del producto:',
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                }),
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: (this.getValue(submission, "plan_organico_fungi_manejo_11_corte") ? '■' : '❏') + '  Corte',
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                }),
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: (this.getValue(submission, "plan_organico_fungi_manejo_11_seleccion") ? '■' : '❏') + '  Selección',
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                }),
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: (this.getValue(submission, "plan_organico_fungi_manejo_11_lavado") ? '■' : '❏') + '  Lavado',
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                }),
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: (this.getValue(submission, "plan_organico_fungi_manejo_11_refrigeracion") ? '■' : '❏') + '  Refrigeración',
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                }),
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: (this.getValue(submission, "plan_organico_fungi_manejo_11_transporte") ? '■' : '❏') + '  Transporte',
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                }),
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: (this.getValue(submission, "plan_organico_fungi_manejo_11_otro") ? '■' : '❏') + ' Otro: ' + this.getValue(submission, "plan_organico_fungi_manejo_11_otro_text"),
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                }),
                            ],
                            verticalAlign: VerticalAlign.TOP
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: this.getValue(submission, "plan_organico_fungi_manejo_11_comentarios"),
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                })
                            ],
                            verticalAlign: VerticalAlign.TOP
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: '❏ I  ❏ NI',
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                })
                            ],
                            verticalAlign: VerticalAlign.TOP
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: '❏ C  ❏ NC ❏ NA',
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                })
                            ],
                            verticalAlign: VerticalAlign.TOP
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: 'Comentario:',
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                })
                            ],
                            verticalAlign: VerticalAlign.TOP
                        })
                    ]
                }),
                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: '12',
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                })
                            ],
                            verticalAlign: VerticalAlign.TOP
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: 'Los residuos de cosecha se destinan a:',
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                }),
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: (this.getValue(submission, "plan_organico_fungi_manejo_12_reintegran") ? '■' : '❏') + '  Se reintegran a la parcela ',
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                }),
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: (this.getValue(submission, "plan_organico_fungi_manejo_12_desechan") ? '■' : '❏') + '  Se desechan en instalaciones públicas',
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                }),
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: (this.getValue(submission, "plan_organico_fungi_manejo_12_otro") ? '■' : '❏') + ' Otro: ' + this.getValue(submission, "plan_organico_fungi_manejo_12_otro_text"),
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                }),
                            ],
                            verticalAlign: VerticalAlign.TOP
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: this.getValue(submission, "plan_organico_fungi_manejo_12_comentarios"),
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                })
                            ],
                            verticalAlign: VerticalAlign.TOP
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: '❏ I  ❏ NI',
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                })
                            ],
                            verticalAlign: VerticalAlign.TOP
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: '❏ C  ❏ NC ❏ NA',
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                })
                            ],
                            verticalAlign: VerticalAlign.TOP
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: 'Comentario:',
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                })
                            ],
                            verticalAlign: VerticalAlign.TOP
                        })
                    ]
                })
            ],
            margins: {
                left: 70,
                right: 70
            },
            columnWidths: [800, 3534, 1767, 1767, 1770, 1767]
        })
        return table
    }

    public createTableUDP(data, datePipe): Table {
        const table = new Table({
            rows: [
                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.RIGHT,
                                    children: [
                                        new TextRun({
                                            text: 'Código de operación:',
                                            font: 'Calibri (Cuerpo)',
                                            size: 22,
                                            bold: true,

                                        })
                                    ],
                                    spacing: { before: 100, after: 200 }
                                })],
                            verticalAlign: VerticalAlign.CENTER,
                        }),
                        new TableCell({
                            children: [new Paragraph({
                                alignment: AlignmentType.CENTER,
                                children: [
                                    new TextRun({
                                        text: data.customer.Id_Comercial,
                                        font: 'Calibri (Cuerpo)',
                                        size: 22,
                                    })
                                ],
                            })],
                            verticalAlign: VerticalAlign.CENTER
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.RIGHT,
                                    children: [
                                        new TextRun({
                                            text: 'Fecha: ',
                                            font: 'Calibri (Cuerpo)',
                                            size: 22,
                                            bold: true,

                                        })
                                    ],
                                    spacing: { before: 100, after: 200 }
                                })],
                            verticalAlign: VerticalAlign.CENTER,
                            width: {
                                size: 25,
                                type: WidthType.PERCENTAGE,
                            },
                        }),
                        new TableCell({
                            children: [new Paragraph({
                                alignment: AlignmentType.CENTER,
                                children: [
                                    new TextRun({
                                        text: datePipe.transform(data.record.Creation_Date, "d-MMMM-y"),
                                        font: 'Calibri (Cuerpo)',
                                        size: 22,
                                    })
                                ],
                            })],
                            verticalAlign: VerticalAlign.CENTER,
                            width: {
                                size: 25,
                                type: WidthType.PERCENTAGE,
                            },
                        }),
                    ],
                }),
                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.RIGHT,
                                    children: [
                                        new TextRun({
                                            text: 'Operador: ',
                                            font: 'Calibri (Cuerpo)',
                                            size: 22,
                                            bold: true,

                                        })
                                    ],
                                    spacing: { before: 100, after: 200 }
                                })],
                            verticalAlign: VerticalAlign.CENTER,
                        }),
                        new TableCell({
                            children: [new Paragraph({
                                alignment: AlignmentType.CENTER,
                                children: [
                                    new TextRun({
                                        text: data.customer.Company_Name,
                                        font: 'Calibri (Cuerpo)',
                                        size: 22,
                                    })
                                ],
                            })],
                            verticalAlign: VerticalAlign.CENTER,
                            columnSpan: 3,
                        }),
                    ],
                })
            ],
            margins: {
                left: 70,
                right: 70
            },
            width: {
                size: 100,
                type: WidthType.PERCENTAGE,
            },
            columnWidths: [2410, 2410, 2409, 2409]
        })

        let listaUnidades = this.getValueList(data.submission, "lista_unidades")
        listaUnidades.forEach((unidad, i) => {
            table.addChildElement(new TableRow({
                children: [
                    new TableCell({
                        children: [
                            new Paragraph({
                                alignment: AlignmentType.CENTER,
                                children: [
                                    new TextRun({
                                        text: i === 0 ? 'Unidades de producción:' : '',
                                        font: 'Arial',
                                        size: 22,
                                        bold: true
                                    })
                                ],
                                spacing: {
                                    before: 100,
                                    after: 100
                                }
                            })
                        ],
                        verticalAlign: VerticalAlign.CENTER
                    }),
                    new TableCell({
                        children: [
                            new Paragraph({
                                alignment: AlignmentType.LEFT,
                                children: [
                                    new TextRun({
                                        text: 'Nombre: ',
                                        font: 'Calibri (Cuerpo)',
                                        size: 22,
                                        bold: true,
                                    }),
                                    new TextRun({
                                        text: unidad.nombre_unidad_produccion,
                                        font: 'Calibri (Cuerpo)',
                                        size: 22,
                                    }),
                                ],
                                spacing: { before: 100, after: 200 }
                            }),
                            new Paragraph({
                                alignment: AlignmentType.LEFT,
                                children: [
                                    new TextRun({
                                        text: 'Código: ',
                                        font: 'Calibri (Cuerpo)',
                                        size: 22,
                                        bold: true,
                                    }),
                                    new TextRun({
                                        text: unidad.plan_organico_fungi_unidad_produccion_codigo,
                                        font: 'Calibri (Cuerpo)',
                                        size: 22,
                                    }),
                                ],
                                spacing: { before: 100, after: 200 }
                            }),
                            new Paragraph({
                                alignment: AlignmentType.LEFT,
                                children: [
                                    new TextRun({
                                        text: 'Superficie: ',
                                        font: 'Calibri (Cuerpo)',
                                        size: 22,
                                        bold: true,
                                    }),
                                    new TextRun({
                                        text: `${unidad.plan_organico_fungi_superficie_total === "" || unidad.plan_organico_fungi_superficie_total === "0" ? '❏' : '■'}`,
                                        font: 'Calibri (Cuerpo)',
                                        size: 22,
                                        bold: true
                                    }),
                                    new TextRun({
                                        text: ` Total: `,
                                        font: 'Calibri (Cuerpo)',
                                        size: 22,
                                        bold: true
                                    }),
                                    new TextRun({
                                        text: `${parseFloat(unidad.plan_organico_fungi_superficie_total).toFixed(2)} `,
                                        font: 'Calibri (Cuerpo)',
                                        size: 22,
                                    }),
                                    new TextRun({
                                        text: `${parseFloat(unidad.plan_organico_fungi_superficie_organica) === 0 ? '    ❏' : '    ■'}`,
                                        font: 'Calibri (Cuerpo)',
                                        size: 22,
                                        bold: true
                                    }),
                                    new TextRun({
                                        text: ` Orgánica: `,
                                        font: 'Calibri (Cuerpo)',
                                        size: 22,
                                        bold: true
                                    }),
                                    new TextRun({
                                        text: `${parseFloat(unidad.plan_organico_fungi_superficie_organica) === 0 ? '' : parseFloat(unidad.plan_organico_fungi_superficie_organica).toFixed(2)} `,
                                        font: 'Calibri (Cuerpo)',
                                        size: 22,
                                    }),
                                ],
                                spacing: { before: 100, after: 200 }
                            }),
                            new Paragraph({
                                alignment: AlignmentType.LEFT,
                                children: [
                                    new TextRun({
                                        text: '                  ',
                                        font: 'Calibri (Cuerpo)',
                                        size: 22,
                                        bold: true,
                                    }),
                                    new TextRun({
                                        text: `${parseFloat(unidad.plan_organico_fungi_superficie_transicion) === 0 ? ' ❏' : ' ■'}`,
                                        font: 'Calibri (Cuerpo)',
                                        size: 22,
                                        bold: true
                                    }),
                                    new TextRun({
                                        text: ` En transición: `,
                                        font: 'Calibri (Cuerpo)',
                                        size: 22,
                                        bold: true
                                    }),
                                    new TextRun({
                                        text: `${parseFloat(unidad.plan_organico_fungi_superficie_transicion) === 0 ? '' : parseFloat(unidad.plan_organico_fungi_superficie_transicion).toFixed(2)} `,
                                        font: 'Calibri (Cuerpo)',
                                        size: 22,
                                    }),
                                    new TextRun({
                                        text: `${parseFloat(unidad.plan_organico_fungi_superficie_convencional) === 0 ? '    ❏' : '    ■'}`,
                                        font: 'Calibri (Cuerpo)',
                                        size: 22,
                                        bold: true
                                    }),
                                    new TextRun({
                                        text: ` Convencional: `,
                                        font: 'Calibri (Cuerpo)',
                                        size: 22,
                                        bold: true
                                    }),
                                    new TextRun({
                                        text: `${parseFloat(unidad.plan_organico_fungi_superficie_convencional) === 0 ? '' : parseFloat(unidad.plan_organico_fungi_superficie_convencional).toFixed(2)} `,
                                        font: 'Calibri (Cuerpo)',
                                        size: 22,
                                    }),
                                ],
                                spacing: { before: 100, after: 200 }
                            })
                        ],
                        verticalAlign: VerticalAlign.CENTER,
                    }),
                ],
            }))

        })

        return table
    }
    getValue(submission, name) {
        var value = null
        for (const i in submission) {
            const element = submission[i]
            if (element.name === name) {
                value = element.value
            }
        }
        if (value) return value
        else return ''
    }
    getValueList(submission, name) {
        var value = null
        for (const i in submission) {
            const element = submission[i]
            if (element.name === name) {
                value = element.value
            }
        }
        if (value) return value
        else return []
    }

}
