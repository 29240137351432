<div class="modal-header">
    <h4 class="modal-title" id="modal-title">Su reporte se esta generando</h4>
        <button
            type="button"
            class="close"
            aria-label="Close button"
            aria-describedby="modal-title"
            (click)="modal.dismiss('Cross click')"
        >
            <span aria-hidden="true">&times;</span>
        </button>
</div>
  
<div class="modal-body">
    <div class="row">
        <div class="col-12">
            <p>Al terminar, recibira un correo electronico a <strong>{{email}}</strong> con un enlace para descargar su reporte.</p>
        </div>

        <div class="col-12 text-center">
            <button 
                type="button" 
                class="btn btn-success"
                (click)="modal.dismiss('cancel click')"
                style="margin: auto;"
            >
                listo
            </button>
        </div>
    </div>
</div>
  