import { AlignmentType, Document, Paragraph, TextRun, Media, Header, Footer, Table, TableRow, TableCell, WidthType, PageNumber, BorderStyle } from "docx";
import { logo } from "../logo";
import { sinCroquis } from "../sinCroquis";
import { DatePipe } from '@angular/common';

export class DocumentCreatorMTO_FSOL_05_3_v2 {
    // INIT MTO_FSOL_05_3
    public async create_MTO_FSOL_05_3(data): Promise<Document> {
        const document = new Document();
        const image = Media.addImage(document, logo, 50, 50);
        var datePipe = new DatePipe('es-MX');

        for(var i in data.submission[0].value) {
            document.addSection({
                headers: {
                    default: new Header({
                        children: [
                            new Paragraph({ alignment: AlignmentType.CENTER, children: [image], }),
                            new Paragraph({
                                alignment: AlignmentType.CENTER, children: [
                                    new TextRun({
                                        children: [`MTO-FSOL-05-3| R 00 | 16-Diciembre-2020 | Pág. `, PageNumber.CURRENT],
                                        font: 'Book Antiqua',
                                        size: 20,
                                        bold: true
                                    }),
                                    new TextRun({
                                        font: 'Book Antiqua',
                                        size: 20,
                                        bold: true,
                                        children: [' de ', PageNumber.TOTAL_PAGES]
                                    }),
                                ], spacing: { before: 200, after: 100 }
                            })
                        ]
                    })
                },
                footers: {
                    default: new Footer({
                        children: [
                            new Paragraph({ alignment: AlignmentType.CENTER }),
                            this.createFooter()
                        ]
                    }),
                },
                children: [
                    new Paragraph({
                        alignment: AlignmentType.CENTER,
                        children: [
                            new TextRun({
                                text: 'CROQUIS DE UNIDADES DE PROCESAMIENTO',
                                font: 'Calibri (Cuerpo)',
                                size: 22,
                                bold: true
                            })
                        ],
                        spacing: { after: 200 }
                    }),
                    new Paragraph({
                        alignment: AlignmentType.CENTER, children: [
                            new TextRun({
                                text: `Fecha: ${datePipe.transform(new Date(data.record.Applications[0].Creation_Date.includes('UTC') ? data.record.Applications[0].Creation_Date.replace('UTC', '').trim() : data.record.Applications[0].Creation_Date), 'd-MMMM-y')}          Código de operador: ${data.customer.Id_Comercial}`,
                                font: 'Arial',
                                size: 22
                            })
                        ],
                        spacing: { after: 100 }
                    }),
                    await this.createTableUDP(data, document, data.submission[0].value[i])
                ],
                margins: {
                    top: 350,
                    bottom: 500,
                    left: 1000,
                    right: 1000
                }
            });
        }

        return document;
    }

    public createFooter(): Table {
        const table = new Table({
            rows: [
                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: 'Elaboró:',
                                            font: 'Calibri (Cuerpo)',
                                            size: 22
                                        })
                                    ]
                                })
                            ],
                            width: {
                                size: 33,
                                type: WidthType.PERCENTAGE
                            }
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: 'Revisó:',
                                            font: 'Calibri (Cuerpo)',
                                            size: 22
                                        })
                                    ]
                                })
                            ],
                            width: {
                                size: 33,
                                type: WidthType.PERCENTAGE
                            }
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: 'Autorizó:',
                                            font: 'Calibri (Cuerpo)',
                                            size: 22
                                        })
                                    ]
                                }),
                            ],
                            width: {
                                size: 33,
                                type: WidthType.PERCENTAGE
                            }
                        })
                    ]
                }),
                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: 'Director de certificación',
                                            font: 'Calibri (Cuerpo)',
                                            size: 16,
                                            bold: true
                                        })
                                    ]
                                }),

                            ],
                            width: {
                                size: 33,
                                type: WidthType.PERCENTAGE
                            },
                            shading: {
                                fill: '#C0C0C0'
                            }
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: 'Responsable de calidad',
                                            font: 'Calibri (Cuerpo)',
                                            size: 16,
                                            bold: true
                                        })
                                    ]
                                }),

                            ],
                            width: {
                                size: 33,
                                type: WidthType.PERCENTAGE
                            },
                            shading: {
                                fill: '#C0C0C0'
                            }
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: 'Director general',
                                            font: 'Calibri (Cuerpo)',
                                            size: 16,
                                            bold: true
                                        })
                                    ]
                                }),
                            ],
                            width: {
                                size: 33,
                                type: WidthType.PERCENTAGE
                            },
                            shading: {
                                fill: '#C0C0C0'
                            }
                        })
                    ]
                })
            ],
            margins: {
                left: 70,
                right: 70
            },
            columnWidths: [3213, 3213, 3212]
        })
        return table
    }

    public async createTableUDP(data, document, unidad) {
        const table = new Table({
            rows: [
            ],
            margins: {
                left: 70,
                right: 70
            },
            width: {
                size: 100,
                type: WidthType.PERCENTAGE,
            },
            columnWidths: [3312, 3312, 3312, 3312]
        })

        let croquis
        if(unidad.unidad_croquis_url !== "") {
            croquis = await this.getBase64FromUrl(unidad.unidad_croquis_url)
        }
        else {
            croquis = sinCroquis
        }

        table.addChildElement(new TableRow({
            children: [
                new TableCell({
                    children: [
                        new Paragraph({
                            alignment: AlignmentType.LEFT,
                            children: [
                                new TextRun({
                                    text: `Nombre del Operador: ${data.customer.Company_Name}`,
                                    font: 'Arial',
                                    size: 22
                                })
                            ],
                            spacing: { before: 100, after: 200 }
                        })
                    ],
                    columnSpan: 2
                }),
                new TableCell({
                    children: [
                        new Paragraph({
                            alignment: AlignmentType.LEFT,
                            children: [
                                new TextRun({
                                    text: `Nombre de la unidad de producción: ${unidad.nombre_unidad_produccion}`,
                                    font: 'Arial',
                                    size: 22
                                })
                            ],
                            spacing: { before: 100, after: 200 }
                        })
                    ],
                    columnSpan: 2
                })
            ]
        })),
        table.addChildElement(new TableRow({
            children: [
                new TableCell({
                    children: [
                        new Paragraph({
                            spacing: { before: 50, after: 50 }
                        })
                    ],
                    borders: {
                        left: {
                            style: BorderStyle.NONE,
                            color: "white",
                            size: 0
                        },
                        right: {
                            style: BorderStyle.NONE,
                            color: "white",
                            size: 0
                        },
                    }
                }),
            ]
        })),
        table.addChildElement(new TableRow({
            children: [
                new TableCell({
                    children: [
                        new Paragraph({
                            alignment: AlignmentType.LEFT,
                            children: [
                                new TextRun({
                                    text: `Calle, No. exterior e interior: ${unidad.domicilio_calle}`,
                                    font: 'Arial',
                                    size: 22
                                })
                            ],
                            spacing: { before: 100, after: 200 }
                        })
                    ],
                    width: {
                        size: 33,
                        type: WidthType.PERCENTAGE,
                    },
                }),
                new TableCell({
                    children: [
                        new Paragraph({
                            alignment: AlignmentType.LEFT,
                            children: [
                                new TextRun({
                                    text: `Colonia: ${unidad.domicilio_colonia}`,
                                    font: 'Arial',
                                    size: 22
                                })
                            ],
                            spacing: { before: 100, after: 200 }
                        })
                    ],
                    width: {
                        size: 33,
                        type: WidthType.PERCENTAGE,
                    },
                }),
                new TableCell({
                    children: [
                        new Paragraph({
                            alignment: AlignmentType.LEFT,
                            children: [
                                new TextRun({
                                    text: `Delegación/Municipio: ${unidad.domicilio_municipio}`,
                                    font: 'Arial',
                                    size: 22
                                })
                            ],
                            spacing: { before: 100, after: 200 }
                        })
                    ],
                    width: {
                        size: 33,
                        type: WidthType.PERCENTAGE,
                    },
                })
            ]
        })),
        table.addChildElement(new TableRow({
            children: [
                new TableCell({
                    children: [
                        new Paragraph({
                            alignment: AlignmentType.LEFT,
                            children: [
                                new TextRun({
                                    text: `Localidad: ${unidad.domicilio_localidad}`,
                                    font: 'Arial',
                                    size: 22
                                })
                            ],
                            spacing: { before: 100, after: 200 }
                        })
                    ],
                    width: {
                        size: 33,
                        type: WidthType.PERCENTAGE,
                    },
                }),
                new TableCell({
                    children: [
                        new Paragraph({
                            alignment: AlignmentType.LEFT,
                            children: [
                                new TextRun({
                                    text: `C.P.: ${unidad.domicilio_cp}`,
                                    font: 'Arial',
                                    size: 22
                                })
                            ],
                            spacing: { before: 100, after: 200 }
                        })
                    ],
                    width: {
                        size: 33,
                        type: WidthType.PERCENTAGE,
                    },
                }),
                new TableCell({
                    children: [
                        new Paragraph({
                            alignment: AlignmentType.LEFT,
                            children: [
                                new TextRun({
                                    text: `Entidad Federativa: ${unidad.domicilio_estado}`,
                                    font: 'Arial',
                                    size: 22
                                })
                            ],
                            spacing: { before: 100, after: 200 }
                        })
                    ],
                    width: {
                        size: 33,
                        type: WidthType.PERCENTAGE,
                    },
                })
            ]
        })),
        table.addChildElement(new TableRow({
            children: [
                new TableCell({
                    children: [
                        new Paragraph({
                            alignment: AlignmentType.LEFT,
                            children: [
                                new TextRun({
                                    text: `Favor de incluir un croquis que describa la unidad de producción e indicar el uso de los terrenos colindantes.`,
                                    font: 'Arial',
                                    size: 22
                                })
                            ],
                            spacing: { before: 100, after: 100 }
                        })
                    ],
                    borders: {
                        left: {
                            style: BorderStyle.NONE,
                            color: "white",
                            size: 0
                        },
                        right: {
                            style: BorderStyle.NONE,
                            color: "white",
                            size: 0
                        },
                        bottom: {
                            style: BorderStyle.NONE,
                            color: "white",
                            size: 0
                        },
                        top: {
                            style: BorderStyle.NONE,
                            color: "white",
                            size: 0
                        }
                    }
                }),
            ]
        })),
        table.addChildElement(new TableRow({
            children: [
                new TableCell({
                    children: [
                        new Paragraph({
                            spacing: { before: 100 }
                        }),
                        new Paragraph({
                            alignment: AlignmentType.CENTER,
                            children: [
                                Media.addImage(document, croquis.toString(), 340, 425)
                            ]
                        }),
                        new Paragraph({
                            spacing: { after: 100 }
                        }),
                    ],
                    borders: {
                        top: {
                            style: BorderStyle.NONE,
                            size: 0,
                            color: "white",
                        },
                        bottom: {
                            style: BorderStyle.NONE,
                            size: 0,
                            color: "white",
                        },
                        left: {
                            style: BorderStyle.NONE,
                            size: 0,
                            color: "white",
                        },
                        right: {
                            style: BorderStyle.NONE,
                            size: 0,
                            color: "white",
                        },
                    }
                })
            ]
        }))
        
        return table;
    }

    getValue(submission, name) {
        var value = null
        for (const i in submission) {
            const element = submission[i]
            if (element.name === name) {
                value = element.value
            }
        }
        if (value) return value
        else return ''
    }

    getValueList(submission, name) {
        var value = null
        for (const i in submission) {
            const element = submission[i]
            if (element.name === name) {
                value = element.value
            }
        }
        if (value) return value
        else return []
    }

    getBase64FromUrl = async (url) => {
        var data
        try {
            data = await fetch(url, {
                headers: {
                    'Cache-Control': 'no-cache, no-store, must-revalidate',
                    'Pragma': 'no-cache',
                    'Expires': 'Sat, 01 Jan 2000 00:00:00 GMT',
                    'If-Modified-Since': '0'
                }
            })
        } catch (error) {
            return sinCroquis
        }
        const blob = await data.blob();
        return new Promise((resolve) => {
            const reader = new FileReader();
            reader.readAsDataURL(blob); 
            reader.onloadend = function() {
                const base64data = reader.result;   
                resolve(base64data);
            }
        });
    }
    // END MTO_FSOL_05_3
}