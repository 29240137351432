<div class="container">
  <div class="row align-items-center text-center" style="height: 100vh;">
    <form
      class="form-signin fade-in"
      [formGroup]="loginForm"
      (ngSubmit)="onSubmit()"
    >
      <img
        class="mb-4 fade-in"
        src="/assets/icons/mto-logo.png"
        alt=""
        width="72"
        height="72"
      />
      <h1 class="h3 mb-3 font-weight-normal">Iniciar Sesión</h1>

      <div class="form-group text-left pt-2">
        <label for="email">Correo Electrónico</label>
        <input
          type="text"
          formControlName="email"
          class="form-control"
          [ngClass]="{ 'is-invalid': submitted && f.email.errors }"
        />
        <div *ngIf="submitted && f.email.errors" class="invalid-feedback">
          <div *ngIf="f.email.errors.required">
            Correo electrónico es requerido
          </div>
        </div>
      </div>
      <div class="form-group text-left pb-2 password" >
        <label for="password">Contraseña</label>
        <span class="icon-eye" (click)="showPassword()">
          <i class="{{isShowed ? 'fas fa-eye-slash' : 'fas fa-eye'}}"></i>
        </span>
        <input
          type = "{{isShowed ? 'text' : 'password'}}"
          formControlName="password"
          class="form-control"
          [ngClass]="{ 'is-invalid': submitted && f.password.errors }"
        />
        <div *ngIf="submitted && f.password.errors" class="invalid-feedback">
          <div *ngIf="f.password.errors.required">Contraseña es requerida</div>
        </div>
      </div>

      <div class="form-group fade-in">
        <a routerLink="../recuperarCuenta" class="btn btn-link btn-sm"
          >¿Olvidaste tu contraseña?</a
        >
        <button [disabled]="loading" class="btn btn-primary">
          <span
            *ngIf="loading"
            class="spinner-border spinner-border-sm mr-1"
          ></span>
          Acceder
        </button>
        <!--  -->
      </div>
    </form>
  </div>
</div>
<div style="background-color: #FFF; box-shadow: none;" class="native-fixed fade-in">
  <span>
    <small style="color: #00000080;">
      v{{versionSystem}}
    </small>
  </span>
</div>