import { environment } from '@environments/environment';
import { io, Socket } from 'socket.io-client'

export class SocketsService {
    
    socket: any;

    constructor() { }

    setupSocketConnection() {
        this.socket = io(environment.apiUrl, {
            transports: ['websocket'] 
        })
        setTimeout(() => {
            this.socket.emit('message', 'Hello!')
            this.socket.disconnect()
        }, 4000);
    }

}