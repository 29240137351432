import { map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '@environments/environment';
import { Observable, of } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class SubstancesService {

    constructor(private http: HttpClient) { }


    getSubstancesPending(page, search) {
        const jwtToken = (document.cookie.split(';').find(x => x.includes('jwtToken')) || '=').split('=')[1]

        const headers = {
            'Content-Type': 'application/json',
            'Authorization': jwtToken
        };

        const body = {
            page: page,
            search: search
        }

        return this.http.post<any>(`${environment.apiUrl}/substances/pending`, body, { headers });
    }


    getReviewerSubstances() {
        const jwtToken = (document.cookie.split(';').find(x => x.includes('jwtToken')) || '=').split('=')[1]

        const headers = {
            'Content-Type': 'application/json',
            'Authorization': jwtToken
        };

        return this.http.get<any>(`${environment.apiUrl}/substances/substanceReviewers`, { headers });
    }

    changeReviewerSubstances(idReviewerSubstance, idReviewSubstance) {
        const jwtToken = (document.cookie.split(';').find(x => x.includes('jwtToken')) || '=').split('=')[1]

        const headers = {
            'Content-Type': 'application/json',
            'Authorization': jwtToken
        };

        const payload = {}

        return this.http.patch<any>(`${environment.apiUrl}/substances/substanceReviewers/${idReviewerSubstance}/assign/${idReviewSubstance}`, payload, { headers });
    }

    assign(idReviewSubstance) {
        const jwtToken = (document.cookie.split(';').find(x => x.includes('jwtToken')) || '=').split('=')[1]

        const headers = {
            'Content-Type': 'application/json',
            'Authorization': jwtToken
        };

        const payload = {}

        return this.http.patch<any>(`${environment.apiUrl}/substances/assign/${idReviewSubstance}`, payload, { headers });
    }

    getSubstancesNonEvaluated(page, search) {
        const jwtToken = (document.cookie.split(';').find(x => x.includes('jwtToken')) || '=').split('=')[1]

        const headers = {
            'Content-Type': 'application/json',
            'Authorization': jwtToken
        };

        const body = {
            page: page,
            search: search
        }

        return this.http.post<any>(`${environment.apiUrl}/substances/nonEvaluatedRecords`, body, { headers });
    }

    getDetailsSubstancesNonEvaluated(recordId, versionForm) {
        const jwtToken = (document.cookie.split(';').find(x => x.includes('jwtToken')) || '=').split('=')[1]

        const headers = {
            'Content-Type': 'application/json',
            'Authorization': jwtToken
        };

        return this.http.get<any>(`${environment.apiUrl}/substances/nonEvaluated/${recordId}/${versionForm}`, { headers });
    }

    searchSubstances(query: string) {
        if (query === '') {
            return of([]);
        }

        const jwtToken = (document.cookie.split(';').find(x => x.includes('jwtToken')) || '=').split('=')[1]

        const headers = {
            'Content-Type': 'application/json',
            'Authorization': jwtToken
        };

        return this.http.get<any>(`${environment.apiUrl}/substances/${query}`, { headers }).pipe(
            map(response => {
                //console.log(response['data'])
                return response['data']
            })
        );
    }

    searchSubstancesOperator(query: string) {
        if (query === '') {
            return of([]);
        }

        const jwtToken = (document.cookie.split(';').find(x => x.includes('jwtToken')) || '=').split('=')[1]

        const headers = {
            'Content-Type': 'application/json',
            'Authorization': jwtToken
        };

        return this.http.get<any>(`${environment.apiUrl}/substances/recordsSubstances/${query}`, { headers }).pipe(
            map(response => {
                //console.log(response['data'])
                return response['data']
            })
        );
    }

    saveFormSubstances(recordId, data, version) {
        for(var i in data["lista_sustancias"]) {
            data["lista_sustancias"][i].isSaved = "Si"
        }

        let body = {
            submission: []
        }

        for (var i in Object.keys(data)) {
            const key = Object.keys(data)[i]
            body.submission.push({
                name: key,
                value: data[key]
            })
        }

        const jwtToken = (document.cookie.split(';').find(x => x.includes('jwtToken')) || '=').split('=')[1]

        const headers = {
            'Content-Type': 'application/json',
            'Authorization': jwtToken
        };
        
        return this.http.post<any>(`${environment.apiUrl}/substances/${recordId}/${version}`, body, { headers });
    }

    saveOnlyFormSubstances(recordId, data, version) {
        let body = {
            submission: []
        }

        for (var i in Object.keys(data)) {
            const key = Object.keys(data)[i]
            body.submission.push({
                name: key,
                value: data[key]
            })
        }

        const jwtToken = (document.cookie.split(';').find(x => x.includes('jwtToken')) || '=').split('=')[1]

        const headers = {
            'Content-Type': 'application/json',
            'Authorization': jwtToken
        };
        
        return this.http.post<any>(`${environment.apiUrl}/substances/save/${recordId}/${version}`, body, { headers });
    }

    saveOnlyOneSubstances(recordId, data, index, version) {
        data["lista_sustancias"][index].isSaved = "Si"

        let body = {
            submission: [{
                name: "lista-sustancias",
                value: [data["lista_sustancias"][index]]
            }],
            position: index
        }

        const jwtToken = (document.cookie.split(';').find(x => x.includes('jwtToken')) || '=').split('=')[1]

        const headers = {
            'Content-Type': 'application/json',
            'Authorization': jwtToken
        };
        
        return this.http.patch<any>(`${environment.apiUrl}/substances/update/${recordId}/${version}`, body, { headers });
    }

    evaluationSubstances(data) {
        const jwtToken = (document.cookie.split(';').find(x => x.includes('jwtToken')) || '=').split('=')[1]

        const headers = {
            'Content-Type': 'application/json',
            'Authorization': jwtToken
        };

        return this.http.post<any>(`${environment.apiUrl}/substances/`, data, { headers });
    }

    validate(recordId) {
        const jwtToken = (document.cookie.split(';').find(x => x.includes('jwtToken')) || '=').split('=')[1]

        const headers = {
            'Content-Type': 'application/json',
            'Authorization': jwtToken
        };

        const payload = {}

        return this.http.post<any>(`${environment.apiUrl}/substances/validate/${recordId}`, payload, { headers });
    }
}