import { AlignmentType, Document, Paragraph, TextRun, Media, Header, Footer, Table, TableRow, TableCell, VerticalAlign, WidthType, PageNumber, BorderStyle } from "docx";
import { logo } from "../logo";
import { userWithoutSign } from "../userWithoutSign"
import { DatePipe } from '@angular/common';

export class DocumentCreatorMTO_FSOL_08_4_v3 {

    public getBase64FromUrl = async (url) => {
        const data = await fetch(url, {
            headers: {
                'Cache-Control': 'no-cache, no-store, must-revalidate',
                'Pragma': 'no-cache',
                'Expires': 'Sat, 01 Jan 2000 00:00:00 GMT',
                'If-Modified-Since': '0'
            }
        });
        const blob = await data.blob();
        return new Promise((resolve) => {
            const reader = new FileReader();
            reader.readAsDataURL(blob); 
            reader.onloadend = function() {
                const base64data = reader.result;   
                resolve(base64data);
            }
        });
    }

    // INIT MTO_FSOL_08_4
    public async create_MTO_FSOL_08_4(data, isReview = true, signature = '', signature2 = ''): Promise<Document> {
        const document = new Document();
        const image = Media.addImage(document, logo, 50, 50);
        var datePipe = new DatePipe('es-MX');

        let signatureImage = null
        if (signature) {
            signatureImage = await this.getBase64FromUrl(signature)
        } else {
            signatureImage = userWithoutSign
        }

        let signatureImage2 = null
        if (signature2) {
          signatureImage2 = await this.getBase64FromUrl(signature2)
        } else {
          signatureImage2 = isReview ? '' : userWithoutSign
        }
        
        var unidadesProduccion = ''
        const unidades = this.getValueList(data.submission, "lista_unidades")
        unidades.forEach(unidad => {
            unidadesProduccion += unidad.nombre_unidad_produccion + ', '
        });
        unidadesProduccion = unidadesProduccion.slice(0, -2)

        document.addSection({
            headers: {
                default: new Header({
                    children: [
                        new Paragraph({ alignment: AlignmentType.CENTER, children: [image], }),
                        new Paragraph({
                            alignment: AlignmentType.CENTER, children: [
                                new TextRun({
                                    children: [`MTO-FSOL-08-4| R 04 | 22-Abril-2021 | Pág. `, PageNumber.CURRENT],
                                    font: 'Book Antiqua',
                                    size: 20,
                                    bold: true
                                }),
                                new TextRun({
                                    font: 'Book Antiqua',
                                    size: 20,
                                    bold: true,
                                    children: [' de ', PageNumber.TOTAL_PAGES]
                                }),
                            ], spacing: { before: 200, after: 100 }
                        })
                    ]
                })
            },
            footers: {
                default: new Footer({
                    children: [
                        new Paragraph({ alignment: AlignmentType.CENTER }),
                        this.createFooter()
                    ]
                }),
            },
            children: [
                new Paragraph({
                    alignment: AlignmentType.CENTER,
                    children: [
                        new TextRun({
                            text: 'PLAN ORGÁNICO GRUPO DE PRODUCTORES',
                            font: 'Calibri (Cuerpo)',
                            size: 22,
                            bold: true
                        })
                    ],
                    spacing: { after: 200 }
                }),
                this.createTableUDP(data, datePipe),
                new Paragraph({
                    spacing: {
                        after: 400
                    }
                }),
                this.createGeneralRegisters_MTO_FSOL_08_4(data.submission),
                new Paragraph({
                    spacing: {
                        after: 400
                    }
                }),
                this.createRegistersPerMember_MTO_FSOL_08_4(data.submission),            
                new Paragraph({
                    alignment: AlignmentType.JUSTIFIED,
                    children: [
                        new TextRun({
                            text: `El que suscribe: ${data.customer.Legal_Authorities[0].Name} ${data.customer.Legal_Authorities[0].Middle_Name} ${data.customer.Legal_Authorities[0].Last_Name}, Titular / Representante Legal de la(s) Unidad(es) de Producción / Empresa: ${data.customer.Company_Type === "PERSONA_FISICA" ? unidadesProduccion : data.customer.Company_Name}, declaro que la información descrita en este Plan Orgánico y documentos adjuntos es verídica, y me comprometo a mantenerlo actualizado, al igual que las actividades descritas en él; con la finalidad de cumplir con la Ley de Productos Orgánicos de México (LPO) y el Régimen Orgánico de Canadá (COR/USCOEA).`,
                            font: 'Arial',
                            size: 18
                        })
                    ],
                    spacing: {
                        before: 400,
                        after: 400
                    },
                }),
                new Paragraph({
                    alignment: AlignmentType.JUSTIFIED,
                    children: [
                        new TextRun({
                            text: 'Atentamente:',
                            font: 'Arial',
                            size: 18, bold: true
                        })
                    ],
                    spacing: {
                        before: 400,
                        after: 400
                    },
                }),
                new Paragraph({
                    alignment: AlignmentType.CENTER,
                    children: [
                        signatureImage ? Media.addImage(document, signatureImage.toString(), 200, 100) : new TextRun({
                            text: ''
                        })
                    ]
                }),
                new Paragraph({
                    alignment: AlignmentType.LEFT,
                    children: [
                        new TextRun({
                            text: `Fecha: ${datePipe.transform(new Date(data.record.Applications[0].Creation_Date.includes('UTC') ? data.record.Applications[0].Creation_Date.replace('UTC', '').trim() : data.record.Applications[0].Creation_Date), 'd-MMMM-y')}                  Nombre y firma del operador: ${data.customer.Legal_Authorities[0].Name} ${data.customer.Legal_Authorities[0].Middle_Name} ${data.customer.Legal_Authorities[0].Last_Name ? data.customer.Legal_Authorities[0].Last_Name : ''}`,
                            font: 'Arial',
                            size: 20,
                            italics: true
                        })
                    ],
                    spacing: {
                        after: 200
                    }
                }),
                new Table({
                    rows: [
                        new TableRow({
                            children: [
                                new TableCell({
                                    children: [
                                        new Paragraph({
                                            alignment: AlignmentType.CENTER,
                                            children: [
                                                new TextRun({
                                                    text: 'EVALUACIÓN POR EL REVISOR',
                                                    font: 'Arial',
                                                    size: 20,
                                                    bold: true
                                                })
                                            ],
                                            spacing: {
                                                after: 75,
                                                before: 75
                                            }
                                        }),
                                    ],
                                    verticalAlign: VerticalAlign.CENTER,
                                    columnSpan: 2,
                                    shading: {
                                        fill: '#C0C0C0'
                                    }
                                })
                            ]
                        }),
                        new TableRow({
                            children: [
                                new TableCell({
                                    children: [
                                        new Paragraph({
                                            alignment: AlignmentType.RIGHT,
                                            children: [
                                                new TextRun({
                                                    text: 'Nombre del Revisor: ',
                                                    font: 'Calibri (Cuerpo)',
                                                    size: 22,
                                                })
                                            ],
                                            spacing: { before: 100, after: 200 }
                                        })],
                                    verticalAlign: VerticalAlign.CENTER,
                                }),
                                new TableCell({
                                    children: [
                                        new Paragraph({
                                            alignment: AlignmentType.LEFT,
                                            children: [
                                                new TextRun({
                                                    text: data.reviewer ? data.reviewer.name : '',
                                                    font: 'Calibri (Cuerpo)',
                                                    size: 22,
                                                })
                                            ],
                                            spacing: { before: 100, after: 200 }
                                        })],
                                    verticalAlign: VerticalAlign.CENTER,
                                }),
                            ],
                        }),
                        new TableRow({
                            children: [
                                new TableCell({
                                    children: [
                                        new Paragraph({
                                            alignment: AlignmentType.RIGHT,
                                            children: [
                                                new TextRun({
                                                    text: 'Fecha: ',
                                                    font: 'Calibri (Cuerpo)',
                                                    size: 22,
                                                })
                                            ],
                                            spacing: { before: 100, after: 200 }
                                        })],
                                    verticalAlign: VerticalAlign.CENTER,
                                }),
                                new TableCell({
                                    children: [
                                        new Paragraph({
                                            alignment: AlignmentType.LEFT,
                                            children: [
                                                new TextRun({
                                                    text: `${data.reviewer ? datePipe.transform(new Date(data.reviewer.date), 'd-MMMM-y') : ''}`,
                                                    font: 'Calibri (Cuerpo)',
                                                    size: 22,
                                                })
                                            ],
                                            spacing: { before: 100, after: 200 }
                                        })],
                                    verticalAlign: VerticalAlign.CENTER,
                                }),
                            ],
                        }),
                        new TableRow({
                            children: [
                                new TableCell({
                                    children: [
                                        new Paragraph({
                                            alignment: AlignmentType.RIGHT,
                                            children: [
                                                new TextRun({
                                                    text: 'Conformidad: ',
                                                    font: 'Calibri (Cuerpo)',
                                                    size: 22,
                                                })
                                            ],
                                            spacing: { before: 100, after: 200 }
                                        })],
                                    verticalAlign: VerticalAlign.CENTER,
                                }),
                                new TableCell({
                                    children: [
                                        new Paragraph({
                                            alignment: AlignmentType.LEFT,
                                            children: [
                                                new TextRun({
                                                    text: `${this.getValue(data.revision, 'plan_organico_conformidad_no_conformidades_graves') === true ? '■' : '❏'} No conformidades graves, no procederá la certificación.`,
                                                    font: 'Arial',
                                                    size: 18
                                                })
                                            ]
                                        }),
                                        new Paragraph({
                                            alignment: AlignmentType.LEFT,
                                            children: [
                                                new TextRun({
                                                    text: `${this.getValue(data.revision, 'plan_organico_conformidad_no_conformidades_menores_con_hallazgos') === true ? '■' : '❏'} No conformidades menores, se requiere de corrección antes de inspección. Listar los hallazgos abajo.`,
                                                    font: 'Arial',
                                                    size: 18
                                                })
                                            ]
                                        }),
                                        new Paragraph({
                                            alignment: AlignmentType.LEFT,
                                            children: [
                                                new TextRun({
                                                    text: `${this.getValue(data.revision, 'plan_organico_conformidad_no_conformidades_menores') === true ? '■' : '❏'} No conformidades menores, se puede realizar inspección.`,
                                                    font: 'Arial',
                                                    size: 18
                                                })
                                            ]
                                        }),
                                        new Paragraph({
                                            alignment: AlignmentType.LEFT,
                                            children: [
                                                new TextRun({
                                                    text: `${this.getValue(data.revision, 'plan_organico_conformidad_conformidad') === true ? '■' : '❏'} Conformidad`,
                                                    font: 'Arial',
                                                    size: 18
                                                })
                                            ]
                                        }),
                                        new Paragraph({
                                            alignment: AlignmentType.LEFT,
                                            children: [
                                                new TextRun({
                                                    text: `${this.getValue(data.revision, 'plan_organico_conformidad_otro') === true ? `■ Otro: ${this.getValue(data.revision, 'plan_organico_conformidad_otro_text')}` : '❏ Otro:'}`,
                                                    font: 'Arial',
                                                    size: 18
                                                })
                                            ]
                                        })
                                    ],
                                    verticalAlign: VerticalAlign.CENTER,
                                    columnSpan: 1,
                                }),
                            ],
                        }),
                        new TableRow({
                            children: [
                                new TableCell({
                                    children: [
                                        new Paragraph({
                                            alignment: AlignmentType.RIGHT,
                                            children: [
                                                new TextRun({
                                                    text: 'Hallazgos: ',
                                                    font: 'Calibri (Cuerpo)',
                                                    size: 22,
                                                })
                                            ],
                                            spacing: { before: 100, after: 200 }
                                        })],
                                    verticalAlign: VerticalAlign.CENTER,
                                }),
                                new TableCell({
                                    children: [
                                        new Paragraph({
                                            alignment: AlignmentType.LEFT,
                                            children: [
                                                new TextRun({
                                                    text: this.getValue(data.revision, "plan_organico_hallazgos"),
                                                    font: 'Calibri (Cuerpo)',
                                                    size: 22,
                                                })
                                            ],
                                            spacing: { before: 100, after: 200 }
                                        })],
                                    verticalAlign: VerticalAlign.CENTER,
                                }),
                            ],
                        }),
                        new TableRow({
                            children: [
                                new TableCell({
                                    children: [
                                        new Paragraph({
                                            alignment: AlignmentType.RIGHT,
                                            children: [
                                                new TextRun({
                                                    text: 'Recomendaciones para inspector: ',
                                                    font: 'Calibri (Cuerpo)',
                                                    size: 22,
                                                })
                                            ],
                                            spacing: { before: 100, after: 200 }
                                        })],
                                    verticalAlign: VerticalAlign.CENTER,
                                }),
                                new TableCell({
                                    children: [
                                        new Paragraph({
                                            alignment: AlignmentType.LEFT,
                                            children: [
                                                new TextRun({
                                                    text: this.getValue(data.revision, "plan_organico_recomendaciones"),
                                                    font: 'Calibri (Cuerpo)',
                                                    size: 22,
                                                })
                                            ],
                                            spacing: { before: 100, after: 200 }
                                        })],
                                    verticalAlign: VerticalAlign.CENTER,
                                }),
                            ],
                        }),
                        new TableRow({
                            children: [
                                new TableCell({
                                    children: [
                                        new Paragraph({
                                            alignment: AlignmentType.RIGHT,
                                            children: [
                                                new TextRun({
                                                    text: 'Comentarios generales: ',
                                                    font: 'Calibri (Cuerpo)',
                                                    size: 22,
                                                })
                                            ],
                                            spacing: { before: 100, after: 200 }
                                        })],
                                    verticalAlign: VerticalAlign.CENTER,
                                }),
                                new TableCell({
                                    children: [
                                        new Paragraph({
                                            alignment: AlignmentType.LEFT,
                                            children: [
                                                new TextRun({
                                                    text: this.getValue(data.revision, "plan_organico_comentarios"),
                                                    font: 'Calibri (Cuerpo)',
                                                    size: 22,
                                                })
                                            ],
                                            spacing: { before: 100, after: 200 }
                                        })],
                                    verticalAlign: VerticalAlign.CENTER,
                                }),
                            ],
                        }),
                        new TableRow({
                            children: [
                                new TableCell({
                                    children: [
                                        new Paragraph({
                                            alignment: AlignmentType.RIGHT,
                                            children: [
                                                new TextRun({
                                                    text: `Firma del Revisor:`,
                                                    font: 'Calibri (Cuerpo)',
                                                    size: 22,
                                                })
                                            ],
                                            spacing: { before: 100, after: 200 }
                                        })],
                                    verticalAlign: VerticalAlign.CENTER,
                                }),
                                new TableCell({
                                    children: [
                                        new Paragraph({
                                            alignment: AlignmentType.LEFT,
                                            children: [
                                              signatureImage2 ? Media.addImage(document, signatureImage2.toString(), 200, 100) : new TextRun({
                                                text: ''
                                              })
                                            ],
                                            spacing: { before: 100, after: 200 }
                                        })
                                    ],
                                    verticalAlign: VerticalAlign.CENTER,
                                    columnSpan: 1,
                                }),
                            ],
                        }),
                    ],
                    margins: {
                        left: 70,
                        right: 70
                    },
                    width: {
                        size: 100,
                        type: WidthType.PERCENTAGE,
                    },
                    columnWidths: [2410, 7227]
                  })
            ],
            margins: {
                top: 350,
                bottom: 500,
                left: 1000,
                right: 1000
            }
        });

        return document;
    }

    public createFooter(): Table {
        const table = new Table({
            rows: [
                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: 'Elaboró:',
                                            font: 'Calibri (Cuerpo)',
                                            size: 22
                                        })
                                    ]
                                })
                            ],
                            width: {
                                size: 33,
                                type: WidthType.PERCENTAGE
                            }
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: 'Revisó:',
                                            font: 'Calibri (Cuerpo)',
                                            size: 22
                                        })
                                    ]
                                })
                            ],
                            width: {
                                size: 33,
                                type: WidthType.PERCENTAGE
                            }
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: 'Autorizó:',
                                            font: 'Calibri (Cuerpo)',
                                            size: 22
                                        })
                                    ]
                                }),
                            ],
                            width: {
                                size: 33,
                                type: WidthType.PERCENTAGE
                            }
                        })
                    ]
                }),
                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: 'Director de certificación',
                                            font: 'Calibri (Cuerpo)',
                                            size: 16,
                                            bold: true
                                        })
                                    ]
                                }),

                            ],
                            width: {
                                size: 33,
                                type: WidthType.PERCENTAGE
                            },
                            shading: {
                                fill: '#C0C0C0'
                            }
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: 'Responsable de calidad',
                                            font: 'Calibri (Cuerpo)',
                                            size: 16,
                                            bold: true
                                        })
                                    ]
                                }),

                            ],
                            width: {
                                size: 33,
                                type: WidthType.PERCENTAGE
                            },
                            shading: {
                                fill: '#C0C0C0'
                            }
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: 'Director general',
                                            font: 'Calibri (Cuerpo)',
                                            size: 16,
                                            bold: true
                                        })
                                    ]
                                }),
                            ],
                            width: {
                                size: 33,
                                type: WidthType.PERCENTAGE
                            },
                            shading: {
                                fill: '#C0C0C0'
                            }
                        })
                    ]
                })
            ],
            margins: {
                left: 70,
                right: 70
            },
            //9638
            columnWidths: [3213, 3213, 3212],
            alignment: AlignmentType.CENTER
        })
        return table
    }
    public createGeneralRegisters_MTO_FSOL_08_4(submission): Table {
        const table = new Table({
            rows: [
                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: 'REGISTROS GENERALES',
                                            font: 'Arial',
                                            size: 20,
                                            bold: true
                                        })
                                    ],
                                    spacing: {
                                        after: 75,
                                        before: 75
                                    }
                                }),
                            ],
                            verticalAlign: VerticalAlign.CENTER,
                            columnSpan: 7,
                            shading: {
                                fill: '#C0C0C0'
                            }
                        })
                    ]
                }),
                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: 'No.',
                                            font: 'Arial',
                                            size: 18,
                                            bold: true
                                        })
                                    ]
                                })
                            ],
                            rowSpan: 2,
                            verticalAlign: VerticalAlign.CENTER,
                            width: {
                                size: 25,
                                type: WidthType.PERCENTAGE
                            }
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: 'Requisitos',
                                            font: 'Arial',
                                            size: 18,
                                            bold: true
                                        })
                                    ]
                                })
                            ],
                            rowSpan: 2,
                            verticalAlign: VerticalAlign.CENTER,
                            width: {
                                size: 15,
                                type: WidthType.PERCENTAGE
                            }
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: 'Incluyo información',
                                            font: 'Arial',
                                            size: 18,
                                            bold: true
                                        })
                                    ]
                                })
                            ],
                            rowSpan: 2,
                            verticalAlign: VerticalAlign.CENTER,
                            width: {
                                size: 15,
                                type: WidthType.PERCENTAGE
                            }
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: 'Comentarios',
                                            font: 'Arial',
                                            size: 18,
                                            bold: true
                                        })
                                    ]
                                })
                            ],
                            rowSpan: 2,
                            verticalAlign: VerticalAlign.CENTER,
                            width: {
                                size: 15,
                                type: WidthType.PERCENTAGE
                            }
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: 'Verificación en sitio (Evaluada por Inspector)',
                                            font: 'Arial',
                                            size: 18,
                                            bold: true
                                        })
                                    ]
                                })
                            ],
                            columnSpan: 3,
                            verticalAlign: VerticalAlign.CENTER,
                            width: {
                                size: 15,
                                type: WidthType.PERCENTAGE
                            }
                        })
                    ]
                }),
                new TableRow({
                    children: [new TableCell({
                        children: [
                            new Paragraph({
                                alignment: AlignmentType.CENTER,
                                children: [
                                    new TextRun({
                                        text: 'Implementación',
                                        font: 'Arial',
                                        size: 18,
                                        bold: true
                                    })
                                ]
                            })
                        ],
                        verticalAlign: VerticalAlign.CENTER
                    }),
                    new TableCell({
                        children: [
                            new Paragraph({
                                alignment: AlignmentType.CENTER,
                                children: [
                                    new TextRun({
                                        text: 'Confirmación',
                                        font: 'Arial',
                                        size: 18,
                                        bold: true
                                    })
                                ]
                            })
                        ],
                        verticalAlign: VerticalAlign.CENTER,
                        columnSpan: 2
                    }),]
                }),
                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: '1',
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                })
                            ],
                            verticalAlign: VerticalAlign.TOP
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: 'He incluido una lista completa de los miembros del grupo y las unidades de producción que estoy sometiendo a evaluación para la certificación orgánica. MTO-LPO-FSOL-18',
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                })
                            ],
                            verticalAlign: VerticalAlign.TOP
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: this.getValue(submission, 'plan_organico_grupo_registros_1_declaracion') === 'Si' ? '■ SÍ  ❏ NO' : '❏ SÍ  ■ NO',
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                })
                            ],
                            verticalAlign: VerticalAlign.TOP
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: this.getValue(submission, 'plan_organico_grupo_registros_1_comentarios'),
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                })
                            ],
                            verticalAlign: VerticalAlign.TOP
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: ` ${this.getValue(submission, 'plan_organico_grupo_registros_1_implementacion') === 'I' ? '■' : '❏'} I`,
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                  }),
                                  new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: ` ${this.getValue(submission, 'plan_organico_grupo_registros_1_implementacion') === 'NI' ? '■' : '❏'} NI`,
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                  })
                            ],
                            verticalAlign: VerticalAlign.TOP
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: ` ${this.getValue(submission, 'plan_organico_grupo_registros_1_conformidad') === 'C' ? '■' : '❏'} C`,
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                  }),
                                  new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: ` ${this.getValue(submission, 'plan_organico_grupo_registros_1_conformidad') === 'NC' ? '■' : '❏'} NC`,
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                  }),
                                  new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: ` ${this.getValue(submission, 'plan_organico_grupo_registros_1_conformidad') === 'NA' ? '■' : '❏'} NA`,
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                  })
                            ],
                            verticalAlign: VerticalAlign.TOP
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: this.getValue(submission, 'plan_organico_grupo_registros_1_comentarios_inspector'),
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                  })
                            ],
                            verticalAlign: VerticalAlign.TOP
                        })
                    ]
                }),
                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: '2',
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                })
                            ],
                            verticalAlign: VerticalAlign.TOP
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: 'He incluido croquis de ubicación de cada una de las unidades de producción. MTO-LPO-FSOL-05',
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                })
                            ],
                            verticalAlign: VerticalAlign.TOP
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: this.getValue(submission, 'plan_organico_grupo_registros_2_declaracion') === 'Si' ? '■ SÍ  ❏ NO' : '❏ SÍ  ■ NO',
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                })
                            ],
                            verticalAlign: VerticalAlign.TOP
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: this.getValue(submission, 'plan_organico_grupo_registros_2_comentarios'),
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                })
                            ],
                            verticalAlign: VerticalAlign.TOP
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: ` ${this.getValue(submission, 'plan_organico_grupo_registros_2_implementacion') === 'I' ? '■' : '❏'} I`,
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                  }),
                                  new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: ` ${this.getValue(submission, 'plan_organico_grupo_registros_2_implementacion') === 'NI' ? '■' : '❏'} NI`,
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                  })
                            ],
                            verticalAlign: VerticalAlign.TOP
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: ` ${this.getValue(submission, 'plan_organico_grupo_registros_2_conformidad') === 'C' ? '■' : '❏'} C`,
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                  }),
                                  new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: ` ${this.getValue(submission, 'plan_organico_grupo_registros_2_conformidad') === 'NC' ? '■' : '❏'} NC`,
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                  }),
                                  new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: ` ${this.getValue(submission, 'plan_organico_grupo_registros_2_conformidad') === 'NA' ? '■' : '❏'} NA`,
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                  })
                            ],
                            verticalAlign: VerticalAlign.TOP
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: this.getValue(submission, 'plan_organico_grupo_registros_2_comentarios_inspector'),
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                  })
                            ],
                            verticalAlign: VerticalAlign.TOP
                        })
                    ]
                }),
                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: '3',
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                })
                            ],
                            verticalAlign: VerticalAlign.TOP
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: 'He incluido copia actualizada del reglamento interno para el grupo de productores',
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                })
                            ],
                            verticalAlign: VerticalAlign.TOP
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: this.getValue(submission, 'plan_organico_grupo_registros_3_declaracion') === 'Si' ? '■ SÍ  ❏ NO' : '❏ SÍ  ■ NO',
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                })
                            ],
                            verticalAlign: VerticalAlign.TOP
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: this.getValue(submission, 'plan_organico_grupo_registros_3_comentarios'),
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                })
                            ],
                            verticalAlign: VerticalAlign.TOP
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: ` ${this.getValue(submission, 'plan_organico_grupo_registros_3_implementacion') === 'I' ? '■' : '❏'} I`,
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                  }),
                                  new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: ` ${this.getValue(submission, 'plan_organico_grupo_registros_3_implementacion') === 'NI' ? '■' : '❏'} NI`,
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                  })
                            ],
                            verticalAlign: VerticalAlign.TOP
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: ` ${this.getValue(submission, 'plan_organico_grupo_registros_3_conformidad') === 'C' ? '■' : '❏'} C`,
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                  }),
                                  new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: ` ${this.getValue(submission, 'plan_organico_grupo_registros_3_conformidad') === 'NC' ? '■' : '❏'} NC`,
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                  }),
                                  new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: ` ${this.getValue(submission, 'plan_organico_grupo_registros_3_conformidad') === 'NA' ? '■' : '❏'} NA`,
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                  })
                            ],
                            verticalAlign: VerticalAlign.TOP
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: this.getValue(submission, 'plan_organico_grupo_registros_3_comentarios_inspector'),
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                  })
                            ],
                            verticalAlign: VerticalAlign.TOP
                        })
                    ]
                }),
                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: '4',
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                })
                            ],
                            verticalAlign: VerticalAlign.TOP
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: 'Dispongo de la siguiente información sobre el personal del SCI',
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                }),
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: (this.getValue(submission, "plan_organico_grupo_registros_4_lista_personal") ? '■' : '❏') + '  a) Lista de personal',
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                }),
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: (this.getValue(submission, "plan_organico_grupo_registros_4_curriculo") ? '■' : '❏') + '  b) Currículo',
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                }),
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: (this.getValue(submission, "plan_organico_grupo_registros_4_imarcialidad") ? '■' : '❏') + '  c) Exhorto a la imparcialidad',
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                })
                            ],
                            verticalAlign: VerticalAlign.TOP
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: this.getValue(submission, 'plan_organico_grupo_registros_4_declaraciones') === 'Si' ? '■ SÍ  ❏ NO' : '❏ SÍ  ■ NO',
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                })
                            ],
                            verticalAlign: VerticalAlign.TOP
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: this.getValue(submission, 'plan_organico_grupo_registros_4_comentarios'),
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                })
                            ],
                            verticalAlign: VerticalAlign.TOP
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: ` ${this.getValue(submission, 'plan_organico_grupo_registros_4_implementacion') === 'I' ? '■' : '❏'} I`,
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                  }),
                                  new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: ` ${this.getValue(submission, 'plan_organico_grupo_registros_4_implementacion') === 'NI' ? '■' : '❏'} NI`,
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                  })
                            ],
                            verticalAlign: VerticalAlign.TOP
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: ` ${this.getValue(submission, 'plan_organico_grupo_registros_4_conformidad') === 'C' ? '■' : '❏'} C`,
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                  }),
                                  new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: ` ${this.getValue(submission, 'plan_organico_grupo_registros_4_conformidad') === 'NC' ? '■' : '❏'} NC`,
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                  }),
                                  new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: ` ${this.getValue(submission, 'plan_organico_grupo_registros_4_conformidad') === 'NA' ? '■' : '❏'} NA`,
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                  })
                            ],
                            verticalAlign: VerticalAlign.TOP
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: this.getValue(submission, 'plan_organico_grupo_registros_4_comentarios_inspector'),
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                  })
                            ],
                            verticalAlign: VerticalAlign.TOP
                        })
                    ]
                }),
                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: '5',
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                })
                            ],
                            verticalAlign: VerticalAlign.TOP
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: 'He incluido una lista completa de riesgos para la integridad orgánica de los productos en el formato MTO-FSOL-04',
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                })
                            ],
                            verticalAlign: VerticalAlign.TOP
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: this.getValue(submission, 'plan_organico_grupo_registros_5_declaracion') === 'Si' ? '■ SÍ  ❏ NO' : '❏ SÍ  ■ NO',
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                })
                            ],
                            verticalAlign: VerticalAlign.TOP
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: this.getValue(submission, 'plan_organico_grupo_registros_5_comentarios'),
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                })
                            ],
                            verticalAlign: VerticalAlign.TOP
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: ` ${this.getValue(submission, 'plan_organico_grupo_registros_5_implementacion') === 'I' ? '■' : '❏'} I`,
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                  }),
                                  new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: ` ${this.getValue(submission, 'plan_organico_grupo_registros_5_implementacion') === 'NI' ? '■' : '❏'} NI`,
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                  })
                            ],
                            verticalAlign: VerticalAlign.TOP
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: ` ${this.getValue(submission, 'plan_organico_grupo_registros_5_conformidad') === 'C' ? '■' : '❏'} C`,
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                  }),
                                  new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: ` ${this.getValue(submission, 'plan_organico_grupo_registros_5_conformidad') === 'NC' ? '■' : '❏'} NC`,
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                  }),
                                  new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: ` ${this.getValue(submission, 'plan_organico_grupo_registros_5_conformidad') === 'NA' ? '■' : '❏'} NA`,
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                  })
                            ],
                            verticalAlign: VerticalAlign.TOP
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: this.getValue(submission, 'plan_organico_grupo_registros_5_comentarios_inspector'),
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                  })
                            ],
                            verticalAlign: VerticalAlign.TOP
                        })
                    ]
                })
            ],
            margins: {
                left: 70,
                right: 70
            },
            //9885
            columnWidths: [450, 3050, 1345, 1545, 1150, 800, 1545]
        })
        return table
    }

    public createRegistersPerMember_MTO_FSOL_08_4(submission): Table {
        const table = new Table({
            rows: [
                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: 'REGISTROS POR MIEMBRO',
                                            font: 'Arial',
                                            size: 20,
                                            bold: true
                                        })
                                    ],
                                    spacing: {
                                        after: 75,
                                        before: 75
                                    }
                                }),
                            ],
                            verticalAlign: VerticalAlign.CENTER,
                            columnSpan: 7,
                            shading: {
                                fill: '#C0C0C0'
                            }
                        })
                    ]
                }),
                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: 'No.',
                                            font: 'Arial',
                                            size: 18,
                                            bold: true
                                        })
                                    ]
                                })
                            ],
                            rowSpan: 2,
                            verticalAlign: VerticalAlign.CENTER,
                            width: {
                                size: 25,
                                type: WidthType.PERCENTAGE
                            }
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: 'Requisitos',
                                            font: 'Arial',
                                            size: 18,
                                            bold: true
                                        })
                                    ]
                                })
                            ],
                            rowSpan: 2,
                            verticalAlign: VerticalAlign.CENTER,
                            width: {
                                size: 15,
                                type: WidthType.PERCENTAGE
                            }
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: 'Incluyo información',
                                            font: 'Arial',
                                            size: 18,
                                            bold: true
                                        })
                                    ]
                                })
                            ],
                            rowSpan: 2,
                            verticalAlign: VerticalAlign.CENTER,
                            width: {
                                size: 15,
                                type: WidthType.PERCENTAGE
                            }
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: 'Comentarios',
                                            font: 'Arial',
                                            size: 18,
                                            bold: true
                                        })
                                    ]
                                })
                            ],
                            rowSpan: 2,
                            verticalAlign: VerticalAlign.CENTER,
                            width: {
                                size: 15,
                                type: WidthType.PERCENTAGE
                            }
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: 'Verificación en sitio (Evaluada por Inspector)',
                                            font: 'Arial',
                                            size: 18,
                                            bold: true
                                        })
                                    ]
                                })
                            ],
                            columnSpan: 3,
                            verticalAlign: VerticalAlign.CENTER,
                            width: {
                                size: 15,
                                type: WidthType.PERCENTAGE
                            }
                        })
                    ]
                }),
                new TableRow({
                    children: [new TableCell({
                        children: [
                            new Paragraph({
                                alignment: AlignmentType.CENTER,
                                children: [
                                    new TextRun({
                                        text: 'Implementación',
                                        font: 'Arial',
                                        size: 18,
                                        bold: true
                                    })
                                ]
                            })
                        ],
                        verticalAlign: VerticalAlign.CENTER
                    }),
                    new TableCell({
                        children: [
                            new Paragraph({
                                alignment: AlignmentType.CENTER,
                                children: [
                                    new TextRun({
                                        text: 'Confirmación',
                                        font: 'Arial',
                                        size: 18,
                                        bold: true
                                    })
                                ]
                            })
                        ],
                        verticalAlign: VerticalAlign.CENTER,
                        columnSpan: 2
                    }),]
                }),
                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: '1',
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                })
                            ],
                            verticalAlign: VerticalAlign.TOP
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: 'Dispongo de croquis que describen cada una de las unidades de producción. Anexo ejemplo',
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                })
                            ],
                            verticalAlign: VerticalAlign.TOP
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: this.getValue(submission, 'registros_miembros_1_declaracion') === 'Si' ? '■ SÍ  ❏ NO' : '❏ SÍ  ■ NO',
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                })
                            ],
                            verticalAlign: VerticalAlign.TOP
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: this.getValue(submission, 'adicionales_registros_miembros_1_comentarios'),
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                })
                            ],
                            verticalAlign: VerticalAlign.TOP
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: ` ${this.getValue(submission, 'registros_miembros_1_implementacion') === 'I' ? '■' : '❏'} I`,
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                  }),
                                  new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: ` ${this.getValue(submission, 'registros_miembros_1_implementacion') === 'NI' ? '■' : '❏'} NI`,
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                  })
                            ],
                            verticalAlign: VerticalAlign.TOP
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: ` ${this.getValue(submission, 'registros_miembros_1_conformidad') === 'C' ? '■' : '❏'} C`,
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                  }),
                                  new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: ` ${this.getValue(submission, 'registros_miembros_1_conformidad') === 'NC' ? '■' : '❏'} NC`,
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                  }),
                                  new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: ` ${this.getValue(submission, 'registros_miembros_1_conformidad') === 'NA' ? '■' : '❏'} NA`,
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                  })
                            ],
                            verticalAlign: VerticalAlign.TOP
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: this.getValue(submission, 'registros_miembros_1_comentarios_inspector'),
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                  })
                            ],
                            verticalAlign: VerticalAlign.TOP
                        })
                    ]
                }),
                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: '2',
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                })
                            ],
                            verticalAlign: VerticalAlign.TOP
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: 'Dispongo de listas de verificación de la evaluación de cada miembro y sus unidades de producción. Anexo ejemplo',
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                })
                            ],
                            verticalAlign: VerticalAlign.TOP
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: this.getValue(submission, 'registros_miembros_2_declaracion') === 'Si' ? '■ SÍ  ❏ NO' : '❏ SÍ  ■ NO',
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                })
                            ],
                            verticalAlign: VerticalAlign.TOP
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: this.getValue(submission, 'adicionales_registros_miembros_2_comentarios'),
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                })
                            ],
                            verticalAlign: VerticalAlign.TOP
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: ` ${this.getValue(submission, 'registros_miembros_2_implementacion') === 'I' ? '■' : '❏'} I`,
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                  }),
                                  new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: ` ${this.getValue(submission, 'registros_miembros_2_implementacion') === 'NI' ? '■' : '❏'} NI`,
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                  })
                            ],
                            verticalAlign: VerticalAlign.TOP
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: ` ${this.getValue(submission, 'registros_miembros_2_conformidad') === 'C' ? '■' : '❏'} C`,
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                  }),
                                  new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: ` ${this.getValue(submission, 'registros_miembros_2_conformidad') === 'NC' ? '■' : '❏'} NC`,
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                  }),
                                  new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: ` ${this.getValue(submission, 'registros_miembros_2_conformidad') === 'NA' ? '■' : '❏'} NA`,
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                  })
                            ],
                            verticalAlign: VerticalAlign.TOP
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: this.getValue(submission, 'registros_miembros_2_comentarios_inspector'),
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                  })
                            ],
                            verticalAlign: VerticalAlign.TOP
                        })
                    ]
                }),
                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: '3',
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                })
                            ],
                            verticalAlign: VerticalAlign.TOP
                        }),
                        new TableCell({
                            children: [
                                new Table({
                                    rows: [
                                        new TableRow({
                                            children: [
                                                new TableCell({
                                                    children: [
                                                        new Paragraph({
                                                            alignment: AlignmentType.LEFT,
                                                            children: [
                                                                new TextRun({
                                                                    text: 'Cuento con las siguientes bitácoras:',
                                                                    font: 'Arial',
                                                                    size: 18
                                                                })
                                                            ]
                                                        })
                                                    ],
                                                    borders: {
                                                        left: {
                                                            style: BorderStyle.NONE,
                                                            color: "white",
                                                            size: 0
                                                        },
                                                        top: {
                                                            style: BorderStyle.NONE,
                                                            color: "white",
                                                            size: 0
                                                        },
                                                        bottom: {
                                                            style: BorderStyle.NONE,
                                                            color: "white",
                                                            size: 0
                                                        }
                                                    }
                                                }),
                                                new TableCell({
                                                    children: [
                                                        new Paragraph({
                                                            alignment: AlignmentType.CENTER,
                                                            children: [
                                                                new TextRun({
                                                                    text: '',
                                                                    font: 'Arial',
                                                                    size: 18
                                                                })
                                                            ]
                                                        })
                                                    ],
                                                    borders: {
                                                        right: {
                                                            style: BorderStyle.NONE,
                                                            color: "white",
                                                            size: 0
                                                        },
                                                        top: {
                                                            style: BorderStyle.NONE,
                                                            color: "white",
                                                            size: 0
                                                        },
                                                        bottom: {
                                                            style: BorderStyle.NONE,
                                                            color: "white",
                                                            size: 0
                                                        }
                                                    }
                                                })
                                            ]
                                        }),
                                        new TableRow({
                                            children: [
                                                new TableCell({
                                                    children: [
                                                        new Paragraph({
                                                            alignment: AlignmentType.LEFT,
                                                            children: [
                                                                new TextRun({
                                                                    text: `${this.getValue(submission, 'registros_miembros_3_compra_inventari_semilla_check') ? '■' : '❏'} Compra e Inventario de semilla`,
                                                                    font: 'Arial',
                                                                    size: 18
                                                                })
                                                            ]
                                                        })
                                                    ],
                                                    borders: {
                                                        left: {
                                                            style: BorderStyle.NONE,
                                                            color: "white",
                                                            size: 0
                                                        },
                                                        top: {
                                                            style: BorderStyle.NONE,
                                                            color: "white",
                                                            size: 0
                                                        },
                                                        bottom: {
                                                            style: BorderStyle.NONE,
                                                            color: "white",
                                                            size: 0
                                                        }
                                                    }
                                                }),
                                                new TableCell({
                                                    children: [
                                                        new Paragraph({
                                                            alignment: AlignmentType.CENTER,
                                                            children: [
                                                                new TextRun({
                                                                    text: `${this.getValue(submission, 'registros_miembros_3_compra_inventari_semilla') === "Si" ? '■ Sí ❏ No' : this.getValue(submission, 'registros_miembros_3_compra_inventari_semilla') === "No" ? '❏ Sí ■ No' : '❏ Sí ❏ No'}`,
                                                                    font: 'Arial',
                                                                    size: 18
                                                                })
                                                            ]
                                                        })
                                                    ],
                                                    borders: {
                                                        right: {
                                                            style: BorderStyle.NONE,
                                                            color: "white",
                                                            size: 0
                                                        },
                                                        top: {
                                                            style: BorderStyle.NONE,
                                                            color: "white",
                                                            size: 0
                                                        },
                                                        bottom: {
                                                            style: BorderStyle.NONE,
                                                            color: "white",
                                                            size: 0
                                                        }
                                                    }
                                                })
                                            ]
                                        }),
                                        new TableRow({
                                            children: [
                                                new TableCell({
                                                    children: [
                                                        new Paragraph({
                                                            alignment: AlignmentType.LEFT,
                                                            children: [
                                                                new TextRun({
                                                                    text: `${this.getValue(submission, 'registros_miembros_3_compra_inventario_e_insumos_check') ? '■' : '❏'} Compra e Inventario de insumos`,
                                                                    font: 'Arial',
                                                                    size: 18
                                                                })
                                                            ]
                                                        })
                                                    ],
                                                    borders: {
                                                        left: {
                                                            style: BorderStyle.NONE,
                                                            color: "white",
                                                            size: 0
                                                        },
                                                        top: {
                                                            style: BorderStyle.NONE,
                                                            color: "white",
                                                            size: 0
                                                        },
                                                        bottom: {
                                                            style: BorderStyle.NONE,
                                                            color: "white",
                                                            size: 0
                                                        }
                                                    }
                                                }),
                                                new TableCell({
                                                    children: [
                                                        new Paragraph({
                                                            alignment: AlignmentType.CENTER,
                                                            children: [
                                                                new TextRun({
                                                                    text: `${this.getValue(submission, 'registros_miembros_3_compra_inventario_e_insumos') === "Si" ? '■ Sí ❏ No' : this.getValue(submission, 'registros_miembros_3_compra_inventario_e_insumos') === "No" ? '❏ Sí ■ No' : '❏ Sí ❏ No'}`,
                                                                    font: 'Arial',
                                                                    size: 18
                                                                })
                                                            ]
                                                        })
                                                    ],
                                                    borders: {
                                                        right: {
                                                            style: BorderStyle.NONE,
                                                            color: "white",
                                                            size: 0
                                                        },
                                                        top: {
                                                            style: BorderStyle.NONE,
                                                            color: "white",
                                                            size: 0
                                                        },
                                                        bottom: {
                                                            style: BorderStyle.NONE,
                                                            color: "white",
                                                            size: 0
                                                        }
                                                    }
                                                })
                                            ]
                                        }),
                                        new TableRow({
                                            children: [
                                                new TableCell({
                                                    children: [
                                                        new Paragraph({
                                                            alignment: AlignmentType.LEFT,
                                                            children: [
                                                                new TextRun({
                                                                    text: `${this.getValue(submission, 'registros_miembros_3_aplicacion_insumos_check') ? '■' : '❏'} Aplicación de insumos`,
                                                                    font: 'Arial',
                                                                    size: 18
                                                                })
                                                            ]
                                                        })
                                                    ],
                                                    borders: {
                                                        left: {
                                                            style: BorderStyle.NONE,
                                                            color: "white",
                                                            size: 0
                                                        },
                                                        top: {
                                                            style: BorderStyle.NONE,
                                                            color: "white",
                                                            size: 0
                                                        },
                                                        bottom: {
                                                            style: BorderStyle.NONE,
                                                            color: "white",
                                                            size: 0
                                                        }
                                                    }
                                                }),
                                                new TableCell({
                                                    children: [
                                                        new Paragraph({
                                                            alignment: AlignmentType.CENTER,
                                                            children: [
                                                                new TextRun({
                                                                    text: `${this.getValue(submission, 'registros_miembros_3_aplicacion_insumos') === "Si" ? '■ Sí ❏ No' : this.getValue(submission, 'registros_miembros_3_aplicacion_insumos') === "No" ? '❏ Sí ■ No' : '❏ Sí ❏ No'}`,
                                                                    font: 'Arial',
                                                                    size: 18
                                                                })
                                                            ]
                                                        })
                                                    ],
                                                    borders: {
                                                        right: {
                                                            style: BorderStyle.NONE,
                                                            color: "white",
                                                            size: 0
                                                        },
                                                        top: {
                                                            style: BorderStyle.NONE,
                                                            color: "white",
                                                            size: 0
                                                        },
                                                        bottom: {
                                                            style: BorderStyle.NONE,
                                                            color: "white",
                                                            size: 0
                                                        }
                                                    }
                                                })
                                            ]
                                        }),
                                        new TableRow({
                                            children: [
                                                new TableCell({
                                                    children: [
                                                        new Paragraph({
                                                            alignment: AlignmentType.LEFT,
                                                            children: [
                                                                new TextRun({
                                                                    text: `${this.getValue(submission, 'registros_miembros_3_cosecha_check') ? '■' : '❏'} Cosecha`,
                                                                    font: 'Arial',
                                                                    size: 18
                                                                })
                                                            ]
                                                        })
                                                    ],
                                                    borders: {
                                                        left: {
                                                            style: BorderStyle.NONE,
                                                            color: "white",
                                                            size: 0
                                                        },
                                                        top: {
                                                            style: BorderStyle.NONE,
                                                            color: "white",
                                                            size: 0
                                                        },
                                                        bottom: {
                                                            style: BorderStyle.NONE,
                                                            color: "white",
                                                            size: 0
                                                        }
                                                    }
                                                }),
                                                new TableCell({
                                                    children: [
                                                        new Paragraph({
                                                            alignment: AlignmentType.CENTER,
                                                            children: [
                                                                new TextRun({
                                                                    text: `${this.getValue(submission, 'registros_miembros_3_cosecha') === "Si" ? '■ Sí ❏ No' : this.getValue(submission, 'registros_miembros_3_cosecha') === "No" ? '❏ Sí ■ No' : '❏ Sí ❏ No'}`,
                                                                    font: 'Arial',
                                                                    size: 18
                                                                })
                                                            ]
                                                        })
                                                    ],
                                                    borders: {
                                                        right: {
                                                            style: BorderStyle.NONE,
                                                            color: "white",
                                                            size: 0
                                                        },
                                                        top: {
                                                            style: BorderStyle.NONE,
                                                            color: "white",
                                                            size: 0
                                                        },
                                                        bottom: {
                                                            style: BorderStyle.NONE,
                                                            color: "white",
                                                            size: 0
                                                        }
                                                    }
                                                })
                                            ]
                                        }),
                                        new TableRow({
                                            children: [
                                                new TableCell({
                                                    children: [
                                                        new Paragraph({
                                                            alignment: AlignmentType.LEFT,
                                                            children: [
                                                                new TextRun({
                                                                    text: `${this.getValue(submission, 'registros_miembros_3_ventas_check') ? '■' : '❏'} Ventas`,
                                                                    font: 'Arial',
                                                                    size: 18
                                                                })
                                                            ]
                                                        })
                                                    ],
                                                    borders: {
                                                        left: {
                                                            style: BorderStyle.NONE,
                                                            color: "white",
                                                            size: 0
                                                        },
                                                        top: {
                                                            style: BorderStyle.NONE,
                                                            color: "white",
                                                            size: 0
                                                        },
                                                        bottom: {
                                                            style: BorderStyle.NONE,
                                                            color: "white",
                                                            size: 0
                                                        }
                                                    }
                                                }),
                                                new TableCell({
                                                    children: [
                                                        new Paragraph({
                                                            alignment: AlignmentType.CENTER,
                                                            children: [
                                                                new TextRun({
                                                                    text: `${this.getValue(submission, 'registros_miembros_3_ventas') === "Si" ? '■ Sí ❏ No' : this.getValue(submission, 'registros_miembros_3_ventas') === "No" ? '❏ Sí ■ No' : '❏ Sí ❏ No'}`,
                                                                    font: 'Arial',
                                                                    size: 18
                                                                })
                                                            ]
                                                        })
                                                    ],
                                                    borders: {
                                                        right: {
                                                            style: BorderStyle.NONE,
                                                            color: "white",
                                                            size: 0
                                                        },
                                                        top: {
                                                            style: BorderStyle.NONE,
                                                            color: "white",
                                                            size: 0
                                                        },
                                                        bottom: {
                                                            style: BorderStyle.NONE,
                                                            color: "white",
                                                            size: 0
                                                        }
                                                    }
                                                })
                                            ]
                                        }),
                                        new TableRow({
                                            children: [
                                                new TableCell({
                                                    children: [
                                                        new Paragraph({
                                                            alignment: AlignmentType.LEFT,
                                                            children: [
                                                                new TextRun({
                                                                    text: `${this.getValue(submission, 'registros_miembros_3_destino_check') ? '■' : '❏'} Destino producto de zonas buffer`,
                                                                    font: 'Arial',
                                                                    size: 18
                                                                })
                                                            ]
                                                        })
                                                    ],
                                                    borders: {
                                                        left: {
                                                            style: BorderStyle.NONE,
                                                            color: "white",
                                                            size: 0
                                                        },
                                                        top: {
                                                            style: BorderStyle.NONE,
                                                            color: "white",
                                                            size: 0
                                                        },
                                                        bottom: {
                                                            style: BorderStyle.NONE,
                                                            color: "white",
                                                            size: 0
                                                        }
                                                    }
                                                }),
                                                new TableCell({
                                                    children: [
                                                        new Paragraph({
                                                            alignment: AlignmentType.CENTER,
                                                            children: [
                                                                new TextRun({
                                                                    text: `${this.getValue(submission, 'registros_miembros_3_destino') === "Si" ? '■ Sí ❏ No' : this.getValue(submission, 'registros_miembros_3_destino') === "No" ? '❏ Sí ■ No' : '❏ Sí ❏ No'}`,
                                                                    font: 'Arial',
                                                                    size: 18
                                                                })
                                                            ]
                                                        })
                                                    ],
                                                    borders: {
                                                        right: {
                                                            style: BorderStyle.NONE,
                                                            color: "white",
                                                            size: 0
                                                        },
                                                        top: {
                                                            style: BorderStyle.NONE,
                                                            color: "white",
                                                            size: 0
                                                        },
                                                        bottom: {
                                                            style: BorderStyle.NONE,
                                                            color: "white",
                                                            size: 0
                                                        }
                                                    }
                                                })
                                            ]
                                        }),
                                        new TableRow({
                                            children: [
                                                new TableCell({
                                                    children: [
                                                        new Paragraph({
                                                            alignment: AlignmentType.LEFT,
                                                            children: [
                                                                new TextRun({
                                                                    text: `${this.getValue(submission, 'registros_miembros_3_otro_check') ? '■' : '❏'}  Otra: ${this.getValue(submission, 'registros_miembros_3_otro')}`,
                                                                    font: 'Arial',
                                                                    size: 18
                                                                })
                                                            ]
                                                        })
                                                    ],
                                                    borders: {
                                                        left: {
                                                            style: BorderStyle.NONE,
                                                            color: "white",
                                                            size: 0
                                                        },
                                                        top: {
                                                            style: BorderStyle.NONE,
                                                            color: "white",
                                                            size: 0
                                                        },
                                                        bottom: {
                                                            style: BorderStyle.NONE,
                                                            color: "white",
                                                            size: 0
                                                        }
                                                    }
                                                }),
                                                new TableCell({
                                                    children: [
                                                        new Paragraph({
                                                            alignment: AlignmentType.CENTER,
                                                            children: [
                                                                new TextRun({
                                                                    text: `${this.getValue(submission, 'registros_miembros_3_options') === "Si" ? '■ Sí ❏ No' : this.getValue(submission, 'registros_miembros_3_options') === "No" ? '❏ Sí ■ No' : '❏ Sí ❏ No'}`,
                                                                    font: 'Arial',
                                                                    size: 18
                                                                })
                                                            ]
                                                        })
                                                    ],
                                                    borders: {
                                                        right: {
                                                            style: BorderStyle.NONE,
                                                            color: "white",
                                                            size: 0
                                                        },
                                                        top: {
                                                            style: BorderStyle.NONE,
                                                            color: "white",
                                                            size: 0
                                                        },
                                                        bottom: {
                                                            style: BorderStyle.NONE,
                                                            color: "white",
                                                            size: 0
                                                        }
                                                    }
                                                })
                                            ]
                                        })
                                    ],
                                    //4395
                                    columnWidths: [3075, 1320]
                                })
                            ],
                            columnSpan: 2
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: this.getValue(submission, 'plan_organico_fungi_registros_3_comentarios'),
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                })
                            ],
                            verticalAlign: VerticalAlign.TOP
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: ` ${this.getValue(submission, 'registros_miembros_3_implementacion') === 'I' ? '■' : '❏'} I`,
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                  }),
                                  new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: ` ${this.getValue(submission, 'registros_miembros_3_implementacion') === 'NI' ? '■' : '❏'} NI`,
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                  })
                            ],
                            verticalAlign: VerticalAlign.TOP
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: ` ${this.getValue(submission, 'registros_miembros_3_conformidad') === 'C' ? '■' : '❏'} C`,
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                  }),
                                  new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: ` ${this.getValue(submission, 'registros_miembros_3_conformidad') === 'NC' ? '■' : '❏'} NC`,
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                  }),
                                  new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: ` ${this.getValue(submission, 'registros_miembros_3_conformidad') === 'NA' ? '■' : '❏'} NA`,
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                  })
                            ],
                            verticalAlign: VerticalAlign.TOP
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: this.getValue(submission, 'registros_miembros_3_comentarios_inspector'),
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                  })
                            ],
                            verticalAlign: VerticalAlign.TOP
                        })
                    ]
                }),
                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: '4',
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                })
                            ],
                            verticalAlign: VerticalAlign.TOP
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: 'Dispongo de un análisis de riesgos de contaminación por colindantes en el formato MTO-FSOL-06. Anexo ejemplo',
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                })
                            ],
                            verticalAlign: VerticalAlign.TOP
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: this.getValue(submission, 'registros_miembros_4_declaracion') === 'Si' ? '■ SÍ  ❏ NO' : '❏ SÍ  ■ NO',
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                })
                            ],
                            verticalAlign: VerticalAlign.TOP
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: this.getValue(submission, 'registros_miembros_4_comentarios'),
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                })
                            ],
                            verticalAlign: VerticalAlign.TOP
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: ` ${this.getValue(submission, 'registros_miembros_4_implementacion') === 'I' ? '■' : '❏'} I`,
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                  }),
                                  new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: ` ${this.getValue(submission, 'registros_miembros_4_implementacion') === 'NI' ? '■' : '❏'} NI`,
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                  })
                            ],
                            verticalAlign: VerticalAlign.TOP
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: ` ${this.getValue(submission, 'registros_miembros_4_conformidad') === 'C' ? '■' : '❏'} C`,
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                  }),
                                  new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: ` ${this.getValue(submission, 'registros_miembros_4_conformidad') === 'NC' ? '■' : '❏'} NC`,
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                  }),
                                  new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: ` ${this.getValue(submission, 'registros_miembros_4_conformidad') === 'NA' ? '■' : '❏'} NA`,
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                  })
                            ],
                            verticalAlign: VerticalAlign.TOP
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: this.getValue(submission, 'registros_miembros_4_comentarios_inspector'),
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                  })
                            ],
                            verticalAlign: VerticalAlign.TOP
                        })
                    ]
                }),
                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: '5',
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                })
                            ],
                            verticalAlign: VerticalAlign.TOP
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: 'Dispongo de listas de los insumos utilizados para la fertilización en el formato MTO-FSOL-07. Anexo ejemplo',
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                })
                            ],
                            verticalAlign: VerticalAlign.TOP
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: this.getValue(submission, 'registros_miembros_5_declaracion') === 'Si' ? '■ SÍ  ❏ NO' : '❏ SÍ  ■ NO',
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                })
                            ],
                            verticalAlign: VerticalAlign.TOP
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: this.getValue(submission, 'registros_miembros_5_comentarios'),
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                })
                            ],
                            verticalAlign: VerticalAlign.TOP
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: ` ${this.getValue(submission, 'registros_miembros_5_implementacion') === 'I' ? '■' : '❏'} I`,
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                  }),
                                  new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: ` ${this.getValue(submission, 'registros_miembros_5_implementacion') === 'NI' ? '■' : '❏'} NI`,
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                  })
                            ],
                            verticalAlign: VerticalAlign.TOP
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: ` ${this.getValue(submission, 'registros_miembros_5_conformidad') === 'C' ? '■' : '❏'} C`,
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                  }),
                                  new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: ` ${this.getValue(submission, 'registros_miembros_5_conformidad') === 'NC' ? '■' : '❏'} NC`,
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                  }),
                                  new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: ` ${this.getValue(submission, 'registros_miembros_5_conformidad') === 'NA' ? '■' : '❏'} NA`,
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                  })
                            ],
                            verticalAlign: VerticalAlign.TOP
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: this.getValue(submission, 'registros_miembros_5_comentarios_inspector'),
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                  })
                            ],
                            verticalAlign: VerticalAlign.TOP
                        })
                    ]
                }),
                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: '6',
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                })
                            ],
                            verticalAlign: VerticalAlign.TOP
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: 'Dispongo de registro de la fuente, cantidad, tipo de estiércol y evaluación del cumplimiento del criterio 5.5.1 CAN/CGSB-32.310. Anexo ejemplo',
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                })
                            ],
                            verticalAlign: VerticalAlign.TOP
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: this.getValue(submission, 'registros_miembros_6_declaracion') === 'Si' ? '■ SÍ  ❏ NO' : '❏ SÍ  ■ NO',
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                })
                            ],
                            verticalAlign: VerticalAlign.TOP
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: this.getValue(submission, 'registros_miembros_6_comentarios'),
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                })
                            ],
                            verticalAlign: VerticalAlign.TOP
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: ` ${this.getValue(submission, 'registros_miembros_6_implementacion') === 'I' ? '■' : '❏'} I`,
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                  }),
                                  new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: ` ${this.getValue(submission, 'registros_miembros_6_implementacion') === 'NI' ? '■' : '❏'} NI`,
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                  })
                            ],
                            verticalAlign: VerticalAlign.TOP
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: ` ${this.getValue(submission, 'registros_miembros_6_conformidad') === 'C' ? '■' : '❏'} C`,
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                  }),
                                  new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: ` ${this.getValue(submission, 'registros_miembros_6_conformidad') === 'NC' ? '■' : '❏'} NC`,
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                  }),
                                  new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: ` ${this.getValue(submission, 'registros_miembros_6_conformidad') === 'NA' ? '■' : '❏'} NA`,
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                  })
                            ],
                            verticalAlign: VerticalAlign.TOP
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: this.getValue(submission, 'registros_miembros_6_comentarios_inspector'),
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                  })
                            ],
                            verticalAlign: VerticalAlign.TOP
                        })
                    ]
                }),
                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: '7',
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                })
                            ],
                            verticalAlign: VerticalAlign.TOP
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: 'Dispongo de listas de las sustancias utilizadas para el control de plagas y enfermedades en el formato MTO-FSOL-07. Anexo ejemplo',
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                })
                            ],
                            verticalAlign: VerticalAlign.TOP
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: this.getValue(submission, 'registros_miembros_8_declaracion') === 'Si' ? '■ SÍ  ❏ NO' : '❏ SÍ  ■ NO',
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                })
                            ],
                            verticalAlign: VerticalAlign.TOP
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: this.getValue(submission, 'adicionales_registros_miembros_8_comentarios'),
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                })
                            ],
                            verticalAlign: VerticalAlign.TOP
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: ` ${this.getValue(submission, 'registros_miembros_7_implementacion') === 'I' ? '■' : '❏'} I`,
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                  }),
                                  new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: ` ${this.getValue(submission, 'registros_miembros_7_implementacion') === 'NI' ? '■' : '❏'} NI`,
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                  })
                            ],
                            verticalAlign: VerticalAlign.TOP
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: ` ${this.getValue(submission, 'registros_miembros_7_conformidad') === 'C' ? '■' : '❏'} C`,
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                  }),
                                  new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: ` ${this.getValue(submission, 'registros_miembros_7_conformidad') === 'NC' ? '■' : '❏'} NC`,
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                  }),
                                  new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: ` ${this.getValue(submission, 'registros_miembros_7_conformidad') === 'NA' ? '■' : '❏'} NA`,
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                  })
                            ],
                            verticalAlign: VerticalAlign.TOP
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: this.getValue(submission, 'registros_miembros_7_comentarios_inspector'),
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                  })
                            ],
                            verticalAlign: VerticalAlign.TOP
                        })
                    ]
                }),
                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: '8',
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                })
                            ],
                            verticalAlign: VerticalAlign.TOP
                        }),
                        new TableCell({
                            children: [
                                new Table({
                                    rows: [
                                        new TableRow({
                                            children: [
                                                new TableCell({
                                                    children: [
                                                        new Paragraph({
                                                            alignment: AlignmentType.LEFT,
                                                            children: [
                                                                new TextRun({
                                                                    text: 'Dispongo de solicitudes de la excepción para:',
                                                                    font: 'Arial',
                                                                    size: 18
                                                                })
                                                            ]
                                                        })
                                                    ],
                                                    borders: {
                                                        left: {
                                                            style: BorderStyle.NONE,
                                                            color: "white",
                                                            size: 0
                                                        },
                                                        top: {
                                                            style: BorderStyle.NONE,
                                                            color: "white",
                                                            size: 0
                                                        },
                                                        bottom: {
                                                            style: BorderStyle.NONE,
                                                            color: "white",
                                                            size: 0
                                                        }
                                                    }
                                                }),
                                                new TableCell({
                                                    children: [
                                                        new Paragraph({
                                                            alignment: AlignmentType.CENTER,
                                                            children: [
                                                                new TextRun({
                                                                    text: '',
                                                                    font: 'Arial',
                                                                    size: 18
                                                                })
                                                            ]
                                                        })
                                                    ],
                                                    borders: {
                                                        right: {
                                                            style: BorderStyle.NONE,
                                                            color: "white",
                                                            size: 0
                                                        },
                                                        top: {
                                                            style: BorderStyle.NONE,
                                                            color: "white",
                                                            size: 0
                                                        },
                                                        bottom: {
                                                            style: BorderStyle.NONE,
                                                            color: "white",
                                                            size: 0
                                                        }
                                                    }
                                                })
                                            ]
                                        }),
                                        new TableRow({
                                            children: [
                                                new TableCell({
                                                    children: [
                                                        new Paragraph({
                                                            alignment: AlignmentType.LEFT,
                                                            children: [
                                                                new TextRun({
                                                                    text: `${this.getValue(submission, 'registros_miembros_8_reconocimiento_check') ? '■' : '❏'} Reconocimiento retroactivo de periodo de conversión`,
                                                                    font: 'Arial',
                                                                    size: 18
                                                                })
                                                            ]
                                                        })
                                                    ],
                                                    borders: {
                                                        left: {
                                                            style: BorderStyle.NONE,
                                                            color: "white",
                                                            size: 0
                                                        },
                                                        top: {
                                                            style: BorderStyle.NONE,
                                                            color: "white",
                                                            size: 0
                                                        },
                                                        bottom: {
                                                            style: BorderStyle.NONE,
                                                            color: "white",
                                                            size: 0
                                                        }
                                                    }
                                                }),
                                                new TableCell({
                                                    children: [
                                                        new Paragraph({
                                                            alignment: AlignmentType.CENTER,
                                                            children: [
                                                                new TextRun({
                                                                    text: `${this.getValue(submission, 'registros_miembros_8_reconocimiento') === "Si" ? '■ Sí ❏ No' : this.getValue(submission, 'registros_miembros_8_reconocimiento') === "No" ? '❏ Sí ■ No' : '❏ Sí ❏ No'}`,
                                                                    font: 'Arial',
                                                                    size: 18
                                                                })
                                                            ]
                                                        })
                                                    ],
                                                    borders: {
                                                        right: {
                                                            style: BorderStyle.NONE,
                                                            color: "white",
                                                            size: 0
                                                        },
                                                        top: {
                                                            style: BorderStyle.NONE,
                                                            color: "white",
                                                            size: 0
                                                        },
                                                        bottom: {
                                                            style: BorderStyle.NONE,
                                                            color: "white",
                                                            size: 0
                                                        }
                                                    }
                                                })
                                            ]
                                        }),
                                        new TableRow({
                                            children: [
                                                new TableCell({
                                                    children: [
                                                        new Paragraph({
                                                            alignment: AlignmentType.LEFT,
                                                            children: [
                                                                new TextRun({
                                                                    text: `${this.getValue(submission, 'registros_miembros_8_uso_semilla_no_organica_check') ? '■' : '❏'} Uso de semillas no orgánicas`,
                                                                    font: 'Arial',
                                                                    size: 18
                                                                })
                                                            ]
                                                        })
                                                    ],
                                                    borders: {
                                                        left: {
                                                            style: BorderStyle.NONE,
                                                            color: "white",
                                                            size: 0
                                                        },
                                                        top: {
                                                            style: BorderStyle.NONE,
                                                            color: "white",
                                                            size: 0
                                                        },
                                                        bottom: {
                                                            style: BorderStyle.NONE,
                                                            color: "white",
                                                            size: 0
                                                        }
                                                    }
                                                }),
                                                new TableCell({
                                                    children: [
                                                        new Paragraph({
                                                            alignment: AlignmentType.CENTER,
                                                            children: [
                                                                new TextRun({
                                                                    text: `${this.getValue(submission, 'registros_miembros_8_uso_semilla_no_organica') === "Si" ? '■ Sí ❏ No' : this.getValue(submission, 'registros_miembros_8_uso_semilla_no_organica') === "No" ? '❏ Sí ■ No' : '❏ Sí ❏ No'}`,
                                                                    font: 'Arial',
                                                                    size: 18
                                                                })
                                                            ]
                                                        })
                                                    ],
                                                    borders: {
                                                        right: {
                                                            style: BorderStyle.NONE,
                                                            color: "white",
                                                            size: 0
                                                        },
                                                        top: {
                                                            style: BorderStyle.NONE,
                                                            color: "white",
                                                            size: 0
                                                        },
                                                        bottom: {
                                                            style: BorderStyle.NONE,
                                                            color: "white",
                                                            size: 0
                                                        }
                                                    }
                                                })
                                            ]
                                        }),
                                        new TableRow({
                                            children: [
                                                new TableCell({
                                                    children: [
                                                        new Paragraph({
                                                            alignment: AlignmentType.LEFT,
                                                            children: [
                                                                new TextRun({
                                                                    text: `${this.getValue(submission, 'registros_miembros_8_prod_paralela_check') ? '■' : '❏'} Producción paralela`,
                                                                    font: 'Arial',
                                                                    size: 18
                                                                })
                                                            ]
                                                        })
                                                    ],
                                                    borders: {
                                                        left: {
                                                            style: BorderStyle.NONE,
                                                            color: "white",
                                                            size: 0
                                                        },
                                                        top: {
                                                            style: BorderStyle.NONE,
                                                            color: "white",
                                                            size: 0
                                                        },
                                                        bottom: {
                                                            style: BorderStyle.NONE,
                                                            color: "white",
                                                            size: 0
                                                        }
                                                    }
                                                }),
                                                new TableCell({
                                                    children: [
                                                        new Paragraph({
                                                            alignment: AlignmentType.CENTER,
                                                            children: [
                                                                new TextRun({
                                                                    text: `${this.getValue(submission, 'registros_miembros_8_prod_paralela') === "Si" ? '■ Sí ❏ No' : this.getValue(submission, 'registros_miembros_8_prod_paralela') === "No" ? '❏ Sí ■ No' : '❏ Sí ❏ No'}`,
                                                                    font: 'Arial',
                                                                    size: 18
                                                                })
                                                            ]
                                                        })
                                                    ],
                                                    borders: {
                                                        right: {
                                                            style: BorderStyle.NONE,
                                                            color: "white",
                                                            size: 0
                                                        },
                                                        top: {
                                                            style: BorderStyle.NONE,
                                                            color: "white",
                                                            size: 0
                                                        },
                                                        bottom: {
                                                            style: BorderStyle.NONE,
                                                            color: "white",
                                                            size: 0
                                                        }
                                                    }
                                                })
                                            ]
                                        }),
                                        new TableRow({
                                            children: [
                                                new TableCell({
                                                    children: [
                                                        new Paragraph({
                                                            alignment: AlignmentType.LEFT,
                                                            children: [
                                                                new TextRun({
                                                                    text: `${this.getValue(submission, 'registros_miembros_8_otro_check') ? '■' : '❏'} Otro: ${this.getValue(submission, 'registros_miembros_8_otro')}`,
                                                                    font: 'Arial',
                                                                    size: 18
                                                                })
                                                            ]
                                                        })
                                                    ],
                                                    borders: {
                                                        left: {
                                                            style: BorderStyle.NONE,
                                                            color: "white",
                                                            size: 0
                                                        },
                                                        top: {
                                                            style: BorderStyle.NONE,
                                                            color: "white",
                                                            size: 0
                                                        },
                                                        bottom: {
                                                            style: BorderStyle.NONE,
                                                            color: "white",
                                                            size: 0
                                                        }
                                                    }
                                                }),
                                                new TableCell({
                                                    children: [
                                                        new Paragraph({
                                                            alignment: AlignmentType.CENTER,
                                                            children: [
                                                                new TextRun({
                                                                    text: `${this.getValue(submission, 'registros_miembros_8_options') === "Si" ? '■ Sí ❏ No' : this.getValue(submission, 'registros_miembros_8_options') === "No" ? '❏ Sí ■ No' : '❏ Sí ❏ No'}`,
                                                                    font: 'Arial',
                                                                    size: 18
                                                                })
                                                            ]
                                                        })
                                                    ],
                                                    borders: {
                                                        right: {
                                                            style: BorderStyle.NONE,
                                                            color: "white",
                                                            size: 0
                                                        },
                                                        top: {
                                                            style: BorderStyle.NONE,
                                                            color: "white",
                                                            size: 0
                                                        },
                                                        bottom: {
                                                            style: BorderStyle.NONE,
                                                            color: "white",
                                                            size: 0
                                                        }
                                                    }
                                                })
                                            ]
                                        })
                                    ],
                                    //4395
                                    columnWidths: [3075, 1320]
                                })
                            ],
                            columnSpan: 2
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: this.getValue(submission, 'plan_organico_fungi_registros_8_comentarios'),
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                })
                            ],
                            verticalAlign: VerticalAlign.TOP
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: ` ${this.getValue(submission, 'registros_miembros_8_implementacion') === 'I' ? '■' : '❏'} I`,
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                  }),
                                  new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: ` ${this.getValue(submission, 'registros_miembros_8_implementacion') === 'NI' ? '■' : '❏'} NI`,
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                  })
                            ],
                            verticalAlign: VerticalAlign.TOP
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: ` ${this.getValue(submission, 'registros_miembros_8_conformidad') === 'C' ? '■' : '❏'} C`,
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                  }),
                                  new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: ` ${this.getValue(submission, 'registros_miembros_8_conformidad') === 'NC' ? '■' : '❏'} NC`,
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                  }),
                                  new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: ` ${this.getValue(submission, 'registros_miembros_8_conformidad') === 'NA' ? '■' : '❏'} NA`,
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                  })
                            ],
                            verticalAlign: VerticalAlign.TOP
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: this.getValue(submission, 'registros_miembros_8_comentarios_inspector'),
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                  })
                            ],
                            verticalAlign: VerticalAlign.TOP
                        })
                    ]
                }),
            ],
            margins: {
                left: 70,
                right: 70
            },
            //9885
            columnWidths: [450, 3050, 1345, 1545, 1150, 800, 1545]
        })
        return table
    }

    public createTableUDP(data, datePipe): Table {
        const table = new Table({
            rows: [
                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.RIGHT,
                                    children: [
                                        new TextRun({
                                            text: 'Operador: ',
                                            font: 'Calibri (Cuerpo)',
                                            size: 22,
                                            bold: true,

                                        })
                                    ],
                                    spacing: { before: 100, after: 200 }
                                })],
                            verticalAlign: VerticalAlign.CENTER,
                        }),
                        new TableCell({
                            children: [new Paragraph({
                                alignment: AlignmentType.CENTER,
                                children: [
                                    new TextRun({
                                        text: data.customer.Company_Name,
                                        font: 'Calibri (Cuerpo)',
                                        size: 22,
                                    })
                                ],
                            })],
                            verticalAlign: VerticalAlign.CENTER,
                            columnSpan: 3,
                        }),
                    ],
                }),
                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.RIGHT,
                                    children: [
                                        new TextRun({
                                            text: 'Código del operador: ',
                                            font: 'Calibri (Cuerpo)',
                                            size: 22,
                                            bold: true,

                                        })
                                    ],
                                    spacing: { before: 100, after: 200 }
                                })],
                            width: {
                                size: 25,
                                type: WidthType.PERCENTAGE,
                            },
                        }),
                        new TableCell({
                            children: [new Paragraph({
                                alignment: AlignmentType.CENTER,
                                children: [
                                    new TextRun({
                                        text: data.customer.Id_Comercial,
                                        font: 'Calibri (Cuerpo)',
                                        size: 22,
                                    })
                                ],
                            })],
                            verticalAlign: VerticalAlign.CENTER,
                            width: {
                                size: 25,
                                type: WidthType.PERCENTAGE,
                            },
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.RIGHT,
                                    children: [
                                        new TextRun({
                                            text: 'Fecha: ',
                                            font: 'Calibri (Cuerpo)',
                                            size: 22,
                                            bold: true,

                                        })
                                    ],
                                    spacing: { before: 100, after: 200 }
                                })],
                            verticalAlign: VerticalAlign.CENTER,
                            width: {
                                size: 25,
                                type: WidthType.PERCENTAGE,
                            },
                        }),
                        new TableCell({
                            children: [new Paragraph({
                                alignment: AlignmentType.CENTER,
                                children: [
                                    new TextRun({
                                        text: datePipe.transform(data.record.Applications[0].Creation_Date.includes('UTC') ? data.record.Applications[0].Creation_Date.replace('UTC', '').trim() : data.record.Applications[0].Creation_Date, "d-MMMM-y"),
                                        font: 'Calibri (Cuerpo)',
                                        size: 22,
                                    })
                                ],
                            })],
                            verticalAlign: VerticalAlign.CENTER,
                            width: {
                                size: 25,
                                type: WidthType.PERCENTAGE,
                            },
                        }),
                    ],
                }),
            ],
            margins: {
                left: 70,
                right: 70
            },
            width: {
                size: 100,
                type: WidthType.PERCENTAGE,
            },
            //9638
            columnWidths: [2410, 2410, 2409, 2409]
        })

        let listaUnidades = this.getValueList(data.submission, "lista_unidades")
        listaUnidades.forEach((unidad, i) => {
            table.addChildElement(new TableRow({
                children: [
                    new TableCell({
                        children: [
                            new Paragraph({
                                alignment: AlignmentType.RIGHT,
                                children: [
                                    new TextRun({
                                        text: i === 0 ? 'Unidades de producción: ' : '',
                                        font: 'Calibri (Cuerpo)',
                                        size: 22,
                                        bold: true,

                                    })
                                ],
                                spacing: { before: 100, after: 200 }
                            })],
                        verticalAlign: VerticalAlign.CENTER,
                    }),
                    new TableCell({
                        children: [
                            new Paragraph({
                                children: [
                                    new TextRun({
                                        text: 'Nombre: ',
                                        font: 'Calibri (Cuerpo)',
                                        size: 22,
                                        bold: true,

                                    }),
                                    new TextRun({
                                        text: unidad.nombre_unidad_produccion,
                                        font: 'Calibri (Cuerpo)',
                                        size: 22,
                                    }),
                                ],
                                spacing: { before: 100, after: 200 }
                            }),
                            new Paragraph({
                                children: [
                                    new TextRun({
                                        text: 'Número de miembros: ',
                                        font: 'Calibri (Cuerpo)',
                                        size: 22,
                                        bold: true,

                                    }),
                                    new TextRun({
                                        text: unidad.numero_miembros,
                                        font: 'Calibri (Cuerpo)',
                                        size: 22,

                                    })
                                ],
                                spacing: { before: 100, after: 200 }
                            }),
                            new Paragraph({
                                children: [
                                    new TextRun({
                                        text: `Número de comunidades: `,
                                        font: 'Calibri (Cuerpo)',
                                        size: 22,
                                        bold: true,

                                    }),
                                    new TextRun({
                                        text: `${unidad.numero_comunidades}`,
                                        font: 'Calibri (Cuerpo)',
                                        size: 22,

                                    }),
                                ],
                                spacing: { before: 100, after: 200 }
                            }),
                            new Paragraph({
                                children: [
                                    new TextRun({
                                        text: `Superficie: `,
                                        font: 'Calibri (Cuerpo)',
                                        size: 22,
                                        bold: true
                                    }),
                                    new TextRun({
                                        text: `${unidad.plan_organico_grupo_productores_superficie_total === "" || unidad.plan_organico_grupo_productores_superficie_total === "0" || unidad.plan_organico_grupo_productores_superficie_total === "0.00"? '    ❏' : '    ■'} Total: `,
                                        font: 'Calibri (Cuerpo)',
                                        size: 22,
                                    }),
                                    new TextRun({
                                        text: `${isNaN(parseFloat(unidad.plan_organico_grupo_productores_superficie_total)) ? '0.00' : parseFloat(unidad.plan_organico_grupo_productores_superficie_total).toFixed(2)}`,
                                        font: 'Calibri (Cuerpo)',
                                        size: 22,
                                    }),
                                    new TextRun({
                                        text: `${isNaN(parseFloat(unidad.plan_organico_grupo_productores_superficie_organica))? '    ❏' : '    ■'} Orgánica: `,
                                        font: 'Calibri (Cuerpo)',
                                        size: 22,
                                    }),
                                    new TextRun({
                                        text: `${isNaN(parseFloat(unidad.plan_organico_grupo_productores_superficie_organica)) ? '0.00' : parseFloat(unidad.plan_organico_grupo_productores_superficie_organica).toFixed(2)}`,
                                        font: 'Calibri (Cuerpo)',
                                        size: 22,
                                    }),
                                ],
                                spacing: { before: 100, after: 200 }
                            }),

                            new Paragraph({
                                children: [
                                    new TextRun({
                                        text: `${(parseFloat(unidad.plan_organico_grupo_productores_superficie_transicion) === 0 || parseFloat(unidad.plan_organico_grupo_productores_superficie_transicion) === 0.00 || isNaN(parseFloat(unidad.plan_organico_grupo_productores_superficie_transicion)))? '    ❏' : '    ■'} En transición: `,
                                        font: 'Calibri (Cuerpo)',
                                        size: 22,
                                    }),
                                    new TextRun({
                                        text: `${isNaN(parseFloat(unidad.plan_organico_grupo_productores_superficie_transicion)) ? '0.00' : parseFloat(unidad.plan_organico_grupo_productores_superficie_transicion).toFixed(2)} `,
                                        font: 'Calibri (Cuerpo)',
                                        size: 22,
                                    }),
                                    new TextRun({
                                        text: `${(parseFloat(unidad.plan_organico_grupo_productores_superficie_convecional) === 0 || parseFloat(unidad.plan_organico_grupo_productores_superficie_convecional) === 0.00 || isNaN(parseFloat(unidad.plan_organico_grupo_productores_superficie_convecional))) ? '    ❏' : '    ■'} Convencional: `,
                                        font: 'Calibri (Cuerpo)',
                                        size: 22,
                                    }),
                                    new TextRun({
                                        text: `${isNaN(parseFloat(unidad.plan_organico_grupo_productores_superficie_convecional)) ? '0.00' : parseFloat(unidad.plan_organico_grupo_productores_superficie_convecional).toFixed(2)}`,
                                        font: 'Calibri (Cuerpo)',
                                        size: 22,
                                    })
                                ],
                                spacing: { before: 100, after: 200 }
                            })
                        ],
                        verticalAlign: VerticalAlign.CENTER,
                        columnSpan: 3,
                    }),
                ],
            }))
        })
        return table
    }

    getValue(submission, name) {
        var value = null
        for (const i in submission) {
            const element = submission[i]
            if (element.name === name) {
                value = element.value
            }
        }
        if (value) return value
        else return ''
    }
    getValueList(submission, name) {
        var value = null
        for (const i in submission) {
            const element = submission[i]
            if (element.name === name) {
                value = element.value
            }
        }
        if (value) return value
        else return []
    }

    // END MTO_FSOL_08_4
}