import { AlignmentType, Document, Paragraph, TextRun, Media, Header, Footer, Table, TableRow, TableCell, VerticalAlign, WidthType, HeightRule, PageNumberFormat, PageNumber } from "docx";
import { logo } from "../logo";
import { userWithoutSign } from "../userWithoutSign"
import { DatePipe } from '@angular/common'

export class DocumentCreatorMTO_FSOL_01_v1 {

    datePipe = new DatePipe('es-MX');

    public getCambioOdeCValue(values, name) {
        for (var i in values) {
            const value = values[i]
            if (value.name === name) {
                return value.value
            }
        }
        return '_________________________________'
    }

    // tslint:disable-next-line: typedef
    public async create_MTO_FSOL_01(data, signature = '', signature2 = ''): Promise<Document> {
        const customer = data.customer
        const submission = data.submission
        const record = data.record
        const followup = data.followup
        const reviewer = data.reviewer
        let scopes = []
        for (var i in record.Scopes) {
            const scope = record.Scopes[i]
            scopes.push(scope.Name)
        }
        const document = new Document();
        const image = Media.addImage(document, logo, 50, 50);
        let signatureImage = null
        if (signature) {
            signatureImage = await this.getBase64FromUrl(signature)
        } else {
            signatureImage = userWithoutSign
        }

        let signatureImage2 = null
        if(signature2) {
            signatureImage2 = await this.getBase64FromUrl(signature2)
        } else {
            signatureImage2 = userWithoutSign
        }

        document.addSection({
            headers: {
                default: new Header({
                    children: [
                        new Paragraph({ alignment: AlignmentType.CENTER, children: [image], }),
                        new Paragraph({
                            alignment: AlignmentType.CENTER, children: [
                                new TextRun({
                                    font: 'Book Antiqua',
                                    size: 20,
                                    bold: true,
                                    children: [`MTO-FSOL-01| R 05 | 21-Julio-2020 | Pág. `, PageNumber.CURRENT]
                                }),
                                new TextRun({
                                    font: 'Book Antiqua',
                                    size: 20,
                                    bold: true,
                                    children: [' de ', PageNumber.TOTAL_PAGES]
                                }),
                            ], spacing: { before: 200, after: 300 }
                        })
                    ]
                })
            },
            footers: {
                default: new Footer({
                    children: [
                        new Paragraph({ alignment: AlignmentType.CENTER }),
                        this.createFooter()
                    ]
                }),
            },
            children: [
                new Paragraph({
                    alignment: AlignmentType.CENTER,
                    children: [
                        new TextRun({
                            text: 'SOLICITUD DE INSPECCIÓN Y CERTIFICACIÓN ORGÁNICA',
                            font: 'Calibri (Cuerpo)',
                            size: 22,
                            bold: true
                        })
                    ]
                }),
                new Paragraph({
                    alignment: AlignmentType.CENTER, children: [
                        new TextRun({
                            text: `Fecha: ${this.datePipe.transform(new Date(), 'd-MMMM-y')}          Código de operador (asignado por Metrocert): ${customer.Id_Comercial}`,
                            font: 'Arial',
                            size: 20
                        })
                    ]
                }),
                new Paragraph({
                    alignment: AlignmentType.CENTER,
                    children: [
                        new TextRun({
                            text: `${record.Type == 'PRIMERA_SOLICITUD' ? '■' : '❏'} Primera solicitud  ${record.Type == 'RENOVACION' ? '■' : '❏'} Renovación   ${record.Type == 'VARIACION' ? '■' : '❏'} Variación  ${record.Type == 'CAMBIO_ODEC' ? '■' : '❏'} Cambio de OdeC  ${record.Type == 'RECERTIFICACION' ? '■' : '❏'} Recertificación`,
                            font: 'Arial',
                            size: 20,
                            bold: true
                        })
                    ], spacing: { before: 100 }
                }),
                new Paragraph({
                    alignment: AlignmentType.LEFT,
                    children: [
                        new TextRun({
                            text: 'Ing. Mauricio Soberanes Hernández,',
                            font: 'Arial',
                            size: 20,
                            bold: true
                        })
                    ],
                    spacing: { before: 600 }
                }),
                new Paragraph({
                    alignment: AlignmentType.LEFT,
                    children: [
                        new TextRun({
                            text: 'Director general de METROCERT.',
                            font: 'Arial',
                            size: 20
                        })
                    ]
                }),
                new Paragraph({
                    alignment: AlignmentType.LEFT,
                    children: [
                        new TextRun({
                            text: 'Solicito de manera formal la inspección y certificación orgánica para la actividad cuyos datos a continuación se indican:',
                            font: 'Arial',
                            size: 20
                        })
                    ],
                    spacing: {
                        before: 200, after: 200
                    }
                }),
                this.createSectionDatosGeneralesOperador(customer, submission, record, followup),
                new Paragraph({
                    spacing: {
                        after: 400
                    }
                }),
                this.createSectionDatosGenerales(scopes),
                new Paragraph({
                    spacing: {
                        after: 400
                    }
                }),
                this.createProductsList(submission),
                new Paragraph({
                    spacing: {
                        after: 400
                    }
                }),
                this.createSpecificActivities(submission[1][1].value),
                new Paragraph({
                    spacing: {
                        after: 400
                    }
                }),
                this.createExceptions(submission[3]),
                new Paragraph({
                    spacing: {
                        after: 400
                    }
                }),
                new Paragraph({
                    alignment: AlignmentType.LEFT,
                    children: [
                        new TextRun({
                            text: 'IV. Información sobre el cambio de organismo de control ',
                            font: 'Arial',
                            size: 20,
                            bold: true
                        })
                    ],
                    spacing: { after: 200 }
                }),
                new Paragraph({
                    alignment: AlignmentType.LEFT,
                    children: [
                        new TextRun({
                            text: '1. ¿Con quién estuvo certificado anteriormente?',
                            font: 'Arial',
                            size: 20,
                        })
                    ]
                }),
                new Paragraph({
                    alignment: AlignmentType.LEFT,
                    children: [
                        new TextRun({
                            text: `${this.getCambioOdeCValue(submission[2], 'cambio_certificado_anterior')}`,
                            font: 'Arial',
                            size: 20,
                            bold: true
                        })
                    ],
                    spacing: { after: 200 }
                }),
                new Paragraph({
                    alignment: AlignmentType.LEFT,
                    children: [
                        new TextRun({
                            text: '2. ¿Cuál fue la fecha de vigencia del último certificado orgánico emitido por esta certificadora?',
                            font: 'Arial',
                            size: 20,
                        })
                    ]
                }),
                new Paragraph({
                    alignment: AlignmentType.LEFT,
                    children: [
                        new TextRun({
                            text: `${this.getCambioOdeCValue(submission[2], 'cambio_certificado_anterior_vigencia')}`,
                            font: 'Arial',
                            size: 20,
                            bold: true
                        })
                    ],
                    spacing: { after: 200 }
                }),
                new Paragraph({
                    alignment: AlignmentType.LEFT,
                    children: [
                        new TextRun({
                            text: '3. ¿Cuál es la razón del cambio de certificadora?',
                            font: 'Arial',
                            size: 20,
                        })
                    ]
                }),
                new Paragraph({
                    alignment: AlignmentType.LEFT,
                    children: [
                        new TextRun({
                            text: `${this.getCambioOdeCValue(submission[2], 'cambio_certificado_razon')}`,
                            font: 'Arial',
                            size: 20,
                            bold: true
                        })
                    ],
                    spacing: { after: 200 }
                }),
                new Paragraph({
                    alignment: AlignmentType.LEFT,
                    children: [
                        new TextRun({
                            text: '4. ¿Tiene usted No Conformidades abiertas con su anterior certificadora?',
                            font: 'Arial',
                            size: 20,
                        })
                    ]
                }),
                new Paragraph({
                    alignment: AlignmentType.LEFT,
                    children: [
                        new TextRun({
                            text: `${this.getCambioOdeCValue(submission[2], 'cambio_certificado_no_conformidades')}`,
                            font: 'Arial',
                            size: 20,
                            bold: true
                        })
                    ],
                    spacing: { after: 200 }
                }),
                new Paragraph({
                    alignment: AlignmentType.LEFT,
                    children: [
                        new TextRun({
                            text: '5. En caso de no haber tenido la certificación y solo haber hecho la solicitud, favor de indicar el nombre de la certificadora con quien hizo la solicitud:',
                            font: 'Arial',
                            size: 20,
                        })
                    ]
                }),
                new Paragraph({
                    alignment: AlignmentType.LEFT,
                    children: [
                        new TextRun({
                            text: `${this.getCambioOdeCValue(submission[2], 'cambio_certificado_nombre_certificador')}`,
                            font: 'Arial',
                            size: 20,
                            bold: true
                        })
                    ],
                    spacing: { after: 200 }
                }),
                new Paragraph({
                    alignment: AlignmentType.LEFT,
                    children: [
                        new TextRun({
                            text: '6. Favor de incluir copia del último Certificado y la Carta Liberatoria de parte de la certificadora anterior.',
                            font: 'Arial',
                            size: 20,
                        })
                    ]
                }),
                new Paragraph({
                    alignment: AlignmentType.LEFT,
                    children: [
                        new TextRun({
                            text: 'V.	Documentos adicionales que deberá de anexar a la solicitud en versión digital ',
                            font: 'Arial',
                            size: 20,
                            bold: true
                        })
                    ],
                    spacing: { after: 200, before: 400 }
                }),
                this.createAditionalDocumentsList(),
                new Paragraph({
                    spacing: {
                        after: 200
                    }
                }),
                new Paragraph({
                    alignment: AlignmentType.LEFT,
                    children: [
                        new TextRun({
                            text: 'Atentamente:',
                            font: 'Arial',
                            size: 20,
                        })
                    ],
                    spacing: {
                        after: 100
                    }
                }),
                new Paragraph({
                    alignment: AlignmentType.CENTER,
                    children: [
                        signatureImage ? Media.addImage(document, signatureImage.toString(), 200, 100) : new TextRun({
                            text: ''
                        })
                    ]
                }),
                new Paragraph({
                    alignment: AlignmentType.LEFT,
                    children: [
                        new TextRun({
                            text: `Fecha: ${this.datePipe.transform(new Date(record.Applications[0].Signature_Date), 'd-MMMM-y')}                  Nombre y firma del operador: ${customer.Legal_Authorities[0].Name} ${customer.Legal_Authorities[0].Middle_Name} ${customer.Legal_Authorities[0].Last_Name ? customer.Legal_Authorities[0].Last_Name : ''}`,
                            font: 'Arial',
                            size: 20,
                            italics: true
                        })
                    ],
                    spacing: {
                        after: 200
                    }
                }),
                new Paragraph({
                    alignment: AlignmentType.LEFT,
                    children: [
                        new TextRun({
                            text: `Fecha en que se completó el expediente: ${this.datePipe.transform(new Date(record.Applications[0].Validation_Date), 'd-MMMM-y')}`,
                            font: 'Arial',
                            size: 20
                        })
                    ],
                    spacing: {
                        after: 200
                    }
                }),
                new Paragraph({
                    alignment: AlignmentType.LEFT,
                    children: [
                        new TextRun({
                            text: 'Revisión por Metrocert:',
                            font: 'Arial',
                            size: 20
                        })
                    ],
                    spacing: {
                        after: 100
                    }
                }),
                new Paragraph({
                    alignment: AlignmentType.LEFT,
                    children: [
                        signatureImage2 ? Media.addImage(document, signatureImage2.toString(), 200, 100) : new TextRun({
                            text: ''
                        })
                    ]
                }),
                new Paragraph({
                    alignment: AlignmentType.LEFT,
                    children: [
                        new TextRun({
                            text: `Fecha: ${reviewer && reviewer.date ? this.datePipe.transform(new Date(reviewer.date), 'd-MMMM-y') : ''}                  Nombre y firma del revisor: ${reviewer && reviewer.name ? reviewer.name : ''}`,
                            font: 'Arial',
                            size: 20,
                            italics: true
                        })
                    ],
                    spacing: {
                        after: 200
                    }
                }),
            ],
            margins: {
                top: 350,
                bottom: 500,
                left: 1000,
                right: 1000
            },
            properties: {
                pageNumberStart: 1,
                pageNumberFormatType: PageNumberFormat.DECIMAL
            }
        });

        return document;
    }

    public createSectionDatosGeneralesOperador(customer, submission, record, followup): Table {
        const table = new Table({
            rows: [
                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: '1.	DATOS GENERALES DEL OPERADOR.',
                                            font: 'Arial',
                                            size: 20,
                                            bold: true
                                        })
                                    ],
                                    spacing: {
                                        after: 75
                                    }
                                })
                            ],
                            verticalAlign: VerticalAlign.CENTER,
                            columnSpan: 3,
                            shading: {
                                fill: '#C0C0C0'
                            }
                        })
                    ]
                }),
                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: 'Nombre del titular (persona física) o razón social (persona moral): ',
                                            font: 'Arial',
                                            size: 20
                                        }),
                                        new TextRun({
                                            text: customer.Company_Name,
                                            font: 'Arial',
                                            italics: true,
                                            size: 20
                                        })
                                    ]
                                })
                            ],
                            width: {
                                size: 33,
                                type: WidthType.PERCENTAGE
                            }
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: 'Nombre del representante legal: ',
                                            font: 'Arial',
                                            size: 20
                                        }),
                                        new TextRun({
                                            text: `${customer.Legal_Authorities[0].Name} ${customer.Legal_Authorities[0].Middle_Name} ${customer.Legal_Authorities[0].Last_Name ? customer.Legal_Authorities[0].Last_Name : ''}`,
                                            font: 'Arial',
                                            size: 20,
                                            italics: true
                                        })
                                    ]
                                })
                            ],
                            width: {
                                size: 33,
                                type: WidthType.PERCENTAGE
                            }
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: `RFC: ${customer.Legal_Authorities[0].RFC}`,
                                            font: 'Arial',
                                            size: 20
                                        })
                                    ],
                                    spacing: { after: 250 }
                                }),
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: `CURP (Opcional): ${customer.Legal_Authorities[0].CURP}`,
                                            font: 'Arial',
                                            size: 20
                                        })
                                    ],
                                    spacing: { after: 250 }
                                })
                            ],
                            width: {
                                size: 33,
                                type: WidthType.PERCENTAGE
                            }
                        })
                    ],
                    height: {
                        height: 1200,
                        rule: HeightRule.EXACT
                    }
                }),
                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: 'Persona responsable del seguimiento a la certificación: ',
                                            font: 'Arial',
                                            size: 20
                                        }),
                                        new TextRun({
                                            text: `${followup.Name}`,
                                            font: 'Arial',
                                            size: 20,
                                            italics: true
                                        })
                                    ]
                                })
                            ]
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: 'Tel: ',
                                            font: 'Arial',
                                            size: 20
                                        }),
                                        new TextRun({
                                            text: `${followup.Phone_Number}`,
                                            font: 'Arial',
                                            size: 20,
                                            italics: true
                                        })
                                    ]
                                })
                            ]
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: "Correo electrónico: ",
                                            font: 'Arial',
                                            size: 20
                                        }),
                                        new TextRun({
                                            text: `${followup.Email}`,
                                            font: 'Arial',
                                            size: 20,
                                            italics: true
                                        })
                                    ],
                                    spacing: { after: 250 }
                                })
                            ]
                        })
                    ],
                    height: {
                        height: 900,
                        rule: HeightRule.EXACT
                    }
                }),
                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: 'Tipo de operador: ',
                                            font: 'Arial',
                                            size: 20
                                        })
                                    ]
                                }),
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: `${record.Operator_Type == 'INDIVIDUAL' ? '■' : '❏'} Individual          ${record.Operator_Type == 'EMPRESA' ? '■' : '❏'} Empresa          ${record.Operator_Type == 'GRUPO' ? '■' : '❏'} Grupo de Productores         `,
                                            font: 'Arial',
                                            size: 20
                                        })
                                    ]
                                })
                            ],
                            columnSpan: 3
                        })
                    ]
                }),
                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: 'Destino del producto certificado:',
                                            font: 'Arial',
                                            size: 20
                                        })
                                    ]
                                }),
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: `${record.Destiny == 'MEXICO' || record.Destiny == 'MEXICO_CANADA' || record.Destiny == 'MEXICO_CANADA_USA' ? '■' : '❏'} México       ${record.Destiny == 'CANADA' || record.Destiny == 'CANADA_USA' || record.Destiny == 'MEXICO_CANADA' || record.Destiny == 'MEXICO_CANADA_USA' ? '■' : '❏'}  Canadá        ${record.Destiny == 'CANADA_USA' || record.Destiny == 'MEXICO_CANADA_USA' ? '■' : '❏'}  Estados Unidos     ❏ Otro (Especificar):`,
                                            font: 'Arial',
                                            size: 20
                                        })
                                    ]
                                })
                            ],
                            columnSpan: 3
                        })
                    ]
                }),
                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: 'Domicilio Fiscal.',
                                            font: 'Arial',
                                            size: 20
                                        })
                                    ],
                                    spacing: {
                                        after: 75
                                    }
                                })
                            ],
                            verticalAlign: VerticalAlign.CENTER,
                            columnSpan: 3,
                            shading: {
                                fill: '#C0C0C0'
                            }
                        })
                    ]
                }),
                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: 'Calle, No. exterior e interior:  ',
                                            font: 'Arial',
                                            size: 20
                                        }),
                                        new TextRun({
                                            text: `${customer.Address_2}` + (customer.Address_Number_Ext !== "" ? " No. Ext " + customer.Address_Number_Ext : "") + (customer.Address_Number_Int !== "" ? " No. Int " + customer.Address_Number_Int : ""),
                                            font: 'Arial',
                                            italics: true,
                                            size: 20
                                        })
                                    ]
                                })
                            ],
                            width: {
                                size: 33,
                                type: WidthType.PERCENTAGE
                            }
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: 'Colonia: ',
                                            font: 'Arial',
                                            size: 20
                                        }),
                                        new TextRun({
                                            text: `${customer.Address_1}`,
                                            font: 'Arial',
                                            italics: true,
                                            size: 20
                                        })
                                    ]
                                }),
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: '',
                                            font: 'Arial',
                                            size: 20
                                        })
                                    ]
                                })
                            ],
                            width: {
                                size: 33,
                                type: WidthType.PERCENTAGE
                            }
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: "Delegación / Municipio: ",
                                            font: 'Arial',
                                            size: 20
                                        }),
                                        new TextRun({
                                            text: `${customer.Town.split("/")[1]}`,
                                            font: 'Arial',
                                            italics: true,
                                            size: 20
                                        })
                                    ],
                                    spacing: { after: 250 }
                                })
                            ],
                            width: {
                                size: 33,
                                type: WidthType.PERCENTAGE
                            }
                        })
                    ]
                }),
                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: 'Localidad: ',
                                            font: 'Arial',
                                            size: 20
                                        }),
                                        new TextRun({
                                            text: `${customer.City}`,
                                            font: 'Arial',
                                            italics: true,
                                            size: 20
                                        })
                                    ]
                                })
                            ],
                            width: {
                                size: 33,
                                type: WidthType.PERCENTAGE
                            }
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: 'C.P.: ',
                                            font: 'Arial',
                                            size: 20
                                        }),
                                        new TextRun({
                                            text: `${customer.Zip_Code}`,
                                            font: 'Arial',
                                            italics: true,
                                            size: 20
                                        })
                                    ]
                                }),
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: '',
                                            font: 'Arial',
                                            size: 20
                                        })
                                    ]
                                })
                            ],
                            width: {
                                size: 33,
                                type: WidthType.PERCENTAGE
                            }
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: "Entidad Federativa: ",
                                            font: 'Arial',
                                            size: 20
                                        }),
                                        new TextRun({
                                            text: `${customer.State.split("/")[1]}`,
                                            font: 'Arial',
                                            italics: true,
                                            size: 20
                                        })
                                    ],
                                    spacing: { after: 250 }
                                })
                            ],
                            width: {
                                size: 33,
                                type: WidthType.PERCENTAGE
                            }
                        })
                    ]
                }),
                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: 'Teléfono, fax: ',
                                            font: 'Arial',
                                            size: 20
                                        }),
                                        new TextRun({
                                            text: `${customer.Phone_Number}`,
                                            font: 'Arial',
                                            italics: true,
                                            size: 20
                                        })
                                    ]
                                })
                            ],
                            width: {
                                size: 33,
                                type: WidthType.PERCENTAGE
                            }
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: 'Email: ',
                                            font: 'Arial',
                                            size: 20
                                        }),
                                        new TextRun({
                                            text: `${customer.Email}`,
                                            font: 'Arial',
                                            size: 20
                                        })
                                    ]
                                })
                            ],
                            width: {
                                size: 66,
                                type: WidthType.PERCENTAGE
                            },
                            columnSpan: 2
                        }),
                    ]
                }),
                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: 'Domicilio físico de la operación (lugar donde se llevará a cabo la inspección).',
                                            font: 'Arial',
                                            size: 20
                                        })
                                    ],
                                    spacing: {
                                        after: 75
                                    }
                                })
                            ],
                            verticalAlign: VerticalAlign.CENTER,
                            columnSpan: 3,
                            shading: {
                                fill: '#C0C0C0'
                            }
                        })
                    ]
                })
            ],
            columnWidths: [3213, 3213, 3212]
        })
        for (var i in submission[0]) {
            const s = submission[0][i]
            if (s.name == 'unidades_produccion') {
                const values = s.value
                for (var j in values) {
                    const unidad = values[j]
                    table.addChildElement(new TableRow({
                        children: [
                            new TableCell({
                                children: [
                                    new Paragraph({
                                        alignment: AlignmentType.LEFT,
                                        children: [
                                            new TextRun({
                                                text: 'Nombre de la Unidad de Producción: ',
                                                font: 'Arial',
                                                size: 20
                                            })
                                        ]
                                    }),
                                    new Paragraph({
                                        alignment: AlignmentType.LEFT,
                                        children: [
                                            new TextRun({
                                                text: `${unidad.nombre_unidad_produccion}`,
                                                font: 'Arial',
                                                italics: true,
                                                size: 20
                                            })
                                        ]
                                    })
                                ],
                                columnSpan: 3
                            })
                        ]
                    }))
                    table.addChildElement(new TableRow({
                        children: [
                            new TableCell({
                                children: [
                                    new Paragraph({
                                        alignment: AlignmentType.LEFT,
                                        children: [
                                            new TextRun({
                                                text: 'Calle, No. exterior e interior: ',
                                                font: 'Arial',
                                                size: 20
                                            })
                                        ]
                                    }),
                                    new Paragraph({
                                        alignment: AlignmentType.LEFT,
                                        children: [
                                            new TextRun({
                                                text: `${unidad.domicilio_calle}`,
                                                font: 'Arial',
                                                italics: true,
                                                size: 20
                                            })
                                        ]
                                    }),
                                ],
                                width: {
                                    size: 33,
                                    type: WidthType.PERCENTAGE
                                }
                            }),
                            new TableCell({
                                children: [
                                    new Paragraph({
                                        alignment: AlignmentType.LEFT,
                                        children: [
                                            new TextRun({
                                                text: 'Colonia: ',
                                                font: 'Arial',
                                                size: 20
                                            })
                                        ]
                                    }),
                                    new Paragraph({
                                        alignment: AlignmentType.LEFT,
                                        children: [
                                            new TextRun({
                                                text: `${unidad.domicilio_colonia}`,
                                                font: 'Arial',
                                                italics: true,
                                                size: 20
                                            })
                                        ]
                                    }),
                                ],
                                width: {
                                    size: 33,
                                    type: WidthType.PERCENTAGE
                                }
                            }),
                            new TableCell({
                                children: [
                                    new Paragraph({
                                        alignment: AlignmentType.LEFT,
                                        children: [
                                            new TextRun({
                                                text: "Delegación / Municipio: ",
                                                font: 'Arial',
                                                size: 20
                                            })
                                        ]
                                    }),
                                    new Paragraph({
                                        alignment: AlignmentType.LEFT,
                                        children: [
                                            new TextRun({
                                                text: `${unidad.domicilio_municipio}`,
                                                font: 'Arial',
                                                italics: true,
                                                size: 20
                                            })
                                        ]
                                    }),
                                ],
                                width: {
                                    size: 33,
                                    type: WidthType.PERCENTAGE
                                }
                            })
                        ]
                    }))
                    table.addChildElement(new TableRow({
                        children: [
                            new TableCell({
                                children: [
                                    new Paragraph({
                                        alignment: AlignmentType.LEFT,
                                        children: [
                                            new TextRun({
                                                text: 'Localidad: ',
                                                font: 'Arial',
                                                size: 20
                                            })
                                        ]
                                    }),
                                    new Paragraph({
                                        alignment: AlignmentType.LEFT,
                                        children: [
                                            new TextRun({
                                                text: `${unidad.domicilio_localidad}`,
                                                font: 'Arial',
                                                italics: true,
                                                size: 20
                                            })
                                        ]
                                    }),
                                ],
                                width: {
                                    size: 33,
                                    type: WidthType.PERCENTAGE
                                }
                            }),
                            new TableCell({
                                children: [
                                    new Paragraph({
                                        alignment: AlignmentType.LEFT,
                                        children: [
                                            new TextRun({
                                                text: 'C.P.: ',
                                                font: 'Arial',
                                                size: 20
                                            })
                                        ]
                                    }),
                                    new Paragraph({
                                        alignment: AlignmentType.LEFT,
                                        children: [
                                            new TextRun({
                                                text: `${unidad.domicilio_cp}`,
                                                font: 'Arial',
                                                italics: true,
                                                size: 20
                                            })
                                        ]
                                    }),
                                ],
                                width: {
                                    size: 33,
                                    type: WidthType.PERCENTAGE
                                }
                            }),
                            new TableCell({
                                children: [
                                    new Paragraph({
                                        alignment: AlignmentType.LEFT,
                                        children: [
                                            new TextRun({
                                                text: "Entidad Federativa: ",
                                                font: 'Arial',
                                                size: 20
                                            })
                                        ],
                                    }),
                                    new Paragraph({
                                        alignment: AlignmentType.LEFT,
                                        children: [
                                            new TextRun({
                                                text: `${unidad.domicilio_estado}`,
                                                font: 'Arial',
                                                italics: true,
                                                size: 20
                                            })
                                        ],
                                        spacing: { after: 250 }
                                    }),
                                ],
                                width: {
                                    size: 33,
                                    type: WidthType.PERCENTAGE
                                }
                            })
                        ]
                    }))
                }
            }
        }
        return table
    }

    public createSectionDatosGenerales(scopes): Table {
        const table = new Table({
            rows: [
                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: '2.	DATOS GENERALES (Alcance: Producto y Proceso a certificar).',
                                            font: 'Arial',
                                            size: 20,
                                            bold: true
                                        })
                                    ],
                                    spacing: {
                                        after: 75
                                    }
                                }),
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: 'Nota: En caso de solicitar una extensión, marque con azul los nuevos alcances. En caso de solicitar una reducción, maque con rojos los alcances de baja.',
                                            font: 'Arial',
                                            size: 20
                                        })
                                    ],
                                    spacing: {
                                        after: 75
                                    }
                                })
                            ],
                            verticalAlign: VerticalAlign.CENTER,
                            columnSpan: 3,
                            shading: {
                                fill: '#C0C0C0'
                            }
                        })
                    ]
                }),
                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: '             Categoría de certificación LPO ',
                                            font: 'Arial',
                                            size: 20,
                                            bold: true
                                        })
                                    ]
                                }),
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: `${scopes.includes('LPO_PRODUCCION_VEGETAL') ? '■' : '❏'} Producción vegetal`,
                                            font: 'Arial',
                                            size: 20
                                        })
                                    ]
                                }),
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: `${scopes.includes('LPO_PROCESAMIENTO_PRODUCTOS_AGROPECUARIOS') ? '■' : '❏'} Procesamiento de productos de las actividades agropecuarias`,
                                            font: 'Arial',
                                            size: 20
                                        })
                                    ]
                                }),
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: `${scopes.includes('LPO_COMERCIALIZACION_PRODUCTOS_AGROPECUARIOS') ? '■' : '❏'} Comercialización de productos de las actividades agropecuarias`,
                                            font: 'Arial',
                                            size: 20
                                        })
                                    ]
                                }),
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: `${scopes.includes('LPO_PRODUCCION_ANIMAL_DOMESTICOS') ? '■' : '❏'} Producción animal (domésticos)`,
                                            font: 'Arial',
                                            size: 20
                                        })
                                    ]
                                }),
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: `${scopes.includes('LPO_PRODUCCION_ANIMAL_ECOSISTEMAS') ? '■' : '❏'} Producción animal de ecosistemas naturales o no domésticos`,
                                            font: 'Arial',
                                            size: 20
                                        })
                                    ]
                                }),
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: `${scopes.includes('LPO_PRODUCTOS_FUNGI') ? '■' : '❏'}  Productos de la clase fungi`,
                                            font: 'Arial',
                                            size: 20
                                        })
                                    ]
                                }),
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: `${scopes.includes('LPO_PRODUCTOS_RECOLECCION') ? '■' : '❏'} Productos vegetales de recolección silvestre`,
                                            font: 'Arial',
                                            size: 20
                                        })
                                    ]
                                })
                            ],
                            columnSpan: 3
                        })
                    ]
                }),
                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: '             Categoría de certificación OPR (Canadá) ',
                                            font: 'Arial',
                                            size: 20,
                                            bold: true
                                        })
                                    ]
                                }),
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: `${scopes.includes('OPR_PLANTAS_NO_PROCESADOS') ? '■' : '❏'} Producción agrícola`,
                                            font: 'Arial',
                                            size: 20
                                        })
                                    ]
                                }),
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: `${scopes.includes('OPR_ALIMENTO_GANADO') ? '■' : '❏'} Producción de alimentos para el ganado`,
                                            font: 'Arial',
                                            size: 20
                                        })
                                    ]
                                }),
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: `${scopes.includes('OPR_PRODUCTOS_AGRICOLAS_GANADEROS_TRANSFORMADOS') ? '■' : '❏'} Productos procesados`,
                                            font: 'Arial',
                                            size: 20
                                        })
                                    ]
                                }),
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: `${scopes.includes('OPR_PRODUCTOS_AGRICOLAS_GANADEROS_TRANSFORMADOS_ALIMENTACION') ? '■' : '❏'} Producción especializada (invernaderos, miel de abeja, recolección, germinados)`,
                                            font: 'Arial',
                                            size: 20
                                        })
                                    ]
                                })
                            ],
                            columnSpan: 3
                        })
                    ]
                }),
            ],
            margins: {
                left: 70,
                right: 70
            },
            columnWidths: [3213, 3213, 3212]
        })
        return table
    }

    public createProductsList(submission): Table {
        const products = submission[1][0].value
        const table = new Table({
            rows: [
                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: 'Lista de productos:',
                                            font: 'Arial',
                                            size: 20,
                                            bold: true
                                        })
                                    ],
                                    spacing: {
                                        after: 75
                                    }
                                })
                            ],
                            verticalAlign: VerticalAlign.CENTER,
                            columnSpan: 6,
                            shading: {
                                fill: '#C0C0C0'
                            }
                        })
                    ]
                }),
                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: 'No.',
                                            font: 'Arial',
                                            size: 20
                                        })
                                    ]
                                })
                            ],
                            verticalAlign: VerticalAlign.CENTER,
                            shading: {
                                fill: '#C0C0C0'
                            },
                            width: {
                                size: 5,
                                type: WidthType.PERCENTAGE
                            }
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: 'Producto',
                                            font: 'Arial',
                                            size: 20
                                        })
                                    ]
                                })
                            ],
                            verticalAlign: VerticalAlign.CENTER,
                            shading: {
                                fill: '#C0C0C0'
                            },
                            width: {
                                size: 19,
                                type: WidthType.PERCENTAGE
                            }
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: 'Variedad / Marca Comercial / Especie y Raza',
                                            font: 'Arial',
                                            size: 20
                                        })
                                    ]
                                })
                            ],
                            verticalAlign: VerticalAlign.CENTER,
                            shading: {
                                fill: '#C0C0C0'
                            },
                            width: {
                                size: 19,
                                type: WidthType.PERCENTAGE
                            }
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: 'Hectáreas / No. de Cabezas / Apiarios ',
                                            font: 'Arial',
                                            size: 20
                                        })
                                    ]
                                })
                            ],
                            verticalAlign: VerticalAlign.CENTER,
                            shading: {
                                fill: '#C0C0C0'
                            },
                            width: {
                                size: 19,
                                type: WidthType.PERCENTAGE
                            }
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: 'Producción Anual (Kg, Lt, Pza) ',
                                            font: 'Arial',
                                            size: 20
                                        })
                                    ]
                                })
                            ],
                            verticalAlign: VerticalAlign.CENTER,
                            shading: {
                                fill: '#C0C0C0'
                            },
                            width: {
                                size: 19,
                                type: WidthType.PERCENTAGE
                            }
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: 'Certificación',
                                            font: 'Arial',
                                            size: 20
                                        })
                                    ]
                                })
                            ],
                            verticalAlign: VerticalAlign.CENTER,
                            shading: {
                                fill: '#C0C0C0'
                            },
                            width: {
                                size: 19,
                                type: WidthType.PERCENTAGE
                            }
                        })
                    ]
                })
            ],
            margins: {
                left: 70,
                right: 70
            },
            columnWidths: [1606, 1606, 1607, 1606, 1606, 1607] // Debe sumar 9638
        })
        for (var i in products) {
            const product = products[i]
            table.addChildElement(new TableRow({
                children: [
                    new TableCell({
                        children: [
                            new Paragraph({
                                alignment: AlignmentType.LEFT,
                                children: [
                                    new TextRun({
                                        text: `${parseInt(i) + 1}`,
                                        font: 'Arial',
                                        size: 20,
                                        italics: true
                                    })
                                ]
                            })
                        ],
                        verticalAlign: VerticalAlign.TOP
                    }),
                    new TableCell({
                        children: [
                            new Paragraph({
                                alignment: AlignmentType.LEFT,
                                children: [
                                    new TextRun({
                                        text: `${product.lista_productos_producto}`,
                                        font: 'Arial',
                                        size: 20,
                                        italics: true
                                    })
                                ]
                            })
                        ],
                        verticalAlign: VerticalAlign.TOP
                    }),
                    new TableCell({
                        children: [
                            new Paragraph({
                                alignment: AlignmentType.LEFT,
                                children: [
                                    new TextRun({
                                        text: `${product.lista_productos_variedad}`,
                                        font: 'Arial',
                                        size: 20,
                                        italics: true
                                    })
                                ]
                            })
                        ],
                        verticalAlign: VerticalAlign.TOP
                    }),
                    new TableCell({
                        children: [
                            new Paragraph({
                                alignment: AlignmentType.LEFT,
                                children: [
                                    new TextRun({
                                        text: `${product.lista_productos_hectareas}`,
                                        font: 'Arial',
                                        size: 20,
                                        italics: true
                                    })
                                ]
                            })
                        ],
                        verticalAlign: VerticalAlign.TOP
                    }),
                    new TableCell({
                        children: [
                            new Paragraph({
                                alignment: AlignmentType.LEFT,
                                children: [
                                    new TextRun({
                                        text: `${product.lista_productos_produccion_anual}`,
                                        font: 'Arial',
                                        size: 20,
                                        italics: true
                                    })
                                ]
                            })
                        ],
                        verticalAlign: VerticalAlign.TOP
                    }),
                    new TableCell({
                        children: [
                            new Paragraph({
                                alignment: AlignmentType.LEFT,
                                children: [
                                    new TextRun({
                                        text: `${product.lista_productos_lpo ? '■' : '❏'} LPO`,
                                        font: 'Arial',
                                        size: 20
                                    })
                                ]
                            }),
                            new Paragraph({
                                alignment: AlignmentType.LEFT,
                                children: [
                                    new TextRun({
                                        text: `${product.lista_productos_opr ? '■' : '❏'} OPR`,
                                        font: 'Arial',
                                        size: 20
                                    })
                                ]
                            }),
                            new Paragraph({
                                alignment: AlignmentType.LEFT,
                                children: [
                                    new TextRun({
                                        text: `${product.lista_productos_opr_nop ? '■' : '❏'} OPR/NOP`,
                                        font: 'Arial',
                                        size: 20
                                    })
                                ]
                            })
                        ],
                        verticalAlign: VerticalAlign.TOP
                    })
                ]
            }))
        }
        return table
    }

    public isInActivities(activities, type): {type: string, index: number} {
        /* for (var i in activities) {
            const activity = activities[i]
            if (activity.type === type) {
                return activity
            }
        } */
        if (activities.type === type) {
            return activities
        }
        return null
    }

    public isInActivitiesOther(activities, type) {
        /* for (var i in activities) {
            const activity = activities[i]
            if (activity.type === type) {
                return activity.typeOther
            }
        } */
        if (activities.type === type) {
            return activities.typeOther
        }
        return ""
    }

    public createSpecificActivities(activities): Table {
        let activities_list = []
        activities.forEach((activity: any, index: number) => {
            activities_list.push({
                type: activity.actividades_tipo,
                typeOther: activity.actividades_tipo_otro,
                index
            })
        })
        const table = new Table({
            rows: [
                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: 'Actividades específicas realizadas por el operador ',
                                            font: 'Arial',
                                            size: 20,
                                            bold: true
                                        })
                                    ],
                                    spacing: {
                                        after: 75
                                    }
                                }),
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: 'Por favor indique los procesos de su sistema de producción que son desarrollados ',
                                            font: 'Arial',
                                            size: 20
                                        })
                                    ],
                                    spacing: {
                                        after: 75
                                    }
                                })
                            ],
                            verticalAlign: VerticalAlign.CENTER,
                            columnSpan: 6,
                            shading: {
                                fill: '#C0C0C0'
                            }
                        })
                    ]
                }),
                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: 'Proceso',
                                            font: 'Arial',
                                            size: 18,
                                            bold: true
                                        })
                                    ]
                                })
                            ],
                            verticalAlign: VerticalAlign.TOP,
                            width: {
                                size: 25,
                                type: WidthType.PERCENTAGE
                            }
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: 'Proceso realizado dentro (Interno) o fuera  (Externo) de la unidad de producción',
                                            font: 'Arial',
                                            size: 18,
                                            bold: true
                                        })
                                    ]
                                })
                            ],
                            verticalAlign: VerticalAlign.TOP,
                            width: {
                                size: 15,
                                type: WidthType.PERCENTAGE
                            }
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: 'Operador que lo desarrolla',
                                            font: 'Arial',
                                            size: 18,
                                            bold: true
                                        })
                                    ]
                                })
                            ],
                            verticalAlign: VerticalAlign.TOP,
                            width: {
                                size: 15,
                                type: WidthType.PERCENTAGE
                            }
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: '¿Operador certificado?',
                                            font: 'Arial',
                                            size: 18,
                                            bold: true
                                        })
                                    ]
                                })
                            ],
                            verticalAlign: VerticalAlign.TOP,
                            width: {
                                size: 15,
                                type: WidthType.PERCENTAGE
                            }
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: 'Código',
                                            font: 'Arial',
                                            size: 18,
                                            bold: true
                                        })
                                    ]
                                })
                            ],
                            verticalAlign: VerticalAlign.TOP,
                            width: {
                                size: 15,
                                type: WidthType.PERCENTAGE
                            }
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: 'Ubicación del operador (Dirección)',
                                            font: 'Arial',
                                            size: 18,
                                            bold: true
                                        })
                                    ]
                                })
                            ],
                            verticalAlign: VerticalAlign.TOP,
                            width: {
                                size: 15,
                                type: WidthType.PERCENTAGE
                            }
                        }),
                    ]
                })
            ],
            margins: {
                left: 70,
                right: 70
            },
            columnWidths: [2006, 1606, 1607, 1606, 1206, 1607]
        })

        activities_list.forEach((activities_list, i) => {
            if(i === 0 || this.isInActivities(activities_list, 'Semillero o Vivero')) {
                table.addChildElement(
                    new TableRow({
                        children: [
                            new TableCell({
                                children: [
                                    new Paragraph({
                                        alignment: AlignmentType.LEFT,
                                        children: [
                                            new TextRun({
                                                text: `${this.isInActivities(activities_list, 'Semillero o Vivero') ? '■' : '❏'} Semillero o Vivero`,
                                                font: 'Arial',
                                                size: 18
                                            })
                                        ]
                                    })
                                ],
                                verticalAlign: VerticalAlign.TOP
                            }),
                            new TableCell({
                                children: [
                                    new Paragraph({
                                        alignment: AlignmentType.LEFT,
                                        children: [
                                            new TextRun({
                                                text: `${this.isInActivities(activities_list, 'Semillero o Vivero') ? activities[this.isInActivities(activities_list, 'Semillero o Vivero').index].actividades_interno_externo === "Interno" ? '■' : '❏' : '❏' } Int.  ${this.isInActivities(activities_list, 'Semillero o Vivero') ? activities[this.isInActivities(activities_list, 'Semillero o Vivero').index].actividades_interno_externo === "Externo" ? '■' : '❏' : '❏' } Ext.`,
                                                font: 'Arial',
                                                size: 18
                                            })
                                        ]
                                    })
                                ],
                                verticalAlign: VerticalAlign.TOP
                            }),
                            new TableCell({
                                children: [
                                    new Paragraph({
                                        alignment: AlignmentType.LEFT,
                                        children: [
                                            new TextRun({
                                                text: `${this.isInActivities(activities_list, 'Semillero o Vivero') ? activities[this.isInActivities(activities_list, 'Semillero o Vivero').index].activades_operador_que_desarrolla : '' }`,
                                                font: 'Arial',
                                                size: 18
                                            })
                                        ]
                                    })
                                ],
                                verticalAlign: VerticalAlign.TOP
                            }),
                            new TableCell({
                                children: [
                                    new Paragraph({
                                        alignment: AlignmentType.LEFT,
                                        children: [
                                            new TextRun({
                                                text: `${this.isInActivities(activities_list, 'Semillero o Vivero') ? activities[this.isInActivities(activities_list, 'Semillero o Vivero').index].actividades_certificado ? '■' : '❏' : '❏' } Si ${this.isInActivities(activities_list, 'Semillero o Vivero') ? activities[this.isInActivities(activities_list, 'Semillero o Vivero').index].actividades_no_certificado ? '■' : '❏' : '❏' } No`,
                                                font: 'Arial',
                                                size: 18
                                            })
                                        ]
                                    })
                                ],
                                verticalAlign: VerticalAlign.TOP
                            }),
                            new TableCell({
                                children: [
                                    new Paragraph({
                                        alignment: AlignmentType.LEFT,
                                        children: [
                                            new TextRun({
                                                text: `${this.isInActivities(activities_list, 'Semillero o Vivero') ? activities[this.isInActivities(activities_list, 'Semillero o Vivero').index].activades_codigo : '' }`,
                                                font: 'Arial',
                                                size: 18
                                            })
                                        ]
                                    })
                                ],
                                verticalAlign: VerticalAlign.TOP
                            }),
                            new TableCell({
                                children: [
                                    new Paragraph({
                                        alignment: AlignmentType.LEFT,
                                        children: [
                                            new TextRun({
                                                text: `${this.isInActivities(activities_list, 'Semillero o Vivero') ? activities[this.isInActivities(activities_list, 'Semillero o Vivero').index].activades_ubicacion : '' }`,
                                                font: 'Arial',
                                                size: 18
                                            })
                                        ]
                                    })
                                ],
                                verticalAlign: VerticalAlign.TOP
                            })
                        ]
                    })
                )
            }
        })

        activities_list.forEach((activities_list, i) => {
            if(i === 0 || this.isInActivities(activities_list, 'Cosecha/Corte')) {
                table.addChildElement(
                    new TableRow({
                        children: [
                            new TableCell({
                                children: [
                                    new Paragraph({
                                        alignment: AlignmentType.LEFT,
                                        children: [
                                            new TextRun({
                                                text: `${this.isInActivities(activities_list, 'Cosecha/Corte') ? '■' : '❏'} Cosecha/Corte`,
                                                font: 'Arial',
                                                size: 18
                                            })
                                        ]
                                    })
                                ],
                                verticalAlign: VerticalAlign.TOP
                            }),
                            new TableCell({
                                children: [
                                    new Paragraph({
                                        alignment: AlignmentType.LEFT,
                                        children: [
                                            new TextRun({
                                                text: `${this.isInActivities(activities_list, 'Cosecha/Corte') ? activities[this.isInActivities(activities_list, 'Cosecha/Corte').index].actividades_interno_externo === "Interno" ? '■' : '❏' : '❏' } Int.  ${this.isInActivities(activities_list, 'Cosecha/Corte') ? activities[this.isInActivities(activities_list, 'Cosecha/Corte').index].actividades_interno_externo === "Externo" ? '■' : '❏' : '❏' } Ext.`,
                                                font: 'Arial',
                                                size: 18
                                            })
                                        ]
                                    })
                                ],
                                verticalAlign: VerticalAlign.TOP
                            }),
                            new TableCell({
                                children: [
                                    new Paragraph({
                                        alignment: AlignmentType.LEFT,
                                        children: [
                                            new TextRun({
                                                text: `${this.isInActivities(activities_list, 'Cosecha/Corte') ? activities[this.isInActivities(activities_list, 'Cosecha/Corte').index].activades_operador_que_desarrolla : '' }`,
                                                font: 'Arial',
                                                size: 18
                                            })
                                        ]
                                    })
                                ],
                                verticalAlign: VerticalAlign.TOP
                            }),
                            new TableCell({
                                children: [
                                    new Paragraph({
                                        alignment: AlignmentType.LEFT,
                                        children: [
                                            new TextRun({
                                                text: `${this.isInActivities(activities_list, 'Cosecha/Corte') ? activities[this.isInActivities(activities_list, 'Cosecha/Corte').index].actividades_certificado ? '■' : '❏' : '❏' } Si ${this.isInActivities(activities_list, 'Cosecha/Corte') ? activities[this.isInActivities(activities_list, 'Cosecha/Corte').index].actividades_no_certificado ? '■' : '❏' : '❏' } No`,
                                                font: 'Arial',
                                                size: 18
                                            })
                                        ]
                                    })
                                ],
                                verticalAlign: VerticalAlign.TOP
                            }),
                            new TableCell({
                                children: [
                                    new Paragraph({
                                        alignment: AlignmentType.LEFT,
                                        children: [
                                            new TextRun({
                                                text: `${this.isInActivities(activities_list, 'Cosecha/Corte') ? activities[this.isInActivities(activities_list, 'Cosecha/Corte').index].activades_codigo : '' }`,
                                                font: 'Arial',
                                                size: 18
                                            })
                                        ]
                                    })
                                ],
                                verticalAlign: VerticalAlign.TOP
                            }),
                            new TableCell({
                                children: [
                                    new Paragraph({
                                        alignment: AlignmentType.LEFT,
                                        children: [
                                            new TextRun({
                                                text: `${this.isInActivities(activities_list, 'Cosecha/Corte') ? activities[this.isInActivities(activities_list, 'Cosecha/Corte').index].activades_ubicacion : '' }`,
                                                font: 'Arial',
                                                size: 18
                                            })
                                        ]
                                    })
                                ],
                                verticalAlign: VerticalAlign.TOP
                            })
                        ]
                    })
                )
            }
        })

        activities_list.forEach((activities_list, i) => {
            if(i === 0 || this.isInActivities(activities_list, 'Transporte')) {
                table.addChildElement(
                    new TableRow({
                        children: [
                            new TableCell({
                                children: [
                                    new Paragraph({
                                        alignment: AlignmentType.LEFT,
                                        children: [
                                            new TextRun({
                                                text: `${this.isInActivities(activities_list, 'Transporte') ? '■' : '❏'} Transporte`,
                                                font: 'Arial',
                                                size: 18
                                            })
                                        ]
                                    })
                                ],
                                verticalAlign: VerticalAlign.TOP
                            }),
                            new TableCell({
                                children: [
                                    new Paragraph({
                                        alignment: AlignmentType.LEFT,
                                        children: [
                                            new TextRun({
                                                text: `${this.isInActivities(activities_list, 'Transporte') ? activities[this.isInActivities(activities_list, 'Transporte').index].actividades_interno_externo === "Interno" ? '■' : '❏' : '❏' } Int.  ${this.isInActivities(activities_list, 'Transporte') ? activities[this.isInActivities(activities_list, 'Transporte').index].actividades_interno_externo === "Externo" ? '■' : '❏' : '❏' } Ext.`,
                                                font: 'Arial',
                                                size: 18
                                            })
                                        ]
                                    })
                                ],
                                verticalAlign: VerticalAlign.TOP
                            }),
                            new TableCell({
                                children: [
                                    new Paragraph({
                                        alignment: AlignmentType.LEFT,
                                        children: [
                                            new TextRun({
                                                text: `${this.isInActivities(activities_list, 'Transporte') ? activities[this.isInActivities(activities_list, 'Transporte').index].activades_operador_que_desarrolla : '' }`,
                                                font: 'Arial',
                                                size: 18
                                            })
                                        ]
                                    })
                                ],
                                verticalAlign: VerticalAlign.TOP
                            }),
                            new TableCell({
                                children: [
                                    new Paragraph({
                                        alignment: AlignmentType.LEFT,
                                        children: [
                                            new TextRun({
                                                text: `${this.isInActivities(activities_list, 'Transporte') ? activities[this.isInActivities(activities_list, 'Transporte').index].actividades_certificado ? '■' : '❏' : '❏' } Si ${this.isInActivities(activities_list, 'Transporte') ? activities[this.isInActivities(activities_list, 'Transporte').index].actividades_no_certificado ? '■' : '❏' : '❏' } No`,
                                                font: 'Arial',
                                                size: 18
                                            })
                                        ]
                                    })
                                ],
                                verticalAlign: VerticalAlign.TOP
                            }),
                            new TableCell({
                                children: [
                                    new Paragraph({
                                        alignment: AlignmentType.LEFT,
                                        children: [
                                            new TextRun({
                                                text: `${this.isInActivities(activities_list, 'Transporte') ? activities[this.isInActivities(activities_list, 'Transporte').index].activades_codigo : '' }`,
                                                font: 'Arial',
                                                size: 18
                                            })
                                        ]
                                    })
                                ],
                                verticalAlign: VerticalAlign.TOP
                            }),
                            new TableCell({
                                children: [
                                    new Paragraph({
                                        alignment: AlignmentType.LEFT,
                                        children: [
                                            new TextRun({
                                                text: `${this.isInActivities(activities_list, 'Transporte') ? activities[this.isInActivities(activities_list, 'Transporte').index].activades_ubicacion : '' }`,
                                                font: 'Arial',
                                                size: 18
                                            })
                                        ]
                                    })
                                ],
                                verticalAlign: VerticalAlign.TOP
                            })
                        ]
                    })
                )
            }
        })

        activities_list.forEach((activities_list, i) => {
            if(i === 0 || this.isInActivities(activities_list, 'Lavado/Sanitizado')) {
                table.addChildElement(
                    new TableRow({
                        children: [
                            new TableCell({
                                children: [
                                    new Paragraph({
                                        alignment: AlignmentType.LEFT,
                                        children: [
                                            new TextRun({
                                                text: `${this.isInActivities(activities_list, 'Lavado/Sanitizado') ? '■' : '❏'} Lavado/Sanitizado`,
                                                font: 'Arial',
                                                size: 18
                                            })
                                        ]
                                    })
                                ],
                                verticalAlign: VerticalAlign.TOP
                            }),
                            new TableCell({
                                children: [
                                    new Paragraph({
                                        alignment: AlignmentType.LEFT,
                                        children: [
                                            new TextRun({
                                                text: `${this.isInActivities(activities_list, 'Lavado/Sanitizado') ? activities[this.isInActivities(activities_list, 'Lavado/Sanitizado').index].actividades_interno_externo === "Interno" ? '■' : '❏' : '❏' } Int.  ${this.isInActivities(activities_list, 'Lavado/Sanitizado') ? activities[this.isInActivities(activities_list, 'Lavado/Sanitizado').index].actividades_interno_externo === "Externo" ? '■' : '❏' : '❏' } Ext.`,
                                                font: 'Arial',
                                                size: 18
                                            })
                                        ]
                                    })
                                ],
                                verticalAlign: VerticalAlign.TOP
                            }),
                            new TableCell({
                                children: [
                                    new Paragraph({
                                        alignment: AlignmentType.LEFT,
                                        children: [
                                            new TextRun({
                                                text: `${this.isInActivities(activities_list, 'Lavado/Sanitizado') ? activities[this.isInActivities(activities_list, 'Lavado/Sanitizado').index].activades_operador_que_desarrolla : '' }`,
                                                font: 'Arial',
                                                size: 18
                                            })
                                        ]
                                    })
                                ],
                                verticalAlign: VerticalAlign.TOP
                            }),
                            new TableCell({
                                children: [
                                    new Paragraph({
                                        alignment: AlignmentType.LEFT,
                                        children: [
                                            new TextRun({
                                                text: `${this.isInActivities(activities_list, 'Lavado/Sanitizado') ? activities[this.isInActivities(activities_list, 'Lavado/Sanitizado').index].actividades_certificado ? '■' : '❏' : '❏' } Si ${this.isInActivities(activities_list, 'Lavado/Sanitizado') ? activities[this.isInActivities(activities_list, 'Lavado/Sanitizado').index].actividades_no_certificado ? '■' : '❏' : '❏' } No`,
                                                font: 'Arial',
                                                size: 18
                                            })
                                        ]
                                    })
                                ],
                                verticalAlign: VerticalAlign.TOP
                            }),
                            new TableCell({
                                children: [
                                    new Paragraph({
                                        alignment: AlignmentType.LEFT,
                                        children: [
                                            new TextRun({
                                                text: `${this.isInActivities(activities_list, 'Lavado/Sanitizado') ? activities[this.isInActivities(activities_list, 'Lavado/Sanitizado').index].activades_codigo : '' }`,
                                                font: 'Arial',
                                                size: 18
                                            })
                                        ]
                                    })
                                ],
                                verticalAlign: VerticalAlign.TOP
                            }),
                            new TableCell({
                                children: [
                                    new Paragraph({
                                        alignment: AlignmentType.LEFT,
                                        children: [
                                            new TextRun({
                                                text: `${this.isInActivities(activities_list, 'Lavado/Sanitizado') ? activities[this.isInActivities(activities_list, 'Lavado/Sanitizado').index].activades_ubicacion : '' }`,
                                                font: 'Arial',
                                                size: 18
                                            })
                                        ]
                                    })
                                ],
                                verticalAlign: VerticalAlign.TOP
                            })
                        ]
                    })
                )
            }
        })

        activities_list.forEach((activities_list, i) => {
            if(i === 0 || this.isInActivities(activities_list, 'Selección')) {
                table.addChildElement(
                    new TableRow({
                        children: [
                            new TableCell({
                                children: [
                                    new Paragraph({
                                        alignment: AlignmentType.LEFT,
                                        children: [
                                            new TextRun({
                                                text: `${this.isInActivities(activities_list, 'Selección') ? '■' : '❏'} Selección`,
                                                font: 'Arial',
                                                size: 18
                                            })
                                        ]
                                    })
                                ],
                                verticalAlign: VerticalAlign.TOP
                            }),
                            new TableCell({
                                children: [
                                    new Paragraph({
                                        alignment: AlignmentType.LEFT,
                                        children: [
                                            new TextRun({
                                                text: `${this.isInActivities(activities_list, 'Selección') ? activities[this.isInActivities(activities_list, 'Selección').index].actividades_interno_externo === "Interno" ? '■' : '❏' : '❏' } Int.  ${this.isInActivities(activities_list, 'Selección') ? activities[this.isInActivities(activities_list, 'Selección').index].actividades_interno_externo === "Externo" ? '■' : '❏' : '❏' } Ext.`,
                                                font: 'Arial',
                                                size: 18
                                            })
                                        ]
                                    })
                                ],
                                verticalAlign: VerticalAlign.TOP
                            }),
                            new TableCell({
                                children: [
                                    new Paragraph({
                                        alignment: AlignmentType.LEFT,
                                        children: [
                                            new TextRun({
                                                text: `${this.isInActivities(activities_list, 'Selección') ? activities[this.isInActivities(activities_list, 'Selección').index].activades_operador_que_desarrolla : '' }`,
                                                font: 'Arial',
                                                size: 18
                                            })
                                        ]
                                    })
                                ],
                                verticalAlign: VerticalAlign.TOP
                            }),
                            new TableCell({
                                children: [
                                    new Paragraph({
                                        alignment: AlignmentType.LEFT,
                                        children: [
                                            new TextRun({
                                                text: `${this.isInActivities(activities_list, 'Selección') ? activities[this.isInActivities(activities_list, 'Selección').index].actividades_certificado ? '■' : '❏' : '❏' } Si ${this.isInActivities(activities_list, 'Selección') ? activities[this.isInActivities(activities_list, 'Selección').index].actividades_no_certificado ? '■' : '❏' : '❏' } No`,
                                                font: 'Arial',
                                                size: 18
                                            })
                                        ]
                                    })
                                ],
                                verticalAlign: VerticalAlign.TOP
                            }),
                            new TableCell({
                                children: [
                                    new Paragraph({
                                        alignment: AlignmentType.LEFT,
                                        children: [
                                            new TextRun({
                                                text: `${this.isInActivities(activities_list, 'Selección') ? activities[this.isInActivities(activities_list, 'Selección').index].activades_codigo : '' }`,
                                                font: 'Arial',
                                                size: 18
                                            })
                                        ]
                                    })
                                ],
                                verticalAlign: VerticalAlign.TOP
                            }),
                            new TableCell({
                                children: [
                                    new Paragraph({
                                        alignment: AlignmentType.LEFT,
                                        children: [
                                            new TextRun({
                                                text: `${this.isInActivities(activities_list, 'Selección') ? activities[this.isInActivities(activities_list, 'Selección').index].activades_ubicacion : '' }`,
                                                font: 'Arial',
                                                size: 18
                                            })
                                        ]
                                    })
                                ],
                                verticalAlign: VerticalAlign.TOP
                            })
                        ]
                    })
                )
            }
        })

        activities_list.forEach((activities_list, i) => {
            if(i === 0 || this.isInActivities(activities_list, 'Envasado')) {
                table.addChildElement(
                    new TableRow({
                        children: [
                            new TableCell({
                                children: [
                                    new Paragraph({
                                        alignment: AlignmentType.LEFT,
                                        children: [
                                            new TextRun({
                                                text: `${this.isInActivities(activities_list, 'Envasado') ? '■' : '❏'} Envasado`,
                                                font: 'Arial',
                                                size: 18
                                            })
                                        ]
                                    })
                                ],
                                verticalAlign: VerticalAlign.TOP
                            }),
                            new TableCell({
                                children: [
                                    new Paragraph({
                                        alignment: AlignmentType.LEFT,
                                        children: [
                                            new TextRun({
                                                text: `${this.isInActivities(activities_list, 'Envasado') ? activities[this.isInActivities(activities_list, 'Envasado').index].actividades_interno_externo === "Interno" ? '■' : '❏' : '❏' } Int.  ${this.isInActivities(activities_list, 'Envasado') ? activities[this.isInActivities(activities_list, 'Envasado').index].actividades_interno_externo === "Externo" ? '■' : '❏' : '❏' } Ext.`,
                                                font: 'Arial',
                                                size: 18
                                            })
                                        ]
                                    })
                                ],
                                verticalAlign: VerticalAlign.TOP
                            }),
                            new TableCell({
                                children: [
                                    new Paragraph({
                                        alignment: AlignmentType.LEFT,
                                        children: [
                                            new TextRun({
                                                text: `${this.isInActivities(activities_list, 'Envasado') ? activities[this.isInActivities(activities_list, 'Envasado').index].activades_operador_que_desarrolla : '' }`,
                                                font: 'Arial',
                                                size: 18
                                            })
                                        ]
                                    })
                                ],
                                verticalAlign: VerticalAlign.TOP
                            }),
                            new TableCell({
                                children: [
                                    new Paragraph({
                                        alignment: AlignmentType.LEFT,
                                        children: [
                                            new TextRun({
                                                text: `${this.isInActivities(activities_list, 'Envasado') ? activities[this.isInActivities(activities_list, 'Envasado').index].actividades_certificado ? '■' : '❏' : '❏' } Si ${this.isInActivities(activities_list, 'Envasado') ? activities[this.isInActivities(activities_list, 'Envasado').index].actividades_no_certificado ? '■' : '❏' : '❏' } No`,
                                                font: 'Arial',
                                                size: 18
                                            })
                                        ]
                                    })
                                ],
                                verticalAlign: VerticalAlign.TOP
                            }),
                            new TableCell({
                                children: [
                                    new Paragraph({
                                        alignment: AlignmentType.LEFT,
                                        children: [
                                            new TextRun({
                                                text: `${this.isInActivities(activities_list, 'Envasado') ? activities[this.isInActivities(activities_list, 'Envasado').index].activades_codigo : '' }`,
                                                font: 'Arial',
                                                size: 18
                                            })
                                        ]
                                    })
                                ],
                                verticalAlign: VerticalAlign.TOP
                            }),
                            new TableCell({
                                children: [
                                    new Paragraph({
                                        alignment: AlignmentType.LEFT,
                                        children: [
                                            new TextRun({
                                                text: `${this.isInActivities(activities_list, 'Envasado') ? activities[this.isInActivities(activities_list, 'Envasado').index].activades_ubicacion : '' }`,
                                                font: 'Arial',
                                                size: 18
                                            })
                                        ]
                                    })
                                ],
                                verticalAlign: VerticalAlign.TOP
                            })
                        ]
                    })
                )
            }
        })

        activities_list.forEach((activities_list, i) => {
            if(i === 0 || this.isInActivities(activities_list, 'Empaque')) {
                table.addChildElement(
                    new TableRow({
                        children: [
                            new TableCell({
                                children: [
                                    new Paragraph({
                                        alignment: AlignmentType.LEFT,
                                        children: [
                                            new TextRun({
                                                text: `${this.isInActivities(activities_list, 'Empaque') ? '■' : '❏'} Empaque`,
                                                font: 'Arial',
                                                size: 18
                                            })
                                        ]
                                    })
                                ],
                                verticalAlign: VerticalAlign.TOP
                            }),
                            new TableCell({
                                children: [
                                    new Paragraph({
                                        alignment: AlignmentType.LEFT,
                                        children: [
                                            new TextRun({
                                                text: `${this.isInActivities(activities_list, 'Empaque') ? activities[this.isInActivities(activities_list, 'Empaque').index].actividades_interno_externo === "Interno" ? '■' : '❏' : '❏' } Int.  ${this.isInActivities(activities_list, 'Empaque') ? activities[this.isInActivities(activities_list, 'Empaque').index].actividades_interno_externo === "Externo" ? '■' : '❏' : '❏' } Ext.`,
                                                font: 'Arial',
                                                size: 18
                                            })
                                        ]
                                    })
                                ],
                                verticalAlign: VerticalAlign.TOP
                            }),
                            new TableCell({
                                children: [
                                    new Paragraph({
                                        alignment: AlignmentType.LEFT,
                                        children: [
                                            new TextRun({
                                                text: `${this.isInActivities(activities_list, 'Empaque') ? activities[this.isInActivities(activities_list, 'Empaque').index].activades_operador_que_desarrolla : '' }`,
                                                font: 'Arial',
                                                size: 18
                                            })
                                        ]
                                    })
                                ],
                                verticalAlign: VerticalAlign.TOP
                            }),
                            new TableCell({
                                children: [
                                    new Paragraph({
                                        alignment: AlignmentType.LEFT,
                                        children: [
                                            new TextRun({
                                                text: `${this.isInActivities(activities_list, 'Empaque') ? activities[this.isInActivities(activities_list, 'Empaque').index].actividades_certificado ? '■' : '❏' : '❏' } Si ${this.isInActivities(activities_list, 'Empaque') ? activities[this.isInActivities(activities_list, 'Empaque').index].actividades_no_certificado ? '■' : '❏' : '❏' } No`,
                                                font: 'Arial',
                                                size: 18
                                            })
                                        ]
                                    })
                                ],
                                verticalAlign: VerticalAlign.TOP
                            }),
                            new TableCell({
                                children: [
                                    new Paragraph({
                                        alignment: AlignmentType.LEFT,
                                        children: [
                                            new TextRun({
                                                text: `${this.isInActivities(activities_list, 'Empaque') ? activities[this.isInActivities(activities_list, 'Empaque').index].activades_codigo : '' }`,
                                                font: 'Arial',
                                                size: 18
                                            })
                                        ]
                                    })
                                ],
                                verticalAlign: VerticalAlign.TOP
                            }),
                            new TableCell({
                                children: [
                                    new Paragraph({
                                        alignment: AlignmentType.LEFT,
                                        children: [
                                            new TextRun({
                                                text: `${this.isInActivities(activities_list, 'Empaque') ? activities[this.isInActivities(activities_list, 'Empaque').index].activades_ubicacion : '' }`,
                                                font: 'Arial',
                                                size: 18
                                            })
                                        ]
                                    })
                                ],
                                verticalAlign: VerticalAlign.TOP
                            })
                        ]
                    })
                )
            }
        })

        activities_list.forEach((activities_list, i) => {
            if(i === 0 || this.isInActivities(activities_list, 'Almacenamiento')) {
                table.addChildElement(
                    new TableRow({
                        children: [
                            new TableCell({
                                children: [
                                    new Paragraph({
                                        alignment: AlignmentType.LEFT,
                                        children: [
                                            new TextRun({
                                                text: `${this.isInActivities(activities_list, 'Almacenamiento') ? '■' : '❏'} Almacenamiento`,
                                                font: 'Arial',
                                                size: 18
                                            })
                                        ]
                                    })
                                ],
                                verticalAlign: VerticalAlign.TOP
                            }),
                            new TableCell({
                                children: [
                                    new Paragraph({
                                        alignment: AlignmentType.LEFT,
                                        children: [
                                            new TextRun({
                                                text: `${this.isInActivities(activities_list, 'Almacenamiento') ? activities[this.isInActivities(activities_list, 'Almacenamiento').index].actividades_interno_externo === "Interno" ? '■' : '❏' : '❏' } Int.  ${this.isInActivities(activities_list, 'Almacenamiento') ? activities[this.isInActivities(activities_list, 'Almacenamiento').index].actividades_interno_externo === "Externo" ? '■' : '❏' : '❏' } Ext.`,
                                                font: 'Arial',
                                                size: 18
                                            })
                                        ]
                                    })
                                ],
                                verticalAlign: VerticalAlign.TOP
                            }),
                            new TableCell({
                                children: [
                                    new Paragraph({
                                        alignment: AlignmentType.LEFT,
                                        children: [
                                            new TextRun({
                                                text: `${this.isInActivities(activities_list, 'Almacenamiento') ? activities[this.isInActivities(activities_list, 'Almacenamiento').index].activades_operador_que_desarrolla : '' }`,
                                                font: 'Arial',
                                                size: 18
                                            })
                                        ]
                                    })
                                ],
                                verticalAlign: VerticalAlign.TOP
                            }),
                            new TableCell({
                                children: [
                                    new Paragraph({
                                        alignment: AlignmentType.LEFT,
                                        children: [
                                            new TextRun({
                                                text: `${this.isInActivities(activities_list, 'Almacenamiento') ? activities[this.isInActivities(activities_list, 'Almacenamiento').index].actividades_certificado ? '■' : '❏' : '❏' } Si ${this.isInActivities(activities_list, 'Almacenamiento') ? activities[this.isInActivities(activities_list, 'Almacenamiento').index].actividades_no_certificado ? '■' : '❏' : '❏' } No`,
                                                font: 'Arial',
                                                size: 18
                                            })
                                        ]
                                    })
                                ],
                                verticalAlign: VerticalAlign.TOP
                            }),
                            new TableCell({
                                children: [
                                    new Paragraph({
                                        alignment: AlignmentType.LEFT,
                                        children: [
                                            new TextRun({
                                                text: `${this.isInActivities(activities_list, 'Almacenamiento') ? activities[this.isInActivities(activities_list, 'Almacenamiento').index].activades_codigo : '' }`,
                                                font: 'Arial',
                                                size: 18
                                            })
                                        ]
                                    })
                                ],
                                verticalAlign: VerticalAlign.TOP
                            }),
                            new TableCell({
                                children: [
                                    new Paragraph({
                                        alignment: AlignmentType.LEFT,
                                        children: [
                                            new TextRun({
                                                text: `${this.isInActivities(activities_list, 'Almacenamiento') ? activities[this.isInActivities(activities_list, 'Almacenamiento').index].activades_ubicacion : '' }`,
                                                font: 'Arial',
                                                size: 18
                                            })
                                        ]
                                    })
                                ],
                                verticalAlign: VerticalAlign.TOP
                            })
                        ]
                    })
                )
            }
        })

        activities_list.forEach((activities_list, i) => {
            if(i === 0 || this.isInActivities(activities_list, 'Refrigerado / Congelado')) {
                table.addChildElement(
                    new TableRow({
                        children: [
                            new TableCell({
                                children: [
                                    new Paragraph({
                                        alignment: AlignmentType.LEFT,
                                        children: [
                                            new TextRun({
                                                text: `${this.isInActivities(activities_list, 'Refrigerado / Congelado') ? '■' : '❏'} Refrigerado / Congelado`,
                                                font: 'Arial',
                                                size: 18
                                            })
                                        ]
                                    })
                                ],
                                verticalAlign: VerticalAlign.TOP
                            }),
                            new TableCell({
                                children: [
                                    new Paragraph({
                                        alignment: AlignmentType.LEFT,
                                        children: [
                                            new TextRun({
                                                text: `${this.isInActivities(activities_list, 'Refrigerado / Congelado') ? activities[this.isInActivities(activities_list, 'Refrigerado / Congelado').index].actividades_interno_externo === "Interno" ? '■' : '❏' : '❏' } Int.  ${this.isInActivities(activities_list, 'Refrigerado / Congelado') ? activities[this.isInActivities(activities_list, 'Refrigerado / Congelado').index].actividades_interno_externo === "Externo" ? '■' : '❏' : '❏' } Ext.`,
                                                font: 'Arial',
                                                size: 18
                                            })
                                        ]
                                    })
                                ],
                                verticalAlign: VerticalAlign.TOP
                            }),
                            new TableCell({
                                children: [
                                    new Paragraph({
                                        alignment: AlignmentType.LEFT,
                                        children: [
                                            new TextRun({
                                                text: `${this.isInActivities(activities_list, 'Refrigerado / Congelado') ? activities[this.isInActivities(activities_list, 'Refrigerado / Congelado').index].activades_operador_que_desarrolla : '' }`,
                                                font: 'Arial',
                                                size: 18
                                            })
                                        ]
                                    })
                                ],
                                verticalAlign: VerticalAlign.TOP
                            }),
                            new TableCell({
                                children: [
                                    new Paragraph({
                                        alignment: AlignmentType.LEFT,
                                        children: [
                                            new TextRun({
                                                text: `${this.isInActivities(activities_list, 'Refrigerado / Congelado') ? activities[this.isInActivities(activities_list, 'Refrigerado / Congelado').index].actividades_certificado ? '■' : '❏' : '❏' } Si ${this.isInActivities(activities_list, 'Refrigerado / Congelado') ? activities[this.isInActivities(activities_list, 'Refrigerado / Congelado').index].actividades_no_certificado ? '■' : '❏' : '❏' } No`,
                                                font: 'Arial',
                                                size: 18
                                            })
                                        ]
                                    })
                                ],
                                verticalAlign: VerticalAlign.TOP
                            }),
                            new TableCell({
                                children: [
                                    new Paragraph({
                                        alignment: AlignmentType.LEFT,
                                        children: [
                                            new TextRun({
                                                text: `${this.isInActivities(activities_list, 'Refrigerado / Congelado') ? activities[this.isInActivities(activities_list, 'Refrigerado / Congelado').index].activades_codigo : '' }`,
                                                font: 'Arial',
                                                size: 18
                                            })
                                        ]
                                    })
                                ],
                                verticalAlign: VerticalAlign.TOP
                            }),
                            new TableCell({
                                children: [
                                    new Paragraph({
                                        alignment: AlignmentType.LEFT,
                                        children: [
                                            new TextRun({
                                                text: `${this.isInActivities(activities_list, 'Refrigerado / Congelado') ? activities[this.isInActivities(activities_list, 'Refrigerado / Congelado').index].activades_ubicacion : '' }`,
                                                font: 'Arial',
                                                size: 18
                                            })
                                        ]
                                    })
                                ],
                                verticalAlign: VerticalAlign.TOP
                            })
                        ]
                    })
                )
            }
        })

        activities_list.forEach((activities_list, i) => {
            if(i === 0 || this.isInActivities(activities_list, 'Deshidratado')) {
                table.addChildElement(
                    new TableRow({
                        children: [
                            new TableCell({
                                children: [
                                    new Paragraph({
                                        alignment: AlignmentType.LEFT,
                                        children: [
                                            new TextRun({
                                                text: `${this.isInActivities(activities_list, 'Deshidratado') ? '■' : '❏'} Deshidratado`,
                                                font: 'Arial',
                                                size: 18
                                            })
                                        ]
                                    })
                                ],
                                verticalAlign: VerticalAlign.TOP
                            }),
                            new TableCell({
                                children: [
                                    new Paragraph({
                                        alignment: AlignmentType.LEFT,
                                        children: [
                                            new TextRun({
                                                text: `${this.isInActivities(activities_list, 'Deshidratado') ? activities[this.isInActivities(activities_list, 'Deshidratado').index].actividades_interno_externo === "Interno" ? '■' : '❏' : '❏' } Int.  ${this.isInActivities(activities_list, 'Deshidratado') ? activities[this.isInActivities(activities_list, 'Deshidratado').index].actividades_interno_externo === "Externo" ? '■' : '❏' : '❏' } Ext.`,
                                                font: 'Arial',
                                                size: 18
                                            })
                                        ]
                                    })
                                ],
                                verticalAlign: VerticalAlign.TOP
                            }),
                            new TableCell({
                                children: [
                                    new Paragraph({
                                        alignment: AlignmentType.LEFT,
                                        children: [
                                            new TextRun({
                                                text: `${this.isInActivities(activities_list, 'Deshidratado') ? activities[this.isInActivities(activities_list, 'Deshidratado').index].activades_operador_que_desarrolla : '' }`,
                                                font: 'Arial',
                                                size: 18
                                            })
                                        ]
                                    })
                                ],
                                verticalAlign: VerticalAlign.TOP
                            }),
                            new TableCell({
                                children: [
                                    new Paragraph({
                                        alignment: AlignmentType.LEFT,
                                        children: [
                                            new TextRun({
                                                text: `${this.isInActivities(activities_list, 'Deshidratado') ? activities[this.isInActivities(activities_list, 'Deshidratado').index].actividades_certificado ? '■' : '❏' : '❏' } Si ${this.isInActivities(activities_list, 'Deshidratado') ? activities[this.isInActivities(activities_list, 'Deshidratado').index].actividades_no_certificado ? '■' : '❏' : '❏' } No`,
                                                font: 'Arial',
                                                size: 18
                                            })
                                        ]
                                    })
                                ],
                                verticalAlign: VerticalAlign.TOP
                            }),
                            new TableCell({
                                children: [
                                    new Paragraph({
                                        alignment: AlignmentType.LEFT,
                                        children: [
                                            new TextRun({
                                                text: `${this.isInActivities(activities_list, 'Deshidratado') ? activities[this.isInActivities(activities_list, 'Deshidratado').index].activades_codigo : '' }`,
                                                font: 'Arial',
                                                size: 18
                                            })
                                        ]
                                    })
                                ],
                                verticalAlign: VerticalAlign.TOP
                            }),
                            new TableCell({
                                children: [
                                    new Paragraph({
                                        alignment: AlignmentType.LEFT,
                                        children: [
                                            new TextRun({
                                                text: `${this.isInActivities(activities_list, 'Deshidratado') ? activities[this.isInActivities(activities_list, 'Deshidratado').index].activades_ubicacion : '' }`,
                                                font: 'Arial',
                                                size: 18
                                            })
                                        ]
                                    })
                                ],
                                verticalAlign: VerticalAlign.TOP
                            })
                        ]
                    })
                )
            }
        })

        activities_list.forEach((activities_list, i) => {
            if(i === 0 || this.isInActivities(activities_list, 'Mezclado')) {
                table.addChildElement(
                    new TableRow({
                        children: [
                            new TableCell({
                                children: [
                                    new Paragraph({
                                        alignment: AlignmentType.LEFT,
                                        children: [
                                            new TextRun({
                                                text: `${this.isInActivities(activities_list, 'Mezclado') ? '■' : '❏'} Mezclado`,
                                                font: 'Arial',
                                                size: 18
                                            })
                                        ]
                                    })
                                ],
                                verticalAlign: VerticalAlign.TOP
                            }),
                            new TableCell({
                                children: [
                                    new Paragraph({
                                        alignment: AlignmentType.LEFT,
                                        children: [
                                            new TextRun({
                                                text: `${this.isInActivities(activities_list, 'Mezclado') ? activities[this.isInActivities(activities_list, 'Mezclado').index].actividades_interno_externo === "Interno" ? '■' : '❏' : '❏' } Int.  ${this.isInActivities(activities_list, 'Mezclado') ? activities[this.isInActivities(activities_list, 'Mezclado').index].actividades_interno_externo === "Externo" ? '■' : '❏' : '❏' } Ext.`,
                                                font: 'Arial',
                                                size: 18
                                            })
                                        ]
                                    })
                                ],
                                verticalAlign: VerticalAlign.TOP
                            }),
                            new TableCell({
                                children: [
                                    new Paragraph({
                                        alignment: AlignmentType.LEFT,
                                        children: [
                                            new TextRun({
                                                text: `${this.isInActivities(activities_list, 'Mezclado') ? activities[this.isInActivities(activities_list, 'Mezclado').index].activades_operador_que_desarrolla : '' }`,
                                                font: 'Arial',
                                                size: 18
                                            })
                                        ]
                                    })
                                ],
                                verticalAlign: VerticalAlign.TOP
                            }),
                            new TableCell({
                                children: [
                                    new Paragraph({
                                        alignment: AlignmentType.LEFT,
                                        children: [
                                            new TextRun({
                                                text: `${this.isInActivities(activities_list, 'Mezclado') ? activities[this.isInActivities(activities_list, 'Mezclado').index].actividades_certificado ? '■' : '❏' : '❏' } Si ${this.isInActivities(activities_list, 'Mezclado') ? activities[this.isInActivities(activities_list, 'Mezclado').index].actividades_no_certificado ? '■' : '❏' : '❏' } No`,
                                                font: 'Arial',
                                                size: 18
                                            })
                                        ]
                                    })
                                ],
                                verticalAlign: VerticalAlign.TOP
                            }),
                            new TableCell({
                                children: [
                                    new Paragraph({
                                        alignment: AlignmentType.LEFT,
                                        children: [
                                            new TextRun({
                                                text: `${this.isInActivities(activities_list, 'Mezclado') ? activities[this.isInActivities(activities_list, 'Mezclado').index].activades_codigo : '' }`,
                                                font: 'Arial',
                                                size: 18
                                            })
                                        ]
                                    })
                                ],
                                verticalAlign: VerticalAlign.TOP
                            }),
                            new TableCell({
                                children: [
                                    new Paragraph({
                                        alignment: AlignmentType.LEFT,
                                        children: [
                                            new TextRun({
                                                text: `${this.isInActivities(activities_list, 'Mezclado') ? activities[this.isInActivities(activities_list, 'Mezclado').index].activades_ubicacion : '' }`,
                                                font: 'Arial',
                                                size: 18
                                            })
                                        ]
                                    })
                                ],
                                verticalAlign: VerticalAlign.TOP
                            })
                        ]
                    })
                )
            }
        })

        activities_list.forEach((activities_list, i) => {
            if(i === 0 || this.isInActivities(activities_list, 'Pasteurización')) {
                table.addChildElement(
                    new TableRow({
                        children: [
                            new TableCell({
                                children: [
                                    new Paragraph({
                                        alignment: AlignmentType.LEFT,
                                        children: [
                                            new TextRun({
                                                text: `${this.isInActivities(activities_list, 'Pasteurización') ? '■' : '❏'} Pasteurización`,
                                                font: 'Arial',
                                                size: 18
                                            })
                                        ]
                                    })
                                ],
                                verticalAlign: VerticalAlign.TOP
                            }),
                            new TableCell({
                                children: [
                                    new Paragraph({
                                        alignment: AlignmentType.LEFT,
                                        children: [
                                            new TextRun({
                                                text: `${this.isInActivities(activities_list, 'Pasteurización') ? activities[this.isInActivities(activities_list, 'Pasteurización').index].actividades_interno_externo === "Interno" ? '■' : '❏' : '❏' } Int.  ${this.isInActivities(activities_list, 'Pasteurización') ? activities[this.isInActivities(activities_list, 'Pasteurización').index].actividades_interno_externo === "Externo" ? '■' : '❏' : '❏' } Ext.`,
                                                font: 'Arial',
                                                size: 18
                                            })
                                        ]
                                    })
                                ],
                                verticalAlign: VerticalAlign.TOP
                            }),
                            new TableCell({
                                children: [
                                    new Paragraph({
                                        alignment: AlignmentType.LEFT,
                                        children: [
                                            new TextRun({
                                                text: `${this.isInActivities(activities_list, 'Pasteurización') ? activities[this.isInActivities(activities_list, 'Pasteurización').index].activades_operador_que_desarrolla : '' }`,
                                                font: 'Arial',
                                                size: 18
                                            })
                                        ]
                                    })
                                ],
                                verticalAlign: VerticalAlign.TOP
                            }),
                            new TableCell({
                                children: [
                                    new Paragraph({
                                        alignment: AlignmentType.LEFT,
                                        children: [
                                            new TextRun({
                                                text: `${this.isInActivities(activities_list, 'Pasteurización') ? activities[this.isInActivities(activities_list, 'Pasteurización').index].actividades_certificado ? '■' : '❏' : '❏' } Si ${this.isInActivities(activities_list, 'Pasteurización') ? activities[this.isInActivities(activities_list, 'Pasteurización').index].actividades_no_certificado ? '■' : '❏' : '❏' } No`,
                                                font: 'Arial',
                                                size: 18
                                            })
                                        ]
                                    })
                                ],
                                verticalAlign: VerticalAlign.TOP
                            }),
                            new TableCell({
                                children: [
                                    new Paragraph({
                                        alignment: AlignmentType.LEFT,
                                        children: [
                                            new TextRun({
                                                text: `${this.isInActivities(activities_list, 'Pasteurización') ? activities[this.isInActivities(activities_list, 'Pasteurización').index].activades_codigo : '' }`,
                                                font: 'Arial',
                                                size: 18
                                            })
                                        ]
                                    })
                                ],
                                verticalAlign: VerticalAlign.TOP
                            }),
                            new TableCell({
                                children: [
                                    new Paragraph({
                                        alignment: AlignmentType.LEFT,
                                        children: [
                                            new TextRun({
                                                text: `${this.isInActivities(activities_list, 'Pasteurización') ? activities[this.isInActivities(activities_list, 'Pasteurización').index].activades_ubicacion : '' }`,
                                                font: 'Arial',
                                                size: 18
                                            })
                                        ]
                                    })
                                ],
                                verticalAlign: VerticalAlign.TOP
                            })
                        ]
                    })
                )
            }
        })

        activities_list.forEach((activities_list, i) => {
            if(i === 0 || this.isInActivities(activities_list, 'Liofilizado')) {
                table.addChildElement(
                    new TableRow({
                        children: [
                            new TableCell({
                                children: [
                                    new Paragraph({
                                        alignment: AlignmentType.LEFT,
                                        children: [
                                            new TextRun({
                                                text: `${this.isInActivities(activities_list, 'Liofilizado') ? '■' : '❏'} Liofilizado`,
                                                font: 'Arial',
                                                size: 18
                                            })
                                        ]
                                    })
                                ],
                                verticalAlign: VerticalAlign.TOP
                            }),
                            new TableCell({
                                children: [
                                    new Paragraph({
                                        alignment: AlignmentType.LEFT,
                                        children: [
                                            new TextRun({
                                                text: `${this.isInActivities(activities_list, 'Liofilizado') ? activities[this.isInActivities(activities_list, 'Liofilizado').index].actividades_interno_externo === "Interno" ? '■' : '❏' : '❏' } Int.  ${this.isInActivities(activities_list, 'Liofilizado') ? activities[this.isInActivities(activities_list, 'Liofilizado').index].actividades_interno_externo === "Externo" ? '■' : '❏' : '❏' } Ext.`,
                                                font: 'Arial',
                                                size: 18
                                            })
                                        ]
                                    })
                                ],
                                verticalAlign: VerticalAlign.TOP
                            }),
                            new TableCell({
                                children: [
                                    new Paragraph({
                                        alignment: AlignmentType.LEFT,
                                        children: [
                                            new TextRun({
                                                text: `${this.isInActivities(activities_list, 'Liofilizado') ? activities[this.isInActivities(activities_list, 'Liofilizado').index].activades_operador_que_desarrolla : '' }`,
                                                font: 'Arial',
                                                size: 18
                                            })
                                        ]
                                    })
                                ],
                                verticalAlign: VerticalAlign.TOP
                            }),
                            new TableCell({
                                children: [
                                    new Paragraph({
                                        alignment: AlignmentType.LEFT,
                                        children: [
                                            new TextRun({
                                                text: `${this.isInActivities(activities_list, 'Liofilizado') ? activities[this.isInActivities(activities_list, 'Liofilizado').index].actividades_certificado ? '■' : '❏' : '❏' } Si ${this.isInActivities(activities_list, 'Liofilizado') ? activities[this.isInActivities(activities_list, 'Liofilizado').index].actividades_no_certificado ? '■' : '❏' : '❏' } No`,
                                                font: 'Arial',
                                                size: 18
                                            })
                                        ]
                                    })
                                ],
                                verticalAlign: VerticalAlign.TOP
                            }),
                            new TableCell({
                                children: [
                                    new Paragraph({
                                        alignment: AlignmentType.LEFT,
                                        children: [
                                            new TextRun({
                                                text: `${this.isInActivities(activities_list, 'Liofilizado') ? activities[this.isInActivities(activities_list, 'Liofilizado').index].activades_codigo : '' }`,
                                                font: 'Arial',
                                                size: 18
                                            })
                                        ]
                                    })
                                ],
                                verticalAlign: VerticalAlign.TOP
                            }),
                            new TableCell({
                                children: [
                                    new Paragraph({
                                        alignment: AlignmentType.LEFT,
                                        children: [
                                            new TextRun({
                                                text: `${this.isInActivities(activities_list, 'Liofilizado') ? activities[this.isInActivities(activities_list, 'Liofilizado').index].activades_ubicacion : '' }`,
                                                font: 'Arial',
                                                size: 18
                                            })
                                        ]
                                    })
                                ],
                                verticalAlign: VerticalAlign.TOP
                            })
                        ]
                    })
                )
            }
        })

        activities_list.forEach((activities_list, i) => {
            if(i === 0 || this.isInActivities(activities_list, 'Sacrificio')) {
                table.addChildElement(
                    new TableRow({
                        children: [
                            new TableCell({
                                children: [
                                    new Paragraph({
                                        alignment: AlignmentType.LEFT,
                                        children: [
                                            new TextRun({
                                                text: `${this.isInActivities(activities_list, 'Sacrificio') ? '■' : '❏'} Sacrificio`,
                                                font: 'Arial',
                                                size: 18
                                            })
                                        ]
                                    })
                                ],
                                verticalAlign: VerticalAlign.TOP
                            }),
                            new TableCell({
                                children: [
                                    new Paragraph({
                                        alignment: AlignmentType.LEFT,
                                        children: [
                                            new TextRun({
                                                text: `${this.isInActivities(activities_list, 'Sacrificio') ? activities[this.isInActivities(activities_list, 'Sacrificio').index].actividades_interno_externo === "Interno" ? '■' : '❏' : '❏' } Int.  ${this.isInActivities(activities_list, 'Sacrificio') ? activities[this.isInActivities(activities_list, 'Sacrificio').index].actividades_interno_externo === "Externo" ? '■' : '❏' : '❏' } Ext.`,
                                                font: 'Arial',
                                                size: 18
                                            })
                                        ]
                                    })
                                ],
                                verticalAlign: VerticalAlign.TOP
                            }),
                            new TableCell({
                                children: [
                                    new Paragraph({
                                        alignment: AlignmentType.LEFT,
                                        children: [
                                            new TextRun({
                                                text: `${this.isInActivities(activities_list, 'Sacrificio') ? activities[this.isInActivities(activities_list, 'Sacrificio').index].activades_operador_que_desarrolla : '' }`,
                                                font: 'Arial',
                                                size: 18
                                            })
                                        ]
                                    })
                                ],
                                verticalAlign: VerticalAlign.TOP
                            }),
                            new TableCell({
                                children: [
                                    new Paragraph({
                                        alignment: AlignmentType.LEFT,
                                        children: [
                                            new TextRun({
                                                text: `${this.isInActivities(activities_list, 'Sacrificio') ? activities[this.isInActivities(activities_list, 'Sacrificio').index].actividades_certificado ? '■' : '❏' : '❏' } Si ${this.isInActivities(activities_list, 'Sacrificio') ? activities[this.isInActivities(activities_list, 'Sacrificio').index].actividades_no_certificado ? '■' : '❏' : '❏' } No`,
                                                font: 'Arial',
                                                size: 18
                                            })
                                        ]
                                    })
                                ],
                                verticalAlign: VerticalAlign.TOP
                            }),
                            new TableCell({
                                children: [
                                    new Paragraph({
                                        alignment: AlignmentType.LEFT,
                                        children: [
                                            new TextRun({
                                                text: `${this.isInActivities(activities_list, 'Sacrificio') ? activities[this.isInActivities(activities_list, 'Sacrificio').index].activades_codigo : '' }`,
                                                font: 'Arial',
                                                size: 18
                                            })
                                        ]
                                    })
                                ],
                                verticalAlign: VerticalAlign.TOP
                            }),
                            new TableCell({
                                children: [
                                    new Paragraph({
                                        alignment: AlignmentType.LEFT,
                                        children: [
                                            new TextRun({
                                                text: `${this.isInActivities(activities_list, 'Sacrificio') ? activities[this.isInActivities(activities_list, 'Sacrificio').index].activades_ubicacion : '' }`,
                                                font: 'Arial',
                                                size: 18
                                            })
                                        ]
                                    })
                                ],
                                verticalAlign: VerticalAlign.TOP
                            })
                        ]
                    })
                )
            }
        })

        activities_list.forEach((activities_list, i) => {
            if(i === 0 || this.isInActivities(activities_list, 'Comercialización')) {
                table.addChildElement(
                    new TableRow({
                        children: [
                            new TableCell({
                                children: [
                                    new Paragraph({
                                        alignment: AlignmentType.LEFT,
                                        children: [
                                            new TextRun({
                                                text: `${this.isInActivities(activities_list, 'Comercialización') ? '■' : '❏'} Comercialización`,
                                                font: 'Arial',
                                                size: 18
                                            })
                                        ]
                                    })
                                ],
                                verticalAlign: VerticalAlign.TOP
                            }),
                            new TableCell({
                                children: [
                                    new Paragraph({
                                        alignment: AlignmentType.LEFT,
                                        children: [
                                            new TextRun({
                                                text: `${this.isInActivities(activities_list, 'Comercialización') ? activities[this.isInActivities(activities_list, 'Comercialización').index].actividades_interno_externo === "Interno" ? '■' : '❏' : '❏' } Int.  ${this.isInActivities(activities_list, 'Comercialización') ? activities[this.isInActivities(activities_list, 'Comercialización').index].actividades_interno_externo === "Externo" ? '■' : '❏' : '❏' } Ext.`,
                                                font: 'Arial',
                                                size: 18
                                            })
                                        ]
                                    })
                                ],
                                verticalAlign: VerticalAlign.TOP
                            }),
                            new TableCell({
                                children: [
                                    new Paragraph({
                                        alignment: AlignmentType.LEFT,
                                        children: [
                                            new TextRun({
                                                text: `${this.isInActivities(activities_list, 'Comercialización') ? activities[this.isInActivities(activities_list, 'Comercialización').index].activades_operador_que_desarrolla : '' }`,
                                                font: 'Arial',
                                                size: 18
                                            })
                                        ]
                                    })
                                ],
                                verticalAlign: VerticalAlign.TOP
                            }),
                            new TableCell({
                                children: [
                                    new Paragraph({
                                        alignment: AlignmentType.LEFT,
                                        children: [
                                            new TextRun({
                                                text: `${this.isInActivities(activities_list, 'Comercialización') ? activities[this.isInActivities(activities_list, 'Comercialización').index].actividades_certificado ? '■' : '❏' : '❏' } Si ${this.isInActivities(activities_list, 'Comercialización') ? activities[this.isInActivities(activities_list, 'Comercialización').index].actividades_no_certificado ? '■' : '❏' : '❏' } No`,
                                                font: 'Arial',
                                                size: 18
                                            })
                                        ]
                                    })
                                ],
                                verticalAlign: VerticalAlign.TOP
                            }),
                            new TableCell({
                                children: [
                                    new Paragraph({
                                        alignment: AlignmentType.LEFT,
                                        children: [
                                            new TextRun({
                                                text: `${this.isInActivities(activities_list, 'Comercialización') ? activities[this.isInActivities(activities_list, 'Comercialización').index].activades_codigo : '' }`,
                                                font: 'Arial',
                                                size: 18
                                            })
                                        ]
                                    })
                                ],
                                verticalAlign: VerticalAlign.TOP
                            }),
                            new TableCell({
                                children: [
                                    new Paragraph({
                                        alignment: AlignmentType.LEFT,
                                        children: [
                                            new TextRun({
                                                text: `${this.isInActivities(activities_list, 'Comercialización') ? activities[this.isInActivities(activities_list, 'Comercialización').index].activades_ubicacion : '' }`,
                                                font: 'Arial',
                                                size: 18
                                            })
                                        ]
                                    })
                                ],
                                verticalAlign: VerticalAlign.TOP
                            })
                        ]
                    })
                )
            }
        })

        activities_list.forEach((activities_list, i) => {
            if(i === 0 || this.isInActivities(activities_list, 'Otro')) {
                table.addChildElement(
                    new TableRow({
                        children: [
                            new TableCell({
                                children: [
                                    new Paragraph({
                                        alignment: AlignmentType.LEFT,
                                        children: [
                                            new TextRun({
                                                text: `${this.isInActivities(activities_list, 'Otro') ? '■' : '❏'} Otro: ${this.isInActivitiesOther(activities_list, 'Otro')}`,
                                                font: 'Arial',
                                                size: 18
                                            })
                                        ]
                                    })
                                ],
                                verticalAlign: VerticalAlign.TOP
                            }),
                            new TableCell({
                                children: [
                                    new Paragraph({
                                        alignment: AlignmentType.LEFT,
                                        children: [
                                            new TextRun({
                                                text: `${this.isInActivities(activities_list, 'Otro') ? activities[this.isInActivities(activities_list, 'Otro').index].actividades_interno_externo === "Interno" ? '■' : '❏' : '❏' } Int.  ${this.isInActivities(activities_list, 'Otro') ? activities[this.isInActivities(activities_list, 'Otro').index].actividades_interno_externo === "Externo" ? '■' : '❏' : '❏' } Ext.`,
                                                font: 'Arial',
                                                size: 18
                                            })
                                        ]
                                    })
                                ],
                                verticalAlign: VerticalAlign.TOP
                            }),
                            new TableCell({
                                children: [
                                    new Paragraph({
                                        alignment: AlignmentType.LEFT,
                                        children: [
                                            new TextRun({
                                                text: `${this.isInActivities(activities_list, 'Otro') ? activities[this.isInActivities(activities_list, 'Otro').index].activades_operador_que_desarrolla : '' }`,
                                                font: 'Arial',
                                                size: 18
                                            })
                                        ]
                                    })
                                ],
                                verticalAlign: VerticalAlign.TOP
                            }),
                            new TableCell({
                                children: [
                                    new Paragraph({
                                        alignment: AlignmentType.LEFT,
                                        children: [
                                            new TextRun({
                                                text: `${this.isInActivities(activities_list, 'Otro') ? activities[this.isInActivities(activities_list, 'Otro').index].actividades_certificado ? '■' : '❏' : '❏' } Si ${this.isInActivities(activities_list, 'Otro') ? activities[this.isInActivities(activities_list, 'Otro').index].actividades_no_certificado ? '■' : '❏' : '❏' } No`,
                                                font: 'Arial',
                                                size: 18
                                            })
                                        ]
                                    })
                                ],
                                verticalAlign: VerticalAlign.TOP
                            }),
                            new TableCell({
                                children: [
                                    new Paragraph({
                                        alignment: AlignmentType.LEFT,
                                        children: [
                                            new TextRun({
                                                text: `${this.isInActivities(activities_list, 'Otro') ? activities[this.isInActivities(activities_list, 'Otro').index].activades_codigo : '' }`,
                                                font: 'Arial',
                                                size: 18
                                            })
                                        ]
                                    })
                                ],
                                verticalAlign: VerticalAlign.TOP
                            }),
                            new TableCell({
                                children: [
                                    new Paragraph({
                                        alignment: AlignmentType.LEFT,
                                        children: [
                                            new TextRun({
                                                text: `${this.isInActivities(activities_list, 'Otro') ? activities[this.isInActivities(activities_list, 'Otro').index].activades_ubicacion : '' }`,
                                                font: 'Arial',
                                                size: 18
                                            })
                                        ]
                                    })
                                ],
                                verticalAlign: VerticalAlign.TOP
                            })
                        ]
                    })
                )
            }
        })


        return table
    }

    public getException(exceptions, name) {
        //console.log(exceptions)
        for (var i in exceptions) {
            const exception = exceptions[i]
            if (exception.name === name) {
                return exception.value
            }
        }
        return false
    }

    public createExceptions(exceptions): Table {
        const table = new Table({
            rows: [
                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: '3.	Excepciones.',
                                            font: 'Arial',
                                            size: 20,
                                            bold: true
                                        })
                                    ],
                                    spacing: {
                                        after: 75
                                    }
                                })
                            ],
                            verticalAlign: VerticalAlign.CENTER,
                            columnSpan: 3,
                            shading: {
                                fill: '#C0C0C0'
                            }
                        })
                    ]
                }),
                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: 'Por favor indique si solicita el reconocimiento de alguna de las siguientes excepciones a la norma:',
                                            font: 'Arial',
                                            size: 20,
                                        })
                                    ],
                                    spacing: {
                                        after: 75
                                    }
                                })
                            ],
                            verticalAlign: VerticalAlign.CENTER,
                            columnSpan: 3
                        })
                    ]
                }),
                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: `${this.getException(exceptions, 'excepcion_fsol_10') ? '■' : '❏'}`,
                                            font: 'Arial',
                                            size: 20,
                                        })
                                    ],
                                    spacing: {
                                        after: 75
                                    }
                                })
                            ],
                            verticalAlign: VerticalAlign.CENTER,
                            width: {
                                size: 20,
                                type: WidthType.PERCENTAGE
                            }
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: 'Solicitud de reconocimiento retroactivo.',
                                            font: 'Arial',
                                            size: 20,
                                        })
                                    ],
                                    spacing: {
                                        after: 75
                                    }
                                })
                            ],
                            verticalAlign: VerticalAlign.CENTER
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: 'Llenar formato MTO-FSOL-10',
                                            font: 'Arial',
                                            size: 20,
                                        })
                                    ],
                                    spacing: {
                                        after: 75
                                    }
                                })
                            ],
                            verticalAlign: VerticalAlign.CENTER
                        })
                    ]
                }),
                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: `${this.getException(exceptions, 'excepcion_fsol_11') ? '■' : '❏'}`,
                                            font: 'Arial',
                                            size: 20,
                                        })
                                    ],
                                    spacing: {
                                        after: 75
                                    }
                                })
                            ],
                            verticalAlign: VerticalAlign.CENTER,
                            width: {
                                size: 20,
                                type: WidthType.PERCENTAGE
                            }
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: 'Solicitud de uso de semillas no orgánicas.',
                                            font: 'Arial',
                                            size: 20,
                                        })
                                    ],
                                    spacing: {
                                        after: 75
                                    }
                                })
                            ],
                            verticalAlign: VerticalAlign.CENTER
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: 'Llenar formato MTO-FSOL-11',
                                            font: 'Arial',
                                            size: 20,
                                        })
                                    ],
                                    spacing: {
                                        after: 75
                                    }
                                })
                            ],
                            verticalAlign: VerticalAlign.CENTER
                        })
                    ]
                }),
                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: `${this.getException(exceptions, 'excepcion_fsol_12') ? '■' : '❏'}`,
                                            font: 'Arial',
                                            size: 20,
                                        })
                                    ],
                                    spacing: {
                                        after: 75
                                    }
                                })
                            ],
                            verticalAlign: VerticalAlign.CENTER,
                            width: {
                                size: 20,
                                type: WidthType.PERCENTAGE
                            }
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: 'Solicitud de periodo para adaptación de instalaciones.',
                                            font: 'Arial',
                                            size: 20,
                                        })
                                    ],
                                    spacing: {
                                        after: 75
                                    }
                                })
                            ],
                            verticalAlign: VerticalAlign.CENTER
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: 'Llenar formato MTO-FSOL-12',
                                            font: 'Arial',
                                            size: 20,
                                        })
                                    ],
                                    spacing: {
                                        after: 75
                                    }
                                })
                            ],
                            verticalAlign: VerticalAlign.CENTER
                        })
                    ]
                }),
                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: `${this.getException(exceptions, 'excepcion_fsol_13') ? '■' : '❏'}`,
                                            font: 'Arial',
                                            size: 20,
                                        })
                                    ],
                                    spacing: {
                                        after: 75
                                    }
                                })
                            ],
                            verticalAlign: VerticalAlign.CENTER,
                            width: {
                                size: 20,
                                type: WidthType.PERCENTAGE
                            }
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: 'Solicitud de uso de alimento no orgánico.',
                                            font: 'Arial',
                                            size: 20,
                                        })
                                    ],
                                    spacing: {
                                        after: 75
                                    }
                                })
                            ],
                            verticalAlign: VerticalAlign.CENTER
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: 'Llenar formato MTO-FSOL-13',
                                            font: 'Arial',
                                            size: 20,
                                        })
                                    ],
                                    spacing: {
                                        after: 75
                                    }
                                })
                            ],
                            verticalAlign: VerticalAlign.CENTER
                        })
                    ]
                }),
                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: `${this.getException(exceptions, 'excepcion_fsol_14') ? '■' : '❏'}`,
                                            font: 'Arial',
                                            size: 20,
                                        })
                                    ],
                                    spacing: {
                                        after: 75
                                    }
                                })
                            ],
                            verticalAlign: VerticalAlign.CENTER,
                            width: {
                                size: 20,
                                type: WidthType.PERCENTAGE
                            }
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: 'Solicitud de excepción para producción paralela.',
                                            font: 'Arial',
                                            size: 20,
                                        })
                                    ],
                                    spacing: {
                                        after: 75
                                    }
                                })
                            ],
                            verticalAlign: VerticalAlign.CENTER
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: 'Llenar formato MTO-FSOL-14-OPR',
                                            font: 'Arial',
                                            size: 20,
                                        })
                                    ],
                                    spacing: {
                                        after: 75
                                    }
                                })
                            ],
                            verticalAlign: VerticalAlign.CENTER
                        })
                    ]
                }),
                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: `${this.getException(exceptions, 'excepcion_fsol_21') ? '■' : '❏'}`,
                                            font: 'Arial',
                                            size: 20,
                                        })
                                    ],
                                    spacing: {
                                        after: 75
                                    }
                                })
                            ],
                            verticalAlign: VerticalAlign.CENTER,
                            width: {
                                size: 20,
                                type: WidthType.PERCENTAGE
                            }
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: 'Solicitud de reconstitución de colmenas.',
                                            font: 'Arial',
                                            size: 20,
                                        })
                                    ],
                                    spacing: {
                                        after: 75
                                    }
                                })
                            ],
                            verticalAlign: VerticalAlign.CENTER
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: 'Llenar formato MTO-FSOL-21',
                                            font: 'Arial',
                                            size: 20,
                                        })
                                    ],
                                    spacing: {
                                        after: 75
                                    }
                                })
                            ],
                            verticalAlign: VerticalAlign.CENTER
                        })
                    ]
                }),
                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: `${this.getException(exceptions, 'excepcion_fsol_24') ? '■' : '❏'}`,
                                            font: 'Arial',
                                            size: 20,
                                        })
                                    ],
                                    spacing: {
                                        after: 75
                                    }
                                })
                            ],
                            verticalAlign: VerticalAlign.CENTER,
                            width: {
                                size: 20,
                                type: WidthType.PERCENTAGE
                            }
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: 'Notificación General de Procesamiento Paralelo.',
                                            font: 'Arial',
                                            size: 20,
                                        })
                                    ],
                                    spacing: {
                                        after: 75
                                    }
                                })
                            ],
                            verticalAlign: VerticalAlign.CENTER
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: 'Llenar formato MTO-FSOL-24',
                                            font: 'Arial',
                                            size: 20,
                                        })
                                    ],
                                    spacing: {
                                        after: 75
                                    }
                                })
                            ],
                            verticalAlign: VerticalAlign.CENTER
                        })
                    ]
                }),
            ],
            margins: {
                left: 70,
                right: 70
            },
            columnWidths: [600, 4519, 4519]
        })
        return table
    }

    public createAditionalDocuments(): Table {
        const table = new Table({
            rows: [
                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: 'Formato',
                                            font: 'Calibri (Cuerpo)',
                                            size: 20,
                                            bold: true
                                        })
                                    ],
                                    spacing: {
                                        after: 75
                                    }
                                })
                            ],
                            verticalAlign: VerticalAlign.CENTER,
                            shading: {
                                fill: '#C0C0C0'
                            }
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: 'Producción Vegetal',
                                            font: 'Calibri (Cuerpo)',
                                            size: 20,
                                            bold: true
                                        })
                                    ],
                                    spacing: {
                                        after: 75
                                    }
                                })
                            ],
                            verticalAlign: VerticalAlign.CENTER,
                            shading: {
                                fill: '#C0C0C0'
                            }
                        })
                    ]
                }),
            ],
            margins: {
                left: 70,
                right: 70
            },
            columnWidths: [3355, 1063, 1063, 1063, 1063, 1063]
        })
        return table
    }

    public createAditionalDocumentsList(): Table {
        const table = new Table({
            rows: [
                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: 'Formato',
                                            font: 'Calibri (Cuerpo)',
                                            size: 16,
                                            bold: true
                                        })
                                    ],
                                    spacing: {
                                        after: 75
                                    }
                                })
                            ],
                            verticalAlign: VerticalAlign.CENTER,
                            shading: {
                                fill: '#C0C0C0'
                            }
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: 'Producción Vegetal',
                                            font: 'Calibri (Cuerpo)',
                                            size: 16,
                                            bold: true
                                        })
                                    ],
                                    spacing: {
                                        after: 75
                                    }
                                })
                            ],
                            verticalAlign: VerticalAlign.CENTER,
                            shading: {
                                fill: '#C0C0C0'
                            }
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: 'Procesamiento / Comercialización',
                                            font: 'Calibri (Cuerpo)',
                                            size: 16,
                                            bold: true
                                        })
                                    ],
                                    spacing: {
                                        after: 75
                                    }
                                })
                            ],
                            verticalAlign: VerticalAlign.CENTER,
                            shading: {
                                fill: '#C0C0C0'
                            }
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: 'Producción Animal',
                                            font: 'Calibri (Cuerpo)',
                                            size: 16,
                                            bold: true
                                        })
                                    ],
                                    spacing: {
                                        after: 75
                                    }
                                })
                            ],
                            verticalAlign: VerticalAlign.CENTER,
                            shading: {
                                fill: '#C0C0C0'
                            }
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: 'Recolección Silvestre',
                                            font: 'Calibri (Cuerpo)',
                                            size: 16,
                                            bold: true
                                        })
                                    ],
                                    spacing: {
                                        after: 75
                                    }
                                })
                            ],
                            verticalAlign: VerticalAlign.CENTER,
                            shading: {
                                fill: '#C0C0C0'
                            }
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: 'Fungi',
                                            font: 'Calibri (Cuerpo)',
                                            size: 16,
                                            bold: true
                                        })
                                    ],
                                    spacing: {
                                        after: 75
                                    }
                                })
                            ],
                            verticalAlign: VerticalAlign.CENTER,
                            shading: {
                                fill: '#C0C0C0'
                            }
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: 'Clase Insecta',
                                            font: 'Calibri (Cuerpo)',
                                            size: 16,
                                            bold: true
                                        })
                                    ],
                                    spacing: {
                                        after: 75
                                    }
                                })
                            ],
                            verticalAlign: VerticalAlign.CENTER,
                            shading: {
                                fill: '#C0C0C0'
                            }
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: 'Grupo de Productores',
                                            font: 'Calibri (Cuerpo)',
                                            size: 16,
                                            bold: true
                                        })
                                    ],
                                    spacing: {
                                        after: 75
                                    }
                                })
                            ],
                            verticalAlign: VerticalAlign.CENTER,
                            shading: {
                                fill: '#C0C0C0'
                            }
                        })
                    ]
                }),
                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: 'Solicitud de inspección y certificación orgánica MTO-FSOL-01',
                                            font: 'Calibri (Cuerpo)',
                                            size: 16
                                        })
                                    ]
                                })
                            ]
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: 'X',
                                            font: 'Calibri (Cuerpo)',
                                            size: 16
                                        })
                                    ]
                                })
                            ],
                            verticalAlign: VerticalAlign.CENTER
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: 'X',
                                            font: 'Calibri (Cuerpo)',
                                            size: 16
                                        })
                                    ]
                                })
                            ],
                            verticalAlign: VerticalAlign.CENTER
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: 'X',
                                            font: 'Calibri (Cuerpo)',
                                            size: 16
                                        })
                                    ]
                                })
                            ],
                            verticalAlign: VerticalAlign.CENTER
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: 'X',
                                            font: 'Calibri (Cuerpo)',
                                            size: 16
                                        })
                                    ]
                                })
                            ],
                            verticalAlign: VerticalAlign.CENTER
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: 'X',
                                            font: 'Calibri (Cuerpo)',
                                            size: 16
                                        })
                                    ]
                                })
                            ],
                            verticalAlign: VerticalAlign.CENTER
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: 'X',
                                            font: 'Calibri (Cuerpo)',
                                            size: 16
                                        })
                                    ]
                                })
                            ],
                            verticalAlign: VerticalAlign.CENTER
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: 'X',
                                            font: 'Calibri (Cuerpo)',
                                            size: 16
                                        })
                                    ]
                                })
                            ],
                            verticalAlign: VerticalAlign.CENTER
                        })
                    ]
                }),
                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: 'Lista de parcelas MTO-FSOL-02',
                                            font: 'Calibri (Cuerpo)',
                                            size: 16
                                        })
                                    ]
                                })
                            ]
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: 'X',
                                            font: 'Calibri (Cuerpo)',
                                            size: 16
                                        })
                                    ]
                                })
                            ],
                            verticalAlign: VerticalAlign.CENTER
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: '',
                                            font: 'Calibri (Cuerpo)',
                                            size: 16
                                        })
                                    ]
                                })
                            ],
                            verticalAlign: VerticalAlign.CENTER
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: 'X',
                                            font: 'Calibri (Cuerpo)',
                                            size: 16
                                        })
                                    ]
                                })
                            ],
                            verticalAlign: VerticalAlign.CENTER
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: 'X',
                                            font: 'Calibri (Cuerpo)',
                                            size: 16
                                        })
                                    ]
                                })
                            ],
                            verticalAlign: VerticalAlign.CENTER
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: 'X',
                                            font: 'Calibri (Cuerpo)',
                                            size: 16
                                        })
                                    ]
                                })
                            ],
                            verticalAlign: VerticalAlign.CENTER
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: 'X',
                                            font: 'Calibri (Cuerpo)',
                                            size: 16
                                        })
                                    ]
                                })
                            ],
                            verticalAlign: VerticalAlign.CENTER
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: 'X',
                                            font: 'Calibri (Cuerpo)',
                                            size: 16
                                        })
                                    ]
                                })
                            ],
                            verticalAlign: VerticalAlign.CENTER
                        })
                    ]
                }),
                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: 'Acuerdo para la certificación firmado MTO-FSOL-03',
                                            font: 'Calibri (Cuerpo)',
                                            size: 16
                                        })
                                    ]
                                })
                            ]
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: 'X',
                                            font: 'Calibri (Cuerpo)',
                                            size: 16
                                        })
                                    ]
                                })
                            ],
                            verticalAlign: VerticalAlign.CENTER
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: 'X',
                                            font: 'Calibri (Cuerpo)',
                                            size: 16
                                        })
                                    ]
                                })
                            ],
                            verticalAlign: VerticalAlign.CENTER
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: 'X',
                                            font: 'Calibri (Cuerpo)',
                                            size: 16
                                        })
                                    ]
                                })
                            ],
                            verticalAlign: VerticalAlign.CENTER
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: 'X',
                                            font: 'Calibri (Cuerpo)',
                                            size: 16
                                        })
                                    ]
                                })
                            ],
                            verticalAlign: VerticalAlign.CENTER
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: 'X',
                                            font: 'Calibri (Cuerpo)',
                                            size: 16
                                        })
                                    ]
                                })
                            ],
                            verticalAlign: VerticalAlign.CENTER
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: 'X',
                                            font: 'Calibri (Cuerpo)',
                                            size: 16
                                        })
                                    ]
                                })
                            ],
                            verticalAlign: VerticalAlign.CENTER
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: 'X',
                                            font: 'Calibri (Cuerpo)',
                                            size: 16
                                        })
                                    ]
                                })
                            ],
                            verticalAlign: VerticalAlign.CENTER
                        })
                    ]
                }),
                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: 'Análisis general de riesgos MTO-FSOL-04',
                                            font: 'Calibri (Cuerpo)',
                                            size: 16
                                        })
                                    ]
                                })
                            ]
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: 'V1',
                                            font: 'Calibri (Cuerpo)',
                                            size: 16
                                        })
                                    ]
                                })
                            ],
                            verticalAlign: VerticalAlign.CENTER
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: 'V2',
                                            font: 'Calibri (Cuerpo)',
                                            size: 16
                                        })
                                    ]
                                })
                            ],
                            verticalAlign: VerticalAlign.CENTER
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: 'V1',
                                            font: 'Calibri (Cuerpo)',
                                            size: 16
                                        })
                                    ]
                                })
                            ],
                            verticalAlign: VerticalAlign.CENTER
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: 'V1',
                                            font: 'Calibri (Cuerpo)',
                                            size: 16
                                        })
                                    ]
                                })
                            ],
                            verticalAlign: VerticalAlign.CENTER
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: 'V1',
                                            font: 'Calibri (Cuerpo)',
                                            size: 16
                                        })
                                    ]
                                })
                            ],
                            verticalAlign: VerticalAlign.CENTER
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: 'V1',
                                            font: 'Calibri (Cuerpo)',
                                            size: 16
                                        })
                                    ]
                                })
                            ],
                            verticalAlign: VerticalAlign.CENTER
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: 'V1',
                                            font: 'Calibri (Cuerpo)',
                                            size: 16
                                        })
                                    ]
                                })
                            ],
                            verticalAlign: VerticalAlign.CENTER
                        })
                    ]
                }),
                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: 'Croquis de parcelas MTO-FSOL-05',
                                            font: 'Calibri (Cuerpo)',
                                            size: 16
                                        })
                                    ]
                                })
                            ]
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: 'X',
                                            font: 'Calibri (Cuerpo)',
                                            size: 16
                                        })
                                    ]
                                })
                            ],
                            verticalAlign: VerticalAlign.CENTER
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: '',
                                            font: 'Calibri (Cuerpo)',
                                            size: 16
                                        })
                                    ]
                                })
                            ],
                            verticalAlign: VerticalAlign.CENTER
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: 'X',
                                            font: 'Calibri (Cuerpo)',
                                            size: 16
                                        })
                                    ]
                                })
                            ],
                            verticalAlign: VerticalAlign.CENTER
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: 'X',
                                            font: 'Calibri (Cuerpo)',
                                            size: 16
                                        })
                                    ]
                                })
                            ],
                            verticalAlign: VerticalAlign.CENTER
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: 'X',
                                            font: 'Calibri (Cuerpo)',
                                            size: 16
                                        })
                                    ]
                                })
                            ],
                            verticalAlign: VerticalAlign.CENTER
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: 'X',
                                            font: 'Calibri (Cuerpo)',
                                            size: 16
                                        })
                                    ]
                                })
                            ],
                            verticalAlign: VerticalAlign.CENTER
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: 'X',
                                            font: 'Calibri (Cuerpo)',
                                            size: 16
                                        })
                                    ]
                                })
                            ],
                            verticalAlign: VerticalAlign.CENTER
                        })
                    ]
                }),
                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: 'Límites con riesgo de contaminación MTO-FSOL-06',
                                            font: 'Calibri (Cuerpo)',
                                            size: 16
                                        })
                                    ]
                                })
                            ]
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: 'X',
                                            font: 'Calibri (Cuerpo)',
                                            size: 16
                                        })
                                    ]
                                })
                            ],
                            verticalAlign: VerticalAlign.CENTER
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: '',
                                            font: 'Calibri (Cuerpo)',
                                            size: 16
                                        })
                                    ]
                                })
                            ],
                            verticalAlign: VerticalAlign.CENTER
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: 'X',
                                            font: 'Calibri (Cuerpo)',
                                            size: 16
                                        })
                                    ]
                                })
                            ],
                            verticalAlign: VerticalAlign.CENTER
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: 'X',
                                            font: 'Calibri (Cuerpo)',
                                            size: 16
                                        })
                                    ]
                                })
                            ],
                            verticalAlign: VerticalAlign.CENTER
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: 'X',
                                            font: 'Calibri (Cuerpo)',
                                            size: 16
                                        })
                                    ]
                                })
                            ],
                            verticalAlign: VerticalAlign.CENTER
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: 'X',
                                            font: 'Calibri (Cuerpo)',
                                            size: 16
                                        })
                                    ]
                                })
                            ],
                            verticalAlign: VerticalAlign.CENTER
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: 'X',
                                            font: 'Calibri (Cuerpo)',
                                            size: 16
                                        })
                                    ]
                                })
                            ],
                            verticalAlign: VerticalAlign.CENTER
                        })
                    ]
                }),
                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: 'Lista de sustancias (Insumos) MTO-FSOL-07',
                                            font: 'Calibri (Cuerpo)',
                                            size: 16
                                        })
                                    ]
                                })
                            ]
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: 'X',
                                            font: 'Calibri (Cuerpo)',
                                            size: 16
                                        })
                                    ]
                                })
                            ],
                            verticalAlign: VerticalAlign.CENTER
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: 'X',
                                            font: 'Calibri (Cuerpo)',
                                            size: 16
                                        })
                                    ]
                                })
                            ],
                            verticalAlign: VerticalAlign.CENTER
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: 'X',
                                            font: 'Calibri (Cuerpo)',
                                            size: 16
                                        })
                                    ]
                                })
                            ],
                            verticalAlign: VerticalAlign.CENTER
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: 'X',
                                            font: 'Calibri (Cuerpo)',
                                            size: 16
                                        })
                                    ]
                                })
                            ],
                            verticalAlign: VerticalAlign.CENTER
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: 'X',
                                            font: 'Calibri (Cuerpo)',
                                            size: 16
                                        })
                                    ]
                                })
                            ],
                            verticalAlign: VerticalAlign.CENTER
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: 'X',
                                            font: 'Calibri (Cuerpo)',
                                            size: 16
                                        })
                                    ]
                                })
                            ],
                            verticalAlign: VerticalAlign.CENTER
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: 'X',
                                            font: 'Calibri (Cuerpo)',
                                            size: 16
                                        })
                                    ]
                                })
                            ],
                            verticalAlign: VerticalAlign.CENTER
                        })
                    ]
                }),
                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: 'Plan Orgánico (Revisar por alcance) MTO-FSOL-08',
                                            font: 'Calibri (Cuerpo)',
                                            size: 16
                                        })
                                    ]
                                })
                            ]
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: 'V1',
                                            font: 'Calibri (Cuerpo)',
                                            size: 16
                                        })
                                    ]
                                })
                            ],
                            verticalAlign: VerticalAlign.CENTER
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: 'V3',
                                            font: 'Calibri (Cuerpo)',
                                            size: 16
                                        })
                                    ]
                                })
                            ],
                            verticalAlign: VerticalAlign.CENTER
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: 'V2',
                                            font: 'Calibri (Cuerpo)',
                                            size: 16
                                        })
                                    ]
                                })
                            ],
                            verticalAlign: VerticalAlign.CENTER
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: 'V4',
                                            font: 'Calibri (Cuerpo)',
                                            size: 16
                                        })
                                    ]
                                })
                            ],
                            verticalAlign: VerticalAlign.CENTER
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: 'V5',
                                            font: 'Calibri (Cuerpo)',
                                            size: 16
                                        })
                                    ]
                                })
                            ],
                            verticalAlign: VerticalAlign.CENTER
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: 'V2',
                                            font: 'Calibri (Cuerpo)',
                                            size: 16
                                        })
                                    ]
                                })
                            ],
                            verticalAlign: VerticalAlign.CENTER
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: 'V6',
                                            font: 'Calibri (Cuerpo)',
                                            size: 16
                                        })
                                    ]
                                })
                            ],
                            verticalAlign: VerticalAlign.CENTER
                        })
                    ]
                }),
                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: 'Lista de ingredientes MTO-FSOL-09',
                                            font: 'Calibri (Cuerpo)',
                                            size: 16
                                        })
                                    ]
                                })
                            ]
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: '',
                                            font: 'Calibri (Cuerpo)',
                                            size: 16
                                        })
                                    ]
                                })
                            ],
                            verticalAlign: VerticalAlign.CENTER
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: 'X',
                                            font: 'Calibri (Cuerpo)',
                                            size: 16
                                        })
                                    ]
                                })
                            ],
                            verticalAlign: VerticalAlign.CENTER
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: '',
                                            font: 'Calibri (Cuerpo)',
                                            size: 16
                                        })
                                    ]
                                })
                            ],
                            verticalAlign: VerticalAlign.CENTER
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: '',
                                            font: 'Calibri (Cuerpo)',
                                            size: 16
                                        })
                                    ]
                                })
                            ],
                            verticalAlign: VerticalAlign.CENTER
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: '',
                                            font: 'Calibri (Cuerpo)',
                                            size: 16
                                        })
                                    ]
                                })
                            ],
                            verticalAlign: VerticalAlign.CENTER
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: '',
                                            font: 'Calibri (Cuerpo)',
                                            size: 16
                                        })
                                    ]
                                })
                            ],
                            verticalAlign: VerticalAlign.CENTER
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: '',
                                            font: 'Calibri (Cuerpo)',
                                            size: 16
                                        })
                                    ]
                                })
                            ],
                            verticalAlign: VerticalAlign.CENTER
                        })
                    ]
                }),
                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: 'Declaración de Cumplimiento USCOEA MTO-FSOL-22',
                                            font: 'Calibri (Cuerpo)',
                                            size: 16
                                        })
                                    ]
                                })
                            ]
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: '',
                                            font: 'Calibri (Cuerpo)',
                                            size: 16
                                        })
                                    ]
                                })
                            ],
                            verticalAlign: VerticalAlign.CENTER
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: 'X',
                                            font: 'Calibri (Cuerpo)',
                                            size: 16
                                        })
                                    ]
                                })
                            ],
                            verticalAlign: VerticalAlign.CENTER
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: '',
                                            font: 'Calibri (Cuerpo)',
                                            size: 16
                                        })
                                    ]
                                })
                            ],
                            verticalAlign: VerticalAlign.CENTER
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: '',
                                            font: 'Calibri (Cuerpo)',
                                            size: 16
                                        })
                                    ]
                                })
                            ],
                            verticalAlign: VerticalAlign.CENTER
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: '',
                                            font: 'Calibri (Cuerpo)',
                                            size: 16
                                        })
                                    ]
                                })
                            ],
                            verticalAlign: VerticalAlign.CENTER
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: '',
                                            font: 'Calibri (Cuerpo)',
                                            size: 16
                                        })
                                    ]
                                })
                            ],
                            verticalAlign: VerticalAlign.CENTER
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: '',
                                            font: 'Calibri (Cuerpo)',
                                            size: 16
                                        })
                                    ]
                                })
                            ],
                            verticalAlign: VerticalAlign.CENTER
                        })
                    ]
                }),
                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: 'Lista de Proveedores MTO-FSOL-23',
                                            font: 'Calibri (Cuerpo)',
                                            size: 16
                                        })
                                    ]
                                })
                            ]
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: '',
                                            font: 'Calibri (Cuerpo)',
                                            size: 16
                                        })
                                    ]
                                })
                            ],
                            verticalAlign: VerticalAlign.CENTER
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: 'X',
                                            font: 'Calibri (Cuerpo)',
                                            size: 16
                                        })
                                    ]
                                })
                            ],
                            verticalAlign: VerticalAlign.CENTER
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: '',
                                            font: 'Calibri (Cuerpo)',
                                            size: 16
                                        })
                                    ]
                                })
                            ],
                            verticalAlign: VerticalAlign.CENTER
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: '',
                                            font: 'Calibri (Cuerpo)',
                                            size: 16
                                        })
                                    ]
                                })
                            ],
                            verticalAlign: VerticalAlign.CENTER
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: '',
                                            font: 'Calibri (Cuerpo)',
                                            size: 16
                                        })
                                    ]
                                })
                            ],
                            verticalAlign: VerticalAlign.CENTER
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: '',
                                            font: 'Calibri (Cuerpo)',
                                            size: 16
                                        })
                                    ]
                                })
                            ],
                            verticalAlign: VerticalAlign.CENTER
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: '',
                                            font: 'Calibri (Cuerpo)',
                                            size: 16
                                        })
                                    ]
                                })
                            ],
                            verticalAlign: VerticalAlign.CENTER
                        })
                    ]
                }),
                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: 'Identificación del Titular / Representante legal',
                                            font: 'Calibri (Cuerpo)',
                                            size: 16
                                        })
                                    ]
                                })
                            ]
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: 'X',
                                            font: 'Calibri (Cuerpo)',
                                            size: 16
                                        })
                                    ]
                                })
                            ],
                            verticalAlign: VerticalAlign.CENTER
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: 'X',
                                            font: 'Calibri (Cuerpo)',
                                            size: 16
                                        })
                                    ]
                                })
                            ],
                            verticalAlign: VerticalAlign.CENTER
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: 'X',
                                            font: 'Calibri (Cuerpo)',
                                            size: 16
                                        })
                                    ]
                                })
                            ],
                            verticalAlign: VerticalAlign.CENTER
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: 'X',
                                            font: 'Calibri (Cuerpo)',
                                            size: 16
                                        })
                                    ]
                                })
                            ],
                            verticalAlign: VerticalAlign.CENTER
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: 'X',
                                            font: 'Calibri (Cuerpo)',
                                            size: 16
                                        })
                                    ]
                                })
                            ],
                            verticalAlign: VerticalAlign.CENTER
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: 'X',
                                            font: 'Calibri (Cuerpo)',
                                            size: 16
                                        })
                                    ]
                                })
                            ],
                            verticalAlign: VerticalAlign.CENTER
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: 'X',
                                            font: 'Calibri (Cuerpo)',
                                            size: 16
                                        })
                                    ]
                                })
                            ],
                            verticalAlign: VerticalAlign.CENTER
                        })
                    ]
                }),
                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: 'RFC del Titular o de la Empresa',
                                            font: 'Calibri (Cuerpo)',
                                            size: 16
                                        })
                                    ]
                                })
                            ]
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: 'X',
                                            font: 'Calibri (Cuerpo)',
                                            size: 16
                                        })
                                    ]
                                })
                            ],
                            verticalAlign: VerticalAlign.CENTER
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: 'X',
                                            font: 'Calibri (Cuerpo)',
                                            size: 16
                                        })
                                    ]
                                })
                            ],
                            verticalAlign: VerticalAlign.CENTER
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: 'X',
                                            font: 'Calibri (Cuerpo)',
                                            size: 16
                                        })
                                    ]
                                })
                            ],
                            verticalAlign: VerticalAlign.CENTER
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: 'X',
                                            font: 'Calibri (Cuerpo)',
                                            size: 16
                                        })
                                    ]
                                })
                            ],
                            verticalAlign: VerticalAlign.CENTER
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: 'X',
                                            font: 'Calibri (Cuerpo)',
                                            size: 16
                                        })
                                    ]
                                })
                            ],
                            verticalAlign: VerticalAlign.CENTER
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: 'X',
                                            font: 'Calibri (Cuerpo)',
                                            size: 16
                                        })
                                    ]
                                })
                            ],
                            verticalAlign: VerticalAlign.CENTER
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: 'X',
                                            font: 'Calibri (Cuerpo)',
                                            size: 16
                                        })
                                    ]
                                })
                            ],
                            verticalAlign: VerticalAlign.CENTER
                        })
                    ]
                }),
                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: 'Documento legal de propiedad de terrenos',
                                            font: 'Calibri (Cuerpo)',
                                            size: 16
                                        })
                                    ]
                                })
                            ]
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: 'X',
                                            font: 'Calibri (Cuerpo)',
                                            size: 16
                                        })
                                    ]
                                })
                            ],
                            verticalAlign: VerticalAlign.CENTER
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: 'X',
                                            font: 'Calibri (Cuerpo)',
                                            size: 16
                                        })
                                    ]
                                })
                            ],
                            verticalAlign: VerticalAlign.CENTER
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: 'X',
                                            font: 'Calibri (Cuerpo)',
                                            size: 16
                                        })
                                    ]
                                })
                            ],
                            verticalAlign: VerticalAlign.CENTER
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: 'X',
                                            font: 'Calibri (Cuerpo)',
                                            size: 16
                                        })
                                    ]
                                })
                            ],
                            verticalAlign: VerticalAlign.CENTER
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: 'X',
                                            font: 'Calibri (Cuerpo)',
                                            size: 16
                                        })
                                    ]
                                })
                            ],
                            verticalAlign: VerticalAlign.CENTER
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: 'X',
                                            font: 'Calibri (Cuerpo)',
                                            size: 16
                                        })
                                    ]
                                })
                            ],
                            verticalAlign: VerticalAlign.CENTER
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: 'X',
                                            font: 'Calibri (Cuerpo)',
                                            size: 16
                                        })
                                    ]
                                })
                            ],
                            verticalAlign: VerticalAlign.CENTER
                        })
                    ]
                }),
                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: 'Aceptación de tarifas y cotización',
                                            font: 'Calibri (Cuerpo)',
                                            size: 16
                                        })
                                    ]
                                })
                            ]
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: 'X',
                                            font: 'Calibri (Cuerpo)',
                                            size: 16
                                        })
                                    ]
                                })
                            ],
                            verticalAlign: VerticalAlign.CENTER
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: 'X',
                                            font: 'Calibri (Cuerpo)',
                                            size: 16
                                        })
                                    ]
                                })
                            ],
                            verticalAlign: VerticalAlign.CENTER
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: 'X',
                                            font: 'Calibri (Cuerpo)',
                                            size: 16
                                        })
                                    ]
                                })
                            ],
                            verticalAlign: VerticalAlign.CENTER
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: 'X',
                                            font: 'Calibri (Cuerpo)',
                                            size: 16
                                        })
                                    ]
                                })
                            ],
                            verticalAlign: VerticalAlign.CENTER
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: 'X',
                                            font: 'Calibri (Cuerpo)',
                                            size: 16
                                        })
                                    ]
                                })
                            ],
                            verticalAlign: VerticalAlign.CENTER
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: 'X',
                                            font: 'Calibri (Cuerpo)',
                                            size: 16
                                        })
                                    ]
                                })
                            ],
                            verticalAlign: VerticalAlign.CENTER
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: 'X',
                                            font: 'Calibri (Cuerpo)',
                                            size: 16
                                        })
                                    ]
                                })
                            ],
                            verticalAlign: VerticalAlign.CENTER
                        })
                    ]
                })
            ],
            margins: {
                left: 70,
                right: 70
            },
            columnWidths: [2355, 1026, 1026, 1026, 1026, 1026, 1026, 1026]
        })
        return table
    }

    public createFooter(): Table {
        const table = new Table({
            rows: [
                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: 'Elaboró:',
                                            font: 'Calibri (Cuerpo)',
                                            size: 22
                                        })
                                    ]
                                })
                            ],
                            width: {
                                size: 33,
                                type: WidthType.PERCENTAGE
                            }
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: 'Revisó:',
                                            font: 'Calibri (Cuerpo)',
                                            size: 22
                                        })
                                    ]
                                })
                            ],
                            width: {
                                size: 33,
                                type: WidthType.PERCENTAGE
                            }
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: 'Autorizó:',
                                            font: 'Calibri (Cuerpo)',
                                            size: 22
                                        })
                                    ]
                                }),
                            ],
                            width: {
                                size: 33,
                                type: WidthType.PERCENTAGE
                            }
                        })
                    ]
                }),
                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: 'Director de certificación',
                                            font: 'Calibri (Cuerpo)',
                                            size: 16,
                                            bold: true
                                        })
                                    ]
                                }),

                            ],
                            width: {
                                size: 33,
                                type: WidthType.PERCENTAGE
                            },
                            shading: {
                                fill: '#C0C0C0'
                            }
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: 'Responsable de calidad',
                                            font: 'Calibri (Cuerpo)',
                                            size: 16,
                                            bold: true
                                        })
                                    ]
                                }),

                            ],
                            width: {
                                size: 33,
                                type: WidthType.PERCENTAGE
                            },
                            shading: {
                                fill: '#C0C0C0'
                            }
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: 'Director general',
                                            font: 'Calibri (Cuerpo)',
                                            size: 16,
                                            bold: true
                                        })
                                    ]
                                }),
                            ],
                            width: {
                                size: 33,
                                type: WidthType.PERCENTAGE
                            },
                            shading: {
                                fill: '#C0C0C0'
                            }
                        })
                    ]
                })
            ],
            margins: {
                left: 70,
                right: 70
            },
            columnWidths: [3213, 3213, 3212]
        })
        return table
    }

    public getMonthFromInt(value: number): string {
        switch (value) {
            case 1:
                return "Jan";
            case 2:
                return "Feb";
            case 3:
                return "Mar";
            case 4:
                return "Apr";
            case 5:
                return "May";
            case 6:
                return "Jun";
            case 7:
                return "Jul";
            case 8:
                return "Aug";
            case 9:
                return "Sept";
            case 10:
                return "Oct";
            case 11:
                return "Nov";
            case 12:
                return "Dec";
            default:
                return "N/A";
        }
    }

    public getBase64FromUrl = async (url) => {
        const data = await fetch(url, {
            headers: {
                'Cache-Control': 'no-cache, no-store, must-revalidate',
                'Pragma': 'no-cache',
                'Expires': 'Sat, 01 Jan 2000 00:00:00 GMT',
                'If-Modified-Since': '0'
            }
        });
        const blob = await data.blob();
        return new Promise((resolve) => {
            const reader = new FileReader();
            reader.readAsDataURL(blob);
            reader.onloadend = function() {
                const base64data = reader.result;
                resolve(base64data);
            }
        });
    }
}
