import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';

import { AuthenticationService, TokenService } from '@app/core/services';

import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { catchError, filter, mergeMap, switchMap, take } from 'rxjs/operators';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
    private isRefreshing = false;
    private refreshTokenSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);

    constructor(
        private authenticationService: AuthenticationService,
        private tokenService: TokenService
    ) { }

    /* intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(request).pipe(catchError(err => {
            if ([401, 403].includes(err.status) && this.authenticationService.userValue) {
                // Auto logout if 401 or 403 response returned from api
                this.authenticationService.logout();
            }

            const error = (err && err.error && err.error.message) || err.statusText;
            return throwError(error);
        }))
    } */

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<Object>> {
        let authReq = req
        const token = this.tokenService.getToken()
        if (token.tokenUser !== '') {
            authReq = this.addTokenHeader(req, token.tokenUser)
        }

        return next.handle(authReq).pipe(catchError(error => {
            //console.log(authReq, error.status, this.authenticationService.userValue, error.status && this.authenticationService.userValue !== null)
            if([401].includes(error.status) && this.authenticationService.userValue !== null && !authReq.url.includes('auth/refresh-token')) {
                /*  Responses status 401 and 403
                    * Try to update the authorization token if it expired but the user has not logged out it is updated, if not, log out the user.
                    * Validate if user has permission to access the service
                */
                return this.handle401Error(authReq, next, token)
            } else if([401].includes(error.status) && authReq.url.includes('auth/refresh-token')) {
                // Auto logout if 401 or 403 response returned from api on refresh-token
                this.authenticationService.logout();
            }

            const err = (error && error.error && error.error.message) || error.statusText;
            //console.log(err)

            return throwError(err)
        }))
        
    }

    private handle401Error(request: HttpRequest<any>, next: HttpHandler, tokens) {
        if(!this.isRefreshing) {
            this.refreshTokenSubject.next(null)

            return this.tokenService.refreshToken(tokens.tokenUser, tokens.tokenRefresh).pipe(
                switchMap((data: any) => {
                    this.isRefreshing = false

                    this.tokenService.saveToken(data.authorization, data.refresh)
                    this.refreshTokenSubject.next(data.authorization)

                    return next.handle(this.addTokenHeader(request, data.authorization))
                }),
                catchError((error) => {
                    this.isRefreshing = false

                    // Auto logout if 403 response returned from api
                    this.authenticationService.logout();

                    const err = (error && error.error && error.error.message) || error.statusText;
                    //console.log(err)

                    return throwError(err)
                })
            )
        }

        return this.refreshTokenSubject.pipe(
            filter(token => token !== null),
            take(1),
            switchMap((token) => next.handle(this.addTokenHeader(request, token)))
        )
    }

    private addTokenHeader(request: HttpRequest<any>, token: string) {
        return request.clone({
            setHeaders: {
                Authorization: token
            }
        })
        
        //return request.clone({ headers: request.headers.set('Authorization', token) });
    }
}