<div *ngIf="data" id="xdLol">

    <!-- <div *ngFor="let item of content">
        <div [ngSwitch]="item.type">
            <dynamic-container *ngSwitchCase="'dynamic'" [data]="item" [form]="formGroup"></dynamic-container>
            <section-container *ngSwitchCase="'section'" [data]="item" [form]="formGroup"></section-container>
            <card-container *ngSwitchCase="'card'" [data]="item" [form]="formGroup"></card-container>
            <table-container *ngSwitchCase="'table'" [data]="item" [form]="formGroup"></table-container>
            <div *ngSwitchDefault>{{item.type}}</div>
        </div>
    </div> -->


    <div [ngSwitch]="attributes.variant">
        <card-right-dynamic *ngSwitchCase="'card-right'" [data]="data" [form]="form"></card-right-dynamic>
        <card-top-dynamic *ngSwitchCase="'card-top'" [data]="data" [form]="form"></card-top-dynamic>
        <table-dynamic *ngSwitchCase="'table'" [data]="data" [form]="form"></table-dynamic>
        <table-form-dynamic *ngSwitchCase="'table-form'" [data]="data" [form]="form"></table-form-dynamic>
        <table-top-dynamic *ngSwitchCase="'table-top'" [data]="data" [form]="form"></table-top-dynamic>
        <table-top-hallazgos-dynamic *ngSwitchCase="'table-top-hallazgos'" [data]="data" [form]="form"></table-top-hallazgos-dynamic>
        <table-top-reply-dynamic *ngSwitchCase="'table-top-reply'" [data]="data" [form]="form"></table-top-reply-dynamic>
        <table-top-substances-dynamic *ngSwitchCase="'table-top-substances'" [data]="data" [form]="form"></table-top-substances-dynamic>
        <div *ngSwitchDefault>{{attributes.variant}}</div>
    </div>



    <!-- <div *ngIf="attributes.variant === 'card-top'" [formGroup]="form">
        <div id="dynamic-container" class="row overflow-auto" style="max-height: 35vh;">
            <div class="col-lg-4" style="margin-bottom: 10px; margin-top: 10px;"
                *ngFor="let formGroup of formArray.controls; let i = index;">
                <div class="card" [ngClass]="{'border-success': i===currentIndex, 'shadow-sm': i===currentIndex}"
                    [id]="i">
                    <div class="card-body">
                        <h5 class="card-title">
                            {{formGroup.get(attributes.card_title_prop) && formGroup.get(attributes.card_title_prop).value ? formGroup.get(attributes.card_title_prop).value.substring(0, 24) : 'Nuevo'}}
                        </h5>
                        <a class="card-link" style="cursor: pointer;" (click)="changeIndex(i)"
                            [ngClass]="{'text-success': i===currentIndex, 'text-info': i!==currentIndex}">{{currentIndex === i ? 'Seleccionado': 'Editar'}}</a>
                    </div>
                </div>
            </div>
        </div>
        <div class="card">
            <div class="card-body">
                <div [formArrayName]="attributes.group_name">
                    <div class="row">
                        <div class="col-12">
                            <h3>{{formArray.controls[currentIndex] && formArray.controls[currentIndex].get(attributes.card_title_prop) ? formArray.controls[currentIndex].get(attributes.card_title_prop).value : 'Nuevo'}}
                            </h3>
                        </div>
                    </div>
                    <containers-fabric *ngFor="let formGroup of formArray.controls; let i = index;"
                        [content]="data.content" [formGroup]="formGroup" [ngClass]="{'d-none': i !== currentIndex}">
                    </containers-fabric>
                </div>
            </div>
        </div>
        <button *ngIf="!attributes.fixed" class="btn btn-info" (click)="addFormGroup($event)">Añadir</button>
    </div>
    <div class="mb-3" *ngIf="attributes.variant === 'table-top'" [formGroup]="form">
        <div id="dynamic-container" class="row overflow-auto" [ngStyle]="{'max-height': attributes.maxHeight ? attributes.maxHeight : '35vh'}">
            <table class="table">
                <thead class="thead-dark">
                    <tr>
                        <th *ngIf="attributes.numbered">#</th>
                        <th *ngFor="let header of attributes.header; let i = index" [ngStyle]="{'font-size': attributes.size ? attributes.size : '15px', 'width': attributes.fixedSize ? attributes.fixedSize[i] : 'unset'}">{{header[0]}}</th>
                        <th>Acciones</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let formGroup of formArray.controls; let i = index;" [ngStyle]="{'background': currentIndex === i ? 'forestgreen' : 'none'}" style="max-height: 5vh ;">
                        <td *ngIf="attributes.numbered" [ngStyle]="{'color': currentIndex === i ? 'white' : 'black'}">{{i + 1}}</td>
                        <td *ngFor="let header of attributes.header" [ngStyle]="{'color': currentIndex === i ? 'white' : 'black'}">{{formGroup.get(header[1]) && formGroup.get(header[1]).value ? formGroup.get(header[1]).value : '...'}}</td>
                        <td>
                            <button type="button" class="btn btn-sm" (click)="changeIndex(i)"><i class="fas fa-edit fa-2x" [ngStyle]="{'color': currentIndex === i ? 'white' : 'black'}"></i></button>
                            <button type="button" class="btn btn-sm" *ngIf="attributes.save"  (click)="save(i)"><i class="fas fa-save fa-2x" [ngStyle]="{'color': currentIndex === i ? 'white' : 'black', 'display': currentIndex === i ? 'block' : 'none'}"></i></button>
                            <button type="button" class="btn btn-sm" *ngIf="attributes.removable" (click)="deleteGroup(i)"><i class="fas fa-trash-alt fa-2x" [ngStyle]="{'color': currentIndex === i ? 'white' : 'black', 'display': currentIndex === i ? 'block' : 'none'}"></i></button>
                        </td>
                    </tr>
                </tbody>
            </table>
            <div *ngIf="formArray.controls.length === 0" style="width: 100%;">
                <p style="text-align: center; color: #828282; margin: 0;">No hay registros</p>
            </div>
        </div>
        <br>

        <div class="card" *ngIf="attributes.search && type !== 'Operador'">
            <div class="card-body">
                <fieldset>
                    <ng-template #rt let-r="result" let-t="term">
                        <strong>
                            <ngb-highlight [result]="r.code" [term]="t"></ngb-highlight>
                        </strong>
                         - 
                        <ngb-highlight [result]="r.name" [term]="t"></ngb-highlight>
                    </ng-template>
                
                
                    <Label><strong>Buscar sustancias de otro operador</strong></Label>
                    <input
                        id="typeahead-http"
                        type="text"
                        class="form-control"
                        [class.is-invalid]="searchFailed"
                        [ngbTypeahead]="search"
                        [resultTemplate]="rt"
                        [inputFormatter]="formatter"
                        (selectItem)="loadSubstance($event)"
                    />
                
                    <small *ngIf="searching" class="form-text text-muted">Buscando...</small>
                    <div class="invalid-feedback" *ngIf="searchFailed">
                        Lo sentimos, las sugerencias no se pudieron cargar.
                    </div>
                </fieldset>
            </div>
        </div>
        <br>
        
        <div *ngIf="!attributes.no_body" class="card">
            <div class="card-body">
                <div [formArrayName]="attributes.group_name">
                    <containers-fabric *ngFor="let formGroup of formArray.controls; let i = index;"
                        [content]="data.content" [formGroup]="formGroup" [ngClass]="{'d-none': i !== currentIndex}">
                    </containers-fabric>
                </div>
            </div>
            <div *ngIf="attributes.addible !== false" class="row">
                <div class="col-lg-12 d-flex justify-content-center">
                    <button  class="btn btn-small btn-info mb-3" *ngIf="!attributes.fixed" (click)="addFormGroup($event)">{{attributes.btn_title ? attributes.btn_title : 'Añadir'}}</button>
                </div>
            </div>
        </div>
    </div>
    <div class="mb-3" *ngIf="attributes.variant === 'table-top-hallazgos'" [formGroup]="form">
        <div id="dynamic-container" class="row overflow-auto" [ngStyle]="{'max-height': attributes.maxHeight ? attributes.maxHeight : '35vh'}">
            <table class="table">
                <thead class="thead-dark">
                    <tr>
                        <th *ngIf="attributes.numbered">#</th>
                        <th *ngFor="let header of attributes.header; let i = index" [ngStyle]="{'font-size': attributes.size ? attributes.size : '15px', 'width': attributes.fixedSize ? attributes.fixedSize[i] : 'unset'}">{{header[0]}}</th>
                        <th>Acciones</th>
                        <th colspan="2">Requiere acción</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let formGroup of formArray.controls; let i = index;" [ngStyle]="{'background': currentIndex === i ? 'forestgreen' : 'none'}" style="max-height: 5vh ;">
                        <td *ngIf="attributes.numbered" [ngStyle]="{'color': currentIndex === i ? 'white' : 'black'}">{{i + 1}}</td>
                        <td *ngFor="let header of attributes.header" [ngStyle]="{'color': currentIndex === i ? 'white' : 'black'}">{{formGroup.get(header[1]) && formGroup.get(header[1]).value ? formGroup.get(header[1]).value : '...'}}</td>
                        <td>
                            <button type="button" class="btn btn-sm" (click)="changeIndex(i)"><i class="fas fa-edit fa-2x" [ngStyle]="{'color': currentIndex === i ? 'white' : 'black'}"></i></button>
                            <button type="button" class="btn btn-sm" *ngIf="attributes.save"  (click)="save(i)"><i class="fas fa-save fa-2x" [ngStyle]="{'color': currentIndex === i ? 'white' : 'black', 'display': currentIndex === i ? 'block' : 'none'}"></i></button>
                            <button type="button" class="btn btn-sm" *ngIf="attributes.removable" (click)="deleteGroup(i)"><i class="fas fa-trash-alt fa-2x" [ngStyle]="{'color': currentIndex === i ? 'white' : 'black', 'display': currentIndex === i ? 'block' : 'none'}"></i></button>
                        </td>
                        <td>
                            <a
                                (click)="open('preview', 'MTO-FRYD-05', 68, true, false, i, formArray.controls[i].value.version)"
                                style="color: blue; text-align: center;"
                            >
                                <h5 style="margin: 0px;">MTO-FRYD-05</h5>
                                <h6 style="color: black; margin: 0px;">Notificación de No Conformidad</h6>
                            </a>
                        </td>
                        <td style="text-align: center;">
                            <input class="btn btn-sm" type="checkbox" (change)="requiresAction(i)" style="width: 32px; height: 32px;" [checked]="getRequiresAction(i)" [disabled]="formArray.controls[i].value.ncNotificationSent ? 'true' : null" />
                        </td>
                    </tr>
                </tbody>
            </table>
            <div *ngIf="formArray.controls.length === 0" style="width: 100%;">
                <p style="text-align: center; color: #828282; margin: 0;">No hay registros</p>
            </div>
        </div>
        <br>

        <div class="col-12">
            <div class="row justify-content-center align-items-center">
                <div class="col-4">
                    Correcciones a cosiderar
                </div>
                <div class="col-8">
                    <textarea id="corrections" class="form-control" rows="3" (change)="updateCorrecciones($event)" (input)="updateCorrecciones($event, true)" [value]="formArray.controls[currentIndex].value.correcciones" style="resize: none;" ></textarea>
                </div>
            </div>

            <div *ngIf="formArray.controls[currentIndex].value.ncNotificationSent" class="row justify-content-center align-items-center mt-2" >
                <div class="col-4">
                    Comentarios del Operador
                </div>
                <div class="col-8">
                    <textarea class="form-control" rows="3" [value]="getCommentsOperator(formArray.controls[currentIndex].value.files)" style="resize: none;" disabled="true" ></textarea>
                </div>
            </div>

            <br>
            
            <div *ngIf="!formArray.controls[currentIndex].value.ncNotificationSent">
                <h6>Seleccione los formatos que el Operador deba actualizar:</h6>
                <div class="row">
                    <div class="col-12">
                        <div *ngFor="let option of formArray.controls[currentIndex].value.forms" class="form-check form-check-inline col-4" style="margin: 0px; padding: 0px;">
                            <label class="form-check-label">
                                <input class="form-check-input" type="checkbox" [value]="option.id" [checked]="option.selected" (change)="updateCheckbox(currentIndex, option.id)" />
                                {{ option.form }} {{ option.title}}
                            </label>
                        </div>
                    </div>
                </div>
            </div>

            <div *ngIf="formArray.controls[currentIndex].value.ncNotificationSent">
                <div class="row">
                    <div class="col-6">
                        <h6>Formatos actualizados por el Operador</h6>

                        <div class="row">
                            <div class="col-6 mb-2" *ngFor="let docs of formArray.controls[currentIndex].value.docs">
                                <div class="card home-fade-in hover-card">
                                    <div class="card-body bg-white text-center btn"
                                        (click)="open('preview', docs.form, docs.idDoc, false, true)"
                                    >
                                        <h5 class="card-title">{{ docs.form }}</h5>
                                        <a class="text-info">Consultar</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="mt-1" *ngIf="formArray.controls[currentIndex].value.docs.length === 0">
                            No se actualizaron formatos
                        </div>
                    </div>

                    <div class="col-6">
                        <h6>Archivos adjuntos por el Operador</h6>
                        <div class="mt-1" *ngIf="formArray.controls[currentIndex].value.files.length > 0">
                            <div style="margin-bottom: 10px;" *ngFor="let file of formArray.controls[currentIndex].value.files">
                              <a class="bg-info text-light" style="padding: 5px 20px 5px 20px; border-radius: 5px;" target="_blank"
                                [href]="file.url"><i style="margin-right: 10px;" class="fas fa-file"></i>{{getFilename(file.url)}}</a>
                            </div>
                        </div>
                        <div class="mt-1" *ngIf="formArray.controls[currentIndex].value.files.length === 0">
                            Sin archivos adjuntos
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <br>
        
        <div *ngIf="!attributes.no_body" class="card">
            <div class="card-body">
                <div [formArrayName]="attributes.group_name">
                    <containers-fabric *ngFor="let formGroup of formArray.controls; let i = index;"
                        [content]="data.content" [formGroup]="formGroup" [ngClass]="{'d-none': i !== currentIndex}">
                    </containers-fabric>
                </div>
            </div>
            <div *ngIf="attributes.addible !== false" class="row">
                <div class="col-lg-12 d-flex justify-content-center">
                    <button  class="btn btn-small btn-info mb-3" *ngIf="!attributes.fixed" (click)="addFormGroup($event)">{{attributes.btn_title ? attributes.btn_title : 'Añadir'}}</button>
                </div>
            </div>
        </div>
    </div>
    <div class="mb-3" *ngIf="attributes.variant === 'table-top-substances'" [formGroup]="form">
        <div class="row">
            <div class="col-md-5">
                <div id="dynamic-container" class="row overflow-auto" [ngStyle]="{'max-height': attributes.maxHeight ? attributes.maxHeight : '35vh'}">
                    <table class="table">
                        <thead class="thead-dark">
                            <tr>
                                <th *ngIf="attributes.numbered">#</th>
                                <th *ngFor="let header of attributes.header; let i = index" [ngStyle]="{'font-size': attributes.size ? attributes.size : '15px', 'width': attributes.fixedSize ? attributes.fixedSize[i] : 'unset'}">{{header[0]}}</th>
                                <th>Acciones</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let formGroup of formArray.controls; let i = index;" [ngStyle]="{'background': currentIndex === i ? 'forestgreen' : 'none'}" style="max-height: 5vh ;">
                                <td *ngIf="attributes.numbered" [ngStyle]="{'color': currentIndex === i ? 'white' : 'black'}">{{i + 1}}</td>
                                <td *ngFor="let header of attributes.header" [ngStyle]="{'color': currentIndex === i ? 'white' : 'black'}">{{formGroup.get(header[1]) && formGroup.get(header[1]).value ? formGroup.get(header[1]).value : '...'}}</td>
                                <td>
                                    <button type="button" class="btn btn-sm" (click)="changeIndex(i)"><i class="fas fa-edit fa-2x" [ngStyle]="{'color': currentIndex === i ? 'white' : 'black'}"></i></button>
                                    <button type="button" class="btn btn-sm" *ngIf="attributes.save"  (click)="save(i)" [ngStyle]="{'display': currentIndex === i ? 'block' : 'none'}"><i class="fas fa-save fa-2x" [ngStyle]="{'color': currentIndex === i ? 'white' : 'black'}"></i></button>
                                    <button type="button" class="btn btn-sm" *ngIf="attributes.removable" (click)="deleteGroup(i)" [ngStyle]="{'display': currentIndex === i ? 'block' : 'none'}"><i class="fas fa-trash-alt fa-2x" [ngStyle]="{'color': currentIndex === i ? 'white' : 'black'}"></i></button>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <div *ngIf="formArray.controls.length === 0" style="width: 100%;">
                        <p style="text-align: center; color: #828282; margin: 0;">No hay registros</p>
                    </div>
                </div>
            </div>

            <div class="col-md-7">
                <div class="card" *ngIf="attributes.search && type !== 'Operador'">
                    <div class="card-body">
                        <fieldset>
                            <ng-template #rt let-r="result" let-t="term">
                                <strong>
                                    <ngb-highlight [result]="r.code" [term]="t"></ngb-highlight>
                                </strong>
                                - 
                                <ngb-highlight [result]="r.name" [term]="t"></ngb-highlight>
                            </ng-template>
                        
                        
                            <Label><strong>Buscar sustancias de otro operador</strong></Label>
                            <input
                                id="typeahead-http"
                                type="text"
                                class="form-control"
                                [class.is-invalid]="searchFailed"
                                [ngbTypeahead]="search"
                                [resultTemplate]="rt"
                                [inputFormatter]="formatter"
                                (selectItem)="loadSubstance($event)"
                            />
                        
                            <small *ngIf="searching" class="form-text text-muted">Buscando...</small>
                            <div class="invalid-feedback" *ngIf="searchFailed">
                                Lo sentimos, las sugerencias no se pudieron cargar.
                            </div>
                        </fieldset>
                    </div>
                </div>
                
                <div *ngIf="!attributes.no_body" class="card">
                    <div class="card-body">
                        <div [formArrayName]="attributes.group_name">
                            <containers-fabric *ngFor="let formGroup of formArray.controls; let i = index;"
                                [content]="data.content" [formGroup]="formGroup" [ngClass]="{'d-none': i !== currentIndex}">
                            </containers-fabric>
                        </div>
                    </div>
                    <div *ngIf="attributes.addible !== false" class="row">
                        <div class="col-lg-12 d-flex justify-content-center">
                            <button  class="btn btn-small btn-info mb-3" *ngIf="!attributes.fixed" (click)="addFormGroup($event)">{{attributes.btn_title ? attributes.btn_title : 'Añadir'}}</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="mb-3" *ngIf="attributes.variant === 'table-top-reply'" [formGroup]="form">
        <div id="dynamic-container" class="row overflow-auto" style="max-height: 35vh;">
            <table class="table">
                <thead class="thead-dark">
                    <tr>
                        <th *ngIf="attributes.numbered">#</th>
                        <th *ngFor="let header of attributes.header">{{header[0]}}</th>
                        <th *ngIf="!attributes.fixed">Acciones</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let formGroup of formArray.controls; let i = index;" [ngStyle]="{'background': currentIndex === i ? 'forestgreen' : 'none'}">
                        <td *ngIf="attributes.numbered" [ngStyle]="{'color': currentIndex === i ? 'white' : 'black'}">{{i + 1}}</td>
                        <td *ngFor="let header of attributes.header" [ngStyle]="{'color': currentIndex === i ? 'white' : 'black'}">{{formGroup.get(header[1]) && formGroup.get(header[1]).value ? formGroup.get(header[1]).value.substring(0, 24) : '...'}}</td>
                        <td *ngIf="!attributes.fixed">
                            <button type="button" class="btn btn-sm" [class]="currentIndex === i ? 'btn-success' : 'btn-outline-success'" (click)="changeIndex(i)">{{currentIndex === i ? 'Activo' : 'Revisar'}}</button>
                            <button type="button" class="btn btn-sm btn-danger ml-2" *ngIf="attributes.removable" (click)="deleteGroup(i)">Eliminar</button>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
        
        <div class="card mb-3">
            <div class="card-body">
                <label>Replicar datos de:</label>
                <select class="form-control browser-default select" (change)="copy($event)">
                    <option style="display: none;">Seleccione una opción</option>
                    <option *ngFor="let option of formArray.controls">{{option.get(attributes.header[0][1]) && option.get(attributes.header[0][1]).value ? option.get(attributes.header[0][1]).value.substring(0, 24) : '...'}}</option>
                </select>
            </div>
        </div>

        <div *ngIf="!attributes.no_body" class="card">
            <div class="card-body">
                <div [formArrayName]="attributes.group_name">
                    <containers-fabric *ngFor="let formGroup of formArray.controls; let i = index;"
                        [content]="data.content" [formGroup]="formGroup" [ngClass]="{'d-none': i !== currentIndex}">
                    </containers-fabric>
                </div>
            </div>
            <div *ngIf="attributes.addible !== false" class="row">
                <div class="col-lg-12 d-flex justify-content-center">
                    <button  class="btn btn-small btn-info mb-3" *ngIf="!attributes.fixed" (click)="addFormGroup($event)">{{attributes.btn_title ? attributes.btn_title : 'Añadir'}}</button>
                </div>
            </div>
        </div>
    </div>
    <div *ngIf="attributes.variant === 'table'" [formGroup]="form">
        <div class="card" *ngIf="attributes.search && type !== 'Operador'">
            <div class="card-body">
                <fieldset>
                    <ng-template #rt let-r="result" let-t="term">
                        <strong>
                            <ngb-highlight [result]="r.code" [term]="t"></ngb-highlight>
                        </strong>
                         - 
                        <ngb-highlight [result]="r.name" [term]="t"></ngb-highlight>
                    </ng-template>
                
                
                    <Label><strong>Buscar sustancias de otro operador</strong></Label>
                    <input
                        id="typeahead-http"
                        type="text"
                        class="form-control"
                        [class.is-invalid]="searchFailed"
                        [ngbTypeahead]="search"
                        [resultTemplate]="rt"
                        [inputFormatter]="formatter"
                        (selectItem)="loadSubstance($event)"
                    />
                
                    <small *ngIf="searching" class="form-text text-muted">Buscando...</small>
                    <div class="invalid-feedback" *ngIf="searchFailed">
                        Lo sentimos, las sugerencias no se pudieron cargar.
                    </div>
                </fieldset>
            </div>
        </div>
        <br>

        <div class="card">
            <div class="card-body">
                <div [formArrayName]="attributes.group_name">
                    <div class="row">
                        <div class="col-12">
                            <h3>{{attributes.title}}
                            </h3>
                        </div>
                    </div>
                    <div *ngIf="formArray.controls.length === 0">
                        <p style="text-align: center; color: #828282; margin: 0;">No hay registros</p>
                    </div>
                    <containers-fabric *ngFor="let formGroup of formArray.controls; let i = index;"
                        [content]="data.content" [formGroup]="formGroup" [ngClass]="{'d-none': i !== currentIndex}">
                    </containers-fabric>
                </div>
            </div>
        </div>
        <div style="margin-top: 20px;">
            <h5 style="margin-top: 20px; float: left; margin-top: 20px; margin-bottom: 20px;" >
                {{attributes.table_title}}
            </h5>
            <button class="btn btn-primary align-self-center" (click)="addFormGroup($event)" style="float: right; margin-top: 15px;" [ngStyle]="{'margin-bottom': attributes.table_title ? '' : '20px'}">
                {{attributes.btn_title}}
            </button>
        </div>
        
        <table class="table" style="margin-top: 20px;">
            <thead class="thead-dark">
                <tr>
                    <th *ngIf="attributes.numbered">#</th>
                    <th scope="col" *ngFor="let header of attributes.headers">{{header[0]}}</th>
                    <th>Acciones</th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let formGroup of formArray.controls; let i = index;" [ngStyle]="{'background': currentIndex === i ? 'forestgreen' : 'none'}">
                    <td *ngIf="attributes.numbered" [ngStyle]="{'color': currentIndex === i ? 'white' : 'black'}">{{i+1}}</td>
                    <td *ngFor="let header of attributes.headers" [ngClass]="{'text-danger': !formGroup.get(header[1]) || !formGroup.get(header[1]).value}" [ngStyle]="{'color': currentIndex === i ? 'white' : 'black'}">{{formGroup.get(header[1]) && formGroup.get(header[1]).value ? formGroup.get(header[1]).value.substring(0, 24) : "---"}}</td>
                    <td>
                        <button (click)="changeIndex(i)" type="button" class="btn btn-sm mb-1 mr-1" [class]="currentIndex === i ? 'btn-warning' : 'btn-secondary'">
                            {{currentIndex === i ? 'Activo' : 'Editar'}}
                        </button>
                        <button (click)="deleteGroup(i)" type="button" class="btn btn-danger  btn-sm">
                            Remover
                        </button>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
    <div *ngIf="attributes.variant === 'card-right'" [formGroup]="form">
        <div id="dynamic-container" class="row">
            <div class="col-lg-6">
                <div [formArrayName]="attributes.group_name">
                    <containers-fabric *ngFor="let formGroup of formArray.controls; let i = index;"
                        [content]="data.content" [formGroup]="formGroup" [ngClass]="{'d-none': i !== currentIndex}">
                    </containers-fabric>
                </div>
            </div>
            <div class="col-lg-6" style="max-height: 500px; overflow: scroll;">
                <div *ngFor="let formGroup of formArray.controls; let i = index;">
                    <div class="card" style="margin-bottom: 10px;" [ngClass]="{'border-success': i===currentIndex, 'shadow-sm': i===currentIndex}" [id]="i">
                        <div class="card-body">
                            <h5 class="card-title">
                                {{formGroup.get(attributes.card_title_prop) && formGroup.get(attributes.card_title_prop).value ? formGroup.get(attributes.card_title_prop).value : 'Nuevo'}}
                            </h5>
                            <h6>
                                {{formGroup.get(attributes.card_title_carg) && formGroup.get(attributes.card_title_carg).value ? formGroup.get(attributes.card_title_carg).value : ''}}
                            </h6>
                            <a class="card-link" style="cursor: pointer;" (click)="changeIndex(i)" [ngClass]="{'text-success': i===currentIndex, 'text-info': i!==currentIndex}">{{currentIndex === i ? 'Seleccionado': 'Editar'}}</a>
                            <a *ngIf="!attributes.deleted" class="card-link" style="cursor: pointer;" (click)="deleteGroup(i)" [ngClass]="'text-danger'">Eliminar</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <button *ngIf="!attributes.fixed" class="btn btn-info" (click)="addFormGroup($event)">Añadir</button>
    </div>
    <div style="overflow: scroll; max-height: 70vh;" *ngIf="attributes.variant === 'table-form'" [formGroup]="form">
        <h3>{{attributes.title}}</h3>
        <table class="table" style="margin-top: 20px;">
            <thead *ngIf="attributes.minWidth">
                <tr>
                    <th style="width: 20px;" *ngIf="attributes.numbered">#</th>
                    <th [ngStyle]="{'min-width': attributes.minWidth}" scope="col" *ngFor="let header of attributes.header">{{header}}</th>
                    <th *ngIf="!attributes.fixed">Acciones</th>
                </tr>
            </thead>
            <thead *ngIf="!attributes.minWidth">
                <tr>
                    <th style="width: 20px;" *ngIf="attributes.numbered">#</th>
                    <th scope="col" *ngFor="let header of attributes.header; let i = index" [ngStyle]="{'min-width': attributes.fixedSize ? attributes.fixedSize[i] : 'unset'}">{{header}}</th>
                    <th *ngIf="!attributes.fixed">Acciones</th>
                </tr>
            </thead>
            <tbody [formArrayName]="attributes.group_name">
                <tr *ngFor="let formGroup of formArray.controls; let i = index">
                    <td *ngIf="attributes.numbered" style="width: 20px;">{{i + 1}}</td>
                    <td class="align-top" *ngFor="let itemx of data.content[0].content" [ngSwitch]="itemx.type">
                        <input-element *ngSwitchCase="'input'" [data]="itemx" [form]="formGroup" (keyup.enter)="addFormGroup($event)"></input-element>
                        <select-element *ngSwitchCase="'select'" [data]="itemx" [form]="formGroup"></select-element>
                        <button-element *ngSwitchCase="'button'" [data]="itemx" [form]="formGroup"></button-element>
                        <checkbox-group-element *ngSwitchCase="'checkbox'" [data]="itemx" [form]="formGroup"></checkbox-group-element>
                        <radio-group-element *ngSwitchCase="'radio'" [data]="itemx" [form]="formGroup"></radio-group-element>
                        <text-element *ngSwitchCase="'text'" [data]="itemx"></text-element>
                        <textarea-element *ngSwitchCase="'textarea'" [data]="itemx" [form]="formGroup"></textarea-element>
                        <file-upload-element *ngSwitchCase="'fileupload'" [data]="itemx" [form]="formGroup"></file-upload-element>
                        <search-element *ngSwitchCase="'search'" [data]="itemx" [form]="formGroup"></search-element>
                        <dynamic-container *ngSwitchCase="'dynamic'" [data]="itemx" [form]="formGroup"></dynamic-container>
                        <multiselect-element *ngSwitchCase="'multiselect'" [data]="itemx" [form]="formGroup"></multiselect-element>
                        <section-container *ngSwitchCase="'section'" [data]="itemx" [form]="formGroup"></section-container>
                        <div *ngSwitchDefault>Elemento desconocido</div>
                    </td>
                    <td *ngIf="!attributes.fixed" style="width: 10%;">
                        <button type="button" class="btn btn-danger btn-block" (click)="deleteGroup(i)">Eliminar</button>
                    </td>
                </tr>
            </tbody>
        </table>  
        <div *ngIf="formArray.controls?.length === 0" style="display: flex; flex-direction: column; justify-content: center; align-items: center; margin-bottom: 16px;">
            <span style="opacity: 0.5;">No se tiene ningún registro</span>
        </div>
        <div *ngIf="!attributes.fixed" style="display: flex; flex-direction: column; justify-content: center; align-items: center;" class="mb-2">
            <button type="button" class="btn btn-info" (click)="addFormGroup($event)">{{attributes.btn_title ? attributes.btn_title : 'Añadir'}}</button>
        </div>      
    </div> -->
</div>