<div class="container" >
  <div class="row align-items-center text-center" style="height: 100vh;">
    <form class="form-recover-password fade-in" [formGroup]="recoverForm" (ngSubmit)="onSubmit()">
      <img class="mb-4 fade-in" src="/assets/icons/ecert.png" alt="" width="72" height="72" />
      <h3 class="h3 mb-3 font-weight-normal">Reestablece tu contraseña.</h3>


      <div *ngIf="isTemp" class="form-group text-left pt-2 pb-2 temp_pass">
        <label for="temp">Contraseña temporal</label>
        <span class="icon-eye" (click)="showPassword1()">
          <i class="{{isShowed1 ? 'fas fa-eye-slash' : 'fas fa-eye'}}"></i>
        </span>
        <input
          type = "{{isShowed1 ? 'text' : 'password'}}"
          formControlName="temp"
          class="form-control"
          [ngClass]="{ 'is-invalid': submitted && f.temp.errors }"
        />
        <div *ngIf="submitted && f.temp.errors" class="invalid-feedback">
          <div *ngIf="f.temp.errors.required">Contraseña temporal requerida</div>
        </div>
      </div>
      <div class="form-group text-left pt-2 pb-2 newPass">
        <label for="newPassword">Contraseña nueva</label>
        <span class="icon-eye" (click)="showPassword2()">
          <i class="{{isShowed2 ? 'fas fa-eye-slash' : 'fas fa-eye'}}"></i>
        </span>
        <input
          type = "{{isShowed2 ? 'text' : 'password'}}"
          formControlName="newPassword"
          class="form-control"
          [ngClass]="{ 'is-invalid': submitted && f.newPassword.errors }"
        />
        <div *ngIf="submitted && f.newPassword.errors" class="invalid-feedback">
          <div *ngIf="f.newPassword.errors.required">Contraseña nueva requerida</div>
        </div>
      </div>
      <div class="form-group text-left pt-2 pb-2 confirmPass">
        <label for="confirmPassword">Confirmar nueva contraseña</label>
        <span class="icon-eye" (click)="showPassword3()">
          <i class="{{isShowed3 ? 'fas fa-eye-slash' : 'fas fa-eye'}}"></i>
        </span>
        <input
          type = "{{isShowed3 ? 'text' : 'password'}}"
          formControlName="confirmPassword"
          class="form-control"
          [ngClass]="{ 'is-invalid': submitted && f.confirmPassword.errors }"
        />
        <div *ngIf="submitted && f.confirmPassword.errors" class="invalid-feedback">
          <div *ngIf="f.confirmPassword.errors.required">Contraseña nueva requerida</div>
        </div>
      </div>
      <div class="form-group fade-in">
        <button [disabled]="loading" class="btn btn-primary">
          <span
            *ngIf="loading"
            class="spinner-border spinner-border-sm mr-1"
          ></span>
          Acceder
        </button>
      </div>
    </form>
  </div>
</div>
<!-- <div style="background-color: #030303;" class="native-fixed fade-in">
  <a
    style="color: #ffffff;"
    class="native-link fade-in"
    href="https://digitalignition.com.mx/es/home/"
  >
    <div
      class="native-sponsor"
      style="background-color: #ffffff; color: #030303;"
    >
      En Desarrollo
    </div>
    <div class="native-text">
      Digital Ignition — El sistema se encuentra en fase de desarrollo por lo que algunas funcionalidades pueden no estar disponibles.
    </div>
  </a>
</div> -->
