<div [formGroup]="form">
    <div class="card" *ngIf="attributes.search && type !== 'Operador'">
        <div class="card-body">
            <fieldset>
                <ng-template #rt let-r="result" let-t="term">
                    <strong>
                        <ngb-highlight [result]="r.code" [term]="t"></ngb-highlight>
                    </strong>
                     - 
                    <ngb-highlight [result]="r.name" [term]="t"></ngb-highlight>
                </ng-template>
            
            
                <Label><strong>Buscar sustancias de otro operador</strong></Label>
                <input
                    id="typeahead-http"
                    type="text"
                    class="form-control"
                    [class.is-invalid]="searchFailed"
                    [ngbTypeahead]="search"
                    [resultTemplate]="rt"
                    [inputFormatter]="formatter"
                    (selectItem)="loadSubstance($event)"
                />
            
                <small *ngIf="searching" class="form-text text-muted">Buscando...</small>
                <div class="invalid-feedback" *ngIf="searchFailed">
                    Lo sentimos, las sugerencias no se pudieron cargar.
                </div>
            </fieldset>
        </div>
    </div>
    <br>

    <div class="card">
        <div class="card-body">
            <div [formArrayName]="attributes.group_name">
                <div class="row">
                    <div class="col-12">
                        <h3>{{attributes.title}}
                        </h3>
                    </div>
                </div>
                <div *ngIf="formArray.controls.length === 0">
                    <p style="text-align: center; color: #828282; margin: 0;">No hay registros</p>
                </div>
                <containers-fabric *ngFor="let formGroup of formArray.controls; let i = index;"
                    [content]="data.content" [formGroup]="formGroup" [ngClass]="{'d-none': i !== currentIndex}">
                </containers-fabric>
            </div>
        </div>
    </div>
    <div style="margin-top: 20px;">
        <h5 style="margin-top: 20px; float: left; margin-top: 20px; margin-bottom: 20px;" >
            {{attributes.table_title}}
        </h5>
        <button class="btn btn-primary align-self-center" (click)="addFormGroup($event)" style="float: right; margin-top: 15px;" [ngStyle]="{'margin-bottom': attributes.table_title ? '' : '20px'}">
            {{attributes.btn_title}}
        </button>
    </div>
    
    <table class="table" style="margin-top: 20px;">
        <thead class="thead-dark">
            <tr>
                <th *ngIf="attributes.numbered">#</th>
                <th scope="col" *ngFor="let header of attributes.headers">{{header[0]}}</th>
                <th>Acciones</th>
            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let formGroup of formArray.controls; let i = index;" [ngStyle]="{'background': currentIndex === i ? 'forestgreen' : 'none'}">
                <td *ngIf="attributes.numbered" [ngStyle]="{'color': currentIndex === i ? 'white' : 'black'}">{{i+1}}</td>
                <td *ngFor="let header of attributes.headers" [ngClass]="{'text-danger': !formGroup.get(header[1]) || !formGroup.get(header[1]).value}" [ngStyle]="{'color': currentIndex === i ? 'white' : 'black'}">{{formGroup.get(header[1]) && formGroup.get(header[1]).value ? formGroup.get(header[1]).value.substring(0, 24) : "---"}}</td>
                <td>
                    <button (click)="changeIndex(i)" type="button" class="btn btn-sm mb-1 mr-1" [class]="currentIndex === i ? 'btn-warning' : 'btn-secondary'">
                        {{currentIndex === i ? 'Activo' : 'Editar'}}
                    </button>
                    <button (click)="deleteGroup(i)" type="button" class="btn btn-danger  btn-sm">
                        Remover
                    </button>
                </td>
            </tr>
        </tbody>
    </table>
</div>