import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '@environments/environment';

@Injectable({ providedIn: 'root' })
export class CataloguesService {
    constructor(private http: HttpClient) { }

    //Catalogo de pais/estado/ciudad
    getCountries() {
        const jwtToken = (document.cookie.split(';').find(x => x.includes('jwtToken')) || '=').split('=')[1]

        const headers = {
            'Content-Type': 'application/json',
            'Authorization': jwtToken
        };

        return this.http.get<any>(`${environment.apiUrl}/catalogues/countries`, { headers });
    }

    getStates(country) {
        //console.log(country)
        const jwtToken = (document.cookie.split(';').find(x => x.includes('jwtToken')) || '=').split('=')[1]

        const headers = {
            'Content-Type': 'application/json',
            'Authorization': jwtToken
        };

        return this.http.get<any>(`${environment.apiUrl}/catalogues/states/${country}`, { headers });
    }

    getCities(state) {
        const jwtToken = (document.cookie.split(';').find(x => x.includes('jwtToken')) || '=').split('=')[1]

        const headers = {
            'Content-Type': 'application/json',
            'Authorization': jwtToken
        };

        return this.http.get<any>(`${environment.apiUrl}/catalogues/cities/${state}`, { headers });
    }

    // Catalogo de roles de usuarios
    getRoles() {
        const jwtToken = (document.cookie.split(';').find(x => x.includes('jwtToken')) || '=').split('=')[1]

        const headers = {
            'Content-Type': 'application/json',
            'Authorization': jwtToken
        };

        return this.http.get<any>(`${environment.apiUrl}/catalogues/roles`, { headers });
    }

    getCustomerTypes() {
        const jwtToken = (document.cookie.split(';').find(x => x.includes('jwtToken')) || '=').split('=')[1]

        const headers = {
            'Content-Type': 'application/json',
            'Authorization': jwtToken
        };

        return this.http.get<any>(`${environment.apiUrl}/catalogues/customer-types`, { headers });
    }

    getRecordTypes(version) {
        const jwtToken = (document.cookie.split(';').find(x => x.includes('jwtToken')) || '=').split('=')[1]

        const headers = {
            'Content-Type': 'application/json',
            'Authorization': jwtToken
        };

        return this.http.get<any>(`${environment.apiUrl}/catalogues/record-types/${version}`, { headers });
    }


    //Catalogo de laboratorios
    getLaboratories() {
        const jwtToken = (document.cookie.split(';').find(x => x.includes('jwtToken')) || '=').split('=')[1]

        const headers = {
            'Content-Type': 'application/json',
            'Authorization': jwtToken
        };

        return this.http.get<any>(`${environment.apiUrl}/catalogues/laboratories`, { headers });
    }

    createLaboratories(data) {
        const jwtToken = (document.cookie.split(';').find(x => x.includes('jwtToken')) || '=').split('=')[1]

        const headers = {
            'Content-Type': 'application/json',
            'Authorization': jwtToken
        };

        return this.http.post<any>(`${environment.apiUrl}/catalogues/laboratories`, data, { headers });
    }

    updateLaboratories(idLaboratory, data) {
        const jwtToken = (document.cookie.split(';').find(x => x.includes('jwtToken')) || '=').split('=')[1]

        const headers = {
            'Content-Type': 'application/json',
            'Authorization': jwtToken
        };

        return this.http.patch<any>(`${environment.apiUrl}/catalogues/laboratories/${idLaboratory}`, data, { headers });
    }

    deleteLaboratory(idLaboratory) {
        const jwtToken = (document.cookie.split(';').find(x => x.includes('jwtToken')) || '=').split('=')[1]

        const headers = {
            'Content-Type': 'application/json',
            'Authorization': jwtToken
        };

        return this.http.delete(`${environment.apiUrl}/catalogues/laboratories/${idLaboratory}`, { headers })
    }

    getOnlyLaboratories() {
        const jwtToken = (document.cookie.split(';').find(x => x.includes('jwtToken')) || '=').split('=')[1]

        const headers = {
            'Content-Type': 'application/json',
            'Authorization': jwtToken
        };

        return this.http.get<any>(`${environment.apiUrl}/catalogues/formLaboratories`, { headers });
    }

    getLaboratoriesById(idLaboratory) {
        const jwtToken = (document.cookie.split(';').find(x => x.includes('jwtToken')) || '=').split('=')[1]

        const headers = {
            'Content-Type': 'application/json',
            'Authorization': jwtToken
        };

        return this.http.get<any>(`${environment.apiUrl}/catalogues/laboratoryTests/${idLaboratory}`, { headers });
    }
}