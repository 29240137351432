import { AlignmentType, Document, Paragraph, TextRun, Media, Header, Footer, Table, TableRow, TableCell, VerticalAlign, WidthType, PageNumber, PageOrientation, BorderStyle } from "docx";
import { logo } from "../logo";
import { userWithoutSign } from "../userWithoutSign"
import { DatePipe } from '@angular/common';

export class DocumentCreatorMTO_FSOL_02_1_v2 {

    public getBase64FromUrl = async (url) => {
        const data = await fetch(url, {
            headers: {
                'Cache-Control': 'no-cache, no-store, must-revalidate',
                'Pragma': 'no-cache',
                'Expires': 'Sat, 01 Jan 2000 00:00:00 GMT',
                'If-Modified-Since': '0'
            }
        });
        const blob = await data.blob();
        return new Promise((resolve) => {
            const reader = new FileReader();
            reader.readAsDataURL(blob);
            reader.onloadend = function() {
                const base64data = reader.result;
                resolve(base64data);
            }
        });
    }

    // INIT MTO_FSOL_02_1
    private numero = 0
    private total = 0
    private utilizada = 0

    public async create_MTO_FSOL_02_1(data, signature = ''): Promise<Document> {
        const document = new Document();
        const image = Media.addImage(document, logo, 50, 50);
        var datePipe = new DatePipe('es-MX');
        let signatureImage = null
        if (signature) {
            signatureImage = await this.getBase64FromUrl(signature)
        } else {
            signatureImage = userWithoutSign
        }

        this.huertasData(data)
        document.addSection({
            headers: {
                default: new Header({
                    children: [
                        new Paragraph({ alignment: AlignmentType.CENTER, children: [image], }),
                        new Paragraph({
                            alignment: AlignmentType.CENTER, children: [
                                new TextRun({
                                    children: [`MTO-FSOL-02-1| R 02 | 16-Diciembre-2020 | Pág. `, PageNumber.CURRENT],
                                    font: 'Book Antiqua',
                                    size: 20,
                                    bold: true
                                }),
                                new TextRun({
                                    font: 'Book Antiqua',
                                    size: 20,
                                    bold: true,
                                    children: [' de ', PageNumber.TOTAL_PAGES]
                                })
                            ], spacing: { before: 200, after: 100 }
                        })
                    ]
                })
            },
            footers: {
                default: new Footer({
                    children: [
                        new Paragraph({ alignment: AlignmentType.CENTER }),
                        this.createFooter()
                    ]
                }),
            },
            children: [
                new Paragraph({
                    alignment: AlignmentType.CENTER,
                    children: [
                        new TextRun({
                            text: 'LISTA PARCELAS / PLAN ANUAL DE PRODUCCION (producción vegetal)',
                            font: 'Calibri (Cuerpo)',
                            size: 22,
                            bold: true
                        })
                    ],
                    spacing: { after: 200 }
                }),
                this.createTableUDP(data, datePipe, signatureImage, document),
                new Paragraph({
                    alignment: AlignmentType.CENTER,
                    children: [
                        new TextRun({
                            text: '',
                            font: 'Calibri (Cuerpo)',
                            size: 22,
                            bold: true
                        })
                    ],
                    spacing: { after: 400 }
                }),
                new Paragraph({
                    alignment: AlignmentType.LEFT,
                    children: [
                        new TextRun({
                            text: 'Leyenda',
                            font: 'Calibri (Body)',
                            size: 18,
                            bold: true
                        })
                    ]
                }),
                new Paragraph({
                    alignment: AlignmentType.LEFT,
                    children: [
                        new TextRun({
                            text: '(1) Dato obligatorio: indicar el número de la parcela',
                            font: 'Calibri (Body)',
                            size: 18
                        })
                    ]
                }),
                new Paragraph({
                    alignment: AlignmentType.LEFT,
                    children: [
                        new TextRun({
                            text: '(2) Dato obligatorio: indicar el nombre de la parcela',
                            font: 'Calibri (Body)',
                            size: 18
                        })
                    ]
                }),
                new Paragraph({
                    alignment: AlignmentType.LEFT,
                    children: [
                        new TextRun({
                            text: '(3) Dato obligatorio: colocar las coordenas geograficas de la unidad de producción (Latitud y Longitud)',
                            font: 'Calibri (Body)',
                            size: 18
                        })
                    ]
                }),
                new Paragraph({
                    alignment: AlignmentType.LEFT,
                    children: [
                        new TextRun({
                            text: '(4) Dato obligatorio: indicar el nùmero de los lotes que forman el terreno/parcela presentado por el operador (en mapa de ubicación), enviada en fecha ____________ o anexa al presente documento',
                            font: 'Calibri (Body)',
                            size: 18
                        })
                    ]
                }),
                new Paragraph({
                    alignment: AlignmentType.LEFT,
                    children: [
                        new TextRun({
                            text: '(5) Tìtulo de propiedad : 1-propiedad del productor, 2-En renta o asociación, 3-cedido por terceros, 4-otro',
                            font: 'Calibri (Body)',
                            size: 18
                        })
                    ]
                }),
                new Paragraph({
                    alignment: AlignmentType.LEFT,
                    children: [
                        new TextRun({
                            text: '(6) Dato obligatorio: superficie total del terreno en hectáreas',
                            font: 'Calibri (Body)',
                            size: 18
                        })
                    ]
                }),
                new Paragraph({
                    alignment: AlignmentType.LEFT,
                    children: [
                        new TextRun({
                            text: '(7) Dato obligatorio: superficie total cultivada del terreno en hectáreas',
                            font: 'Calibri (Body)',
                            size: 18
                        })
                    ]
                }),
                new Paragraph({
                    alignment: AlignmentType.LEFT,
                    children: [
                        new TextRun({
                            text: '(8) Dato obligatorio: Org  ---> "orgànico"     Tran ---> "en trancisión"    Conv ---> "convencional, quìmico',
                            font: 'Calibri (Body)',
                            size: 18
                        })
                    ]
                }),
                new Paragraph({
                    alignment: AlignmentType.LEFT,
                    children: [
                        new TextRun({
                            text: '(9) Dato obligatorio: indicar los cultivos que piensa sembrar o cultivar durante el año. (respetando la rotación de cultivos) utilizando los siguientes códigos:',
                            font: 'Calibri (Body)',
                            size: 18
                        })
                    ]
                }),
                new Paragraph({
                    alignment: AlignmentType.LEFT,
                    children: [
                        new TextRun({
                            text: ' 10=Cereales',
                            font: 'Calibri (Body)',
                            size: 18
                        })
                    ]
                }),
                new Paragraph({
                    alignment: AlignmentType.LEFT,
                    children: [
                        new TextRun({
                            text: ' 20=Hortalizas',
                            font: 'Calibri (Body)',
                            size: 18
                        })
                    ]
                }),
                new Paragraph({
                    alignment: AlignmentType.LEFT,
                    children: [
                        new TextRun({
                            text: ' 30=Frutales',
                            font: 'Calibri (Body)',
                            size: 18
                        })
                    ]
                }),
                new Paragraph({
                    alignment: AlignmentType.LEFT,
                    children: [
                        new TextRun({
                            text: ' 40=Viñedo',
                            font: 'Calibri (Body)',
                            size: 18
                        })
                    ]
                }),
                new Paragraph({
                    alignment: AlignmentType.LEFT,
                    children: [
                        new TextRun({
                            text: ' 50=Olivo (aceitunas)',
                            font: 'Calibri (Body)',
                            size: 18
                        })
                    ]
                }),
                new Paragraph({
                    alignment: AlignmentType.LEFT,
                    children: [
                        new TextRun({
                            text: ' 60=Viveros, producción de semillas, floricultura',
                            font: 'Calibri (Body)',
                            size: 18
                        })
                    ]
                }),
                new Paragraph({
                    alignment: AlignmentType.LEFT,
                    children: [
                        new TextRun({
                            text: ' 70=Cultivos industriales',
                            font: 'Calibri (Body)',
                            size: 18
                        })
                    ]
                }),
                new Paragraph({
                    alignment: AlignmentType.LEFT,
                    children: [
                        new TextRun({
                            text: ' 80=Forrajes',
                            font: 'Calibri (Body)',
                            size: 18
                        })
                    ]
                }),
                new Paragraph({
                    alignment: AlignmentType.LEFT,
                    children: [
                        new TextRun({
                            text: ' 90=Superficie relacionada con la cria de ganado',
                            font: 'Calibri (Body)',
                            size: 18
                        })
                    ]
                }),
                new Paragraph({
                    alignment: AlignmentType.LEFT,
                    children: [
                        new TextRun({
                            text: ' 99=Otro (especificar)',
                            font: 'Calibri (Body)',
                            size: 18
                        })
                    ]
                }),
                new Paragraph({
                    alignment: AlignmentType.LEFT,
                    children: [
                        new TextRun({
                            text: '(10) Dato obligatorio: Indicar el nombre de la especie',
                            font: 'Calibri (Body)',
                            size: 18
                        })
                    ]
                }),
                new Paragraph({
                    alignment: AlignmentType.LEFT,
                    children: [
                        new TextRun({
                            text: '(11) Dato obligatorio: Indicar el nombre de la variedad',
                            font: 'Calibri (Body)',
                            size: 18
                        })
                    ]
                }),
                new Paragraph({
                    alignment: AlignmentType.LEFT,
                    children: [
                        new TextRun({
                            text: '(12) Dato obligatorio: U ---> cultivo único (indicar la posición en cuanto a rotación en el caso de más de un cultivo durante el año ) o A ---> cultivo asociado',
                            font: 'Calibri (Body)',
                            size: 18
                        })
                    ]
                }),
                new Paragraph({
                    alignment: AlignmentType.LEFT,
                    children: [
                        new TextRun({
                            text: '(13) Dato obligatorio: indicar el año de siembra/ plantación en el caso de cultivos perennes o forestales ',
                            font: 'Calibri (Body)',
                            size: 18
                        })
                    ]
                }),
                new Paragraph({
                    alignment: AlignmentType.LEFT,
                    children: [
                        new TextRun({
                            text: '(14) Dato obligatorio: indicar la estimación de cosecha en kg por cada especie/variedad',
                            font: 'Calibri (Body)',
                            size: 18
                        })
                    ]
                }),
                new Paragraph({
                    alignment: AlignmentType.LEFT,
                    children: [
                        new TextRun({
                            text: '(15) Sección obligatoria solo en caso de primera notificación: indicar el tipo de cultivo presente el año anterior',
                            font: 'Calibri (Body)',
                            size: 18
                        })
                    ]
                }),
                new Paragraph({
                    alignment: AlignmentType.LEFT,
                    children: [
                        new TextRun({
                            text: '(16) Sección obligatoria solo en caso de primera notificación : indicar el tipo de cultivo presente los dos años anteriores',
                            font: 'Calibri (Body)',
                            size: 18
                        })
                    ]
                })
            ],
            margins: {
                top: 350,
                bottom: 500,
                left: 1000,
                right: 1000
            },
            size: {
                orientation: PageOrientation.LANDSCAPE,
                height: 29000,
                width: 21000
            }
        });

        return document;
    }

    public createFooter(): Table {
        const table = new Table({
            rows: [
                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: 'Elaboró:',
                                            font: 'Calibri (Cuerpo)',
                                            size: 22
                                        })
                                    ]
                                })
                            ],
                            width: {
                                size: 33,
                                type: WidthType.PERCENTAGE
                            }
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: 'Revisó:',
                                            font: 'Calibri (Cuerpo)',
                                            size: 22
                                        })
                                    ]
                                })
                            ],
                            width: {
                                size: 33,
                                type: WidthType.PERCENTAGE
                            }
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: 'Autorizó:',
                                            font: 'Calibri (Cuerpo)',
                                            size: 22
                                        })
                                    ]
                                }),
                            ],
                            width: {
                                size: 33,
                                type: WidthType.PERCENTAGE
                            }
                        })
                    ]
                }),
                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: 'Director de certificación',
                                            font: 'Calibri (Cuerpo)',
                                            size: 16,
                                            bold: true
                                        })
                                    ]
                                }),

                            ],
                            width: {
                                size: 33,
                                type: WidthType.PERCENTAGE
                            },
                            shading: {
                                fill: '#C0C0C0'
                            }
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: 'Responsable de calidad',
                                            font: 'Calibri (Cuerpo)',
                                            size: 16,
                                            bold: true
                                        })
                                    ]
                                }),

                            ],
                            width: {
                                size: 33,
                                type: WidthType.PERCENTAGE
                            },
                            shading: {
                                fill: '#C0C0C0'
                            }
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: 'Director general',
                                            font: 'Calibri (Cuerpo)',
                                            size: 16,
                                            bold: true
                                        })
                                    ]
                                }),
                            ],
                            width: {
                                size: 33,
                                type: WidthType.PERCENTAGE
                            },
                            shading: {
                                fill: '#C0C0C0'
                            }
                        })
                    ]
                })
            ],
            margins: {
                left: 70,
                right: 70
            },
            columnWidths: [3213, 3213, 3212],
            alignment: AlignmentType.CENTER
        })
        return table
    }

    public createTableUDP(data, datePipe, signatureImage, document): Table {
        const table = new Table({
            rows: [
                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: '',
                                            font: 'Calibri (Body)',
                                            size: 18
                                        })
                                    ]
                                })
                            ],
                            borders: {
                                top: {
                                    style: BorderStyle.NONE,
                                    color: "white",
                                    size: 0
                                },
                                bottom: {
                                    style: BorderStyle.NONE,
                                    color: "white",
                                    size: 0
                                },
                                left: {
                                    style: BorderStyle.NONE,
                                    color: "white",
                                    size: 0
                                },
                                right: {
                                    style: BorderStyle.NONE,
                                    color: "white",
                                    size: 0
                                }
                            },
                            verticalAlign: VerticalAlign.BOTTOM,
                            columnSpan: 2
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: 'Primera Solicitud ' + (data.record.Type === "PRIMERA_SOLICITUD" ? '■' : '❏'),
                                            font: 'Calibri (Body)',
                                            size: 18
                                        })
                                    ]
                                })
                            ],
                            borders: {
                                top: {
                                    style: BorderStyle.NONE,
                                    color: "white",
                                    size: 0
                                },
                                bottom: {
                                    style: BorderStyle.NONE,
                                    color: "white",
                                    size: 0
                                },
                                left: {
                                    style: BorderStyle.NONE,
                                    color: "white",
                                    size: 0
                                },
                                right: {
                                    style: BorderStyle.NONE,
                                    color: "white",
                                    size: 0
                                }
                            },
                            shading: {
                                fill: "99D9EA",
                            },
                            verticalAlign: VerticalAlign.BOTTOM,
                            columnSpan: 1
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: 'Renovacion ' + (data.record.Type === "RENOVACION" ? '■' : '❏'),
                                            font: 'Calibri (Body)',
                                            size: 18
                                        })
                                    ]
                                })
                            ],
                            borders: {
                                top: {
                                    style: BorderStyle.NONE,
                                    color: "white",
                                    size: 0
                                },
                                bottom: {
                                    style: BorderStyle.NONE,
                                    color: "white",
                                    size: 0
                                },
                                left: {
                                    style: BorderStyle.NONE,
                                    color: "white",
                                    size: 0
                                },
                                right: {
                                    style: BorderStyle.NONE,
                                    color: "white",
                                    size: 0
                                }
                            },
                            shading: {
                                fill: "99D9EA",
                            },
                            verticalAlign: VerticalAlign.BOTTOM,
                            columnSpan: 1
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: 'Variacion ' + (data.record.Type === "VARIACION" ? '■' : '❏'),
                                            font: 'Calibri (Body)',
                                            size: 18
                                        })
                                    ]
                                })
                            ],
                            borders: {
                                top: {
                                    style: BorderStyle.NONE,
                                    color: "white",
                                    size: 0
                                },
                                bottom: {
                                    style: BorderStyle.NONE,
                                    color: "white",
                                    size: 0
                                },
                                left: {
                                    style: BorderStyle.NONE,
                                    color: "white",
                                    size: 0
                                },
                                right: {
                                    style: BorderStyle.NONE,
                                    color: "white",
                                    size: 0
                                }
                            },
                            shading: {
                                fill: "99D9EA",
                            },
                            verticalAlign: VerticalAlign.BOTTOM,
                            columnSpan: 2
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: 'Cambio de OdeC ' + (data.record.Type === "CAMBIO_ODEC" ? '■' : '❏'),
                                            font: 'Calibri (Body)',
                                            size: 18
                                        })
                                    ]
                                })
                            ],
                            borders: {
                                top: {
                                    style: BorderStyle.NONE,
                                    color: "white",
                                    size: 0
                                },
                                bottom: {
                                    style: BorderStyle.NONE,
                                    color: "white",
                                    size: 0
                                },
                                left: {
                                    style: BorderStyle.NONE,
                                    color: "white",
                                    size: 0
                                },
                                right: {
                                    style: BorderStyle.NONE,
                                    color: "white",
                                    size: 0
                                }
                            },
                            shading: {
                                fill: "99D9EA",
                            },
                            verticalAlign: VerticalAlign.BOTTOM,
                            columnSpan: 3
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: '',
                                            font: 'Calibri (Body)',
                                            size: 18
                                        })
                                    ]
                                })
                            ],
                            borders: {
                                top: {
                                    style: BorderStyle.NONE,
                                    color: "white",
                                    size: 0
                                },
                                bottom: {
                                    style: BorderStyle.NONE,
                                    color: "white",
                                    size: 0
                                },
                                left: {
                                    style: BorderStyle.NONE,
                                    color: "white",
                                    size: 0
                                },
                                right: {
                                    style: BorderStyle.NONE,
                                    color: "white",
                                    size: 0
                                }
                            },
                            verticalAlign: VerticalAlign.BOTTOM,
                            columnSpan: 1
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: `Formato No. 1 del año ${data.record.Year}`,
                                            font: 'Calibri (Body)',
                                            size: 18
                                        })
                                    ]
                                })
                            ],
                            borders: {
                                top: {
                                    style: BorderStyle.NONE,
                                    color: "white",
                                    size: 0
                                },
                                bottom: {
                                    style: BorderStyle.NONE,
                                    color: "white",
                                    size: 0
                                },
                                left: {
                                    style: BorderStyle.NONE,
                                    color: "white",
                                    size: 0
                                },
                                right: {
                                    style: BorderStyle.NONE,
                                    color: "white",
                                    size: 0
                                }
                            },
                            verticalAlign: VerticalAlign.BOTTOM,
                            columnSpan: 4
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: '',
                                            font: 'Calibri (Body)',
                                            size: 18
                                        })
                                    ]
                                })
                            ],
                            borders: {
                                top: {
                                    style: BorderStyle.NONE,
                                    color: "white",
                                    size: 0
                                },
                                bottom: {
                                    style: BorderStyle.NONE,
                                    color: "white",
                                    size: 0
                                },
                                left: {
                                    style: BorderStyle.NONE,
                                    color: "white",
                                    size: 0
                                },
                                right: {
                                    style: BorderStyle.NONE,
                                    color: "white",
                                    size: 0
                                }
                            },
                            verticalAlign: VerticalAlign.BOTTOM,
                            columnSpan: 4
                        })
                    ]
                }),
                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: '',
                                            font: 'Calibri (Body)',
                                            size: 18
                                        })
                                    ]
                                })
                            ],
                            borders: {
                                top: {
                                    style: BorderStyle.NONE,
                                    color: "white",
                                    size: 0
                                },
                                bottom: {
                                    style: BorderStyle.NONE,
                                    color: "white",
                                    size: 0
                                },
                                right: {
                                    style: BorderStyle.NONE,
                                    color: "white",
                                    size: 0
                                },
                                left: {
                                    style: BorderStyle.NONE,
                                    color: "white",
                                    size: 0
                                }
                            },
                            verticalAlign: VerticalAlign.CENTER
                        })
                    ]
                }),
                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: 'Número de huertas o lótes:',
                                            font: 'Calibri (Body)',
                                            size: 18
                                        })
                                    ]
                                })
                            ],
                            verticalAlign: VerticalAlign.CENTER,
                            columnSpan: 2
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: `${this.numero}`,
                                            font: 'Calibri (Body)',
                                            size: 18
                                        })
                                    ]
                                })
                            ],
                            verticalAlign: VerticalAlign.CENTER,
                            columnSpan: 1
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: 'Superficie total (ha):',
                                            font: 'Calibri (Body)',
                                            size: 18
                                        })
                                    ]
                                })
                            ],
                            verticalAlign: VerticalAlign.CENTER,
                            columnSpan: 1
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.RIGHT,
                                    children: [
                                        new TextRun({
                                            text: `${this.total.toFixed(4)}`,
                                            font: 'Calibri (Body)',
                                            size: 18
                                        })
                                    ]
                                })
                            ],
                            verticalAlign: VerticalAlign.CENTER,
                            columnSpan: 1
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: 'Superficie utilizada (ha):',
                                            font: 'Calibri (Body)',
                                            size: 18
                                        })
                                    ]
                                })
                            ],
                            verticalAlign: VerticalAlign.CENTER,
                            columnSpan: 3
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.RIGHT,
                                    children: [
                                        new TextRun({
                                            text: `${this.utilizada.toFixed(4)}`,
                                            font: 'Calibri (Body)',
                                            size: 18
                                        })
                                    ]
                                })
                            ],
                            verticalAlign: VerticalAlign.CENTER,
                            columnSpan: 1
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: '',
                                            font: 'Calibri (Body)',
                                            size: 18
                                        })
                                    ]
                                })
                            ],
                            borders: {
                                top: {
                                    style: BorderStyle.NONE,
                                    color: "white",
                                    size: 0
                                },
                                bottom: {
                                    style: BorderStyle.NONE,
                                    color: "white",
                                    size: 0
                                },
                            },
                            verticalAlign: VerticalAlign.CENTER,
                            columnSpan: 1
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: 'Fecha, sello y firma del operador',
                                            font: 'Calibri (Body)',
                                            size: 18
                                        })
                                    ]
                                }),
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        signatureImage ? Media.addImage(document, signatureImage.toString(), 100, 50) : new TextRun({
                                            text: ''
                                        })
                                    ]
                                }),
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: `${datePipe.transform(new Date(data.record.Applications[0].Creation_Date.includes('UTC') ? data.record.Applications[0].Creation_Date.replace('UTC', '').trim() : data.record.Applications[0].Creation_Date), 'd-MMMM-y')}`,
                                            font: 'Calibri (Body)',
                                            size: 18
                                        })
                                    ]
                                }),
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: `${data.customer.Legal_Authorities[0].Name} ${data.customer.Legal_Authorities[0].Middle_Name} ${data.customer.Legal_Authorities[0].Last_Name ? data.customer.Legal_Authorities[0].Last_Name : ''}`,
                                            font: 'Calibri (Body)',
                                            size: 18
                                        })
                                    ]
                                })
                            ],
                            columnSpan: 4
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: '',
                                            font: 'Calibri (Body)',
                                            size: 18
                                        })
                                    ]
                                })
                            ],
                            borders: {
                                top: {
                                    style: BorderStyle.NONE,
                                    color: "white",
                                    size: 0
                                },
                                bottom: {
                                    style: BorderStyle.NONE,
                                    color: "white",
                                    size: 0
                                },
                                right: {
                                    style: BorderStyle.NONE,
                                    color: "white",
                                    size: 0
                                }
                            },
                            verticalAlign: VerticalAlign.CENTER,
                            columnSpan: 4
                        })
                    ]
                }),
                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: '',
                                            font: 'Calibri (Body)',
                                            size: 18
                                        })
                                    ]
                                })
                            ],
                            borders: {
                                top: {
                                    style: BorderStyle.NONE,
                                    color: "white",
                                    size: 0
                                },
                                bottom: {
                                    style: BorderStyle.NONE,
                                    color: "white",
                                    size: 0
                                },
                                right: {
                                    style: BorderStyle.NONE,
                                    color: "white",
                                    size: 0
                                },
                                left: {
                                    style: BorderStyle.NONE,
                                    color: "white",
                                    size: 0
                                }
                            },
                            verticalAlign: VerticalAlign.CENTER
                        })
                    ]
                }),
                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: 'No.',
                                            font: 'Calibri (Body)',
                                            size: 18
                                        })
                                    ]
                                })
                            ],
                            verticalAlign: VerticalAlign.CENTER,
                            columnSpan: 1
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: 'Nombre de la Unidad de Producción',
                                            font: 'Calibri (Body)',
                                            size: 18
                                        })
                                    ]
                                })
                            ],
                            verticalAlign: VerticalAlign.CENTER,
                            columnSpan: 1
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: 'Ubicación del terreno',
                                            font: 'Calibri (Body)',
                                            size: 18
                                        })
                                    ]
                                })
                            ],
                            verticalAlign: VerticalAlign.CENTER,
                            columnSpan: 1
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: 'Identificación del terreno en el mapa (subdivisiones por cultivo)',
                                            font: 'Calibri (Body)',
                                            size: 18
                                        })
                                    ]
                                })
                            ],
                            verticalAlign: VerticalAlign.CENTER,
                            columnSpan: 1
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: 'Título de propiedad',
                                            font: 'Calibri (Body)',
                                            size: 18
                                        })
                                    ]
                                })
                            ],
                            verticalAlign: VerticalAlign.CENTER,
                            columnSpan: 1
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: 'Superficie total',
                                            font: 'Calibri (Body)',
                                            size: 18
                                        })
                                    ]
                                })
                            ],
                            verticalAlign: VerticalAlign.CENTER,
                            columnSpan: 1
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: 'Superficie utilizada',
                                            font: 'Calibri (Body)',
                                            size: 18
                                        })
                                    ]
                                })
                            ],
                            verticalAlign: VerticalAlign.CENTER,
                            columnSpan: 1
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: 'Método de cultivo',
                                            font: 'Calibri (Body)',
                                            size: 18
                                        })
                                    ]
                                })
                            ],
                            verticalAlign: VerticalAlign.CENTER,
                            columnSpan: 1
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: 'Orientación productiva',
                                            font: 'Calibri (Body)',
                                            size: 18
                                        })
                                    ]
                                })
                            ],
                            verticalAlign: VerticalAlign.CENTER,
                            columnSpan: 1
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: 'Cultivos',
                                            font: 'Calibri (Body)',
                                            size: 18
                                        })
                                    ]
                                })
                            ],
                            verticalAlign: VerticalAlign.CENTER,
                            columnSpan: 5
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: 'Cultivos anteriores',
                                            font: 'Calibri (Body)',
                                            size: 18
                                        })
                                    ]
                                })
                            ],
                            verticalAlign: VerticalAlign.CENTER,
                            columnSpan: 4
                        })
                    ]
                }),
                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: '',
                                            font: 'Calibri (Body)',
                                            size: 18
                                        })
                                    ]
                                })
                            ],
                            verticalAlign: VerticalAlign.CENTER
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: '',
                                            font: 'Calibri (Body)',
                                            size: 18
                                        })
                                    ]
                                })
                            ],
                            verticalAlign: VerticalAlign.CENTER
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: '(Coordenadas Geograficas)',
                                            font: 'Calibri (Body)',
                                            size: 18
                                        })
                                    ]
                                })
                            ],
                            verticalAlign: VerticalAlign.CENTER
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: '(Ver el mapa)',
                                            font: 'Calibri (Body)',
                                            size: 18
                                        })
                                    ]
                                })
                            ],
                            verticalAlign: VerticalAlign.CENTER
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: '(ver códigos en la leyenda)',
                                            font: 'Calibri (Body)',
                                            size: 18
                                        })
                                    ]
                                })
                            ],
                            verticalAlign: VerticalAlign.CENTER
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: 'ha',
                                            font: 'Calibri (Body)',
                                            size: 18
                                        })
                                    ]
                                })
                            ],
                            verticalAlign: VerticalAlign.CENTER
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: 'ha',
                                            font: 'Calibri (Body)',
                                            size: 18
                                        })
                                    ]
                                })
                            ],
                            verticalAlign: VerticalAlign.CENTER
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: '(Org / Tran / Conv)',
                                            font: 'Calibri (Body)',
                                            size: 18
                                        })
                                    ]
                                })
                            ],
                            verticalAlign: VerticalAlign.CENTER
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: '(ver códigos en la leyenda)',
                                            font: 'Calibri (Body)',
                                            size: 18
                                        })
                                    ]
                                })
                            ],
                            verticalAlign: VerticalAlign.CENTER
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: 'Especie',
                                            font: 'Calibri (Body)',
                                            size: 18
                                        })
                                    ]
                                })
                            ],
                            verticalAlign: VerticalAlign.CENTER
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: 'Variedad',
                                            font: 'Calibri (Body)',
                                            size: 18
                                        })
                                    ]
                                })
                            ],
                            verticalAlign: VerticalAlign.CENTER
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: 'Cultivo único (U) o en asociación (A)',
                                            font: 'Calibri (Body)',
                                            size: 18
                                        })
                                    ]
                                })
                            ],
                            verticalAlign: VerticalAlign.CENTER
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: 'Año de plantación',
                                            font: 'Calibri (Body)',
                                            size: 18
                                        })
                                    ]
                                })
                            ],
                            verticalAlign: VerticalAlign.CENTER
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: 'Estimación de cosecha (Kg)',
                                            font: 'Calibri (Body)',
                                            size: 18
                                        })
                                    ]
                                })
                            ],
                            verticalAlign: VerticalAlign.CENTER
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: '1 ciclo antes',
                                            font: 'Calibri (Body)',
                                            size: 18
                                        })
                                    ]
                                })
                            ],
                            verticalAlign: VerticalAlign.CENTER
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: '(Org / Tran / Conv)',
                                            font: 'Calibri (Body)',
                                            size: 18
                                        })
                                    ]
                                })
                            ],
                            verticalAlign: VerticalAlign.CENTER
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: '2 ciclos antes',
                                            font: 'Calibri (Body)',
                                            size: 18
                                        })
                                    ]
                                })
                            ],
                            verticalAlign: VerticalAlign.CENTER
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: '(Org / Tran / Conv)',
                                            font: 'Calibri (Body)',
                                            size: 18
                                        })
                                    ]
                                })
                            ],
                            verticalAlign: VerticalAlign.CENTER
                        })
                    ]
                }),
                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: '(1)',
                                            font: 'Calibri (Body)',
                                            size: 18
                                        })
                                    ]
                                })
                            ],
                            verticalAlign: VerticalAlign.CENTER
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: '(2)',
                                            font: 'Calibri (Body)',
                                            size: 18
                                        })
                                    ]
                                })
                            ],
                            verticalAlign: VerticalAlign.CENTER
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: '(3)',
                                            font: 'Calibri (Body)',
                                            size: 18
                                        })
                                    ]
                                })
                            ],
                            verticalAlign: VerticalAlign.CENTER
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: '(4)',
                                            font: 'Calibri (Body)',
                                            size: 18
                                        })
                                    ]
                                })
                            ],
                            verticalAlign: VerticalAlign.CENTER
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: '(5)',
                                            font: 'Calibri (Body)',
                                            size: 18
                                        })
                                    ]
                                })
                            ],
                            verticalAlign: VerticalAlign.CENTER
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: '(6)',
                                            font: 'Calibri (Body)',
                                            size: 18
                                        })
                                    ]
                                })
                            ],
                            verticalAlign: VerticalAlign.CENTER
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: '(7)',
                                            font: 'Calibri (Body)',
                                            size: 18
                                        })
                                    ]
                                })
                            ],
                            verticalAlign: VerticalAlign.CENTER
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: '(8)',
                                            font: 'Calibri (Body)',
                                            size: 18
                                        })
                                    ]
                                })
                            ],
                            verticalAlign: VerticalAlign.CENTER
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: '(9)',
                                            font: 'Calibri (Body)',
                                            size: 18
                                        })
                                    ]
                                })
                            ],
                            verticalAlign: VerticalAlign.CENTER
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: '(10)',
                                            font: 'Calibri (Body)',
                                            size: 18
                                        })
                                    ]
                                })
                            ],
                            verticalAlign: VerticalAlign.CENTER
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: '(11)',
                                            font: 'Calibri (Body)',
                                            size: 18
                                        })
                                    ]
                                })
                            ],
                            verticalAlign: VerticalAlign.CENTER
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: '(12)',
                                            font: 'Calibri (Body)',
                                            size: 18
                                        })
                                    ]
                                })
                            ],
                            verticalAlign: VerticalAlign.CENTER
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: '(13)',
                                            font: 'Calibri (Body)',
                                            size: 18
                                        })
                                    ]
                                })
                            ],
                            verticalAlign: VerticalAlign.CENTER
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: '(14)',
                                            font: 'Calibri (Body)',
                                            size: 18
                                        })
                                    ]
                                })
                            ],
                            verticalAlign: VerticalAlign.CENTER
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: '(15)',
                                            font: 'Calibri (Body)',
                                            size: 18
                                        })
                                    ]
                                })
                            ],
                            verticalAlign: VerticalAlign.CENTER
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: '(8)',
                                            font: 'Calibri (Body)',
                                            size: 18
                                        })
                                    ]
                                })
                            ],
                            verticalAlign: VerticalAlign.CENTER
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: '(16)',
                                            font: 'Calibri (Body)',
                                            size: 18
                                        })
                                    ]
                                })
                            ],
                            verticalAlign: VerticalAlign.CENTER
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: '(8)',
                                            font: 'Calibri (Body)',
                                            size: 18
                                        })
                                    ]
                                })
                            ],
                            verticalAlign: VerticalAlign.CENTER
                        })
                    ]
                })
            ],
            margins: {
                left: 70,
                right: 70
            },
            width: {
                size: 100,
                type: WidthType.PERCENTAGE,
            },

            //1000
            columnWidths: [1600, 5312, 5312, 5712, 2812, 2812, 2812, 3012, 3012, 4012, 4012, 3312, 3312, 3312, 3312, 2812, 3312, 2812],
            alignment: AlignmentType.CENTER
        })

        let listaUnidades = this.getValueList(data.submission, "plan_anual_produccion")
        listaUnidades.forEach((unidad, i) => {
            let latitud = (unidad.ubicacion_latitud !== "" ? unidad.ubicacion_latitud : 0)
            latitud = parseFloat(latitud).toFixed(6)
            let longitud = (unidad.ubicacion_longitud !== "" ? unidad.ubicacion_longitud : 0)
            longitud = parseFloat(longitud).toFixed(6)

            const span = 2
            let tab = new TableRow({
                children: [
                    new TableCell({
                        children: [
                            new Paragraph({
                                alignment: AlignmentType.CENTER,
                                children: [
                                    new TextRun({
                                        text: `${i + 1}`,
                                        font: 'Calibri (Body)',
                                        size: 18
                                    })
                                ]
                            })
                        ],
                        verticalAlign: VerticalAlign.CENTER
                    }),
                    new TableCell({
                        children: [
                            new Paragraph({
                                alignment: AlignmentType.CENTER,
                                children: [
                                    new TextRun({
                                        text: `${unidad.nombre_unidad_produccion}`,
                                        font: 'Calibri (Body)',
                                        size: 18
                                    })
                                ]
                            })
                        ],
                        verticalAlign: VerticalAlign.CENTER
                    }),
                    new TableCell({
                        children: [
                            new Paragraph({
                                alignment: AlignmentType.CENTER,
                                children: [
                                    new TextRun({
                                        text: `${latitud + ', ' + longitud}`,
                                        font: 'Calibri (Body)',
                                        size: 18
                                    })
                                ]
                            })
                        ],
                        verticalAlign: VerticalAlign.CENTER
                    }),
                    new TableCell({
                        children: [
                            new Paragraph({
                                alignment: AlignmentType.CENTER,
                                children: [
                                    new TextRun({
                                        text: `${unidad.terreno_identificacion_mapa}`,
                                        font: 'Calibri (Body)',
                                        size: 18
                                    })
                                ]
                            })
                        ],
                        verticalAlign: VerticalAlign.CENTER
                    }),
                    new TableCell({
                        children: [
                            new Paragraph({
                                alignment: AlignmentType.CENTER,
                                children: [
                                    new TextRun({
                                        text: unidad.titulo_de_propiedad.toLowerCase() === 'propiedad del productor'
                                            ? '1'
                                            : unidad.titulo_de_propiedad.toLowerCase() === 'en renta o asociación'
                                                ? '2'
                                                : unidad.titulo_de_propiedad.toLowerCase() === 'cedido por terceros'
                                                    ? '3'
                                                    : unidad.titulo_de_propiedad,
                                        font: 'Calibri (Body)',
                                        size: 18
                                    })
                                ]
                            })
                        ],
                        verticalAlign: VerticalAlign.CENTER
                    })
                ]
            })
            tab.addChildElement(new TableCell({
                children: [
                    new Paragraph({
                        alignment: AlignmentType.CENTER,
                        children: [
                            new TextRun({
                                text: `${isNaN(parseFloat(unidad.superficie_total_hectareas)) ? '0.00' : parseFloat(unidad.superficie_total_hectareas).toFixed(4)}`,
                                font: 'Calibri (Body)',
                                size: 18
                            })
                        ]
                    })
                ],
                verticalAlign: VerticalAlign.CENTER
            }))
            for (var j in unidad['lista_divisiones']) {
                const div = unidad['lista_divisiones'][j]
                if (parseInt(j) !== 0) {
                    tab = new TableRow({
                        children: [
                            new TableCell({
                                children: [
                                    new Paragraph({
                                        alignment: AlignmentType.CENTER,
                                        children: [
                                            new TextRun({
                                                text: '',
                                                font: 'Calibri (Body)',
                                                size: 18
                                            })
                                        ]
                                    })
                                ],
                                verticalAlign: VerticalAlign.CENTER
                            }),
                            new TableCell({
                                children: [
                                    new Paragraph({
                                        alignment: AlignmentType.CENTER,
                                        children: [
                                            new TextRun({
                                                text: '',
                                                font: 'Calibri (Body)',
                                                size: 18
                                            })
                                        ]
                                    })
                                ],
                                verticalAlign: VerticalAlign.CENTER
                            }),
                            new TableCell({
                                children: [
                                    new Paragraph({
                                        alignment: AlignmentType.CENTER,
                                        children: [
                                            new TextRun({
                                                text: '',
                                                font: 'Calibri (Body)',
                                                size: 18
                                            })
                                        ]
                                    })
                                ],
                                verticalAlign: VerticalAlign.CENTER
                            }),
                            new TableCell({
                                children: [
                                    new Paragraph({
                                        alignment: AlignmentType.CENTER,
                                        children: [
                                            new TextRun({
                                                text: '',
                                                font: 'Calibri (Body)',
                                                size: 18
                                            })
                                        ]
                                    })
                                ],
                                verticalAlign: VerticalAlign.CENTER
                            }),
                            new TableCell({
                                children: [
                                    new Paragraph({
                                        alignment: AlignmentType.CENTER,
                                        children: [
                                            new TextRun({
                                                text: '',
                                                font: 'Calibri (Body)',
                                                size: 18
                                            })
                                        ]
                                    })
                                ],
                                verticalAlign: VerticalAlign.CENTER
                            }),
                            new TableCell({
                                children: [
                                    new Paragraph({
                                        alignment: AlignmentType.CENTER,
                                        children: [
                                            new TextRun({
                                                text: '',
                                                font: 'Calibri (Body)',
                                                size: 18
                                            })
                                        ]
                                    })
                                ],
                                verticalAlign: VerticalAlign.CENTER
                            })
                        ]
                    })
                }
                tab.addChildElement(
                    new TableCell({
                        children: [
                            new Paragraph({
                                alignment: AlignmentType.CENTER,
                                children: [
                                    new TextRun({
                                        text: `${isNaN(parseFloat(div.superficie_utilizada_hectareas)) ? '0.00' : parseFloat(div.superficie_utilizada_hectareas).toFixed(4)}`,
                                        font: 'Calibri (Body)',
                                        size: 18
                                    })
                                ]
                            })
                        ],
                        verticalAlign: VerticalAlign.CENTER
                    }))
                tab.addChildElement(
                    new TableCell({
                        children: [
                            new Paragraph({
                                alignment: AlignmentType.CENTER,
                                children: [
                                    new TextRun({
                                        text: div.metodo_cultivo.toLowerCase() === 'orgánico'
                                                ? 'Org'
                                                : div.metodo_cultivo.toLowerCase() === 'convencional' || div.metodo_cultivo.toLowerCase() === 'químico'
                                                    ? 'Conv'
                                                    : div.metodo_cultivo.toLowerCase() === 'en transición'
                                                        ? 'Tran'
                                                        : 'N/A',
                                        font: 'Calibri (Body)',
                                        size: 18
                                    })
                                ]
                            })
                        ],
                        verticalAlign: VerticalAlign.CENTER
                    }))
                tab.addChildElement(
                    new TableCell({
                        children: [
                            new Paragraph({
                                alignment: AlignmentType.CENTER,
                                children: [
                                    new TextRun({
                                        text: div.orientacion_productiva.toLowerCase() === 'cereales'
                                            ? '10'
                                            : div.orientacion_productiva.toLowerCase() === 'hortalizas'
                                                ? '18'
                                                : div.orientacion_productiva.toLowerCase() === 'frutales'
                                                    ? '30'
                                                    : div.orientacion_productiva.toLowerCase() === 'viñedo'
                                                        ? '40'
                                                        : div.orientacion_productiva.toLowerCase() === 'olivo (aceitunas)'
                                                            ? '50'
                                                            : div.orientacion_productiva.toLowerCase() === 'viveros, producción de semillas, floricultura'
                                                                ? '60'
                                                                : div.orientacion_productiva.toLowerCase() === 'cultivos industriales'
                                                                    ? '70'
                                                                    : div.orientacion_productiva.toLowerCase() === 'forrajes'
                                                                        ? '80'
                                                                        : div.orientacion_productiva.toLowerCase() === 'superficie relacionada con la cria de ganado'
                                                                            ? '90'
                                                                            : '99 - ' + div.orientacion_productiva_otro,
                                        font: 'Calibri (Body)',
                                        size: 18
                                    })
                                ]
                            })
                        ],
                        verticalAlign: VerticalAlign.CENTER
                    }))
                tab.addChildElement(
                    new TableCell({
                        children: [
                            new Paragraph({
                                alignment: AlignmentType.CENTER,
                                children: [
                                    new TextRun({
                                        text: `${div.cultivo_especie}`,
                                        font: 'Calibri (Body)',
                                        size: 18
                                    })
                                ]
                            })
                        ],
                        verticalAlign: VerticalAlign.CENTER
                    }))
                tab.addChildElement(
                    new TableCell({
                        children: [
                            new Paragraph({
                                alignment: AlignmentType.CENTER,
                                children: [
                                    new TextRun({
                                        text: `${div.cultivo_variedad}`,
                                        font: 'Calibri (Body)',
                                        size: 18
                                    })
                                ]
                            })
                        ],
                        verticalAlign: VerticalAlign.CENTER
                    }))
                tab.addChildElement(
                    new TableCell({
                        children: [
                            new Paragraph({
                                alignment: AlignmentType.CENTER,
                                children: [
                                    new TextRun({
                                        text: div.cultivo_unico_o_asociacion.toLowerCase() === 'único'
                                            ? 'U'
                                            : 'A',
                                        font: 'Calibri (Body)',
                                        size: 18
                                    })
                                ]
                            })
                        ],
                        verticalAlign: VerticalAlign.CENTER
                    }))
                tab.addChildElement(
                    new TableCell({
                        children: [
                            new Paragraph({
                                alignment: AlignmentType.CENTER,
                                children: [
                                    new TextRun({
                                        text: `${div.cultivo_año_plantificacion}`,
                                        font: 'Calibri (Body)',
                                        size: 18
                                    })
                                ]
                            })
                        ],
                        verticalAlign: VerticalAlign.CENTER
                    }))
                tab.addChildElement(
                    new TableCell({
                        children: [
                            new Paragraph({
                                alignment: AlignmentType.CENTER,
                                children: [
                                    new TextRun({
                                        text: `${isNaN(parseFloat(div.cultivo_estimacion_cosechas_kg)) ? 0 : div.cultivo_estimacion_cosechas_kg}`,
                                        font: 'Calibri (Body)',
                                        size: 18
                                    })
                                ]
                            })
                        ],
                        verticalAlign: VerticalAlign.CENTER
                    }))
                tab.addChildElement(
                    new TableCell({
                        children: [
                            new Paragraph({
                                alignment: AlignmentType.CENTER,
                                children: [
                                    new TextRun({
                                        text: `${div.cultivos_anteriores_un_ciclo_antes}`,
                                        font: 'Calibri (Body)',
                                        size: 18
                                    })
                                ]
                            })
                        ],
                        verticalAlign: VerticalAlign.CENTER
                    }))
                tab.addChildElement(
                    new TableCell({
                        children: [
                            new Paragraph({
                                alignment: AlignmentType.CENTER,
                                children: [
                                    new TextRun({
                                        text: div.cultivos_anteriores_un_ciclo_antes_metodo.toLowerCase() === 'orgánico'
                                                ? 'Org'
                                                : div.cultivos_anteriores_un_ciclo_antes_metodo.toLowerCase() === 'convencional' || div.cultivos_anteriores_un_ciclo_antes_metodo.toLowerCase() === 'químico'
                                                    ? 'Conv'
                                                    : div.cultivos_anteriores_un_ciclo_antes_metodo.toLowerCase() === 'en transición'
                                                        ? 'Tran'
                                                        : 'N/A',
                                        font: 'Calibri (Body)',
                                        size: 18
                                    })
                                ]
                            })
                        ],
                        verticalAlign: VerticalAlign.CENTER
                    }))
                tab.addChildElement(
                    new TableCell({
                        children: [
                            new Paragraph({
                                alignment: AlignmentType.CENTER,
                                children: [
                                    new TextRun({
                                        text: `${div.cultivos_anteriores_dos_ciclo_antes}`,
                                        font: 'Calibri (Body)',
                                        size: 18
                                    })
                                ]
                            })
                        ],
                        verticalAlign: VerticalAlign.CENTER
                    }))
                tab.addChildElement(
                    new TableCell({
                        children: [
                            new Paragraph({
                                alignment: AlignmentType.CENTER,
                                children: [
                                    new TextRun({
                                        text: div.cultivos_anteriores_dos_ciclo_antes_metodo.toLowerCase() === 'orgánico'
                                                ? 'Org'
                                                : div.cultivos_anteriores_dos_ciclo_antes_metodo.toLowerCase() === 'convencional' || div.cultivos_anteriores_dos_ciclo_antes_metodo.toLowerCase() === 'químico'
                                                    ? 'Conv'
                                                    : div.cultivos_anteriores_dos_ciclo_antes_metodo.toLowerCase() === 'en transición'
                                                        ? 'Tran'
                                                        : 'N/A',
                                        font: 'Calibri (Body)',
                                        size: 18
                                    })
                                ]
                            })
                        ],
                        verticalAlign: VerticalAlign.CENTER
                    }))
                table.addChildElement(tab)
            }
        });



        return table
    }

    huertasData(data) {
        let listaUnidades = this.getValueList(data.submission, "plan_anual_produccion")
        listaUnidades.forEach((unidad, i) => {
            this.numero = i + 1
            this.total = this.total + (isNaN(parseFloat(unidad.superficie_total_hectareas)) ? 0.00 : parseFloat(unidad.superficie_total_hectareas))
            for (var j in unidad['lista_divisiones']) {
                const div = unidad['lista_divisiones'][j]
                this.utilizada = this.utilizada + (isNaN(parseFloat(div.superficie_utilizada_hectareas)) ? 0.00 : parseFloat(div.superficie_utilizada_hectareas))
            }


            this.total = parseFloat(this.total.toFixed(4))
            this.utilizada = parseFloat(this.utilizada.toFixed(4))
        });
    }

    getValue(submission, name) {
        var value = null
        for (const i in submission) {
            const element = submission[i]
            if (element.name === name) {
                value = element.value
            }
        }
        if (value) return value
        else return ''
    }

    getValueList(submission, name) {
        var value = null
        for (const i in submission) {
            const element = submission[i]
            if (element.name === name) {
                value = element.value
            }
        }
        if (value) return value
        else return []
    }
    // END MTO_FSOL_02_1
}
