<div class="modal-header">
    <h4 class="modal-title" id="modal-title">Denegar inspección</h4>
    <button
      type="button"
      class="close"
      aria-label="Close button"
      aria-describedby="modal-title"
      (click)="modal.dismiss('Cross click')"
    >
      <span aria-hidden="true">&times;</span>
    </button>
  </div>

  <form [formGroup]="form" (ngSubmit)="onSubmit()">
    <div class="modal-body">
      <p>
        <strong
          >Solicitud de denegación por <span class="text-success">{{ name }}</span>.</strong
        >
      </p>
      <p class="text-muted">
        <small><i>Para continuar indique el motivo de la denegación y confirme su contraseña.</i></small>
      </p>

      <div class="form-group approveDenyInspection">
        <label for="comment">Motivo de la denegación</label>
        <textarea
          type="textarea"
          formControlName="comment"
          class="form-control"
          rows="2"
          [ngClass]="{
            'is-invalid': (submitted && f.comment.errors) || forbiddenId
          }"
        ></textarea>
        <div *ngIf="submitted && f.comment.errors" class="invalid-feedback">
          <div *ngIf="f.comment.errors.required">
            Razón de denegación requerida
          </div>
        </div>
      </div>

      <div class="form-group approveDenyInspection">
        <label for="password">Contraseña</label>
        <div class="approveDenyInspection">
          <span class="icon-eye" (click)="showPasswordDenyInspection()">
            <i class="{{isShowPasswordDenyInspection ? 'fas fa-eye-slash' : 'fas fa-eye'}}"></i>
          </span>
          <input
            type = "{{isShowPasswordDenyInspection ? 'text' : 'password'}}"
            formControlName="password"
            class="form-control"
            [ngClass]="{
              'is-invalid': (submitted && f.password.errors) || forbiddenId
            }"
          />
        </div>
        <div *ngIf="submitted && f.password.errors" class="invalid-feedback">
          <div *ngIf="f.password.errors.required">
            Contraseña requerida
          </div>
        </div>
        <div *ngIf="forbiddenId" class="invalid-feedback">
          <div>
            Contraseña incorrecta
          </div>
        </div>
      </div>
    </div>
    <div class="modal-footer">
      <button
        type="button"
        class="btn btn-outline-secondary"
        (click)="modal.dismiss('cancel click')"
      >
        Cancelar
      </button>
      <button
        [disabled]="loading"
        type="button"
        ngbAutofocus
        class="btn btn-success"
        (click)="onSubmit()"
      >
        <span
          *ngIf="loading"
          class="spinner-border spinner-border-sm mr-1"
        ></span>
        Aceptar
      </button>
    </div>
  </form>
