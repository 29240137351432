import { AlignmentType, Document, Paragraph, TextRun, Media, Header, Footer, Table, TableRow, TableCell, VerticalAlign, WidthType, PageNumber, BorderStyle } from "docx";
import { logo } from "../logo";
import { userWithoutSign } from "../userWithoutSign"
import { DatePipe } from '@angular/common';

export class DocumentCreatorMTO_FSOL_08_2_1_v3 {

    public getBase64FromUrl = async (url) => {
        const data = await fetch(url, {
            headers: {
                'Cache-Control': 'no-cache, no-store, must-revalidate',
                'Pragma': 'no-cache',
                'Expires': 'Sat, 01 Jan 2000 00:00:00 GMT',
                'If-Modified-Since': '0'
            }
        });
        const blob = await data.blob();
        return new Promise((resolve) => {
            const reader = new FileReader();
            reader.readAsDataURL(blob); 
            reader.onloadend = function() {
                const base64data = reader.result;   
                resolve(base64data);
            }
        });
    }

    // INIT MTO_FSOL_08_2_1
    public async create_MTO_FSOL_08_2_1(data, isReview = true, signature = '', signature2 = ''): Promise<Document> {
        const document = new Document();
        const image = Media.addImage(document, logo, 50, 50);
        var datePipe = new DatePipe('es-MX');

        let signatureImage = null
        if (signature) {
            signatureImage = await this.getBase64FromUrl(signature)
        } else {
            signatureImage = userWithoutSign
        }

        let signatureImage2 = null
        if (signature2) {
          signatureImage2 = await this.getBase64FromUrl(signature2)
        } else {
          signatureImage2 = isReview ? '' : userWithoutSign
        }
        
        var unidadesProduccion = ''
        const unidades = this.getValueList(data.submission, "lista_unidades")
        unidades.forEach(unidad => {
            unidadesProduccion += unidad.nombre_unidad_produccion + ', '
        });
        unidadesProduccion = unidadesProduccion.slice(0, -2)

        document.addSection({
            headers: {
                default: new Header({
                    children: [
                        new Paragraph({ alignment: AlignmentType.CENTER, children: [image], }),
                        new Paragraph({
                            alignment: AlignmentType.CENTER, children: [
                                new TextRun({
                                    children: [`MTO-FSOL-08-2-1| R 08 | 22-Abril-2021 | Pág. `, PageNumber.CURRENT],
                                    font: 'Book Antiqua',
                                    size: 20,
                                    bold: true
                                }),
                                new TextRun({
                                    font: 'Book Antiqua',
                                    size: 20,
                                    bold: true,
                                    children: [' de ', PageNumber.TOTAL_PAGES]
                                }),
                            ], spacing: { before: 200, after: 100 }
                        })
                    ]
                })
            },
            footers: {
                default: new Footer({
                    children: [
                        new Paragraph({ alignment: AlignmentType.CENTER }),
                        this.createFooter()
                    ]
                }),
            },
            children: [
                new Paragraph({
                    alignment: AlignmentType.CENTER,
                    children: [
                        new TextRun({
                            text: 'PLAN ORGÁNICO PRODUCCIÓN ANIMAL',
                            font: 'Calibri (Cuerpo)',
                            size: 22,
                            bold: true
                        })
                    ],
                    spacing: { after: 200 }
                }),
                this.createTableUDP(data, datePipe),
                new Paragraph({
                    spacing: {
                        after: 400
                    }
                }),
                this.createRegisters_MTO_FSOL_08_2_1(data.submission),
                new Paragraph({
                    spacing: {
                        after: 400
                    }
                }),
                this.createHandling_MTO_FSOL_08_2_1(data.submission),
                new Paragraph({
                    spacing: {
                        after: 400
                    }
                }),
                this.createInstalacionesQuestion1_MTO_FSOL_08_2_1(data.submission),
                this.createInstalaciones_MTO_FSOL_08_2_1(data.submission),
                new Paragraph({
                    spacing: {
                        after: 400
                    }
                }),
                this.createAnimalHandling_MTO_FSOL_08_2_1(data.submission),
                new Paragraph({
                    alignment: AlignmentType.JUSTIFIED,
                    children: [
                        new TextRun({
                            text: `El que suscribe: ${data.customer.Legal_Authorities[0].Name} ${data.customer.Legal_Authorities[0].Middle_Name} ${data.customer.Legal_Authorities[0].Last_Name}, Titular / Representante Legal de la(s) Unidad(es) de Producción / Empresa: ${data.customer.Company_Type === "PERSONA_FISICA" ? unidadesProduccion : data.customer.Company_Name}, declaro que la información descrita en este Plan Orgánico y documentos adjuntos es verídica, y me comprometo a mantenerlo actualizado, al igual que las actividades descritas en él; con la finalidad de cumplir con la Ley de Productos Orgánicos de México (LPO) y el Régimen Orgánico de Canadá (COR/USCOEA).`,
                            font: 'Arial',
                            size: 18
                        })
                    ],
                    spacing: {
                        before: 400,
                        after: 400
                    },
                }),
                new Paragraph({
                    alignment: AlignmentType.JUSTIFIED,
                    children: [
                        new TextRun({
                            text: 'Atentamente:',
                            font: 'Arial',
                            size: 18, bold: true
                        })
                    ],
                    spacing: {
                        before: 400,
                        after: 400
                    },
                }),
                new Paragraph({
                    alignment: AlignmentType.CENTER,
                    children: [
                        signatureImage ? Media.addImage(document, signatureImage.toString(), 200, 100) : new TextRun({
                            text: ''
                        })
                    ]
                }),
                new Paragraph({
                    alignment: AlignmentType.LEFT,
                    children: [
                        new TextRun({
                            text: `Fecha: ${datePipe.transform(new Date(data.record.Applications[0].Creation_Date.includes('UTC') ? data.record.Applications[0].Creation_Date.replace('UTC', '').trim() : data.record.Applications[0].Creation_Date), 'd-MMMM-y')}                  Nombre y firma del operador: ${data.customer.Legal_Authorities[0].Name} ${data.customer.Legal_Authorities[0].Middle_Name} ${data.customer.Legal_Authorities[0].Last_Name ? data.customer.Legal_Authorities[0].Last_Name : ''}`,
                            font: 'Arial',
                            size: 20,
                            italics: true
                        })
                    ],
                    spacing: {
                        after: 200
                    }
                }),
                new Table({
                    rows: [
                        new TableRow({
                            children: [
                                new TableCell({
                                    children: [
                                        new Paragraph({
                                            alignment: AlignmentType.CENTER,
                                            children: [
                                                new TextRun({
                                                    text: 'EVALUACIÓN POR EL REVISOR',
                                                    font: 'Arial',
                                                    size: 20,
                                                    bold: true
                                                })
                                            ],
                                            spacing: {
                                                after: 75,
                                                before: 75
                                            }
                                        }),
                                    ],
                                    verticalAlign: VerticalAlign.CENTER,
                                    columnSpan: 2,
                                    shading: {
                                        fill: '#C0C0C0'
                                    }
                                })
                            ]
                        }),
                        new TableRow({
                            children: [
                                new TableCell({
                                    children: [
                                        new Paragraph({
                                            alignment: AlignmentType.RIGHT,
                                            children: [
                                                new TextRun({
                                                    text: 'Nombre del Revisor: ',
                                                    font: 'Calibri (Cuerpo)',
                                                    size: 22,
                                                })
                                            ],
                                            spacing: { before: 100, after: 200 }
                                        })],
                                    verticalAlign: VerticalAlign.CENTER,
                                }),
                                new TableCell({
                                    children: [
                                        new Paragraph({
                                            alignment: AlignmentType.LEFT,
                                            children: [
                                                new TextRun({
                                                    text: data.reviewer ? data.reviewer.name : '',
                                                    font: 'Calibri (Cuerpo)',
                                                    size: 22,
                                                })
                                            ],
                                            spacing: { before: 100, after: 200 }
                                        })],
                                    verticalAlign: VerticalAlign.CENTER,
                                }),
                            ],
                        }),
                        new TableRow({
                            children: [
                                new TableCell({
                                    children: [
                                        new Paragraph({
                                            alignment: AlignmentType.RIGHT,
                                            children: [
                                                new TextRun({
                                                    text: 'Fecha: ',
                                                    font: 'Calibri (Cuerpo)',
                                                    size: 22,
                                                })
                                            ],
                                            spacing: { before: 100, after: 200 }
                                        })],
                                    verticalAlign: VerticalAlign.CENTER,
                                }),
                                new TableCell({
                                    children: [
                                        new Paragraph({
                                            alignment: AlignmentType.LEFT,
                                            children: [
                                                new TextRun({
                                                    text: `${data.reviewer ? datePipe.transform(new Date(data.reviewer.date), 'd-MMMM-y') : ''}`,
                                                    font: 'Calibri (Cuerpo)',
                                                    size: 22,
                                                })
                                            ],
                                            spacing: { before: 100, after: 200 }
                                        })],
                                    verticalAlign: VerticalAlign.CENTER,
                                }),
                            ],
                        }),
                        new TableRow({
                            children: [
                                new TableCell({
                                    children: [
                                        new Paragraph({
                                            alignment: AlignmentType.RIGHT,
                                            children: [
                                                new TextRun({
                                                    text: 'Conformidad: ',
                                                    font: 'Calibri (Cuerpo)',
                                                    size: 22,
                                                })
                                            ],
                                            spacing: { before: 100, after: 200 }
                                        })],
                                    verticalAlign: VerticalAlign.CENTER,
                                }),
                                new TableCell({
                                    children: [
                                        new Paragraph({
                                            alignment: AlignmentType.LEFT,
                                            children: [
                                                new TextRun({
                                                    text: `${this.getValue(data.revision, 'plan_organico_conformidad_no_conformidades_graves') === true ? '■' : '❏'} No conformidades graves, no procederá la certificación.`,
                                                    font: 'Arial',
                                                    size: 18
                                                })
                                            ]
                                        }),
                                        new Paragraph({
                                            alignment: AlignmentType.LEFT,
                                            children: [
                                                new TextRun({
                                                    text: `${this.getValue(data.revision, 'plan_organico_conformidad_no_conformidades_menores_con_hallazgos') === true ? '■' : '❏'} No conformidades menores, se requiere de corrección antes de inspección. Listar los hallazgos abajo.`,
                                                    font: 'Arial',
                                                    size: 18
                                                })
                                            ]
                                        }),
                                        new Paragraph({
                                            alignment: AlignmentType.LEFT,
                                            children: [
                                                new TextRun({
                                                    text: `${this.getValue(data.revision, 'plan_organico_conformidad_no_conformidades_menores') === true ? '■' : '❏'} No conformidades menores, se puede realizar inspección.`,
                                                    font: 'Arial',
                                                    size: 18
                                                })
                                            ]
                                        }),
                                        new Paragraph({
                                            alignment: AlignmentType.LEFT,
                                            children: [
                                                new TextRun({
                                                    text: `${this.getValue(data.revision, 'plan_organico_conformidad_conformidad') === true ? '■' : '❏'} Conformidad`,
                                                    font: 'Arial',
                                                    size: 18
                                                })
                                            ]
                                        }),
                                        new Paragraph({
                                            alignment: AlignmentType.LEFT,
                                            children: [
                                                new TextRun({
                                                    text: `${this.getValue(data.revision, 'plan_organico_conformidad_otro') === true ? `■ Otro: ${this.getValue(data.revision, 'plan_organico_conformidad_otro_text')}` : '❏ Otro:'}`,
                                                    font: 'Arial',
                                                    size: 18
                                                })
                                            ]
                                        })
                                    ],
                                    verticalAlign: VerticalAlign.CENTER,
                                    columnSpan: 1,
                                }),
                            ],
                        }),
                        new TableRow({
                            children: [
                                new TableCell({
                                    children: [
                                        new Paragraph({
                                            alignment: AlignmentType.RIGHT,
                                            children: [
                                                new TextRun({
                                                    text: 'Hallazgos: ',
                                                    font: 'Calibri (Cuerpo)',
                                                    size: 22,
                                                })
                                            ],
                                            spacing: { before: 100, after: 200 }
                                        })],
                                    verticalAlign: VerticalAlign.CENTER,
                                }),
                                new TableCell({
                                    children: [
                                        new Paragraph({
                                            alignment: AlignmentType.LEFT,
                                            children: [
                                                new TextRun({
                                                    text: this.getValue(data.revision, "plan_organico_hallazgos"),
                                                    font: 'Calibri (Cuerpo)',
                                                    size: 22,
                                                })
                                            ],
                                            spacing: { before: 100, after: 200 }
                                        })],
                                    verticalAlign: VerticalAlign.CENTER,
                                }),
                            ],
                        }),
                        new TableRow({
                            children: [
                                new TableCell({
                                    children: [
                                        new Paragraph({
                                            alignment: AlignmentType.RIGHT,
                                            children: [
                                                new TextRun({
                                                    text: 'Recomendaciones para inspector: ',
                                                    font: 'Calibri (Cuerpo)',
                                                    size: 22,
                                                })
                                            ],
                                            spacing: { before: 100, after: 200 }
                                        })],
                                    verticalAlign: VerticalAlign.CENTER,
                                }),
                                new TableCell({
                                    children: [
                                        new Paragraph({
                                            alignment: AlignmentType.LEFT,
                                            children: [
                                                new TextRun({
                                                    text: this.getValue(data.revision, "plan_organico_recomendaciones"),
                                                    font: 'Calibri (Cuerpo)',
                                                    size: 22,
                                                })
                                            ],
                                            spacing: { before: 100, after: 200 }
                                        })],
                                    verticalAlign: VerticalAlign.CENTER,
                                }),
                            ],
                        }),
                        new TableRow({
                            children: [
                                new TableCell({
                                    children: [
                                        new Paragraph({
                                            alignment: AlignmentType.RIGHT,
                                            children: [
                                                new TextRun({
                                                    text: 'Comentarios generales: ',
                                                    font: 'Calibri (Cuerpo)',
                                                    size: 22,
                                                })
                                            ],
                                            spacing: { before: 100, after: 200 }
                                        })],
                                    verticalAlign: VerticalAlign.CENTER,
                                }),
                                new TableCell({
                                    children: [
                                        new Paragraph({
                                            alignment: AlignmentType.LEFT,
                                            children: [
                                                new TextRun({
                                                    text: this.getValue(data.revision, "plan_organico_comentarios"),
                                                    font: 'Calibri (Cuerpo)',
                                                    size: 22,
                                                })
                                            ],
                                            spacing: { before: 100, after: 200 }
                                        })],
                                    verticalAlign: VerticalAlign.CENTER,
                                }),
                            ],
                        }),
                        new TableRow({
                            children: [
                                new TableCell({
                                    children: [
                                        new Paragraph({
                                            alignment: AlignmentType.RIGHT,
                                            children: [
                                                new TextRun({
                                                    text: `Firma del Revisor:`,
                                                    font: 'Calibri (Cuerpo)',
                                                    size: 22,
                                                })
                                            ],
                                            spacing: { before: 100, after: 200 }
                                        })],
                                    verticalAlign: VerticalAlign.CENTER,
                                }),
                                new TableCell({
                                    children: [
                                        new Paragraph({
                                            alignment: AlignmentType.LEFT,
                                            children: [
                                              signatureImage2 ? Media.addImage(document, signatureImage2.toString(), 200, 100) : new TextRun({
                                                text: ''
                                              })
                                            ],
                                            spacing: { before: 100, after: 200 }
                                        })
                                    ],
                                    verticalAlign: VerticalAlign.CENTER,
                                    columnSpan: 1,
                                }),
                            ],
                        }),
                    ],
                    margins: {
                        left: 70,
                        right: 70
                    },
                    width: {
                        size: 100,
                        type: WidthType.PERCENTAGE,
                    },
                    columnWidths: [2410, 7227]
                  })
            ],
            margins: {
                top: 350,
                bottom: 500,
                left: 1000,
                right: 1000
            }
        });

        return document;
    }

    public createFooter(): Table {
        const table = new Table({
            rows: [
                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: 'Elaboró:',
                                            font: 'Calibri (Cuerpo)',
                                            size: 22
                                        })
                                    ]
                                })
                            ],
                            width: {
                                size: 33,
                                type: WidthType.PERCENTAGE
                            }
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: 'Revisó:',
                                            font: 'Calibri (Cuerpo)',
                                            size: 22
                                        })
                                    ]
                                })
                            ],
                            width: {
                                size: 33,
                                type: WidthType.PERCENTAGE
                            }
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: 'Autorizó:',
                                            font: 'Calibri (Cuerpo)',
                                            size: 22
                                        })
                                    ]
                                }),
                            ],
                            width: {
                                size: 33,
                                type: WidthType.PERCENTAGE
                            }
                        })
                    ]
                }),
                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: 'Director de certificación',
                                            font: 'Calibri (Cuerpo)',
                                            size: 16,
                                            bold: true
                                        })
                                    ]
                                }),

                            ],
                            width: {
                                size: 33,
                                type: WidthType.PERCENTAGE
                            },
                            shading: {
                                fill: '#C0C0C0'
                            }
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: 'Responsable de calidad',
                                            font: 'Calibri (Cuerpo)',
                                            size: 16,
                                            bold: true
                                        })
                                    ]
                                }),

                            ],
                            width: {
                                size: 33,
                                type: WidthType.PERCENTAGE
                            },
                            shading: {
                                fill: '#C0C0C0'
                            }
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: 'Director general',
                                            font: 'Calibri (Cuerpo)',
                                            size: 16,
                                            bold: true
                                        })
                                    ]
                                }),
                            ],
                            width: {
                                size: 33,
                                type: WidthType.PERCENTAGE
                            },
                            shading: {
                                fill: '#C0C0C0'
                            }
                        })
                    ]
                })
            ],
            margins: {
                left: 70,
                right: 70
            },
            //9638
            columnWidths: [3213, 3213, 3212],
            alignment: AlignmentType.CENTER
        })
        return table
    }

    public createRegisters_MTO_FSOL_08_2_1(submission): Table {
        const table = new Table({
            rows: [
                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: 'REGISTROS',
                                            font: 'Arial',
                                            size: 20,
                                            bold: true
                                        })
                                    ],
                                    spacing: {
                                        after: 75,
                                        before: 75
                                    }
                                }),
                            ],
                            verticalAlign: VerticalAlign.CENTER,
                            columnSpan: 7,
                            shading: {
                                fill: '#C0C0C0'
                            }
                        })
                    ]
                }),
                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: 'No.',
                                            font: 'Arial',
                                            size: 18,
                                            bold: true
                                        })
                                    ]
                                })
                            ],
                            rowSpan: 2,
                            verticalAlign: VerticalAlign.CENTER,
                            width: {
                                size: 25,
                                type: WidthType.PERCENTAGE
                            }
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: 'Requisitos',
                                            font: 'Arial',
                                            size: 18,
                                            bold: true
                                        })
                                    ]
                                })
                            ],
                            rowSpan: 2,
                            verticalAlign: VerticalAlign.CENTER,
                            width: {
                                size: 15,
                                type: WidthType.PERCENTAGE
                            }
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: 'Incluyo información',
                                            font: 'Arial',
                                            size: 18,
                                            bold: true
                                        })
                                    ]
                                })
                            ],
                            rowSpan: 2,
                            verticalAlign: VerticalAlign.CENTER,
                            width: {
                                size: 15,
                                type: WidthType.PERCENTAGE
                            }
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: 'Comentarios',
                                            font: 'Arial',
                                            size: 18,
                                            bold: true
                                        })
                                    ]
                                })
                            ],
                            rowSpan: 2,
                            verticalAlign: VerticalAlign.CENTER,
                            width: {
                                size: 15,
                                type: WidthType.PERCENTAGE
                            }
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: 'Conformidad',
                                            font: 'Arial',
                                            size: 18,
                                            bold: true
                                        })
                                    ]
                                })
                            ],
                            columnSpan: 3,
                            verticalAlign: VerticalAlign.CENTER,
                            width: {
                                size: 15,
                                type: WidthType.PERCENTAGE
                            }
                        })
                    ]
                }),
                new TableRow({
                    children: [new TableCell({
                        children: [
                            new Paragraph({
                                alignment: AlignmentType.CENTER,
                                children: [
                                    new TextRun({
                                        text: 'Implementación',
                                        font: 'Arial',
                                        size: 18,
                                        bold: true
                                    })
                                ]
                            })
                        ],
                        verticalAlign: VerticalAlign.CENTER
                    }),
                    new TableCell({
                        children: [
                            new Paragraph({
                                alignment: AlignmentType.CENTER,
                                children: [
                                    new TextRun({
                                        text: 'Conformidad',
                                        font: 'Arial',
                                        size: 18,
                                        bold: true
                                    })
                                ]
                            })
                        ],
                        verticalAlign: VerticalAlign.CENTER,
                        columnSpan: 2
                    }),]
                }),
                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: '1',
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                })
                            ],
                            verticalAlign: VerticalAlign.TOP
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: 'He incluido una lista completa de las parcelas, potreros y unidades de producción ganadera que estoy sometiendo a la certificación orgánica en el formato MTO-FSOL-02',
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                })
                            ],
                            verticalAlign: VerticalAlign.TOP
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: this.getValue(submission, 'plan_organico_animal_registros_1_declaracion') === 'Si' ? '■ SÍ  ❏ NO' : '❏ SÍ  ■ NO',
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                })
                            ],
                            verticalAlign: VerticalAlign.TOP
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: this.getValue(submission, 'plan_organico_animal_registros_1_comentarios'),
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                })
                            ],
                            verticalAlign: VerticalAlign.TOP
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: ` ${this.getValue(submission, 'plan_organico_animal_registros_implementacion_1') === 'I' ? '■' : '❏'} I`,
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                  }),
                                  new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: ` ${this.getValue(submission, 'plan_organico_animal_registros_implementacion_1') === 'NI' ? '■' : '❏'} NI`,
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                  })
                            ],
                            verticalAlign: VerticalAlign.TOP
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: ` ${this.getValue(submission, 'plan_organico_animal_registros_conformidad_1') === 'C' ? '■' : '❏'} C`,
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                  }),
                                  new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: ` ${this.getValue(submission, 'plan_organico_animal_registros_conformidad_1') === 'NC' ? '■' : '❏'} NC`,
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                  }),
                                  new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: ` ${this.getValue(submission, 'plan_organico_animal_registros_conformidad_1') === 'NA' ? '■' : '❏'} NA`,
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                  })
                            ],
                            verticalAlign: VerticalAlign.TOP
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: this.getValue(submission, 'plan_organico_animal_registros_comentarios_inspector_1'),
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                  })
                            ],
                            verticalAlign: VerticalAlign.TOP
                        })
                    ]
                }),
                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: '2',
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                })
                            ],
                            verticalAlign: VerticalAlign.TOP
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: 'He incluido una lista completa de las unidades de producción que estoy sometiendo a evaluación para la certificación orgánica. MTO-FSOL-28',
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                })
                            ],
                            verticalAlign: VerticalAlign.TOP
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: this.getValue(submission, 'plan_organico_animal_registros_2_declaracion') === 'Si' ? '■ SÍ  ❏ NO' : '❏ SÍ  ■ NO',
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                })
                            ],
                            verticalAlign: VerticalAlign.TOP
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: this.getValue(submission, 'plan_organico_animal_registros_2_comentarios'),
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                })
                            ],
                            verticalAlign: VerticalAlign.TOP
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: ` ${this.getValue(submission, 'plan_organico_animal_registros_implementacion_2') === 'I' ? '■' : '❏'} I`,
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                  }),
                                  new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: ` ${this.getValue(submission, 'plan_organico_animal_registros_implementacion_2') === 'NI' ? '■' : '❏'} NI`,
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                  })
                            ],
                            verticalAlign: VerticalAlign.TOP
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: ` ${this.getValue(submission, 'plan_organico_animal_registros_conformidad_2') === 'C' ? '■' : '❏'} C`,
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                  }),
                                  new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: ` ${this.getValue(submission, 'plan_organico_animal_registros_conformidad_2') === 'NC' ? '■' : '❏'} NC`,
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                  }),
                                  new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: ` ${this.getValue(submission, 'plan_organico_animal_registros_conformidad_2') === 'NA' ? '■' : '❏'} NA`,
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                  })
                            ],
                            verticalAlign: VerticalAlign.TOP
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: this.getValue(submission, 'plan_organico_animal_registros_comentarios_inspector_2'),
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                  })
                            ],
                            verticalAlign: VerticalAlign.TOP
                        })
                    ]
                }),
                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: '3',
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                })
                            ],
                            verticalAlign: VerticalAlign.TOP
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: 'He incluido croquis de cada una de las parcelas, potreros y unidades de producción ganadera en el formato MTO-FSOL-05',
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                })
                            ],
                            verticalAlign: VerticalAlign.TOP
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: this.getValue(submission, 'plan_organico_animal_registros_3_declaracion') === 'Si' ? '■ SÍ  ❏ NO' : '❏ SÍ  ■ NO',
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                })
                            ],
                            verticalAlign: VerticalAlign.TOP
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: this.getValue(submission, 'plan_organico_animal_registros_3_comentarios'),
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                })
                            ],
                            verticalAlign: VerticalAlign.TOP
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: ` ${this.getValue(submission, 'plan_organico_animal_registros_implementacion_3') === 'I' ? '■' : '❏'} I`,
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                  }),
                                  new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: ` ${this.getValue(submission, 'plan_organico_animal_registros_implementacion_3') === 'NI' ? '■' : '❏'} NI`,
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                  })
                            ],
                            verticalAlign: VerticalAlign.TOP
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: ` ${this.getValue(submission, 'plan_organico_animal_registros_conformidad_3') === 'C' ? '■' : '❏'} C`,
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                  }),
                                  new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: ` ${this.getValue(submission, 'plan_organico_animal_registros_conformidad_3') === 'NC' ? '■' : '❏'} NC`,
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                  }),
                                  new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: ` ${this.getValue(submission, 'plan_organico_animal_registros_conformidad_3') === 'NA' ? '■' : '❏'} NA`,
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                  })
                            ],
                            verticalAlign: VerticalAlign.TOP
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: this.getValue(submission, 'plan_organico_animal_registros_comentarios_inspector_3'),
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                  })
                            ],
                            verticalAlign: VerticalAlign.TOP
                        })
                    ]
                }),
                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: '4',
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                })
                            ],
                            verticalAlign: VerticalAlign.TOP
                        }),
                        new TableCell({
                            children: [
                                new Table({
                                    rows: [
                                        new TableRow({
                                            children: [
                                                new TableCell({
                                                    children: [
                                                        new Paragraph({
                                                            alignment: AlignmentType.LEFT,
                                                            children: [
                                                                new TextRun({
                                                                    text: 'Cuento con las siguientes bitácoras',
                                                                    font: 'Arial',
                                                                    size: 18
                                                                })
                                                            ]
                                                        })
                                                    ],
                                                    borders: {
                                                        left: {
                                                            style: BorderStyle.NONE,
                                                            color: "white",
                                                            size: 0
                                                        },
                                                        top: {
                                                            style: BorderStyle.NONE,
                                                            color: "white",
                                                            size: 0
                                                        },
                                                        bottom: {
                                                            style: BorderStyle.NONE,
                                                            color: "white",
                                                            size: 0
                                                        }
                                                    }
                                                }),
                                                new TableCell({
                                                    children: [
                                                        new Paragraph({
                                                            alignment: AlignmentType.CENTER,
                                                            children: [
                                                                new TextRun({
                                                                    text: '',
                                                                    font: 'Arial',
                                                                    size: 18
                                                                })
                                                            ]
                                                        })
                                                    ],
                                                    borders: {
                                                        right: {
                                                            style: BorderStyle.NONE,
                                                            color: "white",
                                                            size: 0
                                                        },
                                                        top: {
                                                            style: BorderStyle.NONE,
                                                            color: "white",
                                                            size: 0
                                                        },
                                                        bottom: {
                                                            style: BorderStyle.NONE,
                                                            color: "white",
                                                            size: 0
                                                        }
                                                    }
                                                })
                                            ]
                                        }),
                                        new TableRow({
                                            children: [
                                                new TableCell({
                                                    children: [
                                                        new Paragraph({
                                                            alignment: AlignmentType.LEFT,
                                                            children: [
                                                                new TextRun({
                                                                    text: `${this.getValue(submission, 'plan_organico_animal_registros_4_a_check') ? '■' : '❏'} Compra e inventario de semillas`,
                                                                    font: 'Arial',
                                                                    size: 18
                                                                })
                                                            ]
                                                        })
                                                    ],
                                                    borders: {
                                                        left: {
                                                            style: BorderStyle.NONE,
                                                            color: "white",
                                                            size: 0
                                                        },
                                                        top: {
                                                            style: BorderStyle.NONE,
                                                            color: "white",
                                                            size: 0
                                                        },
                                                        bottom: {
                                                            style: BorderStyle.NONE,
                                                            color: "white",
                                                            size: 0
                                                        }
                                                    }
                                                }),
                                                new TableCell({
                                                    children: [
                                                        new Paragraph({
                                                            alignment: AlignmentType.CENTER,
                                                            children: [
                                                                new TextRun({
                                                                    text: `${this.getValue(submission, 'plan_organico_animal_registros_4_a_declaracion') === "Si" ? '■ Sí ❏ No' : this.getValue(submission, 'plan_organico_animal_registros_4_a_declaracion') === "No" ? '❏ Sí ■ No' : '❏ Sí ❏ No'}`,
                                                                    font: 'Arial',
                                                                    size: 18
                                                                })
                                                            ]
                                                        })
                                                    ],
                                                    borders: {
                                                        right: {
                                                            style: BorderStyle.NONE,
                                                            color: "white",
                                                            size: 0
                                                        },
                                                        top: {
                                                            style: BorderStyle.NONE,
                                                            color: "white",
                                                            size: 0
                                                        },
                                                        bottom: {
                                                            style: BorderStyle.NONE,
                                                            color: "white",
                                                            size: 0
                                                        }
                                                    }
                                                })
                                            ]
                                        }),
                                        new TableRow({
                                            children: [
                                                new TableCell({
                                                    children: [
                                                        new Paragraph({
                                                            alignment: AlignmentType.LEFT,
                                                            children: [
                                                                new TextRun({
                                                                    text: `${this.getValue(submission, 'plan_organico_animal_registros_4_b_check') ? '■' : '❏'} Compra e inventario de alimentos, pasturas o complementos alimenticios`,
                                                                    font: 'Arial',
                                                                    size: 18
                                                                })
                                                            ]
                                                        })
                                                    ],
                                                    borders: {
                                                        left: {
                                                            style: BorderStyle.NONE,
                                                            color: "white",
                                                            size: 0
                                                        },
                                                        top: {
                                                            style: BorderStyle.NONE,
                                                            color: "white",
                                                            size: 0
                                                        },
                                                        bottom: {
                                                            style: BorderStyle.NONE,
                                                            color: "white",
                                                            size: 0
                                                        }
                                                    }
                                                }),
                                                new TableCell({
                                                    children: [
                                                        new Paragraph({
                                                            alignment: AlignmentType.CENTER,
                                                            children: [
                                                                new TextRun({
                                                                    text: `${this.getValue(submission, 'plan_organico_animal_registros_4_b_declaracion') === "Si" ? '■ Sí ❏ No' : this.getValue(submission, 'plan_organico_animal_registros_4_b_declaracion') === "No" ? '❏ Sí ■ No' : '❏ Sí ❏ No'}`,
                                                                    font: 'Arial',
                                                                    size: 18
                                                                })
                                                            ]
                                                        })
                                                    ],
                                                    borders: {
                                                        right: {
                                                            style: BorderStyle.NONE,
                                                            color: "white",
                                                            size: 0
                                                        },
                                                        top: {
                                                            style: BorderStyle.NONE,
                                                            color: "white",
                                                            size: 0
                                                        },
                                                        bottom: {
                                                            style: BorderStyle.NONE,
                                                            color: "white",
                                                            size: 0
                                                        }
                                                    }
                                                })
                                            ]
                                        }),
                                        new TableRow({
                                            children: [
                                                new TableCell({
                                                    children: [
                                                        new Paragraph({
                                                            alignment: AlignmentType.LEFT,
                                                            children: [
                                                                new TextRun({
                                                                    text: `${this.getValue(submission, 'plan_organico_animal_registros_3_b_check') ? '■' : '❏'} Compra e inventario de insumos`,
                                                                    font: 'Arial',
                                                                    size: 18
                                                                })
                                                            ]
                                                        })
                                                    ],
                                                    borders: {
                                                        left: {
                                                            style: BorderStyle.NONE,
                                                            color: "white",
                                                            size: 0
                                                        },
                                                        top: {
                                                            style: BorderStyle.NONE,
                                                            color: "white",
                                                            size: 0
                                                        },
                                                        bottom: {
                                                            style: BorderStyle.NONE,
                                                            color: "white",
                                                            size: 0
                                                        }
                                                    }
                                                }),
                                                new TableCell({
                                                    children: [
                                                        new Paragraph({
                                                            alignment: AlignmentType.CENTER,
                                                            children: [
                                                                new TextRun({
                                                                    text: `${this.getValue(submission, 'plan_organico_animal_registros_3_b_declaracion') === "Si" ? '■ Sí ❏ No' : this.getValue(submission, 'plan_organico_animal_registros_3_b_declaracion') === "No" ? '❏ Sí ■ No' : '❏ Sí ❏ No'}`,
                                                                    font: 'Arial',
                                                                    size: 18
                                                                })
                                                            ]
                                                        })
                                                    ],
                                                    borders: {
                                                        right: {
                                                            style: BorderStyle.NONE,
                                                            color: "white",
                                                            size: 0
                                                        },
                                                        top: {
                                                            style: BorderStyle.NONE,
                                                            color: "white",
                                                            size: 0
                                                        },
                                                        bottom: {
                                                            style: BorderStyle.NONE,
                                                            color: "white",
                                                            size: 0
                                                        }
                                                    }
                                                })
                                            ]
                                        }),
                                        new TableRow({
                                            children: [
                                                new TableCell({
                                                    children: [
                                                        new Paragraph({
                                                            alignment: AlignmentType.LEFT,
                                                            children: [
                                                                new TextRun({
                                                                    text: `${this.getValue(submission, 'plan_organico_animal_registros_4_c_check') ? '■' : '❏'} Aplicación de insumos`,
                                                                    font: 'Arial',
                                                                    size: 18
                                                                })
                                                            ]
                                                        })
                                                    ],
                                                    borders: {
                                                        left: {
                                                            style: BorderStyle.NONE,
                                                            color: "white",
                                                            size: 0
                                                        },
                                                        top: {
                                                            style: BorderStyle.NONE,
                                                            color: "white",
                                                            size: 0
                                                        },
                                                        bottom: {
                                                            style: BorderStyle.NONE,
                                                            color: "white",
                                                            size: 0
                                                        }
                                                    }
                                                }),
                                                new TableCell({
                                                    children: [
                                                        new Paragraph({
                                                            alignment: AlignmentType.CENTER,
                                                            children: [
                                                                new TextRun({
                                                                    text: `${this.getValue(submission, 'plan_organico_animal_registros_4_c_declaracion') === "Si" ? '■ Sí ❏ No' : this.getValue(submission, 'plan_organico_animal_registros_4_c_declaracion') === "No" ? '❏ Sí ■ No' : '❏ Sí ❏ No'}`,
                                                                    font: 'Arial',
                                                                    size: 18
                                                                })
                                                            ]
                                                        })
                                                    ],
                                                    borders: {
                                                        right: {
                                                            style: BorderStyle.NONE,
                                                            color: "white",
                                                            size: 0
                                                        },
                                                        top: {
                                                            style: BorderStyle.NONE,
                                                            color: "white",
                                                            size: 0
                                                        },
                                                        bottom: {
                                                            style: BorderStyle.NONE,
                                                            color: "white",
                                                            size: 0
                                                        }
                                                    }
                                                })
                                            ]
                                        }),
                                        new TableRow({
                                            children: [
                                                new TableCell({
                                                    children: [
                                                        new Paragraph({
                                                            alignment: AlignmentType.LEFT,
                                                            children: [
                                                                new TextRun({
                                                                    text: `${this.getValue(submission, 'plan_organico_animal_registros_4_d_check') ? '■' : '❏'} Cosecha de pasturas`,
                                                                    font: 'Arial',
                                                                    size: 18
                                                                })
                                                            ]
                                                        })
                                                    ],
                                                    borders: {
                                                        left: {
                                                            style: BorderStyle.NONE,
                                                            color: "white",
                                                            size: 0
                                                        },
                                                        top: {
                                                            style: BorderStyle.NONE,
                                                            color: "white",
                                                            size: 0
                                                        },
                                                        bottom: {
                                                            style: BorderStyle.NONE,
                                                            color: "white",
                                                            size: 0
                                                        }
                                                    }
                                                }),
                                                new TableCell({
                                                    children: [
                                                        new Paragraph({
                                                            alignment: AlignmentType.CENTER,
                                                            children: [
                                                                new TextRun({
                                                                    text: `${this.getValue(submission, 'plan_organico_animal_registros_4_d_declaracion') === "Si" ? '■ Sí ❏ No' : this.getValue(submission, 'plan_organico_animal_registros_4_d_declaracion') === "No" ? '❏ Sí ■ No' : '❏ Sí ❏ No'}`,
                                                                    font: 'Arial',
                                                                    size: 18
                                                                })
                                                            ]
                                                        })
                                                    ],
                                                    borders: {
                                                        right: {
                                                            style: BorderStyle.NONE,
                                                            color: "white",
                                                            size: 0
                                                        },
                                                        top: {
                                                            style: BorderStyle.NONE,
                                                            color: "white",
                                                            size: 0
                                                        },
                                                        bottom: {
                                                            style: BorderStyle.NONE,
                                                            color: "white",
                                                            size: 0
                                                        }
                                                    }
                                                })
                                            ]
                                        }),
                                        new TableRow({
                                            children: [
                                                new TableCell({
                                                    children: [
                                                        new Paragraph({
                                                            alignment: AlignmentType.LEFT,
                                                            children: [
                                                                new TextRun({
                                                                    text: `${this.getValue(submission, 'plan_organico_animal_registros_4_e_check') ? '■' : '❏'} Alimentación`,
                                                                    font: 'Arial',
                                                                    size: 18
                                                                })
                                                            ]
                                                        })
                                                    ],
                                                    borders: {
                                                        left: {
                                                            style: BorderStyle.NONE,
                                                            color: "white",
                                                            size: 0
                                                        },
                                                        top: {
                                                            style: BorderStyle.NONE,
                                                            color: "white",
                                                            size: 0
                                                        },
                                                        bottom: {
                                                            style: BorderStyle.NONE,
                                                            color: "white",
                                                            size: 0
                                                        }
                                                    }
                                                }),
                                                new TableCell({
                                                    children: [
                                                        new Paragraph({
                                                            alignment: AlignmentType.CENTER,
                                                            children: [
                                                                new TextRun({
                                                                    text: `${this.getValue(submission, 'plan_organico_animal_registros_4_e_declaracion') === "Si" ? '■ Sí ❏ No' : this.getValue(submission, 'plan_organico_animal_registros_4_e_declaracion') === "No" ? '❏ Sí ■ No' : '❏ Sí ❏ No'}`,
                                                                    font: 'Arial',
                                                                    size: 18
                                                                })
                                                            ]
                                                        })
                                                    ],
                                                    borders: {
                                                        right: {
                                                            style: BorderStyle.NONE,
                                                            color: "white",
                                                            size: 0
                                                        },
                                                        top: {
                                                            style: BorderStyle.NONE,
                                                            color: "white",
                                                            size: 0
                                                        },
                                                        bottom: {
                                                            style: BorderStyle.NONE,
                                                            color: "white",
                                                            size: 0
                                                        }
                                                    }
                                                })
                                            ]
                                        }),
                                        new TableRow({
                                            children: [
                                                new TableCell({
                                                    children: [
                                                        new Paragraph({
                                                            alignment: AlignmentType.LEFT,
                                                            children: [
                                                                new TextRun({
                                                                    text: `${this.getValue(submission, 'plan_organico_animal_registros_4_f_check') ? '■' : '❏'} Tratamientos veterinarios`,
                                                                    font: 'Arial',
                                                                    size: 18
                                                                })
                                                            ]
                                                        })
                                                    ],
                                                    borders: {
                                                        left: {
                                                            style: BorderStyle.NONE,
                                                            color: "white",
                                                            size: 0
                                                        },
                                                        top: {
                                                            style: BorderStyle.NONE,
                                                            color: "white",
                                                            size: 0
                                                        },
                                                        bottom: {
                                                            style: BorderStyle.NONE,
                                                            color: "white",
                                                            size: 0
                                                        }
                                                    }
                                                }),
                                                new TableCell({
                                                    children: [
                                                        new Paragraph({
                                                            alignment: AlignmentType.CENTER,
                                                            children: [
                                                                new TextRun({
                                                                    text: `${this.getValue(submission, 'plan_organico_animal_registros_4_f_declaracion') === "Si" ? '■ Sí ❏ No' : this.getValue(submission, 'plan_organico_animal_registros_4_f_declaracion') === "No" ? '❏ Sí ■ No' : '❏ Sí ❏ No'}`,
                                                                    font: 'Arial',
                                                                    size: 18
                                                                })
                                                            ]
                                                        })
                                                    ],
                                                    borders: {
                                                        right: {
                                                            style: BorderStyle.NONE,
                                                            color: "white",
                                                            size: 0
                                                        },
                                                        top: {
                                                            style: BorderStyle.NONE,
                                                            color: "white",
                                                            size: 0
                                                        },
                                                        bottom: {
                                                            style: BorderStyle.NONE,
                                                            color: "white",
                                                            size: 0
                                                        }
                                                    }
                                                })
                                            ]
                                        }),
                                        new TableRow({
                                            children: [
                                                new TableCell({
                                                    children: [
                                                        new Paragraph({
                                                            alignment: AlignmentType.LEFT,
                                                            children: [
                                                                new TextRun({
                                                                    text: `${this.getValue(submission, 'plan_organico_animal_registros_4_g_check') ? '■' : '❏'} Volumen de producción`,
                                                                    font: 'Arial',
                                                                    size: 18
                                                                })
                                                            ]
                                                        })
                                                    ],
                                                    borders: {
                                                        left: {
                                                            style: BorderStyle.NONE,
                                                            color: "white",
                                                            size: 0
                                                        },
                                                        top: {
                                                            style: BorderStyle.NONE,
                                                            color: "white",
                                                            size: 0
                                                        },
                                                        bottom: {
                                                            style: BorderStyle.NONE,
                                                            color: "white",
                                                            size: 0
                                                        }
                                                    }
                                                }),
                                                new TableCell({
                                                    children: [
                                                        new Paragraph({
                                                            alignment: AlignmentType.CENTER,
                                                            children: [
                                                                new TextRun({
                                                                    text: `${this.getValue(submission, 'plan_organico_animal_registros_4_g_declaracion') === "Si" ? '■ Sí ❏ No' : this.getValue(submission, 'plan_organico_animal_registros_4_g_declaracion') === "No" ? '❏ Sí ■ No' : '❏ Sí ❏ No'}`,
                                                                    font: 'Arial',
                                                                    size: 18
                                                                })
                                                            ]
                                                        })
                                                    ],
                                                    borders: {
                                                        right: {
                                                            style: BorderStyle.NONE,
                                                            color: "white",
                                                            size: 0
                                                        },
                                                        top: {
                                                            style: BorderStyle.NONE,
                                                            color: "white",
                                                            size: 0
                                                        },
                                                        bottom: {
                                                            style: BorderStyle.NONE,
                                                            color: "white",
                                                            size: 0
                                                        }
                                                    }
                                                })
                                            ]
                                        }),
                                        new TableRow({
                                            children: [
                                                new TableCell({
                                                    children: [
                                                        new Paragraph({
                                                            alignment: AlignmentType.LEFT,
                                                            children: [
                                                                new TextRun({
                                                                    text: `${this.getValue(submission, 'plan_organico_animal_registros_4_h_check') ? '■' : '❏'} Ventas`,
                                                                    font: 'Arial',
                                                                    size: 18
                                                                })
                                                            ]
                                                        })
                                                    ],
                                                    borders: {
                                                        left: {
                                                            style: BorderStyle.NONE,
                                                            color: "white",
                                                            size: 0
                                                        },
                                                        top: {
                                                            style: BorderStyle.NONE,
                                                            color: "white",
                                                            size: 0
                                                        },
                                                        bottom: {
                                                            style: BorderStyle.NONE,
                                                            color: "white",
                                                            size: 0
                                                        }
                                                    }
                                                }),
                                                new TableCell({
                                                    children: [
                                                        new Paragraph({
                                                            alignment: AlignmentType.CENTER,
                                                            children: [
                                                                new TextRun({
                                                                    text: `${this.getValue(submission, 'plan_organico_animal_registros_4_h_declaracion') === "Si" ? '■ Sí ❏ No' : this.getValue(submission, 'plan_organico_animal_registros_4_h_declaracion') === "No" ? '❏ Sí ■ No' : '❏ Sí ❏ No'}`,
                                                                    font: 'Arial',
                                                                    size: 18
                                                                })
                                                            ]
                                                        })
                                                    ],
                                                    borders: {
                                                        right: {
                                                            style: BorderStyle.NONE,
                                                            color: "white",
                                                            size: 0
                                                        },
                                                        top: {
                                                            style: BorderStyle.NONE,
                                                            color: "white",
                                                            size: 0
                                                        },
                                                        bottom: {
                                                            style: BorderStyle.NONE,
                                                            color: "white",
                                                            size: 0
                                                        }
                                                    }
                                                })
                                            ]
                                        }),
                                        new TableRow({
                                            children: [
                                                new TableCell({
                                                    children: [
                                                        new Paragraph({
                                                            alignment: AlignmentType.LEFT,
                                                            children: [
                                                                new TextRun({
                                                                    text: `${this.getValue(submission, 'plan_organico_animal_registros_4_otro_check') ? '■' : '❏'} Otro: ${this.getValue(submission, 'plan_organico_animal_registros_4_otro')}`,
                                                                    font: 'Arial',
                                                                    size: 18
                                                                })
                                                            ]
                                                        })
                                                    ],
                                                    borders: {
                                                        left: {
                                                            style: BorderStyle.NONE,
                                                            color: "white",
                                                            size: 0
                                                        },
                                                        top: {
                                                            style: BorderStyle.NONE,
                                                            color: "white",
                                                            size: 0
                                                        },
                                                        bottom: {
                                                            style: BorderStyle.NONE,
                                                            color: "white",
                                                            size: 0
                                                        }
                                                    }
                                                }),
                                                new TableCell({
                                                    children: [
                                                        new Paragraph({
                                                            alignment: AlignmentType.CENTER,
                                                            children: [
                                                                new TextRun({
                                                                    text: `${this.getValue(submission, 'plan_organico_animal_registros_4_otro_declaracion') === "Si" ? '■ Sí ❏ No' : this.getValue(submission, 'plan_organico_animal_registros_4_otro_declaracion') === "No" ? '❏ Sí ■ No' : '❏ Sí ❏ No'}`,
                                                                    font: 'Arial',
                                                                    size: 18
                                                                })
                                                            ]
                                                        })
                                                    ],
                                                    borders: {
                                                        right: {
                                                            style: BorderStyle.NONE,
                                                            color: "white",
                                                            size: 0
                                                        },
                                                        top: {
                                                            style: BorderStyle.NONE,
                                                            color: "white",
                                                            size: 0
                                                        },
                                                        bottom: {
                                                            style: BorderStyle.NONE,
                                                            color: "white",
                                                            size: 0
                                                        }
                                                    }
                                                })
                                            ]
                                        })
                                    ],
                                    //4395
                                    columnWidths: [3075, 1320]
                                })
                            ],
                            columnSpan: 2
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: this.getValue(submission, 'plan_organico_animal_registros_4_comentarios'),
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                })
                            ],
                            verticalAlign: VerticalAlign.TOP
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: ` ${this.getValue(submission, 'plan_organico_animal_registros_implementacion_4') === 'I' ? '■' : '❏'} I`,
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                  }),
                                  new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: ` ${this.getValue(submission, 'plan_organico_animal_registros_implementacion_4') === 'NI' ? '■' : '❏'} NI`,
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                  })
                            ],
                            verticalAlign: VerticalAlign.TOP
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: ` ${this.getValue(submission, 'plan_organico_animal_registros_conformidad_4') === 'C' ? '■' : '❏'} C`,
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                  }),
                                  new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: ` ${this.getValue(submission, 'plan_organico_animal_registros_conformidad_4') === 'NC' ? '■' : '❏'} NC`,
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                  }),
                                  new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: ` ${this.getValue(submission, 'plan_organico_animal_registros_conformidad_4') === 'NA' ? '■' : '❏'} NA`,
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                  })
                            ],
                            verticalAlign: VerticalAlign.TOP
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: this.getValue(submission, 'plan_organico_animal_registros_comentarios_inspector_4'),
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                  })
                            ],
                            verticalAlign: VerticalAlign.TOP
                        })
                    ]
                }),
                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: '5',
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                })
                            ],
                            verticalAlign: VerticalAlign.TOP
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: 'He incluido una lista completa de riesgos para la integridad orgánica de los productos en el formato MTO-FSOL-04 (Agrícola, Animal y Procesados)',
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                })
                            ],
                            verticalAlign: VerticalAlign.TOP
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: this.getValue(submission, 'plan_organico_animal_registros_5_declaracion') === 'Si' ? '■ SÍ  ❏ NO' : '❏ SÍ  ■ NO',
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                })
                            ],
                            verticalAlign: VerticalAlign.TOP
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: this.getValue(submission, 'plan_organico_animal_registros_5_comentarios'),
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                })
                            ],
                            verticalAlign: VerticalAlign.TOP
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: ` ${this.getValue(submission, 'plan_organico_animal_registros_implementacion_5') === 'I' ? '■' : '❏'} I`,
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                  }),
                                  new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: ` ${this.getValue(submission, 'plan_organico_animal_registros_implementacion_5') === 'NI' ? '■' : '❏'} NI`,
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                  })
                            ],
                            verticalAlign: VerticalAlign.TOP
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: ` ${this.getValue(submission, 'plan_organico_animal_registros_conformidad_5') === 'C' ? '■' : '❏'} C`,
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                  }),
                                  new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: ` ${this.getValue(submission, 'plan_organico_animal_registros_conformidad_5') === 'NC' ? '■' : '❏'} NC`,
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                  }),
                                  new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: ` ${this.getValue(submission, 'plan_organico_animal_registros_conformidad_5') === 'NA' ? '■' : '❏'} NA`,
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                  })
                            ],
                            verticalAlign: VerticalAlign.TOP
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: this.getValue(submission, 'plan_organico_animal_registros_comentarios_inspector_5'),
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                  })
                            ],
                            verticalAlign: VerticalAlign.TOP
                        })
                    ]
                }),
                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: '6',
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                })
                            ],
                            verticalAlign: VerticalAlign.TOP
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: 'He incluido un análisis completo de riesgos de contaminación por colindantes en el formato MTO-FSOL-06',
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                })
                            ],
                            verticalAlign: VerticalAlign.TOP
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: this.getValue(submission, 'plan_organico_animal_registros_6_declaracion') === 'Si' ? '■ SÍ  ❏ NO' : '❏ SÍ  ■ NO',
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                })
                            ],
                            verticalAlign: VerticalAlign.TOP
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: this.getValue(submission, 'plan_organico_animal_registros_6_comentarios'),
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                })
                            ],
                            verticalAlign: VerticalAlign.TOP
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: ` ${this.getValue(submission, 'plan_organico_animal_registros_implementacion_6') === 'I' ? '■' : '❏'} I`,
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                  }),
                                  new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: ` ${this.getValue(submission, 'plan_organico_animal_registros_implementacion_6') === 'NI' ? '■' : '❏'} NI`,
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                  })
                            ],
                            verticalAlign: VerticalAlign.TOP
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: ` ${this.getValue(submission, 'plan_organico_animal_registros_conformidad_6') === 'C' ? '■' : '❏'} C`,
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                  }),
                                  new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: ` ${this.getValue(submission, 'plan_organico_animal_registros_conformidad_6') === 'NC' ? '■' : '❏'} NC`,
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                  }),
                                  new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: ` ${this.getValue(submission, 'plan_organico_animal_registros_conformidad_6') === 'NA' ? '■' : '❏'} NA`,
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                  })
                            ],
                            verticalAlign: VerticalAlign.TOP
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: this.getValue(submission, 'plan_organico_animal_registros_comentarios_inspector_6'),
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                  })
                            ],
                            verticalAlign: VerticalAlign.TOP
                        })
                    ]
                }),
                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: '7',
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                })
                            ],
                            verticalAlign: VerticalAlign.TOP
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: 'He incluido una lista completa de los insumos que utilizo para el abonado de pasturas en el formato MTO-FSOL-07',
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                })
                            ],
                            verticalAlign: VerticalAlign.TOP
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: this.getValue(submission, 'plan_organico_animal_registros_7_declaracion') === 'Si' ? '■ SÍ  ❏ NO' : '❏ SÍ  ■ NO',
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                })
                            ],
                            verticalAlign: VerticalAlign.TOP
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: this.getValue(submission, 'plan_organico_animal_registros_7_comentarios'),
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                })
                            ],
                            verticalAlign: VerticalAlign.TOP
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: ` ${this.getValue(submission, 'plan_organico_animal_registros_implementacion_7') === 'I' ? '■' : '❏'} I`,
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                  }),
                                  new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: ` ${this.getValue(submission, 'plan_organico_animal_registros_implementacion_7') === 'NI' ? '■' : '❏'} NI`,
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                  })
                            ],
                            verticalAlign: VerticalAlign.TOP
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: ` ${this.getValue(submission, 'plan_organico_animal_registros_conformidad_7') === 'C' ? '■' : '❏'} C`,
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                  }),
                                  new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: ` ${this.getValue(submission, 'plan_organico_animal_registros_conformidad_7') === 'NC' ? '■' : '❏'} NC`,
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                  }),
                                  new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: ` ${this.getValue(submission, 'plan_organico_animal_registros_conformidad_7') === 'NA' ? '■' : '❏'} NA`,
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                  })
                            ],
                            verticalAlign: VerticalAlign.TOP
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: this.getValue(submission, 'plan_organico_animal_registros_comentarios_inspector_7'),
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                  })
                            ],
                            verticalAlign: VerticalAlign.TOP
                        })
                    ]
                }),
                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: '8',
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                })
                            ],
                            verticalAlign: VerticalAlign.TOP
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: 'He incluido una lista completa de las sustancias que utilizo para el control de plagas y enfermedades de pasturas en el formato MTO-FSOL-07',
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                })
                            ],
                            verticalAlign: VerticalAlign.TOP
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: this.getValue(submission, 'plan_organico_animal_registros_8_declaracion') === 'Si' ? '■ SÍ  ❏ NO' : '❏ SÍ  ■ NO',
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                })
                            ],
                            verticalAlign: VerticalAlign.TOP
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: this.getValue(submission, 'plan_organico_animal_registros_8_comentarios'),
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                })
                            ],
                            verticalAlign: VerticalAlign.TOP
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: ` ${this.getValue(submission, 'plan_organico_animal_registros_implementacion_8') === 'I' ? '■' : '❏'} I`,
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                  }),
                                  new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: ` ${this.getValue(submission, 'plan_organico_animal_registros_implementacion_8') === 'NI' ? '■' : '❏'} NI`,
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                  })
                            ],
                            verticalAlign: VerticalAlign.TOP
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: ` ${this.getValue(submission, 'plan_organico_animal_registros_conformidad_8') === 'C' ? '■' : '❏'} C`,
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                  }),
                                  new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: ` ${this.getValue(submission, 'plan_organico_animal_registros_conformidad_8') === 'NC' ? '■' : '❏'} NC`,
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                  }),
                                  new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: ` ${this.getValue(submission, 'plan_organico_animal_registros_conformidad_8') === 'NA' ? '■' : '❏'} NA`,
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                  })
                            ],
                            verticalAlign: VerticalAlign.TOP
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: this.getValue(submission, 'plan_organico_animal_registros_comentarios_inspector_8'),
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                  })
                            ],
                            verticalAlign: VerticalAlign.TOP
                        })
                    ]
                }),
                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: '9',
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                })
                            ],
                            verticalAlign: VerticalAlign.TOP
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: 'He incluido una lista completa de los alimentos y complementos alimenticios para el ganado en el formato MTO-FSOL-07',
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                })
                            ],
                            verticalAlign: VerticalAlign.TOP
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: this.getValue(submission, 'plan_organico_animal_registros_9_declaracion') === 'Si' ? '■ SÍ  ❏ NO' : '❏ SÍ  ■ NO',
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                })
                            ],
                            verticalAlign: VerticalAlign.TOP
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: this.getValue(submission, 'plan_organico_animal_registros_9_comentarios'),
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                })
                            ],
                            verticalAlign: VerticalAlign.TOP
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: ` ${this.getValue(submission, 'plan_organico_animal_registros_implementacion_9') === 'I' ? '■' : '❏'} I`,
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                  }),
                                  new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: ` ${this.getValue(submission, 'plan_organico_animal_registros_implementacion_9') === 'NI' ? '■' : '❏'} NI`,
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                  })
                            ],
                            verticalAlign: VerticalAlign.TOP
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: ` ${this.getValue(submission, 'plan_organico_animal_registros_conformidad_9') === 'C' ? '■' : '❏'} C`,
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                  }),
                                  new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: ` ${this.getValue(submission, 'plan_organico_animal_registros_conformidad_9') === 'NC' ? '■' : '❏'} NC`,
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                  }),
                                  new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: ` ${this.getValue(submission, 'plan_organico_animal_registros_conformidad_9') === 'NA' ? '■' : '❏'} NA`,
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                  })
                            ],
                            verticalAlign: VerticalAlign.TOP
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: this.getValue(submission, 'plan_organico_animal_registros_comentarios_inspector_9'),
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                  })
                            ],
                            verticalAlign: VerticalAlign.TOP
                        })
                    ]
                }),
                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: '10',
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                })
                            ],
                            verticalAlign: VerticalAlign.TOP
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: 'He incluido una lista completa de las sustancias para manejo veterinario del ganado en el formato MTO-FSOL-07',
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                })
                            ],
                            verticalAlign: VerticalAlign.TOP
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: this.getValue(submission, 'plan_organico_animal_registros_10_declaracion') === 'Si' ? '■ SÍ  ❏ NO' : '❏ SÍ  ■ NO',
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                })
                            ],
                            verticalAlign: VerticalAlign.TOP
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: this.getValue(submission, 'plan_organico_animal_registros_10_comentarios'),
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                })
                            ],
                            verticalAlign: VerticalAlign.TOP
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: ` ${this.getValue(submission, 'plan_organico_animal_registros_implementacion_10') === 'I' ? '■' : '❏'} I`,
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                  }),
                                  new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: ` ${this.getValue(submission, 'plan_organico_animal_registros_implementacion_10') === 'NI' ? '■' : '❏'} NI`,
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                  })
                            ],
                            verticalAlign: VerticalAlign.TOP
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: ` ${this.getValue(submission, 'plan_organico_animal_registros_conformidad_10') === 'C' ? '■' : '❏'} C`,
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                  }),
                                  new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: ` ${this.getValue(submission, 'plan_organico_animal_registros_conformidad_10') === 'NC' ? '■' : '❏'} NC`,
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                  }),
                                  new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: ` ${this.getValue(submission, 'plan_organico_animal_registros_conformidad_10') === 'NA' ? '■' : '❏'} NA`,
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                  })
                            ],
                            verticalAlign: VerticalAlign.TOP
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: this.getValue(submission, 'plan_organico_animal_registros_comentarios_inspector_10'),
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                  })
                            ],
                            verticalAlign: VerticalAlign.TOP
                        })
                    ]
                }),
                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: '11',
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                })
                            ],
                            verticalAlign: VerticalAlign.TOP
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: 'En caso de aprovechamiento de animales no domesticados, he incluido copias de los siguientes documentos relacionados con la autorización de mí Unidad de Manejo para la Conservación de la Vida Silvestre (UAM): ',
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                }),
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: '1.- Tasa de aprovechamiento.',
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                }),
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: '2.- Copia de constancia emitida por SEMARNAT de no estar alterando el ecosistema y no estar haciendo una recolección mayor a la tasa de reproducción a la que tiene la especie.',
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                }),
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: '3.- Copia de constancia emitida por SEMARNAT de no estar afectando ninguna especie animal y vegetal en peligro de extinción.',
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                })
                            ],
                            verticalAlign: VerticalAlign.TOP
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: this.getValue(submission, 'plan_organico_animal_registros_11_declaracion') === 'Si' ? '■ SÍ  ❏ NO' : '❏ SÍ  ■ NO',
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                })
                            ],
                            verticalAlign: VerticalAlign.TOP
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: this.getValue(submission, 'plan_organico_animal_registros_11_comentarios'),
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                })
                            ],
                            verticalAlign: VerticalAlign.TOP
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: ` ${this.getValue(submission, 'plan_organico_animal_registros_implementacion_11') === 'I' ? '■' : '❏'} I`,
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                  }),
                                  new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: ` ${this.getValue(submission, 'plan_organico_animal_registros_implementacion_11') === 'NI' ? '■' : '❏'} NI`,
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                  })
                            ],
                            verticalAlign: VerticalAlign.TOP
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: ` ${this.getValue(submission, 'plan_organico_animal_registros_conformidad_11') === 'C' ? '■' : '❏'} C`,
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                  }),
                                  new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: ` ${this.getValue(submission, 'plan_organico_animal_registros_conformidad_11') === 'NC' ? '■' : '❏'} NC`,
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                  }),
                                  new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: ` ${this.getValue(submission, 'plan_organico_animal_registros_conformidad_11') === 'NA' ? '■' : '❏'} NA`,
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                  })
                            ],
                            verticalAlign: VerticalAlign.TOP
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: this.getValue(submission, 'plan_organico_animal_registros_comentarios_inspector_11'),
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                  })
                            ],
                            verticalAlign: VerticalAlign.TOP
                        })
                    ]
                }),
                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: '12',
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                })
                            ],
                            verticalAlign: VerticalAlign.TOP
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: 'En caso de desastres naturales, he incluido una carta de solicitud de uso de alimentos no orgánicos.',
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                })
                            ],
                            verticalAlign: VerticalAlign.TOP
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: this.getValue(submission, 'plan_organico_animal_registros_12_declaracion') === 'Si' ? '■ SÍ  ❏ NO' : '❏ SÍ  ■ NO',
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                })
                            ],
                            verticalAlign: VerticalAlign.TOP
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: this.getValue(submission, 'plan_organico_animal_registros_12_comentarios'),
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                })
                            ],
                            verticalAlign: VerticalAlign.TOP
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: ` ${this.getValue(submission, 'plan_organico_animal_registros_implementacion_12') === 'I' ? '■' : '❏'} I`,
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                  }),
                                  new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: ` ${this.getValue(submission, 'plan_organico_animal_registros_implementacion_12') === 'NI' ? '■' : '❏'} NI`,
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                  })
                            ],
                            verticalAlign: VerticalAlign.TOP
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: ` ${this.getValue(submission, 'plan_organico_animal_registros_conformidad_12') === 'C' ? '■' : '❏'} C`,
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                  }),
                                  new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: ` ${this.getValue(submission, 'plan_organico_animal_registros_conformidad_12') === 'NC' ? '■' : '❏'} NC`,
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                  }),
                                  new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: ` ${this.getValue(submission, 'plan_organico_animal_registros_conformidad_12') === 'NA' ? '■' : '❏'} NA`,
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                  })
                            ],
                            verticalAlign: VerticalAlign.TOP
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: this.getValue(submission, 'plan_organico_animal_registros_comentarios_inspector_12'),
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                  })
                            ],
                            verticalAlign: VerticalAlign.TOP
                        })
                    ]
                }),
            ],
            margins: {
                left: 70,
                right: 70
            },
            //9885
            columnWidths: [450, 3050, 1345, 1545, 1150, 800, 1545]
        })
        return table
    }

    public createHandling_MTO_FSOL_08_2_1(submission): Table {
        const table = new Table({
            rows: [
                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: 'MANEJO PASTURAS',
                                            font: 'Arial',
                                            size: 20,
                                            bold: true
                                        })
                                    ],
                                    spacing: {
                                        after: 75,
                                        before: 75
                                    }
                                }),
                            ],
                            verticalAlign: VerticalAlign.CENTER,
                            columnSpan: 5,
                            shading: {
                                fill: '#C0C0C0'
                            }
                        })
                    ]
                }),
                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: 'No.',
                                            font: 'Arial',
                                            size: 18,
                                            bold: true
                                        })
                                    ]
                                })
                            ],
                            rowSpan: 2,
                            verticalAlign: VerticalAlign.CENTER,
                            width: {
                                size: 25,
                                type: WidthType.PERCENTAGE
                            }
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: 'Requisitos',
                                            font: 'Arial',
                                            size: 18,
                                            bold: true
                                        })
                                    ]
                                })
                            ],
                            rowSpan: 2,
                            verticalAlign: VerticalAlign.CENTER,
                            width: {
                                size: 15,
                                type: WidthType.PERCENTAGE
                            }
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: 'Comentarios',
                                            font: 'Arial',
                                            size: 18,
                                            bold: true
                                        })
                                    ]
                                })
                            ],
                            rowSpan: 2,
                            verticalAlign: VerticalAlign.CENTER,
                            width: {
                                size: 15,
                                type: WidthType.PERCENTAGE
                            }
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: 'Conformidad',
                                            font: 'Arial',
                                            size: 18,
                                            bold: true
                                        })
                                    ]
                                })
                            ],
                            columnSpan: 3,
                            verticalAlign: VerticalAlign.CENTER,
                            width: {
                                size: 15,
                                type: WidthType.PERCENTAGE
                            }
                        })
                    ]
                }),
                new TableRow({
                    children: [new TableCell({
                        children: [
                            new Paragraph({
                                alignment: AlignmentType.CENTER,
                                children: [
                                    new TextRun({
                                        text: 'Implementación',
                                        font: 'Arial',
                                        size: 18,
                                        bold: true
                                    })
                                ]
                            })
                        ],
                        verticalAlign: VerticalAlign.CENTER
                    }),
                    new TableCell({
                        children: [
                            new Paragraph({
                                alignment: AlignmentType.CENTER,
                                children: [
                                    new TextRun({
                                        text: 'Conformidad',
                                        font: 'Arial',
                                        size: 18,
                                        bold: true
                                    })
                                ]
                            })
                        ],
                        verticalAlign: VerticalAlign.CENTER,
                        columnSpan: 2
                    }),]
                }),
                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: '1',
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                })
                            ],
                            verticalAlign: VerticalAlign.TOP
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: 'El material de propagación es:',
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                }),
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: (this.getValue(submission, "pplan_organico_animal_manejo_1_organico_propio") ? '■' : '❏') + '  Orgánico propio',
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                }),
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: (this.getValue(submission, "plan_organico_animal_manejo_1_organico_certificado") ? '■' : '❏') + '  Orgánico externo y certificado',
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                }),
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: (this.getValue(submission, "plan_organico_animal_manejo_1_convencional") ? '■' : '❏') + '  Convencional no tratado',
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                }),
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: (this.getValue(submission, "plan_organico_animal_manejo_1_convencional_tratado") ? '■' : '❏') + '  Convencional y tratado',
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                }),
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: (this.getValue(submission, "plan_organico_animal_manejo_1_otro") ? '■' : '❏') + ' Otro (¿Cuál?): ' + this.getValue(submission, "plan_organico_animal_manejo_1_otro_text"),
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                }),
                            ],
                            verticalAlign: VerticalAlign.TOP
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: this.getValue(submission, "plan_organico_animal_manejo_1_comentarios"),
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                })
                            ],
                            verticalAlign: VerticalAlign.TOP
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: ` ${this.getValue(submission, 'plan_organico_animal_manejo_implementacion_1') === 'I' ? '■' : '❏'} I`,
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                  }),
                                  new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: ` ${this.getValue(submission, 'plan_organico_animal_manejo_implementacion_1') === 'NI' ? '■' : '❏'} NI`,
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                  })
                            ],
                            verticalAlign: VerticalAlign.TOP
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: ` ${this.getValue(submission, 'plan_organico_animal_manejo_conformidad_1') === 'C' ? '■' : '❏'} C`,
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                  }),
                                  new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: ` ${this.getValue(submission, 'plan_organico_animal_manejo_conformidad_1') === 'NC' ? '■' : '❏'} NC`,
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                  }),
                                  new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: ` ${this.getValue(submission, 'plan_organico_animal_manejo_conformidad_1') === 'NA' ? '■' : '❏'} NA`,
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                  })
                            ],
                            verticalAlign: VerticalAlign.TOP
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: this.getValue(submission, 'plan_organico_animal_manejo_comentarios_inspector_1'),
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                  })
                            ],
                            verticalAlign: VerticalAlign.TOP
                        })
                    ]
                }),
                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: '2',
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                })
                            ],
                            verticalAlign: VerticalAlign.TOP
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: 'Realizo las siguientes prácticas de preparación del suelo:',
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                }),
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: (this.getValue(submission, "plan_organico_animal_manejo_2_barbecho") ? '■' : '❏') + ' Barbecho',
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                }),
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: (this.getValue(submission, "plan_organico_animal_manejo_2_rastreo") ? '■' : '❏') + ' Rastreo',
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                }),
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: (this.getValue(submission, "plan_organico_animal_manejo_2_surcado") ? '■' : '❏') + ' Surcado',
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                }),
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: (this.getValue(submission, "plan_organico_animal_manejo_2_nivelacion") ? '■' : '❏') + ' Nivelación',
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                }),
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: (this.getValue(submission, "plan_organico_animal_manejo_2_roza") ? '■' : '❏') + ' Roza',
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                }),
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: (this.getValue(submission, "plan_organico_animal_manejo_2_otro") ? '■' : '❏') + ' Otro (¿Cuál?): ' + this.getValue(submission, "plan_organico_animal_manejo_2_otro_text"),
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                }),
                            ],
                            verticalAlign: VerticalAlign.TOP
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: this.getValue(submission, "plan_organico_animal_manejo_2_comentarios"),
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                })
                            ],
                            verticalAlign: VerticalAlign.TOP
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: ` ${this.getValue(submission, 'plan_organico_animal_manejo_implementacion_2') === 'I' ? '■' : '❏'} I`,
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                  }),
                                  new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: ` ${this.getValue(submission, 'plan_organico_animal_manejo_implementacion_2') === 'NI' ? '■' : '❏'} NI`,
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                  })
                            ],
                            verticalAlign: VerticalAlign.TOP
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: ` ${this.getValue(submission, 'plan_organico_animal_manejo_conformidad_2') === 'C' ? '■' : '❏'} C`,
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                  }),
                                  new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: ` ${this.getValue(submission, 'plan_organico_animal_manejo_conformidad_2') === 'NC' ? '■' : '❏'} NC`,
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                  }),
                                  new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: ` ${this.getValue(submission, 'plan_organico_animal_manejo_conformidad_2') === 'NA' ? '■' : '❏'} NA`,
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                  })
                            ],
                            verticalAlign: VerticalAlign.TOP
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: this.getValue(submission, 'plan_organico_animal_manejo_comentarios_inspector_2'),
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                  })
                            ],
                            verticalAlign: VerticalAlign.TOP
                        })
                    ]
                }),
                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: '3',
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                })
                            ],
                            verticalAlign: VerticalAlign.TOP
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: 'Realizo las siguientes actividades para el control de la erosión:',
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                }),
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: (this.getValue(submission, "plan_organico_animal_manejo_3_siembra_nivel") ? '■' : '❏') + '  Siembra a nivel',
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                }),
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: (this.getValue(submission, "plan_organico_animal_manejo_3_terrazas") ? '■' : '❏') + '  Terrazas',
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                }),
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: (this.getValue(submission, "plan_organico_animal_manejo_3_barreras_vivas") ? '■' : '❏') + ' Barreras vivas',
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                }),
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: (this.getValue(submission, "plan_organico_animal_manejo_3_barreras_muertas") ? '■' : '❏') + '  Barreras muertas',
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                }),
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: (this.getValue(submission, "plan_organico_animal_manejo_3_zanjas") ? '■' : '❏') + ' Bordos y zanjas',
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                }),
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: (this.getValue(submission, "plan_organico_animal_manejo_3_cultivos_cobertura") ? '■' : '❏') + ' Cultivos de cobertura',
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                }),
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: (this.getValue(submission, "plan_organico_animal_manejo_3_otro") ? '■' : '❏') + ' Otro (¿Cuál?): ' + this.getValue(submission, "plan_organico_animal_manejo_3_otro_text"),
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                }),
                            ],
                            verticalAlign: VerticalAlign.TOP
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: this.getValue(submission, "plan_organico_animal_manejo_3_comentarios"),
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                })
                            ],
                            verticalAlign: VerticalAlign.TOP
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: ` ${this.getValue(submission, 'plan_organico_animal_manejo_implementacion_3') === 'I' ? '■' : '❏'} I`,
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                  }),
                                  new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: ` ${this.getValue(submission, 'plan_organico_animal_manejo_implementacion_3') === 'NI' ? '■' : '❏'} NI`,
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                  })
                            ],
                            verticalAlign: VerticalAlign.TOP
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: ` ${this.getValue(submission, 'plan_organico_animal_manejo_conformidad_3') === 'C' ? '■' : '❏'} C`,
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                  }),
                                  new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: ` ${this.getValue(submission, 'plan_organico_animal_manejo_conformidad_3') === 'NC' ? '■' : '❏'} NC`,
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                  }),
                                  new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: ` ${this.getValue(submission, 'plan_organico_animal_manejo_conformidad_3') === 'NA' ? '■' : '❏'} NA`,
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                  })
                            ],
                            verticalAlign: VerticalAlign.TOP
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: this.getValue(submission, 'plan_organico_animal_manejo_comentarios_inspector_3'),
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                  })
                            ],
                            verticalAlign: VerticalAlign.TOP
                        })
                    ]
                }),
                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: '4',
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                })
                            ],
                            verticalAlign: VerticalAlign.TOP
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: 'Realizo las siguientes actividades para la conservación y mejoramiento de la fertilidad del suelo:',
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                }),
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: (this.getValue(submission, "plan_organico_animal_manejo_4_rotacion") ? '■' : '❏') + ' Rotación de cultivos',
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                }),
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: (this.getValue(submission, "plan_organico_animal_manejo_4_aportacion") ? '■' : '❏') + ' Aportación de materia orgánica',
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                }),
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: (this.getValue(submission, "plan_organico_animal_manejo_4_abonos") ? '■' : '❏') + ' Abonos verdes',
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                }),
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: (this.getValue(submission, "plan_organico_animal_manejo_4_cal") ? '■' : '❏') + ' Aplicación de cal o yeso',
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                }),
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: (this.getValue(submission, "plan_organico_animal_manejo_4_microorganismos") ? '■' : '❏') + ' Aplicación de microorganismos',
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                }),
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: (this.getValue(submission, "plan_organico_animal_manejo_4_mejoradores") ? '■' : '❏') + ' Aplicación de mejoradores del suelo',
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                }),
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: (this.getValue(submission, "plan_organico_animal_manejo_4_otro") ? '■' : '❏') + ' Otro (¿Cuál?):' + this.getValue(submission, "plan_organico_animal_manejo_4_otro_text"),
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                })
                            ],
                            verticalAlign: VerticalAlign.TOP
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: this.getValue(submission, "plan_organico_animal_manejo_4_comentarios"),
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                })
                            ],
                            verticalAlign: VerticalAlign.TOP
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: ` ${this.getValue(submission, 'plan_organico_animal_manejo_implementacion_4') === 'I' ? '■' : '❏'} I`,
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                  }),
                                  new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: ` ${this.getValue(submission, 'plan_organico_animal_manejo_implementacion_4') === 'NI' ? '■' : '❏'} NI`,
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                  })
                            ],
                            verticalAlign: VerticalAlign.TOP
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: ` ${this.getValue(submission, 'plan_organico_animal_manejo_conformidad_4') === 'C' ? '■' : '❏'} C`,
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                  }),
                                  new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: ` ${this.getValue(submission, 'plan_organico_animal_manejo_conformidad_4') === 'NC' ? '■' : '❏'} NC`,
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                  }),
                                  new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: ` ${this.getValue(submission, 'plan_organico_animal_manejo_conformidad_4') === 'NA' ? '■' : '❏'} NA`,
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                  })
                            ],
                            verticalAlign: VerticalAlign.TOP
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: this.getValue(submission, 'plan_organico_animal_manejo_comentarios_inspector_4'),
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                  })
                            ],
                            verticalAlign: VerticalAlign.TOP
                        })
                    ]
                }),
                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: '5',
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                })
                            ],
                            verticalAlign: VerticalAlign.TOP
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: 'Controlo las malezas por medio de:',
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                }),
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: (this.getValue(submission, "plan_organico_animal_manejo_5_machete") ? '■' : '❏') + ' Machete',
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                }),
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: (this.getValue(submission, "plan_organico_animal_manejo_5_pala") ? '■' : '❏') + ' Pala o azadón',
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                }),
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: (this.getValue(submission, "plan_organico_animal_manejo_5_desbrozadora") ? '■' : '❏') + ' Desbrozadora',
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                }),
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: (this.getValue(submission, "plan_organico_animal_manejo_5_rastra") ? '■' : '❏') + ' Rastra',
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                }),
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: (this.getValue(submission, "plan_organico_animal_manejo_5_herbicida") ? '■' : '❏') + ' Herbicida',
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                }),
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: (this.getValue(submission, "plan_organico_animal_manejo_5_otro") ? '■' : '❏') + ' Otro (¿Cuál?): ' + this.getValue(submission, "plan_organico_animal_manejo_5_otro_text"),
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                })
                            ],
                            verticalAlign: VerticalAlign.TOP
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: this.getValue(submission, "plan_organico_animal_manejo_5_comentarios"),
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                })
                            ],
                            verticalAlign: VerticalAlign.TOP
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: ` ${this.getValue(submission, 'plan_organico_animal_manejo_implementacion_5') === 'I' ? '■' : '❏'} I`,
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                  }),
                                  new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: ` ${this.getValue(submission, 'plan_organico_animal_manejo_implementacion_5') === 'NI' ? '■' : '❏'} NI`,
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                  })
                            ],
                            verticalAlign: VerticalAlign.TOP
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: ` ${this.getValue(submission, 'plan_organico_animal_manejo_conformidad_5') === 'C' ? '■' : '❏'} C`,
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                  }),
                                  new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: ` ${this.getValue(submission, 'plan_organico_animal_manejo_conformidad_5') === 'NC' ? '■' : '❏'} NC`,
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                  }),
                                  new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: ` ${this.getValue(submission, 'plan_organico_animal_manejo_conformidad_5') === 'NA' ? '■' : '❏'} NA`,
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                  })
                            ],
                            verticalAlign: VerticalAlign.TOP
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: this.getValue(submission, 'plan_organico_animal_manejo_comentarios_inspector_5'),
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                  })
                            ],
                            verticalAlign: VerticalAlign.TOP
                        })
                    ]
                }),
                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: '6',
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                })
                            ],
                            verticalAlign: VerticalAlign.TOP
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: 'Para el control de plagas y enfermedades realizó las siguientes prácticas:',
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                }),
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: (this.getValue(submission, "plan_organico_animal_manejo_6_misma_enemigos_naturales") ? '■' : '❏') + ' Enemigos naturales',
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                }),
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: (this.getValue(submission, "plan_organico_animal_manejo_6_trampas") ? '■' : '❏') + ' Trampas',
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                }),
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: (this.getValue(submission, "plan_organico_animal_manejo_6_variedades") ? '■' : '❏') + ' Variedades resistentes',
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                }),
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: (this.getValue(submission, "plan_organico_animal_manejo_6_extractos") ? '■' : '❏') + ' Extractos vegetales',
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                }),
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: (this.getValue(submission, "plan_organico_animal_manejo_6_homeopaticas") ? '■' : '❏') + ' Sustancias homeopáticas',
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                }),
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: (this.getValue(submission, "plan_organico_animal_manejo_6_sustancias_permitidas") ? '■' : '❏') + ' Sustancias permitidas por las normas',
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                }),
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: (this.getValue(submission, "plan_organico_animal_manejo_6_agroquimicos") ? '■' : '❏') + ' Agroquímicos',
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                }),
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: (this.getValue(submission, "plan_organico_animal_manejo_6_otro") ? '■' : '❏') + ' Otro (¿Cuál?): ' + this.getValue(submission, "plan_organico_animal_manejo_6_otro_text"),
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                })
                            ],
                            verticalAlign: VerticalAlign.TOP
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: this.getValue(submission, "plan_organico_animal_manejo_6_comentarios"),
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                })
                            ],
                            verticalAlign: VerticalAlign.TOP
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: ` ${this.getValue(submission, 'plan_organico_animal_manejo_implementacion_6') === 'I' ? '■' : '❏'} I`,
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                  }),
                                  new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: ` ${this.getValue(submission, 'plan_organico_animal_manejo_implementacion_6') === 'NI' ? '■' : '❏'} NI`,
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                  })
                            ],
                            verticalAlign: VerticalAlign.TOP
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: ` ${this.getValue(submission, 'plan_organico_animal_manejo_conformidad_6') === 'C' ? '■' : '❏'} C`,
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                  }),
                                  new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: ` ${this.getValue(submission, 'plan_organico_animal_manejo_conformidad_6') === 'NC' ? '■' : '❏'} NC`,
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                  }),
                                  new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: ` ${this.getValue(submission, 'plan_organico_animal_manejo_conformidad_6') === 'NA' ? '■' : '❏'} NA`,
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                  })
                            ],
                            verticalAlign: VerticalAlign.TOP
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: this.getValue(submission, 'plan_organico_animal_manejo_comentarios_inspector_6'),
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                  })
                            ],
                            verticalAlign: VerticalAlign.TOP
                        })
                    ]
                }),
                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: '7',
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                })
                            ],
                            verticalAlign: VerticalAlign.TOP
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: 'Para la cosecha realizo las siguientes prácticas:',
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                }),
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: (this.getValue(submission, "plan_organico_animal_manejo_7_corte") ? '■' : '❏') + ' Corte',
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                }),
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: (this.getValue(submission, "plan_organico_animal_manejo_7_seleccion") ? '■' : '❏') + ' Selección',
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                }),
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: (this.getValue(submission, "plan_organico_animal_manejo_7_lavado") ? '■' : '❏') + ' Lavado',
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                }),
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: (this.getValue(submission, "plan_organico_animal_manejo_7_otro") ? '■' : '❏') + ' Otro (¿Cuál?): ' + this.getValue(submission, "plan_organico_animal_manejo_7_otro_text"),
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                })
                            ],
                            verticalAlign: VerticalAlign.TOP
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: this.getValue(submission, "plan_organico_animal_manejo_7_comentarios"),
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                })
                            ],
                            verticalAlign: VerticalAlign.TOP
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: ` ${this.getValue(submission, 'plan_organico_animal_manejo_implementacion_7') === 'I' ? '■' : '❏'} I`,
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                  }),
                                  new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: ` ${this.getValue(submission, 'plan_organico_animal_manejo_implementacion_7') === 'NI' ? '■' : '❏'} NI`,
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                  })
                            ],
                            verticalAlign: VerticalAlign.TOP
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: ` ${this.getValue(submission, 'plan_organico_animal_manejo_conformidad_7') === 'C' ? '■' : '❏'} C`,
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                  }),
                                  new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: ` ${this.getValue(submission, 'plan_organico_animal_manejo_conformidad_7') === 'NC' ? '■' : '❏'} NC`,
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                  }),
                                  new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: ` ${this.getValue(submission, 'plan_organico_animal_manejo_conformidad_7') === 'NA' ? '■' : '❏'} NA`,
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                  })
                            ],
                            verticalAlign: VerticalAlign.TOP
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: this.getValue(submission, 'plan_organico_animal_manejo_comentarios_inspector_7'),
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                  })
                            ],
                            verticalAlign: VerticalAlign.TOP
                        })
                    ]
                }),
                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: '8',
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                })
                            ],
                            verticalAlign: VerticalAlign.TOP
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: 'Los residuos de cosecha se destinan a:',
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                }),
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: (this.getValue(submission, "plan_organico_animal_manejo_8_reintegran") ? '■' : '❏') + ' Se reintegran a la parcela',
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                }),
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: (this.getValue(submission, "plan_organico_animal_manejo_8_desechan") ? '■' : '❏') + ' Se desechan en instalaciones públicas',
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                }),
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: (this.getValue(submission, "plan_organico_animal_manejo_8_venden") ? '■' : '❏') + ' Se venden',
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                }),
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: (this.getValue(submission, "plan_organico_animal_manejo_8_otro") ? '■' : '❏') + ' Otro (¿Cuál?): ' + this.getValue(submission, "plan_organico_animal_manejo_8_otro_text"),
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                })
                            ],
                            verticalAlign: VerticalAlign.TOP
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: this.getValue(submission, "plan_organico_animal_manejo_8_comentarios"),
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                })
                            ],
                            verticalAlign: VerticalAlign.TOP
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: ` ${this.getValue(submission, 'plan_organico_animal_manejo_implementacion_8') === 'I' ? '■' : '❏'} I`,
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                  }),
                                  new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: ` ${this.getValue(submission, 'plan_organico_animal_manejo_implementacion_8') === 'NI' ? '■' : '❏'} NI`,
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                  })
                            ],
                            verticalAlign: VerticalAlign.TOP
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: ` ${this.getValue(submission, 'plan_organico_animal_manejo_conformidad_8') === 'C' ? '■' : '❏'} C`,
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                  }),
                                  new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: ` ${this.getValue(submission, 'plan_organico_animal_manejo_conformidad_8') === 'NC' ? '■' : '❏'} NC`,
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                  }),
                                  new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: ` ${this.getValue(submission, 'plan_organico_animal_manejo_conformidad_8') === 'NA' ? '■' : '❏'} NA`,
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                  })
                            ],
                            verticalAlign: VerticalAlign.TOP
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: this.getValue(submission, 'plan_organico_animal_manejo_comentarios_inspector_8'),
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                  })
                            ],
                            verticalAlign: VerticalAlign.TOP
                        })
                    ]
                }),
            ],
            margins: {
                left: 70,
                right: 70
            },
            //9885
            columnWidths: [450, 3895, 2045, 1150, 800, 1545]
        })
        return table
    }

    public createTableUDP(data, datePipe): Table {
        const table = new Table({
            rows: [
                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.RIGHT,
                                    children: [
                                        new TextRun({
                                            text: 'Operador: ',
                                            font: 'Calibri (Cuerpo)',
                                            size: 22,
                                            bold: true,

                                        })
                                    ],
                                    spacing: { before: 100, after: 200 }
                                })],
                            verticalAlign: VerticalAlign.CENTER,
                        }),
                        new TableCell({
                            children: [new Paragraph({
                                alignment: AlignmentType.CENTER,
                                children: [
                                    new TextRun({
                                        text: data.customer.Company_Name,
                                        font: 'Calibri (Cuerpo)',
                                        size: 22,
                                    })
                                ],
                            })],
                            verticalAlign: VerticalAlign.CENTER,
                            columnSpan: 3,
                        }),
                    ],
                }),
                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.RIGHT,
                                    children: [
                                        new TextRun({
                                            text: 'Código del operador: ',
                                            font: 'Calibri (Cuerpo)',
                                            size: 22,
                                            bold: true,

                                        })
                                    ],
                                    spacing: { before: 100, after: 200 }
                                })],
                            width: {
                                size: 25,
                                type: WidthType.PERCENTAGE,
                            },
                        }),
                        new TableCell({
                            children: [new Paragraph({
                                alignment: AlignmentType.CENTER,
                                children: [
                                    new TextRun({
                                        text: data.customer.Id_Comercial,
                                        font: 'Calibri (Cuerpo)',
                                        size: 22,
                                    })
                                ],
                            })],
                            verticalAlign: VerticalAlign.CENTER,
                            width: {
                                size: 25,
                                type: WidthType.PERCENTAGE,
                            },
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.RIGHT,
                                    children: [
                                        new TextRun({
                                            text: 'Fecha: ',
                                            font: 'Calibri (Cuerpo)',
                                            size: 22,
                                            bold: true,

                                        })
                                    ],
                                    spacing: { before: 100, after: 200 }
                                })],
                            verticalAlign: VerticalAlign.CENTER,
                            width: {
                                size: 25,
                                type: WidthType.PERCENTAGE,
                            },
                        }),
                        new TableCell({
                            children: [new Paragraph({
                                alignment: AlignmentType.CENTER,
                                children: [
                                    new TextRun({
                                        text: datePipe.transform(data.record.Applications[0].Creation_Date.includes('UTC') ? data.record.Applications[0].Creation_Date.replace('UTC', '').trim() : data.record.Applications[0].Creation_Date, "d-MMMM-y"),
                                        font: 'Calibri (Cuerpo)',
                                        size: 22,
                                    })
                                ],
                            })],
                            verticalAlign: VerticalAlign.CENTER,
                            width: {
                                size: 25,
                                type: WidthType.PERCENTAGE,
                            },
                        }),
                    ],
                }),
            ],
            margins: {
                left: 70,
                right: 70
            },
            width: {
                size: 100,
                type: WidthType.PERCENTAGE,
            },
            //9638
            columnWidths: [2410, 2410, 2409, 2409]
        })

        return table
    }

    public createInstalacionesQuestion1_MTO_FSOL_08_2_1(submission): Table {
        const table = new Table({
            rows: [
                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: 'INSTALACIONES',
                                            font: 'Arial',
                                            size: 20,
                                            bold: true
                                        })
                                    ],
                                    spacing: {
                                        after: 75,
                                        before: 75
                                    }
                                }),
                            ],
                            verticalAlign: VerticalAlign.CENTER,
                            columnSpan: 6,
                            shading: {
                                fill: '#C0C0C0'
                            }
                        })
                    ]
                })
            ],
            margins: {
                left: 70,
                right: 70
            },
            //9885
            columnWidths: [450, 3895, 2045, 1150, 800, 1545]
        })

        const tableRow = new TableRow({
            children: [
                new TableCell({
                    children: [
                        new Paragraph({
                            alignment: AlignmentType.CENTER,
                            children: [
                                new TextRun({
                                    text: '1',
                                    font: 'Arial',
                                    size: 18
                                })
                            ]
                        })
                    ],
                    verticalAlign: VerticalAlign.TOP,
                    columnSpan: 1
                })
            ]
        })

        const tableCell = new TableCell({
            children: [
                new Paragraph({
                    alignment: AlignmentType.CENTER,
                    children: [
                        new TextRun({
                            text: 'Por favor indique las condiciones de sus instalaciones:',
                            font: 'Arial',
                            size: 18
                        })
                    ]
                })
            ],
            verticalAlign: VerticalAlign.TOP,
            columnSpan: 5
        })

        const tableCondiciones = new Table({
            rows: [
                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: 'Especie',
                                            font: 'Arial',
                                            size: 18,
                                        })
                                    ]
                                })
                            ],
                            rowSpan: 2,
                            verticalAlign: VerticalAlign.CENTER,
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: 'Densidad de animales (cabeza/m2)',
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                }),
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: '*Aves (m2/cabeza)',
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                })
                            ],
                            verticalAlign: VerticalAlign.CENTER,
                            columnSpan: 4
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: 'Comentarios',
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                })
                            ],
                            rowSpan: 2,
                            verticalAlign: VerticalAlign.CENTER
                        })
                    ]
                }),
                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: 'Zona cubierta',
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                })
                            ],
                            verticalAlign: VerticalAlign.CENTER,
                            columnSpan: 2
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: 'Zona aire libre*',
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                })
                            ],
                            verticalAlign: VerticalAlign.CENTER,
                            columnSpan: 2
                        })
                    ]
                })
            ],
            columnWidths: [1958, 979, 979, 979, 979, 1958],
            alignment: AlignmentType.CENTER
        })

        const listaInstalaciones = this.getValueList(submission, "lista_condiciones_instalaciones")
        listaInstalaciones.forEach(element => {
            tableCondiciones.addChildElement(
                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: element.plan_organico_animal_instalaciones_1_especie,
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                })
                            ],
                            verticalAlign: VerticalAlign.CENTER
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: element.plan_organico_animal_instalaciones_1_cubierta,
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                })
                            ],
                            verticalAlign: VerticalAlign.CENTER,
                            columnSpan: 2
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: element.plan_organico_animal_instalaciones_1_libre,
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                })
                            ],
                            verticalAlign: VerticalAlign.CENTER,
                            columnSpan: 2
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: element.plan_organico_animal_instalaciones_1_comentarios,
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                })
                            ],
                            verticalAlign: VerticalAlign.CENTER,
                        })
                    ]
                })
            )
        });

        tableCell.addChildElement(tableCondiciones)
        tableRow.addChildElement(tableCell)
        table.addChildElement(tableRow)
        
        return table
    }

    public createInstalaciones_MTO_FSOL_08_2_1(submission): Table {
        const table = new Table({
            rows: [
                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: 'No.',
                                            font: 'Arial',
                                            size: 18,
                                            bold: true
                                        })
                                    ]
                                })
                            ],
                            rowSpan: 2,
                            verticalAlign: VerticalAlign.CENTER,
                            width: {
                                size: 25,
                                type: WidthType.PERCENTAGE
                            }
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: 'Requisitos',
                                            font: 'Arial',
                                            size: 18,
                                            bold: true
                                        })
                                    ]
                                })
                            ],
                            rowSpan: 2,
                            verticalAlign: VerticalAlign.CENTER,
                            width: {
                                size: 15,
                                type: WidthType.PERCENTAGE
                            }
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: 'Comentarios',
                                            font: 'Arial',
                                            size: 18,
                                            bold: true
                                        })
                                    ]
                                })
                            ],
                            rowSpan: 2,
                            verticalAlign: VerticalAlign.CENTER,
                            width: {
                                size: 15,
                                type: WidthType.PERCENTAGE
                            }
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: 'Conformidad (Evaluada por MTO)',
                                            font: 'Arial',
                                            size: 18,
                                            bold: true
                                        })
                                    ]
                                })
                            ],
                            columnSpan: 3,
                            verticalAlign: VerticalAlign.CENTER,
                            width: {
                                size: 15,
                                type: WidthType.PERCENTAGE
                            }
                        })
                    ]
                }),
                new TableRow({
                    children: [new TableCell({
                        children: [
                            new Paragraph({
                                alignment: AlignmentType.CENTER,
                                children: [
                                    new TextRun({
                                        text: 'Implementación',
                                        font: 'Arial',
                                        size: 18,
                                        bold: true
                                    })
                                ]
                            })
                        ],
                        verticalAlign: VerticalAlign.CENTER
                    }),
                    new TableCell({
                        children: [
                            new Paragraph({
                                alignment: AlignmentType.CENTER,
                                children: [
                                    new TextRun({
                                        text: 'Conformidad',
                                        font: 'Arial',
                                        size: 18,
                                        bold: true
                                    })
                                ]
                            })
                        ],
                        verticalAlign: VerticalAlign.CENTER,
                        columnSpan: 2
                    }),]
                }),
                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: '2',
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                })
                            ],
                            verticalAlign: VerticalAlign.TOP
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: 'Los animales permanecen la mayor parte del tiempo:',
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                }),
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: (this.getValue(submission, "plan_organico_animal_instalaciones_2_libres") ? '■' : '❏') + ' Libres en espacios abiertos',
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                }),
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: (this.getValue(submission, "plan_organico_animal_instalaciones_2_encerrados") ? '■' : '❏') + ' Encerrados a causa de: ' + this.getValue(submission, "plan_organico_animal_instalaciones_2_encerrados_texto"),
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                }),
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: (this.getValue(submission, "plan_organico_animal_instalaciones_2_atados") ? '■' : '❏') + ' Atados a causa de: ' + this.getValue(submission, "plan_organico_animal_instalaciones_2_atados_texto"),
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                }),
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: (this.getValue(submission, "plan_organico_animal_instalaciones_2_jaula") ? '■' : '❏') + ' En jaulas a causa de: ' + this.getValue(submission, "plan_organico_animal_instalaciones_2_jaula_texto"),
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                }),
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: (this.getValue(submission, "plan_organico_animal_instalaciones_2_otro") ? '■' : '❏') + ' Otro (¿Cuál?): ' + this.getValue(submission, "plan_organico_animal_instalaciones_2_otro_text"),
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                }),
                            ],
                            verticalAlign: VerticalAlign.TOP
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: this.getValue(submission, "plan_organico_animal_instalaciones_2_comentarios"),
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                })
                            ],
                            verticalAlign: VerticalAlign.TOP
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: ` ${this.getValue(submission, 'plan_organico_animal_instalaciones_implementacion_2') === 'I' ? '■' : '❏'} I`,
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                  }),
                                  new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: ` ${this.getValue(submission, 'plan_organico_animal_instalaciones_implementacion_2') === 'NI' ? '■' : '❏'} NI`,
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                  })
                            ],
                            verticalAlign: VerticalAlign.TOP
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: ` ${this.getValue(submission, 'plan_organico_animal_instalaciones_conformidad_2') === 'C' ? '■' : '❏'} C`,
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                  }),
                                  new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: ` ${this.getValue(submission, 'plan_organico_animal_instalaciones_conformidad_2') === 'NC' ? '■' : '❏'} NC`,
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                  }),
                                  new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: ` ${this.getValue(submission, 'plan_organico_animal_instalaciones_conformidad_2') === 'NA' ? '■' : '❏'} NA`,
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                  })
                            ],
                            verticalAlign: VerticalAlign.TOP
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: this.getValue(submission, 'plan_organico_animal_instalaciones_comentarios_inspector_2'),
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                  })
                            ],
                            verticalAlign: VerticalAlign.TOP
                        })
                    ]
                }),
                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: '3',
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                })
                            ],
                            verticalAlign: VerticalAlign.TOP
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: 'Por favor indique:',
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                }),
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: '¿Cuánto tiempo permanecen los animales encerrados o atados? R: ' + this.getValue(submission, "plan_organico_animal_instalaciones_3_tiempo_encerrados"),
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                }),
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: '¿Cuánto tiempo permanecen al aire libre? R: ' + this.getValue(submission, "plan_organico_animal_instalaciones_3_tiempo_libres"),
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                }),
                            ],
                            verticalAlign: VerticalAlign.TOP
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: this.getValue(submission, "plan_organico_animal_instalaciones_3_comentarios"),
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                })
                            ],
                            verticalAlign: VerticalAlign.TOP
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: ` ${this.getValue(submission, 'plan_organico_animal_instalaciones_implementacion_3') === 'I' ? '■' : '❏'} I`,
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                  }),
                                  new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: ` ${this.getValue(submission, 'plan_organico_animal_instalaciones_implementacion_3') === 'NI' ? '■' : '❏'} NI`,
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                  })
                            ],
                            verticalAlign: VerticalAlign.TOP
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: ` ${this.getValue(submission, 'plan_organico_animal_instalaciones_conformidad_3') === 'C' ? '■' : '❏'} C`,
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                  }),
                                  new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: ` ${this.getValue(submission, 'plan_organico_animal_instalaciones_conformidad_3') === 'NC' ? '■' : '❏'} NC`,
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                  }),
                                  new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: ` ${this.getValue(submission, 'plan_organico_animal_instalaciones_conformidad_3') === 'NA' ? '■' : '❏'} NA`,
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                  })
                            ],
                            verticalAlign: VerticalAlign.TOP
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: this.getValue(submission, 'plan_organico_animal_instalaciones_comentarios_inspector_3'),
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                  })
                            ],
                            verticalAlign: VerticalAlign.TOP
                        })
                    ]
                }),
                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: '4',
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                })
                            ],
                            verticalAlign: VerticalAlign.TOP
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: 'Las instalaciones están adaptadas para que los animales dispongan de:',
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                }),
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: (this.getValue(submission, "plan_organico_animal_instalaciones_4_refugio") ? '■' : '❏') + ' Áreas de refugio',
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                }),
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: (this.getValue(submission, "plan_organico_animal_instalaciones_4_camas") ? '■' : '❏') + ' Camas secas',
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                }),
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: (this.getValue(submission, "plan_organico_animal_instalaciones_4_sombra") ? '■' : '❏') + ' Sombra',
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                }),
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: (this.getValue(submission, "plan_organico_animal_instalaciones_4_recreo") ? '■' : '❏') + ' Áreas para recreo',
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                }),
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: (this.getValue(submission, "plan_organico_animal_instalaciones_4_bebederos") ? '■' : '❏') + ' Bebederos y/o acceso a agua limpia',
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                }),
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: (this.getValue(submission, "plan_organico_animal_instalaciones_4_comederos") ? '■' : '❏') + ' Comederos',
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                }),
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: (this.getValue(submission, "plan_organico_animal_instalaciones_4_otro") ? '■' : '❏') + ' Otro (¿Cuál?):' + this.getValue(submission, "plan_organico_animal_instalaciones_4_otro_text"),
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                })
                            ],
                            verticalAlign: VerticalAlign.TOP
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: this.getValue(submission, "plan_organico_animal_instalaciones_4_comentarios"),
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                })
                            ],
                            verticalAlign: VerticalAlign.TOP
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: ` ${this.getValue(submission, 'plan_organico_animal_instalaciones_implementacion_4') === 'I' ? '■' : '❏'} I`,
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                  }),
                                  new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: ` ${this.getValue(submission, 'plan_organico_animal_instalaciones_implementacion_4') === 'NI' ? '■' : '❏'} NI`,
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                  })
                            ],
                            verticalAlign: VerticalAlign.TOP
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: ` ${this.getValue(submission, 'plan_organico_animal_instalaciones_conformidad_4') === 'C' ? '■' : '❏'} C`,
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                  }),
                                  new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: ` ${this.getValue(submission, 'plan_organico_animal_instalaciones_conformidad_4') === 'NC' ? '■' : '❏'} NC`,
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                  }),
                                  new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: ` ${this.getValue(submission, 'plan_organico_animal_instalaciones_conformidad_4') === 'NA' ? '■' : '❏'} NA`,
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                  })
                            ],
                            verticalAlign: VerticalAlign.TOP
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: this.getValue(submission, 'plan_organico_animal_instalaciones_comentarios_inspector_4'),
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                  })
                            ],
                            verticalAlign: VerticalAlign.TOP
                        })
                    ]
                }),
                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: '5',
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                })
                            ],
                            verticalAlign: VerticalAlign.TOP
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: 'Los animales disponen de espacio suficiente para expresar:',
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                }),
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: (this.getValue(submission, "plan_organico_animal_expresion_5_social") ? '■' : '❏') + ' Conducta social',
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                }),
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: (this.getValue(submission, "pplan_organico_animal_expresion_5_reproductiva") ? '■' : '❏') + ' Conducta reproductiva',
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                }),
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: (this.getValue(submission, "plan_organico_animal_expresion_5_trofico") ? '■' : '❏') + ' Comportamiento trófico',
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                }),
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: (this.getValue(submission, "plan_organico_animal_expresion_5_estres") ? '■' : '❏') + ' Disminución del estrés por confinamiento',
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                }),
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: (this.getValue(submission, "plan_organico_animal_expresion_5_otro") ? '■' : '❏') + ' Otro que represente bienestar animal (¿Cuál?):' + this.getValue(submission, "plan_organico_animal_expresion_5_otro_text"),
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                })
                            ],
                            verticalAlign: VerticalAlign.TOP
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: this.getValue(submission, "plan_organico_animal_instalaciones_5_comentarios"),
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                })
                            ],
                            verticalAlign: VerticalAlign.TOP
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: ` ${this.getValue(submission, 'plan_organico_animal_instalaciones_implementacion_5') === 'I' ? '■' : '❏'} I`,
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                  }),
                                  new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: ` ${this.getValue(submission, 'plan_organico_animal_instalaciones_implementacion_5') === 'NI' ? '■' : '❏'} NI`,
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                  })
                            ],
                            verticalAlign: VerticalAlign.TOP
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: ` ${this.getValue(submission, 'plan_organico_animal_instalaciones_conformidad_5') === 'C' ? '■' : '❏'} C`,
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                  }),
                                  new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: ` ${this.getValue(submission, 'plan_organico_animal_instalaciones_conformidad_5') === 'NC' ? '■' : '❏'} NC`,
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                  }),
                                  new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: ` ${this.getValue(submission, 'plan_organico_animal_instalaciones_conformidad_5') === 'NA' ? '■' : '❏'} NA`,
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                  })
                            ],
                            verticalAlign: VerticalAlign.TOP
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: this.getValue(submission, 'plan_organico_animal_instalaciones_comentarios_inspector_5'),
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                  })
                            ],
                            verticalAlign: VerticalAlign.TOP
                        })
                    ]
                }),
                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: '6',
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                })
                            ],
                            verticalAlign: VerticalAlign.TOP
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: 'Para la limpieza de las instalaciones y equipo utilizo:',
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                }),
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: (this.getValue(submission, "plan_organico_animal_instalaciones_6_agua") ? '■' : '❏') + ' Agua',
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                }),
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: (this.getValue(submission, "plan_organico_animal_instalaciones_6_detergentes") ? '■' : '❏') + ' Detergentes',
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                }),
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: (this.getValue(submission, "plan_organico_animal_instalaciones_6_cloro") ? '■' : '❏') + ' Cloro',
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                }),
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: (this.getValue(submission, "plan_organico_animal_instalaciones_6_peroxido") ? '■' : '❏') + ' Peróxido de hidrógeno',
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                }),
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: (this.getValue(submission, "plan_organico_animal_instalaciones_6_acido") ? '■' : '❏') + ' Ácido fosfórico',
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                }),
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: (this.getValue(submission, "plan_organico_animal_instalaciones_6_jabon") ? '■' : '❏') + ' Jabón de potasa y sosa',
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                }),
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: (this.getValue(submission, "plan_organico_animal_instalaciones_6_otro") ? '■' : '❏') + ' Otro (¿Cuál?): ' + this.getValue(submission, "plan_organico_animal_instalaciones_6_otro_text"),
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                })
                            ],
                            verticalAlign: VerticalAlign.TOP
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: this.getValue(submission, "plan_organico_animal_instalaciones_6_comentarios"),
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                })
                            ],
                            verticalAlign: VerticalAlign.TOP
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: ` ${this.getValue(submission, 'plan_organico_animal_instalaciones_implementacion_6') === 'I' ? '■' : '❏'} I`,
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                  }),
                                  new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: ` ${this.getValue(submission, 'plan_organico_animal_instalaciones_implementacion_6') === 'NI' ? '■' : '❏'} NI`,
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                  })
                            ],
                            verticalAlign: VerticalAlign.TOP
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: ` ${this.getValue(submission, 'plan_organico_animal_instalaciones_conformidad_6') === 'C' ? '■' : '❏'} C`,
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                  }),
                                  new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: ` ${this.getValue(submission, 'plan_organico_animal_instalaciones_conformidad_6') === 'NC' ? '■' : '❏'} NC`,
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                  }),
                                  new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: ` ${this.getValue(submission, 'plan_organico_animal_instalaciones_conformidad_6') === 'NA' ? '■' : '❏'} NA`,
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                  })
                            ],
                            verticalAlign: VerticalAlign.TOP
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: this.getValue(submission, 'plan_organico_animal_instalaciones_comentarios_inspector_6'),
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                  })
                            ],
                            verticalAlign: VerticalAlign.TOP
                        })
                    ]
                }),
                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: '7',
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                })
                            ],
                            verticalAlign: VerticalAlign.TOP
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: 'Para el control de plagas en las instalaciones utilizo:',
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                }),
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: (this.getValue(submission, "plan_organico_animal_instalaciones_7_enemigos") ? '■' : '❏') + ' Enemigos naturales',
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                }),
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: (this.getValue(submission, "plan_organico_animal_instalaciones_7_trampas") ? '■' : '❏') + ' Trampas',
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                }),
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: (this.getValue(submission, "plan_organico_animal_instalaciones_7_sustancias_permitidas") ? '■' : '❏') + ' Sustancias permitidas por las normas',
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                }),
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: (this.getValue(submission, "plan_organico_animal_instalaciones_7_agroquimicos") ? '■' : '❏') + ' Agroquímicos',
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                }),
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: (this.getValue(submission, "plan_organico_animal_instalaciones_7_otro") ? '■' : '❏') + ' Otro (¿Cuál?): ' + this.getValue(submission, "plan_organico_animal_instalaciones_7_otro_text"),
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                })
                            ],
                            verticalAlign: VerticalAlign.TOP
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: this.getValue(submission, "plan_organico_animal_instalaciones_7_comentarios"),
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                })
                            ],
                            verticalAlign: VerticalAlign.TOP
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: ` ${this.getValue(submission, 'plan_organico_animal_instalaciones_implementacion_7') === 'I' ? '■' : '❏'} I`,
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                  }),
                                  new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: ` ${this.getValue(submission, 'plan_organico_animal_instalaciones_implementacion_7') === 'NI' ? '■' : '❏'} NI`,
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                  })
                            ],
                            verticalAlign: VerticalAlign.TOP
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: ` ${this.getValue(submission, 'plan_organico_animal_instalaciones_conformidad_7') === 'C' ? '■' : '❏'} C`,
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                  }),
                                  new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: ` ${this.getValue(submission, 'plan_organico_animal_instalaciones_conformidad_7') === 'NC' ? '■' : '❏'} NC`,
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                  }),
                                  new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: ` ${this.getValue(submission, 'plan_organico_animal_instalaciones_conformidad_7') === 'NA' ? '■' : '❏'} NA`,
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                  })
                            ],
                            verticalAlign: VerticalAlign.TOP
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: this.getValue(submission, 'plan_organico_animal_instalaciones_comentarios_inspector_7'),
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                  })
                            ],
                            verticalAlign: VerticalAlign.TOP
                        })
                    ]
                }),
                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: '8',
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                })
                            ],
                            verticalAlign: VerticalAlign.TOP
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: '¿Se dispone de áreas para acumular y manejar excretas?',
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                }),
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: (this.getValue(submission, "plan_organico_animal_instalaciones_8_declaraciones") ? '■' : '❏') + ' Sí',
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                }),
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: (this.getValue(submission, "plan_organico_animal_instalaciones_8_declaraciones") ? '❏' : '■') + ' No',
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                }),
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: (this.getValue(submission, "plan_organico_animal_instalaciones_8_declaraciones") ? '❏' : '■') + ' N/A',
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                })
                            ],
                            verticalAlign: VerticalAlign.TOP
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: this.getValue(submission, "plan_organico_animal_instalaciones_8_comentarios"),
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                })
                            ],
                            verticalAlign: VerticalAlign.TOP
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: ` ${this.getValue(submission, 'plan_organico_animal_instalaciones_implementacion_8') === 'I' ? '■' : '❏'} I`,
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                  }),
                                  new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: ` ${this.getValue(submission, 'plan_organico_animal_instalaciones_implementacion_8') === 'NI' ? '■' : '❏'} NI`,
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                  })
                            ],
                            verticalAlign: VerticalAlign.TOP
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: ` ${this.getValue(submission, 'plan_organico_animal_instalaciones_conformidad_8') === 'C' ? '■' : '❏'} C`,
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                  }),
                                  new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: ` ${this.getValue(submission, 'plan_organico_animal_instalaciones_conformidad_8') === 'NC' ? '■' : '❏'} NC`,
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                  }),
                                  new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: ` ${this.getValue(submission, 'plan_organico_animal_instalaciones_conformidad_8') === 'NA' ? '■' : '❏'} NA`,
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                  })
                            ],
                            verticalAlign: VerticalAlign.TOP
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: this.getValue(submission, 'plan_organico_animal_instalaciones_comentarios_inspector_8'),
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                  })
                            ],
                            verticalAlign: VerticalAlign.TOP
                        })
                    ]
                }),
                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: '9',
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                })
                            ],
                            verticalAlign: VerticalAlign.TOP
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: 'Los residuos y/o subproductos de la unidad de producción ganadera (estiércol, purín, camas de paja o heno, etc) se destinan a:',
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                }),
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: (this.getValue(submission, "plan_organico_animal_instalaciones_9_reintegran") ? '■' : '❏') + ' Se reintegran a las parcelas o potreros',
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                }),
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: (this.getValue(submission, "plan_organico_animal_instalaciones_9_desechan") ? '■' : '❏') + ' Se desechan en instalaciones públicas',
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                }),
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: (this.getValue(submission, "plan_organico_animal_instalaciones_9_venden") ? '■' : '❏') + ' Se venden',
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                }),
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: (this.getValue(submission, "plan_organico_animal_instalaciones_9_otro") ? '■' : '❏') + ' Otro (¿Cuál?): ' + this.getValue(submission, "plan_organico_animal_instalaciones_9_otro_text"),
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                })
                            ],
                            verticalAlign: VerticalAlign.TOP
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: this.getValue(submission, "plan_organico_animal_instalaciones_9_comentarios"),
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                })
                            ],
                            verticalAlign: VerticalAlign.TOP
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: ` ${this.getValue(submission, 'plan_organico_animal_instalaciones_implementacion_9') === 'I' ? '■' : '❏'} I`,
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                  }),
                                  new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: ` ${this.getValue(submission, 'plan_organico_animal_instalaciones_implementacion_9') === 'NI' ? '■' : '❏'} NI`,
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                  })
                            ],
                            verticalAlign: VerticalAlign.TOP
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: ` ${this.getValue(submission, 'plan_organico_animal_instalaciones_conformidad_9') === 'C' ? '■' : '❏'} C`,
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                  }),
                                  new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: ` ${this.getValue(submission, 'plan_organico_animal_instalaciones_conformidad_9') === 'NC' ? '■' : '❏'} NC`,
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                  }),
                                  new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: ` ${this.getValue(submission, 'plan_organico_animal_instalaciones_conformidad_9') === 'NA' ? '■' : '❏'} NA`,
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                  })
                            ],
                            verticalAlign: VerticalAlign.TOP
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: this.getValue(submission, 'plan_organico_animal_instalaciones_comentarios_inspector_9'),
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                  })
                            ],
                            verticalAlign: VerticalAlign.TOP
                        })
                    ]
                }),
                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: '10',
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                })
                            ],
                            verticalAlign: VerticalAlign.TOP
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: 'Realizo las siguientes prácticas para conservar el agua:',
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                }),
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: (this.getValue(submission, "plan_organico_animal_instalaciones_10_cosecha") ? '■' : '❏') + ' Cosecha de agua de lluvia de los techos',
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                }),
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: (this.getValue(submission, "plan_organico_animal_instalaciones_10_excavacion") ? '■' : '❏') + ' Excavación para retener agua (ollas)',
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                }),
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: (this.getValue(submission, "plan_organico_animal_instalaciones_10_zanja") ? '■' : '❏') + ' Zanja de infiltración con barreras vivas',
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                }),
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: (this.getValue(submission, "plan_organico_animal_instalaciones_10_cauces") ? '■' : '❏') + ' Mantenemos la vegetación en cauces naturales',
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                }),
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: (this.getValue(submission, "plan_organico_animal_instalaciones_10_protegemos") ? '■' : '❏') + ' Protegemos fuentes de agua localizadas en los predios agrícolas',
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                }),
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: (this.getValue(submission, "plan_organico_animal_agua_10_otro") ? '■' : '❏') + ' Otro (¿Cuál?): ' + this.getValue(submission, "plan_organico_animal_agua_10_otro_text"),
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                })
                            ],
                            verticalAlign: VerticalAlign.TOP
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: this.getValue(submission, "plan_organico_animal_instalaciones_10_comentarios"),
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                })
                            ],
                            verticalAlign: VerticalAlign.TOP
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: ` ${this.getValue(submission, 'plan_organico_animal_instalaciones_implementacion_10') === 'I' ? '■' : '❏'} I`,
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                  }),
                                  new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: ` ${this.getValue(submission, 'plan_organico_animal_instalaciones_implementacion_10') === 'NI' ? '■' : '❏'} NI`,
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                  })
                            ],
                            verticalAlign: VerticalAlign.TOP
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: ` ${this.getValue(submission, 'plan_organico_animal_instalaciones_conformidad_10') === 'C' ? '■' : '❏'} C`,
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                  }),
                                  new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: ` ${this.getValue(submission, 'plan_organico_animal_instalaciones_conformidad_10') === 'NC' ? '■' : '❏'} NC`,
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                  }),
                                  new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: ` ${this.getValue(submission, 'plan_organico_animal_instalaciones_conformidad_10') === 'NA' ? '■' : '❏'} NA`,
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                  })
                            ],
                            verticalAlign: VerticalAlign.TOP
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: this.getValue(submission, 'plan_organico_animal_instalaciones_comentarios_inspector_10'),
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                  })
                            ],
                            verticalAlign: VerticalAlign.TOP
                        })
                    ]
                }),
            ],
            margins: {
                left: 70,
                right: 70
            },
            //9885
            columnWidths: [450, 3895, 2045, 1150, 800, 1545]
        })
        return table
    }

    public createAnimalHandling_MTO_FSOL_08_2_1(submission): Table {
        const table = new Table({
            rows: [
                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: 'MANEJO DE LOS ANIMALES',
                                            font: 'Arial',
                                            size: 20,
                                            bold: true
                                        })
                                    ],
                                    spacing: {
                                        after: 75,
                                        before: 75
                                    }
                                }),
                            ],
                            verticalAlign: VerticalAlign.CENTER,
                            columnSpan: 6,
                            shading: {
                                fill: '#C0C0C0'
                            }
                        })
                    ]
                }),
                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: 'No.',
                                            font: 'Arial',
                                            size: 18,
                                            bold: true
                                        })
                                    ]
                                })
                            ],
                            rowSpan: 2,
                            verticalAlign: VerticalAlign.CENTER,
                            width: {
                                size: 25,
                                type: WidthType.PERCENTAGE
                            }
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: 'Requisitos',
                                            font: 'Arial',
                                            size: 18,
                                            bold: true
                                        })
                                    ]
                                })
                            ],
                            rowSpan: 2,
                            verticalAlign: VerticalAlign.CENTER,
                            width: {
                                size: 15,
                                type: WidthType.PERCENTAGE
                            }
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: 'Comentarios',
                                            font: 'Arial',
                                            size: 18,
                                            bold: true
                                        })
                                    ]
                                })
                            ],
                            rowSpan: 2,
                            verticalAlign: VerticalAlign.CENTER,
                            width: {
                                size: 15,
                                type: WidthType.PERCENTAGE
                            }
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: 'Conformidad',
                                            font: 'Arial',
                                            size: 18,
                                            bold: true
                                        })
                                    ]
                                })
                            ],
                            columnSpan: 3,
                            verticalAlign: VerticalAlign.CENTER,
                            width: {
                                size: 15,
                                type: WidthType.PERCENTAGE
                            }
                        })
                    ]
                }),
                new TableRow({
                    children: [new TableCell({
                        children: [
                            new Paragraph({
                                alignment: AlignmentType.CENTER,
                                children: [
                                    new TextRun({
                                        text: 'Implementación',
                                        font: 'Arial',
                                        size: 18,
                                        bold: true
                                    })
                                ]
                            })
                        ],
                        verticalAlign: VerticalAlign.CENTER
                    }),
                    new TableCell({
                        children: [
                            new Paragraph({
                                alignment: AlignmentType.CENTER,
                                children: [
                                    new TextRun({
                                        text: 'Conformidad',
                                        font: 'Arial',
                                        size: 18,
                                        bold: true
                                    })
                                ]
                            })
                        ],
                        verticalAlign: VerticalAlign.CENTER,
                        columnSpan: 2
                    }),]
                }),
                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: '1',
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                })
                            ],
                            verticalAlign: VerticalAlign.TOP
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: 'El origen del ganado para una operación inicial es:',
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                }),
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: (this.getValue(submission, "plan_organico_animal_manejo_animales_1_organico") ? '■' : '❏') + '  Orgánico propio',
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                }),
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: (this.getValue(submission, "plan_organico_animal_manejo_animales_1_organico_externo") ? '■' : '❏') + '  Orgánico externo y certificado',
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                }),
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: (this.getValue(submission, "plan_organico_animal_manejo_animales_1_convencional") ? '■' : '❏') + '  Convencional con edad o peso menor al permitido',
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                }),
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: (this.getValue(submission, "plan_organico_animal_manejo_animales_1_convencional_mayor") ? '■' : '❏') + '  Convencional y mayor a la edad o peso permitido',
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                }),
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: (this.getValue(submission, "plan_organico_animal_manejo_animales_1_otro") ? '■' : '❏') + ' Otro (¿Cuál?): ' + this.getValue(submission, "plan_organico_animal_manejo_animales_1_otro_text"),
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                }),
                            ],
                            verticalAlign: VerticalAlign.TOP
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: this.getValue(submission, "plan_organico_animal_manejo_animales_1_comentarios"),
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                })
                            ],
                            verticalAlign: VerticalAlign.TOP
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: ` ${this.getValue(submission, 'plan_organico_animal_manejo_animales_implementacion_1') === 'I' ? '■' : '❏'} I`,
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                  }),
                                  new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: ` ${this.getValue(submission, 'plan_organico_animal_manejo_animales_implementacion_1') === 'NI' ? '■' : '❏'} NI`,
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                  })
                            ],
                            verticalAlign: VerticalAlign.TOP
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: ` ${this.getValue(submission, 'plan_organico_animal_manejo_animales_conformidad_1') === 'C' ? '■' : '❏'} C`,
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                  }),
                                  new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: ` ${this.getValue(submission, 'plan_organico_animal_manejo_animales_conformidad_1') === 'NC' ? '■' : '❏'} NC`,
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                  }),
                                  new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: ` ${this.getValue(submission, 'plan_organico_animal_manejo_animales_conformidad_1') === 'NA' ? '■' : '❏'} NA`,
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                  })
                            ],
                            verticalAlign: VerticalAlign.TOP
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: this.getValue(submission, 'plan_organico_animal_manejo_animales_comentarios_inspector_1'),
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                  })
                            ],
                            verticalAlign: VerticalAlign.TOP
                        })
                    ]
                }),
                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: '2',
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                })
                            ],
                            verticalAlign: VerticalAlign.TOP
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: 'El origen del ganado para la renovación y reconstitución es:',
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                }),
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: (this.getValue(submission, "plan_organico_animal_manejo_animales_2_organico") ? '■' : '❏') + '  Orgánico propio',
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                }),
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: (this.getValue(submission, "plan_organico_animal_manejo_animales_2_organico_externo") ? '■' : '❏') + '  Orgánico externo y certificado',
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                }),
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: (this.getValue(submission, "plan_organico_animal_manejo_animales_2_convencional") ? '■' : '❏') + '  Convencional con edad o peso menor al permitido',
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                }),
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: (this.getValue(submission, "plan_organico_animal_manejo_animales_2_convencional_mayor") ? '■' : '❏') + '  Convencional y mayor a la edad o peso permitido',
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                }),
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: (this.getValue(submission, "plan_organico_animal_manejo_renovacion_animales_2_otro") ? '■' : '❏') + ' Otro (¿Cuál?): ' + this.getValue(submission, "plan_organico_animal_manejo_renovacion_animales_2_otro_text"),
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                }),
                            ],
                            verticalAlign: VerticalAlign.TOP
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: this.getValue(submission, "plan_organico_animal_manejo_animales_2_comentarios"),
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                })
                            ],
                            verticalAlign: VerticalAlign.TOP
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: ` ${this.getValue(submission, 'plan_organico_animal_manejo_animales_implementacion_2') === 'I' ? '■' : '❏'} I`,
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                  }),
                                  new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: ` ${this.getValue(submission, 'plan_organico_animal_manejo_animales_implementacion_2') === 'NI' ? '■' : '❏'} NI`,
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                  })
                            ],
                            verticalAlign: VerticalAlign.TOP
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: ` ${this.getValue(submission, 'plan_organico_animal_manejo_animales_conformidad_2') === 'C' ? '■' : '❏'} C`,
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                  }),
                                  new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: ` ${this.getValue(submission, 'plan_organico_animal_manejo_animales_conformidad_2') === 'NC' ? '■' : '❏'} NC`,
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                  }),
                                  new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: ` ${this.getValue(submission, 'plan_organico_animal_manejo_animales_conformidad_2') === 'NA' ? '■' : '❏'} NA`,
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                  })
                            ],
                            verticalAlign: VerticalAlign.TOP
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: this.getValue(submission, 'plan_organico_animal_manejo_animales_comentarios_inspector_2'),
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                  })
                            ],
                            verticalAlign: VerticalAlign.TOP
                        })
                    ]
                })
            ],
            margins: {
                left: 70,
                right: 70
            },
            //9885
            columnWidths: [450, 3895, 2045, 1150, 800, 1545]
        })

        const tableRow = new TableRow({
            children: [
                new TableCell({
                    children: [
                        new Paragraph({
                            alignment: AlignmentType.CENTER,
                            children: [
                                new TextRun({
                                    text: '3',
                                    font: 'Arial',
                                    size: 18
                                })
                            ]
                        })
                    ],
                    verticalAlign: VerticalAlign.TOP,
                    columnSpan: 1,
                })
            ]
            
        })

        const tableCell = new TableCell({
            children: [
                new Paragraph({
                    alignment: AlignmentType.LEFT,
                    children: [
                        new TextRun({
                            text: 'Por favor señale los componentes de una ración diaria',
                            font: 'Arial',
                            size: 18
                        })
                    ]
                })
            ],
            columnSpan: 5
        })

        const tableRacion = new Table({
            rows: [
                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: 'Ingrediente',
                                            font: 'Arial',
                                            size: 18,
                                        })
                                    ]
                                })
                            ],
                            verticalAlign: VerticalAlign.CENTER,
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: 'Cantidad (Kg)',
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                })
                            ],
                            verticalAlign: VerticalAlign.CENTER
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: 'Proporción (% Materia Seca)',
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                })
                            ],
                            verticalAlign: VerticalAlign.CENTER
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: 'Proveedor',
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                })
                            ],
                            verticalAlign: VerticalAlign.CENTER
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: 'Calidad',
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                })
                            ],
                            verticalAlign: VerticalAlign.CENTER
                        })
                    ]
                })
            ],
            columnWidths: [2173, 1057, 1057, 2073, 2073],
            alignment: AlignmentType.CENTER
        })

        const listaRacion = this.getValueList(submission, "lista_componentes_racion")
        listaRacion.forEach(element => {
            tableRacion.addChildElement(
                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: element.plan_organico_animal_manejo_animales_3_ingrediente,
                                            font: 'Arial',
                                            size: 18,
                                        })
                                    ]
                                })
                            ],
                            verticalAlign: VerticalAlign.CENTER,
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: element.plan_organico_animal_manejo_animales_3_cantidad,
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                })
                            ],
                            verticalAlign: VerticalAlign.CENTER
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: element.plan_organico_animal_manejo_animales_3_proporcion,
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                })
                            ],
                            verticalAlign: VerticalAlign.CENTER
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: element.plan_organico_animal_manejo_animales_3_proveedor,
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                })
                            ],
                            verticalAlign: VerticalAlign.CENTER
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: element.plan_organico_animal_manejo_animales_3_calidad === "Orgánica" ? " ■ Orgánica" : " ❏ Organica",
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                }),
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: element.plan_organico_animal_manejo_animales_3_calidad === "Convencional" ? " ■ Convencional" : " ❏ Convencional",
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                }),
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: element.plan_organico_animal_manejo_animales_3_calidad === "Transición" ? " ■ Transición" : " ❏ Transición",
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                })
                            ],
                            verticalAlign: VerticalAlign.CENTER
                        })
                    ]
                })
            )
        });

        tableCell.addChildElement(tableRacion)

        tableCell.addChildElement(
            new Paragraph({
                alignment: AlignmentType.LEFT,
                children: [
                    new TextRun({
                        text: "Comentarios: " + this.getValue(submission, "plan_organico_animal_manejo_animales_3_comentarios"),
                        font: 'Arial',
                        size: 18
                    })
                ],
                spacing: { before: 100, after: 100 }
            })
        )
        tableRow.addChildElement(tableCell)
        table.addChildElement(tableRow)

        table.addChildElement(
            new TableRow({
                children: [
                    new TableCell({
                        children: [
                            new Paragraph({
                                alignment: AlignmentType.CENTER,
                                children: [
                                    new TextRun({
                                        text: '4',
                                        font: 'Arial',
                                        size: 18
                                    })
                                ]
                            })
                        ],
                        verticalAlign: VerticalAlign.TOP
                    }),
                    new TableCell({
                        children: [
                            new Paragraph({
                                alignment: AlignmentType.LEFT,
                                children: [
                                    new TextRun({
                                        text: 'En el caso de producción de aves se utiliza metionina como aditivo en el alimento ¿En qué proporción se utiliza?:',
                                        font: 'Arial',
                                        size: 18
                                    })
                                ]
                            }),
                            new Paragraph({
                                alignment: AlignmentType.LEFT,
                                children: [
                                    new TextRun({
                                        text: (this.getValue(submission, "plan_organico_animal_produccion_aves_4_gallinas") ? '■' : '❏') + '  Máximo de 2 libras por tonelada en el caso de gallinas',
                                        font: 'Arial',
                                        size: 18
                                    })
                                ]
                            }),
                            new Paragraph({
                                alignment: AlignmentType.LEFT,
                                children: [
                                    new TextRun({
                                        text: (this.getValue(submission, "plan_organico_animal_produccion_aves_4_otras_aves") ? '■' : '❏') + ' Máximo de 3 libras por tonelada en el caso de pavos u otras aves',
                                        font: 'Arial',
                                        size: 18
                                    })
                                ]
                            }),
                            new Paragraph({
                                alignment: AlignmentType.LEFT,
                                children: [
                                    new TextRun({
                                        text: (this.getValue(submission, "plan_organico_animal_manejo_renovacion_animales_4_otro") ? '■' : '❏') + ' Otro (¿Cuál?): ' + this.getValue(submission, "plan_organico_animal_manejo_renovacion_animales_4_otro_texto"),
                                        font: 'Arial',
                                        size: 18
                                    })
                                ]
                            }),
                        ],
                        verticalAlign: VerticalAlign.TOP
                    }),
                    new TableCell({
                        children: [
                            new Paragraph({
                                alignment: AlignmentType.LEFT,
                                children: [
                                    new TextRun({
                                        text: this.getValue(submission, "plan_organico_animal_manejo_animales_4_comentarios"),
                                        font: 'Arial',
                                        size: 18
                                    })
                                ]
                            })
                        ],
                        verticalAlign: VerticalAlign.TOP
                    }),
                    new TableCell({
                        children: [
                            new Paragraph({
                                alignment: AlignmentType.LEFT,
                                children: [
                                    new TextRun({
                                        text: ` ${this.getValue(submission, 'plan_organico_animal_manejo_animales_implementacion_4') === 'I' ? '■' : '❏'} I`,
                                        font: 'Arial',
                                        size: 18
                                    })
                                ]
                              }),
                              new Paragraph({
                                alignment: AlignmentType.LEFT,
                                children: [
                                    new TextRun({
                                        text: ` ${this.getValue(submission, 'plan_organico_animal_manejo_animales_implementacion_4') === 'NI' ? '■' : '❏'} NI`,
                                        font: 'Arial',
                                        size: 18
                                    })
                                ]
                              })
                        ],
                        verticalAlign: VerticalAlign.TOP
                    }),
                    new TableCell({
                        children: [
                            new Paragraph({
                                alignment: AlignmentType.LEFT,
                                children: [
                                    new TextRun({
                                        text: ` ${this.getValue(submission, 'plan_organico_animal_manejo_animales_conformidad_4') === 'C' ? '■' : '❏'} C`,
                                        font: 'Arial',
                                        size: 18
                                    })
                                ]
                              }),
                              new Paragraph({
                                alignment: AlignmentType.LEFT,
                                children: [
                                    new TextRun({
                                        text: ` ${this.getValue(submission, 'plan_organico_animal_manejo_animales_conformidad_4') === 'NC' ? '■' : '❏'} NC`,
                                        font: 'Arial',
                                        size: 18
                                    })
                                ]
                              }),
                              new Paragraph({
                                alignment: AlignmentType.LEFT,
                                children: [
                                    new TextRun({
                                        text: ` ${this.getValue(submission, 'plan_organico_animal_manejo_animales_conformidad_4') === 'NA' ? '■' : '❏'} NA`,
                                        font: 'Arial',
                                        size: 18
                                    })
                                ]
                              })
                        ],
                        verticalAlign: VerticalAlign.TOP
                    }),
                    new TableCell({
                        children: [
                            new Paragraph({
                                alignment: AlignmentType.LEFT,
                                children: [
                                    new TextRun({
                                        text: this.getValue(submission, 'plan_organico_animal_manejo_animales_comentarios_inspector_4'),
                                        font: 'Arial',
                                        size: 18
                                    })
                                ]
                              })
                        ],
                        verticalAlign: VerticalAlign.TOP
                    })
                ]
            })
        )
        table.addChildElement(
            new TableRow({
                children: [
                    new TableCell({
                        children: [
                            new Paragraph({
                                alignment: AlignmentType.CENTER,
                                children: [
                                    new TextRun({
                                        text: '5',
                                        font: 'Arial',
                                        size: 18
                                    })
                                ]
                            })
                        ],
                        verticalAlign: VerticalAlign.TOP
                    }),
                    new TableCell({
                        children: [
                            new Paragraph({
                                alignment: AlignmentType.LEFT,
                                children: [
                                    new TextRun({
                                        text: 'Realizo las siguientes actividades para el manejo ecológico de enfermedades:',
                                        font: 'Arial',
                                        size: 18
                                    })
                                ]
                            }),
                            new Paragraph({
                                alignment: AlignmentType.LEFT,
                                children: [
                                    new TextRun({
                                        text: (this.getValue(submission, "plan_organico_animal_manejo_animales_5_razas") ? '■' : '❏') + '  Razas resistentes',
                                        font: 'Arial',
                                        size: 18
                                    })
                                ]
                            }),
                            new Paragraph({
                                alignment: AlignmentType.LEFT,
                                children: [
                                    new TextRun({
                                        text: (this.getValue(submission, "plan_organico_animal_manejo_animales_5_zootecnia") ? '■' : '❏') + ' Prácticas zootécnicas acordes con la raza',
                                        font: 'Arial',
                                        size: 18
                                    })
                                ]
                            }),
                            new Paragraph({
                                alignment: AlignmentType.LEFT,
                                children: [
                                    new TextRun({
                                        text: (this.getValue(submission, "plan_organico_animal_manejo_animales_5_alimentos") ? '■' : '❏') + ' Alimentos de alta calidad',
                                        font: 'Arial',
                                        size: 18
                                    })
                                ]
                            }),
                            new Paragraph({
                                alignment: AlignmentType.LEFT,
                                children: [
                                    new TextRun({
                                        text: (this.getValue(submission, "plan_organico_animal_manejo_animales_5_ejercicio") ? '■' : '❏') + ' Ejercicio y acceso a pastos',
                                        font: 'Arial',
                                        size: 18
                                    })
                                ]
                            }),
                            new Paragraph({
                                alignment: AlignmentType.LEFT,
                                children: [
                                    new TextRun({
                                        text: (this.getValue(submission, "plan_organico_animal_manejo_animales_5_carga") ? '■' : '❏') + ' Adecuada carga animal',
                                        font: 'Arial',
                                        size: 18
                                    })
                                ]
                            }),
                            new Paragraph({
                                alignment: AlignmentType.LEFT,
                                children: [
                                    new TextRun({
                                        text: (this.getValue(submission, "plan_organico_animal_manejo_animales_5_vacunas") ? '■' : '❏') + ' Vacunación',
                                        font: 'Arial',
                                        size: 18
                                    })
                                ]
                            }),
                            new Paragraph({
                                alignment: AlignmentType.LEFT,
                                children: [
                                    new TextRun({
                                        text: (this.getValue(submission, "plan_organico_animal_manejo_animales_3_extractos_vegetales") ? '■' : '❏') + ' Extractos vegetales',
                                        font: 'Arial',
                                        size: 18
                                    })
                                ]
                            }),
                            new Paragraph({
                                alignment: AlignmentType.LEFT,
                                children: [
                                    new TextRun({
                                        text: (this.getValue(submission, "plan_organico_animal_manejo_animales_5_sustancias_homeopaticas") ? '■' : '❏') + ' Sustancias homeopáticas',
                                        font: 'Arial',
                                        size: 18
                                    })
                                ]
                            }),
                            new Paragraph({
                                alignment: AlignmentType.LEFT,
                                children: [
                                    new TextRun({
                                        text: (this.getValue(submission, "plan_organico_animal_manejo_animales_5_sustancias_permitidas") ? '■' : '❏') + ' Sustancias permitidas por las normas',
                                        font: 'Arial',
                                        size: 18
                                    })
                                ]
                            }),
                            new Paragraph({
                                alignment: AlignmentType.LEFT,
                                children: [
                                    new TextRun({
                                        text: (this.getValue(submission, "plan_organico_animal_manejo_animales_5_medicamentos") ? '■' : '❏') + ' Medicamentos veterinarios alopáticos de síntesis química',
                                        font: 'Arial',
                                        size: 18
                                    })
                                ]
                            }),
                            new Paragraph({
                                alignment: AlignmentType.LEFT,
                                children: [
                                    new TextRun({
                                        text: (this.getValue(submission, "plan_organico_animal_manejo_animales_5_aislamiento") ? '■' : '❏') + ' Aislamiento de animales enfermos',
                                        font: 'Arial',
                                        size: 18
                                    })
                                ]
                            }),
                            new Paragraph({
                                alignment: AlignmentType.LEFT,
                                children: [
                                    new TextRun({
                                        text: (this.getValue(submission, "plan_organico_animal_manejo_animales_5_otro") ? '■' : '❏') + ' Otro (¿Cuál?): ' + this.getValue(submission, "plan_organico_animal_manejo_animales_5_otro_texto"),
                                        font: 'Arial',
                                        size: 18
                                    })
                                ]
                            }),
                        ],
                        verticalAlign: VerticalAlign.TOP
                    }),
                    new TableCell({
                        children: [
                            new Paragraph({
                                alignment: AlignmentType.LEFT,
                                children: [
                                    new TextRun({
                                        text: this.getValue(submission, "plan_organico_animal_manejo_animales_5_comentarios"),
                                        font: 'Arial',
                                        size: 18
                                    })
                                ]
                            })
                        ],
                        verticalAlign: VerticalAlign.TOP
                    }),
                    new TableCell({
                        children: [
                            new Paragraph({
                                alignment: AlignmentType.LEFT,
                                children: [
                                    new TextRun({
                                        text: ` ${this.getValue(submission, 'plan_organico_animal_manejo_animales_implementacion_5') === 'I' ? '■' : '❏'} I`,
                                        font: 'Arial',
                                        size: 18
                                    })
                                ]
                              }),
                              new Paragraph({
                                alignment: AlignmentType.LEFT,
                                children: [
                                    new TextRun({
                                        text: ` ${this.getValue(submission, 'plan_organico_animal_manejo_animales_implementacion_5') === 'NI' ? '■' : '❏'} NI`,
                                        font: 'Arial',
                                        size: 18
                                    })
                                ]
                              })
                        ],
                        verticalAlign: VerticalAlign.TOP
                    }),
                    new TableCell({
                        children: [
                            new Paragraph({
                                alignment: AlignmentType.LEFT,
                                children: [
                                    new TextRun({
                                        text: ` ${this.getValue(submission, 'plan_organico_animal_manejo_animales_conformidad_5') === 'C' ? '■' : '❏'} C`,
                                        font: 'Arial',
                                        size: 18
                                    })
                                ]
                              }),
                              new Paragraph({
                                alignment: AlignmentType.LEFT,
                                children: [
                                    new TextRun({
                                        text: ` ${this.getValue(submission, 'plan_organico_animal_manejo_animales_conformidad_5') === 'NC' ? '■' : '❏'} NC`,
                                        font: 'Arial',
                                        size: 18
                                    })
                                ]
                              }),
                              new Paragraph({
                                alignment: AlignmentType.LEFT,
                                children: [
                                    new TextRun({
                                        text: ` ${this.getValue(submission, 'plan_organico_animal_manejo_animales_conformidad_5') === 'NA' ? '■' : '❏'} NA`,
                                        font: 'Arial',
                                        size: 18
                                    })
                                ]
                              })
                        ],
                        verticalAlign: VerticalAlign.TOP
                    }),
                    new TableCell({
                        children: [
                            new Paragraph({
                                alignment: AlignmentType.LEFT,
                                children: [
                                    new TextRun({
                                        text: this.getValue(submission, 'plan_organico_animal_manejo_animales_comentarios_inspector_5'),
                                        font: 'Arial',
                                        size: 18
                                    })
                                ]
                              })
                        ],
                        verticalAlign: VerticalAlign.TOP
                    })
                ]
            })
        )
        table.addChildElement(
            new TableRow({
                children: [
                    new TableCell({
                        children: [
                            new Paragraph({
                                alignment: AlignmentType.CENTER,
                                children: [
                                    new TextRun({
                                        text: '6',
                                        font: 'Arial',
                                        size: 18
                                    })
                                ]
                            })
                        ],
                        verticalAlign: VerticalAlign.TOP
                    }),
                    new TableCell({
                        children: [
                            new Paragraph({
                                alignment: AlignmentType.LEFT,
                                children: [
                                    new TextRun({
                                        text: 'La reproducción de los animales se realiza por medio de:',
                                        font: 'Arial',
                                        size: 18
                                    })
                                ]
                            }),
                            new Paragraph({
                                alignment: AlignmentType.LEFT,
                                children: [
                                    new TextRun({
                                        text: (this.getValue(submission, "plan_organico_animal_manejo_animales_6_natural") ? '■' : '❏') + ' Monta u otro proceso natural',
                                        font: 'Arial',
                                        size: 18
                                    })
                                ]
                            }),
                            new Paragraph({
                                alignment: AlignmentType.LEFT,
                                children: [
                                    new TextRun({
                                        text: (this.getValue(submission, "plan_organico_animal_manejo_animales_6_hormonas") ? '■' : '❏') + ' Uso de hormonas',
                                        font: 'Arial',
                                        size: 18
                                    })
                                ]
                            }),
                            new Paragraph({
                                alignment: AlignmentType.LEFT,
                                children: [
                                    new TextRun({
                                        text: (this.getValue(submission, "plan_organico_animal_manejo_animales_6_inseminacion") ? '■' : '❏') + ' Inseminación artificial',
                                        font: 'Arial',
                                        size: 18
                                    })
                                ]
                            }),
                            new Paragraph({
                                alignment: AlignmentType.LEFT,
                                children: [
                                    new TextRun({
                                        text: (this.getValue(submission, "plan_organico_animal_manejo_animales_6_otro") ? '■' : '❏') + ' Otro (¿Cuál?):' + this.getValue(submission, "plan_organico_animal_manejo_animales_6_otro_text"),
                                        font: 'Arial',
                                        size: 18
                                    })
                                ]
                            })
                        ],
                        verticalAlign: VerticalAlign.TOP
                    }),
                    new TableCell({
                        children: [
                            new Paragraph({
                                alignment: AlignmentType.LEFT,
                                children: [
                                    new TextRun({
                                        text: this.getValue(submission, "plan_organico_animal_manejo_animales_6_comentarios"),
                                        font: 'Arial',
                                        size: 18
                                    })
                                ]
                            })
                        ],
                        verticalAlign: VerticalAlign.TOP
                    }),
                    new TableCell({
                        children: [
                            new Paragraph({
                                alignment: AlignmentType.LEFT,
                                children: [
                                    new TextRun({
                                        text: ` ${this.getValue(submission, 'plan_organico_animal_manejo_animales_implementacion_6') === 'I' ? '■' : '❏'} I`,
                                        font: 'Arial',
                                        size: 18
                                    })
                                ]
                              }),
                              new Paragraph({
                                alignment: AlignmentType.LEFT,
                                children: [
                                    new TextRun({
                                        text: ` ${this.getValue(submission, 'plan_organico_animal_manejo_animales_implementacion_6') === 'NI' ? '■' : '❏'} NI`,
                                        font: 'Arial',
                                        size: 18
                                    })
                                ]
                              })
                        ],
                        verticalAlign: VerticalAlign.TOP
                    }),
                    new TableCell({
                        children: [
                            new Paragraph({
                                alignment: AlignmentType.LEFT,
                                children: [
                                    new TextRun({
                                        text: ` ${this.getValue(submission, 'plan_organico_animal_manejo_animales_conformidad_6') === 'C' ? '■' : '❏'} C`,
                                        font: 'Arial',
                                        size: 18
                                    })
                                ]
                              }),
                              new Paragraph({
                                alignment: AlignmentType.LEFT,
                                children: [
                                    new TextRun({
                                        text: ` ${this.getValue(submission, 'plan_organico_animal_manejo_animales_conformidad_6') === 'NC' ? '■' : '❏'} NC`,
                                        font: 'Arial',
                                        size: 18
                                    })
                                ]
                              }),
                              new Paragraph({
                                alignment: AlignmentType.LEFT,
                                children: [
                                    new TextRun({
                                        text: ` ${this.getValue(submission, 'plan_organico_animal_manejo_animales_conformidad_6') === 'NA' ? '■' : '❏'} NA`,
                                        font: 'Arial',
                                        size: 18
                                    })
                                ]
                              })
                        ],
                        verticalAlign: VerticalAlign.TOP
                    }),
                    new TableCell({
                        children: [
                            new Paragraph({
                                alignment: AlignmentType.LEFT,
                                children: [
                                    new TextRun({
                                        text: this.getValue(submission, 'plan_organico_animal_manejo_animales_comentarios_inspector_6'),
                                        font: 'Arial',
                                        size: 18
                                    })
                                ]
                              })
                        ],
                        verticalAlign: VerticalAlign.TOP
                    })
                ]
            })
        )
        table.addChildElement(
            new TableRow({
                children: [
                    new TableCell({
                        children: [
                            new Paragraph({
                                alignment: AlignmentType.CENTER,
                                children: [
                                    new TextRun({
                                        text: '7',
                                        font: 'Arial',
                                        size: 18
                                    })
                                ]
                            })
                        ],
                        verticalAlign: VerticalAlign.TOP
                    }),
                    new TableCell({
                        children: [
                            new Paragraph({
                                alignment: AlignmentType.LEFT,
                                children: [
                                    new TextRun({
                                        text: 'Para el manejo de los animales se realizan las siguientes prácticas zootécnicas:',
                                        font: 'Arial',
                                        size: 18
                                    })
                                ]
                            }),
                            new Paragraph({
                                alignment: AlignmentType.LEFT,
                                children: [
                                    new TextRun({
                                        text: (this.getValue(submission, "plan_organico_animal_manejo_animales_7_descorne") ? '■' : '❏') + ' Descorne',
                                        font: 'Arial',
                                        size: 18
                                    })
                                ]
                            }),
                            new Paragraph({
                                alignment: AlignmentType.LEFT,
                                children: [
                                    new TextRun({
                                        text: (this.getValue(submission, "plan_organico_animal_manejo_animales_7_corte_rabo") ? '■' : '❏') + ' Corte de rabo',
                                        font: 'Arial',
                                        size: 18
                                    })
                                ]
                            }),
                            new Paragraph({
                                alignment: AlignmentType.LEFT,
                                children: [
                                    new TextRun({
                                        text: (this.getValue(submission, "plan_organico_animal_manejo_animales_7_corte_dientes") ? '■' : '❏') + ' Recorte de dientes',
                                        font: 'Arial',
                                        size: 18
                                    })
                                ]
                            }),
                            new Paragraph({
                                alignment: AlignmentType.LEFT,
                                children: [
                                    new TextRun({
                                        text: (this.getValue(submission, "plan_organico_animal_manejo_animales_7_corte_pico") ? '■' : '❏') + ' Corte de pico',
                                        font: 'Arial',
                                        size: 18
                                    })
                                ]
                            }),
                            new Paragraph({
                                alignment: AlignmentType.LEFT,
                                children: [
                                    new TextRun({
                                        text: (this.getValue(submission, "plan_organico_animal_manejo_animales_7_castracion") ? '■' : '❏') + ' Castración',
                                        font: 'Arial',
                                        size: 18
                                    })
                                ]
                            }),
                            new Paragraph({
                                alignment: AlignmentType.LEFT,
                                children: [
                                    new TextRun({
                                        text: (this.getValue(submission, "plan_organico_animal_manejo_animales_7_otro") ? '■' : '❏') + ' Otro (¿Cuál?): ' + this.getValue(submission, "plan_organico_animal_manejo_animales_7_otro_text"),
                                        font: 'Arial',
                                        size: 18
                                    })
                                ]
                            })
                        ],
                        verticalAlign: VerticalAlign.TOP
                    }),
                    new TableCell({
                        children: [
                            new Paragraph({
                                alignment: AlignmentType.LEFT,
                                children: [
                                    new TextRun({
                                        text: this.getValue(submission, "plan_organico_animal_manejo_animales_7_comentarios"),
                                        font: 'Arial',
                                        size: 18
                                    })
                                ]
                            })
                        ],
                        verticalAlign: VerticalAlign.TOP
                    }),
                    new TableCell({
                        children: [
                            new Paragraph({
                                alignment: AlignmentType.LEFT,
                                children: [
                                    new TextRun({
                                        text: ` ${this.getValue(submission, 'plan_organico_animal_manejo_animales_implementacion_7') === 'I' ? '■' : '❏'} I`,
                                        font: 'Arial',
                                        size: 18
                                    })
                                ]
                              }),
                              new Paragraph({
                                alignment: AlignmentType.LEFT,
                                children: [
                                    new TextRun({
                                        text: ` ${this.getValue(submission, 'plan_organico_animal_manejo_animales_implementacion_7') === 'NI' ? '■' : '❏'} NI`,
                                        font: 'Arial',
                                        size: 18
                                    })
                                ]
                              })
                        ],
                        verticalAlign: VerticalAlign.TOP
                    }),
                    new TableCell({
                        children: [
                            new Paragraph({
                                alignment: AlignmentType.LEFT,
                                children: [
                                    new TextRun({
                                        text: ` ${this.getValue(submission, 'plan_organico_animal_manejo_animales_conformidad_7') === 'C' ? '■' : '❏'} C`,
                                        font: 'Arial',
                                        size: 18
                                    })
                                ]
                              }),
                              new Paragraph({
                                alignment: AlignmentType.LEFT,
                                children: [
                                    new TextRun({
                                        text: ` ${this.getValue(submission, 'plan_organico_animal_manejo_animales_conformidad_7') === 'NC' ? '■' : '❏'} NC`,
                                        font: 'Arial',
                                        size: 18
                                    })
                                ]
                              }),
                              new Paragraph({
                                alignment: AlignmentType.LEFT,
                                children: [
                                    new TextRun({
                                        text: ` ${this.getValue(submission, 'plan_organico_animal_manejo_animales_conformidad_7') === 'NA' ? '■' : '❏'} NA`,
                                        font: 'Arial',
                                        size: 18
                                    })
                                ]
                              })
                        ],
                        verticalAlign: VerticalAlign.TOP
                    }),
                    new TableCell({
                        children: [
                            new Paragraph({
                                alignment: AlignmentType.LEFT,
                                children: [
                                    new TextRun({
                                        text: this.getValue(submission, 'plan_organico_animal_manejo_animales_comentarios_inspector_7'),
                                        font: 'Arial',
                                        size: 18
                                    })
                                ]
                              })
                        ],
                        verticalAlign: VerticalAlign.TOP
                    })
                ]
            })
        )
        table.addChildElement(
            new TableRow({
                children: [
                    new TableCell({
                        children: [
                            new Paragraph({
                                alignment: AlignmentType.CENTER,
                                children: [
                                    new TextRun({
                                        text: '8',
                                        font: 'Arial',
                                        size: 18
                                    })
                                ]
                            })
                        ],
                        verticalAlign: VerticalAlign.TOP
                    }),
                    new TableCell({
                        children: [
                            new Paragraph({
                                alignment: AlignmentType.LEFT,
                                children: [
                                    new TextRun({
                                        text: 'Los animales están identificados por medio de:',
                                        font: 'Arial',
                                        size: 18
                                    })
                                ]
                            }),
                            new Paragraph({
                                alignment: AlignmentType.LEFT,
                                children: [
                                    new TextRun({
                                        text: (this.getValue(submission, "plan_organico_animal_manejo_animales_8_aretes") ? '■' : '❏') + ' Aretes individuales',
                                        font: 'Arial',
                                        size: 18
                                    })
                                ]
                            }),
                            new Paragraph({
                                alignment: AlignmentType.LEFT,
                                children: [
                                    new TextRun({
                                        text: (this.getValue(submission, "plan_organico_animal_manejo_animales_8_marcas") ? '■' : '❏') + ' Marcas individuales',
                                        font: 'Arial',
                                        size: 18
                                    })
                                ]
                            }),
                            new Paragraph({
                                alignment: AlignmentType.LEFT,
                                children: [
                                    new TextRun({
                                        text: (this.getValue(submission, "plan_organico_animal_manejo_animales_8_corral") ? '■' : '❏') + ' No. de corral',
                                        font: 'Arial',
                                        size: 18
                                    })
                                ]
                            }),
                            new Paragraph({
                                alignment: AlignmentType.LEFT,
                                children: [
                                    new TextRun({
                                        text: (this.getValue(submission, "plan_organico_animal_manejo_animales_8_lote") ? '■' : '❏') + ' Lote',
                                        font: 'Arial',
                                        size: 18
                                    })
                                ]
                            }),
                            new Paragraph({
                                alignment: AlignmentType.LEFT,
                                children: [
                                    new TextRun({
                                        text: (this.getValue(submission, "plan_organico_animal_manejo_animales_8_otro") ? '■' : '❏') + ' Otro (¿Cuál?): ' + this.getValue(submission, "plan_organico_animal_manejo_animales_8_otro_text"),
                                        font: 'Arial',
                                        size: 18
                                    })
                                ]
                            })
                        ],
                        verticalAlign: VerticalAlign.TOP
                    }),
                    new TableCell({
                        children: [
                            new Paragraph({
                                alignment: AlignmentType.LEFT,
                                children: [
                                    new TextRun({
                                        text: this.getValue(submission, "plan_organico_animal_manejo_animales_8_comentarios"),
                                        font: 'Arial',
                                        size: 18
                                    })
                                ]
                            })
                        ],
                        verticalAlign: VerticalAlign.TOP
                    }),
                    new TableCell({
                        children: [
                            new Paragraph({
                                alignment: AlignmentType.LEFT,
                                children: [
                                    new TextRun({
                                        text: ` ${this.getValue(submission, 'plan_organico_animal_manejo_animales_implementacion_8') === 'I' ? '■' : '❏'} I`,
                                        font: 'Arial',
                                        size: 18
                                    })
                                ]
                              }),
                              new Paragraph({
                                alignment: AlignmentType.LEFT,
                                children: [
                                    new TextRun({
                                        text: ` ${this.getValue(submission, 'plan_organico_animal_manejo_animales_implementacion_8') === 'NI' ? '■' : '❏'} NI`,
                                        font: 'Arial',
                                        size: 18
                                    })
                                ]
                              })
                        ],
                        verticalAlign: VerticalAlign.TOP
                    }),
                    new TableCell({
                        children: [
                            new Paragraph({
                                alignment: AlignmentType.LEFT,
                                children: [
                                    new TextRun({
                                        text: ` ${this.getValue(submission, 'plan_organico_animal_manejo_animales_conformidad_8') === 'C' ? '■' : '❏'} C`,
                                        font: 'Arial',
                                        size: 18
                                    })
                                ]
                              }),
                              new Paragraph({
                                alignment: AlignmentType.LEFT,
                                children: [
                                    new TextRun({
                                        text: ` ${this.getValue(submission, 'plan_organico_animal_manejo_animales_conformidad_8') === 'NC' ? '■' : '❏'} NC`,
                                        font: 'Arial',
                                        size: 18
                                    })
                                ]
                              }),
                              new Paragraph({
                                alignment: AlignmentType.LEFT,
                                children: [
                                    new TextRun({
                                        text: ` ${this.getValue(submission, 'plan_organico_animal_manejo_animales_conformidad_8') === 'NA' ? '■' : '❏'} NA`,
                                        font: 'Arial',
                                        size: 18
                                    })
                                ]
                              })
                        ],
                        verticalAlign: VerticalAlign.TOP
                    }),
                    new TableCell({
                        children: [
                            new Paragraph({
                                alignment: AlignmentType.LEFT,
                                children: [
                                    new TextRun({
                                        text: this.getValue(submission, 'plan_organico_animal_manejo_animales_comentarios_inspector_8'),
                                        font: 'Arial',
                                        size: 18
                                    })
                                ]
                              })
                        ],
                        verticalAlign: VerticalAlign.TOP
                    })
                ]
            })
        )
        table.addChildElement(
            new TableRow({
                children: [
                    new TableCell({
                        children: [
                            new Paragraph({
                                alignment: AlignmentType.CENTER,
                                children: [
                                    new TextRun({
                                        text: '9',
                                        font: 'Arial',
                                        size: 18
                                    })
                                ]
                            })
                        ],
                        verticalAlign: VerticalAlign.TOP
                    }),
                    new TableCell({
                        children: [
                            new Paragraph({
                                alignment: AlignmentType.LEFT,
                                children: [
                                    new TextRun({
                                        text: 'Se utiliza alguna de las siguientes sustancias para el manejo animal:',
                                        font: 'Arial',
                                        size: 18
                                    })
                                ]
                            }),
                            new Paragraph({
                                alignment: AlignmentType.LEFT,
                                children: [
                                    new TextRun({
                                        text: (this.getValue(submission, "plan_organico_animal_manejo_animales_9_estimulantes") ? '■' : '❏') + ' Estimuladores de crecimiento',
                                        font: 'Arial',
                                        size: 18
                                    })
                                ]
                            }),
                            new Paragraph({
                                alignment: AlignmentType.LEFT,
                                children: [
                                    new TextRun({
                                        text: (this.getValue(submission, "plan_organico_animal_manejo_animales_9_hormonas") ? '■' : '❏') + ' Hormonas',
                                        font: 'Arial',
                                        size: 18
                                    })
                                ]
                            }),
                            new Paragraph({
                                alignment: AlignmentType.LEFT,
                                children: [
                                    new TextRun({
                                        text: (this.getValue(submission, "plan_organico_animal_manejo_animales_9_antibioticos") ? '■' : '❏') + ' Antibióticos',
                                        font: 'Arial',
                                        size: 18
                                    })
                                ]
                            }),
                            new Paragraph({
                                alignment: AlignmentType.LEFT,
                                children: [
                                    new TextRun({
                                        text: (this.getValue(submission, "plan_organico_animal_manejo_animales_9_coccidiostáticos") ? '■' : '❏') + ' Coccidiostáticos',
                                        font: 'Arial',
                                        size: 18
                                    })
                                ]
                            }),
                            new Paragraph({
                                alignment: AlignmentType.LEFT,
                                children: [
                                    new TextRun({
                                        text: (this.getValue(submission, "plan_organico_animal_manejo_animales_9_ninguna") ? '■' : '❏') + ' Ninguna',
                                        font: 'Arial',
                                        size: 18
                                    })
                                ]
                            }),
                            new Paragraph({
                                alignment: AlignmentType.LEFT,
                                children: [
                                    new TextRun({
                                        text: (this.getValue(submission, "plan_organico_animal_manejo_animales_9_otro") ? '■' : '❏') + ' Otro (¿Cuál?): ' + this.getValue(submission, "plan_organico_animal_manejo_animales_9_otro_text"),
                                        font: 'Arial',
                                        size: 18
                                    })
                                ]
                            })
                        ],
                        verticalAlign: VerticalAlign.TOP
                    }),
                    new TableCell({
                        children: [
                            new Paragraph({
                                alignment: AlignmentType.LEFT,
                                children: [
                                    new TextRun({
                                        text: this.getValue(submission, "plan_organico_animal_manejo_animales_9_comentarios"),
                                        font: 'Arial',
                                        size: 18
                                    })
                                ]
                            })
                        ],
                        verticalAlign: VerticalAlign.TOP
                    }),
                    new TableCell({
                        children: [
                            new Paragraph({
                                alignment: AlignmentType.LEFT,
                                children: [
                                    new TextRun({
                                        text: ` ${this.getValue(submission, 'plan_organico_animal_manejo_animales_implementacion_9') === 'I' ? '■' : '❏'} I`,
                                        font: 'Arial',
                                        size: 18
                                    })
                                ]
                              }),
                              new Paragraph({
                                alignment: AlignmentType.LEFT,
                                children: [
                                    new TextRun({
                                        text: ` ${this.getValue(submission, 'plan_organico_animal_manejo_animales_implementacion_9') === 'NI' ? '■' : '❏'} NI`,
                                        font: 'Arial',
                                        size: 18
                                    })
                                ]
                              })
                        ],
                        verticalAlign: VerticalAlign.TOP
                    }),
                    new TableCell({
                        children: [
                            new Paragraph({
                                alignment: AlignmentType.LEFT,
                                children: [
                                    new TextRun({
                                        text: ` ${this.getValue(submission, 'plan_organico_animal_manejo_animales_conformidad_9') === 'C' ? '■' : '❏'} C`,
                                        font: 'Arial',
                                        size: 18
                                    })
                                ]
                              }),
                              new Paragraph({
                                alignment: AlignmentType.LEFT,
                                children: [
                                    new TextRun({
                                        text: ` ${this.getValue(submission, 'plan_organico_animal_manejo_animales_conformidad_9') === 'NC' ? '■' : '❏'} NC`,
                                        font: 'Arial',
                                        size: 18
                                    })
                                ]
                              }),
                              new Paragraph({
                                alignment: AlignmentType.LEFT,
                                children: [
                                    new TextRun({
                                        text: ` ${this.getValue(submission, 'plan_organico_animal_manejo_animales_conformidad_9') === 'NA' ? '■' : '❏'} NA`,
                                        font: 'Arial',
                                        size: 18
                                    })
                                ]
                              })
                        ],
                        verticalAlign: VerticalAlign.TOP
                    }),
                    new TableCell({
                        children: [
                            new Paragraph({
                                alignment: AlignmentType.LEFT,
                                children: [
                                    new TextRun({
                                        text: this.getValue(submission, 'plan_organico_animal_manejo_animales_comentarios_inspector_9'),
                                        font: 'Arial',
                                        size: 18
                                    })
                                ]
                              })
                        ],
                        verticalAlign: VerticalAlign.TOP
                    })
                ]
            })
        )
        table.addChildElement(
            new TableRow({
                children: [
                    new TableCell({
                        children: [
                            new Paragraph({
                                alignment: AlignmentType.CENTER,
                                children: [
                                    new TextRun({
                                        text: '10',
                                        font: 'Arial',
                                        size: 18
                                    })
                                ]
                            })
                        ],
                        verticalAlign: VerticalAlign.TOP
                    }),
                    new TableCell({
                        children: [
                            new Paragraph({
                                alignment: AlignmentType.LEFT,
                                children: [
                                    new TextRun({
                                        text: 'El destino de la producción es:',
                                        font: 'Arial',
                                        size: 18
                                    })
                                ]
                            }),
                            new Paragraph({
                                alignment: AlignmentType.LEFT,
                                children: [
                                    new TextRun({
                                        text: (this.getValue(submission, "plan_organico_animal_manejo_animales_10_venta_directa") ? '■' : '❏') + ' Venta directa al consumidor',
                                        font: 'Arial',
                                        size: 18
                                    })
                                ]
                            }),
                            new Paragraph({
                                alignment: AlignmentType.LEFT,
                                children: [
                                    new TextRun({
                                        text: (this.getValue(submission, "plan_organico_animal_manejo_animales_10_venta_intermediarios") ? '■' : '❏') + ' Venta a intermediarios',
                                        font: 'Arial',
                                        size: 18
                                    })
                                ]
                            }),
                            new Paragraph({
                                alignment: AlignmentType.LEFT,
                                children: [
                                    new TextRun({
                                        text: (this.getValue(submission, "plan_organico_animal_manejo_animales_10_industria") ? '■' : '❏') + ' Industria',
                                        font: 'Arial',
                                        size: 18
                                    })
                                ]
                            }),
                            new Paragraph({
                                alignment: AlignmentType.LEFT,
                                children: [
                                    new TextRun({
                                        text: (this.getValue(submission, "plan_organico_animal_manejo_animales_10_otro") ? '■' : '❏') + ' Otro (¿Cuál?): ' + this.getValue(submission, "plan_organico_animal_manejo_animales_10_otro_text"),
                                        font: 'Arial',
                                        size: 18
                                    })
                                ]
                            })
                        ],
                        verticalAlign: VerticalAlign.TOP
                    }),
                    new TableCell({
                        children: [
                            new Paragraph({
                                alignment: AlignmentType.LEFT,
                                children: [
                                    new TextRun({
                                        text: this.getValue(submission, "plan_organico_animal_manejo_animales_10_comentarios"),
                                        font: 'Arial',
                                        size: 18
                                    })
                                ]
                            })
                        ],
                        verticalAlign: VerticalAlign.TOP
                    }),
                    new TableCell({
                        children: [
                            new Paragraph({
                                alignment: AlignmentType.LEFT,
                                children: [
                                    new TextRun({
                                        text: ` ${this.getValue(submission, 'plan_organico_animal_manejo_animales_comentarios_inspector_9') === 'I' ? '■' : '❏'} I`,
                                        font: 'Arial',
                                        size: 18
                                    })
                                ]
                              }),
                              new Paragraph({
                                alignment: AlignmentType.LEFT,
                                children: [
                                    new TextRun({
                                        text: ` ${this.getValue(submission, 'plan_organico_animal_manejo_animales_comentarios_inspector_9') === 'NI' ? '■' : '❏'} NI`,
                                        font: 'Arial',
                                        size: 18
                                    })
                                ]
                              })
                        ],
                        verticalAlign: VerticalAlign.TOP
                    }),
                    new TableCell({
                        children: [
                            new Paragraph({
                                alignment: AlignmentType.LEFT,
                                children: [
                                    new TextRun({
                                        text: ` ${this.getValue(submission, 'plan_organico_animal_manejo_animales_conformidad_10') === 'C' ? '■' : '❏'} C`,
                                        font: 'Arial',
                                        size: 18
                                    })
                                ]
                              }),
                              new Paragraph({
                                alignment: AlignmentType.LEFT,
                                children: [
                                    new TextRun({
                                        text: ` ${this.getValue(submission, 'plan_organico_animal_manejo_animales_conformidad_10') === 'NC' ? '■' : '❏'} NC`,
                                        font: 'Arial',
                                        size: 18
                                    })
                                ]
                              }),
                              new Paragraph({
                                alignment: AlignmentType.LEFT,
                                children: [
                                    new TextRun({
                                        text: ` ${this.getValue(submission, 'plan_organico_animal_manejo_animales_conformidad_10') === 'NA' ? '■' : '❏'} NA`,
                                        font: 'Arial',
                                        size: 18
                                    })
                                ]
                              })
                        ],
                        verticalAlign: VerticalAlign.TOP
                    }),
                    new TableCell({
                        children: [
                            new Paragraph({
                                alignment: AlignmentType.LEFT,
                                children: [
                                    new TextRun({
                                        text: this.getValue(submission, 'plan_organico_animal_manejo_animales_comentarios_inspector_10'),
                                        font: 'Arial',
                                        size: 18
                                    })
                                ]
                              })
                        ],
                        verticalAlign: VerticalAlign.TOP
                    })
                ]
            })
        )
        table.addChildElement(
            new TableRow({
                children: [
                    new TableCell({
                        children: [
                            new Paragraph({
                                alignment: AlignmentType.CENTER,
                                children: [
                                    new TextRun({
                                        text: '11',
                                        font: 'Arial',
                                        size: 18
                                    })
                                ]
                            })
                        ],
                        verticalAlign: VerticalAlign.TOP
                    }),
                    new TableCell({
                        children: [
                            new Paragraph({
                                alignment: AlignmentType.LEFT,
                                children: [
                                    new TextRun({
                                        text: 'Por favor describa las condiciones de transporte y las herramientas de manejo del ganado durante el transporte:',
                                        font: 'Arial',
                                        size: 18
                                    })
                                ]
                            }),
                            new Paragraph({
                                alignment: AlignmentType.LEFT,
                                children: [
                                    new TextRun({
                                        text: 'R: ' + (this.getValue(submission, "plan_organico_animal_manejo_animales_11_declaracion")),
                                        font: 'Arial',
                                        size: 18
                                    })
                                ]
                            }),
                        ],
                        verticalAlign: VerticalAlign.TOP
                    }),
                    new TableCell({
                        children: [
                            new Paragraph({
                                alignment: AlignmentType.LEFT,
                                children: [
                                    new TextRun({
                                        text: this.getValue(submission, "plan_organico_animal_manejo_animales_11_comentarios"),
                                        font: 'Arial',
                                        size: 18
                                    })
                                ]
                            })
                        ],
                        verticalAlign: VerticalAlign.TOP
                    }),
                    new TableCell({
                        children: [
                            new Paragraph({
                                alignment: AlignmentType.LEFT,
                                children: [
                                    new TextRun({
                                        text: ` ${this.getValue(submission, 'plan_organico_animal_manejo_animales_implementacion_11') === 'I' ? '■' : '❏'} I`,
                                        font: 'Arial',
                                        size: 18
                                    })
                                ]
                              }),
                              new Paragraph({
                                alignment: AlignmentType.LEFT,
                                children: [
                                    new TextRun({
                                        text: ` ${this.getValue(submission, 'plan_organico_animal_manejo_animales_implementacion_11') === 'NI' ? '■' : '❏'} NI`,
                                        font: 'Arial',
                                        size: 18
                                    })
                                ]
                              })
                        ],
                        verticalAlign: VerticalAlign.TOP
                    }),
                    new TableCell({
                        children: [
                            new Paragraph({
                                alignment: AlignmentType.LEFT,
                                children: [
                                    new TextRun({
                                        text: ` ${this.getValue(submission, 'plan_organico_animal_manejo_animales_conformidad_11') === 'C' ? '■' : '❏'} C`,
                                        font: 'Arial',
                                        size: 18
                                    })
                                ]
                              }),
                              new Paragraph({
                                alignment: AlignmentType.LEFT,
                                children: [
                                    new TextRun({
                                        text: ` ${this.getValue(submission, 'plan_organico_animal_manejo_animales_conformidad_11') === 'NC' ? '■' : '❏'} NC`,
                                        font: 'Arial',
                                        size: 18
                                    })
                                ]
                              }),
                              new Paragraph({
                                alignment: AlignmentType.LEFT,
                                children: [
                                    new TextRun({
                                        text: ` ${this.getValue(submission, 'plan_organico_animal_manejo_animales_conformidad_11') === 'NA' ? '■' : '❏'} NA`,
                                        font: 'Arial',
                                        size: 18
                                    })
                                ]
                              })
                        ],
                        verticalAlign: VerticalAlign.TOP
                    }),
                    new TableCell({
                        children: [
                            new Paragraph({
                                alignment: AlignmentType.LEFT,
                                children: [
                                    new TextRun({
                                        text: this.getValue(submission, 'plan_organico_animal_manejo_animales_comentarios_inspector_11'),
                                        font: 'Arial',
                                        size: 18
                                    })
                                ]
                              })
                        ],
                        verticalAlign: VerticalAlign.TOP
                    })
                ]
            })
        )
        table.addChildElement(
            new TableRow({
                children: [
                    new TableCell({
                        children: [
                            new Paragraph({
                                alignment: AlignmentType.CENTER,
                                children: [
                                    new TextRun({
                                        text: '12',
                                        font: 'Arial',
                                        size: 18
                                    })
                                ]
                            })
                        ],
                        verticalAlign: VerticalAlign.TOP
                    }),
                    new TableCell({
                        children: [
                            new Paragraph({
                                alignment: AlignmentType.LEFT,
                                children: [
                                    new TextRun({
                                        text: 'Por favor indique la edad a la que son sacrificados los animales:',
                                        font: 'Arial',
                                        size: 18
                                    })
                                ]
                            }),
                            new Paragraph({
                                alignment: AlignmentType.LEFT,
                                children: [
                                    new TextRun({
                                        text: 'R: ' + (this.getValue(submission, "plan_organico_animal_manejo_animales_12_declaracion")),
                                        font: 'Arial',
                                        size: 18
                                    })
                                ]
                            }),
                        ],
                        verticalAlign: VerticalAlign.TOP
                    }),
                    new TableCell({
                        children: [
                            new Paragraph({
                                alignment: AlignmentType.LEFT,
                                children: [
                                    new TextRun({
                                        text: this.getValue(submission, "plan_organico_animal_manejo_animales_12_comentarios"),
                                        font: 'Arial',
                                        size: 18
                                    })
                                ]
                            })
                        ],
                        verticalAlign: VerticalAlign.TOP
                    }),
                    new TableCell({
                        children: [
                            new Paragraph({
                                alignment: AlignmentType.LEFT,
                                children: [
                                    new TextRun({
                                        text: ` ${this.getValue(submission, 'plan_organico_animal_manejo_animales_implementacion_12') === 'I' ? '■' : '❏'} I`,
                                        font: 'Arial',
                                        size: 18
                                    })
                                ]
                              }),
                              new Paragraph({
                                alignment: AlignmentType.LEFT,
                                children: [
                                    new TextRun({
                                        text: ` ${this.getValue(submission, 'plan_organico_animal_manejo_animales_implementacion_12') === 'NI' ? '■' : '❏'} NI`,
                                        font: 'Arial',
                                        size: 18
                                    })
                                ]
                              })
                        ],
                        verticalAlign: VerticalAlign.TOP
                    }),
                    new TableCell({
                        children: [
                            new Paragraph({
                                alignment: AlignmentType.LEFT,
                                children: [
                                    new TextRun({
                                        text: ` ${this.getValue(submission, 'plan_organico_animal_manejo_animales_conformidad_12') === 'C' ? '■' : '❏'} C`,
                                        font: 'Arial',
                                        size: 18
                                    })
                                ]
                              }),
                              new Paragraph({
                                alignment: AlignmentType.LEFT,
                                children: [
                                    new TextRun({
                                        text: ` ${this.getValue(submission, 'plan_organico_animal_manejo_animales_conformidad_12') === 'NC' ? '■' : '❏'} NC`,
                                        font: 'Arial',
                                        size: 18
                                    })
                                ]
                              }),
                              new Paragraph({
                                alignment: AlignmentType.LEFT,
                                children: [
                                    new TextRun({
                                        text: ` ${this.getValue(submission, 'plan_organico_animal_manejo_animales_conformidad_12') === 'NA' ? '■' : '❏'} NA`,
                                        font: 'Arial',
                                        size: 18
                                    })
                                ]
                              })
                        ],
                        verticalAlign: VerticalAlign.TOP
                    }),
                    new TableCell({
                        children: [
                            new Paragraph({
                                alignment: AlignmentType.LEFT,
                                children: [
                                    new TextRun({
                                        text: this.getValue(submission, 'plan_organico_animal_manejo_animales_comentarios_inspector_12'),
                                        font: 'Arial',
                                        size: 18
                                    })
                                ]
                            })
                        ],
                        verticalAlign: VerticalAlign.TOP
                    })
                ]
            })
        )

        return table
    }
    
    getValue(submission, name) {
        var value = null
        for (const i in submission) {
            const element = submission[i]
            if (element.name === name) {
                value = element.value
            }
        }
        if (value) return value
        else return ''
    }

    getValueList(submission, name) {
        var value = null
        for (const i in submission) {
            const element = submission[i]
            if (element.name === name) {
                value = element.value
            }
        }
        if (value) return value
        else return []
    }

    // END MTO_FSOL_08_2_1
}