<div class="modal-header">
    <h4 class="modal-title" id="modal-title">Asignar cotizacion a operador</h4>
    <button
      type="button"
      class="close"
      aria-label="Close button"
      aria-describedby="modal-title"
      (click)="modal.dismiss('Cross click')"
    >
      <span aria-hidden="true">&times;</span>
    </button>
</div>
  
<form [formGroup]="form" (ngSubmit)="onSubmit()">
  <div class="modal-body">
    <div class="form-group">
      <label for="customer">
        Operador<span class="text-danger">*</span></label
      >

      <fieldset>
        <ng-template #rt let-r="result" let-t="term">
            <strong>
              <ngb-highlight [result]="r.Id_Comercial" [term]="t"></ngb-highlight>
            </strong>
              - 
            <ngb-highlight [result]="r.Name" [term]="t"></ngb-highlight>
        </ng-template>
    

        <input
            id="typeahead-http"
            type="text"
            class="form-control"
            [class.is-invalid]="searchFailed"
            [ngbTypeahead]="search"
            [resultTemplate]="rt"
            [inputFormatter]="formatter"
            (selectItem)="loadSubstance($event)"
            formControlName="customer"
        />
    
        <small *ngIf="searching" class="form-text text-muted">Buscando...</small>
        <div class="invalid-feedback" *ngIf="searchFailed">
            Lo sentimos, las sugerencias no se pudieron cargar.
        </div>
      </fieldset>

      <div *ngIf="submitted && f.customer.errors">
        <div *ngIf="f.customer.errors.required" class="text-danger">
          <small>Operador requerido</small>
        </div>
      </div>
    </div>
  </div>

  <div class="modal-footer">
    <button
      type="button"
      class="btn btn-outline-secondary"
      (click)="modal.dismiss('cancel click')"
    >
      Cancelar
    </button>
    <button
      [disabled]="loading"
      type="button"
      ngbAutofocus
      class="btn btn-primary"
      (click)="onSubmit()"
    >
      <span
        *ngIf="loading"
        class="spinner-border spinner-border-sm mr-1"
      ></span>
      Asignar
    </button>
  </div>
</form>
  