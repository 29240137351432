<div [ngClass]="{'was-validated': validations.required, 'form': true}"
    [class]="attributes.class ? attributes.class : 's'" style="margin-bottom: 10px;" [formGroup]="form">
    <label *ngIf="attributes.label" [for]="attributes.name">{{attributes.label}}</label>
    <input *ngIf="!attributes.conditional_name && attributes.type !== 'number'" class="form-control" [id]="attributes.name" [type]="attributes.type"
        [placeholder]="attributes.placeholder ? attributes.placeholder : ''" [formControlName]="attributes.name | sanitizeInputText"
        [required]="validations.required" [maxlength]="validations.maxlength" [minlength]="validations.minlength" 
        [min]="validations.block_prev_years ? minYear : validations.block_prev_dates ? minDate : ''" [max]="validations.block_prev_dates ? maxDate : ''"
        [attr.disabled]="validations.disabled" autocomplete="off" (change)="update()" />
    <div *ngIf="!attributes.conditional_name && attributes.type === 'number'">
        <input *ngIf="!attributes.conditional_name && attributes.type === 'number'" class="form-control" [id]="attributes.name"
        [placeholder]="attributes.placeholder ? attributes.placeholder : ''" [formControlName]="attributes.name"
        [required]="validations.required" [maxlength]="validations.maxlength" [minlength]="validations.minlength"
        [attr.disabled]="validations.disabled" autocomplete="off" (change)="update()" />
    </div>
    <div *ngIf="attributes.conditional_name">
        <input *ngFor="let name of attributes.conditional_name" [type]="attributes.type" class="form-control"
        [id]="attributes.name" [placeholder]="attributes.placeholder ? attributes.placeholder : ''"
            [formControlName]="name | sanitizeInputText" [required]="validations.required" [maxlength]="validations.maxlength"
            [minlength]="validations.minlength" [min]="validations.min" [max]="validations.max"
            [attr.disabled]="validations.disabled" [hidden]="!form.get(name).value" />
    </div>
    <div class="valid-feedback">{{attributes.input_valid}}</div>
    <div class="invalid-feedback">{{attributes.input_invalid}}</div>
</div>