<div class="mb-3" [formGroup]="form">
    <div class="row">
        <div class="col-md-5">
            <div id="dynamic-container" class="row overflow-auto" [ngStyle]="{'max-height': attributes.maxHeight ? attributes.maxHeight : '35vh'}">
                <table class="table">
                    <thead class="thead-dark">
                        <tr>
                            <th *ngIf="attributes.numbered">#</th>
                            <th *ngFor="let header of attributes.header; let i = index" [ngStyle]="{'font-size': attributes.size ? attributes.size : '15px', 'width': attributes.fixedSize ? attributes.fixedSize[i] : 'unset'}">{{header[0]}}</th>
                            <th>Acciones</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let formGroup of formArray.controls; let i = index;" [ngStyle]="{'background': currentIndex === i ? 'forestgreen' : 'none'}" style="max-height: 5vh ;">
                            <td *ngIf="attributes.numbered" [ngStyle]="{'color': currentIndex === i ? 'white' : 'black'}">{{i + 1}}</td>
                            <td *ngFor="let header of attributes.header" [ngStyle]="{'color': currentIndex === i ? 'white' : 'black'}">{{formGroup.get(header[1]) && formGroup.get(header[1]).value ? formGroup.get(header[1]).value : '...'}}</td>
                            <td>
                                <button type="button" class="btn btn-sm" (click)="changeIndex(i)"><i class="fas fa-edit fa-2x" [ngStyle]="{'color': currentIndex === i ? 'white' : 'black'}"></i></button>
                                <button type="button" class="btn btn-sm" *ngIf="attributes.save"  (click)="save(i)" [ngStyle]="{'display': currentIndex === i ? 'block' : 'none'}"><i class="fas fa-save fa-2x" [ngStyle]="{'color': currentIndex === i ? 'white' : 'black'}"></i></button>
                                <button type="button" class="btn btn-sm" *ngIf="attributes.removable" (click)="deleteGroup(i)" [ngStyle]="{'display': currentIndex === i ? 'block' : 'none'}"><i class="fas fa-trash-alt fa-2x" [ngStyle]="{'color': currentIndex === i ? 'white' : 'black'}"></i></button>
                            </td>
                        </tr>
                    </tbody>
                </table>
                <div *ngIf="formArray.controls.length === 0" style="width: 100%;">
                    <p style="text-align: center; color: #828282; margin: 0;">No hay registros</p>
                </div>
            </div>
        </div>

        <div class="col-md-7">
            <div class="card" *ngIf="attributes.search && type !== 'Operador'">
                <div class="card-body">
                    <fieldset>
                        <ng-template #rt let-r="result" let-t="term">
                            <strong>
                                <ngb-highlight [result]="r.code" [term]="t"></ngb-highlight>
                            </strong>
                            - 
                            <ngb-highlight [result]="r.name" [term]="t"></ngb-highlight>
                        </ng-template>
                    
                    
                        <Label><strong>Buscar sustancias de otro operador</strong></Label>
                        <input
                            id="typeahead-http"
                            type="text"
                            class="form-control"
                            [class.is-invalid]="searchFailed"
                            [ngbTypeahead]="search"
                            [resultTemplate]="rt"
                            [inputFormatter]="formatter"
                            (selectItem)="loadSubstance($event)"
                        />
                    
                        <small *ngIf="searching" class="form-text text-muted">Buscando...</small>
                        <div class="invalid-feedback" *ngIf="searchFailed">
                            Lo sentimos, las sugerencias no se pudieron cargar.
                        </div>
                    </fieldset>
                </div>
            </div>
            
            <div *ngIf="!attributes.no_body" class="card">
                <div class="card-body">
                    <div [formArrayName]="attributes.group_name">
                        <containers-fabric *ngFor="let formGroup of formArray.controls; let i = index;"
                            [content]="data.content" [formGroup]="formGroup" [ngClass]="{'d-none': i !== currentIndex}">
                        </containers-fabric>
                    </div>
                </div>
                <div *ngIf="attributes.addible !== false" class="row">
                    <div class="col-lg-12 d-flex justify-content-center">
                        <button  class="btn btn-small btn-info mb-3" *ngIf="!attributes.fixed" (click)="addFormGroup($event)">{{attributes.btn_title ? attributes.btn_title : 'Añadir'}}</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>