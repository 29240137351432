<div class="modal-header">
    <h5 class="modal-title" id="modal-title">
        DOCUMENTOS DE HALLAZGOS
        <p class="lead" style="margin: 0;">{{ code }} - {{ name }}</p>
    </h5>
    <button type="button" class="close" aria-label="Close button" aria-describedby="modal-title" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
</div>

<div class="modal-body container">
    <div class="row">
        <div *ngFor="let format of nc_urls; let i = index" class="col-lg-3">
            <div class="card home-fade-in hover-card mt-3">
                <div class="card-body bg-white text-center btn" (click)="show(i)">
                    <h5 class="card-title">MTO-FRYD-05</h5>
                    <h6 class="card-subtitle mb-2 text-muted">Notificación de no conformidad</h6>
                    <a *ngIf="indexNC !== i" class="text-info">Ver</a>
                </div>
            </div>
        </div>
    </div>

    <div *ngIf="nc_urls.length" class="col-12 mt-5 hide-print">
        <ngx-doc-viewer [url]="nc_urls[indexNC].document_url" viewer="pdf" width="100%" style="border: none; height: 50vh;"></ngx-doc-viewer>
    </div>
</div>

<div class="modal-footer">
    <button type="button" class="btn btn-outline-secondary" (click)="modal.close('cancel click')">
      'Cerrar'
    </button>
</div>