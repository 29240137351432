import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { environment } from '@environments/environment';
import { User } from '@app/core/models';
import { AlertService } from '@app/core/services';

// Item in local storage for registered user
const userKey = "user"

@Injectable({ providedIn: 'root' })
export class TokenService {
    constructor(
        private router: Router,
        private http: HttpClient,
        private alertService: AlertService
    ) {}

    saveToken(tokenUser, tokenRefresh) {
        // Add token cookie that expires in 24 hours
        const expires = new Date(Date.now() + 24 * 60 * 60 * 1000).toUTCString();
        document.cookie = `jwtToken=${tokenUser}; expires=${expires}; path=/`;

        // Add token refresh cookie that expires in 5 days
        const expiresRefresh = new Date(Date.now() + 5 * 24 * 60 * 60 * 1000).toUTCString();
        document.cookie = `jwtRefresh=${tokenRefresh}; expires=${expiresRefresh}; path=/`;
    }

    getToken() {
        // Check if token is expired
        const jwtToken = (document.cookie.split(';').find(x => x.includes('jwtToken')) || '=').split('=')[1]

        // Get token refresh
        const jwtTokenRefresh = (document.cookie.split(';').find(x => x.includes('jwtRefresh')) || '=').split('=')[1]

        const tokens = {
            tokenUser: jwtToken,
            tokenRefresh: jwtTokenRefresh
        }

        return tokens
    }

    refreshToken(tokenUser, tokenRefresh) {
        const headers = {
            'Content-Type': 'application/json',
            'Authorization': tokenUser,
            'Refresh': tokenRefresh
        };

        return this.http.post<any>(`${environment.apiUrl}/auth/refresh-token`, {}, { headers })
    }
}