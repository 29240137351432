<div class="modal-header">
  <h5 class="modal-title text-center" id="modal-title">MTO-FINS-04 Reporte de Hallazgos</h5>
  <button type="button" class="close" aria-label="Close button" aria-describedby="modal-title"
    (click)="modal.dismiss('Cross click')">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
  <div class="row justify-content-center" style="margin-top: 100px; margin-bottom: 100px;" *ngIf="docloading">
    <div class="d-flex align-items-center">
      <div class="spinner-border ml-auto mr-2" role="status" aria-hidden="true"></div>
      <strong>Cargando...</strong>
    </div>
  </div>
  <form-builder *ngIf="showForm" [formData]="response.form"></form-builder>
  <div class="mt-1" *ngIf="attachments.length > 0 && isEvaluation">
    <h4>Anexos</h4>
    <div style="margin-bottom: 10px;" *ngFor="let url of attachments; let i = index">
      <a class="bg-info text-light" style="padding: 5px 20px 5px 20px; border-radius: 5px;" target="_blank"
        [href]="url"><i style="margin-right: 10px;" class="fas fa-file"></i>{{getFilename(url)}}</a>
    </div>
  </div>
</div>
<div class="modal-footer justify-content-center" style="margin-top: 50px;">
  <button type="button" class="btn btn-outline-secondary" (click)="modal.close('cancel click')">
    {{'Cerrar'}}
  </button>
  <button *ngIf="!ncNotificationSent && isEvaluation" [disabled]="validateAllNC" type="button" ngbAutofocus class="btn btn-primary" (click)="onSubmit(true)">
    <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>
    {{button}}
  </button>
  <button type="button" ngbAutofocus class="btn btn-success" (click)="save()">
    <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>
    Guardar
  </button>
  <button *ngIf="ncNotificationSent" type="button" class="btn btn-danger" (click)="reject()">
    <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>
    Rechazar
  </button>
  <button *ngIf="ncNotificationSent" [disabled]="validateAllNC" type="button" ngbAutofocus class="btn btn-primary" (click)="onSubmit(false)">
    <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>
    Dictaminar
  </button>
</div>

<div *ngIf="generating" style="width: 100vw; height: 100vh; background-color: #00000055; position: fixed; top: 0; left: 0; z-index: 100000;">
  <div class="saving-documents" style="position: fixed; right: 20px; bottom: 20px; width: 280px; height: 45px; background-color: #FFFFFF; padding: 10px; border-radius: 5px;">
    <span class="text-dark" style="margin-right: 10px;">Enviando hallazgos al operador...</span>
    <span class="spinner-border spinner-border-sm align-center text-success" ></span>
  </div>
</div>

<div *ngIf="saving"
  style="width: 100vw; height: 100vh; background-color: #00000055; position: fixed; top: 0; left: 0; z-index: 100000;">
  <div
    style="position: fixed; right: 20px; bottom: 20px; background-color: #FFFFFF; padding: 10px; border-radius: 5px;">
    <span class="text-dark" style="margin-right: 10px;">{{percentage != 0 ? 'Subiendo archivos: ' : 'Guardando'}}<span
        *ngIf="percentage != 0">{{percentage}}%</span></span>
    <span class="spinner-border spinner-border-sm align-center text-success"></span>
  </div>
</div>