import { Component, OnInit, Input, EventEmitter, Type } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AlertService, AuthenticationService } from '@app/core/services'
import { first } from 'rxjs/operators';

import { UploadOutput, UploadInput, UploadFile, humanizeBytes, UploaderOptions } from 'ngx-uploader';
import { environment } from '@environments/environment';

@Component({
    templateUrl: 'change-imageProfile.component.html',
    styleUrls: ['../../../app.component.scss']
})
export class ModalChangeProfileImage implements OnInit {
    @Input() public name = ''

    form: FormGroup;
    type = ''
    isEditable: false;
    saving = false;

    options: UploaderOptions;
    files: UploadFile[];
    uploadInput: EventEmitter<UploadInput>;
    dragOver: boolean;

    loading = false;
    submitted = false;
    
    constructor(
        private formBuilder: FormBuilder, 
        public modal: NgbActiveModal,
        private authenticationService: AuthenticationService,
        private alertService: AlertService
    ) {
        this.options = { concurrency: 1, maxUploads: 1, maxFileSize: 5000000 };
        this.files = []; // local uploading files array
        this.uploadInput = new EventEmitter<UploadInput>(); // input events, we use this to emit data to ngx-uploader
     }

    ngOnInit() {
        
    }

    async onSubmit() {
        this.submitted = true;
        this.loading = true

        // Reset alerts on submit
        this.alertService.clear();

        this.startUpload()
    }

    onUploadOutput(output: UploadOutput): void {
        switch (output.type) {
            case 'allAddedToQueue':
                // uncomment this if you want to auto upload files when added
                // const event: UploadInput = {
                //   type: 'uploadAll',
                //   url: '/upload',
                //   method: 'POST',
                //   data: { foo: 'bar' }
                // };
                // this.uploadInput.emit(event);
                break;
            case 'addedToQueue':
                if (typeof output.file !== 'undefined') {
                    this.files.push(output.file);
                }
                break;
            case 'uploading':
                if (typeof output.file !== 'undefined') {
                    // update current data in files array for uploading file
                    const index = this.files.findIndex(file => typeof output.file !== 'undefined' && file.id === output.file.id);
                    this.files[index] = output.file;
                }
                break;
            case 'removed':
                // remove file from array when removed
                this.files = this.files.filter((file: UploadFile) => file !== output.file);
                break;
            case 'dragOver':
                this.dragOver = true;
                break;
            case 'dragOut':
            case 'drop':
                this.dragOver = false;
                break;
            case 'rejected':
                this.files.length = 0
                if (typeof output.file !== 'undefined') {
                    this.files.push(output.file);
                }
                break
            case 'done':
                // The file is downloaded
                //console.log("The file is downloaded")
                //console.log(output.file.response)
                let currentData = JSON.parse(localStorage.getItem("user"))
                currentData.photoUrl = output.file.response.data
                localStorage.setItem('user', JSON.stringify(currentData))
                this.authenticationService.updatePhoto(currentData)
                this.uploadInput.emit({ type: 'removeAll' })
                break;
        }
    }

    startUpload() {
        const jwtToken = (document.cookie.split(';').find(x => x.includes('jwtToken')) || '=').split('=')[1]

        const event: UploadInput = {
            type: 'uploadAll',
            url: `${environment.apiUrl}/users/me/change-profile-picture`,
            method: 'POST',
            headers: {
                'Authorization': jwtToken
            },
        };

        this.uploadInput.emit(event);
    }

    finishUpload() {
        this.alertService.success('La actualización de foto de perfil a sido realizada exitosamente', { autoClose: true });

        this.loading = false

        setTimeout(() => {
            this.modal.close(true)
            this.modal.dismiss()
        }, 1000);
    }

    cancelUpload(id: string): void {
        this.uploadInput.emit({ type: 'cancel', id: id });
    }

    removeFile(id: string): void {
        this.uploadInput.emit({ type: 'remove', id: id });
    }

    removeAllFiles(): void {
        this.uploadInput.emit({ type: 'removeAll' });
    }
}