import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { first } from 'rxjs/operators';

import { AuthenticationService, AlertService } from '@app/core/services';

import { environment } from '../../../environments/environment'

@Component({ templateUrl: 'login.component.html', styleUrls: ['../../styles/signin.component.scss', '../../styles/sponsor.component.scss'] })
export class LoginComponent implements OnInit {
    loginForm: FormGroup;
    loading = false;
    submitted = false;
    returnUrl: string;
    error = '';
    token: string;
    resetToken: string;
    isShowed = false;

    versionSystem

    constructor(
        private formBuilder: FormBuilder,
        private route: ActivatedRoute,
        private router: Router,
        private authenticationService: AuthenticationService,
        private alertService: AlertService
    ) {
        // Redirect to home if already logged in
        if (this.authenticationService.userValue) {
            this.router.navigate(['/']);
        }
    }

    ngOnInit() {
        this.versionSystem = environment.version

        this.loginForm = this.formBuilder.group({
            email: ['', Validators.required],
            password: ['', Validators.required]
        });

        this.token = this.route.snapshot.queryParams['token']
        //console.log(this.token)

        if (this.token) {
            //console.log("Bienvenido")
            this.router.navigate(['bienvenido', this.token])
        }

        this.resetToken = this.route.snapshot.queryParams['resetToken']
        //console.log(this.resetToken)

        if (this.resetToken) {
            this.router.navigate(['cambiarContrasena', this.resetToken])
        }
        // Get return url from route parameters or default to '/'
        this.returnUrl = /* this.route.snapshot.queryParams['returnUrl'] || */ '/';
    }

    // Convenience getter for easy access to form fields
    get f() { return this.loginForm.controls; }

    onSubmit() {
        this.submitted = true;

        // Reset alerts on submit
        this.alertService.clear();

        // Stop here if form is invalid
        if (this.loginForm.invalid) {
            return;
        }

        this.loading = true;
        this.authenticationService.login(this.f.email.value, this.f.password.value)
            .pipe(first())
            .subscribe({
                next: () => {
                    this.router.navigate([this.returnUrl]);
                },
                error: error => {
                    this.alertService.error(error);
                    this.error = error;
                    this.loading = false;
                }
            });
    }

    showPassword(): void {
      this.isShowed = !this.isShowed;
    }


}
