import { AlignmentType, Document, Paragraph, TextRun, Media, Header, Footer, Table, TableRow, TableCell, VerticalAlign, WidthType, PageNumberFormat, PageNumber, PageOrientation, BorderStyle } from "docx";
import { logo } from "../logo";
import { DatePipe } from '@angular/common'

export class DocumentCreatorMTO_FSOL_01_1_v2 {
    // INIT MTO-FSOL-01-1
    public async create_MTO_FSOL_01_1() {
        const document = new Document();
        const image = Media.addImage(document, logo, 50, 50);
        var datePipe = new DatePipe('es-MX');

        document.addSection({
            headers: {
                default: new Header({
                    children: [
                        new Paragraph({ alignment: AlignmentType.CENTER, children: [image], }),
                        new Paragraph({
                            alignment: AlignmentType.CENTER, children: [
                                new TextRun({
                                    font: 'Book Antiqua',
                                    size: 20,
                                    bold: true,
                                    children: [`MTO-FSOL-01-1| R 01 | 22-Abril-2021 | Pág. `, PageNumber.CURRENT]
                                }),
                                new TextRun({
                                    font: 'Book Antiqua',
                                    size: 20,
                                    bold: true,
                                    children: [' de ', PageNumber.TOTAL_PAGES]
                                }),
                            ], spacing: { before: 200, after: 300 }
                        })
                    ]
                })
            },
            footers: {
                default: new Footer({
                    children: [
                        new Paragraph({ alignment: AlignmentType.CENTER }),
                        this.createFooter()
                    ]
                }),
            },
            children: [
                new Paragraph({
                    alignment: AlignmentType.CENTER,
                    children: [
                        new TextRun({
                            text: 'LISTA DE DOCUMENTOS POR ALCANCE',
                            font: 'Calibri (Cuerpo)',
                            size: 22,
                            bold: true
                        })
                    ],
                    spacing: {
                        before: 200, after: 400
                    }
                }),
                this.createTableListOfDocuments(),
                new Paragraph({
                    spacing: {
                        before: 200, after: 200
                    }
                }),
            ],
            margins: {
                top: 350,
                bottom: 500,
                left: 1000,
                right: 1000
            },
            properties: {
                pageNumberStart: 1,
                pageNumberFormatType: PageNumberFormat.DECIMAL
            },
            size: {
                orientation: PageOrientation.LANDSCAPE
            }
        })

        return document
    }

    public createFooter(): Table {
        const table = new Table({
            rows: [
                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: 'Elaboró:',
                                            font: 'Calibri (Cuerpo)',
                                            size: 22
                                        })
                                    ]
                                })
                            ],
                            width: {
                                size: 33,
                                type: WidthType.PERCENTAGE
                            }
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: 'Revisó:',
                                            font: 'Calibri (Cuerpo)',
                                            size: 22
                                        })
                                    ]
                                })
                            ],
                            width: {
                                size: 33,
                                type: WidthType.PERCENTAGE
                            }
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: 'Autorizó:',
                                            font: 'Calibri (Cuerpo)',
                                            size: 22
                                        })
                                    ]
                                }),
                            ],
                            width: {
                                size: 33,
                                type: WidthType.PERCENTAGE
                            }
                        })
                    ]
                }),
                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: 'Director de certificación',
                                            font: 'Calibri (Cuerpo)',
                                            size: 16,
                                            bold: true
                                        })
                                    ]
                                }),

                            ],
                            width: {
                                size: 33,
                                type: WidthType.PERCENTAGE
                            },
                            shading: {
                                fill: '#C0C0C0'
                            }
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: 'Responsable de calidad',
                                            font: 'Calibri (Cuerpo)',
                                            size: 16,
                                            bold: true
                                        })
                                    ]
                                }),

                            ],
                            width: {
                                size: 33,
                                type: WidthType.PERCENTAGE
                            },
                            shading: {
                                fill: '#C0C0C0'
                            }
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: 'Director general',
                                            font: 'Calibri (Cuerpo)',
                                            size: 16,
                                            bold: true
                                        })
                                    ]
                                }),
                            ],
                            width: {
                                size: 33,
                                type: WidthType.PERCENTAGE
                            },
                            shading: {
                                fill: '#C0C0C0'
                            }
                        })
                    ]
                })
            ],
            margins: {
                left: 70,
                right: 70
            },
            columnWidths: [3213, 3213, 3212],
            alignment: AlignmentType.CENTER
        })
        return table
    }
    
    public createTableListOfDocuments() {
        const table = new Table({
            rows: [
                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: 'Formato',
                                            font: 'Calibri (Body)',
                                            size: 18,
                                            bold: true
                                        })
                                    ]
                                })
                            ],
                            rowSpan: 2,
                            columnSpan: 4,
                            shading: {
                                fill: "#BFBFBF"
                            },
                            verticalAlign: VerticalAlign.CENTER
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: 'Producción Vegetal',
                                            font: 'Calibri (Body)',
                                            size: 18,
                                            bold: true
                                        })
                                    ]
                                })
                            ],
                            rowSpan: 1,
                            columnSpan: 4,
                            shading: {
                                fill: "#BFBFBF"
                            },
                            verticalAlign: VerticalAlign.CENTER
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: 'Producción Animal',
                                            font: 'Calibri (Body)',
                                            size: 18,
                                            bold: true
                                        })
                                    ]
                                })
                            ],
                            rowSpan: 1,
                            columnSpan: 4,
                            shading: {
                                fill: "#BFBFBF"
                            },
                            verticalAlign: VerticalAlign.CENTER
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: 'Clase Fungi',
                                            font: 'Calibri (Body)',
                                            size: 18,
                                            bold: true
                                        })
                                    ]
                                })
                            ],
                            rowSpan: 2,
                            columnSpan: 1,
                            shading: {
                                fill: "#BFBFBF"
                            },
                            verticalAlign: VerticalAlign.CENTER
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: 'Procesamiento de productos de las actividades agropecuarias',
                                            font: 'Calibri (Body)',
                                            size: 18,
                                            bold: true
                                        })
                                    ]
                                })
                            ],
                            rowSpan: 2,
                            columnSpan: 1,
                            shading: {
                                fill: "#BFBFBF"
                            },
                            verticalAlign: VerticalAlign.CENTER
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: 'Comercialización de productos de las actividades agropecuarias',
                                            font: 'Calibri (Body)',
                                            size: 18,
                                            bold: true
                                        })
                                    ]
                                })
                            ],
                            rowSpan: 2,
                            columnSpan: 1,
                            shading: {
                                fill: "#BFBFBF"
                            },
                            verticalAlign: VerticalAlign.CENTER
                        })
                    ]
                }),
                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: 'Granos básicos y frutales',
                                            font: 'Calibri (Body)',
                                            size: 18,
                                            bold: true
                                        })
                                    ]
                                })
                            ],
                            rowSpan: 1,
                            columnSpan: 1,
                            shading: {
                                fill: "#BFBFBF"
                            },
                            verticalAlign: VerticalAlign.CENTER
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: 'Estructuras y contenedores',
                                            font: 'Calibri (Body)',
                                            size: 18,
                                            bold: true
                                        })
                                    ]
                                })
                            ],
                            rowSpan: 1,
                            columnSpan: 1,
                            shading: {
                                fill: "#BFBFBF"
                            },
                            verticalAlign: VerticalAlign.CENTER
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: 'Germinados',
                                            font: 'Calibri (Body)',
                                            size: 18,
                                            bold: true
                                        })
                                    ]
                                })
                            ],
                            rowSpan: 1,
                            columnSpan: 1,
                            shading: {
                                fill: "#BFBFBF"
                            },
                            verticalAlign: VerticalAlign.CENTER
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: 'Productos vegetales de recolección silvestre',
                                            font: 'Calibri (Body)',
                                            size: 18,
                                            bold: true
                                        })
                                    ]
                                })
                            ],
                            rowSpan: 1,
                            columnSpan: 1,
                            shading: {
                                fill: "#BFBFBF"
                            },
                            verticalAlign: VerticalAlign.CENTER
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: 'Producción animal (domésticos)',
                                            font: 'Calibri (Body)',
                                            size: 18,
                                            bold: true
                                        })
                                    ]
                                })
                            ],
                            rowSpan: 1,
                            columnSpan: 1,
                            shading: {
                                fill: "#BFBFBF"
                            },
                            verticalAlign: VerticalAlign.CENTER
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: 'Producción animal de ecosistemas naturales o no domésticos',
                                            font: 'Calibri (Body)',
                                            size: 18,
                                            bold: true
                                        })
                                    ]
                                })
                            ],
                            rowSpan: 1,
                            columnSpan: 1,
                            shading: {
                                fill: "#BFBFBF"
                            },
                            verticalAlign: VerticalAlign.CENTER
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: 'Producción animal clase insecta',
                                            font: 'Calibri (Body)',
                                            size: 18,
                                            bold: true
                                        })
                                    ]
                                })
                            ],
                            rowSpan: 1,
                            columnSpan: 1,
                            shading: {
                                fill: "#BFBFBF"
                            },
                            verticalAlign: VerticalAlign.CENTER
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: 'Producción animal acuícola',
                                            font: 'Calibri (Body)',
                                            size: 18,
                                            bold: true
                                        })
                                    ]
                                })
                            ],
                            rowSpan: 1,
                            columnSpan: 1,
                            shading: {
                                fill: "#BFBFBF"
                            },
                            verticalAlign: VerticalAlign.CENTER
                        })
                    ]
                }),
                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: 'Solicitud de inspección y certificación orgánica',
                                            font: 'Calibri (Body)',
                                            size: 18
                                        })
                                    ]
                                }),
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: 'MTO-FSOL-01',
                                            font: 'Calibri (Body)',
                                            size: 18
                                        })
                                    ],
                                    spacing: { after: 200}
                                })
                            ],
                            rowSpan: 1,
                            columnSpan: 4,
                            verticalAlign: VerticalAlign.CENTER
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: '✓',
                                            font: 'Calibri (Body)',
                                            size: 18
                                        })
                                    ],
                                    spacing: { after: 200}
                                })
                            ],
                            rowSpan: 1,
                            columnSpan: 1,
                            verticalAlign: VerticalAlign.CENTER
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: '✓',
                                            font: 'Calibri (Body)',
                                            size: 18
                                        })
                                    ],
                                    spacing: { after: 200}
                                })
                            ],
                            rowSpan: 1,
                            columnSpan: 1,
                            verticalAlign: VerticalAlign.CENTER
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: '✓',
                                            font: 'Calibri (Body)',
                                            size: 18
                                        })
                                    ],
                                    spacing: { after: 200}
                                })
                            ],
                            rowSpan: 1,
                            columnSpan: 1,
                            verticalAlign: VerticalAlign.CENTER
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: '✓',
                                            font: 'Calibri (Body)',
                                            size: 18
                                        })
                                    ],
                                    spacing: { after: 200}
                                })
                            ],
                            rowSpan: 1,
                            columnSpan: 1,
                            verticalAlign: VerticalAlign.CENTER
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: '✓',
                                            font: 'Calibri (Body)',
                                            size: 18
                                        })
                                    ],
                                    spacing: { after: 200}
                                })
                            ],
                            rowSpan: 1,
                            columnSpan: 1,
                            verticalAlign: VerticalAlign.CENTER
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: '✓',
                                            font: 'Calibri (Body)',
                                            size: 18
                                        })
                                    ],
                                    spacing: { after: 200}
                                })
                            ],
                            rowSpan: 1,
                            columnSpan: 1,
                            verticalAlign: VerticalAlign.CENTER
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: '✓',
                                            font: 'Calibri (Body)',
                                            size: 18
                                        })
                                    ],
                                    spacing: { after: 200}
                                })
                            ],
                            rowSpan: 1,
                            columnSpan: 1,
                            verticalAlign: VerticalAlign.CENTER
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: '✓',
                                            font: 'Calibri (Body)',
                                            size: 18
                                        })
                                    ],
                                    spacing: { after: 200}
                                })
                            ],
                            rowSpan: 1,
                            columnSpan: 1,
                            verticalAlign: VerticalAlign.CENTER
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: '✓',
                                            font: 'Calibri (Body)',
                                            size: 18
                                        })
                                    ],
                                    spacing: { after: 200}
                                })
                            ],
                            rowSpan: 1,
                            columnSpan: 1,
                            verticalAlign: VerticalAlign.CENTER
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: '✓',
                                            font: 'Calibri (Body)',
                                            size: 18
                                        })
                                    ],
                                    spacing: { after: 200}
                                })
                            ],
                            rowSpan: 1,
                            columnSpan: 1,
                            verticalAlign: VerticalAlign.CENTER
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: '✓',
                                            font: 'Calibri (Body)',
                                            size: 18
                                        })
                                    ],
                                    spacing: { after: 200}
                                })
                            ],
                            rowSpan: 1,
                            columnSpan: 1,
                            verticalAlign: VerticalAlign.CENTER
                        })
                    ]
                }),
                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: 'Lista de parcelas / Lista de unidades de producción',
                                            font: 'Calibri (Body)',
                                            size: 18
                                        })
                                    ]
                                }),
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: 'MTO-FSOL-02',
                                            font: 'Calibri (Body)',
                                            size: 18
                                        })
                                    ],
                                    spacing: { after: 200}
                                })
                            ],
                            rowSpan: 1,
                            columnSpan: 4,
                            verticalAlign: VerticalAlign.CENTER
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: '02-1',
                                            font: 'Calibri (Body)',
                                            size: 18
                                        })
                                    ],
                                    spacing: { after: 200}
                                })
                            ],
                            rowSpan: 1,
                            columnSpan: 1,
                            verticalAlign: VerticalAlign.CENTER
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: '02-1',
                                            font: 'Calibri (Body)',
                                            size: 18
                                        })
                                    ],
                                    spacing: { after: 200}
                                })
                            ],
                            rowSpan: 1,
                            columnSpan: 1,
                            verticalAlign: VerticalAlign.CENTER
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: '02-1',
                                            font: 'Calibri (Body)',
                                            size: 18
                                        })
                                    ],
                                    spacing: { after: 200}
                                })
                            ],
                            rowSpan: 1,
                            columnSpan: 1,
                            verticalAlign: VerticalAlign.CENTER
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: '',
                                            font: 'Calibri (Body)',
                                            size: 18
                                        })
                                    ],
                                    spacing: { after: 200}
                                })
                            ],
                            rowSpan: 1,
                            columnSpan: 1,
                            verticalAlign: VerticalAlign.CENTER
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: '02-2',
                                            font: 'Calibri (Body)',
                                            size: 18
                                        })
                                    ],
                                    spacing: { after: 200}
                                })
                            ],
                            rowSpan: 1,
                            columnSpan: 1,
                            verticalAlign: VerticalAlign.CENTER
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: '02-2',
                                            font: 'Calibri (Body)',
                                            size: 18
                                        })
                                    ],
                                    spacing: { after: 200}
                                })
                            ],
                            rowSpan: 1,
                            columnSpan: 1,
                            verticalAlign: VerticalAlign.CENTER
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: '02-2',
                                            font: 'Calibri (Body)',
                                            size: 18
                                        })
                                    ],
                                    spacing: { after: 200}
                                })
                            ],
                            rowSpan: 1,
                            columnSpan: 1,
                            verticalAlign: VerticalAlign.CENTER
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: '02-2',
                                            font: 'Calibri (Body)',
                                            size: 18
                                        })
                                    ],
                                    spacing: { after: 200}
                                })
                            ],
                            rowSpan: 1,
                            columnSpan: 1,
                            verticalAlign: VerticalAlign.CENTER
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: '02-1',
                                            font: 'Calibri (Body)',
                                            size: 18
                                        })
                                    ],
                                    spacing: { after: 200}
                                })
                            ],
                            rowSpan: 1,
                            columnSpan: 1,
                            verticalAlign: VerticalAlign.CENTER
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: '',
                                            font: 'Calibri (Body)',
                                            size: 18
                                        })
                                    ],
                                    spacing: { after: 200}
                                })
                            ],
                            rowSpan: 1,
                            columnSpan: 1,
                            verticalAlign: VerticalAlign.CENTER
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: '',
                                            font: 'Calibri (Body)',
                                            size: 18
                                        })
                                    ],
                                    spacing: { after: 200}
                                })
                            ],
                            rowSpan: 1,
                            columnSpan: 1,
                            verticalAlign: VerticalAlign.CENTER
                        })
                    ]
                }),
                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: 'Acuerdo para la certificación',
                                            font: 'Calibri (Body)',
                                            size: 18
                                        })
                                    ]
                                }),
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: 'MTO-FSOL-03',
                                            font: 'Calibri (Body)',
                                            size: 18
                                        })
                                    ],
                                    spacing: { after: 200}
                                })
                            ],
                            rowSpan: 1,
                            columnSpan: 4,
                            verticalAlign: VerticalAlign.CENTER
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: '✓',
                                            font: 'Calibri (Body)',
                                            size: 18
                                        })
                                    ],
                                    spacing: { after: 200}
                                })
                            ],
                            rowSpan: 1,
                            columnSpan: 1,
                            verticalAlign: VerticalAlign.CENTER
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: '✓',
                                            font: 'Calibri (Body)',
                                            size: 18
                                        })
                                    ],
                                    spacing: { after: 200}
                                })
                            ],
                            rowSpan: 1,
                            columnSpan: 1,
                            verticalAlign: VerticalAlign.CENTER
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: '✓',
                                            font: 'Calibri (Body)',
                                            size: 18
                                        })
                                    ],
                                    spacing: { after: 200}
                                })
                            ],
                            rowSpan: 1,
                            columnSpan: 1,
                            verticalAlign: VerticalAlign.CENTER
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: '✓',
                                            font: 'Calibri (Body)',
                                            size: 18
                                        })
                                    ],
                                    spacing: { after: 200}
                                })
                            ],
                            rowSpan: 1,
                            columnSpan: 1,
                            verticalAlign: VerticalAlign.CENTER
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: '✓',
                                            font: 'Calibri (Body)',
                                            size: 18
                                        })
                                    ],
                                    spacing: { after: 200}
                                })
                            ],
                            rowSpan: 1,
                            columnSpan: 1,
                            verticalAlign: VerticalAlign.CENTER
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: '✓',
                                            font: 'Calibri (Body)',
                                            size: 18
                                        })
                                    ],
                                    spacing: { after: 200}
                                })
                            ],
                            rowSpan: 1,
                            columnSpan: 1,
                            verticalAlign: VerticalAlign.CENTER
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: '✓',
                                            font: 'Calibri (Body)',
                                            size: 18
                                        })
                                    ],
                                    spacing: { after: 200}
                                })
                            ],
                            rowSpan: 1,
                            columnSpan: 1,
                            verticalAlign: VerticalAlign.CENTER
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: '✓',
                                            font: 'Calibri (Body)',
                                            size: 18
                                        })
                                    ],
                                    spacing: { after: 200}
                                })
                            ],
                            rowSpan: 1,
                            columnSpan: 1,
                            verticalAlign: VerticalAlign.CENTER
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: '✓',
                                            font: 'Calibri (Body)',
                                            size: 18
                                        })
                                    ],
                                    spacing: { after: 200}
                                })
                            ],
                            rowSpan: 1,
                            columnSpan: 1,
                            verticalAlign: VerticalAlign.CENTER
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: '✓',
                                            font: 'Calibri (Body)',
                                            size: 18
                                        })
                                    ],
                                    spacing: { after: 200}
                                })
                            ],
                            rowSpan: 1,
                            columnSpan: 1,
                            verticalAlign: VerticalAlign.CENTER
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: '✓',
                                            font: 'Calibri (Body)',
                                            size: 18
                                        })
                                    ],
                                    spacing: { after: 200}
                                })
                            ],
                            rowSpan: 1,
                            columnSpan: 1,
                            verticalAlign: VerticalAlign.CENTER
                        })
                    ]
                }),
                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: 'Análisis general de riesgos',
                                            font: 'Calibri (Body)',
                                            size: 18
                                        })
                                    ]
                                }),
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: 'MTO-FSOL-04',
                                            font: 'Calibri (Body)',
                                            size: 18
                                        })
                                    ],
                                    spacing: { after: 200}
                                })
                            ],
                            rowSpan: 1,
                            columnSpan: 4,
                            verticalAlign: VerticalAlign.CENTER
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: '04-1',
                                            font: 'Calibri (Body)',
                                            size: 18
                                        })
                                    ],
                                    spacing: { after: 200}
                                })
                            ],
                            rowSpan: 1,
                            columnSpan: 1,
                            verticalAlign: VerticalAlign.CENTER
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: '04-1',
                                            font: 'Calibri (Body)',
                                            size: 18
                                        })
                                    ],
                                    spacing: { after: 200}
                                })
                            ],
                            rowSpan: 1,
                            columnSpan: 1,
                            verticalAlign: VerticalAlign.CENTER
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: '04-1',
                                            font: 'Calibri (Body)',
                                            size: 18
                                        })
                                    ],
                                    spacing: { after: 200}
                                })
                            ],
                            rowSpan: 1,
                            columnSpan: 1,
                            verticalAlign: VerticalAlign.CENTER
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: '04-1',
                                            font: 'Calibri (Body)',
                                            size: 18
                                        })
                                    ],
                                    spacing: { after: 200}
                                })
                            ],
                            rowSpan: 1,
                            columnSpan: 1,
                            verticalAlign: VerticalAlign.CENTER
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: '04-2',
                                            font: 'Calibri (Body)',
                                            size: 18
                                        })
                                    ],
                                    spacing: { after: 200}
                                })
                            ],
                            rowSpan: 1,
                            columnSpan: 1,
                            verticalAlign: VerticalAlign.CENTER
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: '04-2',
                                            font: 'Calibri (Body)',
                                            size: 18
                                        })
                                    ],
                                    spacing: { after: 200}
                                })
                            ],
                            rowSpan: 1,
                            columnSpan: 1,
                            verticalAlign: VerticalAlign.CENTER
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: '04-2',
                                            font: 'Calibri (Body)',
                                            size: 18
                                        })
                                    ],
                                    spacing: { after: 200}
                                })
                            ],
                            rowSpan: 1,
                            columnSpan: 1,
                            verticalAlign: VerticalAlign.CENTER
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: '04-2',
                                            font: 'Calibri (Body)',
                                            size: 18
                                        })
                                    ],
                                    spacing: { after: 200}
                                })
                            ],
                            rowSpan: 1,
                            columnSpan: 1,
                            verticalAlign: VerticalAlign.CENTER
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: '04-1',
                                            font: 'Calibri (Body)',
                                            size: 18
                                        })
                                    ],
                                    spacing: { after: 200}
                                })
                            ],
                            rowSpan: 1,
                            columnSpan: 1,
                            verticalAlign: VerticalAlign.CENTER
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: '04-3',
                                            font: 'Calibri (Body)',
                                            size: 18
                                        })
                                    ],
                                    spacing: { after: 200}
                                })
                            ],
                            rowSpan: 1,
                            columnSpan: 1,
                            verticalAlign: VerticalAlign.CENTER
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: '04-3',
                                            font: 'Calibri (Body)',
                                            size: 18
                                        })
                                    ],
                                    spacing: { after: 200}
                                })
                            ],
                            rowSpan: 1,
                            columnSpan: 1,
                            verticalAlign: VerticalAlign.CENTER
                        })
                    ]
                }),
                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: 'Croquis de parcelas y unidades de producción / Croquis de Unidades de Procesamiento',
                                            font: 'Calibri (Body)',
                                            size: 18
                                        })
                                    ]
                                }),
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: 'MTO-FSOL-05',
                                            font: 'Calibri (Body)',
                                            size: 18
                                        })
                                    ],
                                    spacing: { after: 200}
                                })
                            ],
                            rowSpan: 1,
                            columnSpan: 4,
                            verticalAlign: VerticalAlign.CENTER
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: '05-1',
                                            font: 'Calibri (Body)',
                                            size: 18
                                        })
                                    ],
                                    spacing: { after: 200}
                                })
                            ],
                            rowSpan: 1,
                            columnSpan: 1,
                            verticalAlign: VerticalAlign.CENTER
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: '05-1',
                                            font: 'Calibri (Body)',
                                            size: 18
                                        })
                                    ],
                                    spacing: { after: 200}
                                })
                            ],
                            rowSpan: 1,
                            columnSpan: 1,
                            verticalAlign: VerticalAlign.CENTER
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: '05-1',
                                            font: 'Calibri (Body)',
                                            size: 18
                                        })
                                    ],
                                    spacing: { after: 200}
                                })
                            ],
                            rowSpan: 1,
                            columnSpan: 1,
                            verticalAlign: VerticalAlign.CENTER
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: '05-1',
                                            font: 'Calibri (Body)',
                                            size: 18
                                        })
                                    ],
                                    spacing: { after: 200}
                                })
                            ],
                            rowSpan: 1,
                            columnSpan: 1,
                            verticalAlign: VerticalAlign.CENTER
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: '05-1',
                                            font: 'Calibri (Body)',
                                            size: 18
                                        })
                                    ],
                                    spacing: { after: 200}
                                })
                            ],
                            rowSpan: 1,
                            columnSpan: 1,
                            verticalAlign: VerticalAlign.CENTER
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: '05-1',
                                            font: 'Calibri (Body)',
                                            size: 18
                                        })
                                    ],
                                    spacing: { after: 200}
                                })
                            ],
                            rowSpan: 1,
                            columnSpan: 1,
                            verticalAlign: VerticalAlign.CENTER
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: '05-1',
                                            font: 'Calibri (Body)',
                                            size: 18
                                        })
                                    ],
                                    spacing: { after: 200}
                                })
                            ],
                            rowSpan: 1,
                            columnSpan: 1,
                            verticalAlign: VerticalAlign.CENTER
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: '05-1',
                                            font: 'Calibri (Body)',
                                            size: 18
                                        })
                                    ],
                                    spacing: { after: 200}
                                })
                            ],
                            rowSpan: 1,
                            columnSpan: 1,
                            verticalAlign: VerticalAlign.CENTER
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: '05-1',
                                            font: 'Calibri (Body)',
                                            size: 18
                                        })
                                    ],
                                    spacing: { after: 200}
                                })
                            ],
                            rowSpan: 1,
                            columnSpan: 1,
                            verticalAlign: VerticalAlign.CENTER
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: '05-3',
                                            font: 'Calibri (Body)',
                                            size: 18
                                        })
                                    ],
                                    spacing: { after: 200}
                                })
                            ],
                            rowSpan: 1,
                            columnSpan: 1,
                            verticalAlign: VerticalAlign.CENTER
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: '05-3',
                                            font: 'Calibri (Body)',
                                            size: 18
                                        })
                                    ],
                                    spacing: { after: 200}
                                })
                            ],
                            rowSpan: 1,
                            columnSpan: 1,
                            verticalAlign: VerticalAlign.CENTER
                        })
                    ]
                }),
                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: 'Límites con riesgo de contaminación',
                                            font: 'Calibri (Body)',
                                            size: 18
                                        })
                                    ]
                                }),
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: 'MTO-FSOL-06',
                                            font: 'Calibri (Body)',
                                            size: 18
                                        })
                                    ],
                                    spacing: { after: 200}
                                })
                            ],
                            rowSpan: 1,
                            columnSpan: 4,
                            verticalAlign: VerticalAlign.CENTER
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: '✓',
                                            font: 'Calibri (Body)',
                                            size: 18
                                        })
                                    ],
                                    spacing: { after: 200}
                                })
                            ],
                            rowSpan: 1,
                            columnSpan: 1,
                            verticalAlign: VerticalAlign.CENTER
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: '✓',
                                            font: 'Calibri (Body)',
                                            size: 18
                                        })
                                    ],
                                    spacing: { after: 200}
                                })
                            ],
                            rowSpan: 1,
                            columnSpan: 1,
                            verticalAlign: VerticalAlign.CENTER
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: '✓',
                                            font: 'Calibri (Body)',
                                            size: 18
                                        })
                                    ],
                                    spacing: { after: 200}
                                })
                            ],
                            rowSpan: 1,
                            columnSpan: 1,
                            verticalAlign: VerticalAlign.CENTER
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: '✓',
                                            font: 'Calibri (Body)',
                                            size: 18
                                        })
                                    ],
                                    spacing: { after: 200}
                                })
                            ],
                            rowSpan: 1,
                            columnSpan: 1,
                            verticalAlign: VerticalAlign.CENTER
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: '✓',
                                            font: 'Calibri (Body)',
                                            size: 18
                                        })
                                    ],
                                    spacing: { after: 200}
                                })
                            ],
                            rowSpan: 1,
                            columnSpan: 1,
                            verticalAlign: VerticalAlign.CENTER
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: '✓',
                                            font: 'Calibri (Body)',
                                            size: 18
                                        })
                                    ],
                                    spacing: { after: 200}
                                })
                            ],
                            rowSpan: 1,
                            columnSpan: 1,
                            verticalAlign: VerticalAlign.CENTER
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: '✓',
                                            font: 'Calibri (Body)',
                                            size: 18
                                        })
                                    ],
                                    spacing: { after: 200}
                                })
                            ],
                            rowSpan: 1,
                            columnSpan: 1,
                            verticalAlign: VerticalAlign.CENTER
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: '✓',
                                            font: 'Calibri (Body)',
                                            size: 18
                                        })
                                    ],
                                    spacing: { after: 200}
                                })
                            ],
                            rowSpan: 1,
                            columnSpan: 1,
                            verticalAlign: VerticalAlign.CENTER
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: '✓',
                                            font: 'Calibri (Body)',
                                            size: 18
                                        })
                                    ],
                                    spacing: { after: 200}
                                })
                            ],
                            rowSpan: 1,
                            columnSpan: 1,
                            verticalAlign: VerticalAlign.CENTER
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: '',
                                            font: 'Calibri (Body)',
                                            size: 18
                                        })
                                    ],
                                    spacing: { after: 200}
                                })
                            ],
                            rowSpan: 1,
                            columnSpan: 1,
                            verticalAlign: VerticalAlign.CENTER
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: '',
                                            font: 'Calibri (Body)',
                                            size: 18
                                        })
                                    ],
                                    spacing: { after: 200}
                                })
                            ],
                            rowSpan: 1,
                            columnSpan: 1,
                            verticalAlign: VerticalAlign.CENTER
                        })
                    ]
                }),
                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: 'Lista de sustancias (Insumos)',
                                            font: 'Calibri (Body)',
                                            size: 18
                                        })
                                    ]
                                }),
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: 'MTO-FSOL-07',
                                            font: 'Calibri (Body)',
                                            size: 18
                                        })
                                    ],
                                    spacing: { after: 200}
                                })
                            ],
                            rowSpan: 1,
                            columnSpan: 4,
                            verticalAlign: VerticalAlign.CENTER
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: '✓',
                                            font: 'Calibri (Body)',
                                            size: 18
                                        })
                                    ],
                                    spacing: { after: 200}
                                })
                            ],
                            rowSpan: 1,
                            columnSpan: 1,
                            verticalAlign: VerticalAlign.CENTER
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: '✓',
                                            font: 'Calibri (Body)',
                                            size: 18
                                        })
                                    ],
                                    spacing: { after: 200}
                                })
                            ],
                            rowSpan: 1,
                            columnSpan: 1,
                            verticalAlign: VerticalAlign.CENTER
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: '✓',
                                            font: 'Calibri (Body)',
                                            size: 18
                                        })
                                    ],
                                    spacing: { after: 200}
                                })
                            ],
                            rowSpan: 1,
                            columnSpan: 1,
                            verticalAlign: VerticalAlign.CENTER
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: '✓',
                                            font: 'Calibri (Body)',
                                            size: 18
                                        })
                                    ],
                                    spacing: { after: 200}
                                })
                            ],
                            rowSpan: 1,
                            columnSpan: 1,
                            verticalAlign: VerticalAlign.CENTER
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: '✓',
                                            font: 'Calibri (Body)',
                                            size: 18
                                        })
                                    ],
                                    spacing: { after: 200}
                                })
                            ],
                            rowSpan: 1,
                            columnSpan: 1,
                            verticalAlign: VerticalAlign.CENTER
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: '✓',
                                            font: 'Calibri (Body)',
                                            size: 18
                                        })
                                    ],
                                    spacing: { after: 200}
                                })
                            ],
                            rowSpan: 1,
                            columnSpan: 1,
                            verticalAlign: VerticalAlign.CENTER
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: '✓',
                                            font: 'Calibri (Body)',
                                            size: 18
                                        })
                                    ],
                                    spacing: { after: 200}
                                })
                            ],
                            rowSpan: 1,
                            columnSpan: 1,
                            verticalAlign: VerticalAlign.CENTER
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: '✓',
                                            font: 'Calibri (Body)',
                                            size: 18
                                        })
                                    ],
                                    spacing: { after: 200}
                                })
                            ],
                            rowSpan: 1,
                            columnSpan: 1,
                            verticalAlign: VerticalAlign.CENTER
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: '✓',
                                            font: 'Calibri (Body)',
                                            size: 18
                                        })
                                    ],
                                    spacing: { after: 200}
                                })
                            ],
                            rowSpan: 1,
                            columnSpan: 1,
                            verticalAlign: VerticalAlign.CENTER
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: '✓',
                                            font: 'Calibri (Body)',
                                            size: 18
                                        })
                                    ],
                                    spacing: { after: 200}
                                })
                            ],
                            rowSpan: 1,
                            columnSpan: 1,
                            verticalAlign: VerticalAlign.CENTER
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: '✓',
                                            font: 'Calibri (Body)',
                                            size: 18
                                        })
                                    ],
                                    spacing: { after: 200}
                                })
                            ],
                            rowSpan: 1,
                            columnSpan: 1,
                            verticalAlign: VerticalAlign.CENTER
                        })
                    ]
                }),
                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: 'Plan Orgánico (Revisar por alcance)',
                                            font: 'Calibri (Body)',
                                            size: 18
                                        })
                                    ]
                                }),
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: 'MTO-FSOL-08',
                                            font: 'Calibri (Body)',
                                            size: 18
                                        })
                                    ],
                                    spacing: { after: 200}
                                })
                            ],
                            rowSpan: 1,
                            columnSpan: 4,
                            verticalAlign: VerticalAlign.CENTER
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: '08-1-1',
                                            font: 'Calibri (Body)',
                                            size: 18
                                        })
                                    ],
                                    spacing: { after: 200}
                                })
                            ],
                            rowSpan: 1,
                            columnSpan: 1,
                            verticalAlign: VerticalAlign.CENTER
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: '08-1-2',
                                            font: 'Calibri (Body)',
                                            size: 18
                                        })
                                    ],
                                    spacing: { after: 200}
                                })
                            ],
                            rowSpan: 1,
                            columnSpan: 1,
                            verticalAlign: VerticalAlign.CENTER
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: '08-1-3',
                                            font: 'Calibri (Body)',
                                            size: 18
                                        })
                                    ],
                                    spacing: { after: 200}
                                })
                            ],
                            rowSpan: 1,
                            columnSpan: 1,
                            verticalAlign: VerticalAlign.CENTER
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: '08-1-4',
                                            font: 'Calibri (Body)',
                                            size: 18
                                        })
                                    ],
                                    spacing: { after: 200}
                                })
                            ],
                            rowSpan: 1,
                            columnSpan: 1,
                            verticalAlign: VerticalAlign.CENTER
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: '08-2-1',
                                            font: 'Calibri (Body)',
                                            size: 18
                                        })
                                    ],
                                    spacing: { after: 200}
                                })
                            ],
                            rowSpan: 1,
                            columnSpan: 1,
                            verticalAlign: VerticalAlign.CENTER
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: '08-2-2',
                                            font: 'Calibri (Body)',
                                            size: 18
                                        })
                                    ],
                                    spacing: { after: 200}
                                })
                            ],
                            rowSpan: 1,
                            columnSpan: 1,
                            verticalAlign: VerticalAlign.CENTER
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: '08-2-3',
                                            font: 'Calibri (Body)',
                                            size: 18
                                        })
                                    ],
                                    spacing: { after: 200}
                                })
                            ],
                            rowSpan: 1,
                            columnSpan: 1,
                            verticalAlign: VerticalAlign.CENTER
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: '08-2-4',
                                            font: 'Calibri (Body)',
                                            size: 18
                                        })
                                    ],
                                    spacing: { after: 200}
                                })
                            ],
                            rowSpan: 1,
                            columnSpan: 1,
                            verticalAlign: VerticalAlign.CENTER
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: '08-1-5',
                                            font: 'Calibri (Body)',
                                            size: 18
                                        })
                                    ],
                                    spacing: { after: 200}
                                })
                            ],
                            rowSpan: 1,
                            columnSpan: 1,
                            verticalAlign: VerticalAlign.CENTER
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: '08-3',
                                            font: 'Calibri (Body)',
                                            size: 18
                                        })
                                    ],
                                    spacing: { after: 200}
                                })
                            ],
                            rowSpan: 1,
                            columnSpan: 1,
                            verticalAlign: VerticalAlign.CENTER
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: '08-3',
                                            font: 'Calibri (Body)',
                                            size: 18
                                        })
                                    ],
                                    spacing: { after: 200}
                                })
                            ],
                            rowSpan: 1,
                            columnSpan: 1,
                            verticalAlign: VerticalAlign.CENTER
                        })
                    ]
                }),
                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: 'Plan Orgánico Grupo de Productores',
                                            font: 'Calibri (Body)',
                                            size: 18
                                        })
                                    ]
                                }),
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: 'MTO-FSOL-08-4',
                                            font: 'Calibri (Body)',
                                            size: 18
                                        })
                                    ],
                                    spacing: { after: 200}
                                })
                            ],
                            rowSpan: 1,
                            columnSpan: 4,
                            verticalAlign: VerticalAlign.CENTER
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: 'Si Aplica',
                                            font: 'Calibri (Body)',
                                            size: 18
                                        })
                                    ],
                                    spacing: { after: 200}
                                })
                            ],
                            rowSpan: 1,
                            columnSpan: 1,
                            verticalAlign: VerticalAlign.CENTER
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: 'Si Aplica',
                                            font: 'Calibri (Body)',
                                            size: 18
                                        })
                                    ],
                                    spacing: { after: 200}
                                })
                            ],
                            rowSpan: 1,
                            columnSpan: 1,
                            verticalAlign: VerticalAlign.CENTER
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: 'Si Aplica',
                                            font: 'Calibri (Body)',
                                            size: 18
                                        })
                                    ],
                                    spacing: { after: 200}
                                })
                            ],
                            rowSpan: 1,
                            columnSpan: 1,
                            verticalAlign: VerticalAlign.CENTER
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: 'Si Aplica',
                                            font: 'Calibri (Body)',
                                            size: 18
                                        })
                                    ],
                                    spacing: { after: 200}
                                })
                            ],
                            rowSpan: 1,
                            columnSpan: 1,
                            verticalAlign: VerticalAlign.CENTER
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: 'Si Aplica',
                                            font: 'Calibri (Body)',
                                            size: 18
                                        })
                                    ],
                                    spacing: { after: 200}
                                })
                            ],
                            rowSpan: 1,
                            columnSpan: 1,
                            verticalAlign: VerticalAlign.CENTER
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: 'Si Aplica',
                                            font: 'Calibri (Body)',
                                            size: 18
                                        })
                                    ],
                                    spacing: { after: 200}
                                })
                            ],
                            rowSpan: 1,
                            columnSpan: 1,
                            verticalAlign: VerticalAlign.CENTER
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: 'Si Aplica',
                                            font: 'Calibri (Body)',
                                            size: 18
                                        })
                                    ],
                                    spacing: { after: 200}
                                })
                            ],
                            rowSpan: 1,
                            columnSpan: 1,
                            verticalAlign: VerticalAlign.CENTER
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: 'Si Aplica',
                                            font: 'Calibri (Body)',
                                            size: 18
                                        })
                                    ],
                                    spacing: { after: 200}
                                })
                            ],
                            rowSpan: 1,
                            columnSpan: 1,
                            verticalAlign: VerticalAlign.CENTER
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: 'Si Aplica',
                                            font: 'Calibri (Body)',
                                            size: 18
                                        })
                                    ],
                                    spacing: { after: 200}
                                })
                            ],
                            rowSpan: 1,
                            columnSpan: 1,
                            verticalAlign: VerticalAlign.CENTER
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: '',
                                            font: 'Calibri (Body)',
                                            size: 18
                                        })
                                    ],
                                    spacing: { after: 200}
                                })
                            ],
                            rowSpan: 1,
                            columnSpan: 1,
                            verticalAlign: VerticalAlign.CENTER
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: '',
                                            font: 'Calibri (Body)',
                                            size: 18
                                        })
                                    ],
                                    spacing: { after: 200}
                                })
                            ],
                            rowSpan: 1,
                            columnSpan: 1,
                            verticalAlign: VerticalAlign.CENTER
                        })
                    ]
                }),
                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: 'Lista de ingredientes',
                                            font: 'Calibri (Body)',
                                            size: 18
                                        })
                                    ]
                                }),
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: 'MTO-FSOL-09',
                                            font: 'Calibri (Body)',
                                            size: 18
                                        })
                                    ],
                                    spacing: { after: 200}
                                })
                            ],
                            rowSpan: 1,
                            columnSpan: 4,
                            verticalAlign: VerticalAlign.CENTER
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: '',
                                            font: 'Calibri (Body)',
                                            size: 18
                                        })
                                    ],
                                    spacing: { after: 200}
                                })
                            ],
                            rowSpan: 1,
                            columnSpan: 1,
                            verticalAlign: VerticalAlign.CENTER
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: '',
                                            font: 'Calibri (Body)',
                                            size: 18
                                        })
                                    ],
                                    spacing: { after: 200}
                                })
                            ],
                            rowSpan: 1,
                            columnSpan: 1,
                            verticalAlign: VerticalAlign.CENTER
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: '',
                                            font: 'Calibri (Body)',
                                            size: 18
                                        })
                                    ],
                                    spacing: { after: 200}
                                })
                            ],
                            rowSpan: 1,
                            columnSpan: 1,
                            verticalAlign: VerticalAlign.CENTER
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: '',
                                            font: 'Calibri (Body)',
                                            size: 18
                                        })
                                    ],
                                    spacing: { after: 200}
                                })
                            ],
                            rowSpan: 1,
                            columnSpan: 1,
                            verticalAlign: VerticalAlign.CENTER
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: '✓',
                                            font: 'Calibri (Body)',
                                            size: 18
                                        })
                                    ],
                                    spacing: { after: 200}
                                })
                            ],
                            rowSpan: 1,
                            columnSpan: 1,
                            verticalAlign: VerticalAlign.CENTER
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: '',
                                            font: 'Calibri (Body)',
                                            size: 18
                                        })
                                    ],
                                    spacing: { after: 200}
                                })
                            ],
                            rowSpan: 1,
                            columnSpan: 1,
                            verticalAlign: VerticalAlign.CENTER
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: '',
                                            font: 'Calibri (Body)',
                                            size: 18
                                        })
                                    ],
                                    spacing: { after: 200}
                                })
                            ],
                            rowSpan: 1,
                            columnSpan: 1,
                            verticalAlign: VerticalAlign.CENTER
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: '✓',
                                            font: 'Calibri (Body)',
                                            size: 18
                                        })
                                    ],
                                    spacing: { after: 200}
                                })
                            ],
                            rowSpan: 1,
                            columnSpan: 1,
                            verticalAlign: VerticalAlign.CENTER
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: '',
                                            font: 'Calibri (Body)',
                                            size: 18
                                        })
                                    ],
                                    spacing: { after: 200}
                                })
                            ],
                            rowSpan: 1,
                            columnSpan: 1,
                            verticalAlign: VerticalAlign.CENTER
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: '✓',
                                            font: 'Calibri (Body)',
                                            size: 18
                                        })
                                    ],
                                    spacing: { after: 200}
                                })
                            ],
                            rowSpan: 1,
                            columnSpan: 1,
                            verticalAlign: VerticalAlign.CENTER
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: '✓',
                                            font: 'Calibri (Body)',
                                            size: 18
                                        })
                                    ],
                                    spacing: { after: 200}
                                })
                            ],
                            rowSpan: 1,
                            columnSpan: 1,
                            verticalAlign: VerticalAlign.CENTER
                        })
                    ]
                }),
                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: 'Solicitud de reconocimiento retroactivo',
                                            font: 'Calibri (Body)',
                                            size: 18
                                        })
                                    ]
                                }),
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: 'MTO-FSOL-10',
                                            font: 'Calibri (Body)',
                                            size: 18
                                        })
                                    ],
                                    spacing: { after: 200}
                                })
                            ],
                            rowSpan: 1,
                            columnSpan: 4,
                            verticalAlign: VerticalAlign.CENTER
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: 'Si Aplica',
                                            font: 'Calibri (Body)',
                                            size: 18
                                        })
                                    ],
                                    spacing: { after: 200}
                                })
                            ],
                            rowSpan: 1,
                            columnSpan: 1,
                            verticalAlign: VerticalAlign.CENTER
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: 'Si Aplica',
                                            font: 'Calibri (Body)',
                                            size: 18
                                        })
                                    ],
                                    spacing: { after: 200}
                                })
                            ],
                            rowSpan: 1,
                            columnSpan: 1,
                            verticalAlign: VerticalAlign.CENTER
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: 'Si Aplica',
                                            font: 'Calibri (Body)',
                                            size: 18
                                        })
                                    ],
                                    spacing: { after: 200}
                                })
                            ],
                            rowSpan: 1,
                            columnSpan: 1,
                            verticalAlign: VerticalAlign.CENTER
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: '',
                                            font: 'Calibri (Body)',
                                            size: 18
                                        })
                                    ],
                                    spacing: { after: 200}
                                })
                            ],
                            rowSpan: 1,
                            columnSpan: 1,
                            verticalAlign: VerticalAlign.CENTER
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: 'Si Aplica',
                                            font: 'Calibri (Body)',
                                            size: 18
                                        })
                                    ],
                                    spacing: { after: 200}
                                })
                            ],
                            rowSpan: 1,
                            columnSpan: 1,
                            verticalAlign: VerticalAlign.CENTER
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: '',
                                            font: 'Calibri (Body)',
                                            size: 18
                                        })
                                    ],
                                    spacing: { after: 200}
                                })
                            ],
                            rowSpan: 1,
                            columnSpan: 1,
                            verticalAlign: VerticalAlign.CENTER
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: '',
                                            font: 'Calibri (Body)',
                                            size: 18
                                        })
                                    ],
                                    spacing: { after: 200}
                                })
                            ],
                            rowSpan: 1,
                            columnSpan: 1,
                            verticalAlign: VerticalAlign.CENTER
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: '',
                                            font: 'Calibri (Body)',
                                            size: 18
                                        })
                                    ],
                                    spacing: { after: 200}
                                })
                            ],
                            rowSpan: 1,
                            columnSpan: 1,
                            verticalAlign: VerticalAlign.CENTER
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: 'Si Aplica',
                                            font: 'Calibri (Body)',
                                            size: 18
                                        })
                                    ],
                                    spacing: { after: 200}
                                })
                            ],
                            rowSpan: 1,
                            columnSpan: 1,
                            verticalAlign: VerticalAlign.CENTER
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: '',
                                            font: 'Calibri (Body)',
                                            size: 18
                                        })
                                    ],
                                    spacing: { after: 200}
                                })
                            ],
                            rowSpan: 1,
                            columnSpan: 1,
                            verticalAlign: VerticalAlign.CENTER
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: '',
                                            font: 'Calibri (Body)',
                                            size: 18
                                        })
                                    ],
                                    spacing: { after: 200}
                                })
                            ],
                            rowSpan: 1,
                            columnSpan: 1,
                            verticalAlign: VerticalAlign.CENTER
                        })
                    ]
                }),
                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: 'Solicitud de uso de semilla no orgánica',
                                            font: 'Calibri (Body)',
                                            size: 18
                                        })
                                    ]
                                }),
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: 'MTO-FSOL-11',
                                            font: 'Calibri (Body)',
                                            size: 18
                                        })
                                    ],
                                    spacing: { after: 200}
                                })
                            ],
                            rowSpan: 1,
                            columnSpan: 4,
                            verticalAlign: VerticalAlign.CENTER
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: 'Si Aplica',
                                            font: 'Calibri (Body)',
                                            size: 18
                                        })
                                    ],
                                    spacing: { after: 200}
                                })
                            ],
                            rowSpan: 1,
                            columnSpan: 1,
                            verticalAlign: VerticalAlign.CENTER
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: 'Si Aplica',
                                            font: 'Calibri (Body)',
                                            size: 18
                                        })
                                    ],
                                    spacing: { after: 200}
                                })
                            ],
                            rowSpan: 1,
                            columnSpan: 1,
                            verticalAlign: VerticalAlign.CENTER
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: 'Si Aplica',
                                            font: 'Calibri (Body)',
                                            size: 18
                                        })
                                    ],
                                    spacing: { after: 200}
                                })
                            ],
                            rowSpan: 1,
                            columnSpan: 1,
                            verticalAlign: VerticalAlign.CENTER
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: '',
                                            font: 'Calibri (Body)',
                                            size: 18
                                        })
                                    ],
                                    spacing: { after: 200}
                                })
                            ],
                            rowSpan: 1,
                            columnSpan: 1,
                            verticalAlign: VerticalAlign.CENTER
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: 'Si Aplica',
                                            font: 'Calibri (Body)',
                                            size: 18
                                        })
                                    ],
                                    spacing: { after: 200}
                                })
                            ],
                            rowSpan: 1,
                            columnSpan: 1,
                            verticalAlign: VerticalAlign.CENTER
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: '',
                                            font: 'Calibri (Body)',
                                            size: 18
                                        })
                                    ],
                                    spacing: { after: 200}
                                })
                            ],
                            rowSpan: 1,
                            columnSpan: 1,
                            verticalAlign: VerticalAlign.CENTER
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: '',
                                            font: 'Calibri (Body)',
                                            size: 18
                                        })
                                    ],
                                    spacing: { after: 200}
                                })
                            ],
                            rowSpan: 1,
                            columnSpan: 1,
                            verticalAlign: VerticalAlign.CENTER
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: '',
                                            font: 'Calibri (Body)',
                                            size: 18
                                        })
                                    ],
                                    spacing: { after: 200}
                                })
                            ],
                            rowSpan: 1,
                            columnSpan: 1,
                            verticalAlign: VerticalAlign.CENTER
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: 'Si Aplica',
                                            font: 'Calibri (Body)',
                                            size: 18
                                        })
                                    ],
                                    spacing: { after: 200}
                                })
                            ],
                            rowSpan: 1,
                            columnSpan: 1,
                            verticalAlign: VerticalAlign.CENTER
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: '',
                                            font: 'Calibri (Body)',
                                            size: 18
                                        })
                                    ],
                                    spacing: { after: 200}
                                })
                            ],
                            rowSpan: 1,
                            columnSpan: 1,
                            verticalAlign: VerticalAlign.CENTER
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: '',
                                            font: 'Calibri (Body)',
                                            size: 18
                                        })
                                    ],
                                    spacing: { after: 200}
                                })
                            ],
                            rowSpan: 1,
                            columnSpan: 1,
                            verticalAlign: VerticalAlign.CENTER
                        })
                    ]
                }),
                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: 'Solicitud de periodo de adaptación de instalaciones ganadería',
                                            font: 'Calibri (Body)',
                                            size: 18
                                        })
                                    ]
                                }),
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: 'MTO-FSOL-12',
                                            font: 'Calibri (Body)',
                                            size: 18
                                        })
                                    ],
                                    spacing: { after: 200}
                                })
                            ],
                            rowSpan: 1,
                            columnSpan: 4,
                            verticalAlign: VerticalAlign.CENTER
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: '',
                                            font: 'Calibri (Body)',
                                            size: 18
                                        })
                                    ],
                                    spacing: { after: 200}
                                })
                            ],
                            rowSpan: 1,
                            columnSpan: 1,
                            verticalAlign: VerticalAlign.CENTER
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: '',
                                            font: 'Calibri (Body)',
                                            size: 18
                                        })
                                    ],
                                    spacing: { after: 200}
                                })
                            ],
                            rowSpan: 1,
                            columnSpan: 1,
                            verticalAlign: VerticalAlign.CENTER
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: '',
                                            font: 'Calibri (Body)',
                                            size: 18
                                        })
                                    ],
                                    spacing: { after: 200}
                                })
                            ],
                            rowSpan: 1,
                            columnSpan: 1,
                            verticalAlign: VerticalAlign.CENTER
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: '',
                                            font: 'Calibri (Body)',
                                            size: 18
                                        })
                                    ],
                                    spacing: { after: 200}
                                })
                            ],
                            rowSpan: 1,
                            columnSpan: 1,
                            verticalAlign: VerticalAlign.CENTER
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: 'Si Aplica',
                                            font: 'Calibri (Body)',
                                            size: 18
                                        })
                                    ],
                                    spacing: { after: 200}
                                })
                            ],
                            rowSpan: 1,
                            columnSpan: 1,
                            verticalAlign: VerticalAlign.CENTER
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: 'Si Aplica',
                                            font: 'Calibri (Body)',
                                            size: 18
                                        })
                                    ],
                                    spacing: { after: 200}
                                })
                            ],
                            rowSpan: 1,
                            columnSpan: 1,
                            verticalAlign: VerticalAlign.CENTER
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: '',
                                            font: 'Calibri (Body)',
                                            size: 18
                                        })
                                    ],
                                    spacing: { after: 200}
                                })
                            ],
                            rowSpan: 1,
                            columnSpan: 1,
                            verticalAlign: VerticalAlign.CENTER
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: '',
                                            font: 'Calibri (Body)',
                                            size: 18
                                        })
                                    ],
                                    spacing: { after: 200}
                                })
                            ],
                            rowSpan: 1,
                            columnSpan: 1,
                            verticalAlign: VerticalAlign.CENTER
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: '',
                                            font: 'Calibri (Body)',
                                            size: 18
                                        })
                                    ],
                                    spacing: { after: 200}
                                })
                            ],
                            rowSpan: 1,
                            columnSpan: 1,
                            verticalAlign: VerticalAlign.CENTER
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: '',
                                            font: 'Calibri (Body)',
                                            size: 18
                                        })
                                    ],
                                    spacing: { after: 200}
                                })
                            ],
                            rowSpan: 1,
                            columnSpan: 1,
                            verticalAlign: VerticalAlign.CENTER
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: '',
                                            font: 'Calibri (Body)',
                                            size: 18
                                        })
                                    ],
                                    spacing: { after: 200}
                                })
                            ],
                            rowSpan: 1,
                            columnSpan: 1,
                            verticalAlign: VerticalAlign.CENTER
                        })
                    ]
                }),
                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: 'Solicitud de uso de alimento no orgánico',
                                            font: 'Calibri (Body)',
                                            size: 18
                                        })
                                    ]
                                }),
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: 'MTO-FSOL-13',
                                            font: 'Calibri (Body)',
                                            size: 18
                                        })
                                    ],
                                    spacing: { after: 200}
                                })
                            ],
                            rowSpan: 1,
                            columnSpan: 4,
                            verticalAlign: VerticalAlign.CENTER
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: '',
                                            font: 'Calibri (Body)',
                                            size: 18
                                        })
                                    ],
                                    spacing: { after: 200}
                                })
                            ],
                            rowSpan: 1,
                            columnSpan: 1,
                            verticalAlign: VerticalAlign.CENTER
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: '',
                                            font: 'Calibri (Body)',
                                            size: 18
                                        })
                                    ],
                                    spacing: { after: 200}
                                })
                            ],
                            rowSpan: 1,
                            columnSpan: 1,
                            verticalAlign: VerticalAlign.CENTER
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: '',
                                            font: 'Calibri (Body)',
                                            size: 18
                                        })
                                    ],
                                    spacing: { after: 200}
                                })
                            ],
                            rowSpan: 1,
                            columnSpan: 1,
                            verticalAlign: VerticalAlign.CENTER
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: '',
                                            font: 'Calibri (Body)',
                                            size: 18
                                        })
                                    ],
                                    spacing: { after: 200}
                                })
                            ],
                            rowSpan: 1,
                            columnSpan: 1,
                            verticalAlign: VerticalAlign.CENTER
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: 'Si Aplica',
                                            font: 'Calibri (Body)',
                                            size: 18
                                        })
                                    ],
                                    spacing: { after: 200}
                                })
                            ],
                            rowSpan: 1,
                            columnSpan: 1,
                            verticalAlign: VerticalAlign.CENTER
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: 'Si Aplica',
                                            font: 'Calibri (Body)',
                                            size: 18
                                        })
                                    ],
                                    spacing: { after: 200}
                                })
                            ],
                            rowSpan: 1,
                            columnSpan: 1,
                            verticalAlign: VerticalAlign.CENTER
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: '',
                                            font: 'Calibri (Body)',
                                            size: 18
                                        })
                                    ],
                                    spacing: { after: 200}
                                })
                            ],
                            rowSpan: 1,
                            columnSpan: 1,
                            verticalAlign: VerticalAlign.CENTER
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: 'Si Aplica',
                                            font: 'Calibri (Body)',
                                            size: 18
                                        })
                                    ],
                                    spacing: { after: 200}
                                })
                            ],
                            rowSpan: 1,
                            columnSpan: 1,
                            verticalAlign: VerticalAlign.CENTER
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: '',
                                            font: 'Calibri (Body)',
                                            size: 18
                                        })
                                    ],
                                    spacing: { after: 200}
                                })
                            ],
                            rowSpan: 1,
                            columnSpan: 1,
                            verticalAlign: VerticalAlign.CENTER
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: '',
                                            font: 'Calibri (Body)',
                                            size: 18
                                        })
                                    ],
                                    spacing: { after: 200}
                                })
                            ],
                            rowSpan: 1,
                            columnSpan: 1,
                            verticalAlign: VerticalAlign.CENTER
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: '',
                                            font: 'Calibri (Body)',
                                            size: 18
                                        })
                                    ],
                                    spacing: { after: 200}
                                })
                            ],
                            rowSpan: 1,
                            columnSpan: 1,
                            verticalAlign: VerticalAlign.CENTER
                        })
                    ]
                }),
                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: 'Solicitud de excepción para producción paralela',
                                            font: 'Calibri (Body)',
                                            size: 18
                                        })
                                    ]
                                }),
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: 'MTO-FSOL-14',
                                            font: 'Calibri (Body)',
                                            size: 18
                                        })
                                    ],
                                    spacing: { after: 200}
                                })
                            ],
                            rowSpan: 1,
                            columnSpan: 4,
                            verticalAlign: VerticalAlign.CENTER
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: 'Si Aplica',
                                            font: 'Calibri (Body)',
                                            size: 18
                                        })
                                    ],
                                    spacing: { after: 200}
                                })
                            ],
                            rowSpan: 1,
                            columnSpan: 1,
                            verticalAlign: VerticalAlign.CENTER
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: 'Si Aplica',
                                            font: 'Calibri (Body)',
                                            size: 18
                                        })
                                    ],
                                    spacing: { after: 200}
                                })
                            ],
                            rowSpan: 1,
                            columnSpan: 1,
                            verticalAlign: VerticalAlign.CENTER
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: 'Si Aplica',
                                            font: 'Calibri (Body)',
                                            size: 18
                                        })
                                    ],
                                    spacing: { after: 200}
                                })
                            ],
                            rowSpan: 1,
                            columnSpan: 1,
                            verticalAlign: VerticalAlign.CENTER
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: '',
                                            font: 'Calibri (Body)',
                                            size: 18
                                        })
                                    ],
                                    spacing: { after: 200}
                                })
                            ],
                            rowSpan: 1,
                            columnSpan: 1,
                            verticalAlign: VerticalAlign.CENTER
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: 'Si Aplica',
                                            font: 'Calibri (Body)',
                                            size: 18
                                        })
                                    ],
                                    spacing: { after: 200}
                                })
                            ],
                            rowSpan: 1,
                            columnSpan: 1,
                            verticalAlign: VerticalAlign.CENTER
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: 'Si Aplica',
                                            font: 'Calibri (Body)',
                                            size: 18
                                        })
                                    ],
                                    spacing: { after: 200}
                                })
                            ],
                            rowSpan: 1,
                            columnSpan: 1,
                            verticalAlign: VerticalAlign.CENTER
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: '',
                                            font: 'Calibri (Body)',
                                            size: 18
                                        })
                                    ],
                                    spacing: { after: 200}
                                })
                            ],
                            rowSpan: 1,
                            columnSpan: 1,
                            verticalAlign: VerticalAlign.CENTER
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: 'Si Aplica',
                                            font: 'Calibri (Body)',
                                            size: 18
                                        })
                                    ],
                                    spacing: { after: 200}
                                })
                            ],
                            rowSpan: 1,
                            columnSpan: 1,
                            verticalAlign: VerticalAlign.CENTER
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: 'Si Aplica',
                                            font: 'Calibri (Body)',
                                            size: 18
                                        })
                                    ],
                                    spacing: { after: 200}
                                })
                            ],
                            rowSpan: 1,
                            columnSpan: 1,
                            verticalAlign: VerticalAlign.CENTER
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: 'Si Aplica',
                                            font: 'Calibri (Body)',
                                            size: 18
                                        })
                                    ],
                                    spacing: { after: 200}
                                })
                            ],
                            rowSpan: 1,
                            columnSpan: 1,
                            verticalAlign: VerticalAlign.CENTER
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: 'Si Aplica',
                                            font: 'Calibri (Body)',
                                            size: 18
                                        })
                                    ],
                                    spacing: { after: 200}
                                })
                            ],
                            rowSpan: 1,
                            columnSpan: 1,
                            verticalAlign: VerticalAlign.CENTER
                        })
                    ]
                }),
                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: 'Lista de Verificación para la Recertificación LPO',
                                            font: 'Calibri (Body)',
                                            size: 18
                                        })
                                    ]
                                }),
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: 'MTO-FSOL-17',
                                            font: 'Calibri (Body)',
                                            size: 18
                                        })
                                    ],
                                    spacing: { after: 200}
                                })
                            ],
                            rowSpan: 1,
                            columnSpan: 4,
                            verticalAlign: VerticalAlign.CENTER
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: '',
                                            font: 'Calibri (Body)',
                                            size: 18
                                        })
                                    ],
                                    spacing: { after: 200}
                                })
                            ],
                            rowSpan: 1,
                            columnSpan: 1,
                            verticalAlign: VerticalAlign.CENTER
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: '',
                                            font: 'Calibri (Body)',
                                            size: 18
                                        })
                                    ],
                                    spacing: { after: 200}
                                })
                            ],
                            rowSpan: 1,
                            columnSpan: 1,
                            verticalAlign: VerticalAlign.CENTER
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: '',
                                            font: 'Calibri (Body)',
                                            size: 18
                                        })
                                    ],
                                    spacing: { after: 200}
                                })
                            ],
                            rowSpan: 1,
                            columnSpan: 1,
                            verticalAlign: VerticalAlign.CENTER
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: '',
                                            font: 'Calibri (Body)',
                                            size: 18
                                        })
                                    ],
                                    spacing: { after: 200}
                                })
                            ],
                            rowSpan: 1,
                            columnSpan: 1,
                            verticalAlign: VerticalAlign.CENTER
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: '',
                                            font: 'Calibri (Body)',
                                            size: 18
                                        })
                                    ],
                                    spacing: { after: 200}
                                })
                            ],
                            rowSpan: 1,
                            columnSpan: 1,
                            verticalAlign: VerticalAlign.CENTER
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: '',
                                            font: 'Calibri (Body)',
                                            size: 18
                                        })
                                    ],
                                    spacing: { after: 200}
                                })
                            ],
                            rowSpan: 1,
                            columnSpan: 1,
                            verticalAlign: VerticalAlign.CENTER
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: '',
                                            font: 'Calibri (Body)',
                                            size: 18
                                        })
                                    ],
                                    spacing: { after: 200}
                                })
                            ],
                            rowSpan: 1,
                            columnSpan: 1,
                            verticalAlign: VerticalAlign.CENTER
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: '',
                                            font: 'Calibri (Body)',
                                            size: 18
                                        })
                                    ],
                                    spacing: { after: 200}
                                })
                            ],
                            rowSpan: 1,
                            columnSpan: 1,
                            verticalAlign: VerticalAlign.CENTER
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: '',
                                            font: 'Calibri (Body)',
                                            size: 18
                                        })
                                    ],
                                    spacing: { after: 200}
                                })
                            ],
                            rowSpan: 1,
                            columnSpan: 1,
                            verticalAlign: VerticalAlign.CENTER
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: 'Si Aplica',
                                            font: 'Calibri (Body)',
                                            size: 18
                                        })
                                    ],
                                    spacing: { after: 200}
                                })
                            ],
                            rowSpan: 1,
                            columnSpan: 1,
                            verticalAlign: VerticalAlign.CENTER
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: 'Si Aplica',
                                            font: 'Calibri (Body)',
                                            size: 18
                                        })
                                    ],
                                    spacing: { after: 200}
                                })
                            ],
                            rowSpan: 1,
                            columnSpan: 1,
                            verticalAlign: VerticalAlign.CENTER
                        })
                    ]
                }),
                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: 'Tarifas Metrocert',
                                            font: 'Calibri (Body)',
                                            size: 18
                                        })
                                    ]
                                }),
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: 'MTO-FSOL-18',
                                            font: 'Calibri (Body)',
                                            size: 18
                                        })
                                    ],
                                    spacing: { after: 200}
                                })
                            ],
                            rowSpan: 1,
                            columnSpan: 4,
                            verticalAlign: VerticalAlign.CENTER
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: '✓',
                                            font: 'Calibri (Body)',
                                            size: 18
                                        })
                                    ],
                                    spacing: { after: 200}
                                })
                            ],
                            rowSpan: 1,
                            columnSpan: 1,
                            verticalAlign: VerticalAlign.CENTER
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: '✓',
                                            font: 'Calibri (Body)',
                                            size: 18
                                        })
                                    ],
                                    spacing: { after: 200}
                                })
                            ],
                            rowSpan: 1,
                            columnSpan: 1,
                            verticalAlign: VerticalAlign.CENTER
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: '✓',
                                            font: 'Calibri (Body)',
                                            size: 18
                                        })
                                    ],
                                    spacing: { after: 200}
                                })
                            ],
                            rowSpan: 1,
                            columnSpan: 1,
                            verticalAlign: VerticalAlign.CENTER
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: '✓',
                                            font: 'Calibri (Body)',
                                            size: 18
                                        })
                                    ],
                                    spacing: { after: 200}
                                })
                            ],
                            rowSpan: 1,
                            columnSpan: 1,
                            verticalAlign: VerticalAlign.CENTER
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: '✓',
                                            font: 'Calibri (Body)',
                                            size: 18
                                        })
                                    ],
                                    spacing: { after: 200}
                                })
                            ],
                            rowSpan: 1,
                            columnSpan: 1,
                            verticalAlign: VerticalAlign.CENTER
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: '✓',
                                            font: 'Calibri (Body)',
                                            size: 18
                                        })
                                    ],
                                    spacing: { after: 200}
                                })
                            ],
                            rowSpan: 1,
                            columnSpan: 1,
                            verticalAlign: VerticalAlign.CENTER
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: '✓',
                                            font: 'Calibri (Body)',
                                            size: 18
                                        })
                                    ],
                                    spacing: { after: 200}
                                })
                            ],
                            rowSpan: 1,
                            columnSpan: 1,
                            verticalAlign: VerticalAlign.CENTER
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: '✓',
                                            font: 'Calibri (Body)',
                                            size: 18
                                        })
                                    ],
                                    spacing: { after: 200}
                                })
                            ],
                            rowSpan: 1,
                            columnSpan: 1,
                            verticalAlign: VerticalAlign.CENTER
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: '✓',
                                            font: 'Calibri (Body)',
                                            size: 18
                                        })
                                    ],
                                    spacing: { after: 200}
                                })
                            ],
                            rowSpan: 1,
                            columnSpan: 1,
                            verticalAlign: VerticalAlign.CENTER
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: '✓',
                                            font: 'Calibri (Body)',
                                            size: 18
                                        })
                                    ],
                                    spacing: { after: 200}
                                })
                            ],
                            rowSpan: 1,
                            columnSpan: 1,
                            verticalAlign: VerticalAlign.CENTER
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: '✓',
                                            font: 'Calibri (Body)',
                                            size: 18
                                        })
                                    ],
                                    spacing: { after: 200}
                                })
                            ],
                            rowSpan: 1,
                            columnSpan: 1,
                            verticalAlign: VerticalAlign.CENTER
                        })
                    ]
                }),
                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: 'Cotización Nuevos Operadores / Cotización Renovación',
                                            font: 'Calibri (Body)',
                                            size: 18
                                        })
                                    ]
                                }),
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: 'MTO-FSOL-19',
                                            font: 'Calibri (Body)',
                                            size: 18
                                        })
                                    ],
                                    spacing: { after: 200}
                                })
                            ],
                            rowSpan: 1,
                            columnSpan: 4,
                            verticalAlign: VerticalAlign.CENTER
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: '✓',
                                            font: 'Calibri (Body)',
                                            size: 18
                                        })
                                    ],
                                    spacing: { after: 200}
                                })
                            ],
                            rowSpan: 1,
                            columnSpan: 1,
                            verticalAlign: VerticalAlign.CENTER
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: '✓',
                                            font: 'Calibri (Body)',
                                            size: 18
                                        })
                                    ],
                                    spacing: { after: 200}
                                })
                            ],
                            rowSpan: 1,
                            columnSpan: 1,
                            verticalAlign: VerticalAlign.CENTER
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: '✓',
                                            font: 'Calibri (Body)',
                                            size: 18
                                        })
                                    ],
                                    spacing: { after: 200}
                                })
                            ],
                            rowSpan: 1,
                            columnSpan: 1,
                            verticalAlign: VerticalAlign.CENTER
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: '✓',
                                            font: 'Calibri (Body)',
                                            size: 18
                                        })
                                    ],
                                    spacing: { after: 200}
                                })
                            ],
                            rowSpan: 1,
                            columnSpan: 1,
                            verticalAlign: VerticalAlign.CENTER
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: '✓',
                                            font: 'Calibri (Body)',
                                            size: 18
                                        })
                                    ],
                                    spacing: { after: 200}
                                })
                            ],
                            rowSpan: 1,
                            columnSpan: 1,
                            verticalAlign: VerticalAlign.CENTER
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: '✓',
                                            font: 'Calibri (Body)',
                                            size: 18
                                        })
                                    ],
                                    spacing: { after: 200}
                                })
                            ],
                            rowSpan: 1,
                            columnSpan: 1,
                            verticalAlign: VerticalAlign.CENTER
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: '✓',
                                            font: 'Calibri (Body)',
                                            size: 18
                                        })
                                    ],
                                    spacing: { after: 200}
                                })
                            ],
                            rowSpan: 1,
                            columnSpan: 1,
                            verticalAlign: VerticalAlign.CENTER
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: '✓',
                                            font: 'Calibri (Body)',
                                            size: 18
                                        })
                                    ],
                                    spacing: { after: 200}
                                })
                            ],
                            rowSpan: 1,
                            columnSpan: 1,
                            verticalAlign: VerticalAlign.CENTER
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: '✓',
                                            font: 'Calibri (Body)',
                                            size: 18
                                        })
                                    ],
                                    spacing: { after: 200}
                                })
                            ],
                            rowSpan: 1,
                            columnSpan: 1,
                            verticalAlign: VerticalAlign.CENTER
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: '✓',
                                            font: 'Calibri (Body)',
                                            size: 18
                                        })
                                    ],
                                    spacing: { after: 200}
                                })
                            ],
                            rowSpan: 1,
                            columnSpan: 1,
                            verticalAlign: VerticalAlign.CENTER
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: '✓',
                                            font: 'Calibri (Body)',
                                            size: 18
                                        })
                                    ],
                                    spacing: { after: 200}
                                })
                            ],
                            rowSpan: 1,
                            columnSpan: 1,
                            verticalAlign: VerticalAlign.CENTER
                        })
                    ]
                }),
                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: '',
                                            font: 'Calibri (Body)',
                                            size: 18
                                        })
                                    ]
                                })
                            ],
                            rowSpan: 1,
                            columnSpan: 15,
                            verticalAlign: VerticalAlign.CENTER,
                            borders: {
                                top: {
                                    style: BorderStyle.NONE,
                                    color: "white",
                                    size: 0
                                },
                                bottom: {
                                    style: BorderStyle.NONE,
                                    color: "white",
                                    size: 0
                                },
                                left: {
                                    style: BorderStyle.NONE,
                                    color: "white",
                                    size: 0
                                },
                                right: {
                                    style: BorderStyle.NONE,
                                    color: "white",
                                    size: 0
                                }
                            }
                        })
                    ]
                }),
                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: 'Solicitud de reconstitución de colmenas',
                                            font: 'Calibri (Body)',
                                            size: 18
                                        })
                                    ]
                                }),
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: 'MTO-FSOL-21',
                                            font: 'Calibri (Body)',
                                            size: 18
                                        })
                                    ],
                                    spacing: { after: 200}
                                })
                            ],
                            rowSpan: 1,
                            columnSpan: 4,
                            verticalAlign: VerticalAlign.CENTER
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: '',
                                            font: 'Calibri (Body)',
                                            size: 18
                                        })
                                    ],
                                    spacing: { after: 200}
                                })
                            ],
                            rowSpan: 1,
                            columnSpan: 1,
                            verticalAlign: VerticalAlign.CENTER
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: '',
                                            font: 'Calibri (Body)',
                                            size: 18
                                        })
                                    ],
                                    spacing: { after: 200}
                                })
                            ],
                            rowSpan: 1,
                            columnSpan: 1,
                            verticalAlign: VerticalAlign.CENTER
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: '',
                                            font: 'Calibri (Body)',
                                            size: 18
                                        })
                                    ],
                                    spacing: { after: 200}
                                })
                            ],
                            rowSpan: 1,
                            columnSpan: 1,
                            verticalAlign: VerticalAlign.CENTER
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: '',
                                            font: 'Calibri (Body)',
                                            size: 18
                                        })
                                    ],
                                    spacing: { after: 200}
                                })
                            ],
                            rowSpan: 1,
                            columnSpan: 1,
                            verticalAlign: VerticalAlign.CENTER
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: '',
                                            font: 'Calibri (Body)',
                                            size: 18
                                        })
                                    ],
                                    spacing: { after: 200}
                                })
                            ],
                            rowSpan: 1,
                            columnSpan: 1,
                            verticalAlign: VerticalAlign.CENTER
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: '',
                                            font: 'Calibri (Body)',
                                            size: 18
                                        })
                                    ],
                                    spacing: { after: 200}
                                })
                            ],
                            rowSpan: 1,
                            columnSpan: 1,
                            verticalAlign: VerticalAlign.CENTER
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: 'Si Aplica',
                                            font: 'Calibri (Body)',
                                            size: 18
                                        })
                                    ],
                                    spacing: { after: 200}
                                })
                            ],
                            rowSpan: 1,
                            columnSpan: 1,
                            verticalAlign: VerticalAlign.CENTER
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: '',
                                            font: 'Calibri (Body)',
                                            size: 18
                                        })
                                    ],
                                    spacing: { after: 200}
                                })
                            ],
                            rowSpan: 1,
                            columnSpan: 1,
                            verticalAlign: VerticalAlign.CENTER
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: '',
                                            font: 'Calibri (Body)',
                                            size: 18
                                        })
                                    ],
                                    spacing: { after: 200}
                                })
                            ],
                            rowSpan: 1,
                            columnSpan: 1,
                            verticalAlign: VerticalAlign.CENTER
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: '',
                                            font: 'Calibri (Body)',
                                            size: 18
                                        })
                                    ],
                                    spacing: { after: 200}
                                })
                            ],
                            rowSpan: 1,
                            columnSpan: 1,
                            verticalAlign: VerticalAlign.CENTER
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: '',
                                            font: 'Calibri (Body)',
                                            size: 18
                                        })
                                    ],
                                    spacing: { after: 200}
                                })
                            ],
                            rowSpan: 1,
                            columnSpan: 1,
                            verticalAlign: VerticalAlign.CENTER
                        })
                    ]
                }),
                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: 'Declaración de Cumplimiento COR/USCOEA',
                                            font: 'Calibri (Body)',
                                            size: 18
                                        })
                                    ]
                                }),
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: 'MTO-FSOL-22',
                                            font: 'Calibri (Body)',
                                            size: 18
                                        })
                                    ],
                                    spacing: { after: 200}
                                })
                            ],
                            rowSpan: 1,
                            columnSpan: 4,
                            verticalAlign: VerticalAlign.CENTER
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: '',
                                            font: 'Calibri (Body)',
                                            size: 18
                                        })
                                    ],
                                    spacing: { after: 200}
                                })
                            ],
                            rowSpan: 1,
                            columnSpan: 1,
                            verticalAlign: VerticalAlign.CENTER
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: '',
                                            font: 'Calibri (Body)',
                                            size: 18
                                        })
                                    ],
                                    spacing: { after: 200}
                                })
                            ],
                            rowSpan: 1,
                            columnSpan: 1,
                            verticalAlign: VerticalAlign.CENTER
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: '',
                                            font: 'Calibri (Body)',
                                            size: 18
                                        })
                                    ],
                                    spacing: { after: 200}
                                })
                            ],
                            rowSpan: 1,
                            columnSpan: 1,
                            verticalAlign: VerticalAlign.CENTER
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: '',
                                            font: 'Calibri (Body)',
                                            size: 18
                                        })
                                    ],
                                    spacing: { after: 200}
                                })
                            ],
                            rowSpan: 1,
                            columnSpan: 1,
                            verticalAlign: VerticalAlign.CENTER
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: '',
                                            font: 'Calibri (Body)',
                                            size: 18
                                        })
                                    ],
                                    spacing: { after: 200}
                                })
                            ],
                            rowSpan: 1,
                            columnSpan: 1,
                            verticalAlign: VerticalAlign.CENTER
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: '',
                                            font: 'Calibri (Body)',
                                            size: 18
                                        })
                                    ],
                                    spacing: { after: 200}
                                })
                            ],
                            rowSpan: 1,
                            columnSpan: 1,
                            verticalAlign: VerticalAlign.CENTER
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: '',
                                            font: 'Calibri (Body)',
                                            size: 18
                                        })
                                    ],
                                    spacing: { after: 200}
                                })
                            ],
                            rowSpan: 1,
                            columnSpan: 1,
                            verticalAlign: VerticalAlign.CENTER
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: '',
                                            font: 'Calibri (Body)',
                                            size: 18
                                        })
                                    ],
                                    spacing: { after: 200}
                                })
                            ],
                            rowSpan: 1,
                            columnSpan: 1,
                            verticalAlign: VerticalAlign.CENTER
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: '',
                                            font: 'Calibri (Body)',
                                            size: 18
                                        })
                                    ],
                                    spacing: { after: 200}
                                })
                            ],
                            rowSpan: 1,
                            columnSpan: 1,
                            verticalAlign: VerticalAlign.CENTER
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: 'Si Aplica',
                                            font: 'Calibri (Body)',
                                            size: 18
                                        })
                                    ],
                                    spacing: { after: 200}
                                })
                            ],
                            rowSpan: 1,
                            columnSpan: 1,
                            verticalAlign: VerticalAlign.CENTER
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: 'Si Aplica',
                                            font: 'Calibri (Body)',
                                            size: 18
                                        })
                                    ],
                                    spacing: { after: 200}
                                })
                            ],
                            rowSpan: 1,
                            columnSpan: 1,
                            verticalAlign: VerticalAlign.CENTER
                        })
                    ]
                }),
                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: 'Lista de Proveedores',
                                            font: 'Calibri (Body)',
                                            size: 18
                                        })
                                    ]
                                }),
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: 'MTO-FSOL-23',
                                            font: 'Calibri (Body)',
                                            size: 18
                                        })
                                    ],
                                    spacing: { after: 200}
                                })
                            ],
                            rowSpan: 1,
                            columnSpan: 4,
                            verticalAlign: VerticalAlign.CENTER
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: '',
                                            font: 'Calibri (Body)',
                                            size: 18
                                        })
                                    ],
                                    spacing: { after: 200}
                                })
                            ],
                            rowSpan: 1,
                            columnSpan: 1,
                            verticalAlign: VerticalAlign.CENTER
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: '',
                                            font: 'Calibri (Body)',
                                            size: 18
                                        })
                                    ],
                                    spacing: { after: 200}
                                })
                            ],
                            rowSpan: 1,
                            columnSpan: 1,
                            verticalAlign: VerticalAlign.CENTER
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: '',
                                            font: 'Calibri (Body)',
                                            size: 18
                                        })
                                    ],
                                    spacing: { after: 200}
                                })
                            ],
                            rowSpan: 1,
                            columnSpan: 1,
                            verticalAlign: VerticalAlign.CENTER
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: '',
                                            font: 'Calibri (Body)',
                                            size: 18
                                        })
                                    ],
                                    spacing: { after: 200}
                                })
                            ],
                            rowSpan: 1,
                            columnSpan: 1,
                            verticalAlign: VerticalAlign.CENTER
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: '✓',
                                            font: 'Calibri (Body)',
                                            size: 18
                                        })
                                    ],
                                    spacing: { after: 200}
                                })
                            ],
                            rowSpan: 1,
                            columnSpan: 1,
                            verticalAlign: VerticalAlign.CENTER
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: '',
                                            font: 'Calibri (Body)',
                                            size: 18
                                        })
                                    ],
                                    spacing: { after: 200}
                                })
                            ],
                            rowSpan: 1,
                            columnSpan: 1,
                            verticalAlign: VerticalAlign.CENTER
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: '',
                                            font: 'Calibri (Body)',
                                            size: 18
                                        })
                                    ],
                                    spacing: { after: 200}
                                })
                            ],
                            rowSpan: 1,
                            columnSpan: 1,
                            verticalAlign: VerticalAlign.CENTER
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: '✓',
                                            font: 'Calibri (Body)',
                                            size: 18
                                        })
                                    ],
                                    spacing: { after: 200}
                                })
                            ],
                            rowSpan: 1,
                            columnSpan: 1,
                            verticalAlign: VerticalAlign.CENTER
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: '',
                                            font: 'Calibri (Body)',
                                            size: 18
                                        })
                                    ],
                                    spacing: { after: 200}
                                })
                            ],
                            rowSpan: 1,
                            columnSpan: 1,
                            verticalAlign: VerticalAlign.CENTER
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: '✓',
                                            font: 'Calibri (Body)',
                                            size: 18
                                        })
                                    ],
                                    spacing: { after: 200}
                                })
                            ],
                            rowSpan: 1,
                            columnSpan: 1,
                            verticalAlign: VerticalAlign.CENTER
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: '✓',
                                            font: 'Calibri (Body)',
                                            size: 18
                                        })
                                    ],
                                    spacing: { after: 200}
                                })
                            ],
                            rowSpan: 1,
                            columnSpan: 1,
                            verticalAlign: VerticalAlign.CENTER
                        })
                    ]
                }),
                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: 'Notificación de Procesamiento Paralelo',
                                            font: 'Calibri (Body)',
                                            size: 18
                                        })
                                    ]
                                }),
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: 'MTO-FSOL-24',
                                            font: 'Calibri (Body)',
                                            size: 18
                                        })
                                    ],
                                    spacing: { after: 200}
                                })
                            ],
                            rowSpan: 1,
                            columnSpan: 4,
                            verticalAlign: VerticalAlign.CENTER
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: '',
                                            font: 'Calibri (Body)',
                                            size: 18
                                        })
                                    ],
                                    spacing: { after: 200}
                                })
                            ],
                            rowSpan: 1,
                            columnSpan: 1,
                            verticalAlign: VerticalAlign.CENTER
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: '',
                                            font: 'Calibri (Body)',
                                            size: 18
                                        })
                                    ],
                                    spacing: { after: 200}
                                })
                            ],
                            rowSpan: 1,
                            columnSpan: 1,
                            verticalAlign: VerticalAlign.CENTER
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: '',
                                            font: 'Calibri (Body)',
                                            size: 18
                                        })
                                    ],
                                    spacing: { after: 200}
                                })
                            ],
                            rowSpan: 1,
                            columnSpan: 1,
                            verticalAlign: VerticalAlign.CENTER
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: '',
                                            font: 'Calibri (Body)',
                                            size: 18
                                        })
                                    ],
                                    spacing: { after: 200}
                                })
                            ],
                            rowSpan: 1,
                            columnSpan: 1,
                            verticalAlign: VerticalAlign.CENTER
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: '',
                                            font: 'Calibri (Body)',
                                            size: 18
                                        })
                                    ],
                                    spacing: { after: 200}
                                })
                            ],
                            rowSpan: 1,
                            columnSpan: 1,
                            verticalAlign: VerticalAlign.CENTER
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: '',
                                            font: 'Calibri (Body)',
                                            size: 18
                                        })
                                    ],
                                    spacing: { after: 200}
                                })
                            ],
                            rowSpan: 1,
                            columnSpan: 1,
                            verticalAlign: VerticalAlign.CENTER
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: '',
                                            font: 'Calibri (Body)',
                                            size: 18
                                        })
                                    ],
                                    spacing: { after: 200}
                                })
                            ],
                            rowSpan: 1,
                            columnSpan: 1,
                            verticalAlign: VerticalAlign.CENTER
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: '',
                                            font: 'Calibri (Body)',
                                            size: 18
                                        })
                                    ],
                                    spacing: { after: 200}
                                })
                            ],
                            rowSpan: 1,
                            columnSpan: 1,
                            verticalAlign: VerticalAlign.CENTER
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: '',
                                            font: 'Calibri (Body)',
                                            size: 18
                                        })
                                    ],
                                    spacing: { after: 200}
                                })
                            ],
                            rowSpan: 1,
                            columnSpan: 1,
                            verticalAlign: VerticalAlign.CENTER
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: 'Si Aplica',
                                            font: 'Calibri (Body)',
                                            size: 18
                                        })
                                    ],
                                    spacing: { after: 200}
                                })
                            ],
                            rowSpan: 1,
                            columnSpan: 1,
                            verticalAlign: VerticalAlign.CENTER
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: 'Si Aplica',
                                            font: 'Calibri (Body)',
                                            size: 18
                                        })
                                    ],
                                    spacing: { after: 200}
                                })
                            ],
                            rowSpan: 1,
                            columnSpan: 1,
                            verticalAlign: VerticalAlign.CENTER
                        })
                    ]
                }),
                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: 'Identificación del Titular / Representante legal',
                                            font: 'Calibri (Body)',
                                            size: 18
                                        })
                                    ],
                                    spacing: { after: 200}
                                })
                            ],
                            rowSpan: 1,
                            columnSpan: 4,
                            verticalAlign: VerticalAlign.CENTER
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: '✓',
                                            font: 'Calibri (Body)',
                                            size: 18
                                        })
                                    ],
                                    spacing: { after: 200}
                                })
                            ],
                            rowSpan: 1,
                            columnSpan: 1,
                            verticalAlign: VerticalAlign.CENTER
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: '✓',
                                            font: 'Calibri (Body)',
                                            size: 18
                                        })
                                    ],
                                    spacing: { after: 200}
                                })
                            ],
                            rowSpan: 1,
                            columnSpan: 1,
                            verticalAlign: VerticalAlign.CENTER
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: '✓',
                                            font: 'Calibri (Body)',
                                            size: 18
                                        })
                                    ],
                                    spacing: { after: 200}
                                })
                            ],
                            rowSpan: 1,
                            columnSpan: 1,
                            verticalAlign: VerticalAlign.CENTER
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: '✓',
                                            font: 'Calibri (Body)',
                                            size: 18
                                        })
                                    ],
                                    spacing: { after: 200}
                                })
                            ],
                            rowSpan: 1,
                            columnSpan: 1,
                            verticalAlign: VerticalAlign.CENTER
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: '✓',
                                            font: 'Calibri (Body)',
                                            size: 18
                                        })
                                    ],
                                    spacing: { after: 200}
                                })
                            ],
                            rowSpan: 1,
                            columnSpan: 1,
                            verticalAlign: VerticalAlign.CENTER
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: '✓',
                                            font: 'Calibri (Body)',
                                            size: 18
                                        })
                                    ],
                                    spacing: { after: 200}
                                })
                            ],
                            rowSpan: 1,
                            columnSpan: 1,
                            verticalAlign: VerticalAlign.CENTER
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: '✓',
                                            font: 'Calibri (Body)',
                                            size: 18
                                        })
                                    ],
                                    spacing: { after: 200}
                                })
                            ],
                            rowSpan: 1,
                            columnSpan: 1,
                            verticalAlign: VerticalAlign.CENTER
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: '✓',
                                            font: 'Calibri (Body)',
                                            size: 18
                                        })
                                    ],
                                    spacing: { after: 200}
                                })
                            ],
                            rowSpan: 1,
                            columnSpan: 1,
                            verticalAlign: VerticalAlign.CENTER
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: '✓',
                                            font: 'Calibri (Body)',
                                            size: 18
                                        })
                                    ],
                                    spacing: { after: 200}
                                })
                            ],
                            rowSpan: 1,
                            columnSpan: 1,
                            verticalAlign: VerticalAlign.CENTER
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: '✓',
                                            font: 'Calibri (Body)',
                                            size: 18
                                        })
                                    ],
                                    spacing: { after: 200}
                                })
                            ],
                            rowSpan: 1,
                            columnSpan: 1,
                            verticalAlign: VerticalAlign.CENTER
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: '✓',
                                            font: 'Calibri (Body)',
                                            size: 18
                                        })
                                    ],
                                    spacing: { after: 200}
                                })
                            ],
                            rowSpan: 1,
                            columnSpan: 1,
                            verticalAlign: VerticalAlign.CENTER
                        })
                    ]
                }),
                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: 'Acta Constitutiva',
                                            font: 'Calibri (Body)',
                                            size: 18
                                        })
                                    ],
                                    spacing: { after: 200}
                                })
                            ],
                            rowSpan: 1,
                            columnSpan: 4,
                            verticalAlign: VerticalAlign.CENTER
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: '✓',
                                            font: 'Calibri (Body)',
                                            size: 18
                                        })
                                    ],
                                    spacing: { after: 200}
                                })
                            ],
                            rowSpan: 1,
                            columnSpan: 1,
                            verticalAlign: VerticalAlign.CENTER
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: '✓',
                                            font: 'Calibri (Body)',
                                            size: 18
                                        })
                                    ],
                                    spacing: { after: 200}
                                })
                            ],
                            rowSpan: 1,
                            columnSpan: 1,
                            verticalAlign: VerticalAlign.CENTER
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: '✓',
                                            font: 'Calibri (Body)',
                                            size: 18
                                        })
                                    ],
                                    spacing: { after: 200}
                                })
                            ],
                            rowSpan: 1,
                            columnSpan: 1,
                            verticalAlign: VerticalAlign.CENTER
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: '✓',
                                            font: 'Calibri (Body)',
                                            size: 18
                                        })
                                    ],
                                    spacing: { after: 200}
                                })
                            ],
                            rowSpan: 1,
                            columnSpan: 1,
                            verticalAlign: VerticalAlign.CENTER
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: '✓',
                                            font: 'Calibri (Body)',
                                            size: 18
                                        })
                                    ],
                                    spacing: { after: 200}
                                })
                            ],
                            rowSpan: 1,
                            columnSpan: 1,
                            verticalAlign: VerticalAlign.CENTER
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: '✓',
                                            font: 'Calibri (Body)',
                                            size: 18
                                        })
                                    ],
                                    spacing: { after: 200}
                                })
                            ],
                            rowSpan: 1,
                            columnSpan: 1,
                            verticalAlign: VerticalAlign.CENTER
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: '✓',
                                            font: 'Calibri (Body)',
                                            size: 18
                                        })
                                    ],
                                    spacing: { after: 200}
                                })
                            ],
                            rowSpan: 1,
                            columnSpan: 1,
                            verticalAlign: VerticalAlign.CENTER
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: '✓',
                                            font: 'Calibri (Body)',
                                            size: 18
                                        })
                                    ],
                                    spacing: { after: 200}
                                })
                            ],
                            rowSpan: 1,
                            columnSpan: 1,
                            verticalAlign: VerticalAlign.CENTER
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: '✓',
                                            font: 'Calibri (Body)',
                                            size: 18
                                        })
                                    ],
                                    spacing: { after: 200}
                                })
                            ],
                            rowSpan: 1,
                            columnSpan: 1,
                            verticalAlign: VerticalAlign.CENTER
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: '✓',
                                            font: 'Calibri (Body)',
                                            size: 18
                                        })
                                    ],
                                    spacing: { after: 200}
                                })
                            ],
                            rowSpan: 1,
                            columnSpan: 1,
                            verticalAlign: VerticalAlign.CENTER
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: '✓',
                                            font: 'Calibri (Body)',
                                            size: 18
                                        })
                                    ],
                                    spacing: { after: 200}
                                })
                            ],
                            rowSpan: 1,
                            columnSpan: 1,
                            verticalAlign: VerticalAlign.CENTER
                        })
                    ]
                }),
                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: 'RFC del Titular o de la Empresa',
                                            font: 'Calibri (Body)',
                                            size: 18
                                        })
                                    ],
                                    spacing: { after: 200}
                                })
                            ],
                            rowSpan: 1,
                            columnSpan: 4,
                            verticalAlign: VerticalAlign.CENTER
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: '✓',
                                            font: 'Calibri (Body)',
                                            size: 18
                                        })
                                    ],
                                    spacing: { after: 200}
                                })
                            ],
                            rowSpan: 1,
                            columnSpan: 1,
                            verticalAlign: VerticalAlign.CENTER
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: '✓',
                                            font: 'Calibri (Body)',
                                            size: 18
                                        })
                                    ],
                                    spacing: { after: 200}
                                })
                            ],
                            rowSpan: 1,
                            columnSpan: 1,
                            verticalAlign: VerticalAlign.CENTER
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: '✓',
                                            font: 'Calibri (Body)',
                                            size: 18
                                        })
                                    ],
                                    spacing: { after: 200}
                                })
                            ],
                            rowSpan: 1,
                            columnSpan: 1,
                            verticalAlign: VerticalAlign.CENTER
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: '✓',
                                            font: 'Calibri (Body)',
                                            size: 18
                                        })
                                    ],
                                    spacing: { after: 200}
                                })
                            ],
                            rowSpan: 1,
                            columnSpan: 1,
                            verticalAlign: VerticalAlign.CENTER
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: '✓',
                                            font: 'Calibri (Body)',
                                            size: 18
                                        })
                                    ],
                                    spacing: { after: 200}
                                })
                            ],
                            rowSpan: 1,
                            columnSpan: 1,
                            verticalAlign: VerticalAlign.CENTER
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: '✓',
                                            font: 'Calibri (Body)',
                                            size: 18
                                        })
                                    ],
                                    spacing: { after: 200}
                                })
                            ],
                            rowSpan: 1,
                            columnSpan: 1,
                            verticalAlign: VerticalAlign.CENTER
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: '✓',
                                            font: 'Calibri (Body)',
                                            size: 18
                                        })
                                    ],
                                    spacing: { after: 200}
                                })
                            ],
                            rowSpan: 1,
                            columnSpan: 1,
                            verticalAlign: VerticalAlign.CENTER
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: '✓',
                                            font: 'Calibri (Body)',
                                            size: 18
                                        })
                                    ],
                                    spacing: { after: 200}
                                })
                            ],
                            rowSpan: 1,
                            columnSpan: 1,
                            verticalAlign: VerticalAlign.CENTER
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: '✓',
                                            font: 'Calibri (Body)',
                                            size: 18
                                        })
                                    ],
                                    spacing: { after: 200}
                                })
                            ],
                            rowSpan: 1,
                            columnSpan: 1,
                            verticalAlign: VerticalAlign.CENTER
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: '✓',
                                            font: 'Calibri (Body)',
                                            size: 18
                                        })
                                    ],
                                    spacing: { after: 200}
                                })
                            ],
                            rowSpan: 1,
                            columnSpan: 1,
                            verticalAlign: VerticalAlign.CENTER
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: '✓',
                                            font: 'Calibri (Body)',
                                            size: 18
                                        })
                                    ],
                                    spacing: { after: 200}
                                })
                            ],
                            rowSpan: 1,
                            columnSpan: 1,
                            verticalAlign: VerticalAlign.CENTER
                        })
                    ]
                }),
                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: 'Documento legal de propiedad de terrenos',
                                            font: 'Calibri (Body)',
                                            size: 18
                                        })
                                    ],
                                    spacing: { after: 200}
                                })
                            ],
                            rowSpan: 1,
                            columnSpan: 4,
                            verticalAlign: VerticalAlign.CENTER
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: '✓',
                                            font: 'Calibri (Body)',
                                            size: 18
                                        })
                                    ],
                                    spacing: { after: 200}
                                })
                            ],
                            rowSpan: 1,
                            columnSpan: 1,
                            verticalAlign: VerticalAlign.CENTER
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: '✓',
                                            font: 'Calibri (Body)',
                                            size: 18
                                        })
                                    ],
                                    spacing: { after: 200}
                                })
                            ],
                            rowSpan: 1,
                            columnSpan: 1,
                            verticalAlign: VerticalAlign.CENTER
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: '✓',
                                            font: 'Calibri (Body)',
                                            size: 18
                                        })
                                    ],
                                    spacing: { after: 200}
                                })
                            ],
                            rowSpan: 1,
                            columnSpan: 1,
                            verticalAlign: VerticalAlign.CENTER
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: '✓',
                                            font: 'Calibri (Body)',
                                            size: 18
                                        })
                                    ],
                                    spacing: { after: 200}
                                })
                            ],
                            rowSpan: 1,
                            columnSpan: 1,
                            verticalAlign: VerticalAlign.CENTER
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: '✓',
                                            font: 'Calibri (Body)',
                                            size: 18
                                        })
                                    ],
                                    spacing: { after: 200}
                                })
                            ],
                            rowSpan: 1,
                            columnSpan: 1,
                            verticalAlign: VerticalAlign.CENTER
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: '✓',
                                            font: 'Calibri (Body)',
                                            size: 18
                                        })
                                    ],
                                    spacing: { after: 200}
                                })
                            ],
                            rowSpan: 1,
                            columnSpan: 1,
                            verticalAlign: VerticalAlign.CENTER
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: '✓',
                                            font: 'Calibri (Body)',
                                            size: 18
                                        })
                                    ],
                                    spacing: { after: 200}
                                })
                            ],
                            rowSpan: 1,
                            columnSpan: 1,
                            verticalAlign: VerticalAlign.CENTER
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: '✓',
                                            font: 'Calibri (Body)',
                                            size: 18
                                        })
                                    ],
                                    spacing: { after: 200}
                                })
                            ],
                            rowSpan: 1,
                            columnSpan: 1,
                            verticalAlign: VerticalAlign.CENTER
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: '✓',
                                            font: 'Calibri (Body)',
                                            size: 18
                                        })
                                    ],
                                    spacing: { after: 200}
                                })
                            ],
                            rowSpan: 1,
                            columnSpan: 1,
                            verticalAlign: VerticalAlign.CENTER
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: '',
                                            font: 'Calibri (Body)',
                                            size: 18
                                        })
                                    ],
                                    spacing: { after: 200}
                                })
                            ],
                            rowSpan: 1,
                            columnSpan: 1,
                            verticalAlign: VerticalAlign.CENTER
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: '',
                                            font: 'Calibri (Body)',
                                            size: 18
                                        })
                                    ],
                                    spacing: { after: 200}
                                })
                            ],
                            rowSpan: 1,
                            columnSpan: 1,
                            verticalAlign: VerticalAlign.CENTER
                        })
                    ]
                })
            ],
            margins: {
                left: 70,
                right: 70
            },
            width: {
                size: 100,
                type: WidthType.PERCENTAGE,
            },

            //62604/15
            columnWidths: [ 4618, 4618, 4618, 4618, 4012, 4012, 4012, 4012, 4012, 4012, 4012, 4012, 4012, 4012, 4012],
            alignment: AlignmentType.CENTER
        })

        return table
    }

    // END MTO-FSOL-01-1
}