<div class="container" >
  <div class="row align-items-center text-center" style="height: 100vh;">
    <form class="form-recover-password-confirmation fade-in" [formGroup]="recoverForm" (ngSubmit)="onSubmit()">
      <img class="mb-4 fade-in" src="/assets/icons/ecert.png" alt="" width="72" height="72" />
      <h1 class="h1 mb-3 font-weight-normal">¡Listo!</h1>
      <h6 class="h6 mb-3 font-weight-normal">Si el email que ingresaste esta registrado, recibirás un correo
        electrónico con las instrucciones para recuperar tu cuenta.</h6>
      <div class="form-group fade-in mt-4">
        <button [disabled]="loading" class="btn btn-primary">
          <span
            *ngIf="loading"
            class="spinner-border spinner-border-sm mr-1"
          ></span>
          Volver a Inicio
        </button>
      </div>
    </form>
  </div>
</div>
<!-- <div style="background-color: #030303;" class="native-fixed fade-in">
  <a
    style="color: #ffffff;"
    class="native-link fade-in"
    href="https://digitalignition.com.mx/es/home/"
  >
    <div
      class="native-sponsor"
      style="background-color: #ffffff; color: #030303;"
    >
      En Desarrollo
    </div>
    <div class="native-text">
      Digital Ignition — El sistema se encuentra en fase de desarrollo por lo que algunas funcionalidades pueden no estar disponibles.
    </div>
  </a>
</div> -->