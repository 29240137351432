import { AlignmentType, Document, Paragraph, TextRun, Media, Header, Footer, Table, TableRow, TableCell, WidthType, PageNumber, ShadingType } from "docx";
import { logo } from "../logo";
import { DatePipe } from '@angular/common';

export class DocumentCreatorMTO_FINS_03_2_v1 {
    // INIT MTO_FINS_03_2
    public create_MTO_FINS_03_2(data): Document {
        const document = new Document();
        const image = Media.addImage(document, logo, 50, 50);
        var datePipe = new DatePipe('es-MX');
        document.addSection({
            headers: {
                default: new Header({
                    children: [
                        new Paragraph({ alignment: AlignmentType.CENTER, children: [image], }),
                        new Paragraph({
                            alignment: AlignmentType.CENTER, children: [
                                new TextRun({
                                    children: [`MTO-FSOL-03-2| R 02 | 23-Julio-2020 | Pág. `, PageNumber.CURRENT],
                                    font: 'Book Antiqua',
                                    size: 20,
                                    bold: true
                                }),
                                new TextRun({
                                    font: 'Book Antiqua',
                                    size: 20,
                                    bold: true,
                                    children: [' de ', PageNumber.TOTAL_PAGES]
                                }),
                            ], spacing: { before: 200, after: 100 }
                        })
                    ]
                })
            },
            footers: {
                default: new Footer({
                    children: [
                        new Paragraph({ alignment: AlignmentType.CENTER }),
                        this.createFooter()
                    ]
                }),
            },
            children: [
                new Paragraph({
                    alignment: AlignmentType.CENTER,
                    children: [
                        new TextRun({
                            text: 'REPORTE DE INSPECCIÓN PRODUCCIÓN ANIMAL',
                            font: 'Calibri (Body)',
                            size: 22,
                            bold: true
                        })
                    ],
                    spacing: { after: 300, before: 200 }
                }),
                this.createTableUDP(data, datePipe),
                new Paragraph({
                    spacing: { after: 200, before: 200 }
                }),
                this.createRegistros(data),
                new Paragraph({
                    spacing: { after: 200, before: 200 }
                }),
                this.createManejoPasturas(data),
                new Paragraph({
                    spacing: { after: 300, before: 200 }
                }),
                this.createManejoGanado(data),
                new Paragraph({
                    spacing: { after: 300, before: 200 }
                }),
                this.createInstalaciones(data),
                new Paragraph({
                    spacing: { after: 300, before: 200 }
                }),
                this.createProductosCertificados(data),
                new Paragraph({
                    alignment: AlignmentType.CENTER,
                    children: [
                        new TextRun({
                            text: 'RESUMEN DE HALLAZGOS',
                            font: 'Calibri (Body)',
                            size: 22,
                            bold: true
                        })
                    ],
                    spacing: { after: 300, before: 200 }
                }),
                this.createResumen(data),
                new Paragraph({
                    spacing: { after: 300, before: 200 }
                }),
                this.createResumenReunion(data),
                new Paragraph({
                    spacing: { after: 300, before: 200 }
                }),
                this.createComentariosOperador(data),
            ],
            margins: {
                top: 350,
                bottom: 500,
                left: 1000,
                right: 1000
            }
        });

        return document;
    }

    public createFooter(): Table {
        const table = new Table({
            rows: [
                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: 'Elaboró:',
                                            font: 'Calibri (Cuerpo)',
                                            size: 22
                                        })
                                    ]
                                })
                            ],
                            width: {
                                size: 33,
                                type: WidthType.PERCENTAGE
                            }
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: 'Revisó:',
                                            font: 'Calibri (Cuerpo)',
                                            size: 22
                                        })
                                    ]
                                })
                            ],
                            width: {
                                size: 33,
                                type: WidthType.PERCENTAGE
                            }
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: 'Autorizó:',
                                            font: 'Calibri (Cuerpo)',
                                            size: 22
                                        })
                                    ]
                                }),
                            ],
                            width: {
                                size: 33,
                                type: WidthType.PERCENTAGE
                            }
                        })
                    ]
                }),
                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: 'Director de certificación',
                                            font: 'Calibri (Cuerpo)',
                                            size: 16,
                                            bold: true
                                        })
                                    ]
                                }),

                            ],
                            width: {
                                size: 33,
                                type: WidthType.PERCENTAGE
                            },
                            shading: {
                                fill: '#C0C0C0'
                            }
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: 'Responsable de calidad',
                                            font: 'Calibri (Cuerpo)',
                                            size: 16,
                                            bold: true
                                        })
                                    ]
                                }),

                            ],
                            width: {
                                size: 33,
                                type: WidthType.PERCENTAGE
                            },
                            shading: {
                                fill: '#C0C0C0'
                            }
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: 'Director general',
                                            font: 'Calibri (Cuerpo)',
                                            size: 16,
                                            bold: true
                                        })
                                    ]
                                }),
                            ],
                            width: {
                                size: 33,
                                type: WidthType.PERCENTAGE
                            },
                            shading: {
                                fill: '#C0C0C0'
                            }
                        })
                    ]
                })
            ],
            margins: {
                left: 70,
                right: 70
            },
            columnWidths: [3213, 3213, 3212]
        })
        return table
    }

    public createTableUDP(data, datePipe): Table {
        const table = new Table({
            rows: [
                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: 'Código de operación:',
                                            font: 'Arial',
                                            size: 22,
                                            bold: true
                                        })
                                    ],
                                    spacing: { before: 100, after: 100 }
                                })
                            ],
                            columnSpan: 2
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: data.customer.Id_Comercial,
                                            font: 'Arial',
                                            size: 22
                                        })
                                    ],
                                    spacing: { before: 100, after: 100 }
                                })
                            ],
                            columnSpan: 2
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: 'Fecha:',
                                            font: 'Arial',
                                            size: 22,
                                            bold: true
                                        })
                                    ],
                                    spacing: { before: 100, after: 100 }
                                })
                            ],
                            columnSpan: 1
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: `${datePipe.transform(new Date(), 'd-MMMM-y')}`,
                                            font: 'Arial',
                                            size: 22
                                        })
                                    ],
                                    spacing: { before: 100, after: 100 }
                                })
                            ],
                            columnSpan: 2
                        })
                    ]
                }),
                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: 'Inspector:',
                                            font: 'Arial',
                                            size: 22,
                                            bold: true
                                        })
                                    ],
                                    spacing: { before: 100, after: 100 }
                                })
                            ],
                            columnSpan: 2
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: data.inspector,
                                            font: 'Arial',
                                            size: 22
                                        })
                                    ],
                                    spacing: { before: 100, after: 100 }
                                })
                            ],
                            columnSpan: 5
                        })
                    ]
                }),
                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: 'Operador:',
                                            font: 'Arial',
                                            size: 22,
                                            bold: true
                                        })
                                    ],
                                    spacing: { before: 100, after: 100 }
                                })
                            ],
                            columnSpan: 2
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: data.customer.Company_Name,
                                            font: 'Arial',
                                            size: 22
                                        })
                                    ],
                                    spacing: { before: 100, after: 100 }
                                })
                            ],
                            columnSpan: 5
                        })
                    ]
                }),
            ],
            margins: {
                left: 70,
                right: 70
            },
            width: {
                size: 100,
                type: WidthType.PERCENTAGE,
            },
            columnWidths: [1892,1892,1892,1892,1892,1892,1892]
        })

        const unidades = this.getValueList(data.submission, 'unidades_produccion')
        unidades.forEach( (unidad, index) => {
            table.addChildElement(
                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: `${index === 0 ? 'Unidades de producción evaluadas:' : ''}`,
                                            font: 'Arial',
                                            size: 22,
                                            bold: true
                                        })
                                    ],
                                    spacing: { before: 100, after: 100 }
                                })
                            ],
                            columnSpan: 2,
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: `Nombre: ${unidad['unidades_produccion_nombre']}`,
                                            font: 'Arial',
                                            size: 22,
                                            bold: true
                                        })
                                    ],
                                    spacing: { before: 100 }
                                }),
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: `Ubicación: ${unidad['unidades_produccion_ubicacion']}`,
                                            font: 'Arial',
                                            size: 22
                                        })
                                    ],
                                    spacing: { after: 100 }
                                }),
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: `Especie: ${unidad['unidades_produccion_especie']}`,
                                            font: 'Arial',
                                            size: 22
                                        })
                                    ],
                                    spacing: { after: 100 }
                                }),
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: `No. Unidades Ganaderas: ${unidad['unidades_produccion_unidades_ganaderas_total'] ? '■' : '❏'} Total: ${unidad['unidades_produccion_unidades_ganaderas_total']} ${unidad['unidades_produccion_unidades_ganaderas_organica'] ? '■' : '❏'}  Orgánica: ${unidad['unidades_produccion_unidades_ganaderas_organica']}  ${unidad['unidades_produccion_unidades_ganaderas_transicion'] ? '■' : '❏'}  En transición: ${unidad['unidades_produccion_unidades_ganaderas_transicion']}  ${unidad['unidades_produccion_unidades_ganaderas_convencional'] ? '■' : '❏'}  Convencional: ${unidad['unidades_produccion_unidades_ganaderas_convencional']}`,
                                            font: 'Arial',
                                            size: 22
                                        })
                                    ],
                                    spacing: { after: 100 }
                                }),
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: `Superficie: ${unidad['unidades_produccion_superficie_total'] ? '■' : '❏'} Total: ${unidad['unidades_produccion_superficie_total']} ${unidad['unidades_produccion_superficie_organica'] ? '■' : '❏'} Orgánica: ${unidad['unidades_produccion_superficie_organica']} ${unidad['unidades_produccion_superficie_transicion'] ? '■' : '❏'} En transición: ${unidad['unidades_produccion_superficie_transicion']} ${unidad['unidades_produccion_superficie_convencional'] ? '■' : '❏'} Convencional: ${unidad['unidades_produccion_superficie_convencional']}`,
                                            font: 'Arial',
                                            size: 22
                                        })
                                    ],
                                    spacing: { after: 100 }
                                })
                            ],
                            columnSpan: 5
                        })
                    ]
                })
            )
        })

        const start = new Date(`2020-01-01 ${this.getValue(data.submission, 'duracion_inspeccion_hora_inicio')}`)
        const end = new Date(`2020-01-01 ${this.getValue(data.submission, 'duracion_inspeccion_hora_fin')}`)

        const duration = end.getHours() - start.getHours()

        table.addChildElement(
            new TableRow({
                children: [
                    new TableCell({
                        children: [
                            new Paragraph({
                                alignment: AlignmentType.LEFT,
                                children: [
                                    new TextRun({
                                        text: `Duración de la inspección:`,
                                        font: 'Arial',
                                        size: 22,
                                        bold: true
                                    })
                                ],
                                spacing: { before: 100 }
                            })
                        ],
                        columnSpan: 1
                    }),
                    new TableCell({
                        children: [
                            new Paragraph({
                                alignment: AlignmentType.LEFT,
                                children: [
                                    new TextRun({
                                        text: `Hora de inicio`,
                                        font: 'Arial',
                                        size: 22,
                                        bold: true
                                    })
                                ],
                                spacing: { before: 100 }
                            })
                        ],
                        columnSpan: 1
                    }),
                    new TableCell({
                        children: [
                            new Paragraph({
                                alignment: AlignmentType.LEFT,
                                children: [
                                    new TextRun({
                                        text: this.getValue(data.submission, 'duracion_inspeccion_hora_inicio'),
                                        font: 'Arial',
                                        size: 22
                                    })
                                ],
                                spacing: { before: 100 }
                            })
                        ],
                        columnSpan: 1
                    }),
                    new TableCell({
                        children: [
                            new Paragraph({
                                alignment: AlignmentType.LEFT,
                                children: [
                                    new TextRun({
                                        text: `Hora de Terminación`,
                                        font: 'Arial',
                                        size: 22,
                                        bold: true
                                    })
                                ],
                                spacing: { before: 100 }
                            })
                        ],
                        columnSpan: 1
                    }),
                    new TableCell({
                        children: [
                            new Paragraph({
                                alignment: AlignmentType.LEFT,
                                children: [
                                    new TextRun({
                                        text: this.getValue(data.submission, 'duracion_inspeccion_hora_fin'),
                                        font: 'Arial',
                                        size: 22
                                    })
                                ],
                                spacing: { before: 100 }
                            })
                        ],
                        columnSpan: 1
                    }),
                    new TableCell({
                        children: [
                            new Paragraph({
                                alignment: AlignmentType.LEFT,
                                children: [
                                    new TextRun({
                                        text: `Duración:`,
                                        font: 'Arial',
                                        size: 22,
                                        bold: true
                                    })
                                ],
                                spacing: { before: 100 }
                            })
                        ],
                        columnSpan: 1
                    }),
                    new TableCell({
                        children: [
                            new Paragraph({
                                alignment: AlignmentType.LEFT,
                                children: [
                                    new TextRun({
                                        text: `${duration} hrs.`,
                                        font: 'Arial',
                                        size: 22
                                    })
                                ],
                                spacing: { before: 100 }
                            })
                        ],
                        columnSpan: 1
                    })
                ]
            }),
        )

        const persons = this.getValueList(data.submission, 'personas_entrevistadas')
        persons.forEach( (person, index) => {
            table.addChildElement(
                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: `${index === 0 ? 'Personas entrevistadas:' : ''}`,
                                            font: 'Arial',
                                            size: 22,
                                            bold: true
                                        })
                                    ],
                                    spacing: { before: 100 }
                                })
                            ],
                            columnSpan: 2
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: `Nombre: `,
                                            font: 'Arial',
                                            size: 22,
                                            bold: true
                                        })
                                    ],
                                    spacing: { before: 100 }
                                }),
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: person.personas_entrevistadas_nombre,
                                            font: 'Arial',
                                            size: 22
                                        })
                                    ],
                                    spacing: { after: 100 }
                                })
                            ],
                            columnSpan: 3
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: `Cargo: `,
                                            font: 'Arial',
                                            size: 22,
                                            bold: true
                                        })
                                    ],
                                    spacing: { before: 100 }
                                }),
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: person.personas_entrevistadas_cargo,
                                            font: 'Arial',
                                            size: 22
                                        })
                                    ],
                                    spacing: { after: 100 }
                                })
                            ],
                            columnSpan: 2
                        })
                    ]
                })
            )
        })

        return table
    }

    public createRegistros(data) {
        const table = new Table({
            rows: [
                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: 'REGISTROS',
                                            font: 'Calibri (Body)',
                                            size: 28,
                                            bold: true
                                        })
                                    ]
                                })
                            ],
                            shading: {
                                fill: '#C0C0C0',
                                val: ShadingType.PERCENT_80
                            },
                            columnSpan: 10
                        })
                    ]
                }),
                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: '1',
                                            font: 'Arial',
                                            size: 20
                                        })
                                    ],
                                    spacing: {
                                        after: 100,
                                        before: 100
                                    }
                                })
                            ],
                            columnSpan: 1
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: '¿El Plan de Manejo Orgánico refleja la realidad de la unidad de producción?',
                                            font: 'Arial',
                                            size: 20
                                        })
                                    ],
                                    spacing: {
                                        after: 100,
                                        before: 100
                                    }
                                })
                            ],
                            columnSpan: 7
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: `${this.getValue(data.submission, "registros_1_conformidad") === 'C' ? '■' : '❏'} C ${this.getValue(data.submission, "registros_1_conformidad") === 'NC' ? '■' : '❏'} NC ${this.getValue(data.submission, "registros_1_conformidad") === 'NA' ? '■' : '❏'} NA`,
                                            font: 'Arial',
                                            size: 20
                                        })
                                    ],
                                    spacing: {
                                        after: 100,
                                        before: 100
                                    }
                                })
                            ],
                            columnSpan: 2
                        })
                    ]
                }),
                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text:`Anexos: ${this.getFileList(this.getValue(data.submission, "registros_1_adjuntos_url"))}`,
                                            font: 'Arial',
                                            size: 20
                                        })
                                    ],
                                    spacing: {
                                        after: 100,
                                        before: 100
                                    }
                                })
                            ],
                            columnSpan: 10
                        }),
                    ]
                }),
                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text:`Explique: ${this.getValue(data.submission, "registros_1_pregunta")}`,
                                            font: 'Arial',
                                            size: 20
                                        })
                                    ],
                                    spacing: {
                                        after: 100,
                                        before: 100
                                    }
                                })
                            ],
                            columnSpan: 10
                        }),
                    ]
                }),
                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: '2',
                                            font: 'Arial',
                                            size: 20
                                        })
                                    ],
                                    spacing: {
                                        after: 100,
                                        before: 100
                                    }
                                })
                            ],
                            columnSpan: 1
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: '¿El operador cuenta con registros (bitácoras) que reflejan las actividades desarrolladas regularmente en la unidad de producción?',
                                            font: 'Arial',
                                            size: 20
                                        })
                                    ],
                                    spacing: {
                                        after: 100,
                                        before: 100
                                    }
                                })
                            ],
                            columnSpan: 7
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: `${this.getValue(data.submission, "registros_2_conformidad") === 'C' ? '■' : '❏'} C ${this.getValue(data.submission, "registros_2_conformidad") === 'NC' ? '■' : '❏'} NC ${this.getValue(data.submission, "registros_2_conformidad") === 'NA' ? '■' : '❏'} NA`,
                                            font: 'Arial',
                                            size: 20
                                        })
                                    ],
                                    spacing: {
                                        after: 100,
                                        before: 100
                                    }
                                })
                            ],
                            columnSpan: 2
                        })
                    ]
                }),
                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text:`Anexos: ${this.getFileList(this.getValue(data.submission, "registros_2_adjuntos_url"))}`,
                                            font: 'Arial',
                                            size: 20
                                        })
                                    ],
                                    spacing: {
                                        after: 100,
                                        before: 100
                                    }
                                })
                            ],
                            columnSpan: 10
                        }),
                    ]
                }),
                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: `Explique: ${this.getValue(data.submission, "registros_2_pregunta")}`,
                                            font: 'Arial',
                                            size: 20
                                        })
                                    ],
                                    spacing: {
                                        after: 100,
                                        before: 100
                                    }
                                })
                            ],
                            columnSpan: 10
                        }),
                    ]
                }),
                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: '3',
                                            font: 'Arial',
                                            size: 20
                                        })
                                    ],
                                    spacing: {
                                        after: 100,
                                        before: 100
                                    }
                                })
                            ],
                            columnSpan: 1
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: '¿Los registros (bitácoras) revisados, reflejan el uso de prácticas y sustancias permitidas?',
                                            font: 'Arial',
                                            size: 20
                                        })
                                    ],
                                    spacing: {
                                        after: 100,
                                        before: 100
                                    }
                                })
                            ],
                            columnSpan: 7
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: `${this.getValue(data.submission, "registros_3_conformidad") === 'C' ? '■' : '❏'} C ${this.getValue(data.submission, "registros_3_conformidad") === 'NC' ? '■' : '❏'} NC ${this.getValue(data.submission, "registros_3_conformidad") === 'NA' ? '■' : '❏'} NA`,
                                            font: 'Arial',
                                            size: 20
                                        })
                                    ],
                                    spacing: {
                                        after: 100,
                                        before: 100
                                    }
                                })
                            ],
                            columnSpan: 2
                        })
                    ]
                }),
                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text:`Anexos: ${this.getFileList(this.getValue(data.submission, "registros_3_adjuntos_url"))}`,
                                            font: 'Arial',
                                            size: 20
                                        })
                                    ],
                                    spacing: {
                                        after: 100,
                                        before: 100
                                    }
                                })
                            ],
                            columnSpan: 10
                        }),
                    ]
                }),
                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: `Explique: ${this.getValue(data.submission, "registros_3_pregunta")}`,
                                            font: 'Arial',
                                            size: 20
                                        })
                                    ],
                                    spacing: {
                                        after: 100,
                                        before: 100
                                    }
                                })
                            ],
                            columnSpan: 10
                        }),
                    ]
                }),
                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: '4',
                                            font: 'Arial',
                                            size: 20
                                        })
                                    ],
                                    spacing: {
                                        after: 100,
                                        before: 100
                                    }
                                })
                            ],
                            columnSpan: 1
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: '¿Las etiquetas, uso de logos y referencias a la calidad orgánica del producto son conformes?',
                                            font: 'Arial',
                                            size: 20
                                        })
                                    ],
                                    spacing: {
                                        after: 100,
                                        before: 100
                                    }
                                })
                            ],
                            columnSpan: 7
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: `${this.getValue(data.submission, "registros_4_conformidad") === 'C' ? '■' : '❏'} C ${this.getValue(data.submission, "registros_4_conformidad") === 'NC' ? '■' : '❏'} NC ${this.getValue(data.submission, "registros_4_conformidad") === 'NA' ? '■' : '❏'} NA`,
                                            font: 'Arial',
                                            size: 20
                                        })
                                    ],
                                    spacing: {
                                        after: 100,
                                        before: 100
                                    }
                                })
                            ],
                            columnSpan: 2
                        })
                    ]
                }),
                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text:`Anexos: ${this.getFileList(this.getValue(data.submission, "registros_4_adjuntos_url"))}`,
                                            font: 'Arial',
                                            size: 20
                                        })
                                    ],
                                    spacing: {
                                        after: 100,
                                        before: 100
                                    }
                                })
                            ],
                            columnSpan: 10
                        }),
                    ]
                }),
                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: `Explique: ${this.getValue(data.submission, "registros_4_pregunta")}`,
                                            font: 'Arial',
                                            size: 20
                                        })
                                    ],
                                    spacing: {
                                        after: 100,
                                        before: 100
                                    }
                                })
                            ],
                            columnSpan: 10
                        }),
                    ]
                }),
                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: '5',
                                            font: 'Arial',
                                            size: 20
                                        })
                                    ],
                                    spacing: {
                                        after: 100,
                                        before: 100
                                    }
                                })
                            ],
                            columnSpan: 1
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: '¿El operador tiene un registro completo de quejas y atención a las quejas?',
                                            font: 'Arial',
                                            size: 20
                                        })
                                    ],
                                    spacing: {
                                        after: 100,
                                        before: 100
                                    }
                                })
                            ],
                            columnSpan: 7
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: `${this.getValue(data.submission, "registros_5_conformidad") === 'C' ? '■' : '❏'} C ${this.getValue(data.submission, "registros_5_conformidad") === 'NC' ? '■' : '❏'} NC ${this.getValue(data.submission, "registros_5_conformidad") === 'NA' ? '■' : '❏'} NA`,
                                            font: 'Arial',
                                            size: 20
                                        })
                                    ],
                                    spacing: {
                                        after: 100,
                                        before: 100
                                    }
                                })
                            ],
                            columnSpan: 2
                        })
                    ]
                }),
                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text:`Anexos: ${this.getFileList(this.getValue(data.submission, "registros_5_adjuntos_url"))}`,
                                            font: 'Arial',
                                            size: 20
                                        })
                                    ],
                                    spacing: {
                                        after: 100,
                                        before: 100
                                    }
                                })
                            ],
                            columnSpan: 10
                        }),
                    ]
                }),
                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: `Explique: ${this.getValue(data.submission, "registros_5_pregunta")}`,
                                            font: 'Arial',
                                            size: 20
                                        })
                                    ],
                                    spacing: {
                                        after: 100,
                                        before: 100
                                    }
                                })
                            ],
                            columnSpan: 10
                        }),
                    ]
                }),
                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: '6',
                                            font: 'Arial',
                                            size: 20
                                        })
                                    ],
                                    spacing: {
                                        after: 100,
                                        before: 100
                                    }
                                })
                            ],
                            columnSpan: 1
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: '¿Los registros son conservados por al menos 5 años?',
                                            font: 'Arial',
                                            size: 20
                                        })
                                    ],
                                    spacing: {
                                        after: 100,
                                        before: 100
                                    }
                                })
                            ],
                            columnSpan: 7
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: `${this.getValue(data.submission, "registros_6_conformidad") === 'C' ? '■' : '❏'} C ${this.getValue(data.submission, "registros_6_conformidad") === 'NC' ? '■' : '❏'} NC ${this.getValue(data.submission, "registros_6_conformidad") === 'NA' ? '■' : '❏'} NA`,
                                            font: 'Arial',
                                            size: 20
                                        })
                                    ],
                                    spacing: {
                                        after: 100,
                                        before: 100
                                    }
                                })
                            ],
                            columnSpan: 2
                        })
                    ]
                }),
                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text:`Anexos: ${this.getFileList(this.getValue(data.submission, "registros_6_adjuntos_url"))}`,
                                            font: 'Arial',
                                            size: 20
                                        })
                                    ],
                                    spacing: {
                                        after: 100,
                                        before: 100
                                    }
                                })
                            ],
                            columnSpan: 10
                        }),
                    ]
                }),
                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: `Explique: ${this.getValue(data.submission, "registros_6_pregunta")}`,
                                            font: 'Arial',
                                            size: 20
                                        })
                                    ],
                                    spacing: {
                                        after: 100,
                                        before: 100
                                    }
                                })
                            ],
                            columnSpan: 10
                        }),
                    ]
                }),
                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: '7',
                                            font: 'Arial',
                                            size: 20
                                        })
                                    ],
                                    spacing: {
                                        after: 100,
                                        before: 100
                                    }
                                })
                            ],
                            columnSpan: 1
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: '¿Se dio seguimiento a las No Conformidades detectadas en la evaluación anterior?',
                                            font: 'Arial',
                                            size: 20
                                        })
                                    ],
                                    spacing: {
                                        after: 100,
                                        before: 100
                                    }
                                })
                            ],
                            columnSpan: 7
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: `${this.getValue(data.submission, "registros_7_conformidad") === 'C' ? '■' : '❏'} C ${this.getValue(data.submission, "registros_7_conformidad") === 'NC' ? '■' : '❏'} NC ${this.getValue(data.submission, "registros_7_conformidad") === 'NA' ? '■' : '❏'} NA`,
                                            font: 'Arial',
                                            size: 20
                                        })
                                    ],
                                    spacing: {
                                        after: 100,
                                        before: 100
                                    }
                                })
                            ],
                            columnSpan: 2
                        })
                    ]
                }),
                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text:`Anexos: ${this.getFileList(this.getValue(data.submission, "registros_7_adjuntos_url"))}`,
                                            font: 'Arial',
                                            size: 20
                                        })
                                    ],
                                    spacing: {
                                        after: 100,
                                        before: 100
                                    }
                                })
                            ],
                            columnSpan: 10
                        }),
                    ]
                }),
                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: '',
                                            font: 'Arial',
                                            size: 20
                                        })
                                    ]
                                }),
                                new Table({
                                    rows: [
                                        new TableRow({
                                            children: [
                                                new TableCell({
                                                    children: [
                                                        new Paragraph({
                                                            alignment: AlignmentType.CENTER,
                                                            children: [
                                                                new TextRun({
                                                                    text: 'No Conformidad',
                                                                    font: 'Arial',
                                                                    size: 20,
                                                                    bold: true
                                                                })
                                                            ]
                                                        })
                                                    ],
                                                    shading: {
                                                        fill: '#C0C0C0',
                                                        val: ShadingType.PERCENT_80
                                                    },
                                                    columnSpan: 4
                                                }),
                                                new TableCell({
                                                    children: [
                                                        new Paragraph({
                                                            alignment: AlignmentType.CENTER,
                                                            children: [
                                                                new TextRun({
                                                                    text: 'Acción correctiva propuesta',
                                                                    font: 'Arial',
                                                                    size: 20,
                                                                    bold: true
                                                                })
                                                            ]
                                                        })
                                                    ],
                                                    shading: {
                                                        fill: '#C0C0C0',
                                                        val: ShadingType.PERCENT_80
                                                    },
                                                    columnSpan: 4
                                                }),
                                                new TableCell({
                                                    children: [
                                                        new Paragraph({
                                                            alignment: AlignmentType.CENTER,
                                                            children: [
                                                                new TextRun({
                                                                    text: 'Situación encontrada',
                                                                    font: 'Arial',
                                                                    size: 20,
                                                                    bold: true
                                                                })
                                                            ]
                                                        })
                                                    ],
                                                    shading: {
                                                        fill: '#C0C0C0',
                                                        val: ShadingType.PERCENT_80
                                                    },
                                                    columnSpan: 4
                                                }),
                                            ]
                                        }),
                                        new TableRow({
                                            children: [
                                                new TableCell({
                                                    children: [
                                                        new Paragraph({
                                                            alignment: AlignmentType.LEFT,
                                                            children: [
                                                                new TextRun({
                                                                    text: ``,
                                                                    font: 'Arial',
                                                                    size: 20
                                                                })
                                                            ],
                                                            spacing: {
                                                                after: 100,
                                                                before: 100
                                                            }
                                                        })
                                                    ],
                                                    columnSpan: 4
                                                }),
                                                new TableCell({
                                                    children: [
                                                        new Paragraph({
                                                            alignment: AlignmentType.LEFT,
                                                            children: [
                                                                new TextRun({
                                                                    text: ``,
                                                                    font: 'Arial',
                                                                    size: 20
                                                                })
                                                            ],
                                                            spacing: {
                                                                after: 100,
                                                                before: 100
                                                            }
                                                        })
                                                    ],
                                                    columnSpan: 4
                                                }),
                                                new TableCell({
                                                    children: [
                                                        new Paragraph({
                                                            alignment: AlignmentType.LEFT,
                                                            children: [
                                                                new TextRun({
                                                                    text: ``,
                                                                    font: 'Arial',
                                                                    size: 20
                                                                })
                                                            ],
                                                            spacing: {
                                                                after: 100,
                                                                before: 100
                                                            }
                                                        })
                                                    ],
                                                    columnSpan: 4
                                                }),
                                            ]
                                        }),
                                        new TableRow({
                                            children: [
                                                new TableCell({
                                                    children: [
                                                        new Paragraph({
                                                            alignment: AlignmentType.LEFT,
                                                            children: [
                                                                new TextRun({
                                                                    text: ``,
                                                                    font: 'Arial',
                                                                    size: 20
                                                                })
                                                            ],
                                                            spacing: {
                                                                after: 100,
                                                                before: 100
                                                            }
                                                        })
                                                    ],
                                                    columnSpan: 4
                                                }),
                                                new TableCell({
                                                    children: [
                                                        new Paragraph({
                                                            alignment: AlignmentType.LEFT,
                                                            children: [
                                                                new TextRun({
                                                                    text: ``,
                                                                    font: 'Arial',
                                                                    size: 20
                                                                })
                                                            ],
                                                            spacing: {
                                                                after: 100,
                                                                before: 100
                                                            }
                                                        })
                                                    ],
                                                    columnSpan: 4
                                                }),
                                                new TableCell({
                                                    children: [
                                                        new Paragraph({
                                                            alignment: AlignmentType.LEFT,
                                                            children: [
                                                                new TextRun({
                                                                    text: ``,
                                                                    font: 'Arial',
                                                                    size: 20
                                                                })
                                                            ],
                                                            spacing: {
                                                                after: 100,
                                                                before: 100
                                                            }
                                                        })
                                                    ],
                                                    columnSpan: 4
                                                }),
                                            ]
                                        }),
                                        new TableRow({
                                            children: [
                                                new TableCell({
                                                    children: [
                                                        new Paragraph({
                                                            alignment: AlignmentType.LEFT,
                                                            children: [
                                                                new TextRun({
                                                                    text: ``,
                                                                    font: 'Arial',
                                                                    size: 20
                                                                })
                                                            ],
                                                            spacing: {
                                                                after: 100,
                                                                before: 100
                                                            }
                                                        })
                                                    ],
                                                    columnSpan: 4
                                                }),
                                                new TableCell({
                                                    children: [
                                                        new Paragraph({
                                                            alignment: AlignmentType.LEFT,
                                                            children: [
                                                                new TextRun({
                                                                    text: ``,
                                                                    font: 'Arial',
                                                                    size: 20
                                                                })
                                                            ],
                                                            spacing: {
                                                                after: 100,
                                                                before: 100
                                                            }
                                                        })
                                                    ],
                                                    columnSpan: 4
                                                }),
                                                new TableCell({
                                                    children: [
                                                        new Paragraph({
                                                            alignment: AlignmentType.LEFT,
                                                            children: [
                                                                new TextRun({
                                                                    text: ``,
                                                                    font: 'Arial',
                                                                    size: 20
                                                                })
                                                            ],
                                                            spacing: {
                                                                after: 100,
                                                                before: 100
                                                            }
                                                        })
                                                    ],
                                                    columnSpan: 4
                                                }),
                                            ]
                                        }),
                                        new TableRow({
                                            children: [
                                                new TableCell({
                                                    children: [
                                                        new Paragraph({
                                                            alignment: AlignmentType.LEFT,
                                                            children: [
                                                                new TextRun({
                                                                    text: ``,
                                                                    font: 'Arial',
                                                                    size: 20
                                                                })
                                                            ],
                                                            spacing: {
                                                                after: 100,
                                                                before: 100
                                                            }
                                                        })
                                                    ],
                                                    columnSpan: 4
                                                }),
                                                new TableCell({
                                                    children: [
                                                        new Paragraph({
                                                            alignment: AlignmentType.LEFT,
                                                            children: [
                                                                new TextRun({
                                                                    text: ``,
                                                                    font: 'Arial',
                                                                    size: 20
                                                                })
                                                            ],
                                                            spacing: {
                                                                after: 100,
                                                                before: 100
                                                            }
                                                        })
                                                    ],
                                                    columnSpan: 4
                                                }),
                                                new TableCell({
                                                    children: [
                                                        new Paragraph({
                                                            alignment: AlignmentType.LEFT,
                                                            children: [
                                                                new TextRun({
                                                                    text: ``,
                                                                    font: 'Arial',
                                                                    size: 20
                                                                })
                                                            ],
                                                            spacing: {
                                                                after: 100,
                                                                before: 100
                                                            }
                                                        })
                                                    ],
                                                    columnSpan: 4
                                                }),
                                            ]
                                        }),
                                    ],
                                    margins: {
                                        left: 70,
                                        right: 70
                                    },
                                    width: {
                                        size: 100,
                                        type: WidthType.PERCENTAGE
                                    },
                                    columnWidths: [500,1359,1359,1359,1359,1359,1359,1359,1200,1200]
                                }),
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: '',
                                            font: 'Arial',
                                            size: 20
                                        })
                                    ]
                                }),
                            ]
                        }),
                    ]
                }),
            ],
            margins: {
                left: 70,
                right: 70
            },
            width: {
                size: 100,
                type: WidthType.PERCENTAGE
            },
            columnWidths: [500,1359,1359,1359,1359,1359,1359,1359,1200,1200]
        })

        return table
    }

    public createManejoPasturas(data) {
        const table = new Table({
            rows: [
                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: 'MANEJO DE PASTURAS',
                                            font: 'Calibri (Body)',
                                            size: 28,
                                            bold: true
                                        })
                                    ]
                                })
                            ],
                            shading: {
                                fill: '#C0C0C0',
                                val: ShadingType.PERCENT_80
                            },
                            columnSpan: 10
                        })
                    ]
                }),
                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: '1',
                                            font: 'Arial',
                                            size: 20
                                        })
                                    ],
                                    spacing: {
                                        after: 100,
                                        before: 100
                                    }
                                })
                            ],
                            columnSpan: 1
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: '¿Las parcelas se han manejado sin uso de sustancias no permitidas al menos 3 años?',
                                            font: 'Arial',
                                            size: 20
                                        })
                                    ],
                                    spacing: {
                                        after: 100,
                                        before: 100
                                    }
                                })
                            ],
                            columnSpan: 7
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: `${this.getValue(data.submission, "manejo_pasturas_1_conformidad") === 'C' ? '■' : '❏'} C ${this.getValue(data.submission, "manejo_pasturas_1_conformidad") === 'NC' ? '■' : '❏'} NC ${this.getValue(data.submission, "manejo_pasturas_1_conformidad") === 'NA' ? '■' : '❏'} NA`,
                                            font: 'Arial',
                                            size: 20
                                        })
                                    ],
                                    spacing: {
                                        after: 100,
                                        before: 100
                                    }
                                })
                            ],
                            columnSpan: 2
                        })
                    ]
                }),
                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text:`Anexos: ${this.getFileList(this.getValue(data.submission, "manejo_pasturas_1_adjuntos_url"))}`,
                                            font: 'Arial',
                                            size: 20
                                        })
                                    ],
                                    spacing: {
                                        after: 100,
                                        before: 100
                                    }
                                })
                            ],
                            columnSpan: 10
                        }),
                    ]
                }),
                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: `Explique: ${this.getValue(data.submission, "manejo_pasturas_1_pregunta")}`,
                                            font: 'Arial',
                                            size: 20
                                        })
                                    ],
                                    spacing: {
                                        after: 100,
                                        before: 100
                                    }
                                })
                            ],
                            columnSpan: 10
                        }),
                    ]
                }),
                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: '2',
                                            font: 'Arial',
                                            size: 20
                                        })
                                    ],
                                    spacing: {
                                        after: 100,
                                        before: 100
                                    }
                                })
                            ],
                            columnSpan: 1
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: '¿Se tienen definidas prácticas para controlar los riesgos de contaminación de las parcelas con pasturas?',
                                            font: 'Arial',
                                            size: 20
                                        })
                                    ],
                                    spacing: {
                                        after: 100,
                                        before: 100
                                    }
                                })
                            ],
                            columnSpan: 7
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: `${this.getValue(data.submission, "manejo_pasturas_2_conformidad") === 'C' ? '■' : '❏'} C ${this.getValue(data.submission, "manejo_pasturas_2_conformidad") === 'NC' ? '■' : '❏'} NC ${this.getValue(data.submission, "manejo_pasturas_2_conformidad") === 'NA' ? '■' : '❏'} NA`,
                                            font: 'Arial',
                                            size: 20
                                        })
                                    ],
                                    spacing: {
                                        after: 100,
                                        before: 100
                                    }
                                })
                            ],
                            columnSpan: 2
                        })
                    ]
                }),
                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text:`Anexos: ${this.getFileList(this.getValue(data.submission, "manejo_pasturas_2_adjuntos_url"))}`,
                                            font: 'Arial',
                                            size: 20
                                        })
                                    ],
                                    spacing: {
                                        after: 100,
                                        before: 100
                                    }
                                })
                            ],
                            columnSpan: 10
                        }),
                    ]
                }),
                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: `Explique: ${this.getValue(data.submission, "manejo_pasturas_2_pregunta")}`,
                                            font: 'Arial',
                                            size: 20
                                        })
                                    ],
                                    spacing: {
                                        after: 100,
                                        before: 100
                                    }
                                })
                            ],
                            columnSpan: 10
                        }),
                    ]
                }),
                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: '3',
                                            font: 'Arial',
                                            size: 20
                                        })
                                    ],
                                    spacing: {
                                        after: 100,
                                        before: 100
                                    }
                                })
                            ],
                            columnSpan: 1
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: '¿Se tiene una clara separación de productos convencionales de los orgánicos (producción paralela)?',
                                            font: 'Arial',
                                            size: 20
                                        })
                                    ],
                                    spacing: {
                                        after: 100,
                                        before: 100
                                    }
                                })
                            ],
                            columnSpan: 7
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: `${this.getValue(data.submission, "manejo_pasturas_3_conformidad") === 'C' ? '■' : '❏'} C ${this.getValue(data.submission, "manejo_pasturas_3_conformidad") === 'NC' ? '■' : '❏'} NC ${this.getValue(data.submission, "manejo_pasturas_3_conformidad") === 'NA' ? '■' : '❏'} NA`,
                                            font: 'Arial',
                                            size: 20
                                        })
                                    ],
                                    spacing: {
                                        after: 100,
                                        before: 100
                                    }
                                })
                            ],
                            columnSpan: 2
                        })
                    ]
                }),
                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text:`Anexos: ${this.getFileList(this.getValue(data.submission, "manejo_pasturas_3_adjuntos_url"))}`,
                                            font: 'Arial',
                                            size: 20
                                        })
                                    ],
                                    spacing: {
                                        after: 100,
                                        before: 100
                                    }
                                })
                            ],
                            columnSpan: 10
                        }),
                    ]
                }),
                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: `Explique: ${this.getValue(data.submission, "manejo_pasturas_3_pregunta")}`,
                                            font: 'Arial',
                                            size: 20
                                        })
                                    ],
                                    spacing: {
                                        after: 100,
                                        before: 100
                                    }
                                })
                            ],
                            columnSpan: 10
                        }),
                    ]
                }),
                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: '4',
                                            font: 'Arial',
                                            size: 20
                                        })
                                    ],
                                    spacing: {
                                        after: 100,
                                        before: 100
                                    }
                                })
                            ],
                            columnSpan: 1
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: '¿El origen del material de propagación es orgánico o cumple con las excepciones?',
                                            font: 'Arial',
                                            size: 20
                                        })
                                    ],
                                    spacing: {
                                        after: 100,
                                        before: 100
                                    }
                                })
                            ],
                            columnSpan: 7
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: `${this.getValue(data.submission, "manejo_pasturas_4_conformidad") === 'C' ? '■' : '❏'} C ${this.getValue(data.submission, "manejo_pasturas_4_conformidad") === 'NC' ? '■' : '❏'} NC ${this.getValue(data.submission, "manejo_pasturas_4_conformidad") === 'NA' ? '■' : '❏'} NA`,
                                            font: 'Arial',
                                            size: 20
                                        })
                                    ],
                                    spacing: {
                                        after: 100,
                                        before: 100
                                    }
                                })
                            ],
                            columnSpan: 2
                        })
                    ]
                }),
                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text:`Anexos: ${this.getFileList(this.getValue(data.submission, "manejo_pasturas_4_adjuntos_url"))}`,
                                            font: 'Arial',
                                            size: 20
                                        })
                                    ],
                                    spacing: {
                                        after: 100,
                                        before: 100
                                    }
                                })
                            ],
                            columnSpan: 10
                        }),
                    ]
                }),
                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: `Explique: ${this.getValue(data.submission, "manejo_pasturas_4_pregunta")}`,
                                            font: 'Arial',
                                            size: 20
                                        })
                                    ],
                                    spacing: {
                                        after: 100,
                                        before: 100
                                    }
                                })
                            ],
                            columnSpan: 10
                        }),
                    ]
                }),
                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: '5',
                                            font: 'Arial',
                                            size: 20
                                        })
                                    ],
                                    spacing: {
                                        after: 100,
                                        before: 100
                                    }
                                })
                            ],
                            columnSpan: 1
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: '¿Se tienen adecuadas y suficientes actividades para mantener u mejorar la fertilidad del suelo?',
                                            font: 'Arial',
                                            size: 20
                                        })
                                    ],
                                    spacing: {
                                        after: 100,
                                        before: 100
                                    }
                                })
                            ],
                            columnSpan: 7
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: `${this.getValue(data.submission, "manejo_pasturas_5_conformidad") === 'C' ? '■' : '❏'} C ${this.getValue(data.submission, "manejo_pasturas_5_conformidad") === 'NC' ? '■' : '❏'} NC ${this.getValue(data.submission, "manejo_pasturas_5_conformidad") === 'NA' ? '■' : '❏'} NA`,
                                            font: 'Arial',
                                            size: 20
                                        })
                                    ],
                                    spacing: {
                                        after: 100,
                                        before: 100
                                    }
                                })
                            ],
                            columnSpan: 2
                        })
                    ]
                }),
                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text:`Anexos: ${this.getFileList(this.getValue(data.submission, "manejo_pasturas_5_adjuntos_url"))}`,
                                            font: 'Arial',
                                            size: 20
                                        })
                                    ],
                                    spacing: {
                                        after: 100,
                                        before: 100
                                    }
                                })
                            ],
                            columnSpan: 10
                        }),
                    ]
                }),
                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: `Explique: ${this.getValue(data.submission, "manejo_pasturas_5_pregunta")}`,
                                            font: 'Arial',
                                            size: 20
                                        })
                                    ],
                                    spacing: {
                                        after: 100,
                                        before: 100
                                    }
                                })
                            ],
                            columnSpan: 10
                        }),
                    ]
                }),
                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: '6',
                                            font: 'Arial',
                                            size: 20
                                        })
                                    ],
                                    spacing: {
                                        after: 100,
                                        before: 100
                                    }
                                })
                            ],
                            columnSpan: 1
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: '¿Se tiene un programa de rotación/asociación de cultivos?',
                                            font: 'Arial',
                                            size: 20
                                        })
                                    ],
                                    spacing: {
                                        after: 100,
                                        before: 100
                                    }
                                })
                            ],
                            columnSpan: 7
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: `${this.getValue(data.submission, "manejo_pasturas_6_conformidad") === 'C' ? '■' : '❏'} C ${this.getValue(data.submission, "manejo_pasturas_6_conformidad") === 'NC' ? '■' : '❏'} NC ${this.getValue(data.submission, "manejo_pasturas_6_conformidad") === 'NA' ? '■' : '❏'} NA`,
                                            font: 'Arial',
                                            size: 20
                                        })
                                    ],
                                    spacing: {
                                        after: 100,
                                        before: 100
                                    }
                                })
                            ],
                            columnSpan: 2
                        })
                    ]
                }),
                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text:`Anexos: ${this.getFileList(this.getValue(data.submission, "manejo_pasturas_6_adjuntos_url"))}`,
                                            font: 'Arial',
                                            size: 20
                                        })
                                    ],
                                    spacing: {
                                        after: 100,
                                        before: 100
                                    }
                                })
                            ],
                            columnSpan: 10
                        }),
                    ]
                }),
                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: `Explique: ${this.getValue(data.submission, "manejo_pasturas_6_pregunta")}`,
                                            font: 'Arial',
                                            size: 20
                                        })
                                    ],
                                    spacing: {
                                        after: 100,
                                        before: 100
                                    }
                                })
                            ],
                            columnSpan: 10
                        }),
                    ]
                }),
                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: '7',
                                            font: 'Arial',
                                            size: 20
                                        })
                                    ],
                                    spacing: {
                                        after: 100,
                                        before: 100
                                    }
                                })
                            ],
                            columnSpan: 1
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: '¿Se usan prácticas y sustancias permitidas para el abonado?',
                                            font: 'Arial',
                                            size: 20
                                        })
                                    ],
                                    spacing: {
                                        after: 100,
                                        before: 100
                                    }
                                })
                            ],
                            columnSpan: 7
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: `${this.getValue(data.submission, "manejo_pasturas_7_conformidad") === 'C' ? '■' : '❏'} C ${this.getValue(data.submission, "manejo_pasturas_7_conformidad") === 'NC' ? '■' : '❏'} NC ${this.getValue(data.submission, "manejo_pasturas_7_conformidad") === 'NA' ? '■' : '❏'} NA`,
                                            font: 'Arial',
                                            size: 20
                                        })
                                    ],
                                    spacing: {
                                        after: 100,
                                        before: 100
                                    }
                                })
                            ],
                            columnSpan: 2
                        })
                    ]
                }),
                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text:`Anexos: ${this.getFileList(this.getValue(data.submission, "manejo_pasturas_7_adjuntos_url"))}`,
                                            font: 'Arial',
                                            size: 20
                                        })
                                    ],
                                    spacing: {
                                        after: 100,
                                        before: 100
                                    }
                                })
                            ],
                            columnSpan: 10
                        }),
                    ]
                }),
                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: `Explique: ${this.getValue(data.submission, "manejo_pasturas_7_pregunta")}`,
                                            font: 'Arial',
                                            size: 20
                                        })
                                    ],
                                    spacing: {
                                        after: 100,
                                        before: 100
                                    }
                                })
                            ],
                            columnSpan: 10
                        }),
                    ]
                }),
                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: '8',
                                            font: 'Arial',
                                            size: 20
                                        })
                                    ],
                                    spacing: {
                                        after: 100,
                                        before: 100
                                    }
                                })
                            ],
                            columnSpan: 1
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: '¿Se usan prácticas y sustancias permitidas para el control de hierbas?',
                                            font: 'Arial',
                                            size: 20
                                        })
                                    ],
                                    spacing: {
                                        after: 100,
                                        before: 100
                                    }
                                })
                            ],
                            columnSpan: 7
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: `${this.getValue(data.submission, "manejo_pasturas_8_conformidad") === 'C' ? '■' : '❏'} C ${this.getValue(data.submission, "manejo_pasturas_8_conformidad") === 'NC' ? '■' : '❏'} NC ${this.getValue(data.submission, "manejo_pasturas_8_conformidad") === 'NA' ? '■' : '❏'} NA`,
                                            font: 'Arial',
                                            size: 20
                                        })
                                    ],
                                    spacing: {
                                        after: 100,
                                        before: 100
                                    }
                                })
                            ],
                            columnSpan: 2
                        })
                    ]
                }),
                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text:`Anexos: ${this.getFileList(this.getValue(data.submission, "manejo_pasturas_8_adjuntos_url"))}`,
                                            font: 'Arial',
                                            size: 20
                                        })
                                    ],
                                    spacing: {
                                        after: 100,
                                        before: 100
                                    }
                                })
                            ],
                            columnSpan: 10
                        }),
                    ]
                }),
                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: `Explique: ${this.getValue(data.submission, "manejo_pasturas_8_pregunta")}`,
                                            font: 'Arial',
                                            size: 20
                                        })
                                    ],
                                    spacing: {
                                        after: 100,
                                        before: 100
                                    }
                                })
                            ],
                            columnSpan: 10
                        }),
                    ]
                }),
                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: '9',
                                            font: 'Arial',
                                            size: 20
                                        })
                                    ],
                                    spacing: {
                                        after: 100,
                                        before: 100
                                    }
                                })
                            ],
                            columnSpan: 1
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: '¿Se usan prácticas y sustancias permitidas para el control de plagas y enfermedades?',
                                            font: 'Arial',
                                            size: 20
                                        })
                                    ],
                                    spacing: {
                                        after: 100,
                                        before: 100
                                    }
                                })
                            ],
                            columnSpan: 7
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: `${this.getValue(data.submission, "manejo_pasturas_9_conformidad") === 'C' ? '■' : '❏'} C ${this.getValue(data.submission, "manejo_pasturas_9_conformidad") === 'NC' ? '■' : '❏'} NC ${this.getValue(data.submission, "manejo_pasturas_9_conformidad") === 'NA' ? '■' : '❏'} NA`,
                                            font: 'Arial',
                                            size: 20
                                        })
                                    ],
                                    spacing: {
                                        after: 100,
                                        before: 100
                                    }
                                })
                            ],
                            columnSpan: 2
                        })
                    ]
                }),
                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text:`Anexos: ${this.getFileList(this.getValue(data.submission, "manejo_pasturas_9_adjuntos_url"))}`,
                                            font: 'Arial',
                                            size: 20
                                        })
                                    ],
                                    spacing: {
                                        after: 100,
                                        before: 100
                                    }
                                })
                            ],
                            columnSpan: 10
                        }),
                    ]
                }),
                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: `Explique: ${this.getValue(data.submission, "manejo_pasturas_9_pregunta")}`,
                                            font: 'Arial',
                                            size: 20
                                        })
                                    ],
                                    spacing: {
                                        after: 100,
                                        before: 100
                                    }
                                })
                            ],
                            columnSpan: 10
                        }),
                    ]
                }),
                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: '10',
                                            font: 'Arial',
                                            size: 20
                                        })
                                    ],
                                    spacing: {
                                        after: 100,
                                        before: 100
                                    }
                                })
                            ],
                            columnSpan: 1
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: '¿Las prácticas realizadas ayudan a la conservación de los recursos naturales?',
                                            font: 'Arial',
                                            size: 20
                                        })
                                    ],
                                    spacing: {
                                        after: 100,
                                        before: 100
                                    }
                                })
                            ],
                            columnSpan: 7
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: `${this.getValue(data.submission, "manejo_pasturas_10_conformidad") === 'C' ? '■' : '❏'} C ${this.getValue(data.submission, "manejo_pasturas_10_conformidad") === 'NC' ? '■' : '❏'} NC ${this.getValue(data.submission, "manejo_pasturas_10_conformidad") === 'NA' ? '■' : '❏'} NA`,
                                            font: 'Arial',
                                            size: 20
                                        })
                                    ],
                                    spacing: {
                                        after: 100,
                                        before: 100
                                    }
                                })
                            ],
                            columnSpan: 2
                        })
                    ]
                }),
                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text:`Anexos: ${this.getFileList(this.getValue(data.submission, "manejo_pasturas_10_adjuntos_url"))}`,
                                            font: 'Arial',
                                            size: 20
                                        })
                                    ],
                                    spacing: {
                                        after: 100,
                                        before: 100
                                    }
                                })
                            ],
                            columnSpan: 10
                        }),
                    ]
                }),
                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: `Explique: ${this.getValue(data.submission, "manejo_pasturas_10_pregunta")}`,
                                            font: 'Arial',
                                            size: 20
                                        })
                                    ],
                                    spacing: {
                                        after: 100,
                                        before: 100
                                    }
                                })
                            ],
                            columnSpan: 10
                        }),
                    ]
                })
            ],
            margins: {
                left: 70,
                right: 70
            },
            width: {
                size: 100,
                type: WidthType.PERCENTAGE
            },
            columnWidths: [500,1359,1359,1359,1359,1359,1359,1359,1200,1200]
        })

        return table
    }

    public createManejoGanado(data) {
        const table = new Table({
            rows: [
                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: 'MANEJO DE GANADO',
                                            font: 'Calibri (Body)',
                                            size: 28,
                                            bold: true
                                        })
                                    ]
                                })
                            ],
                            shading: {
                                fill: '#C0C0C0',
                                val: ShadingType.PERCENT_80
                            },
                            columnSpan: 10
                        })
                    ]
                }),
                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: '1',
                                            font: 'Arial',
                                            size: 20
                                        })
                                    ],
                                    spacing: {
                                        after: 100,
                                        before: 100
                                    }
                                })
                            ],
                            columnSpan: 1
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: '¿La especie se ha manejado sin uso de sustancias no permitidas durante al menos el tiempo definido como periodo de conversión?',
                                            font: 'Arial',
                                            size: 20
                                        })
                                    ],
                                    spacing: {
                                        after: 100,
                                        before: 100
                                    }
                                })
                            ],
                            columnSpan: 7
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: `${this.getValue(data.submission, "manejo_ganado_1_conformidad") === 'C' ? '■' : '❏'} C ${this.getValue(data.submission, "manejo_ganado_1_conformidad") === 'NC' ? '■' : '❏'} NC ${this.getValue(data.submission, "manejo_ganado_1_conformidad") === 'NA' ? '■' : '❏'} NA`,
                                            font: 'Arial',
                                            size: 20
                                        })
                                    ],
                                    spacing: {
                                        after: 100,
                                        before: 100
                                    }
                                })
                            ],
                            columnSpan: 2
                        })
                    ]
                }),
                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text:`Anexos: ${this.getFileList(this.getValue(data.submission, "manejo_ganado_1_adjuntos_url"))}`,
                                            font: 'Arial',
                                            size: 20
                                        })
                                    ],
                                    spacing: {
                                        after: 100,
                                        before: 100
                                    }
                                })
                            ],
                            columnSpan: 10
                        }),
                    ]
                }),
                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: `Explique: ${this.getValue(data.submission, "manejo_ganado_1_pregunta")}`,
                                            font: 'Arial',
                                            size: 20
                                        })
                                    ],
                                    spacing: {
                                        after: 100,
                                        before: 100
                                    }
                                })
                            ],
                            columnSpan: 10
                        }),
                    ]
                }),
                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: '2',
                                            font: 'Arial',
                                            size: 20
                                        })
                                    ],
                                    spacing: {
                                        after: 100,
                                        before: 100
                                    }
                                })
                            ],
                            columnSpan: 1
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: '¿Se aplican prácticas para controlar los riesgos de contaminación en la unidad de producción pecuaria?',
                                            font: 'Arial',
                                            size: 20
                                        })
                                    ],
                                    spacing: {
                                        after: 100,
                                        before: 100
                                    }
                                })
                            ],
                            columnSpan: 7
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: `${this.getValue(data.submission, "manejo_ganado_2_conformidad") === 'C' ? '■' : '❏'} C ${this.getValue(data.submission, "manejo_ganado_2_conformidad") === 'NC' ? '■' : '❏'} NC ${this.getValue(data.submission, "manejo_ganado_2_conformidad") === 'NA' ? '■' : '❏'} NA`,
                                            font: 'Arial',
                                            size: 20
                                        })
                                    ],
                                    spacing: {
                                        after: 100,
                                        before: 100
                                    }
                                })
                            ],
                            columnSpan: 2
                        })
                    ]
                }),
                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text:`Anexos: ${this.getFileList(this.getValue(data.submission, "manejo_ganado_2_adjuntos_url"))}`,
                                            font: 'Arial',
                                            size: 20
                                        })
                                    ],
                                    spacing: {
                                        after: 100,
                                        before: 100
                                    }
                                })
                            ],
                            columnSpan: 10
                        }),
                    ]
                }),
                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: `Explique: ${this.getValue(data.submission, "manejo_ganado_2_pregunta")}`,
                                            font: 'Arial',
                                            size: 20
                                        })
                                    ],
                                    spacing: {
                                        after: 100,
                                        before: 100
                                    }
                                })
                            ],
                            columnSpan: 10
                        }),
                    ]
                }),
                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: '3',
                                            font: 'Arial',
                                            size: 20
                                        })
                                    ],
                                    spacing: {
                                        after: 100,
                                        before: 100
                                    }
                                })
                            ],
                            columnSpan: 1
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: '¿Se tiene una clara separación de productos convencionales de los orgánicos (producción paralela)?',
                                            font: 'Arial',
                                            size: 20
                                        })
                                    ],
                                    spacing: {
                                        after: 100,
                                        before: 100
                                    }
                                })
                            ],
                            columnSpan: 7
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: `${this.getValue(data.submission, "manejo_ganado_3_conformidad") === 'C' ? '■' : '❏'} C ${this.getValue(data.submission, "manejo_ganado_3_conformidad") === 'NC' ? '■' : '❏'} NC ${this.getValue(data.submission, "manejo_ganado_3_conformidad") === 'NA' ? '■' : '❏'} NA`,
                                            font: 'Arial',
                                            size: 20
                                        })
                                    ],
                                    spacing: {
                                        after: 100,
                                        before: 100
                                    }
                                })
                            ],
                            columnSpan: 2
                        })
                    ]
                }),
                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text:`Anexos: ${this.getFileList(this.getValue(data.submission, "manejo_ganado_3_adjuntos_url"))}`,
                                            font: 'Arial',
                                            size: 20
                                        })
                                    ],
                                    spacing: {
                                        after: 100,
                                        before: 100
                                    }
                                })
                            ],
                            columnSpan: 10
                        }),
                    ]
                }),
                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: `Explique: ${this.getValue(data.submission, "manejo_ganado_3_pregunta")}`,
                                            font: 'Arial',
                                            size: 20
                                        })
                                    ],
                                    spacing: {
                                        after: 100,
                                        before: 100
                                    }
                                })
                            ],
                            columnSpan: 10
                        }),
                    ]
                }),
                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: '4',
                                            font: 'Arial',
                                            size: 20
                                        })
                                    ],
                                    spacing: {
                                        after: 100,
                                        before: 100
                                    }
                                })
                            ],
                            columnSpan: 1
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: '¿El origen del ganado es orgánico o cumple con las excepciones?',
                                            font: 'Arial',
                                            size: 20
                                        })
                                    ],
                                    spacing: {
                                        after: 100,
                                        before: 100
                                    }
                                })
                            ],
                            columnSpan: 7
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: `${this.getValue(data.submission, "manejo_ganado_4_conformidad") === 'C' ? '■' : '❏'} C ${this.getValue(data.submission, "manejo_ganado_4_conformidad") === 'NC' ? '■' : '❏'} NC ${this.getValue(data.submission, "manejo_ganado_4_conformidad") === 'NA' ? '■' : '❏'} NA`,
                                            font: 'Arial',
                                            size: 20
                                        })
                                    ],
                                    spacing: {
                                        after: 100,
                                        before: 100
                                    }
                                })
                            ],
                            columnSpan: 2
                        })
                    ]
                }),
                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text:`Anexos: ${this.getFileList(this.getValue(data.submission, "manejo_ganado_4_adjuntos_url"))}`,
                                            font: 'Arial',
                                            size: 20
                                        })
                                    ],
                                    spacing: {
                                        after: 100,
                                        before: 100
                                    }
                                })
                            ],
                            columnSpan: 10
                        }),
                    ]
                }),
                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: `Explique: ${this.getValue(data.submission, "manejo_ganado_4_pregunta")}`,
                                            font: 'Arial',
                                            size: 20
                                        })
                                    ],
                                    spacing: {
                                        after: 100,
                                        before: 100
                                    }
                                })
                            ],
                            columnSpan: 10
                        }),
                    ]
                }),
                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: '5',
                                            font: 'Arial',
                                            size: 20
                                        })
                                    ],
                                    spacing: {
                                        after: 100,
                                        before: 100
                                    }
                                })
                            ],
                            columnSpan: 1
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: '¿Se utiliza alimento orgánico o permitido por la norma?',
                                            font: 'Arial',
                                            size: 20
                                        })
                                    ],
                                    spacing: {
                                        after: 100,
                                        before: 100
                                    }
                                })
                            ],
                            columnSpan: 7
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: `${this.getValue(data.submission, "manejo_ganado_5_conformidad") === 'C' ? '■' : '❏'} C ${this.getValue(data.submission, "manejo_ganado_5_conformidad") === 'NC' ? '■' : '❏'} NC ${this.getValue(data.submission, "manejo_ganado_5_conformidad") === 'NA' ? '■' : '❏'} NA`,
                                            font: 'Arial',
                                            size: 20
                                        })
                                    ],
                                    spacing: {
                                        after: 100,
                                        before: 100
                                    }
                                })
                            ],
                            columnSpan: 2
                        })
                    ]
                }),
                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text:`Anexos: ${this.getFileList(this.getValue(data.submission, "manejo_ganado_5_adjuntos_url"))}`,
                                            font: 'Arial',
                                            size: 20
                                        })
                                    ],
                                    spacing: {
                                        after: 100,
                                        before: 100
                                    }
                                })
                            ],
                            columnSpan: 10
                        }),
                    ]
                }),
                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: `Explique: ${this.getValue(data.submission, "manejo_ganado_5_pregunta")}`,
                                            font: 'Arial',
                                            size: 20
                                        })
                                    ],
                                    spacing: {
                                        after: 100,
                                        before: 100
                                    }
                                })
                            ],
                            columnSpan: 10
                        }),
                    ]
                }),
                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: '6',
                                            font: 'Arial',
                                            size: 20
                                        })
                                    ],
                                    spacing: {
                                        after: 100,
                                        before: 100
                                    }
                                })
                            ],
                            columnSpan: 1
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: '¿Se utilizan prácticas de manejo del ganado permitidas por la norma?',
                                            font: 'Arial',
                                            size: 20
                                        })
                                    ],
                                    spacing: {
                                        after: 100,
                                        before: 100
                                    }
                                })
                            ],
                            columnSpan: 7
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: `${this.getValue(data.submission, "manejo_ganado_6_conformidad") === 'C' ? '■' : '❏'} C ${this.getValue(data.submission, "manejo_ganado_6_conformidad") === 'NC' ? '■' : '❏'} NC ${this.getValue(data.submission, "manejo_ganado_6_conformidad") === 'NA' ? '■' : '❏'} NA`,
                                            font: 'Arial',
                                            size: 20
                                        })
                                    ],
                                    spacing: {
                                        after: 100,
                                        before: 100
                                    }
                                })
                            ],
                            columnSpan: 2
                        })
                    ]
                }),
                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text:`Anexos: ${this.getFileList(this.getValue(data.submission, "manejo_ganado_6_adjuntos_url"))}`,
                                            font: 'Arial',
                                            size: 20
                                        })
                                    ],
                                    spacing: {
                                        after: 100,
                                        before: 100
                                    }
                                })
                            ],
                            columnSpan: 10
                        }),
                    ]
                }),
                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: `Explique: ${this.getValue(data.submission, "manejo_ganado_6_pregunta")}`,
                                            font: 'Arial',
                                            size: 20
                                        })
                                    ],
                                    spacing: {
                                        after: 100,
                                        before: 100
                                    }
                                })
                            ],
                            columnSpan: 10
                        }),
                    ]
                }),
                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: '7',
                                            font: 'Arial',
                                            size: 20
                                        })
                                    ],
                                    spacing: {
                                        after: 100,
                                        before: 100
                                    }
                                })
                            ],
                            columnSpan: 1
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: '¿Se usan prácticas y sustancias permitidas para el control de enfermedades?',
                                            font: 'Arial',
                                            size: 20
                                        })
                                    ],
                                    spacing: {
                                        after: 100,
                                        before: 100
                                    }
                                })
                            ],
                            columnSpan: 7
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: `${this.getValue(data.submission, "manejo_ganado_7_conformidad") === 'C' ? '■' : '❏'} C ${this.getValue(data.submission, "manejo_ganado_7_conformidad") === 'NC' ? '■' : '❏'} NC ${this.getValue(data.submission, "manejo_ganado_7_conformidad") === 'NA' ? '■' : '❏'} NA`,
                                            font: 'Arial',
                                            size: 20
                                        })
                                    ],
                                    spacing: {
                                        after: 100,
                                        before: 100
                                    }
                                })
                            ],
                            columnSpan: 2
                        })
                    ]
                }),
                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text:`Anexos: ${this.getFileList(this.getValue(data.submission, "manejo_ganado_7_adjuntos_url"))}`,
                                            font: 'Arial',
                                            size: 20
                                        })
                                    ],
                                    spacing: {
                                        after: 100,
                                        before: 100
                                    }
                                })
                            ],
                            columnSpan: 10
                        }),
                    ]
                }),
                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: `Explique: ${this.getValue(data.submission, "manejo_ganado_7_pregunta")}`,
                                            font: 'Arial',
                                            size: 20
                                        })
                                    ],
                                    spacing: {
                                        after: 100,
                                        before: 100
                                    }
                                })
                            ],
                            columnSpan: 10
                        }),
                    ]
                }),
                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: '8',
                                            font: 'Arial',
                                            size: 20
                                        })
                                    ],
                                    spacing: {
                                        after: 100,
                                        before: 100
                                    }
                                })
                            ],
                            columnSpan: 1
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: '¿El transporte se realiza en conformidad con la LPO?',
                                            font: 'Arial',
                                            size: 20
                                        })
                                    ],
                                    spacing: {
                                        after: 100,
                                        before: 100
                                    }
                                })
                            ],
                            columnSpan: 7
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: `${this.getValue(data.submission, "manejo_ganado_8_conformidad") === 'C' ? '■' : '❏'} C ${this.getValue(data.submission, "manejo_ganado_8_conformidad") === 'NC' ? '■' : '❏'} NC ${this.getValue(data.submission, "manejo_ganado_8_conformidad") === 'NA' ? '■' : '❏'} NA`,
                                            font: 'Arial',
                                            size: 20
                                        })
                                    ],
                                    spacing: {
                                        after: 100,
                                        before: 100
                                    }
                                })
                            ],
                            columnSpan: 2
                        })
                    ]
                }),
                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text:`Anexos: ${this.getFileList(this.getValue(data.submission, "manejo_ganado_8_adjuntos_url"))}`,
                                            font: 'Arial',
                                            size: 20
                                        })
                                    ],
                                    spacing: {
                                        after: 100,
                                        before: 100
                                    }
                                })
                            ],
                            columnSpan: 10
                        }),
                    ]
                }),
                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: `Explique: ${this.getValue(data.submission, "manejo_ganado_8_pregunta")}`,
                                            font: 'Arial',
                                            size: 20
                                        })
                                    ],
                                    spacing: {
                                        after: 100,
                                        before: 100
                                    }
                                })
                            ],
                            columnSpan: 10
                        }),
                    ]
                }),
                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: '9',
                                            font: 'Arial',
                                            size: 20
                                        })
                                    ],
                                    spacing: {
                                        after: 100,
                                        before: 100
                                    }
                                })
                            ],
                            columnSpan: 1
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: '¿Se usan prácticas y sustancias permitidas en el sacrificio de la especie?',
                                            font: 'Arial',
                                            size: 20
                                        })
                                    ],
                                    spacing: {
                                        after: 100,
                                        before: 100
                                    }
                                })
                            ],
                            columnSpan: 7
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: `${this.getValue(data.submission, "manejo_ganado_9_conformidad") === 'C' ? '■' : '❏'} C ${this.getValue(data.submission, "manejo_ganado_9_conformidad") === 'NC' ? '■' : '❏'} NC ${this.getValue(data.submission, "manejo_ganado_9_conformidad") === 'NA' ? '■' : '❏'} NA`,
                                            font: 'Arial',
                                            size: 20
                                        })
                                    ],
                                    spacing: {
                                        after: 100,
                                        before: 100
                                    }
                                })
                            ],
                            columnSpan: 2
                        })
                    ]
                }),
                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text:`Anexos: ${this.getFileList(this.getValue(data.submission, "manejo_ganado_9_adjuntos_url"))}`,
                                            font: 'Arial',
                                            size: 20
                                        })
                                    ],
                                    spacing: {
                                        after: 100,
                                        before: 100
                                    }
                                })
                            ],
                            columnSpan: 10
                        }),
                    ]
                }),
                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: `Explique: ${this.getValue(data.submission, "manejo_ganado_9_pregunta")}`,
                                            font: 'Arial',
                                            size: 20
                                        })
                                    ],
                                    spacing: {
                                        after: 100,
                                        before: 100
                                    }
                                })
                            ],
                            columnSpan: 10
                        }),
                    ]
                })
            ],
            margins: {
                left: 70,
                right: 70
            },
            width: {
                size: 100,
                type: WidthType.PERCENTAGE
            },
            columnWidths: [500,1359,1359,1359,1359,1359,1359,1359,1200,1200]
        })

        return table
    }

    public createInstalaciones(data) {
        const table = new Table({
            rows: [
                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: 'INSTALACIONES',
                                            font: 'Calibri (Body)',
                                            size: 28,
                                            bold: true
                                        })
                                    ]
                                })
                            ],
                            shading: {
                                fill: '#C0C0C0',
                                val: ShadingType.PERCENT_80
                            },
                            columnSpan: 10
                        })
                    ]
                }),
                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: '1',
                                            font: 'Arial',
                                            size: 20
                                        })
                                    ],
                                    spacing: {
                                        after: 100,
                                        before: 100
                                    }
                                })
                            ],
                            columnSpan: 1
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: '¿La densidad de animales en zonas cubiertas y al aire libre, son conformes?',
                                            font: 'Arial',
                                            size: 20
                                        })
                                    ],
                                    spacing: {
                                        after: 100,
                                        before: 100
                                    }
                                })
                            ],
                            columnSpan: 7
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: `${this.getValue(data.submission, "instalaciones_1_conformidad") === 'C' ? '■' : '❏'} C ${this.getValue(data.submission, "instalaciones_1_conformidad") === 'NC' ? '■' : '❏'} NC ${this.getValue(data.submission, "instalaciones_1_conformidad") === 'NA' ? '■' : '❏'} NA`,
                                            font: 'Arial',
                                            size: 20
                                        })
                                    ],
                                    spacing: {
                                        after: 100,
                                        before: 100
                                    }
                                })
                            ],
                            columnSpan: 2
                        })
                    ]
                }),
                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text:`Anexos: ${this.getFileList(this.getValue(data.submission, "instalaciones_1_adjuntos_url"))}`,
                                            font: 'Arial',
                                            size: 20
                                        })
                                    ],
                                    spacing: {
                                        after: 100,
                                        before: 100
                                    }
                                })
                            ],
                            columnSpan: 10
                        }),
                    ]
                }),
                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: `Explique: ${this.getValue(data.submission, "instalaciones_1_pregunta")}`,
                                            font: 'Arial',
                                            size: 20
                                        })
                                    ],
                                    spacing: {
                                        after: 100,
                                        before: 100
                                    }
                                })
                            ],
                            columnSpan: 10
                        }),
                    ]
                }),
                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: '2',
                                            font: 'Arial',
                                            size: 20
                                        })
                                    ],
                                    spacing: {
                                        after: 100,
                                        before: 100
                                    }
                                })
                            ],
                            columnSpan: 1
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: '¿La carga ganadera se mantiene igual o por debajo de las 500 unidades de N/ha?',
                                            font: 'Arial',
                                            size: 20
                                        })
                                    ],
                                    spacing: {
                                        after: 100,
                                        before: 100
                                    }
                                })
                            ],
                            columnSpan: 7
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: `${this.getValue(data.submission, "instalaciones_2_conformidad") === 'C' ? '■' : '❏'} C ${this.getValue(data.submission, "instalaciones_2_conformidad") === 'NC' ? '■' : '❏'} NC ${this.getValue(data.submission, "instalaciones_2_conformidad") === 'NA' ? '■' : '❏'} NA`,
                                            font: 'Arial',
                                            size: 20
                                        })
                                    ],
                                    spacing: {
                                        after: 100,
                                        before: 100
                                    }
                                })
                            ],
                            columnSpan: 2
                        })
                    ]
                }),
                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text:`Anexos: ${this.getFileList(this.getValue(data.submission, "instalaciones_2_adjuntos_url"))}`,
                                            font: 'Arial',
                                            size: 20
                                        })
                                    ],
                                    spacing: {
                                        after: 100,
                                        before: 100
                                    }
                                })
                            ],
                            columnSpan: 10
                        }),
                    ]
                }),
                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: `Explique: ${this.getValue(data.submission, "instalaciones_2_pregunta")}`,
                                            font: 'Arial',
                                            size: 20
                                        })
                                    ],
                                    spacing: {
                                        after: 100,
                                        before: 100
                                    }
                                })
                            ],
                            columnSpan: 10
                        }),
                    ]
                }),
                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: '3',
                                            font: 'Arial',
                                            size: 20
                                        })
                                    ],
                                    spacing: {
                                        after: 100,
                                        before: 100
                                    }
                                })
                            ],
                            columnSpan: 1
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: '¿Si los animales se mantienen confinados, esto es conforme con la norma?',
                                            font: 'Arial',
                                            size: 20
                                        })
                                    ],
                                    spacing: {
                                        after: 100,
                                        before: 100
                                    }
                                })
                            ],
                            columnSpan: 7
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: `${this.getValue(data.submission, "instalaciones_3_conformidad") === 'C' ? '■' : '❏'} C ${this.getValue(data.submission, "instalaciones_3_conformidad") === 'NC' ? '■' : '❏'} NC ${this.getValue(data.submission, "instalaciones_3_conformidad") === 'NA' ? '■' : '❏'} NA`,
                                            font: 'Arial',
                                            size: 20
                                        })
                                    ],
                                    spacing: {
                                        after: 100,
                                        before: 100
                                    }
                                })
                            ],
                            columnSpan: 2
                        })
                    ]
                }),
                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text:`Anexos: ${this.getFileList(this.getValue(data.submission, "instalaciones_3_adjuntos_url"))}`,
                                            font: 'Arial',
                                            size: 20
                                        })
                                    ],
                                    spacing: {
                                        after: 100,
                                        before: 100
                                    }
                                })
                            ],
                            columnSpan: 10
                        }),
                    ]
                }),
                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: `Explique: ${this.getValue(data.submission, "instalaciones_3_pregunta")}`,
                                            font: 'Arial',
                                            size: 20
                                        })
                                    ],
                                    spacing: {
                                        after: 100,
                                        before: 100
                                    }
                                })
                            ],
                            columnSpan: 10
                        }),
                    ]
                }),
                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: '4',
                                            font: 'Arial',
                                            size: 20
                                        })
                                    ],
                                    spacing: {
                                        after: 100,
                                        before: 100
                                    }
                                })
                            ],
                            columnSpan: 1
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: '¿Se dispone de las instalaciones para alimentación, descanso, ejercicio, y bienestar en general?',
                                            font: 'Arial',
                                            size: 20
                                        })
                                    ],
                                    spacing: {
                                        after: 100,
                                        before: 100
                                    }
                                })
                            ],
                            columnSpan: 7
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: `${this.getValue(data.submission, "instalaciones_4_conformidad") === 'C' ? '■' : '❏'} C ${this.getValue(data.submission, "instalaciones_4_conformidad") === 'NC' ? '■' : '❏'} NC ${this.getValue(data.submission, "instalaciones_4_conformidad") === 'NA' ? '■' : '❏'} NA`,
                                            font: 'Arial',
                                            size: 20
                                        })
                                    ],
                                    spacing: {
                                        after: 100,
                                        before: 100
                                    }
                                })
                            ],
                            columnSpan: 2
                        })
                    ]
                }),
                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text:`Anexos: ${this.getFileList(this.getValue(data.submission, "instalaciones_4_adjuntos_url"))}`,
                                            font: 'Arial',
                                            size: 20
                                        })
                                    ],
                                    spacing: {
                                        after: 100,
                                        before: 100
                                    }
                                })
                            ],
                            columnSpan: 10
                        }),
                    ]
                }),
                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: `Explique: ${this.getValue(data.submission, "instalaciones_4_pregunta")}`,
                                            font: 'Arial',
                                            size: 20
                                        })
                                    ],
                                    spacing: {
                                        after: 100,
                                        before: 100
                                    }
                                })
                            ],
                            columnSpan: 10
                        }),
                    ]
                }),
                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: '',
                                            font: 'Arial',
                                            size: 20
                                        })
                                    ],
                                    spacing: {
                                        after: 100,
                                        before: 100
                                    }
                                })
                            ],
                            columnSpan: 1
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: '¿Las instalaciones se limpian con sustancias permitidas?',
                                            font: 'Arial',
                                            size: 20
                                        })
                                    ],
                                    spacing: {
                                        after: 100,
                                        before: 100
                                    }
                                })
                            ],
                            columnSpan: 7
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: `${this.getValue(data.submission, "instalaciones_5_conformidad") === 'C' ? '■' : '❏'} C ${this.getValue(data.submission, "instalaciones_5_conformidad") === 'NC' ? '■' : '❏'} NC ${this.getValue(data.submission, "instalaciones_5_conformidad") === 'NA' ? '■' : '❏'} NA`,
                                            font: 'Arial',
                                            size: 20
                                        })
                                    ],
                                    spacing: {
                                        after: 100,
                                        before: 100
                                    }
                                })
                            ],
                            columnSpan: 2
                        })
                    ]
                }),
                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text:`Anexos: ${this.getFileList(this.getValue(data.submission, "instalaciones_5_adjuntos_url"))}`,
                                            font: 'Arial',
                                            size: 20
                                        })
                                    ],
                                    spacing: {
                                        after: 100,
                                        before: 100
                                    }
                                })
                            ],
                            columnSpan: 10
                        }),
                    ]
                }),
                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: `Explique: ${this.getValue(data.submission, "instalaciones_5_pregunta")}`,
                                            font: 'Arial',
                                            size: 20
                                        })
                                    ],
                                    spacing: {
                                        after: 100,
                                        before: 100
                                    }
                                })
                            ],
                            columnSpan: 10
                        }),
                    ]
                }),
                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: '5',
                                            font: 'Arial',
                                            size: 20
                                        })
                                    ],
                                    spacing: {
                                        after: 100,
                                        before: 100
                                    }
                                })
                            ],
                            columnSpan: 1
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: '¿El control de plagas en las instalaciones se realiza con métodos y sustancias permitidas?',
                                            font: 'Arial',
                                            size: 20
                                        })
                                    ],
                                    spacing: {
                                        after: 100,
                                        before: 100
                                    }
                                })
                            ],
                            columnSpan: 7
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: `${this.getValue(data.submission, "instalaciones_6_conformidad") === 'C' ? '■' : '❏'} C ${this.getValue(data.submission, "instalaciones_6_conformidad") === 'NC' ? '■' : '❏'} NC ${this.getValue(data.submission, "instalaciones_6_conformidad") === 'NA' ? '■' : '❏'} NA`,
                                            font: 'Arial',
                                            size: 20
                                        })
                                    ],
                                    spacing: {
                                        after: 100,
                                        before: 100
                                    }
                                })
                            ],
                            columnSpan: 2
                        })
                    ]
                }),
                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text:`Anexos: ${this.getFileList(this.getValue(data.submission, "instalaciones_6_adjuntos_url"))}`,
                                            font: 'Arial',
                                            size: 20
                                        })
                                    ],
                                    spacing: {
                                        after: 100,
                                        before: 100
                                    }
                                })
                            ],
                            columnSpan: 10
                        }),
                    ]
                }),
                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: `Explique: ${this.getValue(data.submission, "instalaciones_6_pregunta")}`,
                                            font: 'Arial',
                                            size: 20
                                        })
                                    ],
                                    spacing: {
                                        after: 100,
                                        before: 100
                                    }
                                })
                            ],
                            columnSpan: 10
                        }),
                    ]
                }),
                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: '6',
                                            font: 'Arial',
                                            size: 20
                                        })
                                    ],
                                    spacing: {
                                        after: 100,
                                        before: 100
                                    }
                                })
                            ],
                            columnSpan: 1
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: '¿El manejo de los residuos es conforme con lo requerido por la norma?',
                                            font: 'Arial',
                                            size: 20
                                        })
                                    ],
                                    spacing: {
                                        after: 100,
                                        before: 100
                                    }
                                })
                            ],
                            columnSpan: 7
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: `${this.getValue(data.submission, "instalaciones_7_conformidad") === 'C' ? '■' : '❏'} C ${this.getValue(data.submission, "instalaciones_7_conformidad") === 'NC' ? '■' : '❏'} NC ${this.getValue(data.submission, "instalaciones_7_conformidad") === 'NA' ? '■' : '❏'} NA`,
                                            font: 'Arial',
                                            size: 20
                                        })
                                    ],
                                    spacing: {
                                        after: 100,
                                        before: 100
                                    }
                                })
                            ],
                            columnSpan: 2
                        })
                    ]
                }),
                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text:`Anexos: ${this.getFileList(this.getValue(data.submission, "instalaciones_7_adjuntos_url"))}`,
                                            font: 'Arial',
                                            size: 20
                                        })
                                    ],
                                    spacing: {
                                        after: 100,
                                        before: 100
                                    }
                                })
                            ],
                            columnSpan: 10
                        }),
                    ]
                }),
                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: `Explique: ${this.getValue(data.submission, "instalaciones_7_pregunta")}`,
                                            font: 'Arial',
                                            size: 20
                                        })
                                    ],
                                    spacing: {
                                        after: 100,
                                        before: 100
                                    }
                                })
                            ],
                            columnSpan: 10
                        }),
                    ]
                })
            ],
            margins: {
                left: 70,
                right: 70
            },
            width: {
                size: 100,
                type: WidthType.PERCENTAGE
            },
            columnWidths: [500,1359,1359,1359,1359,1359,1359,1359,1200,1200]
        })

        return table
    }

    public createProductosCertificados(data) {
        const table = new Table({
            rows: [
                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: 'PRODUCTOS CERTIFICADOS',
                                            font: 'Calibri (Body)',
                                            size: 28,
                                            bold: true
                                        })
                                    ]
                                })
                            ],
                            columnSpan: 10
                        })
                    ]
                }),
                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: '1',
                                            font: 'Arial',
                                            size: 20
                                        })
                                    ],
                                    spacing: {
                                        after: 100,
                                        before: 100
                                    }
                                })
                            ],
                            columnSpan: 1
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: '¿Todos los productos comercializados como orgánicos fueron incluidos en la solicitud de certificación?',
                                            font: 'Arial',
                                            size: 20
                                        })
                                    ],
                                    spacing: {
                                        after: 100,
                                        before: 100
                                    }
                                })
                            ],
                            columnSpan: 7
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: `${this.getValue(data.submission, "productos_certificados_1_conformidad") === 'C' ? '■' : '❏'} C ${this.getValue(data.submission, "productos_certificados_1_conformidad") === 'NC' ? '■' : '❏'} NC ${this.getValue(data.submission, "productos_certificados_1_conformidad") === 'NA' ? '■' : '❏'} NA`,
                                            font: 'Arial',
                                            size: 20
                                        })
                                    ],
                                    spacing: {
                                        after: 100,
                                        before: 100
                                    }
                                })
                            ],
                            columnSpan: 2
                        })
                    ]
                }),
                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text:`Anexos: ${this.getFileList(this.getValue(data.submission, "productos_certificados_1_adjuntos_url"))}`,
                                            font: 'Arial',
                                            size: 20
                                        })
                                    ],
                                    spacing: {
                                        after: 100,
                                        before: 100
                                    }
                                })
                            ],
                            columnSpan: 10
                        }),
                    ]
                }),    
                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: `Explique: ${this.getValue(data.submission, "productos_certificados_1_pregunta")}`,
                                            font: 'Arial',
                                            size: 20
                                        })
                                    ],
                                    spacing: {
                                        after: 100,
                                        before: 100
                                    }
                                })
                            ],
                            columnSpan: 10
                        }),
                    ]
                }),
                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: '2',
                                            font: 'Arial',
                                            size: 20
                                        })
                                    ],
                                    spacing: {
                                        after: 100,
                                        before: 100
                                    }
                                })
                            ],
                            columnSpan: 1
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: '¿Fue posible realizar un balance de masas?',
                                            font: 'Arial',
                                            size: 20
                                        })
                                    ],
                                    spacing: {
                                        after: 100,
                                        before: 100
                                    }
                                })
                            ],
                            columnSpan: 7
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: `${this.getValue(data.submission, "productos_certificados_2_conformidad") === 'C' ? '■' : '❏'} C ${this.getValue(data.submission, "productos_certificados_2_conformidad") === 'NC' ? '■' : '❏'} NC ${this.getValue(data.submission, "productos_certificados_2_conformidad") === 'NA' ? '■' : '❏'} NA`,
                                            font: 'Arial',
                                            size: 20
                                        })
                                    ],
                                    spacing: {
                                        after: 100,
                                        before: 100
                                    }
                                })
                            ],
                            columnSpan: 2
                        })
                    ]
                }),
            ],
            margins: {
                left: 70,
                right: 70
            },
            width: {
                size: 100,
                type: WidthType.PERCENTAGE
            },
            columnWidths: [500,1359,1359,1359,1359,1359,1359,1359,1200,1200]
        })

        const balanceMasas = new TableRow({
            children: [],
        })

        const balanceMasasCell = new TableCell({
            children: [
                new Paragraph({
                    alignment: AlignmentType.LEFT,
                    children: [
                        new TextRun({
                            text: '',
                            font: 'Arial',
                            size: 20
                        })
                    ]
                }),
            ]
        })

        const balanceMasasTable = new Table({
            rows: [
                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: 'Producto',
                                            font: 'Arial',
                                            size: 20,
                                            bold: true
                                        })
                                    ]
                                })
                            ],
                            shading: {
                                fill: '#C0C0C0',
                                val: ShadingType.PERCENT_80
                            },
                            columnSpan: 3
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: 'Cant. Estimada',
                                            font: 'Arial',
                                            size: 20,
                                            bold: true
                                        })
                                    ]
                                })
                            ],
                            shading: {
                                fill: '#C0C0C0',
                                val: ShadingType.PERCENT_80
                            },
                            columnSpan: 1
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: 'Cant. Producida',
                                            font: 'Arial',
                                            size: 20,
                                            bold: true
                                        })
                                    ]
                                })
                            ],
                            shading: {
                                fill: '#C0C0C0',
                                val: ShadingType.PERCENT_80
                            },
                            columnSpan: 1
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: 'Cant. vendida',
                                            font: 'Arial',
                                            size: 20,
                                            bold: true
                                        })
                                    ]
                                })
                            ],
                            shading: {
                                fill: '#C0C0C0',
                                val: ShadingType.PERCENT_80
                            },
                            columnSpan: 1
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: 'Documentos de referencia',
                                            font: 'Arial',
                                            size: 20,
                                            bold: true
                                        })
                                    ]
                                })
                            ],
                            shading: {
                                fill: '#C0C0C0',
                                val: ShadingType.PERCENT_80
                            },
                            columnSpan: 3
                        }),
                    ]
                })
            ],
            margins: {
                left: 70,
                right: 70
            },
            width: {
                size: 100,
                type: WidthType.PERCENTAGE
            },
            columnWidths: [500,1359,1359,1359,1359,1359,1359,1359,1200,1200]
        })

        const balanceMasasSubmission = this.getValueList(data.submission, 'balance-masas')
        balanceMasasSubmission.forEach((row, index) => {
            let added = new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: row['producto'],
                                            font: 'Arial',
                                            size: 20,
                                            bold: true
                                        })
                                    ]
                                })
                            ]
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: row['cant_estimada'],
                                            font: 'Arial',
                                            size: 20,
                                            bold: true
                                        })
                                    ]
                                })
                            ],
                            columnSpan: 1
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: row['cant_producida'],
                                            font: 'Arial',
                                            size: 20,
                                            bold: true
                                        })
                                    ]
                                })
                            ],
                            columnSpan: 1
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: row['cant_vendida'],
                                            font: 'Arial',
                                            size: 20,
                                            bold: true
                                        })
                                    ]
                                })
                            ],
                            columnSpan: 1
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: row['documentos'],
                                            font: 'Arial',
                                            size: 20,
                                            bold: true
                                        })
                                    ]
                                })
                            ],
                            columnSpan: 3
                        }),
                    ]
                })
            balanceMasasTable.addChildElement(added)
        })

        const balanceMasasCellComentarios = new Paragraph({
            alignment: AlignmentType.LEFT,
            children: [
                new TextRun({
                    text: `Comentarios: ${this.getValue(data.submission, 'productos_certificados_balance_comentarios')}`,
                    font: 'Arial',
                    size: 20
                })
            ]
        })

        balanceMasasCell.addChildElement(balanceMasasTable)
        balanceMasasCell.addChildElement(balanceMasasCellComentarios)
        balanceMasas.addChildElement(balanceMasasCell)

        table.addChildElement(balanceMasas)

        const trazabilidad = new TableRow({
            children: [],
        })

        const trazabilidadCell = new TableCell({
            children: [
                new Paragraph({
                    alignment: AlignmentType.LEFT,
                    children: [
                        new TextRun({
                            text: '',
                            font: 'Arial',
                            size: 20
                        })
                    ]
                }),
            ]
        })

        const trazabilidadTable = new Table({
            rows: [
                new TableRow({
                    children: [
                        new TableCell({
                                children: [
                                        new Paragraph({
                                            alignment: AlignmentType.LEFT,
                                            children: [
                                                new TextRun({
                                                    text: '3',
                                                    font: 'Arial',
                                                    size: 20
                                                })
                                            ],
                                            spacing: {
                                                after: 100,
                                                before: 100
                                            }
                                        })
                                    ],
                                    columnSpan: 1
                                }),
                                new TableCell({
                                    children: [
                                        new Paragraph({
                                            alignment: AlignmentType.LEFT,
                                            children: [
                                                new TextRun({
                                                    text: '¿Fue posible realizar un ejercicio de trazabilidad?',
                                                    font: 'Arial',
                                                    size: 20
                                                })
                                            ],
                                            spacing: {
                                                after: 100,
                                                before: 100
                                            }
                                        })
                                    ],
                                    columnSpan: 7
                                }),
                                new TableCell({
                                    children: [
                                        new Paragraph({
                                            alignment: AlignmentType.LEFT,
                                            children: [
                                                new TextRun({
                                                    text: `❏ C ❏ NC ❏ NA`,
                                                    font: 'Arial',
                                                    size: 20
                                                })
                                            ],
                                            spacing: {
                                                after: 100,
                                                before: 100
                                            }
                                        })
                                    ],
                                    columnSpan: 2
                                })
                    ],
                }),
                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: 'Lote venta',
                                            font: 'Arial',
                                            size: 20,
                                            bold: true
                                        })
                                    ]
                                })
                            ],
                            shading: {
                                fill: '#C0C0C0',
                                val: ShadingType.PERCENT_80
                            },
                            columnSpan: 1
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: 'Cantidad',
                                            font: 'Arial',
                                            size: 20,
                                            bold: true
                                        })
                                    ]
                                })
                            ],
                            shading: {
                                fill: '#C0C0C0',
                                val: ShadingType.PERCENT_80
                            },
                            columnSpan: 1
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: 'Fecha venta',
                                            font: 'Arial',
                                            size: 20,
                                            bold: true
                                        })
                                    ]
                                })
                            ],
                            shading: {
                                fill: '#C0C0C0',
                                val: ShadingType.PERCENT_80
                            },
                            columnSpan: 1
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: 'Fecha producción',
                                            font: 'Arial',
                                            size: 20,
                                            bold: true
                                        })
                                    ]
                                })
                            ],
                            shading: {
                                fill: '#C0C0C0',
                                val: ShadingType.PERCENT_80
                            },
                            columnSpan: 2
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: 'Parcela o lote de origen',
                                            font: 'Arial',
                                            size: 20,
                                            bold: true
                                        })
                                    ]
                                })
                            ],
                            shading: {
                                fill: '#C0C0C0',
                                val: ShadingType.PERCENT_80
                            },
                            columnSpan: 2
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: 'Documentos de referencia',
                                            font: 'Arial',
                                            size: 20,
                                            bold: true
                                        })
                                    ]
                                })
                            ],
                            shading: {
                                fill: '#C0C0C0',
                                val: ShadingType.PERCENT_80
                            },
                            columnSpan: 2
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: 'anexos',
                                            font: 'Arial',
                                            size: 20,
                                            bold: true
                                        })
                                    ]
                                })
                            ],
                            shading: {
                                fill: '#C0C0C0',
                                val: ShadingType.PERCENT_80
                            },
                            columnSpan: 2
                        }),
                    ]
                })
            ],
            margins: {
                left: 70,
                right: 70
            },
            width: {
                size: 100,
                type: WidthType.PERCENTAGE
            },
            columnWidths: [500,1359,1359,1359,1359,1359,1359,1359,1200,1200]
        })

        const trazabilidadSubmission = this.getValueList(data.submission, 'trazabilidad')
        trazabilidadSubmission.forEach((row, index) => {
            var anexos = ''
            for(var i in row.anexos) {
                const tmp = row.anexos[i].split("/")
                anexos += tmp[tmp.length-1] + ", "
            }

            anexos = anexos.substr(0, anexos.length-2)

            let added = new TableRow({
                children: [
                    new TableCell({
                        children: [
                            new Paragraph({
                                alignment: AlignmentType.CENTER,
                                children: [
                                    new TextRun({
                                        text: row['lote'],
                                        font: 'Arial',
                                        size: 20,
                                        bold: true
                                    })
                                ]
                            })
                        ],
                        columnSpan: 1
                    }),
                    new TableCell({
                        children: [
                            new Paragraph({
                                alignment: AlignmentType.CENTER,
                                children: [
                                    new TextRun({
                                        text: row['cantidad'],
                                        font: 'Arial',
                                        size: 20,
                                        bold: true
                                    })
                                ]
                            })
                        ],
                        columnSpan: 1
                    }),
                    new TableCell({
                        children: [
                            new Paragraph({
                                alignment: AlignmentType.CENTER,
                                children: [
                                    new TextRun({
                                        text: row['fecha_venta'],
                                        font: 'Arial',
                                        size: 20,
                                        bold: true
                                    })
                                ]
                            })
                        ],
                        columnSpan: 1
                    }),
                    new TableCell({
                        children: [
                            new Paragraph({
                                alignment: AlignmentType.CENTER,
                                children: [
                                    new TextRun({
                                        text: row['fecha_produccion'],
                                        font: 'Arial',
                                        size: 20,
                                        bold: true
                                    })
                                ]
                            })
                        ],
                        columnSpan: 2
                    }),
                    new TableCell({
                        children: [
                            new Paragraph({
                                alignment: AlignmentType.CENTER,
                                children: [
                                    new TextRun({
                                        text: row['parcela_origen'],
                                        font: 'Arial',
                                        size: 20,
                                        bold: true
                                    })
                                ]
                            })
                        ],
                        columnSpan: 2
                    }),
                    new TableCell({
                        children: [
                            new Paragraph({
                                alignment: AlignmentType.CENTER,
                                children: [
                                    new TextRun({
                                        text: row['documentos_referencia'],
                                        font: 'Arial',
                                        size: 20,
                                        bold: true
                                    })
                                ]
                            })
                        ],
                        columnSpan: 2
                    }),
                    new TableCell({
                        children: [
                            new Paragraph({
                                alignment: AlignmentType.CENTER,
                                children: [
                                    new TextRun({
                                        text: `${anexos === '' ? 'Sin anexos' : anexos}`,
                                        font: 'Arial',
                                        size: 20,
                                        bold: true
                                    })
                                ]
                            })
                        ],
                        columnSpan: 2
                    }),
                ]
            })
            trazabilidadTable.addChildElement(added)
        })

        const trazabilidadCellComentarios = new Paragraph({
            alignment: AlignmentType.LEFT,
            children: [
                new TextRun({
                    text: `Comentarios: ${this.getValue(data.submission, 'productos_certificados_trazabilidad_comentarios')}`,
                    font: 'Arial',
                    size: 20
                })
            ]
        })

        trazabilidadCell.addChildElement(trazabilidadTable)
        trazabilidadCell.addChildElement(trazabilidadCellComentarios)
        trazabilidad.addChildElement(trazabilidadCell)
        // table.addChildElement(trazabilidad2)
        table.addChildElement(trazabilidad)

        const cicloProd = new TableRow({
            children: [
            ],
        })

        const cicloProdCell = new TableCell({
            children: [
                new Paragraph({
                    alignment: AlignmentType.LEFT,
                    children: [
                        new TextRun({
                            text: '',
                            font: 'Arial',
                            size: 20
                        })
                    ]
                }),
                new Paragraph({
                    alignment: AlignmentType.CENTER,
                    children: [
                        new TextRun({
                            text: 'Por favor agregue un cuadro con información del siguiente ciclo de producción',
                            font: 'Arial',
                            size: 20
                        })
                    ]
                }),
                new Paragraph({
                    alignment: AlignmentType.LEFT,
                    children: [
                        new TextRun({
                            text: '',
                            font: 'Arial',
                            size: 20
                        })
                    ]
                })
            ]
        })

        const cicloProdTable = new Table({
            rows: [
                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: 'Producto',
                                            font: 'Arial',
                                            size: 20,
                                            bold: true
                                        })
                                    ]
                                })
                            ],
                            shading: {
                                fill: '#C0C0C0',
                                val: ShadingType.PERCENT_80
                            },
                            columnSpan: 3
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: 'Parcela',
                                            font: 'Arial',
                                            size: 20,
                                            bold: true
                                        })
                                    ]
                                })
                            ],
                            shading: {
                                fill: '#C0C0C0',
                                val: ShadingType.PERCENT_80
                            },
                            columnSpan: 3
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: 'Superficie (ha)',
                                            font: 'Arial',
                                            size: 20,
                                            bold: true
                                        })
                                    ]
                                })
                            ],
                            shading: {
                                fill: '#C0C0C0',
                                val: ShadingType.PERCENT_80
                            },
                            columnSpan: 2
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: 'Estimación (Kg)',
                                            font: 'Arial',
                                            size: 20,
                                            bold: true
                                        })
                                    ]
                                })
                            ],
                            shading: {
                                fill: '#C0C0C0',
                                val: ShadingType.PERCENT_80
                            },
                            columnSpan: 2
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: 'Anexos',
                                            font: 'Arial',
                                            size: 20,
                                            bold: true
                                        })
                                    ]
                                })
                            ],
                            shading: {
                                fill: '#C0C0C0',
                                val: ShadingType.PERCENT_80
                            },
                            columnSpan: 2
                        }),
                    ]
                })
            ],
            margins: {
                left: 70,
                right: 70
            },
            width: {
                size: 100,
                type: WidthType.PERCENTAGE
            },
            columnWidths: [500,1359,1359,1359,1359,1359,1359,1359,1200,1200]
        })

        const cicloProdSubmission = this.getValueList(data.submission, 'ciclo-produccion')
        cicloProdSubmission.forEach((row, index) => {
            var anexos = ''
            for(var i in row.anexos) {
                const tmp = row.anexos[i].split("/")
                anexos += tmp[tmp.length-1] + ", "
            }

            anexos = anexos.substr(0, anexos.length-2)

            let added = new TableRow({
                children: [
                    new TableCell({
                        children: [
                            new Paragraph({
                                alignment: AlignmentType.LEFT,
                                children: [
                                    new TextRun({
                                        text: row['producto'],
                                        font: 'Arial',
                                        size: 20
                                    })
                                ],
                                spacing: {
                                    after: 100,
                                    before: 100
                                }
                            })
                        ],
                        columnSpan: 3
                    }),
                    new TableCell({
                        children: [
                            new Paragraph({
                                alignment: AlignmentType.LEFT,
                                children: [
                                    new TextRun({
                                        text: row['presentacion'],
                                        font: 'Arial',
                                        size: 20
                                    })
                                ],
                                spacing: {
                                    after: 100,
                                    before: 100
                                }
                            })
                        ],
                        columnSpan: 3
                    }),
                    new TableCell({
                        children: [
                            new Paragraph({
                                alignment: AlignmentType.LEFT,
                                children: [
                                    new TextRun({
                                        text: row['superficie'],
                                        font: 'Arial',
                                        size: 20
                                    })
                                ],
                                spacing: {
                                    after: 100,
                                    before: 100
                                }
                            })
                        ],
                        columnSpan: 2
                    }),
                    new TableCell({
                        children: [
                            new Paragraph({
                                alignment: AlignmentType.LEFT,
                                children: [
                                    new TextRun({
                                        text: row['estimacion'],
                                        font: 'Arial',
                                        size: 20
                                    })
                                ],
                                spacing: {
                                    after: 100,
                                    before: 100
                                }
                            })
                        ],
                        columnSpan: 2
                    }),
                    new TableCell({
                        children: [
                            new Paragraph({
                                alignment: AlignmentType.LEFT,
                                children: [
                                    new TextRun({
                                        text: `${anexos === '' ? 'Sin anexos' : anexos}`,
                                        font: 'Arial',
                                        size: 20
                                    })
                                ],
                                spacing: {
                                    after: 100,
                                    before: 100
                                }
                            })
                        ],
                        columnSpan: 2
                    }),
                ]
            })
            cicloProdTable.addChildElement(added)
        })

        cicloProdCell.addChildElement(cicloProdTable)
        cicloProd.addChildElement(cicloProdCell)

        table.addChildElement(cicloProd)

        const cicloProd2 = new TableRow({
            children: [
            ],
        })

        const cicloProd2Cell = new TableCell({
            children: [
                new Paragraph({
                    alignment: AlignmentType.LEFT,
                    children: [
                        new TextRun({
                            text: '',
                            font: 'Arial',
                            size: 20
                        })
                    ]
                }),
                new Paragraph({
                    alignment: AlignmentType.CENTER,
                    children: [
                        new TextRun({
                            text: 'Por favor agregue un cuadro con información del siguiente ciclo de producción',
                            font: 'Arial',
                            size: 20
                        })
                    ]
                }),
                new Paragraph({
                    alignment: AlignmentType.LEFT,
                    children: [
                        new TextRun({
                            text: '',
                            font: 'Arial',
                            size: 20
                        })
                    ]
                })
            ]
        })

        const cicloProd2Table = new Table({
            rows: [
                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: 'Producto',
                                            font: 'Arial',
                                            size: 20,
                                            bold: true
                                        })
                                    ]
                                })
                            ],
                            shading: {
                                fill: '#C0C0C0',
                                val: ShadingType.PERCENT_80
                            },
                            columnSpan: 3
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: 'Especie',
                                            font: 'Arial',
                                            size: 20,
                                            bold: true
                                        })
                                    ]
                                })
                            ],
                            shading: {
                                fill: '#C0C0C0',
                                val: ShadingType.PERCENT_80
                            },
                            columnSpan: 3
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: 'Número de cabezas',
                                            font: 'Arial',
                                            size: 20,
                                            bold: true
                                        })
                                    ]
                                })
                            ],
                            shading: {
                                fill: '#C0C0C0',
                                val: ShadingType.PERCENT_80
                            },
                            columnSpan: 3
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: 'Estimación (Kg)',
                                            font: 'Arial',
                                            size: 20,
                                            bold: true
                                        })
                                    ]
                                })
                            ],
                            shading: {
                                fill: '#C0C0C0',
                                val: ShadingType.PERCENT_80
                            },
                            columnSpan: 3
                        }),
                    ]
                })
            ],
            margins: {
                left: 70,
                right: 70
            },
            width: {
                size: 100,
                type: WidthType.PERCENTAGE
            },
            columnWidths: [500,1359,1359,1359,1359,1359,1359,1359,1200,1200]
        })

        const cicloProd2Submission = this.getValueList(data.submission, 'ciclo-produccion-2')
        cicloProd2Submission.forEach((row, index) => {
            let added = new TableRow({
                children: [
                    new TableCell({
                        children: [
                            new Paragraph({
                                alignment: AlignmentType.LEFT,
                                children: [
                                    new TextRun({
                                        text: row['producto'],
                                        font: 'Arial',
                                        size: 20
                                    })
                                ],
                                spacing: {
                                    after: 100,
                                    before: 100
                                }
                            })
                        ],
                        columnSpan: 3
                    }),
                    new TableCell({
                        children: [
                            new Paragraph({
                                alignment: AlignmentType.LEFT,
                                children: [
                                    new TextRun({
                                        text: row['especie'],
                                        font: 'Arial',
                                        size: 20
                                    })
                                ],
                                spacing: {
                                    after: 100,
                                    before: 100
                                }
                            })
                        ],
                        columnSpan: 3
                    }),
                    new TableCell({
                        children: [
                            new Paragraph({
                                alignment: AlignmentType.LEFT,
                                children: [
                                    new TextRun({
                                        text: row['cabezas'],
                                        font: 'Arial',
                                        size: 20
                                    })
                                ],
                                spacing: {
                                    after: 100,
                                    before: 100
                                }
                            })
                        ],
                        columnSpan: 3
                    }),
                    new TableCell({
                        children: [
                            new Paragraph({
                                alignment: AlignmentType.LEFT,
                                children: [
                                    new TextRun({
                                        text: row['estimacion'],
                                        font: 'Arial',
                                        size: 20
                                    })
                                ],
                                spacing: {
                                    after: 100,
                                    before: 100
                                }
                            })
                        ],
                        columnSpan: 3
                    }),
                ]
            })
            cicloProd2Table.addChildElement(added)
        })

        const cicloProd2CellComentarios = new Paragraph({
            alignment: AlignmentType.LEFT,
            children: [
                new TextRun({
                    text: `Comentarios: ${this.getValue(data.submission, 'productos_certificados_ciclo_2_comentarios')}`,
                    font: 'Arial',
                    size: 20
                })
            ]
        })
        

        cicloProd2Cell.addChildElement(cicloProd2Table)
        cicloProd2Cell.addChildElement(cicloProd2CellComentarios)
        cicloProd2.addChildElement(cicloProd2Cell)

        table.addChildElement(cicloProd2)

        return table
    }

    public createResumen(data) {
        const table = new Table({
            rows: [
                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: 'No',
                                            font: 'Arial',
                                            size: 20,
                                            bold: true
                                        })
                                    ],
                                    spacing: {
                                        after: 100,
                                        before: 100
                                    }
                                })
                            ],
                            columnSpan: 1
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: 'Hallazgos',
                                            font: 'Arial',
                                            size: 20,
                                            bold: true
                                        })
                                    ],
                                    spacing: {
                                        after: 100,
                                        before: 100
                                    }
                                })
                            ],
                            columnSpan: 3
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: 'Apartado de la norma',
                                            font: 'Arial',
                                            size: 20,
                                            bold: true
                                        })
                                    ],
                                    spacing: {
                                        after: 100,
                                        before: 100
                                    }
                                })
                            ],
                            columnSpan: 2
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: 'Evicencia',
                                            font: 'Arial',
                                            size: 20,
                                            bold: true
                                        })
                                    ],
                                    spacing: {
                                        after: 100,
                                        before: 100
                                    }
                                })
                            ],
                            columnSpan: 3
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: 'Clasificación',
                                            font: 'Arial',
                                            size: 20,
                                            bold: true
                                        })
                                    ],
                                    spacing: {
                                        after: 100,
                                        before: 100
                                    }
                                })
                            ],
                            columnSpan: 2
                        }),
                    ]
                })
            ],
            margins: {
                left: 70,
                right: 70
            },
            width: {
                size: 100,
                type: WidthType.PERCENTAGE
            },
            columnWidths: [500,1359,1359,1359,1359,1359,1359,1359,1200,1200]
        })

        const listaComentarios = this.getValueList(data.submission, "hallazgos")
        listaComentarios.forEach((comentario, index) => {
            table.addChildElement(
                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: index + 1,
                                            font: 'Arial',
                                            size: 20,
                                            bold: true
                                        })
                                    ],
                                    spacing: {
                                        after: 100,
                                        before: 100
                                    }
                                })
                            ],
                            columnSpan: 1
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: comentario["resumen_hallazgos_hallazgo"],
                                            font: 'Arial',
                                            size: 20,
                                            bold: true
                                        })
                                    ],
                                    spacing: {
                                        after: 100,
                                        before: 100
                                    }
                                })
                            ],
                            columnSpan: 3
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: comentario["resumen_hallazgos_apartado"],
                                            font: 'Arial',
                                            size: 20,
                                            bold: true
                                        })
                                    ],
                                    spacing: {
                                        after: 100,
                                        before: 100
                                    }
                                })
                            ],
                            columnSpan: 2
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: comentario["resumen_hallazgos_evidencia"],
                                            font: 'Arial',
                                            size: 20,
                                            bold: true
                                        })
                                    ],
                                    spacing: {
                                        after: 100,
                                        before: 100
                                    }
                                })
                            ],
                            columnSpan: 3
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: comentario["resumen_hallazgos_clasificación"] === 'Mayor' ? '■ Mayor' : '❏ Mayor',
                                            font: 'Arial',
                                            size: 20,
                                            bold: true
                                        })
                                    ],
                                    spacing: {
                                        after: 100,
                                        before: 100
                                    }
                                }),
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: comentario["resumen_hallazgos_clasificación"] === 'Menor' ? '■ Menor' : '❏ Menor',
                                            font: 'Arial',
                                            size: 20,
                                            bold: true
                                        })
                                    ],
                                    spacing: {
                                        after: 100,
                                        before: 100
                                    }
                                })
                            ],
                            columnSpan: 2
                        }),
                    ]
                })
            )
        });

        return table
    }

    public createResumenReunion(data) {
        const table = new Table({
            rows: [
                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: 'Resumen de Reunión Final',
                                            font: 'Arial',
                                            size: 20,
                                            bold: true
                                        })
                                    ],
                                    spacing: {
                                        after: 100,
                                        before: 100
                                    }
                                })
                            ],
                            shading: {
                                fill: '#C0C0C0',
                                val: ShadingType.PERCENT_80
                            },
                            columnSpan: 10
                        })
                    ]
                })
            ],
            margins: {
                left: 70,
                right: 70
            },
            width: {
                size: 100,
                type: WidthType.PERCENTAGE
            },
            columnWidths: [500,1359,1359,1359,1359,1359,1359,1359,1200,1200]
        })

        const listaComentarios = this.getValueList(data.submission, "resumen_reunion")
        listaComentarios.forEach((comentario, index) => {
            table.addChildElement(
                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: `${index + 1}`,
                                            font: 'Arial',
                                            size: 20,
                                            bold: true
                                        })
                                    ],
                                    spacing: {
                                        after: 100,
                                        before: 100
                                    }
                                })
                            ],
                            columnSpan: 1
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: comentario["resumen_reunion_final"],
                                            font: 'Arial',
                                            size: 20,
                                            bold: true
                                        })
                                    ],
                                    spacing: {
                                        after: 100,
                                        before: 100
                                    }
                                })
                            ],
                            columnSpan: 9
                        })
                    ]
                })
            )
        });

        return table
    }

    public createComentariosOperador(data) {
        const table = new Table({
            rows: [
                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: 'Comentarios del Operador',
                                            font: 'Arial',
                                            size: 20,
                                            bold: true
                                        })
                                    ],
                                    spacing: {
                                        after: 100,
                                        before: 100
                                    }
                                })
                            ],
                            shading: {
                                fill: '#C0C0C0',
                                val: ShadingType.PERCENT_80
                            },
                            columnSpan: 10
                        })
                    ]
                })
            ],
            margins: {
                left: 70,
                right: 70
            },
            width: {
                size: 100,
                type: WidthType.PERCENTAGE
            },
            columnWidths: [500,1359,1359,1359,1359,1359,1359,1359,1200,1200]
        })

        const listaComentarios = this.getValueList(data.submission, "resumen_comentarios")
        listaComentarios.forEach((comentario, index) => {
            table.addChildElement(
                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: `${index + 1}`,
                                            font: 'Arial',
                                            size: 20,
                                            bold: true
                                        })
                                    ],
                                    spacing: {
                                        after: 100,
                                        before: 100
                                    }
                                })
                            ],
                            columnSpan: 1
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: comentario["resumen_comentarios_operador"],
                                            font: 'Arial',
                                            size: 20,
                                            bold: true
                                        })
                                    ],
                                    spacing: {
                                        after: 100,
                                        before: 100
                                    }
                                })
                            ],
                            columnSpan: 9
                        })
                    ]
                })
            )
        });

        return table
    }

    getValue(submission, name) {
        var value = null
        for (const i in submission) {
            const element = submission[i]
            if (element.name === name) {
                value = element.value
            }
        }
        if (value) return value
        else return ''
    }

    getValueList(submission, name) {
        var value = null
        for (const i in submission) {
            const element = submission[i]
            if (element.name === name) {
                value = element.value
            }
        }
        if (value) return value
        else return []
    }

    getFileList(value) {
        var anexos = ''
        for(var i in value) {
            const tmp = value[i].split("/")
            anexos += tmp[tmp.length-1] + ", "
        }

        anexos = anexos.substr(0, anexos.length-2)

        return anexos
    }
    // END MTO_FINS_03_2
}