<!-- <app-core></app-core> -->
<!-- nav -->
<nav
  class="navbar navbar-expand navbar-dark bg-dark sticky-top shadow-sm home-fade-in"
  *ngIf="user && certificadoExterno"
  style="z-index: 1000;"
>
  <div class="navbar-nav">
    <a
      class="nav-item nav-link"
      routerLink="/"
      routerLinkActive="active"
      [routerLinkActiveOptions]="{ exact: true }"
    >
      <img
        id="logo-nav"
        src="/assets/icons/mto-logo-white.jpg"
        class="img-fluid"
        style="height: 40px; text-align: center; border-radius: 100%;"
        alt="metrocert"
      />
    </a>
    <a 
      class="nav-item nav-link"
      routerLink="/"
      routerLinkActive="active"
      [routerLinkActiveOptions]="{ exact: true }"
    >
      Inicio
    </a>
    <a
      *ngIf="usuarios"
      class="nav-item nav-link"
      routerLink="/usuarios"
      routerLinkActive="active"
      >Usuarios</a
    >
    <a
      *ngIf="operadores"
      class="nav-item nav-link"
      routerLink="/operadores"
      routerLinkActive="active"
      >Operadores</a
    >
    <a
      *ngIf="solicitudes"
      class="nav-item nav-link"
      routerLink="/solicitudes"
      routerLinkActive="active"
      >Solicitudes</a
    >
    <a
      *ngIf="revisiones"
      class="nav-item nav-link"
      routerLink="/revisiones"
      routerLinkActive="active"
      >Revisiones</a
    >
    <a
      *ngIf="inspecciones"
      class="nav-item nav-link"
      routerLink="/inspecciones"
      routerLinkActive="active"
      >Inspecciones</a
    >
    
    <a
      *ngIf="dictamenes"
      class="nav-item nav-link"
      routerLink="/dictamenes"
      routerLinkActive="active"
      >Dictamenes</a
    >
    <a
      *ngIf="certificados"
      class="nav-item nav-link"
      routerLink="/certificados"
      routerLinkActive="active"
      >Certificados</a
    >
    <a
      *ngIf="expedientes"
      class="nav-item nav-link"
      routerLink="/expedientes"
      routerLinkActive="active"
      >Expedientes</a
    >
    <a
      *ngIf="cotizador"
      class="nav-item nav-link"
      routerLink="/cotizador"
      routerLinkActive="active"
      >Cotizador</a
    >
    <a
      *ngIf="catalogos"
      class="nav-item nav-link"
      routerLink="/catalogos"
      routerLinkActive="active"
      >Catalogos</a
    >
    <a
      *ngIf="sustancias"
      class="nav-item nav-link"
      routerLink="/sustancias"
      routerLinkActive="active"
      >Sustancias</a
    >
    <a
      *ngIf="no_conformidades"
      class="nav-item nav-link"
      routerLink="/no_conformidades"
      routerLinkActive="active"
      >No Conformidades</a
    >
    <a
      *ngIf="perfil"
      class="nav-item nav-link"
      routerLink="/perfil"
      routerLinkActive="active"
      >Perfil</a
    >
    <a
      *ngIf="emails"
      class="nav-item nav-link"
      routerLink="/emails"
      routerLinkActive="active"
      >Emails</a
    >
    <a
      *ngIf="supportUtilities"
      class="nav-item nav-link"
      routerLink="/support_utilities"
      routerLinkActive="active"
      >Support Utilities</a
    >
    <a
      *ngIf="reports"
      class="nav-item nav-link"
      routerLink="/reportes"
      routerLinkActive="active"
      >Reportes</a
    >

    <a
      *ngIf="regenerateDocs"
      class="nav-item nav-link"
      routerLink="/regenerar_docs"
      routerLinkActive="active"
      >Regenerar Documentos</a
    >
    <!-- <a class="nav-item nav-link" (click)="logout()">
      Cerrar sesión
    </a> -->
  </div>
  <div
    class="collapse navbar-collapse justify-content-end"
    id="navbarSupportedContent"
  >
    <ul class="navbar-nav mr-0">
      <li class="nav-item">
        <div
          *ngIf="!user"
          class="spinner-border text-light spinner-border-sm"
        ></div>
        <div *ngIf="user" class="home-fade-in">
          <div ngbDropdown class="">
            <a
              class="nav-link"
              tabindex="0"
              ngbDropdownToggle
              id="menu"
              role="button"
            >
              <small *ngIf="innerWidth > 768" class="text-muted mr-2"
                >Último ingreso: <br>{{ user.lastLogin | date: "longDate" }}</small
              >
              <div class="circle" *ngIf="photoUrl===''">
                <span  class="initials"
                  ><strong>{{ initials | uppercase }}</strong></span
                >
              </div>
              <div class="circle" *ngIf="photoUrl!==''">
                <img [src]="photoUrl" alt="Foto" class="avatar">
              </div>
            </a>
            <div
              ngbDropdownMenu
              aria-labelledby="menu"
              class="dropdown-menu-right"
            >
              <!-- <h5 class="dropdown-header">Menú</h5> -->
              <a routerLink="/perfil" ngbDropdownItem>Perfil</a>
              <div class="dropdown-divider"></div>
              <h6 *ngIf="innerWidth <= 768" class="dropdown-header">
                Último ingreso: <br>{{ user.lastLogin | date: "longDate" }}
              </h6>
              <button ngbDropdownItem (click)="logout()">Cerrar sesión</button>
            </div>
          </div>
        </div>
      </li>
    </ul>
  </div>
</nav>

<!-- main app container -->
<div class="app-container" [ngClass]="{ 'bg-white': user }">
  <alert></alert>
  <router-outlet></router-outlet>
  <footer *ngIf="user && certificadoExterno"></footer>
</div>
