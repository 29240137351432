<a [routerLink]="module?.ref">
  <div class="card home-fade-in hover-card">
    <div class="p-4 text-center bg-light">
      <img
        [src]="module?.iconUrl"
        width="92"
        height="92"
        [alt]="module?.title"
      />
    </div>
    <div class="card-body bg-white">
      <a class="card-link text-secondary"
        ><h4 class="card-title text-dark text-center">
          {{ module?.title }}
        </h4></a
      >
    </div>
  </div>
</a>
