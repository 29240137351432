import {
  AlignmentType,
  Document,
  PageBreak,
  Paragraph,
  TextRun,
  Media,
  Header,
  Footer,
  Table,
  TableRow,
  TableCell,
  WidthType,
  PageNumber
} from "docx";
import {logo} from "../logo";
import {userWithoutSign} from "../userWithoutSign"
import {DatePipe} from '@angular/common';

//Moment import
const moment = require('moment')
moment.locale('es-mx')

export class DocumentCreatorMTO_FSOL_12_v2 {

  public getBase64FromUrl = async (url) => {
    const data = await fetch(url, {
      headers: {
          'Cache-Control': 'no-cache, no-store, must-revalidate',
          'Pragma': 'no-cache',
          'Expires': 'Sat, 01 Jan 2000 00:00:00 GMT',
          'If-Modified-Since': '0'
      }
  });
    const blob = await data.blob();
    return new Promise((resolve) => {
      const reader = new FileReader();
      reader.readAsDataURL(blob);
      reader.onloadend = function () {
        const base64data = reader.result;
        resolve(base64data);
      }
    });
  }

  // INIT MTO_FSOL_12
  public async create_MTO_FSOL_12(data, signature = ''): Promise<Document> {
    const document = new Document();
    const image = Media.addImage(document, logo, 50, 50);
    var datePipe = new DatePipe('es-MX');
    let signatureImage = null
    if (signature) {
      signatureImage = await this.getBase64FromUrl(signature)
    } else {
      signatureImage = userWithoutSign
    }

    document.addSection({
      headers: {
        default: new Header({
          children: [
            new Paragraph({alignment: AlignmentType.CENTER, children: [image],}),
            new Paragraph({
              alignment: AlignmentType.CENTER, children: [
                new TextRun({
                  children: [`MTO-FSOL-12| R 01 | 10-Marzo-2021 | Pág. `, PageNumber.CURRENT],
                  font: 'Book Antiqua',
                  size: 20,
                  bold: true
                }),
                new TextRun({
                  font: 'Book Antiqua',
                  size: 20,
                  bold: true,
                  children: [' de ', PageNumber.TOTAL_PAGES]
                }),
              ], spacing: {before: 200, after: 100}
            })
          ]
        })
      },
      footers: {
        default: new Footer({
          children: [
            new Paragraph({alignment: AlignmentType.CENTER}),
            this.createFooter()
          ]
        }),
      },
      children: [
        new Paragraph({
          alignment: AlignmentType.CENTER,
          children: [
            new TextRun({
              text: 'Solicitud de periodo para adaptación de instalaciones',
              font: 'Calibri (Cuerpo)',
              size: 22,
              bold: true
            })
          ],
          spacing: {before: 400, after: 900}
        }),
        new Paragraph({
          alignment: AlignmentType.RIGHT,
          children: [
            new TextRun({
              text: `Morelia Michoacán, ${moment().format('DD [de] MMMM [de] YYYY')}`,
              font: 'Arial',
              size: 22
            })
          ],
          spacing: {after: 400}
        }),
        new Paragraph({
          alignment: AlignmentType.LEFT,
          children: [
            new TextRun({
              text: `Ing. Mauricio Soberanes Hernández`,
              font: 'Arial',
              size: 22,
              bold: true
            })
          ]
        }),
        new Paragraph({
          alignment: AlignmentType.LEFT,
          children: [
            new TextRun({
              text: `Director General de Metrocert`,
              font: 'Arial',
              size: 22,
              bold: true
            })
          ],
          spacing: {after: 600}
        }),
        new Paragraph({
          alignment: AlignmentType.JUSTIFIED,
          children: [
            new TextRun({
              text: `Por este medio solicito formalmente se aplique la excepción de las regulaciones orgánicas, para el uso de instalaciones construidas y certificadas como orgánicas por esquemas voluntarios antes del 31 de diciembre de 2015. Esto en conformidad con el artículo 138 del Acuerdo por el que se dan a conocer los Lineamientos para la Operación Orgánica de las Actividades Agropecuarias.`,
              font: 'Arial',
              size: 22
            })
          ],
          spacing: {after: 200}
        }),
        new Paragraph({
          alignment: AlignmentType.JUSTIFIED,
          children: [
            new TextRun({
              text: `Adjunto a esta solicitud un cuadro que explica con detalle las condiciones actuales de la instalación, y el plan de adaptación de la misma.`,
              font: 'Arial',
              size: 22
            })
          ],
          spacing: {after: 200}
        }),
        new Paragraph({
          alignment: AlignmentType.JUSTIFIED,
          children: [
            new TextRun({
              text: 'Agradezco la atención y estaré atento por si se requiere de información adicional.',
              font: 'Arial',
              size: 22,
              bold: this.getValue(data.submission, "solicitud_razon_no_disponibles"),
              underline: this.getValue(data.submission, "solicitud_razon_no_disponibles")
            })
          ],
          spacing: {after: 400}
        }),
        new Paragraph({
          alignment: AlignmentType.CENTER,
          children: [
            new TextRun({
              text: `Atte:`,
              font: 'Arial',
              size: 22
            })
          ],
          spacing: {after: 600}
        }),
        new Paragraph({
          alignment: AlignmentType.CENTER,
          children: [
            signatureImage ? Media.addImage(document, signatureImage.toString(), 200, 100) : new TextRun({
              text: ''
            })
          ]
        }),
        new Paragraph({
          alignment: AlignmentType.CENTER,
          children: [
            new TextRun({
              text: `_________________________`,
              font: 'Arial',
              size: 22
            })
          ]
        }),
        new Paragraph({
          alignment: AlignmentType.CENTER,
          children: [
            new TextRun({
              text: `C. ${data.customer.Legal_Authorities[0].Name} ${data.customer.Legal_Authorities[0].Middle_Name} ${data.customer.Legal_Authorities[0].Last_Name ? data.customer.Legal_Authorities[0].Last_Name : ''}`,
              font: 'Arial',
              size: 22,
              bold: true
            })
          ]
        }),
        new Paragraph({
          alignment: AlignmentType.CENTER,
          children: [
            new TextRun({
              text: `Representante Legal`,
              font: 'Arial',
              size: 22,
              bold: true
            })
          ]
        }),
        new Paragraph({
          children: [
            new PageBreak()
          ],
          spacing: {
            after: 0,
            before: 0
          }
        }),
        new Paragraph({
          alignment: AlignmentType.CENTER,
          children: [
            new TextRun({
              text: 'Lista de instalaciones y plan de adaptación',
              font: 'Calibri (Cuerpo)',
              size: 22,
              bold: true
            })
          ],
          spacing: {before: 400}
        }),
        new Paragraph({
          alignment: AlignmentType.CENTER,
          children: [
            new TextRun({
              text: '',
            })
          ],
          spacing: {before: 400, after: 100}
        }),
        this.createTableInstalaciones(data),
        new Paragraph({
          alignment: AlignmentType.JUSTIFIED,
          children: [
            new TextRun({
              text: `Confirmo que la información proporcionada en este cuadro es verdadera y estoy consciente de que el falseamiento de la misma puede representar una negación de la certificación.`,
              font: 'Arial',
              size: 22
            })
          ],
          spacing: {before: 400, after: 400}
        }),
        new Paragraph({
          alignment: AlignmentType.CENTER,
          children: [
            new TextRun({
              text: `Atte:`,
              font: 'Arial',
              size: 22
            })
          ],
          spacing: {after: 600}
        }),
        new Paragraph({
          alignment: AlignmentType.CENTER,
          children: [
            signatureImage ? Media.addImage(document, signatureImage.toString(), 200, 100) : new TextRun({
              text: ''
            })
          ]
        }),
        new Paragraph({
          alignment: AlignmentType.CENTER,
          children: [
            new TextRun({
              text: `_________________________`,
              font: 'Arial',
              size: 22
            })
          ]
        }),
        new Paragraph({
          alignment: AlignmentType.CENTER,
          children: [
            new TextRun({
              text: `C. ${data.customer.Legal_Authorities[0].Name} ${data.customer.Legal_Authorities[0].Middle_Name} ${data.customer.Legal_Authorities[0].Last_Name ? data.customer.Legal_Authorities[0].Last_Name : ''}`,
              font: 'Arial',
              size: 22,
              bold: true
            })
          ]
        }),
        new Paragraph({
          alignment: AlignmentType.CENTER,
          children: [
            new TextRun({
              text: `Representante Legal`,
              font: 'Arial',
              size: 22,
              bold: true
            })
          ]
        }),
        new Paragraph({
          spacing: {
            after: 400
          }
        }),
      ],
      margins: {
        top: 350,
        bottom: 500,
        left: 800,
        right: 800
      }
    });

    return document;
  }

  public createFooter(): Table {
    const table = new Table({
      rows: [
        new TableRow({
          children: [
            new TableCell({
              children: [
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                    new TextRun({
                      text: 'Elaboró:',
                      font: 'Calibri (Cuerpo)',
                      size: 22
                    })
                  ]
                })
              ],
              width: {
                size: 33,
                type: WidthType.PERCENTAGE
              }
            }),
            new TableCell({
              children: [
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                    new TextRun({
                      text: 'Revisó:',
                      font: 'Calibri (Cuerpo)',
                      size: 22
                    })
                  ]
                })
              ],
              width: {
                size: 33,
                type: WidthType.PERCENTAGE
              }
            }),
            new TableCell({
              children: [
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                    new TextRun({
                      text: 'Autorizó:',
                      font: 'Calibri (Cuerpo)',
                      size: 22
                    })
                  ]
                }),
              ],
              width: {
                size: 33,
                type: WidthType.PERCENTAGE
              }
            })
          ]
        }),
        new TableRow({
          children: [
            new TableCell({
              children: [
                new Paragraph({
                  alignment: AlignmentType.CENTER,
                  children: [
                    new TextRun({
                      text: 'Director de certificación',
                      font: 'Calibri (Cuerpo)',
                      size: 16,
                      bold: true
                    })
                  ]
                }),

              ],
              width: {
                size: 33,
                type: WidthType.PERCENTAGE
              },
              shading: {
                fill: '#C0C0C0'
              }
            }),
            new TableCell({
              children: [
                new Paragraph({
                  alignment: AlignmentType.CENTER,
                  children: [
                    new TextRun({
                      text: 'Responsable de calidad',
                      font: 'Calibri (Cuerpo)',
                      size: 16,
                      bold: true
                    })
                  ]
                }),

              ],
              width: {
                size: 33,
                type: WidthType.PERCENTAGE
              },
              shading: {
                fill: '#C0C0C0'
              }
            }),
            new TableCell({
              children: [
                new Paragraph({
                  alignment: AlignmentType.CENTER,
                  children: [
                    new TextRun({
                      text: 'Director general',
                      font: 'Calibri (Cuerpo)',
                      size: 16,
                      bold: true
                    })
                  ]
                }),
              ],
              width: {
                size: 33,
                type: WidthType.PERCENTAGE
              },
              shading: {
                fill: '#C0C0C0'
              }
            })
          ]
        })
      ],
      margins: {
        left: 70,
        right: 70
      },
      columnWidths: [3213, 3213, 3212]
    })
    return table
  }

  public createTableInstalaciones(data) {
    const table = new Table({
      rows: [
        new TableRow({
          children: [
            new TableCell({
              children: [
                new Paragraph({
                  alignment: AlignmentType.CENTER,
                  children: [
                    new TextRun({
                      text: `Especie`,
                      font: 'Arial',
                      size: 22,
                      bold: true
                    })
                  ],
                  spacing: {before: 100, after: 200}
                })
              ],
              shading: {
                fill: "BFBFBF"
              }
            }),
            new TableCell({
              children: [
                new Paragraph({
                  alignment: AlignmentType.CENTER,
                  children: [
                    new TextRun({
                      text: `Instalación`,
                      font: 'Arial',
                      size: 22,
                      bold: true
                    })
                  ],
                  spacing: {before: 100, after: 200}
                })
              ],
              shading: {
                fill: "BFBFBF"
              }
            }),
            new TableCell({
              children: [
                new Paragraph({
                  alignment: AlignmentType.CENTER,
                  children: [
                    new TextRun({
                      text: `Estado Actual`,
                      font: 'Arial',
                      size: 22,
                      bold: true
                    })
                  ],
                  spacing: {before: 100, after: 200}
                })
              ],
              shading: {
                fill: "BFBFBF"
              }
            }),
            new TableCell({
              children: [
                new Paragraph({
                  alignment: AlignmentType.CENTER,
                  children: [
                    new TextRun({
                      text: `Modificación necesaria`,
                      font: 'Arial',
                      size: 22,
                      bold: true
                    })
                  ],
                  spacing: {before: 100, after: 200}
                })
              ],
              shading: {
                fill: "BFBFBF"
              }
            }),
            new TableCell({
              children: [
                new Paragraph({
                  alignment: AlignmentType.CENTER,
                  children: [
                    new TextRun({
                      text: `Plazo para completar la modificación`,
                      font: 'Arial',
                      size: 22,
                      bold: true
                    })
                  ],
                  spacing: {before: 100, after: 200}
                })
              ],
              shading: {
                fill: "BFBFBF"
              }
            })
          ]
        })

      ],
      margins: {
        left: 70,
        right: 70
      },
      width: {
        size: 100,
        type: WidthType.PERCENTAGE,
      },
      columnWidths: [3312, 3312, 3312, 3312, 3312]
    })

    const listaUnidades = this.getValueList(data.submission, "lista_instalaciones")
    listaUnidades.forEach(unidad => {
      table.addChildElement(new TableRow({
        children: [
          new TableCell({
            children: [
              new Paragraph({
                alignment: AlignmentType.CENTER,
                children: [
                  new TextRun({
                    text: `${unidad.instalaciones_especie}`,
                    font: 'Arial',
                    size: 22
                  })
                ],
                spacing: { before: 100, after: 200 }
              })
            ]
          }),
          new TableCell({
            children: [
              new Paragraph({
                alignment: AlignmentType.CENTER,
                children: [
                  new TextRun({
                    text: `${unidad.instalaciones_instalacion}`,
                    font: 'Arial',
                    size: 22
                  })
                ],
                spacing: { before: 100, after: 200 }
              })
            ]
          }),
          new TableCell({
            children: [
              new Paragraph({
                alignment: AlignmentType.CENTER,
                children: [
                  new TextRun({
                    text: `${unidad.instalaciones_estado_actual}`,
                    font: 'Arial',
                    size: 22
                  })
                ],
                spacing: { before: 100, after: 200 }
              })
            ]
          }),
          new TableCell({
            children: [
              new Paragraph({
                alignment: AlignmentType.CENTER,
                children: [
                  new TextRun({
                    text: `${unidad.instalaciones_modificacion}`,
                    font: 'Arial',
                    size: 22
                  })
                ],
                spacing: { before: 100, after: 200 }
              })
            ]
          }),
          new TableCell({
            children: [
              new Paragraph({
                alignment: AlignmentType.CENTER,
                children: [
                  new TextRun({
                    text: `${unidad.instalaciones_plazo}`,
                    font: 'Arial',
                    size: 22
                  })
                ],
                spacing: { before: 100, after: 200 }
              })
            ]
          })
        ]
      }))
    });
    return table;
  }

  getValue(submission, name) {
    var value = null
    for (const i in submission) {
      const element = submission[i]
      if (element.name === name) {
        value = element.value
      }
    }
    if (value) return value
    else return ''
  }

  getValueList(submission, name) {
    var value = null
    for (const i in submission) {
      const element = submission[i]
      if (element.name === name) {
        value = element.value
      }
    }
    if (value) return value
    else return []
  }

  // END MTO_FSOL_12
}
