<div class="mb-3" [formGroup]="form">
    <div id="dynamic-container" class="row overflow-auto" [ngStyle]="{'max-height': attributes.maxHeight ? attributes.maxHeight : '35vh'}">
        <table class="table">
            <thead class="thead-dark">
                <tr>
                    <th *ngIf="attributes.numbered">#</th>
                    <th *ngFor="let header of attributes.header; let i = index" [ngStyle]="{'font-size': attributes.size ? attributes.size : '15px', 'width': attributes.fixedSize ? attributes.fixedSize[i] : 'unset'}">{{header[0]}}</th>
                    <th>Acciones</th>
                    <th colspan="2">Requiere acción</th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let formGroup of formArray.controls; let i = index;" [ngStyle]="{'background': currentIndex === i ? 'forestgreen' : 'none'}" style="max-height: 5vh ;">
                    <td *ngIf="attributes.numbered" [ngStyle]="{'color': currentIndex === i ? 'white' : 'black'}">{{i + 1}}</td>
                    <td *ngFor="let header of attributes.header" [ngStyle]="{'color': currentIndex === i ? 'white' : 'black'}">{{formGroup.get(header[1]) && formGroup.get(header[1]).value ? formGroup.get(header[1]).value : '...'}}</td>
                    <td>
                        <button type="button" class="btn btn-sm" (click)="changeIndex(i)"><i class="fas fa-edit fa-2x" [ngStyle]="{'color': currentIndex === i ? 'white' : 'black'}"></i></button>
                        <button type="button" class="btn btn-sm" *ngIf="attributes.save"  (click)="save(i)"><i class="fas fa-save fa-2x" [ngStyle]="{'color': currentIndex === i ? 'white' : 'black', 'display': currentIndex === i ? 'block' : 'none'}"></i></button>
                        <button type="button" class="btn btn-sm" *ngIf="attributes.removable" (click)="deleteGroup(i)"><i class="fas fa-trash-alt fa-2x" [ngStyle]="{'color': currentIndex === i ? 'white' : 'black', 'display': currentIndex === i ? 'block' : 'none'}"></i></button>
                    </td>
                    <td>
                        <a
                            (click)="open('preview', 'MTO-FRYD-05', 68, true, false, i, formArray.controls[i].value.version)"
                            style="color: blue; text-align: center;"
                        >
                            <h5 style="margin: 0px;">MTO-FRYD-05</h5>
                            <h6 style="color: black; margin: 0px;">Notificación de No Conformidad</h6>
                        </a>
                    </td>
                    <td style="text-align: center;">
                        <input class="btn btn-sm" type="checkbox" (change)="requiresAction(i)" style="width: 32px; height: 32px;" [checked]="getRequiresAction(i)" [disabled]="formArray.controls[i].value.ncNotificationSent ? 'true' : null" />
                    </td>
                </tr>
            </tbody>
        </table>
        <div *ngIf="formArray.controls.length === 0" style="width: 100%;">
            <p style="text-align: center; color: #828282; margin: 0;">No hay registros</p>
        </div>
    </div>
    <br>

    <div class="col-12">
        <div class="row justify-content-center align-items-center">
            <div class="col-4">
                Correcciones a cosiderar
            </div>
            <div class="col-8">
                <textarea id="corrections" class="form-control" rows="3" (change)="updateCorrecciones($event)" (input)="updateCorrecciones($event, true)" [value]="formArray.controls[currentIndex].value.correcciones" style="resize: none;" ></textarea>
            </div>
        </div>

        <div *ngIf="formArray.controls[currentIndex].value.ncNotificationSent" class="row justify-content-center align-items-center mt-2" >
            <div class="col-4">
                Comentarios del Operador
            </div>
            <div class="col-8">
                <textarea class="form-control" rows="3" [value]="getCommentsOperator(formArray.controls[currentIndex].value.files)" style="resize: none;" disabled="true" ></textarea>
            </div>
        </div>

        <br>
        
        <div *ngIf="!formArray.controls[currentIndex].value.ncNotificationSent">
            <h6>Seleccione los formatos que el Operador deba actualizar:</h6>
            <div class="row">
                <div class="col-12">
                    <div *ngFor="let option of formArray.controls[currentIndex].value.forms" class="form-check form-check-inline col-4" style="margin: 0px; padding: 0px;">
                        <label class="form-check-label">
                            <input class="form-check-input" type="checkbox" [value]="option.id" [checked]="option.selected" (change)="updateCheckbox(currentIndex, option.id)" />
                            {{ option.form }} {{ option.title}}
                        </label>
                    </div>
                </div>
            </div>
        </div>

        <div *ngIf="formArray.controls[currentIndex].value.ncNotificationSent">
            <div class="row">
                <div class="col-6">
                    <h6>Formatos actualizados por el Operador</h6>

                    <div class="row">
                        <div class="col-6 mb-2" *ngFor="let docs of formArray.controls[currentIndex].value.docs">
                            <div class="card home-fade-in hover-card">
                                <div class="card-body bg-white text-center btn"
                                    (click)="open('preview', docs.form, docs.idDoc, false, true)"
                                >
                                    <h5 class="card-title">{{ docs.form }}</h5>
                                    <a class="text-info">Consultar</a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="mt-1" *ngIf="formArray.controls[currentIndex].value.docs.length === 0">
                        No se actualizaron formatos
                    </div>
                </div>

                <div class="col-6">
                    <h6>Archivos adjuntos por el Operador</h6>
                    <div class="mt-1" *ngIf="formArray.controls[currentIndex].value.files.length > 0">
                        <div style="margin-bottom: 10px;" *ngFor="let file of formArray.controls[currentIndex].value.files">
                          <a class="bg-info text-light" style="padding: 5px 20px 5px 20px; border-radius: 5px;" target="_blank"
                            [href]="file.url"><i style="margin-right: 10px;" class="fas fa-file"></i>{{getFilename(file.url)}}</a>
                        </div>
                    </div>
                    <div class="mt-1" *ngIf="formArray.controls[currentIndex].value.files.length === 0">
                        Sin archivos adjuntos
                    </div>
                </div>
            </div>
        </div>
    </div>

    <br>
    
    <div *ngIf="!attributes.no_body" class="card">
        <div class="card-body">
            <div [formArrayName]="attributes.group_name">
                <containers-fabric *ngFor="let formGroup of formArray.controls; let i = index;"
                    [content]="data.content" [formGroup]="formGroup" [ngClass]="{'d-none': i !== currentIndex}">
                </containers-fabric>
            </div>
        </div>
        <div *ngIf="attributes.addible !== false" class="row">
            <div class="col-lg-12 d-flex justify-content-center">
                <button  class="btn btn-small btn-info mb-3" *ngIf="!attributes.fixed" (click)="addFormGroup($event)">{{attributes.btn_title ? attributes.btn_title : 'Añadir'}}</button>
            </div>
        </div>
    </div>
</div>