<div class="modal-header">
    <h4 class="modal-title" id="modal-title">Restablecer contraseña</h4>
    <button
      type="button"
      class="close"
      aria-label="Close button"
      aria-describedby="modal-title"
      (click)="modal.dismiss('Cross click')"
    >
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  
  <form [formGroup]="form" (ngSubmit)="onSubmit()">
    <div class="modal-body">
      <p>
        <strong>
            Al usuario <span class="text-primary">{{ name }}</span>, se le enviara un correo para restablecer su contraseña.
        </strong>
      </p>
      <p class="text-muted">
        <small><i>Para continuar confirme que el correo sea correcto.</i></small>
      </p>
  
      <div class="form-group">
        <input
          type="email"
          formControlName="email"
          class="form-control"
          disabled
          [ngClass]="{
            'is-invalid': (submitted && f.email.errors) || forbiddenId
          }"
        />
        <div *ngIf="submitted && f.email.errors" class="invalid-feedback">
          <div *ngIf="f.email.errors.required">
            Email requerido
          </div>
        </div>
        <div *ngIf="forbiddenId" class="invalid-feedback">
          <div>
            Email incorrecto
          </div>
        </div>
      </div>
    </div>
    <div class="modal-footer">
      <button
        type="button"
        class="btn btn-outline-secondary"
        (click)="modal.dismiss('cancel click')"
      >
        Cancelar
      </button>
      <button
        [disabled]="loading"
        type="button"
        ngbAutofocus
        class="btn btn-primary"
        (click)="onSubmit()"
      >
        <span
          *ngIf="loading"
          class="spinner-border spinner-border-sm mr-1"
        ></span>
        Aceptar
      </button>
    </div>
  </form>
  