import { Form } from './../form-builder/interfaces/form.interface';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-core',
  templateUrl: './core.component.html'
})
export class CoreComponent implements OnInit {

  constructor() { }

  ngOnInit() {}

}
