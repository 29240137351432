<div class="modal-header">
    <h4 class="modal-title" id="modal-title">{{title}} inspección</h4>
    <button
      type="button"
      class="close"
      aria-label="Close button"
      aria-describedby="modal-title"
      (click)="modal.dismiss('Cross click')"
    >
      <span aria-hidden="true">&times;</span>
    </button>
  </div>

  <form [formGroup]="form" (ngSubmit)="onSubmit()">
    <div class="modal-body">
      <p *ngIf="!sign">
        <strong>
          Solicitud de aprobación<label *ngIf="name !== null"> por <span class="text-success"> {{ name }}</span></label>.
        </strong>
      </p>
      <p class="text-muted">
        <small><i>Para continuar confirme su contraseña.</i></small>
      </p>

      <div class="form-group approveInspection">
        <span class="icon-eye" (click)="showPasswordApproveInspection()">
          <i class="{{isApproveInspection ? 'fas fa-eye-slash' : 'fas fa-eye'}}"></i>
        </span>
        <input
          type = "{{isApproveInspection ? 'text' : 'password'}}"
          formControlName="password"
          class="form-control"
          [ngClass]="{
            'is-invalid': (submitted && f.password.errors) || forbiddenId
          }"
        />
        <div *ngIf="submitted && f.password.errors" class="invalid-feedback">
          <div *ngIf="f.password.errors.required">
            Contraseña requerida
          </div>
        </div>
        <div *ngIf="forbiddenId" class="invalid-feedback">
          <div>
            Contraseña incorrecta
          </div>
        </div>
      </div>
    </div>
    <div class="modal-footer">
      <button
        type="button"
        class="btn btn-outline-secondary"
        (click)="modal.dismiss('cancel click')"
      >
        Cancelar
      </button>
      <button
        [disabled]="loading"
        type="button"
        ngbAutofocus
        class="btn btn-success"
        (click)="onSubmit()"
      >
        <span
          *ngIf="loading"
          class="spinner-border spinner-border-sm mr-1"
        ></span>
        Aceptar
      </button>
    </div>
  </form>
