<div class="modal-header">
    <h4 class="modal-title" id="modal-title">Cambiar revisor de sustancias</h4>
    <button
      type="button"
      class="close"
      aria-label="Close button"
      aria-describedby="modal-title"
      (click)="modal.dismiss('Cross click')"
    >
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  
  <form [formGroup]="form" (ngSubmit)="onSubmit()">
    <div class="modal-body">
      <p>
        <strong
          >¿Estás seguro de que deseas cambiar al revisor de sustancias actual asignado
          <span class="text-primary">"{{ name }}"</span>?</strong
        >
      </p>
  
      <div class="form-group">
        <label for="newReviewer"
          >Asignar nuevo revisor de sustancias<span class="text-danger">*</span></label
        >
        <select
          formControlName="newReviewer"
          class="form-control"
        >
          <option
            *ngFor="let r of reviewers"
            [value]="r.id"
          >
            {{ r.name }}
          </option>
        </select>
        <div *ngIf="submitted && f.newReviewer.errors">
          <div *ngIf="f.newReviewer.errors.required" class="text-danger">
            <small>Nuevo revisor de sustancias requerido</small>
          </div>
        </div>
      </div>
  
      <p class="text-muted">
        <small><i>Para continuar confirme su contraseña.</i></small>
      </p>
  
      <div class="form-group reviewer">
        <span class="icon-eye" (click)="showPasswordReviewer()">
            <i class="{{isShowPasswordReviewer ? 'fas fa-eye-slash' : 'fas fa-eye'}}"></i>
          </span>
        <input
          type = "{{isShowPasswordReviewer ? 'text' : 'password'}}"
          formControlName="password"
          class="form-control"
          [ngClass]="{
            'is-invalid': (submitted && f.password.errors) || forbiddenId
          }"
        />
        <div *ngIf="submitted && f.password.errors" class="invalid-feedback">
          <div *ngIf="f.password.errors.required">
            Contraseña requerida
          </div>
        </div>
        <div *ngIf="forbiddenId" class="invalid-feedback">
          <div>
            Contraseña incorrecta
          </div>
        </div>
      </div>
    </div>
    <div class="modal-footer">
      <button
        type="button"
        class="btn btn-outline-secondary"
        (click)="modal.dismiss('cancel click')"
      >
        Cancelar
      </button>
      <button
        [disabled]="loading"
        type="button"
        ngbAutofocus
        class="btn btn-primary"
        (click)="onSubmit()"
      >
        <span
          *ngIf="loading"
          class="spinner-border spinner-border-sm mr-1"
        ></span>
        Aceptar
      </button>
    </div>
  </form>
  