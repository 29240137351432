import { Component } from '@angular/core';
import { first } from 'rxjs/operators';

import { User } from '@app/core/models';
import { UsersService } from '@app/core/services';
import { AuthenticationService } from '@app/core/services';

@Component({ templateUrl: 'home.component.html', styleUrls: ['../../app.component.scss'] })
export class HomeComponent {
    user: User;
    loading = false;
    modules = []
    type = ''

    constructor(private authenticationService: AuthenticationService, private usersService: UsersService) {
        this.user = this.authenticationService.userValue;
        switch (this.user.type) {
            case "ROOT":
                this.type = 'Super Administrador'
                this.modules = [
                    {
                        title: "Operadores",
                        iconUrl: "/assets/icons/customers.png",
                        ref: "/operadores"
                    },
                    {
                        title: "Solicitudes",
                        iconUrl: "/assets/icons/lista.png",
                        ref: "/solicitudes"
                    },
                    {
                        title: "Revisión de sustancias",
                        iconUrl: "/assets/icons/analisis.png",
                        ref: "/sustancias"
                    },
                    {
                        title: "Revisiones",
                        iconUrl: "/assets/icons/portapapeles.png",
                        ref: "/revisiones"
                    },
                    {
                        title: "Inspecciones",
                        iconUrl: "/assets/icons/buscar.png",
                        ref: "/inspecciones"
                    },
                    {
                        title: "Dictámenes",
                        iconUrl: "/assets/icons/ley.png",
                        ref: "/dictamenes"
                    },
                    {
                        title: "Certificados",
                        iconUrl: "/assets/icons/codigo-qr.png",
                        ref: "/certificados"
                    },
                    {
                        title: "Expedientes",
                        iconUrl: "/assets/icons/documento.png",
                        ref: "/expedientes"
                    },
                    {
                        title: "Cotizador",
                        iconUrl: "/assets/icons/dinero.png",
                        ref: "/cotizador"
                    },
                    {
                        title: "Catálogos",
                        iconUrl: "/assets/icons/seleccionar.png",
                        ref: "/catalogos"
                    },
                    {
                        title: "Etiquetas",
                        iconUrl: "/assets/icons/etiqueta-engomada.png",
                        ref: "/etiquetas"
                    },
                    {
                        title: "No Conformidades",
                        iconUrl: "/assets/icons/ncs.png",
                        ref: "/no_conformidades"
                    },
                    {
                        title: "Configuración",
                        iconUrl: "/assets/icons/proceso.png",
                        ref: "/configuracion"
                    },
                    {
                        title: "Usuarios",
                        iconUrl: "/assets/icons/usuario.png",
                        ref: "/usuarios"
                    },
                    {
                        title: "Perfil",
                        iconUrl: "/assets/icons/profile-user.png",
                        ref: "/perfil"
                    },
                    {
                        title: "Emails",
                        iconUrl: "/assets/icons/email.png",
                        ref: "/emails"
                    },
                    {
                        title: "Reportes",
                        iconUrl: "/assets/icons/pie-chart.png",
                        ref: "/reportes"
                    },
                    {
                        title: "Support Utilities",
                        iconUrl: "/assets/icons/support.png",
                        ref: "/support_utilities"
                    },
                    /* {
                        title: "Regenerar Documentos",
                        iconUrl: "/assets/icons/editar.png",
                        ref: "/regenerar_docs"
                    } */
                ]
                break;
            case "OPERADOR":
                this.type = 'Operador'
                this.modules = [
                    {
                        title: "Solicitudes",
                        iconUrl: "/assets/icons/lista.png",
                        ref: "/solicitudes"
                    },
                    {
                        title: "Inspecciones",
                        iconUrl: "/assets/icons/buscar.png",
                        ref: "/inspecciones"
                    },
                    {
                        title: "Certificados",
                        iconUrl: "/assets/icons/codigo-qr.png",
                        ref: "/certificados"
                    },
                    {
                        title: "Expedientes",
                        iconUrl: "/assets/icons/documento.png",
                        ref: "/expedientes"
                    },
                    {
                        title: "No Conformidades",
                        iconUrl: "/assets/icons/ncs.png",
                        ref: "/no_conformidades"
                    },
                    {
                        title: "Perfil",
                        iconUrl: "/assets/icons/profile-user.png",
                        ref: "/perfil"
                    }
                ]
                break;
            case "CAPTURISTA":
                this.type = 'Capturista'
                this.modules = [
                    {
                        title: "Operadores",
                        iconUrl: "/assets/icons/customers.png",
                        ref: "/operadores"
                    },
                    {
                        title: "Solicitudes",
                        iconUrl: "/assets/icons/lista.png",
                        ref: "/solicitudes"
                    },
                    {
                        title: "Expedientes",
                        iconUrl: "/assets/icons/documento.png",
                        ref: "/expedientes"
                    },
                    {
                        title: "Perfil",
                        iconUrl: "/assets/icons/profile-user.png",
                        ref: "/perfil"
                    },                    
                    {
                        title: "Emails",
                        iconUrl: "/assets/icons/email.png",
                        ref: "/emails"
                    }
                ]
                break;
            case "RESPONSABLE":
                this.type = 'Responsable'
                this.modules = [
                    {
                        title: "Operadores",
                        iconUrl: "/assets/icons/customers.png",
                        ref: "/operadores"
                    },
                    {
                        title: "Solicitudes",
                        iconUrl: "/assets/icons/lista.png",
                        ref: "/solicitudes"
                    },
                    {
                        title: "Inspecciones",
                        iconUrl: "/assets/icons/buscar.png",
                        ref: "/inspecciones"
                    },
                    {
                        title: "Certificados",
                        iconUrl: "/assets/icons/codigo-qr.png",
                        ref: "/certificados"
                    },
                    {
                        title: "Expedientes",
                        iconUrl: "/assets/icons/documento.png",
                        ref: "/expedientes"
                    },
                    {
                        title: "Cotizador",
                        iconUrl: "/assets/icons/dinero.png",
                        ref: "/cotizador"
                    },
                    {
                        title: "Etiquetas",
                        iconUrl: "/assets/icons/etiqueta-engomada.png",
                        ref: "/etiquetas"
                    },
                    {
                        title: "No Conformidades",
                        iconUrl: "/assets/icons/ncs.png",
                        ref: "/no_conformidades"
                    },
                    {
                        title: "Configuración",
                        iconUrl: "/assets/icons/proceso.png",
                        ref: "/configuracion"
                    },
                    {
                        title: "Perfil",
                        iconUrl: "/assets/icons/profile-user.png",
                        ref: "/perfil"
                    },
                    {
                        title: "Emails",
                        iconUrl: "/assets/icons/email.png",
                        ref: "/emails"
                    }
                ]
                break;
            case "SUSTANCIAS":
                this.type = "Sustancias"
                this.modules = [
                    {
                        title: "Revisión de sustancias",
                        iconUrl: "/assets/icons/analisis.png",
                        ref: "/sustancias"
                    },
                    {
                        title: "Inspecciones",
                        iconUrl: "/assets/icons/buscar.png",
                        ref: "/inspecciones"
                    },
                    {
                        title: "Expedientes",
                        iconUrl: "/assets/icons/documento.png",
                        ref: "/expedientes"
                    },
                    {
                        title: "Perfil",
                        iconUrl: "/assets/icons/profile-user.png",
                        ref: "/perfil"
                    }
                ]
                break
            case "ADMINISTRADOR":
                this.type = 'Administrador'
                this.modules = [
                    {
                        title: "Operadores",
                        iconUrl: "/assets/icons/customers.png",
                        ref: "/operadores"
                    },
                    {
                        title: "Certificados",
                        iconUrl: "/assets/icons/codigo-qr.png",
                        ref: "/certificados"
                    },
                    {
                        title: "Expedientes",
                        iconUrl: "/assets/icons/documento.png",
                        ref: "/expedientes"
                    },
                    {
                        title: "Cotizador",
                        iconUrl: "/assets/icons/dinero.png",
                        ref: "/cotizador"
                    },
                    {
                        title: "Etiquetas",
                        iconUrl: "/assets/icons/etiqueta-engomada.png",
                        ref: "/etiquetas"
                    },
                    {
                        title: "Configuración",
                        iconUrl: "/assets/icons/proceso.png",
                        ref: "/configuracion"
                    },
                    {
                        title: "Usuarios",
                        iconUrl: "/assets/icons/usuario.png",
                        ref: "/usuarios"
                    },
                    {
                        title: "Perfil",
                        iconUrl: "/assets/icons/profile-user.png",
                        ref: "/perfil"
                    }
                ]
                break;
            case "DIRECTOR_CERTIFICACION":
                this.type = 'Supervisor'
                this.modules = [
                    {
                        title: "Operadores",
                        iconUrl: "/assets/icons/customers.png",
                        ref: "/operadores"
                    },
                    {
                        title: "Solicitudes",
                        iconUrl: "/assets/icons/lista.png",
                        ref: "/solicitudes"
                    },
                    {
                        title: "Revisión de sustancias",
                        iconUrl: "/assets/icons/analisis.png",
                        ref: "/sustancias"
                    },
                    {
                        title: "Revisiones",
                        iconUrl: "/assets/icons/portapapeles.png",
                        ref: "/revisiones"
                    },
                    {
                        title: "Inspecciones",
                        iconUrl: "/assets/icons/buscar.png",
                        ref: "/inspecciones"
                    },
                    {
                        title: "Dictámenes",
                        iconUrl: "/assets/icons/ley.png",
                        ref: "/dictamenes"
                    },
                    {
                        title: "Certificados",
                        iconUrl: "/assets/icons/codigo-qr.png",
                        ref: "/certificados"
                    },
                    {
                        title: "Expedientes",
                        iconUrl: "/assets/icons/documento.png",
                        ref: "/expedientes"
                    },
                    {
                        title: "Cotizador",
                        iconUrl: "/assets/icons/dinero.png",
                        ref: "/cotizador"
                    },
                    {
                        title: "Catálogos",
                        iconUrl: "/assets/icons/seleccionar.png",
                        ref: "/catalogos"
                    },
                    {
                        title: "Etiquetas",
                        iconUrl: "/assets/icons/etiqueta-engomada.png",
                        ref: "/etiquetas"
                    },
                    {
                        title: "No Conformidades",
                        iconUrl: "/assets/icons/ncs.png",
                        ref: "/no_conformidades"
                    },
                    {
                        title: "Configuración",
                        iconUrl: "/assets/icons/proceso.png",
                        ref: "/configuracion"
                    },
                    {
                        title: "Usuarios",
                        iconUrl: "/assets/icons/usuario.png",
                        ref: "/usuarios"
                    },
                    {
                        title: "Perfil",
                        iconUrl: "/assets/icons/profile-user.png",
                        ref: "/perfil"
                    },                    
                    {
                        title: "Reportes",
                        iconUrl: "/assets/icons/pie-chart.png",
                        ref: "/reportes"
                    },
                    {
                        title: "Emails",
                        iconUrl: "/assets/icons/email.png",
                        ref: "/emails"
                    }
                ]
                break;
            case "REVISOR":
                this.type = 'Revisor'
                this.modules = [
                    {
                        title: "Revisiones",
                        iconUrl: "/assets/icons/portapapeles.png",
                        ref: "/revisiones"
                    },
                    {
                        title: "Inspecciones",
                        iconUrl: "/assets/icons/buscar.png",
                        ref: "/inspecciones"
                    },
                    {
                        title: "Expedientes",
                        iconUrl: "/assets/icons/documento.png",
                        ref: "/expedientes"
                    },
                    {
                        title: "Perfil",
                        iconUrl: "/assets/icons/profile-user.png",
                        ref: "/perfil"
                    }
                ]
                break;
            case "INSPECTOR":
                this.type = 'Inspector'
                this.modules = [
                    {
                        title: "Inspecciones",
                        iconUrl: "/assets/icons/buscar.png",
                        ref: "/inspecciones"
                    },
                    {
                        title: "Perfil",
                        iconUrl: "/assets/icons/profile-user.png",
                        ref: "/perfil"
                    },
                ]
                break;
            case "DICTAMINADOR":
                this.type = 'Dictaminador'
                this.modules = [
                    {
                        title: "Inspecciones",
                        iconUrl: "/assets/icons/buscar.png",
                        ref: "/inspecciones"
                    },
                    {
                        title: "Dictámenes",
                        iconUrl: "/assets/icons/ley.png",
                        ref: "/dictamenes"
                    },
                    {
                        title: "Expedientes",
                        iconUrl: "/assets/icons/documento.png",
                        ref: "/expedientes"
                    },
                    {
                        title: "Perfil",
                        iconUrl: "/assets/icons/profile-user.png",
                        ref: "/perfil"
                    }
                ]
                break;
            case "REVISOR_DICTAMINADOR":
                this.type = 'Revisor-Dictaminador'
                this.modules = [
                    {
                        title: "Operadores",
                        iconUrl: "/assets/icons/customers.png",
                        ref: "/operadores"
                    },
                    {
                        title: "Solicitudes",
                        iconUrl: "/assets/icons/lista.png",
                        ref: "/solicitudes"
                    },
                    {
                        title: "Revisión de sustancias",
                        iconUrl: "/assets/icons/analisis.png",
                        ref: "/sustancias"
                    },
                    {
                        title: "Revisiones",
                        iconUrl: "/assets/icons/portapapeles.png",
                        ref: "/revisiones"
                    },
                    {
                        title: "Inspecciones",
                        iconUrl: "/assets/icons/buscar.png",
                        ref: "/inspecciones"
                    },
                    {
                        title: "Dictámenes",
                        iconUrl: "/assets/icons/ley.png",
                        ref: "/dictamenes"
                    },
                    {
                        title: "Certificados",
                        iconUrl: "/assets/icons/codigo-qr.png",
                        ref: "/certificados"
                    },
                    {
                        title: "Expedientes",
                        iconUrl: "/assets/icons/documento.png",
                        ref: "/expedientes"
                    },
                    {
                        title: "Perfil",
                        iconUrl: "/assets/icons/profile-user.png",
                        ref: "/perfil"
                    },                    
                    {
                        title: "Emails",
                        iconUrl: "/assets/icons/email.png",
                        ref: "/emails"
                    }
                ]
                break;
            case "OBSERVADOR":
                this.type = 'Observador'
                this.modules = [
                    {
                        title: "Expedientes",
                        iconUrl: "/assets/icons/documento.png",
                        ref: "/expedientes"
                    },
                    {
                        title: "Perfil",
                        iconUrl: "/assets/icons/profile-user.png",
                        ref: "/perfil"
                    }
                ]
                break;
            case "SECRETARIA":
                    this.type = 'Secretaria'
                    this.modules = [
                        {
                            title: "Expedientes",
                            iconUrl: "/assets/icons/documento.png",
                            ref: "/expedientes"
                        },
                        {
                            title: "Perfil",
                            iconUrl: "/assets/icons/profile-user.png",
                            ref: "/perfil"
                        }
                    ]
                    break;
            default:
                break;
        }

        sessionStorage.clear()
    }

}