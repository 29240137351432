import { Component, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';


@Component({
  selector: 'modal-ncs-documents',
  templateUrl: 'modal-ncs-documents.component.html',
  styleUrls: ['../../../app.component.scss']
})
export class ModalNcsDocuments {
  @Input() public nc_urls = []
  @Input() public code = ''
  @Input() public name = ''

  indexNC = 0

  constructor(
      public modal: NgbActiveModal,
  ) {}

  show(i) {
    console.log(this.nc_urls, i)
    if(this.nc_urls.length) this.indexNC = i
  }
}