import { AlignmentType, Document, Paragraph, TextRun, Media, Header, Footer, Table, TableRow, TableCell, VerticalAlign, WidthType, PageNumber, ShadingType, PageOrientation, UnderlineType } from "docx";
import { logo } from "../logo";
import { userWithoutSign } from "../userWithoutSign"
import { DatePipe } from '@angular/common';

export class DocumentCreatorMTO_FSOL_04_4_v3 {

    public getBase64FromUrl = async (url) => {
        const data = await fetch(url, {
            headers: {
                'Cache-Control': 'no-cache, no-store, must-revalidate',
                'Pragma': 'no-cache',
                'Expires': 'Sat, 01 Jan 2000 00:00:00 GMT',
                'If-Modified-Since': '0'
            }
        });
        const blob = await data.blob();
        return new Promise((resolve) => {
            const reader = new FileReader();
            reader.readAsDataURL(blob);
            reader.onloadend = function() {
                const base64data = reader.result;
                resolve(base64data);
            }
        });
    }

    // INIT MTO_FSOL_04_1
    public async create_MTO_FSOL_04_4(data, signature = ''): Promise<Document> {
        const document = new Document();
        const image = Media.addImage(document, logo, 50, 50);
        var datePipe = new DatePipe('es-MX');
        let signatureImage = null
        if (signature) {
            signatureImage = await this.getBase64FromUrl(signature)
        } else {
            signatureImage = userWithoutSign
        }

        document.addSection({
            headers: {
                default: new Header({
                    children: [
                        new Paragraph({ alignment: AlignmentType.CENTER, children: [image], }),
                        new Paragraph({
                            alignment: AlignmentType.CENTER, children: [
                                new TextRun({
                                    children: [`MTO-FSOL-04-4 | R 00 | 15-Mayo-2022 | Pág. `, PageNumber.CURRENT],
                                    font: 'Book Antiqua',
                                    size: 20,
                                    bold: true
                                }),
                                new TextRun({
                                    font: 'Book Antiqua',
                                    size: 20,
                                    bold: true,
                                    children: [' de ', PageNumber.TOTAL_PAGES]
                                }),
                            ], spacing: { before: 200, after: 100 }
                        })
                    ]
                })
            },
            footers: {
                default: new Footer({
                    children: [
                        new Paragraph({ alignment: AlignmentType.CENTER }),
                        this.createFooter()
                    ]
                }),
            },
            children: [
                new Paragraph({
                    alignment: AlignmentType.CENTER,
                    children: [
                        new TextRun({
                            text: 'ANÁLISIS DE RIESGOS PARA PARCELAS SOLICITANTES DE RECONOCIMIENTO RETROACTIVO',
                            font: 'Calibri (Cuerpo)',
                            size: 22,
                            bold: true
                        })
                    ],
                    spacing: { after: 200 }
                }),
                this.createTableUDP(data),
                new Paragraph({
                    alignment: AlignmentType.CENTER,
                    children: [
                        new TextRun({
                            text: '',
                            font: 'Calibri (Cuerpo)',
                            size: 22,
                            bold: true
                        })
                    ],
                    spacing: { after: 400 }
                }),
                this.createTableFases(data),

                new Paragraph({
                    alignment: AlignmentType.LEFT,
                    children: [
                        new TextRun({
                            text: 'I = Implementado, NI = No Implementado, C = Conforme, NC = No Conforme, NA = No Aplica',
                            font: 'Calibri (Body)',
                            size: 20
                        })
                    ],
                    spacing: { after: 200 }
                }),
                new Paragraph({
                    alignment: AlignmentType.LEFT,
                    children: [
                        new TextRun({
                            text: '____________________________________________________________________________________________________________________________________________________',
                            font: 'Calibri (Body)',
                            size: 20
                        })
                    ],
                    spacing: { after: 400 }
                }),
                new Paragraph({
                    alignment: AlignmentType.CENTER,
                    children: [
                        signatureImage ? Media.addImage(document, signatureImage.toString(), 200, 100) : new TextRun({
                            text: ''
                        })
                    ]
                }),
                new Paragraph({
                    alignment: AlignmentType.LEFT,
                    children: [
                        new TextRun({
                            text: `Fecha: `,
                            font: 'Arial',
                            size: 20,
                            italics: true
                        }),
                        new TextRun({
                            text: `                  ${datePipe.transform(new Date(data.record.Applications[0].Creation_Date.includes('UTC') ? data.record.Applications[0].Creation_Date.replace('UTC', '').trim() : data.record.Applications[0].Creation_Date), 'd-MMMM-y')}                  `,
                            font: 'Arial',
                            size: 20,
                            underline: {
                                color: '#000000',
                                type: UnderlineType.SINGLE
                            }
                        }),
                        new TextRun({
                            text: `                                  Nombre y Firma del Operador: `,
                            font: 'Arial',
                            size: 20,
                            italics: true
                        }),
                        new TextRun({
                            text: `                  ${data.customer.Legal_Authorities[0].Name + " " + data.customer.Legal_Authorities[0].Middle_Name + " " + data.customer.Legal_Authorities[0].Last_Name}                  `,
                            font: 'Arial',
                            size: 20,
                            underline: {
                                color: '#000000',
                                type: UnderlineType.SINGLE
                            }
                        }),
                    ],
                    spacing: {
                        after: 200,
                        before: 400
                    }
                })
            ],
            margins: {
                top: 350,
                bottom: 500,
                left: 1000,
                right: 1000
            },
            size: {
                orientation: PageOrientation.LANDSCAPE,
            }
        });

        return document;
    }

    public createFooter(): Table {
        const table = new Table({
            rows: [
                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: 'Elaboró:',
                                            font: 'Calibri (Cuerpo)',
                                            size: 22
                                        })
                                    ]
                                })
                            ],
                            width: {
                                size: 33,
                                type: WidthType.PERCENTAGE
                            }
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: 'Revisó:',
                                            font: 'Calibri (Cuerpo)',
                                            size: 22
                                        })
                                    ]
                                })
                            ],
                            width: {
                                size: 33,
                                type: WidthType.PERCENTAGE
                            }
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: 'Autorizó:',
                                            font: 'Calibri (Cuerpo)',
                                            size: 22
                                        })
                                    ]
                                }),
                            ],
                            width: {
                                size: 33,
                                type: WidthType.PERCENTAGE
                            }
                        })
                    ]
                }),
                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: 'Director de certificación',
                                            font: 'Calibri (Cuerpo)',
                                            size: 16,
                                            bold: true
                                        })
                                    ]
                                }),

                            ],
                            width: {
                                size: 33,
                                type: WidthType.PERCENTAGE
                            },
                            shading: {
                                fill: '#C0C0C0'
                            }
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: 'Responsable de calidad',
                                            font: 'Calibri (Cuerpo)',
                                            size: 16,
                                            bold: true
                                        })
                                    ]
                                }),

                            ],
                            width: {
                                size: 33,
                                type: WidthType.PERCENTAGE
                            },
                            shading: {
                                fill: '#C0C0C0'
                            }
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: 'Director general',
                                            font: 'Calibri (Cuerpo)',
                                            size: 16,
                                            bold: true
                                        })
                                    ]
                                }),
                            ],
                            width: {
                                size: 33,
                                type: WidthType.PERCENTAGE
                            },
                            shading: {
                                fill: '#C0C0C0'
                            }
                        })
                    ]
                })
            ],
            margins: {
                left: 70,
                right: 70
            },
            columnWidths: [3213, 3213, 3212],
            alignment: AlignmentType.CENTER
        })
        return table
    }

    public createTableUDP(data): Table {
        const table = new Table({
            rows: [
                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: 'Nombre del operador:',
                                            font: 'Calibri (Body)',
                                            size: 22
                                        })
                                    ]
                                }),
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: data.customer.Company_Name,
                                            font: 'Calibri (Body)',
                                            size: 22
                                        })
                                    ]
                                })
                            ]
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: 'Código del operador:',
                                            font: 'Calibri (Body)',
                                            size: 22
                                        })
                                    ]
                                }),
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: data.customer.Id_Comercial,
                                            font: 'Calibri (Body)',
                                            size: 22
                                        })
                                    ]
                                })
                            ]
                        })
                    ]
                })
            ],
            margins: {
                left: 70,
                right: 70
            },
            columnWidths: [4819, 4819],
            alignment: AlignmentType.CENTER
        })
        return table
    }

    public createTableFases(data): Table {
        const table = new Table({
            rows: [
                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: 'Criterio',
                                            font: 'Calibri (Body)',
                                            size: 18,
                                            bold: true
                                        })
                                    ]
                                })
                            ],
                            shading: {
                                fill: '#C0C0C0',
                                val: ShadingType.PERCENT_90
                            },
                            verticalAlign: VerticalAlign.CENTER
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: 'Condición',
                                            font: 'Calibri (Body)',
                                            size: 18,
                                            bold: true
                                        })
                                    ]
                                })
                            ],
                            shading: {
                                fill: '#C0C0C0',
                                val: ShadingType.PERCENT_90
                            },
                            verticalAlign: VerticalAlign.CENTER
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: 'Evidencia',
                                            font: 'Calibri (Body)',
                                            size: 18,
                                            bold: true
                                        })
                                    ]
                                })
                            ],
                            shading: {
                                fill: '#C0C0C0',
                                val: ShadingType.PERCENT_90
                            },
                            verticalAlign: VerticalAlign.CENTER
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: 'Verificación MTO (Revisor)',
                                            font: 'Calibri (Body)',
                                            size: 18,
                                            bold: true
                                        })
                                    ]
                                })
                            ],
                            shading: {
                                fill: '#C0C0C0',
                                val: ShadingType.PERCENT_90
                            },
                            columnSpan: 2,
                            verticalAlign: VerticalAlign.CENTER
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: 'Observaciones',
                                            font: 'Calibri (Body)',
                                            size: 18,
                                            bold: true
                                        })
                                    ]
                                })
                            ],
                            shading: {
                                fill: '#C0C0C0',
                                val: ShadingType.PERCENT_90
                            },
                            verticalAlign: VerticalAlign.CENTER
                        }),
                    ]
                }),
                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: 'Propiedad de la tierra',
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                })
                            ]
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: ` ${this.getValue(data.submission, 'condicion_propiedad_tierra_propio') ? '■' : '❏'} Terreno propio`,
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                }),
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: ` ${this.getValue(data.submission, 'condicion_propiedad_tierra_rentado') ? '■' : '❏'} Terreno rentado`,
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                }),
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: ` ${this.getValue(data.submission, 'condicion_propiedad_tierra_otro') ? '■' : '❏'} Otro: ${this.getValue(data.submission, 'condicion_propiedad_tierra_otro_texto')}`,
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                })
                            ]
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: ` ${this.getValue(data.submission, 'evidencia_propiedad_tierra_escritura') ? '■' : '❏'} Escritura, título o similares`,
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                }),
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: ` ${this.getValue(data.submission, 'evidencia_propiedad_tierra_contrato') ? '■' : '❏'} Contrato de arrendamiento`,
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                }),
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: ` ${this.getValue(data.submission, 'evidencia_propiedad_tierra_otro') ? '■' : '❏'} Otra: ${this.getValue(data.submission, 'evidencia_propiedad_tierra_otro_texto')}`,
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                })
                            ]
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: ` ${this.getValue(data.submission, 'propiedad_tierra_implementacion') === 'I' ? '■' : '❏'} I`,
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                }),
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: ` ${this.getValue(data.submission, 'propiedad_tierra_implementacion') === 'NI' ? '■' : '❏'} NI`,
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                })
                            ]
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: ` ${this.getValue(data.submission, 'propiedad_tierra_conformidad') === 'C' ? '■' : '❏'} C`,
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                }),
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: ` ${this.getValue(data.submission, 'propiedad_tierra_conformidad') === 'NC' ? '■' : '❏'} NC`,
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                }),
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: ` ${this.getValue(data.submission, 'propiedad_tierra_conformidad') === 'NA' ? '■' : '❏'} NA`,
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                })
                            ]
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: this.getValue(data.submission, 'propiedad_tierra_comentarios_inspector'),
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                })
                            ]
                        })
                    ]
                }),



                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: 'Tamaño de la superficie',
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                })
                            ]
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: ` ${this.getValue(data.submission, 'condicion_superficie_20_menos') ? '■' : '❏'} Parcela de 20 ha o menos`,
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                }),
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: ` ${this.getValue(data.submission, 'condicion_superficie_20_mas') ? '■' : '❏'} Parcela de más de 20 ha`,
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                }),
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: ` ${this.getValue(data.submission, 'condicion_superficie_otro') ? '■' : '❏'} Otro: ${this.getValue(data.submission, 'condicion_superficie_otro_texto')}`,
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                })
                            ]
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: ` ${this.getValue(data.submission, 'evidencia_superficie_escritura') ? '■' : '❏'} Escritura, título o similares`,
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                }),
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: ` ${this.getValue(data.submission, 'evidencia_superficie_otro') ? '■' : '❏'} Otra: ${this.getValue(data.submission, 'evidencia_superficie_otro_texto')}`,
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                })
                            ]
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: ` ${this.getValue(data.submission, 'superficie_implementacion') === 'I' ? '■' : '❏'} I`,
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                }),
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: ` ${this.getValue(data.submission, 'superficie_implementacion') === 'NI' ? '■' : '❏'} NI`,
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                })
                            ]
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: ` ${this.getValue(data.submission, 'superficie_conformidad') === 'C' ? '■' : '❏'} C`,
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                }),
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: ` ${this.getValue(data.submission, 'superficie_conformidad') === 'NC' ? '■' : '❏'} NC`,
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                }),
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: ` ${this.getValue(data.submission, 'superficie_conformidad') === 'NA' ? '■' : '❏'} NA`,
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                })
                            ]
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: this.getValue(data.submission, 'superficie_comentarios_inspector'),
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                })
                            ]
                        })
                    ]
                }),
                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: 'Uso previo (3  años)',
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                })
                            ]
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: ` ${this.getValue(data.submission, 'condicion_uso_previo_sin_uso') ? '■' : '❏'} Sin uso`,
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                }),
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: ` ${this.getValue(data.submission, 'condicion_uso_previo_potrero_bosque') ? '■' : '❏'} Usado para potrero o bosque`,
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                }),
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: ` ${this.getValue(data.submission, 'condicion_uso_previo_cultivos_naturales') ? '■' : '❏'} Usado para cultivos naturales (autoconsumo, recolección)`,
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                }),
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: ` ${this.getValue(data.submission, 'condicion_uso_previo_otro') ? '■' : '❏'} Otro: ${this.getValue(data.submission, 'condicion_uso_previo_otro_texto')}`,
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                })
                            ]
                        }),
                        
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: ` ${this.getValue(data.submission, 'evidencia_uso_previo_fotografias') ? '■' : '❏'} Fotografías con fecha`,
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                }),
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: ` ${this.getValue(data.submission, 'evidencia_uso_previo_bitacoras_general') ? '■' : '❏'} Bitácoras de manejo general`,
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                }),
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: ` ${this.getValue(data.submission, 'evidencia_uso_previo_bitacoras_insumos') ? '■' : '❏'} Bitácoras de insumos usados`,
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                }),
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: ` ${this.getValue(data.submission, 'evidencia_uso_previo_analisis') ? '■' : '❏'} Análisis de pesticidas`,
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                }),
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: ` ${this.getValue(data.submission, 'evidencia_uso_previo_constancia') ? '■' : '❏'} Constancia de autoridades`,
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                }),
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: ` ${this.getValue(data.submission, 'evidencia_uso_previo_carta_firmada') ? '■' : '❏'} Carta firmada por arrendatario o dueño anterior`,
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                }),
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: ` ${this.getValue(data.submission, 'evidencia_uso_previo_otro') ? '■' : '❏'} Otro: ${this.getValue(data.submission, 'evidencia_uso_previo_otro_texto')}`,
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                })
                            ]
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: ` ${this.getValue(data.submission, 'uso_previo_implementacion') === 'I' ? '■' : '❏'} I`,
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                }),
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: ` ${this.getValue(data.submission, 'uso_previo_implementacion') === 'NI' ? '■' : '❏'} NI`,
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                })
                            ]
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: ` ${this.getValue(data.submission, 'uso_previo_conformidad') === 'C' ? '■' : '❏'} C`,
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                }),
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: ` ${this.getValue(data.submission, 'uso_previo_conformidad') === 'NC' ? '■' : '❏'} NC`,
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                }),
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: ` ${this.getValue(data.submission, 'uso_previo_conformidad') === 'NA' ? '■' : '❏'} NA`,
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                })
                            ]
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: this.getValue(data.submission, 'uso_previo_comentarios_inspector'),
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                })
                            ]
                        })
                    ]
                }),
                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: 'Uso de terrenos vecinos',
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                })
                            ]
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: ` ${this.getValue(data.submission, 'condicion_terreno_vecino_sin_uso') ? '■' : '❏'}  Sin uso`,
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                }),
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: ` ${this.getValue(data.submission, 'condicion_terreno_vecino_potrero_bosque') ? '■' : '❏'} Usado para potrero o bosque`,
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                }),
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: ` ${this.getValue(data.submission, 'condicion_terreno_vecino_cultivos_naturales') ? '■' : '❏'} Usado para cultivos naturales (autoconsumo, recolección)`,
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                }),
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: ` ${this.getValue(data.submission, 'condicion_terreno_vecino_cultivos_convencionales') ? '■' : '❏'} Usado para cultivos convencionales`,
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                }),
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: ` ${this.getValue(data.submission, 'condicion_terreno_vecino_otro') ? '■' : '❏'} Otro: ${this.getValue(data.submission, 'condicion_terreno_vecino_otro_texto')}`,
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                })
                            ]
                        }),
                        
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: ` ${this.getValue(data.submission, 'evidencia_terreno_vecino_fotografias') ? '■' : '❏'} Fotografías con fecha`,
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                }),
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: ` ${this.getValue(data.submission, 'evidencia_terreno_vecino_bitacoras_general') ? '■' : '❏'} Bitácoras de manejo general`,
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                }),
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: ` ${this.getValue(data.submission, 'evidencia_terreno_vecino_bitacoras_insumos') ? '■' : '❏'} Bitácoras de insumos usados`,
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                }),
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: ` ${this.getValue(data.submission, 'evidencia_terreno_vecino_analisis') ? '■' : '❏'} Análisis de pesticidas`,
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                }),
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: ` ${this.getValue(data.submission, 'evidencia_terreno_vecino_constancia') ? '■' : '❏'} Constancia de autoridades`,
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                }),
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: ` ${this.getValue(data.submission, 'evidencia_terreno_vecino_carta_firmada') ? '■' : '❏'} Carta firmada por vecino`,
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                }),
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: ` ${this.getValue(data.submission, 'evidencia_terreno_vecino_otro') ? '■' : '❏'} Otro: ${this.getValue(data.submission, 'evidencia_terreno_vecino_otro_texto')}`,
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                })
                            ]
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: ` ${this.getValue(data.submission, 'terreno_vecino_implementacion') === 'I' ? '■' : '❏'} I`,
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                }),
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: ` ${this.getValue(data.submission, 'terreno_vecino_implementacion') === 'NI' ? '■' : '❏'} NI`,
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                })
                            ]
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: ` ${this.getValue(data.submission, 'terreno_vecino_conformidad') === 'C' ? '■' : '❏'} C`,
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                }),
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: ` ${this.getValue(data.submission, 'terreno_vecino_conformidad') === 'NC' ? '■' : '❏'} NC`,
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                }),
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: ` ${this.getValue(data.submission, 'terreno_vecino_conformidad') === 'NA' ? '■' : '❏'} NA`,
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                })
                            ]
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: this.getValue(data.submission, 'terreno_vecino_comentarios_inspector'),
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                })
                            ]
                        })
                    ]
                }),
                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: 'Comunicación con el responsable de la parcela',
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                })
                            ]
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: ` ${this.getValue(data.submission, 'condicion_responsable_parcela_duenio') ? '■' : '❏'} El dueño es el mismo responsable`,
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                }),
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: ` ${this.getValue(data.submission, 'condicion_responsable_parcela_notificacion') ? '■' : '❏'} El responsable recibió por escrito notificación`,
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                }),
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: ` ${this.getValue(data.submission, 'condicion_responsable_parcela_capacitacion') ? '■' : '❏'} El responsable recibió capacitación sobre los requisitos orgánicos`,
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                }),
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: ` ${this.getValue(data.submission, 'condicion_responsable_parcela_otro') ? '■' : '❏'} Otro: ${this.getValue(data.submission, 'condicion_responsable_parcela_otro_texto')}`,
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                })
                            ]
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: ` ${this.getValue(data.submission, 'evidencia_responsable_parcela_carta_correo') ? '■' : '❏'} Carta, correo o mensaje`,
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                }),
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: ` ${this.getValue(data.submission, 'evidencia_responsable_parcela_capacitacion') ? '■' : '❏'} Constancia de capacitación`,
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                }),
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: ` ${this.getValue(data.submission, 'evidencia_responsable_parcela_otro') ? '■' : '❏'} Otro: ${this.getValue(data.submission, 'evidencia_responsable_parcela_otro_texto')}`,
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                })
                            ]
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: ` ${this.getValue(data.submission, 'responsable_parcela_implementacion') === 'I' ? '■' : '❏'} I`,
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                }),
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: ` ${this.getValue(data.submission, 'responsable_parcela_implementacion') === 'NI' ? '■' : '❏'} NI`,
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                })
                            ]
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: ` ${this.getValue(data.submission, 'responsable_parcela_conformidad') === 'C' ? '■' : '❏'} C`,
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                }),
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: ` ${this.getValue(data.submission, 'responsable_parcela_conformidad') === 'NC' ? '■' : '❏'} NC`,
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                }),
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: ` ${this.getValue(data.submission, 'responsable_parcela_conformidad') === 'NA' ? '■' : '❏'} NA`,
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                })
                            ]
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: this.getValue(data.submission, 'responsable_parcela_comentarios_inspector'),
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                })
                            ]
                        })
                    ]
                }),
                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: 'Disponibilidad de insumos',
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                })
                            ]
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: ` ${this.getValue(data.submission, 'condicion_disponibilidad_insumos_almacen_organicos') ? '■' : '❏'} En el almacén de insumos solo hay insumos orgánicos`,
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                }),
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: ` ${this.getValue(data.submission, 'condicion_disponibilidad_insumos_almacen_mixto') ? '■' : '❏'} El almacén es de uso mixto`,
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                }),
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: ` ${this.getValue(data.submission, 'condicion_disponibilidad_insumos_lista_autorizados') ? '■' : '❏'} El responsable recibió lista de insumos autorizados`,
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                }),
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: ` ${this.getValue(data.submission, 'condicion_disponibilidad_insumos_otro') ? '■' : '❏'} Otro: ${this.getValue(data.submission, 'condicion_disponibilidad_insumos_otro_texto')}`,
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                })
                            ]
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: ` ${this.getValue(data.submission, 'evidencia_disponibilidad_insumos_verificaion_fisica') ? '■' : '❏'} Verificación física`,
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                }),
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: ` ${this.getValue(data.submission, 'evidencia_disponibilidad_insumos_senialamientos_almacen') ? '■' : '❏'} Señalamientos en almacén`,
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                }),
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: ` ${this.getValue(data.submission, 'evidencia_disponibilidad_insumos_comunicado_listas') ? '■' : '❏'} Comunicado que incluye lista de insumos autorizados`,
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                }),
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: ` ${this.getValue(data.submission, 'evidencia_disponibilidad_insumos_otro') ? '■' : '❏'} Otro: ${this.getValue(data.submission, 'evidencia_disponibilidad_insumos_otro_texto')}`,
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                })
                            ]
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: ` ${this.getValue(data.submission, 'disponibilidad_insumos_implementacion') === 'I' ? '■' : '❏'} I`,
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                }),
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: ` ${this.getValue(data.submission, 'disponibilidad_insumos_implementacion') === 'NI' ? '■' : '❏'} NI`,
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                })
                            ]
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: ` ${this.getValue(data.submission, 'disponibilidad_insumos_conformidad') === 'C' ? '■' : '❏'} C`,
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                }),
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: ` ${this.getValue(data.submission, 'disponibilidad_insumos_conformidad') === 'NC' ? '■' : '❏'} NC`,
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                }),
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: ` ${this.getValue(data.submission, 'disponibilidad_insumos_conformidad') === 'NA' ? '■' : '❏'} NA`,
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                })
                            ]
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: this.getValue(data.submission, 'disponibilidad_insumos_comentarios_inspector'),
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                })
                            ]
                        })
                    ]
                }),
                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: 'Registros disponibles',
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                })
                            ]
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: ` ${this.getValue(data.submission, 'condicion_registros_personal') ? '■' : '❏'} Personal asignado para el llenado de registros`,
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                }),
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: ` ${this.getValue(data.submission, 'condicion_registros_procedimientos') ? '■' : '❏'} Procedimientos operativos que describen las actividades de elaboración y verificación de registros`,
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                }),
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: ` ${this.getValue(data.submission, 'condicion_registros_otro') ? '■' : '❏'} Otro: ${this.getValue(data.submission, 'condicion_registros_otro_texto')}`,
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                })
                            ]
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: ` ${this.getValue(data.submission, 'evidencia_registros_capacitacion') ? '■' : '❏'} Registros de capacitación`,
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                }),
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: ` ${this.getValue(data.submission, 'evidencia_registros_bitacoras') ? '■' : '❏'} Bitácoras de manejo`,
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                }),
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: ` ${this.getValue(data.submission, 'evidencia_registros_cosechas_ventas') ? '■' : '❏'} Registros de cosecha y venta de producto de zonas buffer`,
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                }),
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: ` ${this.getValue(data.submission, 'evidencia_registros_acuerdos') ? '■' : '❏'} Acuerdos firmados`,
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                }),
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: ` ${this.getValue(data.submission, 'evidencia_registros_equipo') ? '■' : '❏'} Equipo etiquetado`,
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                }),
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: ` ${this.getValue(data.submission, 'evidencia_registros_otro') ? '■' : '❏'} Otro: ${this.getValue(data.submission, 'evidencia_registros_otro_texto')}`,
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                })
                            ]
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: ` ${this.getValue(data.submission, 'registros_implementacion') === 'I' ? '■' : '❏'} I`,
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                }),
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: ` ${this.getValue(data.submission, 'registros_implementacion') === 'NI' ? '■' : '❏'} NI`,
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                })
                            ]
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: ` ${this.getValue(data.submission, 'registros_conformidad') === 'C' ? '■' : '❏'} C`,
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                }),
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: ` ${this.getValue(data.submission, 'registros_conformidad') === 'NC' ? '■' : '❏'} NC`,
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                }),
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: ` ${this.getValue(data.submission, 'registros_conformidad') === 'NA' ? '■' : '❏'} NA`,
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                })
                            ]
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: this.getValue(data.submission, 'registros_comentarios_inspector'),
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                })
                            ]
                        })
                    ]
                }),
            ],
            columnWidths: [3417,3418,3418,1053, 1053,2507],
            alignment: AlignmentType.CENTER
        })
        return table
    }

    getValue(submission, name) {
        var value = null
        for (const i in submission) {
            const element = submission[i]
            if (element.name === name) {
                value = element.value
            }
        }
        if (value) return value
        else return ''
    }

    getValueList(submission, name) {
        var value = null
        for (const i in submission) {
            const element = submission[i]
            if (element.name === name) {
                value = element.value
            }
        }
        if (value) return value
        else return []
    }
    // END MTO_FSOL_04_1
}
