<div class="mb-3" [formGroup]="form">
    <div id="dynamic-container" class="row overflow-auto" style="max-height: 35vh;">
        <table class="table">
            <thead class="thead-dark">
                <tr>
                    <th *ngIf="attributes.numbered">#</th>
                    <th *ngFor="let header of attributes.header">{{header[0]}}</th>
                    <th *ngIf="!attributes.fixed">Acciones</th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let formGroup of formArray.controls; let i = index;" [ngStyle]="{'background': currentIndex === i ? 'forestgreen' : 'none'}">
                    <td *ngIf="attributes.numbered" [ngStyle]="{'color': currentIndex === i ? 'white' : 'black'}">{{i + 1}}</td>
                    <td *ngFor="let header of attributes.header" [ngStyle]="{'color': currentIndex === i ? 'white' : 'black'}">{{formGroup.get(header[1]) && formGroup.get(header[1]).value ? formGroup.get(header[1]).value.substring(0, 24) : '...'}}</td>
                    <td *ngIf="!attributes.fixed">
                        <button type="button" class="btn btn-sm" [class]="currentIndex === i ? 'btn-success' : 'btn-outline-success'" (click)="changeIndex(i)">{{currentIndex === i ? 'Activo' : 'Revisar'}}</button>
                        <button type="button" class="btn btn-sm btn-danger ml-2" *ngIf="attributes.removable" (click)="deleteGroup(i)">Eliminar</button>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
    
    <div class="card mb-3">
        <div class="card-body">
            <label>Replicar datos de:</label>
            <select class="form-control browser-default select" (change)="copy($event)">
                <option style="display: none;">Seleccione una opción</option>
                <option *ngFor="let option of formArray.controls">{{option.get(attributes.header[0][1]) && option.get(attributes.header[0][1]).value ? option.get(attributes.header[0][1]).value.substring(0, 24) : '...'}}</option>
            </select>
        </div>
    </div>

    <div *ngIf="!attributes.no_body" class="card">
        <div class="card-body">
            <div [formArrayName]="attributes.group_name">
                <containers-fabric *ngFor="let formGroup of formArray.controls; let i = index;"
                    [content]="data.content" [formGroup]="formGroup" [ngClass]="{'d-none': i !== currentIndex}">
                </containers-fabric>
            </div>
        </div>
        <div *ngIf="attributes.addible !== false" class="row">
            <div class="col-lg-12 d-flex justify-content-center">
                <button  class="btn btn-small btn-info mb-3" *ngIf="!attributes.fixed" (click)="addFormGroup($event)">{{attributes.btn_title ? attributes.btn_title : 'Añadir'}}</button>
            </div>
        </div>
    </div>
</div>