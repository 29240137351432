import { ContainerElement, UnknownParams } from './../../../interfaces/form.interface';
import { Component, OnInit, Input } from '@angular/core'
import { FormGroup } from '@angular/forms';

@Component({
    selector: 'section-container',
    templateUrl: './section.component.html'
})
export class SectionComponent implements OnInit {

    attributes: UnknownParams = {}

    @Input() data: ContainerElement
    @Input() form: FormGroup

    constructor() {}

    ngOnInit() {
        for (var i in this.data.attributes) {
            const attribute = this.data.attributes[i]
            this.attributes[attribute.name] = attribute.value
        }
    }

}