import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '@environments/environment';

@Injectable({ providedIn: 'root' })
export class WebhooksService {

    constructor( private http: HttpClient ) { }

    getMails() {
        const jwtToken = (document.cookie.split(';').find(x => x.includes('jwtToken')) || '=').split('=')[1]

        const headers = {
            'Content-Type': 'application/json',
            'Authorization': jwtToken
        };

        return this.http.get<any>(`${environment.apiUrl}/webhooks/get_mails`, { headers });
    }
}