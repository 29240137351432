import { AlignmentType, Document, Paragraph, TextRun, Media, Header, Footer, Table, TableRow, TableCell, WidthType, PageNumber, ShadingType } from "docx";
import { logo } from "../logo";
import { userWithoutSign } from "../userWithoutSign"
import { DatePipe } from '@angular/common';

export class DocumentCreatorMTO_FINS_02_v2 {

    public getBase64FromUrl = async (url) => {
        const data = await fetch(url, {
            headers: {
                'Cache-Control': 'no-cache, no-store, must-revalidate',
                'Pragma': 'no-cache',
                'Expires': 'Sat, 01 Jan 2000 00:00:00 GMT',
                'If-Modified-Since': '0'
            }
        });
        const blob = await data.blob();
        return new Promise((resolve) => {
            const reader = new FileReader();
            reader.readAsDataURL(blob); 
            reader.onloadend = function() {
                const base64data = reader.result;   
                resolve(base64data);
            }
        });
    }

    // INIT MTO_FINS_02
    public async create_MTO_FINS_02(data, signature = ''): Promise<Document> {
        const document = new Document();
        const image = Media.addImage(document, logo, 50, 50);
        var datePipe = new DatePipe('es-MX');
        let signatureImage = null
        if (signature) {
            signatureImage = await this.getBase64FromUrl(signature)
        } else {
            signatureImage = userWithoutSign
        }

        document.addSection({
            headers: {
                default: new Header({
                    children: [
                        new Paragraph({ alignment: AlignmentType.CENTER, children: [image], }),
                        new Paragraph({
                            alignment: AlignmentType.CENTER, children: [
                                new TextRun({
                                    children: [`MTO-FINS-02 | R 03 | 28-Septiembre-2021 | Pág. `, PageNumber.CURRENT],
                                    font: 'Book Antiqua',
                                    size: 20,
                                    bold: true
                                }),
                                new TextRun({
                                    font: 'Book Antiqua',
                                    size: 20,
                                    bold: true,
                                    children: [' de ', PageNumber.TOTAL_PAGES]
                                }),
                            ], spacing: { before: 200, after: 100 }
                        })
                    ]
                })
            },
            footers: {
                default: new Footer({
                    children: [
                        new Paragraph({ alignment: AlignmentType.CENTER }),
                        this.createFooter()
                    ]
                }),
            },
            children: [
                new Paragraph({
                    alignment: AlignmentType.CENTER,
                    children: [
                        new TextRun({
                            text: 'PLAN DE INSPECCIÓN ORGÁNICA',
                            font: 'Calibri (Cuerpo)',
                            size: 22,
                            bold: true
                        })
                    ],
                    spacing: { after: 200 }
                }),
                this.createTableUDP(data, datePipe),
                new Paragraph({
                    spacing: {
                        after: 0,
                        before: 0
                    }
                }),
                this.createCronograma(data),
                new Paragraph({
                    spacing: {
                        after: 0,
                        before: 0
                    }
                }),
                this.createInstalaciones(data),
                new Paragraph({
                    spacing: {
                        after: 0,
                        before: 0
                    }
                }),
                new Paragraph({
                    alignment: AlignmentType.CENTER,
                    children: [
                        signatureImage ? Media.addImage(document, signatureImage.toString(), 100, 50) : new TextRun({
                            text: ''
                        })
                    ]
                }),
                new Paragraph({
                    alignment: AlignmentType.LEFT,
                    children: [
                        new TextRun({
                            text: `Fecha: ${this.getValue(data.submission, 'fecha_inspeccion')}                  Nombre y Firma del Inspector: ${this.getValue(data.submission, 'nombre_inspector')}`,
                            font: 'Arial',
                            size: 20,
                            italics: true
                        })
                    ],
                    spacing: {
                        after: 200,
                        before: 400
                    }
                })
            ],
            margins: {
                top: 350,
                bottom: 500,
                left: 1000,
                right: 1000
            }
        });

        return document;
    }

    public createFooter(): Table {
        const table = new Table({
            rows: [
                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: 'Elaboró:',
                                            font: 'Calibri (Cuerpo)',
                                            size: 22
                                        })
                                    ]
                                })
                            ],
                            width: {
                                size: 33,
                                type: WidthType.PERCENTAGE
                            }
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: 'Revisó:',
                                            font: 'Calibri (Cuerpo)',
                                            size: 22
                                        })
                                    ]
                                })
                            ],
                            width: {
                                size: 33,
                                type: WidthType.PERCENTAGE
                            }
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: 'Autorizó:',
                                            font: 'Calibri (Cuerpo)',
                                            size: 22
                                        })
                                    ]
                                }),
                            ],
                            width: {
                                size: 33,
                                type: WidthType.PERCENTAGE
                            }
                        })
                    ]
                }),
                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: 'Director de certificación',
                                            font: 'Calibri (Cuerpo)',
                                            size: 16,
                                            bold: true
                                        })
                                    ]
                                }),

                            ],
                            width: {
                                size: 33,
                                type: WidthType.PERCENTAGE
                            },
                            shading: {
                                fill: '#C0C0C0'
                            }
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: 'Responsable de calidad',
                                            font: 'Calibri (Cuerpo)',
                                            size: 16,
                                            bold: true
                                        })
                                    ]
                                }),

                            ],
                            width: {
                                size: 33,
                                type: WidthType.PERCENTAGE
                            },
                            shading: {
                                fill: '#C0C0C0'
                            }
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: 'Director general',
                                            font: 'Calibri (Cuerpo)',
                                            size: 16,
                                            bold: true
                                        })
                                    ]
                                }),
                            ],
                            width: {
                                size: 33,
                                type: WidthType.PERCENTAGE
                            },
                            shading: {
                                fill: '#C0C0C0'
                            }
                        })
                    ]
                })
            ],
            margins: {
                left: 70,
                right: 70
            },
            columnWidths: [3213, 3213, 3212]
        })
        return table
    }

    public createTableUDP(data, datePipe): Table {
        let opt14 = ""
        this.getValueList(data.submission, 'opt14_cat_productos').forEach(item => {
           opt14 = opt14 + item.producto + " "
        });
        let opt15 = ""
        this.getValueList(data.submission, 'opt15_cat_productos').forEach(item => {
           opt15 = opt15 + item.producto + " "
        });
        let opt16 = ""
        this.getValueList(data.submission, 'opt16_cat_productos').forEach(item => {
           opt16 = opt16 + item.producto + " "
        });
        let opt17 = ""
        this.getValueList(data.submission, 'opt17_cat_productos').forEach(item => {
           opt17 = opt17 + item.producto + " "
        });
        let opt18 = ""
        this.getValueList(data.submission, 'opt18_cat_productos').forEach(item => {
           opt18 = opt18 + item.producto + " "
        });
        let opt19 = ""
        this.getValueList(data.submission, 'opt19_cat_productos').forEach(item => {
           opt19 = opt19 + item.producto + " "
        });
        let opt20 = ""
        this.getValueList(data.submission, 'opt20_cat_productos').forEach(item => {
           opt20 = opt20 + item.producto + " "
        });
        let opt21 = ""
        this.getValueList(data.submission, 'opt21_cat_productos').forEach(item => {
           opt21 = opt21 + item.producto + " "
        });
        let opt22 = ""
        this.getValueList(data.submission, 'opt22_cat_productos').forEach(item => {
           opt22 = opt22 + item.producto + " "
        });
        let opt23 = ""
        this.getValueList(data.submission, 'opt23_cat_productos').forEach(item => {
           opt23 = opt23 + item.producto + " "
        });
        let opt24 = ""
        this.getValueList(data.submission, 'opt24_cat_productos').forEach(item => {
           opt24 = opt24 + item.producto + " "
        });
        let opt25 = ""
        this.getValueList(data.submission, 'opt25_cat_productos').forEach(item => {
           opt25 = opt25 + item.producto + " "
        });
        let opt26 = ""
        this.getValueList(data.submission, 'opt26_cat_productos').forEach(item => {
           opt26 = opt26 + item.producto + " "
        });
        let opt28 = ""
        this.getValueList(data.submission, 'opt28_cat_productos').forEach(item => {
           opt28 = opt28 + item.producto + " "
        });
        let opt29 = ""
        this.getValueList(data.submission, 'opt29_cat_productos').forEach(item => {
           opt29 = opt29 + item.producto + " "
        });
        let opt30 = ""
        this.getValueList(data.submission, 'opt30_cat_productos').forEach(item => {
           opt30 = opt30 + item.producto + " "
        });
        let opt31 = ""
        this.getValueList(data.submission, 'opt31_cat_productos').forEach(item => {
           opt31 = opt31 + item.producto + " "
        });
        let opt32 = ""
        this.getValueList(data.submission, 'opt32_cat_productos').forEach(item => {
           opt32 = opt32 + item.producto + " "
        });
        let opt33 = ""
        this.getValueList(data.submission, 'opt33_cat_productos').forEach(item => {
           opt33 = opt33 + item.producto + " "
        });
        let opt34 = ""
        this.getValueList(data.submission, 'opt34_cat_productos').forEach(item => {
           opt34 = opt34 + item.producto + " "
        });

        const table = new Table({
            rows: [
                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: 'Operador:',
                                            font: 'Arial',
                                            size: 22,
                                            bold: true
                                        })
                                    ],
                                    spacing: { before: 100, after: 200 }
                                })
                            ]
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: data.customer.Company_Name,
                                            font: 'Arial',
                                            size: 22
                                        })
                                    ],
                                    spacing: { before: 100, after: 200 }
                                })
                            ],
                            columnSpan: 4
                        })
                    ]
                }),
                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: 'Código de operación:',
                                            font: 'Arial',
                                            size: 22,
                                            bold: true
                                        })
                                    ],
                                    spacing: { before: 100, after: 200 }
                                })
                            ]
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: data.customer.Id_Comercial,
                                            font: 'Arial',
                                            size: 22
                                        })
                                    ],
                                    spacing: { before: 100, after: 200 }
                                })
                            ]
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: 'Fecha de inspección:',
                                            font: 'Arial',
                                            size: 22,
                                            bold: true
                                        })
                                    ],
                                    spacing: { before: 100, after: 200 }
                                })
                            ]
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: this.getValue(data.submission, 'fecha_inspeccion'),
                                            font: 'Arial',
                                            size: 22
                                        })
                                    ],
                                    spacing: { before: 100, after: 200 }
                                })
                            ],
                            columnSpan: 2
                        })
                    ]
                }),
                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: 'Inspector:',
                                            font: 'Arial',
                                            size: 22,
                                            bold: true
                                        })
                                    ],
                                    spacing: { before: 100, after: 200 }
                                })
                            ]
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: this.getValue(data.submission, 'nombre_inspector'),
                                            font: 'Arial',
                                            size: 22
                                        })
                                    ],
                                    spacing: { before: 100, after: 200 }
                                })
                            ],
                            columnSpan: 4
                        })
                    ]
                }),
                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: 'Alcance:',
                                            font: 'Arial',
                                            size: 22,
                                            bold: true
                                        })
                                    ],
                                    spacing: { before: 100, after: 200 }
                                })
                            ],
                            rowSpan: 11
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: 'Proceso LPO',
                                            font: 'Arial',
                                            size: 22,
                                            bold: true
                                        })
                                    ],
                                    spacing: { before: 100, after: 200 }
                                })
                            ],
                            shading: {
                                fill: '#000000',
                                val: ShadingType.PERCENT_80
                            }
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: 'Proceso COR/USCOEA',
                                            font: 'Arial',
                                            size: 22,
                                            bold: true
                                        })
                                    ],
                                    spacing: { before: 100, after: 200 }
                                })
                            ],
                            shading: {
                                fill: '#000000',
                                val: ShadingType.PERCENT_80
                            }
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: 'Producto',
                                            font: 'Arial',
                                            size: 22,
                                            bold: true
                                        })
                                    ],
                                    spacing: { before: 100, after: 200 }
                                })
                            ],
                            columnSpan: 2,
                            shading: {
                                fill: '#000000',
                                val: ShadingType.PERCENT_80
                            }
                        })
                    ]
                }),
                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: `${this.getValue(data.submission, 'opt14_cat') || this.getValue(data.submission, 'opt15_cat') || this.getValue(data.submission, 'opt16_cat') ? '■' : '❏'} Producción vegetal`,
                                            font: 'Calibri (Body)',
                                            size: 20
                                        })
                                    ],
                                    spacing: { before: 100, after: 200 }
                                }),
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: `     ${this.getValue(data.submission, 'opt14_cat')  ? '■' : '❏'} Granos básicos y frutales`,
                                            font: 'Calibri (Body)',
                                            size: 20
                                        })
                                    ],
                                    spacing: { before: 100, after: 200 }
                                }),
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: `     ${this.getValue(data.submission, 'opt15_cat') ? '■' : '❏'} Estructuras y contenedores`,
                                            font: 'Calibri (Body)',
                                            size: 20
                                        })
                                    ],
                                    spacing: { before: 100, after: 200 }
                                }),
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: `     ${this.getValue(data.submission, 'opt16_cat') ? '■' : '❏'} Germinados`,
                                            font: 'Calibri (Body)',
                                            size: 20
                                        })
                                    ],
                                    spacing: { before: 100, after: 200 }
                                })
                            ]
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: `${this.getValue(data.submission, 'opt25_cat') ? '■' : '❏'} Producción agrícola`,
                                            font: 'Calibri (Body)',
                                            size: 20
                                        })
                                    ],
                                    spacing: { before: 100, after: 200 }
                                }),
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: `${this.getValue(data.submission, 'opt26_cat') ? '■' : '❏'} Producción de alimentos para el ganado`,
                                            font: 'Calibri (Body)',
                                            size: 20
                                        })
                                    ],
                                    spacing: { before: 100, after: 200 }
                                })
                            ]
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: opt14 + opt15 + opt16 + opt15 + opt25 + opt26,
                                            font: 'Calibri (Body)',
                                            size: 20
                                        })
                                    ],
                                    spacing: { before: 100, after: 200 }
                                })
                            ],
                            columnSpan: 2
                        })
                    ]
                }),
                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: `${this.getValue(data.submission, 'opt23_cat') ? '■' : '❏'} Procesamiento de productos de las actividades agropecuarias`,
                                            font: 'Calibri (Body)',
                                            size: 20
                                        })
                                    ],
                                    spacing: { before: 100, after: 200 }
                                })
                            ]
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: `${this.getValue(data.submission, 'opt32_cat') ? '■' : '❏'} Productos procesados`,
                                            font: 'Calibri (Body)',
                                            size: 20
                                        })
                                    ],
                                    spacing: { before: 100, after: 200 }
                                })
                            ]
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: opt23 + opt32,
                                            font: 'Calibri (Body)',
                                            size: 20
                                        })
                                    ],
                                    spacing: { before: 100, after: 200 }
                                })
                            ],
                            columnSpan: 2
                        })
                    ]
                }),
                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: `${this.getValue(data.submission, 'opt24_cat') ? '■' : '❏'} Comercialización de productos de las actividades agropecuarias`,
                                            font: 'Calibri (Body)',
                                            size: 20
                                        })
                                    ],
                                    spacing: { before: 100, after: 200 }
                                })
                            ]
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: ``,
                                            font: 'Calibri (Body)',
                                            size: 20
                                        })
                                    ],
                                    spacing: { before: 100, after: 200 }
                                })
                            ]
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: opt24,
                                            font: 'Calibri (Body)',
                                            size: 20
                                        })
                                    ],
                                    spacing: { before: 100, after: 200 }
                                })
                            ],
                            columnSpan: 2
                        })
                    ]
                }),
                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: `${this.getValue(data.submission, 'opt18_cat') ? '■' : '❏'} Producción animal (domésticos)`,
                                            font: 'Calibri (Body)',
                                            size: 20
                                        })
                                    ],
                                    spacing: { before: 100, after: 200 }
                                })
                            ]
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: ``,
                                            font: 'Calibri (Body)',
                                            size: 20
                                        })
                                    ],
                                    spacing: { before: 100, after: 200 }
                                })
                            ]
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: opt18,
                                            font: 'Calibri (Body)',
                                            size: 20
                                        })
                                    ],
                                    spacing: { before: 100, after: 200 }
                                })
                            ],
                            columnSpan: 2
                        })
                    ]
                }),
                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: `${this.getValue(data.submission, 'opt19_cat') ? '■' : '❏'} Producción animal de ecosistemas naturales o no domésticos`,
                                            font: 'Calibri (Body)',
                                            size: 20
                                        })
                                    ],
                                    spacing: { before: 100, after: 200 }
                                })
                            ]
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: ``,
                                            font: 'Calibri (Body)',
                                            size: 20
                                        })
                                    ],
                                    spacing: { before: 100, after: 200 }
                                })
                            ]
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: opt19,
                                            font: 'Calibri (Body)',
                                            size: 20
                                        })
                                    ],
                                    spacing: { before: 100, after: 200 }
                                })
                            ],
                            columnSpan: 2
                        })
                    ]
                }),
                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: `${this.getValue(data.submission, 'opt22_cat') ? '■' : '❏'} Productos de la clase fungi`,
                                            font: 'Calibri (Body)',
                                            size: 20
                                        })
                                    ],
                                    spacing: { before: 100, after: 200 }
                                })
                            ]
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: ``,
                                            font: 'Calibri (Body)',
                                            size: 20
                                        })
                                    ],
                                    spacing: { before: 100, after: 200 }
                                })
                            ]
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: opt22,
                                            font: 'Calibri (Body)',
                                            size: 20
                                        })
                                    ],
                                    spacing: { before: 100, after: 200 }
                                })
                            ],
                            columnSpan: 2
                        })
                    ]
                }),
                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: `${this.getValue(data.submission, 'opt17_cat') ? '■' : '❏'} Productos vegetales de recolección silvestre`,
                                            font: 'Calibri (Body)',
                                            size: 20
                                        })
                                    ],
                                    spacing: { before: 100, after: 200 }
                                })
                            ]
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: ``,
                                            font: 'Calibri (Body)',
                                            size: 20
                                        })
                                    ],
                                    spacing: { before: 100, after: 200 }
                                })
                            ]
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: opt17,
                                            font: 'Calibri (Body)',
                                            size: 20
                                        })
                                    ],
                                    spacing: { before: 100, after: 200 }
                                })
                            ],
                            columnSpan: 2
                        })
                    ]
                }),
                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: `${this.getValue(data.submission, 'opt21_cat') ? '■' : '❏'} Produccion animal acuícola`,
                                            font: 'Calibri (Body)',
                                            size: 20
                                        })
                                    ],
                                    spacing: { before: 100, after: 200 }
                                })
                            ]
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: ``,
                                            font: 'Calibri (Body)',
                                            size: 20
                                        })
                                    ],
                                    spacing: { before: 100, after: 200 }
                                })
                            ]
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: opt21,
                                            font: 'Calibri (Body)',
                                            size: 20
                                        })
                                    ],
                                    spacing: { before: 100, after: 200 }
                                })
                            ],
                            columnSpan: 2
                        })
                    ]
                }),
                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: `${this.getValue(data.submission, 'opt20_cat') ? '■' : '❏'} Productos de la clase insecta`,
                                            font: 'Calibri (Body)',
                                            size: 20
                                        })
                                    ],
                                    spacing: { before: 100, after: 200 }
                                })
                            ]
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: ``,
                                            font: 'Calibri (Body)',
                                            size: 20
                                        })
                                    ],
                                    spacing: { before: 100, after: 200 }
                                })
                            ]
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: opt20,
                                            font: 'Calibri (Body)',
                                            size: 20
                                        })
                                    ],
                                    spacing: { before: 100, after: 200 }
                                })
                            ],
                            columnSpan: 2
                        })
                    ]
                }),
                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: ``,
                                            font: 'Calibri (Body)',
                                            size: 20
                                        })
                                    ],
                                    spacing: { before: 100, after: 200 }
                                })
                            ]
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: `${this.getValue(data.submission, 'opt28_cat') || this.getValue(data.submission, 'opt29_cat') || this.getValue(data.submission, 'opt30_cat') || this.getValue(data.submission, 'opt31_cat') ? '■' : '❏'} Producción especializada`,
                                            font: 'Calibri (Body)',
                                            size: 20
                                        })
                                    ],
                                    spacing: { before: 100, after: 200 }
                                }),
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: `     ${this.getValue(data.submission, 'opt38_cat') ? '■' : '❏'} Invernaderos y contenedores`,
                                            font: 'Calibri (Body)',
                                            size: 20
                                        })
                                    ],
                                    spacing: { before: 100, after: 200 }
                                }),
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: `     ${this.getValue(data.submission, 'opt39_cat') ? '■' : '❏'} Apicultura`,
                                            font: 'Calibri (Body)',
                                            size: 20
                                        })
                                    ],
                                    spacing: { before: 100, after: 200 }
                                }),
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: `     ${this.getValue(data.submission, 'opt30_cat') ? '■' : '❏'} Vegetales de recolección silvestre`,
                                            font: 'Calibri (Body)',
                                            size: 20
                                        })
                                    ],
                                    spacing: { before: 100, after: 200 }
                                }),
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: `     ${this.getValue(data.submission, 'opt31_cat') ? '■' : '❏'} Germinados`,
                                            font: 'Calibri (Body)',
                                            size: 20
                                        })
                                    ],
                                    spacing: { before: 100, after: 200 }
                                })
                            ]
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: opt28 + opt29 + opt30 + opt31,
                                            font: 'Calibri (Body)',
                                            size: 20
                                        })
                                    ],
                                    spacing: { before: 100, after: 200 }
                                })
                            ],
                            columnSpan: 2
                        })
                    ]
                }),
                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: 'Subdivisión COR/USCOEA:',
                                            font: 'Arial',
                                            size: 22,
                                            bold: true
                                        })
                                    ],
                                    spacing: { before: 100, after: 200 }
                                })
                            ],
                            rowSpan: 2
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: `${this.getValue(data.submission, 'opt33_cat') ? '■' : '❏'} Productos orgánicos`,
                                            font: 'Arial',
                                            size: 22
                                        })
                                    ],
                                    spacing: { before: 100, after: 200 }
                                })
                            ],
                            columnSpan: 2
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: opt33,
                                            font: 'Arial',
                                            size: 22
                                        })
                                    ],
                                    spacing: { before: 100, after: 200 }
                                })
                            ],
                            columnSpan: 2
                        })
                    ]
                }),
                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: `${this.getValue(data.submission, 'opt34_cat') ? '■' : '❏'} Empaque o etiquetado de productos orgánicos`,
                                            font: 'Arial',
                                            size: 22
                                        })
                                    ],
                                    spacing: { before: 100, after: 200 }
                                })
                            ],
                            columnSpan: 2
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: opt34,
                                            font: 'Arial',
                                            size: 22
                                        })
                                    ],
                                    spacing: { before: 100, after: 200 }
                                })
                            ],
                            columnSpan: 2
                        })
                    ]
                }),
                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: 'Tipo de inspección:',
                                            font: 'Arial',
                                            size: 22,
                                            bold: true
                                        })
                                    ],
                                    spacing: { before: 100, after: 200 }
                                })
                            ]
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: `${this.getValue(data.submission, 'tipo_inspeccion') === 'Renovación' ? '■' : '❏'} Renovación`,
                                            font: 'Calibri (Body)',
                                            size: 20
                                        })
                                    ]
                                }),
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: `${this.getValue(data.submission, 'tipo_inspeccion') === 'Primer Inspección' ? '■' : '❏'} Primer Inspección`,
                                            font: 'Calibri (Body)',
                                            size: 20
                                        })
                                    ]
                                }),
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: `${this.getValue(data.submission, 'tipo_inspeccion') === 'Adicional' ? '■' : '❏'} Adicional`,
                                            font: 'Calibri (Body)',
                                            size: 20
                                        })
                                    ]
                                }),
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: `${this.getValue(data.submission, 'tipo_inspeccion') === 'Anunciada' ? '■' : '❏'} Anunciada`,
                                            font: 'Calibri (Body)',
                                            size: 20
                                        })
                                    ]
                                }),
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: `${this.getValue(data.submission, 'tipo_inspeccion') === 'No Anunciada' ? '■' : '❏'} No Anunciada`,
                                            font: 'Calibri (Body)',
                                            size: 20
                                        })
                                    ]
                                })
                            ],
                            columnSpan: 4
                        })
                    ]
                }),
            ],
            margins: {
                left: 70,
                right: 70
            },
            width: {
                size: 100,
                type: WidthType.PERCENTAGE,
            },
            columnWidths: [1712, 3309, 3309, 1509, 1509]
        })
        return table
    }

    public createCronograma(data) {
        const table = new Table({
            rows: [
                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: 'Cronograma de actividades',
                                            font: 'Arial',
                                            size: 20,
                                            bold: true
                                        })
                                    ]
                                })
                            ],
                            shading: {
                                fill: '#C0C0C0',
                                val: ShadingType.PERCENT_80
                            },
                            columnSpan: 4
                        })
                    ]
                }),
                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: 'Hora:',
                                            font: 'Arial',
                                            size: 20,
                                            bold: true
                                        })
                                    ]
                                })
                            ],
                            shading: {
                                fill: '#C0C0C0',
                                val: ShadingType.PERCENT_80
                            }
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: 'Actividad',
                                            font: 'Arial',
                                            size: 20,
                                            bold: true
                                        })
                                    ]
                                })
                            ],
                            shading: {
                                fill: '#C0C0C0',
                                val: ShadingType.PERCENT_80
                            }
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: 'Personal requerido',
                                            font: 'Arial',
                                            size: 20,
                                            bold: true
                                        })
                                    ]
                                })
                            ],
                            shading: {
                                fill: '#C0C0C0',
                                val: ShadingType.PERCENT_80
                            }
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: 'Documentos requeridos',
                                            font: 'Arial',
                                            size: 20,
                                            bold: true
                                        })
                                    ]
                                })
                            ],
                            shading: {
                                fill: '#C0C0C0',
                                val: ShadingType.PERCENT_80
                            }
                        })
                    ]
                }),
            ],
            margins: {
                left: 70,
                right: 70
            },
            width: {
                size: 100,
                type: WidthType.PERCENTAGE,
            },
            columnWidths: [1500, 3917, 3917, 3917]
        })

        const actividades = this.getValueList(data.submission, 'cronograma')
        actividades.forEach( actividad => {
            let doc = actividad["documento"]
            table.addChildElement(
                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: actividad.hora,
                                            font: 'Arial',
                                            size: 20
                                        })
                                    ]
                                })
                            ]
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: actividad.actividad,
                                            font: 'Arial',
                                            size: 20
                                        })
                                    ]
                                })
                            ]
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: actividad.personal,
                                            font: 'Arial',
                                            size: 20
                                        })
                                    ]
                                })
                            ]
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: doc,
                                            font: 'Arial',
                                            size: 20
                                        })
                                        
                                    ]
                                })
                            ]
                        })
                    ]
                })
            )
        })

        return table
    }

    public createInstalaciones(data) {
        const table = new Table({
            rows: [
                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: 'Unidades de producción a inspeccionar:',
                                            font: 'Arial',
                                            size: 20,
                                            bold: true
                                        })
                                    ]
                                })
                            ],
                            shading: {
                                fill: '#C0C0C0',
                                val: ShadingType.PERCENT_80
                            },
                            columnSpan: 3
                        })
                    ]
                }),
                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: 'No',
                                            font: 'Arial',
                                            size: 20
                                        })
                                    ]
                                })
                            ],
                            shading: {
                                fill: '#C0C0C0',
                                val: ShadingType.PERCENT_80
                            }
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: 'Unidad de producción',
                                            font: 'Arial',
                                            size: 20
                                        })
                                    ]
                                })
                            ],
                            shading: {
                                fill: '#C0C0C0',
                                val: ShadingType.PERCENT_80
                            }
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: 'Ubicación',
                                            font: 'Arial',
                                            size: 20
                                        })
                                    ]
                                })
                            ],
                            shading: {
                                fill: '#C0C0C0',
                                val: ShadingType.PERCENT_80
                            }
                        })
                    ]
                }),
            ],
            margins: {
                left: 70,
                right: 70
            },
            width: {
                size: 100,
                type: WidthType.PERCENTAGE,
            },
            columnWidths: [1000, 6127, 6127]
        })

        const unidades = this.getValueList(data.submission, 'unidades')
        unidades.forEach( (unidad, index) => {
            table.addChildElement(
                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: index + 1,
                                            font: 'Arial',
                                            size: 20
                                        })
                                    ]
                                })
                            ]
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: unidad.nombre_unidad_produccion,
                                            font: 'Arial',
                                            size: 20
                                        })
                                    ]
                                })
                            ]
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: unidad.ubicacion,
                                            font: 'Arial',
                                            size: 20
                                        })
                                    ]
                                })
                            ]
                        })
                    ]
                })
            )
        })

        return table
    }

    getValue(submission, name) {
        var value = null
        for (const i in submission) {
            const element = submission[i]
            if (element.name === name) {
                value = element.value
            }
        }
        if (value) return value
        else return ''
    }

    getValueList(submission, name) {
        var value = null
        for (const i in submission) {
            const element = submission[i]
            if (element.name === name) {
                value = element.value
            }
        }
        if (value) return value
        else return []
    }
    // END MTO_FINS_02
}