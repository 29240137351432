import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { environment } from '@environments/environment';
import { Customer } from '@app/core/models';
import { map } from 'rxjs/operators';
import { of } from 'rxjs';
import { email } from '@rxweb/reactive-form-validators';

@Injectable({ providedIn: 'root' })
export class CustomersService {
    customers = []
    constructor(private http: HttpClient) { }

    registerCustomer(values, arrayEmails, arrayNumbers, idFollowup) {
        let body
        if (idFollowup) {
            body = {
                customer: {
                    idCommercial: values.idCommercial,
                    companyType: values.companyType,
                    companyName: values.companyType !== 'PERSONA_MORAL' ? (values.legalName + ' ' + values.legalMiddleName + ' ' + values.legalLastName) : values.companyName,
                    rfc: values.companyType !== 'PERSONA_MORAL' ? values.legalRfc : values.rfc,
                    phone: values.companyType !== 'PERSONA_MORAL' ? values.legalPhone : values.phone,
                    email: values.companyType !== 'PERSONA_MORAL' ? values.legalEmail : values.email,
                    certificateName: values.certificateName,
                    country: values.country,
                    state: values.state,
                    city: values.city,
                    town: values.town,
                    zip: values.zip,
                    address1: values.colonia,
                    address2: values.calle,
                    addressNumberExt: values.addressNumberExt,
                    addressNumberInt: values.addressNumberInt,
                    addressNotes: values.addressNotes,
                    type: values.type
                },
                legal: {
                    name: values.legalName,
                    middleName: values.legalMiddleName,
                    lastName: values.legalLastName,
                    rfc: values.legalRfc,
                    curp: values.legalCurp,
                    phone: values.legalPhone,
                    email: values.legalEmail
                },
                followup: {
                    id: idFollowup,
                    name: values.followupName,
                    phone: values.followupPhone,
                    email: values.followupEmail,
                    alternateEmails: arrayEmails,
                    alternateNumbers: arrayNumbers
                }
            }
        } else {
            body = {
                customer: {
                    idCommercial: values.idCommercial,
                    companyType: values.companyType,
                    companyName: values.companyType !== 'PERSONA_MORAL' ? (values.legalName + ' ' + values.legalMiddleName + ' ' + values.legalLastName) : values.companyName,
                    rfc: values.companyType !== 'PERSONA_MORAL' ? values.legalRfc : values.rfc,
                    phone: values.companyType !== 'PERSONA_MORAL' ? values.legalPhone : values.phone,
                    email: values.companyType !== 'PERSONA_MORAL' ? values.legalEmail : values.email,
                    certificateName: values.certificateName,
                    country: values.country,
                    state: values.state,
                    city: values.city,
                    town: values.town,
                    zip: values.zip,
                    address1: values.colonia,
                    address2: values.calle,
                    addressNumberExt: values.addressNumberExt,
                    addressNumberInt: values.addressNumberInt,
                    addressNotes: values.addressNotes,
                    type: values.type
                },
                legal: {
                    name: values.legalName,
                    middleName: values.legalMiddleName,
                    lastName: values.legalLastName,
                    rfc: values.legalRfc,
                    curp: values.legalCurp,
                    phone: values.legalPhone,
                    email: values.legalEmail
                },
                followup: {
                    name: values.followupName,
                    phone: values.followupPhone,
                    email: values.followupEmail,
                    alternateEmails: arrayEmails,
                    alternateNumbers: arrayNumbers
                }
            }
        }
        const jwtToken = (document.cookie.split(';').find(x => x.includes('jwtToken')) || '=').split('=')[1]

        const headers = {
            'Content-Type': 'application/json',
            'Authorization': jwtToken
        };

        //console.log(body)
        return this.http.post(`${environment.apiUrl}/customers/`, body, { headers })
    }

    getById(id) {
        const jwtToken = (document.cookie.split(';').find(x => x.includes('jwtToken')) || '=').split('=')[1]

        const headers = {
            'Content-Type': 'application/json',
            'Authorization': jwtToken
        };

        return this.http.get<any>(`${environment.apiUrl}/customers/details/${id}`, { headers });
    }

    getAvailable(id) {
        const jwtToken = (document.cookie.split(';').find(x => x.includes('jwtToken')) || '=').split('=')[1]

        const headers = {
            'Content-Type': 'application/json',
            'Authorization': jwtToken
        };

        return this.http.get<any>(`${environment.apiUrl}/customers/idCommercial/${id}`, { headers });
    }

    getCustomersActive(page, search) {
        const jwtToken = (document.cookie.split(';').find(x => x.includes('jwtToken')) || '=').split('=')[1]

        const headers = {
            'Content-Type': 'application/json',
            'Authorization': jwtToken
        };
        
        const body = {
            page: page,
            search: search
        }

        return this.http.post<any>(`${environment.apiUrl}/customers/active`, body, { headers });
    }

    getCustomersPending() {
        const jwtToken = (document.cookie.split(';').find(x => x.includes('jwtToken')) || '=').split('=')[1]

        const headers = {
            'Content-Type': 'application/json',
            'Authorization': jwtToken
        };

        return this.http.get<any>(`${environment.apiUrl}/customers/pending`, { headers });
    }

    cancelCustomerPending(idCustomer) {
        const jwtToken = (document.cookie.split(';').find(x => x.includes('jwtToken')) || '=').split('=')[1]

        const headers = {
            'Content-Type': 'application/json',
            'Authorization': jwtToken
        };
        
        const body = []

        return this.http.patch<any>(`${environment.apiUrl}/customers/cancel/${idCustomer}`, body, { headers });
    }

    getCustomersCancelled() {
        const jwtToken = (document.cookie.split(';').find(x => x.includes('jwtToken')) || '=').split('=')[1]

        const headers = {
            'Content-Type': 'application/json',
            'Authorization': jwtToken
        };

        return this.http.get<any>(`${environment.apiUrl}/customers/cancelled`, { headers });
    }

    getCustomersInactive(page, search) {
        const jwtToken = (document.cookie.split(';').find(x => x.includes('jwtToken')) || '=').split('=')[1]

        const headers = {
            'Content-Type': 'application/json',
            'Authorization': jwtToken
        };
        
        const body = {
            page: page,
            search: search
        }

        return this.http.post<any>(`${environment.apiUrl}/customers/inactive`, body, { headers });
    }

    updateCustomersReactivate(id: string) {
        const jwtToken = (document.cookie.split(';').find(x => x.includes('jwtToken')) || '=').split('=')[1]

        const headers = {
            'Content-Type': 'application/json',
            'authorization': jwtToken
        };

        return this.http.patch<any>(`${environment.apiUrl}/customers/cancelled/${id}/reactivate`, null, { headers });
    }

    updateCustomersRestart(id: string) {
        const jwtToken = (document.cookie.split(';').find(x => x.includes('jwtToken')) || '=').split('=')[1]

        const headers = {
            'Content-Type': 'application/json',
            'Authorization': jwtToken
        };

        return this.http.patch<any>(`${environment.apiUrl}/customers/pending/${id}/restart`, null, { headers });
    }

    uploadSignature(customerId: number, file: File) {
        const jwtToken = (document.cookie.split(';').find(x => x.includes('jwtToken')) || '=').split('=')[1]

        const headers = {
            'Authorization': jwtToken
        };

        const body = new FormData()
        body.append('file', file)

        return this.http.post<any>(`${environment.apiUrl}/customers/${customerId}/signature/upload`, body, { headers });
    }

    getSignature(customerId: number) {
        const jwtToken = (document.cookie.split(';').find(x => x.includes('jwtToken')) || '=').split('=')[1]

        const headers = {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'Authorization': jwtToken,
            'Cache-Control': "no-store"
        }

        return this.http.get<any>(`${environment.apiUrl}/customers/${customerId}/signature/download`, { headers });
    }

    updateCustomer(values, id, arrayEmails, arrayNumbers, idFollowup) {
        let body

        if (idFollowup) {
            body = {
                customer: {
                    idCommercial: values.idCommercial,
                    companyType: values.companyType,
                    companyName: values.companyType !== 'PERSONA_MORAL' ? (values.legalName + ' ' + values.legalMiddleName + ' ' + values.legalLastName) : values.companyName,
                    rfc: values.companyType !== 'PERSONA_MORAL' ? values.legalRfc : values.rfc,
                    phone: values.companyType !== 'PERSONA_MORAL' ? values.legalPhone : values.phone,
                    email: values.companyType !== 'PERSONA_MORAL' ? values.legalEmail : values.email,
                    certificateName: values.certificateName,
                    country: values.country,
                    state: values.state,
                    city: values.city,
                    town: values.town,
                    zip: values.zip,
                    address1: values.colonia,
                    address2: values.calle,
                    addressNumberExt: values.addressNumberExt,
                    addressNumberInt: values.addressNumberInt,
                    addressNotes: values.addressNotes,
                    type: values.type
                },
                legal: {
                    name: values.legalName,
                    middleName: values.legalMiddleName,
                    lastName: values.legalLastName,
                    rfc: values.legalRfc,
                    curp: values.legalCurp,
                    phone: values.legalPhone,
                    email: values.legalEmail
                },
                followup: {
                    id: idFollowup,
                    name: values.followupName,
                    phone: values.followupPhone,
                    email: values.followupEmail,
                    alternateEmails: arrayEmails,
                    alternateNumbers: arrayNumbers
                }
            }
        } else {
            body = {
                customer: {
                    idCommercial: values.idCommercial,
                    companyType: values.companyType,
                    companyName: values.companyType !== 'PERSONA_MORAL' ? (values.legalName + ' ' + values.legalMiddleName + ' ' + values.legalLastName) : values.companyName,
                    rfc: values.companyType !== 'PERSONA_MORAL' ? values.legalRfc : values.rfc,
                    phone: values.companyType !== 'PERSONA_MORAL' ? values.legalPhone : values.phone,
                    email: values.companyType !== 'PERSONA_MORAL' ? values.legalEmail : values.email,
                    certificateName: values.certificateName,
                    country: values.country,
                    state: values.state,
                    city: values.city,
                    town: values.town,
                    zip: values.zip,
                    address1: values.colonia,
                    address2: values.calle,
                    addressNumberExt: values.addressNumberExt,
                    addressNumberInt: values.addressNumberInt,
                    addressNotes: values.addressNotes,
                    type: values.type
                },
                legal: {
                    name: values.legalName,
                    middleName: values.legalMiddleName,
                    lastName: values.legalLastName,
                    rfc: values.legalRfc,
                    curp: values.legalCurp,
                    phone: values.legalPhone,
                    email: values.legalEmail
                },
                followup: {
                    name: values.followupName,
                    phone: values.followupPhone,
                    email: values.followupEmail,
                    alternateEmails: arrayEmails,
                    alternateNumbers: arrayNumbers
                }
            }
        }

        const jwtToken = (document.cookie.split(';').find(x => x.includes('jwtToken')) || '=').split('=')[1]

        const headers = {
            'Content-Type': 'application/json',
            'Authorization': jwtToken
        };

        //console.log(body)
        return this.http.patch(`${environment.apiUrl}/customers/details/${id}`, body, { headers })
    }

    search(term: string) {
        if (term === '') {
            return of([]);
        }
        const jwtToken = (document.cookie.split(';').find(x => x.includes('jwtToken')) || '=').split('=')[1]

        const headers = {
            'Content-Type': 'application/json',
            'Authorization': jwtToken
        };

        return this.http.get(`${environment.apiUrl}/customers/search/followup/${term}`, { headers }).pipe(
            map(response => {
                //console.log(response['data'])
                return response['data']
            })
        );
    }

    searchAll(term: string) {
        if (term === '') {
            return of([]);
        }
        const jwtToken = (document.cookie.split(';').find(x => x.includes('jwtToken')) || '=').split('=')[1]

        const headers = {
            'Content-Type': 'application/json',
            'Authorization': jwtToken
        };

        return this.http.get(`${environment.apiUrl}/customers/searchAll/${term}`, { headers }).pipe(
            map(response => {
                //console.log(response['data'])
                return response['data']
            })
        );
    }

    
}