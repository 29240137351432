<div style="overflow: scroll; max-height: 70vh;" [formGroup]="form">
    <h3>{{attributes.title}}</h3>
    <table class="table" style="margin-top: 20px;">
        <thead *ngIf="attributes.minWidth">
            <tr>
                <th style="width: 20px;" *ngIf="attributes.numbered">#</th>
                <th [ngStyle]="{'min-width': attributes.minWidth}" scope="col" *ngFor="let header of attributes.header">{{header}}</th>
                <th *ngIf="!attributes.fixed">Acciones</th>
            </tr>
        </thead>
        <thead *ngIf="!attributes.minWidth">
            <tr>
                <th style="width: 20px;" *ngIf="attributes.numbered">#</th>
                <th scope="col" *ngFor="let header of attributes.header; let i = index" [ngStyle]="{'min-width': attributes.fixedSize ? attributes.fixedSize[i] : 'unset'}">{{header}}</th>
                <th *ngIf="!attributes.fixed">Acciones</th>
            </tr>
        </thead>
        <tbody [formArrayName]="attributes.group_name">
            <tr *ngFor="let formGroup of formArray.controls; let i = index">
                <td *ngIf="attributes.numbered" style="width: 20px;">{{i + 1}}</td>
                <td class="align-top" *ngFor="let itemx of data.content[0].content" [ngSwitch]="itemx.type">
                    <input-element *ngSwitchCase="'input'" [data]="itemx" [form]="formGroup" (keyup.enter)="addFormGroup($event)"></input-element>
                    <select-element *ngSwitchCase="'select'" [data]="itemx" [form]="formGroup"></select-element>
                    <button-element *ngSwitchCase="'button'" [data]="itemx" [form]="formGroup"></button-element>
                    <checkbox-group-element *ngSwitchCase="'checkbox'" [data]="itemx" [form]="formGroup"></checkbox-group-element>
                    <radio-group-element *ngSwitchCase="'radio'" [data]="itemx" [form]="formGroup"></radio-group-element>
                    <text-element *ngSwitchCase="'text'" [data]="itemx"></text-element>
                    <textarea-element *ngSwitchCase="'textarea'" [data]="itemx" [form]="formGroup"></textarea-element>
                    <file-upload-element *ngSwitchCase="'fileupload'" [data]="itemx" [form]="formGroup"></file-upload-element>
                    <search-element *ngSwitchCase="'search'" [data]="itemx" [form]="formGroup"></search-element>
                    <dynamic-container *ngSwitchCase="'dynamic'" [data]="itemx" [form]="formGroup"></dynamic-container>
                    <multiselect-element *ngSwitchCase="'multiselect'" [data]="itemx" [form]="formGroup"></multiselect-element>
                    <section-container *ngSwitchCase="'section'" [data]="itemx" [form]="formGroup"></section-container>
                    <timepicker-element *ngSwitchCase="'timepicker'" [data]="item" [form]="formGroup"></timepicker-element>
                    <div *ngSwitchDefault>Elemento desconocido</div>
                </td>
                <td *ngIf="!attributes.fixed" style="width: 10%;">
                    <button type="button" class="btn btn-danger btn-block" (click)="deleteGroup(i)">Eliminar</button>
                </td>
            </tr>
        </tbody>
    </table>  
    <div *ngIf="formArray.controls?.length === 0" style="display: flex; flex-direction: column; justify-content: center; align-items: center; margin-bottom: 16px;">
        <span style="opacity: 0.5;">No se tiene ningún registro</span>
    </div>
    <div *ngIf="!attributes.fixed" style="display: flex; flex-direction: column; justify-content: center; align-items: center;" class="mb-2">
        <button type="button" class="btn btn-info" (click)="addFormGroup($event)">{{attributes.btn_title ? attributes.btn_title : 'Añadir'}}</button>
    </div>      
</div>