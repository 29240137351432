import { ControlCommunicationService } from './../../../form-builder/services/control-communication.service';
import { Component, Input, OnInit, EventEmitter, Output, OnChanges } from '@angular/core';

@Component({
    selector: 'form-stepper',
    templateUrl: 'form-stepper.component.html',
    styleUrls: ['./nav.scss']
})
export class FormStepper implements OnInit, OnChanges {
    
    @Input() public forms = []
    @Input() public currentIndex = 0

    @Output() onClick = new EventEmitter<any>()

    constructor(private communication: ControlCommunicationService) { 
        communication.changeEmmited$.subscribe(msg => {
            // if (msg.identifier === 'display-elem') {
            //     this.show(msg.index)
            // } else if (msg.identifier === 'hide-elem') {
            //     this.hide(msg.index)
            // }
        })
    }

    ngOnInit() {
        for (var i in this.forms) {
            this.forms[i] = {
                ...this.forms[i],
                hidden: this.forms[i].submitted ? false : this.forms[i]['mandatory'] !== undefined ? !this.forms[i]['mandatory'] : false
            }
        }
    }

    ngOnChanges() {
        for (var i in this.forms) {
            this.forms[i] = {
                ...this.forms[i],
                hidden: this.forms[i].submitted ? false : this.forms[i]['mandatory'] !== undefined ? !this.forms[i]['mandatory'] : false
            }
        }
    }

    goToForm(index) {
        this.onClick.emit(index)
    }

    hide(index) {
        this.forms[index].hidden = true
    }

    show(index) {
        this.forms[index].hidden = false
    }

}