import {
  AlignmentType,
  BorderStyle,
  Document,
  Footer,
  Header,
  Media,
  PageNumber,
  Paragraph,
  Table,
  TableCell,
  TableRow,
  TextRun,
  VerticalAlign,
  WidthType
} from "docx";
import {logo} from "../logo";
import {userWithoutSign} from "../userWithoutSign"
import {DatePipe} from '@angular/common';

export class DocumentCreatorMTO_FSOL_08_1_3_v2 {

  public getBase64FromUrl = async (url) => {
    const data = await fetch(url, {
      headers: {
          'Cache-Control': 'no-cache, no-store, must-revalidate',
          'Pragma': 'no-cache',
          'Expires': 'Sat, 01 Jan 2000 00:00:00 GMT',
          'If-Modified-Since': '0'
      }
  });
    const blob = await data.blob();
    return new Promise((resolve) => {
      const reader = new FileReader();
      reader.readAsDataURL(blob);
      reader.onloadend = function () {
        const base64data = reader.result;
        resolve(base64data);
      }
    });
  }

  // INIT MTO_FSOL_08_1_3
  public async create_MTO_FSOL_08_1_3(data, isReview = true, signature = '', signature2 = ''): Promise<Document> {
    const document = new Document();
    const image = Media.addImage(document, logo, 50, 50);
    var datePipe = new DatePipe('es-MX');

    let signatureImage = null
    if (signature) {
      signatureImage = await this.getBase64FromUrl(signature)
    } else {
      signatureImage = userWithoutSign
    }

    let signatureImage2 = null
    if (signature2) {
      signatureImage2 = await this.getBase64FromUrl(signature2)
    } else {
      signatureImage2 = isReview ? '' : userWithoutSign
    }

    var unidadesProduccion = ''
    const unidades = this.getValueList(data.submission, "lista_unidades")
    unidades.forEach(unidad => {
      unidadesProduccion += unidad.nombre_unidad_produccion + ', '
    });
    unidadesProduccion = unidadesProduccion.slice(0, -2)

    document.addSection({
      headers: {
        default: new Header({
          children: [
            new Paragraph({alignment: AlignmentType.CENTER, children: [image],}),
            new Paragraph({
              alignment: AlignmentType.CENTER, children: [
                new TextRun({
                  children: [`MTO-FSOL-08-1-3| R 07 | 22-Abril-2021 | Pág. `, PageNumber.CURRENT],
                  font: 'Book Antiqua',
                  size: 20,
                  bold: true
                }),
                new TextRun({
                  font: 'Book Antiqua',
                  size: 20,
                  bold: true,
                  children: [' de ', PageNumber.TOTAL_PAGES]
                }),
              ], spacing: {before: 200, after: 100}
            })
          ]
        })
      },
      footers: {
        default: new Footer({
          children: [
            new Paragraph({alignment: AlignmentType.CENTER}),
            this.createFooter()
          ]
        }),
      },
      children: [
        new Paragraph({
          alignment: AlignmentType.CENTER,
          children: [
            new TextRun({
              text: 'PLAN ORGÁNICO PRODUCCIÓN VEGETAL DE GERMINADOS',
              font: 'Calibri (Cuerpo)',
              size: 22,
              bold: true
            })
          ],
          spacing: {after: 200}
        }),
        this.createTableUDP(data, datePipe),
        new Paragraph({
          spacing: {
            after: 400
          }
        }),
        this.createRegisters_MTO_FSOL_08_1_3(data.submission),
        new Paragraph({
          spacing: {
            after: 400
          }
        }),
        this.createHandling_MTO_FSOL_08_1_3(data.submission),
        new Paragraph({
          alignment: AlignmentType.JUSTIFIED,
          children: [
            new TextRun({
              text: `El que suscribe: ${data.customer.Legal_Authorities[0].Name} ${data.customer.Legal_Authorities[0].Middle_Name} ${data.customer.Legal_Authorities[0].Last_Name}, Titular / Representante Legal de la(s) Unidad(es) de Producción / Empresa: ${data.customer.Company_Type === "PERSONA_FISICA" ? unidadesProduccion : data.customer.Company_Name}, declaro que la información descrita en este Plan Orgánico y documentos adjuntos es verídica, y me comprometo a mantenerlo actualizado, al igual que las actividades descritas en él; con la finalidad de cumplir con la Ley de Productos Orgánicos de México (LPO) y las Regulaciones de Productos Orgánicos de Canadá (COR/USCOEA).`,
              font: 'Arial',
              size: 18
            })
          ],
          spacing: {
            before: 400,
            after: 400
          },
        }),
        new Paragraph({
          alignment: AlignmentType.JUSTIFIED,
          children: [
            new TextRun({
              text: 'Atentamente:',
              font: 'Arial',
              size: 18, bold: true
            })
          ],
          spacing: {
            before: 400,
            after: 400
          },
        }),
        new Paragraph({
          alignment: AlignmentType.CENTER,
          children: [
            signatureImage ? Media.addImage(document, signatureImage.toString(), 200, 100) : new TextRun({
              text: ''
            })
          ]
        }),
        new Paragraph({
          alignment: AlignmentType.LEFT,
          children: [
            new TextRun({
              text: `Fecha: ${datePipe.transform(new Date(data.record.Applications[0].Creation_Date.includes('UTC') ? data.record.Applications[0].Creation_Date.replace('UTC', '').trim() : data.record.Applications[0].Creation_Date), 'd-MMMM-y')}                  Nombre y firma del operador: ${data.customer.Legal_Authorities[0].Name} ${data.customer.Legal_Authorities[0].Middle_Name} ${data.customer.Legal_Authorities[0].Last_Name ? data.customer.Legal_Authorities[0].Last_Name : ''}`,
              font: 'Arial',
              size: 20,
              italics: true
            })
          ],
          spacing: {
            after: 200
          }
        }),
        new Table({
          rows: [
              new TableRow({
                  children: [
                      new TableCell({
                          children: [
                              new Paragraph({
                                  alignment: AlignmentType.CENTER,
                                  children: [
                                      new TextRun({
                                          text: 'EVALUACIÓN POR EL REVISOR',
                                          font: 'Arial',
                                          size: 20,
                                          bold: true
                                      })
                                  ],
                                  spacing: {
                                      after: 75,
                                      before: 75
                                  }
                              }),
                          ],
                          verticalAlign: VerticalAlign.CENTER,
                          columnSpan: 2,
                          shading: {
                              fill: '#C0C0C0'
                          }
                      })
                  ]
              }),
              new TableRow({
                  children: [
                      new TableCell({
                          children: [
                              new Paragraph({
                                  alignment: AlignmentType.RIGHT,
                                  children: [
                                      new TextRun({
                                          text: 'Nombre del Revisor: ',
                                          font: 'Calibri (Cuerpo)',
                                          size: 22,
                                      })
                                  ],
                                  spacing: { before: 100, after: 200 }
                              })],
                          verticalAlign: VerticalAlign.CENTER,
                      }),
                      new TableCell({
                          children: [
                              new Paragraph({
                                  alignment: AlignmentType.LEFT,
                                  children: [
                                      new TextRun({
                                          text: data.reviewer ? data.reviewer.name : '',
                                          font: 'Calibri (Cuerpo)',
                                          size: 22,
                                      })
                                  ],
                                  spacing: { before: 100, after: 200 }
                              })],
                          verticalAlign: VerticalAlign.CENTER,
                      }),
                  ],
              }),
              new TableRow({
                  children: [
                      new TableCell({
                          children: [
                              new Paragraph({
                                  alignment: AlignmentType.RIGHT,
                                  children: [
                                      new TextRun({
                                          text: 'Fecha: ',
                                          font: 'Calibri (Cuerpo)',
                                          size: 22,
                                      })
                                  ],
                                  spacing: { before: 100, after: 200 }
                              })],
                          verticalAlign: VerticalAlign.CENTER,
                      }),
                      new TableCell({
                          children: [
                              new Paragraph({
                                  alignment: AlignmentType.LEFT,
                                  children: [
                                      new TextRun({
                                          text: `${data.reviewer ? datePipe.transform(new Date(data.reviewer.date), 'd-MMMM-y') : ''}`,
                                          font: 'Calibri (Cuerpo)',
                                          size: 22,
                                      })
                                  ],
                                  spacing: { before: 100, after: 200 }
                              })],
                          verticalAlign: VerticalAlign.CENTER,
                      }),
                  ],
              }),
              new TableRow({
                  children: [
                      new TableCell({
                          children: [
                              new Paragraph({
                                  alignment: AlignmentType.RIGHT,
                                  children: [
                                      new TextRun({
                                          text: 'Conformidad: ',
                                          font: 'Calibri (Cuerpo)',
                                          size: 22,
                                      })
                                  ],
                                  spacing: { before: 100, after: 200 }
                              })],
                          verticalAlign: VerticalAlign.CENTER,
                      }),
                      new TableCell({
                          children: [
                              new Paragraph({
                                  alignment: AlignmentType.LEFT,
                                  children: [
                                      new TextRun({
                                          text: `${this.getValue(data.revision, 'plan_organico_conformidad_no_conformidades_graves') === true ? '■' : '❏'} No conformidades graves, no procederá la certificación.`,
                                          font: 'Arial',
                                          size: 18
                                      })
                                  ]
                              }),
                              new Paragraph({
                                  alignment: AlignmentType.LEFT,
                                  children: [
                                      new TextRun({
                                          text: `${this.getValue(data.revision, 'plan_organico_conformidad_no_conformidades_menores_con_hallazgos') === true ? '■' : '❏'} No conformidades menores, se requiere de corrección antes de inspección. Listar los hallazgos abajo.`,
                                          font: 'Arial',
                                          size: 18
                                      })
                                  ]
                              }),
                              new Paragraph({
                                  alignment: AlignmentType.LEFT,
                                  children: [
                                      new TextRun({
                                          text: `${this.getValue(data.revision, 'plan_organico_conformidad_no_conformidades_menores') === true ? '■' : '❏'} No conformidades menores, se puede realizar inspección.`,
                                          font: 'Arial',
                                          size: 18
                                      })
                                  ]
                              }),
                              new Paragraph({
                                  alignment: AlignmentType.LEFT,
                                  children: [
                                      new TextRun({
                                          text: `${this.getValue(data.revision, 'plan_organico_conformidad_conformidad') === true ? '■' : '❏'} Conformidad`,
                                          font: 'Arial',
                                          size: 18
                                      })
                                  ]
                              }),
                              new Paragraph({
                                  alignment: AlignmentType.LEFT,
                                  children: [
                                      new TextRun({
                                          text: `${this.getValue(data.revision, 'plan_organico_conformidad_otro') === true ? `■ Otro: ${this.getValue(data.revision, 'plan_organico_conformidad_otro_text')}` : '❏ Otro:'}`,
                                          font: 'Arial',
                                          size: 18
                                      })
                                  ]
                              })
                          ],
                          verticalAlign: VerticalAlign.CENTER,
                          columnSpan: 1,
                      }),
                  ],
              }),
              new TableRow({
                  children: [
                      new TableCell({
                          children: [
                              new Paragraph({
                                  alignment: AlignmentType.RIGHT,
                                  children: [
                                      new TextRun({
                                          text: 'Hallazgos: ',
                                          font: 'Calibri (Cuerpo)',
                                          size: 22,
                                      })
                                  ],
                                  spacing: { before: 100, after: 200 }
                              })],
                          verticalAlign: VerticalAlign.CENTER,
                      }),
                      new TableCell({
                          children: [
                              new Paragraph({
                                  alignment: AlignmentType.LEFT,
                                  children: [
                                      new TextRun({
                                          text: this.getValue(data.revision, "plan_organico_hallazgos"),
                                          font: 'Calibri (Cuerpo)',
                                          size: 22,
                                      })
                                  ],
                                  spacing: { before: 100, after: 200 }
                              })],
                          verticalAlign: VerticalAlign.CENTER,
                      }),
                  ],
              }),
              new TableRow({
                  children: [
                      new TableCell({
                          children: [
                              new Paragraph({
                                  alignment: AlignmentType.RIGHT,
                                  children: [
                                      new TextRun({
                                          text: 'Recomendaciones para inspector: ',
                                          font: 'Calibri (Cuerpo)',
                                          size: 22,
                                      })
                                  ],
                                  spacing: { before: 100, after: 200 }
                              })],
                          verticalAlign: VerticalAlign.CENTER,
                      }),
                      new TableCell({
                          children: [
                              new Paragraph({
                                  alignment: AlignmentType.LEFT,
                                  children: [
                                      new TextRun({
                                          text: this.getValue(data.revision, "plan_organico_recomendaciones"),
                                          font: 'Calibri (Cuerpo)',
                                          size: 22,
                                      })
                                  ],
                                  spacing: { before: 100, after: 200 }
                              })],
                          verticalAlign: VerticalAlign.CENTER,
                      }),
                  ],
              }),
              new TableRow({
                  children: [
                      new TableCell({
                          children: [
                              new Paragraph({
                                  alignment: AlignmentType.RIGHT,
                                  children: [
                                      new TextRun({
                                          text: 'Comentarios generales: ',
                                          font: 'Calibri (Cuerpo)',
                                          size: 22,
                                      })
                                  ],
                                  spacing: { before: 100, after: 200 }
                              })],
                          verticalAlign: VerticalAlign.CENTER,
                      }),
                      new TableCell({
                          children: [
                              new Paragraph({
                                  alignment: AlignmentType.LEFT,
                                  children: [
                                      new TextRun({
                                          text: this.getValue(data.revision, "plan_organico_comentarios"),
                                          font: 'Calibri (Cuerpo)',
                                          size: 22,
                                      })
                                  ],
                                  spacing: { before: 100, after: 200 }
                              })],
                          verticalAlign: VerticalAlign.CENTER,
                      }),
                  ],
              }),
              new TableRow({
                  children: [
                      new TableCell({
                          children: [
                              new Paragraph({
                                  alignment: AlignmentType.RIGHT,
                                  children: [
                                      new TextRun({
                                          text: `Firma del Revisor:`,
                                          font: 'Calibri (Cuerpo)',
                                          size: 22,
                                      })
                                  ],
                                  spacing: { before: 100, after: 200 }
                              })],
                          verticalAlign: VerticalAlign.CENTER,
                      }),
                      new TableCell({
                          children: [
                              new Paragraph({
                                  alignment: AlignmentType.LEFT,
                                  children: [
                                    signatureImage2 ? Media.addImage(document, signatureImage2.toString(), 200, 100) : new TextRun({
                                      text: ''
                                    })
                                  ],
                                  spacing: { before: 100, after: 200 }
                              })
                          ],
                          verticalAlign: VerticalAlign.CENTER,
                          columnSpan: 1,
                      }),
                  ],
              }),
          ],
          margins: {
              left: 70,
              right: 70
          },
          width: {
              size: 100,
              type: WidthType.PERCENTAGE,
          },
          columnWidths: [2410, 7227]
        })
      ],
      margins: {
        top: 350,
        bottom: 500,
        left: 1000,
        right: 1000
      }
    });

    return document;
  }

  public createFooter(): Table {
    const table = new Table({
      rows: [
        new TableRow({
          children: [
            new TableCell({
              children: [
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                    new TextRun({
                      text: 'Elaboró:',
                      font: 'Calibri (Cuerpo)',
                      size: 22
                    })
                  ]
                })
              ],
              width: {
                size: 33,
                type: WidthType.PERCENTAGE
              }
            }),
            new TableCell({
              children: [
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                    new TextRun({
                      text: 'Revisó:',
                      font: 'Calibri (Cuerpo)',
                      size: 22
                    })
                  ]
                })
              ],
              width: {
                size: 33,
                type: WidthType.PERCENTAGE
              }
            }),
            new TableCell({
              children: [
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                    new TextRun({
                      text: 'Autorizó:',
                      font: 'Calibri (Cuerpo)',
                      size: 22
                    })
                  ]
                }),
              ],
              width: {
                size: 33,
                type: WidthType.PERCENTAGE
              }
            })
          ]
        }),
        new TableRow({
          children: [
            new TableCell({
              children: [
                new Paragraph({
                  alignment: AlignmentType.CENTER,
                  children: [
                    new TextRun({
                      text: 'Director de certificación',
                      font: 'Calibri (Cuerpo)',
                      size: 16,
                      bold: true
                    })
                  ]
                }),

              ],
              width: {
                size: 33,
                type: WidthType.PERCENTAGE
              },
              shading: {
                fill: '#C0C0C0'
              }
            }),
            new TableCell({
              children: [
                new Paragraph({
                  alignment: AlignmentType.CENTER,
                  children: [
                    new TextRun({
                      text: 'Responsable de calidad',
                      font: 'Calibri (Cuerpo)',
                      size: 16,
                      bold: true
                    })
                  ]
                }),

              ],
              width: {
                size: 33,
                type: WidthType.PERCENTAGE
              },
              shading: {
                fill: '#C0C0C0'
              }
            }),
            new TableCell({
              children: [
                new Paragraph({
                  alignment: AlignmentType.CENTER,
                  children: [
                    new TextRun({
                      text: 'Director general',
                      font: 'Calibri (Cuerpo)',
                      size: 16,
                      bold: true
                    })
                  ]
                }),
              ],
              width: {
                size: 33,
                type: WidthType.PERCENTAGE
              },
              shading: {
                fill: '#C0C0C0'
              }
            })
          ]
        })
      ],
      margins: {
        left: 70,
        right: 70
      },
      //9638
      columnWidths: [3213, 3213, 3212],
      alignment: AlignmentType.CENTER
    })
    return table
  }

  public createRegisters_MTO_FSOL_08_1_3(submission): Table {
    const table = new Table({
      rows: [
        new TableRow({
          children: [
            new TableCell({
              children: [
                new Paragraph({
                  alignment: AlignmentType.CENTER,
                  children: [
                    new TextRun({
                      text: 'REGISTROS',
                      font: 'Arial',
                      size: 20,
                      bold: true
                    })
                  ],
                  spacing: {
                    after: 75,
                    before: 75
                  }
                }),
              ],
              verticalAlign: VerticalAlign.CENTER,
              columnSpan: 6,
              shading: {
                fill: '#C0C0C0'
              }
            })
          ]
        }),
        new TableRow({
          children: [
            new TableCell({
              children: [
                new Paragraph({
                  alignment: AlignmentType.CENTER,
                  children: [
                    new TextRun({
                      text: 'No.',
                      font: 'Arial',
                      size: 18,
                      bold: true
                    })
                  ]
                })
              ],
              rowSpan: 2,
              verticalAlign: VerticalAlign.CENTER,
              width: {
                size: 25,
                type: WidthType.PERCENTAGE
              }
            }),
            new TableCell({
              children: [
                new Paragraph({
                  alignment: AlignmentType.CENTER,
                  children: [
                    new TextRun({
                      text: 'Requisitos',
                      font: 'Arial',
                      size: 18,
                      bold: true
                    })
                  ]
                })
              ],
              rowSpan: 2,
              columnSpan: 1,
              verticalAlign: VerticalAlign.CENTER,
              width: {
                size: 15,
                type: WidthType.PERCENTAGE
              }
            }),
            new TableCell({
              children: [
                new Paragraph({
                  alignment: AlignmentType.CENTER,
                  children: [
                    new TextRun({
                      text: 'Incluyo información',
                      font: 'Arial',
                      size: 18,
                      bold: true
                    })
                  ]
                })
              ],
              rowSpan: 2,
              verticalAlign: VerticalAlign.CENTER,
              width: {
                size: 15,
                type: WidthType.PERCENTAGE
              }
            }),
            new TableCell({
              children: [
                new Paragraph({
                  alignment: AlignmentType.CENTER,
                  children: [
                    new TextRun({
                      text: 'Comentarios',
                      font: 'Arial',
                      size: 18,
                      bold: true
                    })
                  ]
                })
              ],
              rowSpan: 2,
              verticalAlign: VerticalAlign.CENTER,
              width: {
                size: 15,
                type: WidthType.PERCENTAGE
              }
            }),
            new TableCell({
              children: [
                  new Paragraph({
                      alignment: AlignmentType.CENTER,
                      children: [
                          new TextRun({
                              text: 'Verificación en sitio (Evaluada por Inspector)',
                              font: 'Arial',
                              size: 18,
                              bold: true
                          })
                      ]
                  })
              ],
              columnSpan: 3,
              verticalAlign: VerticalAlign.CENTER,
              width: {
                  size: 15,
                  type: WidthType.PERCENTAGE
              }
            })
          ]
        }),
        new TableRow({
          children: [new TableCell({
              children: [
                  new Paragraph({
                      alignment: AlignmentType.CENTER,
                      children: [
                          new TextRun({
                              text: 'Implementación',
                              font: 'Arial',
                              size: 18,
                              bold: true
                          })
                      ]
                  })
              ],
              verticalAlign: VerticalAlign.CENTER
          }),
          new TableCell({
              children: [
                  new Paragraph({
                      alignment: AlignmentType.CENTER,
                      children: [
                          new TextRun({
                              text: 'Confirmación',
                              font: 'Arial',
                              size: 18,
                              bold: true
                          })
                      ]
                  })
              ],
              verticalAlign: VerticalAlign.CENTER,
              columnSpan: 2
          }),]
        }),
        new TableRow({
          children: [
            new TableCell({
              children: [
                new Paragraph({
                  alignment: AlignmentType.CENTER,
                  children: [
                    new TextRun({
                      text: '1',
                      font: 'Arial',
                      size: 18
                    })
                  ]
                })
              ],
              verticalAlign: VerticalAlign.TOP
            }),
            new TableCell({
              children: [
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                    new TextRun({
                      text: 'He incluido una lista completa de las unidades de producción que estoy sometiendo a evaluación para la certificación orgánica. MTO-FSOL-02',
                      font: 'Arial',
                      size: 18
                    })
                  ]
                })
              ],
              verticalAlign: VerticalAlign.CENTER
            }),
            new TableCell({
              children: [
                new Paragraph({
                  alignment: AlignmentType.CENTER,
                  children: [
                    new TextRun({
                      text: this.getValue(submission, 'plan_organico_vegetal_germinados_registros_1_declaracion') === 'Si' ? '■ SÍ  ❏ NO' : '❏ SÍ  ■ NO',
                      font: 'Arial',
                      size: 18
                    })
                  ]
                })
              ],
              verticalAlign: VerticalAlign.TOP
            }),
            new TableCell({
              children: [
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                    new TextRun({
                      text: this.getValue(submission, 'plan_organico_vegetal_germinados_registros_1_comentarios'),
                      font: 'Arial',
                      size: 18
                    })
                  ]
                })
              ],
              verticalAlign: VerticalAlign.TOP
            }),
            new TableCell({
              children: [
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                      new TextRun({
                          text: ` ${this.getValue(submission, 'plan_organico_vegetal_germinados_registros_1_implementacion') === 'I' ? '■' : '❏'} I`,
                          font: 'Arial',
                          size: 18
                      })
                  ]
                }),
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                      new TextRun({
                          text: ` ${this.getValue(submission, 'plan_organico_vegetal_germinados_registros_1_implementacion') === 'NI' ? '■' : '❏'} NI`,
                          font: 'Arial',
                          size: 18
                      })
                  ]
                })
              ],
              verticalAlign: VerticalAlign.TOP
          }),
          new TableCell({
              children: [
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                      new TextRun({
                          text: ` ${this.getValue(submission, 'plan_organico_vegetal_germinados_registros_1_conformidad') === 'C' ? '■' : '❏'} C`,
                          font: 'Arial',
                          size: 18
                      })
                  ]
                }),
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                      new TextRun({
                          text: ` ${this.getValue(submission, 'plan_organico_vegetal_germinados_registros_1_conformidad') === 'NC' ? '■' : '❏'} NC`,
                          font: 'Arial',
                          size: 18
                      })
                  ]
                }),
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                      new TextRun({
                          text: ` ${this.getValue(submission, 'plan_organico_vegetal_germinados_registros_1_conformidad') === 'NA' ? '■' : '❏'} NA`,
                          font: 'Arial',
                          size: 18
                      })
                  ]
                })
              ],
              verticalAlign: VerticalAlign.TOP
          }),
          new TableCell({
              children: [
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                      new TextRun({
                          text: this.getValue(submission, 'plan_organico_vegetal_germinados_registros_1_comentarios_inspector'),
                          font: 'Arial',
                          size: 18
                      })
                  ]
                })
              ],
              verticalAlign: VerticalAlign.TOP
          })
          ]
        }),
        new TableRow({
          children: [
            new TableCell({
              children: [
                new Paragraph({
                  alignment: AlignmentType.CENTER,
                  children: [
                    new TextRun({
                      text: '2',
                      font: 'Arial',
                      size: 18
                    })
                  ]
                })
              ],
              verticalAlign: VerticalAlign.TOP
            }),
            new TableCell({
              children: [
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                    new TextRun({
                      text: 'He incluido croquis de cada una de las unidades de producción. MTO-FSOL-05',
                      font: 'Arial',
                      size: 18
                    })
                  ]
                })
              ],
              verticalAlign: VerticalAlign.TOP
            }),
            new TableCell({
              children: [
                new Paragraph({
                  alignment: AlignmentType.CENTER,
                  children: [
                    new TextRun({
                      text: this.getValue(submission, 'plan_organico_vegetal_germinados_registros_2_declaracion') === 'Si' ? '■ SÍ  ❏ NO' : '❏ SÍ  ■ NO',
                      font: 'Arial',
                      size: 18
                    })
                  ]
                })
              ],
              verticalAlign: VerticalAlign.TOP
            }),
            new TableCell({
              children: [
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                    new TextRun({
                      text: this.getValue(submission, 'plan_organico_vegetal_germinados_registros_2_comentarios'),
                      font: 'Arial',
                      size: 18
                    })
                  ]
                })
              ],
              verticalAlign: VerticalAlign.TOP
            }),
            new TableCell({
              children: [
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                      new TextRun({
                          text: ` ${this.getValue(submission, 'plan_organico_vegetal_germinados_registros_2_implementacion') === 'I' ? '■' : '❏'} I`,
                          font: 'Arial',
                          size: 18
                      })
                  ]
                }),
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                      new TextRun({
                          text: ` ${this.getValue(submission, 'plan_organico_vegetal_germinados_registros_2_implementacion') === 'NI' ? '■' : '❏'} NI`,
                          font: 'Arial',
                          size: 18
                      })
                  ]
                })
              ],
              verticalAlign: VerticalAlign.TOP
          }),
          new TableCell({
              children: [
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                      new TextRun({
                          text: ` ${this.getValue(submission, 'plan_organico_vegetal_germinados_registros_2_conformidad') === 'C' ? '■' : '❏'} C`,
                          font: 'Arial',
                          size: 18
                      })
                  ]
                }),
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                      new TextRun({
                          text: ` ${this.getValue(submission, 'plan_organico_vegetal_germinados_registros_2_conformidad') === 'NC' ? '■' : '❏'} NC`,
                          font: 'Arial',
                          size: 18
                      })
                  ]
                }),
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                      new TextRun({
                          text: ` ${this.getValue(submission, 'plan_organico_vegetal_germinados_registros_2_conformidad') === 'NA' ? '■' : '❏'} NA`,
                          font: 'Arial',
                          size: 18
                      })
                  ]
                })
              ],
              verticalAlign: VerticalAlign.TOP
          }),
          new TableCell({
              children: [
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                      new TextRun({
                          text: this.getValue(submission, 'plan_organico_vegetal_germinados_registros_2_comentarios_inspector'),
                          font: 'Arial',
                          size: 18
                      })
                  ]
                })
              ],
              verticalAlign: VerticalAlign.TOP
          })
          ]
        }),
        new TableRow({
          children: [
            new TableCell({
              children: [
                new Paragraph({
                  alignment: AlignmentType.CENTER,
                  children: [
                    new TextRun({
                      text: '3',
                      font: 'Arial',
                      size: 18
                    })
                  ]
                })
              ],
              verticalAlign: VerticalAlign.TOP
            }),
            new TableCell({
              children: [
                new Table({
                  rows: [
                    new TableRow({
                      children: [
                        new TableCell({
                          children: [
                            new Paragraph({
                              alignment: AlignmentType.LEFT,
                              children: [
                                new TextRun({
                                  text: 'Cuento con las siguientes bitácoras',
                                  font: 'Arial',
                                  size: 18
                                })
                              ]
                            })
                          ],
                          borders: {
                            left: {
                              style: BorderStyle.NONE,
                              color: "white",
                              size: 0
                            },
                            top: {
                              style: BorderStyle.NONE,
                              color: "white",
                              size: 0
                            },
                            bottom: {
                              style: BorderStyle.NONE,
                              color: "white",
                              size: 0
                            }
                          }
                        }),
                        new TableCell({
                          children: [
                            new Paragraph({
                              alignment: AlignmentType.LEFT,
                              children: [
                                new TextRun({
                                  text: '',
                                  font: 'Arial',
                                  size: 18
                                })
                              ]
                            })
                          ],
                          borders: {
                            right: {
                              style: BorderStyle.NONE,
                              color: "white",
                              size: 0
                            },
                            top: {
                              style: BorderStyle.NONE,
                              color: "white",
                              size: 0
                            },
                            bottom: {
                              style: BorderStyle.NONE,
                              color: "white",
                              size: 0
                            }
                          }
                        })
                      ]
                    }),
                    new TableRow({
                      children: [
                        new TableCell({
                          children: [
                            new Paragraph({
                              alignment: AlignmentType.LEFT,
                              children: [
                                new TextRun({
                                  text: `${this.getValue(submission, 'plan_organico_vegetal_germinados_registros_3_compra_inventario_semillas_check') ? '■' : '❏'} Compra e Inventario de semillas`,
                                  font: 'Arial',
                                  size: 18
                                })
                              ]
                            })
                          ],
                          borders: {
                            left: {
                              style: BorderStyle.NONE,
                              color: "white",
                              size: 0
                            },
                            top: {
                              style: BorderStyle.NONE,
                              color: "white",
                              size: 0
                            },
                            bottom: {
                              style: BorderStyle.NONE,
                              color: "white",
                              size: 0
                            }
                          }
                        }),
                        new TableCell({
                          children: [
                            new Paragraph({
                              alignment: AlignmentType.CENTER,
                              children: [
                                new TextRun({
                                  text: `${this.getValue(submission, 'plan_organico_vegetal_germinados_registros_3_compra_inventario_semillas') === "Si" ? '■ Sí ❏ No' : this.getValue(submission, 'plan_organico_vegetal_germinados_registros_3_compra_inventario_semillas') === "No" ? '❏ Sí ■ No' : '❏ Sí ❏ No'}`,
                                  font: 'Arial',
                                  size: 18
                                })
                              ]
                            })
                          ],
                          borders: {
                            right: {
                              style: BorderStyle.NONE,
                              color: "white",
                              size: 0
                            },
                            top: {
                              style: BorderStyle.NONE,
                              color: "white",
                              size: 0
                            },
                            bottom: {
                              style: BorderStyle.NONE,
                              color: "white",
                              size: 0
                            }
                          }
                        })
                      ]
                    }),
                    new TableRow({
                      children: [
                        new TableCell({
                          children: [
                            new Paragraph({
                              alignment: AlignmentType.LEFT,
                              children: [
                                new TextRun({
                                  text: `${this.getValue(submission, 'plan_organico_vegetal_germinados_registros_3_compra_inventario_insumos_check') === "Si" ? '■' : '❏'} Compra e Inventario de insumos`,
                                  font: 'Arial',
                                  size: 18
                                })
                              ]
                            })
                          ],
                          borders: {
                            left: {
                              style: BorderStyle.NONE,
                              color: "white",
                              size: 0
                            },
                            top: {
                              style: BorderStyle.NONE,
                              color: "white",
                              size: 0
                            },
                            bottom: {
                              style: BorderStyle.NONE,
                              color: "white",
                              size: 0
                            }
                          }
                        }),
                        new TableCell({
                          children: [
                            new Paragraph({
                              alignment: AlignmentType.CENTER,
                              children: [
                                new TextRun({
                                  text: `${this.getValue(submission, 'plan_organico_vegetal_germinados_registros_3_compra_inventario_insumos') === "Si" ? '■ Sí ❏ No' : this.getValue(submission, 'plan_organico_vegetal_germinados_registros_3_compra_inventario_insumos') === "No" ? '❏ Sí ■ No' : '❏ Sí ❏ No'}`,
                                  font: 'Arial',
                                  size: 18
                                })
                              ]
                            })
                          ],
                          borders: {
                            right: {
                              style: BorderStyle.NONE,
                              color: "white",
                              size: 0
                            },
                            top: {
                              style: BorderStyle.NONE,
                              color: "white",
                              size: 0
                            },
                            bottom: {
                              style: BorderStyle.NONE,
                              color: "white",
                              size: 0
                            }
                          }
                        })
                      ]
                    }),
                    new TableRow({
                      children: [
                        new TableCell({
                          children: [
                            new Paragraph({
                              alignment: AlignmentType.LEFT,
                              children: [
                                new TextRun({
                                  text: `${this.getValue(submission, 'plan_organico_vegetal_germinados_registros_3_c_check') ? '■' : '❏'} Actividades de limpieza y desinfección`,
                                  font: 'Arial',
                                  size: 18
                                })
                              ]
                            })
                          ],
                          borders: {
                            left: {
                              style: BorderStyle.NONE,
                              color: "white",
                              size: 0
                            },
                            top: {
                              style: BorderStyle.NONE,
                              color: "white",
                              size: 0
                            },
                            bottom: {
                              style: BorderStyle.NONE,
                              color: "white",
                              size: 0
                            }
                          }
                        }),
                        new TableCell({
                          children: [
                            new Paragraph({
                              alignment: AlignmentType.CENTER,
                              children: [
                                new TextRun({
                                  text: `${this.getValue(submission, 'plan_organico_vegetal_germinados_registros_3_c_declaracion') === "Si" ? '■ Sí ❏ No' : this.getValue(submission, 'plan_organico_vegetal_germinados_registros_3_c_declaracion') === "No" ? '❏ Sí ■ No' : '❏ Sí ❏ No'}`,
                                  font: 'Arial',
                                  size: 18
                                })
                              ]
                            })
                          ],
                          borders: {
                            right: {
                              style: BorderStyle.NONE,
                              color: "white",
                              size: 0
                            },
                            top: {
                              style: BorderStyle.NONE,
                              color: "white",
                              size: 0
                            },
                            bottom: {
                              style: BorderStyle.NONE,
                              color: "white",
                              size: 0
                            }
                          }
                        })
                      ]
                    }),
                    new TableRow({
                      children: [
                        new TableCell({
                          children: [
                            new Paragraph({
                              alignment: AlignmentType.LEFT,
                              children: [
                                new TextRun({
                                  text: `${this.getValue(submission, 'plan_organico_vegetal_germinados_registros_3_d_check') ? '■' : '❏'} Calidad del agua`,
                                  font: 'Arial',
                                  size: 18
                                })
                              ]
                            })
                          ],
                          borders: {
                            left: {
                              style: BorderStyle.NONE,
                              color: "white",
                              size: 0
                            },
                            top: {
                              style: BorderStyle.NONE,
                              color: "white",
                              size: 0
                            },
                            bottom: {
                              style: BorderStyle.NONE,
                              color: "white",
                              size: 0
                            }
                          }
                        }),
                        new TableCell({
                          children: [
                            new Paragraph({
                              alignment: AlignmentType.CENTER,
                              children: [
                                new TextRun({
                                  text: `${this.getValue(submission, 'plan_organico_vegetal_germinados_registros_3_d_declaracion') === "Si" ? '■ Sí ❏ No' : this.getValue(submission, 'plan_organico_vegetal_germinados_registros_3_d_declaracion') === "No" ? '❏ Sí ■ No' : '❏ Sí ❏ No'}`,
                                  font: 'Arial',
                                  size: 18
                                })
                              ]
                            })
                          ],
                          borders: {
                            right: {
                              style: BorderStyle.NONE,
                              color: "white",
                              size: 0
                            },
                            top: {
                              style: BorderStyle.NONE,
                              color: "white",
                              size: 0
                            },
                            bottom: {
                              style: BorderStyle.NONE,
                              color: "white",
                              size: 0
                            }
                          }
                        })
                      ]
                    }),
                    new TableRow({
                      children: [
                        new TableCell({
                          children: [
                            new Paragraph({
                              alignment: AlignmentType.LEFT,
                              children: [
                                new TextRun({
                                  text: `${this.getValue(submission, 'plan_organico_vegetal_germinados_registros_3_e_check') ? '■' : '❏'} Cosecha`,
                                  font: 'Arial',
                                  size: 18
                                })
                              ]
                            })
                          ],
                          borders: {
                            left: {
                              style: BorderStyle.NONE,
                              color: "white",
                              size: 0
                            },
                            top: {
                              style: BorderStyle.NONE,
                              color: "white",
                              size: 0
                            },
                            bottom: {
                              style: BorderStyle.NONE,
                              color: "white",
                              size: 0
                            }
                          }
                        }),
                        new TableCell({
                          children: [
                            new Paragraph({
                              alignment: AlignmentType.CENTER,
                              children: [
                                new TextRun({
                                  text: `${this.getValue(submission, 'plan_organico_vegetal_germinados_registros_3_e_declaracion') === "Si" ? '■ Sí ❏ No' : this.getValue(submission, 'plan_organico_vegetal_germinados_registros_3_e_declaracion') === "No" ? '❏ Sí ■ No' : '❏ Sí ❏ No'}`,
                                  font: 'Arial',
                                  size: 18
                                })
                              ]
                            })
                          ],
                          borders: {
                            right: {
                              style: BorderStyle.NONE,
                              color: "white",
                              size: 0
                            },
                            top: {
                              style: BorderStyle.NONE,
                              color: "white",
                              size: 0
                            },
                            bottom: {
                              style: BorderStyle.NONE,
                              color: "white",
                              size: 0
                            }
                          }
                        })
                      ]
                    }),
                    new TableRow({
                      children: [
                        new TableCell({
                          children: [
                            new Paragraph({
                              alignment: AlignmentType.LEFT,
                              children: [
                                new TextRun({
                                  text: `${this.getValue(submission, 'plan_organico_vegetal_germinados_registros_3_f_check') ? '■' : '❏'} Ventas`,
                                  font: 'Arial',
                                  size: 18
                                })
                              ]
                            })
                          ],
                          borders: {
                            left: {
                              style: BorderStyle.NONE,
                              color: "white",
                              size: 0
                            },
                            top: {
                              style: BorderStyle.NONE,
                              color: "white",
                              size: 0
                            },
                            bottom: {
                              style: BorderStyle.NONE,
                              color: "white",
                              size: 0
                            }
                          }
                        }),
                        new TableCell({
                          children: [
                            new Paragraph({
                              alignment: AlignmentType.CENTER,
                              children: [
                                new TextRun({
                                  text: `${this.getValue(submission, 'plan_organico_vegetal_germinados_registros_3_f_declaracion') === "Si" ? '■ Sí ❏ No' : this.getValue(submission, 'plan_organico_vegetal_germinados_registros_3_f_declaracion') === "No" ? '❏ Sí ■ No' : '❏ Sí ❏ No'}`,
                                  font: 'Arial',
                                  size: 18
                                })
                              ]
                            })
                          ],
                          borders: {
                            right: {
                              style: BorderStyle.NONE,
                              color: "white",
                              size: 0
                            },
                            top: {
                              style: BorderStyle.NONE,
                              color: "white",
                              size: 0
                            },
                            bottom: {
                              style: BorderStyle.NONE,
                              color: "white",
                              size: 0
                            }
                          }
                        })
                      ]
                    }),
                    new TableRow({
                      children: [
                        new TableCell({
                          children: [
                            new Paragraph({
                              alignment: AlignmentType.LEFT,
                              children: [
                                new TextRun({
                                  text: `${this.getValue(submission, 'plan_organico_vegetal_germinados_registros_3_otro_check') ? '■' : '❏'} Otro (¿Cual?): ${this.getValue(submission, 'plan_organico_vegetal_germinados_registros_3_otro')}`,
                                  font: 'Arial',
                                  size: 18
                                })
                              ]
                            })
                          ],
                          borders: {
                            left: {
                              style: BorderStyle.NONE,
                              color: "white",
                              size: 0
                            },
                            top: {
                              style: BorderStyle.NONE,
                              color: "white",
                              size: 0
                            },
                            bottom: {
                              style: BorderStyle.NONE,
                              color: "white",
                              size: 0
                            }
                          }
                        }),
                        new TableCell({
                          children: [
                            new Paragraph({
                              alignment: AlignmentType.CENTER,
                              children: [
                                new TextRun({
                                  text: `${this.getValue(submission, 'plan_organico_vegetal_germinados_registros_3_otro_declaracion') === "Si" ? '■ Sí ❏ No' : this.getValue(submission, 'plan_organico_vegetal_germinados_registros_3_otro_declaracion') === "No" ? '❏ Sí ■ No' : '❏ Sí ❏ No'}`,
                                  font: 'Arial',
                                  size: 18
                                })
                              ]
                            })
                          ],
                          borders: {
                            right: {
                              style: BorderStyle.NONE,
                              color: "white",
                              size: 0
                            },
                            top: {
                              style: BorderStyle.NONE,
                              color: "white",
                              size: 0
                            },
                            bottom: {
                              style: BorderStyle.NONE,
                              color: "white",
                              size: 0
                            }
                          }
                        })
                      ]
                    })
                  ],
                  //4395
                  columnWidths: [3075, 1320]
                })
              ],
              columnSpan: 2
            }),
            new TableCell({
              children: [
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                    new TextRun({
                      text: this.getValue(submission, 'plan_organico_vegetal_registros_3_comentarios'),
                      font: 'Arial',
                      size: 18
                    })
                  ]
                })
              ],
              verticalAlign: VerticalAlign.TOP
            }),
            new TableCell({
              children: [
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                      new TextRun({
                          text: ` ${this.getValue(submission, 'plan_organico_vegetal_germinados_registros_3_implementacion') === 'I' ? '■' : '❏'} I`,
                          font: 'Arial',
                          size: 18
                      })
                  ]
                }),
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                      new TextRun({
                          text: ` ${this.getValue(submission, 'plan_organico_vegetal_germinados_registros_3_implementacion') === 'NI' ? '■' : '❏'} NI`,
                          font: 'Arial',
                          size: 18
                      })
                  ]
                })
              ],
              verticalAlign: VerticalAlign.TOP
            }),
            new TableCell({
                children: [
                  new Paragraph({
                    alignment: AlignmentType.LEFT,
                    children: [
                        new TextRun({
                            text: ` ${this.getValue(submission, 'plan_organico_vegetal_germinados_registros_3_conformidad') === 'C' ? '■' : '❏'} C`,
                            font: 'Arial',
                            size: 18
                        })
                    ]
                  }),
                  new Paragraph({
                    alignment: AlignmentType.LEFT,
                    children: [
                        new TextRun({
                            text: ` ${this.getValue(submission, 'plan_organico_vegetal_germinados_registros_3_conformidad') === 'NC' ? '■' : '❏'} NC`,
                            font: 'Arial',
                            size: 18
                        })
                    ]
                  }),
                  new Paragraph({
                    alignment: AlignmentType.LEFT,
                    children: [
                        new TextRun({
                            text: ` ${this.getValue(submission, 'plan_organico_vegetal_germinados_registros_3_conformidad') === 'NA' ? '■' : '❏'} NA`,
                            font: 'Arial',
                            size: 18
                        })
                    ]
                  })
                ],
                verticalAlign: VerticalAlign.TOP
            }),
            new TableCell({
                children: [
                  new Paragraph({
                    alignment: AlignmentType.LEFT,
                    children: [
                        new TextRun({
                            text: this.getValue(submission, 'plan_organico_vegetal_germinados_registros_3_comentarios_inspector'),
                            font: 'Arial',
                            size: 18
                        })
                    ]
                  })
                ],
                verticalAlign: VerticalAlign.TOP
            })
          ]
        }),
        new TableRow({
          children: [
            new TableCell({
              children: [
                new Paragraph({
                  alignment: AlignmentType.CENTER,
                  children: [
                    new TextRun({
                      text: '4',
                      font: 'Arial',
                      size: 18
                    })
                  ]
                })
              ],
              verticalAlign: VerticalAlign.TOP
            }),
            new TableCell({
              children: [
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                    new TextRun({
                      text: 'He incluido una lista completa de riesgos para la integridad orgánica de los productos en el formato MTO-FSOL-04',
                      font: 'Arial',
                      size: 18
                    })
                  ]
                })
              ],
              verticalAlign: VerticalAlign.TOP
            }),
            new TableCell({
              children: [
                new Paragraph({
                  alignment: AlignmentType.CENTER,
                  children: [
                    new TextRun({
                      text: this.getValue(submission, 'plan_organico_vegetal_germinados_registros_4_declaracion') === 'Si' ? '■ SÍ  ❏ NO' : '❏ SÍ  ■ NO',
                      font: 'Arial',
                      size: 18
                    })
                  ]
                })
              ],
              verticalAlign: VerticalAlign.TOP
            }),
            new TableCell({
              children: [
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                    new TextRun({
                      text: this.getValue(submission, 'plan_organico_vegetal_germinados_registros_4_comentarios'),
                      font: 'Arial',
                      size: 18
                    })
                  ]
                })
              ],
              verticalAlign: VerticalAlign.TOP
            }),
            new TableCell({
              children: [
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                      new TextRun({
                          text: ` ${this.getValue(submission, 'plan_organico_vegetal_germinados_registros_4_implementacion') === 'I' ? '■' : '❏'} I`,
                          font: 'Arial',
                          size: 18
                      })
                  ]
                }),
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                      new TextRun({
                          text: ` ${this.getValue(submission, 'plan_organico_vegetal_germinados_registros_4_implementacion') === 'NI' ? '■' : '❏'} NI`,
                          font: 'Arial',
                          size: 18
                      })
                  ]
                })
              ],
              verticalAlign: VerticalAlign.TOP
          }),
          new TableCell({
              children: [
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                      new TextRun({
                          text: ` ${this.getValue(submission, 'plan_organico_vegetal_germinados_registros_4_conformidad') === 'C' ? '■' : '❏'} C`,
                          font: 'Arial',
                          size: 18
                      })
                  ]
                }),
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                      new TextRun({
                          text: ` ${this.getValue(submission, 'plan_organico_vegetal_germinados_registros_4_conformidad') === 'NC' ? '■' : '❏'} NC`,
                          font: 'Arial',
                          size: 18
                      })
                  ]
                }),
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                      new TextRun({
                          text: ` ${this.getValue(submission, 'plan_organico_vegetal_germinados_registros_4_conformidad') === 'NA' ? '■' : '❏'} NA`,
                          font: 'Arial',
                          size: 18
                      })
                  ]
                })
              ],
              verticalAlign: VerticalAlign.TOP
          }),
          new TableCell({
              children: [
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                      new TextRun({
                          text: this.getValue(submission, 'plan_organico_vegetal_germinados_registros_4_comentarios_inspector'),
                          font: 'Arial',
                          size: 18
                      })
                  ]
                })
              ],
              verticalAlign: VerticalAlign.TOP
          })
          ]
        }),
        new TableRow({
          children: [
            new TableCell({
              children: [
                new Paragraph({
                  alignment: AlignmentType.CENTER,
                  children: [
                    new TextRun({
                      text: '5',
                      font: 'Arial',
                      size: 18
                    })
                  ]
                })
              ],
              verticalAlign: VerticalAlign.TOP
            }),
            new TableCell({
              children: [
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                    new TextRun({
                      text: 'He incluido una lista completa de las sustancias que utilizo para limpieza y desinfección MTO-FSOL-07',
                      font: 'Arial',
                      size: 18
                    })
                  ]
                })
              ],
              verticalAlign: VerticalAlign.TOP
            }),
            new TableCell({
              children: [
                new Paragraph({
                  alignment: AlignmentType.CENTER,
                  children: [
                    new TextRun({
                      text: this.getValue(submission, 'plan_organico_vegetal_germinados_registros_5_declaracion') === 'Si' ? '■ SÍ  ❏ NO' : '❏ SÍ  ■ NO',
                      font: 'Arial',
                      size: 18
                    })
                  ]
                })
              ],
              verticalAlign: VerticalAlign.TOP
            }),
            new TableCell({
              children: [
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                    new TextRun({
                      text: this.getValue(submission, 'plan_organico_vegetal_germinados_registros_5_comentarios'),
                      font: 'Arial',
                      size: 18
                    })
                  ]
                })
              ],
              verticalAlign: VerticalAlign.TOP
            }),
            new TableCell({
              children: [
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                      new TextRun({
                          text: ` ${this.getValue(submission, 'plan_organico_vegetal_germinados_registros_5_implementacion') === 'I' ? '■' : '❏'} I`,
                          font: 'Arial',
                          size: 18
                      })
                  ]
                }),
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                      new TextRun({
                          text: ` ${this.getValue(submission, 'plan_organico_vegetal_germinados_registros_5_implementacion') === 'NI' ? '■' : '❏'} NI`,
                          font: 'Arial',
                          size: 18
                      })
                  ]
                })
              ],
              verticalAlign: VerticalAlign.TOP
          }),
          new TableCell({
              children: [
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                      new TextRun({
                          text: ` ${this.getValue(submission, 'plan_organico_vegetal_germinados_registros_5_conformidad') === 'C' ? '■' : '❏'} C`,
                          font: 'Arial',
                          size: 18
                      })
                  ]
                }),
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                      new TextRun({
                          text: ` ${this.getValue(submission, 'plan_organico_vegetal_germinados_registros_5_conformidad') === 'NC' ? '■' : '❏'} NC`,
                          font: 'Arial',
                          size: 18
                      })
                  ]
                }),
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                      new TextRun({
                          text: ` ${this.getValue(submission, 'plan_organico_vegetal_germinados_registros_5_conformidad') === 'NA' ? '■' : '❏'} NA`,
                          font: 'Arial',
                          size: 18
                      })
                  ]
                })
              ],
              verticalAlign: VerticalAlign.TOP
          }),
          new TableCell({
              children: [
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                      new TextRun({
                          text: this.getValue(submission, 'plan_organico_vegetal_germinados_registros_5_comentarios_inspector'),
                          font: 'Arial',
                          size: 18
                      })
                  ]
                })
              ],
              verticalAlign: VerticalAlign.TOP
          })
          ]
        })
      ],
      margins: {
        left: 70,
        right: 70
      },
      //9885
      columnWidths: [450, 3050, 1345, 1545, 1150, 800, 1545]
    })
    return table
  }

  public createHandling_MTO_FSOL_08_1_3(submission): Table {
    const table = new Table({
      rows: [
        new TableRow({
          children: [
            new TableCell({
              children: [
                new Paragraph({
                  alignment: AlignmentType.CENTER,
                  children: [
                    new TextRun({
                      text: 'MANEJO',
                      font: 'Arial',
                      size: 20,
                      bold: true
                    })
                  ],
                  spacing: {
                    after: 75,
                    before: 75
                  }
                }),
              ],
              verticalAlign: VerticalAlign.CENTER,
              columnSpan: 6,
              shading: {
                fill: '#C0C0C0'
              }
            })
          ]
        }),
        new TableRow({
          children: [
            new TableCell({
              children: [
                new Paragraph({
                  alignment: AlignmentType.CENTER,
                  children: [
                    new TextRun({
                      text: 'No.',
                      font: 'Arial',
                      size: 18,
                      bold: true
                    })
                  ]
                })
              ],
              rowSpan: 2,
              columnSpan: 1,
              verticalAlign: VerticalAlign.CENTER,
              width: {
                size: 25,
                type: WidthType.PERCENTAGE
              }
            }),
            new TableCell({
              children: [
                new Paragraph({
                  alignment: AlignmentType.CENTER,
                  children: [
                    new TextRun({
                      text: 'Requisitos',
                      font: 'Arial',
                      size: 18,
                      bold: true
                    })
                  ]
                })
              ],
              rowSpan: 2,
              columnSpan: 1,
              verticalAlign: VerticalAlign.CENTER,
              width: {
                size: 15,
                type: WidthType.PERCENTAGE
              }
            }),
            new TableCell({
              children: [
                new Paragraph({
                  alignment: AlignmentType.CENTER,
                  children: [
                    new TextRun({
                      text: 'Comentarios',
                      font: 'Arial',
                      size: 18,
                      bold: true
                    })
                  ]
                })
              ],
              rowSpan: 2,
              columnSpan: 1,
              verticalAlign: VerticalAlign.CENTER,
              width: {
                size: 15,
                type: WidthType.PERCENTAGE
              }
            }),
            new TableCell({
              children: [
                  new Paragraph({
                      alignment: AlignmentType.CENTER,
                      children: [
                          new TextRun({
                              text: 'Verificación en sitio (Evaluada por Inspector)',
                              font: 'Arial',
                              size: 18,
                              bold: true
                          })
                      ]
                  })
              ],
              columnSpan: 3,
              verticalAlign: VerticalAlign.CENTER,
              width: {
                  size: 15,
                  type: WidthType.PERCENTAGE
              }
            })
          ]
        }),
        new TableRow({
          children: [new TableCell({
              children: [
                  new Paragraph({
                      alignment: AlignmentType.CENTER,
                      children: [
                          new TextRun({
                              text: 'Implementación',
                              font: 'Arial',
                              size: 18,
                              bold: true
                          })
                      ]
                  })
              ],
              verticalAlign: VerticalAlign.CENTER
          }),
          new TableCell({
              children: [
                  new Paragraph({
                      alignment: AlignmentType.CENTER,
                      children: [
                          new TextRun({
                              text: 'Confirmación',
                              font: 'Arial',
                              size: 18,
                              bold: true
                          })
                      ]
                  })
              ],
              verticalAlign: VerticalAlign.CENTER,
              columnSpan: 2
          }),]
        }),
        new TableRow({
          children: [
            new TableCell({
              children: [
                new Paragraph({
                  alignment: AlignmentType.CENTER,
                  children: [
                    new TextRun({
                      text: '1',
                      font: 'Arial',
                      size: 18
                    })
                  ]
                })
              ],
              verticalAlign: VerticalAlign.TOP
            }),
            new TableCell({
              children: [
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                    new TextRun({
                      text: 'El material de propagación (semilla, plántula) es:',
                      font: 'Arial',
                      size: 18
                    })
                  ]
                }),
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                    new TextRun({
                      text: (this.getValue(submission, "plan_organico_vegetal_germinados_manejo_1_organico_propio") ? '■' : '❏') + '  Orgánico propio',
                      font: 'Arial',
                      size: 18
                    })
                  ]
                }),
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                    new TextRun({
                      text: (this.getValue(submission, "plan_organico_vegetal_germinados_manejo_1_organico_externo") ? '■' : '❏') + '  Orgánico externo y certificado',
                      font: 'Arial',
                      size: 18
                    })
                  ]
                }),
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                    new TextRun({
                      text: (this.getValue(submission, "plan_organico_vegetal_germinados_manejo_1_otro") ? '■' : '❏') + ' Otro: ' + this.getValue(submission, "plan_organico_vegetal_germinados_manejo_1_otro_text"),
                      font: 'Arial',
                      size: 18
                    })
                  ]
                }),
              ],
              verticalAlign: VerticalAlign.TOP
            }),
            new TableCell({
              children: [
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                    new TextRun({
                      text: this.getValue(submission, "plan_organico_vegetal_germinados_manejo_1_comentarios"),
                      font: 'Arial',
                      size: 18
                    })
                  ]
                })
              ],
              verticalAlign: VerticalAlign.TOP
            }),
            new TableCell({
              children: [
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                      new TextRun({
                          text: ` ${this.getValue(submission, 'plan_organico_vegetal_germinados_manejo_1_implementacion') === 'I' ? '■' : '❏'} I`,
                          font: 'Arial',
                          size: 18
                      })
                  ]
                }),
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                      new TextRun({
                          text: ` ${this.getValue(submission, 'plan_organico_vegetal_germinados_manejo_1_implementacion') === 'NI' ? '■' : '❏'} NI`,
                          font: 'Arial',
                          size: 18
                      })
                  ]
                })
              ],
              verticalAlign: VerticalAlign.TOP
          }),
          new TableCell({
              children: [
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                      new TextRun({
                          text: ` ${this.getValue(submission, 'plan_organico_vegetal_germinados_manejo_1_conformidad') === 'C' ? '■' : '❏'} C`,
                          font: 'Arial',
                          size: 18
                      })
                  ]
                }),
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                      new TextRun({
                          text: ` ${this.getValue(submission, 'plan_organico_vegetal_germinados_manejo_1_conformidad') === 'NC' ? '■' : '❏'} NC`,
                          font: 'Arial',
                          size: 18
                      })
                  ]
                }),
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                      new TextRun({
                          text: ` ${this.getValue(submission, 'plan_organico_vegetal_germinados_manejo_1_conformidad') === 'NA' ? '■' : '❏'} NA`,
                          font: 'Arial',
                          size: 18
                      })
                  ]
                })
              ],
              verticalAlign: VerticalAlign.TOP
          }),
          new TableCell({
              children: [
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                      new TextRun({
                          text: this.getValue(submission, 'plan_organico_vegetal_germinados_manejo_1_comentarios_inspector'),
                          font: 'Arial',
                          size: 18
                      })
                  ]
                })
              ],
              verticalAlign: VerticalAlign.TOP
          })
          ]
        }),
        new TableRow({
          children: [
            new TableCell({
              children: [
                new Paragraph({
                  alignment: AlignmentType.CENTER,
                  children: [
                    new TextRun({
                      text: '2',
                      font: 'Arial',
                      size: 18
                    })
                  ]
                })
              ],
              verticalAlign: VerticalAlign.TOP
            }),
            new TableCell({
              children: [
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                    new TextRun({
                      text: 'Calidad de agua utilizada en la producción:',
                      font: 'Arial',
                      size: 18
                    })
                  ]
                }),
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                    new TextRun({
                      text: (this.getValue(submission, "plan_organico_vegetal_germinados_manejo_2_calidad_potable") ? '■' : '❏') + ' Calidad potable',
                      font: 'Arial',
                      size: 18
                    })
                  ]
                }),
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                    new TextRun({
                      text: (this.getValue(submission, "plan_organico_vegetal_germinados_manejo_2_calidad_no_controlada") ? '■' : '❏') + ' Calidad no controlada',
                      font: 'Arial',
                      size: 18
                    })
                  ]
                }),
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                    new TextRun({
                      text: (this.getValue(submission, "plan_organico_vegetal_germinados_manejo_2_otro") ? '■' : '❏') + '  Otro (¿Cuál?): ' + this.getValue(submission, "plan_organico_vegetal_germinados_manejo_2_otro_text"),
                      font: 'Arial',
                      size: 18
                    })
                  ]
                })
              ],
              verticalAlign: VerticalAlign.TOP
            }),
            new TableCell({
              children: [
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                    new TextRun({
                      text: this.getValue(submission, "plan_organico_vegetal_germinados_manejo_2_comentarios"),
                      font: 'Arial',
                      size: 18
                    })
                  ]
                })
              ],
              verticalAlign: VerticalAlign.TOP
            }),
            new TableCell({
              children: [
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                      new TextRun({
                          text: ` ${this.getValue(submission, 'plan_organico_vegetal_germinados_manejo_2_implementacion') === 'I' ? '■' : '❏'} I`,
                          font: 'Arial',
                          size: 18
                      })
                  ]
                }),
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                      new TextRun({
                          text: ` ${this.getValue(submission, 'plan_organico_vegetal_germinados_manejo_2_implementacion') === 'NI' ? '■' : '❏'} NI`,
                          font: 'Arial',
                          size: 18
                      })
                  ]
                })
              ],
              verticalAlign: VerticalAlign.TOP
          }),
          new TableCell({
              children: [
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                      new TextRun({
                          text: ` ${this.getValue(submission, 'plan_organico_vegetal_germinados_manejo_2_conformidad') === 'C' ? '■' : '❏'} C`,
                          font: 'Arial',
                          size: 18
                      })
                  ]
                }),
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                      new TextRun({
                          text: ` ${this.getValue(submission, 'plan_organico_vegetal_germinados_manejo_2_conformidad') === 'NC' ? '■' : '❏'} NC`,
                          font: 'Arial',
                          size: 18
                      })
                  ]
                }),
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                      new TextRun({
                          text: ` ${this.getValue(submission, 'plan_organico_vegetal_germinados_manejo_2_conformidad') === 'NA' ? '■' : '❏'} NA`,
                          font: 'Arial',
                          size: 18
                      })
                  ]
                })
              ],
              verticalAlign: VerticalAlign.TOP
          }),
          new TableCell({
              children: [
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                      new TextRun({
                          text: this.getValue(submission, 'plan_organico_vegetal_germinados_manejo_2_comentarios_inspector'),
                          font: 'Arial',
                          size: 18
                      })
                  ]
                })
              ],
              verticalAlign: VerticalAlign.TOP
          })
          ]
        }),
        new TableRow({
          children: [
            new TableCell({
              children: [
                new Paragraph({
                  alignment: AlignmentType.CENTER,
                  children: [
                    new TextRun({
                      text: '3',
                      font: 'Arial',
                      size: 18
                    })
                  ]
                })
              ],
              verticalAlign: VerticalAlign.TOP
            }),
            new TableCell({
              children: [
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                    new TextRun({
                      text: '¿Se realiza un monitoreo de la calidad del agua? ',
                      font: 'Arial',
                      size: 18
                    })
                  ]
                }),
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                    new TextRun({
                      text: (this.getValue(submission, "plan_organico_vegetal_germinados_manejo_3_monitoreo_agua_si") ? '■' : '❏') + '  Si, (mencionar cada cuantos meses): ' + this.getValue(submission, "plan_organico_vegetal_germinados_manejo_3_monitoreo_agua_si_text"),
                      font: 'Arial',
                      size: 18
                    })
                  ]
                }),
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                    new TextRun({
                      text: (this.getValue(submission, "plan_organico_vegetal_germinados_manejo_3_monitoreo_agua_no") ? '■' : '❏') + '  No',
                      font: 'Arial',
                      size: 18
                    })
                  ]
                })
              ],
              verticalAlign: VerticalAlign.TOP
            }),
            new TableCell({
              children: [
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                    new TextRun({
                      text: this.getValue(submission, "plan_organico_vegetal_germinados_manejo_3_comentarios"),
                      font: 'Arial',
                      size: 18
                    })
                  ]
                })
              ],
              verticalAlign: VerticalAlign.TOP
            }),
            new TableCell({
              children: [
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                      new TextRun({
                          text: ` ${this.getValue(submission, 'plan_organico_vegetal_germinados_manejo_3_implementacion') === 'I' ? '■' : '❏'} I`,
                          font: 'Arial',
                          size: 18
                      })
                  ]
                }),
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                      new TextRun({
                          text: ` ${this.getValue(submission, 'plan_organico_vegetal_germinados_manejo_3_implementacion') === 'NI' ? '■' : '❏'} NI`,
                          font: 'Arial',
                          size: 18
                      })
                  ]
                })
              ],
              verticalAlign: VerticalAlign.TOP
          }),
          new TableCell({
              children: [
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                      new TextRun({
                          text: ` ${this.getValue(submission, 'plan_organico_vegetal_germinados_manejo_3_conformidad') === 'C' ? '■' : '❏'} C`,
                          font: 'Arial',
                          size: 18
                      })
                  ]
                }),
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                      new TextRun({
                          text: ` ${this.getValue(submission, 'plan_organico_vegetal_germinados_manejo_3_conformidad') === 'NC' ? '■' : '❏'} NC`,
                          font: 'Arial',
                          size: 18
                      })
                  ]
                }),
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                      new TextRun({
                          text: ` ${this.getValue(submission, 'plan_organico_vegetal_germinados_manejo_3_conformidad') === 'NA' ? '■' : '❏'} NA`,
                          font: 'Arial',
                          size: 18
                      })
                  ]
                })
              ],
              verticalAlign: VerticalAlign.TOP
          }),
          new TableCell({
              children: [
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                      new TextRun({
                          text: this.getValue(submission, 'plan_organico_vegetal_germinados_manejo_3_comentarios_inspector'),
                          font: 'Arial',
                          size: 18
                      })
                  ]
                })
              ],
              verticalAlign: VerticalAlign.TOP
          })
          ]
        }),
        new TableRow({
          children: [
            new TableCell({
              children: [
                new Paragraph({
                  alignment: AlignmentType.CENTER,
                  children: [
                    new TextRun({
                      text: '4',
                      font: 'Arial',
                      size: 18
                    })
                  ]
                })
              ],
              verticalAlign: VerticalAlign.TOP
            }),
            new TableCell({
              children: [
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                    new TextRun({
                      text: '¿Utiliza Fertilizantes?',
                      font: 'Arial',
                      size: 18
                    })
                  ]
                }),
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                    new TextRun({
                      text: (this.getValue(submission, "plan_organico_vegetal_germinados_manejo_4_ninguno") ? '■' : '❏') + ' Ninguno',
                      font: 'Arial',
                      size: 18
                    })
                  ]
                }),
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                    new TextRun({
                      text: (this.getValue(submission, "plan_organico_vegetal_germinados_manejo_4_fertilizantes_utilizados") ? '■' : '❏') + ' Si (¿Cuál?): ' + this.getValue(submission, "plan_organico_vegetal_germinados_manejo_4_fertilizantes_utilizados"),
                      font: 'Arial',
                      size: 18
                    })
                  ]
                })
              ],
              verticalAlign: VerticalAlign.TOP
            }),
            new TableCell({
              children: [
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                    new TextRun({
                      text: this.getValue(submission, "plan_organico_vegetal_germinados_manejo_4_comentarios"),
                      font: 'Arial',
                      size: 18
                    })
                  ]
                })
              ],
              verticalAlign: VerticalAlign.TOP
            }),
            new TableCell({
              children: [
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                      new TextRun({
                          text: ` ${this.getValue(submission, 'plan_organico_vegetal_germinados_manejo_4_implementacion') === 'I' ? '■' : '❏'} I`,
                          font: 'Arial',
                          size: 18
                      })
                  ]
                }),
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                      new TextRun({
                          text: ` ${this.getValue(submission, 'plan_organico_vegetal_germinados_manejo_4_implementacion') === 'NI' ? '■' : '❏'} NI`,
                          font: 'Arial',
                          size: 18
                      })
                  ]
                })
              ],
              verticalAlign: VerticalAlign.TOP
          }),
          new TableCell({
              children: [
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                      new TextRun({
                          text: ` ${this.getValue(submission, 'plan_organico_vegetal_germinados_manejo_4_conformidad') === 'C' ? '■' : '❏'} C`,
                          font: 'Arial',
                          size: 18
                      })
                  ]
                }),
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                      new TextRun({
                          text: ` ${this.getValue(submission, 'plan_organico_vegetal_germinados_manejo_4_conformidad') === 'NC' ? '■' : '❏'} NC`,
                          font: 'Arial',
                          size: 18
                      })
                  ]
                }),
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                      new TextRun({
                          text: ` ${this.getValue(submission, 'plan_organico_vegetal_germinados_manejo_4_conformidad') === 'NA' ? '■' : '❏'} NA`,
                          font: 'Arial',
                          size: 18
                      })
                  ]
                })
              ],
              verticalAlign: VerticalAlign.TOP
          }),
          new TableCell({
              children: [
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                      new TextRun({
                          text: this.getValue(submission, 'plan_organico_vegetal_germinados_manejo_4_comentarios_inspector'),
                          font: 'Arial',
                          size: 18
                      })
                  ]
                })
              ],
              verticalAlign: VerticalAlign.TOP
          })
          ]
        }),
        new TableRow({
          children: [
            new TableCell({
              children: [
                new Paragraph({
                  alignment: AlignmentType.CENTER,
                  children: [
                    new TextRun({
                      text: '5',
                      font: 'Arial',
                      size: 18
                    })
                  ]
                })
              ],
              verticalAlign: VerticalAlign.TOP
            }),
            new TableCell({
              children: [
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                    new TextRun({
                      text: '¿Utiliza iluminación artificial?:',
                      font: 'Arial',
                      size: 18
                    })
                  ]
                }),
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                    new TextRun({
                      text: (this.getValue(submission, "plan_organico_vegetal_germinados_manejo_5_iluminacion_artificial_si") ? '■' : '❏') + ' Si',
                      font: 'Arial',
                      size: 18
                    })
                  ]
                }),
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                    new TextRun({
                      text: (this.getValue(submission, "plan_organico_vegetal_germinados_manejo_5_iluminacion_artificial_no") ? '■' : '❏') + ' No',
                      font: 'Arial',
                      size: 18
                    })
                  ]
                })
              ],
              verticalAlign: VerticalAlign.TOP
            }),
            new TableCell({
              children: [
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                    new TextRun({
                      text: this.getValue(submission, "plan_organico_vegetal_germinados_manejo_5_comentarios"),
                      font: 'Arial',
                      size: 18
                    })
                  ]
                })
              ],
              verticalAlign: VerticalAlign.TOP
            }),
            new TableCell({
              children: [
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                      new TextRun({
                          text: ` ${this.getValue(submission, 'plan_organico_vegetal_germinados_manejo_5_implementacion') === 'I' ? '■' : '❏'} I`,
                          font: 'Arial',
                          size: 18
                      })
                  ]
                }),
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                      new TextRun({
                          text: ` ${this.getValue(submission, 'plan_organico_vegetal_germinados_manejo_5_implementacion') === 'NI' ? '■' : '❏'} NI`,
                          font: 'Arial',
                          size: 18
                      })
                  ]
                })
              ],
              verticalAlign: VerticalAlign.TOP
          }),
          new TableCell({
              children: [
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                      new TextRun({
                          text: ` ${this.getValue(submission, 'plan_organico_vegetal_germinados_manejo_5_conformidad') === 'C' ? '■' : '❏'} C`,
                          font: 'Arial',
                          size: 18
                      })
                  ]
                }),
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                      new TextRun({
                          text: ` ${this.getValue(submission, 'plan_organico_vegetal_germinados_manejo_5_conformidad') === 'NC' ? '■' : '❏'} NC`,
                          font: 'Arial',
                          size: 18
                      })
                  ]
                }),
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                      new TextRun({
                          text: ` ${this.getValue(submission, 'plan_organico_vegetal_germinados_manejo_5_conformidad') === 'NA' ? '■' : '❏'} NA`,
                          font: 'Arial',
                          size: 18
                      })
                  ]
                })
              ],
              verticalAlign: VerticalAlign.TOP
          }),
          new TableCell({
              children: [
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                      new TextRun({
                          text: this.getValue(submission, 'plan_organico_vegetal_germinados_manejo_5_comentarios_inspector'),
                          font: 'Arial',
                          size: 18
                      })
                  ]
                })
              ],
              verticalAlign: VerticalAlign.TOP
          })
          ]
        }),
        new TableRow({
          children: [
            new TableCell({
              children: [
                new Paragraph({
                  alignment: AlignmentType.CENTER,
                  children: [
                    new TextRun({
                      text: '6',
                      font: 'Arial',
                      size: 18
                    })
                  ]
                })
              ],
              verticalAlign: VerticalAlign.TOP
            }),
            new TableCell({
              children: [
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                    new TextRun({
                      text: 'Material de contenedores o charolas usadas para germinación:',
                      font: 'Arial',
                      size: 18
                    })
                  ]
                }),
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                    new TextRun({
                      text: (this.getValue(submission, "plan_organico_vegetal_germinados_manejo_6_polietileno") ? '■' : '❏') + ' Polietileno',
                      font: 'Arial',
                      size: 18
                    })
                  ]
                }),
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                    new TextRun({
                      text: (this.getValue(submission, "plan_organico_vegetal_germinados_manejo_6_polipropileno") ? '■' : '❏') + ' Polipropileno',
                      font: 'Arial',
                      size: 18
                    })
                  ]
                }),
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                    new TextRun({
                      text: (this.getValue(submission, "plan_organico_vegetal_germinados_manejo_6_policarbonato") ? '■' : '❏') + ' Policarbonato',
                      font: 'Arial',
                      size: 18
                    })
                  ]
                }),
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                    new TextRun({
                      text: (this.getValue(submission, "plan_organico_vegetal_germinados_manejo_6_acero_inoxidable") ? '■' : '❏') + ' Acero inoxidable',
                      font: 'Arial',
                      size: 18
                    })
                  ]
                }),
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                    new TextRun({
                      text: (this.getValue(submission, "plan_organico_vegetal_germinados_manejo_6_materiales_vegetales_sin_tratar") ? '■' : '❏') + '  Materiales vegetales sin tratar (¿Cuál?) ' + this.getValue(submission, "plan_organico_vegetal_germinados_manejo_6_materiales_vegetales_sin_tratar_text"),
                      font: 'Arial',
                      size: 18
                    })
                  ]
                }),
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                    new TextRun({
                      text: (this.getValue(submission, "plan_organico_vegetal_germinados_manejo_6_otro") ? '■' : '❏') + ' Otro (¿Cuál?): ' + this.getValue(submission, "plan_organico_vegetal_germinados_manejo_6_otro_text"),
                      font: 'Arial',
                      size: 18
                    })
                  ]
                })
              ],
              verticalAlign: VerticalAlign.TOP
            }),
            new TableCell({
              children: [
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                    new TextRun({
                      text: this.getValue(submission, "plan_organico_vegetal_germinados_manejo_6_comentarios"),
                      font: 'Arial',
                      size: 18
                    })
                  ]
                })
              ],
              verticalAlign: VerticalAlign.TOP
            }),
            new TableCell({
              children: [
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                      new TextRun({
                          text: ` ${this.getValue(submission, 'plan_organico_vegetal_germinados_manejo_6_implementacion') === 'I' ? '■' : '❏'} I`,
                          font: 'Arial',
                          size: 18
                      })
                  ]
                }),
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                      new TextRun({
                          text: ` ${this.getValue(submission, 'plan_organico_vegetal_germinados_manejo_6_implementacion') === 'NI' ? '■' : '❏'} NI`,
                          font: 'Arial',
                          size: 18
                      })
                  ]
                })
              ],
              verticalAlign: VerticalAlign.TOP
          }),
          new TableCell({
              children: [
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                      new TextRun({
                          text: ` ${this.getValue(submission, 'plan_organico_vegetal_germinados_manejo_6_conformidad') === 'C' ? '■' : '❏'} C`,
                          font: 'Arial',
                          size: 18
                      })
                  ]
                }),
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                      new TextRun({
                          text: ` ${this.getValue(submission, 'plan_organico_vegetal_germinados_manejo_6_conformidad') === 'NC' ? '■' : '❏'} NC`,
                          font: 'Arial',
                          size: 18
                      })
                  ]
                }),
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                      new TextRun({
                          text: ` ${this.getValue(submission, 'plan_organico_vegetal_germinados_manejo_6_conformidad') === 'NA' ? '■' : '❏'} NA`,
                          font: 'Arial',
                          size: 18
                      })
                  ]
                })
              ],
              verticalAlign: VerticalAlign.TOP
          }),
          new TableCell({
              children: [
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                      new TextRun({
                          text: this.getValue(submission, 'plan_organico_vegetal_germinados_manejo_6_comentarios_inspector'),
                          font: 'Arial',
                          size: 18
                      })
                  ]
                })
              ],
              verticalAlign: VerticalAlign.TOP
          })
          ]
        }),

        new TableRow({
          children: [
            new TableCell({
              children: [
                new Paragraph({
                  alignment: AlignmentType.CENTER,
                  children: [
                    new TextRun({
                      text: '7',
                      font: 'Arial',
                      size: 18
                    })
                  ]
                })
              ],
              verticalAlign: VerticalAlign.TOP
            }),
            new TableCell({
              children: [
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                    new TextRun({
                      text: 'Se realizan las siguientes actividades de limpieza e higiene de los contenedores:',
                      font: 'Arial',
                      size: 18
                    })
                  ]
                }),
                new Table({
                  rows: [
                      new TableRow({
                          children: [
                              new TableCell({
                                  children: [
                                      new Paragraph({
                                          alignment: AlignmentType.CENTER,
                                          children: [
                                              new TextRun({
                                                  text: 'Actividad',
                                                  font: 'Arial',
                                                  size: 18,
                                                  bold: true
                                              })
                                          ]
                                      })
                                  ],
                                  verticalAlign: VerticalAlign.CENTER,
                                  shading: {
                                      fill: '#C0C0C0'
                                  }
                              }),
                              new TableCell({
                                  children: [
                                      new Paragraph({
                                          alignment: AlignmentType.CENTER,
                                          children: [
                                              new TextRun({
                                                  text: 'Frecuencia',
                                                  font: 'Arial',
                                                  size: 18,
                                                  bold: true
                                              })
                                          ]
                                      })
                                  ],
                                  verticalAlign: VerticalAlign.CENTER,
                                  shading: {
                                      fill: '#C0C0C0'
                                  }
                              }),
                          ]
                      }),
                      new TableRow({
                          children: [
                              new TableCell({
                                  children: [
                                      new Paragraph({
                                          alignment: AlignmentType.LEFT,
                                          children: [
                                              new TextRun({
                                                  text: (this.getValue(submission, "plan_organico_vegetal_germinados_manejo_7_lavado_frecuencia") ? '■' : '❏') + ' Lavado',
                                                  font: 'Arial',
                                                  size: 18,
                                              })
                                          ]
                                      })
                                  ],
                                  verticalAlign: VerticalAlign.CENTER,
                              }),
                              new TableCell({
                                  children: [
                                      new Paragraph({
                                          alignment: AlignmentType.LEFT,
                                          children: [
                                              new TextRun({
                                                  text: `${this.getValue(submission, "plan_organico_vegetal_germinados_manejo_7_lavado_frecuencia")}`,
                                                  font: 'Arial',
                                                  size: 18
                                              })
                                          ]
                                      })
                                  ],
                                  verticalAlign: VerticalAlign.CENTER,
                              }),
                          ]
                      }),
                      new TableRow({
                          children: [
                              new TableCell({
                                  children: [
                                      new Paragraph({
                                          alignment: AlignmentType.LEFT,
                                          children: [
                                              new TextRun({
                                                  text: (this.getValue(submission, "plan_organico_vegetal_germinados_manejo_7_enjuage_frecuencia") ? '■' : '❏') + ' Enjuague',
                                                  font: 'Arial',
                                                  size: 18,
                                              })
                                          ]
                                      })
                                  ],
                                  verticalAlign: VerticalAlign.CENTER,
                              }),
                              new TableCell({
                                  children: [
                                      new Paragraph({
                                          alignment: AlignmentType.LEFT,
                                          children: [
                                              new TextRun({
                                                  text: `${this.getValue(submission, "plan_organico_vegetal_germinados_manejo_7_enjuage_frecuencia")}`,
                                                  font: 'Arial',
                                                  size: 18
                                              })
                                          ]
                                      })
                                  ],
                                  verticalAlign: VerticalAlign.CENTER,
                              }),
                          ]
                      }),
                      new TableRow({
                          children: [
                              new TableCell({
                                  children: [
                                      new Paragraph({
                                          alignment: AlignmentType.LEFT,
                                          children: [
                                              new TextRun({
                                                  text: (this.getValue(submission, "plan_organico_vegetal_germinados_manejo_7_desinfeccion_frecuencia") ? '■' : '❏') + ' Desinfección',
                                                  font: 'Arial',
                                                  size: 18,
                                              })
                                          ]
                                      })
                                  ],
                                  verticalAlign: VerticalAlign.CENTER,
                              }),
                              new TableCell({
                                  children: [
                                      new Paragraph({
                                          alignment: AlignmentType.LEFT,
                                          children: [
                                              new TextRun({
                                                  text: `${this.getValue(submission, "plan_organico_vegetal_germinados_manejo_7_desinfeccion_frecuencia")}`,
                                                  font: 'Arial',
                                                  size: 18
                                              })
                                          ]
                                      })
                                  ],
                                  verticalAlign: VerticalAlign.CENTER,
                              }),
                          ]
                      }),
                      new TableRow({
                          children: [
                              new TableCell({
                                  children: [
                                      new Paragraph({
                                          alignment: AlignmentType.LEFT,
                                          children: [
                                              new TextRun({
                                                  text: (this.getValue(submission, "plan_organico_vegetal_germinados_manejo_7_otro_texto") ? '■' : '❏') + ' Otro (¿Cuál?):' + this.getValue(submission, "plan_organico_vegetal_germinados_manejo_7_otro_texto"),
                                                  font: 'Arial',
                                                  size: 18,
                                              })
                                          ]
                                      })
                                  ],
                                  verticalAlign: VerticalAlign.CENTER,
                              }),
                              new TableCell({
                                  children: [
                                      new Paragraph({
                                          alignment: AlignmentType.LEFT,
                                          children: [
                                              new TextRun({
                                                  text: `${this.getValue(submission, "plan_organico_vegetal_germinados_manejo_7_otro_frecuencia")}`,
                                                  font: 'Arial',
                                                  size: 18
                                              })
                                          ]
                                      })
                                  ],
                                  verticalAlign: VerticalAlign.CENTER,
                              }),
                          ]
                      }),
                  ],
                  margins: {
                      left: 70,
                      right: 70
                  },
                  columnWidths: [2067, 3000]
                }),
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                    new TextRun({
                      text: `Si realiza desinfección, ¿con que desinfecta?: ${this.getValue(submission, "plan_organico_vegetal_germinados_manejo_7_realiza_desinfeccion_texto")}`,
                      font: 'Arial',
                      size: 18
                    })
                  ]
                })
              ],
              verticalAlign: VerticalAlign.TOP
            }),
            new TableCell({
              children: [
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                    new TextRun({
                      text: this.getValue(submission, "plan_organico_vegetal_manejo_7_comentarios"),
                      font: 'Arial',
                      size: 18
                    })
                  ]
                })
              ],
              verticalAlign: VerticalAlign.TOP
            }),
            new TableCell({
              children: [
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                      new TextRun({
                          text: ` ${this.getValue(submission, 'plan_organico_vegetal_germinados_manejo_7_implementacion') === 'I' ? '■' : '❏'} I`,
                          font: 'Arial',
                          size: 18
                      })
                  ]
                }),
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                      new TextRun({
                          text: ` ${this.getValue(submission, 'plan_organico_vegetal_germinados_manejo_7_implementacion') === 'NI' ? '■' : '❏'} NI`,
                          font: 'Arial',
                          size: 18
                      })
                  ]
                })
              ],
              verticalAlign: VerticalAlign.TOP
          }),
          new TableCell({
              children: [
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                      new TextRun({
                          text: ` ${this.getValue(submission, 'plan_organico_vegetal_germinados_manejo_7_conformidad') === 'C' ? '■' : '❏'} C`,
                          font: 'Arial',
                          size: 18
                      })
                  ]
                }),
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                      new TextRun({
                          text: ` ${this.getValue(submission, 'plan_organico_vegetal_germinados_manejo_7_conformidad') === 'NC' ? '■' : '❏'} NC`,
                          font: 'Arial',
                          size: 18
                      })
                  ]
                }),
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                      new TextRun({
                          text: ` ${this.getValue(submission, 'plan_organico_vegetal_germinados_manejo_7_conformidad') === 'NA' ? '■' : '❏'} NA`,
                          font: 'Arial',
                          size: 18
                      })
                  ]
                })
              ],
              verticalAlign: VerticalAlign.TOP
          }),
          new TableCell({
              children: [
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                      new TextRun({
                          text: this.getValue(submission, 'plan_organico_vegetal_germinados_manejo_7_comentarios_inspector'),
                          font: 'Arial',
                          size: 18
                      })
                  ]
                })
              ],
              verticalAlign: VerticalAlign.TOP
          })
          ]
        }),

        new TableRow({
          children: [
            new TableCell({
              children: [
                new Paragraph({
                  alignment: AlignmentType.CENTER,
                  children: [
                    new TextRun({
                      text: '8',
                      font: 'Arial',
                      size: 18
                    })
                  ]
                })
              ],
              verticalAlign: VerticalAlign.TOP
            }),
            new TableCell({
              children: [
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                    new TextRun({
                      text: 'Para la cosecha y post-cosecha las siguientes prácticas están bajo mi control y mantienen la calidad orgánica del producto:',
                      font: 'Arial',
                      size: 18
                    })
                  ]
                }),
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                    new TextRun({
                      text: (this.getValue(submission, "plan_organico_vegetal_germinados_manejo_8_corte") ? '■' : '❏') + ' Corte',
                      font: 'Arial',
                      size: 18
                    })
                  ]
                }),
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                    new TextRun({
                      text: (this.getValue(submission, "plan_organico_vegetal_germinados_manejo_8_no_seleccion") ? '■' : '❏') + ' Selección',
                      font: 'Arial',
                      size: 18
                    })
                  ]
                }),
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                    new TextRun({
                      text: (this.getValue(submission, "plan_organico_vegetal_germinados_manejo_8_lavado") ? '■' : '❏') + ' Lavado',
                      font: 'Arial',
                      size: 18
                    })
                  ]
                }),
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                    new TextRun({
                      text: (this.getValue(submission, "plan_organico_vegetal_germinados_manejo_8_ganado_refrigeracion") ? '■' : '❏') + ' Refrigeración',
                      font: 'Arial',
                      size: 18
                    })
                  ]
                }),
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                    new TextRun({
                      text: (this.getValue(submission, "plan_organico_vegetal_germinados_manejo_8_ganado_transporte") ? '■' : '❏') + ' Transporte',
                      font: 'Arial',
                      size: 18
                    })
                  ]
                }),
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                    new TextRun({
                      text: (this.getValue(submission, "plan_organico_vegetal_germinados_manejo_8_otro") ? '■' : '❏') + ' Otro (¿Cuál?): ' + this.getValue(submission, "plan_organico_vegetal_germinados_manejo_8_otro_text"),
                      font: 'Arial',
                      size: 18
                    })
                  ]
                })
              ],
              verticalAlign: VerticalAlign.TOP
            }),
            new TableCell({
              children: [
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                    new TextRun({
                      text: this.getValue(submission, "plan_organico_vegetal_germinados_manejo_8_comentarios"),
                      font: 'Arial',
                      size: 18
                    })
                  ]
                })
              ],
              verticalAlign: VerticalAlign.TOP
            }),
            new TableCell({
              children: [
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                      new TextRun({
                          text: ` ${this.getValue(submission, 'plan_organico_vegetal_germinados_manejo_8_implementacion') === 'I' ? '■' : '❏'} I`,
                          font: 'Arial',
                          size: 18
                      })
                  ]
                }),
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                      new TextRun({
                          text: ` ${this.getValue(submission, 'plan_organico_vegetal_germinados_manejo_8_implementacion') === 'NI' ? '■' : '❏'} NI`,
                          font: 'Arial',
                          size: 18
                      })
                  ]
                })
              ],
              verticalAlign: VerticalAlign.TOP
          }),
          new TableCell({
              children: [
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                      new TextRun({
                          text: ` ${this.getValue(submission, 'plan_organico_vegetal_germinados_manejo_8_conformidad') === 'C' ? '■' : '❏'} C`,
                          font: 'Arial',
                          size: 18
                      })
                  ]
                }),
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                      new TextRun({
                          text: ` ${this.getValue(submission, 'plan_organico_vegetal_germinados_manejo_8_conformidad') === 'NC' ? '■' : '❏'} NC`,
                          font: 'Arial',
                          size: 18
                      })
                  ]
                }),
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                      new TextRun({
                          text: ` ${this.getValue(submission, 'plan_organico_vegetal_germinados_manejo_8_conformidad') === 'NA' ? '■' : '❏'} NA`,
                          font: 'Arial',
                          size: 18
                      })
                  ]
                })
              ],
              verticalAlign: VerticalAlign.TOP
          }),
          new TableCell({
              children: [
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                      new TextRun({
                          text: this.getValue(submission, 'plan_organico_vegetal_germinados_manejo_8_comentarios_inspector'),
                          font: 'Arial',
                          size: 18
                      })
                  ]
                })
              ],
              verticalAlign: VerticalAlign.TOP
          })
          ]
        }),
        new TableRow({
          children: [
            new TableCell({
              children: [
                new Paragraph({
                  alignment: AlignmentType.CENTER,
                  children: [
                    new TextRun({
                      text: '9',
                      font: 'Arial',
                      size: 18
                    })
                  ]
                })
              ],
              verticalAlign: VerticalAlign.TOP
            }),
            new TableCell({
              children: [
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                    new TextRun({
                      text: 'Los residuos de cosecha se destinan a:',
                      font: 'Arial',
                      size: 18
                    })
                  ]
                }),
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                    new TextRun({
                      text: (this.getValue(submission, "plan_organico_vegetal_germinados_manejo_9_reintegra_a_parcela") ? '■' : '❏') + ' Se reintegran a la parcela',
                      font: 'Arial',
                      size: 18
                    })
                  ]
                }),
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                    new TextRun({
                      text: (this.getValue(submission, "plan_organico_vegetal_germinados_manejo_9_desecho_en_instalaciones_publicas") ? '■' : '❏') + ' Se desechan en instalaciones públicas',
                      font: 'Arial',
                      size: 18
                    })
                  ]
                }),
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                    new TextRun({
                      text: (this.getValue(submission, "plan_organico_vegetal_germinados_manejo_9_se_venden") ? '■' : '❏') + ' Se venden',
                      font: 'Arial',
                      size: 18
                    })
                  ]
                }),
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                    new TextRun({
                      text: (this.getValue(submission, "plan_organico_vegetal_germinados_manejo_9_otro") ? '■' : '❏') + ' Otro (¿Cuál?): ' + this.getValue(submission, "plan_organico_vegetal_germinados_manejo_9_otro_text"),
                      font: 'Arial',
                      size: 18
                    })
                  ]
                })
              ],
              verticalAlign: VerticalAlign.TOP
            }),
            new TableCell({
              children: [
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                    new TextRun({
                      text: this.getValue(submission, "plan_organico_vegetal_germinados_manejo_9_comentarios"),
                      font: 'Arial',
                      size: 18
                    })
                  ]
                })
              ],
              verticalAlign: VerticalAlign.TOP
            }),
            new TableCell({
              children: [
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                      new TextRun({
                          text: ` ${this.getValue(submission, 'plan_organico_vegetal_germinados_manejo_9_implementacion') === 'I' ? '■' : '❏'} I`,
                          font: 'Arial',
                          size: 18
                      })
                  ]
                }),
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                      new TextRun({
                          text: ` ${this.getValue(submission, 'plan_organico_vegetal_germinados_manejo_9_implementacion') === 'NI' ? '■' : '❏'} NI`,
                          font: 'Arial',
                          size: 18
                      })
                  ]
                })
              ],
              verticalAlign: VerticalAlign.TOP
          }),
          new TableCell({
              children: [
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                      new TextRun({
                          text: ` ${this.getValue(submission, 'plan_organico_vegetal_germinados_manejo_9_conformidad') === 'C' ? '■' : '❏'} C`,
                          font: 'Arial',
                          size: 18
                      })
                  ]
                }),
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                      new TextRun({
                          text: ` ${this.getValue(submission, 'plan_organico_vegetal_germinados_manejo_9_conformidad') === 'NC' ? '■' : '❏'} NC`,
                          font: 'Arial',
                          size: 18
                      })
                  ]
                }),
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                      new TextRun({
                          text: ` ${this.getValue(submission, 'plan_organico_vegetal_germinados_manejo_9_conformidad') === 'NA' ? '■' : '❏'} NA`,
                          font: 'Arial',
                          size: 18
                      })
                  ]
                })
              ],
              verticalAlign: VerticalAlign.TOP
          }),
          new TableCell({
              children: [
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                      new TextRun({
                          text: this.getValue(submission, 'plan_organico_vegetal_germinados_manejo_9_comentarios_inspector'),
                          font: 'Arial',
                          size: 18
                      })
                  ]
                })
              ],
              verticalAlign: VerticalAlign.TOP
          })
          ]
        }),
      ],
      margins: {
        left: 70,
        right: 70
      },
      //9885
      columnWidths: [450, 3895, 2045, 1150, 800, 1545]
    })
    return table
  }

  public createTableUDP(data, datePipe): Table {
    const table = new Table({
      rows: [
        new TableRow({
          children: [
            new TableCell({
              children: [
                new Paragraph({
                  alignment: AlignmentType.RIGHT,
                  children: [
                    new TextRun({
                      text: 'Operador: ',
                      font: 'Calibri (Cuerpo)',
                      size: 22,
                      bold: true,

                    })
                  ],
                  spacing: {before: 100, after: 200}
                })],
              verticalAlign: VerticalAlign.CENTER,
            }),
            new TableCell({
              children: [new Paragraph({
                alignment: AlignmentType.CENTER,
                children: [
                  new TextRun({
                    text: data.customer.Company_Name,
                    font: 'Calibri (Cuerpo)',
                    size: 22,
                  })
                ],
              })],
              verticalAlign: VerticalAlign.CENTER,
              columnSpan: 3,
            }),
          ],
        }),
        new TableRow({
          children: [
            new TableCell({
              children: [
                new Paragraph({
                  alignment: AlignmentType.RIGHT,
                  children: [
                    new TextRun({
                      text: 'Código del operador: ',
                      font: 'Calibri (Cuerpo)',
                      size: 22,
                      bold: true,

                    })
                  ],
                  spacing: {before: 100, after: 200}
                })],
              width: {
                size: 25,
                type: WidthType.PERCENTAGE,
              },
            }),
            new TableCell({
              children: [new Paragraph({
                alignment: AlignmentType.CENTER,
                children: [
                  new TextRun({
                    text: data.customer.Id_Comercial,
                    font: 'Calibri (Cuerpo)',
                    size: 22,
                  })
                ],
              })],
              verticalAlign: VerticalAlign.CENTER,
              width: {
                size: 25,
                type: WidthType.PERCENTAGE,
              },
            }),
            new TableCell({
              children: [
                new Paragraph({
                  alignment: AlignmentType.RIGHT,
                  children: [
                    new TextRun({
                      text: 'Fecha: ',
                      font: 'Calibri (Cuerpo)',
                      size: 22,
                      bold: true,

                    })
                  ],
                  spacing: {before: 100, after: 200}
                })],
              verticalAlign: VerticalAlign.CENTER,
              width: {
                size: 25,
                type: WidthType.PERCENTAGE,
              },
            }),
            new TableCell({
              children: [new Paragraph({
                alignment: AlignmentType.CENTER,
                children: [
                  new TextRun({
                    text: datePipe.transform(data.record.Applications[0].Creation_Date.includes('UTC') ? data.record.Applications[0].Creation_Date.replace('UTC', '').trim() : data.record.Applications[0].Creation_Date, "d-MMMM-y"),
                    font: 'Calibri (Cuerpo)',
                    size: 22,
                  })
                ],
              })],
              verticalAlign: VerticalAlign.CENTER,
              width: {
                size: 25,
                type: WidthType.PERCENTAGE,
              },
            }),
          ],
        }),
      ],
      margins: {
        left: 70,
        right: 70
      },
      width: {
        size: 100,
        type: WidthType.PERCENTAGE,
      },
      //9638
      columnWidths: [2410, 2410, 2409, 2409]
    })

    return table
  }

  getValue(submission, name) {
    var value = null
    for (const i in submission) {
      const element = submission[i]
      if (element.name === name) {
        value = element.value
      }
    }
    if (value) return value
    else return ''
  }

  getValueList(submission, name) {
    var value = null
    for (const i in submission) {
      const element = submission[i]
      if (element.name === name) {
        value = element.value
      }
    }
    if (value) return value
    else return []
  }

  // END MTO_FSOL_08_1_3
}
