<div class="row">
  <div class="col-lg-12" *ngIf="!attributes.hide_non_selected || validateSelection(attributes.options)">
    <h6>{{attributes.label}}</h6>
    <div *ngIf="attributes.options" class="form-group" [formGroup]="form">
      <div class="form-check" *ngFor="let item of attributes.options">
        <label class="form-check-label">
          <input 
            class="form-check-input" 
            type="checkbox" 
            [formControlName]="item[0]"
            [attr.disabled]="validations.disabled" 
            (change)="change($event, item[0])"
          />
          {{item[1]}}
        </label>
      </div>

      <div class="form-check" *ngFor="let item of attributes.options_scopes">
        <div
          [ngStyle]="{'margin-left': item[2] ? '60px' : ''}"
        >
          <label class="form-check-label">
            <input 
              class="form-check-input" 
              type="checkbox" 
              [formControlName]="item[0]"
              [attr.disabled]="validations.disabled" 
              (change)="change($event, item[0])"
            />
            {{item[1]}}
          </label>
        </div>
      </div>

      <div *ngIf="attributes.special_option" class="form-check">
        <label class="form-check-label">
          <input 
            class="form-check-input" 
            type="checkbox" 
            [formControlName]="attributes.special_option[0]"
            (change)="specialOptValueChanged($event, attributes.special_option[2])" 
            [attr.disabled]="validations.disabled" 
          />
          {{attributes.special_option[1]}}
        </label>
        <input class="form-control"
          type="number"
          placeholder="Número de meses"
          [attr.disabled]="form.value[attributes.special_option[0]] ? validations.disabled ? true : null : true"
          [formControlName]="attributes.special_option[2]" />
      </div>

      <div *ngIf="attributes.special_option_input" class="form-check">
        <label class="form-check-label">
          <input 
            class="form-check-input" 
            type="checkbox" 
            [formControlName]="attributes.special_option_input[0]"
            (change)="specialOptInpValueChanged($event, attributes.special_option_input[2])" 
            [attr.disabled]="validations.disabled" 
          />
          {{attributes.special_option_input[1]}}
        </label>
        <input class="form-control"
          type="text"
          [attr.disabled]="form.value[attributes.special_option_input[0]] ? validations.disabled ? true : null : true"
          [formControlName]="attributes.special_option_input[2]" />
      </div>

      <div *ngIf="attributes.special_option_area" class="form-check">
        <label class="form-check-label">
          <input 
            class="form-check-input" 
            type="checkbox" 
            [formControlName]="attributes.special_option_area[0]"
            (change)="specialOptInpValueChanged($event, attributes.special_option_area[2])" 
            [attr.disabled]="validations.disabled" 
          />
          {{attributes.special_option_area[1]}}
        </label>
        <textarea 
          style="width: 100%" 
          class="form-control"
          rows="3" 
          [formControlName]="attributes.special_option_area[2]"
          [attr.disabled]="form.value[attributes.special_option_area[0]] ? validations.disabled ? true : null : true"
        ></textarea>
      </div>

      <div *ngIf="attributes.other_option" class="form-check">
        <label class="form-check-label">
          <input 
            class="form-check-input" 
            type="checkbox" 
            [formControlName]="attributes.other_option[0]"
            (change)="otherOptValueChanged($event, attributes.other_option[1])" 
            [attr.disabled]="validations.disabled" 
          />
          {{attributes.other_option[2] && attributes.other_option[2] !== '' ? attributes.other_option[2] : 'Otro:'}}
        </label>
        <input class="form-control"
          [attr.disabled]="form.value[attributes.other_option[0]] ? validations.disabled ? true : null : true"
          [formControlName]="attributes.other_option[1]" />
      </div>
    </div>

    <div *ngIf="attributes.options_multi" class="form-group" [formGroup]="form">
      <div class="form-check" *ngFor="let item of attributes.options_multi">
        <label class="form-check-label" *ngIf="multiOptionsIsChecked(item[0])">
          <input 
            class="form-check-input" 
            type="checkbox" 
            [checked]="multiOptionsIsChecked(item[0])"
            [attr.disabled]="validations.disabled" 
          />
          {{item[1]}}
        </label>
      </div>
    </div>
  </div>
</div>