import { FormGroup } from '@angular/forms';
import { ControlElement, ContainerElement, UnknownParams } from './../../../interfaces/form.interface';
import { OnInit, Component, Input } from '@angular/core'

@Component({
    selector: 'card-container',
    templateUrl: './card.component.html'
})
export class CardComponent implements OnInit {

    @Input() data: ContainerElement
    @Input() form: FormGroup

    attributes: UnknownParams = {}

    constructor() {}

    ngOnInit() {
        for (var i in this.data.attributes) {
            const attribute = this.data.attributes[i]
            this.attributes[attribute.name] = attribute.value
        }
    }

}