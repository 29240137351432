import { readFileSync } from 'fs';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '@environments/environment';

@Injectable({ providedIn: 'root' })
export class CertificatesService {

    constructor(private http: HttpClient) { }

    getCertificates(page, search) {
        const jwtToken = (document.cookie.split(';').find(x => x.includes('jwtToken')) || '=').split('=')[1]

        const headers = {
            'Content-Type': 'application/json',
            'Authorization': jwtToken
        };

        const body = {
            page: page,
            search: search
        }

        return this.http.post<any>(`${environment.apiUrl}/certificates/`, body, { headers });
    }

    getDetailCertificates(id, data = {lpo: '', cor: ''}) {
        const jwtToken = (document.cookie.split(';').find(x => x.includes('jwtToken')) || '=').split('=')[1]

        const headers = {
            'Content-Type': 'application/json',
            'Authorization': jwtToken
        };
        
        const body = {
            certificateNumberLPO: data.lpo,
            certificateNumberOPR: data.cor
        }


        return this.http.post<any>(`${environment.apiUrl}/certificates/${id}`, body, { headers });
    }

    regeneratePDFCertificates(recordId, certificateId) {
        const jwtToken = (document.cookie.split(';').find(x => x.includes('jwtToken')) || '=').split('=')[1]

        const headers = {
            'Content-Type': 'application/json',
            'Authorization': jwtToken
        };

        const body = {
            certificateId: certificateId
        }

        return this.http.post<any>(`${environment.apiUrl}/certificates/regeneratePdf/${recordId}`, body, { headers });
    }

    getUrlStatus(url: string) {
        return this.http.get(url);
    }

    getCertificatesHistory(evaluationId) {
        const jwtToken = (document.cookie.split(';').find(x => x.includes('jwtToken')) || '=').split('=')[1]

        const headers = {
            'Content-Type': 'application/json',
            'Authorization': jwtToken
        };

        return this.http.get<any>(`${environment.apiUrl}/certificates/getJSONs/${evaluationId}`, { headers });

    }

    getFormCertificate(evaluationId, formId) {
        const jwtToken = (document.cookie.split(';').find(x => x.includes('jwtToken')) || '=').split('=')[1]

        const headers = {
            'Content-Type': 'application/json',
            'Authorization': jwtToken
        };

        return this.http.get<any>(`${environment.apiUrl}/certificates/getFormCertificate/${evaluationId}/form/${formId}`, { headers });
    }

    saveFormCertificate(data) {
        const jwtToken = (document.cookie.split(';').find(x => x.includes('jwtToken')) || '=').split('=')[1]

        const headers = {
            'Content-Type': 'application/json',
            'Authorization': jwtToken
        };

        return this.http.post<any>(`${environment.apiUrl}/certificates/saveFormCertificate`, data, { headers });
    }

    //type es 4, 5 (Descargas operador, descargas metrocert)
    registerDownloadCertificate(recordId, type, destination) {
        const jwtToken = (document.cookie.split(';').find(x => x.includes('jwtToken')) || '=').split('=')[1]

        const headers = {
            'Content-Type': 'application/json',
            'Authorization': jwtToken
        };

        const body = {}

        return this.http.post<any>(`${environment.apiUrl}/certificates/downloadMetrocert/${recordId}/${type}/${destination}`, body, { headers });
    }

    //type es 1,2,3 (qr,operator,shared)
    registerDownload(recordId, type, data, destination, other='') {

        //const jwtToken = (document.cookie.split(';').find(x => x.includes('jwtToken')) || '=').split('=')[1]

        const headers = {
            'Content-Type': 'application/json',
            //'Authorization': jwtToken
        };

        let body = {
            signature: data,
            other: other
        }

        return this.http.post<any>(`${environment.apiUrl}/certificates/download/${recordId}/${type}/${destination}`, body, { headers });

    }

    getIP() {
        const headers = {
            'Content-Type': 'application/json'
        };

        return this.http.get<any>("https://myexternalip.com/json", { headers })
    }

    /* getPosition(ip) {
        const headers = {
            'Content-Type': 'application/json'
        };

        return this.http.get<any>(`https://ipapi.co/${ip}/json`, { headers })
    } */
}