import { AlignmentType, Document, Paragraph, TextRun, Media, Header, Footer, Table, TableRow, TableCell, WidthType, PageNumber, ShadingType, PageBreak, BorderStyle } from "docx";
import { logo } from "../logo";
import { DatePipe } from '@angular/common';
import { truncateSync } from 'fs';
import {userWithoutSign} from "../userWithoutSign"

export class DocumentCreatorMTO_FINS_03_1_5_v3 {
    public getBase64FromUrl = async (url) => {
        const data = await fetch(url, {
          headers: {
              'Cache-Control': 'no-cache, no-store, must-revalidate',
              'Pragma': 'no-cache',
              'Expires': 'Sat, 01 Jan 2000 00:00:00 GMT',
              'If-Modified-Since': '0'
          }
      });
        const blob = await data.blob();
        return new Promise((resolve) => {
          const reader = new FileReader();
          reader.readAsDataURL(blob);
          reader.onloadend = function () {
            const base64data = reader.result;
            resolve(base64data);
          }
        });
      }

    // INIT MTO_FINS_03_1_5
    public async create_MTO_FINS_03_1_5(data, typeInspection = '', signature = '', signature2 = ''): Promise<Document> {
        const document = new Document();
        const image = Media.addImage(document, logo, 50, 50);
        var datePipe = new DatePipe('es-MX');

        let signatureImage = null
        if (signature) {
            signatureImage = await this.getBase64FromUrl(signature)
        } else {
            signatureImage = userWithoutSign
        }

        let signatureImage2 = null
        if (signature2) {
        signatureImage2 = await this.getBase64FromUrl(signature2)
        } else {
        signatureImage2 = userWithoutSign
        }

        document.addSection({
            headers: {
                default: new Header({
                    children: [
                        new Paragraph({ alignment: AlignmentType.CENTER, children: [image], }),
                        new Paragraph({
                            alignment: AlignmentType.CENTER, children: [
                                new TextRun({
                                    children: [`MTO-FINS-03-1-5| R 06 | 25-Junio-2024 | Pág. `, PageNumber.CURRENT],
                                    font: 'Book Antiqua',
                                    size: 20,
                                    bold: true
                                }),
                                new TextRun({
                                    font: 'Book Antiqua',
                                    size: 20,
                                    bold: true,
                                    children: [' de ', PageNumber.TOTAL_PAGES]
                                }),
                            ], spacing: { before: 200, after: 100 }
                        })
                    ]
                })
            },
            footers: {
                default: new Footer({
                    children: [
                        new Paragraph({ alignment: AlignmentType.CENTER }),
                        this.createFooter()
                    ]
                }),
            },
            children: [
                new Paragraph({
                    alignment: AlignmentType.CENTER,
                    children: [
                        new TextRun({
                            text: 'REPORTE DE INSPECCIÓN DE CLASE FUNGI',
                            font: 'Calibri (Body)',
                            size: 22,
                            bold: true
                        })
                    ],
                    spacing: { after: 300, before: 200 }
                }),
                this.createTableUDP(data, datePipe, typeInspection),
                new Paragraph({
                    spacing: {
                        after: 0,
                        before: 0
                    }
                }),
                this.createRegistros(data),
                new Paragraph({
                    spacing: {
                        after: 0,
                        before: 0
                    }
                }),
                this.createManejo(data),
                new Paragraph({
                    spacing: {
                        after: 0,
                        before: 0
                    }
                }),
                this.createProductosCertificados(data),
                new Paragraph({
                    children: [
                        new PageBreak()
                    ],
                    spacing: {
                        after: 0,
                        before: 0
                    }
                }),
                new Paragraph({
                    alignment: AlignmentType.CENTER,
                    children: [
                        new TextRun({
                            text: 'RESUMEN DE HALLAZGOS',
                            font: 'Calibri (Body)',
                            size: 22,
                            bold: true
                        })
                    ],
                    spacing: { after: 300, before: 200 }
                }),
                this.createResumen(data),
                new Paragraph({
                    spacing: {
                        after: 0,
                        before: 0
                    }
                }),
                this.createResumenReunion(data),
                new Paragraph({
                    spacing: {
                        after: 0,
                        before: 0
                    }
                }),
                this.createComentariosOperador(data),
                new Paragraph({
                    spacing: {
                        after: 0,
                        before: 0
                    }
                }),
                this.createSigns(data, document, signatureImage, signatureImage2),
            ],
            margins: {
                top: 350,
                bottom: 500,
                left: 1000,
                right: 1000
            }
        });

        return document;
    }

    public createFooter(): Table {
        const table = new Table({
            rows: [
                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: 'Elaboró:',
                                            font: 'Calibri (Cuerpo)',
                                            size: 22
                                        })
                                    ]
                                })
                            ],
                            width: {
                                size: 33,
                                type: WidthType.PERCENTAGE
                            }
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: 'Revisó:',
                                            font: 'Calibri (Cuerpo)',
                                            size: 22
                                        })
                                    ]
                                })
                            ],
                            width: {
                                size: 33,
                                type: WidthType.PERCENTAGE
                            }
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: 'Autorizó:',
                                            font: 'Calibri (Cuerpo)',
                                            size: 22
                                        })
                                    ]
                                }),
                            ],
                            width: {
                                size: 33,
                                type: WidthType.PERCENTAGE
                            }
                        })
                    ]
                }),
                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: 'Director de certificación',
                                            font: 'Calibri (Cuerpo)',
                                            size: 16,
                                            bold: true
                                        })
                                    ]
                                }),

                            ],
                            width: {
                                size: 33,
                                type: WidthType.PERCENTAGE
                            },
                            shading: {
                                fill: '#C0C0C0'
                            }
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: 'Responsable de calidad',
                                            font: 'Calibri (Cuerpo)',
                                            size: 16,
                                            bold: true
                                        })
                                    ]
                                }),

                            ],
                            width: {
                                size: 33,
                                type: WidthType.PERCENTAGE
                            },
                            shading: {
                                fill: '#C0C0C0'
                            }
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: 'Director general',
                                            font: 'Calibri (Cuerpo)',
                                            size: 16,
                                            bold: true
                                        })
                                    ]
                                }),
                            ],
                            width: {
                                size: 33,
                                type: WidthType.PERCENTAGE
                            },
                            shading: {
                                fill: '#C0C0C0'
                            }
                        })
                    ]
                })
            ],
            margins: {
                left: 70,
                right: 70
            },
            columnWidths: [3213, 3213, 3212],
            alignment: AlignmentType.CENTER
        })
        return table
    }

    public createTableUDP(data, datePipe, tipo_inspeccion): Table {
        const table = new Table({
            rows: [
                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: 'Código de operación:',
                                            font: 'Arial',
                                            size: 22,
                                            bold: true
                                        })
                                    ],
                                    spacing: { before: 100, after: 100 }
                                })
                            ],
                            columnSpan: 1
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: data.customer.Id_Comercial,
                                            font: 'Arial',
                                            size: 22
                                        })
                                    ],
                                    spacing: { before: 100, after: 100 }
                                })
                            ],
                            columnSpan: 1
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: 'Fecha:',
                                            font: 'Arial',
                                            size: 22,
                                            bold: true
                                        })
                                    ],
                                    spacing: { before: 100, after: 100 }
                                })
                            ],
                            columnSpan: 1
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: `${datePipe.transform(data.inspectionDate, 'd-MMMM-y')}`,
                                            font: 'Arial',
                                            size: 22
                                        })
                                    ],
                                    spacing: { before: 100, after: 100 }
                                })
                            ],
                            columnSpan: 1
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: `Tipo de inspección:`,
                                            font: 'Arial',
                                            size: 22,
                                            bold: true
                                        })
                                    ],
                                    spacing: { before: 100, after: 100 }
                                })
                            ],
                            columnSpan: 1
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: `${tipo_inspeccion === 'Renovación' ? '■' : '❏'} Renovación`,
                                            font: 'Calibri (Body)',
                                            size: 20
                                        })
                                    ]
                                }),
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: `${tipo_inspeccion === 'Primer Inspección' ? '■' : '❏'} Primer Inspección`,
                                            font: 'Calibri (Body)',
                                            size: 20
                                        })
                                    ]
                                }),
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: `${tipo_inspeccion === 'Adicional' ? '■' : '❏'} Adicional`,
                                            font: 'Calibri (Body)',
                                            size: 20
                                        })
                                    ]
                                }),
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: `${tipo_inspeccion === 'Anunciada' ? '■' : '❏'} Anunciada`,
                                            font: 'Calibri (Body)',
                                            size: 20
                                        })
                                    ]
                                }),
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: `${tipo_inspeccion === 'No Anunciada' ? '■' : '❏'} No Anunciada`,
                                            font: 'Calibri (Body)',
                                            size: 20
                                        })
                                    ]
                                })
                            ],
                            columnSpan: 2
                        })
                    ]
                }),
                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: 'Inspector:',
                                            font: 'Arial',
                                            size: 22,
                                            bold: true
                                        })
                                    ],
                                    spacing: { before: 100, after: 100 }
                                })
                            ],
                            columnSpan: 1
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: data.inspector,
                                            font: 'Arial',
                                            size: 22
                                        })
                                    ],
                                    spacing: { before: 100, after: 100 }
                                })
                            ],
                            columnSpan: 6
                        })
                    ]
                }),
                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: 'Operador:',
                                            font: 'Arial',
                                            size: 22,
                                            bold: true
                                        })
                                    ],
                                    spacing: { before: 100, after: 100 }
                                })
                            ],
                            columnSpan: 1
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: data.customer.Company_Name,
                                            font: 'Arial',
                                            size: 22
                                        })
                                    ],
                                    spacing: { before: 100, after: 100 }
                                })
                            ],
                            columnSpan: 6
                        })
                    ]
                }),
            ],
            margins: {
                left: 70,
                right: 70
            },
            width: {
                size: 100,
                type: WidthType.PERCENTAGE,
            },
            columnWidths: [2192, 1802, 1792, 1972, 1892, 1802, 1792]
        })

        const unidades = this.getValueList(data.submission, 'unidades_produccion')
        unidades.forEach( (unidad, index) => {
            table.addChildElement(
                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: `${index === 0 ? 'Unidades de producción evaluadas:' : ''}`,
                                            font: 'Arial',
                                            size: 22,
                                            bold: true
                                        })
                                    ],
                                    spacing: { before: 100, after: 100 }
                                })
                            ],
                            columnSpan: 1,
                            borders: {
                              top: {
                                style: BorderStyle.NONE,
                                color: "white",
                                size: 0
                              },
                              bottom: {
                                style: BorderStyle.NONE,
                                color: "white",
                                size: 0
                              }
                            }
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: `Nombre:`,
                                            font: 'Arial',
                                            size: 22,
                                            bold: true
                                        })
                                    ],
                                    spacing: { before: 100 }
                                }),
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: unidad.unidades_produccion_nombre,
                                            font: 'Arial',
                                            size: 22
                                        })
                                    ],
                                    spacing: { after: 100 }
                                })
                            ],
                            columnSpan: 3
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: `Código:`,
                                            font: 'Arial',
                                            size: 22,
                                            bold: true
                                        })
                                    ],
                                    spacing: { before: 100 }
                                }),
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: unidad.unidades_produccion_producto,
                                            font: 'Arial',
                                            size: 22
                                        })
                                    ],
                                    spacing: { after: 100 }
                                })
                            ],
                            columnSpan: 3
                        })
                    ]
                })
            )
            table.addChildElement(
                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: ``,
                                            font: 'Arial',
                                            size: 22,
                                            bold: true
                                        })
                                    ],
                                    spacing: { before: 100 }
                                })
                            ],
                            columnSpan: 1,
                            borders: {
                              top: {
                                style: BorderStyle.NONE,
                                color: "white",
                                size: 0
                              },
                              bottom: {
                                style: BorderStyle.NONE,
                                color: "white",
                                size: 0
                              }
                            }
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: `Superficie total:`,
                                            font: 'Arial',
                                            size: 22,
                                            bold: true
                                        })
                                    ],
                                    spacing: { before: 100 }
                                }),
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: unidad.unidades_produccion_superficie_total,
                                            font: 'Arial',
                                            size: 22
                                        })
                                    ],
                                    spacing: { after: 100 }
                                })
                            ],
                            columnSpan: 3
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: `Superficie orgánica:`,
                                            font: 'Arial',
                                            size: 22,
                                            bold: true
                                        })
                                    ],
                                    spacing: { before: 100 }
                                }),
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: unidad.unidades_produccion_superficie_organica,
                                            font: 'Arial',
                                            size: 22
                                        })
                                    ],
                                    spacing: { after: 100 }
                                })
                            ],
                            columnSpan: 3
                        })
                    ]
                }),
            )
        })

        const start = new Date(`2020-01-01 ${this.getValue(data.submission, 'duracion_inspeccion_hora_inicio')}`)
        const end = new Date(`2020-01-01 ${this.getValue(data.submission, 'duracion_inspeccion_hora_fin')}`)

        const duration = end.getHours() - start.getHours()

        table.addChildElement(
            new TableRow({
                children: [
                    new TableCell({
                        children: [
                            new Paragraph({
                                alignment: AlignmentType.LEFT,
                                children: [
                                    new TextRun({
                                        text: `Duración de la inspección:`,
                                        font: 'Arial',
                                        size: 22,
                                        bold: true
                                    })
                                ],
                                spacing: { before: 100 }
                            })
                        ],
                        columnSpan: 1
                    }),
                    new TableCell({
                        children: [
                            new Paragraph({
                                alignment: AlignmentType.LEFT,
                                children: [
                                    new TextRun({
                                        text: `Hora de inicio`,
                                        font: 'Arial',
                                        size: 22,
                                        bold: true
                                    })
                                ],
                                spacing: { before: 100 }
                            })
                        ],
                        columnSpan: 1
                    }),
                    new TableCell({
                        children: [
                            new Paragraph({
                                alignment: AlignmentType.LEFT,
                                children: [
                                    new TextRun({
                                        text: this.getValue(data.submission, 'duracion_inspeccion_hora_inicio'),
                                        font: 'Arial',
                                        size: 22
                                    })
                                ],
                                spacing: { before: 100 }
                            })
                        ],
                        columnSpan: 1
                    }),
                    new TableCell({
                        children: [
                            new Paragraph({
                                alignment: AlignmentType.LEFT,
                                children: [
                                    new TextRun({
                                        text: `Hora de Terminación`,
                                        font: 'Arial',
                                        size: 22,
                                        bold: true
                                    })
                                ],
                                spacing: { before: 100 }
                            })
                        ],
                        columnSpan: 1
                    }),
                    new TableCell({
                        children: [
                            new Paragraph({
                                alignment: AlignmentType.LEFT,
                                children: [
                                    new TextRun({
                                        text: this.getValue(data.submission, 'duracion_inspeccion_hora_fin'),
                                        font: 'Arial',
                                        size: 22
                                    })
                                ],
                                spacing: { before: 100 }
                            })
                        ],
                        columnSpan: 1
                    }),
                    new TableCell({
                        children: [
                            new Paragraph({
                                alignment: AlignmentType.LEFT,
                                children: [
                                    new TextRun({
                                        text: `Duración:`,
                                        font: 'Arial',
                                        size: 22,
                                        bold: true
                                    })
                                ],
                                spacing: { before: 100 }
                            })
                        ],
                        columnSpan: 1
                    }),
                    new TableCell({
                        children: [
                            new Paragraph({
                                alignment: AlignmentType.LEFT,
                                children: [
                                    new TextRun({
                                        text: `${duration} hrs.`,
                                        font: 'Arial',
                                        size: 22
                                    })
                                ],
                                spacing: { before: 100 }
                            })
                        ],
                        columnSpan: 1
                    })
                ]
            }),
        )

        const persons = this.getValueList(data.submission, 'personas_entrevistadas')
        persons.forEach( (person, index) => {
            table.addChildElement(
                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: `${index === 0 ? 'Personas entrevistadas:' : ''}`,
                                            font: 'Arial',
                                            size: 22,
                                            bold: true
                                        })
                                    ],
                                    spacing: { before: 100 }
                                })
                            ],
                            columnSpan: 1
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: `Nombre: `,
                                            font: 'Arial',
                                            size: 22,
                                            bold: true
                                        })
                                    ],
                                    spacing: { before: 100 }
                                }),
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: person.personas_entrevistadas_nombre,
                                            font: 'Arial',
                                            size: 22
                                        })
                                    ],
                                    spacing: { after: 100 }
                                })
                            ],
                            columnSpan: 3
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: `Cargo: `,
                                            font: 'Arial',
                                            size: 22,
                                            bold: true
                                        })
                                    ],
                                    spacing: { before: 100 }
                                }),
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: person.personas_entrevistadas_cargo,
                                            font: 'Arial',
                                            size: 22
                                        })
                                    ],
                                    spacing: { after: 100 }
                                })
                            ],
                            columnSpan: 3
                        })
                    ]
                })
            )
        })

        return table
    }

    public createRegistros(data) {
        const table = new Table({
            rows: [
                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: 'REGISTROS',
                                            font: 'Calibri (Body)',
                                            size: 28,
                                            bold: true
                                        })
                                    ]
                                })
                            ],
                            shading: {
                                fill: '#C0C0C0',
                                val: ShadingType.PERCENT_80
                            },
                            columnSpan: 10
                        })
                    ]
                }),

                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: '1',
                                            font: 'Arial',
                                            size: 20
                                        })
                                    ],
                                    spacing: {
                                        after: 100,
                                        before: 100
                                    }
                                })
                            ],
                            columnSpan: 1
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: '¿El Plan de Manejo Orgánico refleja la realidad de la unidad de producción?',
                                            font: 'Arial',
                                            size: 20
                                        })
                                    ],
                                    spacing: {
                                        after: 100,
                                        before: 100
                                    }
                                })
                            ],
                            columnSpan: 7
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: `${this.getValue(data.submission, "registros_1_conformidad") === 'C' ? '■' : '❏'} C ${this.getValue(data.submission, "registros_1_conformidad") === 'NC' ? '■' : '❏'} NC ${this.getValue(data.submission, "registros_1_conformidad") === 'NA' ? '■' : '❏'} NA`,
                                            font: 'Arial',
                                            size: 20
                                        })
                                    ],
                                    spacing: {
                                        after: 100,
                                        before: 100
                                    }
                                })
                            ],
                            columnSpan: 2
                        })
                    ]
                }),
                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text:`Anexos: ${this.getFileList(this.getValue(data.submission, "registros_1_adjuntos_url"))}`,
                                            font: 'Arial',
                                            size: 20
                                        })
                                    ],
                                    spacing: {
                                        after: 100,
                                        before: 100
                                    }
                                })
                            ],
                            columnSpan: 10
                        }),
                    ]
                }),
                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: 'Explique: ' + this.getValue(data.submission, "registros_1_pregunta"),
                                            font: 'Arial',
                                            size: 20
                                        })
                                    ],
                                    spacing: {
                                        after: 100,
                                        before: 100
                                    }
                                })
                            ],
                            columnSpan: 10
                        }),
                    ]
                }),

                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: '2',
                                            font: 'Arial',
                                            size: 20
                                        })
                                    ],
                                    spacing: {
                                        after: 100,
                                        before: 100
                                    }
                                })
                            ],
                            columnSpan: 1
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: '¿El operador cuenta con registros (bitácoras) que reflejan las actividades desarrolladas regularmente en la unidad de producción?',
                                            font: 'Arial',
                                            size: 20
                                        })
                                    ],
                                    spacing: {
                                        after: 100,
                                        before: 100
                                    }
                                })
                            ],
                            columnSpan: 7
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: `${this.getValue(data.submission, "registros_2_conformidad") === 'C' ? '■' : '❏'} C ${this.getValue(data.submission, "registros_2_conformidad") === 'NC' ? '■' : '❏'} NC ${this.getValue(data.submission, "registros_2_conformidad") === 'NA' ? '■' : '❏'} NA`,
                                            font: 'Arial',
                                            size: 20
                                        })
                                    ],
                                    spacing: {
                                        after: 100,
                                        before: 100
                                    }
                                })
                            ],
                            columnSpan: 2
                        })
                    ]
                }),
                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text:`Anexos: ${this.getFileList(this.getValue(data.submission, "registros_2_adjuntos_url"))}`,
                                            font: 'Arial',
                                            size: 20
                                        })
                                    ],
                                    spacing: {
                                        after: 100,
                                        before: 100
                                    }
                                })
                            ],
                            columnSpan: 10
                        }),
                    ]
                }),
                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: 'Explique: ' + this.getValue(data.submission, "registros_2_pregunta"),
                                            font: 'Arial',
                                            size: 20
                                        })
                                    ],
                                    spacing: {
                                        after: 100,
                                        before: 100
                                    }
                                })
                            ],
                            columnSpan: 10
                        }),
                    ]
                }),

                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: '3',
                                            font: 'Arial',
                                            size: 20
                                        })
                                    ],
                                    spacing: {
                                        after: 100,
                                        before: 100
                                    }
                                })
                            ],
                            columnSpan: 1
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: '¿Los registros (bitácoras) revisados, reflejan el uso de prácticas y sustancias permitidas?',
                                            font: 'Arial',
                                            size: 20
                                        })
                                    ],
                                    spacing: {
                                        after: 100,
                                        before: 100
                                    }
                                })
                            ],
                            columnSpan: 7
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: `${this.getValue(data.submission, "registros_3_conformidad") === 'C' ? '■' : '❏'} C ${this.getValue(data.submission, "registros_3_conformidad") === 'NC' ? '■' : '❏'} NC ${this.getValue(data.submission, "registros_3_conformidad") === 'NA' ? '■' : '❏'} NA`,
                                            font: 'Arial',
                                            size: 20
                                        })
                                    ],
                                    spacing: {
                                        after: 100,
                                        before: 100
                                    }
                                })
                            ],
                            columnSpan: 2
                        })
                    ]
                }),
                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text:`Anexos: ${this.getFileList(this.getValue(data.submission, "registros_3_adjuntos_url"))}`,
                                            font: 'Arial',
                                            size: 20
                                        })
                                    ],
                                    spacing: {
                                        after: 100,
                                        before: 100
                                    }
                                })
                            ],
                            columnSpan: 10
                        }),
                    ]
                }),
                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: 'Explique: ' + this.getValue(data.submission, "registros_3_pregunta"),
                                            font: 'Arial',
                                            size: 20
                                        })
                                    ],
                                    spacing: {
                                        after: 100,
                                        before: 100
                                    }
                                })
                            ],
                            columnSpan: 10
                        }),
                    ]
                }),

                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: '4',
                                            font: 'Arial',
                                            size: 20
                                        })
                                    ],
                                    spacing: {
                                        after: 100,
                                        before: 100
                                    }
                                })
                            ],
                            columnSpan: 1
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: '¿Las etiquetas, uso de logos y referencias a la calidad orgánica del producto son conformes?',
                                            font: 'Arial',
                                            size: 20
                                        })
                                    ],
                                    spacing: {
                                        after: 100,
                                        before: 100
                                    }
                                })
                            ],
                            columnSpan: 7
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: `${this.getValue(data.submission, "registros_4_conformidad") === 'C' ? '■' : '❏'} C ${this.getValue(data.submission, "registros_4_conformidad") === 'NC' ? '■' : '❏'} NC ${this.getValue(data.submission, "registros_4_conformidad") === 'NA' ? '■' : '❏'} NA`,
                                            font: 'Arial',
                                            size: 20
                                        })
                                    ],
                                    spacing: {
                                        after: 100,
                                        before: 100
                                    }
                                })
                            ],
                            columnSpan: 2
                        })
                    ]
                }),
                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text:`Anexos: ${this.getFileList(this.getValue(data.submission, "registros_4_adjuntos_url"))}`,
                                            font: 'Arial',
                                            size: 20
                                        })
                                    ],
                                    spacing: {
                                        after: 100,
                                        before: 100
                                    }
                                })
                            ],
                            columnSpan: 10
                        }),
                    ]
                }),
                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: 'Explique: ' + this.getValue(data.submission, "registros_4_pregunta"),
                                            font: 'Arial',
                                            size: 20
                                        })
                                    ],
                                    spacing: {
                                        after: 100,
                                        before: 100
                                    }
                                })
                            ],
                            columnSpan: 10
                        }),
                    ]
                }),

                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: '5',
                                            font: 'Arial',
                                            size: 20
                                        })
                                    ],
                                    spacing: {
                                        after: 100,
                                        before: 100
                                    }
                                })
                            ],
                            columnSpan: 1
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: '¿El operador tiene un registro completo de quejas y atención a las quejas?',
                                            font: 'Arial',
                                            size: 20
                                        })
                                    ],
                                    spacing: {
                                        after: 100,
                                        before: 100
                                    }
                                })
                            ],
                            columnSpan: 7
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: `${this.getValue(data.submission, "registros_5_conformidad") === 'C' ? '■' : '❏'} C ${this.getValue(data.submission, "registros_5_conformidad") === 'NC' ? '■' : '❏'} NC ${this.getValue(data.submission, "registros_5_conformidad") === 'NA' ? '■' : '❏'} NA`,
                                            font: 'Arial',
                                            size: 20
                                        })
                                    ],
                                    spacing: {
                                        after: 100,
                                        before: 100
                                    }
                                })
                            ],
                            columnSpan: 2
                        })
                    ]
                }),
                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text:`Anexos: ${this.getFileList(this.getValue(data.submission, "registros_5_adjuntos_url"))}`,
                                            font: 'Arial',
                                            size: 20
                                        })
                                    ],
                                    spacing: {
                                        after: 100,
                                        before: 100
                                    }
                                })
                            ],
                            columnSpan: 10
                        }),
                    ]
                }),
                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: 'Explique: ' + this.getValue(data.submission, "registros_5_pregunta"),
                                            font: 'Arial',
                                            size: 20
                                        })
                                    ],
                                    spacing: {
                                        after: 100,
                                        before: 100
                                    }
                                })
                            ],
                            columnSpan: 10
                        }),
                    ]
                }),

                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: '6',
                                            font: 'Arial',
                                            size: 20
                                        })
                                    ],
                                    spacing: {
                                        after: 100,
                                        before: 100
                                    }
                                })
                            ],
                            columnSpan: 1
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: '¿Los registros son conservados por al menos 5 años?',
                                            font: 'Arial',
                                            size: 20
                                        })
                                    ],
                                    spacing: {
                                        after: 100,
                                        before: 100
                                    }
                                })
                            ],
                            columnSpan: 7
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: `${this.getValue(data.submission, "registros_6_conformidad") === 'C' ? '■' : '❏'} C ${this.getValue(data.submission, "registros_6_conformidad") === 'NC' ? '■' : '❏'} NC ${this.getValue(data.submission, "registros_6_conformidad") === 'NA' ? '■' : '❏'} NA`,
                                            font: 'Arial',
                                            size: 20
                                        })
                                    ],
                                    spacing: {
                                        after: 100,
                                        before: 100
                                    }
                                })
                            ],
                            columnSpan: 2
                        })
                    ]
                }),
                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text:`Anexos: ${this.getFileList(this.getValue(data.submission, "registros_6_adjuntos_url"))}`,
                                            font: 'Arial',
                                            size: 20
                                        })
                                    ],
                                    spacing: {
                                        after: 100,
                                        before: 100
                                    }
                                })
                            ],
                            columnSpan: 10
                        }),
                    ]
                }),
                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: 'Explique: ' + this.getValue(data.submission, "registros_6_pregunta"),
                                            font: 'Arial',
                                            size: 20
                                        })
                                    ],
                                    spacing: {
                                        after: 100,
                                        before: 100
                                    }
                                })
                            ],
                            columnSpan: 10
                        }),
                    ]
                }),

                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: '7',
                                            font: 'Arial',
                                            size: 20
                                        })
                                    ],
                                    spacing: {
                                        after: 100,
                                        before: 100
                                    }
                                })
                            ],
                            columnSpan: 1
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: '¿Se dio seguimiento a las No Conformidades detectadas en la evaluación anterior?',
                                            font: 'Arial',
                                            size: 20
                                        })
                                    ],
                                    spacing: {
                                        after: 100,
                                        before: 100
                                    }
                                })
                            ],
                            columnSpan: 7
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: `${this.getValue(data.submission, "registros_7_conformidad") === 'C' ? '■' : '❏'} C ${this.getValue(data.submission, "registros_7_conformidad") === 'NC' ? '■' : '❏'} NC ${this.getValue(data.submission, "registros_7_conformidad") === 'NA' ? '■' : '❏'} NA`,
                                            font: 'Arial',
                                            size: 20
                                        })
                                    ],
                                    spacing: {
                                        after: 100,
                                        before: 100
                                    }
                                })
                            ],
                            columnSpan: 2
                        })
                    ]
                }),
                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text:`Anexos: ${this.getFileList(this.getValue(data.submission, "registros_7_adjuntos_url"))}`,
                                            font: 'Arial',
                                            size: 20
                                        })
                                    ],
                                    spacing: {
                                        after: 100,
                                        before: 100
                                    }
                                })
                            ],
                            columnSpan: 10
                        }),
                    ]
                }),
                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: 'Explique: ' + this.getValue(data.submission, "registros_7_pregunta"),
                                            font: 'Arial',
                                            size: 20
                                        })
                                    ],
                                    spacing: {
                                        after: 100,
                                        before: 100
                                    }
                                })
                            ],
                            columnSpan: 10
                        }),
                    ]
                }),
            ],
            margins: {
                left: 70,
                right: 70
            },
            width: {
                size: 100,
                type: WidthType.PERCENTAGE
            },
            columnWidths: [500,1359,1359,1359,1359,1359,1359,1359,1200,1200]
        })

        let tableRowConformidades = new TableRow({
            children: []
        })

        let tableCellConformidades = new TableCell({
            children: []
        })

        let paragraphConformidades = new Paragraph({
            alignment: AlignmentType.LEFT,
            children: [
                new TextRun({
                    text: '',
                    font: 'Arial',
                    size: 20
                })
            ]
        })

        let table_conformidades = new Table({
            rows: [
                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: 'No Conformidad',
                                            font: 'Arial',
                                            size: 20,
                                            bold: true
                                        })
                                    ]
                                })
                            ],
                            shading: {
                                fill: '#C0C0C0',
                                val: ShadingType.PERCENT_80
                            },
                            columnSpan: 4
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: 'Acción correctiva propuesta',
                                            font: 'Arial',
                                            size: 20,
                                            bold: true
                                        })
                                    ]
                                })
                            ],
                            shading: {
                                fill: '#C0C0C0',
                                val: ShadingType.PERCENT_80
                            },
                            columnSpan: 4
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: 'Situación encontrada',
                                            font: 'Arial',
                                            size: 20,
                                            bold: true
                                        })
                                    ]
                                })
                            ],
                            shading: {
                                fill: '#C0C0C0',
                                val: ShadingType.PERCENT_80
                            },
                            columnSpan: 4
                        }),
                    ]
                })
            ],
            margins: {
                left: 70,
                right: 70
            },
            width: {
                size: 100,
                type: WidthType.PERCENTAGE
            },
            columnWidths: [500,1359,1359,1359,1359,1359,1359,1359,1200,1200]
        })

        let listaConformidades = this.getValueList(data.submission, "registros_no_conformidades")
        listaConformidades.forEach(conformidad => {
            table_conformidades.addChildElement(
                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: conformidad.registros_no_conformidades_nombre,
                                            font: 'Arial',
                                            size: 20
                                        })
                                    ],
                                    spacing: {
                                        after: 100,
                                        before: 100
                                    }
                                })
                            ],
                            columnSpan: 4
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: conformidad.registros_no_conformidades_acciones_correctiva,
                                            font: 'Arial',
                                            size: 20
                                        })
                                    ],
                                    spacing: {
                                        after: 100,
                                        before: 100
                                    }
                                })
                            ],
                            columnSpan: 4
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: conformidad.registros_no_conformidades_situacion_encontrada,
                                            font: 'Arial',
                                            size: 20
                                        })
                                    ],
                                    spacing: {
                                        after: 100,
                                        before: 100
                                    }
                                })
                            ],
                            columnSpan: 4
                        }),
                    ]
                })
            )
        });

        tableCellConformidades.addChildElement(paragraphConformidades)
        tableCellConformidades.addChildElement(table_conformidades)
        tableCellConformidades.addChildElement(paragraphConformidades)
        tableRowConformidades.addChildElement(tableCellConformidades)
        table.addChildElement(tableRowConformidades)

        return table
    }

    public createManejo(data) {
        const table = new Table({
            rows: [
                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: 'MANEJO',
                                            font: 'Calibri (Body)',
                                            size: 28,
                                            bold: true
                                        })
                                    ]
                                })
                            ],
                            shading: {
                                fill: '#C0C0C0',
                                val: ShadingType.PERCENT_80
                            },
                            columnSpan: 10
                        })
                    ]
                }),

                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: '1',
                                            font: 'Arial',
                                            size: 20
                                        })
                                    ],
                                    spacing: {
                                        after: 100,
                                        before: 100
                                    }
                                })
                            ],
                            columnSpan: 1
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: '¿Se encontró evidencia de que los sustratos cumplen con los requisitos de las normas?',
                                            font: 'Arial',
                                            size: 20
                                        })
                                    ],
                                    spacing: {
                                        after: 100,
                                        before: 100
                                    }
                                })
                            ],
                            columnSpan: 7
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: `${this.getValue(data.submission, "manejo_1_conformidad") === 'C' ? '■' : '❏'} C ${this.getValue(data.submission, "manejo_1_conformidad") === 'NC' ? '■' : '❏'} NC ${this.getValue(data.submission, "manejo_1_conformidad") === 'NA' ? '■' : '❏'} NA`,
                                            font: 'Arial',
                                            size: 20
                                        })
                                    ],
                                    spacing: {
                                        after: 100,
                                        before: 100
                                    }
                                })
                            ],
                            columnSpan: 2
                        })
                    ]
                }),
                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text:`Anexos: ${this.getFileList(this.getValue(data.submission, "manejo_1_adjuntos_url"))}`,
                                            font: 'Arial',
                                            size: 20
                                        })
                                    ],
                                    spacing: {
                                        after: 100,
                                        before: 100
                                    }
                                })
                            ],
                            columnSpan: 10
                        }),
                    ]
                }),
                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: 'Explique: ' + this.getValue(data.submission, "manejo_1_pregunta"),
                                            font: 'Arial',
                                            size: 20
                                        })
                                    ],
                                    spacing: {
                                        after: 100,
                                        before: 100
                                    }
                                })
                            ],
                            columnSpan: 10
                        }),
                    ]
                }),

                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: '2',
                                            font: 'Arial',
                                            size: 20
                                        })
                                    ],
                                    spacing: {
                                        after: 100,
                                        before: 100
                                    }
                                })
                            ],
                            columnSpan: 1
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: '¿Se tienen en uso prácticas para controlar los riesgos de contaminación por áreas colindantes?',
                                            font: 'Arial',
                                            size: 20
                                        })
                                    ],
                                    spacing: {
                                        after: 100,
                                        before: 100
                                    }
                                })
                            ],
                            columnSpan: 7
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: `${this.getValue(data.submission, "manejo_2_conformidad") === 'C' ? '■' : '❏'} C ${this.getValue(data.submission, "manejo_2_conformidad") === 'NC' ? '■' : '❏'} NC ${this.getValue(data.submission, "manejo_2_conformidad") === 'NA' ? '■' : '❏'} NA`,
                                            font: 'Arial',
                                            size: 20
                                        })
                                    ],
                                    spacing: {
                                        after: 100,
                                        before: 100
                                    }
                                })
                            ],
                            columnSpan: 2
                        })
                    ]
                }),
                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text:`Anexos: ${this.getFileList(this.getValue(data.submission, "manejo_2_adjuntos_url"))}`,
                                            font: 'Arial',
                                            size: 20
                                        })
                                    ],
                                    spacing: {
                                        after: 100,
                                        before: 100
                                    }
                                })
                            ],
                            columnSpan: 10
                        }),
                    ]
                }),
                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: 'Explique: ' + this.getValue(data.submission, "manejo_2_pregunta"),
                                            font: 'Arial',
                                            size: 20
                                        })
                                    ],
                                    spacing: {
                                        after: 100,
                                        before: 100
                                    }
                                })
                            ],
                            columnSpan: 10
                        }),
                    ]
                }),

                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: '3',
                                            font: 'Arial',
                                            size: 20
                                        })
                                    ],
                                    spacing: {
                                        after: 100,
                                        before: 100
                                    }
                                })
                            ],
                            columnSpan: 1
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: '¿Se tiene una clara separación de productos convencionales de los orgánicos (producción paralela)?',
                                            font: 'Arial',
                                            size: 20
                                        })
                                    ],
                                    spacing: {
                                        after: 100,
                                        before: 100
                                    }
                                })
                            ],
                            columnSpan: 7
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: `${this.getValue(data.submission, "manejo_3_conformidad") === 'C' ? '■' : '❏'} C ${this.getValue(data.submission, "manejo_3_conformidad") === 'NC' ? '■' : '❏'} NC ${this.getValue(data.submission, "manejo_3_conformidad") === 'NA' ? '■' : '❏'} NA`,
                                            font: 'Arial',
                                            size: 20
                                        })
                                    ],
                                    spacing: {
                                        after: 100,
                                        before: 100
                                    }
                                })
                            ],
                            columnSpan: 2
                        })
                    ]
                }),
                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text:`Anexos: ${this.getFileList(this.getValue(data.submission, "manejo_3_adjuntos_url"))}`,
                                            font: 'Arial',
                                            size: 20
                                        })
                                    ],
                                    spacing: {
                                        after: 100,
                                        before: 100
                                    }
                                })
                            ],
                            columnSpan: 10
                        }),
                    ]
                }),
                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: 'Explique: ' + this.getValue(data.submission, "manejo_3_pregunta"),
                                            font: 'Arial',
                                            size: 20
                                        })
                                    ],
                                    spacing: {
                                        after: 100,
                                        before: 100
                                    }
                                })
                            ],
                            columnSpan: 10
                        }),
                    ]
                }),

                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: '4',
                                            font: 'Arial',
                                            size: 20
                                        })
                                    ],
                                    spacing: {
                                        after: 100,
                                        before: 100
                                    }
                                })
                            ],
                            columnSpan: 1
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: '¿El origen del material de propagación es orgánico o cumple con las excepciones?',
                                            font: 'Arial',
                                            size: 20
                                        })
                                    ],
                                    spacing: {
                                        after: 100,
                                        before: 100
                                    }
                                })
                            ],
                            columnSpan: 7
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: `${this.getValue(data.submission, "manejo_4_conformidad") === 'C' ? '■' : '❏'} C ${this.getValue(data.submission, "manejo_4_conformidad") === 'NC' ? '■' : '❏'} NC ${this.getValue(data.submission, "manejo_4_conformidad") === 'NA' ? '■' : '❏'} NA`,
                                            font: 'Arial',
                                            size: 20
                                        })
                                    ],
                                    spacing: {
                                        after: 100,
                                        before: 100
                                    }
                                })
                            ],
                            columnSpan: 2
                        })
                    ]
                }),
                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text:`Anexos: ${this.getFileList(this.getValue(data.submission, "manejo_4_adjuntos_url"))}`,
                                            font: 'Arial',
                                            size: 20
                                        })
                                    ],
                                    spacing: {
                                        after: 100,
                                        before: 100
                                    }
                                })
                            ],
                            columnSpan: 10
                        }),
                    ]
                }),
                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: 'Explique: ' + this.getValue(data.submission, "manejo_4_pregunta"),
                                            font: 'Arial',
                                            size: 20
                                        })
                                    ],
                                    spacing: {
                                        after: 100,
                                        before: 100
                                    }
                                })
                            ],
                            columnSpan: 10
                        }),
                    ]
                }),

                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: '5',
                                            font: 'Arial',
                                            size: 20
                                        })
                                    ],
                                    spacing: {
                                        after: 100,
                                        before: 100
                                    }
                                })
                            ],
                            columnSpan: 1
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: '¿El agua utilizada es potable?',
                                            font: 'Arial',
                                            size: 20
                                        })
                                    ],
                                    spacing: {
                                        after: 100,
                                        before: 100
                                    }
                                })
                            ],
                            columnSpan: 7
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: `${this.getValue(data.submission, "manejo_5_conformidad") === 'C' ? '■' : '❏'} C ${this.getValue(data.submission, "manejo_5_conformidad") === 'NC' ? '■' : '❏'} NC ${this.getValue(data.submission, "manejo_5_conformidad") === 'NA' ? '■' : '❏'} NA`,
                                            font: 'Arial',
                                            size: 20
                                        })
                                    ],
                                    spacing: {
                                        after: 100,
                                        before: 100
                                    }
                                })
                            ],
                            columnSpan: 2
                        })
                    ]
                }),
                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text:`Anexos: ${this.getFileList(this.getValue(data.submission, "manejo_5_adjuntos_url"))}`,
                                            font: 'Arial',
                                            size: 20
                                        })
                                    ],
                                    spacing: {
                                        after: 100,
                                        before: 100
                                    }
                                })
                            ],
                            columnSpan: 10
                        }),
                    ]
                }),
                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: 'Explique: ' + this.getValue(data.submission, "manejo_5_pregunta"),
                                            font: 'Arial',
                                            size: 20
                                        })
                                    ],
                                    spacing: {
                                        after: 100,
                                        before: 100
                                    }
                                })
                            ],
                            columnSpan: 10
                        }),
                    ]
                }),

              new TableRow({
                children: [
                  new TableCell({
                    children: [
                      new Paragraph({
                        alignment: AlignmentType.LEFT,
                        children: [
                          new TextRun({
                            text: '6',
                            font: 'Arial',
                            size: 20
                          })
                        ],
                        spacing: {
                          after: 100,
                          before: 100
                        }
                      })
                    ],
                    columnSpan: 1
                  }),
                  new TableCell({
                    children: [
                      new Paragraph({
                        alignment: AlignmentType.LEFT,
                        children: [
                          new TextRun({
                            text: '¿Se usan prácticas y sustancias permitidas para el control de plagas y enfermedades?, ¿se respetan las restricciones?',
                            font: 'Arial',
                            size: 20
                          })
                        ],
                        spacing: {
                          after: 100,
                          before: 100
                        }
                      })
                    ],
                    columnSpan: 7
                  }),
                  new TableCell({
                    children: [
                      new Paragraph({
                        alignment: AlignmentType.LEFT,
                        children: [
                          new TextRun({
                            text: `${this.getValue(data.submission, "manejo_6_conformidad") === 'C' ? '■' : '❏'} C ${this.getValue(data.submission, "manejo_6_conformidad") === 'NC' ? '■' : '❏'} NC ${this.getValue(data.submission, "manejo_6_conformidad") === 'NA' ? '■' : '❏'} NA`,
                            font: 'Arial',
                            size: 20
                          })
                        ],
                        spacing: {
                          after: 100,
                          before: 100
                        }
                      })
                    ],
                    columnSpan: 2
                  })
                ]
              }),
              new TableRow({
                children: [
                  new TableCell({
                    children: [
                      new Paragraph({
                        alignment: AlignmentType.LEFT,
                        children: [
                          new TextRun({
                            text:`Anexos: ${this.getFileList(this.getValue(data.submission, "manejo_6_adjuntos_url"))}`,
                            font: 'Arial',
                            size: 20
                          })
                        ],
                        spacing: {
                          after: 100,
                          before: 100
                        }
                      })
                    ],
                    columnSpan: 10
                  }),
                ]
              }),
              new TableRow({
                children: [
                  new TableCell({
                    children: [
                      new Paragraph({
                        alignment: AlignmentType.LEFT,
                        children: [
                          new TextRun({
                            text: 'Explique: ' + this.getValue(data.submission, "manejo_6_pregunta"),
                            font: 'Arial',
                            size: 20
                          })
                        ],
                        spacing: {
                          after: 100,
                          before: 100
                        }
                      })
                    ],
                    columnSpan: 10
                  }),
                ]
              }),

                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: '7',
                                            font: 'Arial',
                                            size: 20
                                        })
                                    ],
                                    spacing: {
                                        after: 100,
                                        before: 100
                                    }
                                })
                            ],
                            columnSpan: 1
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: '¿Todas las sustancias se usan para los fines y con las restricciones señaladas en las listas?',
                                            font: 'Arial',
                                            size: 20
                                        })
                                    ],
                                    spacing: {
                                        after: 100,
                                        before: 100
                                    }
                                })
                            ],
                            columnSpan: 7
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: `${this.getValue(data.submission, "manejo_7_conformidad") === 'C' ? '■' : '❏'} C ${this.getValue(data.submission, "manejo_7_conformidad") === 'NC' ? '■' : '❏'} NC ${this.getValue(data.submission, "manejo_7_conformidad") === 'NA' ? '■' : '❏'} NA`,
                                            font: 'Arial',
                                            size: 20
                                        })
                                    ],
                                    spacing: {
                                        after: 100,
                                        before: 100
                                    }
                                })
                            ],
                            columnSpan: 2
                        })
                    ]
                }),
                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text:`Anexos: ${this.getFileList(this.getValue(data.submission, "manejo_7_adjuntos_url"))}`,
                                            font: 'Arial',
                                            size: 20
                                        })
                                    ],
                                    spacing: {
                                        after: 100,
                                        before: 100
                                    }
                                })
                            ],
                            columnSpan: 10
                        }),
                    ]
                }),
                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: 'Explique: ' + this.getValue(data.submission, "manejo_7_pregunta"),
                                            font: 'Arial',
                                            size: 20
                                        })
                                    ],
                                    spacing: {
                                        after: 100,
                                        before: 100
                                    }
                                })
                            ],
                            columnSpan: 10
                        }),
                    ]
                }),

                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: '8',
                                            font: 'Arial',
                                            size: 20
                                        })
                                    ],
                                    spacing: {
                                        after: 100,
                                        before: 100
                                    }
                                })
                            ],
                            columnSpan: 1
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: '¿Se tomaron muestras para análisis de laboratorio?',
                                            font: 'Arial',
                                            size: 20
                                        })
                                    ],
                                    spacing: {
                                        after: 50,
                                        before: 100
                                    }
                                }),
                              new Paragraph({
                                alignment: AlignmentType.LEFT,
                                children: [
                                  new TextRun({
                                    text: 'En caso positivo, favor de indicar el tipo de muestra, el análisis requerido, el lugar y condiciones de la toma de muestra y cualquier otra información que considere relevante.',
                                    font: 'Arial',
                                    size: 20
                                  })
                                ],
                                spacing: {
                                  after: 100,
                                  before: 0
                                }
                              })
                            ],
                            columnSpan: 7
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: `${this.getValue(data.submission, "manejo_8_conformidad") === 'S' ? '■' : '❏'} S ${this.getValue(data.submission, "manejo_8_conformidad") === 'N' ? '■' : '❏'} N ${this.getValue(data.submission, "manejo_8_conformidad") === 'NA' ? '■' : '❏'} NA`,
                                            font: 'Arial',
                                            size: 20
                                        })
                                    ],
                                    spacing: {
                                        after: 100,
                                        before: 100
                                    }
                                })
                            ],
                            columnSpan: 2
                        })
                    ]
                }),
                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text:`Anexos: ${this.getFileList(this.getValue(data.submission, "manejo_8_adjuntos_url"))}`,
                                            font: 'Arial',
                                            size: 20
                                        })
                                    ],
                                    spacing: {
                                        after: 100,
                                        before: 100
                                    }
                                })
                            ],
                            columnSpan: 10
                        }),
                    ]
                }),
                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: 'Explique: ' + this.getValue(data.submission, "manejo_8_pregunta"),
                                            font: 'Arial',
                                            size: 20
                                        })
                                    ],
                                    spacing: {
                                        after: 100,
                                        before: 100
                                    }
                                })
                            ],
                            columnSpan: 10
                        }),
                    ]
                }),

              new TableRow({
                children: [
                  new TableCell({
                    children: [
                      new Paragraph({
                        alignment: AlignmentType.LEFT,
                        children: [
                          new TextRun({
                            text: '9',
                            font: 'Arial',
                            size: 20
                          })
                        ],
                        spacing: {
                          after: 100,
                          before: 100
                        }
                      })
                    ],
                    columnSpan: 1
                  }),
                  new TableCell({
                    children: [
                      new Paragraph({
                        alignment: AlignmentType.LEFT,
                        children: [
                          new TextRun({
                            text: '¿Las prácticas y registros de cosecha, empaque y transport permiten mantener la integridad del producto?',
                            font: 'Arial',
                            size: 20
                          })
                        ],
                        spacing: {
                          after: 50,
                          before: 100
                        }
                      })
                    ],
                    columnSpan: 7
                  }),
                  new TableCell({
                    children: [
                      new Paragraph({
                        alignment: AlignmentType.LEFT,
                        children: [
                          new TextRun({
                            text: `${this.getValue(data.submission, "manejo_9_conformidad") === 'C' ? '■' : '❏'} C ${this.getValue(data.submission, "manejo_9_conformidad") === 'NC' ? '■' : '❏'} NC ${this.getValue(data.submission, "manejo_9_conformidad") === 'NA' ? '■' : '❏'} NA`,
                            font: 'Arial',
                            size: 20
                          })
                        ],
                        spacing: {
                          after: 100,
                          before: 100
                        }
                      })
                    ],
                    columnSpan: 2
                  })
                ]
              }),
              new TableRow({
                children: [
                  new TableCell({
                    children: [
                      new Paragraph({
                        alignment: AlignmentType.LEFT,
                        children: [
                          new TextRun({
                            text:`Anexos: ${this.getFileList(this.getValue(data.submission, "manejo_9_adjuntos_url"))}`,
                            font: 'Arial',
                            size: 20
                          })
                        ],
                        spacing: {
                          after: 100,
                          before: 100
                        }
                      })
                    ],
                    columnSpan: 10
                  }),
                ]
              }),
              new TableRow({
                children: [
                  new TableCell({
                    children: [
                      new Paragraph({
                        alignment: AlignmentType.LEFT,
                        children: [
                          new TextRun({
                            text: 'Explique: ' + this.getValue(data.submission, "manejo_9_pregunta"),
                            font: 'Arial',
                            size: 20
                          })
                        ],
                        spacing: {
                          after: 100,
                          before: 100
                        }
                      })
                    ],
                    columnSpan: 10
                  }),
                ]
              })
            ],
            margins: {
                left: 70,
                right: 70
            },
            width: {
                size: 100,
                type: WidthType.PERCENTAGE
            },
            columnWidths: [500,1359,1359,1359,1359,1359,1359,1359,1200,1200]
        })

        return table
    }

    public createProductosCertificados(data) {
        const table = new Table({
            rows: [
                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: 'PRODUCTOS CERTIFICADOS',
                                            font: 'Calibri (Body)',
                                            size: 28,
                                            bold: true
                                        })
                                    ]
                                })
                            ],
                            columnSpan: 10
                        })
                    ]
                }),

                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: '1',
                                            font: 'Arial',
                                            size: 20
                                        })
                                    ],
                                    spacing: {
                                        after: 100,
                                        before: 100
                                    }
                                })
                            ],
                            columnSpan: 1
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: '¿Todos los productos comercializados como orgánicos fueron incluidos en la solicitud de certificación?',
                                            font: 'Arial',
                                            size: 20
                                        })
                                    ],
                                    spacing: {
                                        after: 100,
                                        before: 100
                                    }
                                })
                            ],
                            columnSpan: 7
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: `${this.getValue(data.submission, "productos_certificados_1_conformidad") === 'C' ? '■' : '❏'} C ${this.getValue(data.submission, "productos_certificados_1_conformidad") === 'NC' ? '■' : '❏'} NC ${this.getValue(data.submission, "productos_certificados_1_conformidad") === 'NA' ? '■' : '❏'} NA`,
                                            font: 'Arial',
                                            size: 20
                                        })
                                    ],
                                    spacing: {
                                        after: 100,
                                        before: 100
                                    }
                                })
                            ],
                            columnSpan: 2
                        })
                    ]
                }),
                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: 'Explique: ' + this.getValue(data.submission, "productos_certificados_1_explique"),
                                            font: 'Arial',
                                            size: 20
                                        })
                                    ],
                                    spacing: {
                                        after: 100,
                                        before: 100
                                    }
                                })
                            ],
                            columnSpan: 10
                        }),
                    ]
                }),

                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: '',
                                            font: 'Arial',
                                            size: 20
                                        })
                                    ],
                                    spacing: {
                                        after: 100,
                                        before: 100
                                    }
                                })
                            ],
                            columnSpan: 1
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: '¿Fue posible realizar un balance de masas?',
                                            font: 'Arial',
                                            size: 20
                                        })
                                    ],
                                    spacing: {
                                        after: 100,
                                        before: 100
                                    }
                                })
                            ],
                            columnSpan: 7
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: `${this.getValue(data.submission, "productos_certificados_1_tabla_conformidad") === 'C' ? '■' : '❏'} C ${this.getValue(data.submission, "productos_certificados_1_tabla_conformidad") === 'NC' ? '■' : '❏'} NC ${this.getValue(data.submission, "productos_certificados_1_tabla_conformidad") === 'NA' ? '■' : '❏'} NA`,
                                            font: 'Arial',
                                            size: 20
                                        })
                                    ],
                                    spacing: {
                                        after: 100,
                                        before: 100
                                    }
                                })
                            ],
                            columnSpan: 2
                        })
                    ]
                })
            ],
            margins: {
                left: 70,
                right: 70
            },
            width: {
                size: 100,
                type: WidthType.PERCENTAGE
            },
            columnWidths: [500,1359,1359,1359,1359,1359,1359,1359,1200,1200]
        })

        let tableRowBalance = new TableRow({
            children: []
        })

        let tableCellBalance = new TableCell({
            children: []
        })

        let paragraphBalance = new Paragraph({
            alignment: AlignmentType.LEFT,
            children: [
                new TextRun({
                    text: '',
                    font: 'Arial',
                    size: 20
                })
            ]
        })

        let table_balance = new Table({
            rows: [
                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: 'Producto',
                                            font: 'Arial',
                                            size: 20,
                                            bold: true
                                        })
                                    ]
                                })
                            ],
                            shading: {
                                fill: '#C0C0C0',
                                val: ShadingType.PERCENT_80
                            },
                            columnSpan: 2
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: 'Cant. Estimada',
                                            font: 'Arial',
                                            size: 20,
                                            bold: true
                                        })
                                    ]
                                })
                            ],
                            shading: {
                                fill: '#C0C0C0',
                                val: ShadingType.PERCENT_80
                            },
                            columnSpan: 1
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: 'Cant. Cosechada',
                                            font: 'Arial',
                                            size: 20,
                                            bold: true
                                        })
                                    ]
                                })
                            ],
                            shading: {
                                fill: '#C0C0C0',
                                val: ShadingType.PERCENT_80
                            },
                            columnSpan: 1
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: 'Cant. vendida',
                                            font: 'Arial',
                                            size: 20,
                                            bold: true
                                        })
                                    ]
                                })
                            ],
                            shading: {
                                fill: '#C0C0C0',
                                val: ShadingType.PERCENT_80
                            },
                            columnSpan: 1
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: 'Documentos de referencia',
                                            font: 'Arial',
                                            size: 20,
                                            bold: true
                                        })
                                    ]
                                })
                            ],
                            shading: {
                                fill: '#C0C0C0',
                                val: ShadingType.PERCENT_80
                            },
                            columnSpan: 2
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: 'Anexos',
                                            font: 'Arial',
                                            size: 20,
                                            bold: true
                                        })
                                    ]
                                })
                            ],
                            shading: {
                                fill: '#C0C0C0',
                                val: ShadingType.PERCENT_80
                            },
                            columnSpan: 2
                        }),
                    ]
                })
            ],
            margins: {
                left: 70,
                right: 70
            },
            width: {
                size: 100,
                type: WidthType.PERCENTAGE
            },
            columnWidths: [500,1359,1359,1359,1359,1359,1359,1359,1200,1200]
        })

        let listaBalances = this.getValueList(data.submission, "productos_comercializados")
        listaBalances.forEach(balance => {
            var anexos = ''
            for(var i in balance.documentos_urls) {
                const tmp = balance.documentos_urls[i].split("/")
                anexos += tmp[tmp.length-1] + ", "
            }

            anexos = anexos.substr(0, anexos.length-2)

            table_balance.addChildElement(
                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: balance.producto,
                                            font: 'Arial',
                                            size: 20
                                        })
                                    ],
                                    spacing: {
                                        after: 100,
                                        before: 100
                                    }
                                })
                            ],
                            columnSpan: 2
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: balance.cant_estimada,
                                            font: 'Arial',
                                            size: 20
                                        })
                                    ],
                                    spacing: {
                                        after: 100,
                                        before: 100
                                    }
                                })
                            ],
                            columnSpan: 1
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: balance.cant_cosechada,
                                            font: 'Arial',
                                            size: 20
                                        })
                                    ],
                                    spacing: {
                                        after: 100,
                                        before: 100
                                    }
                                })
                            ],
                            columnSpan: 1
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: balance.cant_vendida,
                                            font: 'Arial',
                                            size: 20
                                        })
                                    ],
                                    spacing: {
                                        after: 100,
                                        before: 100
                                    }
                                })
                            ],
                            columnSpan: 1
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: balance.documentos,
                                            font: 'Arial',
                                            size: 20
                                        })
                                    ],
                                    spacing: {
                                        after: 100,
                                        before: 100
                                    }
                                })
                            ],
                            columnSpan: 2
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: `${anexos === '' ? 'Sin anexos' : anexos}`,
                                            font: 'Arial',
                                            size: 20
                                        })
                                    ],
                                    spacing: {
                                        after: 100,
                                        before: 100
                                    }
                                })
                            ],
                            columnSpan: 2
                        }),
                    ]
                }))
        });

        tableCellBalance.addChildElement(paragraphBalance)
        tableCellBalance.addChildElement(table_balance)
        tableCellBalance.addChildElement(paragraphBalance)
        tableRowBalance.addChildElement(tableCellBalance)
        table.addChildElement(tableRowBalance)

        table.addChildElement(new TableRow({
            children: [
                new TableCell({
                    children: [
                        new Paragraph({
                            alignment: AlignmentType.LEFT,
                            children: [
                                new TextRun({
                                    text: 'Comentarios: ' + this.getValue(data.submission, "productos_certificados_1_comentarios"),
                                    font: 'Arial',
                                    size: 20
                                })
                            ],
                            spacing: {
                                after: 100,
                                before: 100
                            }
                        })
                    ],
                    columnSpan: 10
                }),
            ]
        }))

        table.addChildElement(new TableRow({
            children: [
                new TableCell({
                    children: [
                        new Paragraph({
                            alignment: AlignmentType.LEFT,
                            children: [
                                new TextRun({
                                    text: '2',
                                    font: 'Arial',
                                    size: 20
                                })
                            ],
                            spacing: {
                                after: 100,
                                before: 100
                            }
                        })
                    ],
                    columnSpan: 1
                }),
                new TableCell({
                    children: [
                        new Paragraph({
                            alignment: AlignmentType.LEFT,
                            children: [
                                new TextRun({
                                    text: '¿Las cantidades cosechadas corresponden con los rendimientos para el tipo de cultivo, el tipo de manejo y la apariencia de las plantas?',
                                    font: 'Arial',
                                    size: 20
                                })
                            ],
                            spacing: {
                                after: 100,
                                before: 100
                            }
                        })
                    ],
                    columnSpan: 7
                }),
                new TableCell({
                    children: [
                        new Paragraph({
                            alignment: AlignmentType.LEFT,
                            children: [
                                new TextRun({
                                    text: `${this.getValue(data.submission, "productos_certificados_2_conformidad") === 'C' ? '■' : '❏'} C ${this.getValue(data.submission, "productos_certificados_2_conformidad") === 'NC' ? '■' : '❏'} NC ${this.getValue(data.submission, "productos_certificados_2_conformidad") === 'NA' ? '■' : '❏'} NA`,
                                    font: 'Arial',
                                    size: 20
                                })
                            ],
                            spacing: {
                                after: 100,
                                before: 100
                            }
                        })
                    ],
                    columnSpan: 2
                })
            ]
        }))
        table.addChildElement(new TableRow({
            children: [
                new TableCell({
                    children: [
                        new Paragraph({
                            alignment: AlignmentType.LEFT,
                            children: [
                                new TextRun({
                                    text: 'Explique: ' + this.getValue(data.submission, "productos_certificados_2_pregunta"),
                                    font: 'Arial',
                                    size: 20
                                })
                            ],
                            spacing: {
                                after: 100,
                                before: 100
                            }
                        })
                    ],
                    columnSpan: 10
                }),
            ]
        }))

        table.addChildElement(new TableRow({
            children: [
                new TableCell({
                    children: [
                        new Paragraph({
                            alignment: AlignmentType.LEFT,
                            children: [
                                new TextRun({
                                    text: '3',
                                    font: 'Arial',
                                    size: 20
                                })
                            ],
                            spacing: {
                                after: 100,
                                before: 100
                            }
                        })
                    ],
                    columnSpan: 1
                }),
                new TableCell({
                    children: [
                        new Paragraph({
                            alignment: AlignmentType.LEFT,
                            children: [
                                new TextRun({
                                    text: '¿Se encuentra congruencia entre las cantidades cosechadas, empacadas, transportadas y vendidas?',
                                    font: 'Arial',
                                    size: 20
                                })
                            ],
                            spacing: {
                                after: 100,
                                before: 100
                            }
                        })
                    ],
                    columnSpan: 7
                }),
                new TableCell({
                    children: [
                        new Paragraph({
                            alignment: AlignmentType.LEFT,
                            children: [
                                new TextRun({
                                    text: `${this.getValue(data.submission, "productos_certificados_3_conformidad") === 'C' ? '■' : '❏'} C ${this.getValue(data.submission, "productos_certificados_3_conformidad") === 'NC' ? '■' : '❏'} NC ${this.getValue(data.submission, "productos_certificados_3_conformidad") === 'NA' ? '■' : '❏'} NA`,
                                    font: 'Arial',
                                    size: 20
                                })
                            ],
                            spacing: {
                                after: 100,
                                before: 100
                            }
                        })
                    ],
                    columnSpan: 2
                })
            ]
        }))
        table.addChildElement(new TableRow({
            children: [
                new TableCell({
                    children: [
                        new Paragraph({
                            alignment: AlignmentType.LEFT,
                            children: [
                                new TextRun({
                                    text: 'Explique: ' + this.getValue(data.submission, "productos_certificados_3_pregunta"),
                                    font: 'Arial',
                                    size: 20
                                })
                            ],
                            spacing: {
                                after: 100,
                                before: 100
                            }
                        })
                    ],
                    columnSpan: 10
                }),
            ]
        }))

        table.addChildElement(new TableRow({
            children: [
                new TableCell({
                    children: [
                        new Paragraph({
                            alignment: AlignmentType.LEFT,
                            children: [
                                new TextRun({
                                    text: '4',
                                    font: 'Arial',
                                    size: 20
                                })
                            ],
                            spacing: {
                                after: 100,
                                before: 100
                            }
                        })
                    ],
                    columnSpan: 1
                }),
                new TableCell({
                    children: [
                        new Paragraph({
                            alignment: AlignmentType.LEFT,
                            children: [
                                new TextRun({
                                    text: '¿Fue posible realizar un ejercicio de trazabilidad?',
                                    font: 'Arial',
                                    size: 20
                                })
                            ],
                            spacing: {
                                after: 100,
                                before: 100
                            }
                        })
                    ],
                    columnSpan: 7
                }),
                new TableCell({
                    children: [
                        new Paragraph({
                            alignment: AlignmentType.LEFT,
                            children: [
                                new TextRun({
                                    text: `${this.getValue(data.submission, "productos_certificados_4_conformidad") === 'C' ? '■' : '❏'} C ${this.getValue(data.submission, "productos_certificados_4_conformidad") === 'NC' ? '■' : '❏'} NC ${this.getValue(data.submission, "productos_certificados_4_conformidad") === 'NA' ? '■' : '❏'} NA`,
                                    font: 'Arial',
                                    size: 20
                                })
                            ],
                            spacing: {
                                after: 100,
                                before: 100
                            }
                        })
                    ],
                    columnSpan: 2
                })
            ]
        }))


        let tableRowTrazabilidad = new TableRow({
            children: []
        })

        let tableCellTrazabilidad = new TableCell({
            children: []
        })

        let paragraphTrazabilidad = new Paragraph({
            alignment: AlignmentType.LEFT,
            children: [
                new TextRun({
                    text: '',
                    font: 'Arial',
                    size: 20
                })
            ]
        })

        let table_trazabilidad = new Table({
            rows: [
                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: 'Lote venta',
                                            font: 'Arial',
                                            size: 20,
                                            bold: true
                                        })
                                    ]
                                })
                            ],
                            shading: {
                                fill: '#C0C0C0',
                                val: ShadingType.PERCENT_80
                            },
                            columnSpan: 1
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: 'Cantidad',
                                            font: 'Arial',
                                            size: 20,
                                            bold: true
                                        })
                                    ]
                                })
                            ],
                            shading: {
                                fill: '#C0C0C0',
                                val: ShadingType.PERCENT_80
                            },
                            columnSpan: 1
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: 'Fecha venta',
                                            font: 'Arial',
                                            size: 20,
                                            bold: true
                                        })
                                    ]
                                })
                            ],
                            shading: {
                                fill: '#C0C0C0',
                                val: ShadingType.PERCENT_80
                            },
                            columnSpan: 1
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: 'Fecha producción',
                                            font: 'Arial',
                                            size: 20,
                                            bold: true
                                        })
                                    ]
                                })
                            ],
                            shading: {
                                fill: '#C0C0C0',
                                val: ShadingType.PERCENT_80
                            },
                            columnSpan: 2
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: 'Parcela o lote de origen',
                                            font: 'Arial',
                                            size: 20,
                                            bold: true
                                        })
                                    ]
                                })
                            ],
                            shading: {
                                fill: '#C0C0C0',
                                val: ShadingType.PERCENT_80
                            },
                            columnSpan: 2
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: 'Documentos de referencia',
                                            font: 'Arial',
                                            size: 20,
                                            bold: true
                                        })
                                    ]
                                })
                            ],
                            shading: {
                                fill: '#C0C0C0',
                                val: ShadingType.PERCENT_80
                            },
                            columnSpan: 2
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: 'Anexos',
                                            font: 'Arial',
                                            size: 20,
                                            bold: true
                                        })
                                    ]
                                })
                            ],
                            shading: {
                                fill: '#C0C0C0',
                                val: ShadingType.PERCENT_80
                            },
                            columnSpan: 2
                        }),
                    ]
                })
            ],
            margins: {
                left: 70,
                right: 70
            },
            width: {
                size: 100,
                type: WidthType.PERCENTAGE
            },
            columnWidths: [500,1359,1359,1359,1359,1359,1359,1359,1200,1200]
        })

        let listaTrazabilidad = this.getValueList(data.submission, "trazabilidad")
        listaTrazabilidad.forEach(trazabilidad => {
            var anexos = ''
            for(var i in trazabilidad.anexos) {
                const tmp = trazabilidad.anexos[i].split("/")
                anexos += tmp[tmp.length-1] + ", "
            }

            anexos = anexos.substr(0, anexos.length-2)

            table_trazabilidad.addChildElement(
                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: trazabilidad.lote,
                                            font: 'Arial',
                                            size: 20
                                        })
                                    ],
                                    spacing: {
                                        after: 100,
                                        before: 100
                                    }
                                })
                            ],
                            columnSpan: 2
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: trazabilidad.cantidad,
                                            font: 'Arial',
                                            size: 20
                                        })
                                    ],
                                    spacing: {
                                        after: 100,
                                        before: 100
                                    }
                                })
                            ],
                            columnSpan: 2
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: trazabilidad.fecha_venta,
                                            font: 'Arial',
                                            size: 20
                                        })
                                    ],
                                    spacing: {
                                        after: 100,
                                        before: 100
                                    }
                                })
                            ],
                            columnSpan: 2
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: trazabilidad.fecha_produccion,
                                            font: 'Arial',
                                            size: 20
                                        })
                                    ],
                                    spacing: {
                                        after: 100,
                                        before: 100
                                    }
                                })
                            ],
                            columnSpan: 2
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: trazabilidad.lote_origen,
                                            font: 'Arial',
                                            size: 20
                                        })
                                    ],
                                    spacing: {
                                        after: 100,
                                        before: 100
                                    }
                                })
                            ],
                            columnSpan: 2
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: trazabilidad.documentos,
                                            font: 'Arial',
                                            size: 20
                                        })
                                    ],
                                    spacing: {
                                        after: 100,
                                        before: 100
                                    }
                                })
                            ],
                            columnSpan: 2
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: `${anexos === '' ? 'Sin anexos' : anexos}`,
                                            font: 'Arial',
                                            size: 20
                                        })
                                    ],
                                    spacing: {
                                        after: 100,
                                        before: 100
                                    }
                                })
                            ],
                            columnSpan: 2
                        }),
                    ]
                })
            )
        });

        tableCellTrazabilidad.addChildElement(paragraphTrazabilidad)
        tableCellTrazabilidad.addChildElement(table_trazabilidad)
        tableCellTrazabilidad.addChildElement(paragraphTrazabilidad)
        tableRowTrazabilidad.addChildElement(tableCellTrazabilidad)
        table.addChildElement(tableRowTrazabilidad)


        table.addChildElement(new TableRow({
            children: [
                new TableCell({
                    children: [
                        new Paragraph({
                            alignment: AlignmentType.LEFT,
                            children: [
                                new TextRun({
                                    text: 'Comentarios: ' + this.getValue(data.submission, "productos_certificados_4_comentarios"),
                                    font: 'Arial',
                                    size: 20
                                })
                            ],
                            spacing: {
                                after: 100,
                                before: 100
                            }
                        })
                    ],
                    columnSpan: 10
                }),
            ]
        }))


        let tableRowCiclo = new TableRow({
            children: []
        })

        let tableCellCiclo = new TableCell({
            children: []
        })

        let paragraphCiclo = new Paragraph({
            alignment: AlignmentType.LEFT,
            children: [
                new TextRun({
                    text: '',
                    font: 'Arial',
                    size: 20
                })
            ]
        })

        let table_ciclo = new Table({
            rows: [
                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: 'Producto',
                                            font: 'Arial',
                                            size: 20,
                                            bold: true
                                        })
                                    ]
                                })
                            ],
                            shading: {
                                fill: '#C0C0C0',
                                val: ShadingType.PERCENT_80
                            },
                            columnSpan: 3
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: 'Unidad de producción',
                                            font: 'Arial',
                                            size: 20,
                                            bold: true
                                        })
                                    ]
                                })
                            ],
                            shading: {
                                fill: '#C0C0C0',
                                val: ShadingType.PERCENT_80
                            },
                            columnSpan: 3
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: 'Superficie (ha)',
                                            font: 'Arial',
                                            size: 20,
                                            bold: true
                                        })
                                    ]
                                })
                            ],
                            shading: {
                                fill: '#C0C0C0',
                                val: ShadingType.PERCENT_80
                            },
                            columnSpan: 2
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: 'Estimación (Kg)',
                                            font: 'Arial',
                                            size: 20,
                                            bold: true
                                        })
                                    ]
                                })
                            ],
                            shading: {
                                fill: '#C0C0C0',
                                val: ShadingType.PERCENT_80
                            },
                            columnSpan: 2
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: 'Anexos',
                                            font: 'Arial',
                                            size: 20,
                                            bold: true
                                        })
                                    ]
                                })
                            ],
                            shading: {
                                fill: '#C0C0C0',
                                val: ShadingType.PERCENT_80
                            },
                            columnSpan: 2
                        }),
                    ]
                })
            ],
            margins: {
                left: 70,
                right: 70
            },
            width: {
                size: 100,
                type: WidthType.PERCENTAGE
            },
            columnWidths: [500,1359,1359,1359,1359,1359,1359,1359,1200,1200]
        })

        let listaCiclo = this.getValueList(data.submission, "trazabilidad2")
        listaCiclo.forEach(ciclo => {
            var anexos = ''
            for(var i in ciclo.anexos) {
                const tmp = ciclo.anexos[i].split("/")
                anexos += tmp[tmp.length-1] + ", "
            }

            anexos = anexos.substr(0, anexos.length-2)

            table_ciclo.addChildElement(
                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: ciclo.producto,
                                            font: 'Arial',
                                            size: 20
                                        })
                                    ],
                                    spacing: {
                                        after: 100,
                                        before: 100
                                    }
                                })
                            ],
                            columnSpan: 3
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: ciclo.parcela,
                                            font: 'Arial',
                                            size: 20
                                        })
                                    ],
                                    spacing: {
                                        after: 100,
                                        before: 100
                                    }
                                })
                            ],
                            columnSpan: 3
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: ciclo.superficie,
                                            font: 'Arial',
                                            size: 20
                                        })
                                    ],
                                    spacing: {
                                        after: 100,
                                        before: 100
                                    }
                                })
                            ],
                            columnSpan: 2
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: ciclo.estimacion,
                                            font: 'Arial',
                                            size: 20
                                        })
                                    ],
                                    spacing: {
                                        after: 100,
                                        before: 100
                                    }
                                })
                            ],
                            columnSpan: 2
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: `${anexos === '' ? 'Sin anexos' : anexos}`,
                                            font: 'Arial',
                                            size: 20
                                        })
                                    ],
                                    spacing: {
                                        after: 100,
                                        before: 100
                                    }
                                })
                            ],
                            columnSpan: 2
                        }),
                    ]
                })
            )
        });

        tableCellCiclo.addChildElement(new Paragraph({
            alignment: AlignmentType.CENTER,
            children: [
                new TextRun({
                    text: 'Agregue un cuadro con información del siguiente ciclo de producción',
                    font: 'Arial',
                    size: 20,
                    bold: true
                })
            ]
        }))
        tableCellCiclo.addChildElement(table_ciclo)
        tableCellCiclo.addChildElement(paragraphCiclo)
        tableRowCiclo.addChildElement(tableCellCiclo)
        table.addChildElement(tableRowCiclo)

        table.addChildElement(new TableRow({
            children: [
                new TableCell({
                    children: [
                        new Paragraph({
                            alignment: AlignmentType.LEFT,
                            children: [
                                new TextRun({
                                    text: 'Comentarios: ' + this.getValue(data.submission, "productos_certificados_4_comentarios2"),
                                    font: 'Arial',
                                    size: 20
                                })
                            ],
                            spacing: {
                                after: 100,
                                before: 100
                            }
                        })
                    ],
                    columnSpan: 10
                }),
            ]
        }))

        table.addChildElement(new TableRow({
            children: [
                new TableCell({
                    children: [
                        new Paragraph({
                            alignment: AlignmentType.LEFT,
                            children: [
                                new TextRun({
                                    text: '5',
                                    font: 'Arial',
                                    size: 20
                                })
                            ],
                            spacing: {
                                after: 100,
                                before: 100
                            }
                        })
                    ],
                    columnSpan: 1
                }),
                new TableCell({
                    children: [
                        new Paragraph({
                            alignment: AlignmentType.LEFT,
                            children: [
                                new TextRun({
                                    text: 'Se llenó y se dejó copia del formato de Salida de Inspección MTO-FINS-03-14',
                                    font: 'Arial',
                                    size: 20
                                })
                            ],
                            spacing: {
                                after: 100,
                                before: 100
                            }
                        })
                    ],
                    columnSpan: 7
                }),
                new TableCell({
                    children: [
                        new Paragraph({
                            alignment: AlignmentType.LEFT,
                            children: [
                                new TextRun({
                                    text: `${this.getValue(data.submission, "productos_certificados_5_conformidad") === 'C' ? '■' : '❏'} C ${this.getValue(data.submission, "productos_certificados_5_conformidad") === 'NC' ? '■' : '❏'} NC ${this.getValue(data.submission, "productos_certificados_5_conformidad") === 'NA' ? '■' : '❏'} NA`,
                                    font: 'Arial',
                                    size: 20
                                })
                            ],
                            spacing: {
                                after: 100,
                                before: 100
                            }
                        })
                    ],
                    columnSpan: 2
                })
            ]
        }))
        table.addChildElement(new TableRow({
            children: [
                new TableCell({
                    children: [
                        new Paragraph({
                            alignment: AlignmentType.LEFT,
                            children: [
                                new TextRun({
                                    text: 'Explique: ' + this.getValue(data.submission, "productos_certificados_5_pregunta"),
                                    font: 'Arial',
                                    size: 20,
                                })
                            ],
                            spacing: {
                                after: 100,
                                before: 100
                            }
                        })
                    ],
                    columnSpan: 10
                }),
            ]
        }))

        return table
    }

    public createResumen(data) {
        const table = new Table({
            rows: [
                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: 'No',
                                            font: 'Arial',
                                            size: 20,
                                            bold: true
                                        })
                                    ],
                                    spacing: {
                                        after: 100,
                                        before: 100
                                    }
                                })
                            ],
                            columnSpan: 1
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: 'Hallazgos',
                                            font: 'Arial',
                                            size: 20,
                                            bold: true
                                        })
                                    ],
                                    spacing: {
                                        after: 100,
                                        before: 100
                                    }
                                })
                            ],
                            columnSpan: 3
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: 'Apartado de la norma',
                                            font: 'Arial',
                                            size: 20,
                                            bold: true
                                        })
                                    ],
                                    spacing: {
                                        after: 100,
                                        before: 100
                                    }
                                })
                            ],
                            columnSpan: 2
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: 'Evicencia',
                                            font: 'Arial',
                                            size: 20,
                                            bold: true
                                        })
                                    ],
                                    spacing: {
                                        after: 100,
                                        before: 100
                                    }
                                })
                            ],
                            columnSpan: 3
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: 'Clasificación',
                                            font: 'Arial',
                                            size: 20,
                                            bold: true
                                        })
                                    ],
                                    spacing: {
                                        after: 100,
                                        before: 100
                                    }
                                })
                            ],
                            columnSpan: 2
                        }),
                    ]
                })
            ],
            margins: {
                left: 70,
                right: 70
            },
            width: {
                size: 100,
                type: WidthType.PERCENTAGE
            },
            columnWidths: [500,1359,1359,1359,1359,1359,1359,1359,1200,1200]
        })

        const listaComentarios = this.getValueList(data.submission, "hallazgos")
        listaComentarios.forEach((comentario, index) => {
            table.addChildElement(
                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: (index+1),
                                            font: 'Arial',
                                            size: 20,
                                            bold: true
                                        })
                                    ],
                                    spacing: {
                                        after: 100,
                                        before: 100
                                    }
                                })
                            ],
                            columnSpan: 1
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: comentario.resumen_hallazgos_hallazgo,
                                            font: 'Arial',
                                            size: 20,
                                            bold: true
                                        })
                                    ],
                                    spacing: {
                                        after: 100,
                                        before: 100
                                    }
                                })
                            ],
                            columnSpan: 3
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: comentario.resumen_hallazgos_apartado,
                                            font: 'Arial',
                                            size: 20,
                                            bold: true
                                        })
                                    ],
                                    spacing: {
                                        after: 100,
                                        before: 100
                                    }
                                })
                            ],
                            columnSpan: 2
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: comentario.resumen_hallazgos_evidencia,
                                            font: 'Arial',
                                            size: 20,
                                            bold: true
                                        })
                                    ],
                                    spacing: {
                                        after: 100,
                                        before: 100
                                    }
                                })
                            ],
                            columnSpan: 3
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: comentario.resumen_hallazgos_clasificación === 'Mayor' ? '■ Mayor' : '❏ Mayor',
                                            font: 'Arial',
                                            size: 20,
                                            bold: true
                                        })
                                    ],
                                    spacing: {
                                        after: 100,
                                        before: 100
                                    }
                                }),
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: comentario.resumen_hallazgos_clasificación === 'Menor' ? '■ Menor' : '❏ Menor',
                                            font: 'Arial',
                                            size: 20,
                                            bold: true
                                        })
                                    ],
                                    spacing: {
                                        after: 100,
                                        before: 100
                                    }
                                })
                            ],
                            columnSpan: 2
                        }),
                    ]
                })
            )
        });

        return table
    }

    public createResumenReunion(data) {
        const table = new Table({
            rows: [
                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: 'Resumen de Reunión Final',
                                            font: 'Arial',
                                            size: 20,
                                            bold: true
                                        })
                                    ],
                                    spacing: {
                                        after: 100,
                                        before: 100
                                    }
                                })
                            ],
                            shading: {
                                fill: '#C0C0C0',
                                val: ShadingType.PERCENT_80
                            },
                            columnSpan: 10
                        })
                    ]
                })
            ],
            margins: {
                left: 70,
                right: 70
            },
            width: {
                size: 100,
                type: WidthType.PERCENTAGE
            },
            columnWidths: [500,1359,1359,1359,1359,1359,1359,1359,1200,1200]
        })

        const listaComentarios = this.getValueList(data.submission, "resumen_reunion")
        listaComentarios.forEach((comentario, index) => {
            table.addChildElement(
                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: `${index+1}`,
                                            font: 'Arial',
                                            size: 20,
                                            bold: true
                                        })
                                    ],
                                    spacing: {
                                        after: 100,
                                        before: 100
                                    }
                                })
                            ],
                            columnSpan: 1
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: comentario.resumen_reunion_final,
                                            font: 'Arial',
                                            size: 20,
                                            bold: true
                                        })
                                    ],
                                    spacing: {
                                        after: 100,
                                        before: 100
                                    }
                                })
                            ],
                            columnSpan: 9
                        })
                    ]
                })
            )
        });

        return table
    }

    public createComentariosOperador(data) {
        const table = new Table({
            rows: [
                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: 'Comentarios del Operador',
                                            font: 'Arial',
                                            size: 20,
                                            bold: true
                                        })
                                    ],
                                    spacing: {
                                        after: 100,
                                        before: 100
                                    }
                                })
                            ],
                            shading: {
                                fill: '#C0C0C0',
                                val: ShadingType.PERCENT_80
                            },
                            columnSpan: 10
                        })
                    ]
                })
            ],
            margins: {
                left: 70,
                right: 70
            },
            width: {
                size: 100,
                type: WidthType.PERCENTAGE
            },
            columnWidths: [500,1359,1359,1359,1359,1359,1359,1359,1200,1200]
        })

        const listaComentarios = this.getValueList(data.submission, "resumen_comentarios")
        listaComentarios.forEach((comentario, index) => {
            table.addChildElement(
                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: `${index+1}`,
                                            font: 'Arial',
                                            size: 20,
                                            bold: true
                                        })
                                    ],
                                    spacing: {
                                        after: 100,
                                        before: 100
                                    }
                                })
                            ],
                            columnSpan: 1
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: comentario.resumen_comentarios_operador,
                                            font: 'Arial',
                                            size: 20,
                                            bold: true
                                        })
                                    ],
                                    spacing: {
                                        after: 100,
                                        before: 100
                                    }
                                })
                            ],
                            columnSpan: 9
                        })
                    ]
                })
            )
        });

        return table
    }

    public createSigns(data, document, signatureImage, signatureImage2) {
        const table = new Table({
            rows: [
                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: 'Confirmación de la información',
                                            font: 'Arial',
                                            size: 20,
                                            bold: true
                                        })
                                    ],
                                    spacing: {
                                        after: 100,
                                        before: 100
                                    }
                                })
                            ],
                            shading: {
                                fill: '#C0C0C0',
                                val: ShadingType.PERCENT_80
                            },
                            columnSpan: 2
                        })
                    ]
                }),
                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        signatureImage2 
                                            ? Media.addImage(document, signatureImage2.toString(), 200, 100) 
                                            : new TextRun({
                                                text: ''
                                            })
                                    ],
                                    spacing: {
                                        after: 100,
                                        before: 100
                                    }
                                }),
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: data.inspector,
                                            font: 'Arial',
                                            size: 20,
                                        })
                                    ]
                                }),
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: '______________________________________',
                                            font: 'Arial',
                                            size: 20,
                                        })
                                    ]
                                }),
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: 'Inspector',
                                            font: 'Arial',
                                            size: 20,
                                        })
                                    ],
                                    spacing: {
                                        after: 100,
                                        before: 100
                                    }
                                })
                            ]
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        signatureImage 
                                            ? Media.addImage(document, signatureImage.toString(), 200, 100) 
                                            : new TextRun({
                                                text: ''
                                            })
                                    ],
                                    spacing: {
                                        after: 100,
                                        before: 100
                                    }
                                }),
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: data.customer.Company_Name,
                                            font: 'Arial',
                                            size: 20,
                                        })
                                    ]
                                }),new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: '______________________________________',
                                            font: 'Arial',
                                            size: 20,
                                        })
                                    ]
                                }),
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: 'Operador',
                                            font: 'Arial',
                                            size: 20,
                                        })
                                    ],
                                    spacing: {
                                        after: 100,
                                        before: 100
                                    }
                                })
                            ]
                        })
                    ]
                })
            ],
            margins: {
                left: 70,
                right: 70
            },
            width: {
                size: 100,
                type: WidthType.PERCENTAGE
            },
            columnWidths: [6206, 6206]
        })

        return table
    }

    getValue(submission, name) {
        var value = null
        for (const i in submission) {
            const element = submission[i]
            if (element.name === name) {
                value = element.value
            }
        }
        if (value) return value
        else return ''
    }

    getValueList(submission, name) {
        var value = null
        for (const i in submission) {
            const element = submission[i]
            if (element.name === name) {
                value = element.value
            }
        }
        if (value) return value
        else return []
    }

    getFileList(value) {
        var anexos = ''
        for(var i in value) {
            const tmp = value[i].split("/")
            anexos += tmp[tmp.length-1] + ", "
        }

        anexos = anexos.substr(0, anexos.length-2)

        return anexos
    }
    // END MTO_FINS_03_1_5
}
