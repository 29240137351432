<div class="modal-header">
    <h4 class="modal-title" id="modal-title">Cambiar foto de perfil</h4>
    <button
      type="button"
      class="close"
      aria-label="Close button"
      aria-describedby="modal-title"
      (click)="modal.dismiss('Cross click')"
    >
      <span aria-hidden="true">&times;</span>
    </button>
</div>  
  
<div class="modal-body">
    <div class="col-12">
        <div class="upload-box">
            <div class="upload-box-header">
            <div class="control-circles">
                <span class="circle is-green"></span>
                <span class="circle is-yellow"></span>
                <span class="circle is-red"></span>
            </div>
            <div class="header-title">
                <h3>Actualizar foto de perfil de <span class="text-primary">{{ name }}</span></h3>
            </div>
            </div>
            <div class="upload-box-content">
            <div
                class="drop-container"
                ngFileDrop
                [options]="options"
                (uploadOutput)="onUploadOutput($event)"
                [uploadInput]="uploadInput"
            >
                <p class="noMarginBottom">
                Arrastra archivos aquí o
                <label class="upload-button">
                    <input
                    type="file"
                    ngFileSelect
                    [options]="options"
                    (uploadOutput)="onUploadOutput($event)"
                    [uploadInput]="uploadInput"
                    />
                    navega
                </label>
                para cargar.
                </p>
            </div>
            <div class="upload-item" *ngFor="let f of files; let i = index">
                <div class="upload-item-content">
                <div class="filename">
                    <div class="filename-left">
                    <i class="ionicon ion-ios-copy"></i>
                    <span>{{ f.name }}</span>
                    </div>
                    <div class="filename-right">
                    <i
                        class="ionicon ion-close-round"
                        (click)="cancelUpload(f.id)"
                    ></i>
                    </div>
                </div>
                <div class="progress-content">
                    <div class="progress">
                    <span
                        class="bar"
                        [style.width]="f?.progress?.data?.percentage + '%'"
                        [class.is-done]="f?.progress?.data?.percentage === 100"
                    ></span>
                    </div>
                </div>
                <div class="progress-text-content">
                    <span
                    class="progress-text"
                    [class.is-done]="f?.progress?.data?.percentage === 100"
                    >
                    <span>{{ f.progress?.data?.percentage }}% </span>
                    <span
                        *ngIf="
                        f.progress?.data?.percentage !== 100 &&
                        f.progress?.data?.percentage !== 0
                        "
                        >Cargando...</span
                    >
                    <span *ngIf="f.progress?.data?.percentage === 100"
                        >Hecho {{finishUpload()}}</span
                    >
                    </span>
                    <span
                    class="speed-and-eta-text"
                    *ngIf="
                        f.progress?.data?.percentage !== 0 &&
                        f.progress?.data?.percentage !== 100
                    "
                    >
                    <span>{{ f.progress?.data?.speedHuman }} </span>
                    <span
                        >Tiempo restante {{ f.progress?.data?.etaHuman }}</span
                    >
                    </span>
                </div>
                </div>
            </div>
            </div>
        </div>
    </div>
</div>

<div class="modal-footer">
    <button
    type="button"
    class="btn btn-outline-secondary"
    (click)="modal.dismiss('cancel click')"
    >
    Cancelar
    </button>
    <button
    [disabled]="loading"
    type="button"
    ngbAutofocus
    class="btn btn-primary"
    (click)="onSubmit()"
    >
    <span
        *ngIf="loading"
        class="spinner-border spinner-border-sm mr-1"
    ></span>
    Guardar
    </button>
</div>
  
  