import { AlignmentType, Document, Paragraph, TextRun, Media, Header, Footer, Table, TableRow, TableCell, VerticalAlign, WidthType, HeightRule, PageNumberFormat, PageNumber } from "docx";
import { logo } from "../logo";
import { userWithoutSign } from "../userWithoutSign"
import { DatePipe } from '@angular/common'

export class DocumentCreatorMTO_FSOL_01_v2 {
    // INIT MTO-FSOL-01
    public async create_MTO_FSOL_01(data, signature = '', signature2 = ''): Promise<Document> {
        const customer = data.customer
        const submission = data.submission
        const record = data.record
        const followup = data.followup
        const reviewer = data.reviewer
        let scopes = []
        for (var i in record.Scopes) {
            const scope = record.Scopes[i]
            scopes.push(scope.Name)
        }

        const document = new Document();
        const image = Media.addImage(document, logo, 50, 50);
        var datePipe = new DatePipe('es-MX');
        let signatureImage = null
        if (signature) {
            signatureImage = await this.getBase64FromUrl(signature)
        } else {
            signatureImage = userWithoutSign
        }
        let signatureImage2 = null
        if (signature2) {
            signatureImage2 = await this.getBase64FromUrl(signature2)
        } else {
            signatureImage2 = userWithoutSign
        }

        document.addSection({
            headers: {
                default: new Header({
                    children: [
                        new Paragraph({ alignment: AlignmentType.CENTER, children: [image], }),
                        new Paragraph({
                            alignment: AlignmentType.CENTER, children: [
                                new TextRun({
                                    font: 'Book Antiqua',
                                    size: 20,
                                    bold: true,
                                    children: [`MTO-FSOL-01 | R 08 | 28-Septiembre-2021 | Pág. `, PageNumber.CURRENT]
                                }),
                                new TextRun({
                                    font: 'Book Antiqua',
                                    size: 20,
                                    bold: true,
                                    children: [' de ', PageNumber.TOTAL_PAGES]
                                }),
                            ], spacing: { before: 200, after: 300 }
                        })
                    ]
                })
            },
            footers: {
                default: new Footer({
                    children: [
                        new Paragraph({ alignment: AlignmentType.CENTER }),
                        this.createFooter()
                    ]
                }),
            },
            children: [
                new Paragraph({
                    alignment: AlignmentType.CENTER,
                    children: [
                        new TextRun({
                            text: 'SOLICITUD DE INSPECCIÓN Y CERTIFICACIÓN ORGÁNICA',
                            font: 'Calibri (Cuerpo)',
                            size: 22,
                            bold: true
                        })
                    ]
                }),
                new Paragraph({
                    alignment: AlignmentType.CENTER, children: [
                        new TextRun({
                            text: `Fecha: ${datePipe.transform(new Date(record.Applications[0].Creation_Date.includes('UTC') ? record.Applications[0].Creation_Date.replace('UTC', '').trim() : record.Applications[0].Creation_Date), 'd-MMMM-y')}          Código de operador (asignado por Metrocert): ${customer.Id_Comercial}`,
                            font: 'Arial',
                            size: 20
                        })
                    ]
                }),
                new Paragraph({
                    alignment: AlignmentType.CENTER,
                    children: [
                        new TextRun({
                            text: `${record.Type == 'PRIMERA_SOLICITUD' ? '■' : '❏'} Primera solicitud  ${record.Type == 'RENOVACION' ? '■' : '❏'} Renovación   ${record.Type == 'VARIACION' ? '■' : '❏'} Variación  ${record.Type == 'CAMBIO_ODEC' ? '■' : '❏'} Cambio de OdeC  ${record.Type == 'RECERTIFICACION' ? '■' : '❏'} Recertificación`,
                            font: 'Arial',
                            size: 20,
                            bold: true
                        })
                    ], spacing: { before: 100 }
                }),
                new Paragraph({
                    alignment: AlignmentType.LEFT,
                    children: [
                        new TextRun({
                            text: 'Ing. Mauricio Soberanes Hernández,',
                            font: 'Arial',
                            size: 20,
                            bold: true
                        })
                    ],
                    spacing: { before: 600 }
                }),
                new Paragraph({
                    alignment: AlignmentType.LEFT,
                    children: [
                        new TextRun({
                            text: 'Director general de METROCERT.',
                            font: 'Arial',
                            size: 20
                        })
                    ]
                }),
                new Paragraph({
                    alignment: AlignmentType.LEFT,
                    children: [
                        new TextRun({
                            text: 'Solicito de manera formal la inspección y certificación orgánica para la actividad cuyos datos a continuación se indican:',
                            font: 'Arial',
                            size: 20
                        })
                    ],
                    spacing: {
                        before: 200, after: 200
                    }
                }),
                this.createSectionDatosGeneralesOperador(customer, submission, record, followup),
                new Paragraph({
                    spacing: {
                        after: 400
                    }
                }),
                this.createSectionDatosGenerales(scopes),
                new Paragraph({
                    spacing: {
                        after: 400
                    }
                }),
                this.createProductsList(submission),
                new Paragraph({
                    spacing: {
                        after: 400
                    }
                }),
                this.createSpecificActivities(submission),
                new Paragraph({
                    spacing: {
                        after: 400
                    }
                }),
                this.createExceptions(submission),
                new Paragraph({
                    spacing: {
                        after: 400
                    }
                }),
                new Paragraph({
                    alignment: AlignmentType.LEFT,
                    children: [
                        new TextRun({
                            text: 'IV. Información sobre el cambio de organismo de control ',
                            font: 'Arial',
                            size: 20,
                            bold: true
                        })
                    ],
                    spacing: { after: 200 }
                }),
                new Paragraph({
                    alignment: AlignmentType.LEFT,
                    children: [
                        new TextRun({
                            text: '1. ¿Con quién estuvo certificado anteriormente?',
                            font: 'Arial',
                            size: 20,
                        })
                    ]
                }),
                new Paragraph({
                    alignment: AlignmentType.LEFT,
                    children: [
                        new TextRun({
                            text: this.getCambioOdeCValue(submission[2], "cambio_certificado_anterior"),
                            font: 'Arial',
                            size: 20,
                            bold: true
                        })
                    ],
                    spacing: { after: 200 }
                }),
                new Paragraph({
                    alignment: AlignmentType.LEFT,
                    children: [
                        new TextRun({
                            text: '2. ¿Cuál fue la fecha de vigencia del último certificado orgánico emitido por esta certificadora?',
                            font: 'Arial',
                            size: 20,
                        })
                    ]
                }),
                new Paragraph({
                    alignment: AlignmentType.LEFT,
                    children: [
                        new TextRun({
                            text: this.getCambioOdeCValue(submission[2], "cambio_certificado_anterior_vigencia"),
                            font: 'Arial',
                            size: 20,
                            bold: true
                        })
                    ],
                    spacing: { after: 200 }
                }),
                new Paragraph({
                    alignment: AlignmentType.LEFT,
                    children: [
                        new TextRun({
                            text: '3. ¿Cuál es la razón del cambio de certificadora?',
                            font: 'Arial',
                            size: 20,
                        })
                    ]
                }),
                new Paragraph({
                    alignment: AlignmentType.LEFT,
                    children: [
                        new TextRun({
                            text: this.getCambioOdeCValue(submission[2], "cambio_certificado_razon"),
                            font: 'Arial',
                            size: 20,
                            bold: true
                        })
                    ],
                    spacing: { after: 200 }
                }),
                new Paragraph({
                    alignment: AlignmentType.LEFT,
                    children: [
                        new TextRun({
                            text: '4. ¿Tiene usted No Conformidades abiertas con su anterior certificadora?',
                            font: 'Arial',
                            size: 20,
                        })
                    ]
                }),
                new Paragraph({
                    alignment: AlignmentType.LEFT,
                    children: [
                        new TextRun({
                            text: this.getCambioOdeCValue(submission[2], "cambio_certificado_no_conformidades"),
                            font: 'Arial',
                            size: 20,
                            bold: true
                        })
                    ],
                    spacing: { after: 200 }
                }),
                new Paragraph({
                    alignment: AlignmentType.LEFT,
                    children: [
                        new TextRun({
                            text: '5. En caso de no haber tenido la certificación y solo haber hecho la solicitud, favor de indicar el nombre de la certificadora con quien hizo la solicitud:',
                            font: 'Arial',
                            size: 20,
                        })
                    ]
                }),
                new Paragraph({
                    alignment: AlignmentType.LEFT,
                    children: [
                        new TextRun({
                            text: this.getCambioOdeCValue(submission[2], "cambio_certificado_nombre_certificador"),
                            font: 'Arial',
                            size: 20,
                            bold: true
                        })
                    ],
                    spacing: { after: 200 }
                }),
                new Paragraph({
                    alignment: AlignmentType.LEFT,
                    children: [
                        new TextRun({
                            text: '6. Favor de incluir copia del último Certificado y la Carta Liberatoria de parte de la certificadora anterior.',
                            font: 'Arial',
                            size: 20,
                        })
                    ]
                }),
                new Paragraph({
                    spacing: {
                        after: 200
                    }
                }),
                new Paragraph({
                    alignment: AlignmentType.LEFT,
                    children: [
                        new TextRun({
                            text: 'Atentamente:',
                            font: 'Arial',
                            size: 20,
                        })
                    ],
                    spacing: {
                        after: 100
                    }
                }),
                new Paragraph({
                    alignment: AlignmentType.CENTER,
                    children: [
                        signatureImage ? Media.addImage(document, signatureImage.toString(), 200, 100) : new TextRun({
                            text: ''
                        })
                    ]
                }),
                new Paragraph({
                    alignment: AlignmentType.LEFT,
                    children: [
                        new TextRun({
                            text: `Fecha: ${datePipe.transform(new Date(record.Applications[0].Signature_Date), 'd-MMMM-y')}                  Nombre y firma del operador: ${customer.Legal_Authorities[0].Name} ${customer.Legal_Authorities[0].Middle_Name} ${customer.Legal_Authorities[0].Last_Name ? customer.Legal_Authorities[0].Last_Name : ''}`,
                            font: 'Arial',
                            size: 20,
                            italics: true
                        })
                    ],
                    spacing: {
                        after: 200
                    }
                }),
                new Paragraph({
                    alignment: AlignmentType.LEFT,
                    children: [
                        new TextRun({
                            text: `Fecha en que se completó el expediente: ${datePipe.transform(new Date(record.Applications[0].Validation_Date), 'd-MMMM-y')}`,
                            font: 'Arial',
                            size: 20
                        })
                    ],
                    spacing: {
                        after: 200
                    }
                }),
                new Paragraph({
                    alignment: AlignmentType.LEFT,
                    children: [
                        new TextRun({
                            text: 'Revisión por Metrocert:',
                            font: 'Arial',
                            size: 20
                        })
                    ],
                    spacing: {
                        after: 100
                    }
                }),
                new Paragraph({
                    alignment: AlignmentType.LEFT,
                    children: [
                        signatureImage2 ? Media.addImage(document, signatureImage2.toString(), 200, 100) : new TextRun({
                            text: ''
                        })
                    ]
                }),
                new Paragraph({
                    alignment: AlignmentType.LEFT,
                    children: [
                        new TextRun({
                            text: `Fecha: ${reviewer && reviewer.date ? datePipe.transform(new Date(reviewer.date), 'd-MMMM-y') : ''}                  Nombre y firma del revisor: ${reviewer && reviewer.name ? reviewer.name : ''}`,
                            font: 'Arial',
                            size: 20,
                            italics: true
                        })
                    ],
                    spacing: {
                        after: 200
                    }
                })
            ],
            margins: {
                top: 350,
                bottom: 500,
                left: 1000,
                right: 1000
            },
            properties: {
                pageNumberStart: 1,
                pageNumberFormatType: PageNumberFormat.DECIMAL
            }
        })

        return document
    }

    public createFooter(): Table {
        const table = new Table({
            rows: [
                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: 'Elaboró:',
                                            font: 'Calibri (Cuerpo)',
                                            size: 22
                                        })
                                    ]
                                })
                            ],
                            width: {
                                size: 33,
                                type: WidthType.PERCENTAGE
                            }
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: 'Revisó:',
                                            font: 'Calibri (Cuerpo)',
                                            size: 22
                                        })
                                    ]
                                })
                            ],
                            width: {
                                size: 33,
                                type: WidthType.PERCENTAGE
                            }
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: 'Autorizó:',
                                            font: 'Calibri (Cuerpo)',
                                            size: 22
                                        })
                                    ]
                                }),
                            ],
                            width: {
                                size: 33,
                                type: WidthType.PERCENTAGE
                            }
                        })
                    ]
                }),
                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: 'Director de certificación',
                                            font: 'Calibri (Cuerpo)',
                                            size: 16,
                                            bold: true
                                        })
                                    ]
                                }),

                            ],
                            width: {
                                size: 33,
                                type: WidthType.PERCENTAGE
                            },
                            shading: {
                                fill: '#C0C0C0'
                            }
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: 'Responsable de calidad',
                                            font: 'Calibri (Cuerpo)',
                                            size: 16,
                                            bold: true
                                        })
                                    ]
                                }),

                            ],
                            width: {
                                size: 33,
                                type: WidthType.PERCENTAGE
                            },
                            shading: {
                                fill: '#C0C0C0'
                            }
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: 'Director general',
                                            font: 'Calibri (Cuerpo)',
                                            size: 16,
                                            bold: true
                                        })
                                    ]
                                }),
                            ],
                            width: {
                                size: 33,
                                type: WidthType.PERCENTAGE
                            },
                            shading: {
                                fill: '#C0C0C0'
                            }
                        })
                    ]
                })
            ],
            margins: {
                left: 70,
                right: 70
            },
            columnWidths: [3213, 3213, 3212],
            alignment: AlignmentType.CENTER
        })
        return table
    }

    public createSectionDatosGeneralesOperador(customer, submission, record, followup): Table {
        const table = new Table({
            rows: [
                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: 'I.	DATOS GENERALES DEL OPERADOR.',
                                            font: 'Arial',
                                            size: 20,
                                            bold: true
                                        })
                                    ],
                                    spacing: {
                                        after: 75
                                    }
                                })
                            ],
                            verticalAlign: VerticalAlign.CENTER,
                            columnSpan: 3,
                            shading: {
                                fill: '#C0C0C0'
                            }
                        })
                    ]
                }),
                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: 'Nombre del titular (persona física) o razón social (persona moral): ',
                                            font: 'Arial',
                                            size: 20
                                        }),
                                        new TextRun({
                                            text: customer.Company_Name,
                                            font: 'Arial',
                                            italics: true,
                                            size: 20
                                        })
                                    ]
                                })
                            ],
                            width: {
                                size: 33,
                                type: WidthType.PERCENTAGE
                            }
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: 'Nombre del representante legal: ',
                                            font: 'Arial',
                                            size: 20
                                        }),
                                        new TextRun({
                                            text: `${customer.Legal_Authorities[0].Name} ${customer.Legal_Authorities[0].Middle_Name} ${customer.Legal_Authorities[0].Last_Name ? customer.Legal_Authorities[0].Last_Name : ''}`,
                                            font: 'Arial',
                                            size: 20,
                                            italics: true
                                        })
                                    ]
                                })
                            ],
                            width: {
                                size: 33,
                                type: WidthType.PERCENTAGE
                            }
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: `RFC: ${customer.Legal_Authorities[0].RFC}`,
                                            font: 'Arial',
                                            size: 20
                                        })
                                    ],
                                    spacing: { after: 250 }
                                }),
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: `CURP (Opcional): ${customer.Legal_Authorities[0].CURP}`,
                                            font: 'Arial',
                                            size: 20
                                        })
                                    ],
                                    spacing: { after: 250 }
                                })
                            ],
                            width: {
                                size: 33,
                                type: WidthType.PERCENTAGE
                            }
                        })
                    ],
                    height: {
                        height: 1200,
                        rule: HeightRule.EXACT
                    }
                }),
                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: 'Persona responsable del seguimiento a la certificación: ',
                                            font: 'Arial',
                                            size: 20
                                        }),
                                        new TextRun({
                                            text: `${followup.Name}`,
                                            font: 'Arial',
                                            size: 20,
                                            italics: true
                                        })
                                    ]
                                })
                            ]
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: 'Tel: ',
                                            font: 'Arial',
                                            size: 20
                                        }),
                                        new TextRun({
                                            text: `${followup.Phone_Number}`,
                                            font: 'Arial',
                                            size: 20,
                                            italics: true
                                        })
                                    ]
                                })
                            ]
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: "Correo electrónico: ",
                                            font: 'Arial',
                                            size: 20
                                        }),
                                        new TextRun({
                                            text: `${followup.Email}`,
                                            font: 'Arial',
                                            size: 20,
                                            italics: true
                                        })
                                    ],
                                    spacing: { after: 250 }
                                })
                            ]
                        })
                    ],
                    height: {
                        height: 900,
                        rule: HeightRule.EXACT
                    }
                }),
                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: 'Tipo de operador: ',
                                            font: 'Arial',
                                            size: 20
                                        })
                                    ]
                                }),
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: `${record.Operator_Type == 'INDIVIDUAL' ? '■' : '❏'} Individual          ${record.Operator_Type == 'EMPRESA' ? '■' : '❏'} Empresa          ${record.Operator_Type == 'GRUPO' ? '■' : '❏'} Grupo de Productores         `,
                                            font: 'Arial',
                                            size: 20
                                        })
                                    ]
                                })
                            ],
                            columnSpan: 3
                        })
                    ]
                }),
                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: 'Destino del producto certificado:',
                                            font: 'Arial',
                                            size: 20
                                        })
                                    ]
                                }),
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: `${record.Destiny == 'MEXICO' || record.Destiny == 'MEXICO_CANADA' || record.Destiny == 'MEXICO_CANADA_USA' ? '■' : '❏'} México       ${record.Destiny == 'CANADA' || record.Destiny == 'CANADA_USA' || record.Destiny == 'MEXICO_CANADA' || record.Destiny == 'MEXICO_CANADA_USA' ? '■' : '❏'}  Canadá / Estados Unidos`,
                                            font: 'Arial',
                                            size: 20
                                        })
                                    ]
                                })
                            ],
                            columnSpan: 3
                        })
                    ]
                }),
                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: 'Domicilio Fiscal.',
                                            font: 'Arial',
                                            size: 20
                                        })
                                    ],
                                    spacing: {
                                        after: 75
                                    }
                                })
                            ],
                            verticalAlign: VerticalAlign.CENTER,
                            columnSpan: 3,
                            shading: {
                                fill: '#C0C0C0'
                            }
                        })
                    ]
                }),
                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: 'Calle, No. exterior e interior:  ',
                                            font: 'Arial',
                                            size: 20
                                        }),
                                        new TextRun({
                                            text: `${customer.Address_2}` + (customer.Address_Number_Ext !== "" ? " No. Ext " + customer.Address_Number_Ext : "") + (customer.Address_Number_Int !== "" ? " No. Int " + customer.Address_Number_Int : ""),
                                            font: 'Arial',
                                            italics: true,
                                            size: 20
                                        })
                                    ]
                                })
                            ],
                            width: {
                                size: 33,
                                type: WidthType.PERCENTAGE
                            }
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: 'Colonia: ',
                                            font: 'Arial',
                                            size: 20
                                        }),
                                        new TextRun({
                                            text: `${customer.Address_1}`,
                                            font: 'Arial',
                                            italics: true,
                                            size: 20
                                        })
                                    ]
                                }),
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: '',
                                            font: 'Arial',
                                            size: 20
                                        })
                                    ]
                                })
                            ],
                            width: {
                                size: 33,
                                type: WidthType.PERCENTAGE
                            }
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: "Delegación / Municipio: ",
                                            font: 'Arial',
                                            size: 20
                                        }),
                                        new TextRun({
                                            text: `${customer.Town.split("/")[1]}`,
                                            font: 'Arial',
                                            italics: true,
                                            size: 20
                                        })
                                    ],
                                    spacing: { after: 250 }
                                })
                            ],
                            width: {
                                size: 33,
                                type: WidthType.PERCENTAGE
                            }
                        })
                    ]
                }),
                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: 'Localidad: ',
                                            font: 'Arial',
                                            size: 20
                                        }),
                                        new TextRun({
                                            text: `${customer.City}`,
                                            font: 'Arial',
                                            italics: true,
                                            size: 20
                                        })
                                    ]
                                })
                            ],
                            width: {
                                size: 33,
                                type: WidthType.PERCENTAGE
                            }
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: 'C.P.: ',
                                            font: 'Arial',
                                            size: 20
                                        }),
                                        new TextRun({
                                            text: `${customer.Zip_Code}`,
                                            font: 'Arial',
                                            italics: true,
                                            size: 20
                                        })
                                    ]
                                }),
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: '',
                                            font: 'Arial',
                                            size: 20
                                        })
                                    ]
                                })
                            ],
                            width: {
                                size: 33,
                                type: WidthType.PERCENTAGE
                            }
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: "Entidad Federativa: ",
                                            font: 'Arial',
                                            size: 20
                                        }),
                                        new TextRun({
                                            text: `${customer.State.split("/")[1]}`,
                                            font: 'Arial',
                                            italics: true,
                                            size: 20
                                        })
                                    ],
                                    spacing: { after: 250 }
                                })
                            ],
                            width: {
                                size: 33,
                                type: WidthType.PERCENTAGE
                            }
                        })
                    ]
                }),
                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: 'Teléfono, fax: ',
                                            font: 'Arial',
                                            size: 20
                                        }),
                                        new TextRun({
                                            text: `${customer.Phone_Number}`,
                                            font: 'Arial',
                                            italics: true,
                                            size: 20
                                        })
                                    ]
                                })
                            ],
                            width: {
                                size: 33,
                                type: WidthType.PERCENTAGE
                            }
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: 'Email: ',
                                            font: 'Arial',
                                            size: 20
                                        }),
                                        new TextRun({
                                            text: `${customer.Email}`,
                                            font: 'Arial',
                                            size: 20
                                        })
                                    ]
                                })
                            ],
                            width: {
                                size: 66,
                                type: WidthType.PERCENTAGE
                            },
                            columnSpan: 2
                        }),
                    ]
                }),
                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: 'Domicilio físico de la operación (lugar donde se llevará a cabo la inspección).',
                                            font: 'Arial',
                                            size: 20
                                        })
                                    ],
                                    spacing: {
                                        after: 75
                                    }
                                })
                            ],
                            verticalAlign: VerticalAlign.CENTER,
                            columnSpan: 3,
                            shading: {
                                fill: '#C0C0C0'
                            }
                        })
                    ]
                })
            ],
            columnWidths: [3213, 3213, 3212]
        })
        for (var i in submission[0]) {
            const s = submission[0][i]
            if (s.name == 'unidades_produccion') {
                const values = s.value
                for (var j in values) {
                    const unidad = values[j]
                    table.addChildElement(new TableRow({
                        children: [
                            new TableCell({
                                children: [
                                    new Paragraph({
                                        alignment: AlignmentType.LEFT,
                                        children: [
                                            new TextRun({
                                                text: 'Nombre de la Unidad de Producción: ',
                                                font: 'Arial',
                                                size: 20
                                            })
                                        ]
                                    }),
                                    new Paragraph({
                                        alignment: AlignmentType.LEFT,
                                        children: [
                                            new TextRun({
                                                text: `${unidad.nombre_unidad_produccion}`,
                                                font: 'Arial',
                                                italics: true,
                                                size: 20
                                            })
                                        ]
                                    })
                                ],
                                columnSpan: 2
                            }),
                            new TableCell({
                                children: [
                                    new Paragraph({
                                        alignment: AlignmentType.LEFT,
                                        children: [
                                            new TextRun({
                                                text: 'Tipo de Unidad: ',
                                                font: 'Arial',
                                                size: 20
                                            })
                                        ]
                                    }),
                                    new Paragraph({
                                        alignment: AlignmentType.LEFT,
                                        children: [
                                            new TextRun({
                                                text: `${unidad.tipo_unidad_produccion.includes("vegetal") ? '■' : '❏'} Producción vegetal`,
                                                font: 'Arial',
                                                italics: true,
                                                size: 20
                                            })
                                        ]
                                    }),
                                    new Paragraph({
                                        alignment: AlignmentType.LEFT,
                                        children: [
                                            new TextRun({
                                                text: `${unidad.tipo_unidad_produccion.includes("animal") ? '■' : '❏'} Producción animal`,
                                                font: 'Arial',
                                                italics: true,
                                                size: 20
                                            })
                                        ]
                                    }),
                                    new Paragraph({
                                        alignment: AlignmentType.LEFT,
                                        children: [
                                            new TextRun({
                                                text: `${unidad.tipo_unidad_produccion.includes("procesamiento") ? '■' : '❏'} Procesamiento/comercialización`,
                                                font: 'Arial',
                                                italics: true,
                                                size: 20
                                            })
                                        ]
                                    })
                                ],
                                columnSpan: 1
                            })
                        ]
                    }))
                    table.addChildElement(new TableRow({
                        children: [
                            new TableCell({
                                children: [
                                    new Paragraph({
                                        alignment: AlignmentType.LEFT,
                                        children: [
                                            new TextRun({
                                                text: 'Calle, No. exterior e interior: ',
                                                font: 'Arial',
                                                size: 20
                                            })
                                        ]
                                    }),
                                    new Paragraph({
                                        alignment: AlignmentType.LEFT,
                                        children: [
                                            new TextRun({
                                                text: `${unidad.domicilio_calle}`,
                                                font: 'Arial',
                                                italics: true,
                                                size: 20
                                            })
                                        ]
                                    }),
                                ],
                                width: {
                                    size: 33,
                                    type: WidthType.PERCENTAGE
                                }
                            }),
                            new TableCell({
                                children: [
                                    new Paragraph({
                                        alignment: AlignmentType.LEFT,
                                        children: [
                                            new TextRun({
                                                text: 'Colonia: ',
                                                font: 'Arial',
                                                size: 20
                                            })
                                        ]
                                    }),
                                    new Paragraph({
                                        alignment: AlignmentType.LEFT,
                                        children: [
                                            new TextRun({
                                                text: `${unidad.domicilio_colonia}`,
                                                font: 'Arial',
                                                italics: true,
                                                size: 20
                                            })
                                        ]
                                    }),
                                ],
                                width: {
                                    size: 33,
                                    type: WidthType.PERCENTAGE
                                }
                            }),
                            new TableCell({
                                children: [
                                    new Paragraph({
                                        alignment: AlignmentType.LEFT,
                                        children: [
                                            new TextRun({
                                                text: "Delegación / Municipio: ",
                                                font: 'Arial',
                                                size: 20
                                            })
                                        ]
                                    }),
                                    new Paragraph({
                                        alignment: AlignmentType.LEFT,
                                        children: [
                                            new TextRun({
                                                text: `${unidad.domicilio_municipio}`,
                                                font: 'Arial',
                                                italics: true,
                                                size: 20
                                            })
                                        ]
                                    }),
                                ],
                                width: {
                                    size: 33,
                                    type: WidthType.PERCENTAGE
                                }
                            })
                        ]
                    }))
                    table.addChildElement(new TableRow({
                        children: [
                            new TableCell({
                                children: [
                                    new Paragraph({
                                        alignment: AlignmentType.LEFT,
                                        children: [
                                            new TextRun({
                                                text: 'Localidad: ',
                                                font: 'Arial',
                                                size: 20
                                            })
                                        ]
                                    }),
                                    new Paragraph({
                                        alignment: AlignmentType.LEFT,
                                        children: [
                                            new TextRun({
                                                text: `${unidad.domicilio_localidad}`,
                                                font: 'Arial',
                                                italics: true,
                                                size: 20
                                            })
                                        ]
                                    }),
                                ],
                                width: {
                                    size: 33,
                                    type: WidthType.PERCENTAGE
                                }
                            }),
                            new TableCell({
                                children: [
                                    new Paragraph({
                                        alignment: AlignmentType.LEFT,
                                        children: [
                                            new TextRun({
                                                text: 'C.P.: ',
                                                font: 'Arial',
                                                size: 20
                                            })
                                        ]
                                    }),
                                    new Paragraph({
                                        alignment: AlignmentType.LEFT,
                                        children: [
                                            new TextRun({
                                                text: `${unidad.domicilio_cp}`,
                                                font: 'Arial',
                                                italics: true,
                                                size: 20
                                            })
                                        ]
                                    }),
                                ],
                                width: {
                                    size: 33,
                                    type: WidthType.PERCENTAGE
                                }
                            }),
                            new TableCell({
                                children: [
                                    new Paragraph({
                                        alignment: AlignmentType.LEFT,
                                        children: [
                                            new TextRun({
                                                text: "Entidad Federativa: ",
                                                font: 'Arial',
                                                size: 20
                                            })
                                        ],
                                    }),
                                    new Paragraph({
                                        alignment: AlignmentType.LEFT,
                                        children: [
                                            new TextRun({
                                                text: `${unidad.domicilio_estado}`,
                                                font: 'Arial',
                                                italics: true,
                                                size: 20
                                            })
                                        ],
                                        spacing: { after: 250 }
                                    }),
                                ],
                                width: {
                                    size: 33,
                                    type: WidthType.PERCENTAGE
                                }
                            })
                        ]
                    }))
                }
            }
        }
        return table
    }

    public createSectionDatosGenerales(scopes): Table {
        const table = new Table({
            rows: [
                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: 'II.	DATOS GENERALES (Alcance: Producto y Proceso a certificar).',
                                            font: 'Arial',
                                            size: 20,
                                            bold: true
                                        })
                                    ],
                                    spacing: {
                                        after: 75
                                    }
                                }),
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: 'Nota: En caso de solicitar una extensión, marque con azul los nuevos alcances. En caso de solicitar una reducción, maque con rojos los alcances de baja.',
                                            font: 'Arial',
                                            size: 20
                                        })
                                    ],
                                    spacing: {
                                        after: 75
                                    }
                                })
                            ],
                            verticalAlign: VerticalAlign.CENTER,
                            columnSpan: 3,
                            shading: {
                                fill: '#C0C0C0'
                            }
                        })
                    ]
                }),
                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: '             Categoría de certificación LPO (México) ',
                                            font: 'Arial',
                                            size: 20,
                                            bold: true
                                        })
                                    ]
                                }),
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: `${scopes.includes('LPO_PRODUCCION_VEGETAL_GRANOS') || scopes.includes('LPO_PRODUCCION_VEGETAL_ESTRUCTURAS') || scopes.includes('LPO_PRODUCCION_VEGETAL_GERMINADOS') ? '■' : '❏'} Producción vegetal`,
                                            font: 'Arial',
                                            size: 20
                                        })
                                    ]
                                }),
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: `         ${scopes.includes('LPO_PRODUCCION_VEGETAL_GRANOS') ? '■' : '❏'} Granos básicos y frutales`,
                                            font: 'Arial',
                                            size: 20
                                        })
                                    ]
                                }),
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: `         ${scopes.includes('LPO_PRODUCCION_VEGETAL_ESTRUCTURAS') ? '■' : '❏'} Estructuras y contenedores`,
                                            font: 'Arial',
                                            size: 20
                                        })
                                    ]
                                }),
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: `         ${scopes.includes('LPO_PRODUCCION_VEGETAL_GERMINADOS') ? '■' : '❏'} Germinados`,
                                            font: 'Arial',
                                            size: 20
                                        })
                                    ]
                                }),
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: `${scopes.includes('LPO_PRODUCTOS_RECOLECCION_V2') ? '■' : '❏'} Productos vegetales de recolección silvestre`,
                                            font: 'Arial',
                                            size: 20
                                        })
                                    ]
                                }),
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: `${scopes.includes('LPO_PRODUCCION_ANIMAL_DOMESTICOS_V2') ? '■' : '❏'} Producción animal (domésticos)`,
                                            font: 'Arial',
                                            size: 20
                                        })
                                    ]
                                }),
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: `${scopes.includes('LPO_PRODUCCION_ANIMAL_ECOSISTEMAS_V2') ? '■' : '❏'} Producción animal de ecosistemas naturales o no domésticos`,
                                            font: 'Arial',
                                            size: 20
                                        })
                                    ]
                                }),
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: `${scopes.includes('LPO_PRODUCTOS_INSECTA_V2') ? '■' : '❏'} Producción animal clase insecta`,
                                            font: 'Arial',
                                            size: 20
                                        })
                                    ]
                                }),
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: `${scopes.includes('LPO_PRODUCCION_ACUICOLA') ? '■' : '❏'} Producción animal acuícola`,
                                            font: 'Arial',
                                            size: 20
                                        })
                                    ]
                                }),
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: `${scopes.includes('LPO_PRODUCTOS_FUNGI_V2') ? '■' : '❏'} Productos de la clase fungi`,
                                            font: 'Arial',
                                            size: 20
                                        })
                                    ]
                                }),
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: `${scopes.includes('LPO_PROCESAMIENTO_PRODUCTOS_AGROPECUARIOS_V2') ? '■' : '❏'} Procesamiento de productos de las actividades agropecuarias`,
                                            font: 'Arial',
                                            size: 20
                                        })
                                    ]
                                }),
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: `${scopes.includes('LPO_COMERCIALIZACION_PRODUCTOS_AGROPECUARIOS_V2') ? '■' : '❏'} Comercialización de productos de las actividades agropecuarias`,
                                            font: 'Arial',
                                            size: 20
                                        })
                                    ]
                                })
                            ],
                            columnSpan: 3
                        })
                    ]
                }),
                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: '             Categoría de certificación COR/USCOEA (Canadá/Estados Unidos) ',
                                            font: 'Arial',
                                            size: 20,
                                            bold: true
                                        })
                                    ]
                                }),
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: `${scopes.includes('COR_PRODUCCION_AGRICOLA') ? '■' : '❏'} Producción agrícola`,
                                            font: 'Arial',
                                            size: 20
                                        })
                                    ]
                                }),
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: `${scopes.includes('COR_PRODUCCION_ALIMENTO_GANADO') ? '■' : '❏'} Producción de alimentos para el ganado`,
                                            font: 'Arial',
                                            size: 20
                                        })
                                    ]
                                }),
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: `${scopes.includes('COR_PRODUCCION_ESPECIALIZADA_INVERNADEROS') || scopes.includes('COR_PRODUCCION_ESPECIALIZADA_APICULTURA') || scopes.includes('COR_PRODUCCION_ESPECIALIZADA_VEGETALES') || scopes.includes('COR_PRODUCCION_ESPECIALIZADA_GERMINADOS') ? '■' : '❏'} Producción especializada`,
                                            font: 'Arial',
                                            size: 20
                                        })
                                    ]
                                }),
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: `         ${scopes.includes('COR_PRODUCCION_ESPECIALIZADA_INVERNADEROS') ? '■' : '❏'} Invernaderos y contenedores`,
                                            font: 'Arial',
                                            size: 20
                                        })
                                    ]
                                }),
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: `         ${scopes.includes('COR_PRODUCCION_ESPECIALIZADA_APICULTURA') ? '■' : '❏'} Apicultura`,
                                            font: 'Arial',
                                            size: 20
                                        })
                                    ]
                                }),
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: `         ${scopes.includes('COR_PRODUCCION_ESPECIALIZADA_VEGETALES') ? '■' : '❏'} Vegetales de recolección silvestre`,
                                            font: 'Arial',
                                            size: 20
                                        })
                                    ]
                                }),
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: `         ${scopes.includes('COR_PRODUCCION_ESPECIALIZADA_GERMINADOS') ? '■' : '❏'} Germinados`,
                                            font: 'Arial',
                                            size: 20
                                        })
                                    ]
                                }),
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: `${scopes.includes('COR_PRODUCTOS_PROCESADOS') ? '■' : '❏'} Productos procesados`,
                                            font: 'Arial',
                                            size: 20
                                        })
                                    ]
                                })
                            ],
                            columnSpan: 3
                        })
                    ]
                }),
                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: '             Subdivisión COR/USCOEA (Canadá/Estados Unidos) ',
                                            font: 'Arial',
                                            size: 20,
                                            bold: true
                                        })
                                    ]
                                }),
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: `${scopes.includes('COR_PRODUCTOS_ORGANICOS') ? '■' : '❏'} Productos orgánicos`,
                                            font: 'Arial',
                                            size: 20
                                        })
                                    ]
                                }),
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: `${scopes.includes('COR_EMPAQUE_ETIQUETADO_PRODUCTOS_ORGANICOS') ? '■' : '❏'} Empaque o etiquetado de productos orgánicos`,
                                            font: 'Arial',
                                            size: 20
                                        })
                                    ]
                                })
                            ],
                            columnSpan: 3
                        })
                    ]
                })
            ],
            margins: {
                left: 70,
                right: 70
            },
            columnWidths: [3213, 3213, 3212]
        })
        return table
    }

    public createProductsList(submission): Table {
        const products = submission[1][0].value
        const table = new Table({
            rows: [
                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: 'Lista de productos:',
                                            font: 'Arial',
                                            size: 20,
                                            bold: true
                                        })
                                    ],
                                    spacing: {
                                        after: 75
                                    }
                                })
                            ],
                            verticalAlign: VerticalAlign.CENTER,
                            columnSpan: 7,
                            shading: {
                                fill: '#C0C0C0'
                            }
                        })
                    ]
                }),
                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: 'No.',
                                            font: 'Arial',
                                            size: 20
                                        })
                                    ]
                                })
                            ],
                            verticalAlign: VerticalAlign.CENTER,
                            shading: {
                                fill: '#C0C0C0'
                            },
                            width: {
                                size: 5,
                                type: WidthType.PERCENTAGE
                            }
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: 'Producto/Especie',
                                            font: 'Arial',
                                            size: 20
                                        })
                                    ]
                                })
                            ],
                            verticalAlign: VerticalAlign.CENTER,
                            shading: {
                                fill: '#C0C0C0'
                            },
                            width: {
                                size: 19,
                                type: WidthType.PERCENTAGE
                            }
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: 'Variedad/Raza/Marca Comercial',
                                            font: 'Arial',
                                            size: 20
                                        })
                                    ]
                                })
                            ],
                            verticalAlign: VerticalAlign.CENTER,
                            shading: {
                                fill: '#C0C0C0'
                            },
                            width: {
                                size: 19,
                                type: WidthType.PERCENTAGE
                            }
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: 'Hectáreas/No. de Animales',
                                            font: 'Arial',
                                            size: 20
                                        })
                                    ]
                                })
                            ],
                            verticalAlign: VerticalAlign.CENTER,
                            shading: {
                                fill: '#C0C0C0'
                            },
                            width: {
                                size: 19,
                                type: WidthType.PERCENTAGE
                            }
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: 'No de Unidades de producción/Lote/Apiario/Tanque/Corral/Caseta',
                                            font: 'Arial',
                                            size: 20
                                        })
                                    ]
                                })
                            ],
                            verticalAlign: VerticalAlign.CENTER,
                            shading: {
                                fill: '#C0C0C0'
                            },
                            width: {
                                size: 19,
                                type: WidthType.PERCENTAGE
                            }
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: 'Producción Anual o Por Ciclo Estimada (Kg, Lt, Pza) ',
                                            font: 'Arial',
                                            size: 20
                                        })
                                    ]
                                })
                            ],
                            verticalAlign: VerticalAlign.CENTER,
                            shading: {
                                fill: '#C0C0C0'
                            },
                            width: {
                                size: 19,
                                type: WidthType.PERCENTAGE
                            }
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: 'Certificación',
                                            font: 'Arial',
                                            size: 20
                                        })
                                    ]
                                })
                            ],
                            verticalAlign: VerticalAlign.CENTER,
                            shading: {
                                fill: '#C0C0C0'
                            },
                            width: {
                                size: 19,
                                type: WidthType.PERCENTAGE
                            }
                        })
                    ]
                })
            ],
            margins: {
                left: 70,
                right: 70
            },
            columnWidths: [803, 1506, 1507, 1406, 1606, 1407, 1403] // Debe sumar 9638
        })
        for (var i in products) {
            const product = products[i]
            table.addChildElement(new TableRow({
                children: [
                    new TableCell({
                        children: [
                            new Paragraph({
                                alignment: AlignmentType.LEFT,
                                children: [
                                    new TextRun({
                                        text: `${parseInt(i) + 1}`,
                                        font: 'Arial',
                                        size: 20,
                                        italics: true
                                    })
                                ]
                            })
                        ],
                        verticalAlign: VerticalAlign.TOP
                    }),
                    new TableCell({
                        children: [
                            new Paragraph({
                                alignment: AlignmentType.LEFT,
                                children: [
                                    new TextRun({
                                        text: `${product.lista_productos_producto}`,
                                        font: 'Arial',
                                        size: 20,
                                        italics: true
                                    })
                                ]
                            })
                        ],
                        verticalAlign: VerticalAlign.TOP
                    }),
                    new TableCell({
                        children: [
                            new Paragraph({
                                alignment: AlignmentType.LEFT,
                                children: [
                                    new TextRun({
                                        text: `${product.lista_productos_variedad}`,
                                        font: 'Arial',
                                        size: 20,
                                        italics: true
                                    })
                                ]
                            })
                        ],
                        verticalAlign: VerticalAlign.TOP
                    }),
                    new TableCell({ 
                        children: [
                            new Paragraph({
                                alignment: AlignmentType.LEFT,
                                children: [
                                    new TextRun({
                                        text: `${product.lista_productos_hectareas} ${product.lista_productos_hectareas_tipo}`,
                                        font: 'Arial',
                                        size: 20,
                                        italics: true
                                    })
                                ]
                            })
                        ],
                        verticalAlign: VerticalAlign.TOP
                    }),
                    new TableCell({
                        children: [
                            new Paragraph({
                                alignment: AlignmentType.LEFT,
                                children: [
                                    new TextRun({
                                        text: `${product.lista_productos_no_unidades} ${product.lista_productos_no_unidades_tipo}`,
                                        font: 'Arial',
                                        size: 20,
                                        italics: true
                                    })
                                ]
                            })
                        ],
                        verticalAlign: VerticalAlign.TOP
                    }),
                    new TableCell({
                        children: [
                            new Paragraph({
                                alignment: AlignmentType.LEFT,
                                children: [
                                    new TextRun({
                                        text: `${product.lista_productos_produccion_anual} ${product.lista_productos_produccion_anual_tipo}`,
                                        font: 'Arial',
                                        size: 20,
                                        italics: true
                                    })
                                ]
                            })
                        ],
                        verticalAlign: VerticalAlign.TOP
                    }),
                    new TableCell({
                        children: [
                            new Paragraph({
                                alignment: AlignmentType.LEFT,
                                children: [
                                    new TextRun({
                                        text: `${product.lista_productos_lpo ? '■' : '❏'} LPO`,
                                        font: 'Arial',
                                        size: 20
                                    })
                                ]
                            }),
                            new Paragraph({
                                alignment: AlignmentType.LEFT,
                                children: [
                                    new TextRun({
                                        text: `${product.lista_productos_cor ? '■' : '❏'} COR/USCOEA`,
                                        font: 'Arial',
                                        size: 20
                                    })
                                ]
                            })
                        ],
                        verticalAlign: VerticalAlign.TOP
                    })
                ]
            }))
        }
        return table
    }

    public createSpecificActivities(submission): Table {
        const activities = submission[1][1].value.length === 0 ? [''] : submission[1][1].value

        const table = new Table({
            rows: [
                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: 'Actividades específicas realizadas por el operador ',
                                            font: 'Arial',
                                            size: 20,
                                            bold: true
                                        })
                                    ],
                                    spacing: {
                                        after: 75
                                    }
                                }),
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: 'Por favor indique los procesos de su sistema de producción que son desarrollados ',
                                            font: 'Arial',
                                            size: 20
                                        })
                                    ],
                                    spacing: {
                                        after: 75
                                    }
                                })
                            ],
                            verticalAlign: VerticalAlign.CENTER,
                            columnSpan: 6,
                            shading: {
                                fill: '#C0C0C0'
                            }
                        })
                    ]
                }),
                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: 'Proceso',
                                            font: 'Arial',
                                            size: 18,
                                            bold: true
                                        })
                                    ]
                                })
                            ],
                            verticalAlign: VerticalAlign.TOP,
                            width: {
                                size: 25,
                                type: WidthType.PERCENTAGE
                            }
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: 'Proceso realizado dentro (Interno) o fuera (Externo) de la unidad de producción',
                                            font: 'Arial',
                                            size: 18,
                                            bold: true
                                        })
                                    ]
                                })
                            ],
                            verticalAlign: VerticalAlign.TOP,
                            width: {
                                size: 15,
                                type: WidthType.PERCENTAGE
                            }
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: 'Operador que lo desarrolla',
                                            font: 'Arial',
                                            size: 18,
                                            bold: true
                                        })
                                    ]
                                })
                            ],
                            verticalAlign: VerticalAlign.TOP,
                            width: {
                                size: 15,
                                type: WidthType.PERCENTAGE
                            }
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: '¿Operador certificado?',
                                            font: 'Arial',
                                            size: 18,
                                            bold: true
                                        })
                                    ]
                                })
                            ],
                            verticalAlign: VerticalAlign.TOP,
                            width: {
                                size: 15,
                                type: WidthType.PERCENTAGE
                            }
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: 'Ubicación del operador (Dirección)',
                                            font: 'Arial',
                                            size: 18,
                                            bold: true
                                        })
                                    ]
                                })
                            ],
                            verticalAlign: VerticalAlign.TOP,
                            width: {
                                size: 15,
                                type: WidthType.PERCENTAGE
                            }
                        }),
                    ]
                })
            ],
            margins: {
                left: 70,
                right: 70
            },
            columnWidths: [2308, 1706, 1959, 1706, 1959]
        })

        // Creation of Activities object
        let actividades = {
            semillero: [
                {
                    actividades_tipo: '',
                    actividades_interno_externo: '',
                    activades_operador_que_desarrolla: '',
                    actividades_certificado: false,
                    actividades_no_certificado: false,
                    activades_ubicacion: ''
                }
            ],
            cosecha: [
                {
                    actividades_tipo: '',
                    actividades_interno_externo: '',
                    activades_operador_que_desarrolla: '',
                    actividades_certificado: false,
                    actividades_no_certificado: false,
                    activades_ubicacion: ''
                }
            ],
            transporte: [
                {
                    actividades_tipo: '',
                    actividades_interno_externo: '',
                    activades_operador_que_desarrolla: '',
                    actividades_certificado: false,
                    actividades_no_certificado: false,
                    activades_ubicacion: ''
                }
            ],
            lavado: [
                {
                    actividades_tipo: '',
                    actividades_interno_externo: '',
                    activades_operador_que_desarrolla: '',
                    actividades_certificado: false,
                    actividades_no_certificado: false,
                    activades_ubicacion: ''
                }
            ],
            seleccion: [
                {
                    actividades_tipo: '',
                    actividades_interno_externo: '',
                    activades_operador_que_desarrolla: '',
                    actividades_certificado: false,
                    actividades_no_certificado: false,
                    activades_ubicacion: ''
                }
            ],
            envasado: [
                {
                    actividades_tipo: '',
                    actividades_interno_externo: '',
                    activades_operador_que_desarrolla: '',
                    actividades_certificado: false,
                    actividades_no_certificado: false,
                    activades_ubicacion: ''
                }
            ],
            empaque: [
                {
                    actividades_tipo: '',
                    actividades_interno_externo: '',
                    activades_operador_que_desarrolla: '',
                    actividades_certificado: false,
                    actividades_no_certificado: false,
                    activades_ubicacion: ''
                }
            ],
            almacenamiento: [
                {
                    actividades_tipo: '',
                    actividades_interno_externo: '',
                    activades_operador_que_desarrolla: '',
                    actividades_certificado: false,
                    actividades_no_certificado: false,
                    activades_ubicacion: ''
                }
            ],
            refrigerado: [
                {
                    actividades_tipo: '',
                    actividades_interno_externo: '',
                    activades_operador_que_desarrolla: '',
                    actividades_certificado: false,
                    actividades_no_certificado: false,
                    activades_ubicacion: ''
                }
            ],
            deshidratado: [
                {
                    actividades_tipo: '',
                    actividades_interno_externo: '',
                    activades_operador_que_desarrolla: '',
                    actividades_certificado: false,
                    actividades_no_certificado: false,
                    activades_ubicacion: ''
                }
            ],
            mezclado: [
                {
                    actividades_tipo: '',
                    actividades_interno_externo: '',
                    activades_operador_que_desarrolla: '',
                    actividades_certificado: false,
                    actividades_no_certificado: false,
                    activades_ubicacion: ''
                }
            ],
            pasteurizacion: [
                {
                    actividades_tipo: '',
                    actividades_interno_externo: '',
                    activades_operador_que_desarrolla: '',
                    actividades_certificado: false,
                    actividades_no_certificado: false,
                    activades_ubicacion: ''
                }
            ],
            liofilizado: [
                {
                    actividades_tipo: '',
                    actividades_interno_externo: '',
                    activades_operador_que_desarrolla: '',
                    actividades_certificado: false,
                    actividades_no_certificado: false,
                    activades_ubicacion: ''
                }
            ],
            sacrificio: [
                {
                    actividades_tipo: '',
                    actividades_interno_externo: '',
                    activades_operador_que_desarrolla: '',
                    actividades_certificado: false,
                    actividades_no_certificado: false,
                    activades_ubicacion: ''
                }
            ],
            comercializacion: [
                {
                    actividades_tipo: '',
                    actividades_interno_externo: '',
                    activades_operador_que_desarrolla: '',
                    actividades_certificado: false,
                    actividades_no_certificado: false,
                    activades_ubicacion: ''
                }
            ],
            otro: [
                {
                    actividades_tipo: '',
                    actividades_tipo_otro: '',
                    actividades_interno_externo: '',
                    activades_operador_que_desarrolla: '',
                    actividades_certificado: false,
                    actividades_no_certificado: false,
                    activades_ubicacion: ''
                }
            ]
        }

        // Get each activity by type
        var semillero = []
        var cosecha = []
        var transporte = []
        var lavado = []
        var seleccion = []
        var envasado = []
        var empaque = []
        var almacenamiento = []
        var refrigerado = []
        var deshidratado = []
        var mezclado = []
        var pasteurizacion = []
        var liofilizado = []
        var sacrificio = []
        var comercializacion = []
        var otro = []
        activities.forEach(activity => {          
            switch(activity.actividades_tipo) {
                case 'Semillero o Vivero':
                    semillero.push({
                        actividades_tipo: activity.actividades_tipo,
                        actividades_interno_externo: activity.actividades_interno_externo,
                        activades_operador_que_desarrolla: activity.activades_operador_que_desarrolla,
                        actividades_certificado: activity.actividades_certificado,
                        actividades_no_certificado: activity.actividades_no_certificado,
                        activades_ubicacion: activity.activades_ubicacion
                    })
                    break
                case 'Cosecha/Corte':
                    cosecha.push({
                        actividades_tipo: activity.actividades_tipo,
                        actividades_interno_externo: activity.actividades_interno_externo,
                        activades_operador_que_desarrolla: activity.activades_operador_que_desarrolla,
                        actividades_certificado: activity.actividades_certificado,
                        actividades_no_certificado: activity.actividades_no_certificado,
                        activades_ubicacion: activity.activades_ubicacion
                    })
                    break
                case 'Transporte':
                    transporte.push({
                        actividades_tipo: activity.actividades_tipo,
                        actividades_interno_externo: activity.actividades_interno_externo,
                        activades_operador_que_desarrolla: activity.activades_operador_que_desarrolla,
                        actividades_certificado: activity.actividades_certificado,
                        actividades_no_certificado: activity.actividades_no_certificado,
                        activades_ubicacion: activity.activades_ubicacion
                    })
                    break
                case 'Lavado/Sanitizado':
                    lavado.push({
                        actividades_tipo: activity.actividades_tipo,
                        actividades_interno_externo: activity.actividades_interno_externo,
                        activades_operador_que_desarrolla: activity.activades_operador_que_desarrolla,
                        actividades_certificado: activity.actividades_certificado,
                        actividades_no_certificado: activity.actividades_no_certificado,
                        activades_ubicacion: activity.activades_ubicacion
                    })
                    break
                case 'Selección':
                    seleccion.push({
                        actividades_tipo: activity.actividades_tipo,
                        actividades_interno_externo: activity.actividades_interno_externo,
                        activades_operador_que_desarrolla: activity.activades_operador_que_desarrolla,
                        actividades_certificado: activity.actividades_certificado,
                        actividades_no_certificado: activity.actividades_no_certificado,
                        activades_ubicacion: activity.activades_ubicacion
                    })
                    break
                case 'Envasado':
                    envasado.push({
                        actividades_tipo: activity.actividades_tipo,
                        actividades_interno_externo: activity.actividades_interno_externo,
                        activades_operador_que_desarrolla: activity.activades_operador_que_desarrolla,
                        actividades_certificado: activity.actividades_certificado,
                        actividades_no_certificado: activity.actividades_no_certificado,
                        activades_ubicacion: activity.activades_ubicacion
                    })
                    break
                case 'Empaque':
                    empaque.push({
                        actividades_tipo: activity.actividades_tipo,
                        actividades_interno_externo: activity.actividades_interno_externo,
                        activades_operador_que_desarrolla: activity.activades_operador_que_desarrolla,
                        actividades_certificado: activity.actividades_certificado,
                        actividades_no_certificado: activity.actividades_no_certificado,
                        activades_ubicacion: activity.activades_ubicacion
                    })
                    break
                case 'Almacenamiento':
                    almacenamiento.push({
                        actividades_tipo: activity.actividades_tipo,
                        actividades_interno_externo: activity.actividades_interno_externo,
                        activades_operador_que_desarrolla: activity.activades_operador_que_desarrolla,
                        actividades_certificado: activity.actividades_certificado,
                        actividades_no_certificado: activity.actividades_no_certificado,
                        activades_ubicacion: activity.activades_ubicacion
                    })
                    break
                case 'Refrigerado / Congelado':
                    refrigerado.push({
                        actividades_tipo: activity.actividades_tipo,
                        actividades_interno_externo: activity.actividades_interno_externo,
                        activades_operador_que_desarrolla: activity.activades_operador_que_desarrolla,
                        actividades_certificado: activity.actividades_certificado,
                        actividades_no_certificado: activity.actividades_no_certificado,
                        activades_ubicacion: activity.activades_ubicacion
                    })
                    break
                case 'Deshidratado':
                    deshidratado.push({
                        actividades_tipo: activity.actividades_tipo,
                        actividades_interno_externo: activity.actividades_interno_externo,
                        activades_operador_que_desarrolla: activity.activades_operador_que_desarrolla,
                        actividades_certificado: activity.actividades_certificado,
                        actividades_no_certificado: activity.actividades_no_certificado,
                        activades_ubicacion: activity.activades_ubicacion
                    })
                    break
                case 'Mezclado':
                    mezclado.push({
                        actividades_tipo: activity.actividades_tipo,
                        actividades_interno_externo: activity.actividades_interno_externo,
                        activades_operador_que_desarrolla: activity.activades_operador_que_desarrolla,
                        actividades_certificado: activity.actividades_certificado,
                        actividades_no_certificado: activity.actividades_no_certificado,
                        activades_ubicacion: activity.activades_ubicacion
                    })
                    break
                case 'Pasteurización':
                    pasteurizacion.push({
                        actividades_tipo: activity.actividades_tipo,
                        actividades_interno_externo: activity.actividades_interno_externo,
                        activades_operador_que_desarrolla: activity.activades_operador_que_desarrolla,
                        actividades_certificado: activity.actividades_certificado,
                        actividades_no_certificado: activity.actividades_no_certificado,
                        activades_ubicacion: activity.activades_ubicacion
                    })
                    break
                case 'Liofilizado':
                    liofilizado.push({
                        actividades_tipo: activity.actividades_tipo,
                        actividades_interno_externo: activity.actividades_interno_externo,
                        activades_operador_que_desarrolla: activity.activades_operador_que_desarrolla,
                        actividades_certificado: activity.actividades_certificado,
                        actividades_no_certificado: activity.actividades_no_certificado,
                        activades_ubicacion: activity.activades_ubicacion
                    })
                    break
                case 'Sacrificio':
                    sacrificio.push({
                        actividades_tipo: activity.actividades_tipo,
                        actividades_interno_externo: activity.actividades_interno_externo,
                        activades_operador_que_desarrolla: activity.activades_operador_que_desarrolla,
                        actividades_certificado: activity.actividades_certificado,
                        actividades_no_certificado: activity.actividades_no_certificado,
                        activades_ubicacion: activity.activades_ubicacion
                    })
                    break
                case 'Comercialización':
                    comercializacion.push({
                        actividades_tipo: activity.actividades_tipo,
                        actividades_interno_externo: activity.actividades_interno_externo,
                        activades_operador_que_desarrolla: activity.activades_operador_que_desarrolla,
                        actividades_certificado: activity.actividades_certificado,
                        actividades_no_certificado: activity.actividades_no_certificado,
                        activades_ubicacion: activity.activades_ubicacion
                    })
                    break
                case 'Otro':
                    otro.push({
                        actividades_tipo: activity.actividades_tipo,
                        actividades_tipo_otro: activity.actividades_tipo_otro,
                        actividades_interno_externo:  activity.actividades_interno_externo,
                        activades_operador_que_desarrolla: activity.activades_operador_que_desarrolla,
                        actividades_certificado: activity.actividades_certificado,
                        actividades_no_certificado: activity.actividades_no_certificado,
                        activades_ubicacion: activity.activades_ubicacion
                    })
                    break
                default:
                    break
            }
        });

        // Reasignation of values of each activity tipe on Activities object
        semillero.length !== 0 ? actividades.semillero = semillero : null
        cosecha.length !== 0 ? actividades.cosecha = cosecha : null
        transporte.length !== 0 ? actividades.transporte = transporte : null
        lavado.length !== 0 ? actividades.lavado = lavado : null
        seleccion.length !== 0 ? actividades.seleccion = seleccion : null
        envasado.length !== 0 ? actividades.envasado = envasado : null
        empaque.length !== 0 ? actividades.empaque = empaque : null
        almacenamiento.length !== 0 ? actividades.almacenamiento = almacenamiento : null
        refrigerado.length !== 0 ? actividades.refrigerado = refrigerado : null
        deshidratado.length !== 0 ? actividades.deshidratado = deshidratado : null
        mezclado.length !== 0 ? actividades.mezclado = mezclado : null
        pasteurizacion.length !== 0 ? actividades.pasteurizacion = pasteurizacion : null
        liofilizado.length !== 0 ? actividades.liofilizado = liofilizado : null
        sacrificio.length !== 0 ? actividades.sacrificio = sacrificio : null
        comercializacion.length !== 0 ? actividades.comercializacion = comercializacion : null
        otro.length !== 0 ? actividades.otro = otro : null

        // Creation of each Activity or Activities on document
        actividades.semillero.forEach(activity => {
            table.addChildElement(
                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: `${activity.actividades_tipo === 'Semillero o Vivero' ? '■' : '❏'} Semillero o Vivero`,
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                })
                            ],
                            verticalAlign: VerticalAlign.TOP
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: `${activity.actividades_interno_externo === "Interno" ? '■' : '❏' } Int.  ${activity.actividades_interno_externo === "Externo" ? '■' : '❏' } Ext.`,
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                })
                            ],
                            verticalAlign: VerticalAlign.TOP
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: activity.activades_operador_que_desarrolla,
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                })
                            ],
                            verticalAlign: VerticalAlign.TOP
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: `${activity.actividades_certificado ? '■' : '❏' } Si ${activity.actividades_no_certificado ? '■' : '❏' } No`,
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                })
                            ],
                            verticalAlign: VerticalAlign.TOP
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: activity.activades_ubicacion,
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                })
                            ],
                            verticalAlign: VerticalAlign.TOP
                        })
                    ]
                })
            )
        })

        actividades.cosecha.forEach(activity => {
            table.addChildElement(
                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: `${activity.actividades_tipo === 'Cosecha/Corte' ? '■' : '❏'} Cosecha/Corte`,
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                })
                            ],
                            verticalAlign: VerticalAlign.TOP
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: `${activity.actividades_interno_externo === "Interno" ? '■' : '❏' } Int.  ${activity.actividades_interno_externo === "Externo" ? '■' : '❏' } Ext.`,
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                })
                            ],
                            verticalAlign: VerticalAlign.TOP
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: activity.activades_operador_que_desarrolla,
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                })
                            ],
                            verticalAlign: VerticalAlign.TOP
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: `${activity.actividades_certificado ? '■' : '❏' } Si ${activity.actividades_no_certificado ? '■' : '❏' } No`,
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                })
                            ],
                            verticalAlign: VerticalAlign.TOP
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: activity.activades_ubicacion,
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                })
                            ],
                            verticalAlign: VerticalAlign.TOP
                        })
                    ]
                })
            )
        })

        actividades.transporte.forEach(activity => {
            table.addChildElement(
                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: `${activity.actividades_tipo === 'Transporte' ? '■' : '❏'} Transporte`,
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                })
                            ],
                            verticalAlign: VerticalAlign.TOP
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: `${activity.actividades_interno_externo === "Interno" ? '■' : '❏' } Int.  ${activity.actividades_interno_externo === "Externo" ? '■' : '❏' } Ext.`,
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                })
                            ],
                            verticalAlign: VerticalAlign.TOP
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: activity.activades_operador_que_desarrolla,
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                })
                            ],
                            verticalAlign: VerticalAlign.TOP
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: `${activity.actividades_certificado ? '■' : '❏' } Si ${activity.actividades_no_certificado ? '■' : '❏' } No`,
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                })
                            ],
                            verticalAlign: VerticalAlign.TOP
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: activity.activades_ubicacion,
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                })
                            ],
                            verticalAlign: VerticalAlign.TOP
                        })
                    ]
                })
            )
        })

        actividades.lavado.forEach(activity => {
            table.addChildElement(
                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: `${activity.actividades_tipo === 'Lavado/Sanitizado' ? '■' : '❏'} Lavado/Sanitizado`,
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                })
                            ],
                            verticalAlign: VerticalAlign.TOP
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: `${activity.actividades_interno_externo === "Interno" ? '■' : '❏' } Int.  ${activity.actividades_interno_externo === "Externo" ? '■' : '❏' } Ext.`,
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                })
                            ],
                            verticalAlign: VerticalAlign.TOP
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: activity.activades_operador_que_desarrolla,
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                })
                            ],
                            verticalAlign: VerticalAlign.TOP
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: `${activity.actividades_certificado ? '■' : '❏' } Si ${activity.actividades_no_certificado ? '■' : '❏' } No`,
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                })
                            ],
                            verticalAlign: VerticalAlign.TOP
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: activity.activades_ubicacion,
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                })
                            ],
                            verticalAlign: VerticalAlign.TOP
                        })
                    ]
                })
            )
        })

        actividades.seleccion.forEach(activity => {
            table.addChildElement(
                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: `${activity.actividades_tipo === 'Selección' ? '■' : '❏'} Selección`,
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                })
                            ],
                            verticalAlign: VerticalAlign.TOP
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: `${activity.actividades_interno_externo === "Interno" ? '■' : '❏' } Int.  ${activity.actividades_interno_externo === "Externo" ? '■' : '❏' } Ext.`,
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                })
                            ],
                            verticalAlign: VerticalAlign.TOP
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: activity.activades_operador_que_desarrolla,
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                })
                            ],
                            verticalAlign: VerticalAlign.TOP
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: `${activity.actividades_certificado ? '■' : '❏' } Si ${activity.actividades_no_certificado ? '■' : '❏' } No`,
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                })
                            ],
                            verticalAlign: VerticalAlign.TOP
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: activity.activades_ubicacion,
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                })
                            ],
                            verticalAlign: VerticalAlign.TOP
                        })
                    ]
                })
            )
        })

        actividades.envasado.forEach(activity => {
            table.addChildElement(
                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: `${activity.actividades_tipo === 'Envasado' ? '■' : '❏'} Envasado`,
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                })
                            ],
                            verticalAlign: VerticalAlign.TOP
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: `${activity.actividades_interno_externo === "Interno" ? '■' : '❏' } Int.  ${activity.actividades_interno_externo === "Externo" ? '■' : '❏' } Ext.`,
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                })
                            ],
                            verticalAlign: VerticalAlign.TOP
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: activity.activades_operador_que_desarrolla,
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                })
                            ],
                            verticalAlign: VerticalAlign.TOP
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: `${activity.actividades_certificado ? '■' : '❏' } Si ${activity.actividades_no_certificado ? '■' : '❏' } No`,
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                })
                            ],
                            verticalAlign: VerticalAlign.TOP
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: activity.activades_ubicacion,
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                })
                            ],
                            verticalAlign: VerticalAlign.TOP
                        })
                    ]
                })
            )
        })

        actividades.empaque.forEach(activity => {
            table.addChildElement(
                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: `${activity.actividades_tipo === 'Empaque' ? '■' : '❏'} Empaque`,
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                })
                            ],
                            verticalAlign: VerticalAlign.TOP
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: `${activity.actividades_interno_externo === "Interno" ? '■' : '❏' } Int.  ${activity.actividades_interno_externo === "Externo" ? '■' : '❏' } Ext.`,
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                })
                            ],
                            verticalAlign: VerticalAlign.TOP
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: activity.activades_operador_que_desarrolla,
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                })
                            ],
                            verticalAlign: VerticalAlign.TOP
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: `${activity.actividades_certificado ? '■' : '❏' } Si ${activity.actividades_no_certificado ? '■' : '❏' } No`,
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                })
                            ],
                            verticalAlign: VerticalAlign.TOP
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: activity.activades_ubicacion,
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                })
                            ],
                            verticalAlign: VerticalAlign.TOP
                        })
                    ]
                })
            )
        })

        actividades.almacenamiento.forEach(activity => {
            table.addChildElement(
                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: `${activity.actividades_tipo === 'Almacenamiento' ? '■' : '❏'} Almacenamiento`,
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                })
                            ],
                            verticalAlign: VerticalAlign.TOP
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: `${activity.actividades_interno_externo === "Interno" ? '■' : '❏' } Int.  ${activity.actividades_interno_externo === "Externo" ? '■' : '❏' } Ext.`,
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                })
                            ],
                            verticalAlign: VerticalAlign.TOP
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: activity.activades_operador_que_desarrolla,
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                })
                            ],
                            verticalAlign: VerticalAlign.TOP
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: `${activity.actividades_certificado ? '■' : '❏' } Si ${activity.actividades_no_certificado ? '■' : '❏' } No`,
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                })
                            ],
                            verticalAlign: VerticalAlign.TOP
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: activity.activades_ubicacion,
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                })
                            ],
                            verticalAlign: VerticalAlign.TOP
                        })
                    ]
                })
            )
        })

        actividades.refrigerado.forEach(activity => {
            table.addChildElement(
                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: `${activity.actividades_tipo === 'Refrigerado / Congelado' ? '■' : '❏'} Refrigerado / Congelado`,
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                })
                            ],
                            verticalAlign: VerticalAlign.TOP
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: `${activity.actividades_interno_externo === "Interno" ? '■' : '❏' } Int.  ${activity.actividades_interno_externo === "Externo" ? '■' : '❏' } Ext.`,
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                })
                            ],
                            verticalAlign: VerticalAlign.TOP
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: activity.activades_operador_que_desarrolla,
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                })
                            ],
                            verticalAlign: VerticalAlign.TOP
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: `${activity.actividades_certificado ? '■' : '❏' } Si ${activity.actividades_no_certificado ? '■' : '❏' } No`,
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                })
                            ],
                            verticalAlign: VerticalAlign.TOP
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: activity.activades_ubicacion,
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                })
                            ],
                            verticalAlign: VerticalAlign.TOP
                        })
                    ]
                })
            )
        })

        actividades.deshidratado.forEach(activity => {
            table.addChildElement(
                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: `${activity.actividades_tipo === 'Deshidratado' ? '■' : '❏'} Deshidratado`,
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                })
                            ],
                            verticalAlign: VerticalAlign.TOP
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: `${activity.actividades_interno_externo === "Interno" ? '■' : '❏' } Int.  ${activity.actividades_interno_externo === "Externo" ? '■' : '❏' } Ext.`,
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                })
                            ],
                            verticalAlign: VerticalAlign.TOP
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: activity.activades_operador_que_desarrolla,
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                })
                            ],
                            verticalAlign: VerticalAlign.TOP
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: `${activity.actividades_certificado ? '■' : '❏' } Si ${activity.actividades_no_certificado ? '■' : '❏' } No`,
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                })
                            ],
                            verticalAlign: VerticalAlign.TOP
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: activity.activades_ubicacion,
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                })
                            ],
                            verticalAlign: VerticalAlign.TOP
                        })
                    ]
                })
            )
        })

        actividades.mezclado.forEach(activity => {
            table.addChildElement(
                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: `${activity.actividades_tipo === 'Mezclado' ? '■' : '❏'} Mezclado`,
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                })
                            ],
                            verticalAlign: VerticalAlign.TOP
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: `${activity.actividades_interno_externo === "Interno" ? '■' : '❏' } Int.  ${activity.actividades_interno_externo === "Externo" ? '■' : '❏' } Ext.`,
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                })
                            ],
                            verticalAlign: VerticalAlign.TOP
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: activity.activades_operador_que_desarrolla,
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                })
                            ],
                            verticalAlign: VerticalAlign.TOP
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: `${activity.actividades_certificado ? '■' : '❏' } Si ${activity.actividades_no_certificado ? '■' : '❏' } No`,
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                })
                            ],
                            verticalAlign: VerticalAlign.TOP
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: activity.activades_ubicacion,
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                })
                            ],
                            verticalAlign: VerticalAlign.TOP
                        })
                    ]
                })
            )
        })

        actividades.pasteurizacion.forEach(activity => {
            table.addChildElement(
                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: `${activity.actividades_tipo === 'Pasteurización' ? '■' : '❏'} Pasteurización`,
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                })
                            ],
                            verticalAlign: VerticalAlign.TOP
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: `${activity.actividades_interno_externo === "Interno" ? '■' : '❏' } Int.  ${activity.actividades_interno_externo === "Externo" ? '■' : '❏' } Ext.`,
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                })
                            ],
                            verticalAlign: VerticalAlign.TOP
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: activity.activades_operador_que_desarrolla,
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                })
                            ],
                            verticalAlign: VerticalAlign.TOP
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: `${activity.actividades_certificado ? '■' : '❏' } Si ${activity.actividades_no_certificado ? '■' : '❏' } No`,
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                })
                            ],
                            verticalAlign: VerticalAlign.TOP
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: activity.activades_ubicacion,
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                })
                            ],
                            verticalAlign: VerticalAlign.TOP
                        })
                    ]
                })
            )
        })

        actividades.liofilizado.forEach(activity => {
            table.addChildElement(
                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: `${activity.actividades_tipo === 'Liofilizado' ? '■' : '❏'} Liofilizado`,
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                })
                            ],
                            verticalAlign: VerticalAlign.TOP
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: `${activity.actividades_interno_externo === "Interno" ? '■' : '❏' } Int.  ${activity.actividades_interno_externo === "Externo" ? '■' : '❏' } Ext.`,
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                })
                            ],
                            verticalAlign: VerticalAlign.TOP
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: activity.activades_operador_que_desarrolla,
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                })
                            ],
                            verticalAlign: VerticalAlign.TOP
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: `${activity.actividades_certificado ? '■' : '❏' } Si ${activity.actividades_no_certificado ? '■' : '❏' } No`,
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                })
                            ],
                            verticalAlign: VerticalAlign.TOP
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: activity.activades_ubicacion,
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                })
                            ],
                            verticalAlign: VerticalAlign.TOP
                        })
                    ]
                })
            )
        })

        actividades.sacrificio.forEach(activity => {
            table.addChildElement(
                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: `${activity.actividades_tipo === 'Sacrificio' ? '■' : '❏'} Sacrificio`,
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                })
                            ],
                            verticalAlign: VerticalAlign.TOP
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: `${activity.actividades_interno_externo === "Interno" ? '■' : '❏' } Int.  ${activity.actividades_interno_externo === "Externo" ? '■' : '❏' } Ext.`,
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                })
                            ],
                            verticalAlign: VerticalAlign.TOP
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: activity.activades_operador_que_desarrolla,
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                })
                            ],
                            verticalAlign: VerticalAlign.TOP
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: `${activity.actividades_certificado ? '■' : '❏' } Si ${activity.actividades_no_certificado ? '■' : '❏' } No`,
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                })
                            ],
                            verticalAlign: VerticalAlign.TOP
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: activity.activades_ubicacion,
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                })
                            ],
                            verticalAlign: VerticalAlign.TOP
                        })
                    ]
                })
            )
        })

        actividades.comercializacion.forEach(activity => {
            table.addChildElement(
                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: `${activity.actividades_tipo === 'Comercialización' ? '■' : '❏'} Comercialización`,
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                })
                            ],
                            verticalAlign: VerticalAlign.TOP
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: `${activity.actividades_interno_externo === "Interno" ? '■' : '❏' } Int.  ${activity.actividades_interno_externo === "Externo" ? '■' : '❏' } Ext.`,
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                })
                            ],
                            verticalAlign: VerticalAlign.TOP
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: activity.activades_operador_que_desarrolla,
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                })
                            ],
                            verticalAlign: VerticalAlign.TOP
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: `${activity.actividades_certificado ? '■' : '❏' } Si ${activity.actividades_no_certificado ? '■' : '❏' } No`,
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                })
                            ],
                            verticalAlign: VerticalAlign.TOP
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: activity.activades_ubicacion,
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                })
                            ],
                            verticalAlign: VerticalAlign.TOP
                        })
                    ]
                })
            )
        })

        actividades.otro.forEach(activity => {
            table.addChildElement(
                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: `${activity.actividades_tipo === 'Otro' ? '■' : '❏'} Otro: ${activity.actividades_tipo_otro}`,
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                })
                            ],
                            verticalAlign: VerticalAlign.TOP
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: `${activity.actividades_interno_externo === "Interno" ? '■' : '❏' } Int.  ${activity.actividades_interno_externo === "Externo" ? '■' : '❏' } Ext.`,
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                })
                            ],
                            verticalAlign: VerticalAlign.TOP
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: activity.activades_operador_que_desarrolla,
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                })
                            ],
                            verticalAlign: VerticalAlign.TOP
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: `${activity.actividades_certificado ? '■' : '❏' } Si ${activity.actividades_no_certificado ? '■' : '❏' } No`,
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                })
                            ],
                            verticalAlign: VerticalAlign.TOP
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: activity.activades_ubicacion,
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                })
                            ],
                            verticalAlign: VerticalAlign.TOP
                        })
                    ]
                })
            )
        })

        return table
    }

    public createExceptions(submission): Table {
        const exceptions = submission[3]

        const table = new Table({
            rows: [
                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: 'III.	Excepciones.',
                                            font: 'Arial',
                                            size: 20,
                                            bold: true
                                        })
                                    ],
                                    spacing: {
                                        after: 75
                                    }
                                })
                            ],
                            verticalAlign: VerticalAlign.CENTER,
                            columnSpan: 3,
                            shading: {
                                fill: '#C0C0C0'
                            }
                        })
                    ]
                }),
                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: 'Por favor indique si solicita el reconocimiento de alguna de las siguientes excepciones a la norma:',
                                            font: 'Arial',
                                            size: 20,
                                        })
                                    ],
                                    spacing: {
                                        after: 75
                                    }
                                })
                            ],
                            verticalAlign: VerticalAlign.CENTER,
                            columnSpan: 3
                        })
                    ]
                }),
                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: `${this.getValue(exceptions, "excepcion_fsol_10") ? '■' : '❏'}`,
                                            font: 'Arial',
                                            size: 20,
                                        })
                                    ],
                                    spacing: {
                                        after: 75
                                    }
                                })
                            ],
                            verticalAlign: VerticalAlign.CENTER,
                            width: {
                                size: 20,
                                type: WidthType.PERCENTAGE
                            }
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: 'Solicitud de reconocimiento retroactivo.',
                                            font: 'Arial',
                                            size: 20,
                                        })
                                    ],
                                    spacing: {
                                        after: 75
                                    }
                                })
                            ],
                            verticalAlign: VerticalAlign.CENTER
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: 'Llenar formato MTO-FSOL-10',
                                            font: 'Arial',
                                            size: 20,
                                        })
                                    ],
                                    spacing: {
                                        after: 75
                                    }
                                })
                            ],
                            verticalAlign: VerticalAlign.CENTER
                        })
                    ]
                }),
                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: `${this.getValue(exceptions, "excepcion_fsol_11") ? '■' : '❏'}`,
                                            font: 'Arial',
                                            size: 20,
                                        })
                                    ],
                                    spacing: {
                                        after: 75
                                    }
                                })
                            ],
                            verticalAlign: VerticalAlign.CENTER,
                            width: {
                                size: 20,
                                type: WidthType.PERCENTAGE
                            }
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: 'Solicitud de uso de semillas no orgánicas.',
                                            font: 'Arial',
                                            size: 20,
                                        })
                                    ],
                                    spacing: {
                                        after: 75
                                    }
                                })
                            ],
                            verticalAlign: VerticalAlign.CENTER
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: 'Llenar formato MTO-FSOL-11',
                                            font: 'Arial',
                                            size: 20,
                                        })
                                    ],
                                    spacing: {
                                        after: 75
                                    }
                                })
                            ],
                            verticalAlign: VerticalAlign.CENTER
                        })
                    ]
                }),
                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: `${this.getValue(exceptions, "excepcion_fsol_12") ? '■' : '❏'}`,
                                            font: 'Arial',
                                            size: 20,
                                        })
                                    ],
                                    spacing: {
                                        after: 75
                                    }
                                })
                            ],
                            verticalAlign: VerticalAlign.CENTER,
                            width: {
                                size: 20,
                                type: WidthType.PERCENTAGE
                            }
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: 'Solicitud de periodo para adaptación de instalaciones.',
                                            font: 'Arial',
                                            size: 20,
                                        })
                                    ],
                                    spacing: {
                                        after: 75
                                    }
                                })
                            ],
                            verticalAlign: VerticalAlign.CENTER
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: 'Llenar formato MTO-FSOL-12',
                                            font: 'Arial',
                                            size: 20,
                                        })
                                    ],
                                    spacing: {
                                        after: 75
                                    }
                                })
                            ],
                            verticalAlign: VerticalAlign.CENTER
                        })
                    ]
                }),
                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: `${this.getValue(exceptions, "excepcion_fsol_13") ? '■' : '❏'}`,
                                            font: 'Arial',
                                            size: 20,
                                        })
                                    ],
                                    spacing: {
                                        after: 75
                                    }
                                })
                            ],
                            verticalAlign: VerticalAlign.CENTER,
                            width: {
                                size: 20,
                                type: WidthType.PERCENTAGE
                            }
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: 'Solicitud de uso de alimento no orgánico.',
                                            font: 'Arial',
                                            size: 20,
                                        })
                                    ],
                                    spacing: {
                                        after: 75
                                    }
                                })
                            ],
                            verticalAlign: VerticalAlign.CENTER
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: 'Llenar formato MTO-FSOL-13',
                                            font: 'Arial',
                                            size: 20,
                                        })
                                    ],
                                    spacing: {
                                        after: 75
                                    }
                                })
                            ],
                            verticalAlign: VerticalAlign.CENTER
                        })
                    ]
                }),
                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: `${this.getValue(exceptions, "excepcion_fsol_14") ? '■' : '❏'}`,
                                            font: 'Arial',
                                            size: 20,
                                        })
                                    ],
                                    spacing: {
                                        after: 75
                                    }
                                })
                            ],
                            verticalAlign: VerticalAlign.CENTER,
                            width: {
                                size: 20,
                                type: WidthType.PERCENTAGE
                            }
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: 'Solicitud de excepción para producción paralela.',
                                            font: 'Arial',
                                            size: 20,
                                        })
                                    ],
                                    spacing: {
                                        after: 75
                                    }
                                })
                            ],
                            verticalAlign: VerticalAlign.CENTER
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: 'Llenar formato MTO-FSOL-14',
                                            font: 'Arial',
                                            size: 20,
                                        })
                                    ],
                                    spacing: {
                                        after: 75
                                    }
                                })
                            ],
                            verticalAlign: VerticalAlign.CENTER
                        })
                    ]
                }),
                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: `${this.getValue(exceptions, "excepcion_fsol_21") ? '■' : '❏'}`,
                                            font: 'Arial',
                                            size: 20,
                                        })
                                    ],
                                    spacing: {
                                        after: 75
                                    }
                                })
                            ],
                            verticalAlign: VerticalAlign.CENTER,
                            width: {
                                size: 20,
                                type: WidthType.PERCENTAGE
                            }
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: 'Solicitud de reconstitución de colmenas.',
                                            font: 'Arial',
                                            size: 20,
                                        })
                                    ],
                                    spacing: {
                                        after: 75
                                    }
                                })
                            ],
                            verticalAlign: VerticalAlign.CENTER
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: 'Llenar formato MTO-FSOL-21',
                                            font: 'Arial',
                                            size: 20,
                                        })
                                    ],
                                    spacing: {
                                        after: 75
                                    }
                                })
                            ],
                            verticalAlign: VerticalAlign.CENTER
                        })
                    ]
                }),
                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: `${this.getValue(exceptions, "excepcion_fsol_24") ? '■' : '❏'}`,
                                            font: 'Arial',
                                            size: 20,
                                        })
                                    ],
                                    spacing: {
                                        after: 75
                                    }
                                })
                            ],
                            verticalAlign: VerticalAlign.CENTER,
                            width: {
                                size: 20,
                                type: WidthType.PERCENTAGE
                            }
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: 'Notificación General de Procesamiento Paralelo.',
                                            font: 'Arial',
                                            size: 20,
                                        })
                                    ],
                                    spacing: {
                                        after: 75
                                    }
                                })
                            ],
                            verticalAlign: VerticalAlign.CENTER
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: 'Llenar formato MTO-FSOL-24',
                                            font: 'Arial',
                                            size: 20,
                                        })
                                    ],
                                    spacing: {
                                        after: 75
                                    }
                                })
                            ],
                            verticalAlign: VerticalAlign.CENTER
                        })
                    ]
                }),
            ],
            margins: {
                left: 70,
                right: 70
            },
            columnWidths: [600, 4519, 4519]
        })

        return table
    }

    public getCambioOdeCValue(values, name) {
        for (var i in values) {
            const value = values[i]
            if (value.name === name) {
                return value.value
            }
        }
        return '_________________________________'
    }

    public getBase64FromUrl = async (url) => {
        const data = await fetch(url, {
            headers: {
                'Cache-Control': 'no-cache, no-store, must-revalidate',
                'Pragma': 'no-cache',
                'Expires': 'Sat, 01 Jan 2000 00:00:00 GMT',
                'If-Modified-Since': '0'
            }
        });
        const blob = await data.blob();
        return new Promise((resolve) => {
            const reader = new FileReader();
            reader.readAsDataURL(blob);
            reader.onloadend = function() {
                const base64data = reader.result;
                resolve(base64data);
            }
        });
    }

    getValue(submission, name) {
        var value = null
        for (const i in submission) {
            const element = submission[i]
            if (element.name === name) {
                value = element.value
            }
        }
        if (value) return value
        else return ''
    }

    getValueList(submission, name) {
        var value = null
        for (const i in submission) {
            const element = submission[i]
            if (element.name === name) {
                value = element.value
            }
        }
        if (value) return value
        else return []
    }

    // END MTO-FSOL-01
}