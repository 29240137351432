import { readFileSync } from 'fs';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '@environments/environment';

@Injectable({ providedIn: 'root' })
export class QuotesService {

    constructor(private http: HttpClient) { }

    getNumberToText(number) {
        return this.http.get<any>(`https://nal.azurewebsites.net/api/Nal?num=${number}`)
    }

    getUrlStatus(url: string) {
        return this.http.get(url);
    }
}