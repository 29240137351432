<div class="modal-header">
  <h4 class="modal-title" id="modal-title">Motivo de la denegación por Operador/Inspector</h4>
  <button
    type="button"
    class="close"
    aria-label="Close button"
    aria-describedby="modal-title"
    (click)="modal.dismiss('Cross click')"
  >
    <span aria-hidden="true">&times;</span>
  </button>
</div>
  
<div class="modal-body">
  <p>
    {{ comments }}
  </p>
</div>
<div class="modal-footer">
  <button
    type="button"
    class="btn btn-outline-secondary"
    (click)="modal.dismiss('cancel click')"
  >
    Cerrar
  </button>
</div>
  