import { ControlCommunicationService } from './../../../services/control-communication.service';
import { Component, OnInit, Input } from '@angular/core';
import { Observable, of } from 'rxjs';
import { catchError, debounceTime, distinctUntilChanged, tap, switchMap } from 'rxjs/operators';
import { SubstancesService } from '../../../../core/services/substances.services'

import { FormBuilderComponent } from './../../../form-builder.component';
import { UnknownParams, ControlElement } from './../../../interfaces/form.interface';
import { FormGroup, FormControl, FormArray, FormBuilder } from '@angular/forms';

@Component({
    selector: 'search-element',
    templateUrl: './search.component.html'
  })
  export class SearchComponent implements OnInit {
    model: any;
    searching = false;
    searchFailed = false;

    attributes: UnknownParams = {}
    validations: UnknownParams = {}

    @Input() data: ControlElement

    @Input() form: FormGroup
  
    constructor(
        private substancesService: SubstancesService,
        private parentControl: FormBuilderComponent,
        private communication: ControlCommunicationService
    ) {}

    ngOnInit() {
        //console.log(this.data)
        //console.log(this.form)

        if (!this.form) {this.form = this.parentControl.formGroup}
        for (var i in this.data.attributes) {
            const attribute = this.data.attributes[i]
            this.attributes[attribute.name] = attribute.value
        }
        for (var i in this.data.validations) {
            const validation = this.data.validations[i]
            this.validations[validation.name] = validation.value
        }
    }
  
    search = (text$: Observable<string>) =>
        text$.pipe(
            debounceTime(300),
            distinctUntilChanged(),
            tap(() => this.searching = true),
            switchMap(term =>
            this.substancesService.searchSubstances(term).pipe(
                tap(() => this.searchFailed = false),
                catchError(() => {
                this.searchFailed = true;
                return of([]);
                }))
            ),
            tap(() => this.searching = false)
        )

    formatter = ( substance: string ) => 
        //console.log(substance['nombre_comercial']) 
        substance['nombre_comercial']

    loadSubstance(event) {
        //console.log(event)
        //console.log(this.form)

        this.form.patchValue({
            nombre_comercial: event.item["nombre_comercial"],
            //cultivo_area_aplicacion: event.item["cultivo_area_aplicacion"],
            ficha_tecnica_anexos_urls: event.item["ficha_tecnica_anexos_urls"],
            ingredientes_activos: event.item["ingredientes_activos"],
            ingredientes_inertes: event.item["ingredientes_inertes"],
            isValidated: event.item["isValidated"],
            listado_omri_canada: event.item["listado_omri_canada"],
            listado_omri_nop: event.item["listado_omri_nop"],
            listado_otro: event.item["listado_otro"],
            listado_otro_texto: event.item["listado_otro_texto"],
            nombre_proveedor: event.item["nombre_proveedor"],
            //uso_abono_fungicida_etc: event.item["uso_abono_fungicida_etc"]
        })


        setTimeout(() => {
            this.communication.emitEvent({
                identifier: 'set-values',
                form: this.form
            })
        }, 100);
    }
  }