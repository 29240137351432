<div class="modal-header">
  <h5 class="modal-title text-center" id="modal-title">{{ name }}</h5>
  <button type="button" class="close" aria-label="Close button" aria-describedby="modal-title"
    (click)="modal.dismiss('Cross click')">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
  <!--<h5 class="text-center">{{ title }}</h5>-->
  <div class="row justify-content-center" style="margin-top: 100px" *ngIf="docloading">
    <div class="d-flex align-items-center">
      <div class="spinner-border ml-auto mr-2" role="status" aria-hidden="true"></div>
      <strong>Generando documento...</strong>
    </div>
  </div>

  <!-- <div class="row justify-content-center" style="margin-top: 100px; width: 100%; position: fixed; text-align: center; z-index: -1;" *ngIf="!docloading">
    <div class="d-flex align-items-center">
      <div class="spinner-border ml-auto mr-2" role="status" aria-hidden="true"></div>
      <strong>Cargando documento...</strong>
    </div>
  </div> -->

  <!-- <iframe [src]="url" *ngIf="showDoc" width="100%" style="border: none;height: 75vh;">
  </iframe> -->
  <ngx-doc-viewer [url]="url" viewer="pdf" *ngIf="showDoc" width="100%" style="border: none;height: 75vh;">
  </ngx-doc-viewer>

  <!-- <ngx-extended-pdf-viewer
    *ngIf="showDoc && !docloading"
    [src]="url"
    [showToolbar]="true"
    [showSidebarButton]="true"
    [showFindButton]="true"
    [showPagingButtons]="true"
    [showZoomButtons]="true"
    [showPresentationModeButton]="false"
    [showOpenFileButton]="false"
    [showPrintButton]="true"
    [showDownloadButton]="true"
    [showBookmarkButton]="false"
    [showSecondaryToolbarButton]="true"
    [showRotateButton]="false"
    [showHandToolButton]="true"
    [showScrollingButton]="false"
    [showSpreadButton]="false"
    [showPropertiesButton]="false"
    [useBrowserLocale]="true" 
    [contextMenuAllowed]="false"
    theme="dark"
    zoom="page-width"
  ></ngx-extended-pdf-viewer> -->

  <form-builder *ngIf="showForm" [formData]="response.form" style="max-height: 90vh;"></form-builder>
  <div class="mt-1" *ngIf="attachments.length > 0">
    <h4>Anexos</h4>
    <div style="margin-bottom: 10px;" *ngFor="let url of attachments; let i = index">
      <a class="bg-info text-light" style="padding: 5px 20px 5px 20px; border-radius: 5px;" target="_blank"
        [href]="url"><i style="margin-right: 10px;" class="fas fa-file"></i>{{getFilename(url)}}</a>
    </div>
  </div>
  <form *ngIf="(showReview && !toSave) || showEval" [formGroup]="form" (ngSubmit)="onSubmit()">
    <div *ngIf="!docloading">
      <p *ngIf="isEvaluation" class="text-muted mt-3">
        <small><i>Observaciones o Comentarios.</i></small>
      </p>
      <div *ngIf="isEvaluation" class="form-group">
        <textarea class="form-control mt-2" formControlName="comments" rows="3" style="resize: none"></textarea>
      </div>
      <div *ngIf="form.get('comments').value || showReview">
        <p class="text-muted mt-3">
          <small><i>Para continuar confirme su contraseña.</i></small>
        </p>
        <div class="form-group">
          <input type="password" formControlName="password" class="form-control" [ngClass]="{
                  'is-invalid': (submitted && f.password.errors) || forbiddenId
                }" />
          <div *ngIf="submitted && f.password.errors" class="invalid-feedback">
            <div *ngIf="f.password.errors.required">Contraseña requerida</div>
          </div>
          <div *ngIf="forbiddenId" class="invalid-feedback">
            <div>Contraseña incorrecta</div>
          </div>
        </div>
      </div>
    </div>
  </form>
</div>
<div *ngIf="!showEval && !isOrderInspection" class="modal-footer">
  <button type="button" class="btn btn-outline-secondary" (click)="modal.dismiss('cancel click')">
    {{showReview ? 'Cancelar' : 'Cerrar'}}
  </button>
  <button *ngIf="showReview" [disabled]="docloading" type="button" ngbAutofocus class="btn btn-success"
    (click)="onSubmit()">
    <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>
    {{toSave ? 'Guardar' : 'Aprobar'}}
  </button>
</div>
<div *ngIf="showEval && !isOrderInspection" class="modal-footer">
  <button type="button" class="btn btn-outline-secondary" (click)="modal.dismiss('cancel click')">
    {{'Cerrar'}}
  </button>
  <button [disabled]="docloading || !form.get('comments').value" type="button" ngbAutofocus class="btn btn-success" (click)="onSubmit()">
    <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>
    Guardar
  </button>
</div>
<div *ngIf="isOrderInspection" class="modal-footer">
  <form [formGroup]="formOrder" style="width: 100%;" *ngIf="!docloading && !isAcceptedOrder">
    <div class="modal-body">
      <p class="text-muted">
        <small><i>Para continuar indique el motivo de la denegación (Si aplica) y confirme su contraseña.</i></small>
      </p>
  
      <div class="form-group">
        <label for="comment">Motivo de la denegación</label>
        <textarea
          type="textarea"
          formControlName="comments"
          class="form-control"
          rows="2"
          [ngClass]="{
            'is-invalid': (submitted && fOrder.comments.errors) || forbiddenId
          }"
        ></textarea>
        <div *ngIf="submitted && fOrder.comments.errors" class="invalid-feedback">
          <div *ngIf="fOrder.comments.errors.required">
            Razón de denegación requerida
          </div>
        </div>

        <label for="password">Contraseña</label>
        <input
          type="password"
          formControlName="password"
          class="form-control"
          [ngClass]="{
            'is-invalid': (submitted && fOrder.password.errors) || forbiddenId
          }"
        />
        <div *ngIf="submitted && fOrder.password.errors" class="invalid-feedback">
          <div *ngIf="fOrder.password.errors.required">
            Contraseña requerida
          </div>
        </div>
        <div *ngIf="forbiddenId" class="invalid-feedback">
          <div>
            Contraseña incorrecta
          </div>
        </div>
      </div>
    </div>
    <div class="modal-footer">
      <button
        type="button"
        class="btn btn-success"
        (click)="approve()"
      >
        <span
          *ngIf="loading"
          class="spinner-border spinner-border-sm mr-1"
        ></span>
        Aceptar
      </button>
      <button
        [disabled]="loading"
        type="button"
        class="btn btn-danger"
        (click)="deny()"
      >
        <span
          *ngIf="loading"
          class="spinner-border spinner-border-sm mr-1"
        ></span>
        Denegar
      </button>
    </div>
  </form>

  <button type="button" class="btn btn-outline-secondary" (click)="modal.dismiss('cancel click')">
    {{'Cerrar'}}
  </button>
</div>