<div class="row" *ngIf="content">
    <div class="col-lg-12">
        <containers-fabric [content]="content[0]" [formGroup]="form"></containers-fabric>
    </div>
    <div class="col-lg-6">
        <containers-fabric [content]="content[1]" [formGroup]="form" ></containers-fabric>
    </div>
    <div class="col-lg-6">
        <containers-fabric [content]="content[2]" [formGroup]="form" ></containers-fabric>
    </div>
    <div class="col-lg-12">
        <containers-fabric [content]="content[3]" [formGroup]="form" ></containers-fabric>
    </div>
</div>