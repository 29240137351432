import { Component, Input } from '@angular/core';
import { signDictator } from '../../../../features/generator/signDictator'

@Component({
    selector: 'contract-mexico-v3',
    templateUrl: 'mexico.component.html',
    styleUrls: ['../../../../app.component.scss']
})
export class ContractMexico {
    @Input() public name = ''
    @Input() public date
    @Input() public customer
    @Input() public metrocert
    @Input() public signOperator = ''

    signDictator = signDictator

    constructor() {}
}

@Component({
    selector: 'contract-canada-v3',
    templateUrl: 'canada.component.html',
    styleUrls: ['../../../../app.component.scss']
})
export class ContractCanada {
    @Input() public name = ''
    @Input() public date
    @Input() public customer
    @Input() public metrocert
    @Input() public signOperator = ''

    signDictator = signDictator

    constructor() {}
}

@Component({
    selector: 'contract-europe-v3',
    templateUrl: 'europe.component.html',
    styleUrls: ['../../../../app.component.scss']
})
export class ContractEurope {
    @Input() public name = ''
    @Input() public date
    @Input() public customer
    @Input() public metrocert
    @Input() public signOperator = ''

    signDictator = signDictator

    constructor() {}
}