import { AlignmentType, Document, Paragraph, TextRun, Media, Header, Footer, Table, TableRow, TableCell, WidthType, PageNumber, BorderStyle } from "docx";
import { logo } from "../logo";
import { userWithoutSign } from "../userWithoutSign"
import { DatePipe } from '@angular/common';

//Moment import
const moment = require('moment')
moment.locale('es-mx')

export class DocumentCreatorMTO_FINS_06_v3 {
    // INIT MTO_FINS_06

    public getBase64FromUrl = async (url) => {
        const data = await fetch(url, {
            headers: {
                'Cache-Control': 'no-cache, no-store, must-revalidate',
                'Pragma': 'no-cache',
                'Expires': 'Sat, 01 Jan 2000 00:00:00 GMT',
                'If-Modified-Since': '0'
            }
        });
        const blob = await data.blob();
        return new Promise((resolve) => {
            const reader = new FileReader();
            reader.readAsDataURL(blob); 
            reader.onloadend = function() {
                const base64data = reader.result;   
                resolve(base64data);
            }
        });
    }

    public async create_MTO_FINS_06(data, signature = '', signature2 = ''): Promise<Document> {
        const document = new Document();
        const image = Media.addImage(document, logo, 50, 50);
        var datePipe = new DatePipe('es-MX');
        let signatureImage = null
        if (signature) {
            signatureImage = await this.getBase64FromUrl(signature)
        } else {
            signatureImage = userWithoutSign
        }
        let signatureImage2 = null
        if (signature2) {
            signatureImage2 = await this.getBase64FromUrl(signature2)
        } else {
            signatureImage2 = userWithoutSign
        }
        const listaMuestras = this.getValueList(data.submission, "lista-muestras")
        listaMuestras.forEach(muestra => {
            document.addSection({
                headers: {
                    default: new Header({
                        children: [
                            new Paragraph({ alignment: AlignmentType.CENTER, children: [image], }),
                            new Paragraph({
                                alignment: AlignmentType.CENTER, children: [
                                    new TextRun({
                                        children: [`MTO-FINS-06 | R 04 | 24-Julio-2024 | Pág. `, PageNumber.CURRENT],
                                        font: 'Book Antiqua',
                                        size: 20,
                                        bold: true
                                    }),
                                    new TextRun({
                                        font: 'Book Antiqua',
                                        size: 20,
                                        bold: true,
                                        children: [' de ', PageNumber.TOTAL_PAGES]
                                    }),
                                ], spacing: { before: 200, after: 100 }
                            })
                        ]
                    })
                },
                footers: {
                    default: new Footer({
                        children: [
                            new Paragraph({ alignment: AlignmentType.CENTER }),
                            this.createFooter()
                        ]
                    }),
                },
                children: [
                    new Paragraph({
                        alignment: AlignmentType.CENTER,
                        children: [
                            new TextRun({
                                text: 'INFORME DE TOMA DE MUESTRAS',
                                font: 'Calibri (Cuerpo)',
                                size: 22,
                                bold: true
                            })
                        ],
                        spacing: { before: 200, after: 200 }
                    }),
                    new Paragraph({
                        alignment: AlignmentType.JUSTIFIED,
                        children: [
                            new TextRun({
                                text: `El que suscribe ${data.inspector} en calidad de Inspector calificado de `,
                                font: 'Calibri (Cuerpo)',
                                size: 18
                            }),
                            new TextRun({
                                text: `METROCERT`,
                                font: 'Calibri (Cuerpo)',
                                size: 18,
                                bold: true
                            }),
                            new TextRun({
                                text: `, con fecha ${moment(data.inspectionDate).format('DD-MMMM-YYYY')} en el cumplimiento de verificar la conformidad de las unidades de producción de acuerdo a las regulaciones orgánicas `,
                                font: 'Calibri (Cuerpo)',
                                size: 18
                            }),
                            new TextRun({
                                text: `LPO, COR/USCOEA, UE, NOP`,
                                font: 'Calibri (Cuerpo)',
                                size: 18,
                                bold: true
                            }),
                            new TextRun({
                                text: ` y sucesivas modificaciones`,
                                font: 'Calibri (Cuerpo)',
                                size: 18
                            })
                        ],
                        spacing: { before: 200, after: 200 }
                    }),
                    this.getTableOperator(data),
                    new Paragraph({
                        alignment: AlignmentType.JUSTIFIED,
                        children: [
                            new TextRun({
                                text: `He realizado un muestreo de: `,
                                font: 'Calibri (Cuerpo)',
                                size: 18
                            }),
                            new TextRun({
                                text: `${muestra.informe_nombre_producto}`,
                                font: 'Calibri (Cuerpo)',
                                size: 18,
                                italics: true
                            }),
                            new TextRun({
                                text: `, el cual fue tomado de:`,
                                font: 'Calibri (Cuerpo)',
                                size: 18
                            })
                        ],
                        spacing: { before: 200, after: 200 }
                    }),
                    new Paragraph({
                        alignment: AlignmentType.JUSTIFIED,
                        children: [
                            new TextRun({
                                text: `${muestra.informe_nombre_producto_tomado_de_parcela ? '■' : '❏'}`,
                                font: 'Calibri (Cuerpo)',
                                size: 18
                            }),
                            new TextRun({
                                text: ` 1) PARCELA: `,
                                font: 'Calibri (Cuerpo)',
                                size: 18,
                                bold: true
                            }),
                            new TextRun({
                                text: `${muestra.informe_nombre_producto_tomado_de_parcela_especificacion}`,
                                font: 'Calibri (Cuerpo)',
                                size: 18
                            })
                        ],
                        spacing: { before: 200 }
                    }),,
                    new Paragraph({
                        alignment: AlignmentType.JUSTIFIED,
                        children: [
                            new TextRun({
                                text: `${muestra.informe_nombre_producto_tomado_de_almacen ? '■' : '❏'}`,
                                font: 'Calibri (Cuerpo)',
                                size: 18
                            }),
                            new TextRun({
                                text: ` 2) ALMACÉN: `,
                                font: 'Calibri (Cuerpo)',
                                size: 18,
                                bold: true
                            }),
                            new TextRun({
                                text: `${muestra.informe_nombre_producto_tomado_de_almacen_especificacion}`,
                                font: 'Calibri (Cuerpo)',
                                size: 18
                            })
                        ]
                    }),,
                    new Paragraph({
                        alignment: AlignmentType.JUSTIFIED,
                        children: [
                            new TextRun({
                                text: `${muestra.informe_nombre_producto_tomado_de_otro ? '■' : '❏'}`,
                                font: 'Calibri (Cuerpo)',
                                size: 18
                            }),
                            new TextRun({
                                text: ` 3) OTRO: `,
                                font: 'Calibri (Cuerpo)',
                                size: 18,
                                bold: true
                            }),
                            new TextRun({
                                text: `${muestra.informe_nombre_producto_tomado_de_otro_especificacion}`,
                                font: 'Calibri (Cuerpo)',
                                size: 18
                            })
                        ],
                        spacing: { after: 200 }
                    }),
                    new Paragraph({
                        alignment: AlignmentType.JUSTIFIED,
                        children: [
                            new TextRun({
                                text: `*Completar el siguiente cuadro solo si el muestreo de realizó en almacén u otro sitio especificado`,
                                font: 'Calibri (Cuerpo)',
                                size: 18
                            })
                        ],
                        spacing: { before: 200, after: 200 }
                    }),
                    new Table({
                        rows: [
                            new TableRow({
                                children: [
                                    new TableCell({
                                        children: [
                                            new Paragraph({
                                                alignment: AlignmentType.LEFT,
                                                children: [
                                                    new TextRun({
                                                        text: 'Cantidad de producto: ',
                                                        font: 'Calibri (Cuerpo)',
                                                        size: 18,
                                                        bold: true
                                                    }),
                                                    new TextRun({
                                                        text: `${muestra.informe_cantidad_producto}`,
                                                        font: 'Calibri (Cuerpo)',
                                                        size: 18
                                                    })
                                                ]
                                            })
                                        ],
                                        borders: {
                                            top: {
                                                style: BorderStyle.NONE,
                                                color: "white",
                                                size: 0
                                            },
                                            bottom: {
                                                style: BorderStyle.NONE,
                                                color: "white",
                                                size: 0
                                            },
                                            left: {
                                                style: BorderStyle.NONE,
                                                color: "white",
                                                size: 0
                                            },
                                            right: {
                                                style: BorderStyle.NONE,
                                                color: "white",
                                                size: 0
                                            }
                                        }
                                    }),
                                    new TableCell({
                                        children: [
                                            new Paragraph({
                                                alignment: AlignmentType.LEFT,
                                                children: [
                                                    new TextRun({
                                                        text: 'Unidad de medida: ',
                                                        font: 'Calibri (Cuerpo)',
                                                        size: 18,
                                                        bold: true
                                                    }),
                                                    new TextRun({
                                                        text: `${muestra.informe_unidad_de_medida}`,
                                                        font: 'Calibri (Cuerpo)',
                                                        size: 18
                                                    })
                                                ]
                                            })
                                        ],
                                        borders: {
                                            top: {
                                                style: BorderStyle.NONE,
                                                color: "white",
                                                size: 0
                                            },
                                            bottom: {
                                                style: BorderStyle.NONE,
                                                color: "white",
                                                size: 0
                                            },
                                            left: {
                                                style: BorderStyle.NONE,
                                                color: "white",
                                                size: 0
                                            },
                                            right: {
                                                style: BorderStyle.NONE,
                                                color: "white",
                                                size: 0
                                            }
                                        }
                                    }),
                                    new TableCell({
                                        children: [
                                            new Paragraph({
                                                alignment: AlignmentType.LEFT,
                                                children: [
                                                    new TextRun({
                                                        text: 'Número de lote: ',
                                                        font: 'Calibri (Cuerpo)',
                                                        size: 18,
                                                        bold: true
                                                    }),
                                                    new TextRun({
                                                        text: `${muestra.informe_numero_lote}`,
                                                        font: 'Calibri (Cuerpo)',
                                                        size: 18
                                                    })
                                                ]
                                            })
                                        ],
                                        borders: {
                                            top: {
                                                style: BorderStyle.NONE,
                                                color: "white",
                                                size: 0
                                            },
                                            bottom: {
                                                style: BorderStyle.NONE,
                                                color: "white",
                                                size: 0
                                            },
                                            left: {
                                                style: BorderStyle.NONE,
                                                color: "white",
                                                size: 0
                                            },
                                            right: {
                                                style: BorderStyle.NONE,
                                                color: "white",
                                                size: 0
                                            }
                                        }
                                    })
                                ]
                            })
                        ],
                        margins: {
                            left: 70,
                            right: 70
                        },
                        columnWidths: [3413, 3412, 3412],
                    }),
                    new Paragraph({
                        alignment: AlignmentType.JUSTIFIED,
                        children: [
                            new TextRun({
                                text: `Condiciones higiénicas del sitio muestreado:`,
                                font: 'Calibri (Cuerpo)',
                                size: 18
                            })
                        ],
                        spacing: { before: 200, after: 200 }
                    }),
                    new Table({
                        rows: [
                            new TableRow({
                                children: [
                                    new TableCell({
                                        children: [
                                            new Paragraph({
                                                alignment: AlignmentType.LEFT,
                                                children: [
                                                    new TextRun({
                                                        text: `${muestra.informe_condiciones_higienicas === "Buenas" ? '■' : '❏'} Buenas`,
                                                        font: 'Calibri (Cuerpo)',
                                                        size: 18,
                                                        bold: true
                                                    })
                                                ]
                                            })
                                        ],
                                        borders: {
                                            top: {
                                                style: BorderStyle.NONE,
                                                color: "white",
                                                size: 0
                                            },
                                            bottom: {
                                                style: BorderStyle.NONE,
                                                color: "white",
                                                size: 0
                                            },
                                            left: {
                                                style: BorderStyle.NONE,
                                                color: "white",
                                                size: 0
                                            },
                                            right: {
                                                style: BorderStyle.NONE,
                                                color: "white",
                                                size: 0
                                            }
                                        }
                                    }),
                                    new TableCell({
                                        children: [
                                            new Paragraph({
                                                alignment: AlignmentType.LEFT,
                                                children: [
                                                    new TextRun({
                                                        text: `${muestra.informe_condiciones_higienicas === "Regulares" ? '■' : '❏'} Regulares`,
                                                        font: 'Calibri (Cuerpo)',
                                                        size: 18,
                                                        bold: true
                                                    })
                                                ]
                                            })
                                        ],
                                        borders: {
                                            top: {
                                                style: BorderStyle.NONE,
                                                color: "white",
                                                size: 0
                                            },
                                            bottom: {
                                                style: BorderStyle.NONE,
                                                color: "white",
                                                size: 0
                                            },
                                            left: {
                                                style: BorderStyle.NONE,
                                                color: "white",
                                                size: 0
                                            },
                                            right: {
                                                style: BorderStyle.NONE,
                                                color: "white",
                                                size: 0
                                            }
                                        }
                                    }),
                                    new TableCell({
                                        children: [
                                            new Paragraph({
                                                alignment: AlignmentType.LEFT,
                                                children: [
                                                    new TextRun({
                                                        text: `${muestra.informe_condiciones_higienicas === "Inadecuadas" ? '■' : '❏'} Inadecuadas`,
                                                        font: 'Calibri (Cuerpo)',
                                                        size: 18,
                                                        bold: true
                                                    })
                                                ]
                                            })
                                        ],
                                        borders: {
                                            top: {
                                                style: BorderStyle.NONE,
                                                color: "white",
                                                size: 0
                                            },
                                            bottom: {
                                                style: BorderStyle.NONE,
                                                color: "white",
                                                size: 0
                                            },
                                            left: {
                                                style: BorderStyle.NONE,
                                                color: "white",
                                                size: 0
                                            },
                                            right: {
                                                style: BorderStyle.NONE,
                                                color: "white",
                                                size: 0
                                            }
                                        }
                                    }),
                                    new TableCell({
                                        children: [
                                            new Paragraph({
                                                alignment: AlignmentType.LEFT,
                                                children: [
                                                    new TextRun({
                                                        text: `${muestra.informe_condiciones_higienicas_otro ? '■' : '❏'} Otro: ${muestra.informe_condiciones_higienicas_otro_texto}`,
                                                        font: 'Calibri (Cuerpo)',
                                                        size: 18,
                                                        bold: true
                                                    })
                                                ]
                                            })
                                        ],
                                        borders: {
                                            top: {
                                                style: BorderStyle.NONE,
                                                color: "white",
                                                size: 0
                                            },
                                            bottom: {
                                                style: BorderStyle.NONE,
                                                color: "white",
                                                size: 0
                                            },
                                            left: {
                                                style: BorderStyle.NONE,
                                                color: "white",
                                                size: 0
                                            },
                                            right: {
                                                style: BorderStyle.NONE,
                                                color: "white",
                                                size: 0
                                            }
                                        }
                                    })
                                ]
                            })
                        ],
                        margins: {
                            left: 70,
                            right: 70
                        },
                        columnWidths: [2559, 2559, 2559, 2559],
                    }),
                    new Paragraph({
                        alignment: AlignmentType.JUSTIFIED,
                        children: [
                            new TextRun({
                                text: `Condiciones estructurales del sitio muestreado:`,
                                font: 'Calibri (Cuerpo)',
                                size: 18
                            })
                        ],
                        spacing: { before: 200, after: 200 }
                    }),
                    new Table({
                        rows: [
                            new TableRow({
                                children: [
                                    new TableCell({
                                        children: [
                                            new Paragraph({
                                                alignment: AlignmentType.LEFT,
                                                children: [
                                                    new TextRun({
                                                        text: `${muestra.informe_condiciones_estructurales === "Buenas" ? '■' : '❏'} Buenas`,
                                                        font: 'Calibri (Cuerpo)',
                                                        size: 18,
                                                        bold: true
                                                    })
                                                ]
                                            })
                                        ],
                                        borders: {
                                            top: {
                                                style: BorderStyle.NONE,
                                                color: "white",
                                                size: 0
                                            },
                                            bottom: {
                                                style: BorderStyle.NONE,
                                                color: "white",
                                                size: 0
                                            },
                                            left: {
                                                style: BorderStyle.NONE,
                                                color: "white",
                                                size: 0
                                            },
                                            right: {
                                                style: BorderStyle.NONE,
                                                color: "white",
                                                size: 0
                                            }
                                        }
                                    }),
                                    new TableCell({
                                        children: [
                                            new Paragraph({
                                                alignment: AlignmentType.LEFT,
                                                children: [
                                                    new TextRun({
                                                        text: `${muestra.informe_condiciones_estructurales === "Regulares" ? '■' : '❏'} Regulares`,
                                                        font: 'Calibri (Cuerpo)',
                                                        size: 18,
                                                        bold: true
                                                    })
                                                ]
                                            })
                                        ],
                                        borders: {
                                            top: {
                                                style: BorderStyle.NONE,
                                                color: "white",
                                                size: 0
                                            },
                                            bottom: {
                                                style: BorderStyle.NONE,
                                                color: "white",
                                                size: 0
                                            },
                                            left: {
                                                style: BorderStyle.NONE,
                                                color: "white",
                                                size: 0
                                            },
                                            right: {
                                                style: BorderStyle.NONE,
                                                color: "white",
                                                size: 0
                                            }
                                        }
                                    }),
                                    new TableCell({
                                        children: [
                                            new Paragraph({
                                                alignment: AlignmentType.LEFT,
                                                children: [
                                                    new TextRun({
                                                        text: `${muestra.informe_condiciones_estructurales === "Inadecuadas" ? '■' : '❏'} Inadecuadas`,
                                                        font: 'Calibri (Cuerpo)',
                                                        size: 18,
                                                        bold: true
                                                    })
                                                ]
                                            })
                                        ],
                                        borders: {
                                            top: {
                                                style: BorderStyle.NONE,
                                                color: "white",
                                                size: 0
                                            },
                                            bottom: {
                                                style: BorderStyle.NONE,
                                                color: "white",
                                                size: 0
                                            },
                                            left: {
                                                style: BorderStyle.NONE,
                                                color: "white",
                                                size: 0
                                            },
                                            right: {
                                                style: BorderStyle.NONE,
                                                color: "white",
                                                size: 0
                                            }
                                        }
                                    }),
                                    new TableCell({
                                        children: [
                                            new Paragraph({
                                                alignment: AlignmentType.LEFT,
                                                children: [
                                                    new TextRun({
                                                        text: `${muestra.informe_condiciones_estructurales_otro ? '■' : '❏'} Otro: ${muestra.informe_condiciones_estructurales_otro_texto}`,
                                                        font: 'Calibri (Cuerpo)',
                                                        size: 18,
                                                        bold: true
                                                    })
                                                ]
                                            })
                                        ],
                                        borders: {
                                            top: {
                                                style: BorderStyle.NONE,
                                                color: "white",
                                                size: 0
                                            },
                                            bottom: {
                                                style: BorderStyle.NONE,
                                                color: "white",
                                                size: 0
                                            },
                                            left: {
                                                style: BorderStyle.NONE,
                                                color: "white",
                                                size: 0
                                            },
                                            right: {
                                                style: BorderStyle.NONE,
                                                color: "white",
                                                size: 0
                                            }
                                        }
                                    })
                                ]
                            })
                        ],
                        margins: {
                            left: 70,
                            right: 70
                        },
                        columnWidths: [2559, 2559, 2559, 2559],
                    }),
                    new Paragraph({
                        alignment: AlignmentType.JUSTIFIED,
                        children: [
                            new TextRun({
                                text: `***El muestreo fue realizado según la siguiente modalidad:`,
                                font: 'Calibri (Cuerpo)',
                                size: 18
                            })
                        ],
                        spacing: { before: 200, after: 200 }
                    }),
                    new Paragraph({
                        alignment: AlignmentType.JUSTIFIED,
                        children: [
                            new TextRun({
                                text: `${muestra.informe_muestreo_modalidad === "Lejos de posibles fuentes de contaminación: en áreas/lotes, representativa/s del/a parcela/partida/lote." ? '■' : '❏'}`,
                                font: 'Calibri (Cuerpo)',
                                size: 18
                            }),
                            new TextRun({
                                text: `Lejos de posibles fuentes de contaminación: `,
                                font: 'Calibri (Cuerpo)',
                                size: 18,
                                bold: true
                            }),
                            new TextRun({
                                text: `en áreas/lotes, representativa/s del/a parcela/partida/lote.`,
                                font: 'Calibri (Cuerpo)',
                                size: 18
                            })
                        ],
                        spacing: { before: 200 }
                    }),,
                    new Paragraph({
                        alignment: AlignmentType.JUSTIFIED,
                        children: [
                            new TextRun({
                                text: `${muestra.informe_muestreo_modalidad === "En puntos o fases de la producción particulares: en los que pudiera existir riesgo de contaminación." ? '■' : '❏'}`,
                                font: 'Calibri (Cuerpo)',
                                size: 18
                            }),
                            new TextRun({
                                text: `En puntos o fases de la producción particulares: `,
                                font: 'Calibri (Cuerpo)',
                                size: 18,
                                bold: true
                            }),
                            new TextRun({
                                text: `en los que pudiera existir riesgo de contaminación.`,
                                font: 'Calibri (Cuerpo)',
                                size: 18
                            })
                        ]
                    }),,
                    new Paragraph({
                        alignment: AlignmentType.JUSTIFIED,
                        children: [
                            new TextRun({
                                text: `${muestra.informe_muestreo_modalidad_otro ? '■' : '❏'}`,
                                font: 'Calibri (Cuerpo)',
                                size: 18
                            }),
                            new TextRun({
                                text: `Otro: `,
                                font: 'Calibri (Cuerpo)',
                                size: 18,
                                bold: true
                            }),
                            new TextRun({
                                text: `${muestra.informe_muestreo_modalidad_otro_texto}`,
                                font: 'Calibri (Cuerpo)',
                                size: 18
                            })
                        ],
                        spacing: { after: 200 }
                    }),
                    new Paragraph({
                        alignment: AlignmentType.JUSTIFIED,
                        children: [
                            new TextRun({
                                text: `El muestreo fue realizado empleando: guantes desechables sin material amiláceo, recipientes limpios y químicamente inertes, herramientas limpias (tijeras, perforadoras, etc.) y evitando contacto accidental con algún material externo en la preparación de muestras (realizar sobre superficies limpias).`,
                                font: 'Calibri (Cuerpo)',
                                size: 18
                            })
                        ],
                        spacing: { before: 200, after: 200 }
                    }),
                    new Paragraph({
                        alignment: AlignmentType.JUSTIFIED,
                        children: [
                            new TextRun({
                                text: `Se tiene una muestra global de ${muestra.informe_muestra_global_de} de peso, que fue reducido, recurriendo al siguiente método: ${muestra.informe_muestra_global_metodo}`,
                                font: 'Calibri (Cuerpo)',
                                size: 18
                            })
                        ],
                        spacing: { before: 200, after: 200 }
                    }),
                    new Paragraph({
                        alignment: AlignmentType.JUSTIFIED,
                        children: [
                            new TextRun({
                                text: `La muestra homogénea fue subdividida en `,
                                font: 'Calibri (Cuerpo)',
                                size: 18
                            }),
                            new TextRun({
                                text: `N°3 submuestras + `,
                                font: 'Calibri (Cuerpo)',
                                size: 18,
                                bold: true
                            }),
                            new TextRun({
                                text: `${muestra.informe_muestra_subdivision}`,
                                font: 'Calibri (Cuerpo)',
                                size: 18,
                            }),
                            new TextRun({
                                text: ` que fueron cerrados y sellados mediante ${muestra.informe_muestra_sellados_mediante}`,
                                font: 'Calibri (Cuerpo)',
                                size: 18
                            }),
                            new TextRun({
                                text: ` e identificados `,
                                font: 'Calibri (Cuerpo)',
                                size: 18
                            }),
                            new TextRun({
                                text: ` e identificados con fecha del muestreo ${muestra.informe_muestra_identificados_con_fecha}, código del operador ${this.getValue(data.submission, "informe_nombre_codigo_operacion")}, n° de submuestra ${muestra.informe_muestra_identificados_con_sub}, producto muestreado ${muestra.informe_muestra_identificados_con_producto}, número de muestra o lugar del muestreo ${muestra.informe_muestra_identificados_con_codigo}`,
                                font: 'Calibri (Cuerpo)',
                                size: 18,
                                bold: true
                            }),
                            new TextRun({
                                text: `, (otro), `,
                                font: 'Calibri (Cuerpo)',
                                size: 18
                            }),
                            new TextRun({
                                text: ` e
                                identificados como sigue por el Inspector: ${data.inspector} y del operador: ${this.getValue(data.submission, "informe_nombre_propietario")}.`,
                                font: 'Calibri (Cuerpo)',
                                size: 18
                            }),
                        ],
                        spacing: { before: 200, after: 200 }
                    }),
                    this.getTableSubmuestras(muestra),
                    new Paragraph({
                        alignment: AlignmentType.JUSTIFIED,
                        children: [
                            new TextRun({
                                text: `La sub-muestra n°1, con código: `,
                                font: 'Calibri (Cuerpo)',
                                size: 18,
                                bold: true
                            }),
                            new TextRun({
                                text: `${muestra.informe_submuestras_1_codigo}`,
                                font: 'Calibri (Cuerpo)',
                                size: 18,
                                bold: true
                            }),
                            new TextRun({
                                text: `será enviada para las pruebas al laboratorio: `,
                                font: 'Calibri (Cuerpo)',
                                size: 18
                            }),
                            new TextRun({
                                text: `${muestra.informe_submuestra_1_laboratorio}`,
                                font: 'Calibri (Cuerpo)',
                                size: 18,
                                bold: true
                            }),
                            new TextRun({
                                text: ` para la realización de los siguientes análisis*: `,
                                font: 'Calibri (Cuerpo)',
                                size: 18
                            }),
                            new TextRun({
                                text: `${this.getAnalisis(muestra.informe_submuestra_1_analisis)}`,
                                font: 'Calibri (Cuerpo)',
                                size: 18,
                                bold: true
                            })
                        ],
                        spacing: { before: 200, after: 200 }
                    }),
                    new Paragraph({
                        alignment: AlignmentType.JUSTIFIED,
                        children: [
                            new TextRun({
                                text: `*Definida por Metrocert posteriormente`,
                                font: 'Calibri (Cuerpo)',
                                size: 18
                            })
                        ],
                        spacing: { before: 200, after: 200 }
                    }),
                    new Paragraph({
                        alignment: AlignmentType.JUSTIFIED,
                        children: [
                            new TextRun({
                                text: `La sub-muestra n°2, con código: `,
                                font: 'Calibri (Cuerpo)',
                                size: 18,
                                bold: true
                            }),
                            new TextRun({
                                text: `${muestra.informe_submuestras_2_codigo}`,
                                font: 'Calibri (Cuerpo)',
                                size: 18,
                                bold: true
                            }),
                            new TextRun({
                                text: `será cuidadosamente conservada por METROCERT para la ejecución de los eventuales contra análisis. El laboratorio utilizado para la(s) prueba(s) será: `,
                                font: 'Calibri (Cuerpo)',
                                size: 18
                            }),
                            new TextRun({
                                text: `${muestra.informe_submuestra_2_laboratorio}`,
                                font: 'Calibri (Cuerpo)',
                                size: 18,
                                bold: true
                            })
                        ],
                        spacing: { before: 200, after: 200 }
                    }),
                    new Paragraph({
                        alignment: AlignmentType.JUSTIFIED,
                        children: [
                            new TextRun({
                                text: `En todas las fases del muestreo ha asistido el Sr. ${muestra.informe_submuestra_3_responsable} ,en calidad de ${muestra.informe_submuestra_3_en_calidad_de}, que declara `,
                                font: 'Calibri (Cuerpo)',
                                size: 18
                            }),
                            new TextRun({
                                text: `HABER TOMADO EN CUSTODIA LA sub-muestra (n° 3), `,
                                font: 'Calibri (Cuerpo)',
                                size: 18,
                                bold: true
                            }),
                            new TextRun({
                                text: `asumiendo la responsabilidad de su adecuada conservación, `,
                                font: 'Calibri (Cuerpo)',
                                size: 18
                            }),
                            new TextRun({
                                text: `por un periodo mínimo de 20 días y/o hasta la emisión de un resultado por parte de METROCERT.`,
                                font: 'Calibri (Cuerpo)',
                                size: 18,
                                bold: true
                            })
                        ],
                        spacing: { before: 200, after: 200 }
                    }),
                    new Paragraph({
                        alignment: AlignmentType.JUSTIFIED,
                        children: [
                            new TextRun({
                                text: `Asimismo declara:`,
                                font: 'Calibri (Cuerpo)',
                                size: 18,
                                italics: true
                            })
                        ],
                        spacing: { before: 200, after: 200 }
                    }),
                    new Paragraph({
                        alignment: AlignmentType.JUSTIFIED,
                        children: [
                            new TextRun({
                                text: `${muestra.informe_submuestra_3_conforme ? '■' : '❏'}`,
                                font: 'Calibri (Cuerpo)',
                                size: 18,
                                bold: true
                            }),
                            new TextRun({
                                text: ` A) ESTA CONFORME SOBRE LAS MODALIDADES DEL MUESTREO`,
                                font: 'Calibri (Cuerpo)',
                                size: 18
                            })
                        ],
                        spacing: { before: 200 }
                    }),
                    new Paragraph({
                        alignment: AlignmentType.JUSTIFIED,
                        children: [
                            new TextRun({
                                text: `${muestra.informe_submuestra_3_inconforme ? '■' : '❏'}`,
                                font: 'Calibri (Cuerpo)',
                                size: 18
                            }),
                            new TextRun({
                                text: ` ESTÁ INCONFORME SOBRE LAS MODALIDADES DEL MUESTREO (En su caso, favor de especificar)`,
                                font: 'Calibri (Cuerpo)',
                                size: 18
                            }),
                            new TextRun({
                                text: `${muestra.informe_submuestra_3_inconforme_razon}`,
                                font: 'Calibri (Cuerpo)',
                                size: 18
                            })
                        ],
                        spacing: { after: 200 }
                    }),
                    new Paragraph({
                        alignment: AlignmentType.JUSTIFIED,
                        children: [
                            new TextRun({
                                text: `${muestra.informe_submuestra_3_conforme_analisis ? '■' : '❏'}`,
                                font: 'Calibri (Cuerpo)',
                                size: 18,
                                bold: true
                            }),
                            new TextRun({
                                text: ` B) ESTÁ CONFORME CON EL LABORATORIO DE ANÁLISIS DE MUESTRAS `,
                                font: 'Calibri (Cuerpo)',
                                size: 18
                            })
                        ],
                        spacing: { before: 200 }
                    }),
                    new Paragraph({
                        alignment: AlignmentType.JUSTIFIED,
                        children: [
                            new TextRun({
                                text: `${muestra.informe_submuestra_3_inconforme_analisis ? '■' : '❏'}`,
                                font: 'Calibri (Cuerpo)',
                                size: 18
                            }),
                            new TextRun({
                                text: ` ESTÁ INCONFORME CON EL LABORATORIO DE ANÁLISIS DE MUESTRAS (En su caso, favor de especificar) `,
                                font: 'Calibri (Cuerpo)',
                                size: 18
                            }),
                            new TextRun({
                                text: `${muestra.informe_submuestra_3_inconforme_analisis_razon}`,
                                font: 'Calibri (Cuerpo)',
                                size: 18
                            })
                        ],
                        spacing: { after: 200 }
                    }),
                    new Paragraph({
                        alignment: AlignmentType.JUSTIFIED,
                        children: [
                            new TextRun({
                                text: `Las sub-muestras n° ( ) *Si son superiores a 3* se destinaron a:`,
                                font: 'Calibri (Cuerpo)',
                                size: 18
                            })
                        ],
                        spacing: { before: 200, after: 200 }
                    }),
                    new Paragraph({
                        alignment: AlignmentType.JUSTIFIED,
                        children: [
                            new TextRun({
                                text: `${muestra.informe_submuestra_n_destino}`,
                                font: 'Calibri (Cuerpo)',
                                size: 18
                            })
                        ],
                        spacing: { before: 200, after: 200 }
                    }),
                    new Paragraph({
                        alignment: AlignmentType.JUSTIFIED,
                        children: [
                            new TextRun({
                                text: `*El presente informe fue redactado en doble copia, una de las cuales se entregará al operador, quien después de haberlo leído, declara: ${muestra.informe_declaracion}`,
                                font: 'Calibri (Cuerpo)',
                                size: 18
                            })
                        ],
                        spacing: { before: 200, after: 200 }
                    }),
                    new Table({
                        rows: [
                            new TableRow({
                                children: [
                                    new TableCell({
                                        children: [
                                            new Paragraph({
                                                alignment: AlignmentType.CENTER,
                                                children: [
                                                    new TextRun({
                                                        text: `Firma del Inspector de METROCERT`,
                                                        font: 'Arial',
                                                        size: 20
                                                    })
                                                ],
                                                spacing: {
                                                    after: 100
                                                }
                                            }),
                                            new Paragraph({
                                                alignment: AlignmentType.CENTER,
                                                children: [
                                                    signatureImage2 ? Media.addImage(document, signatureImage2.toString(), 100, 50) : new TextRun({
                                                        text: ''
                                                    })
                                                ]
                                            }),
                                            new Paragraph({
                                                alignment: AlignmentType.CENTER,
                                                children: [
                                                    new TextRun({
                                                        text: `${data.inspector}`,
                                                        font: 'Arial',
                                                        size: 20
                                                    })
                                                ],
                                                spacing: {
                                                    before: 200,
                                                    after: 200
                                                }
                                            })
                                        ],
                                        columnSpan: 2,
                                        borders: {
                                            top: {
                                                style: BorderStyle.NONE,
                                                color: "white",
                                                size: 0
                                            },
                                            bottom: {
                                                style: BorderStyle.NONE,
                                                color: "white",
                                                size: 0
                                            },
                                            right: {
                                                style: BorderStyle.NONE,
                                                color: "white",
                                                size: 0
                                            },
                                            left: {
                                                style: BorderStyle.NONE,
                                                color: "white",
                                                size: 0
                                            }
                                        }
                                    }),
                                    new TableCell({
                                        children: [
                                            new Paragraph({
                                                alignment: AlignmentType.CENTER,
                                                children: [
                                                    new TextRun({
                                                        text: `Firma del Operador o Delegado`,
                                                        font: 'Arial',
                                                        size: 20
                                                    })
                                                ],
                                                spacing: {
                                                    after: 100
                                                }
                                            }),
                                            new Paragraph({
                                                alignment: AlignmentType.CENTER,
                                                children: [
                                                    signatureImage ? Media.addImage(document, signatureImage.toString(), 100, 50) : new TextRun({
                                                        text: ''
                                                    })
                                                ]
                                            }),
                                            new Paragraph({
                                                alignment: AlignmentType.CENTER,
                                                children: [
                                                    new TextRun({
                                                        text: `${data.customer.Company_Name}`,
                                                        font: 'Arial',
                                                        size: 20
                                                    })
                                                ],
                                                spacing: {
                                                    before: 200,
                                                    after: 200
                                                }
                                            })
                                        ],
                                        columnSpan: 2,
                                        borders: {
                                            top: {
                                                style: BorderStyle.NONE,
                                                color: "white",
                                                size: 0
                                            },
                                            bottom: {
                                                style: BorderStyle.NONE,
                                                color: "white",
                                                size: 0
                                            },
                                            right: {
                                                style: BorderStyle.NONE,
                                                color: "white",
                                                size: 0
                                            },
                                            left: {
                                                style: BorderStyle.NONE,
                                                color: "white",
                                                size: 0
                                            }
                                        }
                                    })
                                ]
                            })
                        ],
                        margins: {
                            left: 70,
                            right: 70
                        },
                        width: {
                            size: 100,
                            type: WidthType.PERCENTAGE,
                        },
                        columnWidths: [3312, 3312, 3312, 3312]
                    })
                ],
                margins: {
                    top: 350,
                    bottom: 500,
                    left: 800,
                    right: 800
                }
            });
        });

        return document;
    }

    public createFooter(): Table {
        const table = new Table({
            rows: [
                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: 'Elaboró:',
                                            font: 'Calibri (Cuerpo)',
                                            size: 22
                                        })
                                    ]
                                })
                            ],
                            width: {
                                size: 33,
                                type: WidthType.PERCENTAGE
                            }
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: 'Revisó:',
                                            font: 'Calibri (Cuerpo)',
                                            size: 22
                                        })
                                    ]
                                })
                            ],
                            width: {
                                size: 33,
                                type: WidthType.PERCENTAGE
                            }
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: 'Autorizó:',
                                            font: 'Calibri (Cuerpo)',
                                            size: 22
                                        })
                                    ]
                                }),
                            ],
                            width: {
                                size: 33,
                                type: WidthType.PERCENTAGE
                            }
                        })
                    ]
                }),
                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: 'Director de certificación',
                                            font: 'Calibri (Cuerpo)',
                                            size: 16,
                                            bold: true
                                        })
                                    ]
                                }),

                            ],
                            width: {
                                size: 33,
                                type: WidthType.PERCENTAGE
                            },
                            shading: {
                                fill: '#C0C0C0'
                            }
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: 'Responsable de calidad',
                                            font: 'Calibri (Cuerpo)',
                                            size: 16,
                                            bold: true
                                        })
                                    ]
                                }),

                            ],
                            width: {
                                size: 33,
                                type: WidthType.PERCENTAGE
                            },
                            shading: {
                                fill: '#C0C0C0'
                            }
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: 'Director general',
                                            font: 'Calibri (Cuerpo)',
                                            size: 16,
                                            bold: true
                                        })
                                    ]
                                }),
                            ],
                            width: {
                                size: 33,
                                type: WidthType.PERCENTAGE
                            },
                            shading: {
                                fill: '#C0C0C0'
                            }
                        })
                    ]
                })
            ],
            margins: {
                left: 70,
                right: 70
            },
            columnWidths: [3213, 3213, 3212],
            alignment: AlignmentType.CENTER
        })
        return table
    }

    public getTableOperator(data) {
        const table = new Table({
            rows: [
                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: 'Nombre del propietario (persona física) o razón social (persona moral):',
                                            font: 'Calibri (Cuerpo)',
                                            size: 18,
                                            bold: true
                                        })
                                    ]
                                }),
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: this.getValue(data.submission, "informe_nombre_propietario"),
                                            font: 'Calibri (Cuerpo)',
                                            size: 18
                                        })
                                    ]
                                })
                            ],
                            columnSpan: 2
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: 'Código del operador:',
                                            font: 'Calibri (Cuerpo)',
                                            size: 18,
                                            bold: true
                                        })
                                    ]
                                }),
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: this.getValue(data.submission, "informe_nombre_codigo_operacion"),
                                            font: 'Calibri (Cuerpo)',
                                            size: 18
                                        })
                                    ]
                                })
                            ],
                            columnSpan: 1
                        })
                    ]
                })
            ],
            margins: {
                left: 70,
                right: 70
            },
            columnWidths: [3513, 3512, 3212],
        })

        return table
    }

    public getTableSubmuestras(data) {
        const table = new Table({
            rows: [
                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: `Todas las sub-muestras, homogéneas y representativas, presentan las mismas características:`,
                                            font: 'Calibri (Cuerpo)',
                                            size: 18
                                        })
                                    ]
                                })
                            ]
                        })
                    ]
                }),
                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: `Peso neto`,
                                            font: 'Calibri (Cuerpo)',
                                            size: 18,
                                            bold: true
                                        })
                                    ]
                                }),
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: data["informe_submuestras_peso_neto"],
                                            font: 'Calibri (Cuerpo)',
                                            size: 18
                                        })
                                    ]
                                })
                            ]
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: `Peso bruto`,
                                            font: 'Calibri (Cuerpo)',
                                            size: 18,
                                            bold: true
                                        })
                                    ]
                                }),
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: data["informe_submuestras_peso_bruto"],
                                            font: 'Calibri (Cuerpo)',
                                            size: 18
                                        })
                                    ]
                                })
                            ]
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: `Número de piezas`,
                                            font: 'Calibri (Cuerpo)',
                                            size: 18,
                                            bold: true
                                        })
                                    ]
                                }),
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: data["informe_submuestras_numero_piezas"],
                                            font: 'Calibri (Cuerpo)',
                                            size: 18
                                        })
                                    ]
                                })
                            ]
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: `Conservados en`,
                                            font: 'Calibri (Cuerpo)',
                                            size: 18,
                                            bold: true
                                        })
                                    ]
                                }),
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: data["informe_submuestras_conservados_en"],
                                            font: 'Calibri (Cuerpo)',
                                            size: 18
                                        })
                                    ]
                                })
                            ]
                        })
                    ]
                })
            ],
            margins: {
                left: 70,
                right: 70
            },
            columnWidths: [2559, 2559, 2559, 2559],
        })

        return table
    }

    getValue(submission, name) {
        var value = null
        for (const i in submission) {
            const element = submission[i]
            if (element.name === name) {
                value = element.value
            }
        }
        if (value) return value
        else return ''
    }

    getValueList(submission, name) {
        var value = null
        for (const i in submission) {
            const element = submission[i]
            if (element.name === name) {
                value = element.value
            }
        }
        if (value) return value
        else return []
    }

    getAnalisis(data) {
        var analisis = ''
        for(var i in data) {
            analisis += data[i] + ", "
        }

        analisis = analisis.substr(0, analisis.length-2)
        return analisis
    }

    // END MTO_FINS_06
}