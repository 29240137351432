<div class="modal-header">
  <h4 class="modal-title" id="modal-title">Cancelar prospecto</h4>
  <button
    type="button"
    class="close"
    aria-label="Close button"
    aria-describedby="modal-title"
    (click)="modal.dismiss('Cross click')"
  >
    <span aria-hidden="true">&times;</span>
  </button>
</div>

<form [formGroup]="form" (ngSubmit)="onSubmit()">
  <div class="modal-body">
    <p>¿Estás seguro de que deseas cancelar el propecto con código <strong>{{ code }}</strong>?</p>
    <p><span class="text-danger">Esta operación no se puede deshacer.</span></p>
    <p class="text-muted"><small><i>Para continuar confirme su contraseña.</i></small></p>

    <div class="form-group updateLab">
      <span class="icon-eye" (click)="showPasswordCancelCustomer()">
        <i class="{{ isShowPasswordCancelCustromer ? 'fas fa-eye-slash' : 'fas fa-eye' }}"></i>
      </span>
      <input
        type="{{ isShowPasswordCancelCustromer ? 'text' : 'password' }}"
        formControlName="password"
        class="form-control"
        [ngClass]="{ 'is-invalid': (submitted && f.password.errors) || forbiddenId }"
      />
      <div *ngIf="submitted && f.password.errors" class="invalid-feedback">
        <div *ngIf="f.password.errors.required">Contraseña requerida</div>
      </div>
      <div *ngIf="forbiddenId" class="invalid-feedback">Contraseña incorrecta</div>
    </div>
  </div>
  <div class="modal-footer">
    <button
      type="button"
      class="btn btn-outline-secondary"
      (click)="modal.dismiss('cancel click')"
    >
      Cancelar
    </button>
    <button
      [disabled]="loading"
      type="button"
      ngbAutofocus
      class="btn btn-primary"
      (click)="onSubmit()"
    >
      <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>
      Aceptar
    </button>
  </div>
</form>
