import { Component, Input } from '@angular/core';

import { DashboardItem } from "@app/core/models"

@Component({
    selector: 'dashboard-item',
    templateUrl: 'dashboard-item.component.html',
    styleUrls: ['../../../app.component.scss']
})
export class DashboardItemComponent {
    @Input() module: DashboardItem
}