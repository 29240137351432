<fieldset>
    <ng-template #rt let-r="result" let-t="term">
        <ngb-highlight [result]="r.nombre_comercial" [term]="t"></ngb-highlight>
    </ng-template>


    <label *ngIf="attributes.label"><strong>{{attributes.label}}</strong></label>
    <input
        id="typeahead-http"
        type="text"
        class="form-control"
        [class.is-invalid]="searchFailed"
        [(ngModel)]="model"
        [ngbTypeahead]="search"
        [resultTemplate]="rt"
        [inputFormatter]="formatter"
        (selectItem)="loadSubstance($event)"
    />

    <small *ngIf="searching" class="form-text text-muted">Buscando...</small>
    <div class="invalid-feedback" *ngIf="searchFailed">
        Lo sentimos, las sugerencias no se pudieron cargar.
    </div>
</fieldset>
<hr>
<!--<pre>Model: {{ model | json }}</pre>-->