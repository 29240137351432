<div [formGroup]="form">
    <div id="dynamic-container" class="row">
        <div class="col-lg-6">
            <div [formArrayName]="attributes.group_name" >
                <containers-fabric *ngFor="let formGroup of formArray.controls; let i = index;"
                    [content]="data.content" [formGroup]="formGroup" [ngClass]="{'d-none': i !== currentIndex}">
                </containers-fabric>
                <!-- <containers-fabric id="{{cardIndex}}"
                    [content]="data.content" [formGroup]="formArray.controls[cardIndex]" [ngClass]="{'d-none': cardIndex !== currentIndex}">
                </containers-fabric> -->
            </div>
        </div>
        <div class="col-lg-6" style="max-height: 500px; overflow: scroll;">
            <div *ngFor="let formGroup of formArray.controls; let i = index;">
                <div class="card" style="margin-bottom: 10px;" [ngClass]="{'border-success': i===currentIndex, 'shadow-sm': i===currentIndex}" [id]="i">
                    <div class="card-body">
                        <h5 class="card-title">
                            {{formGroup.get(attributes.card_title_prop) && formGroup.get(attributes.card_title_prop).value ? formGroup.get(attributes.card_title_prop).value : 'Nuevo'}}
                        </h5>
                        <h6>
                            {{formGroup.get(attributes.card_title_carg) && formGroup.get(attributes.card_title_carg).value ? formGroup.get(attributes.card_title_carg).value : ''}}
                        </h6>
                        <a class="card-link" style="cursor: pointer;" (click)="changeIndex(i); changeIndexCard(i)" [ngClass]="{'text-success': i===currentIndex, 'text-info': i!==currentIndex}">{{currentIndex === i ? 'Seleccionado': 'Editar'}}</a>
                        <a *ngIf="!attributes.deleted" class="card-link" style="cursor: pointer;" (click)="deleteGroup(i)" [ngClass]="'text-danger'">Eliminar</a>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <button *ngIf="!attributes.fixed" class="btn btn-info" (click)="addFormGroup($event)">Añadir</button>
</div>