import { AlignmentType, Document, Paragraph, PageOrientation, TextRun, Media, Header, Footer, Table, TableRow, TableCell, VerticalAlign, WidthType, PageNumber, UnderlineType, BorderStyle } from "docx";
import { logo } from "../logo";
import { userWithoutSign } from "../userWithoutSign"
import { DatePipe } from '@angular/common';

export class DocumentCreatorMTO_FSOL_08_3_v3 {

    public getBase64FromUrl = async (url) => {
        const data = await fetch(url, {
            headers: {
                'Cache-Control': 'no-cache, no-store, must-revalidate',
                'Pragma': 'no-cache',
                'Expires': 'Sat, 01 Jan 2000 00:00:00 GMT',
                'If-Modified-Since': '0'
            }
        });
        const blob = await data.blob();
        return new Promise((resolve) => {
            const reader = new FileReader();
            reader.readAsDataURL(blob); 
            reader.onloadend = function() {
                const base64data = reader.result;   
                resolve(base64data);
            }
        });
    }

    // INIT MTO_FSOL_08_3
    public async create_MTO_FSOL_08_3(data, isReview = true, signature = '', signature2 = ''): Promise<Document> {
        const document = new Document();
        const image = Media.addImage(document, logo, 50, 50);
        var datePipe = new DatePipe('es-MX');

        let signatureImage = null
        if (signature) {
            signatureImage = await this.getBase64FromUrl(signature)
        } else {
            signatureImage = userWithoutSign
        }

        let signatureImage2 = null
        if (signature2) {
          signatureImage2 = await this.getBase64FromUrl(signature2)
        } else {
          signatureImage2 = isReview ? '' : userWithoutSign
        }
        
        var unidadesProduccion = ''
        const unidades = this.getValueList(data.submission, "lista_unidades")
        unidades.forEach(unidad => {
            unidadesProduccion += unidad.nombre_unidad_produccion + ', '
        });
        unidadesProduccion = unidadesProduccion.slice(0, -2)

        //Seccion de datos, registro y manejo, orientacion vertical
        document.addSection({
            headers: {
                default: new Header({
                    children: [
                        new Paragraph({ alignment: AlignmentType.CENTER, children: [image], }),
                        new Paragraph({
                            alignment: AlignmentType.CENTER, children: [
                                new TextRun({
                                    font: 'Book Antiqua',
                                    size: 20,
                                    bold: true,
                                    children: [`MTO-FSOL-08-3 | R 10 | 15-Mayo-2022 | Pág. `, PageNumber.CURRENT]
                                }),
                                new TextRun({
                                    font: 'Book Antiqua',
                                    size: 20,
                                    bold: true,
                                    children: [' de ', PageNumber.TOTAL_PAGES]
                                }),
                            ], spacing: { before: 200, after: 300 }
                        })
                    ]
                })
            },
            footers: {
                default: new Footer({
                    children: [
                        new Paragraph({ alignment: AlignmentType.CENTER }),
                        this.createFooter()
                    ]
                }),
            },
            children: [
                new Paragraph({
                    alignment: AlignmentType.CENTER,
                    children: [
                        new TextRun({
                            text: 'PLAN ORGÁNICO PROCESAMIENTO Y COMERCIALIZACIÓN DE PRODUCTOS DE LAS ACTIVIDADES AGROPECUARIAS',
                            font: 'Calibri (Cuerpo)',
                            size: 22,
                            bold: true
                        })
                    ],
                    spacing: { after: 200 }
                }),
                this.createTableUDP(data, datePipe),
                new Paragraph({
                    spacing: {
                        after: 400
                    }
                }),
                this.createRegisters(data.submission),
                new Paragraph({
                    spacing: {
                        after: 400
                    }
                }),
                this.createHandling(data.submission),
            ],
            margins: {
                top: 350,
                bottom: 500,
                left: 1000,
                right: 1000
            }
        });

        //Seccion de actividades, orientacion horizontal
        document.addSection({
            headers: {
                default: new Header({
                    children: [
                        new Paragraph({ alignment: AlignmentType.CENTER, children: [image], }),
                        new Paragraph({
                            alignment: AlignmentType.CENTER, children: [
                                new TextRun({
                                    font: 'Book Antiqua',
                                    size: 20,
                                    bold: true,
                                    children: [`MTO-FSOL-08-3 | R 10 | 15-Mayo-2022 | Pág. `, PageNumber.CURRENT]
                                }),
                                new TextRun({
                                    font: 'Book Antiqua',
                                    size: 20,
                                    bold: true,
                                    children: [' de ', PageNumber.TOTAL_PAGES]
                                }),
                            ], spacing: { before: 200, after: 300 }
                        })
                    ]
                })
            },
            footers: {
                default: new Footer({
                    children: [
                        new Paragraph({ alignment: AlignmentType.CENTER }),
                        this.createFooter()
                    ]
                }),
            },
            children: [
                new Paragraph({
                    alignment: AlignmentType.LEFT,
                    children: [
                        new TextRun({
                            text: 'ACTIVIDADES PARA EL PROCESO',
                            font: 'Calibri (Cuerpo)',
                            size: 22,
                            bold: true
                        })
                    ],
                    spacing: {
                        before: 300,
                        after: 200
                    }
                }),
                this.createProcessActivities(data.submission),
                new Paragraph({
                    alignment: AlignmentType.LEFT,
                    children: [
                        new TextRun({
                            text: 'ACTIVIDADES PARA LA LIMPIEZA',
                            font: 'Calibri (Cuerpo)',
                            size: 22,
                            bold: true
                        })
                    ],
                    spacing: {
                        before: 300,
                        after: 200
                    }
                }),
                this.createCleaningActivities(data.submission),
                new Paragraph({
                    alignment: AlignmentType.LEFT,
                    children: [
                        new TextRun({
                            text: 'ACTIVIDADES PARA CONTROL DE PLAGAS',
                            font: 'Calibri (Cuerpo)',
                            size: 22,
                            bold: true
                        })
                    ],
                    spacing: {
                        before: 300,
                        after: 200
                    }
                }),
                this.createPlagueActivities(data.submission),
                new Paragraph({
                    alignment: AlignmentType.LEFT,
                    children: [
                        new TextRun({
                            text: 'TIPOS DE EMPAQUE Y/O ENVASADO',
                            font: 'Calibri (Cuerpo)',
                            size: 22,
                            bold: true
                        })
                    ],
                    spacing: {
                        before: 300,
                        after: 200
                    }
                }),
                this.createPackagingTypes(data.submission)
            ],
            margins: {
                top: 350,
                bottom: 500,
                left: 1000,
                right: 1000
            },
            size: {
                orientation: PageOrientation.LANDSCAPE,
            }            
        })

        //Seccion de evaluacion, orientacion vertical
        document.addSection({
            headers: {
                default: new Header({
                    children: [
                        new Paragraph({ alignment: AlignmentType.CENTER, children: [image], }),
                        new Paragraph({
                            alignment: AlignmentType.CENTER, children: [
                                new TextRun({
                                    font: 'Book Antiqua',
                                    size: 20,
                                    bold: true,
                                    children: [`MTO-FSOL-08-3 | R 10 | 15-Mayo-2022 | Pág. `, PageNumber.CURRENT]
                                }),
                                new TextRun({
                                    font: 'Book Antiqua',
                                    size: 20,
                                    bold: true,
                                    children: [' de ', PageNumber.TOTAL_PAGES]
                                }),
                            ], spacing: { before: 200, after: 300 }
                        })
                    ]
                })
            },
            footers: {
                default: new Footer({
                    children: [
                        new Paragraph({ alignment: AlignmentType.CENTER }),
                        this.createFooter()
                    ]
                }),
            },
            children: [
                new Paragraph({
                    alignment: AlignmentType.JUSTIFIED,
                    children: [
                        new TextRun({
                            text: `El que suscribe: ${data.customer.Legal_Authorities[0].Name} ${data.customer.Legal_Authorities[0].Middle_Name} ${data.customer.Legal_Authorities[0].Last_Name}, Titular / Representante Legal de la(s) Unidad(es) de Producción / Empresa: ${data.customer.Company_Type === "PERSONA_FISICA" ? unidadesProduccion : data.customer.Company_Name}, declaro que la información descrita en este Plan Orgánico y documentos adjuntos es verídica, y me comprometo a mantenerlo actualizado, al igual que las actividades descritas en él; con la finalidad de cumplir con la Ley de Productos Orgánicos de México (LPO) y el Régimen Orgánico de Canadá (COR/USCOEA).`,
                            font: 'Arial',
                            size: 18
                        })
                    ],
                    spacing: {
                        before: 400,
                        after: 400
                    },
                }),
                new Paragraph({
                    alignment: AlignmentType.JUSTIFIED,
                    children: [
                        new TextRun({
                            text: 'Atentamente:',
                            font: 'Arial',
                            size: 18, bold: true
                        })
                    ],
                    spacing: {
                        before: 400,
                        after: 400
                    },
                }),
                new Paragraph({
                    alignment: AlignmentType.CENTER,
                    children: [
                        signatureImage ? Media.addImage(document, signatureImage.toString(), 200, 100) : new TextRun({
                            text: ''
                        })
                    ]
                }),
                new Paragraph({
                    alignment: AlignmentType.LEFT,
                    children: [
                        new TextRun({
                            text: `Fecha: ${datePipe.transform(new Date(data.record.Applications[0].Creation_Date.includes('UTC') ? data.record.Applications[0].Creation_Date.replace('UTC', '').trim() : data.record.Applications[0].Creation_Date), 'd-MMMM-y')}                  Nombre y firma del operador: ${data.customer.Legal_Authorities[0].Name} ${data.customer.Legal_Authorities[0].Middle_Name} ${data.customer.Legal_Authorities[0].Last_Name ? data.customer.Legal_Authorities[0].Last_Name : ''}`,
                            font: 'Arial',
                            size: 20,
                            italics: true
                        })
                    ],
                    spacing: {
                        after: 200
                    }
                }),
                new Table({
                    rows: [
                        new TableRow({
                            children: [
                                new TableCell({
                                    children: [
                                        new Paragraph({
                                            alignment: AlignmentType.CENTER,
                                            children: [
                                                new TextRun({
                                                    text: 'EVALUACIÓN POR EL REVISOR',
                                                    font: 'Arial',
                                                    size: 20,
                                                    bold: true
                                                })
                                            ],
                                            spacing: {
                                                after: 75,
                                                before: 75
                                            }
                                        }),
                                    ],
                                    verticalAlign: VerticalAlign.CENTER,
                                    columnSpan: 2,
                                    shading: {
                                        fill: '#C0C0C0'
                                    }
                                })
                            ]
                        }),
                        new TableRow({
                            children: [
                                new TableCell({
                                    children: [
                                        new Paragraph({
                                            alignment: AlignmentType.RIGHT,
                                            children: [
                                                new TextRun({
                                                    text: 'Nombre del Revisor: ',
                                                    font: 'Calibri (Cuerpo)',
                                                    size: 22,
                                                })
                                            ],
                                            spacing: { before: 100, after: 200 }
                                        })],
                                    verticalAlign: VerticalAlign.CENTER,
                                }),
                                new TableCell({
                                    children: [
                                        new Paragraph({
                                            alignment: AlignmentType.LEFT,
                                            children: [
                                                new TextRun({
                                                    text: data.reviewer ? data.reviewer.name : '',
                                                    font: 'Calibri (Cuerpo)',
                                                    size: 22,
                                                })
                                            ],
                                            spacing: { before: 100, after: 200 }
                                        })],
                                    verticalAlign: VerticalAlign.CENTER,
                                }),
                            ],
                        }),
                        new TableRow({
                            children: [
                                new TableCell({
                                    children: [
                                        new Paragraph({
                                            alignment: AlignmentType.RIGHT,
                                            children: [
                                                new TextRun({
                                                    text: 'Fecha: ',
                                                    font: 'Calibri (Cuerpo)',
                                                    size: 22,
                                                })
                                            ],
                                            spacing: { before: 100, after: 200 }
                                        })],
                                    verticalAlign: VerticalAlign.CENTER,
                                }),
                                new TableCell({
                                    children: [
                                        new Paragraph({
                                            alignment: AlignmentType.LEFT,
                                            children: [
                                                new TextRun({
                                                    text: `${data.reviewer ? datePipe.transform(new Date(data.reviewer.date), 'd-MMMM-y') : ''}`,
                                                    font: 'Calibri (Cuerpo)',
                                                    size: 22,
                                                })
                                            ],
                                            spacing: { before: 100, after: 200 }
                                        })],
                                    verticalAlign: VerticalAlign.CENTER,
                                }),
                            ],
                        }),
                        new TableRow({
                            children: [
                                new TableCell({
                                    children: [
                                        new Paragraph({
                                            alignment: AlignmentType.RIGHT,
                                            children: [
                                                new TextRun({
                                                    text: 'Conformidad: ',
                                                    font: 'Calibri (Cuerpo)',
                                                    size: 22,
                                                })
                                            ],
                                            spacing: { before: 100, after: 200 }
                                        })],
                                    verticalAlign: VerticalAlign.CENTER,
                                }),
                                new TableCell({
                                    children: [
                                        new Paragraph({
                                            alignment: AlignmentType.LEFT,
                                            children: [
                                                new TextRun({
                                                    text: `${this.getValue(data.revision, 'plan_organico_conformidad_no_conformidades_graves') === true ? '■' : '❏'} No conformidades graves, no procederá la certificación.`,
                                                    font: 'Arial',
                                                    size: 18
                                                })
                                            ]
                                        }),
                                        new Paragraph({
                                            alignment: AlignmentType.LEFT,
                                            children: [
                                                new TextRun({
                                                    text: `${this.getValue(data.revision, 'plan_organico_conformidad_no_conformidades_menores_con_hallazgos') === true ? '■' : '❏'} No conformidades menores, se requiere de corrección antes de inspección. Listar los hallazgos abajo.`,
                                                    font: 'Arial',
                                                    size: 18
                                                })
                                            ]
                                        }),
                                        new Paragraph({
                                            alignment: AlignmentType.LEFT,
                                            children: [
                                                new TextRun({
                                                    text: `${this.getValue(data.revision, 'plan_organico_conformidad_no_conformidades_menores') === true ? '■' : '❏'} No conformidades menores, se puede realizar inspección.`,
                                                    font: 'Arial',
                                                    size: 18
                                                })
                                            ]
                                        }),
                                        new Paragraph({
                                            alignment: AlignmentType.LEFT,
                                            children: [
                                                new TextRun({
                                                    text: `${this.getValue(data.revision, 'plan_organico_conformidad_conformidad') === true ? '■' : '❏'} Conformidad`,
                                                    font: 'Arial',
                                                    size: 18
                                                })
                                            ]
                                        }),
                                        new Paragraph({
                                            alignment: AlignmentType.LEFT,
                                            children: [
                                                new TextRun({
                                                    text: `${this.getValue(data.revision, 'plan_organico_conformidad_otro') === true ? `■ Otro: ${this.getValue(data.revision, 'plan_organico_conformidad_otro_text')}` : '❏ Otro:'}`,
                                                    font: 'Arial',
                                                    size: 18
                                                })
                                            ]
                                        })
                                    ],
                                    verticalAlign: VerticalAlign.CENTER,
                                    columnSpan: 1,
                                }),
                            ],
                        }),
                        new TableRow({
                            children: [
                                new TableCell({
                                    children: [
                                        new Paragraph({
                                            alignment: AlignmentType.RIGHT,
                                            children: [
                                                new TextRun({
                                                    text: 'Hallazgos: ',
                                                    font: 'Calibri (Cuerpo)',
                                                    size: 22,
                                                })
                                            ],
                                            spacing: { before: 100, after: 200 }
                                        })],
                                    verticalAlign: VerticalAlign.CENTER,
                                }),
                                new TableCell({
                                    children: [
                                        new Paragraph({
                                            alignment: AlignmentType.LEFT,
                                            children: [
                                                new TextRun({
                                                    text: this.getValue(data.revision, "plan_organico_hallazgos"),
                                                    font: 'Calibri (Cuerpo)',
                                                    size: 22,
                                                })
                                            ],
                                            spacing: { before: 100, after: 200 }
                                        })],
                                    verticalAlign: VerticalAlign.CENTER,
                                }),
                            ],
                        }),
                        new TableRow({
                            children: [
                                new TableCell({
                                    children: [
                                        new Paragraph({
                                            alignment: AlignmentType.RIGHT,
                                            children: [
                                                new TextRun({
                                                    text: 'Recomendaciones para inspector: ',
                                                    font: 'Calibri (Cuerpo)',
                                                    size: 22,
                                                })
                                            ],
                                            spacing: { before: 100, after: 200 }
                                        })],
                                    verticalAlign: VerticalAlign.CENTER,
                                }),
                                new TableCell({
                                    children: [
                                        new Paragraph({
                                            alignment: AlignmentType.LEFT,
                                            children: [
                                                new TextRun({
                                                    text: this.getValue(data.revision, "plan_organico_recomendaciones"),
                                                    font: 'Calibri (Cuerpo)',
                                                    size: 22,
                                                })
                                            ],
                                            spacing: { before: 100, after: 200 }
                                        })],
                                    verticalAlign: VerticalAlign.CENTER,
                                }),
                            ],
                        }),
                        new TableRow({
                            children: [
                                new TableCell({
                                    children: [
                                        new Paragraph({
                                            alignment: AlignmentType.RIGHT,
                                            children: [
                                                new TextRun({
                                                    text: 'Comentarios generales: ',
                                                    font: 'Calibri (Cuerpo)',
                                                    size: 22,
                                                })
                                            ],
                                            spacing: { before: 100, after: 200 }
                                        })],
                                    verticalAlign: VerticalAlign.CENTER,
                                }),
                                new TableCell({
                                    children: [
                                        new Paragraph({
                                            alignment: AlignmentType.LEFT,
                                            children: [
                                                new TextRun({
                                                    text: this.getValue(data.revision, "plan_organico_comentarios"),
                                                    font: 'Calibri (Cuerpo)',
                                                    size: 22,
                                                })
                                            ],
                                            spacing: { before: 100, after: 200 }
                                        })],
                                    verticalAlign: VerticalAlign.CENTER,
                                }),
                            ],
                        }),
                        new TableRow({
                            children: [
                                new TableCell({
                                    children: [
                                        new Paragraph({
                                            alignment: AlignmentType.RIGHT,
                                            children: [
                                                new TextRun({
                                                    text: `Firma del Revisor:`,
                                                    font: 'Calibri (Cuerpo)',
                                                    size: 22,
                                                })
                                            ],
                                            spacing: { before: 100, after: 200 }
                                        })],
                                    verticalAlign: VerticalAlign.CENTER,
                                }),
                                new TableCell({
                                    children: [
                                        new Paragraph({
                                            alignment: AlignmentType.LEFT,
                                            children: [
                                              signatureImage2 ? Media.addImage(document, signatureImage2.toString(), 200, 100) : new TextRun({
                                                text: ''
                                              })
                                            ],
                                            spacing: { before: 100, after: 200 }
                                        })
                                    ],
                                    verticalAlign: VerticalAlign.CENTER,
                                    columnSpan: 1,
                                }),
                            ],
                        }),
                    ],
                    margins: {
                        left: 70,
                        right: 70
                    },
                    width: {
                        size: 100,
                        type: WidthType.PERCENTAGE,
                    },
                    columnWidths: [2410, 7227]
                  })
            ],
            margins: {
                top: 350,
                bottom: 500,
                left: 1000,
                right: 1000
            }           
        })

        return document;
    }

    public createFooter(): Table {
        const table = new Table({
            rows: [
                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: 'Elaboró:',
                                            font: 'Calibri (Cuerpo)',
                                            size: 22
                                        })
                                    ]
                                })
                            ],
                            width: {
                                size: 33,
                                type: WidthType.PERCENTAGE
                            }
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: 'Revisó:',
                                            font: 'Calibri (Cuerpo)',
                                            size: 22
                                        })
                                    ]
                                })
                            ],
                            width: {
                                size: 33,
                                type: WidthType.PERCENTAGE
                            }
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: 'Autorizó:',
                                            font: 'Calibri (Cuerpo)',
                                            size: 22
                                        })
                                    ]
                                }),
                            ],
                            width: {
                                size: 33,
                                type: WidthType.PERCENTAGE
                            }
                        })
                    ]
                }),
                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: 'Director de certificación',
                                            font: 'Calibri (Cuerpo)',
                                            size: 16,
                                            bold: true
                                        })
                                    ]
                                }),

                            ],
                            width: {
                                size: 33,
                                type: WidthType.PERCENTAGE
                            },
                            shading: {
                                fill: '#C0C0C0'
                            }
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: 'Responsable de calidad',
                                            font: 'Calibri (Cuerpo)',
                                            size: 16,
                                            bold: true
                                        })
                                    ]
                                }),

                            ],
                            width: {
                                size: 33,
                                type: WidthType.PERCENTAGE
                            },
                            shading: {
                                fill: '#C0C0C0'
                            }
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: 'Director general',
                                            font: 'Calibri (Cuerpo)',
                                            size: 16,
                                            bold: true
                                        })
                                    ]
                                }),
                            ],
                            width: {
                                size: 33,
                                type: WidthType.PERCENTAGE
                            },
                            shading: {
                                fill: '#C0C0C0'
                            }
                        })
                    ]
                })
            ],
            margins: {
                left: 70,
                right: 70
            },
            //9638
            columnWidths: [3213, 3213, 3212],
            alignment: AlignmentType.CENTER
        })

        return table
    }

    public createRegisters(submission): Table {
        const table = new Table({
            rows: [
                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: 'REGISTROS',
                                            font: 'Arial',
                                            size: 20,
                                            bold: true
                                        })
                                    ],
                                    spacing: {
                                        after: 75,
                                        before: 75
                                    }
                                }),
                            ],
                            verticalAlign: VerticalAlign.CENTER,
                            columnSpan: 6,
                            shading: {
                                fill: '#C0C0C0'
                            }
                        })
                    ]
                }),
                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: 'No.',
                                            font: 'Arial',
                                            size: 18,
                                            bold: true
                                        })
                                    ]
                                })
                            ],
                            rowSpan: 2,
                            verticalAlign: VerticalAlign.CENTER,
                            width: {
                                size: 25,
                                type: WidthType.PERCENTAGE
                            }
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: 'Requisitos',
                                            font: 'Arial',
                                            size: 18,
                                            bold: true
                                        })
                                    ]
                                })
                            ],
                            rowSpan: 2,
                            verticalAlign: VerticalAlign.CENTER,
                            width: {
                                size: 15,
                                type: WidthType.PERCENTAGE
                            }
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: 'Declaración',
                                            font: 'Arial',
                                            size: 18,
                                            bold: true
                                        })
                                    ]
                                })
                            ],
                            rowSpan: 2,
                            verticalAlign: VerticalAlign.CENTER,
                            width: {
                                size: 15,
                                type: WidthType.PERCENTAGE
                            }
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: 'Comentarios',
                                            font: 'Arial',
                                            size: 18,
                                            bold: true
                                        })
                                    ]
                                })
                            ],
                            rowSpan: 2,
                            verticalAlign: VerticalAlign.CENTER,
                            width: {
                                size: 15,
                                type: WidthType.PERCENTAGE
                            }
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: 'Verificación en sitio (Evaluada por Inspector)',
                                            font: 'Arial',
                                            size: 18,
                                            bold: true
                                        })
                                    ]
                                })
                            ],
                            columnSpan: 3,
                            verticalAlign: VerticalAlign.CENTER,
                            width: {
                                size: 15,
                                type: WidthType.PERCENTAGE
                            }
                        })
                    ]
                }),
                new TableRow({
                    children: [new TableCell({
                        children: [
                            new Paragraph({
                                alignment: AlignmentType.CENTER,
                                children: [
                                    new TextRun({
                                        text: 'Implementación',
                                        font: 'Arial',
                                        size: 18,
                                        bold: true
                                    })
                                ]
                            })
                        ],
                        verticalAlign: VerticalAlign.CENTER
                    }),
                    new TableCell({
                        children: [
                            new Paragraph({
                                alignment: AlignmentType.CENTER,
                                children: [
                                    new TextRun({
                                        text: 'Confirmación',
                                        font: 'Arial',
                                        size: 18,
                                        bold: true
                                    })
                                ]
                            })
                        ],
                        verticalAlign: VerticalAlign.CENTER,
                        columnSpan: 2
                    }),]
                }),
                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: '1',
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                })
                            ],
                            verticalAlign: VerticalAlign.TOP
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: 'He incluido una lista completa de los ingredientes usados en cada uno de los productos. MTO-FSOL-09',
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                }),
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: 'Nota: Para operadores con certificación UE, si se usan aditivos alimentarios no-orgánicos, será necesaria una justificación por escrito de la necesidad tecnológica o nutricional de la sustancia.',
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                })
                            ],
                            verticalAlign: VerticalAlign.TOP
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: this.getValue(submission, 'plan_organico_procesamiento_registros_1_declaracion') === 'Si' ? '■ SÍ  ❏ NO' : '❏ SÍ  ■ NO',
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                })
                            ],
                            verticalAlign: VerticalAlign.TOP
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: this.getValue(submission, 'plan_organico_procesamiento_registros_1_comentarios'),
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                })
                            ],
                            verticalAlign: VerticalAlign.TOP
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: ` ${this.getValue(submission, 'plan_organico_procesamiento_registros_1_implementacion') === 'I' ? '■' : '❏'} I`,
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                  }),
                                  new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: ` ${this.getValue(submission, 'plan_organico_procesamiento_registros_1_implementacion') === 'NI' ? '■' : '❏'} NI`,
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                  })
                            ],
                            verticalAlign: VerticalAlign.TOP
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: ` ${this.getValue(submission, 'plan_organico_procesamiento_registros_1_conformidad') === 'C' ? '■' : '❏'} C`,
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                  }),
                                  new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: ` ${this.getValue(submission, 'plan_organico_procesamiento_registros_1_conformidad') === 'NC' ? '■' : '❏'} NC`,
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                  }),
                                  new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: ` ${this.getValue(submission, 'plan_organico_procesamiento_registros_1_conformidad') === 'NA' ? '■' : '❏'} NA`,
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                  })
                            ],
                            verticalAlign: VerticalAlign.TOP
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: this.getValue(submission, 'plan_organico_procesamiento_registros_1_comentarios_inspector'),
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                  })
                            ],
                            verticalAlign: VerticalAlign.TOP
                        })
                    ]
                }),
                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: '2',
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                })
                            ],
                            verticalAlign: VerticalAlign.TOP
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: 'He incluido una Declaración de Cumplimiento USCOEA para cada proveedor con certificación NOP (empacadores y etiquetadores). MTO-FSOL-22 ',
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                })
                            ],
                            verticalAlign: VerticalAlign.TOP
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: this.getValue(submission, 'plan_organico_procesamiento_registros_2_declaracion') === 'Si' ? '■ SÍ  ❏ NO' : '❏ SÍ  ■ NO',
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                })
                            ],
                            verticalAlign: VerticalAlign.TOP
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: this.getValue(submission, 'plan_organico_procesamiento_registros_2_comentarios'),
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                })
                            ],
                            verticalAlign: VerticalAlign.TOP
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: ` ${this.getValue(submission, 'plan_organico_procesamiento_registros_2_implementacion') === 'I' ? '■' : '❏'} I`,
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                  }),
                                  new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: ` ${this.getValue(submission, 'plan_organico_procesamiento_registros_2_implementacion') === 'NI' ? '■' : '❏'} NI`,
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                  })
                            ],
                            verticalAlign: VerticalAlign.TOP
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: ` ${this.getValue(submission, 'plan_organico_procesamiento_registros_2_conformidad') === 'C' ? '■' : '❏'} C`,
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                  }),
                                  new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: ` ${this.getValue(submission, 'plan_organico_procesamiento_registros_2_conformidad') === 'NC' ? '■' : '❏'} NC`,
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                  }),
                                  new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: ` ${this.getValue(submission, 'plan_organico_procesamiento_registros_2_conformidad') === 'NA' ? '■' : '❏'} NA`,
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                  })
                            ],
                            verticalAlign: VerticalAlign.TOP
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: this.getValue(submission, 'plan_organico_procesamiento_registros_2_comentarios_inspector'),
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                  })
                            ],
                            verticalAlign: VerticalAlign.TOP
                        })
                    ]
                }),
                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: '3',
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                })
                            ],
                            verticalAlign: VerticalAlign.TOP
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: 'He incluido una lista de proveedores de productos orgánicos certificados (empacadores y etiquetadores). MTO-FSOL-23',
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                })
                            ],
                            verticalAlign: VerticalAlign.TOP
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: this.getValue(submission, 'plan_organico_procesamiento_registros_3_declaracion') === 'Si' ? '■ SÍ  ❏ NO' : '❏ SÍ  ■ NO',
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                })
                            ],
                            verticalAlign: VerticalAlign.TOP
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: this.getValue(submission, 'plan_organico_procesamiento_registros_3_comentarios'),
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                })
                            ],
                            verticalAlign: VerticalAlign.TOP
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: ` ${this.getValue(submission, 'plan_organico_procesamiento_registros_3_implementacion') === 'I' ? '■' : '❏'} I`,
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                  }),
                                  new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: ` ${this.getValue(submission, 'plan_organico_procesamiento_registros_3_implementacion') === 'NI' ? '■' : '❏'} NI`,
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                  })
                            ],
                            verticalAlign: VerticalAlign.TOP
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: ` ${this.getValue(submission, 'plan_organico_procesamiento_registros_3_conformidad') === 'C' ? '■' : '❏'} C`,
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                  }),
                                  new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: ` ${this.getValue(submission, 'plan_organico_procesamiento_registros_3_conformidad') === 'NC' ? '■' : '❏'} NC`,
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                  }),
                                  new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: ` ${this.getValue(submission, 'plan_organico_procesamiento_registros_3_conformidad') === 'NA' ? '■' : '❏'} NA`,
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                  })
                            ],
                            verticalAlign: VerticalAlign.TOP
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: this.getValue(submission, 'plan_organico_procesamiento_registros_3_comentarios_inspector'),
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                  })
                            ],
                            verticalAlign: VerticalAlign.TOP
                        })
                    ]
                }),
                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: '4',
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                })
                            ],
                            verticalAlign: VerticalAlign.TOP
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: 'He incluido copia de los certificados orgánicos de las materias primas',
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                })
                            ],
                            verticalAlign: VerticalAlign.TOP
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: this.getValue(submission, 'plan_organico_procesamiento_registros_4_declaracion') === 'Si' ? '■ SÍ  ❏ NO' : '❏ SÍ  ■ NO',
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                })
                            ],
                            verticalAlign: VerticalAlign.TOP
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: this.getValue(submission, 'plan_organico_procesamiento_registros_4_comentarios'),
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                })
                            ],
                            verticalAlign: VerticalAlign.TOP
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: ` ${this.getValue(submission, 'plan_organico_procesamiento_registros_4_implementacion') === 'I' ? '■' : '❏'} I`,
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                  }),
                                  new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: ` ${this.getValue(submission, 'plan_organico_procesamiento_registros_4_implementacion') === 'NI' ? '■' : '❏'} NI`,
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                  })
                            ],
                            verticalAlign: VerticalAlign.TOP
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: ` ${this.getValue(submission, 'plan_organico_procesamiento_registros_4_conformidad') === 'C' ? '■' : '❏'} C`,
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                  }),
                                  new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: ` ${this.getValue(submission, 'plan_organico_procesamiento_registros_4_conformidad') === 'NC' ? '■' : '❏'} NC`,
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                  }),
                                  new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: ` ${this.getValue(submission, 'plan_organico_procesamiento_registros_4_conformidad') === 'NA' ? '■' : '❏'} NA`,
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                  })
                            ],
                            verticalAlign: VerticalAlign.TOP
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: this.getValue(submission, 'plan_organico_procesamiento_registros_4_comentarios_inspector'),
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                  })
                            ],
                            verticalAlign: VerticalAlign.TOP
                        })
                    ]
                }),
                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: '5',
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                })
                            ],
                            verticalAlign: VerticalAlign.TOP
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: 'He incluido un diagrama de flujo para cada producto procesado. (Formato propio)',
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                })
                            ],
                            verticalAlign: VerticalAlign.TOP
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: this.getValue(submission, 'plan_organico_procesamiento_registros_5_declaracion') === 'Si' ? '■ SÍ  ❏ NO' : '❏ SÍ  ■ NO',
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                })
                            ],
                            verticalAlign: VerticalAlign.TOP
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: this.getValue(submission, 'plan_organico_procesamiento_registros_5_comentarios'),
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                })
                            ],
                            verticalAlign: VerticalAlign.TOP
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: ` ${this.getValue(submission, 'plan_organico_procesamiento_registros_5_implementacion') === 'I' ? '■' : '❏'} I`,
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                  }),
                                  new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: ` ${this.getValue(submission, 'plan_organico_procesamiento_registros_5_implementacion') === 'NI' ? '■' : '❏'} NI`,
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                  })
                            ],
                            verticalAlign: VerticalAlign.TOP
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: ` ${this.getValue(submission, 'plan_organico_procesamiento_registros_5_conformidad') === 'C' ? '■' : '❏'} C`,
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                  }),
                                  new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: ` ${this.getValue(submission, 'plan_organico_procesamiento_registros_5_conformidad') === 'NC' ? '■' : '❏'} NC`,
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                  }),
                                  new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: ` ${this.getValue(submission, 'plan_organico_procesamiento_registros_5_conformidad') === 'NA' ? '■' : '❏'} NA`,
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                  })
                            ],
                            verticalAlign: VerticalAlign.TOP
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: this.getValue(submission, 'plan_organico_procesamiento_registros_5_comentarios_inspector'),
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                  })
                            ],
                            verticalAlign: VerticalAlign.TOP
                        })
                    ]
                }),
                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: '6',
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                })
                            ],
                            verticalAlign: VerticalAlign.TOP
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: 'He incluido una copia de mi programa de control del plagas',
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                })
                            ],
                            verticalAlign: VerticalAlign.TOP
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: this.getValue(submission, 'plan_organico_procesamiento_registros_6_declaracion') === 'Si' ? '■ SÍ  ❏ NO' : '❏ SÍ  ■ NO',
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                })
                            ],
                            verticalAlign: VerticalAlign.TOP
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: this.getValue(submission, 'plan_organico_procesamiento_registros_6_comentarios'),
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                })
                            ],
                            verticalAlign: VerticalAlign.TOP
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: ` ${this.getValue(submission, 'plan_organico_procesamiento_registros_6_implementacion') === 'I' ? '■' : '❏'} I`,
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                  }),
                                  new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: ` ${this.getValue(submission, 'plan_organico_procesamiento_registros_6_implementacion') === 'NI' ? '■' : '❏'} NI`,
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                  })
                            ],
                            verticalAlign: VerticalAlign.TOP
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: ` ${this.getValue(submission, 'plan_organico_procesamiento_registros_6_conformidad') === 'C' ? '■' : '❏'} C`,
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                  }),
                                  new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: ` ${this.getValue(submission, 'plan_organico_procesamiento_registros_6_conformidad') === 'NC' ? '■' : '❏'} NC`,
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                  }),
                                  new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: ` ${this.getValue(submission, 'plan_organico_procesamiento_registros_6_conformidad') === 'NA' ? '■' : '❏'} NA`,
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                  })
                            ],
                            verticalAlign: VerticalAlign.TOP
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: this.getValue(submission, 'plan_organico_procesamiento_registros_6_comentarios_inspector'),
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                  })
                            ],
                            verticalAlign: VerticalAlign.TOP
                        })
                    ]
                }),
                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: '7',
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                })
                            ],
                            verticalAlign: VerticalAlign.TOP
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: 'He incluido una copia de mi permiso de operación por parte de la Secretaria de Salud o similar',
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                })
                            ],
                            verticalAlign: VerticalAlign.TOP
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: this.getValue(submission, 'plan_organico_procesamiento_registros_7_declaracion') === 'Si' ? '■ SÍ  ❏ NO' : '❏ SÍ  ■ NO',
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                })
                            ],
                            verticalAlign: VerticalAlign.TOP
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: this.getValue(submission, 'plan_organico_procesamiento_registros_7_comentarios'),
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                })
                            ],
                            verticalAlign: VerticalAlign.TOP
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: ` ${this.getValue(submission, 'plan_organico_procesamiento_registros_7_implementacion') === 'I' ? '■' : '❏'} I`,
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                  }),
                                  new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: ` ${this.getValue(submission, 'plan_organico_procesamiento_registros_7_implementacion') === 'NI' ? '■' : '❏'} NI`,
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                  })
                            ],
                            verticalAlign: VerticalAlign.TOP
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: ` ${this.getValue(submission, 'plan_organico_procesamiento_registros_7_conformidad') === 'C' ? '■' : '❏'} C`,
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                  }),
                                  new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: ` ${this.getValue(submission, 'plan_organico_procesamiento_registros_7_conformidad') === 'NC' ? '■' : '❏'} NC`,
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                  }),
                                  new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: ` ${this.getValue(submission, 'plan_organico_procesamiento_registros_7_conformidad') === 'NA' ? '■' : '❏'} NA`,
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                  })
                            ],
                            verticalAlign: VerticalAlign.TOP
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: this.getValue(submission, 'plan_organico_procesamiento_registros_7_comentarios_inspector'),
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                  })
                            ],
                            verticalAlign: VerticalAlign.TOP
                        })
                    ]
                }),
                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: '8',
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                })
                            ],
                            verticalAlign: VerticalAlign.TOP
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: 'He incluido una copia de mi certificado buenas prácticas de higiene y manejo.',
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                })
                            ],
                            verticalAlign: VerticalAlign.TOP
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: this.getValue(submission, 'plan_organico_procesamiento_registros_8_declaracion') === 'Si' ? '■ SÍ  ❏ NO' : '❏ SÍ  ■ NO',
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                })
                            ],
                            verticalAlign: VerticalAlign.TOP
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: this.getValue(submission, 'plan_organico_procesamiento_registros_8_comentarios'),
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                })
                            ],
                            verticalAlign: VerticalAlign.TOP
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: ` ${this.getValue(submission, 'plan_organico_procesamiento_registros_8_implementacion') === 'I' ? '■' : '❏'} I`,
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                  }),
                                  new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: ` ${this.getValue(submission, 'plan_organico_procesamiento_registros_8_implementacion') === 'NI' ? '■' : '❏'} NI`,
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                  })
                            ],
                            verticalAlign: VerticalAlign.TOP
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: ` ${this.getValue(submission, 'plan_organico_procesamiento_registros_8_conformidad') === 'C' ? '■' : '❏'} C`,
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                  }),
                                  new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: ` ${this.getValue(submission, 'plan_organico_procesamiento_registros_8_conformidad') === 'NC' ? '■' : '❏'} NC`,
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                  }),
                                  new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: ` ${this.getValue(submission, 'plan_organico_procesamiento_registros_8_conformidad') === 'NA' ? '■' : '❏'} NA`,
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                  })
                            ],
                            verticalAlign: VerticalAlign.TOP
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: this.getValue(submission, 'plan_organico_procesamiento_registros_8_comentarios_inspector'),
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                  })
                            ],
                            verticalAlign: VerticalAlign.TOP
                        })
                    ]
                }),
                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: '9',
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                })
                            ],
                            verticalAlign: VerticalAlign.TOP
                        }),
                        new TableCell({
                            children: [
                                new Table({
                                    rows: [
                                        new TableRow({
                                            children: [
                                                new TableCell({
                                                    children: [
                                                        new Paragraph({
                                                            alignment: AlignmentType.LEFT,
                                                            children: [
                                                                new TextRun({
                                                                    text: 'Cuento con las siguientes bitácoras:',
                                                                    font: 'Arial',
                                                                    size: 18
                                                                })
                                                            ]
                                                        })
                                                    ],
                                                    borders: {
                                                        left: {
                                                            style: BorderStyle.NONE,
                                                            color: "white",
                                                            size: 0
                                                        },
                                                        top: {
                                                            style: BorderStyle.NONE,
                                                            color: "white",
                                                            size: 0
                                                        },
                                                        bottom: {
                                                            style: BorderStyle.NONE,
                                                            color: "white",
                                                            size: 0
                                                        }
                                                    }
                                                }),
                                                new TableCell({
                                                    children: [
                                                        new Paragraph({
                                                            alignment: AlignmentType.CENTER,
                                                            children: [
                                                                new TextRun({
                                                                    text: '',
                                                                    font: 'Arial',
                                                                    size: 18
                                                                })
                                                            ]
                                                        })
                                                    ],
                                                    borders: {
                                                        right: {
                                                            style: BorderStyle.NONE,
                                                            color: "white",
                                                            size: 0
                                                        },
                                                        top: {
                                                            style: BorderStyle.NONE,
                                                            color: "white",
                                                            size: 0
                                                        },
                                                        bottom: {
                                                            style: BorderStyle.NONE,
                                                            color: "white",
                                                            size: 0
                                                        }
                                                    }
                                                })
                                            ]
                                        }),
                                        new TableRow({
                                            children: [
                                                new TableCell({
                                                    children: [
                                                        new Paragraph({
                                                            alignment: AlignmentType.LEFT,
                                                            children: [
                                                                new TextRun({
                                                                    text: `${this.getValue(submission, 'plan_organico_procesamiento_registros_9_a_check') ? '■' : '❏'} Compra e inventario de ingredientes`,
                                                                    font: 'Arial',
                                                                    size: 18
                                                                })
                                                            ]
                                                        })
                                                    ],
                                                    borders: {
                                                        left: {
                                                            style: BorderStyle.NONE,
                                                            color: "white",
                                                            size: 0
                                                        },
                                                        top: {
                                                            style: BorderStyle.NONE,
                                                            color: "white",
                                                            size: 0
                                                        },
                                                        bottom: {
                                                            style: BorderStyle.NONE,
                                                            color: "white",
                                                            size: 0
                                                        }
                                                    }
                                                }),
                                                new TableCell({
                                                    children: [
                                                        new Paragraph({
                                                            alignment: AlignmentType.CENTER,
                                                            children: [
                                                                new TextRun({
                                                                    text: `${this.getValue(submission, 'plan_organico_procesamiento_registros_9_a_declaracion') === "Si" ? '■ Sí ❏ No' : this.getValue(submission, 'plan_organico_procesamiento_registros_9_a_declaracion') === "No" ? '❏ Sí ■ No' : '❏ Sí ❏ No'}`,
                                                                    font: 'Arial',
                                                                    size: 18
                                                                })
                                                            ]
                                                        })
                                                    ],
                                                    borders: {
                                                        right: {
                                                            style: BorderStyle.NONE,
                                                            color: "white",
                                                            size: 0
                                                        },
                                                        top: {
                                                            style: BorderStyle.NONE,
                                                            color: "white",
                                                            size: 0
                                                        },
                                                        bottom: {
                                                            style: BorderStyle.NONE,
                                                            color: "white",
                                                            size: 0
                                                        }
                                                    }
                                                })
                                            ]
                                        }),
                                        new TableRow({
                                            children: [
                                                new TableCell({
                                                    children: [
                                                        new Paragraph({
                                                            alignment: AlignmentType.LEFT,
                                                            children: [
                                                                new TextRun({
                                                                    text: `${this.getValue(submission, 'plan_organico_procesamiento_registros_9_b_check') ? '■' : '❏'} Resultados de proceso`,
                                                                    font: 'Arial',
                                                                    size: 18
                                                                })
                                                            ]
                                                        })
                                                    ],
                                                    borders: {
                                                        left: {
                                                            style: BorderStyle.NONE,
                                                            color: "white",
                                                            size: 0
                                                        },
                                                        top: {
                                                            style: BorderStyle.NONE,
                                                            color: "white",
                                                            size: 0
                                                        },
                                                        bottom: {
                                                            style: BorderStyle.NONE,
                                                            color: "white",
                                                            size: 0
                                                        }
                                                    }
                                                }),
                                                new TableCell({
                                                    children: [
                                                        new Paragraph({
                                                            alignment: AlignmentType.CENTER,
                                                            children: [
                                                                new TextRun({
                                                                    text: `${this.getValue(submission, 'plan_organico_procesamiento_registros_9_b_declaracion') === "Si" ? '■ Sí ❏ No' : this.getValue(submission, 'plan_organico_procesamiento_registros_9_b_declaracion') === "No" ? '❏ Sí ■ No' : '❏ Sí ❏ No'}`,
                                                                    font: 'Arial',
                                                                    size: 18
                                                                })
                                                            ]
                                                        })
                                                    ],
                                                    borders: {
                                                        right: {
                                                            style: BorderStyle.NONE,
                                                            color: "white",
                                                            size: 0
                                                        },
                                                        top: {
                                                            style: BorderStyle.NONE,
                                                            color: "white",
                                                            size: 0
                                                        },
                                                        bottom: {
                                                            style: BorderStyle.NONE,
                                                            color: "white",
                                                            size: 0
                                                        }
                                                    }
                                                })
                                            ]
                                        }),
                                        new TableRow({
                                            children: [
                                                new TableCell({
                                                    children: [
                                                        new Paragraph({
                                                            alignment: AlignmentType.LEFT,
                                                            children: [
                                                                new TextRun({
                                                                    text: `${this.getValue(submission, 'plan_organico_procesamiento_registros_9_c_check') ? '■' : '❏'} Aplicación de insumos (control plagas)`,
                                                                    font: 'Arial',
                                                                    size: 18
                                                                })
                                                            ]
                                                        })
                                                    ],
                                                    borders: {
                                                        left: {
                                                            style: BorderStyle.NONE,
                                                            color: "white",
                                                            size: 0
                                                        },
                                                        top: {
                                                            style: BorderStyle.NONE,
                                                            color: "white",
                                                            size: 0
                                                        },
                                                        bottom: {
                                                            style: BorderStyle.NONE,
                                                            color: "white",
                                                            size: 0
                                                        }
                                                    }
                                                }),
                                                new TableCell({
                                                    children: [
                                                        new Paragraph({
                                                            alignment: AlignmentType.CENTER,
                                                            children: [
                                                                new TextRun({
                                                                    text: `${this.getValue(submission, 'plan_organico_procesamiento_registros_9_c_declaracion') === "Si" ? '■ Sí ❏ No' : this.getValue(submission, 'plan_organico_procesamiento_registros_9_c_declaracion') === "No" ? '❏ Sí ■ No' : '❏ Sí ❏ No'}`,
                                                                    font: 'Arial',
                                                                    size: 18
                                                                })
                                                            ]
                                                        })
                                                    ],
                                                    borders: {
                                                        right: {
                                                            style: BorderStyle.NONE,
                                                            color: "white",
                                                            size: 0
                                                        },
                                                        top: {
                                                            style: BorderStyle.NONE,
                                                            color: "white",
                                                            size: 0
                                                        },
                                                        bottom: {
                                                            style: BorderStyle.NONE,
                                                            color: "white",
                                                            size: 0
                                                        }
                                                    }
                                                })
                                            ]
                                        }),
                                        new TableRow({
                                            children: [
                                                new TableCell({
                                                    children: [
                                                        new Paragraph({
                                                            alignment: AlignmentType.LEFT,
                                                            children: [
                                                                new TextRun({
                                                                    text: `${this.getValue(submission, 'plan_organico_procesamiento_registros_9_d_check') ? '■' : '❏'} Compra e inventario de insumos`,
                                                                    font: 'Arial',
                                                                    size: 18
                                                                })
                                                            ]
                                                        })
                                                    ],
                                                    borders: {
                                                        left: {
                                                            style: BorderStyle.NONE,
                                                            color: "white",
                                                            size: 0
                                                        },
                                                        top: {
                                                            style: BorderStyle.NONE,
                                                            color: "white",
                                                            size: 0
                                                        },
                                                        bottom: {
                                                            style: BorderStyle.NONE,
                                                            color: "white",
                                                            size: 0
                                                        }
                                                    }
                                                }),
                                                new TableCell({
                                                    children: [
                                                        new Paragraph({
                                                            alignment: AlignmentType.CENTER,
                                                            children: [
                                                                new TextRun({
                                                                    text: `${this.getValue(submission, 'plan_organico_procesamiento_registros_9_d_declaracion') === "Si" ? '■ Sí ❏ No' : this.getValue(submission, 'plan_organico_procesamiento_registros_9_d_declaracion') === "No" ? '❏ Sí ■ No' : '❏ Sí ❏ No'}`,
                                                                    font: 'Arial',
                                                                    size: 18
                                                                })
                                                            ]
                                                        })
                                                    ],
                                                    borders: {
                                                        right: {
                                                            style: BorderStyle.NONE,
                                                            color: "white",
                                                            size: 0
                                                        },
                                                        top: {
                                                            style: BorderStyle.NONE,
                                                            color: "white",
                                                            size: 0
                                                        },
                                                        bottom: {
                                                            style: BorderStyle.NONE,
                                                            color: "white",
                                                            size: 0
                                                        }
                                                    }
                                                })
                                            ]
                                        }),
                                        new TableRow({
                                            children: [
                                                new TableCell({
                                                    children: [
                                                        new Paragraph({
                                                            alignment: AlignmentType.LEFT,
                                                            children: [
                                                                new TextRun({
                                                                    text: `${this.getValue(submission, 'plan_organico_procesamiento_registros_9_e_check') ? '■' : '❏'} Limpieza y desinfección`,
                                                                    font: 'Arial',
                                                                    size: 18
                                                                })
                                                            ]
                                                        })
                                                    ],
                                                    borders: {
                                                        left: {
                                                            style: BorderStyle.NONE,
                                                            color: "white",
                                                            size: 0
                                                        },
                                                        top: {
                                                            style: BorderStyle.NONE,
                                                            color: "white",
                                                            size: 0
                                                        },
                                                        bottom: {
                                                            style: BorderStyle.NONE,
                                                            color: "white",
                                                            size: 0
                                                        }
                                                    }
                                                }),
                                                new TableCell({
                                                    children: [
                                                        new Paragraph({
                                                            alignment: AlignmentType.CENTER,
                                                            children: [
                                                                new TextRun({
                                                                    text: `${this.getValue(submission, 'plan_organico_procesamiento_registros_9_e_declaracion') === "Si" ? '■ Sí ❏ No' : this.getValue(submission, 'plan_organico_procesamiento_registros_9_e_declaracion') === "No" ? '❏ Sí ■ No' : '❏ Sí ❏ No'}`,
                                                                    font: 'Arial',
                                                                    size: 18
                                                                })
                                                            ]
                                                        })
                                                    ],
                                                    borders: {
                                                        right: {
                                                            style: BorderStyle.NONE,
                                                            color: "white",
                                                            size: 0
                                                        },
                                                        top: {
                                                            style: BorderStyle.NONE,
                                                            color: "white",
                                                            size: 0
                                                        },
                                                        bottom: {
                                                            style: BorderStyle.NONE,
                                                            color: "white",
                                                            size: 0
                                                        }
                                                    }
                                                })
                                            ]
                                        }),
                                        new TableRow({
                                            children: [
                                                new TableCell({
                                                    children: [
                                                        new Paragraph({
                                                            alignment: AlignmentType.LEFT,
                                                            children: [
                                                                new TextRun({
                                                                    text: `${this.getValue(submission, 'plan_organico_procesamiento_registros_9_f_check') ? '■' : '❏'} Embarque y transporte`,
                                                                    font: 'Arial',
                                                                    size: 18
                                                                })
                                                            ]
                                                        })
                                                    ],
                                                    borders: {
                                                        left: {
                                                            style: BorderStyle.NONE,
                                                            color: "white",
                                                            size: 0
                                                        },
                                                        top: {
                                                            style: BorderStyle.NONE,
                                                            color: "white",
                                                            size: 0
                                                        },
                                                        bottom: {
                                                            style: BorderStyle.NONE,
                                                            color: "white",
                                                            size: 0
                                                        }
                                                    }
                                                }),
                                                new TableCell({
                                                    children: [
                                                        new Paragraph({
                                                            alignment: AlignmentType.CENTER,
                                                            children: [
                                                                new TextRun({
                                                                    text: `${this.getValue(submission, 'plan_organico_procesamiento_registros_9_f_declaracion') === "Si" ? '■ Sí ❏ No' : this.getValue(submission, 'plan_organico_procesamiento_registros_9_f_declaracion') === "No" ? '❏ Sí ■ No' : '❏ Sí ❏ No'}`,
                                                                    font: 'Arial',
                                                                    size: 18
                                                                })
                                                            ]
                                                        })
                                                    ],
                                                    borders: {
                                                        right: {
                                                            style: BorderStyle.NONE,
                                                            color: "white",
                                                            size: 0
                                                        },
                                                        top: {
                                                            style: BorderStyle.NONE,
                                                            color: "white",
                                                            size: 0
                                                        },
                                                        bottom: {
                                                            style: BorderStyle.NONE,
                                                            color: "white",
                                                            size: 0
                                                        }
                                                    }
                                                })
                                            ]
                                        }),
                                        new TableRow({
                                            children: [
                                                new TableCell({
                                                    children: [
                                                        new Paragraph({
                                                            alignment: AlignmentType.LEFT,
                                                            children: [
                                                                new TextRun({
                                                                    text: `${this.getValue(submission, 'plan_organico_procesamiento_registros_9_g_check') ? '■' : '❏'} Ventas`,
                                                                    font: 'Arial',
                                                                    size: 18
                                                                })
                                                            ]
                                                        })
                                                    ],
                                                    borders: {
                                                        left: {
                                                            style: BorderStyle.NONE,
                                                            color: "white",
                                                            size: 0
                                                        },
                                                        top: {
                                                            style: BorderStyle.NONE,
                                                            color: "white",
                                                            size: 0
                                                        },
                                                        bottom: {
                                                            style: BorderStyle.NONE,
                                                            color: "white",
                                                            size: 0
                                                        }
                                                    }
                                                }),
                                                new TableCell({
                                                    children: [
                                                        new Paragraph({
                                                            alignment: AlignmentType.CENTER,
                                                            children: [
                                                                new TextRun({
                                                                    text: `${this.getValue(submission, 'plan_organico_procesamiento_registros_9_g_declaracion') === "Si" ? '■ Sí ❏ No' : this.getValue(submission, 'plan_organico_procesamiento_registros_9_g_declaracion') === "No" ? '❏ Sí ■ No' : '❏ Sí ❏ No'}`,
                                                                    font: 'Arial',
                                                                    size: 18
                                                                })
                                                            ]
                                                        })
                                                    ],
                                                    borders: {
                                                        right: {
                                                            style: BorderStyle.NONE,
                                                            color: "white",
                                                            size: 0
                                                        },
                                                        top: {
                                                            style: BorderStyle.NONE,
                                                            color: "white",
                                                            size: 0
                                                        },
                                                        bottom: {
                                                            style: BorderStyle.NONE,
                                                            color: "white",
                                                            size: 0
                                                        }
                                                    }
                                                })
                                            ]
                                        }),
                                        new TableRow({
                                            children: [
                                                new TableCell({
                                                    children: [
                                                        new Paragraph({
                                                            alignment: AlignmentType.LEFT,
                                                            children: [
                                                                new TextRun({
                                                                    text: `${this.getValue(submission, 'plan_organico_procesamiento_registros_9_h_check') ? '■' : '❏'} Quejas`,
                                                                    font: 'Arial',
                                                                    size: 18
                                                                })
                                                            ]
                                                        })
                                                    ],
                                                    borders: {
                                                        left: {
                                                            style: BorderStyle.NONE,
                                                            color: "white",
                                                            size: 0
                                                        },
                                                        top: {
                                                            style: BorderStyle.NONE,
                                                            color: "white",
                                                            size: 0
                                                        },
                                                        bottom: {
                                                            style: BorderStyle.NONE,
                                                            color: "white",
                                                            size: 0
                                                        }
                                                    }
                                                }),
                                                new TableCell({
                                                    children: [
                                                        new Paragraph({
                                                            alignment: AlignmentType.CENTER,
                                                            children: [
                                                                new TextRun({
                                                                    text: `${this.getValue(submission, 'plan_organico_procesamiento_registros_9_h_declaracion') === "Si" ? '■ Sí ❏ No' : this.getValue(submission, 'plan_organico_procesamiento_registros_9_h_declaracion') === "No" ? '❏ Sí ■ No' : '❏ Sí ❏ No'}`,
                                                                    font: 'Arial',
                                                                    size: 18
                                                                })
                                                            ]
                                                        })
                                                    ],
                                                    borders: {
                                                        right: {
                                                            style: BorderStyle.NONE,
                                                            color: "white",
                                                            size: 0
                                                        },
                                                        top: {
                                                            style: BorderStyle.NONE,
                                                            color: "white",
                                                            size: 0
                                                        },
                                                        bottom: {
                                                            style: BorderStyle.NONE,
                                                            color: "white",
                                                            size: 0
                                                        }
                                                    }
                                                })
                                            ]
                                        }),
                                        new TableRow({
                                            children: [
                                                new TableCell({
                                                    children: [
                                                        new Paragraph({
                                                            alignment: AlignmentType.LEFT,
                                                            children: [
                                                                new TextRun({
                                                                    text: `${this.getValue(submission, 'plan_organico_procesamiento_registros_9_otro_check') ? '■' : '❏'} Otra (¿Cuál?): ${this.getValue(submission, 'plan_organico_procesamiento_registros_9_otro')}`,
                                                                    font: 'Arial',
                                                                    size: 18
                                                                })
                                                            ]
                                                        })
                                                    ],
                                                    borders: {
                                                        left: {
                                                            style: BorderStyle.NONE,
                                                            color: "white",
                                                            size: 0
                                                        },
                                                        top: {
                                                            style: BorderStyle.NONE,
                                                            color: "white",
                                                            size: 0
                                                        },
                                                        bottom: {
                                                            style: BorderStyle.NONE,
                                                            color: "white",
                                                            size: 0
                                                        }
                                                    }
                                                }),
                                                new TableCell({
                                                    children: [
                                                        new Paragraph({
                                                            alignment: AlignmentType.CENTER,
                                                            children: [
                                                                new TextRun({
                                                                    text: `${this.getValue(submission, 'plan_organico_procesamiento_registros_9_otro_declaracion') === "Si" ? '■ Sí ❏ No' : this.getValue(submission, 'plan_organico_procesamiento_registros_9_otro_declaracion') === "No" ? '❏ Sí ■ No' : '❏ Sí ❏ No'}`,
                                                                    font: 'Arial',
                                                                    size: 18
                                                                })
                                                            ]
                                                        })
                                                    ],
                                                    borders: {
                                                        right: {
                                                            style: BorderStyle.NONE,
                                                            color: "white",
                                                            size: 0
                                                        },
                                                        top: {
                                                            style: BorderStyle.NONE,
                                                            color: "white",
                                                            size: 0
                                                        },
                                                        bottom: {
                                                            style: BorderStyle.NONE,
                                                            color: "white",
                                                            size: 0
                                                        }
                                                    }
                                                })
                                            ]
                                        })
                                    ],
                                    //4395
                                    columnWidths: [3075, 1320]
                                })
                            ],
                            columnSpan: 2
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: this.getValue(submission, 'plan_organico_vegetal_registros_9_comentarios'),
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                })
                            ],
                            verticalAlign: VerticalAlign.TOP
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: ` ${this.getValue(submission, 'plan_organico_procesamiento_registros_9_implementacion') === 'I' ? '■' : '❏'} I`,
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                  }),
                                  new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: ` ${this.getValue(submission, 'plan_organico_procesamiento_registros_9_implementacion') === 'NI' ? '■' : '❏'} NI`,
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                  })
                            ],
                            verticalAlign: VerticalAlign.TOP
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: ` ${this.getValue(submission, 'plan_organico_procesamiento_registros_9_conformidad') === 'C' ? '■' : '❏'} C`,
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                  }),
                                  new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: ` ${this.getValue(submission, 'plan_organico_procesamiento_registros_9_conformidad') === 'NC' ? '■' : '❏'} NC`,
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                  }),
                                  new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: ` ${this.getValue(submission, 'plan_organico_procesamiento_registros_9_conformidad') === 'NA' ? '■' : '❏'} NA`,
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                  })
                            ],
                            verticalAlign: VerticalAlign.TOP
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: this.getValue(submission, 'plan_organico_procesamiento_registros_9_comentarios_inspector'),
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                  })
                            ],
                            verticalAlign: VerticalAlign.TOP
                        })
                    ]
                }),
                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: '10',
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                })
                            ],
                            verticalAlign: VerticalAlign.TOP
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: 'He incluido una lista completa de riesgos para la integridad orgánica de los productos en el formato MTO-FSOL-04',
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                })
                            ],
                            verticalAlign: VerticalAlign.TOP
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: this.getValue(submission, 'plan_organico_procesamiento_registros_10_declaracion') === 'Si' ? '■ SÍ  ❏ NO' : '❏ SÍ  ■ NO',
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                })
                            ],
                            verticalAlign: VerticalAlign.TOP
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: this.getValue(submission, 'plan_organico_procesamiento_registros_10_comentarios'),
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                })
                            ],
                            verticalAlign: VerticalAlign.TOP
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: ` ${this.getValue(submission, 'plan_organico_procesamiento_registros_10_implementacion') === 'I' ? '■' : '❏'} I`,
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                  }),
                                  new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: ` ${this.getValue(submission, 'plan_organico_procesamiento_registros_10_implementacion') === 'NI' ? '■' : '❏'} NI`,
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                  })
                            ],
                            verticalAlign: VerticalAlign.TOP
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: ` ${this.getValue(submission, 'plan_organico_procesamiento_registros_10_conformidad') === 'C' ? '■' : '❏'} C`,
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                  }),
                                  new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: ` ${this.getValue(submission, 'plan_organico_procesamiento_registros_10_conformidad') === 'NC' ? '■' : '❏'} NC`,
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                  }),
                                  new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: ` ${this.getValue(submission, 'plan_organico_procesamiento_registros_10_conformidad') === 'NA' ? '■' : '❏'} NA`,
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                  })
                            ],
                            verticalAlign: VerticalAlign.TOP
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: this.getValue(submission, 'plan_organico_procesamiento_registros_10_comentarios_inspector'),
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                  })
                            ],
                            verticalAlign: VerticalAlign.TOP
                        })
                    ]
                }),
                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: '11',
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                })
                            ],
                            verticalAlign: VerticalAlign.TOP
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: 'He incluido una lista completa de las sustancias que utilizo para la limpieza, desinfección, el control de plagas en el formato MTO-FSOL-07 y en anexo a este plan.',
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                })
                            ],
                            verticalAlign: VerticalAlign.TOP
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: this.getValue(submission, 'plan_organico_procesamiento_registros_11_declaracion') === 'Si' ? '■ SÍ  ❏ NO' : '❏ SÍ  ■ NO',
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                })
                            ],
                            verticalAlign: VerticalAlign.TOP
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: this.getValue(submission, 'plan_organico_procesamiento_registros_11_comentarios'),
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                })
                            ],
                            verticalAlign: VerticalAlign.TOP
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: ` ${this.getValue(submission, 'plan_organico_procesamiento_registros_11_implementacion') === 'I' ? '■' : '❏'} I`,
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                  }),
                                  new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: ` ${this.getValue(submission, 'plan_organico_procesamiento_registros_11_implementacion') === 'NI' ? '■' : '❏'} NI`,
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                  })
                            ],
                            verticalAlign: VerticalAlign.TOP
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: ` ${this.getValue(submission, 'plan_organico_procesamiento_registros_11_conformidad') === 'C' ? '■' : '❏'} C`,
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                  }),
                                  new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: ` ${this.getValue(submission, 'plan_organico_procesamiento_registros_11_conformidad') === 'NC' ? '■' : '❏'} NC`,
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                  }),
                                  new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: ` ${this.getValue(submission, 'plan_organico_procesamiento_registros_11_conformidad') === 'NA' ? '■' : '❏'} NA`,
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                  })
                            ],
                            verticalAlign: VerticalAlign.TOP
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: this.getValue(submission, 'plan_organico_procesamiento_registros_11_comentarios_inspector'),
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                  })
                            ],
                            verticalAlign: VerticalAlign.TOP
                        })
                    ]
                }),

                

                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: '12',
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                })
                            ],
                            verticalAlign: VerticalAlign.TOP
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: 'He incluido una copia de la etiqueta que planeo usar en los productos a certificar',
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                })
                            ],
                            verticalAlign: VerticalAlign.TOP
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: this.getValue(submission, 'plan_organico_procesamiento_registros_12_declaracion') === 'Si' ? '■ SÍ  ❏ NO' : '❏ SÍ  ■ NO',
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                })
                            ],
                            verticalAlign: VerticalAlign.TOP
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: this.getValue(submission, 'plan_organico_procesamiento_registros_12_comentarios'),
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                })
                            ],
                            verticalAlign: VerticalAlign.TOP
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: ` ${this.getValue(submission, 'plan_organico_procesamiento_registros_12_implementacion') === 'I' ? '■' : '❏'} I`,
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                  }),
                                  new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: ` ${this.getValue(submission, 'plan_organico_procesamiento_registros_12_implementacion') === 'NI' ? '■' : '❏'} NI`,
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                  })
                            ],
                            verticalAlign: VerticalAlign.TOP
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: ` ${this.getValue(submission, 'plan_organico_procesamiento_registros_12_conformidad') === 'C' ? '■' : '❏'} C`,
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                  }),
                                  new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: ` ${this.getValue(submission, 'plan_organico_procesamiento_registros_12_conformidad') === 'NC' ? '■' : '❏'} NC`,
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                  }),
                                  new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: ` ${this.getValue(submission, 'plan_organico_procesamiento_registros_12_conformidad') === 'NA' ? '■' : '❏'} NA`,
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                  })
                            ],
                            verticalAlign: VerticalAlign.TOP
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: this.getValue(submission, 'plan_organico_procesamiento_registros_12_comentarios_inspector'),
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                  })
                            ],
                            verticalAlign: VerticalAlign.TOP
                        })
                    ]
                }),
                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: '13',
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                })
                            ],
                            verticalAlign: VerticalAlign.TOP
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: 'He agregado una copia de los siguientes documentos y registros:',
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                }),
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: '1.- Procedimiento de manufactura identificando fases de transformación críticas',
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                }),
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: '2.- Procedimiento de limpieza de instalaciones',
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                }),
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: '3. Procedimiento de trazabilidad, rastreabilidad o recall (recuperación) de producto',
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                }),
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: '4.- Copia de un ejemplo de registro de trazabilidad de un lote de producto terminado',
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                })
                            ],
                            verticalAlign: VerticalAlign.TOP
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: this.getValue(submission, 'plan_organico_procesamiento_registros_13_declaracion') === 'Si' ? '■ SÍ  ❏ NO' : '❏ SÍ  ■ NO',
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                })
                            ],
                            verticalAlign: VerticalAlign.TOP
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: this.getValue(submission, 'plan_organico_procesamiento_registros_13_comentarios'),
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                })
                            ],
                            verticalAlign: VerticalAlign.TOP
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: ` ${this.getValue(submission, 'plan_organico_procesamiento_registros_13_implementacion') === 'I' ? '■' : '❏'} I`,
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                  }),
                                  new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: ` ${this.getValue(submission, 'plan_organico_procesamiento_registros_13_implementacion') === 'NI' ? '■' : '❏'} NI`,
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                  })
                            ],
                            verticalAlign: VerticalAlign.TOP
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: ` ${this.getValue(submission, 'plan_organico_procesamiento_registros_13_conformidad') === 'C' ? '■' : '❏'} C`,
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                  }),
                                  new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: ` ${this.getValue(submission, 'plan_organico_procesamiento_registros_13_conformidad') === 'NC' ? '■' : '❏'} NC`,
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                  }),
                                  new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: ` ${this.getValue(submission, 'plan_organico_procesamiento_registros_13_conformidad') === 'NA' ? '■' : '❏'} NA`,
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                  })
                            ],
                            verticalAlign: VerticalAlign.TOP
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: this.getValue(submission, 'plan_organico_procesamiento_registros_13_comentarios_inspector'),
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                  })
                            ],
                            verticalAlign: VerticalAlign.TOP
                        })
                    ]
                })
            ],
            margins: {
                left: 70,
                right: 70
            },
            //9885
            columnWidths: [450, 3050, 1345, 1545, 1150, 800, 1545]
        })

        return table
    }

    public createHandling(submission): Table {
        const table = new Table({
            rows: [
                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: 'MANEJO',
                                            font: 'Arial',
                                            size: 20,
                                            bold: true
                                        })
                                    ],
                                    spacing: {
                                        after: 75,
                                        before: 75
                                    }
                                }),
                            ],
                            verticalAlign: VerticalAlign.CENTER,
                            columnSpan: 5,
                            shading: {
                                fill: '#C0C0C0'
                            }
                        })
                    ]
                }),
                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: 'No.',
                                            font: 'Arial',
                                            size: 18,
                                            bold: true
                                        })
                                    ]
                                })
                            ],
                            rowSpan: 2,
                            verticalAlign: VerticalAlign.CENTER,
                            width: {
                                size: 25,
                                type: WidthType.PERCENTAGE
                            }
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: 'Requisitos',
                                            font: 'Arial',
                                            size: 18,
                                            bold: true
                                        })
                                    ]
                                })
                            ],
                            rowSpan: 2,
                            verticalAlign: VerticalAlign.CENTER,
                            width: {
                                size: 15,
                                type: WidthType.PERCENTAGE
                            }
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: 'Comentarios',
                                            font: 'Arial',
                                            size: 18,
                                            bold: true
                                        })
                                    ]
                                })
                            ],
                            rowSpan: 2,
                            verticalAlign: VerticalAlign.CENTER,
                            width: {
                                size: 15,
                                type: WidthType.PERCENTAGE
                            }
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: 'Verificación en sitio (Evaluada por Inspector)',
                                            font: 'Arial',
                                            size: 18,
                                            bold: true
                                        })
                                    ]
                                })
                            ],
                            columnSpan: 3,
                            verticalAlign: VerticalAlign.CENTER,
                            width: {
                                size: 15,
                                type: WidthType.PERCENTAGE
                            }
                        })
                    ]
                }),
                new TableRow({
                    children: [new TableCell({
                        children: [
                            new Paragraph({
                                alignment: AlignmentType.CENTER,
                                children: [
                                    new TextRun({
                                        text: 'Implementación',
                                        font: 'Arial',
                                        size: 18,
                                        bold: true
                                    })
                                ]
                            })
                        ],
                        verticalAlign: VerticalAlign.CENTER
                    }),
                    new TableCell({
                        children: [
                            new Paragraph({
                                alignment: AlignmentType.CENTER,
                                children: [
                                    new TextRun({
                                        text: 'Confirmación',
                                        font: 'Arial',
                                        size: 18,
                                        bold: true
                                    })
                                ]
                            })
                        ],
                        verticalAlign: VerticalAlign.CENTER,
                        columnSpan: 2
                    }),]
                }),
                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: '1',
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                })
                            ],
                            verticalAlign: VerticalAlign.TOP
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: 'Los ingredientes de origen agrícola son:',
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                }),
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: (this.getValue(submission, "plan_organico_procesamiento_manejo_1_organicos") ? '■' : '❏') + '  Orgánicos',
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                }),
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: (this.getValue(submission, "plan_organico_procesamiento_manejo_1_convencionales_permitidos") ? '■' : '❏') + '  Convencionales permitidos',
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                }),
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: (this.getValue(submission, "plan_organico_procesamiento_manejo_1_convencional_no_permitido") ? '■' : '❏') + '  Convencional no permitido',
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                }),
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: (this.getValue(submission, "plan_organico_procesamiento_manejo_1_otro") ? '■' : '❏') + ' Otro (¿Cuál?): ' + this.getValue(submission, "plan_organico_procesamiento_manejo_1_otro_text"),
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                }),
                            ],
                            verticalAlign: VerticalAlign.TOP
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: this.getValue(submission, "plan_organico_procesamiento_manejo_1_comentarios"),
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                })
                            ],
                            verticalAlign: VerticalAlign.TOP
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: ` ${this.getValue(submission, 'plan_organico_procesamiento_manejo_1_implementacion') === 'I' ? '■' : '❏'} I`,
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                  }),
                                  new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: ` ${this.getValue(submission, 'plan_organico_procesamiento_manejo_1_implementacion') === 'NI' ? '■' : '❏'} NI`,
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                  })
                            ],
                            verticalAlign: VerticalAlign.TOP
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: ` ${this.getValue(submission, 'plan_organico_procesamiento_manejo_1_conformidad') === 'C' ? '■' : '❏'} C`,
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                  }),
                                  new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: ` ${this.getValue(submission, 'plan_organico_procesamiento_manejo_1_conformidad') === 'NC' ? '■' : '❏'} NC`,
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                  }),
                                  new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: ` ${this.getValue(submission, 'plan_organico_procesamiento_manejo_1_conformidad') === 'NA' ? '■' : '❏'} NA`,
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                  })
                            ],
                            verticalAlign: VerticalAlign.TOP
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: this.getValue(submission, 'plan_organico_procesamiento_manejo_1_comentarios_inspector'),
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                  })
                            ],
                            verticalAlign: VerticalAlign.TOP
                        })
                    ]
                }),
                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: '2',
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                })
                            ],
                            verticalAlign: VerticalAlign.TOP
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: 'Los ingredientes de origen no-agrícola son:                                   ',
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                }),
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: (this.getValue(submission, "plan_organico_procesamiento_manejo_2_listados") ? '■' : '❏') + ' Listados (permitidos)',
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                }),
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: (this.getValue(submission, "plan_organico_procesamiento_manejo_2_no_listados") ? '■' : '❏') + ' No listados (No permitido)',
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                }),
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: (this.getValue(submission, "plan_organico_procesamiento_manejo_2_otro") ? '■' : '❏') + ' Otro (¿Cuál?): ' + this.getValue(submission, "plan_organico_procesamiento_manejo_2_otro_text"),
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                }),
                            ],
                            verticalAlign: VerticalAlign.TOP
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: this.getValue(submission, "plan_organico_procesamiento_manejo_2_comentarios"),
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                })
                            ],
                            verticalAlign: VerticalAlign.TOP
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: ` ${this.getValue(submission, 'plan_organico_procesamiento_manejo_2_implementacion') === 'I' ? '■' : '❏'} I`,
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                  }),
                                  new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: ` ${this.getValue(submission, 'plan_organico_procesamiento_manejo_2_implementacion') === 'NI' ? '■' : '❏'} NI`,
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                  })
                            ],
                            verticalAlign: VerticalAlign.TOP
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: ` ${this.getValue(submission, 'plan_organico_procesamiento_manejo_2_conformidad') === 'C' ? '■' : '❏'} C`,
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                  }),
                                  new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: ` ${this.getValue(submission, 'plan_organico_procesamiento_manejo_2_conformidad') === 'NC' ? '■' : '❏'} NC`,
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                  }),
                                  new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: ` ${this.getValue(submission, 'plan_organico_procesamiento_manejo_2_conformidad') === 'NA' ? '■' : '❏'} NA`,
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                  })
                            ],
                            verticalAlign: VerticalAlign.TOP
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: this.getValue(submission, 'plan_organico_procesamiento_manejo_2_comentarios_inspector'),
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                  })
                            ],
                            verticalAlign: VerticalAlign.TOP
                        })
                    ]
                }),
                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: '3',
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                })
                            ],
                            verticalAlign: VerticalAlign.TOP
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: 'En el almacén de materias primas se tienen:',
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                }),
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: (this.getValue(submission, "plan_organico_procesamiento_manejo_3_separadas_rotuladas") ? '■' : '❏') + '  Áreas separadas y rotuladas',
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                }),
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: (this.getValue(submission, "plan_organico_procesamiento_manejo_3_separadas") ? '■' : '❏') + '  Áreas separadas',
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                }),
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: (this.getValue(submission, "plan_organico_procesamiento_manejo_3_no_separadas") ? '■' : '❏') + '  Áreas no separadas ni rotuladas',
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                }),
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: (this.getValue(submission, "plan_organico_procesamiento_manejo_3_otro") ? '■' : '❏') + ' Otro (¿Cuál?): ' + this.getValue(submission, "plan_organico_procesamiento_manejo_3_otro_text"),
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                }),
                            ],
                            verticalAlign: VerticalAlign.TOP
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: this.getValue(submission, "plan_organico_procesamiento_manejo_3_comentarios"),
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                })
                            ],
                            verticalAlign: VerticalAlign.TOP
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: ` ${this.getValue(submission, 'plan_organico_procesamiento_manejo_3_implementacion') === 'I' ? '■' : '❏'} I`,
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                  }),
                                  new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: ` ${this.getValue(submission, 'plan_organico_procesamiento_manejo_3_implementacion') === 'NI' ? '■' : '❏'} NI`,
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                  })
                            ],
                            verticalAlign: VerticalAlign.TOP
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: ` ${this.getValue(submission, 'plan_organico_procesamiento_manejo_3_conformidad') === 'C' ? '■' : '❏'} C`,
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                  }),
                                  new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: ` ${this.getValue(submission, 'plan_organico_procesamiento_manejo_3_conformidad') === 'NC' ? '■' : '❏'} NC`,
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                  }),
                                  new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: ` ${this.getValue(submission, 'plan_organico_procesamiento_manejo_3_conformidad') === 'NA' ? '■' : '❏'} NA`,
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                  })
                            ],
                            verticalAlign: VerticalAlign.TOP
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: this.getValue(submission, 'plan_organico_procesamiento_manejo_3_comentarios_inspector'),
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                  })
                            ],
                            verticalAlign: VerticalAlign.TOP
                        })
                    ]
                }),
                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: '4',
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                })
                            ],
                            verticalAlign: VerticalAlign.TOP
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: 'En el almacén de producto terminado se tienen:',
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                }),
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: (this.getValue(submission, "plan_organico_procesamiento_manejo_4_separadas_rotuladas") ? '■' : '❏') + '  Áreas separadas y rotuladas',
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                }),
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: (this.getValue(submission, "plan_organico_procesamiento_manejo_4_separadas") ? '■' : '❏') + '  Áreas separadas',
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                }),
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: (this.getValue(submission, "plan_organico_procesamiento_manejo_4_no_separadas") ? '■' : '❏') + '  Áreas no separadas ni rotuladas',
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                }),
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: (this.getValue(submission, "plan_organico_procesamiento_manejo_4_otro") ? '■' : '❏') + ' Otro (¿Cuál?): ' + this.getValue(submission, "plan_organico_procesamiento_manejo_4_otro_text"),
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                }),
                            ],
                            verticalAlign: VerticalAlign.TOP
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: this.getValue(submission, "plan_organico_procesamiento_manejo_4_comentarios"),
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                })
                            ],
                            verticalAlign: VerticalAlign.TOP
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: ` ${this.getValue(submission, 'plan_organico_procesamiento_manejo_4_implementacion') === 'I' ? '■' : '❏'} I`,
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                  }),
                                  new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: ` ${this.getValue(submission, 'plan_organico_procesamiento_manejo_4_implementacion') === 'NI' ? '■' : '❏'} NI`,
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                  })
                            ],
                            verticalAlign: VerticalAlign.TOP
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: ` ${this.getValue(submission, 'plan_organico_procesamiento_manejo_4_conformidad') === 'C' ? '■' : '❏'} C`,
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                  }),
                                  new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: ` ${this.getValue(submission, 'plan_organico_procesamiento_manejo_4_conformidad') === 'NC' ? '■' : '❏'} NC`,
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                  }),
                                  new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: ` ${this.getValue(submission, 'plan_organico_procesamiento_manejo_4_conformidad') === 'NA' ? '■' : '❏'} NA`,
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                  })
                            ],
                            verticalAlign: VerticalAlign.TOP
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: this.getValue(submission, 'plan_organico_procesamiento_manejo_4_comentarios_inspector'),
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                  })
                            ],
                            verticalAlign: VerticalAlign.TOP
                        })
                    ]
                }),
                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: '5',
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                })
                            ],
                            verticalAlign: VerticalAlign.TOP
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: 'La maquinaria y equipo son',
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                }),
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: (this.getValue(submission, "plan_organico_procesamiento_manejo_5_exclusivo_organico") ? '■' : '❏') + ' Exclusivos orgánico',
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                }),
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: (this.getValue(submission, "plan_organico_procesamiento_manejo_5_organico_y_convecional") ? '■' : '❏') + ' Usados en orgánico y convencional con proceso de purga y limpia ',
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                }),
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: (this.getValue(submission, "plan_organico_procesamiento_manejo_5_organico_y_convencional_sin_purga") ? '■' : '❏') + ' Usados en orgánico y convencional sin purga y limpia',
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                }),
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: (this.getValue(submission, "plan_organico_procesamiento_manejo_5_otro") ? '■' : '❏') + ' Otro (¿Cuál?): ' + this.getValue(submission, "plan_organico_procesamiento_manejo_5_otro_text"),
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                })
                            ],
                            verticalAlign: VerticalAlign.TOP
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: this.getValue(submission, "plan_organico_procesamiento_manejo_5_comentarios"),
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                })
                            ],
                            verticalAlign: VerticalAlign.TOP
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: ` ${this.getValue(submission, 'plan_organico_procesamiento_manejo_5_implementacion') === 'I' ? '■' : '❏'} I`,
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                  }),
                                  new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: ` ${this.getValue(submission, 'plan_organico_procesamiento_manejo_5_implementacion') === 'NI' ? '■' : '❏'} NI`,
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                  })
                            ],
                            verticalAlign: VerticalAlign.TOP
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: ` ${this.getValue(submission, 'plan_organico_procesamiento_manejo_5_conformidad') === 'C' ? '■' : '❏'} C`,
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                  }),
                                  new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: ` ${this.getValue(submission, 'plan_organico_procesamiento_manejo_5_conformidad') === 'NC' ? '■' : '❏'} NC`,
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                  }),
                                  new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: ` ${this.getValue(submission, 'plan_organico_procesamiento_manejo_5_conformidad') === 'NA' ? '■' : '❏'} NA`,
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                  })
                            ],
                            verticalAlign: VerticalAlign.TOP
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: this.getValue(submission, 'plan_organico_procesamiento_manejo_5_comentarios_inspector'),
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                  })
                            ],
                            verticalAlign: VerticalAlign.TOP
                        })
                    ]
                }),
                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: '6',
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                })
                            ],
                            verticalAlign: VerticalAlign.TOP
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: 'El agua de proceso es:',
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                }),
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: (this.getValue(submission, "plan_organico_procesamiento_manejo_6_potable") ? '■' : '❏') + ' Calidad potable',
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                }),
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: (this.getValue(submission, "plan_organico_procesamiento_manejo_6_no_controlada") ? '■' : '❏') + ' Calidad no controlada',
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                }),
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: (this.getValue(submission, "plan_organico_procesamiento_manejo_6_otro") ? '■' : '❏') + ' Otro (¿Cuál?): ' + this.getValue(submission, "plan_organico_procesamiento_manejo_6_otro_text"),
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                })
                            ],
                            verticalAlign: VerticalAlign.TOP
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: this.getValue(submission, "plan_organico_procesamiento_manejo_6_comentarios_1"),
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                })
                            ],
                            verticalAlign: VerticalAlign.TOP
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: ` ${this.getValue(submission, 'plan_organico_procesamiento_manejo_6_implementacion_1') === 'I' ? '■' : '❏'} I`,
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                  }),
                                  new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: ` ${this.getValue(submission, 'plan_organico_procesamiento_manejo_6_implementacion_1') === 'NI' ? '■' : '❏'} NI`,
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                  })
                            ],
                            verticalAlign: VerticalAlign.TOP
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: ` ${this.getValue(submission, 'plan_organico_procesamiento_manejo_6_conformidad_1') === 'C' ? '■' : '❏'} C`,
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                  }),
                                  new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: ` ${this.getValue(submission, 'plan_organico_procesamiento_manejo_6_conformidad_1') === 'NC' ? '■' : '❏'} NC`,
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                  }),
                                  new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: ` ${this.getValue(submission, 'plan_organico_procesamiento_manejo_6_conformidad_1') === 'NA' ? '■' : '❏'} NA`,
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                  })
                            ],
                            verticalAlign: VerticalAlign.TOP
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: this.getValue(submission, 'plan_organico_procesamiento_manejo_6_comentarios_inspector_1'),
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                  })
                            ],
                            verticalAlign: VerticalAlign.TOP
                        })
                    ]
                }),
                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: '',
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                })
                            ],
                            verticalAlign: VerticalAlign.TOP
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: '¿Se realiza alguna actividad de reconstitución del producto?',
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                }),
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: (this.getValue(submission, "plan_organico_procesamiento_manejo_6_reconstruccion_si") ? '■' : '❏') + ' Si (¿Cuál?): ' + this.getValue(submission, "plan_organico_procesamiento_manejo_6_reconstruccion_si_text"),
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                }),
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: (this.getValue(submission, "plan_organico_procesamiento_manejo_6_reconstruccion_no") ? '■' : '❏') + ' No',
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                }),
                            ],
                            verticalAlign: VerticalAlign.TOP
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: this.getValue(submission, "plan_organico_procesamiento_manejo_6_comentarios_2"),
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                })
                            ],
                            verticalAlign: VerticalAlign.TOP
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: ` ${this.getValue(submission, 'plan_organico_procesamiento_manejo_6_implementacion_2') === 'I' ? '■' : '❏'} I`,
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                  }),
                                  new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: ` ${this.getValue(submission, 'plan_organico_procesamiento_manejo_6_implementacion_2') === 'NI' ? '■' : '❏'} NI`,
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                  })
                            ],
                            verticalAlign: VerticalAlign.TOP
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: ` ${this.getValue(submission, 'plan_organico_procesamiento_manejo_6_conformidad_2') === 'C' ? '■' : '❏'} C`,
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                  }),
                                  new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: ` ${this.getValue(submission, 'plan_organico_procesamiento_manejo_6_conformidad_2') === 'NC' ? '■' : '❏'} NC`,
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                  }),
                                  new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: ` ${this.getValue(submission, 'plan_organico_procesamiento_manejo_6_conformidad_2') === 'NA' ? '■' : '❏'} NA`,
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                  })
                            ],
                            verticalAlign: VerticalAlign.TOP
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: this.getValue(submission, 'plan_organico_procesamiento_manejo_6_comentarios_inspector_2'),
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                  })
                            ],
                            verticalAlign: VerticalAlign.TOP
                        })
                    ]
                }),
                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: '7',
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                })
                            ],
                            verticalAlign: VerticalAlign.TOP
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: 'Los materiales de desecho se destinan a:',
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                }),
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: (this.getValue(submission, "plan_organico_procesamiento_manejo_7_tiradero") ? '■' : '❏') + ' Tiradero municipal',
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                }),
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: (this.getValue(submission, "plan_organico_procesamiento_manejo_7_recoleccion") ? '■' : '❏') + ' Sistema de recolección industrial',
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                }),
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: (this.getValue(submission, "plan_organico_procesamiento_manejo_7_sin_control") ? '■' : '❏') + ' Desechados sin control',
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                }),
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: (this.getValue(submission, "plan_organico_procesamiento_manejo_7_otro") ? '■' : '❏') + ' Otro (¿Cuál?): ' + this.getValue(submission, "plan_organico_procesamiento_manejo_7_otro_text"),
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                })
                            ],
                            verticalAlign: VerticalAlign.TOP
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: this.getValue(submission, "plan_organico_procesamiento_manejo_7_comentarios"),
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                })
                            ],
                            verticalAlign: VerticalAlign.TOP
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: ` ${this.getValue(submission, 'plan_organico_procesamiento_manejo_7_implementacion') === 'I' ? '■' : '❏'} I`,
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                  }),
                                  new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: ` ${this.getValue(submission, 'plan_organico_procesamiento_manejo_7_implementacion') === 'NI' ? '■' : '❏'} NI`,
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                  })
                            ],
                            verticalAlign: VerticalAlign.TOP
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: ` ${this.getValue(submission, 'plan_organico_procesamiento_manejo_7_conformidad') === 'C' ? '■' : '❏'} C`,
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                  }),
                                  new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: ` ${this.getValue(submission, 'plan_organico_procesamiento_manejo_7_conformidad') === 'NC' ? '■' : '❏'} NC`,
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                  }),
                                  new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: ` ${this.getValue(submission, 'plan_organico_procesamiento_manejo_7_conformidad') === 'NA' ? '■' : '❏'} NA`,
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                  })
                            ],
                            verticalAlign: VerticalAlign.TOP
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: this.getValue(submission, 'plan_organico_procesamiento_manejo_7_comentarios_inspector'),
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                  })
                            ],
                            verticalAlign: VerticalAlign.TOP
                        })
                    ]
                }),
                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: '8',
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                })
                            ],
                            verticalAlign: VerticalAlign.TOP
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: 'Se realizan las siguientes actividades de limpieza e higiene:',
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                }),
                                new Table({
                                    rows: [
                                        new TableRow({
                                            children: [
                                                new TableCell({
                                                    children: [
                                                        new Paragraph({
                                                            alignment: AlignmentType.CENTER,
                                                            children: [
                                                                new TextRun({
                                                                    text: 'Actividad',
                                                                    font: 'Arial',
                                                                    size: 18,
                                                                    bold: true
                                                                })
                                                            ]
                                                        })
                                                    ],
                                                    verticalAlign: VerticalAlign.CENTER,
                                                    shading: {
                                                        fill: '#C0C0C0'
                                                    }
                                                }),
                                                new TableCell({
                                                    children: [
                                                        new Paragraph({
                                                            alignment: AlignmentType.CENTER,
                                                            children: [
                                                                new TextRun({
                                                                    text: 'Frecuencia',
                                                                    font: 'Arial',
                                                                    size: 18,
                                                                    bold: true
                                                                })
                                                            ]
                                                        })
                                                    ],
                                                    verticalAlign: VerticalAlign.CENTER,
                                                    shading: {
                                                        fill: '#C0C0C0'
                                                    }
                                                }),
                                            ]
                                        }),
                                        new TableRow({
                                            children: [
                                                new TableCell({
                                                    children: [
                                                        new Paragraph({
                                                            alignment: AlignmentType.LEFT,
                                                            children: [
                                                                new TextRun({
                                                                    text: (this.getValue(submission, "plan_organico_procesamiento_manejo_8_limpieza_exterior_frecuencia") ? '■' : '❏') + ' Limpieza exterior',
                                                                    font: 'Arial',
                                                                    size: 18,
                                                                })
                                                            ]
                                                        })
                                                    ],
                                                    verticalAlign: VerticalAlign.CENTER,
                                                }),
                                                new TableCell({
                                                    children: [
                                                        new Paragraph({
                                                            alignment: AlignmentType.LEFT,
                                                            children: [
                                                                new TextRun({
                                                                    text: `${this.getValue(submission, "plan_organico_procesamiento_manejo_8_limpieza_exterior_frecuencia")}`,
                                                                    font: 'Arial',
                                                                    size: 18
                                                                })
                                                            ]
                                                        })
                                                    ],
                                                    verticalAlign: VerticalAlign.CENTER,
                                                }),
                                            ]
                                        }),
                                        new TableRow({
                                            children: [
                                                new TableCell({
                                                    children: [
                                                        new Paragraph({
                                                            alignment: AlignmentType.LEFT,
                                                            children: [
                                                                new TextRun({
                                                                    text: (this.getValue(submission, "plan_organico_procesamiento_manejo_8_limpieza_interior_frecuencia_check") ? '■' : '❏') + ' Limpieza interior',
                                                                    font: 'Arial',
                                                                    size: 18,
                                                                })
                                                            ]
                                                        })
                                                    ],
                                                    verticalAlign: VerticalAlign.CENTER,
                                                }),
                                                new TableCell({
                                                    children: [
                                                        new Paragraph({
                                                            alignment: AlignmentType.LEFT,
                                                            children: [
                                                                new TextRun({
                                                                    text: `${this.getValue(submission, "plan_organico_procesamiento_manejo_8_limpieza_interior_frecuencia_check") ? this.getValue(submission, "plan_organico_procesamiento_manejo_8_limpieza_interior_frecuencia") : ''}`,
                                                                    font: 'Arial',
                                                                    size: 18
                                                                })
                                                            ]
                                                        })
                                                    ],
                                                    verticalAlign: VerticalAlign.CENTER,
                                                }),
                                            ]
                                        }),
                                        new TableRow({
                                            children: [
                                                new TableCell({
                                                    children: [
                                                        new Paragraph({
                                                            alignment: AlignmentType.LEFT,
                                                            children: [
                                                                new TextRun({
                                                                    text: (this.getValue(submission, "plan_organico_procesamiento_manejo_8_lavado_equipos_frecuencia_check") ? '■' : '❏') + ' Lavado de equipos',
                                                                    font: 'Arial',
                                                                    size: 18,
                                                                })
                                                            ]
                                                        })
                                                    ],
                                                    verticalAlign: VerticalAlign.CENTER,
                                                }),
                                                new TableCell({
                                                    children: [
                                                        new Paragraph({
                                                            alignment: AlignmentType.LEFT,
                                                            children: [
                                                                new TextRun({
                                                                    text: `${this.getValue(submission, "plan_organico_procesamiento_manejo_8_lavado_equipos_frecuencia_check") ? this.getValue(submission, "plan_organico_procesamiento_manejo_8_lavado_equipos_frecuencia") : ''}`,
                                                                    font: 'Arial',
                                                                    size: 18
                                                                })
                                                            ]
                                                        })
                                                    ],
                                                    verticalAlign: VerticalAlign.CENTER,
                                                }),
                                            ]
                                        }),
                                        new TableRow({
                                            children: [
                                                new TableCell({
                                                    children: [
                                                        new Paragraph({
                                                            alignment: AlignmentType.LEFT,
                                                            children: [
                                                                new TextRun({
                                                                    text: (this.getValue(submission, "plan_organico_procesamiento_manejo_8_enjuage_equipos_frecuencia_check") ? '■' : '❏') + ' Enjuague de equipos',
                                                                    font: 'Arial',
                                                                    size: 18,
                                                                })
                                                            ]
                                                        })
                                                    ],
                                                    verticalAlign: VerticalAlign.CENTER,
                                                }),
                                                new TableCell({
                                                    children: [
                                                        new Paragraph({
                                                            alignment: AlignmentType.LEFT,
                                                            children: [
                                                                new TextRun({
                                                                    text: `${this.getValue(submission, "plan_organico_procesamiento_manejo_8_enjuage_equipos_frecuencia_check") ? this.getValue(submission, "plan_organico_procesamiento_manejo_8_enjuage_equipos_frecuencia") : ''}`,
                                                                    font: 'Arial',
                                                                    size: 18
                                                                })
                                                            ]
                                                        })
                                                    ],
                                                    verticalAlign: VerticalAlign.CENTER,
                                                }),
                                            ]
                                        }),
                                        new TableRow({
                                            children: [
                                                new TableCell({
                                                    children: [
                                                        new Paragraph({
                                                            alignment: AlignmentType.LEFT,
                                                            children: [
                                                                new TextRun({
                                                                    text: (this.getValue(submission, "plan_organico_procesamiento_manejo_8_otro") ? '■' : '❏') + ' Otro (¿Cuál?):' + this.getValue(submission, "plan_organico_procesamiento_manejo_8_otro_texto"),
                                                                    font: 'Arial',
                                                                    size: 18,
                                                                })
                                                            ]
                                                        })
                                                    ],
                                                    verticalAlign: VerticalAlign.CENTER,
                                                }),
                                                new TableCell({
                                                    children: [
                                                        new Paragraph({
                                                            alignment: AlignmentType.LEFT,
                                                            children: [
                                                                new TextRun({
                                                                    text: `${this.getValue(submission, "plan_organico_procesamiento_manejo_8_otro") ? this.getValue(submission, "plan_organico_procesamiento_manejo_8_otro_frecuencia") : ''}`,
                                                                    font: 'Arial',
                                                                    size: 18
                                                                })
                                                            ]
                                                        })
                                                    ],
                                                    verticalAlign: VerticalAlign.CENTER,
                                                }),
                                            ]
                                        }),
                                    ],
                                    margins: {
                                        left: 70,
                                        right: 70
                                    },
                                    columnWidths: [2067, 3000]
                                }),
                            ],
                            verticalAlign: VerticalAlign.TOP
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: this.getValue(submission, "plan_organico_procesamiento_manejo_8_comentarios"),
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                })
                            ],
                            verticalAlign: VerticalAlign.TOP
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: ` ${this.getValue(submission, 'plan_organico_procesamiento_manejo_8_implementacion') === 'I' ? '■' : '❏'} I`,
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                  }),
                                  new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: ` ${this.getValue(submission, 'plan_organico_procesamiento_manejo_8_implementacion') === 'NI' ? '■' : '❏'} NI`,
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                  })
                            ],
                            verticalAlign: VerticalAlign.TOP
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: ` ${this.getValue(submission, 'plan_organico_procesamiento_manejo_8_conformidad') === 'C' ? '■' : '❏'} C`,
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                  }),
                                  new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: ` ${this.getValue(submission, 'plan_organico_procesamiento_manejo_8_conformidad') === 'NC' ? '■' : '❏'} NC`,
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                  }),
                                  new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: ` ${this.getValue(submission, 'plan_organico_procesamiento_manejo_8_conformidad') === 'NA' ? '■' : '❏'} NA`,
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                  })
                            ],
                            verticalAlign: VerticalAlign.TOP
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: this.getValue(submission, 'plan_organico_procesamiento_manejo_8_comentarios_inspector'),
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                  })
                            ],
                            verticalAlign: VerticalAlign.TOP
                        })
                    ]
                }),
                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: '9',
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                })
                            ],
                            verticalAlign: VerticalAlign.TOP
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: 'Para el control de plagas realizó las siguientes prácticas:',
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                }),
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: (this.getValue(submission, "plan_organico_procesamiento_manejo_9_exclusion") ? '■' : '❏') + ' Exclusión',
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                }),
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: (this.getValue(submission, "plan_organico_procesamiento_manejo_9_trampas") ? '■' : '❏') + ' Trampas físicas',
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                }),
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: (this.getValue(submission, "plan_organico_procesamiento_manejo_9_trampas_venenos") ? '■' : '❏') + ' Trampas con venenos',
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                }),
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: (this.getValue(submission, "plan_organico_procesamiento_manejo_9_sustancias_permitidas") ? '■' : '❏') + ' Sustancias permitidas por las normas ',
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                }),
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: (this.getValue(submission, "plan_organico_procesamiento_manejo_9_agroquimicos") ? '■' : '❏') + ' Agroquímicos ',
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                }),
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: (this.getValue(submission, "plan_organico_procesamiento_manejo_9_otro") ? '■' : '❏') + ' Otro (¿Cuál?): ' + this.getValue(submission, "plan_organico_procesamiento_manejo_9_otro_text"),
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                })
                            ],
                            verticalAlign: VerticalAlign.TOP
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: this.getValue(submission, "plan_organico_procesamiento_manejo_9_comentarios"),
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                })
                            ],
                            verticalAlign: VerticalAlign.TOP
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: ` ${this.getValue(submission, 'plan_organico_procesamiento_manejo_9_implementacion') === 'I' ? '■' : '❏'} I`,
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                  }),
                                  new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: ` ${this.getValue(submission, 'plan_organico_procesamiento_manejo_9_implementacion') === 'NI' ? '■' : '❏'} NI`,
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                  })
                            ],
                            verticalAlign: VerticalAlign.TOP
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: ` ${this.getValue(submission, 'plan_organico_procesamiento_manejo_9_conformidad') === 'C' ? '■' : '❏'} C`,
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                  }),
                                  new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: ` ${this.getValue(submission, 'plan_organico_procesamiento_manejo_9_conformidad') === 'NC' ? '■' : '❏'} NC`,
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                  }),
                                  new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: ` ${this.getValue(submission, 'plan_organico_procesamiento_manejo_9_conformidad') === 'NA' ? '■' : '❏'} NA`,
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                  })
                            ],
                            verticalAlign: VerticalAlign.TOP
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: this.getValue(submission, 'plan_organico_procesamiento_manejo_9_comentarios_inspector'),
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                  })
                            ],
                            verticalAlign: VerticalAlign.TOP
                        })
                    ]
                }),

                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: '10',
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                })
                            ],
                            verticalAlign: VerticalAlign.TOP
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: 'En el caso de procesamiento paralelo (LPO y COR) y/o Escisión de la producción (UE) ¿Qué medidas de precaución se tienen implementadas?:',
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                }),
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: (this.getValue(submission, "plan_organico_procesamiento_manejo_10_almacenes") ? '■' : '❏') + ' Almacenes separados',
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                }),
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: (this.getValue(submission, "plan_organico_procesamiento_manejo_10_lotes") ? '■' : '❏') + ' Procesamiento por lotes completos',
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                }),
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: (this.getValue(submission, "plan_organico_procesamiento_manejo_10_exclusivo") ? '■' : '❏') + ' Equipo exclusivo',
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                }),
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: (this.getValue(submission, "plan_organico_procesamiento_manejo_10_limpieza") ? '■' : '❏') + '  Limpieza del equipo antes de elaborar producto orgánico',
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                }),
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: (this.getValue(submission, "plan_organico_procesamiento_manejo_10_purga") ? '■' : '❏') + ' Purga del equipo',
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                }),
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: (this.getValue(submission, "plan_organico_procesamiento_manejo_10_identificacion") ? '■' : '❏') + ' Identificación por lotes',
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                }),
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: (this.getValue(submission, "plan_organico_procesamiento_manejo_10_otro") ? '■' : '❏') + ' Otro (¿Cuál?): ' + this.getValue(submission, "plan_organico_procesamiento_manejo_10_otro_text"),
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                })
                            ],
                            verticalAlign: VerticalAlign.TOP
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: this.getValue(submission, "plan_organico_procesamiento_manejo_10_comentarios"),
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                })
                            ],
                            verticalAlign: VerticalAlign.TOP
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: ` ${this.getValue(submission, 'plan_organico_procesamiento_manejo_10_implementacion') === 'I' ? '■' : '❏'} I`,
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                  }),
                                  new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: ` ${this.getValue(submission, 'plan_organico_procesamiento_manejo_10_implementacion') === 'NI' ? '■' : '❏'} NI`,
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                  })
                            ],
                            verticalAlign: VerticalAlign.TOP
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: ` ${this.getValue(submission, 'plan_organico_procesamiento_manejo_10_conformidad') === 'C' ? '■' : '❏'} C`,
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                  }),
                                  new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: ` ${this.getValue(submission, 'plan_organico_procesamiento_manejo_10_conformidad') === 'NC' ? '■' : '❏'} NC`,
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                  }),
                                  new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: ` ${this.getValue(submission, 'plan_organico_procesamiento_manejo_10_conformidad') === 'NA' ? '■' : '❏'} NA`,
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                  })
                            ],
                            verticalAlign: VerticalAlign.TOP
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: this.getValue(submission, 'plan_organico_procesamiento_manejo_10_comentarios_inspector'),
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                  })
                            ],
                            verticalAlign: VerticalAlign.TOP
                        })
                    ]
                }),
                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: '11',
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                })
                            ],
                            verticalAlign: VerticalAlign.TOP
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: 'El transporte usado es:',
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                }),
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: (this.getValue(submission, "plan_organico_procesamiento_manejo_11_propio") ? '■' : '❏') + ' Propio',
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                }),
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: (this.getValue(submission, "plan_organico_procesamiento_manejo_11_rentado") ? '■' : '❏') + ' Rentado',
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                }),
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: (this.getValue(submission, "plan_organico_procesamiento_manejo_11_exclusivo") ? '■' : '❏') + ' Exclusivo para producto orgánico',
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                }),
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: (this.getValue(submission, "plan_organico_procesamiento_manejo_11_limpiado") ? '■' : '❏') + ' No exclusivo, pero limpiado antes de uso',
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                }),
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: (this.getValue(submission, "plan_organico_procesamiento_manejo_11_otro") ? '■' : '❏') + ' Otro: ' + this.getValue(submission, "plan_organico_procesamiento_manejo_11_otro_text"),
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                })
                            ],
                            verticalAlign: VerticalAlign.TOP
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: this.getValue(submission, "plan_organico_procesamiento_manejo_11_comentarios"),
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                })
                            ],
                            verticalAlign: VerticalAlign.TOP
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: ` ${this.getValue(submission, 'plan_organico_procesamiento_manejo_11_implementacion') === 'I' ? '■' : '❏'} I`,
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                  }),
                                  new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: ` ${this.getValue(submission, 'plan_organico_procesamiento_manejo_11_implementacion') === 'NI' ? '■' : '❏'} NI`,
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                  })
                            ],
                            verticalAlign: VerticalAlign.TOP
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: ` ${this.getValue(submission, 'plan_organico_procesamiento_manejo_11_conformidad') === 'C' ? '■' : '❏'} C`,
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                  }),
                                  new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: ` ${this.getValue(submission, 'plan_organico_procesamiento_manejo_11_conformidad') === 'NC' ? '■' : '❏'} NC`,
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                  }),
                                  new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: ` ${this.getValue(submission, 'plan_organico_procesamiento_manejo_11_conformidad') === 'NA' ? '■' : '❏'} NA`,
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                  })
                            ],
                            verticalAlign: VerticalAlign.TOP
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: this.getValue(submission, 'plan_organico_procesamiento_manejo_11_comentarios_inspector'),
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                  })
                            ],
                            verticalAlign: VerticalAlign.TOP
                        })
                    ]
                }),
                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: '12',
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                })
                            ],
                            verticalAlign: VerticalAlign.TOP
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: 'El traslado del producto será:',
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                }),
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: (this.getValue(submission, "plan_organico_procesamiento_manejo_12_nacional") ? '■' : '❏') + ' Nacional',
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                }),
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: (this.getValue(submission, "plan_organico_procesamiento_manejo_12_internacional") ? '■' : '❏') + ' Internacional',
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                })
                            ],
                            verticalAlign: VerticalAlign.TOP
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: this.getValue(submission, "plan_organico_procesamiento_manejo_12_comentarios"),
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                })
                            ],
                            verticalAlign: VerticalAlign.TOP
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: ` ${this.getValue(submission, 'plan_organico_procesamiento_manejo_12_implementacion') === 'I' ? '■' : '❏'} I`,
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                  }),
                                  new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: ` ${this.getValue(submission, 'plan_organico_procesamiento_manejo_12_implementacion') === 'NI' ? '■' : '❏'} NI`,
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                  })
                            ],
                            verticalAlign: VerticalAlign.TOP
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: ` ${this.getValue(submission, 'plan_organico_procesamiento_manejo_12_conformidad') === 'C' ? '■' : '❏'} C`,
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                  }),
                                  new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: ` ${this.getValue(submission, 'plan_organico_procesamiento_manejo_12_conformidad') === 'NC' ? '■' : '❏'} NC`,
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                  }),
                                  new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: ` ${this.getValue(submission, 'plan_organico_procesamiento_manejo_12_conformidad') === 'NA' ? '■' : '❏'} NA`,
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                  })
                            ],
                            verticalAlign: VerticalAlign.TOP
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: this.getValue(submission, 'plan_organico_procesamiento_manejo_12_comentarios_inspector'),
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                  })
                            ],
                            verticalAlign: VerticalAlign.TOP
                        })
                    ]
                }),



                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: '13',
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                })
                            ],
                            verticalAlign: VerticalAlign.TOP
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: 'Por favor ponga describa el sistema de trazabilidad que utiliza para cada de lote de producto orgánico comercializado:',
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                }),
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: '1.- Anote un ejemplo de código asignado a un lote: ',
                                            font: 'Arial',
                                            size: 18
                                        }),
                                        new TextRun({
                                            text: this.getValue(submission, "plan_organico_procesamiento_manejo_13_codigo_lote"),
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                }),
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: '2.- Indique lo que representa cada dígito del código: ',
                                            font: 'Arial',
                                            size: 18
                                        }),
                                        new TextRun({
                                            text: this.getValue(submission, "plan_organico_procesamiento_manejo_13_representacion"),
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                }),
                                
                            ],
                            verticalAlign: VerticalAlign.TOP
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: this.getValue(submission, "plan_organico_procesamiento_manejo_13_comentarios"),
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                })
                            ],
                            verticalAlign: VerticalAlign.TOP
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: ` ${this.getValue(submission, 'plan_organico_procesamiento_manejo_13_implementacion') === 'I' ? '■' : '❏'} I`,
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                  }),
                                  new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: ` ${this.getValue(submission, 'plan_organico_procesamiento_manejo_13_implementacion') === 'NI' ? '■' : '❏'} NI`,
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                  })
                            ],
                            verticalAlign: VerticalAlign.TOP
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: ` ${this.getValue(submission, 'plan_organico_procesamiento_manejo_13_conformidad') === 'C' ? '■' : '❏'} C`,
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                  }),
                                  new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: ` ${this.getValue(submission, 'plan_organico_procesamiento_manejo_13_conformidad') === 'NC' ? '■' : '❏'} NC`,
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                  }),
                                  new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: ` ${this.getValue(submission, 'plan_organico_procesamiento_manejo_13_conformidad') === 'NA' ? '■' : '❏'} NA`,
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                  })
                            ],
                            verticalAlign: VerticalAlign.TOP
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: this.getValue(submission, 'plan_organico_procesamiento_manejo_13_comentarios_inspector'),
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                  })
                            ],
                            verticalAlign: VerticalAlign.TOP
                        })
                    ]
                }),
                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: '14',
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                })
                            ],
                            verticalAlign: VerticalAlign.TOP
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: '¿Cuánto tiempo lleva en el puesto el responsable de producción orgánica?',
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                }),
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: (this.getValue(submission, "plan_organico_procesamiento_manejo_14_tres_o_mas") ? '■' : '❏') + ' 3 años o más',
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                }),
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: (this.getValue(submission, "plan_organico_procesamiento_manejo_14_uno_a_tres") ? '■' : '❏') + ' 1-3 años',
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                }),
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: (this.getValue(submission, "plan_organico_procesamiento_manejo_14_menos_de_uno") ? '■' : '❏') + ' Menos de 1 año',
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                })
                            ],
                            verticalAlign: VerticalAlign.TOP
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: this.getValue(submission, "plan_organico_procesamiento_manejo_14_comentarios"),
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                })
                            ],
                            verticalAlign: VerticalAlign.TOP
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: ` ${this.getValue(submission, 'plan_organico_procesamiento_manejo_14_implementacion') === 'I' ? '■' : '❏'} I`,
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                  }),
                                  new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: ` ${this.getValue(submission, 'plan_organico_procesamiento_manejo_14_implementacion') === 'NI' ? '■' : '❏'} NI`,
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                  })
                            ],
                            verticalAlign: VerticalAlign.TOP
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: ` ${this.getValue(submission, 'plan_organico_procesamiento_manejo_14_conformidad') === 'C' ? '■' : '❏'} C`,
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                  }),
                                  new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: ` ${this.getValue(submission, 'plan_organico_procesamiento_manejo_14_conformidad') === 'NC' ? '■' : '❏'} NC`,
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                  }),
                                  new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: ` ${this.getValue(submission, 'plan_organico_procesamiento_manejo_14_conformidad') === 'NA' ? '■' : '❏'} NA`,
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                  })
                            ],
                            verticalAlign: VerticalAlign.TOP
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: this.getValue(submission, 'plan_organico_procesamiento_manejo_14_comentarios_inspector'),
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                  })
                            ],
                            verticalAlign: VerticalAlign.TOP
                        })
                    ]
                })
            ],
            margins: {
                left: 70,
                right: 70
            },
            //9885
            columnWidths: [450, 3895, 2045, 1150, 800, 1545]
        })

        return table
    }

    public createProcessActivities(submission): Table {
        const table = new Table({
            rows: [
                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: 'No.',
                                            font: 'Arial',
                                            size: 22,
                                        })
                                    ],
                                    spacing: {
                                        before: 100,
                                        after: 100
                                    }
                                })
                            ],
                            verticalAlign: VerticalAlign.CENTER,
                            shading: {
                                fill: '#C0C0C0'
                            }
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: 'Actividad',
                                            font: 'Arial',
                                            size: 22,
                                        })
                                    ],
                                    spacing: {
                                        before: 100,
                                        after: 100
                                    }
                                })
                            ],
                            verticalAlign: VerticalAlign.CENTER,
                            shading: {
                                fill: '#C0C0C0'
                            }
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: 'Equipo utilizado',
                                            font: 'Arial',
                                            size: 22,
                                        })
                                    ],
                                    spacing: {
                                        before: 100,
                                        after: 100
                                    }
                                })
                            ],
                            verticalAlign: VerticalAlign.CENTER,
                            shading: {
                                fill: '#C0C0C0'
                            }
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: 'Ingredientes',
                                            font: 'Arial',
                                            size: 22,
                                        })
                                    ],
                                    spacing: {
                                        before: 100,
                                        after: 100
                                    }
                                })
                            ],
                            verticalAlign: VerticalAlign.CENTER,
                            shading: {
                                fill: '#C0C0C0'
                            }
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: 'Ayudas de proceso',
                                            font: 'Arial',
                                            size: 22,
                                        })
                                    ],
                                    spacing: {
                                        before: 100,
                                        after: 100
                                    }
                                })
                            ],
                            verticalAlign: VerticalAlign.CENTER,
                            shading: {
                                fill: '#C0C0C0'
                            }
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: 'Registros generados',
                                            font: 'Arial',
                                            size: 22,
                                        })
                                    ],
                                    spacing: {
                                        before: 100,
                                        after: 100
                                    }
                                })
                            ],
                            verticalAlign: VerticalAlign.CENTER,
                            shading: {
                                fill: '#C0C0C0'
                            }
                        })
                    ]
                }),
            ],
            //14935
            columnWidths: [800, 2827, 2827, 2827, 2827, 2827]
        })

        let activities = this.getValueList(submission, 'actividades_proceso')
        activities.forEach( (activity, index) => {
            table.addChildElement(
                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: `${index + 1}`,
                                            font: 'Arial',
                                            size: 22,
                                        })
                                    ],
                                    spacing: {
                                        before: 100,
                                        after: 100
                                    }
                                })
                            ],
                            verticalAlign: VerticalAlign.CENTER
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: '  ' + activity.plan_organico_procesamiento_actividades_proceso_actividad,
                                            font: 'Arial',
                                            size: 22,
                                        })
                                    ],
                                    spacing: {
                                        before: 100,
                                        after: 100
                                    }
                                })
                            ],
                            verticalAlign: VerticalAlign.CENTER
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: '  ' + activity.plan_organico_procesamiento_actividades_proceso_equipo,
                                            font: 'Arial',
                                            size: 22,
                                        })
                                    ],
                                    spacing: {
                                        before: 100,
                                        after: 100
                                    }
                                })
                            ],
                            verticalAlign: VerticalAlign.CENTER
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: '  ' + activity.plan_organico_procesamiento_actividades_proceso_ingredientes,
                                            font: 'Arial',
                                            size: 22,
                                        })
                                    ],
                                    spacing: {
                                        before: 100,
                                        after: 100
                                    }
                                })
                            ],
                            verticalAlign: VerticalAlign.CENTER
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: '  ' + activity.plan_organico_procesamiento_actividades_proceso_ayudas,
                                            font: 'Arial',
                                            size: 22,
                                        })
                                    ],
                                    spacing: {
                                        before: 100,
                                        after: 100
                                    }
                                })
                            ],
                            verticalAlign: VerticalAlign.CENTER
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: '  ' + activity.plan_organico_procesamiento_actividades_proceso_registros,
                                            font: 'Arial',
                                            size: 22,
                                        })
                                    ],
                                    spacing: {
                                        before: 100,
                                        after: 100
                                    }
                                })
                            ],
                            verticalAlign: VerticalAlign.CENTER
                        })
                    ]
                }),
            )
        })

        return table
    }

    public createCleaningActivities(submission): Table {
        const table = new Table({
            rows: [
                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: 'No.',
                                            font: 'Arial',
                                            size: 22,
                                        })
                                    ],
                                    spacing: {
                                        before: 100,
                                        after: 100
                                    }
                                })
                            ],
                            verticalAlign: VerticalAlign.CENTER,
                            shading: {
                                fill: '#C0C0C0'
                            }
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: 'Actividad / Equipo limpiado',
                                            font: 'Arial',
                                            size: 22,
                                        })
                                    ],
                                    spacing: {
                                        before: 100,
                                        after: 100
                                    }
                                })
                            ],
                            verticalAlign: VerticalAlign.CENTER,
                            shading: {
                                fill: '#C0C0C0'
                            }
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: 'Equipo o herramientas utilizadas',
                                            font: 'Arial',
                                            size: 22,
                                        })
                                    ],
                                    spacing: {
                                        before: 100,
                                        after: 100
                                    }
                                })
                            ],
                            verticalAlign: VerticalAlign.CENTER,
                            shading: {
                                fill: '#C0C0C0'
                            }
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: 'Sustancias utilizadas',
                                            font: 'Arial',
                                            size: 22,
                                        })
                                    ],
                                    spacing: {
                                        before: 100,
                                        after: 100
                                    }
                                })
                            ],
                            verticalAlign: VerticalAlign.CENTER,
                            shading: {
                                fill: '#C0C0C0'
                            }
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: 'Registros generados',
                                            font: 'Arial',
                                            size: 22,
                                        })
                                    ],
                                    spacing: {
                                        before: 100,
                                        after: 100
                                    }
                                })
                            ],
                            verticalAlign: VerticalAlign.CENTER,
                            shading: {
                                fill: '#C0C0C0'
                            }
                        })
                    ]
                }),
            ],
            //14936
            columnWidths: [800, 3534, 3534, 3534, 3534]
        })

        let activities = this.getValueList(submission, 'actividades_limpieza')
        activities.forEach( (activity, index) => {
            table.addChildElement(
                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: `${index + 1}`,
                                            font: 'Arial',
                                            size: 22,
                                        })
                                    ],
                                    spacing: {
                                        before: 100,
                                        after: 100
                                    }
                                })
                            ],
                            verticalAlign: VerticalAlign.CENTER
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: '  ' + activity.plan_organico_procesamiento_actividades_limpieza_actividad,
                                            font: 'Arial',
                                            size: 22,
                                        })
                                    ],
                                    spacing: {
                                        before: 100,
                                        after: 100
                                    }
                                })
                            ],
                            verticalAlign: VerticalAlign.CENTER
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: '  ' + activity.plan_organico_procesamiento_actividades_limpieza_equipo,
                                            font: 'Arial',
                                            size: 22,
                                        })
                                    ],
                                    spacing: {
                                        before: 100,
                                        after: 100
                                    }
                                })
                            ],
                            verticalAlign: VerticalAlign.CENTER
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: '  ' + activity.plan_organico_procesamiento_actividades_limpieza_sustancias,
                                            font: 'Arial',
                                            size: 22,
                                        })
                                    ],
                                    spacing: {
                                        before: 100,
                                        after: 100
                                    }
                                })
                            ],
                            verticalAlign: VerticalAlign.CENTER
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: '  ' + activity.plan_organico_procesamiento_actividades_limpieza_registros,
                                            font: 'Arial',
                                            size: 22,
                                        })
                                    ],
                                    spacing: {
                                        before: 100,
                                        after: 100
                                    }
                                })
                            ],
                            verticalAlign: VerticalAlign.CENTER
                        })
                    ]
                }),
            )
        })

        return table
    }

    public createPlagueActivities(submission): Table {
        const table = new Table({
            rows: [
                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: 'No.',
                                            font: 'Arial',
                                            size: 22,
                                        })
                                    ],
                                    spacing: {
                                        before: 100,
                                        after: 100
                                    }
                                })
                            ],
                            verticalAlign: VerticalAlign.CENTER,
                            shading: {
                                fill: '#C0C0C0'
                            }
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: 'Actividad',
                                            font: 'Arial',
                                            size: 22,
                                        })
                                    ],
                                    spacing: {
                                        before: 100,
                                        after: 100
                                    }
                                })
                            ],
                            verticalAlign: VerticalAlign.CENTER,
                            shading: {
                                fill: '#C0C0C0'
                            }
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: 'Área tratada',
                                            font: 'Arial',
                                            size: 22,
                                        })
                                    ],
                                    spacing: {
                                        before: 100,
                                        after: 100
                                    }
                                })
                            ],
                            verticalAlign: VerticalAlign.CENTER,
                            shading: {
                                fill: '#C0C0C0'
                            }
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: 'Equipo utilizado',
                                            font: 'Arial',
                                            size: 22,
                                        })
                                    ],
                                    spacing: {
                                        before: 100,
                                        after: 100
                                    }
                                })
                            ],
                            verticalAlign: VerticalAlign.CENTER,
                            shading: {
                                fill: '#C0C0C0'
                            }
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: 'Sustancias utilizadas',
                                            font: 'Arial',
                                            size: 22,
                                        })
                                    ],
                                    spacing: {
                                        before: 100,
                                        after: 100
                                    }
                                })
                            ],
                            verticalAlign: VerticalAlign.CENTER,
                            shading: {
                                fill: '#C0C0C0'
                            }
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: 'Registros generados',
                                            font: 'Arial',
                                            size: 22,
                                        })
                                    ],
                                    spacing: {
                                        before: 100,
                                        after: 100
                                    }
                                })
                            ],
                            verticalAlign: VerticalAlign.CENTER,
                            shading: {
                                fill: '#C0C0C0'
                            }
                        })
                    ]
                }),
            ],
            //14935
            columnWidths: [800, 2827, 2827, 2827, 2827, 2827]
        })

        let activities = this.getValueList(submission, 'actividades_plagas')
        activities.forEach( (activity, index) => {
            table.addChildElement(
                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: `${index + 1}`,
                                            font: 'Arial',
                                            size: 22,
                                        })
                                    ],
                                    spacing: {
                                        before: 100,
                                        after: 100
                                    }
                                })
                            ],
                            verticalAlign: VerticalAlign.CENTER
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: '  ' + activity.plan_organico_procesamiento_actividades_plagas_actividad,
                                            font: 'Arial',
                                            size: 22,
                                        })
                                    ],
                                    spacing: {
                                        before: 100,
                                        after: 100
                                    }
                                })
                            ],
                            verticalAlign: VerticalAlign.CENTER
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: '  ' + activity.plan_organico_procesamiento_actividades_plagas_area,
                                            font: 'Arial',
                                            size: 22,
                                        })
                                    ],
                                    spacing: {
                                        before: 100,
                                        after: 100
                                    }
                                })
                            ],
                            verticalAlign: VerticalAlign.CENTER
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: '  ' + activity.plan_organico_procesamiento_actividades_plagas_equipo,
                                            font: 'Arial',
                                            size: 22,
                                        })
                                    ],
                                    spacing: {
                                        before: 100,
                                        after: 100
                                    }
                                })
                            ],
                            verticalAlign: VerticalAlign.CENTER
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: '  ' + activity.plan_organico_procesamiento_actividades_plagas_sustancias,
                                            font: 'Arial',
                                            size: 22,
                                        })
                                    ],
                                    spacing: {
                                        before: 100,
                                        after: 100
                                    }
                                })
                            ],
                            verticalAlign: VerticalAlign.CENTER
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: '  ' + activity.plan_organico_procesamiento_actividades_plagas_registros,
                                            font: 'Arial',
                                            size: 22,
                                        })
                                    ],
                                    spacing: {
                                        before: 100,
                                        after: 100
                                    }
                                })
                            ],
                            verticalAlign: VerticalAlign.CENTER
                        })
                    ]
                }),
            )
        })

        return table
    }

    public createPackagingTypes(submission): Table {
        const table = new Table({
            rows: [
                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: 'No.',
                                            font: 'Arial',
                                            size: 22,
                                        })
                                    ],
                                    spacing: {
                                        before: 100,
                                        after: 100
                                    }
                                })
                            ],
                            verticalAlign: VerticalAlign.CENTER,
                            shading: {
                                fill: '#C0C0C0'
                            }
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: 'Producto',
                                            font: 'Arial',
                                            size: 22,
                                        })
                                    ],
                                    spacing: {
                                        before: 100,
                                        after: 100
                                    }
                                })
                            ],
                            verticalAlign: VerticalAlign.CENTER,
                            shading: {
                                fill: '#C0C0C0'
                            }
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: 'Presentación (entero, en trozo, en polvo, congelado, etc)',
                                            font: 'Arial',
                                            size: 22,
                                        })
                                    ],
                                    spacing: {
                                        before: 100,
                                        after: 100
                                    }
                                })
                            ],
                            verticalAlign: VerticalAlign.CENTER,
                            shading: {
                                fill: '#C0C0C0'
                            }
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: 'Tipo de empaque (Caja, Bolsa, Clamshell, etc)',
                                            font: 'Arial',
                                            size: 22,
                                        })
                                    ],
                                    spacing: {
                                        before: 100,
                                        after: 100
                                    }
                                })
                            ],
                            verticalAlign: VerticalAlign.CENTER,
                            shading: {
                                fill: '#C0C0C0'
                            }
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: 'Capacidad del envase (Kg, Lb, Litros, etc)',
                                            font: 'Arial',
                                            size: 22,
                                        })
                                    ],
                                    spacing: {
                                        before: 100,
                                        after: 100
                                    }
                                })
                            ],
                            verticalAlign: VerticalAlign.CENTER,
                            shading: {
                                fill: '#C0C0C0'
                            }
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: 'Tratamiento o recubrimiento del envase',
                                            font: 'Arial',
                                            size: 22,
                                        })
                                    ],
                                    spacing: {
                                        before: 100,
                                        after: 100
                                    }
                                })
                            ],
                            verticalAlign: VerticalAlign.CENTER,
                            shading: {
                                fill: '#C0C0C0'
                            }
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: 'Marca',
                                            font: 'Arial',
                                            size: 22,
                                        })
                                    ],
                                    spacing: {
                                        before: 100,
                                        after: 100
                                    }
                                })
                            ],
                            verticalAlign: VerticalAlign.CENTER,
                            shading: {
                                fill: '#C0C0C0'
                            }
                        })
                    ]
                }),
            ],
            //14960
            columnWidths: [800, 2360, 2360, 2360, 2360, 2360, 2360]
        })

        let types = this.getValueList(submission, 'tipos_empaque_envasado')
        types.forEach( (type, index) => {
            table.addChildElement(
                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: `${index + 1}`,
                                            font: 'Arial',
                                            size: 22,
                                        })
                                    ],
                                    spacing: {
                                        before: 100,
                                        after: 100
                                    }
                                })
                            ],
                            verticalAlign: VerticalAlign.CENTER
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: '  ' + type.plan_organico_procesamiento_tipos_empaque_envasado_producto,
                                            font: 'Arial',
                                            size: 22,
                                        })
                                    ],
                                    spacing: {
                                        before: 100,
                                        after: 100
                                    }
                                })
                            ],
                            verticalAlign: VerticalAlign.CENTER
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: '  ' + type.plan_organico_procesamiento_tipos_empaque_envasado_presentacion,
                                            font: 'Arial',
                                            size: 22,
                                        })
                                    ],
                                    spacing: {
                                        before: 100,
                                        after: 100
                                    }
                                })
                            ],
                            verticalAlign: VerticalAlign.CENTER
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: '  ' + type.plan_organico_procesamiento_tipos_empaque_envasado_tipo_empaque,
                                            font: 'Arial',
                                            size: 22,
                                        })
                                    ],
                                    spacing: {
                                        before: 100,
                                        after: 100
                                    }
                                })
                            ],
                            verticalAlign: VerticalAlign.CENTER
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: '  ' + type.plan_organico_procesamiento_tipos_empaque_envasado_capacidad_envase,
                                            font: 'Arial',
                                            size: 22,
                                        })
                                    ],
                                    spacing: {
                                        before: 100,
                                        after: 100
                                    }
                                })
                            ],
                            verticalAlign: VerticalAlign.CENTER
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: '  ' + type.plan_organico_procesamiento_actividades_tipos_empaque_envasado_tratamiento_envase,
                                            font: 'Arial',
                                            size: 22,
                                        })
                                    ],
                                    spacing: {
                                        before: 100,
                                        after: 100
                                    }
                                })
                            ],
                            verticalAlign: VerticalAlign.CENTER
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: '  ' + type.plan_organico_procesamiento_actividades_tipos_empaque_envasado_marca,
                                            font: 'Arial',
                                            size: 22,
                                        })
                                    ],
                                    spacing: {
                                        before: 100,
                                        after: 100
                                    }
                                })
                            ],
                            verticalAlign: VerticalAlign.CENTER
                        })
                    ]
                }),
            )
        })

        return table
    }

    public createTableUDP(data, datePipe): Table {
        const table = new Table({
            rows: [
                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: 'DATOS GENERALES',
                                            font: 'Arial',
                                            size: 22,
                                            bold: true
                                        })
                                    ],
                                    spacing: {
                                        before: 100,
                                        after: 100
                                    }
                                })
                            ],
                            columnSpan: 4,
                            verticalAlign: VerticalAlign.CENTER,
                            shading: {
                                fill: '#C0C0C0'
                            }
                        }),
                    ]
                }),
                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.RIGHT,
                                    children: [
                                        new TextRun({
                                            text: 'Nombre del operador: ',
                                            font: 'Calibri (Cuerpo)',
                                            size: 22,
                                            bold: true,

                                        })
                                    ],
                                    spacing: { before: 100, after: 200 }
                                })],
                            verticalAlign: VerticalAlign.CENTER,
                        }),
                        new TableCell({
                            children: [new Paragraph({
                                alignment: AlignmentType.CENTER,
                                children: [
                                    new TextRun({
                                        text: data.customer.Company_Name,
                                        font: 'Calibri (Cuerpo)',
                                        size: 22,
                                    })
                                ],
                            })],
                            verticalAlign: VerticalAlign.CENTER,
                            columnSpan: 3,
                        }),
                    ],
                }),
                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.RIGHT,
                                    children: [
                                        new TextRun({
                                            text: 'Código del operador: ',
                                            font: 'Calibri (Cuerpo)',
                                            size: 22,
                                            bold: true,

                                        })
                                    ],
                                    spacing: { before: 100, after: 200 }
                                })],
                            width: {
                                size: 25,
                                type: WidthType.PERCENTAGE,
                            },
                        }),
                        new TableCell({
                            children: [new Paragraph({
                                alignment: AlignmentType.CENTER,
                                children: [
                                    new TextRun({
                                        text: data.customer.Id_Comercial,
                                        font: 'Calibri (Cuerpo)',
                                        size: 22,
                                    })
                                ],
                            })],
                            verticalAlign: VerticalAlign.CENTER,
                            width: {
                                size: 25,
                                type: WidthType.PERCENTAGE,
                            },
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.RIGHT,
                                    children: [
                                        new TextRun({
                                            text: 'Fecha: ',
                                            font: 'Calibri (Cuerpo)',
                                            size: 22,
                                            bold: true,

                                        })
                                    ],
                                    spacing: { before: 100, after: 200 }
                                })],
                            verticalAlign: VerticalAlign.CENTER,
                            width: {
                                size: 25,
                                type: WidthType.PERCENTAGE,
                            },
                        }),
                        new TableCell({
                            children: [new Paragraph({
                                alignment: AlignmentType.CENTER,
                                children: [
                                    new TextRun({
                                        text: datePipe.transform(data.record.Applications[0].Creation_Date.includes('UTC') ? data.record.Applications[0].Creation_Date.replace('UTC', '').trim() : data.record.Applications[0].Creation_Date, "d-MMMM-y"),
                                        font: 'Calibri (Cuerpo)',
                                        size: 22,
                                    })
                                ],
                            })],
                            verticalAlign: VerticalAlign.CENTER,
                            width: {
                                size: 25,
                                type: WidthType.PERCENTAGE,
                            },
                        }),
                    ],
                }),
                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: 'Unidades de producción:',
                                            font: 'Arial',
                                            size: 22,
                                            bold: true
                                        })
                                    ],
                                    spacing: {
                                        before: 100,
                                        after: 100
                                    }
                                })
                            ],
                            columnSpan: 4,
                            verticalAlign: VerticalAlign.CENTER,
                            shading: {
                                fill: '#C0C0C0'
                            }
                        }),
                    ]
                }),
            ],
            margins: {
                left: 70,
                right: 70
            },
            width: {
                size: 100,
                type: WidthType.PERCENTAGE,
            },
            //9638
            columnWidths: [2410, 2410, 2409, 2409]
        })

        let listaUnidades = this.getValueList(data.submission, "lista_unidades")
        listaUnidades.forEach((unidad, i) => {
            table.addChildElement(new TableRow({
                children: [
                    new TableCell({
                        children: [
                            new Paragraph({
                                alignment: AlignmentType.RIGHT,
                                children: [
                                    new TextRun({
                                        text: 'Nombre:',
                                        font: 'Calibri (Cuerpo)',
                                        size: 22,
                                        bold: true,

                                    })
                                ],
                                spacing: { before: 100, after: 200 }
                            })],
                        verticalAlign: VerticalAlign.CENTER,
                    }),
                    new TableCell({
                        children: [
                            new Paragraph({
                                children: [
                                    new TextRun({
                                        text: unidad.nombre_unidad_produccion,
                                        font: 'Calibri (Cuerpo)',
                                        size: 22,
                                    }),
                                ],
                                spacing: { before: 100, after: 200 }
                            })
                        ],
                        verticalAlign: VerticalAlign.CENTER,
                        columnSpan: 3,
                    }),
                ],
            }))
            table.addChildElement(new TableRow({
                children: [
                    new TableCell({
                        children: [
                            new Paragraph({
                                alignment: AlignmentType.RIGHT,
                                children: [
                                    new TextRun({
                                        text: 'Ubicación:',
                                        font: 'Calibri (Cuerpo)',
                                        size: 22,
                                        bold: true,

                                    })
                                ],
                                spacing: { before: 100, after: 200 }
                            })],
                        verticalAlign: VerticalAlign.CENTER,
                    }),
                    new TableCell({
                        children: [
                            new Paragraph({
                                children: [
                                    new TextRun({
                                        text: unidad.plan_organico_procesamiento_ubicacion,
                                        font: 'Calibri (Cuerpo)',
                                        size: 22,
                                    }),
                                ],
                                spacing: { before: 100, after: 200 }
                            })
                        ],
                        verticalAlign: VerticalAlign.CENTER,
                        columnSpan: 3,
                    }),
                ],
            }))
            table.addChildElement(new TableRow({
                children: [
                    new TableCell({
                        children: [
                            new Paragraph({
                                alignment: AlignmentType.RIGHT,
                                children: [
                                    new TextRun({
                                        text: 'Actividades desarrolladas:',
                                        font: 'Calibri (Cuerpo)',
                                        size: 22,
                                        bold: true,

                                    })
                                ],
                                spacing: { before: 100, after: 200 }
                            })],
                        verticalAlign: VerticalAlign.TOP,
                    }),
                    new TableCell({
                        children: [
                            new Paragraph({
                                children: [
                                    new TextRun({
                                        text: `${unidad.plan_organico_procesamiento_actividades_cosecha ? '■' : '❏'} Cosecha / Corte`,
                                        font: 'Calibri (Cuerpo)',
                                        size: 22,
                                    }),
                                ]
                            }),
                            new Paragraph({
                                children: [
                                    new TextRun({
                                        text: `${unidad.plan_organico_procesamiento_actividades_transporte ? '■' : '❏'} Transporte`,
                                        font: 'Calibri (Cuerpo)',
                                        size: 22,
                                    }),
                                ]
                            }),
                            new Paragraph({
                                children: [
                                    new TextRun({
                                        text: `${unidad.plan_organico_procesamiento_actividades_lavado ? '■' : '❏'} Lavado / Sanitizado`,
                                        font: 'Calibri (Cuerpo)',
                                        size: 22,
                                    }),
                                ]
                            }),
                            new Paragraph({
                                children: [
                                    new TextRun({
                                        text: `${unidad.plan_organico_procesamiento_actividades_seleccion ? '■' : '❏'} Selección`,
                                        font: 'Calibri (Cuerpo)',
                                        size: 22,
                                    }),
                                ]
                            }),
                            new Paragraph({
                                children: [
                                    new TextRun({
                                        text: `${unidad.plan_organico_procesamiento_actividades_envasado ? '■' : '❏'} Envasado`,
                                        font: 'Calibri (Cuerpo)',
                                        size: 22,
                                    }),
                                ]
                            }),
                            new Paragraph({
                                children: [
                                    new TextRun({
                                        text: `${unidad.plan_organico_procesamiento_actividades_empaque ? '■' : '❏'} Empaque`,
                                        font: 'Calibri (Cuerpo)',
                                        size: 22,
                                    }),
                                ]
                            }),
                            new Paragraph({
                                children: [
                                    new TextRun({
                                        text: `${unidad.plan_organico_procesamiento_actividades_almacenamiento ? '■' : '❏'} Almacenamiento`,
                                        font: 'Calibri (Cuerpo)',
                                        size: 22,
                                    }),
                                ]
                            }),
                            new Paragraph({
                                children: [
                                    new TextRun({
                                        text: `${unidad.plan_organico_procesamiento_actividades_refrigerado ? '■' : '❏'} Refrigerado / Congelado`,
                                        font: 'Calibri (Cuerpo)',
                                        size: 22,
                                    }),
                                ]
                            }),
                            new Paragraph({
                                children: [
                                    new TextRun({
                                        text: `${unidad.plan_organico_procesamiento_actividades_deshidratado ? '■' : '❏'} Deshidratado`,
                                        font: 'Calibri (Cuerpo)',
                                        size: 22,
                                    }),
                                ]
                            }),
                            new Paragraph({
                                children: [
                                    new TextRun({
                                        text: `${unidad.plan_organico_procesamiento_actividades_mezclado ? '■' : '❏'} Mezclado`,
                                        font: 'Calibri (Cuerpo)',
                                        size: 22,
                                    }),
                                ]
                            }),
                            new Paragraph({
                                children: [
                                    new TextRun({
                                        text: `${unidad.plan_organico_procesamiento_actividades_pasteurizado ? '■' : '❏'} Pasteurizado`,
                                        font: 'Calibri (Cuerpo)',
                                        size: 22,
                                    }),
                                ]
                            }),
                            new Paragraph({
                                children: [
                                    new TextRun({
                                        text: `${unidad.plan_organico_procesamiento_actividades_liofilizado ? '■' : '❏'} Liofilizado`,
                                        font: 'Calibri (Cuerpo)',
                                        size: 22,
                                    }),
                                ]
                            }),
                            new Paragraph({
                                children: [
                                    new TextRun({
                                        text: `${unidad.plan_organico_procesamiento_actividades_sacrificio ? '■' : '❏'} Sacrificio`,
                                        font: 'Calibri (Cuerpo)',
                                        size: 22,
                                    }),
                                ]
                            }),
                            new Paragraph({
                                children: [
                                    new TextRun({
                                        text: `${unidad.plan_organico_procesamiento_actividades_comercializacion ? '■' : '❏'} Comercialización`,
                                        font: 'Calibri (Cuerpo)',
                                        size: 22,
                                    }),
                                ]
                            }),
                            ,
                            new Paragraph({
                                children: [
                                    new TextRun({
                                        text: `${unidad.plan_organico_procesamiento_actividades_otro ? '■' : '❏'} Otro: `,
                                        font: 'Calibri (Cuerpo)',
                                        size: 22,
                                    }),
                                    new TextRun({
                                        text: `${unidad.plan_organico_procesamiento_actividades_otro_texto}`,
                                        font: 'Calibri (Cuerpo)',
                                        size: 22,
                                        underline: {
                                            color: '#000000',
                                            type: UnderlineType.SINGLE
                                        }
                                    }),
                                ]
                            })
                        ],
                        verticalAlign: VerticalAlign.CENTER,
                        columnSpan: 3,
                    }),
                ],
            }))
        })
        
        return table
    }

    getValue(submission, name) {
        var value = null
        for (const i in submission) {
            const element = submission[i]
            if (element.name === name) {
                value = element.value
            }
        }
        if (value) return value
        else return ''
    }

    getValueList(submission, name) {
        var value = null
        for (const i in submission) {
            const element = submission[i]
            if (element.name === name) {
                value = element.value
            }
        }
        if (value) return value
        else return []
    }

    // END MTO_FSOL_08_4
}