export class User {
    jwtToken?: string
    id: string
    email: string
    firstName: string
    middleName: string
    lastName: string
    photoUrl: string
    lastLogin: string
    type: string
}