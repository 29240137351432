import { Injectable } from '@angular/core'
import { Subject } from 'rxjs'

/**
 * This service handles communications between children and parents
 * emiting events and allowing a component to subscribe to changes.
 * 
 * The subscription returns and object with 3 parameters, following this structure:
 * 
 * {
 *      type: string - The type of event emitted (ex. 'click'),
 *      identifier: string - The string provided to identify the event
 *      event: any - The event itself
 * }
 * 
 */

@Injectable()
export class ControlCommunicationService {

    constructor() {}

    private emitChangeSource = new Subject<any>()

    changeEmmited$ = this.emitChangeSource.asObservable()

    /**
     * Service message
     * @param msg The variable is going to be emitted
     */
    emitEvent(msg: any) {
        this.emitChangeSource.next(msg)
    }

}