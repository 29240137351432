import { InspectionsService } from '../../services/inspections.service';
import { ControlCommunicationService } from '../../../form-builder/services/control-communication.service';
import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { FormBuilder, FormGroup } from '@angular/forms';
import { AlertService, EvaluationsService, RequestsService } from '../../services'
import { first } from 'rxjs/operators';

@Component({
    selector: 'modal-toma-muestras',
    templateUrl: 'modal-toma-muestras.component.html',
    styleUrls: ['../../../app.component.scss']
})
export class ModalTomaMuestras implements OnInit {
    form: FormGroup;

    @Input() public inspectionId
    @Input() public prevData // datos básicos, como operador, inspector, etc
    @Input() public recordId
    @Input() public formId
    @Input() public isEvaluation = false

    loading = false;
    docloading = true
    submitted = false;
    res = false
    reviewers = []
    forbiddenId = false;
    submission = null
    requirements = null
    response = null
    showForm = false
    showDoc = true
    bdoc
    url
    saving = false
    alreadySaving = false

    attachments = []

    title = ''
    data
    files = []

    loaded = false
    percentage = 0

    constructor(
        private formBuilder: FormBuilder,
        public modal: NgbActiveModal,
        private alertService: AlertService,
        private evaluationService: EvaluationsService,
        private communication: ControlCommunicationService,
        private inspectionService: InspectionsService,
        private requestsService: RequestsService
    ) {
        this.communication.changeEmmited$.subscribe(async msg => {
            if (msg.identifier === 'send-form-tm' && !this.saving && !this.loaded) {
                const formGroup = msg.form as FormGroup
                const f = formGroup.value
                var data = f
                for (var i in this.requirements) {
                    const requirement = this.requirements[i]
                    if (Object.keys(requirement.data).length > 0) {
                        if (requirement.isDynamic) {
                            let form = data[requirement.requiredInto][0]
                            let nval = []
                            for (var j in requirement.data) {
                                const r = requirement.data[j]
                                let s = {}
                                f[requirement.requiredInto].forEach(elem => {
                                    Object.keys(r).forEach(key => {
                                        if (elem['nombre_comercial'] == r['nombre_comercial']) {
                                            s = elem
                                        }
                                    })
                                })
                                Object.keys(r).forEach(key => {
                                    s[key] = r[key]
                                })
                                nval.push(s)
                            }
                            data[requirement.requiredInto] = nval
                        } else if (requirement.full) {
                            for (var j in requirement.data.values) {
                                const val = requirement.data.values[j]
                                //console.log(typeof val.value, val)
                                if (typeof val.value != 'object') {
                                    data[val.name] = val.value
                                } else if (Array.isArray(val.value)) {
                                    for (var k in val.value) {
                                        const elem = val.value[k]
                                        data[val.name][k] = {
                                            ...data[val.name][k],
                                            ...elem
                                        }
                                    }
                                } else {
                                    data[val.name] = {
                                        ...data[val.name],
                                        ...val.value
                                    }
                                }
                            }
                        } else {
                            data[requirement.requiredInto] = requirement.data
                        }
                    }
                }
                if (!this.isEvaluation) {
                    data['informe_nombre_propietario'] = this.prevData.operator
                    data['informe_nombre_codigo_operacion'] = this.prevData.code

                    for (var i in data['lista-muestras']) {
                        let elem = data['lista-muestras'][i]
                        elem = {
                            ...elem,
                            informe_muestra_identificados_operador: this.prevData.operator,
                            informe_muestra_identificados_inspector: this.prevData.inspector
                        }
                        data['lista-muestras'][i] = elem
                    }
                }
                setTimeout(() => {
                    this.loaded = true
                    this.communication.emitEvent({
                        identifier: 'set-values',
                        form: data
                    })
                }, 100);
            } else if (msg.identifier === 'send-form-tm' && this.saving) {
                this.alreadySaving = true
                const formGroup = msg.form as FormGroup
                const f = formGroup.value

                if (f['personas_entrevistadas'] == undefined && this.isEvaluation) {
                    try {
                        await this.requestsService.changeFilesIntoUrls(formGroup, this.formId, this.recordId)
                    } catch (error) {
                        //console.log(error)
                    }
                    //console.log(formGroup.value)
                    this.evaluationService.saveForm(this.inspectionId, this.formId, formGroup.value)
                        .pipe(first())
                        .subscribe(data => {
                            this.alertService.info('Formulario guardado', {
                                autoClose: true
                            })
                            this.saving = false
                            this.modal.close(f)
                            this.modal.dismiss()
                        }, error => {
                            console.log(error)
                        })
                } else if (f['personas_entrevistadas'] == undefined) {
                    try {
                        await this.requestsService.changeFilesIntoUrls(formGroup, this.formId, this.recordId)
                    } catch (error) {
                        //console.log(error)
                    }
                    //console.log(formGroup.value)
                    this.inspectionService.saveForm(this.inspectionId, this.formId, formGroup.value, null, null).subscribe(data => {
                        this.alertService.info('Formulario guardado', {
                            autoClose: true
                        })
                        this.saving = false
                        this.modal.close(f)
                        this.modal.dismiss()
                    }, error => {
                        console.log(error)
                    })
                }
            } else if (msg.identifier == 'file') {
                let added = false
                for (var i in this.files) {
                    const file = this.files[i]
                    if (file.formname == msg.formname && file.atIndex == msg.atIndex) {
                        this.files[i] = msg
                        added = true
                        break
                    }
                }
                if (!added) {
                    this.files.push(msg)
                }
            } else if (msg.identifier == 'uploading') {
                this.percentage = msg.percentage
            }
        })
    }

    getFilename(url: string) {
        if (url.constructor.name !== 'String') {
            return ''
        }
        const parts = url.split("/")
        const name = parts[parts.length - 1]
        return name
    }

    loadAttachments(submission: any) {
        // puede ser un array
        if (Array.isArray(submission)) {
            for (var i in submission) {
                const elem = submission[i]
                this.loadAttachments(elem)
            }
            // puede ser un string    
        } else if (typeof submission === 'string') {
            if (submission.includes('http')) {
                this.attachments.push(submission)
            }
            // o puede ser un objeto
        } else if (typeof submission === 'object' && submission) {
            const keys = Object.keys(submission)
            for (var i in keys) {
                this.loadAttachments(submission[keys[i]])
            }
        }
    }

    ngOnInit() {
        this.saving = false
        if (this.isEvaluation) { // FORMULARIO PARA CARGAR RESULTADOS
            this.evaluationService.getForm(this.inspectionId, this.formId)
                .pipe(first())
                .subscribe(
                    response => {
                        console.log("FORM")
                        console.log(response)
                        this.title = response.form.title
                        this.submission = response.submission
                        this.loadAttachments(this.submission)
                        this.requirements = response.requirements
                        this.response = response
                        this.showDoc = false
                        this.showForm = true
                        this.docloading = false
                        if (this.response.special !== null) {
                            let lastForm = {}
                            for (var i in this.response.special) {
                                const obj = this.response.special[i]
                                lastForm[obj.name] = obj.value
                            }
                            setTimeout(() => {
                                this.communication.emitEvent({
                                    identifier: 'set-values',
                                    form: lastForm
                                })
                            }, 100);
                        }
                        if (this.submission !== null) {
                            let lastForm = {}
                            for (var i in this.submission) {
                                const obj = this.submission[i]
                                lastForm[obj.name] = obj.value
                            }
                            setTimeout(() => {
                                this.communication.emitEvent({
                                    identifier: 'set-values',
                                    form: lastForm
                                })
                            }, 100);
                        }
                        setTimeout(() => {
                            this.communication.emitEvent({
                                identifier: 'submit-tm'
                            })
                        }, 100);
                    },
                    error => {
                        this.alertService.error(error);
                    })
        } else { //FOMRULARIO DE INSPECCION
            this.inspectionService.getPlanForm(this.inspectionId, this.formId)
                .pipe(first())
                .subscribe(
                    response => {
                        console.log("FORM")
                        console.log(response)
                        this.title = response.data.form.title
                        this.submission = response.data.submission
                        this.loadAttachments(this.submission)
                        this.requirements = response.data.requirements
                        this.response = response.data
                        this.showDoc = false
                        this.showForm = true
                        this.docloading = false
                        if (this.response.special !== null) {
                            let lastForm = {}
                            for (var i in this.response.special) {
                                const obj = this.response.special[i]
                                lastForm[obj.name] = obj.value
                            }
                            setTimeout(() => {
                                this.communication.emitEvent({
                                    identifier: 'set-values',
                                    form: lastForm
                                })
                            }, 100);
                        }
                        if (this.submission !== null) {
                            let lastForm = {}
                            for (var i in this.submission) {
                                const obj = this.submission[i]
                                lastForm[obj.name] = obj.value
                            }
                            setTimeout(() => {
                                this.communication.emitEvent({
                                    identifier: 'set-values',
                                    form: lastForm
                                })
                            }, 100);
                        }
                        setTimeout(() => {
                            this.communication.emitEvent({
                                identifier: 'submit-tm'
                            })
                        }, 100);
                    },
                    error => {
                        this.alertService.error(error);
                    })
        }
    }
    close() {
        this.modal.dismiss('closed')
    }
    onSubmit() {
        this.submitted = true;
        this.saving = true
        // Reset alerts on submit
        this.alertService.clear();
        this.communication.emitEvent({
            identifier: 'submit-tm'
        })
        this.loading = true;
        this.forbiddenId = false
    }

}