import { AlignmentType, Document, Paragraph, TextRun, Media, Header, Footer, Table, TableRow, TableCell, WidthType, PageNumber } from "docx";
import { logo } from "../logo";
import { DatePipe } from '@angular/common';
import { signDictator } from "../signDictator"

//Moment import
const moment = require('moment')
moment.locale('es-mx')

export class DocumentCreatorMTO_FRYD_05_v1 {
    // INIT MTO_FRYD_05
    public async create_MTO_FRYD_05(data, index): Promise<Document> {
        var info = this.getValueList(data.requirements[0].data.values, "lista-no-conformidades")
        const submission = this.getValueList(data.submission, "lista-no-conformidades")
        const document = new Document();
        const image = Media.addImage(document, logo, 50, 50);
        var datePipe = new DatePipe('es-MX');
        var ncSent = false

        for(var i in submission) {
            const nc = submission[i]

            if(nc.hasOwnProperty("ncNotificationSent")) {
                if(nc.ncNotificationSent) {
                    ncSent = true
                    break
                }
            }
        }

        document.addSection({
            headers: {
                default: new Header({
                    children: [
                        new Paragraph({ alignment: AlignmentType.CENTER, children: [image], }),
                        new Paragraph({
                            alignment: AlignmentType.CENTER, children: [
                                new TextRun({
                                    children: [`MTO-FRYD-05| R 00 | 01-Julio-2017 | Pág. `, PageNumber.CURRENT],
                                    font: 'Book Antiqua',
                                    size: 20,
                                    bold: true
                                }),
                                new TextRun({
                                    font: 'Book Antiqua',
                                    size: 20,
                                    bold: true,
                                    children: [' de ', PageNumber.TOTAL_PAGES]
                                }),
                            ], spacing: { before: 200, after: 100 }
                        })
                    ]
                })
            },
            footers: {
                default: new Footer({
                    children: [
                        new Paragraph({ alignment: AlignmentType.CENTER }),
                        this.createFooter()
                    ]
                }),
            },
            children: [
                new Paragraph({
                    alignment: AlignmentType.CENTER,
                    children: [
                        new TextRun({
                            text: 'NOTIFICACIÓN DE NO CONFORMIDAD',
                            font: 'Calibri (Cuerpo)',
                            size: 22,
                            bold: true
                        })
                    ],
                    spacing: { after: 200 }
                }),
                new Paragraph({
                    alignment: AlignmentType.RIGHT,
                    children: [
                        new TextRun({
                            text: `Morelia Michoacán, ${ncSent ? moment(submission[0].fecha).format('DD [de] MMMM [de] YYYY') : moment().format('DD [de] MMMM [de] YYYY')}`,
                            font: 'Calibri (Cuerpo)',
                            size: 20
                        })
                    ],
                    spacing: { before: 400, after: 200 }
                }),
                new Paragraph({
                    alignment: AlignmentType.LEFT,
                    children: [
                        new TextRun({
                            text: "Estimado representante legal de la operación",
                            font: 'Calibri (Cuerpo)',
                            size: 20
                        }),
                    ],
                    spacing: { before: 300 }
                }),
                new Paragraph({
                    alignment: AlignmentType.LEFT,
                    children: [
                        new TextRun({
                            text: "C. " + data.customer.Legal_Authorities[0].Name + " " + data.customer.Legal_Authorities[0].Middle_Name + " " + data.customer.Legal_Authorities[0].Last_Name,
                            font: 'Calibri (Cuerpo)',
                            size: 20
                        })
                    ],
                    spacing: { after: 300 }
                }),
                new Paragraph({
                    alignment: AlignmentType.LEFT,
                    children: [
                        new TextRun({
                            text: "Por este medio le comunicamos formalmente que su operación tiene No Conformidades pendientes por atender, como resultado de la: inspección anual/inspección no anunciada/investigación adicional, realizada el ",
                            font: 'Calibri (Cuerpo)',
                            size: 20
                        }),
                        new TextRun({
                            text: `${moment(data.inspectionDate).format("DD [de] MMMM [de] YYYY")}. `,
                            font: 'Calibri (Cuerpo)',
                            size: 20
                        }),
                        new TextRun({
                            text: "Las No Conformidades y acciones para atenderlas, que usted propuso son las siguientes:",
                            font: 'Calibri (Cuerpo)',
                            size: 20
                        })
                    ],
                    spacing: { before: 100, after: 600 }
                }),
                new Paragraph({
                    alignment: AlignmentType.LEFT,
                    children: [
                        new TextRun({
                            text: `NC: `,
                            font: 'Calibri (Cuerpo)',
                            size: 20,
                            bold: true
                        }),
                        new TextRun({
                            text: info[index].no_nc,
                            font: 'Calibri (Cuerpo)',
                            size: 20
                        })
                    ],
                    spacing: { before: 600, after: 200 }
                }),
                new Paragraph({
                    alignment: AlignmentType.LEFT,
                    children: [
                        new TextRun({
                            text: `Descripción de la No Conformidad: `,
                            font: 'Calibri (Cuerpo)',
                            size: 20,
                            bold: true
                        }),
                        new TextRun({
                            text: info[index].no_conformidad_descripcion,
                            font: 'Calibri (Cuerpo)',
                            size: 20
                        })
                    ],
                    spacing: { before: 100, after: 200 }
                }),
                new Paragraph({
                    alignment: AlignmentType.LEFT,
                    children: [
                        new TextRun({
                            text: `Referencia normativa: `,
                            font: 'Calibri (Cuerpo)',
                            size: 20,
                            bold: true
                        }),
                        new TextRun({
                            text: info[index].referencia_normativa,
                            font: 'Calibri (Cuerpo)',
                            size: 20
                        })
                    ],
                    spacing: { before: 100, after: 200 }
                }),
                new Paragraph({
                    alignment: AlignmentType.LEFT,
                    children: [
                        new TextRun({
                            text: `Evidencia objetiva: `,
                            font: 'Calibri (Cuerpo)',
                            size: 20,
                            bold: true
                        }),
                        new TextRun({
                            text: info[index].no_conformidad_evidencia_a_generar,
                            font: 'Calibri (Cuerpo)',
                            size: 20
                        })
                    ],
                    spacing: { before: 200, after: 600 }
                }),
                new Paragraph({
                    alignment: AlignmentType.LEFT,
                    children: [
                        new TextRun({
                            text: `De acuerdo con el procedimiento de certificación, tiene usted 30 días adicionales, a partir de esta fecha, para atender estas No Conformidades.`,
                            font: 'Calibri (Cuerpo)',
                            size: 20
                        })
                    ],
                    spacing: { before: 600, after: 200 }
                }),
                new Paragraph({
                    alignment: AlignmentType.LEFT,
                    children: [
                        new TextRun({
                            text: `En caso de que las No Conformidades no sean atendidas en el tiempo definido, su certificación puede ser: rechazada, suspendida, retirada.`,
                            font: 'Calibri (Cuerpo)',
                            size: 20
                        })
                    ],
                    spacing: { before: 100, after: 200 }
                }),
                new Paragraph({
                    alignment: AlignmentType.LEFT,
                    children: [
                        new TextRun({
                            text: `Contra esta decisión tiene el derecho a apelar, en un plazo no mayor a 30 días después de la emisión de esta notificación. La apelación debe ser dirigida por escrito al correo electrónico `,
                            font: 'Calibri (Cuerpo)',
                            size: 20
                        }),
                        new TextRun({
                            text: `jfortega@metrocert.com`,
                            font: 'Calibri (Cuerpo)',
                            size: 20,
                            bold: true
                        })
                    ],
                    spacing: { before: 100, after: 1600 }
                }),
                new Paragraph({
                    alignment: AlignmentType.CENTER,
                    children: [
                        Media.addImage(document, signDictator, 100, 50)
                    ],
                    spacing: { before: 1600 }
                }),
                new Paragraph({
                    alignment: AlignmentType.CENTER,
                    children: [
                        new TextRun({
                            text: "Ing. Mauricio Soberanes",
                            font: 'Calibri (Cuerpo)',
                            size: 20,
                            bold: true
                        })
                    ]
                }),
                new Paragraph({
                    alignment: AlignmentType.CENTER,
                    children: [
                        new TextRun({
                            text: `Metrocert SC`,
                            font: 'Calibri (Cuerpo)',
                            size: 20,
                            bold: true
                        })
                    ]
                })
            ],
            margins: {
                top: 350,
                bottom: 500,
                left: 800,
                right: 800
            }
        });

        return document;
    }

    public createFooter(): Table {
        const table = new Table({
            rows: [
                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: 'Elaboró:',
                                            font: 'Calibri (Cuerpo)',
                                            size: 22
                                        })
                                    ]
                                })
                            ],
                            width: {
                                size: 33,
                                type: WidthType.PERCENTAGE
                            }
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: 'Revisó:',
                                            font: 'Calibri (Cuerpo)',
                                            size: 22
                                        })
                                    ]
                                })
                            ],
                            width: {
                                size: 33,
                                type: WidthType.PERCENTAGE
                            }
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: 'Autorizó:',
                                            font: 'Calibri (Cuerpo)',
                                            size: 22
                                        })
                                    ]
                                }),
                            ],
                            width: {
                                size: 33,
                                type: WidthType.PERCENTAGE
                            }
                        })
                    ]
                }),
                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: 'Director de certificación',
                                            font: 'Calibri (Cuerpo)',
                                            size: 16,
                                            bold: true
                                        })
                                    ]
                                }),

                            ],
                            width: {
                                size: 33,
                                type: WidthType.PERCENTAGE
                            },
                            shading: {
                                fill: '#C0C0C0'
                            }
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: 'Responsable de calidad',
                                            font: 'Calibri (Cuerpo)',
                                            size: 16,
                                            bold: true
                                        })
                                    ]
                                }),

                            ],
                            width: {
                                size: 33,
                                type: WidthType.PERCENTAGE
                            },
                            shading: {
                                fill: '#C0C0C0'
                            }
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: 'Director general',
                                            font: 'Calibri (Cuerpo)',
                                            size: 16,
                                            bold: true
                                        })
                                    ]
                                }),
                            ],
                            width: {
                                size: 33,
                                type: WidthType.PERCENTAGE
                            },
                            shading: {
                                fill: '#C0C0C0'
                            }
                        })
                    ]
                })
            ],
            margins: {
                left: 70,
                right: 70
            },
            columnWidths: [3213, 3213, 3212],
            alignment: AlignmentType.CENTER
        })
        return table
    }

    getValue(submission, name) {
        var value = null
        for (const i in submission) {
            const element = submission[i]
            if (element.name === name) {
                value = element.value
            }
        }
        if (value) return value
        else return ''
    }

    getValueList(submission, name) {
        var value = null
        for (const i in submission) {
            const element = submission[i]
            if (element.name === name) {
                value = element.value
            }
        }
        if (value) return value
        else return []
    }

    public getBase64FromUrl = async (url) => {
        const data = await fetch(url, {
            headers: {
                'Cache-Control': 'no-cache, no-store, must-revalidate',
                'Pragma': 'no-cache',
                'Expires': 'Sat, 01 Jan 2000 00:00:00 GMT',
                'If-Modified-Since': '0'
            }
        });
        const blob = await data.blob();
        return new Promise((resolve) => {
            const reader = new FileReader();
            reader.readAsDataURL(blob); 
            reader.onloadend = function() {
                const base64data = reader.result;   
                resolve(base64data);
            }
        });
    }

    // END MTO_FRYD_05
}