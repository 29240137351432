<div class="container">
  <div class="text-center">
    <img
      src="/assets/icons/ecert.png"
      class="img-fluid"
      style="height: 60px"
      alt="metrocert"
    />
    <h6>MTO-FSOL-03-2-1 | R 00 | 10-Junio-2024</h6>
    <h4>Acuerdo para la certificación</h4>
  </div>
  <div class="row text">
    <div class="col-12 text-justify">
      <h6>Art.1 General</h6>
      <div class="lista1">
        <ol>
          <li>
            El presente acuerdo tiene la finalidad de presentar las bases sobre
            las que se prestará el servicio de inspección y certificación
            orgánica ofrecido por METROCERT al OPERADOR
            <strong>{{ customer }}</strong> solicitante, en conformidad con las
            regulaciones orgánicas señaladas en su solicitud de certificación y
            la ISO/IEC 17065.
          </li>
          <li>
            METROCERT desarrollará las actividades previstas en el presente
            contrato a través de su oficina ubicada en Académico de Letrán 7
            Fracc. Andrés Quintana Roo CP 58088 Morelia, Michoacán, México.
          </li>
          <li>
            Firmando esta solicitud/contrato, el OPERADOR declara que el/ella ha
            recibido una copia de las regulaciones que le aplicarán de acuerdo
            con el tipo de certificación solicitada.
          </li>
          <li>
            Ley de Productos Orgánicos, el Reglamento de la Ley de Productos
            Orgánicos, los Lineamientos para la Operación Orgánica de las
            actividades Agropecuarias y el Acuerdo por el que se da a conocer el
            Distintivo Nacional de los productos orgánicos y se establecen las
            reglas generales para su uso en el etiquetado de los productos
            certificados como orgánicos.
          </li>
        </ol>
      </div>
      <h6>Art.2 Obligaciones Contractuales del OPERADOR</h6>
      <div class="lista2">
        <ol>
          <li>El OPERADOR se compromete a:</li>
        </ol>
      </div>
      <ol type="a">
        <li>
          Llevar a cabo las actividades sujetas para la certificación en
          cumplimiento de las normas orgánicas bajo las cuales será evaluado y
          observar todas las provisiones legales que tengan que ver con las
          actividades realizadas.
        </li>
        <li>
          Entregar la documentación requerida por la regulación orgánica bajo la
          cual se le está evaluando.
        </li>
        <li>
          Dar facilidades a los inspectores, así como acceso a las
          instalaciones, los registros y documentos necesarios para hacer una
          inspección apropiada.
        </li>
        <li>
          Aceptar, en caso de incumplimiento, las medidas de sanción que se
          mencionan en el Manual de Certificación.
        </li>
        <li>
          Proveer a METROCERT con toda la información necesaria para los
          propósitos de la inspección, a través del llenado de la solicitud, el
          plan orgánico y la inspección en sitio, entre otros formatos que le
          hará llegar la certificadora.
        </li>
        <li>
          Informar a METROCERT de todos los cambios en las actividades sujetas
          para la certificación y los datos contenidos en la solicitud como son:
          la condición legal, comercial, de organización o de propiedad,
          organización y gestión (por ejemplo, directivos clave, personal que
          toma decisiones o personal técnico), modificaciones en el producto o
          en el método de producción, direcciones de contacto y sitios de
          producción, cambios importantes en el sistema de gestión de la
          calidad. (ej. Datos de identificación de las partes involucradas,
          cambios en la unidad de producción, aéreas agrícolas, etc.)
        </li>
        <li>
          Cumplir con las obligaciones financieras y administrativas
          relacionadas con las actividades descritas en este contrato,
          reconociendo que para realizar los servicios de certificación,
          METROCERT requiere la entrega de recursos técnicos y que
          consecuentemente, METROCERT no se hace responsable por no alcanzar los
          objetivos de certificación por los cuales METROCERT lleva a cabo un
          papel funcional.
        </li>
        <li>
          El operador cumple con todos los requisitos que pueda estipular el
          esquema de certificación con relación al uso de las marcas de
          conformidad y a la información relacionada con el producto
        </li>
        <li>
          Se abstengan de seguir utilizando la marca de certificación,
          distintivo nacional, logos, etiquetas de conformidad y/o cualquier
          otra indicación que se refiera a la inspección y certificación de
          METROCERT después de terminada la vigencia del certificado o después
          de la terminación, suspensión o retiro del certificado y de la
          autorización para usar la marca en casos previstos por el Manual de
          Certificación.
        </li>
        <li>
          Hacer declaraciones coherentes con la certificación obtenida bajo las
          condiciones de este acuerdo, en relación con el estatus y alcance del
          proceso de certificación.
        </li>
        <li>
          No utilizar su certificación de producto de manera que ocasione mala
          reputación para el organismo de certificación, y no hace ninguna
          declaración relacionada con su certificación de producto que el
          organismo de certificación pueda considerar engañosa o no autorizada.
        </li>
        <li>
          Si el operador suministra copias de los documentos de certificación a
          otros, los documentos se deben reproducir en su totalidad o según lo
          especifique el esquema de certificación.
        </li>
        <li>
          Al hacer referencia a su certificación de producto en medios de
          comunicación tales como documentos, folletos o publicidad, el operador
          debe cumplir con los requisitos del organismo de certificación o los
          especificados por el esquema de certificación.
        </li>
        <li>
          Mantener registros de todas las quejas referidas a los productos
          certificados, tomar las acciones respecto a las quejas y documentar
          las acciones.
        </li>
        <li>
          Permitir la participación de observadores de las autoridades
          competentes o asignados por Metrocert dentro de sus procesos de
          gestión de calidad.
        </li>
        <li>
          Informar por escrito y sin dilaciones injustificadas a los compradores
          de los productos pertinentes e intercambiar información con la
          autoridad competente, o, en su caso, con la autoridad de control o el
          organismo de control, en caso de que se demuestre una sospecha de
          incumplimiento, de que no pueda descartarse dicha sospecha, o de que
          se demuestre un incumplimiento que afecte a la integridad de los
          productos en cuestión.
        </li>
        <li>
          Aceptar que Metrocert transfiera su expediente de control en caso de
          cambio de la autoridad de control u organismo de control. En caso de
          retirada de la producción ecológica, mantener el expediente de control
          durante al menos cinco años por la última autoridad de control u
          organismo de control.
        </li>
        <li>
          Informar inmediatamente a la autoridad competente o a la autoridad u
          organismo designado conforme a lo dispuesto en el artículo 34,
          apartado 4, en caso de retirada de la producción ecológica.
        </li>
        <li>
          Aceptar intercambiar información entre autoridades de control u
          organismos de control, en caso de que los subcontratistas sean
          inspeccionados por distintas autoridades de control u organismos de
          control.
        </li>
        <li>
          Cuando un operador sospeche que un producto que él mismo ha producido,
          preparado, importado o recibido de otro operador no cumple lo
          dispuesto en el presente Reglamento, dicho operador, con sujeción a lo
          dispuesto en el artículo 28, apartado 2:
        </li>
        <ol type="a">
          <li>Identificará y separará el producto de que se trate.</li>
          <li>Comprobará si la sospecha puede demostrarse</li>
          <li>
            No comercializará el producto de que se trate como producto
            ecológico o en conversión ni lo utilizará en la producción
            ecológica, a menos que pueda descartarse la sospecha.
          </li>
          <li>
            Cuando se demuestre la sospecha o cuando esta no pueda descartarse,
            informará de inmediato a la autoridad competente correspondiente, o,
            cuando proceda, a la autoridad de control u organismo de control
            correspondiente, y le comunicará los elementos de que disponga, en
            su caso.
          </li>
          <li>
            Cooperará plenamente con la autoridad competente correspondiente, o,
            cuando proceda, con la autoridad de control u organismo de control
            correspondiente, para verificar e identificar los motivos del
            supuesto incumplimiento.
          </li>
        </ol>
        <li>
          Aceptar las sanciones definidas por Metrocert derivadas de
          incumplimientos con las regulaciones orgánicas identificadas en el
          proceso de certificación o derivadas de investigaciones desarrolladas
          por Metrocert. En caso que así lo defina Metrocert, se dejará de hacer
          referencia a la certificación orgánica de los productos en etiquetas o
          material de promoción.
        </li>
        <li>
          Aceptar inspecciones y controles adicionales definidas por Metrocert,
          en caso de incumplimientos críticos, repetitivos o continuos.
        </li>
      </ol>
      <h6>Art.3 Obligaciones Contractuales de METROCERT</h6>
      <div class="lista3">
        <ol>
          <li>
            Desarrollar las actividades objeto de la certificación, con la
            pericia requerida para tales actividades, cumplir con las reglas de
            la certificadora y reglamentos y cumplir con lo previsto en el
            Manual de Certificación.
          </li>
          <li>
            METROCERT se obliga a informar al operador acerca de todos los
            cambios y/o modificaciones a los Reglamentos de Certificación, a
            través de la página web (<span style="text-decoration: underline"
              >www.metrocert.com</span
            >), la cual es constantemente actualizada.
          </li>
          <li>
            METROCERT no asume alguna responsabilidad, en caso de que el
            operador no cumpla con las obligaciones establecidas por las
            regulaciones orgánicas y los prerrequisitos para una correcta
            inspección y actividad de certificación.
          </li>
          <li>
            De acuerdo con los requisitos ISO 17065 y las leyes aplicables,
            METROCERT se comprometerme a mantener la confidencialidad de los
            datos personales y de todas las informaciones empresariales
            derivadas de la ejecución de las acciones de control, con excepción
            de aquellos para los cuales el Manual para la certificación y
            eventuales disposiciones de ley o judiciales prevén la publicación,
            transmisión y comunicación.
          </li>
        </ol>
      </div>
      <h6>Art.4 Tarifas y Modalidades de Pago</h6>
      <div class="lista4">
        <ol>
          <li>
            Conforme al respectivo pago para las prestaciones solicitadas a
            METROCERT y objeto del presente contrato, el OPERADOR retribuirá a
            METROCERT los importes señalados en el Presupuesto o Tarifario
            METROCERT vigente para las actividades de control y certificación y
            fijados por METROCERT. Dicho tarifario es suscrito contextualmente
            con el presente contrato, por el OPERADOR y constituye parte
            integrante del mismo.
          </li>
          <li>
            En cada caso, el OPERADOR se encuentra obligado a mantenerse
            informado del Tarifario vigente incluso en el momento de la
            realización de cada control individual, más allá de las
            modificaciones de las disposiciones previstas por el Reglamento para
            la certificación METROCERT.
          </li>
          <li>
            La firma del OPERADOR en el Reporte de Inspección dará testimonio
            del completo conocimiento del OPERADOR y acepta las tasas y
            disposiciones, sin excepciones. Para este fin METROCERT dará la
            información en forma oportuna y pronta a través del sitio web
            www.metrocert.com
          </li>
          <li>
            Los importes referidos a las cuotas debidas en base a las tarifas
            serán retribuidos según las modalidades establecidas en el Tarifario
            METROCERT (o presupuesto) vigente.
          </li>
          <li>
            Cada año, antes del 28 de febrero, METROCERT comunicará al operador
            cualquier cambio relativo al Tarifario, por correo o a través de
            comunicación escrita.
          </li>
        </ol>
      </div>
      <h6>Art.5 Validez y duración - renovación automática</h6>
      <div class="lista5">
        <ol>
          <li>
            El presente contrato será válido a partir de la fecha de recepción
            por parte de /METROCERT.
          </li>
          <li>
            El presente contrato es válido hasta el 31 de diciembre del año
            siguiente al de la suscripción. Se considerará como automáticamente
            renovado por los años sucesivos si no se realiza notificación de
            revocación de una de las partes. Tales comunicaciones deben llegar
            al menos treinta (30) días antes del vencimiento del contrato.
          </li>
        </ol>
      </div>
      <h6>Art.6 Facultad de rescisión y terminación del contrato</h6>
      <div class="lista6">
        <ol>
          <li>
            El OPERADOR podrá renunciar en cualquier momento a la certificación,
            comunicando su renuncia por medio de correo electrónico o alguna
            otra comunicación escrita que muestre la fecha correcta, para ser
            enviada a la oficina de METROCERT. El OPERADOR quedará sin embargo
            obligado al pago de las tarifas fijas y variables por el trabajo
            desarrollado por METROCERT, en base a lo suscrito en el tarifario
            METROCERT (o estimado).
          </li>
          <li>
            El contrato se entenderá mutuamente terminado al momento de la
            rescisión mediante acto escrito, dejando a salvo el derecho de
            METROCERT a negociar la cuota según el Tarifario.
          </li>
        </ol>
      </div>
      <h6>Art.7 Clausula de Terminación Explicita</h6>
      <div class="lista7">
        METROCERT podrá terminar este contrato y cualquier acuerdo entre las
        partes, sin previo aviso, en casos donde el OPERADOR no haya cumplido
        con las obligaciones a su cargo establecidas por el presente contrato,
        violando el reglamento y el listado de tarifas que constituyen parte de
        este contrato. Sólo para los fines del eventual pago de la cuota, la
        resolución está equiparada a la renuncia y por lo tanto la cantidad
        total que deberá retribuir el operador se calculará de acuerdo al
        Tarifario de METROCERT (o estimado).
      </div>
      <h6>Art.8 Jurisdicción Competente</h6>
      <div class="lista8">
        <ol>
          <li>
            En caso de una controversia derivada del presente acuerdo y que
            involucre tareas, responsabilidades, derechos y deberes de
            METROCERT, la jurisdicción competente será la de los tribunales de
            la jurisdicción del Distrito Judicial de la ciudad de Morelia,
            Michoacán, México.
          </li>
          <li>
            Para asuntos diversos y de competencia de METROCERT, en caso de
            controversia y para la recuperación de los pagos, se aplicarán las
            normas de competencia y jurisdicción como se encuentran previstas
            por el Código de Procedimientos Civiles.
          </li>
        </ol>
      </div>

      <div class="sign-group">
        <div>
          <h6>Fecha</h6>
          {{ date | date : "longDate" }}
        </div>
        <div>
          <h6 class="mt-2">Firma del Operador</h6>
          <img [src]="signOperator" width="120" height="60" />
          {{ customer }}
        </div>
      </div>

      <div class="sign-group">
        <div>
          <h6>Fecha</h6>
          {{ date | date : "longDate" }}
        </div>
        <div>
          <h6 class="mt-2">Firma de Metrocert</h6>
          <img [src]="signDictator" width="120" height="60" />
          {{ metrocert }}
        </div>
      </div>

      <h4 class="text-center underline-green my-4">
        Aviso de Privacidad
      </h4>
      <p>
        De acuerdo con la Ley Federal de Protección de Datos Personales en
        Posesión de Particulares, <strong>METROCERT</strong> informa al OPERADOR
        que sus datos personales se manejarán de la siguiente manera:
      </p>
      <div class="listai">
        <ol type="I">
          <li>
            La identidad y domicilio del responsable que los recaba: El
            responsable es el Representante legal de METROCERT, domiciliado para
            efectos de la siguiente comisión en Académico de Letrán 7 Fracc.
            Andrés Quintana Roo CP 58088 Morelia, Michoacán, México, E-mail:
            contacto@metrocert.com
          </li>
          <li>
            Las finalidades del tratamiento de datos: Los datos proporcionados
            por usted se utilizarán inicialmente para poder desarrollar
            adecuadamente el servicio de inspección y certificación que nos ha
            solicitado. Sus datos generales y el estatus de su certificación
            también serán publicado en la lista de operadores certificados por
            esta agencia, para cumplir con requerimientos de Ley y de Norma de
            acreditación. Los datos podrán ser comunicados a las Instituciones
            nacionales, Secretarias de Estado, Oficinas estatales, Oficinas
            regionales, Organismos de Certificación, Centro de elaboración de
            datos u otros entes afines, complementarios o sinérgicos a nuestras
            actividades. La agencia también podrá usar sus datos para hacerle
            llegar actualizaciones de las regulaciones orgánicas u otra
            información de importancia, que puede incluir la publicación de
            eventos de difusión o capacitación.
          </li>
          <li>
            Las opciones y medios que el responsable ofrezca a los titulares
            para limitar el uso o divulgación de los datos: En caso de que usted
            no este de acuerdo en que sus datos o una parte de ellos sean
            tratados con fines adicionales, usted puede comunicarlo por escrito
            a esta agencia de certificación.
          </li>
          <li>
            Los medios para ejercer los derechos de acceso, rectificación,
            cancelación u oposición, de conformidad con lo dispuesto en esta
            Ley; Usted tendrá acceso a sus datos personales, así como a su
            rectificación, a través del sitio web
            <span style="text-decoration: underline">www.metrocert.com</span>, y
            utilizando la clave y código de acceso que le han sido asignados. La
            rectificación de sus datos puede implicar cambios en los documentos
            emitidos por esta certificadora, por lo que información o procesos
            adicionales puede ser necesario que sean implementados, para hacer
            efectivas las rectificaciones. Para cancelar u oponerse a que sus
            datos sean tratados con fines adicionales, usted podrá dirigir un
            escrito al titular del organismo de certificación.
          </li>
          <li>
            Transferencias de datos que se efectúen: Si usted ha solicitado un
            servicio de certificación válido en otros países, Metrocert tendrá
            que transferir la información que la regulación tenga definida, con
            fines de control, estadísticos o de otro tipo.
          </li>
          <li>
            El procedimiento y medio por el cual el responsable comunicará a los
            titulares de cambios al aviso de privacidad, de conformidad con lo
            previsto en esta Ley. Los cambios en el aviso de privacidad se le
            darán a conocer enviándole un nuevo formato de acuerdo para la
            certificación, que incluya los cambios correspondientes.
          </li>
        </ol>
      </div>
      <p>
        Plenamente informado de las características del tratamiento y subrayado
        que el mismo es efectuado con la plena observancia del Aviso de
        privacidad, expreso el consentimiento al tratamiento de mis datos
        personales, inclusive para su comunicación a terceros.
      </p>

      <div class="sign-group">
        <div>
          <h6>Fecha</h6>
          {{ date | date : "longDate" }}
        </div>
        <div>
          <h6 class="mt-2">Firma del Operador</h6>
          <img [src]="signOperator" width="120" height="60" />
          {{ customer }}
        </div>
      </div>
    </div>
  </div>
</div>
