import { AlignmentType, Document, Paragraph, TextRun, Media, Header, Footer, Table, TableRow, TableCell, VerticalAlign, WidthType, PageNumber, BorderStyle } from "docx";
import { logo } from "../logo";
import { userWithoutSign } from "../userWithoutSign"
import { DatePipe } from '@angular/common';

export class DocumentCreatorMTO_FSOL_08_1_v1 {

    public getBase64FromUrl = async (url) => {
        const data = await fetch(url, {
            headers: {
                'Cache-Control': 'no-cache, no-store, must-revalidate',
                'Pragma': 'no-cache',
                'Expires': 'Sat, 01 Jan 2000 00:00:00 GMT',
                'If-Modified-Since': '0'
            }
        });
        const blob = await data.blob();
        return new Promise((resolve) => {
            const reader = new FileReader();
            reader.readAsDataURL(blob); 
            reader.onloadend = function() {
                const base64data = reader.result;   
                resolve(base64data);
            }
        });
    }

    // INIT MTO_FSOL_08_1
    public async create_MTO_FSOL_08_1(data, signature = ''): Promise<Document> {
        const document = new Document();
        const image = Media.addImage(document, logo, 50, 50);
        var datePipe = new DatePipe('es-MX');

        let signatureImage = null
        if (signature) {
            signatureImage = await this.getBase64FromUrl(signature)
        } else {
            signatureImage = userWithoutSign
        }

        var unidadesProduccion = ''
        const unidades = this.getValueList(data.submission, "lista_unidades")
        unidades.forEach(unidad => {
            unidadesProduccion += unidad.nombre_unidad_produccion + ', '
        });
        unidadesProduccion = unidadesProduccion.slice(0, -2)

        document.addSection({
            headers: {
                default: new Header({
                    children: [
                        new Paragraph({ alignment: AlignmentType.CENTER, children: [image], }),
                        new Paragraph({
                            alignment: AlignmentType.CENTER, children: [
                                new TextRun({
                                    children: [`MTO-FSOL-08-1| R 02 | 03-Julio-2020 | Pág. `, PageNumber.CURRENT],
                                    font: 'Book Antiqua',
                                    size: 20,
                                    bold: true
                                }),
                                new TextRun({
                                    font: 'Book Antiqua',
                                    size: 20,
                                    bold: true,
                                    children: [' de ', PageNumber.TOTAL_PAGES]
                                }),
                            ], spacing: { before: 200, after: 100 }
                        })
                    ]
                })
            },
            footers: {
                default: new Footer({
                    children: [
                        new Paragraph({ alignment: AlignmentType.CENTER }),
                        this.createFooter()
                    ]
                }),
            },
            children: [
                new Paragraph({
                    alignment: AlignmentType.CENTER,
                    children: [
                        new TextRun({
                            text: 'PLAN ORGÁNICO PRODUCCIÓN VEGETAL',
                            font: 'Calibri (Cuerpo)',
                            size: 22,
                            bold: true
                        })
                    ],
                    spacing: { after: 200 }
                }),
                this.createTableUDP(data, datePipe),
                new Paragraph({
                    spacing: {
                        after: 400
                    }
                }),
                this.createRegisters_MTO_FSOL_08_1(data.submission),
                new Paragraph({
                    spacing: {
                        after: 400
                    }
                }),
                this.createHandling_MTO_FSOL_08_1(data.submission),
                new Paragraph({
                    alignment: AlignmentType.JUSTIFIED,
                    children: [
                        new TextRun({
                            text: `El que suscribe: ${data.customer.Legal_Authorities[0].Name} ${data.customer.Legal_Authorities[0].Middle_Name} ${data.customer.Legal_Authorities[0].Last_Name}, Titular / Representante Legal de la(s) Unidad(es) de Producción / Empresa: ${data.customer.Company_Type === "PERSONA_FISICA" ? unidadesProduccion : data.customer.Company_Name}, declaro que la información descrita en este Plan Orgánico y documentos adjuntos es verídica, y me comprometo a mantenerlo actualizado, al igual que las actividades descritas en él; con la finalidad de cumplir con la Ley de Productos Orgánicos de México (LPO) y las Regulaciones de Productos Orgánicos de Canadá (OPR).`,
                            font: 'Arial',
                            size: 18
                        })
                    ],
                    spacing: {
                        before: 400,
                        after: 400
                    },
                }),
                new Paragraph({
                    alignment: AlignmentType.JUSTIFIED,
                    children: [
                        new TextRun({
                            text: 'Atentamente:',
                            font: 'Arial',
                            size: 18, bold: true
                        })
                    ],
                    spacing: {
                        before: 400,
                        after: 400
                    },
                }),
                new Paragraph({
                    alignment: AlignmentType.CENTER,
                    children: [
                        signatureImage ? Media.addImage(document, signatureImage.toString(), 200, 100) : new TextRun({
                            text: ''
                        })
                    ]
                }),
                new Paragraph({
                    alignment: AlignmentType.LEFT,
                    children: [
                        new TextRun({
                            text: `Fecha: ${datePipe.transform(new Date(data.record.Applications[0].Signature_Date), 'd-MMMM-y')}                  Nombre y firma del operador: ${data.customer.Legal_Authorities[0].Name} ${data.customer.Legal_Authorities[0].Middle_Name} ${data.customer.Legal_Authorities[0].Last_Name ? data.customer.Legal_Authorities[0].Last_Name : ''}`,
                            font: 'Arial',
                            size: 20,
                            italics: true
                        })
                    ],
                    spacing: {
                        after: 200
                    }
                }),
                new Table({
                    rows: [
                        new TableRow({
                            children: [
                                new TableCell({
                                    children: [
                                        new Paragraph({
                                            alignment: AlignmentType.CENTER,
                                            children: [
                                                new TextRun({
                                                    text: 'EVALUACIÓN POR EL REVISOR',
                                                    font: 'Arial',
                                                    size: 20,
                                                    bold: true
                                                })
                                            ],
                                            spacing: {
                                                after: 75,
                                                before: 75
                                            }
                                        }),
                                    ],
                                    verticalAlign: VerticalAlign.CENTER,
                                    columnSpan: 2,
                                    shading: {
                                        fill: '#C0C0C0'
                                    }
                                })
                            ]
                        }),
                        new TableRow({
                            children: [
                                new TableCell({
                                    children: [
                                        new Paragraph({
                                            alignment: AlignmentType.RIGHT,
                                            children: [
                                                new TextRun({
                                                    text: 'Nombre del Revisor: ',
                                                    font: 'Calibri (Cuerpo)',
                                                    size: 22,
                                                })
                                            ],
                                            spacing: { before: 100, after: 200 }
                                        })],
                                    verticalAlign: VerticalAlign.CENTER,
                                }),
                                new TableCell({
                                    children: [new Paragraph({})],
                                    verticalAlign: VerticalAlign.CENTER,
                                    columnSpan: 1,
                                }),
                            ],
                        }),
                        new TableRow({
                            children: [
                                new TableCell({
                                    children: [
                                        new Paragraph({
                                            alignment: AlignmentType.RIGHT,
                                            children: [
                                                new TextRun({
                                                    text: 'Fecha: ',
                                                    font: 'Calibri (Cuerpo)',
                                                    size: 22,
                                                })
                                            ],
                                            spacing: { before: 100, after: 200 }
                                        })],
                                    verticalAlign: VerticalAlign.CENTER,
                                }),
                                new TableCell({
                                    children: [new Paragraph({})],
                                    verticalAlign: VerticalAlign.CENTER,
                                    columnSpan: 1,
                                }),
                            ],
                        }),
                        new TableRow({
                            children: [
                                new TableCell({
                                    children: [
                                        new Paragraph({
                                            alignment: AlignmentType.RIGHT,
                                            children: [
                                                new TextRun({
                                                    text: 'Conformidad: ',
                                                    font: 'Calibri (Cuerpo)',
                                                    size: 22,
                                                })
                                            ],
                                            spacing: { before: 100, after: 200 }
                                        })],
                                    verticalAlign: VerticalAlign.CENTER,
                                }),
                                new TableCell({
                                    children: [
                                        new Paragraph({
                                            alignment: AlignmentType.LEFT,
                                            children: [
                                                new TextRun({
                                                    text: '❏ No conformidades graves, no procederá la certificación.',
                                                    font: 'Arial',
                                                    size: 18
                                                })
                                            ]
                                        }),
                                        new Paragraph({
                                            alignment: AlignmentType.LEFT,
                                            children: [
                                                new TextRun({
                                                    text: '❏ No conformidades menores, se requiere de corrección antes de inspección. Listar los hallazgos abajo.',
                                                    font: 'Arial',
                                                    size: 18
                                                })
                                            ]
                                        }),
                                        new Paragraph({
                                            alignment: AlignmentType.LEFT,
                                            children: [
                                                new TextRun({
                                                    text: '❏ No conformidades menores, se puede realizar inspección.',
                                                    font: 'Arial',
                                                    size: 18
                                                })
                                            ]
                                        }),
                                        new Paragraph({
                                            alignment: AlignmentType.LEFT,
                                            children: [
                                                new TextRun({
                                                    text: '❏ Conformidad.',
                                                    font: 'Arial',
                                                    size: 18
                                                })
                                            ]
                                        }),
                                        new Paragraph({
                                            alignment: AlignmentType.LEFT,
                                            children: [
                                                new TextRun({
                                                    text: '❏ Otro:',
                                                    font: 'Arial',
                                                    size: 18
                                                })
                                            ]
                                        })
                                    ],
                                    verticalAlign: VerticalAlign.CENTER,
                                    columnSpan: 1,
                                }),
                            ],
                        }),
                        new TableRow({
                            children: [
                                new TableCell({
                                    children: [
                                        new Paragraph({
                                            alignment: AlignmentType.RIGHT,
                                            children: [
                                                new TextRun({
                                                    text: 'Hallazgos: ',
                                                    font: 'Calibri (Cuerpo)',
                                                    size: 22,
                                                })
                                            ],
                                            spacing: { before: 100, after: 200 }
                                        })],
                                    verticalAlign: VerticalAlign.CENTER,
                                }),
                                new TableCell({
                                    children: [new Paragraph({})],
                                    verticalAlign: VerticalAlign.CENTER,
                                    columnSpan: 1,
                                }),
                            ],
                        }),
                        new TableRow({
                            children: [
                                new TableCell({
                                    children: [
                                        new Paragraph({
                                            alignment: AlignmentType.RIGHT,
                                            children: [
                                                new TextRun({
                                                    text: 'Recomendaciones para inspector: ',
                                                    font: 'Calibri (Cuerpo)',
                                                    size: 22,
                                                })
                                            ],
                                            spacing: { before: 100, after: 200 }
                                        })],
                                    verticalAlign: VerticalAlign.CENTER,
                                }),
                                new TableCell({
                                    children: [new Paragraph({})],
                                    verticalAlign: VerticalAlign.CENTER,
                                    columnSpan: 1,
                                }),
                            ],
                        }),
                        new TableRow({
                            children: [
                                new TableCell({
                                    children: [
                                        new Paragraph({
                                            alignment: AlignmentType.RIGHT,
                                            children: [
                                                new TextRun({
                                                    text: 'Comentarios generales: ',
                                                    font: 'Calibri (Cuerpo)',
                                                    size: 22,
                                                })
                                            ],
                                            spacing: { before: 100, after: 200 }
                                        })],
                                    verticalAlign: VerticalAlign.CENTER,
                                }),
                                new TableCell({
                                    children: [new Paragraph({})],
                                    verticalAlign: VerticalAlign.CENTER,
                                    columnSpan: 1,
                                }),
                            ],
                        }),
                        new TableRow({
                            children: [
                                new TableCell({
                                    children: [
                                        new Paragraph({
                                            alignment: AlignmentType.RIGHT,
                                            children: [
                                                new TextRun({
                                                    text: 'Firma del Revisor: ',
                                                    font: 'Calibri (Cuerpo)',
                                                    size: 22,
                                                })
                                            ],
                                            spacing: { before: 100, after: 200 }
                                        })],
                                    verticalAlign: VerticalAlign.CENTER,
                                }),
                                new TableCell({
                                    children: [new Paragraph({})],
                                    verticalAlign: VerticalAlign.CENTER,
                                    columnSpan: 1,
                                }),
                            ],
                        }),
                    ],
                    margins: {
                        left: 70,
                        right: 70
                    },
                    width: {
                        size: 100,
                        type: WidthType.PERCENTAGE,
                    },
                    columnWidths: [2410, 7227]
                }),
            ],
            margins: {
                top: 350,
                bottom: 500,
                left: 1000,
                right: 1000
            }
        });

        return document;
    }

    public createFooter(): Table {
        const table = new Table({
            rows: [
                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: 'Elaboró:',
                                            font: 'Calibri (Cuerpo)',
                                            size: 22
                                        })
                                    ]
                                })
                            ],
                            width: {
                                size: 33,
                                type: WidthType.PERCENTAGE
                            }
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: 'Revisó:',
                                            font: 'Calibri (Cuerpo)',
                                            size: 22
                                        })
                                    ]
                                })
                            ],
                            width: {
                                size: 33,
                                type: WidthType.PERCENTAGE
                            }
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: 'Autorizó:',
                                            font: 'Calibri (Cuerpo)',
                                            size: 22
                                        })
                                    ]
                                }),
                            ],
                            width: {
                                size: 33,
                                type: WidthType.PERCENTAGE
                            }
                        })
                    ]
                }),
                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: 'Director de certificación',
                                            font: 'Calibri (Cuerpo)',
                                            size: 16,
                                            bold: true
                                        })
                                    ]
                                }),

                            ],
                            width: {
                                size: 33,
                                type: WidthType.PERCENTAGE
                            },
                            shading: {
                                fill: '#C0C0C0'
                            }
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: 'Responsable de calidad',
                                            font: 'Calibri (Cuerpo)',
                                            size: 16,
                                            bold: true
                                        })
                                    ]
                                }),

                            ],
                            width: {
                                size: 33,
                                type: WidthType.PERCENTAGE
                            },
                            shading: {
                                fill: '#C0C0C0'
                            }
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: 'Director general',
                                            font: 'Calibri (Cuerpo)',
                                            size: 16,
                                            bold: true
                                        })
                                    ]
                                }),
                            ],
                            width: {
                                size: 33,
                                type: WidthType.PERCENTAGE
                            },
                            shading: {
                                fill: '#C0C0C0'
                            }
                        })
                    ]
                })
            ],
            margins: {
                left: 70,
                right: 70
            },
            columnWidths: [3213, 3213, 3212]
        })
        return table
    }

    public createRegisters_MTO_FSOL_08_1(submission): Table {
        const table = new Table({
            rows: [
                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: 'REGISTROS',
                                            font: 'Arial',
                                            size: 20,
                                            bold: true
                                        })
                                    ],
                                    spacing: {
                                        after: 75,
                                        before: 75
                                    }
                                }),
                            ],
                            verticalAlign: VerticalAlign.CENTER,
                            columnSpan: 6,
                            shading: {
                                fill: '#C0C0C0'
                            }
                        })
                    ]
                }),
                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: 'No.',
                                            font: 'Arial',
                                            size: 18,
                                            bold: true
                                        })
                                    ]
                                })
                            ],
                            rowSpan: 2,
                            verticalAlign: VerticalAlign.CENTER,
                            width: {
                                size: 25,
                                type: WidthType.PERCENTAGE
                            }
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: 'Requisitos',
                                            font: 'Arial',
                                            size: 18,
                                            bold: true
                                        })
                                    ]
                                })
                            ],
                            rowSpan: 2,
                            verticalAlign: VerticalAlign.CENTER,
                            width: {
                                size: 15,
                                type: WidthType.PERCENTAGE
                            }
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: 'Incluyo información',
                                            font: 'Arial',
                                            size: 18,
                                            bold: true
                                        })
                                    ]
                                })
                            ],
                            rowSpan: 2,
                            verticalAlign: VerticalAlign.CENTER,
                            width: {
                                size: 15,
                                type: WidthType.PERCENTAGE
                            }
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: 'Comentarios',
                                            font: 'Arial',
                                            size: 18,
                                            bold: true
                                        })
                                    ]
                                })
                            ],
                            rowSpan: 2,
                            verticalAlign: VerticalAlign.CENTER,
                            width: {
                                size: 15,
                                type: WidthType.PERCENTAGE
                            }
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: 'Verificación en sitio (Evaluada por Inspector)',
                                            font: 'Arial',
                                            size: 18,
                                            bold: true
                                        })
                                    ]
                                })
                            ],
                            columnSpan: 3,
                            verticalAlign: VerticalAlign.CENTER,
                            width: {
                                size: 15,
                                type: WidthType.PERCENTAGE
                            }
                        })
                    ]
                }),
                new TableRow({
                    children: [new TableCell({
                        children: [
                            new Paragraph({
                                alignment: AlignmentType.CENTER,
                                children: [
                                    new TextRun({
                                        text: 'Implementación',
                                        font: 'Arial',
                                        size: 18,
                                        bold: true
                                    })
                                ]
                            })
                        ],
                        verticalAlign: VerticalAlign.CENTER
                    }),
                    new TableCell({
                        children: [
                            new Paragraph({
                                alignment: AlignmentType.CENTER,
                                children: [
                                    new TextRun({
                                        text: 'Confirmación',
                                        font: 'Arial',
                                        size: 18,
                                        bold: true
                                    })
                                ]
                            })
                        ],
                        verticalAlign: VerticalAlign.CENTER,
                        columnSpan: 2
                    }),]
                }),
                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: '1',
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                })
                            ],
                            verticalAlign: VerticalAlign.TOP
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: 'He incluido una lista completa de las parcelas que estoy sometiendo a evaluación para la certificación orgánica. MTO-FSOL-02',
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                })
                            ],
                            verticalAlign: VerticalAlign.TOP
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: this.getValue(submission, 'plan_organico_vegetal_registros_1_declaracion') === 'Si' ? '■ SÍ  ❏ NO' : '❏ SÍ  ■ NO',
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                })
                            ],
                            verticalAlign: VerticalAlign.TOP
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: this.getValue(submission, 'plan_organico_vegetal_registros_1_comentarios'),
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                })
                            ],
                            verticalAlign: VerticalAlign.TOP
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: '❏ I  ❏ NI',
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                })
                            ],
                            verticalAlign: VerticalAlign.TOP
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: '❏ C  ❏ NC ❏ NA',
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                })
                            ],
                            verticalAlign: VerticalAlign.TOP
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: 'Comentario:',
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                })
                            ],
                            verticalAlign: VerticalAlign.TOP
                        })
                    ]
                }),
                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: '2',
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                })
                            ],
                            verticalAlign: VerticalAlign.TOP
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: 'He incluido croquis de cada una de las parcelas. MTO-FSOL-05',
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                })
                            ],
                            verticalAlign: VerticalAlign.TOP
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: this.getValue(submission, 'plan_organico_vegetal_registros_2_declaracion') === 'Si' ? '■ SÍ  ❏ NO' : '❏ SÍ  ■ NO',
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                })
                            ],
                            verticalAlign: VerticalAlign.TOP
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: this.getValue(submission, 'plan_organico_vegetal_registros_2_comentarios'),
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                })
                            ],
                            verticalAlign: VerticalAlign.TOP
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: '❏ I  ❏ NI',
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                })
                            ],
                            verticalAlign: VerticalAlign.TOP
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: '❏ C  ❏ NC ❏ NA',
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                })
                            ],
                            verticalAlign: VerticalAlign.TOP
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: 'Comentario:',
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                })
                            ],
                            verticalAlign: VerticalAlign.TOP
                        })
                    ]
                }),
                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: '3',
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                })
                            ],
                            verticalAlign: VerticalAlign.TOP
                        }),
                        new TableCell({
                            children: [
                                new Table({
                                    rows: [
                                        new TableRow({
                                            children: [
                                                new TableCell({
                                                    children: [
                                                        new Paragraph({
                                                            alignment: AlignmentType.LEFT,
                                                            children: [
                                                                new TextRun({
                                                                    text: 'Cuento con las siguientes bitácoras',
                                                                    font: 'Arial',
                                                                    size: 18
                                                                })
                                                            ]
                                                        })
                                                    ],
                                                    borders: {
                                                        left: {
                                                            style: BorderStyle.NONE,
                                                            color: "white",
                                                            size: 0
                                                        },
                                                        top: {
                                                            style: BorderStyle.NONE,
                                                            color: "white",
                                                            size: 0
                                                        },
                                                        bottom: {
                                                            style: BorderStyle.NONE,
                                                            color: "white",
                                                            size: 0
                                                        }
                                                    }
                                                }),
                                                new TableCell({
                                                    children: [
                                                        new Paragraph({
                                                            alignment: AlignmentType.LEFT,
                                                            children: [
                                                                new TextRun({
                                                                    text: '',
                                                                    font: 'Arial',
                                                                    size: 18
                                                                })
                                                            ]
                                                        })
                                                    ],
                                                    borders: {
                                                        right: {
                                                            style: BorderStyle.NONE,
                                                            color: "white",
                                                            size: 0
                                                        },
                                                        top: {
                                                            style: BorderStyle.NONE,
                                                            color: "white",
                                                            size: 0
                                                        },
                                                        bottom: {
                                                            style: BorderStyle.NONE,
                                                            color: "white",
                                                            size: 0
                                                        }
                                                    }
                                                })
                                            ]
                                        }),
                                        new TableRow({
                                            children: [
                                                new TableCell({
                                                    children: [
                                                        new Paragraph({
                                                            alignment: AlignmentType.LEFT,
                                                            children: [
                                                                new TextRun({
                                                                    text: `${this.getValue(submission, 'plan_organico_vegetal_registros_3_a_declaracion') === "Si" ? '■' : '❏'} Compra e Inventario de insumos`,
                                                                    font: 'Arial',
                                                                    size: 18
                                                                })
                                                            ]
                                                        })
                                                    ],
                                                    borders: {
                                                        left: {
                                                            style: BorderStyle.NONE,
                                                            color: "white",
                                                            size: 0
                                                        },
                                                        top: {
                                                            style: BorderStyle.NONE,
                                                            color: "white",
                                                            size: 0
                                                        },
                                                        bottom: {
                                                            style: BorderStyle.NONE,
                                                            color: "white",
                                                            size: 0
                                                        }
                                                    }
                                                }),
                                                new TableCell({
                                                    children: [
                                                        new Paragraph({
                                                            alignment: AlignmentType.CENTER,
                                                            children: [
                                                                new TextRun({
                                                                    text: `${this.getValue(submission, 'plan_organico_vegetal_registros_3_a_declaracion') === "Si" ? '■ Sí ❏ No' : '❏ Sí ■ No'}`,
                                                                    font: 'Arial',
                                                                    size: 18
                                                                })
                                                            ]
                                                        })
                                                    ],
                                                    borders: {
                                                        right: {
                                                            style: BorderStyle.NONE,
                                                            color: "white",
                                                            size: 0
                                                        },
                                                        top: {
                                                            style: BorderStyle.NONE,
                                                            color: "white",
                                                            size: 0
                                                        },
                                                        bottom: {
                                                            style: BorderStyle.NONE,
                                                            color: "white",
                                                            size: 0
                                                        }
                                                    }
                                                })
                                            ]
                                        }),
                                        new TableRow({
                                            children: [
                                                new TableCell({
                                                    children: [
                                                        new Paragraph({
                                                            alignment: AlignmentType.LEFT,
                                                            children: [
                                                                new TextRun({
                                                                    text: `${this.getValue(submission, 'plan_organico_vegetal_registros_3_b_declaracion') === "Si" ? '■' : '❏'} Aplicación de insumos`,
                                                                    font: 'Arial',
                                                                    size: 18
                                                                })
                                                            ]
                                                        })
                                                    ],
                                                    borders: {
                                                        left: {
                                                            style: BorderStyle.NONE,
                                                            color: "white",
                                                            size: 0
                                                        },
                                                        top: {
                                                            style: BorderStyle.NONE,
                                                            color: "white",
                                                            size: 0
                                                        },
                                                        bottom: {
                                                            style: BorderStyle.NONE,
                                                            color: "white",
                                                            size: 0
                                                        }
                                                    }
                                                }),
                                                new TableCell({
                                                    children: [
                                                        new Paragraph({
                                                            alignment: AlignmentType.CENTER,
                                                            children: [
                                                                new TextRun({
                                                                    text: `${this.getValue(submission, 'plan_organico_vegetal_registros_3_b_declaracion') === "Si" ? '■ Sí ❏ No' : '❏ Sí ■ No'}`,
                                                                    font: 'Arial',
                                                                    size: 18
                                                                })
                                                            ]
                                                        })
                                                    ],
                                                    borders: {
                                                        right: {
                                                            style: BorderStyle.NONE,
                                                            color: "white",
                                                            size: 0
                                                        },
                                                        top: {
                                                            style: BorderStyle.NONE,
                                                            color: "white",
                                                            size: 0
                                                        },
                                                        bottom: {
                                                            style: BorderStyle.NONE,
                                                            color: "white",
                                                            size: 0
                                                        }
                                                    }
                                                })
                                            ]
                                        }),
                                        new TableRow({
                                            children: [
                                                new TableCell({
                                                    children: [
                                                        new Paragraph({
                                                            alignment: AlignmentType.LEFT,
                                                            children: [
                                                                new TextRun({
                                                                    text: `${this.getValue(submission, 'plan_organico_vegetal_registros_3_c_declaracion') === "Si" ? '■' : '❏'} Cosecha`,
                                                                    font: 'Arial',
                                                                    size: 18
                                                                })
                                                            ]
                                                        })
                                                    ],
                                                    borders: {
                                                        left: {
                                                            style: BorderStyle.NONE,
                                                            color: "white",
                                                            size: 0
                                                        },
                                                        top: {
                                                            style: BorderStyle.NONE,
                                                            color: "white",
                                                            size: 0
                                                        },
                                                        bottom: {
                                                            style: BorderStyle.NONE,
                                                            color: "white",
                                                            size: 0
                                                        }
                                                    }
                                                }),
                                                new TableCell({
                                                    children: [
                                                        new Paragraph({
                                                            alignment: AlignmentType.CENTER,
                                                            children: [
                                                                new TextRun({
                                                                    text: `${this.getValue(submission, 'plan_organico_vegetal_registros_3_c_declaracion') === "Si" ? '■ Sí ❏ No' : '❏ Sí ■ No'}`,
                                                                    font: 'Arial',
                                                                    size: 18
                                                                })
                                                            ]
                                                        })
                                                    ],
                                                    borders: {
                                                        right: {
                                                            style: BorderStyle.NONE,
                                                            color: "white",
                                                            size: 0
                                                        },
                                                        top: {
                                                            style: BorderStyle.NONE,
                                                            color: "white",
                                                            size: 0
                                                        },
                                                        bottom: {
                                                            style: BorderStyle.NONE,
                                                            color: "white",
                                                            size: 0
                                                        }
                                                    }
                                                })
                                            ]
                                        }),
                                        new TableRow({
                                            children: [
                                                new TableCell({
                                                    children: [
                                                        new Paragraph({
                                                            alignment: AlignmentType.LEFT,
                                                            children: [
                                                                new TextRun({
                                                                    text: `${this.getValue(submission, 'plan_organico_vegetal_registros_3_d_declaracion') === "Si" ? '■' : '❏'} Ventas`,
                                                                    font: 'Arial',
                                                                    size: 18
                                                                })
                                                            ]
                                                        })
                                                    ],
                                                    borders: {
                                                        left: {
                                                            style: BorderStyle.NONE,
                                                            color: "white",
                                                            size: 0
                                                        },
                                                        top: {
                                                            style: BorderStyle.NONE,
                                                            color: "white",
                                                            size: 0
                                                        },
                                                        bottom: {
                                                            style: BorderStyle.NONE,
                                                            color: "white",
                                                            size: 0
                                                        }
                                                    }
                                                }),
                                                new TableCell({
                                                    children: [
                                                        new Paragraph({
                                                            alignment: AlignmentType.CENTER,
                                                            children: [
                                                                new TextRun({
                                                                    text: `${this.getValue(submission, 'plan_organico_vegetal_registros_3_d_declaracion') === "Si" ? '■ Sí ❏ No' : '❏ Sí ■ No'}`,
                                                                    font: 'Arial',
                                                                    size: 18
                                                                })
                                                            ]
                                                        })
                                                    ],
                                                    borders: {
                                                        right: {
                                                            style: BorderStyle.NONE,
                                                            color: "white",
                                                            size: 0
                                                        },
                                                        top: {
                                                            style: BorderStyle.NONE,
                                                            color: "white",
                                                            size: 0
                                                        },
                                                        bottom: {
                                                            style: BorderStyle.NONE,
                                                            color: "white",
                                                            size: 0
                                                        }
                                                    }
                                                })
                                            ]
                                        }),
                                        new TableRow({
                                            children: [
                                                new TableCell({
                                                    children: [
                                                        new Paragraph({
                                                            alignment: AlignmentType.LEFT,
                                                            children: [
                                                                new TextRun({
                                                                    text: `${this.getValue(submission, 'plan_organico_vegetal_registros_3_e_declaracion') === "Si" ? '■' : '❏'} Destino producto de zonas buffer`,
                                                                    font: 'Arial',
                                                                    size: 18
                                                                })
                                                            ]
                                                        })
                                                    ],
                                                    borders: {
                                                        left: {
                                                            style: BorderStyle.NONE,
                                                            color: "white",
                                                            size: 0
                                                        },
                                                        top: {
                                                            style: BorderStyle.NONE,
                                                            color: "white",
                                                            size: 0
                                                        },
                                                        bottom: {
                                                            style: BorderStyle.NONE,
                                                            color: "white",
                                                            size: 0
                                                        }
                                                    }
                                                }),
                                                new TableCell({
                                                    children: [
                                                        new Paragraph({
                                                            alignment: AlignmentType.CENTER,
                                                            children: [
                                                                new TextRun({
                                                                    text: `${this.getValue(submission, 'plan_organico_vegetal_registros_3_e_declaracion') === "Si" ? '■ Sí ❏ No' : '❏ Sí ■ No'}`,
                                                                    font: 'Arial',
                                                                    size: 18
                                                                })
                                                            ]
                                                        })
                                                    ],
                                                    borders: {
                                                        right: {
                                                            style: BorderStyle.NONE,
                                                            color: "white",
                                                            size: 0
                                                        },
                                                        top: {
                                                            style: BorderStyle.NONE,
                                                            color: "white",
                                                            size: 0
                                                        },
                                                        bottom: {
                                                            style: BorderStyle.NONE,
                                                            color: "white",
                                                            size: 0
                                                        }
                                                    }
                                                })
                                            ]
                                        }),
                                        new TableRow({
                                            children: [
                                                new TableCell({
                                                    children: [
                                                        new Paragraph({
                                                            alignment: AlignmentType.LEFT,
                                                            children: [
                                                                new TextRun({
                                                                    text: `${this.getValue(submission, 'plan_organico_vegetal_registros_3_otro_declaracion') === "Si" ? '■' : '❏'} Otra: ${this.getValue(submission, 'plan_organico_vegetal_registros_3_otro')}`,
                                                                    font: 'Arial',
                                                                    size: 18
                                                                })
                                                            ]
                                                        })
                                                    ],
                                                    borders: {
                                                        left: {
                                                            style: BorderStyle.NONE,
                                                            color: "white",
                                                            size: 0
                                                        },
                                                        top: {
                                                            style: BorderStyle.NONE,
                                                            color: "white",
                                                            size: 0
                                                        },
                                                        bottom: {
                                                            style: BorderStyle.NONE,
                                                            color: "white",
                                                            size: 0
                                                        }
                                                    }
                                                }),
                                                new TableCell({
                                                    children: [
                                                        new Paragraph({
                                                            alignment: AlignmentType.CENTER,
                                                            children: [
                                                                new TextRun({
                                                                    text: `${this.getValue(submission, 'plan_organico_vegetal_registros_3_otro_declaracion') === "Si" ? '■ Sí ❏ No' : '❏ Sí ■ No'}`,
                                                                    font: 'Arial',
                                                                    size: 18
                                                                })
                                                            ]
                                                        })
                                                    ],
                                                    borders: {
                                                        right: {
                                                            style: BorderStyle.NONE,
                                                            color: "white",
                                                            size: 0
                                                        },
                                                        top: {
                                                            style: BorderStyle.NONE,
                                                            color: "white",
                                                            size: 0
                                                        },
                                                        bottom: {
                                                            style: BorderStyle.NONE,
                                                            color: "white",
                                                            size: 0
                                                        }
                                                    }
                                                })
                                            ]
                                        })
                                    ],
                                    columnWidths: [2777, 1325]
                                })
                            ],
                            columnSpan: 2
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: this.getValue(submission, 'plan_organico_vegetal_registros_3_comentarios'),
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                })
                            ],
                            verticalAlign: VerticalAlign.TOP
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: '❏ I  ❏ NI',
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                })
                            ],
                            verticalAlign: VerticalAlign.TOP
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: '❏ C  ❏ NC ❏ NA',
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                })
                            ],
                            verticalAlign: VerticalAlign.TOP
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: 'Comentario:',
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                })
                            ],
                            verticalAlign: VerticalAlign.TOP
                        })
                    ]
                }),
                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: '4',
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                })
                            ],
                            verticalAlign: VerticalAlign.TOP
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: 'He incluido una lista completa de riesgos para la integridad orgánica de los productos en el formato MTO-FSOL-04',
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                })
                            ],
                            verticalAlign: VerticalAlign.TOP
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: this.getValue(submission, 'plan_organico_vegetal_registros_4_declaracion') === 'Si' ? '■ SÍ  ❏ NO' : '❏ SÍ  ■ NO',
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                })
                            ],
                            verticalAlign: VerticalAlign.TOP
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: this.getValue(submission, 'plan_organico_vegetal_registros_4_comentarios'),
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                })
                            ],
                            verticalAlign: VerticalAlign.TOP
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: '❏ I  ❏ NI',
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                })
                            ],
                            verticalAlign: VerticalAlign.TOP
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: '❏ C  ❏ NC ❏ NA',
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                })
                            ],
                            verticalAlign: VerticalAlign.TOP
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: 'Comentario:',
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                })
                            ],
                            verticalAlign: VerticalAlign.TOP
                        })
                    ]
                }),
                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: '5',
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                })
                            ],
                            verticalAlign: VerticalAlign.TOP
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: 'He incluido un análisis completo de riesgos de contaminación por colindantes en el formato MTO-FSOL-06',
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                })
                            ],
                            verticalAlign: VerticalAlign.TOP
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: this.getValue(submission, 'plan_organico_vegetal_registros_5_declaracion') === 'Si' ? '■ SÍ  ❏ NO' : '❏ SÍ  ■ NO',
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                })
                            ],
                            verticalAlign: VerticalAlign.TOP
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: this.getValue(submission, 'plan_organico_vegetal_registros_5_comentarios'),
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                })
                            ],
                            verticalAlign: VerticalAlign.TOP
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: '❏ I  ❏ NI',
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                })
                            ],
                            verticalAlign: VerticalAlign.TOP
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: '❏ C  ❏ NC ❏ NA',
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                })
                            ],
                            verticalAlign: VerticalAlign.TOP
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: 'Comentario:',
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                })
                            ],
                            verticalAlign: VerticalAlign.TOP
                        })
                    ]
                }),
                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: '6',
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                })
                            ],
                            verticalAlign: VerticalAlign.TOP
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: 'He incluido una lista completa de los insumos que utilizo para el abonado y control de plagas y malezas en el formato MTO-FSOL-07',
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                })
                            ],
                            verticalAlign: VerticalAlign.TOP
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: this.getValue(submission, 'plan_organico_vegetal_registros_6_declaracion') === 'Si' ? '■ SÍ  ❏ NO' : '❏ SÍ  ■ NO',
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                })
                            ],
                            verticalAlign: VerticalAlign.TOP
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: this.getValue(submission, 'plan_organico_vegetal_registros_6_comentarios'),
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                })
                            ],
                            verticalAlign: VerticalAlign.TOP
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: '❏ I  ❏ NI',
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                })
                            ],
                            verticalAlign: VerticalAlign.TOP
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: '❏ C  ❏ NC ❏ NA',
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                })
                            ],
                            verticalAlign: VerticalAlign.TOP
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: 'Comentario:',
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                })
                            ],
                            verticalAlign: VerticalAlign.TOP
                        })
                    ]
                }),
                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: '7',
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                })
                            ],
                            verticalAlign: VerticalAlign.TOP
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: 'Registro de la fuente, cantidad, tipo de estiércol y evaluación del cumplimiento del criterio 5.5.1 CAN/CGSB-32.310',
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                })
                            ],
                            verticalAlign: VerticalAlign.TOP
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: this.getValue(submission, 'plan_organico_vegetal_registros_7_declaracion') === 'Si' ? '■ SÍ  ❏ NO' : '❏ SÍ  ■ NO',
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                })
                            ],
                            verticalAlign: VerticalAlign.TOP
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: this.getValue(submission, 'plan_organico_vegetal_registros_7_comentarios'),
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                })
                            ],
                            verticalAlign: VerticalAlign.TOP
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: '❏ I  ❏ NI',
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                })
                            ],
                            verticalAlign: VerticalAlign.TOP
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: '❏ C  ❏ NC ❏ NA',
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                })
                            ],
                            verticalAlign: VerticalAlign.TOP
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: 'Comentario:',
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                })
                            ],
                            verticalAlign: VerticalAlign.TOP
                        })
                    ]
                }),
                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: '8',
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                })
                            ],
                            verticalAlign: VerticalAlign.TOP
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: 'He incluido una lista completa de las sustancias que utilizo para el control de plagas y enfermedades en el formato MTO-FSOL-07',
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                })
                            ],
                            verticalAlign: VerticalAlign.TOP
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: this.getValue(submission, 'plan_organico_vegetal_registros_8_declaracion') === 'Si' ? '■ SÍ  ❏ NO' : '❏ SÍ  ■ NO',
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                })
                            ],
                            verticalAlign: VerticalAlign.TOP
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: this.getValue(submission, 'plan_organico_vegetal_registros_8_comentarios'),
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                })
                            ],
                            verticalAlign: VerticalAlign.TOP
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: '❏ I  ❏ NI',
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                })
                            ],
                            verticalAlign: VerticalAlign.TOP
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: '❏ C  ❏ NC ❏ NA',
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                })
                            ],
                            verticalAlign: VerticalAlign.TOP
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: 'Comentario:',
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                })
                            ],
                            verticalAlign: VerticalAlign.TOP
                        })
                    ]
                }),
                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: '9',
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                })
                            ],
                            verticalAlign: VerticalAlign.TOP
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: 'En caso de uso de semilla no-orgánica, he incluido una carta solicitando la excepción y manifestando mi compromiso para obtener mi propia semilla.',
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                })
                            ],
                            verticalAlign: VerticalAlign.TOP
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: this.getValue(submission, 'plan_organico_vegetal_registros_9_declaracion') === 'Si' ? '■ SÍ  ❏ NO' : '❏ SÍ  ■ NO',
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                })
                            ],
                            verticalAlign: VerticalAlign.TOP
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: this.getValue(submission, 'plan_organico_vegetal_registros_9_comentarios'),
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                })
                            ],
                            verticalAlign: VerticalAlign.TOP
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: '❏ I  ❏ NI',
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                })
                            ],
                            verticalAlign: VerticalAlign.TOP
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: '❏ C  ❏ NC ❏ NA',
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                })
                            ],
                            verticalAlign: VerticalAlign.TOP
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: 'Comentario:',
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                })
                            ],
                            verticalAlign: VerticalAlign.TOP
                        })
                    ]
                }),
            ],
            margins: {
                left: 70,
                right: 70
            },
            columnWidths: [450, 2850, 1345, 1545, 1545, 1200, 1545]
        })
        return table
    }
    public createHandling_MTO_FSOL_08_1(submission): Table {
        const table = new Table({
            rows: [
                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: 'MANEJO',
                                            font: 'Arial',
                                            size: 20,
                                            bold: true
                                        })
                                    ],
                                    spacing: {
                                        after: 75,
                                        before: 75
                                    }
                                }),
                            ],
                            verticalAlign: VerticalAlign.CENTER,
                            columnSpan: 6,
                            shading: {
                                fill: '#C0C0C0'
                            }
                        })
                    ]
                }),
                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: 'No.',
                                            font: 'Arial',
                                            size: 18,
                                            bold: true
                                        })
                                    ]
                                })
                            ],
                            rowSpan: 2,
                            columnSpan: 1,
                            verticalAlign: VerticalAlign.CENTER,
                            width: {
                                size: 25,
                                type: WidthType.PERCENTAGE
                            }
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: 'Requisitos',
                                            font: 'Arial',
                                            size: 18,
                                            bold: true
                                        })
                                    ]
                                })
                            ],
                            rowSpan: 2,
                            columnSpan: 1,
                            verticalAlign: VerticalAlign.CENTER,
                            width: {
                                size: 15,
                                type: WidthType.PERCENTAGE
                            }
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: 'Comentarios',
                                            font: 'Arial',
                                            size: 18,
                                            bold: true
                                        })
                                    ]
                                })
                            ],
                            rowSpan: 2,
                            columnSpan: 1,
                            verticalAlign: VerticalAlign.CENTER,
                            width: {
                                size: 15,
                                type: WidthType.PERCENTAGE
                            }
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: 'Verificación en sitio (Evaluada por Inspector)',
                                            font: 'Arial',
                                            size: 18,
                                            bold: true
                                        })
                                    ]
                                })
                            ],
                            columnSpan: 3,
                            verticalAlign: VerticalAlign.CENTER,
                            width: {
                                size: 15,
                                type: WidthType.PERCENTAGE
                            }
                        })
                    ]
                }),
                new TableRow({
                    children: [new TableCell({
                        children: [
                            new Paragraph({
                                alignment: AlignmentType.CENTER,
                                children: [
                                    new TextRun({
                                        text: 'Implementación',
                                        font: 'Arial',
                                        size: 18,
                                        bold: true
                                    })
                                ]
                            })
                        ],
                        verticalAlign: VerticalAlign.CENTER
                    }),
                    new TableCell({
                        children: [
                            new Paragraph({
                                alignment: AlignmentType.CENTER,
                                children: [
                                    new TextRun({
                                        text: 'Confirmación',
                                        font: 'Arial',
                                        size: 18,
                                        bold: true
                                    })
                                ]
                            })
                        ],
                        verticalAlign: VerticalAlign.CENTER,
                        columnSpan: 2
                    }),]
                }),
                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: '1',
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                })
                            ],
                            verticalAlign: VerticalAlign.TOP
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: 'El material de propagación (semilla, plántula) es:',
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                }),
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: (this.getValue(submission, "plan_organico_vegetal_manejo_1_organico_propio") ? '■' : '❏') + '  Orgánico externo y certificado',
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                }),
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: (this.getValue(submission, "plan_organico_vegetal_manejo_1_organico_certificado") ? '■' : '❏') + '  Convencional no tratado',
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                }),
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: (this.getValue(submission, "plan_organico_vegetal_manejo_1_convencional_tratado") ? '■' : '❏') + '  Convencional y tratado',
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                }),
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: (this.getValue(submission, "plan_organico_vegetal_manejo_1_otro") ? '■' : '❏') + ' Otro: ' + this.getValue(submission, "plan_organico_vegetal_manejo_1_otro_text"),
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                }),
                            ],
                            verticalAlign: VerticalAlign.TOP
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: this.getValue(submission, "plan_organico_vegetal_manejo_1_comentarios"),
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                })
                            ],
                            verticalAlign: VerticalAlign.TOP
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: '❏ I  ❏ NI',
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                })
                            ],
                            verticalAlign: VerticalAlign.TOP
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: '❏ C  ❏ NC ❏ NA',
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                })
                            ],
                            verticalAlign: VerticalAlign.TOP
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: 'Comentario:',
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                })
                            ],
                            verticalAlign: VerticalAlign.TOP
                        })
                    ]
                }),
                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: '2',
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                })
                            ],
                            verticalAlign: VerticalAlign.TOP
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: 'Realizo las siguientes prácticas para conservar el agua:',
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                }),
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: (this.getValue(submission, "plan_organico_vegetal_manejo_2_cosecha_agua") ? '■' : '❏') + ' Cosecha de agua de lluvia de los techos',
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                }),
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: (this.getValue(submission, "plan_organico_vegetal_manejo_2_excavacion") ? '■' : '❏') + ' Excavación para retener agua (ollas)',
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                }),
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: (this.getValue(submission, "plan_organico_vegetal_manejo_2_zanja") ? '■' : '❏') + ' Zanja de infiltración con barreras vivas',
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                }),
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: (this.getValue(submission, "plan_organico_vegetal_manejo_2_fosas_absorcion") ? '■' : '❏') + '  Los restos de praparaciones son desechados en fosas de absorción',
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                }),
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: (this.getValue(submission, "plan_organico_vegetal_manejo_2_vegetacion_cauces") ? '■' : '❏') + '  Mantenemos la vegetación en cauces naturales',
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                }),
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: (this.getValue(submission, "plan_organico_vegetal_manejo_2_protegemos") ? '■' : '❏') + '  Protegemos fuentes de agua localizadas en los predios agrícolas',
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                })
                            ],
                            verticalAlign: VerticalAlign.TOP
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: this.getValue(submission, "plan_organico_vegetal_manejo_2_comentarios"),
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                })
                            ],
                            verticalAlign: VerticalAlign.TOP
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: '❏ I  ❏ NI',
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                })
                            ],
                            verticalAlign: VerticalAlign.TOP
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: '❏ C  ❏ NC ❏ NA',
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                })
                            ],
                            verticalAlign: VerticalAlign.TOP
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: 'Comentario:',
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                })
                            ],
                            verticalAlign: VerticalAlign.TOP
                        })
                    ]
                }),
                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: '3',
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                })
                            ],
                            verticalAlign: VerticalAlign.TOP
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: 'Realizo las siguientes prácticas de preparación del suelo:',
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                }),
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: (this.getValue(submission, "plan_organico_vegetal_manejo_3_barbecho") ? '■' : '❏') + '  Barbecho',
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                }),
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: (this.getValue(submission, "plan_organico_vegetal_manejo_3_rastreo") ? '■' : '❏') + '  Rastreo',
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                }),
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: (this.getValue(submission, "plan_organico_vegetal_manejo_3_surcado") ? '■' : '❏') + '  Surcado',
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                }),
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: (this.getValue(submission, "plan_organico_vegetal_manejo_3_nivelacion") ? '■' : '❏') + '  Nivelación',
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                }),
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: (this.getValue(submission, "plan_organico_vegetal_manejo_3_roza") ? '■' : '❏') + '  Roza',
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                }),
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: (this.getValue(submission, "plan_organico_vegetal_manejo_3_otro") ? '■' : '❏') + ' Otro: ' + this.getValue(submission, "plan_organico_vegetal_manejo_3_otro_text"),
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                }),
                            ],
                            verticalAlign: VerticalAlign.TOP
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: this.getValue(submission, "plan_organico_vegetal_manejo_3_comentarios"),
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                })
                            ],
                            verticalAlign: VerticalAlign.TOP
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: '❏ I  ❏ NI',
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                })
                            ],
                            verticalAlign: VerticalAlign.TOP
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: '❏ C  ❏ NC ❏ NA',
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                })
                            ],
                            verticalAlign: VerticalAlign.TOP
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: 'Comentario:',
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                })
                            ],
                            verticalAlign: VerticalAlign.TOP
                        })
                    ]
                }),
                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: '4',
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                })
                            ],
                            verticalAlign: VerticalAlign.TOP
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: 'Realizo las siguientes actividades para el control de la erosión:',
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                }),
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: (this.getValue(submission, "plan_organico_vegetal_manejo_4_siembra_nivel") ? '■' : '❏') + ' Siembra a nivel',
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                }),
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: (this.getValue(submission, "plan_organico_vegetal_manejo_4_terrazas") ? '■' : '❏') + ' Terrazas',
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                }),
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: (this.getValue(submission, "plan_organico_vegetal_manejo_4_barreras_vivas") ? '■' : '❏') + ' Barreras vivas',
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                }),
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: (this.getValue(submission, "plan_organico_vegetal_manejo_4_barreras_muertas") ? '■' : '❏') + ' Barreras muertas',
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                }),
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: (this.getValue(submission, "plan_organico_vegetal_manejo_4_zanjas") ? '■' : '❏') + ' Bordos y zanjas',
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                }),
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: (this.getValue(submission, "plan_organico_vegetal_manejo_4_cultivos_cobertura") ? '■' : '❏') + ' Cultivos de cobertura',
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                }),
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: (this.getValue(submission, "plan_organico_vegetal_manejo_4_otro") ? '■' : '❏') + ' Otro:' + this.getValue(submission, "plan_organico_vegetal_manejo_4_otro_text"),
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                })
                            ],
                            verticalAlign: VerticalAlign.TOP
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: this.getValue(submission, "plan_organico_vegetal_manejo_4_comentarios"),
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                })
                            ],
                            verticalAlign: VerticalAlign.TOP
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: '❏ I  ❏ NI',
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                })
                            ],
                            verticalAlign: VerticalAlign.TOP
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: '❏ C  ❏ NC ❏ NA',
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                })
                            ],
                            verticalAlign: VerticalAlign.TOP
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: 'Comentario:',
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                })
                            ],
                            verticalAlign: VerticalAlign.TOP
                        })
                    ]
                }),
                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: '5',
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                })
                            ],
                            verticalAlign: VerticalAlign.TOP
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: 'Realizo las siguientes actividades para la conservación y mejoramiento de la fertilidad del suelo:',
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                }),
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: (this.getValue(submission, "plan_organico_vegetal_manejo_5_rotacion") ? '■' : '❏') + ' Rotación de cultivos',
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                }),
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: (this.getValue(submission, "plan_organico_vegetal_manejo_5_aportacion") ? '■' : '❏') + ' Aportación de materia orgánica',
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                }),
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: (this.getValue(submission, "plan_organico_vegetal_manejo_5_abonos") ? '■' : '❏') + ' Abonos verdes',
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                }),
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: (this.getValue(submission, "plan_organico_vegetal_manejo_5_cal") ? '■' : '❏') + ' Aplicación de cal o yeso',
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                }),
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: (this.getValue(submission, "plan_organico_vegetal_manejo_5_microorganismos") ? '■' : '❏') + ' Aplicación de microorganismos',
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                }),
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: (this.getValue(submission, "plan_organico_vegetal_manejo_5_mejoradores") ? '■' : '❏') + ' Aplicación de mejoradores del suelo',
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                }),
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: (this.getValue(submission, "plan_organico_vegetal_manejo_5_estiercol") ? '■' : '❏') + ' Aplicación de estiércol:',
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                }),
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: (this.getValue(submission, "plan_organico_vegetal_manejo_5_otro") ? '■' : '❏') + ' Otro: ' + this.getValue(submission, "plan_organico_vegetal_manejo_5_otro_text"),
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                })
                            ],
                            verticalAlign: VerticalAlign.TOP
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: this.getValue(submission, "plan_organico_vegetal_manejo_5_comentarios"),
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                })
                            ],
                            verticalAlign: VerticalAlign.TOP
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: '❏ I  ❏ NI',
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                })
                            ],
                            verticalAlign: VerticalAlign.TOP
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: '❏ C  ❏ NC ❏ NA',
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                })
                            ],
                            verticalAlign: VerticalAlign.TOP
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: 'Comentario:',
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                })
                            ],
                            verticalAlign: VerticalAlign.TOP
                        })
                    ]
                }),
                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: '6',
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                })
                            ],
                            verticalAlign: VerticalAlign.TOP
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: 'En caso de aplicación de estiércol:',
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                }),
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: (this.getValue(submission, "plan_organico_vegetal_manejo_6_misma_ciudad") ? '■' : '❏') + ' Proviene de la misma unidad de producción',
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                }),
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: (this.getValue(submission, "plan_organico_vegetal_manejo_6_organico") ? '■' : '❏') + ' Es orgánico',
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                }),
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: (this.getValue(submission, "plan_organico_vegetal_manejo_6_no_organico") ? '■' : '❏') + ' No es orgánico, pero proviene de ganadería extensiva (animales no enjaulados, no encerrados)',
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                }),
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: (this.getValue(submission, "plan_organico_vegetal_manejo_6_otro") ? '■' : '❏') + ' Otro: ' + this.getValue(submission, "plan_organico_vegetal_manejo_6_otro_text"),
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                })
                            ],
                            verticalAlign: VerticalAlign.TOP
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: this.getValue(submission, "plan_organico_vegetal_manejo_6_comentarios"),
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                })
                            ],
                            verticalAlign: VerticalAlign.TOP
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: '❏ I  ❏ NI',
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                })
                            ],
                            verticalAlign: VerticalAlign.TOP
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: '❏ C  ❏ NC ❏ NA',
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                })
                            ],
                            verticalAlign: VerticalAlign.TOP
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: 'Comentario:',
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                })
                            ],
                            verticalAlign: VerticalAlign.TOP
                        })
                    ]
                }),
                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: '7',
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                })
                            ],
                            verticalAlign: VerticalAlign.TOP
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: 'En caso de aplicación de estiércol:',
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                }),
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: (this.getValue(submission, "plan_organico_vegetal_manejo_7_adecuadas_condiciones") ? '■' : '❏') + ' Se aplica en adecuadas condiciones de temperatura y humedad',
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                }),
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: (this.getValue(submission, "plan_organico_vegetal_manejo_7_no_contamina") ? '■' : '❏') + ' No contribuye a la contaminación de suelo y agua',
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                }),
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: (this.getValue(submission, "plan_organico_vegetal_manejo_7_90_120_dias_antes") ? '■' : '❏') + ' Se aplica 90 o 120 días antes de cosecha, según tipo de cultivo',
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                }),
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: (this.getValue(submission, "plan_organico_vegetal_manejo_7_ganado_parcela") ? '■' : '❏') + ' Se incluye manejo de ganado en la parcela, y se evita la contaminación del cultivo',
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                }),
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: (this.getValue(submission, "plan_organico_vegetal_manejo_7_otro") ? '■' : '❏') + ' Otro: ' + this.getValue(submission, "plan_organico_vegetal_manejo_7_otro_text"),
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                })
                            ],
                            verticalAlign: VerticalAlign.TOP
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: this.getValue(submission, "plan_organico_vegetal_manejo_7_comentarios"),
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                })
                            ],
                            verticalAlign: VerticalAlign.TOP
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: '❏ I  ❏ NI',
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                })
                            ],
                            verticalAlign: VerticalAlign.TOP
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: '❏ C  ❏ NC ❏ NA',
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                })
                            ],
                            verticalAlign: VerticalAlign.TOP
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: 'Comentario:',
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                })
                            ],
                            verticalAlign: VerticalAlign.TOP
                        })
                    ]
                }),
                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: '8',
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                })
                            ],
                            verticalAlign: VerticalAlign.TOP
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: 'Controlo las malezas por medio de:',
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                }),
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: (this.getValue(submission, "plan_organico_vegetal_manejo_8_machete") ? '■' : '❏') + ' Machete',
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                }),
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: (this.getValue(submission, "plan_organico_vegetal_manejo_8_pala") ? '■' : '❏') + ' Pala o azadón',
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                }),
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: (this.getValue(submission, "plan_organico_vegetal_manejo_8_desbrozadora") ? '■' : '❏') + ' Desbrozadora',
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                }),
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: (this.getValue(submission, "plan_organico_vegetal_manejo_8_rastra") ? '■' : '❏') + ' Rastra',
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                }),
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: (this.getValue(submission, "plan_organico_vegetal_manejo_8_fuego") ? '■' : '❏') + ' Fuego',
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                }),
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: (this.getValue(submission, "plan_organico_vegetal_manejo_8_herbicida") ? '■' : '❏') + ' Herbicida',
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                }),
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: (this.getValue(submission, "plan_organico_vegetal_manejo_8_otro") ? '■' : '❏') + ' Otro: ' + this.getValue(submission, "plan_organico_vegetal_manejo_8_otro_text"),
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                })
                            ],
                            verticalAlign: VerticalAlign.TOP
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: this.getValue(submission, "plan_organico_vegetal_manejo_8_comentarios"),
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                })
                            ],
                            verticalAlign: VerticalAlign.TOP
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: '❏ I  ❏ NI',
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                })
                            ],
                            verticalAlign: VerticalAlign.TOP
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: '❏ C  ❏ NC ❏ NA',
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                })
                            ],
                            verticalAlign: VerticalAlign.TOP
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: 'Comentario:',
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                })
                            ],
                            verticalAlign: VerticalAlign.TOP
                        })
                    ]
                }),
                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: '9',
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                })
                            ],
                            verticalAlign: VerticalAlign.TOP
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: 'Para el control de plagas y enfermedades realizó las siguientes prácticas:',
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                }),
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: (this.getValue(submission, "plan_organico_vegetal_manejo_9_enemigos_naturales") ? '■' : '❏') + ' Enemigos naturales',
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                }),
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: (this.getValue(submission, "plan_organico_vegetal_manejo_9_trampas") ? '■' : '❏') + ' Trampas',
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                }),
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: (this.getValue(submission, "plan_organico_vegetal_manejo_9_variedades") ? '■' : '❏') + ' Variedades resistentes',
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                }),
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: (this.getValue(submission, "plan_organico_vegetal_manejo_9_extractos") ? '■' : '❏') + ' Extractos vegetales',
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                }),
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: (this.getValue(submission, "plan_organico_vegetal_manejo_9_homeopaticas") ? '■' : '❏') + ' Sustancias homeopáticas',
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                }),
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: (this.getValue(submission, "plan_organico_vegetal_manejo_9_sustancias_permitidas") ? '■' : '❏') + ' Sustancias permitidas por las normas',
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                }),
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: (this.getValue(submission, "plan_organico_vegetal_manejo_9_vapor") ? '■' : '❏') + ' Agua caliente o vapor de agua',
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                }),
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: (this.getValue(submission, "plan_organico_vegetal_manejo_9_aspirado") ? '■' : '❏') + ' Aspirado',
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                }),
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: (this.getValue(submission, "plan_organico_vegetal_manejo_9_agroquimicos") ? '■' : '❏') + ' Agroquímicos',
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                }),
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: (this.getValue(submission, "plan_organico_vegetal_manejo_9_otro") ? '■' : '❏') + ' Otro: ' + this.getValue(submission, "plan_organico_vegetal_manejo_9_otro_text"),
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                })
                            ],
                            verticalAlign: VerticalAlign.TOP
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: this.getValue(submission, "plan_organico_vegetal_manejo_9_comentarios"),
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                })
                            ],
                            verticalAlign: VerticalAlign.TOP
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: '❏ I  ❏ NI',
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                })
                            ],
                            verticalAlign: VerticalAlign.TOP
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: '❏ C  ❏ NC ❏ NA',
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                })
                            ],
                            verticalAlign: VerticalAlign.TOP
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: 'Comentario:',
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                })
                            ],
                            verticalAlign: VerticalAlign.TOP
                        })
                    ]
                }),
                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: '10',
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                })
                            ],
                            verticalAlign: VerticalAlign.TOP
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: 'Tipo de plásticos usados:',
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                }),
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: '',
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                }),
                                new Table({
                                    rows: [
                                        new TableRow({
                                            children: [
                                                new TableCell({
                                                    children: [
                                                        new Paragraph({
                                                            alignment: AlignmentType.CENTER,
                                                            children: [
                                                                new TextRun({
                                                                    text: 'Uso',
                                                                    font: 'Arial',
                                                                    size: 18,
                                                                    bold: true
                                                                })
                                                            ]
                                                        })
                                                    ],
                                                    verticalAlign: VerticalAlign.CENTER,
                                                    shading: {
                                                        fill: '#C0C0C0'
                                                    }
                                                }),
                                                new TableCell({
                                                    children: [
                                                        new Paragraph({
                                                            alignment: AlignmentType.CENTER,
                                                            children: [
                                                                new TextRun({
                                                                    text: 'Material',
                                                                    font: 'Arial',
                                                                    size: 18,
                                                                    bold: true
                                                                })
                                                            ]
                                                        })
                                                    ],
                                                    verticalAlign: VerticalAlign.CENTER,
                                                    shading: {
                                                        fill: '#C0C0C0'
                                                    }
                                                }),
                                            ]
                                        }),
                                        new TableRow({
                                            children: [
                                                new TableCell({
                                                    children: [
                                                        new Paragraph({
                                                            alignment: AlignmentType.LEFT,
                                                            children: [
                                                                new TextRun({
                                                                    text: (this.getValue(submission, "plan_organico_vegetal_manejo_10_cubierta_invernadero") ? '■' : '❏') + ' Cubierta invernadero:',
                                                                    font: 'Arial',
                                                                    size: 18,
                                                                })
                                                            ]
                                                        })
                                                    ],
                                                    verticalAlign: VerticalAlign.CENTER,
                                                }),
                                                new TableCell({
                                                    children: [
                                                        new Paragraph({
                                                            alignment: AlignmentType.LEFT,
                                                            children: [
                                                                new TextRun({
                                                                    text: `${(this.getValue(submission, "plan_organico_vegetal_manejo_10_cubierta_invernadero_polietileno") ? '■' : '❏')} Polietileno`,
                                                                    font: 'Arial',
                                                                    size: 18,
                                                                })
                                                            ]
                                                        }),
                                                        new Paragraph({
                                                            alignment: AlignmentType.LEFT,
                                                            children: [
                                                                new TextRun({
                                                                    text: `${(this.getValue(submission, "plan_organico_vegetal_manejo_10_cubierta_invernadero_polipropileno") ? '■' : '❏')} Polipropileno`,
                                                                    font: 'Arial',
                                                                    size: 18,
                                                                })
                                                            ]
                                                        }),
                                                        new Paragraph({
                                                            alignment: AlignmentType.LEFT,
                                                            children: [
                                                                new TextRun({
                                                                    text: `${(this.getValue(submission, "plan_organico_vegetal_manejo_10_cubierta_invernadero_pvc") ? '■' : '❏')} PVC`,
                                                                    font: 'Arial',
                                                                    size: 18,
                                                                })
                                                            ]
                                                        }),
                                                        new Paragraph({
                                                            alignment: AlignmentType.LEFT,
                                                            children: [
                                                                new TextRun({
                                                                    text: `${(this.getValue(submission, "plan_organico_vegetal_manejo_10_cubierta_invernadero_otro") ? '■' : '❏')} Otro: ${this.getValue(submission, "plan_organico_vegetal_manejo_10_cubierta_invernadero_otro_text")}`,
                                                                    font: 'Arial',
                                                                    size: 18,
                                                                })
                                                            ]
                                                        })
                                                    ],
                                                    verticalAlign: VerticalAlign.CENTER,
                                                }),
                                            ]
                                        }),
                                        new TableRow({
                                            children: [
                                                new TableCell({
                                                    children: [
                                                        new Paragraph({
                                                            alignment: AlignmentType.LEFT,
                                                            children: [
                                                                new TextRun({
                                                                    text: (this.getValue(submission, "plan_organico_vegetal_manejo_10_cubierta_suelo") ? '■' : '❏') + ' Cubierta suelo:',
                                                                    font: 'Arial',
                                                                    size: 18,
                                                                })
                                                            ]
                                                        })
                                                    ],
                                                    verticalAlign: VerticalAlign.CENTER,
                                                }),
                                                new TableCell({
                                                    children: [
                                                        new Paragraph({
                                                            alignment: AlignmentType.LEFT,
                                                            children: [
                                                                new TextRun({
                                                                    text: `${(this.getValue(submission, "plan_organico_vegetal_manejo_10_cubierta_suelo_polietileno") ? '■' : '❏')} Polietileno`,
                                                                    font: 'Arial',
                                                                    size: 18,
                                                                })
                                                            ]
                                                        }),
                                                        new Paragraph({
                                                            alignment: AlignmentType.LEFT,
                                                            children: [
                                                                new TextRun({
                                                                    text: `${(this.getValue(submission, "plan_organico_vegetal_manejo_10_cubierta_suelo_polipropileno") ? '■' : '❏')} Polipropileno`,
                                                                    font: 'Arial',
                                                                    size: 18,
                                                                })
                                                            ]
                                                        }),
                                                        new Paragraph({
                                                            alignment: AlignmentType.LEFT,
                                                            children: [
                                                                new TextRun({
                                                                    text: `${(this.getValue(submission, "plan_organico_vegetal_manejo_10_cubierta_suelo_pvc") ? '■' : '❏')} PVC`,
                                                                    font: 'Arial',
                                                                    size: 18,
                                                                })
                                                            ]
                                                        }),
                                                        new Paragraph({
                                                            alignment: AlignmentType.LEFT,
                                                            children: [
                                                                new TextRun({
                                                                    text: `${(this.getValue(submission, "plan_organico_vegetal_manejo_10_cubierta_suelo_otro") ? '■' : '❏')} Otro: ${this.getValue(submission, "plan_organico_vegetal_manejo_10_cubierta_suelo_otro_text")}`,
                                                                    font: 'Arial',
                                                                    size: 18,
                                                                })
                                                            ]
                                                        })
                                                    ],
                                                    verticalAlign: VerticalAlign.CENTER,
                                                }),
                                            ]
                                        }),
                                        new TableRow({
                                            children: [
                                                new TableCell({
                                                    children: [
                                                        new Paragraph({
                                                            alignment: AlignmentType.LEFT,
                                                            children: [
                                                                new TextRun({
                                                                    text: (this.getValue(submission, "plan_organico_vegetal_manejo_10_mallas") ? '■' : '❏') + ' Mallas (especifique):',
                                                                    font: 'Arial',
                                                                    size: 18,
                                                                })
                                                            ]
                                                        })
                                                    ],
                                                    verticalAlign: VerticalAlign.CENTER,
                                                }),
                                                new TableCell({
                                                    children: [
                                                        new Paragraph({
                                                            alignment: AlignmentType.LEFT,
                                                            children: [
                                                                new TextRun({
                                                                    text: `${(this.getValue(submission, "plan_organico_vegetal_manejo_10_mallas_polietileno") ? '■' : '❏')} Polietileno`,
                                                                    font: 'Arial',
                                                                    size: 18,
                                                                })
                                                            ]
                                                        }),
                                                        new Paragraph({
                                                            alignment: AlignmentType.LEFT,
                                                            children: [
                                                                new TextRun({
                                                                    text: `${(this.getValue(submission, "plan_organico_vegetal_manejo_10_mallas_polipropileno") ? '■' : '❏')} Polipropileno`,
                                                                    font: 'Arial',
                                                                    size: 18,
                                                                })
                                                            ]
                                                        }),
                                                        new Paragraph({
                                                            alignment: AlignmentType.LEFT,
                                                            children: [
                                                                new TextRun({
                                                                    text: `${(this.getValue(submission, "plan_organico_vegetal_manejo_10_mallas_pvc") ? '■' : '❏')} PVC`,
                                                                    font: 'Arial',
                                                                    size: 18,
                                                                })
                                                            ]
                                                        }),
                                                        new Paragraph({
                                                            alignment: AlignmentType.LEFT,
                                                            children: [
                                                                new TextRun({
                                                                    text: `${(this.getValue(submission, "plan_organico_vegetal_manejo_10_mallas_otro") ? '■' : '❏')} Otro: ${this.getValue(submission, "plan_organico_vegetal_manejo_10_mallas_otro_text")}`,
                                                                    font: 'Arial',
                                                                    size: 18,
                                                                })
                                                            ]
                                                        })
                                                    ],
                                                    verticalAlign: VerticalAlign.CENTER,
                                                }),
                                            ]
                                        }),
                                        new TableRow({
                                            children: [
                                                new TableCell({
                                                    children: [
                                                        new Paragraph({
                                                            alignment: AlignmentType.LEFT,
                                                            children: [
                                                                new TextRun({
                                                                    text: (this.getValue(submission, "plan_organico_vegetal_manejo_10_tuberia") ? '■' : '❏') + ' Tubería de riego:',
                                                                    font: 'Arial',
                                                                    size: 18,
                                                                })
                                                            ]
                                                        })
                                                    ],
                                                    verticalAlign: VerticalAlign.CENTER,
                                                }),
                                                new TableCell({
                                                    children: [
                                                        new Paragraph({
                                                            alignment: AlignmentType.LEFT,
                                                            children: [
                                                                new TextRun({
                                                                    text: `${(this.getValue(submission, "plan_organico_vegetal_manejo_10_tuberia_polietileno") ? '■' : '❏')} Polietileno`,
                                                                    font: 'Arial',
                                                                    size: 18,
                                                                })
                                                            ]
                                                        }),
                                                        new Paragraph({
                                                            alignment: AlignmentType.LEFT,
                                                            children: [
                                                                new TextRun({
                                                                    text: `${(this.getValue(submission, "plan_organico_vegetal_manejo_10_tuberia_polipropileno") ? '■' : '❏')} Polipropileno`,
                                                                    font: 'Arial',
                                                                    size: 18,
                                                                })
                                                            ]
                                                        }),
                                                        new Paragraph({
                                                            alignment: AlignmentType.LEFT,
                                                            children: [
                                                                new TextRun({
                                                                    text: `${(this.getValue(submission, "plan_organico_vegetal_manejo_10_tuberia_pvc") ? '■' : '❏')} PVC`,
                                                                    font: 'Arial',
                                                                    size: 18,
                                                                })
                                                            ]
                                                        }),
                                                        new Paragraph({
                                                            alignment: AlignmentType.LEFT,
                                                            children: [
                                                                new TextRun({
                                                                    text: `${(this.getValue(submission, "plan_organico_vegetal_manejo_10_tuberia_otro") ? '■' : '❏')} Otro: ${this.getValue(submission, "plan_organico_vegetal_manejo_10_tuberia_otro_text")}`,
                                                                    font: 'Arial',
                                                                    size: 18,
                                                                })
                                                            ]
                                                        })
                                                    ],
                                                    verticalAlign: VerticalAlign.CENTER,
                                                }),
                                            ]
                                        }),
                                        new TableRow({
                                            children: [
                                                new TableCell({
                                                    children: [
                                                        new Paragraph({
                                                            alignment: AlignmentType.LEFT,
                                                            children: [
                                                                new TextRun({
                                                                    text: (this.getValue(submission, "plan_organico_vegetal_manejo_10_aspersor") ? '■' : '❏') + ' Aspersores o goteros:',
                                                                    font: 'Arial',
                                                                    size: 18,
                                                                })
                                                            ]
                                                        })
                                                    ],
                                                    verticalAlign: VerticalAlign.CENTER,
                                                }),
                                                new TableCell({
                                                    children: [
                                                        new Paragraph({
                                                            alignment: AlignmentType.LEFT,
                                                            children: [
                                                                new TextRun({
                                                                    text: `${(this.getValue(submission, "plan_organico_vegetal_manejo_10_aspersor_polietileno") ? '■' : '❏')} Polietileno`,
                                                                    font: 'Arial',
                                                                    size: 18,
                                                                })
                                                            ]
                                                        }),
                                                        new Paragraph({
                                                            alignment: AlignmentType.LEFT,
                                                            children: [
                                                                new TextRun({
                                                                    text: `${(this.getValue(submission, "plan_organico_vegetal_manejo_10_aspersor_polipropileno") ? '■' : '❏')} Polipropileno`,
                                                                    font: 'Arial',
                                                                    size: 18,
                                                                })
                                                            ]
                                                        }),
                                                        new Paragraph({
                                                            alignment: AlignmentType.LEFT,
                                                            children: [
                                                                new TextRun({
                                                                    text: `${(this.getValue(submission, "plan_organico_vegetal_manejo_10_aspersor_pvc") ? '■' : '❏')} PVC`,
                                                                    font: 'Arial',
                                                                    size: 18,
                                                                })
                                                            ]
                                                        }),
                                                        new Paragraph({
                                                            alignment: AlignmentType.LEFT,
                                                            children: [
                                                                new TextRun({
                                                                    text: `${(this.getValue(submission, "plan_organico_vegetal_manejo_10_aspersor_otro") ? '■' : '❏')} Otro: ${this.getValue(submission, "plan_organico_vegetal_manejo_10_aspersor_otro_text")}`,
                                                                    font: 'Arial',
                                                                    size: 18,
                                                                })
                                                            ]
                                                        })
                                                    ],
                                                    verticalAlign: VerticalAlign.CENTER,
                                                }),
                                            ]
                                        }),
                                        new TableRow({
                                            children: [
                                                new TableCell({
                                                    children: [
                                                        new Paragraph({
                                                            alignment: AlignmentType.LEFT,
                                                            children: [
                                                                new TextRun({
                                                                    text: (this.getValue(submission, "plan_organico_vegetal_manejo_10_macetas") ? '■' : '❏') + ' Contenedores / Macetas:',
                                                                    font: 'Arial',
                                                                    size: 18,
                                                                })
                                                            ]
                                                        })
                                                    ],
                                                    verticalAlign: VerticalAlign.CENTER,
                                                }),
                                                new TableCell({
                                                    children: [
                                                        new Paragraph({
                                                            alignment: AlignmentType.LEFT,
                                                            children: [
                                                                new TextRun({
                                                                    text: `${(this.getValue(submission, "plan_organico_vegetal_manejo_10_macetas_polietileno") ? '■' : '❏')} Polietileno`,
                                                                    font: 'Arial',
                                                                    size: 18,
                                                                })
                                                            ]
                                                        }),
                                                        new Paragraph({
                                                            alignment: AlignmentType.LEFT,
                                                            children: [
                                                                new TextRun({
                                                                    text: `${(this.getValue(submission, "plan_organico_vegetal_manejo_10_macetas_polipropileno") ? '■' : '❏')} Polipropileno`,
                                                                    font: 'Arial',
                                                                    size: 18,
                                                                })
                                                            ]
                                                        }),
                                                        new Paragraph({
                                                            alignment: AlignmentType.LEFT,
                                                            children: [
                                                                new TextRun({
                                                                    text: `${(this.getValue(submission, "plan_organico_vegetal_manejo_10_macetas_pvc") ? '■' : '❏')} PVC`,
                                                                    font: 'Arial',
                                                                    size: 18,
                                                                })
                                                            ]
                                                        }),
                                                        new Paragraph({
                                                            alignment: AlignmentType.LEFT,
                                                            children: [
                                                                new TextRun({
                                                                    text: `${(this.getValue(submission, "plan_organico_vegetal_manejo_10_macetas_otro") ? '■' : '❏')} Otro: ${this.getValue(submission, "plan_organico_vegetal_manejo_10_macetas_otro_text")}`,
                                                                    font: 'Arial',
                                                                    size: 18,
                                                                })
                                                            ]
                                                        })
                                                    ],
                                                    verticalAlign: VerticalAlign.CENTER,
                                                }),
                                            ]
                                        }),
                                        new TableRow({
                                            children: [
                                                new TableCell({
                                                    children: [
                                                        new Paragraph({
                                                            alignment: AlignmentType.LEFT,
                                                            children: [
                                                                new TextRun({
                                                                    text: `${(this.getValue(submission, "plan_organico_vegetal_manejo_10_cajas") ? '■' : '❏')}  Cajas de cosecha:`,
                                                                    font: 'Arial',
                                                                    size: 18,
                                                                })
                                                            ]
                                                        })
                                                    ],
                                                    verticalAlign: VerticalAlign.CENTER,
                                                }),
                                                new TableCell({
                                                    children: [
                                                        new Paragraph({
                                                            alignment: AlignmentType.LEFT,
                                                            children: [
                                                                new TextRun({
                                                                    text: `${(this.getValue(submission, "plan_organico_vegetal_manejo_10_cajas_polietileno") ? '■' : '❏')} Polietileno`,
                                                                    font: 'Arial',
                                                                    size: 18,
                                                                })
                                                            ]
                                                        }),
                                                        new Paragraph({
                                                            alignment: AlignmentType.LEFT,
                                                            children: [
                                                                new TextRun({
                                                                    text: `${(this.getValue(submission, "plan_organico_vegetal_manejo_10_cajas_polipropileno") ? '■' : '❏')} Polipropileno`,
                                                                    font: 'Arial',
                                                                    size: 18,
                                                                })
                                                            ]
                                                        }),
                                                        new Paragraph({
                                                            alignment: AlignmentType.LEFT,
                                                            children: [
                                                                new TextRun({
                                                                    text: `${(this.getValue(submission, "plan_organico_vegetal_manejo_10_cajas_pvc") ? '■' : '❏')} PVC`,
                                                                    font: 'Arial',
                                                                    size: 18,
                                                                })
                                                            ]
                                                        }),
                                                        new Paragraph({
                                                            alignment: AlignmentType.LEFT,
                                                            children: [
                                                                new TextRun({
                                                                    text: `${(this.getValue(submission, "plan_organico_vegetal_manejo_10_cajas_otro") ? '■' : '❏')} Otro: ${this.getValue(submission, "plan_organico_vegetal_manejo_10_cajas_otro_text")}`,
                                                                    font: 'Arial',
                                                                    size: 18,
                                                                })
                                                            ]
                                                        })
                                                    ],
                                                    verticalAlign: VerticalAlign.CENTER,
                                                }),
                                            ]
                                        }),
                                        new TableRow({
                                            children: [
                                                new TableCell({
                                                    children: [
                                                        new Paragraph({
                                                            alignment: AlignmentType.LEFT,
                                                            children: [
                                                                new TextRun({
                                                                    text: (this.getValue(submission, "plan_organico_vegetal_manejo_10_otro") ? '■' : '❏') + ' Otro (especifique): ' + this.getValue(submission, "plan_organico_vegetal_manejo_10_otro_name"),
                                                                    font: 'Arial',
                                                                    size: 18,
                                                                })
                                                            ]
                                                        })
                                                    ],
                                                    verticalAlign: VerticalAlign.CENTER,
                                                }),
                                                new TableCell({
                                                    children: [
                                                        new Paragraph({
                                                            alignment: AlignmentType.LEFT,
                                                            children: [
                                                                new TextRun({
                                                                    text: `${(this.getValue(submission, "plan_organico_vegetal_manejo_10_otro_polietileno") ? '■' : '❏')} Polietileno`,
                                                                    font: 'Arial',
                                                                    size: 18,
                                                                })
                                                            ]
                                                        }),
                                                        new Paragraph({
                                                            alignment: AlignmentType.LEFT,
                                                            children: [
                                                                new TextRun({
                                                                    text: `${(this.getValue(submission, "plan_organico_vegetal_manejo_10_otro_polipropileno") ? '■' : '❏')} Polipropileno`,
                                                                    font: 'Arial',
                                                                    size: 18,
                                                                })
                                                            ]
                                                        }),
                                                        new Paragraph({
                                                            alignment: AlignmentType.LEFT,
                                                            children: [
                                                                new TextRun({
                                                                    text: `${(this.getValue(submission, "plan_organico_vegetal_manejo_10_otro_pvc") ? '■' : '❏')} PVC`,
                                                                    font: 'Arial',
                                                                    size: 18,
                                                                })
                                                            ]
                                                        }),
                                                        new Paragraph({
                                                            alignment: AlignmentType.LEFT,
                                                            children: [
                                                                new TextRun({
                                                                    text: `${(this.getValue(submission, "plan_organico_vegetal_manejo_10_otro_otro") ? '■' : '❏')} Otro: ${this.getValue(submission, "plan_organico_vegetal_manejo_10_otro_text")}`,
                                                                    font: 'Arial',
                                                                    size: 18,
                                                                })
                                                            ]
                                                        })
                                                    ],
                                                    verticalAlign: VerticalAlign.CENTER,
                                                }),
                                            ]
                                        }),
                                    ],
                                    margins: {
                                        left: 70,
                                        right: 70
                                    },
                                    columnWidths: [2067, 3000]
                                }),
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: '',
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                }),
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: 'Comentarios: ' + this.getValue(submission, "plan_organico_vegetal_manejo_10_comentarios"),
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                }),
                            ],
                            verticalAlign: VerticalAlign.TOP,
                            columnSpan: 2
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: '❏ I  ❏ NI',
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                })
                            ],
                            verticalAlign: VerticalAlign.TOP
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: '❏ C  ❏ NC ❏ NA',
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                })
                            ],
                            verticalAlign: VerticalAlign.TOP
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: 'Comentario:',
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                })
                            ],
                            verticalAlign: VerticalAlign.TOP
                        })
                    ]
                }),
                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: '11',
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                })
                            ],
                            verticalAlign: VerticalAlign.TOP
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: 'Para la cosecha y post-cosecha las siguientes prácticas están bajo mi control y mantienen la calidad orgánica del producto:',
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                }),
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: (this.getValue(submission, "plan_organico_vegetal_manejo_11_corte") ? '■' : '❏') + ' Corte',
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                }),
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: (this.getValue(submission, "plan_organico_vegetal_manejo_11_seleccion") ? '■' : '❏') + ' Selección',
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                }),
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: (this.getValue(submission, "plan_organico_vegetal_manejo_11_lavado") ? '■' : '❏') + ' Lavado',
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                }),
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: (this.getValue(submission, "plan_organico_vegetal_manejo_11_refrigeracion") ? '■' : '❏') + ' Refrigeración',
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                }),
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: (this.getValue(submission, "plan_organico_vegetal_manejo_11_transporte") ? '■' : '❏') + ' Transporte',
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                }),
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: (this.getValue(submission, "plan_organico_vegetal_manejo_11_otro") ? '■' : '❏') + ' Otro: ' + this.getValue(submission, "plan_organico_vegetal_manejo_11_otro_text"),
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                })
                            ],
                            verticalAlign: VerticalAlign.TOP
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: this.getValue(submission, "plan_organico_vegetal_manejo_11_comentarios"),
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                })
                            ],
                            verticalAlign: VerticalAlign.TOP
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: '❏ I  ❏ NI',
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                })
                            ],
                            verticalAlign: VerticalAlign.TOP
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: '❏ C  ❏ NC ❏ NA',
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                })
                            ],
                            verticalAlign: VerticalAlign.TOP
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: 'Comentario:',
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                })
                            ],
                            verticalAlign: VerticalAlign.TOP
                        })
                    ]
                }),
                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: '12',
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                })
                            ],
                            verticalAlign: VerticalAlign.TOP
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: 'Los residuos de cosecha se destinan a:',
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                }),
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: (this.getValue(submission, "plan_organico_vegetal_manejo_12_reintegran") ? '■' : '❏') + ' Se reintegran a la parcela',
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                }),
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: (this.getValue(submission, "plan_organico_vegetal_manejo_12_desechan") ? '■' : '❏') + ' Se desechan en instalaciones públicas',
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                }),
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: (this.getValue(submission, "plan_organico_vegetal_manejo_12_venden") ? '■' : '❏') + ' Se venden',
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                }),
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: (this.getValue(submission, "plan_organico_vegetal_manejo_12_otro") ? '■' : '❏') + ' Otro: ' + this.getValue(submission, "plan_organico_vegetal_manejo_12_otro_text"),
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                })
                            ],
                            verticalAlign: VerticalAlign.TOP
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.LEFT,
                                    children: [
                                        new TextRun({
                                            text: this.getValue(submission, "plan_organico_vegetal_manejo_12_comentarios"),
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                })
                            ],
                            verticalAlign: VerticalAlign.TOP
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: '❏ I  ❏ NI',
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                })
                            ],
                            verticalAlign: VerticalAlign.TOP
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: '❏ C  ❏ NC ❏ NA',
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                })
                            ],
                            verticalAlign: VerticalAlign.TOP
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.CENTER,
                                    children: [
                                        new TextRun({
                                            text: 'Comentario:',
                                            font: 'Arial',
                                            size: 18
                                        })
                                    ]
                                })
                            ],
                            verticalAlign: VerticalAlign.TOP
                        })
                    ]
                }),

            ],
            margins: {
                left: 70,
                right: 70
            },
            columnWidths: [450, 3650, 1545, 1545, 1200, 1545]
        })
        return table
    }
    public createTableUDP(data, datePipe): Table {
        const table = new Table({
            rows: [
                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.RIGHT,
                                    children: [
                                        new TextRun({
                                            text: 'Operador: ',
                                            font: 'Calibri (Cuerpo)',
                                            size: 22,
                                            bold: true,

                                        })
                                    ],
                                    spacing: { before: 100, after: 200 }
                                })],
                            verticalAlign: VerticalAlign.CENTER,
                        }),
                        new TableCell({
                            children: [new Paragraph({
                                alignment: AlignmentType.CENTER,
                                children: [
                                    new TextRun({
                                        text: data.customer.Company_Name,
                                        font: 'Calibri (Cuerpo)',
                                        size: 22,
                                    })
                                ],
                            })],
                            verticalAlign: VerticalAlign.CENTER,
                            columnSpan: 3,
                        }),
                    ],
                }),
                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.RIGHT,
                                    children: [
                                        new TextRun({
                                            text: 'Código del operador: ',
                                            font: 'Calibri (Cuerpo)',
                                            size: 22,
                                            bold: true,

                                        })
                                    ],
                                    spacing: { before: 100, after: 200 }
                                })],
                            width: {
                                size: 25,
                                type: WidthType.PERCENTAGE,
                            },
                        }),
                        new TableCell({
                            children: [new Paragraph({
                                alignment: AlignmentType.CENTER,
                                children: [
                                    new TextRun({
                                        text: data.customer.Id_Comercial,
                                        font: 'Calibri (Cuerpo)',
                                        size: 22,
                                    })
                                ],
                            })],
                            verticalAlign: VerticalAlign.CENTER,
                            width: {
                                size: 25,
                                type: WidthType.PERCENTAGE,
                            },
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.RIGHT,
                                    children: [
                                        new TextRun({
                                            text: 'Fecha: ',
                                            font: 'Calibri (Cuerpo)',
                                            size: 22,
                                            bold: true,

                                        })
                                    ],
                                    spacing: { before: 100, after: 200 }
                                })],
                            verticalAlign: VerticalAlign.CENTER,
                            width: {
                                size: 25,
                                type: WidthType.PERCENTAGE,
                            },
                        }),
                        new TableCell({
                            children: [new Paragraph({
                                alignment: AlignmentType.CENTER,
                                children: [
                                    new TextRun({
                                        text: datePipe.transform(data.record.Creation_Date, "d-MMMM-y"),
                                        font: 'Calibri (Cuerpo)',
                                        size: 22,
                                    })
                                ],
                            })],
                            verticalAlign: VerticalAlign.CENTER,
                            width: {
                                size: 25,
                                type: WidthType.PERCENTAGE,
                            },
                        }),
                    ],
                }),
            ],
            margins: {
                left: 70,
                right: 70
            },
            width: {
                size: 100,
                type: WidthType.PERCENTAGE,
            },
            columnWidths: [2410, 2410, 2409, 2409]
        })

        let listaUnidades = this.getValueList(data.submission, "lista_unidades")
        listaUnidades.forEach((unidad, i) => {
            table.addChildElement(new TableRow({
                children: [
                    new TableCell({
                        children: [
                            new Paragraph({
                                alignment: AlignmentType.RIGHT,
                                children: [
                                    new TextRun({
                                        text: i === 0 ? 'Unidades de producción: ' : '',
                                        font: 'Calibri (Cuerpo)',
                                        size: 22,
                                        bold: true,

                                    })
                                ],
                                spacing: { before: 100, after: 200 }
                            })],
                        verticalAlign: VerticalAlign.CENTER,
                    }),
                    new TableCell({
                        children: [
                            new Paragraph({
                                children: [
                                    new TextRun({
                                        text: 'Nombre: ',
                                        font: 'Calibri (Cuerpo)',
                                        size: 22,
                                        bold: true,

                                    }),
                                    new TextRun({
                                        text: unidad.nombre_unidad_produccion,
                                        font: 'Calibri (Cuerpo)',
                                        size: 22,
                                    }),
                                ],
                                spacing: { before: 100, after: 200 }
                            }),
                            new Paragraph({
                                children: [
                                    new TextRun({
                                        text: `Producto: `,
                                        font: 'Calibri (Cuerpo)',
                                        size: 22,
                                        bold: true,

                                    }),
                                    new TextRun({
                                        text: `${unidad.plan_organico_vegetal_producto}`,
                                        font: 'Calibri (Cuerpo)',
                                        size: 22,

                                    }),
                                ],
                                spacing: { before: 100, after: 200 }
                            }),
                            new Paragraph({
                                children: [
                                    new TextRun({
                                        text: 'Ubicación: ',
                                        font: 'Calibri (Cuerpo)',
                                        size: 22,
                                        bold: true,

                                    }),
                                    new TextRun({
                                        text: unidad.plan_organico_vegetal_ubicacion,
                                        font: 'Calibri (Cuerpo)',
                                        size: 22,

                                    })
                                ],
                                spacing: { before: 100, after: 200 }
                            }),
                            new Paragraph({
                                children: [
                                    new TextRun({
                                        text: `Superficie: `,
                                        font: 'Calibri (Cuerpo)',
                                        size: 22,
                                        bold: true
                                    }),
                                    new TextRun({
                                        text: `${unidad.plan_organico_vegetal_superficie_total === "" || unidad.plan_organico_vegetal_superficie_total === "0" ? '❏' : '■'}`,
                                        font: 'Calibri (Cuerpo)',
                                        size: 22,
                                        bold: true
                                    }),
                                    new TextRun({
                                        text: `Total: `,
                                        font: 'Calibri (Cuerpo)',
                                        size: 22,
                                        bold: true
                                    }),
                                    new TextRun({
                                        text: `${parseFloat(unidad.plan_organico_vegetal_superficie_total).toFixed(2)} `,
                                        font: 'Calibri (Cuerpo)',
                                        size: 22,
                                    }),
                                    new TextRun({
                                        text: `${parseFloat(unidad.plan_organico_vegetal_superficie_organica) === 0 ? '❏' : '■'}`,
                                        font: 'Calibri (Cuerpo)',
                                        size: 22,
                                        bold: true
                                    }),
                                    new TextRun({
                                        text: `Orgánica: `,
                                        font: 'Calibri (Cuerpo)',
                                        size: 22,
                                        bold: true
                                    }),
                                    new TextRun({
                                        text: `${parseFloat(unidad.plan_organico_vegetal_superficie_organica) === 0 ? '' : parseFloat(unidad.plan_organico_vegetal_superficie_organica).toFixed(2)}`,
                                        font: 'Calibri (Cuerpo)',
                                        size: 22,
                                    }),
                                ],
                                spacing: { before: 100, after: 200 }
                            }),
                            new Paragraph({
                                children: [
                                    new TextRun({
                                        text: `${parseFloat(unidad.plan_organico_vegetal_superficie_transicion) === 0 ? '❏' : '■'} En transición: `,
                                        font: 'Calibri (Cuerpo)',
                                        size: 22,
                                        bold: true
                                    }),
                                    new TextRun({
                                        text: `${parseFloat(unidad.plan_organico_vegetal_superficie_transicion) === 0 ? '' : parseFloat(unidad.plan_organico_vegetal_superficie_transicion).toFixed(2)} `,
                                        font: 'Calibri (Cuerpo)',
                                        size: 22,
                                    }),
                                    new TextRun({
                                        text: `${parseFloat(unidad.plan_organico_vegetal_superficie_convecional) === 0 ? '❏' : '■'} Convencional: `,
                                        font: 'Calibri (Cuerpo)',
                                        size: 22,
                                        bold: true
                                    }),
                                    new TextRun({
                                        text: `${parseFloat(unidad.plan_organico_vegetal_superficie_convecional) === 0 ? '' : parseFloat(unidad.plan_organico_vegetal_superficie_convecional).toFixed(2)}`,
                                        font: 'Calibri (Cuerpo)',
                                        size: 22,
                                    })
                                ],
                                spacing: { before: 100, after: 200 }
                            })
                        ],
                        verticalAlign: VerticalAlign.CENTER,
                        columnSpan: 3,
                    }),
                ],
            }))
        })
        return table
    }
    getValue(submission, name) {
        var value = null
        for (const i in submission) {
            const element = submission[i]
            if (element.name === name) {
                value = element.value
            }
        }
        if (value) return value
        else return ''
    }
    getValueList(submission, name) {
        var value = null
        for (const i in submission) {
            const element = submission[i]
            if (element.name === name) {
                value = element.value
            }
        }
        if (value) return value
        else return []
    }
    // END MTO_FSOL_08_1
}