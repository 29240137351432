<div *ngIf="content">
    <div *ngFor="let item of content">
        <div [ngSwitch]="item.type">
            <dynamic-container *ngSwitchCase="'dynamic'" [data]="item" [form]="formGroup"></dynamic-container>
            <section-container *ngSwitchCase="'section'" [data]="item" [form]="formGroup"></section-container>
            <card-container *ngSwitchCase="'card'" [data]="item" [form]="formGroup"></card-container>
            <table-container *ngSwitchCase="'table'" [data]="item" [form]="formGroup"></table-container>
            <div *ngSwitchDefault>{{item.type}}</div>
        </div>
    </div>
</div>