import {
  AlignmentType,
  Document,
  Paragraph,
  TextRun,
  Media,
  Header,
  Footer,
  Table,
  TableRow,
  TableCell,
  VerticalAlign,
  WidthType,
  PageNumber,
  BorderStyle
} from "docx";
import {logo} from "../logo";
import {userWithoutSign} from "../userWithoutSign"
import {DatePipe} from '@angular/common';

export class DocumentCreatorMTO_FSOL_08_1_5_v3 {

  public getBase64FromUrl = async (url) => {
    const data = await fetch(url, {
      headers: {
          'Cache-Control': 'no-cache, no-store, must-revalidate',
          'Pragma': 'no-cache',
          'Expires': 'Sat, 01 Jan 2000 00:00:00 GMT',
          'If-Modified-Since': '0'
      }
  });
    const blob = await data.blob();
    return new Promise((resolve) => {
      const reader = new FileReader();
      reader.readAsDataURL(blob);
      reader.onloadend = function () {
        const base64data = reader.result;
        resolve(base64data);
      }
    });
  }

  // INIT MTO_FSOL_08_1_5
  public async create_MTO_FSOL_08_1_5(data, isReview = true, signature = '', signature2 = ''): Promise<Document> {
    const document = new Document();
    const image = Media.addImage(document, logo, 50, 50);
    var datePipe = new DatePipe('es-MX');

    let signatureImage = null
    if (signature) {
      signatureImage = await this.getBase64FromUrl(signature)
    } else {
      signatureImage = userWithoutSign
    }

    let signatureImage2 = null
    if (signature2) {
      signatureImage2 = await this.getBase64FromUrl(signature2)
    } else {
      signatureImage2 = isReview ? '' : userWithoutSign
    }

    var unidadesProduccion = ''
    const unidades = this.getValueList(data.submission, "lista_unidades")
    unidades.forEach(unidad => {
      unidadesProduccion += unidad.nombre_unidad_produccion + ', '
    });
    unidadesProduccion = unidadesProduccion.slice(0, -2)

    document.addSection({
      headers: {
        default: new Header({
          children: [
            new Paragraph({alignment: AlignmentType.CENTER, children: [image],}),
            new Paragraph({
              alignment: AlignmentType.CENTER, children: [
                new TextRun({
                  children: [`MTO-FSOL-08-1-5| R 06 | 22-Abril-2021 | Pág. `, PageNumber.CURRENT],
                  font: 'Book Antiqua',
                  size: 20,
                  bold: true
                }),
                new TextRun({
                  font: 'Book Antiqua',
                  size: 20,
                  bold: true,
                  children: [' de ', PageNumber.TOTAL_PAGES]
                }),
              ], spacing: {before: 200, after: 100}
            })
          ]
        })
      },
      footers: {
        default: new Footer({
          children: [
            new Paragraph({alignment: AlignmentType.CENTER}),
            this.createFooter()
          ]
        }),
      },
      children: [
        new Paragraph({
          alignment: AlignmentType.CENTER,
          children: [
            new TextRun({
              text: 'Calidad PLAN ORGÁNICO DE CLASE FUNGI',
              font: 'Calibri (Cuerpo)',
              size: 22,
              bold: true
            })
          ],
          spacing: {after: 200}
        }),
        this.createTableUDP(data, datePipe),
        new Paragraph({
          spacing: {
            after: 400
          }
        }),
        this.createRegisters_MTO_FSOL_08_1_5(data.submission),
        new Paragraph({
          spacing: {
            after: 400
          }
        }),
        this.createHandling_MTO_FSOL_08_1_5(data.submission),
        new Paragraph({
          alignment: AlignmentType.JUSTIFIED,
          children: [
            new TextRun({
              text: `El que suscribe: ${data.customer.Legal_Authorities[0].Name} ${data.customer.Legal_Authorities[0].Middle_Name} ${data.customer.Legal_Authorities[0].Last_Name}, Titular / Representante Legal de la(s) Unidad(es) de Producción / Empresa: ${data.customer.Company_Type === "PERSONA_FISICA" ? unidadesProduccion : data.customer.Company_Name}, declaro que la información descrita en este Plan Orgánico y documentos adjuntos es verídica, y me comprometo a mantenerlo actualizado, al igual que las actividades descritas en él; con la finalidad de cumplir con la Ley de Productos Orgánicos de México (LPO) y las Regulaciones de Productos Orgánicos de Canadá (COR/USCOEA).`,
              font: 'Arial',
              size: 18
            })
          ],
          spacing: {
            before: 400,
            after: 400
          },
        }),
        new Paragraph({
          alignment: AlignmentType.JUSTIFIED,
          children: [
            new TextRun({
              text: 'Atentamente:',
              font: 'Arial',
              size: 18, bold: true
            })
          ],
          spacing: {
            before: 400,
            after: 400
          },
        }),
        new Paragraph({
          alignment: AlignmentType.CENTER,
          children: [
            signatureImage ? Media.addImage(document, signatureImage.toString(), 200, 100) : new TextRun({
              text: ''
            })
          ]
        }),
        new Paragraph({
          alignment: AlignmentType.LEFT,
          children: [
            new TextRun({
              text: `Fecha: ${datePipe.transform(new Date(data.record.Applications[0].Creation_Date.includes('UTC') ? data.record.Applications[0].Creation_Date.replace('UTC', '').trim() : data.record.Applications[0].Creation_Date), 'd-MMMM-y')}                  Nombre y firma del operador: ${data.customer.Legal_Authorities[0].Name} ${data.customer.Legal_Authorities[0].Middle_Name} ${data.customer.Legal_Authorities[0].Last_Name ? data.customer.Legal_Authorities[0].Last_Name : ''}`,
              font: 'Arial',
              size: 20,
              italics: true
            })
          ],
          spacing: {
            after: 200
          }
        }),
        new Table({
          rows: [
              new TableRow({
                  children: [
                      new TableCell({
                          children: [
                              new Paragraph({
                                  alignment: AlignmentType.CENTER,
                                  children: [
                                      new TextRun({
                                          text: 'EVALUACIÓN POR EL REVISOR',
                                          font: 'Arial',
                                          size: 20,
                                          bold: true
                                      })
                                  ],
                                  spacing: {
                                      after: 75,
                                      before: 75
                                  }
                              }),
                          ],
                          verticalAlign: VerticalAlign.CENTER,
                          columnSpan: 2,
                          shading: {
                              fill: '#C0C0C0'
                          }
                      })
                  ]
              }),
              new TableRow({
                  children: [
                      new TableCell({
                          children: [
                              new Paragraph({
                                  alignment: AlignmentType.RIGHT,
                                  children: [
                                      new TextRun({
                                          text: 'Nombre del Revisor: ',
                                          font: 'Calibri (Cuerpo)',
                                          size: 22,
                                      })
                                  ],
                                  spacing: { before: 100, after: 200 }
                              })],
                          verticalAlign: VerticalAlign.CENTER,
                      }),
                      new TableCell({
                          children: [
                              new Paragraph({
                                  alignment: AlignmentType.LEFT,
                                  children: [
                                      new TextRun({
                                          text: data.reviewer ? data.reviewer.name : '',
                                          font: 'Calibri (Cuerpo)',
                                          size: 22,
                                      })
                                  ],
                                  spacing: { before: 100, after: 200 }
                              })],
                          verticalAlign: VerticalAlign.CENTER,
                      }),
                  ],
              }),
              new TableRow({
                  children: [
                      new TableCell({
                          children: [
                              new Paragraph({
                                  alignment: AlignmentType.RIGHT,
                                  children: [
                                      new TextRun({
                                          text: 'Fecha: ',
                                          font: 'Calibri (Cuerpo)',
                                          size: 22,
                                      })
                                  ],
                                  spacing: { before: 100, after: 200 }
                              })],
                          verticalAlign: VerticalAlign.CENTER,
                      }),
                      new TableCell({
                          children: [
                              new Paragraph({
                                  alignment: AlignmentType.LEFT,
                                  children: [
                                      new TextRun({
                                          text: `${data.reviewer ? datePipe.transform(new Date(data.reviewer.date), 'd-MMMM-y') : ''}`,
                                          font: 'Calibri (Cuerpo)',
                                          size: 22,
                                      })
                                  ],
                                  spacing: { before: 100, after: 200 }
                              })],
                          verticalAlign: VerticalAlign.CENTER,
                      }),
                  ],
              }),
              new TableRow({
                  children: [
                      new TableCell({
                          children: [
                              new Paragraph({
                                  alignment: AlignmentType.RIGHT,
                                  children: [
                                      new TextRun({
                                          text: 'Conformidad: ',
                                          font: 'Calibri (Cuerpo)',
                                          size: 22,
                                      })
                                  ],
                                  spacing: { before: 100, after: 200 }
                              })],
                          verticalAlign: VerticalAlign.CENTER,
                      }),
                      new TableCell({
                          children: [
                              new Paragraph({
                                  alignment: AlignmentType.LEFT,
                                  children: [
                                      new TextRun({
                                          text: `${this.getValue(data.revision, 'plan_organico_conformidad_no_conformidades_graves') === true ? '■' : '❏'} No conformidades graves, no procederá la certificación.`,
                                          font: 'Arial',
                                          size: 18
                                      })
                                  ]
                              }),
                              new Paragraph({
                                  alignment: AlignmentType.LEFT,
                                  children: [
                                      new TextRun({
                                          text: `${this.getValue(data.revision, 'plan_organico_conformidad_no_conformidades_menores_con_hallazgos') === true ? '■' : '❏'} No conformidades menores, se requiere de corrección antes de inspección. Listar los hallazgos abajo.`,
                                          font: 'Arial',
                                          size: 18
                                      })
                                  ]
                              }),
                              new Paragraph({
                                  alignment: AlignmentType.LEFT,
                                  children: [
                                      new TextRun({
                                          text: `${this.getValue(data.revision, 'plan_organico_conformidad_no_conformidades_menores') === true ? '■' : '❏'} No conformidades menores, se puede realizar inspección.`,
                                          font: 'Arial',
                                          size: 18
                                      })
                                  ]
                              }),
                              new Paragraph({
                                  alignment: AlignmentType.LEFT,
                                  children: [
                                      new TextRun({
                                          text: `${this.getValue(data.revision, 'plan_organico_conformidad_conformidad') === true ? '■' : '❏'} Conformidad`,
                                          font: 'Arial',
                                          size: 18
                                      })
                                  ]
                              }),
                              new Paragraph({
                                  alignment: AlignmentType.LEFT,
                                  children: [
                                      new TextRun({
                                          text: `${this.getValue(data.revision, 'plan_organico_conformidad_otro') === true ? `■ Otro: ${this.getValue(data.revision, 'plan_organico_conformidad_otro_text')}` : '❏ Otro:'}`,
                                          font: 'Arial',
                                          size: 18
                                      })
                                  ]
                              })
                          ],
                          verticalAlign: VerticalAlign.CENTER,
                          columnSpan: 1,
                      }),
                  ],
              }),
              new TableRow({
                  children: [
                      new TableCell({
                          children: [
                              new Paragraph({
                                  alignment: AlignmentType.RIGHT,
                                  children: [
                                      new TextRun({
                                          text: 'Hallazgos: ',
                                          font: 'Calibri (Cuerpo)',
                                          size: 22,
                                      })
                                  ],
                                  spacing: { before: 100, after: 200 }
                              })],
                          verticalAlign: VerticalAlign.CENTER,
                      }),
                      new TableCell({
                          children: [
                              new Paragraph({
                                  alignment: AlignmentType.LEFT,
                                  children: [
                                      new TextRun({
                                          text: this.getValue(data.revision, "plan_organico_hallazgos"),
                                          font: 'Calibri (Cuerpo)',
                                          size: 22,
                                      })
                                  ],
                                  spacing: { before: 100, after: 200 }
                              })],
                          verticalAlign: VerticalAlign.CENTER,
                      }),
                  ],
              }),
              new TableRow({
                  children: [
                      new TableCell({
                          children: [
                              new Paragraph({
                                  alignment: AlignmentType.RIGHT,
                                  children: [
                                      new TextRun({
                                          text: 'Recomendaciones para inspector: ',
                                          font: 'Calibri (Cuerpo)',
                                          size: 22,
                                      })
                                  ],
                                  spacing: { before: 100, after: 200 }
                              })],
                          verticalAlign: VerticalAlign.CENTER,
                      }),
                      new TableCell({
                          children: [
                              new Paragraph({
                                  alignment: AlignmentType.LEFT,
                                  children: [
                                      new TextRun({
                                          text: this.getValue(data.revision, "plan_organico_recomendaciones"),
                                          font: 'Calibri (Cuerpo)',
                                          size: 22,
                                      })
                                  ],
                                  spacing: { before: 100, after: 200 }
                              })],
                          verticalAlign: VerticalAlign.CENTER,
                      }),
                  ],
              }),
              new TableRow({
                  children: [
                      new TableCell({
                          children: [
                              new Paragraph({
                                  alignment: AlignmentType.RIGHT,
                                  children: [
                                      new TextRun({
                                          text: 'Comentarios generales: ',
                                          font: 'Calibri (Cuerpo)',
                                          size: 22,
                                      })
                                  ],
                                  spacing: { before: 100, after: 200 }
                              })],
                          verticalAlign: VerticalAlign.CENTER,
                      }),
                      new TableCell({
                          children: [
                              new Paragraph({
                                  alignment: AlignmentType.LEFT,
                                  children: [
                                      new TextRun({
                                          text: this.getValue(data.revision, "plan_organico_comentarios"),
                                          font: 'Calibri (Cuerpo)',
                                          size: 22,
                                      })
                                  ],
                                  spacing: { before: 100, after: 200 }
                              })],
                          verticalAlign: VerticalAlign.CENTER,
                      }),
                  ],
              }),
              new TableRow({
                  children: [
                      new TableCell({
                          children: [
                              new Paragraph({
                                  alignment: AlignmentType.RIGHT,
                                  children: [
                                      new TextRun({
                                          text: `Firma del Revisor:`,
                                          font: 'Calibri (Cuerpo)',
                                          size: 22,
                                      })
                                  ],
                                  spacing: { before: 100, after: 200 }
                              })],
                          verticalAlign: VerticalAlign.CENTER,
                      }),
                      new TableCell({
                          children: [
                              new Paragraph({
                                  alignment: AlignmentType.LEFT,
                                  children: [
                                    signatureImage2 ? Media.addImage(document, signatureImage2.toString(), 200, 100) : new TextRun({
                                      text: ''
                                    })
                                  ],
                                  spacing: { before: 100, after: 200 }
                              })
                          ],
                          verticalAlign: VerticalAlign.CENTER,
                          columnSpan: 1,
                      }),
                  ],
              }),
          ],
          margins: {
              left: 70,
              right: 70
          },
          width: {
              size: 100,
              type: WidthType.PERCENTAGE,
          },
          columnWidths: [2410, 7227]
        })
      ],
      margins: {
        top: 350,
        bottom: 500,
        left: 1000,
        right: 1000
      }
    });

    return document;
  }

  public createFooter(): Table {
    const table = new Table({
      rows: [
        new TableRow({
          children: [
            new TableCell({
              children: [
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                    new TextRun({
                      text: 'Elaboró:',
                      font: 'Calibri (Cuerpo)',
                      size: 22
                    })
                  ]
                })
              ],
              width: {
                size: 33,
                type: WidthType.PERCENTAGE
              }
            }),
            new TableCell({
              children: [
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                    new TextRun({
                      text: 'Revisó:',
                      font: 'Calibri (Cuerpo)',
                      size: 22
                    })
                  ]
                })
              ],
              width: {
                size: 33,
                type: WidthType.PERCENTAGE
              }
            }),
            new TableCell({
              children: [
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                    new TextRun({
                      text: 'Autorizó:',
                      font: 'Calibri (Cuerpo)',
                      size: 22
                    })
                  ]
                }),
              ],
              width: {
                size: 33,
                type: WidthType.PERCENTAGE
              }
            })
          ]
        }),
        new TableRow({
          children: [
            new TableCell({
              children: [
                new Paragraph({
                  alignment: AlignmentType.CENTER,
                  children: [
                    new TextRun({
                      text: 'Director de certificación',
                      font: 'Calibri (Cuerpo)',
                      size: 16,
                      bold: true
                    })
                  ]
                }),

              ],
              width: {
                size: 33,
                type: WidthType.PERCENTAGE
              },
              shading: {
                fill: '#C0C0C0'
              }
            }),
            new TableCell({
              children: [
                new Paragraph({
                  alignment: AlignmentType.CENTER,
                  children: [
                    new TextRun({
                      text: 'Responsable de calidad',
                      font: 'Calibri (Cuerpo)',
                      size: 16,
                      bold: true
                    })
                  ]
                }),

              ],
              width: {
                size: 33,
                type: WidthType.PERCENTAGE
              },
              shading: {
                fill: '#C0C0C0'
              }
            }),
            new TableCell({
              children: [
                new Paragraph({
                  alignment: AlignmentType.CENTER,
                  children: [
                    new TextRun({
                      text: 'Director general',
                      font: 'Calibri (Cuerpo)',
                      size: 16,
                      bold: true
                    })
                  ]
                }),
              ],
              width: {
                size: 33,
                type: WidthType.PERCENTAGE
              },
              shading: {
                fill: '#C0C0C0'
              }
            })
          ]
        })
      ],
      margins: {
        left: 70,
        right: 70
      },
      //9638
      columnWidths: [3213, 3213, 3212],
      alignment: AlignmentType.CENTER
    })
    return table
  }

  public createRegisters_MTO_FSOL_08_1_5(submission): Table {
    const table = new Table({
      rows: [
        new TableRow({
          children: [
            new TableCell({
              children: [
                new Paragraph({
                  alignment: AlignmentType.CENTER,
                  children: [
                    new TextRun({
                      text: 'REGISTROS',
                      font: 'Arial',
                      size: 20,
                      bold: true
                    })
                  ],
                  spacing: {
                    after: 75,
                    before: 75
                  }
                }),
              ],
              verticalAlign: VerticalAlign.CENTER,
              columnSpan: 6,
              shading: {
                fill: '#C0C0C0'
              }
            })
          ]
        }),
        new TableRow({
          children: [
            new TableCell({
              children: [
                new Paragraph({
                  alignment: AlignmentType.CENTER,
                  children: [
                    new TextRun({
                      text: 'No.',
                      font: 'Arial',
                      size: 18,
                      bold: true
                    })
                  ]
                })
              ],
              rowSpan: 2,
              verticalAlign: VerticalAlign.CENTER,
              width: {
                size: 25,
                type: WidthType.PERCENTAGE
              }
            }),
            new TableCell({
              children: [
                new Paragraph({
                  alignment: AlignmentType.CENTER,
                  children: [
                    new TextRun({
                      text: 'Requisitos',
                      font: 'Arial',
                      size: 18,
                      bold: true
                    })
                  ]
                })
              ],
              rowSpan: 2,
              columnSpan: 1,
              verticalAlign: VerticalAlign.CENTER,
              width: {
                size: 15,
                type: WidthType.PERCENTAGE
              }
            }),
            new TableCell({
              children: [
                new Paragraph({
                  alignment: AlignmentType.CENTER,
                  children: [
                    new TextRun({
                      text: 'Incluyo información',
                      font: 'Arial',
                      size: 18,
                      bold: true
                    })
                  ]
                })
              ],
              rowSpan: 2,
              verticalAlign: VerticalAlign.CENTER,
              width: {
                size: 15,
                type: WidthType.PERCENTAGE
              }
            }),
            new TableCell({
              children: [
                new Paragraph({
                  alignment: AlignmentType.CENTER,
                  children: [
                    new TextRun({
                      text: 'Comentarios',
                      font: 'Arial',
                      size: 18,
                      bold: true
                    })
                  ]
                })
              ],
              rowSpan: 2,
              verticalAlign: VerticalAlign.CENTER,
              width: {
                size: 15,
                type: WidthType.PERCENTAGE
              }
            }),
            new TableCell({
              children: [
                  new Paragraph({
                      alignment: AlignmentType.CENTER,
                      children: [
                          new TextRun({
                              text: 'Verificación en sitio (Evaluada por Inspector)',
                              font: 'Arial',
                              size: 18,
                              bold: true
                          })
                      ]
                  })
              ],
              columnSpan: 3,
              verticalAlign: VerticalAlign.CENTER,
              width: {
                  size: 15,
                  type: WidthType.PERCENTAGE
              }
            })
          ]
        }),
        new TableRow({
          children: [new TableCell({
              children: [
                  new Paragraph({
                      alignment: AlignmentType.CENTER,
                      children: [
                          new TextRun({
                              text: 'Implementación',
                              font: 'Arial',
                              size: 18,
                              bold: true
                          })
                      ]
                  })
              ],
              verticalAlign: VerticalAlign.CENTER
          }),
          new TableCell({
              children: [
                  new Paragraph({
                      alignment: AlignmentType.CENTER,
                      children: [
                          new TextRun({
                              text: 'Confirmación',
                              font: 'Arial',
                              size: 18,
                              bold: true
                          })
                      ]
                  })
              ],
              verticalAlign: VerticalAlign.CENTER,
              columnSpan: 2
          }),]
        }),
        new TableRow({
          children: [
            new TableCell({
              children: [
                new Paragraph({
                  alignment: AlignmentType.CENTER,
                  children: [
                    new TextRun({
                      text: '1',
                      font: 'Arial',
                      size: 18
                    })
                  ]
                })
              ],
              verticalAlign: VerticalAlign.TOP
            }),
            new TableCell({
              children: [
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                    new TextRun({
                      text: 'He incluido una lista completa de las unidades de producción que estoy sometiendo a evaluación para la certificación orgánica. MTO-FSOL-02',
                      font: 'Arial',
                      size: 18
                    })
                  ]
                })
              ],
              verticalAlign: VerticalAlign.CENTER
            }),
            new TableCell({
              children: [
                new Paragraph({
                  alignment: AlignmentType.CENTER,
                  children: [
                    new TextRun({
                      text: this.getValue(submission, 'plan_organico_fungi_registros_1_declaracion') === 'Si' ? '■ SÍ  ❏ NO' : '❏ SÍ  ■ NO',
                      font: 'Arial',
                      size: 18
                    })
                  ]
                })
              ],
              verticalAlign: VerticalAlign.TOP
            }),
            new TableCell({
              children: [
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                    new TextRun({
                      text: this.getValue(submission, 'plan_organico_fungi_registros_1_comentarios'),
                      font: 'Arial',
                      size: 18
                    })
                  ]
                })
              ],
              verticalAlign: VerticalAlign.TOP
            }),
            new TableCell({
              children: [
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                      new TextRun({
                          text: ` ${this.getValue(submission, 'plan_organico_fungi_registros_implementacion_1') === 'I' ? '■' : '❏'} I`,
                          font: 'Arial',
                          size: 18
                      })
                  ]
                }),
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                      new TextRun({
                          text: ` ${this.getValue(submission, 'plan_organico_fungi_registros_implementacion_1') === 'NI' ? '■' : '❏'} NI`,
                          font: 'Arial',
                          size: 18
                      })
                  ]
                })
              ],
              verticalAlign: VerticalAlign.TOP
          }),
          new TableCell({
              children: [
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                      new TextRun({
                          text: ` ${this.getValue(submission, 'plan_organico_fungi_registros_conformidad_1') === 'C' ? '■' : '❏'} C`,
                          font: 'Arial',
                          size: 18
                      })
                  ]
                }),
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                      new TextRun({
                          text: ` ${this.getValue(submission, 'plan_organico_fungi_registros_conformidad_1') === 'NC' ? '■' : '❏'} NC`,
                          font: 'Arial',
                          size: 18
                      })
                  ]
                }),
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                      new TextRun({
                          text: ` ${this.getValue(submission, 'plan_organico_fungi_registros_conformidad_1') === 'NA' ? '■' : '❏'} NA`,
                          font: 'Arial',
                          size: 18
                      })
                  ]
                })
              ],
              verticalAlign: VerticalAlign.TOP
          }),
          new TableCell({
              children: [
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                      new TextRun({
                          text: this.getValue(submission, 'plan_organico_fungi_registros_comentarios_inspector_1'),
                          font: 'Arial',
                          size: 18
                      })
                  ]
                })
              ],
              verticalAlign: VerticalAlign.TOP
          })
          ]
        }),
        new TableRow({
          children: [
            new TableCell({
              children: [
                new Paragraph({
                  alignment: AlignmentType.CENTER,
                  children: [
                    new TextRun({
                      text: '2',
                      font: 'Arial',
                      size: 18
                    })
                  ]
                })
              ],
              verticalAlign: VerticalAlign.TOP
            }),
            new TableCell({
              children: [
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                    new TextRun({
                      text: 'He incluido croquis de cada una de las unidades de producción. MTO-FSOL-05',
                      font: 'Arial',
                      size: 18
                    })
                  ]
                })
              ],
              verticalAlign: VerticalAlign.TOP
            }),
            new TableCell({
              children: [
                new Paragraph({
                  alignment: AlignmentType.CENTER,
                  children: [
                    new TextRun({
                      text: this.getValue(submission, 'plan_organico_fungi_registros_2_declaracion') === 'Si' ? '■ SÍ  ❏ NO' : '❏ SÍ  ■ NO',
                      font: 'Arial',
                      size: 18
                    })
                  ]
                })
              ],
              verticalAlign: VerticalAlign.TOP
            }),
            new TableCell({
              children: [
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                    new TextRun({
                      text: this.getValue(submission, 'plan_organico_fungi_registros_2_comentarios'),
                      font: 'Arial',
                      size: 18
                    })
                  ]
                })
              ],
              verticalAlign: VerticalAlign.TOP
            }),
            new TableCell({
              children: [
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                      new TextRun({
                          text: ` ${this.getValue(submission, 'plan_organico_fungi_registros_implementacion_2') === 'I' ? '■' : '❏'} I`,
                          font: 'Arial',
                          size: 18
                      })
                  ]
                }),
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                      new TextRun({
                          text: ` ${this.getValue(submission, 'plan_organico_fungi_registros_implementacion_2') === 'NI' ? '■' : '❏'} NI`,
                          font: 'Arial',
                          size: 18
                      })
                  ]
                })
              ],
              verticalAlign: VerticalAlign.TOP
          }),
          new TableCell({
              children: [
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                      new TextRun({
                          text: ` ${this.getValue(submission, 'plan_organico_fungi_registros_conformidad_2') === 'C' ? '■' : '❏'} C`,
                          font: 'Arial',
                          size: 18
                      })
                  ]
                }),
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                      new TextRun({
                          text: ` ${this.getValue(submission, 'plan_organico_fungi_registros_conformidad_2') === 'NC' ? '■' : '❏'} NC`,
                          font: 'Arial',
                          size: 18
                      })
                  ]
                }),
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                      new TextRun({
                          text: ` ${this.getValue(submission, 'plan_organico_fungi_registros_conformidad_2') === 'NA' ? '■' : '❏'} NA`,
                          font: 'Arial',
                          size: 18
                      })
                  ]
                })
              ],
              verticalAlign: VerticalAlign.TOP
          }),
          new TableCell({
              children: [
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                      new TextRun({
                          text: this.getValue(submission, 'plan_organico_fungi_registros_comentarios_inspector_2'),
                          font: 'Arial',
                          size: 18
                      })
                  ]
                })
              ],
              verticalAlign: VerticalAlign.TOP
          })
          ]
        }),

        new TableRow({
          children: [
            new TableCell({
              children: [
                new Paragraph({
                  alignment: AlignmentType.CENTER,
                  children: [
                    new TextRun({
                      text: '3',
                      font: 'Arial',
                      size: 18
                    })
                  ]
                })
              ],
              verticalAlign: VerticalAlign.TOP
            }),
            new TableCell({
              children: [
                new Table({
                  rows: [
                    new TableRow({
                      children: [
                        new TableCell({
                          children: [
                            new Paragraph({
                              alignment: AlignmentType.LEFT,
                              children: [
                                new TextRun({
                                  text: 'Cuento con las siguientes bitácoras',
                                  font: 'Arial',
                                  size: 18
                                })
                              ]
                            })
                          ],
                          borders: {
                            left: {
                              style: BorderStyle.NONE,
                              color: "white",
                              size: 0
                            },
                            top: {
                              style: BorderStyle.NONE,
                              color: "white",
                              size: 0
                            },
                            bottom: {
                              style: BorderStyle.NONE,
                              color: "white",
                              size: 0
                            }
                          }
                        }),
                        new TableCell({
                          children: [
                            new Paragraph({
                              alignment: AlignmentType.LEFT,
                              children: [
                                new TextRun({
                                  text: '',
                                  font: 'Arial',
                                  size: 18
                                })
                              ]
                            })
                          ],
                          borders: {
                            right: {
                              style: BorderStyle.NONE,
                              color: "white",
                              size: 0
                            },
                            top: {
                              style: BorderStyle.NONE,
                              color: "white",
                              size: 0
                            },
                            bottom: {
                              style: BorderStyle.NONE,
                              color: "white",
                              size: 0
                            }
                          }
                        })
                      ]
                    }),
                    new TableRow({
                      children: [
                        new TableCell({
                          children: [
                            new Paragraph({
                              alignment: AlignmentType.LEFT,
                              children: [
                                new TextRun({
                                  text: `${this.getValue(submission, 'plan_organico_fungi_registros_3_a_check') ? '■' : '❏'} Compra e Inventario de semillas/micelio`,
                                  font: 'Arial',
                                  size: 18
                                })
                              ]
                            })
                          ],
                          borders: {
                            left: {
                              style: BorderStyle.NONE,
                              color: "white",
                              size: 0
                            },
                            top: {
                              style: BorderStyle.NONE,
                              color: "white",
                              size: 0
                            },
                            bottom: {
                              style: BorderStyle.NONE,
                              color: "white",
                              size: 0
                            }
                          }
                        }),
                        new TableCell({
                          children: [
                            new Paragraph({
                              alignment: AlignmentType.CENTER,
                              children: [
                                new TextRun({
                                  text: `${this.getValue(submission, 'plan_organico_fungi_registros_3_a_declaracion') === "Si" ? '■ Sí ❏ No' : this.getValue(submission, 'plan_organico_fungi_registros_3_a_declaracion') === "No" ? '❏ Sí ■ No' : '❏ Sí ❏ No'}`,
                                  font: 'Arial',
                                  size: 18
                                })
                              ]
                            })
                          ],
                          borders: {
                            right: {
                              style: BorderStyle.NONE,
                              color: "white",
                              size: 0
                            },
                            top: {
                              style: BorderStyle.NONE,
                              color: "white",
                              size: 0
                            },
                            bottom: {
                              style: BorderStyle.NONE,
                              color: "white",
                              size: 0
                            }
                          }
                        })
                      ]
                    }),
                    new TableRow({
                      children: [
                        new TableCell({
                          children: [
                            new Paragraph({
                              alignment: AlignmentType.LEFT,
                              children: [
                                new TextRun({
                                  text: `${this.getValue(submission, 'plan_organico_fungi_registros_3_compra_inventario_e_insumos_check') ? '■' : '❏'} Compra e Inventario de insumos`,
                                  font: 'Arial',
                                  size: 18
                                })
                              ]
                            })
                          ],
                          borders: {
                            left: {
                              style: BorderStyle.NONE,
                              color: "white",
                              size: 0
                            },
                            top: {
                              style: BorderStyle.NONE,
                              color: "white",
                              size: 0
                            },
                            bottom: {
                              style: BorderStyle.NONE,
                              color: "white",
                              size: 0
                            }
                          }
                        }),
                        new TableCell({
                          children: [
                            new Paragraph({
                              alignment: AlignmentType.CENTER,
                              children: [
                                new TextRun({
                                  text: `${this.getValue(submission, 'plan_organico_fungi_registros_3_compra_inventario_e_insumos') === "Si" ? '■ Sí ❏ No' : this.getValue(submission, 'plan_organico_fungi_registros_3_compra_inventario_e_insumos') === "No" ? '❏ Sí ■ No' : '❏ Sí ❏ No'}`,
                                  font: 'Arial',
                                  size: 18
                                })
                              ]
                            })
                          ],
                          borders: {
                            right: {
                              style: BorderStyle.NONE,
                              color: "white",
                              size: 0
                            },
                            top: {
                              style: BorderStyle.NONE,
                              color: "white",
                              size: 0
                            },
                            bottom: {
                              style: BorderStyle.NONE,
                              color: "white",
                              size: 0
                            }
                          }
                        })
                      ]
                    }),
                    new TableRow({
                      children: [
                        new TableCell({
                          children: [
                            new Paragraph({
                              alignment: AlignmentType.LEFT,
                              children: [
                                new TextRun({
                                  text: `${this.getValue(submission, 'plan_organico_fungi_registros_3_aplicacion_insumos_check') ? '■' : '❏'} Aplicación de insumos`,
                                  font: 'Arial',
                                  size: 18
                                })
                              ]
                            })
                          ],
                          borders: {
                            left: {
                              style: BorderStyle.NONE,
                              color: "white",
                              size: 0
                            },
                            top: {
                              style: BorderStyle.NONE,
                              color: "white",
                              size: 0
                            },
                            bottom: {
                              style: BorderStyle.NONE,
                              color: "white",
                              size: 0
                            }
                          }
                        }),
                        new TableCell({
                          children: [
                            new Paragraph({
                              alignment: AlignmentType.CENTER,
                              children: [
                                new TextRun({
                                  text: `${this.getValue(submission, 'plan_organico_fungi_registros_3_aplicacion_insumos') === "Si" ? '■ Sí ❏ No' : this.getValue(submission, 'plan_organico_fungi_registros_3_aplicacion_insumos') === "No" ? '❏ Sí ■ No' : '❏ Sí ❏ No'}`,
                                  font: 'Arial',
                                  size: 18
                                })
                              ]
                            })
                          ],
                          borders: {
                            right: {
                              style: BorderStyle.NONE,
                              color: "white",
                              size: 0
                            },
                            top: {
                              style: BorderStyle.NONE,
                              color: "white",
                              size: 0
                            },
                            bottom: {
                              style: BorderStyle.NONE,
                              color: "white",
                              size: 0
                            }
                          }
                        })
                      ]
                    }),
                    new TableRow({
                      children: [
                        new TableCell({
                          children: [
                            new Paragraph({
                              alignment: AlignmentType.LEFT,
                              children: [
                                new TextRun({
                                  text: `${this.getValue(submission, 'plan_organico_fungi_registros_3_cosecha_check') ? '■' : '❏'} Cosecha`,
                                  font: 'Arial',
                                  size: 18
                                })
                              ]
                            })
                          ],
                          borders: {
                            left: {
                              style: BorderStyle.NONE,
                              color: "white",
                              size: 0
                            },
                            top: {
                              style: BorderStyle.NONE,
                              color: "white",
                              size: 0
                            },
                            bottom: {
                              style: BorderStyle.NONE,
                              color: "white",
                              size: 0
                            }
                          }
                        }),
                        new TableCell({
                          children: [
                            new Paragraph({
                              alignment: AlignmentType.CENTER,
                              children: [
                                new TextRun({
                                  text: `${this.getValue(submission, 'plan_organico_fungi_registros_3_cosecha') === "Si" ? '■ Sí ❏ No' : this.getValue(submission, 'plan_organico_fungi_registros_3_cosecha') === "No" ? '❏ Sí ■ No' : '❏ Sí ❏ No'}`,
                                  font: 'Arial',
                                  size: 18
                                })
                              ]
                            })
                          ],
                          borders: {
                            right: {
                              style: BorderStyle.NONE,
                              color: "white",
                              size: 0
                            },
                            top: {
                              style: BorderStyle.NONE,
                              color: "white",
                              size: 0
                            },
                            bottom: {
                              style: BorderStyle.NONE,
                              color: "white",
                              size: 0
                            }
                          }
                        })
                      ]
                    }),
                    new TableRow({
                      children: [
                        new TableCell({
                          children: [
                            new Paragraph({
                              alignment: AlignmentType.LEFT,
                              children: [
                                new TextRun({
                                  text: `${this.getValue(submission, 'plan_organico_fungi_registros_3_ventas_check') ? '■' : '❏'} Ventas`,
                                  font: 'Arial',
                                  size: 18
                                })
                              ]
                            })
                          ],
                          borders: {
                            left: {
                              style: BorderStyle.NONE,
                              color: "white",
                              size: 0
                            },
                            top: {
                              style: BorderStyle.NONE,
                              color: "white",
                              size: 0
                            },
                            bottom: {
                              style: BorderStyle.NONE,
                              color: "white",
                              size: 0
                            }
                          }
                        }),
                        new TableCell({
                          children: [
                            new Paragraph({
                              alignment: AlignmentType.CENTER,
                              children: [
                                new TextRun({
                                  text: `${this.getValue(submission, 'plan_organico_fungi_registros_3_ventas') === "Si" ? '■ Sí ❏ No' : this.getValue(submission, 'plan_organico_fungi_registros_3_ventas') === "No" ? '❏ Sí ■ No' : '❏ Sí ❏ No'}`,
                                  font: 'Arial',
                                  size: 18
                                })
                              ]
                            })
                          ],
                          borders: {
                            right: {
                              style: BorderStyle.NONE,
                              color: "white",
                              size: 0
                            },
                            top: {
                              style: BorderStyle.NONE,
                              color: "white",
                              size: 0
                            },
                            bottom: {
                              style: BorderStyle.NONE,
                              color: "white",
                              size: 0
                            }
                          }
                        })
                      ]
                    }),
                    new TableRow({
                      children: [
                        new TableCell({
                          children: [
                            new Paragraph({
                              alignment: AlignmentType.LEFT,
                              children: [
                                new TextRun({
                                  text: `${this.getValue(submission, 'plan_organico_fungi_registros_3_destino_check') ? '■' : '❏'} Destino producto de zonas buffer`,
                                  font: 'Arial',
                                  size: 18
                                })
                              ]
                            })
                          ],
                          borders: {
                            left: {
                              style: BorderStyle.NONE,
                              color: "white",
                              size: 0
                            },
                            top: {
                              style: BorderStyle.NONE,
                              color: "white",
                              size: 0
                            },
                            bottom: {
                              style: BorderStyle.NONE,
                              color: "white",
                              size: 0
                            }
                          }
                        }),
                        new TableCell({
                          children: [
                            new Paragraph({
                              alignment: AlignmentType.CENTER,
                              children: [
                                new TextRun({
                                  text: `${this.getValue(submission, 'plan_organico_fungi_registros_3_destino') === "Si" ? '■ Sí ❏ No' : this.getValue(submission, 'plan_organico_fungi_registros_3_destino') === "No" ? '❏ Sí ■ No' : '❏ Sí ❏ No'}`,
                                  font: 'Arial',
                                  size: 18
                                })
                              ]
                            })
                          ],
                          borders: {
                            right: {
                              style: BorderStyle.NONE,
                              color: "white",
                              size: 0
                            },
                            top: {
                              style: BorderStyle.NONE,
                              color: "white",
                              size: 0
                            },
                            bottom: {
                              style: BorderStyle.NONE,
                              color: "white",
                              size: 0
                            }
                          }
                        })
                      ]
                    }),
                    new TableRow({
                      children: [
                        new TableCell({
                          children: [
                            new Paragraph({
                              alignment: AlignmentType.LEFT,
                              children: [
                                new TextRun({
                                  text: `${this.getValue(submission, 'plan_organico_fungi_registros_3_otro_check') ? '■' : '❏'} Otro ¿Cuál?: ${this.getValue(submission, 'plan_organico_fungi_registros_3_otro')}`,
                                  font: 'Arial',
                                  size: 18
                                })
                              ]
                            })
                          ],
                          borders: {
                            left: {
                              style: BorderStyle.NONE,
                              color: "white",
                              size: 0
                            },
                            top: {
                              style: BorderStyle.NONE,
                              color: "white",
                              size: 0
                            },
                            bottom: {
                              style: BorderStyle.NONE,
                              color: "white",
                              size: 0
                            }
                          }
                        }),
                        new TableCell({
                          children: [
                            new Paragraph({
                              alignment: AlignmentType.CENTER,
                              children: [
                                new TextRun({
                                  text: `${this.getValue(submission, 'plan_organico_fungi_registros_3_options') === "Si" ? '■ Sí ❏ No' : this.getValue(submission, 'plan_organico_fungi_registros_3_options') === "No" ? '❏ Sí ■ No' : '❏ Sí ❏ No'}`,
                                  font: 'Arial',
                                  size: 18
                                })
                              ]
                            })
                          ],
                          borders: {
                            right: {
                              style: BorderStyle.NONE,
                              color: "white",
                              size: 0
                            },
                            top: {
                              style: BorderStyle.NONE,
                              color: "white",
                              size: 0
                            },
                            bottom: {
                              style: BorderStyle.NONE,
                              color: "white",
                              size: 0
                            }
                          }
                        })
                      ]
                    })
                  ],
                  //4395
                  columnWidths: [3075, 1320]
                })
              ],
              columnSpan: 2
            }),
            new TableCell({
              children: [
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                    new TextRun({
                      text: this.getValue(submission, 'plan_organico_fungi_registros_3_comentarios'),
                      font: 'Arial',
                      size: 18
                    })
                  ]
                })
              ],
              verticalAlign: VerticalAlign.TOP
            }),
            new TableCell({
              children: [
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                      new TextRun({
                          text: ` ${this.getValue(submission, 'plan_organico_fungi_registros_implementacion_3') === 'I' ? '■' : '❏'} I`,
                          font: 'Arial',
                          size: 18
                      })
                  ]
                }),
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                      new TextRun({
                          text: ` ${this.getValue(submission, 'plan_organico_fungi_registros_implementacion_3') === 'NI' ? '■' : '❏'} NI`,
                          font: 'Arial',
                          size: 18
                      })
                  ]
                })
              ],
              verticalAlign: VerticalAlign.TOP
          }),
          new TableCell({
              children: [
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                      new TextRun({
                          text: ` ${this.getValue(submission, 'plan_organico_fungi_registros_conformidad_3') === 'C' ? '■' : '❏'} C`,
                          font: 'Arial',
                          size: 18
                      })
                  ]
                }),
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                      new TextRun({
                          text: ` ${this.getValue(submission, 'plan_organico_fungi_registros_conformidad_3') === 'NC' ? '■' : '❏'} NC`,
                          font: 'Arial',
                          size: 18
                      })
                  ]
                }),
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                      new TextRun({
                          text: ` ${this.getValue(submission, 'plan_organico_fungi_registros_conformidad_3') === 'NA' ? '■' : '❏'} NA`,
                          font: 'Arial',
                          size: 18
                      })
                  ]
                })
              ],
              verticalAlign: VerticalAlign.TOP
          }),
          new TableCell({
              children: [
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                      new TextRun({
                          text: this.getValue(submission, 'plan_organico_fungi_registros_comentarios_inspector_3'),
                          font: 'Arial',
                          size: 18
                      })
                  ]
                })
              ],
              verticalAlign: VerticalAlign.TOP
          })
          ]
        }),//puede haber error
        new TableRow({
          children: [
            new TableCell({
              children: [
                new Paragraph({
                  alignment: AlignmentType.CENTER,
                  children: [
                    new TextRun({
                      text: '4',
                      font: 'Arial',
                      size: 18
                    })
                  ]
                })
              ],
              verticalAlign: VerticalAlign.TOP
            }),
            new TableCell({
              children: [
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                    new TextRun({
                      text: 'He incluido una lista completa de riesgos para la integridad orgánica de los productos en el formato MTO-FSOL-04',
                      font: 'Arial',
                      size: 18
                    })
                  ]
                })
              ],
              verticalAlign: VerticalAlign.TOP
            }),
            new TableCell({
              children: [
                new Paragraph({
                  alignment: AlignmentType.CENTER,
                  children: [
                    new TextRun({
                      text: this.getValue(submission, 'plan_organico_fungi_registros_4_declaracion') === 'Si' ? '■ SÍ  ❏ NO' : '❏ SÍ  ■ NO',
                      font: 'Arial',
                      size: 18
                    })
                  ]
                })
              ],
              verticalAlign: VerticalAlign.TOP
            }),
            new TableCell({
              children: [
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                    new TextRun({
                      text: this.getValue(submission, 'plan_organico_fungi_registros_4_comentarios'),
                      font: 'Arial',
                      size: 18
                    })
                  ]
                })
              ],
              verticalAlign: VerticalAlign.TOP
            }),
            new TableCell({
              children: [
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                      new TextRun({
                          text: ` ${this.getValue(submission, 'plan_organico_fungi_registros_implementacion_4') === 'I' ? '■' : '❏'} I`,
                          font: 'Arial',
                          size: 18
                      })
                  ]
                }),
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                      new TextRun({
                          text: ` ${this.getValue(submission, 'plan_organico_fungi_registros_implementacion_4') === 'NI' ? '■' : '❏'} NI`,
                          font: 'Arial',
                          size: 18
                      })
                  ]
                })
              ],
              verticalAlign: VerticalAlign.TOP
          }),
          new TableCell({
              children: [
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                      new TextRun({
                          text: ` ${this.getValue(submission, 'plan_organico_fungi_registros_conformidad_4') === 'C' ? '■' : '❏'} C`,
                          font: 'Arial',
                          size: 18
                      })
                  ]
                }),
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                      new TextRun({
                          text: ` ${this.getValue(submission, 'plan_organico_fungi_registros_conformidad_4') === 'NC' ? '■' : '❏'} NC`,
                          font: 'Arial',
                          size: 18
                      })
                  ]
                }),
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                      new TextRun({
                          text: ` ${this.getValue(submission, 'plan_organico_fungi_registros_conformidad_4') === 'NA' ? '■' : '❏'} NA`,
                          font: 'Arial',
                          size: 18
                      })
                  ]
                })
              ],
              verticalAlign: VerticalAlign.TOP
          }),
          new TableCell({
              children: [
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                      new TextRun({
                          text: this.getValue(submission, 'plan_organico_fungi_registros_comentarios_inspector_4'),
                          font: 'Arial',
                          size: 18
                      })
                  ]
                })
              ],
              verticalAlign: VerticalAlign.TOP
          })
          ]
        }),
        new TableRow({
          children: [
            new TableCell({
              children: [
                new Paragraph({
                  alignment: AlignmentType.CENTER,
                  children: [
                    new TextRun({
                      text: '5',
                      font: 'Arial',
                      size: 18
                    })
                  ]
                })
              ],
              verticalAlign: VerticalAlign.TOP
            }),
            new TableCell({
              children: [
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                    new TextRun({
                      text: 'He incluido un análisis completo de riesgos de contaminación por colindantes en el formato MTO-FSOL-06',
                      font: 'Arial',
                      size: 18
                    })
                  ]
                })
              ],
              verticalAlign: VerticalAlign.TOP
            }),
            new TableCell({
              children: [
                new Paragraph({
                  alignment: AlignmentType.CENTER,
                  children: [
                    new TextRun({
                      text: this.getValue(submission, 'plan_organico_fungi_registros_5_declaracion') === 'Si' ? '■ SÍ  ❏ NO' : '❏ SÍ  ■ NO',
                      font: 'Arial',
                      size: 18
                    })
                  ]
                })
              ],
              verticalAlign: VerticalAlign.TOP
            }),
            new TableCell({
              children: [
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                    new TextRun({
                      text: this.getValue(submission, 'plan_organico_fungi_registros_5_comentarios'),
                      font: 'Arial',
                      size: 18
                    })
                  ]
                })
              ],
              verticalAlign: VerticalAlign.TOP
            }),
            new TableCell({
              children: [
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                      new TextRun({
                          text: ` ${this.getValue(submission, 'plan_organico_fungi_registros_implementacion_5') === 'I' ? '■' : '❏'} I`,
                          font: 'Arial',
                          size: 18
                      })
                  ]
                }),
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                      new TextRun({
                          text: ` ${this.getValue(submission, 'plan_organico_fungi_registros_implementacion_5') === 'NI' ? '■' : '❏'} NI`,
                          font: 'Arial',
                          size: 18
                      })
                  ]
                })
              ],
              verticalAlign: VerticalAlign.TOP
          }),
          new TableCell({
              children: [
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                      new TextRun({
                          text: ` ${this.getValue(submission, 'plan_organico_fungi_registros_conformidad_5') === 'C' ? '■' : '❏'} C`,
                          font: 'Arial',
                          size: 18
                      })
                  ]
                }),
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                      new TextRun({
                          text: ` ${this.getValue(submission, 'plan_organico_fungi_registros_conformidad_5') === 'NC' ? '■' : '❏'} NC`,
                          font: 'Arial',
                          size: 18
                      })
                  ]
                }),
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                      new TextRun({
                          text: ` ${this.getValue(submission, 'plan_organico_fungi_registros_conformidad_5') === 'NA' ? '■' : '❏'} NA`,
                          font: 'Arial',
                          size: 18
                      })
                  ]
                })
              ],
              verticalAlign: VerticalAlign.TOP
          }),
          new TableCell({
              children: [
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                      new TextRun({
                          text: this.getValue(submission, 'plan_organico_fungi_registros_comentarios_inspector_5'),
                          font: 'Arial',
                          size: 18
                      })
                  ]
                })
              ],
              verticalAlign: VerticalAlign.TOP
          })
          ]
        }),
        new TableRow({
          children: [
            new TableCell({
              children: [
                new Paragraph({
                  alignment: AlignmentType.CENTER,
                  children: [
                    new TextRun({
                      text: '6',
                      font: 'Arial',
                      size: 18
                    })
                  ]
                })
              ],
              verticalAlign: VerticalAlign.TOP
            }),
            new TableCell({
              children: [
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                    new TextRun({
                      text: 'He incluido una lista completa de los insumos que utilizo para la fertilización en el formato MTO-FSOL-07',
                      font: 'Arial',
                      size: 18
                    })
                  ]
                })
              ],
              verticalAlign: VerticalAlign.TOP
            }),
            new TableCell({
              children: [
                new Paragraph({
                  alignment: AlignmentType.CENTER,
                  children: [
                    new TextRun({
                      text: this.getValue(submission, 'plan_organico_fungi_registros_6_declaracion') === 'Si' ? '■ SÍ  ❏ NO' : '❏ SÍ  ■ NO',
                      font: 'Arial',
                      size: 18
                    })
                  ]
                })
              ],
              verticalAlign: VerticalAlign.TOP
            }),
            new TableCell({
              children: [
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                    new TextRun({
                      text: this.getValue(submission, 'plan_organico_fungi_registros_6_comentarios'),
                      font: 'Arial',
                      size: 18
                    })
                  ]
                })
              ],
              verticalAlign: VerticalAlign.TOP
            }),
            new TableCell({
              children: [
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                      new TextRun({
                          text: ` ${this.getValue(submission, 'plan_organico_fungi_registros_implementacion_6') === 'I' ? '■' : '❏'} I`,
                          font: 'Arial',
                          size: 18
                      })
                  ]
                }),
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                      new TextRun({
                          text: ` ${this.getValue(submission, 'plan_organico_fungi_registros_implementacion_6') === 'NI' ? '■' : '❏'} NI`,
                          font: 'Arial',
                          size: 18
                      })
                  ]
                })
              ],
              verticalAlign: VerticalAlign.TOP
          }),
          new TableCell({
              children: [
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                      new TextRun({
                          text: ` ${this.getValue(submission, 'plan_organico_fungi_registros_conformidad_6') === 'C' ? '■' : '❏'} C`,
                          font: 'Arial',
                          size: 18
                      })
                  ]
                }),
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                      new TextRun({
                          text: ` ${this.getValue(submission, 'plan_organico_fungi_registros_conformidad_6') === 'NC' ? '■' : '❏'} NC`,
                          font: 'Arial',
                          size: 18
                      })
                  ]
                }),
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                      new TextRun({
                          text: ` ${this.getValue(submission, 'plan_organico_fungi_registros_conformidad_6') === 'NA' ? '■' : '❏'} NA`,
                          font: 'Arial',
                          size: 18
                      })
                  ]
                })
              ],
              verticalAlign: VerticalAlign.TOP
          }),
          new TableCell({
              children: [
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                      new TextRun({
                          text: this.getValue(submission, 'plan_organico_fungi_registros_comentarios_inspector_6'),
                          font: 'Arial',
                          size: 18
                      })
                  ]
                })
              ],
              verticalAlign: VerticalAlign.TOP
          })
          ]
        }),
        new TableRow({
          children: [
            new TableCell({
              children: [
                new Paragraph({
                  alignment: AlignmentType.CENTER,
                  children: [
                    new TextRun({
                      text: '7',
                      font: 'Arial',
                      size: 18
                    })
                  ]
                })
              ],
              verticalAlign: VerticalAlign.TOP
            }),
            new TableCell({
              children: [
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                    new TextRun({
                      text: 'Registro de la fuente, cantidad, tipo de estiércol y evaluación del cumplimiento del criterio 5.5.1 CAN/CGSB-32.310',
                      font: 'Arial',
                      size: 18
                    })
                  ]
                })
              ],
              verticalAlign: VerticalAlign.TOP
            }),
            new TableCell({
              children: [
                new Paragraph({
                  alignment: AlignmentType.CENTER,
                  children: [
                    new TextRun({
                      text: this.getValue(submission, 'plan_organico_fungi_registros_7_declaracion') === 'Si' ? '■ SÍ  ❏ NO' : '❏ SÍ  ■ NO',
                      font: 'Arial',
                      size: 18
                    })
                  ]
                })
              ],
              verticalAlign: VerticalAlign.TOP
            }),
            new TableCell({
              children: [
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                    new TextRun({
                      text: this.getValue(submission, 'plan_organico_fungi_registros_7_comentarios'),
                      font: 'Arial',
                      size: 18
                    })
                  ]
                })
              ],
              verticalAlign: VerticalAlign.TOP
            }),
            new TableCell({
              children: [
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                      new TextRun({
                          text: ` ${this.getValue(submission, 'plan_organico_fungi_registros_implementacion_7') === 'I' ? '■' : '❏'} I`,
                          font: 'Arial',
                          size: 18
                      })
                  ]
                }),
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                      new TextRun({
                          text: ` ${this.getValue(submission, 'plan_organico_fungi_registros_implementacion_7') === 'NI' ? '■' : '❏'} NI`,
                          font: 'Arial',
                          size: 18
                      })
                  ]
                })
              ],
              verticalAlign: VerticalAlign.TOP
          }),
          new TableCell({
              children: [
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                      new TextRun({
                          text: ` ${this.getValue(submission, 'plan_organico_fungi_registros_conformidad_7') === 'C' ? '■' : '❏'} C`,
                          font: 'Arial',
                          size: 18
                      })
                  ]
                }),
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                      new TextRun({
                          text: ` ${this.getValue(submission, 'plan_organico_fungi_registros_conformidad_7') === 'NC' ? '■' : '❏'} NC`,
                          font: 'Arial',
                          size: 18
                      })
                  ]
                }),
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                      new TextRun({
                          text: ` ${this.getValue(submission, 'plan_organico_fungi_registros_conformidad_7') === 'NA' ? '■' : '❏'} NA`,
                          font: 'Arial',
                          size: 18
                      })
                  ]
                })
              ],
              verticalAlign: VerticalAlign.TOP
          }),
          new TableCell({
              children: [
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                      new TextRun({
                          text: this.getValue(submission, 'plan_organico_fungi_registros_comentarios_inspector_7'),
                          font: 'Arial',
                          size: 18
                      })
                  ]
                })
              ],
              verticalAlign: VerticalAlign.TOP
          })
          ]
        }),
        new TableRow({
          children: [
            new TableCell({
              children: [
                new Paragraph({
                  alignment: AlignmentType.CENTER,
                  children: [
                    new TextRun({
                      text: '8',
                      font: 'Arial',
                      size: 18
                    })
                  ]
                })
              ],
              verticalAlign: VerticalAlign.TOP
            }),
            new TableCell({
              children: [
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                    new TextRun({
                      text: 'He incluido una lista completa de las sustancias que utilizo para el control de plagas y enfermedades en el formato MTO-FSOL-07',
                      font: 'Arial',
                      size: 18
                    })
                  ]
                })
              ],
              verticalAlign: VerticalAlign.TOP
            }),
            new TableCell({
              children: [
                new Paragraph({
                  alignment: AlignmentType.CENTER,
                  children: [
                    new TextRun({
                      text: this.getValue(submission, 'plan_organico_fungi_registros_8_declaracion') === 'Si' ? '■ SÍ  ❏ NO' : '❏ SÍ  ■ NO',
                      font: 'Arial',
                      size: 18
                    })
                  ]
                })
              ],
              verticalAlign: VerticalAlign.TOP
            }),
            new TableCell({
              children: [
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                    new TextRun({
                      text: this.getValue(submission, 'plan_organico_fungi_registros_8_comentarios'),
                      font: 'Arial',
                      size: 18
                    })
                  ]
                })
              ],
              verticalAlign: VerticalAlign.TOP
            }),
            new TableCell({
              children: [
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                      new TextRun({
                          text: ` ${this.getValue(submission, 'plan_organico_fungi_registros_implementacion_8') === 'I' ? '■' : '❏'} I`,
                          font: 'Arial',
                          size: 18
                      })
                  ]
                }),
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                      new TextRun({
                          text: ` ${this.getValue(submission, 'plan_organico_fungi_registros_implementacion_8') === 'NI' ? '■' : '❏'} NI`,
                          font: 'Arial',
                          size: 18
                      })
                  ]
                })
              ],
              verticalAlign: VerticalAlign.TOP
          }),
          new TableCell({
              children: [
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                      new TextRun({
                          text: ` ${this.getValue(submission, 'plan_organico_fungi_registros_conformidad_8') === 'C' ? '■' : '❏'} C`,
                          font: 'Arial',
                          size: 18
                      })
                  ]
                }),
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                      new TextRun({
                          text: ` ${this.getValue(submission, 'plan_organico_fungi_registros_conformidad_8') === 'NC' ? '■' : '❏'} NC`,
                          font: 'Arial',
                          size: 18
                      })
                  ]
                }),
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                      new TextRun({
                          text: ` ${this.getValue(submission, 'plan_organico_fungi_registros_conformidad_8') === 'NA' ? '■' : '❏'} NA`,
                          font: 'Arial',
                          size: 18
                      })
                  ]
                })
              ],
              verticalAlign: VerticalAlign.TOP
          }),
          new TableCell({
              children: [
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                      new TextRun({
                          text: this.getValue(submission, 'plan_organico_fungi_registros_comentarios_inspector_8'),
                          font: 'Arial',
                          size: 18
                      })
                  ]
                })
              ],
              verticalAlign: VerticalAlign.TOP
          })
          ]
        }),
        new TableRow({
          children: [
            new TableCell({
              children: [
                new Paragraph({
                  alignment: AlignmentType.CENTER,
                  children: [
                    new TextRun({
                      text: '9',
                      font: 'Arial',
                      size: 18
                    })
                  ]
                })
              ],
              verticalAlign: VerticalAlign.TOP
            }),
            new TableCell({
              children: [
                new Table({
                  rows: [
                    new TableRow({
                      children: [
                        new TableCell({
                          children: [
                            new Paragraph({
                              alignment: AlignmentType.LEFT,
                              children: [
                                new TextRun({
                                  text: 'He incluido la solicitud de la excepción para:',
                                  font: 'Arial',
                                  size: 18
                                })
                              ]
                            })
                          ],
                          borders: {
                            left: {
                              style: BorderStyle.NONE,
                              color: "white",
                              size: 0
                            },
                            top: {
                              style: BorderStyle.NONE,
                              color: "white",
                              size: 0
                            },
                            bottom: {
                              style: BorderStyle.NONE,
                              color: "white",
                              size: 0
                            }
                          }
                        }),
                        new TableCell({
                          children: [
                            new Paragraph({
                              alignment: AlignmentType.LEFT,
                              children: [
                                new TextRun({
                                  text: '',
                                  font: 'Arial',
                                  size: 18
                                })
                              ]
                            })
                          ],
                          borders: {
                            right: {
                              style: BorderStyle.NONE,
                              color: "white",
                              size: 0
                            },
                            top: {
                              style: BorderStyle.NONE,
                              color: "white",
                              size: 0
                            },
                            bottom: {
                              style: BorderStyle.NONE,
                              color: "white",
                              size: 0
                            }
                          }
                        })
                      ]
                    }),
                    new TableRow({
                      children: [
                        new TableCell({
                          children: [
                            new Paragraph({
                              alignment: AlignmentType.LEFT,
                              children: [
                                new TextRun({
                                  text: `${this.getValue(submission, 'plan_organico_fungi_registros_9_reconocimiento_retroactivo') === "Si" ? '■' : '❏'} Reconocimiento retroactivo de periodo de conversión`,
                                  font: 'Arial',
                                  size: 18
                                })
                              ]
                            })
                          ],
                          borders: {
                            left: {
                              style: BorderStyle.NONE,
                              color: "white",
                              size: 0
                            },
                            top: {
                              style: BorderStyle.NONE,
                              color: "white",
                              size: 0
                            },
                            bottom: {
                              style: BorderStyle.NONE,
                              color: "white",
                              size: 0
                            }
                          }
                        }),
                        new TableCell({
                          children: [
                            new Paragraph({
                              alignment: AlignmentType.CENTER,
                              children: [
                                new TextRun({
                                  text: `${this.getValue(submission, 'plan_organico_fungi_registros_9_reconocimiento_retroactivo') === "Si" ? '■ Sí ❏ No' : '❏ Sí ■ No'}`,
                                  font: 'Arial',
                                  size: 18
                                })
                              ]
                            })
                          ],
                          borders: {
                            right: {
                              style: BorderStyle.NONE,
                              color: "white",
                              size: 0
                            },
                            top: {
                              style: BorderStyle.NONE,
                              color: "white",
                              size: 0
                            },
                            bottom: {
                              style: BorderStyle.NONE,
                              color: "white",
                              size: 0
                            }
                          }
                        })
                      ]
                    }),
                    new TableRow({
                      children: [
                        new TableCell({
                          children: [
                            new Paragraph({
                              alignment: AlignmentType.LEFT,
                              children: [
                                new TextRun({
                                  text: `${this.getValue(submission, 'plan_organico_fungi_registros_9_uso_semillas') === "Si" ? '■' : '❏'} Uso de semillas no orgánicas`,
                                  font: 'Arial',
                                  size: 18
                                })
                              ]
                            })
                          ],
                          borders: {
                            left: {
                              style: BorderStyle.NONE,
                              color: "white",
                              size: 0
                            },
                            top: {
                              style: BorderStyle.NONE,
                              color: "white",
                              size: 0
                            },
                            bottom: {
                              style: BorderStyle.NONE,
                              color: "white",
                              size: 0
                            }
                          }
                        }),
                        new TableCell({
                          children: [
                            new Paragraph({
                              alignment: AlignmentType.CENTER,
                              children: [
                                new TextRun({
                                  text: `${this.getValue(submission, 'plan_organico_fungi_registros_9_uso_semillas') === "Si" ? '■ Sí ❏ No' : '❏ Sí ■ No'}`,
                                  font: 'Arial',
                                  size: 18
                                })
                              ]
                            })
                          ],
                          borders: {
                            right: {
                              style: BorderStyle.NONE,
                              color: "white",
                              size: 0
                            },
                            top: {
                              style: BorderStyle.NONE,
                              color: "white",
                              size: 0
                            },
                            bottom: {
                              style: BorderStyle.NONE,
                              color: "white",
                              size: 0
                            }
                          }
                        })
                      ]
                    }),
                    new TableRow({
                      children: [
                        new TableCell({
                          children: [
                            new Paragraph({
                              alignment: AlignmentType.LEFT,
                              children: [
                                new TextRun({
                                  text: `${this.getValue(submission, 'plan_organico_fungi_registros_9_produccion_paralela') === "Si" ? '■' : '❏'} Producción paralela`,
                                  font: 'Arial',
                                  size: 18
                                })
                              ]
                            })
                          ],
                          borders: {
                            left: {
                              style: BorderStyle.NONE,
                              color: "white",
                              size: 0
                            },
                            top: {
                              style: BorderStyle.NONE,
                              color: "white",
                              size: 0
                            },
                            bottom: {
                              style: BorderStyle.NONE,
                              color: "white",
                              size: 0
                            }
                          }
                        }),
                        new TableCell({
                          children: [
                            new Paragraph({
                              alignment: AlignmentType.CENTER,
                              children: [
                                new TextRun({
                                  text: `${this.getValue(submission, 'plan_organico_fungi_registros_9_produccion_paralela') === "Si" ? '■ Sí ❏ No' : '❏ Sí ■ No'}`,
                                  font: 'Arial',
                                  size: 18
                                })
                              ]
                            })
                          ],
                          borders: {
                            right: {
                              style: BorderStyle.NONE,
                              color: "white",
                              size: 0
                            },
                            top: {
                              style: BorderStyle.NONE,
                              color: "white",
                              size: 0
                            },
                            bottom: {
                              style: BorderStyle.NONE,
                              color: "white",
                              size: 0
                            }
                          }
                        })
                      ]
                    }),
                    new TableRow({
                      children: [
                        new TableCell({
                          children: [
                            new Paragraph({
                              alignment: AlignmentType.LEFT,
                              children: [
                                new TextRun({
                                  text: `${this.getValue(submission, 'plan_organico_fungi_registros_9_otro_declaracion') === "Si" ? '■' : '❏'} Otro ¿Cuál?: ${this.getValue(submission, 'plan_organico_fungi_registros_9_otro')}`,
                                  font: 'Arial',
                                  size: 18
                                })
                              ]
                            })
                          ],
                          borders: {
                            left: {
                              style: BorderStyle.NONE,
                              color: "white",
                              size: 0
                            },
                            top: {
                              style: BorderStyle.NONE,
                              color: "white",
                              size: 0
                            },
                            bottom: {
                              style: BorderStyle.NONE,
                              color: "white",
                              size: 0
                            }
                          }
                        }),
                        new TableCell({
                          children: [
                            new Paragraph({
                              alignment: AlignmentType.CENTER,
                              children: [
                                new TextRun({
                                  text: `${this.getValue(submission, 'plan_organico_fungi_registros_9_otro_declaracion') === "Si" ? '■ Sí ❏ No' : '❏ Sí ■ No'}`,
                                  font: 'Arial',
                                  size: 18
                                })
                              ]
                            })
                          ],
                          borders: {
                            right: {
                              style: BorderStyle.NONE,
                              color: "white",
                              size: 0
                            },
                            top: {
                              style: BorderStyle.NONE,
                              color: "white",
                              size: 0
                            },
                            bottom: {
                              style: BorderStyle.NONE,
                              color: "white",
                              size: 0
                            }
                          }
                        })
                      ]
                    })
                  ],
                  //4395
                  columnWidths: [3075, 1320]
                })
              ],
              columnSpan: 2
            }),
            new TableCell({
              children: [
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                    new TextRun({
                      text: this.getValue(submission, 'plan_organico_fungi_registros_9_comentarios'),
                      font: 'Arial',
                      size: 18
                    })
                  ]
                })
              ],
              verticalAlign: VerticalAlign.TOP
            }),
            new TableCell({
              children: [
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                      new TextRun({
                          text: ` ${this.getValue(submission, 'plan_organico_fungi_registros_implementacion_9') === 'I' ? '■' : '❏'} I`,
                          font: 'Arial',
                          size: 18
                      })
                  ]
                }),
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                      new TextRun({
                          text: ` ${this.getValue(submission, 'plan_organico_fungi_registros_implementacion_9') === 'NI' ? '■' : '❏'} NI`,
                          font: 'Arial',
                          size: 18
                      })
                  ]
                })
              ],
              verticalAlign: VerticalAlign.TOP
          }),
          new TableCell({
              children: [
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                      new TextRun({
                          text: ` ${this.getValue(submission, 'plan_organico_fungi_registros_conformidad_9') === 'C' ? '■' : '❏'} C`,
                          font: 'Arial',
                          size: 18
                      })
                  ]
                }),
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                      new TextRun({
                          text: ` ${this.getValue(submission, 'plan_organico_fungi_registros_conformidad_9') === 'NC' ? '■' : '❏'} NC`,
                          font: 'Arial',
                          size: 18
                      })
                  ]
                }),
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                      new TextRun({
                          text: ` ${this.getValue(submission, 'plan_organico_fungi_registros_conformidad_9') === 'NA' ? '■' : '❏'} NA`,
                          font: 'Arial',
                          size: 18
                      })
                  ]
                })
              ],
              verticalAlign: VerticalAlign.TOP
          }),
          new TableCell({
              children: [
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                      new TextRun({
                          text: this.getValue(submission, 'plan_organico_fungi_registros_comentarios_inspector_9'),
                          font: 'Arial',
                          size: 18
                      })
                  ]
                })
              ],
              verticalAlign: VerticalAlign.TOP
          })
          ]
        }),

        new TableRow({
          children: [
            new TableCell({
              children: [
                new Paragraph({
                  alignment: AlignmentType.CENTER,
                  children: [
                    new TextRun({
                      text: '10',
                      font: 'Arial',
                      size: 18
                    })
                  ]
                })
              ],
              verticalAlign: VerticalAlign.TOP
            }),
            new TableCell({
              children: [
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                    new TextRun({
                      text: 'He incluido una copia de los análisis del agua que utilizo para la producción, conforme a los requisitos de la NOM-127-SSA1.',
                      font: 'Arial',
                      size: 18
                    })
                  ]
                })
              ],
              verticalAlign: VerticalAlign.TOP
            }),
            new TableCell({
              children: [
                new Paragraph({
                  alignment: AlignmentType.CENTER,
                  children: [
                    new TextRun({
                      text: this.getValue(submission, 'plan_organico_fungi_registros_10_copia_analisis') === 'Si' ? '■ SÍ  ❏ NO' : '❏ SÍ  ■ NO',
                      font: 'Arial',
                      size: 18
                    })
                  ]
                })
              ],
              verticalAlign: VerticalAlign.TOP
            }),
            new TableCell({
              children: [
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                    new TextRun({
                      text: this.getValue(submission, 'plan_organico_fungi_registros_10_comentarios'),
                      font: 'Arial',
                      size: 18
                    })
                  ]
                })
              ],
              verticalAlign: VerticalAlign.TOP
            }),
            new TableCell({
              children: [
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                      new TextRun({
                          text: ` ${this.getValue(submission, 'plan_organico_fungi_registros_implementacion_10') === 'I' ? '■' : '❏'} I`,
                          font: 'Arial',
                          size: 18
                      })
                  ]
                }),
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                      new TextRun({
                          text: ` ${this.getValue(submission, 'plan_organico_fungi_registros_implementacion_10') === 'NI' ? '■' : '❏'} NI`,
                          font: 'Arial',
                          size: 18
                      })
                  ]
                })
              ],
              verticalAlign: VerticalAlign.TOP
          }),
          new TableCell({
              children: [
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                      new TextRun({
                          text: ` ${this.getValue(submission, 'plan_organico_fungi_registros_conformidad_10') === 'C' ? '■' : '❏'} C`,
                          font: 'Arial',
                          size: 18
                      })
                  ]
                }),
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                      new TextRun({
                          text: ` ${this.getValue(submission, 'plan_organico_fungi_registros_conformidad_10') === 'NC' ? '■' : '❏'} NC`,
                          font: 'Arial',
                          size: 18
                      })
                  ]
                }),
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                      new TextRun({
                          text: ` ${this.getValue(submission, 'plan_organico_fungi_registros_conformidad_10') === 'NA' ? '■' : '❏'} NA`,
                          font: 'Arial',
                          size: 18
                      })
                  ]
                })
              ],
              verticalAlign: VerticalAlign.TOP
          }),
          new TableCell({
              children: [
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                      new TextRun({
                          text: this.getValue(submission, 'plan_organico_fungi_registros_comentarios_inspector_10'),
                          font: 'Arial',
                          size: 18
                      })
                  ]
                })
              ],
              verticalAlign: VerticalAlign.TOP
          })
          ]
        }),
      ],
      margins: {
        left: 70,
        right: 70
      },
      //9885
      columnWidths: [450, 3050, 1345, 1545, 1150, 800, 1545]
    })
    return table
  }

  public createHandling_MTO_FSOL_08_1_5(submission): Table {
    const table = new Table({
      rows: [
        new TableRow({
          children: [
            new TableCell({
              children: [
                new Paragraph({
                  alignment: AlignmentType.CENTER,
                  children: [
                    new TextRun({
                      text: 'MANEJO',
                      font: 'Arial',
                      size: 20,
                      bold: true
                    })
                  ],
                  spacing: {
                    after: 75,
                    before: 75
                  }
                }),
              ],
              verticalAlign: VerticalAlign.CENTER,
              columnSpan: 6,
              shading: {
                fill: '#C0C0C0'
              }
            })
          ]
        }),
        new TableRow({
          children: [
            new TableCell({
              children: [
                new Paragraph({
                  alignment: AlignmentType.CENTER,
                  children: [
                    new TextRun({
                      text: 'No.',
                      font: 'Arial',
                      size: 18,
                      bold: true
                    })
                  ]
                })
              ],
              rowSpan: 2,
              columnSpan: 1,
              verticalAlign: VerticalAlign.CENTER,
              width: {
                size: 25,
                type: WidthType.PERCENTAGE
              }
            }),
            new TableCell({
              children: [
                new Paragraph({
                  alignment: AlignmentType.CENTER,
                  children: [
                    new TextRun({
                      text: 'Requisitos',
                      font: 'Arial',
                      size: 18,
                      bold: true
                    })
                  ]
                })
              ],
              rowSpan: 2,
              columnSpan: 1,
              verticalAlign: VerticalAlign.CENTER,
              width: {
                size: 15,
                type: WidthType.PERCENTAGE
              }
            }),
            new TableCell({
              children: [
                new Paragraph({
                  alignment: AlignmentType.CENTER,
                  children: [
                    new TextRun({
                      text: 'Comentarios',
                      font: 'Arial',
                      size: 18,
                      bold: true
                    })
                  ]
                })
              ],
              rowSpan: 2,
              columnSpan: 1,
              verticalAlign: VerticalAlign.CENTER,
              width: {
                size: 15,
                type: WidthType.PERCENTAGE
              }
            }),
            new TableCell({
              children: [
                  new Paragraph({
                      alignment: AlignmentType.CENTER,
                      children: [
                          new TextRun({
                              text: 'Verificación en sitio (Evaluada por Inspector)',
                              font: 'Arial',
                              size: 18,
                              bold: true
                          })
                      ]
                  })
              ],
              columnSpan: 3,
              verticalAlign: VerticalAlign.CENTER,
              width: {
                  size: 15,
                  type: WidthType.PERCENTAGE
              }
          })
          ]
        }),
        new TableRow({
          children: [new TableCell({
              children: [
                  new Paragraph({
                      alignment: AlignmentType.CENTER,
                      children: [
                          new TextRun({
                              text: 'Implementación',
                              font: 'Arial',
                              size: 18,
                              bold: true
                          })
                      ]
                  })
              ],
              verticalAlign: VerticalAlign.CENTER
          }),
          new TableCell({
              children: [
                  new Paragraph({
                      alignment: AlignmentType.CENTER,
                      children: [
                          new TextRun({
                              text: 'Confirmación',
                              font: 'Arial',
                              size: 18,
                              bold: true
                          })
                      ]
                  })
              ],
              verticalAlign: VerticalAlign.CENTER,
              columnSpan: 2
          }),]
        }),
        new TableRow({
          children: [
            new TableCell({
              children: [
                new Paragraph({
                  alignment: AlignmentType.CENTER,
                  children: [
                    new TextRun({
                      text: '1',
                      font: 'Arial',
                      size: 18
                    })
                  ]
                })
              ],
              verticalAlign: VerticalAlign.TOP
            }),
            new TableCell({
              children: [
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                    new TextRun({
                      text: 'El medio de reproducción utilizado es',
                      font: 'Arial',
                      size: 18
                    })
                  ]
                }),
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                    new TextRun({
                      text: `${this.getValue(submission, 'plan_organico_fungi_manejo_1_troncos') ? '■' : '❏'} Troncos`,
                      font: 'Arial',
                      size: 18
                    })
                  ]
                }),
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                    new TextRun({
                      text: `${this.getValue(submission, 'plan_organico_fungi_manejo_1_suelo') ? '■' : '❏'} Suelo`,
                      font: 'Arial',
                      size: 18
                    })
                  ]
                }),
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                    new TextRun({
                      text: `${this.getValue(submission, 'plan_organico_fungi_manejo_1_sustrato') ? '■' : '❏'} Sustrato`,
                      font: 'Arial',
                      size: 18
                    })
                  ]
                }),
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                    new TextRun({
                      text: `${this.getValue(submission, 'plan_organico_fungi_manejo_1_otro') ? '■' : '❏'} Otro ¿Cuál?: ${this.getValue(submission, 'plan_organico_fungi_manejo_1_otro_text')}`,
                      font: 'Arial',
                      size: 18
                    })
                  ]
                })
              ],
              verticalAlign: VerticalAlign.TOP
            }),
            new TableCell({
              children: [
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                    new TextRun({
                      text: this.getValue(submission, 'plan_organico_fungi_manejo_1_comentarios'),
                      font: 'Arial',
                      size: 18
                    })
                  ]
                })
              ],
              verticalAlign: VerticalAlign.TOP
            }),
            new TableCell({
              children: [
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                      new TextRun({
                          text: ` ${this.getValue(submission, 'plan_organico_fungi_manejo_implementacion_1') === 'I' ? '■' : '❏'} I`,
                          font: 'Arial',
                          size: 18
                      })
                  ]
                }),
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                      new TextRun({
                          text: ` ${this.getValue(submission, 'plan_organico_fungi_manejo_implementacion_1') === 'NI' ? '■' : '❏'} NI`,
                          font: 'Arial',
                          size: 18
                      })
                  ]
                })
              ],
              verticalAlign: VerticalAlign.TOP
            }),
            new TableCell({
                children: [
                  new Paragraph({
                    alignment: AlignmentType.LEFT,
                    children: [
                        new TextRun({
                            text: ` ${this.getValue(submission, 'plan_organico_fungi_manejo_conformidad_1') === 'C' ? '■' : '❏'} C`,
                            font: 'Arial',
                            size: 18
                        })
                    ]
                  }),
                  new Paragraph({
                    alignment: AlignmentType.LEFT,
                    children: [
                        new TextRun({
                            text: ` ${this.getValue(submission, 'plan_organico_fungi_manejo_conformidad_1') === 'NC' ? '■' : '❏'} NC`,
                            font: 'Arial',
                            size: 18
                        })
                    ]
                  }),
                  new Paragraph({
                    alignment: AlignmentType.LEFT,
                    children: [
                        new TextRun({
                            text: ` ${this.getValue(submission, 'plan_organico_fungi_manejo_conformidad_1') === 'NA' ? '■' : '❏'} NA`,
                            font: 'Arial',
                            size: 18
                        })
                    ]
                  })
                ],
                verticalAlign: VerticalAlign.TOP
            }),
            new TableCell({
                children: [
                  new Paragraph({
                    alignment: AlignmentType.LEFT,
                    children: [
                        new TextRun({
                            text: this.getValue(submission, 'plan_organico_fungi_manejo_comentarios_inspector_1'),
                            font: 'Arial',
                            size: 18
                        })
                    ]
                  })
                ],
                verticalAlign: VerticalAlign.TOP
            })
          ]
        }),
        new TableRow({
          children: [
            new TableCell({
              children: [
                new Paragraph({
                  alignment: AlignmentType.CENTER,
                  children: [
                    new TextRun({
                      text: '2',
                      font: 'Arial',
                      size: 18
                    })
                  ]
                })
              ],
              verticalAlign: VerticalAlign.TOP
            }),
            new TableCell({
              children: [
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                    new TextRun({
                      text: 'La calidad del medio de reproducción es:',
                      font: 'Arial',
                      size: 18
                    })
                  ]
                }),
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                    new TextRun({
                      text: `${this.getValue(submission, 'plan_organico_fungi_manejo_2_organica') ? '■' : '❏'} Orgánica`,
                      font: 'Arial',
                      size: 18
                    })
                  ]
                }),
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                    new TextRun({
                      text: `${this.getValue(submission, 'plan_organico_fungi_manejo_2_natural') ? '■' : '❏'} Natural (no tratado)`,
                      font: 'Arial',
                      size: 18
                    })
                  ]
                }),
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                    new TextRun({
                      text: `${this.getValue(submission, 'plan_organico_fungi_manejo_2_convencional') ? '■' : '❏'} Convencional`,
                      font: 'Arial',
                      size: 18
                    })
                  ]
                }),
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                    new TextRun({
                      text: `${this.getValue(submission, 'plan_organico_fungi_manejo_2_otro') ? '■' : '❏'} Otro ¿Cuál?: ${this.getValue(submission, 'plan_organico_fungi_manejo_2_otro_text')}`,
                      font: 'Arial',
                      size: 18
                    })
                  ]
                })
              ],
              verticalAlign: VerticalAlign.TOP
            }),
            new TableCell({
              children: [
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                    new TextRun({
                      text: this.getValue(submission, 'plan_organico_fungi_manejo_2_comentarios'),
                      font: 'Arial',
                      size: 18
                    })
                  ]
                })
              ],
              verticalAlign: VerticalAlign.TOP
            }),
            new TableCell({
              children: [
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                      new TextRun({
                          text: ` ${this.getValue(submission, 'plan_organico_fungi_manejo_implementacion_2') === 'I' ? '■' : '❏'} I`,
                          font: 'Arial',
                          size: 18
                      })
                  ]
                }),
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                      new TextRun({
                          text: ` ${this.getValue(submission, 'plan_organico_fungi_manejo_implementacion_2') === 'NI' ? '■' : '❏'} NI`,
                          font: 'Arial',
                          size: 18
                      })
                  ]
                })
              ],
              verticalAlign: VerticalAlign.TOP
            }),
            new TableCell({
                children: [
                  new Paragraph({
                    alignment: AlignmentType.LEFT,
                    children: [
                        new TextRun({
                            text: ` ${this.getValue(submission, 'plan_organico_fungi_manejo_conformidad_2') === 'C' ? '■' : '❏'} C`,
                            font: 'Arial',
                            size: 18
                        })
                    ]
                  }),
                  new Paragraph({
                    alignment: AlignmentType.LEFT,
                    children: [
                        new TextRun({
                            text: ` ${this.getValue(submission, 'plan_organico_fungi_manejo_conformidad_2') === 'NC' ? '■' : '❏'} NC`,
                            font: 'Arial',
                            size: 18
                        })
                    ]
                  }),
                  new Paragraph({
                    alignment: AlignmentType.LEFT,
                    children: [
                        new TextRun({
                            text: ` ${this.getValue(submission, 'plan_organico_fungi_manejo_conformidad_2') === 'NA' ? '■' : '❏'} NA`,
                            font: 'Arial',
                            size: 18
                        })
                    ]
                  })
                ],
                verticalAlign: VerticalAlign.TOP
            }),
            new TableCell({
                children: [
                  new Paragraph({
                    alignment: AlignmentType.LEFT,
                    children: [
                        new TextRun({
                            text: this.getValue(submission, 'plan_organico_fungi_manejo_comentarios_inspector_2'),
                            font: 'Arial',
                            size: 18
                        })
                    ]
                  })
                ],
                verticalAlign: VerticalAlign.TOP
            })
          ]
        }),
        new TableRow({
          children: [
            new TableCell({
              children: [
                new Paragraph({
                  alignment: AlignmentType.CENTER,
                  children: [
                    new TextRun({
                      text: '3',
                      font: 'Arial',
                      size: 18
                    })
                  ]
                })
              ],
              verticalAlign: VerticalAlign.TOP
            }),
            new TableCell({
              children: [
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                    new TextRun({
                      text: 'En caso de reproducción el suelo, se tiene evidencia de que este no ha recibido aplicación de sustancias permitidas en al menos 36 meses:',
                      font: 'Arial',
                      size: 18
                    })
                  ]
                }),
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                    new TextRun({
                      text: `${this.getValue(submission, 'plan_organico_fungi_manejo_3_si') ? '■' : '❏'} Si`,
                      font: 'Arial',
                      size: 18
                    })
                  ]
                }),
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                    new TextRun({
                      text: `${this.getValue(submission, 'plan_organico_fungi_manejo_3_no') ? '■' : '❏'} No`,
                      font: 'Arial',
                      size: 18
                    })
                  ]
                })
              ],
              verticalAlign: VerticalAlign.TOP
            }),
            new TableCell({
              children: [
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                    new TextRun({
                      text: this.getValue(submission, 'plan_organico_fungi_manejo_3_comentarios'),
                      font: 'Arial',
                      size: 18
                    })
                  ]
                })
              ],
              verticalAlign: VerticalAlign.TOP
            }),
            new TableCell({
              children: [
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                      new TextRun({
                          text: ` ${this.getValue(submission, 'plan_organico_fungi_manejo_implementacion_3') === 'I' ? '■' : '❏'} I`,
                          font: 'Arial',
                          size: 18
                      })
                  ]
                }),
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                      new TextRun({
                          text: ` ${this.getValue(submission, 'plan_organico_fungi_manejo_implementacion_3') === 'NI' ? '■' : '❏'} NI`,
                          font: 'Arial',
                          size: 18
                      })
                  ]
                })
              ],
              verticalAlign: VerticalAlign.TOP
            }),
            new TableCell({
                children: [
                  new Paragraph({
                    alignment: AlignmentType.LEFT,
                    children: [
                        new TextRun({
                            text: ` ${this.getValue(submission, 'plan_organico_fungi_manejo_conformidad_3') === 'C' ? '■' : '❏'} C`,
                            font: 'Arial',
                            size: 18
                        })
                    ]
                  }),
                  new Paragraph({
                    alignment: AlignmentType.LEFT,
                    children: [
                        new TextRun({
                            text: ` ${this.getValue(submission, 'plan_organico_fungi_manejo_conformidad_3') === 'NC' ? '■' : '❏'} NC`,
                            font: 'Arial',
                            size: 18
                        })
                    ]
                  }),
                  new Paragraph({
                    alignment: AlignmentType.LEFT,
                    children: [
                        new TextRun({
                            text: ` ${this.getValue(submission, 'plan_organico_fungi_manejo_conformidad_3') === 'NA' ? '■' : '❏'} NA`,
                            font: 'Arial',
                            size: 18
                        })
                    ]
                  })
                ],
                verticalAlign: VerticalAlign.TOP
            }),
            new TableCell({
                children: [
                  new Paragraph({
                    alignment: AlignmentType.LEFT,
                    children: [
                        new TextRun({
                            text: this.getValue(submission, 'plan_organico_fungi_manejo_comentarios_inspector_3'),
                            font: 'Arial',
                            size: 18
                        })
                    ]
                  })
                ],
                verticalAlign: VerticalAlign.TOP
            })
          ]
        }),
        new TableRow({
          children: [
            new TableCell({
              children: [
                new Paragraph({
                  alignment: AlignmentType.CENTER,
                  children: [
                    new TextRun({
                      text: '4',
                      font: 'Arial',
                      size: 18
                    })
                  ]
                })
              ],
              verticalAlign: VerticalAlign.TOP
            }),
            new TableCell({
              children: [
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                    new TextRun({
                      text: 'En caso de uso de troncos, se usan como cubiertas para prevenir la perdida de humedad:',
                      font: 'Arial',
                      size: 18
                    })
                  ]
                }),
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                    new TextRun({
                      text: `${this.getValue(submission, 'plan_organico_fungi_manejo_4_cera_abeja') ? '■' : '❏'} Cera de abeja`,
                      font: 'Arial',
                      size: 18
                    })
                  ]
                }),
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                    new TextRun({
                      text: `${this.getValue(submission, 'plan_organico_fungi_manejo_4_parafina') ? '■' : '❏'} Parafina grado alimenticio`,
                      font: 'Arial',
                      size: 18
                    })
                  ]
                }),
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                    new TextRun({
                      text: `${this.getValue(submission, 'plan_organico_fungi_manejo_4_cera_queso') ? '■' : '❏'} Cera para queso`,
                      font: 'Arial',
                      size: 18
                    })
                  ]
                }),
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                    new TextRun({
                      text: `${this.getValue(submission, 'plan_organico_fungi_manejo_4_aceite') ? '■' : '❏'} Aceite mineral`,
                      font: 'Arial',
                      size: 18
                    })
                  ]
                }),
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                    new TextRun({
                      text: `${this.getValue(submission, 'plan_organico_fungi_manejo_4_otro') ? '■' : '❏'} Otro ¿Cuál?: ${this.getValue(submission, 'plan_organico_fungi_manejo_4_otro_text')}`,
                      font: 'Arial',
                      size: 18
                    })
                  ]
                })
              ],
              verticalAlign: VerticalAlign.TOP
            }),
            new TableCell({
              children: [
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                    new TextRun({
                      text: this.getValue(submission, 'plan_organico_fungi_manejo_4_comentarios'),
                      font: 'Arial',
                      size: 18
                    })
                  ]
                })
              ],
              verticalAlign: VerticalAlign.TOP
            }),
            new TableCell({
              children: [
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                      new TextRun({
                          text: ` ${this.getValue(submission, 'plan_organico_fungi_manejo_implementacion_4') === 'I' ? '■' : '❏'} I`,
                          font: 'Arial',
                          size: 18
                      })
                  ]
                }),
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                      new TextRun({
                          text: ` ${this.getValue(submission, 'plan_organico_fungi_manejo_implementacion_4') === 'NI' ? '■' : '❏'} NI`,
                          font: 'Arial',
                          size: 18
                      })
                  ]
                })
              ],
              verticalAlign: VerticalAlign.TOP
            }),
            new TableCell({
                children: [
                  new Paragraph({
                    alignment: AlignmentType.LEFT,
                    children: [
                        new TextRun({
                            text: ` ${this.getValue(submission, 'plan_organico_fungi_manejo_conformidad_4') === 'C' ? '■' : '❏'} C`,
                            font: 'Arial',
                            size: 18
                        })
                    ]
                  }),
                  new Paragraph({
                    alignment: AlignmentType.LEFT,
                    children: [
                        new TextRun({
                            text: ` ${this.getValue(submission, 'plan_organico_fungi_manejo_conformidad_4') === 'NC' ? '■' : '❏'} NC`,
                            font: 'Arial',
                            size: 18
                        })
                    ]
                  }),
                  new Paragraph({
                    alignment: AlignmentType.LEFT,
                    children: [
                        new TextRun({
                            text: ` ${this.getValue(submission, 'plan_organico_fungi_manejo_conformidad_4') === 'NA' ? '■' : '❏'} NA`,
                            font: 'Arial',
                            size: 18
                        })
                    ]
                  })
                ],
                verticalAlign: VerticalAlign.TOP
            }),
            new TableCell({
                children: [
                  new Paragraph({
                    alignment: AlignmentType.LEFT,
                    children: [
                        new TextRun({
                            text: this.getValue(submission, 'plan_organico_fungi_manejo_comentarios_inspector_4'),
                            font: 'Arial',
                            size: 18
                        })
                    ]
                  })
                ],
                verticalAlign: VerticalAlign.TOP
            })
          ]
        }),
        new TableRow({
          children: [
            new TableCell({
              children: [
                new Paragraph({
                  alignment: AlignmentType.CENTER,
                  children: [
                    new TextRun({
                      text: '5',
                      font: 'Arial',
                      size: 18
                    })
                  ]
                })
              ],
              verticalAlign: VerticalAlign.TOP
            }),
            new TableCell({
              children: [
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                    new TextRun({
                      text: 'Si el método de reproducción es en espacios abiertos, la distancia con cultivos convencionales o fuentes de contamianción es:',
                      font: 'Arial',
                      size: 18
                    })
                  ]
                }),
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                    new TextRun({
                      text: `${this.getValue(submission, 'plan_organico_fungi_manejo_5_menor_35') ? '■' : '❏'} Menor a 35 m`,
                      font: 'Arial',
                      size: 18
                    })
                  ]
                }),
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                    new TextRun({
                      text: `${this.getValue(submission, 'plan_organico_fungi_manejo_5_35_mayor') ? '■' : '❏'} Igual o mayor a 35 m`,
                      font: 'Arial',
                      size: 18
                    })
                  ]
                }),
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                    new TextRun({
                      text: `${this.getValue(submission, 'plan_organico_fungi_manejo_5_otro') ? '■' : '❏'} Otro ¿Cuál?: ${this.getValue(submission, 'plan_organico_fungi_manejo_5_otro_text')}`,
                      font: 'Arial',
                      size: 18
                    })
                  ]
                })
              ],
              verticalAlign: VerticalAlign.TOP
            }),
            new TableCell({
              children: [
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                    new TextRun({
                      text: this.getValue(submission, 'plan_organico_fungi_manejo_5_comentarios'),
                      font: 'Arial',
                      size: 18
                    })
                  ]
                })
              ],
              verticalAlign: VerticalAlign.TOP
            }),
            new TableCell({
              children: [
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                      new TextRun({
                          text: ` ${this.getValue(submission, 'plan_organico_fungi_manejo_implementacion_5') === 'I' ? '■' : '❏'} I`,
                          font: 'Arial',
                          size: 18
                      })
                  ]
                }),
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                      new TextRun({
                          text: ` ${this.getValue(submission, 'plan_organico_fungi_manejo_implementacion_5') === 'NI' ? '■' : '❏'} NI`,
                          font: 'Arial',
                          size: 18
                      })
                  ]
                })
              ],
              verticalAlign: VerticalAlign.TOP
            }),
            new TableCell({
                children: [
                  new Paragraph({
                    alignment: AlignmentType.LEFT,
                    children: [
                        new TextRun({
                            text: ` ${this.getValue(submission, 'plan_organico_fungi_manejo_conformidad_5') === 'C' ? '■' : '❏'} C`,
                            font: 'Arial',
                            size: 18
                        })
                    ]
                  }),
                  new Paragraph({
                    alignment: AlignmentType.LEFT,
                    children: [
                        new TextRun({
                            text: ` ${this.getValue(submission, 'plan_organico_fungi_manejo_conformidad_5') === 'NC' ? '■' : '❏'} NC`,
                            font: 'Arial',
                            size: 18
                        })
                    ]
                  }),
                  new Paragraph({
                    alignment: AlignmentType.LEFT,
                    children: [
                        new TextRun({
                            text: ` ${this.getValue(submission, 'plan_organico_fungi_manejo_conformidad_5') === 'NA' ? '■' : '❏'} NA`,
                            font: 'Arial',
                            size: 18
                        })
                    ]
                  })
                ],
                verticalAlign: VerticalAlign.TOP
            }),
            new TableCell({
                children: [
                  new Paragraph({
                    alignment: AlignmentType.LEFT,
                    children: [
                        new TextRun({
                            text: this.getValue(submission, 'plan_organico_fungi_manejo_comentarios_inspector_5'),
                            font: 'Arial',
                            size: 18
                        })
                    ]
                  })
                ],
                verticalAlign: VerticalAlign.TOP
            })
          ]
        }),
        new TableRow({
          children: [
            new TableCell({
              children: [
                new Paragraph({
                  alignment: AlignmentType.CENTER,
                  children: [
                    new TextRun({
                      text: '6',
                      font: 'Arial',
                      size: 18
                    })
                  ]
                })
              ],
              verticalAlign: VerticalAlign.TOP
            }),
            new TableCell({
              children: [
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                    new TextRun({
                      text: 'En caso de uso de estiércol como medio de reroducción, cumple los requisitos:',
                      font: 'Arial',
                      size: 18
                    })
                  ]
                }),
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                    new TextRun({
                      text: `${this.getValue(submission, 'plan_organico_fungi_manejo_6_composteado') ? '■' : '❏'} Composteado`,
                      font: 'Arial',
                      size: 18
                    })
                  ]
                }),
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                    new TextRun({
                      text: `${this.getValue(submission, 'plan_organico_fungi_manejo_6_ganaderia_extensiva') ? '■' : '❏'} Proviene de ganadería extensiva (animales no enjaulados, animales no encerrados, se tienen registros de la cantidad, origen y tipo de ganadería)`,
                      font: 'Arial',
                      size: 18
                    })
                  ]
                })
              ],
              verticalAlign: VerticalAlign.TOP
            }),
            new TableCell({
              children: [
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                    new TextRun({
                      text: this.getValue(submission, 'plan_organico_fungi_manejo_6_comentarios'),
                      font: 'Arial',
                      size: 18
                    })
                  ]
                })
              ],
              verticalAlign: VerticalAlign.TOP
            }),
            new TableCell({
              children: [
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                      new TextRun({
                          text: ` ${this.getValue(submission, 'plan_organico_fungi_manejo_implementacion_6') === 'I' ? '■' : '❏'} I`,
                          font: 'Arial',
                          size: 18
                      })
                  ]
                }),
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                      new TextRun({
                          text: ` ${this.getValue(submission, 'plan_organico_fungi_manejo_implementacion_6') === 'NI' ? '■' : '❏'} NI`,
                          font: 'Arial',
                          size: 18
                      })
                  ]
                })
              ],
              verticalAlign: VerticalAlign.TOP
            }),
            new TableCell({
                children: [
                  new Paragraph({
                    alignment: AlignmentType.LEFT,
                    children: [
                        new TextRun({
                            text: ` ${this.getValue(submission, 'plan_organico_fungi_manejo_conformidad_6') === 'C' ? '■' : '❏'} C`,
                            font: 'Arial',
                            size: 18
                        })
                    ]
                  }),
                  new Paragraph({
                    alignment: AlignmentType.LEFT,
                    children: [
                        new TextRun({
                            text: ` ${this.getValue(submission, 'plan_organico_fungi_manejo_conformidad_6') === 'NC' ? '■' : '❏'} NC`,
                            font: 'Arial',
                            size: 18
                        })
                    ]
                  }),
                  new Paragraph({
                    alignment: AlignmentType.LEFT,
                    children: [
                        new TextRun({
                            text: ` ${this.getValue(submission, 'plan_organico_fungi_manejo_conformidad_6') === 'NA' ? '■' : '❏'} NA`,
                            font: 'Arial',
                            size: 18
                        })
                    ]
                  })
                ],
                verticalAlign: VerticalAlign.TOP
            }),
            new TableCell({
                children: [
                  new Paragraph({
                    alignment: AlignmentType.LEFT,
                    children: [
                        new TextRun({
                            text: this.getValue(submission, 'plan_organico_fungi_manejo_comentarios_inspector_6'),
                            font: 'Arial',
                            size: 18
                        })
                    ]
                  })
                ],
                verticalAlign: VerticalAlign.TOP
            })
          ]
        }),
        new TableRow({
          children: [
            new TableCell({
              children: [
                new Paragraph({
                  alignment: AlignmentType.CENTER,
                  children: [
                    new TextRun({
                      text: '7',
                      font: 'Arial',
                      size: 18
                    })
                  ]
                })
              ],
              verticalAlign: VerticalAlign.TOP
            }),
            new TableCell({
              children: [
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                    new TextRun({
                      text: 'La semilla o mocelio es:',
                      font: 'Arial',
                      size: 18
                    })
                  ]
                }),
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                    new TextRun({
                      text: `${this.getValue(submission, 'plan_organico_fungi_manejo_7_organica') ? '■' : '❏'} Orgánica`,
                      font: 'Arial',
                      size: 18
                    })
                  ]
                }),
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                    new TextRun({
                      text: `${this.getValue(submission, 'plan_organico_fungi_manejo_7_natural') ? '■' : '❏'} Natural (no tratada)`,
                      font: 'Arial',
                      size: 18
                    })
                  ]
                }),
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                    new TextRun({
                      text: `${this.getValue(submission, 'plan_organico_fungi_manejo_7_otro') ? '■' : '❏'} Otro ¿Cuál?: ${this.getValue(submission, 'plan_organico_fungi_manejo_7_otro_text')}`,
                      font: 'Arial',
                      size: 18
                    })
                  ]
                })
              ],
              verticalAlign: VerticalAlign.TOP
            }),
            new TableCell({
              children: [
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                    new TextRun({
                      text: this.getValue(submission, 'plan_organico_fungi_manejo_7_comentarios'),
                      font: 'Arial',
                      size: 18
                    })
                  ]
                })
              ],
              verticalAlign: VerticalAlign.TOP
            }),
            new TableCell({
              children: [
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                      new TextRun({
                          text: ` ${this.getValue(submission, 'plan_organico_fungi_manejo_implementacion_7') === 'I' ? '■' : '❏'} I`,
                          font: 'Arial',
                          size: 18
                      })
                  ]
                }),
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                      new TextRun({
                          text: ` ${this.getValue(submission, 'plan_organico_fungi_manejo_implementacion_7') === 'NI' ? '■' : '❏'} NI`,
                          font: 'Arial',
                          size: 18
                      })
                  ]
                })
              ],
              verticalAlign: VerticalAlign.TOP
            }),
            new TableCell({
                children: [
                  new Paragraph({
                    alignment: AlignmentType.LEFT,
                    children: [
                        new TextRun({
                            text: ` ${this.getValue(submission, 'plan_organico_fungi_manejo_conformidad_7') === 'C' ? '■' : '❏'} C`,
                            font: 'Arial',
                            size: 18
                        })
                    ]
                  }),
                  new Paragraph({
                    alignment: AlignmentType.LEFT,
                    children: [
                        new TextRun({
                            text: ` ${this.getValue(submission, 'plan_organico_fungi_manejo_conformidad_7') === 'NC' ? '■' : '❏'} NC`,
                            font: 'Arial',
                            size: 18
                        })
                    ]
                  }),
                  new Paragraph({
                    alignment: AlignmentType.LEFT,
                    children: [
                        new TextRun({
                            text: ` ${this.getValue(submission, 'plan_organico_fungi_manejo_conformidad_7') === 'NA' ? '■' : '❏'} NA`,
                            font: 'Arial',
                            size: 18
                        })
                    ]
                  })
                ],
                verticalAlign: VerticalAlign.TOP
            }),
            new TableCell({
                children: [
                  new Paragraph({
                    alignment: AlignmentType.LEFT,
                    children: [
                        new TextRun({
                            text: this.getValue(submission, 'plan_organico_fungi_manejo_comentarios_inspector_7'),
                            font: 'Arial',
                            size: 18
                        })
                    ]
                  })
                ],
                verticalAlign: VerticalAlign.TOP
            })
          ]
        }),
        new TableRow({
          children: [
            new TableCell({
              children: [
                new Paragraph({
                  alignment: AlignmentType.CENTER,
                  children: [
                    new TextRun({
                      text: '8',
                      font: 'Arial',
                      size: 18
                    })
                  ]
                })
              ],
              verticalAlign: VerticalAlign.TOP
            }),
            new TableCell({
              children: [
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                    new TextRun({
                      text: 'El agua utilizada es:',
                      font: 'Arial',
                      size: 18
                    })
                  ]
                }),
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                    new TextRun({
                      text: `${this.getValue(submission, 'plan_organico_fungi_manejo_8_potable') ? '■' : '❏'} Potable`,
                      font: 'Arial',
                      size: 18
                    })
                  ]
                }),
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                    new TextRun({
                      text: `${this.getValue(submission, 'plan_organico_fungi_manejo_8_natural') ? '■' : '❏'} Natural, no tratada`,
                      font: 'Arial',
                      size: 18
                    })
                  ]
                }),
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                    new TextRun({
                      text: `${this.getValue(submission, 'plan_organico_fungi_manejo_8_analisis_disponible') ? '■' : '❏'} Análisis de calidad disponibles`,
                      font: 'Arial',
                      size: 18
                    })
                  ]
                }),
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                    new TextRun({
                      text: `${this.getValue(submission, 'plan_organico_fungi_manejo_8_otro') ? '■' : '❏'} Otro ¿Cuál?: ${this.getValue(submission, 'plan_organico_fungi_manejo_8_otro_text')}`,
                      font: 'Arial',
                      size: 18
                    })
                  ]
                })                
              ],
              verticalAlign: VerticalAlign.TOP
            }),
            new TableCell({
              children: [
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                    new TextRun({
                      text: this.getValue(submission, 'plan_organico_fungi_manejo_8_comentarios'),
                      font: 'Arial',
                      size: 18
                    })
                  ]
                })
              ],
              verticalAlign: VerticalAlign.TOP
            }),
            new TableCell({
              children: [
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                      new TextRun({
                          text: ` ${this.getValue(submission, 'plan_organico_fungi_manejo_implementacion_8') === 'I' ? '■' : '❏'} I`,
                          font: 'Arial',
                          size: 18
                      })
                  ]
                }),
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                      new TextRun({
                          text: ` ${this.getValue(submission, 'plan_organico_fungi_manejo_implementacion_8') === 'NI' ? '■' : '❏'} NI`,
                          font: 'Arial',
                          size: 18
                      })
                  ]
                })
              ],
              verticalAlign: VerticalAlign.TOP
            }),
            new TableCell({
                children: [
                  new Paragraph({
                    alignment: AlignmentType.LEFT,
                    children: [
                        new TextRun({
                            text: ` ${this.getValue(submission, 'plan_organico_fungi_manejo_conformidad_8') === 'C' ? '■' : '❏'} C`,
                            font: 'Arial',
                            size: 18
                        })
                    ]
                  }),
                  new Paragraph({
                    alignment: AlignmentType.LEFT,
                    children: [
                        new TextRun({
                            text: ` ${this.getValue(submission, 'plan_organico_fungi_manejo_conformidad_8') === 'NC' ? '■' : '❏'} NC`,
                            font: 'Arial',
                            size: 18
                        })
                    ]
                  }),
                  new Paragraph({
                    alignment: AlignmentType.LEFT,
                    children: [
                        new TextRun({
                            text: ` ${this.getValue(submission, 'plan_organico_fungi_manejo_conformidad_8') === 'NA' ? '■' : '❏'} NA`,
                            font: 'Arial',
                            size: 18
                        })
                    ]
                  })
                ],
                verticalAlign: VerticalAlign.TOP
            }),
            new TableCell({
                children: [
                  new Paragraph({
                    alignment: AlignmentType.LEFT,
                    children: [
                        new TextRun({
                            text: this.getValue(submission, 'plan_organico_fungi_manejo_comentarios_inspector_8'),
                            font: 'Arial',
                            size: 18
                        })
                    ]
                  })
                ],
                verticalAlign: VerticalAlign.TOP
            })
          ]
        }),
        new TableRow({
          children: [
            new TableCell({
              children: [
                new Paragraph({
                  alignment: AlignmentType.CENTER,
                  children: [
                    new TextRun({
                      text: '9',
                      font: 'Arial',
                      size: 18
                    })
                  ]
                })
              ],
              verticalAlign: VerticalAlign.TOP
            }),
            new TableCell({
              children: [
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                    new TextRun({
                      text: 'Para el control de plagas y enfermedades realizó las siguientes prácticas:',
                      font: 'Arial',
                      size: 18
                    })
                  ]
                }),
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                    new TextRun({
                      text: `${this.getValue(submission, 'plan_organico_fungi_manejo_9_retiran_enfermas') ? '■' : '❏'} Se retiran las piezas enfermas (50m)`,
                      font: 'Arial',
                      size: 18
                    })
                  ]
                }),
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                    new TextRun({
                      text: `${this.getValue(submission, 'plan_organico_fungi_manejo_9_trampas') ? '■' : '❏'} Trampas y barreras físicas`,
                      font: 'Arial',
                      size: 18
                    })
                  ]
                }),
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                    new TextRun({
                      text: `${this.getValue(submission, 'plan_organico_fungi_manejo_9_control_biologico') ? '■' : '❏'} Control biológico`,
                      font: 'Arial',
                      size: 18
                    })
                  ]
                }),
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                    new TextRun({
                      text: `${this.getValue(submission, 'plan_organico_fungi_manejo_9_sitios_libres') ? '■' : '❏'} Sitios libres de restos del sotobosque, árboles enfermos y árboles infectados por otras plagas`,
                      font: 'Arial',
                      size: 18
                    })
                  ]
                }),
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                    new TextRun({
                      text: `${this.getValue(submission, 'plan_organico_fungi_manejo_9_otro') ? '■' : '❏'} Otro ¿Cuál?: ${this.getValue(submission, 'plan_organico_fungi_manejo_9_otro_text')}`,
                      font: 'Arial',
                      size: 18
                    })
                  ]
                })
              ],
              verticalAlign: VerticalAlign.TOP
            }),
            new TableCell({
              children: [
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                    new TextRun({
                      text: this.getValue(submission, 'plan_organico_fungi_manejo_9_comentarios'),
                      font: 'Arial',
                      size: 18
                    })
                  ]
                })
              ],
              verticalAlign: VerticalAlign.TOP
            }),
            new TableCell({
              children: [
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                      new TextRun({
                          text: ` ${this.getValue(submission, 'plan_organico_fungi_manejo_implementacion_9') === 'I' ? '■' : '❏'} I`,
                          font: 'Arial',
                          size: 18
                      })
                  ]
                }),
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                      new TextRun({
                          text: ` ${this.getValue(submission, 'plan_organico_fungi_manejo_implementacion_9') === 'NI' ? '■' : '❏'} NI`,
                          font: 'Arial',
                          size: 18
                      })
                  ]
                })
              ],
              verticalAlign: VerticalAlign.TOP
            }),
            new TableCell({
                children: [
                  new Paragraph({
                    alignment: AlignmentType.LEFT,
                    children: [
                        new TextRun({
                            text: ` ${this.getValue(submission, 'plan_organico_fungi_manejo_conformidad_9') === 'C' ? '■' : '❏'} C`,
                            font: 'Arial',
                            size: 18
                        })
                    ]
                  }),
                  new Paragraph({
                    alignment: AlignmentType.LEFT,
                    children: [
                        new TextRun({
                            text: ` ${this.getValue(submission, 'plan_organico_fungi_manejo_conformidad_9') === 'NC' ? '■' : '❏'} NC`,
                            font: 'Arial',
                            size: 18
                        })
                    ]
                  }),
                  new Paragraph({
                    alignment: AlignmentType.LEFT,
                    children: [
                        new TextRun({
                            text: ` ${this.getValue(submission, 'plan_organico_fungi_manejo_conformidad_9') === 'NA' ? '■' : '❏'} NA`,
                            font: 'Arial',
                            size: 18
                        })
                    ]
                  })
                ],
                verticalAlign: VerticalAlign.TOP
            }),
            new TableCell({
                children: [
                  new Paragraph({
                    alignment: AlignmentType.LEFT,
                    children: [
                        new TextRun({
                            text: this.getValue(submission, 'plan_organico_fungi_manejo_comentarios_inspector_9'),
                            font: 'Arial',
                            size: 18
                        })
                    ]
                  })
                ],
                verticalAlign: VerticalAlign.TOP
            })
          ]
        }),
        new TableRow({
          children: [
            new TableCell({
              children: [
                new Paragraph({
                  alignment: AlignmentType.CENTER,
                  children: [
                    new TextRun({
                      text: '10',
                      font: 'Arial',
                      size: 18
                    })
                  ]
                })
              ],
              verticalAlign: VerticalAlign.TOP
            }),
            new TableCell({
              children: [
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                    new TextRun({
                      text: 'Para la cosecha y post-cosecha las siguientes prácticas están bajo mi control y mantienen la calidad orgánica del producto:',
                      font: 'Arial',
                      size: 18
                    })
                  ]
                }),
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                    new TextRun({
                      text: `${this.getValue(submission, 'plan_organico_fungi_manejo_10_corte') ? '■' : '❏'} Corte`,
                      font: 'Arial',
                      size: 18
                    })
                  ]
                }),
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                    new TextRun({
                      text: `${this.getValue(submission, 'plan_organico_fungi_manejo_10_seleccion') ? '■' : '❏'} Selección`,
                      font: 'Arial',
                      size: 18
                    })
                  ]
                }),
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                    new TextRun({
                      text: `${this.getValue(submission, 'plan_organico_fungi_manejo_10_lavado') ? '■' : '❏'} Lavado`,
                      font: 'Arial',
                      size: 18
                    })
                  ]
                }),
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                    new TextRun({
                      text: `${this.getValue(submission, 'plan_organico_fungi_manejo_10_refrigeracion') ? '■' : '❏'} Refrigeración`,
                      font: 'Arial',
                      size: 18
                    })
                  ]
                }),
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                    new TextRun({
                      text: `${this.getValue(submission, 'plan_organico_fungi_manejo_10_transporte') ? '■' : '❏'} Transporte`,
                      font: 'Arial',
                      size: 18
                    })
                  ]
                }),
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                    new TextRun({
                      text: `${this.getValue(submission, 'plan_organico_fungi_manejo_10_otro') ? '■' : '❏'} Otro ¿Cuál?: ${this.getValue(submission, 'plan_organico_fungi_manejo_10_otro_text')}`,
                      font: 'Arial',
                      size: 18
                    })
                  ]
                })
              ],
              verticalAlign: VerticalAlign.TOP
            }),
            new TableCell({
              children: [
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                    new TextRun({
                      text: this.getValue(submission, 'plan_organico_fungi_manejo_10_comentarios'),
                      font: 'Arial',
                      size: 18
                    })
                  ]
                })
              ],
              verticalAlign: VerticalAlign.TOP
            }),
            new TableCell({
              children: [
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                      new TextRun({
                          text: ` ${this.getValue(submission, 'plan_organico_fungi_manejo_implementacion_10') === 'I' ? '■' : '❏'} I`,
                          font: 'Arial',
                          size: 18
                      })
                  ]
                }),
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                      new TextRun({
                          text: ` ${this.getValue(submission, 'plan_organico_fungi_manejo_implementacion_10') === 'NI' ? '■' : '❏'} NI`,
                          font: 'Arial',
                          size: 18
                      })
                  ]
                })
              ],
              verticalAlign: VerticalAlign.TOP
            }),
            new TableCell({
                children: [
                  new Paragraph({
                    alignment: AlignmentType.LEFT,
                    children: [
                        new TextRun({
                            text: ` ${this.getValue(submission, 'plan_organico_fungi_manejo_conformidad_10') === 'C' ? '■' : '❏'} C`,
                            font: 'Arial',
                            size: 18
                        })
                    ]
                  }),
                  new Paragraph({
                    alignment: AlignmentType.LEFT,
                    children: [
                        new TextRun({
                            text: ` ${this.getValue(submission, 'plan_organico_fungi_manejo_conformidad_10') === 'NC' ? '■' : '❏'} NC`,
                            font: 'Arial',
                            size: 18
                        })
                    ]
                  }),
                  new Paragraph({
                    alignment: AlignmentType.LEFT,
                    children: [
                        new TextRun({
                            text: ` ${this.getValue(submission, 'plan_organico_fungi_manejo_conformidad_10') === 'NA' ? '■' : '❏'} NA`,
                            font: 'Arial',
                            size: 18
                        })
                    ]
                  })
                ],
                verticalAlign: VerticalAlign.TOP
            }),
            new TableCell({
                children: [
                  new Paragraph({
                    alignment: AlignmentType.LEFT,
                    children: [
                        new TextRun({
                            text: this.getValue(submission, 'plan_organico_fungi_manejo_comentarios_inspector_10'),
                            font: 'Arial',
                            size: 18
                        })
                    ]
                  })
                ],
                verticalAlign: VerticalAlign.TOP
            })
          ]
        }),
        new TableRow({
          children: [
            new TableCell({
              children: [
                new Paragraph({
                  alignment: AlignmentType.CENTER,
                  children: [
                    new TextRun({
                      text: '11',
                      font: 'Arial',
                      size: 18
                    })
                  ]
                })
              ],
              verticalAlign: VerticalAlign.TOP
            }),
            new TableCell({
              children: [
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                    new TextRun({
                      text: 'Los residuos de cosecha se destinan a:',
                      font: 'Arial',
                      size: 18
                    })
                  ]
                }),
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                    new TextRun({
                      text: `${this.getValue(submission, 'plan_organico_fungi_manejo_11_reintegran') ? '■' : '❏'} Se reintegran a la parcela`,
                      font: 'Arial',
                      size: 18
                    })
                  ]
                }),
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                    new TextRun({
                      text: `${this.getValue(submission, 'plan_organico_fungi_manejo_11_desechan') ? '■' : '❏'} Se desechan en instalaciones públicas`,
                      font: 'Arial',
                      size: 18
                    })
                  ]
                }),
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                    new TextRun({
                      text: `${this.getValue(submission, 'plan_organico_fungi_manejo_11_otro') ? '■' : '❏'} Otro ¿Cuál?: ${this.getValue(submission, 'plan_organico_fungi_manejo_11_otro_text')}`,
                      font: 'Arial',
                      size: 18
                    })
                  ]
                })
              ],
              verticalAlign: VerticalAlign.TOP
            }),
            new TableCell({
              children: [
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                    new TextRun({
                      text: this.getValue(submission, 'plan_organico_fungi_manejo_11_comentarios'),
                      font: 'Arial',
                      size: 18
                    })
                  ]
                })
              ],
              verticalAlign: VerticalAlign.TOP
            }),
            new TableCell({
              children: [
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                      new TextRun({
                          text: ` ${this.getValue(submission, 'plan_organico_fungi_manejo_implementacion_11') === 'I' ? '■' : '❏'} I`,
                          font: 'Arial',
                          size: 18
                      })
                  ]
                }),
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                      new TextRun({
                          text: ` ${this.getValue(submission, 'plan_organico_fungi_manejo_implementacion_11') === 'NI' ? '■' : '❏'} NI`,
                          font: 'Arial',
                          size: 18
                      })
                  ]
                })
              ],
              verticalAlign: VerticalAlign.TOP
            }),
            new TableCell({
                children: [
                  new Paragraph({
                    alignment: AlignmentType.LEFT,
                    children: [
                        new TextRun({
                            text: ` ${this.getValue(submission, 'plan_organico_fungi_manejo_conformidad_11') === 'C' ? '■' : '❏'} C`,
                            font: 'Arial',
                            size: 18
                        })
                    ]
                  }),
                  new Paragraph({
                    alignment: AlignmentType.LEFT,
                    children: [
                        new TextRun({
                            text: ` ${this.getValue(submission, 'plan_organico_fungi_manejo_conformidad_11') === 'NC' ? '■' : '❏'} NC`,
                            font: 'Arial',
                            size: 18
                        })
                    ]
                  }),
                  new Paragraph({
                    alignment: AlignmentType.LEFT,
                    children: [
                        new TextRun({
                            text: ` ${this.getValue(submission, 'plan_organico_fungi_manejo_conformidad_11') === 'NA' ? '■' : '❏'} NA`,
                            font: 'Arial',
                            size: 18
                        })
                    ]
                  })
                ],
                verticalAlign: VerticalAlign.TOP
            }),
            new TableCell({
                children: [
                  new Paragraph({
                    alignment: AlignmentType.LEFT,
                    children: [
                        new TextRun({
                            text: this.getValue(submission, 'plan_organico_fungi_manejo_comentarios_inspector_11'),
                            font: 'Arial',
                            size: 18
                        })
                    ]
                  })
                ],
                verticalAlign: VerticalAlign.TOP
            })
          ]
        })
      ],
      margins: {
        left: 70,
        right: 70
      },
      //9885
      columnWidths: [450, 3895, 2045, 1150, 800, 1545]
    })
    return table
  }

  public createTableUDP(data, datePipe): Table {
    const table = new Table({
      rows: [
        new TableRow({
          children: [
            new TableCell({
              children: [
                new Paragraph({
                  alignment: AlignmentType.RIGHT,
                  children: [
                    new TextRun({
                      text: 'Operador: ',
                      font: 'Calibri (Cuerpo)',
                      size: 22,
                      bold: true,

                    })
                  ],
                  spacing: {before: 100, after: 200}
                })],
              verticalAlign: VerticalAlign.CENTER,
            }),
            new TableCell({
              children: [new Paragraph({
                alignment: AlignmentType.CENTER,
                children: [
                  new TextRun({
                    text: data.customer.Company_Name,
                    font: 'Calibri (Cuerpo)',
                    size: 22,
                  })
                ],
              })],
              verticalAlign: VerticalAlign.CENTER,
              columnSpan: 3,
            }),
          ],
        }),
        new TableRow({
          children: [
            new TableCell({
              children: [
                new Paragraph({
                  alignment: AlignmentType.RIGHT,
                  children: [
                    new TextRun({
                      text: 'Código del operador: ',
                      font: 'Calibri (Cuerpo)',
                      size: 22,
                      bold: true,

                    })
                  ],
                  spacing: {before: 100, after: 200}
                })],
              width: {
                size: 25,
                type: WidthType.PERCENTAGE,
              },
            }),
            new TableCell({
              children: [new Paragraph({
                alignment: AlignmentType.CENTER,
                children: [
                  new TextRun({
                    text: data.customer.Id_Comercial,
                    font: 'Calibri (Cuerpo)',
                    size: 22,
                  })
                ],
              })],
              verticalAlign: VerticalAlign.CENTER,
              width: {
                size: 25,
                type: WidthType.PERCENTAGE,
              },
            }),
            new TableCell({
              children: [
                new Paragraph({
                  alignment: AlignmentType.RIGHT,
                  children: [
                    new TextRun({
                      text: 'Fecha: ',
                      font: 'Calibri (Cuerpo)',
                      size: 22,
                      bold: true,

                    })
                  ],
                  spacing: {before: 100, after: 200}
                })],
              verticalAlign: VerticalAlign.CENTER,
              width: {
                size: 25,
                type: WidthType.PERCENTAGE,
              },
            }),
            new TableCell({
              children: [new Paragraph({
                alignment: AlignmentType.CENTER,
                children: [
                  new TextRun({
                    text: datePipe.transform(data.record.Applications[0].Creation_Date.includes('UTC') ? data.record.Applications[0].Creation_Date.replace('UTC', '').trim() : data.record.Applications[0].Creation_Date, "d-MMMM-y"),
                    font: 'Calibri (Cuerpo)',
                    size: 22,
                  })
                ],
              })],
              verticalAlign: VerticalAlign.CENTER,
              width: {
                size: 25,
                type: WidthType.PERCENTAGE,
              },
            }),
          ],
        }),
      ],
      margins: {
        left: 70,
        right: 70
      },
      width: {
        size: 100,
        type: WidthType.PERCENTAGE,
      },
      //9638
      columnWidths: [2410, 2410, 2409, 2409]
    })

    return table
  }

  getValue(submission, name) {
    var value = null
    for (const i in submission) {
      const element = submission[i]
      if (element.name === name) {
        value = element.value
      }
    }
    if (value) return value
    else return ''
  }

  getValueList(submission, name) {
    var value = null
    for (const i in submission) {
      const element = submission[i]
      if (element.name === name) {
        value = element.value
      }
    }
    if (value) return value
    else return []
  }

  // END MTO_FSOL_08_1_5
}
